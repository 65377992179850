import { can, route } from "../../../../../../js/helpers";
import { trans, uctrans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../../js/react/components/general/overview/SearchBarFilter";
import SelectFilter from "../../../../../../js/react/components/general/overview/SelectFilter";
import Translate from "../../../../../../js/react/components/general/Translate";
import Navigation from "../SettingsNavigation";

export default function MailingListRecipientOverview() {
	const existingEmails = window.data.existing_recipient_emails || [];

	return (
		<>
			<Navigation selectedTab="mailingListRecipient" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("communication.mailing_list_recipients.plural")}</h1>
				{can("communication.mailing_list_recipient", "create") && (
					<div>
						<IconButton
							href={route("crm.communication-settings.mailing-list-recipient.create")}
							content={uctrans("general.add_:item", {}, { item: "communication.mailing_list_recipients.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			{existingEmails.length > 0 && (
				<div className="mt-3">
					<div className="callout-warning p-4 mb-4">
						<Translate content="communication.mailing_list_recipients.existing_emails_warning" />

						<div className="mt-3">
							<ul>
								{existingEmails.map(mailingListRecipient => (
									<li key={mailingListRecipient.id}>
										<a
											href={route("crm.communication-settings.mailing-list-recipient.edit", mailingListRecipient.id)}
											className="row-title link">
											{mailingListRecipient.email}
										</a>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			)}

			<AbnOverview
				initialData={window.data.overview}
				indexUrl={route("crm.communication-settings.mailing-list-recipient.index")}
				deleteRouteName="crm.communication-settings.mailing-list-recipient.delete"
				modelTranslations={{
					plural: trans("communication.mailing_list_recipients.plural"),
					singular: trans("communication.mailing_list_recipients.singular"),
				}}
				renderFilters={overview => (
					<Filters>
						<SearchBarFilter type="text" name="search" />

						<SelectFilter
							className="form-1-2"
							name="newsletter_subscription_type"
							label={uctrans("communication.mailing_list_recipients.fields.newsletter_subscription_types")}
							options={overview.data.filterdata.newsletter_subscription_types || []}
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("communication.mailing_list_recipients.fields.email")}
							renderCellContent={mailingListRecipient => {
								if (can("communication.mailing_list_recipient", "update")) {
									return (
										<a
											href={route("crm.communication-settings.mailing-list-recipient.edit", mailingListRecipient.id)}
											className="row-title link">
											{mailingListRecipient.email}
										</a>
									);
								} else {
									return mailingListRecipient.email;
								}
							}}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("communication.mailing_list_recipients.fields.full_name")}
							renderCellContent={mailingListRecipient => mailingListRecipient.full_name}
						/>
						{can("communication.mailing_list_recipient", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(mailingListRecipient, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(mailingListRecipient)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
