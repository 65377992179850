import PropTypes from "prop-types";
import AccountSupportReport from "./account_support/AccountSupportReport";
import AfmReport from "./afm/AfmReport";
import CooperationTerminationReport from "./cooperation_termination/CooperationTerminationReport";
import ClientTreatmentReport from "./clientTreatment/ClientTreatmentReport";
import ConversationReport from "./conversation/ConversationReport";
import GiveawayReport from "./giveaway/GiveawayReport";
import IntermediariesReport from "./intermediaries/IntermediariesReport";
import MarketingReport from "./marketing/MarketingReport";
import OverruleReport from "./overrule/OverruleReport";
import SegmentationReport from "./segmentation/SegmentationReport";
import WalletAnalysisReport from "./wallet_analysis/WalletAnalysisReport";
import MarketScanReport from "./market_scan/MarketScanReport";
import CommercialReport from "./commercial/CommercialReport";
import ApplicationsReport from "./new_request/ApplicationsReport";

import Navigation from "./Navigation";

const Report = ({ type, reportTypes }) => {
	const getReport = type => {
		switch (type) {
			case "commercial":
				return <CommercialReport />;
			case "marketing":
				return <MarketingReport />;
			case "conversation":
				return <ConversationReport />;
			case "intermediaries_organisations":
				return <IntermediariesReport />;
			case "wallet_analysis":
				return <WalletAnalysisReport />;
			case "market_scan":
				return <MarketScanReport />;
			case "client_treatment":
				return <ClientTreatmentReport />;
			case "account_support":
				return <AccountSupportReport />;
			case "segmentation":
				return <SegmentationReport />;
			case "afm":
				return <AfmReport />;
			case "cooperation_termination":
				return <CooperationTerminationReport />;
			case "overrule":
				return <OverruleReport />;
			case "giveaway":
				return <GiveawayReport />;
			case "applications":
				return <ApplicationsReport />;
			default:
				return type;
		}
	};

	return (
		<>
			<Navigation reportTypes={reportTypes} selectedTab={type} />

			{getReport(type)}
		</>
	);
};

Report.propTypes = {
	type: PropTypes.string.isRequired,
	reportTypes: PropTypes.object,
};

export default Report;
