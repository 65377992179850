import { renderComponent } from "../../../../js/componentRenderer";
import IntermediaryDetail from "../components/intermediaries/IntermediaryDetail";
import LoanDetail from "../components/loans/LoanDetail";
import OrganisationDetail from "../components/organisations/OrganisationDetail";
import MapTool from "../components/location/MapTool";
/**
 * Entry file for forms, all forms are rendered here
 */
renderComponent(IntermediaryDetail, "intermediary_detail");
renderComponent(OrganisationDetail, "organisation_detail");
renderComponent(LoanDetail, "loan_detail");
renderComponent(MapTool, "map_tool");
