import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { route } from "../../../../../js/helpers";
import { format, parse } from "../../../../../js/lib/Date";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import AppDeviceOverview from "../../../../../js/react/components/app/AppDeviceOverview";
import CommunicationPreferences from "../../../../../js/react/components/communication/CommunicationPreferences";
import ContactpersonCommunicationRequestedBySelect from "../../../../../js/react/components/communication/ContactpersonCommunicationRequestedBySelect";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import MultiSelectAutoComplete from "../../../../../js/react/components/general/autocomplete/MultiSelectAutoComplete";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import ImageThumbnailFileInput from "../../../../../js/react/components/general/form/ImageThumbnailFileInput";
import InitialsTextInput from "../../../../../js/react/components/general/form/InitialsTextInput";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import Select from "../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../js/react/components/general/Translate";
import SelectInterestList from "../../../../../js/react/components/interests/SelectInterestList";
import useDateHandler from "../../../../../js/react/hooks/useDateHandler";
import useGenericHandler from "../../../../../js/react/hooks/useGenericHandler";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import UseRadioHandler from "../../../../../js/react/hooks/useRadioHandler";
import useSelectHandler from "../../../../../js/react/hooks/useSelectHandler";
import getEngagementScoreColor from "../engagementScore/EngagementScoreColor";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import CollapsibleContent from "../general/CollapsibleContent";
import SideBarChangelog from "../general/SideBarChangelog";
import TabLinks from "../general/tabs/TabLinks";
import FormattedNumberText from "../report/FormattedNumberText";

export default function ContactpersonForm(props) {
	const [employmentChanged, setEmploymentChanged] = useState(false);

	const [formData, setFormData] = useState(
		typeof props.contactpersonFormData === "undefined"
			? cloneDeep(window.data.contactperson_form_data)
			: props.contactpersonFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.contactpersonStaticData === "undefined"
			? cloneDeep(window.data.contactperson_static_data)
			: props.contactpersonStaticData,
	);

	const [selectedOccupation, setSelectedOccupation] = useState(
		staticData.occupations.find(occupancy => Number(occupancy.id) === Number(formData.occupation_id)),
	);

	const inputHandler = useInputHandler(setFormData);
	const radioHandler = UseRadioHandler(setFormData);
	const selectHandler = useSelectHandler(setFormData);
	const datePickerHandler = useDateHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);

	useEffect(() => {
		if (typeof props.contactpersonFormData !== "undefined") {
			setFormData(props.contactpersonFormData);
		}
	}, [props.contactpersonFormData]);

	useEffect(() => {
		if (typeof props.contactpersonStaticData !== "undefined") {
			setStaticData(props.contactpersonStaticData);
		}
	}, [props.contactpersonStaticData]);

	useEffect(() => {
		const newIds = (formData.employed_companies || [])
			.filter(item => item.active)
			.map(item => Number(item.value))
			.sort();

		const oldIds = (window.data.contactperson_form_data.employed_companies || [])
			.filter(item => item.active)
			.map(item => Number(item.value))
			.sort();

		const added = newIds.filter(x => !oldIds.includes(x));

		setEmploymentChanged(added.length > 0 || (staticData.creating && oldIds.length > 0));
	}, [staticData.creating, formData.employed_companies]);

	const handleOccupationChange = (selectedValue, metaData) => {
		selectHandler(selectedValue, metaData);
		if (selectedValue !== null) {
			const { value } = selectedValue;
			const result = staticData.occupations.find(occupancy => Number(occupancy.id) === Number(value));
			if (typeof result !== "undefined" && result.is_open) {
				setSelectedOccupation(result);
			} else {
				setSelectedOccupation("");
			}
		} else {
			setSelectedOccupation("");
		}
	};

	const hasPrivacyPreference = type =>
		formData.privacy_preferences.find(preference => Number(preference.type) === Number(type));

	const hasPrivacyPreferenceText = type => {
		const preference = hasPrivacyPreference(type);

		if (hasPrivacyPreference(type)) {
			return (
				<>
					<EvaIcon type="close-outline" width="18" height="18" fill="#f9acaa" className="mr-2" />{" "}
					{preference.when
						? uctrans("contactpersons.fields.permission_invoked_:at_:time", {
								at: format(preference.when, "dd-MM-y"),
								time: format(preference.when, "HH:mm"),
						  })
						: null}
				</>
			);
		} else {
			return <EvaIcon type="checkmark-outline" width="18" height="18" fill="#009286" className="mr-2" />;
		}
	};

	return (
		<>
			{window.data.nav_data && (
				<>
					<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
					<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
				</>
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					{staticData.page_title
						? staticData.page_title
						: uctrans(
								staticData.creating ? "general.add_:item" : "general.edit_:item",
								{},
								{ item: "contactpersons.singular" },
						  )}
				</h1>

				{staticData.previous_link && (
					<a className="link" href={staticData.previous_link}>
						{uctrans("general.back_to_overview_:page", {}, { page: "contactpersons.plural" })}
					</a>
				)}
			</div>

			<AjaxForm
				method={staticData.save_method}
				submitUrl={staticData.save_link}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "contactpersons.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "contactpersons.singular" })}
				onSuccess={response => Nav.go(response.data.redirect_route)}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<h2>{staticData.form_title}</h2>

							<div className="form-full">
								<label htmlFor="salutation">
									<Translate content="contactpersons.fields.salutation" />
								</label>
								<RadioGroup
									name="salutation"
									value={formData.salutation}
									onChange={value => radioHandler(value, "salutation")}
									horizontal>
									<Radio label={staticData.salutation_types.MR.label} value={staticData.salutation_types.MR.key} />
									<Radio label={staticData.salutation_types.MRS.label} value={staticData.salutation_types.MRS.key} />
								</RadioGroup>
							</div>

							<div className="form-1-6">
								<InitialsTextInput
									name="initials"
									value={formData.initials}
									onChange={inputHandler}
									label="contactpersons.fields.initials"
								/>
							</div>

							<div className="form-1-3">
								<LabeledTextInput
									name="first_name"
									value={formData.first_name}
									onChange={inputHandler}
									label="contactpersons.fields.first_name"
								/>
							</div>

							<div className="form-1-6">
								<LabeledTextInput
									name="insertion"
									value={formData.insertion}
									onChange={inputHandler}
									label="contactpersons.fields.insertion"
								/>
							</div>

							<div className="form-1-3">
								<LabeledTextInput
									name="last_name"
									value={formData.last_name}
									onChange={inputHandler}
									label="contactpersons.fields.last_name"
									required
								/>
							</div>

							<div className="form-full">
								<div className="form-1-2">
									<label htmlFor="occupation">
										<Translate content="contactpersons.fields.occupation" />
									</label>
									<Select
										placeholder={uctrans("general.select_:item", {}, { item: "contactpersons.fields.occupation" })}
										value={staticData.occupations_select.filter(
											({ value }) => Number(value) === Number(formData.occupation_id),
										)}
										options={staticData.occupations_select}
										name="occupation_id"
										onChange={handleOccupationChange}
									/>
								</div>
								{selectedOccupation && selectedOccupation.is_open && (
									<div className="form-1-2">
										<LabeledTextInput
											name="occupation_value"
											value={formData.occupation_value}
											onChange={inputHandler}
											label={selectedOccupation.open_title}
										/>
									</div>
								)}
							</div>

							<div className="form-full">
								<div className="form-1-3">
									<label htmlFor="date_of_birth">
										<Translate content="contactpersons.fields.date_of_birth" />
									</label>
									{hasPrivacyPreference(staticData.privacy_preference_types.DATE_OF_BIRTH.key) ? (
										uctrans("contactpersons.fields.date_of_birth_not_allowed")
									) : (
										<DatePicker
											initialValue={formData.date_of_birth !== null ? parse(formData.date_of_birth) : null}
											onChange={date => datePickerHandler(date, "date_of_birth")}
										/>
									)}
								</div>

								<div className="form-1-3">
									<label htmlFor="is_active">
										<Translate content="contactpersons.fields.active" />
									</label>
									<RadioGroup
										name="is_active"
										value={formData.is_active}
										onChange={value => radioHandler(value, "is_active")}
										horizontal>
										<Radio label={uctrans("general.yes")} value={1} />
										<Radio label={uctrans("general.no")} value={0} />
									</RadioGroup>
								</div>

								<div className="form-1-3">
									<label htmlFor="photo">
										<Translate content="contactpersons.fields.photo" />
									</label>
									{hasPrivacyPreference(staticData.privacy_preference_types.PHOTO.key) ? (
										uctrans("contactpersons.fields.photo_capture_not_allowed")
									) : (
										<ImageThumbnailFileInput
											storeRoute="crm.files.store"
											newFileDownloadRoute="crm.files.download"
											existingFileDownloadRoute="crm.contactpersons.download-image"
											maxFiles={1}
											image={formData.photo}
											onChange={files => genericHandler(files, "photo")}
										/>
									)}
								</div>
							</div>

							<div className="form-full">
								<CollapsibleContent
									initialCollapseState={false}
									title={uctrans("contactpersons.fields.occupanced_by")}
									required>
									<div className="form-1-2">
										<MultiSelectAutoComplete
											dataSource={staticData.search_companies_link}
											name="employed_companies"
											items={formData.employed_companies}
											onChange={selectedCompanies => {
												genericHandler(selectedCompanies, "employed_companies");
											}}
											placeholder={uctrans("general.type_to_add_:item", {}, { item: "companies.singular" })}
											async
										/>
									</div>

									{employmentChanged && (
										<ContactpersonCommunicationRequestedBySelect
											name="communication_creation_requested_by_contactperson_id"
											formData={formData}
											setFormData={setFormData}
											requestedByTypeOptions={staticData.communication_creation_requested_by_type_options}
											creating={staticData.creating}
											requestContactpersonCompanyIds={window.data.filter_company_ids}
											fromCrm
										/>
									)}
								</CollapsibleContent>
							</div>
							<div className="form-full">
								<CollapsibleContent initialCollapseState={false} title={uctrans("intermediaries.fields.contact")}>
									<div className="form-full">
										<div className="form-1-2">
											<LabeledTextInput
												name="email"
												label="contactpersons.fields.email_address"
												value={formData.email}
												onChange={inputHandler}
												required
											/>
										</div>
									</div>
									<div className="form-1-2">
										<LabeledTextInput
											name="landline"
											label="contactpersons.fields.landline"
											value={formData.landline}
											onChange={inputHandler}
										/>
									</div>
									<div className="form-1-2">
										<LabeledTextInput
											name="mobile"
											label="contactpersons.fields.mobile"
											value={formData.mobile}
											onChange={inputHandler}
										/>
									</div>
									<div className="form-full">
										<Checkmark
											onChange={inputHandler}
											name="mobile_for_login_only"
											checked={formData.mobile_for_login_only}
											label={uctrans("contactpersons.fields.mobile_for_login_only")}
										/>
									</div>
								</CollapsibleContent>
							</div>
							<div className="form-full">
								<CollapsibleContent title={uctrans("contactpersons.fields.interest")}>
									{hasPrivacyPreference(staticData.privacy_preference_types.INTEREST.key) ? (
										uctrans("contactpersons.fields.interests_not_allowed")
									) : (
										<SelectInterestList
											initialInteresttypes={formData.interests}
											initialCategories={staticData.interest_categories}
											onChange={selectedInterests => genericHandler(selectedInterests, "interests")}
										/>
									)}
								</CollapsibleContent>
							</div>

							<div className="form-full">
								<CollapsibleContent title={uctrans("contactpersons.fields.communication_preferences")}>
									<div className="form-full mb-4">
										<Translate content="contactpersons.communication_preferences_description" />
									</div>

									<div className="form-full mt-4">
										<CommunicationPreferences
											communicationPreferenceData={formData.unsubscribe_preferences}
											onPreferenceChange={value => genericHandler(value, "unsubscribe_preferences")}
											staticNewsLetterSubscriptionTypes={staticData.newsletter_subscription_types}
											unsubscribeReasons={staticData.unsubscribe_reasons}
											forCrm
										/>
									</div>
								</CollapsibleContent>
							</div>

							<div className="form-full">
								<CollapsibleContent title={uctrans("contactpersons.fields.privacy_preferences")}>
									{map(staticData.privacy_preference_types, (preference, index) => (
										<div key={index} className="flex w-full justify-between">
											<span className="font-bold mr-5 w-1/3">{preference.label}</span>
											<div className="flex w-full justify-start">
												<span className="ml-5">{hasPrivacyPreferenceText(preference.key)}</span>
											</div>
										</div>
									))}
								</CollapsibleContent>
							</div>
							<div className="form-full">
								<CollapsibleContent
									initialCollapseState={false}
									title={uctrans("contactpersons.fields.intermediary_website")}>
									<div className="bg-primary-lightest blocks-center px-3 py-3 my-2 ">
										<p>{uctrans("contactpersons.fields.iv_website_explanation")}</p>
									</div>

									<div className="form-full">
										<div className="form-1-3">
											<label htmlFor="website_permissiongroup_id">
												<Translate content="contactpersons.fields.website_permissiongroup" />{" "}
												<span className="text-orange"> *</span>
											</label>
											<Select
												placeholder={uctrans("general.select_:item", {}, { item: "contactpersons.fields.group" })}
												value={staticData.website_permissiongroup_options.filter(
													({ value }) => Number(value) === Number(formData.website_permissiongroup_id),
												)}
												options={staticData.website_permissiongroup_options}
												name="website_permissiongroup_id"
												onChange={selectHandler}
											/>
										</div>
									</div>

									<div className="form-full">
										<div className="form-1-2">
											<label htmlFor="password_change_type">
												<Translate content="contactpersons.fields.site_password" />
											</label>
											<Select
												placeholder={uctrans(
													"general.select_:item",
													{},
													{ item: "contactpersons.fields.password_change_type" },
												)}
												value={staticData.password_change_options.filter(
													({ value }) => Number(value) === Number(formData.password_change_type),
												)}
												isClearable={false}
												options={staticData.password_change_options}
												name="password_change_type"
												onChange={selectHandler}
											/>
										</div>
									</div>
									{formData.password_change_type &&
									Number(formData.password_change_type) ===
										Number(staticData.password_change_types.CHOOSE_NEW_PASSWORD) ? (
										<>
											<div className="form-full">
												<div className="form-1-3">
													<label htmlFor="current_password">
														<Translate content="passwords.your_current_password" />
													</label>
													<input
														id="current_password"
														type="password"
														name="current_password"
														value={formData.current_password}
														onChange={inputHandler}
														autoComplete="off"
													/>
												</div>
											</div>
											<div className="form-container">
												<div className="form-1-3">
													<label htmlFor="new_password">
														<Translate content="passwords.users_new_password" />
													</label>
													<input
														id="raw_password"
														type="password"
														name="raw_password"
														onChange={inputHandler}
														autoComplete="off"
													/>
												</div>
												<div className="form-1-3">
													<label htmlFor="raw_password_confirmation">
														<Translate content="passwords.users_new_password_confirmation" />
													</label>
													<input
														id="raw_password_confirmation"
														type="password"
														name="raw_password_confirmation"
														onChange={inputHandler}
														autoComplete="off"
													/>
												</div>
											</div>
										</>
									) : null}
								</CollapsibleContent>
							</div>
							<div className="form-full">
								<CollapsibleContent title={uctrans("app.app")}>
									<p>
										<Translate content="app.register_app_explanation" />
									</p>
									<strong className="text-2xl">{formData.generated_app_activation_code}</strong>

									<AppDeviceOverview
										devices={formData.app_devices}
										deleteRoute="crm.contactpersons.appdevices.delete"
										deleteRouteParams={{ contactperson: formData.id }}
									/>
								</CollapsibleContent>
							</div>

							<div className="row">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar
										item="contactpersons.singular"
										onSubmit={() => {
											if (selectedOccupation && !selectedOccupation.is_open) {
												setFormData({
													...formData,
													["occupation_value"]: "",
												});
											}
										}}
										addAnotherAvailable={false}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="flex-none w-1/3 border-l pl-12">
						<div>
							{!staticData.creating && (
								<>
									{staticData.login_history && (
										<CollapsibleContent initialCollapseState={false} title={uctrans("contactpersons.login_history")}>
											<div className="mb-6">
												<label>
													<Translate content="contactpersons.last_login" />
												</label>
												<span>{staticData.login_history.last_login}</span>
												<br />
												<span>{staticData.login_history.last_12_months}</span>
												<br />
												<label>
													<Translate content="contactpersons.last_app_activity" />
												</label>
												<span>{staticData.login_history.last_app_activity}</span>
												<br />
												<span>{staticData.login_history.last_app_12_months}</span>
											</div>
										</CollapsibleContent>
									)}
									<CollapsibleContent title={uctrans("intermediaries.fields.classification")}>
										{typeof formData.is_active !== "undefined" && (
											<div className="flex w-full justify-start text-m">
												<h5 className="mr-5 w-1/3">{uctrans("contactpersons.fields.current")}</h5>
												<div className="flex w-full justify-start">
													<span>
														{formData.is_active
															? uctrans("contactpersons.fields.active")
															: uctrans("contactpersons.fields.in_active")}
													</span>
												</div>
											</div>
										)}
										{staticData.changelog_changes && staticData.changelog_changes.is_active && (
											<>
												<h5>{uctrans("contactpersons.fields.employment_changelog")}</h5>
												<SideBarChangelog
													changelogData={staticData.changelog_changes.is_active}
													changelogChangeTypes={staticData.changelog_change_types}
													changelogRelations={[
														{ value: 0, label: uctrans("contactpersons.fields.in_active") },
														{ value: 1, label: uctrans("contactpersons.fields.active") },
													]}
												/>
											</>
										)}
									</CollapsibleContent>
									<CollapsibleContent initialCollapseState={false} title={uctrans("contactpersons.fields.employed_at")}>
										<div className="mb-6 html-content">
											<ul>
												{formData.employed_companies.map(company => (
													<li key={company.value}>
														<a className="link" href={company.edit_link}>
															{company.label}
														</a>
													</li>
												))}
											</ul>
										</div>
										{staticData.employment_changelog && staticData.employment_changelog.length ? (
											<>
												<h5 className="inline-block">{uctrans("contactpersons.fields.employment_changelog")}</h5>
												{staticData.employment_changelog.map((changeLog, index) => (
													<div key={index} className="flex w-full justify-start text-xs">
														<span className="font-bold mr-5 w-1/3">{format(changeLog.datetime, "dd-MM-y")}</span>
														<div className="flex w-full justify-start">
															{Number(changeLog.type) === 1 ? (
																<EvaIcon type="plus-circle-outline" fill="#009286" height="18" width="18" />
															) : (
																<EvaIcon type="minus-circle-outline" fill="#f9ACAA" height="18" width="18" />
															)}
															<span className="ml-5">{changeLog.label}</span>
														</div>
													</div>
												))}
											</>
										) : (
											<span>geen changelog items om te tonen</span>
										)}
									</CollapsibleContent>
									{staticData.engagement_score && (
										<CollapsibleContent initialCollapseState={false} title={uctrans("engagement_score.singular")}>
											<div className="mb-1">
												<Translate content="engagement_score.fields.website_visitors" />
												{": "}
												<span>{staticData.engagement_score.website_visitors || 0}</span>
											</div>
											<div className="mb-1">
												<Translate content="engagement_score.fields.app_visitors" />
												{": "}
												<span>{staticData.engagement_score.app_visitors || 0}</span>
											</div>
											<div className="mb-1">
												<Translate content="engagement_score.fields.emails_opened" />
												{": "}
												<span>{staticData.engagement_score.emails_opened || 0}</span>
											</div>
											<br />
											{staticData.total_relative_engagement_score && (
												<div className="flex w-full">
													<div>
														<div
															className="rounded-full dashboard-circle-engagement-score flex items-center justify-center"
															style={{
																background: getEngagementScoreColor(
																	staticData.total_relative_engagement_score,
																	staticData.engagement_score.score_relative,
																),
															}}>
															<span className="font-medium">{staticData.engagement_score.score_relative}</span>
														</div>
													</div>
													<div className="flex items-center ml-5">
														<ul className="border-l p-2 text-sm">
															<li>{uctrans("dashboard.nationwide")}</li>
															<li>{staticData.total_relative_engagement_score}</li>
														</ul>
													</div>
												</div>
											)}
										</CollapsibleContent>
									)}

									<CollapsibleContent
										initialCollapseState={false}
										title={uctrans("contactpersons.fields.overview_costs")}>
										<div className="mb-6">
											<div className="flex items-center justify-between mx-2 my-2">
												<span>{uctrans("client_treatment.fields.with_usefull_content_character")}:</span>
												<FormattedNumberText
													prefix="€ "
													decimalScale={2}
													value={
														staticData.ct_costs && staticData.ct_costs.with_usefull_content_character
															? staticData.ct_costs.with_usefull_content_character
															: 0
													}
												/>
											</div>
											<div className="flex items-center justify-between mx-2 my-2">
												<span>{uctrans("client_treatment.fields.without_usefull_content_character")}:</span>
												<FormattedNumberText
													prefix="€ "
													decimalScale={2}
													value={
														staticData.ct_costs && staticData.ct_costs.without_usefull_content_character
															? staticData.ct_costs.without_usefull_content_character
															: 0
													}
												/>
											</div>
											<div className="flex items-center justify-between mx-2 my-2">
												<span>{uctrans("giveaways.plural")}:</span>
												<FormattedNumberText
													prefix="€ "
													decimalScale={2}
													value={
														staticData.ct_costs && staticData.ct_costs.giveawaycosts
															? staticData.ct_costs.giveawaycosts
															: 0
													}
												/>
											</div>
											<hr className="w-full" />
											<div className="flex items-center justify-between mx-2 my-2">
												<span>{uctrans("report.total")}:</span>
												<FormattedNumberText
													prefix="€ "
													decimalScale={2}
													value={staticData.ct_costs && staticData.ct_costs.total ? staticData.ct_costs.total : 0}
												/>
											</div>
										</div>
									</CollapsibleContent>

									{window.data.company_scope && formData.id > 0 && (
										<a
											className="button button-primary"
											href={route("crm.contactpersons.communicationlog", formData.id)}>
											{uctrans("contactpersons.fields.communicationlog")}
										</a>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}

ContactpersonForm.propTypes = {
	contactpersonFormData: PropTypes.object,
	contactpersonStaticData: PropTypes.object,
};
