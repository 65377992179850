import axios from "axios";
import { useEffect, useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import DebouncedInput from "../../../../../js/react/components/general/form/DebouncedInput";
import { route, can } from "../../../../../js/helpers";
import Spinner from "../../../../../js/react/components/general/Spinner";

export default function CrmMenuSearchBox() {
	const [searchTerm, setSearchterm] = useState("");
	const [searching, setSearching] = useState(false);
	const [searchResults, setSearchResults] = useState([]);

	useEffect(() => {
		if (searchTerm !== "") {
			setSearching(true);
			axios
				.post(route("crm.search.data"), {
					term: searchTerm,
				})
				.then(response => {
					setSearchResults(response.data);
					setSearching(false);
				})
				.catch(error => {
					console.error(`Errors during search: ${error}`);
				});
		} else {
			setSearchResults([]);
			setSearching(false);
		}
	}, [searchTerm]);

	return (
		can("crm-search") && (
			<>
				<div className="main-search-bar">
					<DebouncedInput
						type="text"
						className="main-search-bar-input"
						name="filters[term]"
						autocomplete={false}
						placeholder={uctrans("general.type_to_search")}
						value={searchTerm}
						onChange={e => setSearchterm(e.target.value)}
					/>
				</div>
				{searching && (
					<div className="search-loader">
						<Spinner width={50} />
					</div>
				)}
				{!searching && !!searchResults.length && (
					<div className="search-results">
						<ul className="pl-0">
							{searchResults.map((searchResult, key) => (
								<li key={key} className="search-result pl-0 mb-4">
									<div className="search-category w-32">{searchResult.category}</div>
									<div className="search-value">
										{searchResult.url && <a href={searchResult.url}>{searchResult.label}</a>}
										{!searchResult.url && <span>{searchResult.label}</span>}
									</div>
								</li>
							))}
						</ul>
					</div>
				)}
			</>
		)
	);
}
