import PropTypes from "prop-types";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import Tippy from "@tippyjs/react";

export default function KvkTreeNaturalPersonNotification({ name, dateOfBirth, dossierDirectors, ownerFunction }) {
	const reteiveLastPartFromName = longName => longName.toLowerCase().split(/[, ]+/).pop();

	const numericDateOfBirthToCompareDateOfBirth = numericDateOfBirth =>
		`${numericDateOfBirth.substring(0, 4)}-${numericDateOfBirth.substring(4, 6)}-${numericDateOfBirth.substring(6, 8)}`;

	const checkIfDirectorExists = (kvkName, kvkDateOfBirth, directors) =>
		directors.some(
			director =>
				director.has_files &&
				director.date_of_birth === numericDateOfBirthToCompareDateOfBirth(kvkDateOfBirth) &&
				reteiveLastPartFromName(director.last_name) === reteiveLastPartFromName(kvkName),
		);
	const functionShouldGetIcon = func =>
		!["enig aandeelhouder", "gevolmachtigde", "commissaris"].includes(func.toLowerCase());

	return functionShouldGetIcon(ownerFunction) ? (
		<span className="ml-2">
			{checkIfDirectorExists(name, dateOfBirth, dossierDirectors) ? (
				<EvaIcon type="checkmark-circle-2" height="16" width="16" fill="#009286" />
			) : (
				<Tippy
					placement="auto"
					arrow
					animation="perspective"
					duration="400"
					content={uctrans("statutory_companies.establishments.no_director_id")}>
					<span>
						<EvaIcon type="alert-triangle-outline" height="16" width="16" fill="#E3342F" />
					</span>
				</Tippy>
			)}
		</span>
	) : null;
}

KvkTreeNaturalPersonNotification.propTypes = {
	name: PropTypes.string,
	dateOfBirth: PropTypes.string,
	ownerFunction: PropTypes.string,
	dossierDirectors: PropTypes.arrayOf(
		PropTypes.shape({
			date_of_birth: PropTypes.string,
			first_name: PropTypes.string,
			has_files: PropTypes.bool,
			id: PropTypes.number,
			initials: PropTypes.string,
			insertion: PropTypes.string,
			last_name: PropTypes.string,
			salutation: PropTypes.number,
		}),
	),
};
