import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import PropTypes from "prop-types";
import { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Translate from "../../../../../js/react/components/general/Translate";

class MatrixQuestionRowList extends Component {
	constructor(props) {
		super(props);

		this.addQuestionRow = this.addQuestionRow.bind(this);
		this.editQuestionRowField = this.editQuestionRowField.bind(this);
		this.deleteQuestionRow = this.deleteQuestionRow.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.updateQuestionRows = this.updateQuestionRows.bind(this);
	}

	addQuestionRow() {
		const questionRows = this.props.questionRows.slice(0);
		questionRows.push({
			content: "",
		});
		this.updateQuestionRows(questionRows);
	}

	editQuestionRowField(index, field, value) {
		const questionRows = this.props.questionRows.slice(0);
		questionRows[index][field] = value;
		this.updateQuestionRows(questionRows);
	}

	deleteQuestionRow(index) {
		const questionRows = this.props.questionRows.slice(0);
		questionRows.splice(index, 1);
		this.updateQuestionRows(questionRows);
	}

	onDragEnd(dragEvent) {
		if (dragEvent.destination) {
			const sourceIndex = dragEvent.source.index;
			const targetIndex = dragEvent.destination.index;

			if (`${sourceIndex}` !== `${targetIndex}`) {
				const questionRows = this.props.questionRows.slice(0);
				const questionRow = questionRows[sourceIndex];
				questionRows.splice(sourceIndex, 1);
				questionRows.splice(targetIndex, 0, questionRow);
				this.updateQuestionRows(questionRows);
			}
		}
	}

	updateQuestionRows(questionRows) {
		this.props.onQuestionRowsChange(questionRows);
	}

	static getDraggingClassNames(isDragging) {
		if (isDragging) {
			return "active";
		}

		return "inactive";
	}

	static getListStyle(isDraggingOver) {
		return {
			background: isDraggingOver ? "lightblue" : "lightgrey",
		};
	}

	render() {
		return (
			<div>
				<DragDropContext onDragEnd={dragEvent => this.onDragEnd(dragEvent)}>
					<Droppable droppableId="questionRows">
						{(provided, snapshot) => (
							<div
								className="answer-option-list"
								ref={provided.innerRef}
								style={MatrixQuestionRowList.getListStyle(snapshot.isDraggingOver)}
								{...provided.droppableProps}>
								{this.props.questionRows.map((questionRow, index) => (
									<Draggable
										key={index}
										draggableId={index.toString()}
										index={index}
										isDragDisabled={this.props.questionRows.length <= 1}>
										{(provided, snapshot) => (
											<div
												{...provided.dragHandleProps}
												ref={provided.innerRef}
												{...provided.draggableProps}
												className={`answer-option-list-item form-container q-draggable-overview-item q-draggable-item q-dragging-${MatrixQuestionRowList.getDraggingClassNames(
													snapshot.isDragging,
												)}`}>
												<br />

												<div className="form-full answer-value">
													<div className="answer-number form-1-4">
														<h6 className="block">
															<Translate content="questionnaires.question.question_row.singular" /> {index + 1}
														</h6>
													</div>

													<div className="answer-action ml-auto">
														<span className="float-right delete-button" onClick={() => this.deleteQuestionRow(index)}>
															<EvaIcon type="trash-2-outline" height="18" width="18" fill="#f9acaa" />
														</span>
													</div>
												</div>

												<div className="form-full answer-content">
													<div className="form-1-6">
														<Translate content="questionnaires.question.question_row.fields.content" />
													</div>
													<div className="form-3-4">
														<input
															type="text"
															name="content"
															value={questionRow.content || ""}
															onChange={event => this.editQuestionRowField(index, "content", event.target.value)}
														/>
													</div>
												</div>

												<br />
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				<br />

				<div className="float-right">
					<a onClick={() => this.addQuestionRow()} className="button button-secondary">
						<Translate content="questionnaires.question.add_question_row" />
					</a>
				</div>
			</div>
		);
	}
}

export default MatrixQuestionRowList;

MatrixQuestionRowList.propTypes = {
	questionRows: PropTypes.array,
	onQuestionRowsChange: PropTypes.func.isRequired,
};

MatrixQuestionRowList.defaultProps = {
	questionRows: [],
};
