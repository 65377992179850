import PropTypes from "prop-types";
import { useContext } from "react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import IbanTextInput from "../../../../../../../js/react/components/general/form/IbanTextInput";
import KvkTextInput from "../../../../../../../js/react/components/general/form/KvkTextInput";
import LabeledTextInput from "../../../../../../../js/react/components/general/form/LabeledTextInput";
import CompanyKvkData from "../../CompanyKvkData";
import CompanyDossierContext from "../CompanyDossierContext";
import DossierFileInput from "../fileInput/DossierFileInput";
import DossierPartOrganisationIsCentral from "./DossierPartOrganisationIsCentral";

export default function BoundFieldDossierPart({ completionData, fieldName, partData }) {
	const {
		getPartType,
		handleFormChange,
		getCompanyDossierValue,
		dossierPartTypes,
		eArchiveUrl,
		readOnly,
		companyId,
		mainCompany,
	} = useContext(CompanyDossierContext);

	const partType = getPartType(partData.type);

	const value = completionData
		? completionData.field_value === null
			? getCompanyDossierValue(fieldName)
			: completionData.field_value
		: "";

	const dossierInput = () => {
		switch (fieldName) {
			case "iban":
				return (
					<div className="dossier-row">
						<IbanTextInput
							translateLabel={false}
							label={partData.field_name && partData.field_name !== "" ? partData.field_name : partType.label}
							disabled={!!readOnly}
							value={value}
							name={fieldName}
							onChange={e => handleFormChange(partData.id, "field_value", e.target.value)}
						/>
					</div>
				);
			case "kvk_number":
				return (
					<>
						<div className="dossier-row">
							<CompanyKvkData companyId={companyId} mainCompany={mainCompany} />
						</div>
						<div className="dossier-row">
							<KvkTextInput
								translateLabel={false}
								label={partData.field_name && partData.field_name !== "" ? partData.field_name : partType.label}
								disabled
								value={value}
								name={fieldName}
							/>
						</div>
					</>
				);
			default:
				return (
					<>
						<div className="dossier-row">
							<LabeledTextInput
								translateLabel={false}
								label={partData.field_name && partData.field_name !== "" ? partData.field_name : partType.label}
								disabled={readOnly}
								value={value}
								name={fieldName}
								onChange={e => handleFormChange(partData.id, "field_value", e.target.value)}
							/>
						</div>
						{Number(partData.type) === Number(dossierPartTypes.EARCHIVE.key) && (
							<div className="dossier-row">
								<label />
								<a href={eArchiveUrl} className="link block" rel="noopener noreferrer" target="_blank">
									<small>
										{uctrans("dossiers.fields.to_e_archive")}
										<span> ({uctrans("dossiers.fields.rights_needed")})</span>
									</small>
								</a>
							</div>
						)}
					</>
				);
		}
	};

	return (
		<>
			{dossierInput()}

			<DossierPartOrganisationIsCentral completionData={completionData} />

			<div className="dossier-row">
				<label htmlFor="type">{uctrans("dossiers.fields.files")}</label>
				<DossierFileInput
					disabled={readOnly}
					existingFileDownloadRoute="crm.intermediaries.dossier.download-completion-file"
					initialFiles={completionData ? completionData.files.files : []}
					initialArchivedFiles={completionData ? completionData.files.archivedFiles : []}
					onChange={files => handleFormChange(partData.id, "files", files)}
				/>
			</div>
		</>
	);
}

BoundFieldDossierPart.propTypes = {
	partData: PropTypes.object,
	completionData: PropTypes.object,
	fieldName: PropTypes.string,
};
