import find from "lodash/find";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { CmsTypesContext } from "../../cms-types-context";
import Boolean from "../attributes/Boolean";
import Text from "../attributes/Text";

export default function ClickRemember(props) {
	const [values, setValues] = useState(props.values ? props.values : {});
	useEffect(() => {
		setValues(props.values ? props.values : {});
	}, [props.values]);

	const handleValueChange = (key, value) => {
		const newValues = { ...values };
		newValues[key] = value;
		setValues(newValues);
		props.onChange(newValues);
	};

	const { partTypes } = useContext(CmsTypesContext);
	const partType = find(partTypes, partType => partType.key === "click_remember");

	if (partType) {
		return (
			<>
				{props.label && <label>{props.label}</label>}

				<div className="mt-3">
					<Boolean
						label={partType.attributes.hide_after_click.label}
						value={values.hide_after_click}
						onChange={value => {
							handleValueChange("hide_after_click", value);
						}}
					/>
					{!!values.hide_after_click && (
						<Text
							label={partType.attributes.click_reference.label}
							value={values.click_reference}
							onChange={value => {
								handleValueChange("click_reference", value);
							}}
						/>
					)}
				</div>
			</>
		);
	}
}

ClickRemember.propTypes = {
	label: PropTypes.string,
	values: PropTypes.shape({
		hide_after_click: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
		click_reference: PropTypes.string,
	}),
	onChange: PropTypes.func.isRequired,
};
