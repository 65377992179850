import Tippy from "@tippyjs/react";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { format } from "../../../../../js/lib/Date";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import { can, route } from "../../../../../js/helpers";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import Navigation from "./Navigation";

export default function ClientTreatmentEvaluationOverview(props) {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	const [staticData, setStaticData] = useState(
		typeof props.ClietTreatmentStaticData === "undefined"
			? cloneDeep(window.data.client_treatment_static_data)
			: props.ClietTreatmentStaticData,
	);

	useEffect(() => {
		if (typeof props.ClietTreatmentStaticData !== "undefined") {
			setStaticData(props.ClietTreatmentStaticData);
		}
	}, [props.ClietTreatmentStaticData]);

	return (
		<>
			<Navigation
				selectedTab="client-treatment-evaluations"
				clientTreatment={staticData.client_treatment}
				clientTreatmentTypes={staticData.client_treatment_types}
				loggedInInviter={
					window.data.user
						? staticData.client_treatment.inviters.find(
								inviter => Number(inviter.user_id) === Number(window.data.user.id),
						  )
						: null
				}
				canUpdate={can("client_treatment.client_treatment", "update")}
			/>
			<div className="page-title-container">
				<h1 className="page-title">{`${uctrans("client_treatment.singular")} ${staticData.client_treatment.name}`}</h1>

				{can("client_treatment.client_treatment", "update") && (
					<div>
						<IconButton
							icon="edit-outline"
							href={route("crm.client-treatments.evaluations.questionnaire", {
								client_treatment: staticData.client_treatment.id,
							})}
							content={uctrans("client_treatment.evaluations.fields.edit_questions")}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.client-treatments.evaluations", {
					client_treatment: staticData.client_treatment.id,
				})}
				modelTranslations={{
					plural: trans("client_treatment.evaluations.plural"),
					singular: trans("client_treatment.evaluations.singular"),
				}}
				renderFilters={overview => (
					<Filters>
						<SelectFilter
							className="form-1-3"
							name="evaluation_status"
							label={uctrans("client_treatment.evaluations.fields.evaluation_status")}
							options={overview.data.filterdata.evaluation_statuses}
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={4}
							renderHeaderContent={() => uctrans("intermediaries.fields.relationmanager")}
							renderCellContent={treatment => (treatment.inviter ? treatment.inviter : "-")}
						/>
						<DataColumn
							width={6}
							renderHeaderContent={() => uctrans("client_treatment.completions.type")}
							renderCellContent={participant => (participant.type ? participant.type.label : "-")}
						/>
						<DataColumn
							width={4}
							renderHeaderContent={() => uctrans("contactpersons.singular")}
							renderCellContent={participant =>
								participant.participant_object ? participant.participant_object.label : "-"
							}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("client_treatment.evaluations.fields.date")}
							renderCellContent={participant =>
								participant.questionnaire_completion
									? format(participant.questionnaire_completion.created_at, "dd-MM-y")
									: uctrans("client_treatment.evaluations.fields.not_filled_out")
							}
						/>
						<DataColumn
							width={4}
							renderHeaderContent={() => uctrans("client_treatment.evaluations.fields.evaluation_status")}
							renderCellContent={participant => (participant.status ? participant.status : "-")}
						/>
						<DataColumn
							width={1}
							renderHeaderContent={() => ""}
							renderCellContent={(participant, overview) => (
								<div className="overview-actions">
									{can("client_treatment.client_treatment", "update") && (
										<a
											className="link mr-3"
											target="_blank"
											rel="noopener noreferrer"
											href={route("website.client-treatment-evaluation", {
												participant_id_token: participant.id_token,
											})}>
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("client_treatment.evaluations.fields.to_participant_evaluation")}>
												<span>
													<EvaIcon type="external-link-outline" width="18" height="18" fill="#009286" />
												</span>
											</Tippy>
										</a>
									)}

									{participant.questionnaire_completion && (
										<a
											className="link mr-3"
											href={route("crm.client-treatments.evaluations.questionnaire.answers", {
												client_treatment: staticData.client_treatment.id,
												participant: participant.id,
											})}>
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("client_treatment.evaluations.fields.go_to_answers")}>
												<span>
													<EvaIcon type="eye-outline" width="18" height="18" fill="#009286" />
												</span>
											</Tippy>
										</a>
									)}

									{can("client_treatment.client_treatment", "update") && participant.questionnaire_completion && (
										<a
											onClick={() => {
												overview.askDelete(participant);
											}}>
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("client_treatment.evaluations.fields.delete_evaluation_participation")}>
												<span>
													<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
												</span>
											</Tippy>
										</a>
									)}
								</div>
							)}
						/>
					</DataTable>
				)}
			/>

			{staticData.show_statistics ? (
				<div className="flex w-full justify-start">
					<div className="w-full mt-3">
						<a
							className="button button-tertiary"
							href={route("crm.client-treatments.evaluations.statistics-not-collegues", {
								client_treatment: staticData.client_treatment.id,
							})}>
							{uctrans("client_treatment.evaluations.fields.view_statistics_not_collegues")}
						</a>
						<a
							className="button button-tertiary ml-6"
							href={route("crm.client-treatments.evaluations.statistics-collegues", {
								client_treatment: staticData.client_treatment.id,
							})}>
							{uctrans("client_treatment.evaluations.fields.view_statistics_collegues")}
						</a>
					</div>
				</div>
			) : null}
		</>
	);
}
ClientTreatmentEvaluationOverview.propTypes = {
	ClietTreatmentStaticData: PropTypes.object,
};
