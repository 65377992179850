import { useState } from "react";
import Nav from "../../../../../../js/lib/Nav";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import { route } from "../../../../../../js/helpers";
import UserFormFields from "../../users/UserFormFields";
import Navigation from "../Navigation";

export default function AahgUserForm() {
	const [formData, setFormData] = useState({ ...window.data.user_form_data });
	const staticData = { ...window.data.user_static_data };
	const readOnly = window.data.read_only;

	const onSuccess = () => {
		Nav.go(staticData.is_active ? route("crm.aahg-users.index") : route("crm.aahg-users.deactivated"));
	};

	return (
		<>
			<Navigation selectedTab="aahg-users" />

			<div className="page-title-container">
				<h1 className="page-title">
					{staticData.creating
						? uctrans("general.add_:item", {}, { item: "users.aahg_users.singular" })
						: staticData.label}
				</h1>
				<a
					className="link"
					href={staticData.is_active ? route("crm.aahg-users.index") : route("crm.aahg-users.deactivated")}>
					{uctrans(
						"general.back_to_overview_:page",
						{},
						{ page: staticData.is_active ? "users.aahg_users.plural" : "users.aahg_users.deactivated_plural" },
					)}
				</a>
			</div>
			<AjaxForm
				method={staticData.creating ? "POST" : "PUT"}
				submitUrl={staticData.creating ? route("crm.aahg-users.store") : route("crm.aahg-users.update", staticData.id)}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "users.aahg_users.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "users.aahg_users.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<UserFormFields
								formData={formData}
								setFormData={setFormData}
								staticData={staticData}
								readOnly={readOnly}
								existingFileDownloadRoute="crm.aahg-users.avatar.download"
								useInternalEmailAddress={false}
								ignoreAutomaticExclusion
							/>

							{!readOnly && (
								<div className="form-full">
									<div className="w-full md:w-1/2 lg:w-1/3 column">
										<SubmitBar item="users.aahg_users.singular" addAnotherAvailable={false} />
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
