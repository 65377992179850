import PropTypes from "prop-types";
import { useCallback } from "react";
import { route } from "../../../../../../../js/helpers";
import { default_date_format, format, parse } from "../../../../../../../js/lib/Date";
import { uctrans } from "../../../../../../../js/lib/Translator";

export default function DossierSidebarSharedData({ staticData, formData }) {
	const getValueFromDossierCompletion = useCallback(
		(type, value) => {
			switch (Number(type)) {
				case staticData.dossier_part_types.DATE.key:
					return parse(value, default_date_format);
				case staticData.dossier_part_types.YES_NO.key:
					return Number(value === 0) ? uctrans("general.yes") : uctrans("general.no");
				default:
					return value;
			}
		},
		[staticData.dossier_part_types.DATE.key, staticData.dossier_part_types.YES_NO.key],
	);

	const getFieldNameFromDossierCompletion = useCallback(
		type => {
			const dossierPart = [
				...staticData.available_establishment_dossier_parts,
				...staticData.available_statutory_dossier_parts,
			].find(part => `${part.type}` === `${type}`);

			return dossierPart && dossierPart.field_name !== "" ? dossierPart.field_name : uctrans("dossiers.field_value");
		},
		[staticData.available_establishment_dossier_parts, staticData.available_statutory_dossier_parts],
	);

	return (
		<>
			<div>
				<span>{uctrans("intermediaries.connected_to")}</span>
				{": "}
				<a
					href={
						window.data.agendaNamespace
							? route("crm.agenda.organisations.dossier.edit", staticData.company_data.parent_id)
							: route("crm.organisations.dossier.edit", staticData.company_data.parent_id)
					}
					className="row-title link">
					{staticData.company_data.organisation.name}
				</a>{" "}
			</div>
			{staticData.company_data.organisation.statutory_company && (
				<>
					{" "}
					<div>
						<span className="font-bold">{uctrans("intermediaries.fields.kvk_number")}</span>
						{": "}
						<span>{staticData.company_data.organisation.statutory_company.kvk_number}</span>
					</div>
					<div>
						<span className="font-bold">{uctrans("intermediaries.fields.afm_number")}</span>
						{": "}
						<span>{staticData.company_data.organisation.statutory_company.afm_number}</span>
					</div>
				</>
			)}
			{staticData.company_data.organisation.iban && (
				<div>
					<span className="font-bold">{uctrans("intermediaries.fields.bank_number")}</span>
					{": "}
					<span>{staticData.company_data.organisation.iban}</span>
				</div>
			)}
			<div>
				<span className="font-bold">{uctrans("dossiers.central_parts")}:</span>
				<br />

				<ul className="linked-items">
					{formData.parent_central_dossier_completions.map(
						(completion, index) =>
							completion.dossier_part !== null && (
								<li className="bg-grey-light px-3 py-2 my-2" key={index}>
									<span className="font-bold">{uctrans("intermediaries.fields.name")}:</span>
									<span className="ml-2">{completion.dossier_part.name}</span>
									<br />

									{Number(completion.dossier_part.type) === 10 && formData.parent_dossier_directors ? (
										<>
											<div>
												<span className="font-bold">{uctrans("dossiers.directors.plural")}:</span>
												<br />
												<div className="ml-2">
													{formData.parent_dossier_directors
														.filter(director => !director.archived)
														.map((director, index) => (
															<div key={index}>
																<span className="text-sm ml-4">{`${director.first_name} ${director.insertion} ${
																	director.last_name
																}${
																	director.date_of_birth ? ` - ${format(director.date_of_birth, "dd-MM-y")}` : ""
																}`}</span>
																<br />
																{!!director.files && (
																	<>
																		{!!director.files.files && director.files.files.length ? (
																			<>
																				{director.files.files.map((file, index) => (
																					<div key={index} className="ml-4">
																						{"- "}
																						<a
																							className="link flex-1 text-sm"
																							href={route(
																								"crm.intermediaries.dossier.download-director-file",
																								file.id,
																							)}>
																							{file.filename}
																						</a>
																						<br />
																					</div>
																				))}
																			</>
																		) : null}
																		{!!director.files.archivedFiles && director.files.archivedFiles.length ? (
																			<>
																				{director.files.archivedFiles.map((file, index) => (
																					<div key={index} className="ml-4">
																						{"- "}
																						<a
																							className="link flex-1 text-sm"
																							href={route(
																								"crm.intermediaries.dossier.download-director-file",
																								file.id,
																							)}>
																							{file.filename}
																						</a>
																						<br />
																					</div>
																				))}
																			</>
																		) : null}
																	</>
																)}
																<br />
															</div>
														))}
												</div>
											</div>
											<div>
												<span className="font-bold">{uctrans("dossiers.directors.fields.archived")}:</span>
												<br />
												<div className="ml-2">
													{formData.parent_dossier_directors
														.filter(director => director.archived)
														.map((director, index) => (
															<div key={index}>
																<span className="text-sm ml-4">{`${director.first_name} ${director.insertion} ${director.last_name}`}</span>
																<br />
																{!!director.files && (
																	<>
																		{!!director.files.files && director.files.files.length ? (
																			<>
																				{director.files.files.map((file, index) => (
																					<div key={index} className="ml-4">
																						{"- "}
																						<a
																							className="link flex-1 text-sm"
																							href={route(
																								"crm.intermediaries.dossier.download-director-file",
																								file.id,
																							)}>
																							{file.filename}
																						</a>
																						<br />
																					</div>
																				))}
																			</>
																		) : null}
																		{!!director.files.archivedFiles && director.files.archivedFiles.length ? (
																			<>
																				{director.files.archivedFiles.map((file, index) => (
																					<div key={index} className="ml-4">
																						{"- "}
																						<a
																							className="link flex-1 text-sm"
																							href={route(
																								"crm.intermediaries.dossier.download-director-file",
																								file.id,
																							)}>
																							{file.filename}
																						</a>
																						<br />
																					</div>
																				))}
																			</>
																		) : null}
																	</>
																)}
																<br />
															</div>
														))}
												</div>
											</div>
										</>
									) : (
										<>
											{!!completion.field_value && (
												<>
													<span className="font-bold">
														{getFieldNameFromDossierCompletion(completion.dossier_part.type)}:
													</span>
													<span className="ml-2">
														{getValueFromDossierCompletion(completion.dossier_part.type, completion.field_value)}
													</span>
													<br />
												</>
											)}

											{!!completion.files && (
												<>
													{!!completion.files.files && completion.files.files.length ? (
														<>
															<span className="font-bold">{uctrans("file.plural")}:</span>
															<br />
															{completion.files.files.map((file, index) => (
																<div key={index} className="ml-4">
																	{"- "}
																	<a
																		className="link flex-1"
																		href={route("crm.intermediaries.dossier.download-completion-file", file.id)}>
																		{file.filename}
																	</a>
																	<br />
																</div>
															))}
														</>
													) : null}
													{!!completion.files.archivedFiles && completion.files.archivedFiles.length ? (
														<>
															<span className="font-bold">{uctrans("file.archived_files")}:</span>
															<br />
															{completion.files.archivedFiles.map((file, index) => (
																<div key={index} className="ml-4">
																	{"- "}
																	<a
																		className="link flex-1"
																		href={route("crm.intermediaries.dossier.download-completion-file", file.id)}>
																		{file.filename}
																	</a>
																	<br />
																</div>
															))}
														</>
													) : null}
												</>
											)}
										</>
									)}
								</li>
							),
					)}
				</ul>
			</div>
		</>
	);
}

DossierSidebarSharedData.propTypes = {
	staticData: PropTypes.object,
	formData: PropTypes.object,
};
