import PropTypes from "prop-types";
import classNames from "classnames";
import SingleSelectAutoComplete from "../autocomplete/SingleSelectAutoComplete";
import Filter from "./Filter";

class SingleSelectAutoCompleteFilter extends Filter {
	renderAs(context) {
		const className = classNames("overview-filter filter-select", this.props.className);

		return (
			<div className={className}>
				<label htmlFor="">{this.props.label}</label>
				<SingleSelectAutoComplete
					placeholder={this.props.placeholder}
					dataSource={this.props.dataSource}
					options={this.props.options}
					item={this.props.item}
					name={this.props.name}
					onChange={item => this.handleChange(context, { target: { ...item, ...{ name: this.props.name } } })}
					async={this.props.async}
					showItemsOnFirstClick={this.props.showItemsOnFirstClick}
					initialItem={this.props.initialItem}
					disabled={this.props.disabled}
					isClearable={this.props.isClearable}
					clearOnSelect={this.props.clearOnSelect}
					exclude={this.props.exclude}
				/>
			</div>
		);
	}
}

export default SingleSelectAutoCompleteFilter;
SingleSelectAutoCompleteFilter.propTypes = {
	placeholder: PropTypes.string,
	dataSource: PropTypes.string,
	options: PropTypes.array,
	item: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	async: PropTypes.bool,
	showItemsOnFirstClick: PropTypes.bool,
	initialItem: PropTypes.any,
	disabled: PropTypes.bool,
	isClearable: PropTypes.bool,
	clearOnSelect: PropTypes.bool,
	exclude: PropTypes.array,
	label: PropTypes.string,
};
