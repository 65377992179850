import { useState } from "react";
import SubmitBar from "../../../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../../js/react/components/general/AjaxForm";
import useInputHandler from "../../../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../../../js/react/components/general/form/LabeledTextInput";
import Nav from "../../../../../../../js/lib/Nav";
import { route } from "../../../../../../../js/helpers";
import Navigation from "../Navigation";

export default function ProvinceForm() {
	const [formData, setFormData] = useState({ ...window.data.province_values });
	const creating = window.data.province_values.id === -1;
	const inputHandler = useInputHandler(setFormData);

	const onSuccess = () => {
		Nav.go(route("crm.location-settings.provinces.index"));
	};

	return (
		<>
			<Navigation selectedTab="provinces" />

			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(creating ? "general.add_:item" : "general.edit_:item", {}, { item: "location.provinces.singular" })}
				</h1>

				<a className="link" href={route("crm.location-settings.provinces.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "location.provinces.plural" })}
				</a>
			</div>
			<AjaxForm
				method={creating ? "POST" : "PUT"}
				submitUrl={
					creating
						? route("crm.location-settings.provinces.store")
						: route("crm.location-settings.provinces.update", formData.id)
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "location.provinces.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "location.provinces.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="name"
										value={formData.name ? `${formData.name}` : ""}
										onChange={inputHandler}
										label="location.provinces.fields.name"
										required
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="location.provinces.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
