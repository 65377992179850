import PropTypes from "prop-types";
import { format } from "../../../../../../js/lib/Date";
import { uctrans } from "../../../../../../js/lib/Translator";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import CompanyPermits from "../../company/permits/CompanyPermits";
import CollapsibleContent from "../../general/CollapsibleContent";

export default function AFMPermits(props) {
	const { afmNumber, afmPermits, missingPermits, requiredPermits, afmShowChangeLog, isOpen } = props;

	return (
		<CollapsibleContent title="AFM vergunningen (statutair)" initialCollapseState={!isOpen}>
			<label>AFM-nummer</label>
			<span>{afmNumber}</span>
			<br />
			<br />
			<CompanyPermits permitData={afmPermits} missingPermits={missingPermits} requiredPermits={requiredPermits} />

			{afmShowChangeLog && (
				<CollapsibleContent title={uctrans("afm_permits.fields.afm_permit_changelog")}>
					{props.afmChangeLogs && props.afmChangeLogs.length ? (
						props.afmChangeLogs.map((changeLog, index) => (
							<div key={index} className="flex w-full justify-start text-xs">
								<span className="font-bold mr-5 w-1/5">{format(changeLog.datetime, "dd-MM-y")}</span>
								<div className="flex w-full justify-start">
									{Number(changeLog.type) === Number(props.afmChangeLogTypes.NEW.key) ? (
										<EvaIcon type="plus-circle-outline" fill="#009286" height="18" width="18" />
									) : (
										<EvaIcon type="minus-circle-outline" fill="#f9ACAA" height="18" width="18" />
									)}
									<span className="ml-5">{`${changeLog.afm_product_label} - ${changeLog.afm_service_label} ${
										changeLog.different_date_from_afm ? ` (${format(changeLog.date_afm, "dd-MM-y")})` : ""
									}`}</span>
								</div>
							</div>
						))
					) : (
						<span>geen changelog items om te tonen</span>
					)}
				</CollapsibleContent>
			)}
		</CollapsibleContent>
	);
}

AFMPermits.propTypes = {
	afmNumber: PropTypes.string,
	afmPermits: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	missingPermits: PropTypes.array,
	requiredPermits: PropTypes.array,
	afmShowChangeLog: PropTypes.bool,
	afmChangeLogs: PropTypes.array,
	afmChangeLogTypes: PropTypes.object,
	isOpen: PropTypes.bool,
};

AFMPermits.defaultProps = {
	afmShowChangeLog: true,
	isOpen: false,
};
