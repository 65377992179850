import Tippy from "@tippyjs/react";
import { can, route } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import TextFilter from "../../../../../js/react/components/general/overview/TextFilter";
import Nav from "../../../../../js/lib/Nav";

export default function ClientConsentOverview() {
	const overviewData = { ...window.data.overview };

	const handleExport = overviewParameters => {
		Nav.go(route(`crm.client-consents.export`, overviewParameters));
	};

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("client_consents.plural")}</h1>
				{can("client_consent.client_consent", "create") && (
					<div>
						<IconButton
							href={route("crm.client-consents.create")}
							content={uctrans("general.add_:item", {}, { item: "client_consents.singular" })}
							primary
						/>
					</div>
				)}
			</div>
			<AbnOverview
				initialData={overviewData}
				indexUrl={route("crm.client-consents.index")}
				deleteRouteName="crm.client-consents.delete"
				modelTranslations={{
					plural: trans("client_consents.plural"),
					singular: trans("client_consents.singular"),
				}}
				renderFilters={overview => (
					<Filters>
						<TextFilter
							hideIcon
							className="form-1-2"
							name="search"
							label={uctrans("general.search")}
							placeholder={uctrans("client_consents.search_placeholder")}
						/>
						<TextFilter
							hideIcon
							className="form-1-2"
							name="company_name"
							label={uctrans("intermediaries.singular")}
							placeholder={uctrans("general.search_:item", {}, { item: "intermediaries.singular" })}
						/>
						<SelectFilter
							className="form-1-2"
							name="view_status"
							label={uctrans("client_consents.fields.view_status")}
							options={overview.data.metadata.filterdata.view_status_options}
						/>
						<SelectFilter
							className="form-1-2"
							name="sample_status"
							label={uctrans("client_consents.sample")}
							options={overview.data.metadata.filterdata.sample_status_options}
						/>
						<SelectFilter
							className="form-1-2"
							name="team_id"
							label={uctrans("intermediaries.fields.team")}
							options={overview.data.metadata.filterdata.teams}
							collapsible
						/>
						<SelectFilter
							className="form-1-2"
							name="relationmanager_id"
							label={uctrans("intermediaries.fields.relationmanager")}
							options={overview.data.metadata.filterdata.relationmanagers}
							collapsible
						/>
						<SelectFilter
							className="form-1-2"
							name="segment_id"
							label={uctrans("intermediaries.fields.segment")}
							options={overview.data.metadata.filterdata.segments}
							collapsible
						/>
						<SelectFilter
							className="form-1-2"
							name="organisation_id"
							label={uctrans("intermediaries.fields.organisation")}
							options={overview.data.metadata.filterdata.organisations}
							collapsible
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={6}
							sortable="company_label"
							renderHeaderContent={() => uctrans("client_consents.fields.company")}
							renderCellContent={clientConsent => (clientConsent.company_label ? clientConsent.company_label : "-")}
						/>
						<DataColumn
							width={6}
							sortable="name_first_applicant"
							renderHeaderContent={() => uctrans("client_consents.fields.client_name")}
							renderCellContent={clientConsent =>
								clientConsent.name_second_applicant
									? `${clientConsent.name_first_applicant} & ${clientConsent.name_second_applicant}`
									: clientConsent.name_first_applicant
							}
						/>
						<DataColumn
							width={3}
							sortable="loan_number"
							renderHeaderContent={() => uctrans("client_consents.loan_number_short")}
							renderCellContent={clientConsent => (clientConsent.loan_number ? clientConsent.loan_number : "-")}
						/>

						<DataColumn
							width={3}
							sortable="submitted_at"
							renderHeaderContent={() => uctrans("client_consents.first_log_of_cycle_datetime")}
							renderCellContent={clientConsent =>
								clientConsent.submitted_at ? format(clientConsent.submitted_at, "dd-MM-y") : "-"
							}
						/>
						<DataColumn
							width={5}
							renderHeaderContent={() => uctrans("client_consents.first_log_of_cycle_actor")}
							renderCellContent={clientConsent =>
								clientConsent.first_log_of_cycle ? clientConsent.first_log_of_cycle.actor : "-"
							}
						/>
						<DataColumn
							width={3}
							sortable="starts_at"
							renderHeaderContent={() => uctrans("client_consents.fields.starts_at")}
							renderCellContent={clientConsent =>
								clientConsent.starts_at ? format(clientConsent.starts_at, "dd-MM-y") : "-"
							}
						/>
						<DataColumn
							width={3}
							sortable="expires_at"
							renderHeaderContent={() => uctrans("client_consents.fields.expires_at")}
							renderCellContent={clientConsent =>
								clientConsent.expires_at ? format(clientConsent.expires_at, "dd-MM-y") : "-"
							}
						/>
						<DataColumn
							width={5}
							sortable="view_status"
							renderHeaderContent={() => uctrans("client_consents.fields.view_status")}
							renderCellContent={clientConsent => clientConsent.view_status_label}
						/>
						<DataColumn
							width={1}
							sortable="sample_status"
							renderHeaderContent={() => uctrans("client_consents.sample")}
							renderCellContent={(clientConsent, overview) => (
								<div className="flex justify-between">
									<div>{clientConsent.sample_status_label}</div>
									<div className="overview-actions">
										{can("client_consent.client_consent", "update") ? (
											<a className="link" href={route("crm.client-consents.edit", clientConsent.id)}>
												<EvaIcon type="edit-outline" width="18" height="18" fill="#009286" />
											</a>
										) : can("client_consent.client_consent", "view") ? (
											<a className="link" href={route("crm.client-consents.show", clientConsent.id)}>
												<EvaIcon type="eye-outline" width="18" height="18" fill="#009286" />
											</a>
										) : (
											""
										)}
										{can("client_consent.client_consent", "delete") && (
											<a
												onClick={() => {
													overview.askDelete(clientConsent);
												}}>
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("general.delete")}>
													<span>
														<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
													</span>
												</Tippy>
											</a>
										)}
									</div>
								</div>
							)}
						/>
					</DataTable>
				)}
				renderFooter={overview => (
					<a className="button button-primary mr-1" onClick={() => handleExport(overview.getParameters())}>
						{uctrans("client_consents.export")}
					</a>
				)}
			/>
		</>
	);
}
