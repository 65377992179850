import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import { can, route } from "../../../../../js/helpers";
import QuestionnaireQuestionForm from "../questionnaires/QuestionnaireQuestionForm";
import Navigation from "./Navigation";

export default function ClientTreatmentQuestionnaireForm(props) {
	const [staticData, setStaticData] = useState(
		typeof props.ClietTreatmentStaticData === "undefined"
			? cloneDeep(window.data.questionnaire_static_data)
			: props.ClietTreatmentStaticData,
	);

	useEffect(() => {
		if (typeof props.ClietTreatmentStaticData !== "undefined") {
			setStaticData(props.ClietTreatmentStaticData);
		}
	}, [props.ClietTreatmentStaticData]);

	return (
		<>
			<Navigation
				selectedTab="client-treatment-evaluations"
				clientTreatment={staticData.client_treatment}
				clientTreatmentTypes={staticData.client_treatment_types}
				loggedInInviter={
					window.data.user
						? staticData.client_treatment.inviters.find(
								inviter => Number(inviter.user_id) === Number(window.data.user.id),
						  )
						: null
				}
				canUpdate={can("client_treatment.client_treatment", "update")}
			/>

			<div className="page-title-container">
				<h1 className="page-title">{`${uctrans("client_treatment.singular")} ${staticData.client_treatment.name}`}</h1>

				{can("client_treatment.client_treatment", "update") && (
					<div>
						<a
							className="link"
							href={route("crm.client-treatments.evaluations", {
								client_treatment: staticData.client_treatment.id,
							})}>
							{uctrans("general.back_to_overview_:page", {}, { page: "client_treatment.evaluations.plural" })}
						</a>
					</div>
				)}
			</div>

			<QuestionnaireQuestionForm />
		</>
	);
}
ClientTreatmentQuestionnaireForm.propTypes = {
	ClietTreatmentStaticData: PropTypes.object,
};
