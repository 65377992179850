import { useState } from "react";
import LabeledTextarea from "../../../../../js/react/components/general/form/LabeledTextarea";
import Select from "../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import Nav from "../../../../../js/lib/Nav";
import { route } from "../../../../../js/helpers";
import useSelectHandler from "../../../../../js/react/hooks/useSelectHandler";

export default function IdentificationVerificationForm() {
	const [formData, setFormData] = useState({ ...window.data.identification_verification_values });
	const creating = window.data.identification_verification_values.id === -1;
	const inputHandler = useInputHandler(setFormData);
	const selectHandler = useSelectHandler(setFormData);

	const onSuccess = () => {
		Nav.go(route("crm.identification-verification.index"));
	};

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("identificationverification.singular")}</h1>

				<a className="link" href={route("crm.identification-verification.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "identificationverification.plural" })}
				</a>
			</div>
			<AjaxForm
				method={creating ? "POST" : "PUT"}
				submitUrl={
					creating
						? route("crm.identification-verification.store")
						: route("crm.identification-verification.update", formData.id)
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "identificationverification.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "identificationverification.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="name"
										value={formData.name}
										onChange={inputHandler}
										label="identificationverification.fields.name"
										required
									/>
								</div>
							</div>
							<div className="form-full">
								<div className="form-1-2">
									<LabeledTextarea
										name="questionnaire_explanation"
										value={formData.questionnaire_explanation}
										onChange={inputHandler}
										label="identificationverification.fields.text"
										required
									/>
								</div>
							</div>
							<div className="form-1-3">
								<label htmlFor="color_code">
									{uctrans("identificationverification.fields.color_code")}
									<span className="text-orange">*</span>
								</label>
								<Select
									placeholder={uctrans(
										"general.select_:item",
										{},
										{ item: "identificationverification.fields.color_code" },
									)}
									value={window.data.color_codes.filter(({ value }) => Number(value) === Number(formData.color_code))}
									options={window.data.color_codes}
									name="color_code"
									onChange={(selectedValue, metaData) => selectHandler(selectedValue, metaData)}
									isClearable={false}
									required
								/>
							</div>
							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="identificationverification.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
