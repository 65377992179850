import find from "lodash/find";
import forEach from "lodash/forEach";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { CmsTypesContext } from "../../cms-types-context";
import Attribute from "../attributes/Attribute";
import Html from "../attributes/Html";
import Text from "../attributes/Text";
import Select from "../attributes/Select";
import Part from "./Part";

export default function SubmenuPromo(props) {
	const [values, setValues] = useState(props.values ? props.values : {});
	useEffect(() => {
		setValues(props.values ? props.values : "");
	}, [props.values]);
	const handleValuesChange = newValues => {
		setValues(newValues);
		props.onChange(newValues);
	};
	const handleValueChange = (name, newValue) => {
		const newValues = { ...values };
		newValues[name] = newValue;
		handleValuesChange(newValues);
	};
	const { partTypes } = useContext(CmsTypesContext);
	const partType = find(partTypes, partType => partType.key === "submenupromo");

	if (partType) {
		const typeOptions = [];
		forEach(partType.attributes.type.options, option => {
			if (props.showParentOption || option.value !== "parent") {
				typeOptions.push(option);
			}
		});

		return (
			<>
				{props.label && <h5>{props.label}</h5>}
				<div className="pl-6 mt-3  border-l border-grey-light">
					<div className="form-container">
						<span className="form-1-3">
							<Select
								label={partType.attributes.type.label}
								value={values.type}
								options={typeOptions}
								onChange={value => {
									handleValueChange("type", value);
								}}
							/>
						</span>
					</div>
					{values.type === "own" && (
						<>
							<div className="form-container">
								<span className="form-1-2">
									<Text
										label={partType.attributes.title.label}
										value={values.title}
										onChange={value => {
											handleValueChange("title", value);
										}}
									/>
									<Html
										label={partType.attributes.html.label}
										value={values.html}
										onChange={value => {
											handleValueChange("html", value);
										}}
									/>
								</span>
								<span className="form-1-2">
									<Attribute
										attribute={partType.attributes.button}
										values={values.button ? values.button : {}}
										onChange={value => {
											handleValueChange("button", value);
										}}
									/>
								</span>
							</div>
							<div className="form-container">
								<span className="form-full">
									<Part
										partTypes={partTypes}
										parttype="image"
										attribute={partType.attributes.image}
										values={values.image ? values.image : {}}
										onChange={value => {
											handleValueChange("image", value);
										}}
									/>
								</span>
							</div>
						</>
					)}
				</div>
			</>
		);
	}
}

SubmenuPromo.propTypes = {
	label: PropTypes.string,
	values: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func.isRequired,
	showParentOption: PropTypes.bool,
};
SubmenuPromo.defaultProps = {
	showParentOption: false,
};
