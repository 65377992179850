import find from "lodash/find";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import { CmsTypesContext } from "../../cms-types-context";
import PartContent from "./PartContent";

export default function ColumnsBlock(props) {
	const [values, setValues] = useState(props.values ? props.values : {});
	useEffect(() => {
		setValues(props.values ? props.values : "");
	}, [props.values]);
	const handleValuesChange = newValues => {
		setValues(newValues);
		props.onChange(newValues);
	};
	const handleValueChange = (name, newValue) => {
		const newValues = { ...values };
		newValues[name] = newValue;
		handleValuesChange(newValues);
	};

	const swapContents = (name1, name2) => {
		const newValues = { ...values };
		newValues[name1] = values[name2];
		newValues[name2] = values[name1];
		handleValuesChange(newValues);
	};

	const { partTypes } = useContext(CmsTypesContext);
	const partType =
		props.parttype === "two_columns_block" || props.parttype === "three_columns_block"
			? find(partTypes, partType => partType.key === props.parttype)
			: null;

	if (partType) {
		return (
			<div className="mt-5">
				<h5>{props.label}</h5>
				<PartContent
					label={partType.attributes.column_one.label}
					parttype="column"
					values={values.column_one}
					onChange={value => {
						handleValueChange("column_one", value);
					}}
				/>
				<div className="swap-columns">
					<button
						style={{ transform: "rotate(90deg)" }}
						onClick={() => {
							swapContents("column_one", "column_two");
						}}>
						<EvaIcon type="swap-outline" height="16" width="16" fill="#9c9c9c" />
					</button>
				</div>
				<PartContent
					label={partType.attributes.column_two.label}
					parttype="column"
					values={values.column_two}
					onChange={value => {
						handleValueChange("column_two", value);
					}}
				/>
				{partType.key === "three_columns_block" && (
					<>
						<div className="swap-columns">
							<button
								style={{ transform: "rotate(90deg)" }}
								onClick={() => {
									swapContents("column_two", "column_three");
								}}>
								<EvaIcon type="swap-outline" height="16" width="16" fill="#9c9c9c" />
							</button>
						</div>
						<PartContent
							label={partType.attributes.column_three.label}
							parttype="column"
							values={values.column_three}
							onChange={value => {
								handleValueChange("column_three", value);
							}}
						/>
					</>
				)}
			</div>
		);
	}
}

ColumnsBlock.propTypes = {
	parttype: PropTypes.string.isRequired,
	label: PropTypes.string,
	values: PropTypes.shape({
		column_one: PropTypes.object,
		column_two: PropTypes.object,
		column_three: PropTypes.object,
	}),
	onChange: PropTypes.func.isRequired,
};
