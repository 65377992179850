import { useState } from "react";
import PropTypes from "prop-types";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import { can, route } from "../../../../../../js/helpers";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import Translate from "../../../../../../js/react/components/general/Translate";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import DiscountExceptionForm from "./DiscountExceptionForm";
import Dialog from "../../../../../../js/react/components/general/Dialog";

export default function DiscountExceptionOverview({ interestType }) {
	const [currentDiscountException, setCurrentDiscountException] = useState(null);
	const [overviewDataUpdatedAt, setOverviewDataUpdatedAt] = useState(new Date().getTime());

	const addDiscountException = () => {
		setCurrentDiscountException({
			id: -1,
			name: "",
			description: "",
			interest_type_id: interestType.id,
		});
	};

	const handleSuccess = () => {
		setOverviewDataUpdatedAt(new Date().getTime());
		setCurrentDiscountException(null);
	};

	return (
		<>
			<br />
			<hr />
			<br />
			<h3>
				<Translate content="interest.discount_exceptions.plural" />
			</h3>

			<AbnOverview
				indexUrl={route("crm.interest.discount-exceptions.index", interestType.id)}
				deleteRouteName="crm.interest.discount-exceptions.delete"
				moveRouteName="crm.interest.discount-exceptions.move"
				useDragAndDrop
				modelTranslations={{
					plural: trans("interest.discount_exceptions.plural"),
					singular: trans("interest.discount_exceptions.singular"),
				}}
				dataUpdatedAt={overviewDataUpdatedAt}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={1}
							className="draggable-overview-item-handle"
							renderHeaderContent={() => ""}
							renderCellContent={() => ""}
						/>
						<DataColumn
							width={10}
							sortable="name"
							renderHeaderContent={() => uctrans("interest.fields.name")}
							renderCellContent={discountException => (
								<a onClick={() => setCurrentDiscountException(discountException)} className="row-title link">
									{discountException.discounts.map((discount, index) =>
										discountException.discounts.length !== index + 1 ? `${discount.name}, ` : discount.name,
									)}
								</a>
							)}
						/>
						{can("interest.discount_exception", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(discount, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(discount)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
			<IconButton
				onClick={addDiscountException}
				content={uctrans("general.add_:item", {}, { item: "interest.discount_exceptions.singular" })}
				primary
			/>
			{currentDiscountException !== null && (
				<Dialog
					isOpen={currentDiscountException !== null}
					title={
						currentDiscountException.id === -1
							? uctrans("general.add_:item", {}, { item: "interest.discount_exceptions.singular" })
							: uctrans("general.edit_:item", {}, { item: "interest.discount_exceptions.singular" })
					}
					isFullScreen
					onClose={() => setCurrentDiscountException(null)}>
					<DiscountExceptionForm
						discountException={currentDiscountException}
						onSuccess={() => handleSuccess()}
						onCancel={() => setCurrentDiscountException(null)}
					/>
				</Dialog>
			)}
		</>
	);
}

DiscountExceptionOverview.propTypes = {
	interestType: PropTypes.object.isRequired,
};
