import PropTypes from "prop-types";
import { useContext } from "react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import CompanyDossierContext from "../CompanyDossierContext";
import DossierFileInput from "../fileInput/DossierFileInput";
import DossierPartOrganisationIsCentral from "./DossierPartOrganisationIsCentral";

export default function RDCviaSSODossierPart({ partData, completionData }) {
	const { handleFormChange, readOnly } = useContext(CompanyDossierContext);

	return (
		<div className="w-full">
			<div className="dossier-row">
				<label />
				{readOnly ? (
					<span className="button button-secondary inline-block relative disabled">
						<EvaIcon type="activity-outline" fill="#ffffff" height="20" width="20" />
						&nbsp;
						{uctrans("dossiers.fields.rdc_via_sso")}
					</span>
				) : (
					<a
						className="button button-secondary inline-block relative"
						href="https://security-ifs.connect.abnamro.com/idp/startSSO.ping?PartnerSpId=https://www.okta.com/saml2/service-provider/spnwaqgfmmrxeioavotr&TargetResource=https://login.moodysanalytics.com/home/bookmark/0oa6kvdkykWL72rLc4x7/2557"
						rel="noopener noreferrer"
						target="_blank">
						<EvaIcon type="activity-outline" fill="#ffffff" height="20" width="20" />
						&nbsp;
						{uctrans("dossiers.fields.rdc_via_sso")}
					</a>
				)}
			</div>
			<DossierPartOrganisationIsCentral completionData={completionData} />
			<div className="dossier-row">
				<label htmlFor="type">{uctrans("dossiers.fields.files")}</label>
				<DossierFileInput
					existingFileDownloadRoute="crm.intermediaries.dossier.download-completion-file"
					initialFiles={completionData ? completionData.files.files : []}
					initialArchivedFiles={completionData ? completionData.files.archivedFiles : []}
					onChange={files => handleFormChange(partData.id, "files", files)}
					disabled={readOnly}
				/>
			</div>
		</div>
	);
}

RDCviaSSODossierPart.propTypes = {
	partData: PropTypes.object,
	completionData: PropTypes.object,
};
