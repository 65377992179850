import { useState } from "react";
import IconButton from "../../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../../js/react/components/general/overview/DataColumn";
import DeleteColumnContent from "../../../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route } from "../../../../../../../js/helpers";
import Navigation from "../Navigation";

export default function TakeonStatusOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<Navigation selectedTab="statuses" />

			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans("takeon_requests.singular")} {trans("takeon_requests.status.plural")}
				</h1>
				{can("agenda.settings.takeon_status", "create") && (
					<div>
						<IconButton
							href={route("crm.agenda-settings.takeon-statuses.create")}
							content={uctrans("general.add_:item", {}, { item: "takeon_requests.status.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.agenda-settings.takeon-statuses.index")}
				deleteRouteName="crm.agenda-settings.takeon-statuses.delete"
				moveRouteName="crm.agenda-settings.takeon-statuses.move"
				useDragAndDrop={can("agenda.settings.takeon_status", "move")}
				hideControls
				modelTranslations={{
					plural: trans("takeon_requests.status.plural"),
					singular: trans("takeon_requests.status.singular"),
				}}
				renderBody={() => (
					<DataTable>
						{can("agenda.settings.takeon_status", "move") && (
							<DataColumn
								width={1}
								className="draggable-overview-item-handle"
								renderHeaderContent={() => ""}
								renderCellContent={() => ""}
							/>
						)}
						<DataColumn
							width={10}
							sortable="name"
							renderHeaderContent={() => uctrans("takeon_requests.status.fields.name")}
							renderCellContent={status => {
								if (can("agenda.settings.takeon_status", "update")) {
									return (
										<a href={route("crm.agenda-settings.takeon-statuses.edit", status.id)} className="row-title link">
											{status.name}
										</a>
									);
								} else {
									return status.name;
								}
							}}
						/>
						<DataColumn
							width={5}
							sortable="status"
							renderHeaderContent={() => uctrans("takeon_requests.status.fields.default")}
							renderCellContent={status => (status.default ? uctrans("general.yes") : uctrans("general.no"))}
						/>
						<DataColumn
							width={5}
							sortable="is_final"
							renderHeaderContent={() => uctrans("takeon_requests.status.fields.is_final")}
							renderCellContent={status => (status.is_final ? uctrans("general.yes") : uctrans("general.no"))}
						/>
						{can("agenda.settings.takeon_status", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(status, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(status)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
