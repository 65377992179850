import PropTypes from "prop-types";
import { useState } from "react";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import { route } from "../../../../../js/helpers";

export default function RandomCheckForm({ randomCheck, onSuccess, onCancel }) {
	const [formData, setFormData] = useState({ ...randomCheck });

	const onNameChange = name => {
		setFormData({
			...formData,
			name,
		});
	};

	const onAmountChange = amount => {
		setFormData({
			...formData,
			amount,
		});
	};

	return (
		<AjaxForm
			method="PUT"
			submitUrl={route("crm.random-checks.update", formData.id)}
			loaderText={uctrans("general.:item_is_being_saved", {}, { item: "random_checks.singular" })}
			successText={uctrans("general.saved_:item", {}, { item: "random_checks.singular" })}
			onSuccess={onSuccess}
			data={{ ...formData }}>
			<div className="form-1-2 pb-0">
				<LabeledTextInput
					name="name"
					value={formData.name}
					onChange={event => onNameChange(event.target.value)}
					label="random_checks.fields.name"
					required
				/>
			</div>
			<div className="form-1-3 pb-0">
				<LabeledTextInput
					name="amount"
					value={formData.amount}
					onChange={event => onAmountChange(event.target.value)}
					label="random_checks.fields.amount"
					required
				/>
			</div>
			<div className="form-full">
				<SubmitBar item="random_checks.singular" addAnotherAvailable={false} onCancel={onCancel} />
			</div>
		</AjaxForm>
	);
}

RandomCheckForm.propTypes = {
	randomCheck: PropTypes.object.isRequired,
	onSuccess: PropTypes.func,
	onCancel: PropTypes.func,
};
