import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";
import { format } from "../../../../../../../js/lib/Date";
import { uctrans } from "../../../../../../../js/lib/Translator";

export default function DossierFileInputTrackData({ file, children }) {
	let content = file.created_at
		? `${uctrans("dossiers.fields.created_at")} ${format(file.created_at, "dd-MM-y HH:mm")}`
		: "";

	content += file.created_by !== "" ? ` - ${file.created_by}` : "";

	if (file.updated_at) {
		content += `<br />${uctrans("dossiers.fields.updated_at")} ${format(file.updated_at, "dd-MM-y HH:mm")}`;
		if (file.updated_by !== "") {
			content += ` - ${file.created_by}`;
		}
	}

	if (file.deleted_at) {
		content += `<br />${uctrans("dossiers.fields.deleted_at")} ${format(file.deleted_at, "dd-MM-y HH:mm")}`;
		if (file.deleted_by !== "") {
			content += ` - ${file.deleted_by}`;
		}
	}

	return (
		<Tippy placement="auto" maxWidth={400} arrow content={<span dangerouslySetInnerHTML={{ __html: content }} />}>
			{children}
		</Tippy>
	);
}

DossierFileInputTrackData.propTypes = {
	file: PropTypes.object,
	children: PropTypes.any.isRequired,
};
