import { uctrans } from "../../../../../js/lib/Translator";
import DiscussRequestOverviewContent from "../../../../../js/react/components/discussRequests/DiscussRequestOverviewContent";

export default function DiscussRequestOverview() {
	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("discuss_requests.plural")}</h1>
			</div>
			<DiscussRequestOverviewContent
				indexRoute="crm.discuss-requests.index"
				editRoute="crm.discuss-requests.edit"
				forCrm
			/>
		</>
	);
}
