import PropTypes from "prop-types";
import { format } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";

export default function AgendaItemContactmomentLink({ contactmoment }) {
	return contactmoment && contactmoment.edit_link ? (
		<div className="mb-3">
			{uctrans("agendaitems.from_contactmoment")}{" "}
			<a className="link" href={contactmoment.edit_link}>
				{contactmoment.datetime ? format(contactmoment.datetime, "dd-MM-y") : uctrans("general.no_date")}
			</a>
		</div>
	) : null;
}

AgendaItemContactmomentLink.propTypes = {
	contactmoment: PropTypes.object,
};
