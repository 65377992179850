import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import { useState, useEffect, useRef } from "react";
import { parse } from "../../../../../../js/lib/Date";
import Nav from "../../../../../../js/lib/Nav";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import CheckmarkGroup from "../../../../../../js/react/components/general/form/checkmark/CheckmarkGroup";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import useDateHandler from "../../../../../../js/react/hooks/useDateHandler";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";
import LineGraph from "../graph/LineGraph";
import ChannelResults from "./components/ChannelResults";
import LabelResults from "./components/LabelResults";
import LoanResults from "./components/LoanResults";
import LoanWeekResults from "./components/LoanWeekResults";
import { route } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import DatePicker from "../../../../../../js/react/components/general/DatePicker";
import Select from "../../../../../../js/react/components/general/Select";
import useSelectHandler from "../../../../../../js/react/hooks/useSelectHandler";

export default function CommercialReport() {
	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);
	const [staticData] = useState(window.data.staticData ? cloneDeep(window.data.staticData) : []);
	const [loading, setLoading] = useState(false);

	const formRef = useRef(null);
	const [result, setResult] = useState(null);
	const formInfo = window.data.formInfo ? cloneDeep(window.data.formInfo) : [];

	const selectHandler = useSelectHandler(setFormValues);
	const datePickerHandler = useDateHandler(setFormValues);
	const genericHandler = useGenericHandler(setFormValues);

	const reportTypes = Object.values(formInfo.commercialReportTypes);

	const periodTypeOptions =
		formValues.commercialReportType === "label_results" || formValues.commercialReportType === "channel_results"
			? formInfo.periodTypes.filter(({ value }) => value !== "date_range")
			: formInfo.periodTypes;

	const onSuccess = response => {
		if (response.data) {
			setResult(response.data);
		}
		setLoading(false);
	};

	const onError = error => {
		console.error(error);
		setLoading(false);
	};

	const getComponent = result => {
		switch (result.type) {
			case "loan_results":
				return <LoanResults data={result.calculated} title={result.title} staticData={staticData} />;
			case "loan_development":
				return <LineGraph series={result.calculated} title={result.title} chartOptions={formInfo.lineChartOptions} />;
			case "loan_weekreport":
				return <LoanWeekResults data={result.calculated.result} title={result.title} staticData={staticData} />;
			case "label_results":
				return (
					<LabelResults
						graphData={result.graph_data}
						data={result.calculated}
						title={result.title}
						formValues={formValues}
						staticData={staticData}
					/>
				);
			case "channel_results":
				return (
					<ChannelResults
						graphData={result.graph_data}
						data={result.calculated}
						title={result.title}
						formValues={formValues}
						staticData={staticData}
					/>
				);
			default:
				return null;
		}
	};

	const generateCsv = async () => {
		const result = await axios.post(route("crm.report.commercial.generate-csv"), {
			...formRef.current.props.data,
			csv: 1,
		});

		if (result && result.data.success) {
			Nav.go(
				route("crm.report.commercial.download-csv", {
					download_key: result.data.download_key,
				}),
			);
		}
	};

	async function fetchDefaultResult() {
		setLoading(true);
		const response = await axios.post(route(`crm.report.commercial.data`, { ...formValues }));
		if (response) {
			setResult(response.data);
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchDefaultResult();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<AjaxForm
				method="POST"
				submitUrl={route("crm.report.commercial.data")}
				onSubmit={() => setLoading(true)}
				onSuccess={onSuccess}
				onError={onError}
				data={{ ...formValues }}
				ref={formRef}
				useFlashMessage>
				<div className="page-title-container">
					<h1 className="page-title">
						{uctrans("report.report")} - {uctrans("report.report_types.commercial")}
					</h1>
				</div>
				<div className="flex w-full justify-between">
					<div className="w-1/3 pr-2">
						<label htmlFor="commercialReportType">{uctrans("report.type")}</label>
						<Select
							name="commercialReportType"
							value={reportTypes.filter(({ value }) => `${value}` === `${formValues.commercialReportType}`)}
							options={reportTypes}
							isClearable={false}
							onChange={selectHandler}
						/>
					</div>
					<div className="flex w-1/2 justify-end">
						<div className="w-1/3 mt-2">
							<Select
								name="totalResultYear"
								value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.totalResultYear}`)}
								options={formInfo.years}
								isClearable={false}
								onChange={selectHandler}
							/>
						</div>
						<div className="w-1/3 ml-2">
							<IconButton
								icon="file-text-outline"
								onClick={() => Nav.go(route("crm.report.commercial.pdf", formValues.totalResultYear))}
								content={uctrans("report.generate_total_report")}
								primary
							/>
						</div>
					</div>
				</div>
				{formValues.commercialReportType === "loan_results" ||
				formValues.commercialReportType === "label_results" ||
				formValues.commercialReportType === "channel_results" ||
				formValues.commercialReportType === "conversations" ? (
					<>
						{formValues.commercialReportType === "loan_results" ? (
							<div className="flex w-full">
								<div className="w-1/4 pr-2">
									<label htmlFor="groupType">{uctrans("report.group_type")}</label>
									<Select
										name="groupType"
										value={formInfo.groupTypes.filter(({ value }) => `${value}` === `${formValues.groupType}`)}
										options={formInfo.groupTypes}
										isClearable={false}
										onChange={selectHandler}
									/>
								</div>
							</div>
						) : null}
						<div className="flex w-full">
							<div className="w-1/4 pr-2">
								<label htmlFor="periodType">{uctrans("report.period_type")}</label>
								<Select
									name="periodType"
									value={periodTypeOptions.filter(({ value }) => `${value}` === `${formValues.periodType}`)}
									options={periodTypeOptions}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
							{formValues.periodType === "week" && (
								<div className="w-1/8 pr-2">
									<label htmlFor="week">{uctrans("report.week")}</label>
									<Select
										name="week"
										value={formInfo.weeks.filter(({ value }) => `${value}` === `${formValues.week}`)}
										options={formInfo.weeks}
										isClearable={false}
										onChange={selectHandler}
									/>
								</div>
							)}
							{formValues.periodType === "month" && (
								<div className="w-1/8 pr-2">
									<label htmlFor="month">{uctrans("report.month")}</label>
									<Select
										name="month"
										value={formInfo.months.filter(({ value }) => `${value}` === `${formValues.month}`)}
										options={formInfo.months}
										isClearable={false}
										onChange={selectHandler}
									/>
								</div>
							)}
							{(formValues.periodType === "year" ||
								formValues.periodType === "month" ||
								formValues.periodType === "week") && (
								<div className="w-1/8 pr-2">
									<label htmlFor="year">{uctrans("report.year")}</label>
									<Select
										name="year"
										value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.year}`)}
										options={formInfo.years}
										isClearable={false}
										onChange={selectHandler}
									/>
								</div>
							)}
							{formValues.periodType === "week_range" && (
								<>
									<div className="w-1/8 pr-2">
										<label htmlFor="fromWeek">{uctrans("report.from")}</label>
										<Select
											name="fromWeek"
											value={formInfo.weeks.filter(({ value }) => `${value}` === `${formValues.fromWeek}`)}
											options={formInfo.weeks}
											isClearable={false}
											onChange={selectHandler}
										/>
									</div>
									<div className="w-1/8 pr-2">
										<label htmlFor="fromWeekYear">&nbsp;</label>
										<Select
											name="fromWeekYear"
											value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.fromWeekYear}`)}
											options={formInfo.years}
											isClearable={false}
											onChange={selectHandler}
										/>
									</div>
									<div className="w-1/8 pr-2">
										<label htmlFor="toWeek">{uctrans("report.to")}</label>
										<Select
											name="toWeek"
											value={formInfo.weeks.filter(({ value }) => `${value}` === `${formValues.toWeek}`)}
											options={formInfo.weeks}
											isClearable={false}
											onChange={selectHandler}
										/>
									</div>
									<div className="w-1/8 pr-2">
										<label htmlFor="toWeekYear">&nbsp;</label>
										<Select
											name="toWeekYear"
											value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.toWeekYear}`)}
											options={formInfo.years}
											isClearable={false}
											onChange={selectHandler}
										/>
									</div>
								</>
							)}
							{formValues.periodType === "month_range" && (
								<>
									<div className="w-1/8 pr-2">
										<label htmlFor="fromMonth">{uctrans("report.from")}</label>
										<Select
											name="fromMonth"
											value={formInfo.months.filter(({ value }) => `${value}` === `${formValues.fromMonth}`)}
											options={formInfo.months}
											isClearable={false}
											onChange={selectHandler}
										/>
									</div>
									<div className="w-1/8 pr-2">
										<label htmlFor="fromMonthYear">&nbsp;</label>
										<Select
											name="fromMonthYear"
											value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.fromMonthYear}`)}
											options={formInfo.years}
											isClearable={false}
											onChange={selectHandler}
										/>
									</div>
									<div className="w-1/8 pr-2">
										<label htmlFor="toMonth">{uctrans("report.to")}</label>
										<Select
											name="toMonth"
											value={formInfo.months.filter(({ value }) => `${value}` === `${formValues.toMonth}`)}
											options={formInfo.months}
											isClearable={false}
											onChange={selectHandler}
										/>
									</div>
									<div className="w-1/8 pr-2">
										<label htmlFor="toMonthYear">&nbsp;</label>
										<Select
											name="toMonthYear"
											value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.toMonthYear}`)}
											options={formInfo.years}
											isClearable={false}
											onChange={selectHandler}
										/>
									</div>
								</>
							)}
							{formValues.periodType === "date_range" && (
								<>
									<div className="w-1/8 pr-2">
										<label htmlFor="fromDate">{uctrans("report.from")}</label>
										<DatePicker
											initialValue={formValues.fromDate ? parse(formValues.fromDate) : null}
											onChange={date => datePickerHandler(date, "fromDate")}
										/>
									</div>
									<div className="w-1/8 pr-2">
										<label htmlFor="toDate">{uctrans("report.to")}</label>
										<DatePicker
											initialValue={formValues.toDate ? parse(formValues.toDate) : null}
											onChange={date => datePickerHandler(date, "toDate")}
										/>
									</div>
								</>
							)}
						</div>
					</>
				) : null}
				{formValues.commercialReportType === "loan_development" && (
					<>
						<div className="flex w-full">
							<div className="w-1/4 pr-2">
								<label htmlFor="groupType">{uctrans("report.group_type")}</label>
								<Select
									name="developmentType"
									value={formInfo.loanDevelopmentTypes.filter(
										({ value }) => `${value}` === `${formValues.developmentType}`,
									)}
									options={formInfo.loanDevelopmentTypes}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
							{formValues.developmentType === "relationmanager" && (
								<div className="w-1/4 pr-2">
									<label htmlFor="relationmanager">{uctrans("users.relationmanagers.singular")}</label>
									<Select
										name="relationmanager"
										value={formInfo.relationmanagerOptions.filter(
											({ value }) => `${value}` === `${formValues.relationmanager}`,
										)}
										options={formInfo.relationmanagerOptions}
										isClearable={false}
										onChange={selectHandler}
									/>
								</div>
							)}
							{formValues.developmentType === "organisation" && (
								<div className="w-1/4 pr-2">
									<label htmlFor="organisation">{uctrans("organisations.singular")}</label>
									<Select
										name="organisation"
										value={formInfo.organisationOptions.filter(
											({ value }) => `${value}` === `${formValues.organisation}`,
										)}
										options={formInfo.organisationOptions}
										isClearable={false}
										onChange={selectHandler}
									/>
								</div>
							)}
							<div className="w-1/8 pr-2">
								<label htmlFor="year">{uctrans("report.year")}</label>
								<Select
									name="year"
									value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.year}`)}
									options={formInfo.years}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
						</div>
						<div className="flex w-full">
							<div className="w-1/6 pr-2">
								<label htmlFor="loanStatuses">{uctrans("report.loan_status_types")}</label>
								<CheckmarkGroup
									name="loan_statuses"
									initialChecked={formValues.loanStatuses}
									onChange={selectedStatuses => genericHandler(selectedStatuses, "loanStatuses")}>
									{map(formInfo.loanStatusOptions, (status, index) => (
										<Checkmark key={index} value={index} name={index} label={status} />
									))}
								</CheckmarkGroup>
							</div>
						</div>
					</>
				)}
				{formValues.commercialReportType === "loan_weekreport" ? (
					<>
						<div className="flex w-full mt-2">
							<div className="w-1/8 pr-2">
								<label htmlFor="year">{uctrans("report.year")}</label>
								<Select
									name="year"
									value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.year}`)}
									options={formInfo.years}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
							<div className="w-1/6 pr-2">
								<label htmlFor="relationmanager">{uctrans("users.relationmanagers.singular")}</label>
								<Select
									name="relationmanager"
									value={formInfo.relationmanagerOptions.filter(
										({ value }) => `${value}` === `${formValues.relationmanager}`,
									)}
									options={formInfo.relationmanagerOptions}
									isClearable
									onChange={selectHandler}
								/>
							</div>
							<div className="w-1/6 pr-2">
								<label htmlFor="organisation">{uctrans("organisations.singular")}</label>
								<Select
									name="organisation"
									value={formInfo.organisationOptions.filter(
										({ value }) => `${value}` === `${formValues.organisation}`,
									)}
									options={formInfo.organisationOptions}
									isClearable
									onChange={selectHandler}
								/>
							</div>
						</div>
						<div className="flex w-full mt-2">
							<div className="w-1/6 pr-2">
								<label htmlFor="segment">{uctrans("segments.singular")}</label>
								<Select
									name="segment"
									value={formInfo.segmentOptions.filter(({ value }) => `${value}` === `${formValues.segment}`)}
									options={formInfo.segmentOptions}
									isClearable
									onChange={selectHandler}
								/>
							</div>
							<div className="w-1/6 pr-2">
								<label htmlFor="team">{uctrans("teams.singular")}</label>
								<Select
									name="team"
									value={formInfo.teamOptions.filter(({ value }) => `${value}` === `${formValues.team}`)}
									options={formInfo.teamOptions}
									isClearable
									onChange={selectHandler}
								/>
							</div>
						</div>
					</>
				) : null}
				<div className="flex w-full justify-start">
					<button type="submit" disabled={loading} className="button button-primary leading-normal">
						{uctrans("report.show_report")}
					</button>
					{formValues.commercialReportType === "loan_weekreport" ? (
						<IconButton
							icon="file-text-outline"
							className="ml-3"
							onClick={() => generateCsv()}
							content={uctrans("report.generate_csv")}
							primary
						/>
					) : null}
				</div>
			</AjaxForm>

			<div className="mt-5">
				{!!loading && (
					<div className="flex justify-center">
						<Spinner width={50} />
					</div>
				)}
				{result && !loading ? getComponent(result) : null}
			</div>
		</>
	);
}
