import { useState } from "react";
import { can, route } from "../../../../../../js/helpers";
import { trans, uctrans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import Navigation from "../SettingsNavigation";

export default function FlowmailerWhitelistRecipientsOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<Navigation selectedTab="flowMailerWhitelistRecipients" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("flowmailer_whitelist_recipients.plural")}</h1>
				{can("communication.flowmailer_whitelist_recipient", "create") && (
					<div>
						<IconButton
							href={route("crm.communication-settings.flow-mailer-whitelist-recipients.create")}
							content={uctrans("general.add_:item", {}, { item: "flowmailer_whitelist_recipients.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.communication-settings.flow-mailer-whitelist-recipients.index")}
				deleteRouteName="crm.communication-settings.flow-mailer-whitelist-recipients.delete"
				hideControls
				modelTranslations={{
					plural: trans("flowmailer_whitelist_recipients.plural"),
					singular: trans("flowmailer_whitelist_recipients.singular"),
				}}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={4}
							sortable="email"
							renderHeaderContent={() => uctrans("flowmailer_whitelist_recipients.fields.email")}
							renderCellContent={recipient => {
								if (can("communication.flowmailer_whitelist_recipient", "update")) {
									return (
										<a
											href={route("crm.communication-settings.flow-mailer-whitelist-recipients.edit", recipient.id)}
											className="row-title link">
											{recipient.email}
										</a>
									);
								} else {
									return recipient.email;
								}
							}}
						/>
						<DataColumn
							width={4}
							sortable="name"
							renderHeaderContent={() => uctrans("flowmailer_whitelist_recipients.fields.name")}
							renderCellContent={recipient => recipient.name}
						/>
						<DataColumn
							width={2}
							sortable="default_fallback"
							renderHeaderContent={() => uctrans("flowmailer_whitelist_recipients.fields.default_fallback")}
							renderCellContent={recipient =>
								recipient.default_fallback ? uctrans("general.yes") : uctrans("general.no")
							}
						/>
						{can("communication.flowmailer_whitelist_recipient", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(recipient, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(recipient)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
