import { useState } from "react";
import Nav from "../../../../../js/lib/Nav";
import MultiSelectAutoComplete from "../../../../../js/react/components/general/autocomplete/MultiSelectAutoComplete";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Translate from "../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../js/react/hooks/useGenericHandler";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import { route } from "../../../../../js/helpers";
import useRadioHandler from "../../../../../js/react/hooks/useRadioHandler";
import TextEditor from "../forms/textEditor/TextEditor";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";

export default function CompanyAlertForm() {
	const [formData, setFormData] = useState({ ...window.data.formdata });
	const inputHandler = useInputHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);
	const radioHandler = useRadioHandler(setFormData);
	const { intermediary } = window.data;
	const creating = formData.id === -1;
	const readOnly = window.data.read_only;
	const roleOptions = window.data.roleOptions ? [...window.data.roleOptions] : [];

	const getRoute = (action = null) =>
		intermediary
			? route(`crm.intermediaries.company-alerts.${action ? action : "index"}`, window.data.company_id)
			: route(`crm.organisations.company-alerts.${action ? action : "index"}`, window.data.company_id);

	return (
		<>
			{window.data.nav_data && (
				<>
					<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
					<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
				</>
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.company_name
						? uctrans(
								creating ? "general.new :item1 by :item2" : "general.:item1 from :item2",
								{
									item2: window.data.company_name,
								},
								{ item1: "company_alerts.singular" },
						  )
						: uctrans(creating ? "general.add_:item" : "general.edit_:item", {
								item: uctrans("company_alerts.singular"),
						  })}
				</h1>

				<a className="link" href={getRoute()}>
					{uctrans("general.back_to_overview_:page", {}, { page: "company_alerts.plural" })}
				</a>
			</div>
			{!readOnly && (
				<AjaxForm
					method={creating ? "POST" : "PUT"}
					submitUrl={
						creating
							? getRoute("store")
							: intermediary
							  ? route(`crm.intermediaries.company-alerts.update`, {
										company_alert: formData.id,
										intermediary: window.data.company_id,
							    })
							  : route(`crm.organisations.company-alerts.update`, {
										company_alert: formData.id,
										organisation: window.data.company_id,
							    })
					}
					loaderText={uctrans("general.:item_is_being_saved", {}, { item: "company_alerts.singular" })}
					successText={uctrans("general.saved_:item", {}, { item: "company_alerts.singular" })}
					onSuccess={() => Nav.go(getRoute())}
					data={{ ...formData }}
					useFlashMessage={creating}>
					<div className="min-h-screen md:flex">
						<div className="flex-1 w-1/3 mr-12">
							<div className="form-container">
								<div className="form-1-2">
									<LabeledTextInput
										name="title"
										label="company_alerts.fields.title"
										value={formData.title}
										onChange={inputHandler}
										required
									/>
								</div>

								<div className="form-full">
									<label>
										{uctrans("company_alerts.fields.content")} <span className="text-orange">*</span>
									</label>
									<TextEditor value={formData.content} onChange={value => genericHandler(value, "content")} />
								</div>

								<div className="form-full">
									<label htmlFor="active">
										<Translate content="company_alerts.fields.active" />
									</label>
									<RadioGroup
										name="active"
										value={formData.active}
										onChange={value => radioHandler(value, "active")}
										horizontal>
										<Radio label={uctrans("general.yes")} value={1} />
										<Radio label={uctrans("general.no")} value={0} />
									</RadioGroup>
								</div>

								<div className="form-full">
									<label htmlFor="use_role_filter">
										<Translate content="company_alerts.roles" />
									</label>
									<RadioGroup
										name="use_role_filter"
										value={formData.use_role_filter}
										onChange={value => radioHandler(value, "use_role_filter")}
										horizontal>
										<Radio label={uctrans("company_alerts.visible_fo_all_roles")} value={0} />
										<Radio label={uctrans("company_alerts.visible_fo_selected_roles")} value={1} />
									</RadioGroup>
								</div>

								{!!formData.use_role_filter && (
									<div className="form-1-3">
										<MultiSelectAutoComplete
											options={roleOptions}
											name="selected_teams"
											items={formData.selected_roles}
											onChange={selectedRoles => genericHandler(selectedRoles, "selected_roles")}
											placeholder={uctrans("general.type_to_add_:item", {}, { item: "roles.singular" })}
											showItemsOnFirstClick
										/>
									</div>
								)}

								<div className="form-full">
									<div className="row">
										<div className="w-full md:w-1/2 lg:w-1/3 column">
											<SubmitBar translateLabel item="company_alerts.singular" addAnotherAvailable={false} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</AjaxForm>
			)}
			{readOnly && (
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-1/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<label>{uctrans("company_alerts.fields.title")}</label>
								<p>{formData.title}</p>
							</div>
							<div className="form-full">
								<label>{uctrans("company_alerts.fields.content")}</label>
								<div
									className="company_alert_content w-full border-2 p-4"
									dangerouslySetInnerHTML={{ __html: formData.content }}
								/>
							</div>
							<div className="form-full">
								<label>{uctrans("company_alerts.fields.active")}</label>
								<p>{formData.active ? uctrans("general.yes") : uctrans("general.no")}</p>
							</div>

							<div className="form-full">
								<label>{uctrans("company_alerts.roles")}</label>
								<p>
									{formData.use_role_filter
										? uctrans("company_alerts.visible_fo_selected_roles")
										: uctrans("company_alerts.visible_fo_all_roles")}
								</p>
							</div>

							{formData.use_role_filter && (
								<div className="form-full">
									<ul className="list-disc pl-4">
										{!!roleOptions &&
											!!formData.selected_roles &&
											roleOptions
												.filter(
													item => formData.selected_roles.map(e => String(e.value)).indexOf(String(item.value)) !== -1,
												)
												.map(item => <li key={item.value}>{item.label}</li>)}
									</ul>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
}
