import { renderComponent } from "../../../../js/componentRenderer";
import CooperationTerminationReasonOverview from "../components/agenda/settings/cooperationTerminationReasons/CooperationTerminationReasonOverview";
import AnnuallyCooperationCheckOverview from "../components/agenda/settings/formulaMonitor/AnnuallyCooperationCheckOverview";
import CooperationRequestOverview from "../components/agenda/settings/formulaMonitor/CooperationRequestOverview";
import ApiUserOverview from "../components/apiUsers/ApiUserOverview";
import AppOnboardingPageOverview from "../components/app/onboardingpage/AppOnboardingPageOverview";
import ClientConsentOverview from "../components/clientConsents/ClientConsentOverview";
import ClientConsentWithLeadsOverview from "../components/clientConsents/ClientConsentWithLeadsOverview";
import ClientLeadOverview from "../components/clientConsents/ClientLeadOverview";
import DefinitionOverview from "../components/cms/definitions/DefinitionOverview";
import FilterProfileOverview from "../components/cms/filter_profiles/FilterProfileOverview";
import CmsPageOverview from "../components/cms/pages/PageOverview";
import CmsFixedContentOverview from "../components/cms/fixed_content/FixedContentOverview";
import CmsMenuitemOverview from "../components/cms/menuitems/MenuitemOverview";
import CmsNewsitemOverview from "../components/cms/newsitems/NewsitemOverview";
import MailingListRecipientOverview from "../components/communication/mailingListRecipients/MailingListRecipientOverview";
import NewsletterCommunicationEventOverview from "../components/communication/newsletters/NewsletterCommunicationEventOverview";
import NewsletterCommunicationLogOverview from "../components/communication/newsletters/NewsletterCommunicationLogOverview";
import CommunicationNewsletterOverview from "../components/communication/newsletters/NewsletterOverview";
import CommunicationNewsletterMailingOverview from "../components/communication/newsletters/NewsletterMailingOverview";
import CommunicationNewsletterSubscriptionCategoryOverview from "../components/communication/newsletterSubscriptionCategories/NewsletterSubscriptionCategoryOverview";
import CommunicationNewsletterSubscriptionTypeOverview from "../components/communication/newsletterSubscriptionTypes/NewsletterSubscriptionTypeOverview";
import CompanyAlertOverview from "../components/companyAlerts/companyAlertOverview";
import ContactmomentTypeOverview from "../components/contactmoments/ContactmomentTypeOverview";
import ContactmomentCategoryOverview from "../components/contactmoments/ContactmomentCategoryOverview";
import ContactpersonOverview from "../components/contactpersons/ContactpersonOverview";
import ExistingContactpersonOverview from "../components/contactpersons/ExistingContactpersonOverview";
import InterestcategoryOverview from "../components/contactpersons/InterestCategoryOverview";
import InteresttypeOverview from "../components/contactpersons/InterestTypeOverview";
import DataimportOverview from "../components/dataimport/DataimportOverview";
import DosierMutationOverview from "../components/dossierMutations/DossierMutationOverview";
import SbiCodeOverview from "../components/kvk/SbiCodeOverview";
import LoanOverview from "../components/loans/LoanOverview";
import LocationTownshipOverview from "../components/location/settings/townships/TownshipOverview";
import LocationProvinceOverview from "../components/location/settings/provinces/ProvinceOverview";
import LocationZipcodeAreaOverview from "../components/location/settings/zipcodeAreas/ZipcodeAreaOverview";
import ThemeOverview from "../components/marketing/themes/ThemeOverview";
import MediaLibrary from "../components/media/library/MediaLibrary";
import NotificationOverview from "../components/notifications/NotificationOverview";
import OccupationOverview from "../components/occupations/OccupationOverview";
import ReplaceTagOverview from "../components/replaceTags/ReplaceTagOverview";
import StatutoryCompanyOverview from "../components/statutoryCompanies/StatutoryCompanyOverview";
import CompanyTargetOverview from "../components/targets/CompanyTargetOverview";
import RelationmanagerTargetOverview from "../components/targets/RelationmanagerTargetOverview";
import TargetYearOverview from "../components/targets/TargetYearOverview";
import AahgUserOverview from "../components/teams/aahgUsers/AahgUserOverview";
import ProcessTimeActivityOverview from "../components/teams/processTimeActivities/ProcessTimeActivityOverview";
import TeamGroupOverview from "../components/teams/teamGroups/TeamGroupOverview";
import TeamOverview from "../components/teams/TeamOverview";
import DepartmentOverview from "../components/departments/DepartmentOverview";
import OrganisationOverview from "../components/organisations/OrganisationOverview";
import QuestionnairesOverview from "../components/questionnaires/QuestionnairesOverview";
import RoleOverview from "../components/roles/RoleOverview";
import SegmentOverview from "../components/segments/SegmentOverview";
import UserOverview from "../components/users/UserOverview";
import InterestInterestTypeOverview from "../components/interest/interesttypes/InterestInterestTypeOverview";
import InterestPeriodOverview from "../components/interest/interestPeriods/InterestPeriodOverview";
import AccountSupportAgendaOverview from "../components/accountsupport/AccountSupportAgendaOverview";
import TakeonRequestStatusOverview from "../components/agenda/settings/takeonStatuses/TakeonStatusOverview";
import DiscussRequestCategoryOverview from "../components/discussRequests/categories/CategoryOverview";
import DiscussRequestAdviceOverview from "../components/discussRequests/advices/AdviceOverview";
import DiscussRequestOverview from "../components/discussRequests/DiscussRequestOverview";
import DiscountOverviewTab from "../components/interest/discounts/DiscountOverviewTab";
import DossierCheckActionsOverview from "../components/agenda/settings/dossierCheckActions/DossierCheckActionOverview";
import ContactmomentOverview from "../components/contactmoments/ContactmomentOverview";
import DossierPartOverview from "../components/dossier/dossierParts/DossierPartOverview";
import BadPressChecksTermsOverview from "../components/dossier/badPressCheckTerms/BadPressCheckTermOverview";
import KifidCheckActionsOverview from "../components/agenda/settings/kifidCheckActions/KifidCheckActionOverview";
import AfmPermitOverview from "../components/agenda/settings/afmPermits/AfmPermitOverview";
import AfmCheckActionOverview from "../components/agenda/settings/afmCheckActions/AfmCheckActionOverview";
import ClientTreatmentOverview from "../components/clienttreatment/ClientTreatmentOverview";
import ClientTreatmentInvitersOverview from "../components/clienttreatment/ClientTreatmentInvitersOverview";
import ClientTreatmentCompletionsOverview from "../components/clienttreatment/ClientTreatmentCompletionsOverview";
import ClientTreatmentEvaluationOverview from "../components/clienttreatment/ClientTreatmentEvaluationOverview";
import SoftwarePackageOverview from "../components/softwarePackage/SoftwarePackageOverview";
import IdentificationVerificationOverview from "../components/identificationVerification/IdentificationVerificationOverview";
import AutomatedMessageOverview from "../components/communication/automatedMessages/AutomatedMessageOverview";
import CommunicationLogOverview from "../components/communication/communicationLog/CommunicationLogOverview";
import CompanyClientTreatmentOverview from "../components/company/clientTreatment/ClientTreatmentOverview";
import RandomCheckOverview from "../components/randomChecks/RandomCheckOverview";
import CompanyTargetDashboardOverview from "../components/targets/CompanyTargetDashboardOverview";
import ClientTreatmentBudgetYearOverview from "../components/clienttreatment/ClientTreatmentBudgetYearOverview";
import ConversationsOverview from "../components/dashboard/ConversationsOverview";
import GiveawaysOverview from "../components/giveaways/giveawaysOverview";
import InterestPeriodSettingsOverview from "../components/cms/interest/InterestPeriodSettingsOverview";
/**
 * Entry file for forms, all forms are rendered here
 */
renderComponent(AppOnboardingPageOverview, "apponboardingpage_overview");
renderComponent(CmsMenuitemOverview, "cms_menuitem_overview");
renderComponent(CmsNewsitemOverview, "cms_newsitem_overview");
renderComponent(CmsPageOverview, "cms_page_overview");
renderComponent(CmsFixedContentOverview, "cms_fixed_content_overview");
renderComponent(FilterProfileOverview, "filter_profile_overview");
renderComponent(DefinitionOverview, "definition_overview");
renderComponent(ContactpersonOverview, "contactperson_overview");
renderComponent(ExistingContactpersonOverview, "contactperson_existing_overview");
renderComponent(InterestcategoryOverview, "interestcategory_overview");
renderComponent(InteresttypeOverview, "interesttype_overview");
renderComponent(MediaLibrary, "media_library");
renderComponent(OccupationOverview, "occupation_overview");
renderComponent(OrganisationOverview, "organisation_overview");
renderComponent(QuestionnairesOverview, "questionnaires_overview");
renderComponent(RoleOverview, "role_overview");
renderComponent(SegmentOverview, "segment_overview");
renderComponent(UserOverview, "user_overview");
renderComponent(TeamOverview, "team_overview");
renderComponent(AahgUserOverview, "aahg_user_overview");
renderComponent(ProcessTimeActivityOverview, "process_time_acivity_overview");
renderComponent(TeamGroupOverview, "team_group_overview");
renderComponent(DepartmentOverview, "department_overview");
renderComponent(InterestInterestTypeOverview, "interest_interesttype_overview");
renderComponent(LoanOverview, "loan_overview");
renderComponent(AccountSupportAgendaOverview, "accountsupport_agenda_overview");
renderComponent(TakeonRequestStatusOverview, "takeon_request_status_overview");
renderComponent(DiscussRequestCategoryOverview, "discuss_request_category_overview");
renderComponent(DiscussRequestAdviceOverview, "discuss_request_advice_overview");
renderComponent(DiscussRequestOverview, "discuss_request_overview");
renderComponent(InterestPeriodOverview, "interest_period_overview");
renderComponent(InterestPeriodSettingsOverview, "interest_period_settings_overview");
renderComponent(LocationZipcodeAreaOverview, "location_zipcode_area_overview");
renderComponent(LocationTownshipOverview, "location_township_overview");
renderComponent(LocationProvinceOverview, "location_province_overview");
renderComponent(DiscountOverviewTab, "discount_overview_tab");
renderComponent(CommunicationNewsletterOverview, "communication_newsletter_overview");
renderComponent(CommunicationNewsletterMailingOverview, "communication_newsletter_mailing_overview");
renderComponent(CommunicationNewsletterSubscriptionTypeOverview, "communication_newsletter_subscription_type_overview");
renderComponent(
	CommunicationNewsletterSubscriptionCategoryOverview,
	"communication_newsletter_subscription_category_overview",
);
renderComponent(CooperationRequestOverview, "cooperation_request_overview");
renderComponent(AnnuallyCooperationCheckOverview, "annually_cooperation_check_overview");

renderComponent(MailingListRecipientOverview, "communication_mailing_list_recipient_overview");
renderComponent(DossierCheckActionsOverview, "dossier_check_actions_overview");
renderComponent(ContactmomentOverview, "contactmoment_overview");
renderComponent(ContactmomentTypeOverview, "contactmoment_type_overview");
renderComponent(ContactmomentCategoryOverview, "contactmoment_category_overview");
renderComponent(DossierPartOverview, "dossier_part_overview");
renderComponent(BadPressChecksTermsOverview, "bad_press_check_terms_overview");
renderComponent(KifidCheckActionsOverview, "kifid_check_actions_overview");
renderComponent(AfmPermitOverview, "afm_permits_overview");
renderComponent(AfmCheckActionOverview, "afm_check_action_overview");
renderComponent(ApiUserOverview, "api_user_overview");
renderComponent(ClientConsentOverview, "client_consent_overview");
renderComponent(ClientConsentWithLeadsOverview, "client_consent_with_leads_overview");
renderComponent(ClientLeadOverview, "client_lead_overview");
renderComponent(ClientTreatmentOverview, "client_treatment_overview");
renderComponent(ClientTreatmentInvitersOverview, "client_treatment_inviters_overview");
renderComponent(ClientTreatmentCompletionsOverview, "client_treatment_completions_overview");
renderComponent(ClientTreatmentEvaluationOverview, "client_treatment_evaluations_overview");
renderComponent(SoftwarePackageOverview, "software_package_overview");
renderComponent(AutomatedMessageOverview, "automated_messages_overview");
renderComponent(TargetYearOverview, "target_year_overview");
renderComponent(CompanyTargetOverview, "company_target_overview");
renderComponent(CompanyTargetDashboardOverview, "company_target_dashboard_overview");
renderComponent(RelationmanagerTargetOverview, "relationmanager_target_overview");
renderComponent(CommunicationLogOverview, "communication_log_overview");
renderComponent(NewsletterCommunicationLogOverview, "newsletter_communication_log_overview");
renderComponent(NewsletterCommunicationEventOverview, "newsletter_communication_event_overview");
renderComponent(CompanyClientTreatmentOverview, "client_treatment_company_overview");
renderComponent(RandomCheckOverview, "random_check_overview");
renderComponent(DataimportOverview, "dataimport_overview");
renderComponent(NotificationOverview, "notification_overview");
renderComponent(ClientTreatmentBudgetYearOverview, "client_treatment_budget_year_overview");
renderComponent(IdentificationVerificationOverview, "identification_verification_overview");
renderComponent(ConversationsOverview, "conversations_overview");
renderComponent(GiveawaysOverview, "giveaways_overview");
renderComponent(ThemeOverview, "themes_overview");
renderComponent(CompanyAlertOverview, "companyalerts_overview");
renderComponent(ReplaceTagOverview, "replace_tag_setting_overview");
renderComponent(DosierMutationOverview, "dossier_mutation_overview");
renderComponent(StatutoryCompanyOverview, "statutory_company_overview");
renderComponent(SbiCodeOverview, "sbi_code_overview");
renderComponent(CooperationTerminationReasonOverview, "cooperation_termination_reason_overview");
