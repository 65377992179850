import PropTypes from "prop-types";
import { useContext } from "react";
import SelectField from "../../../../../../../js/react/components/general/Select";
import { InterestTypeContext } from "../../interest-type-context";

export default function FixedInterestPeriod(props) {
	const onchange = selectedOption => {
		props.onChange(selectedOption ? selectedOption.value : null);
	};

	const { fixedInterestPeriods } = useContext(InterestTypeContext);
	const selectedOption = fixedInterestPeriods.find(({ value }) => value === props.value);

	return (
		<>
			{props.label && <label>{props.label}</label>}
			<SelectField value={selectedOption} options={fixedInterestPeriods} onChange={onchange} />
		</>
	);
}

FixedInterestPeriod.propTypes = {
	label: PropTypes.string,
	value: PropTypes.number,
	onChange: PropTypes.func,
};
