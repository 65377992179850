import { useState } from "react";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import useInputHandler from "../../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import Nav from "../../../../../../js/lib/Nav";
import { route } from "../../../../../../js/helpers";
import Navigation from "../Navigation";

export default function AdviceForm() {
	const [formData, setFormData] = useState({ ...window.data.discuss_request_advice_values });
	const creating = window.data.discuss_request_advice_values.id === -1;
	const inputHandler = useInputHandler(setFormData);

	const onSuccess = () => {
		Nav.go(route("crm.discuss-request-settings.advices.index"));
	};

	return (
		<>
			<Navigation selectedTab="advices" />

			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(
						creating ? "general.add_:item" : "general.edit_:item",
						{},
						{ item: "discuss_requests.advices.singular" },
					)}
				</h1>

				<a className="link" href={route("crm.discuss-request-settings.advices.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "discuss_requests.advices.plural" })}
				</a>
			</div>
			<AjaxForm
				method={creating ? "POST" : "PUT"}
				submitUrl={
					creating
						? route("crm.discuss-request-settings.advices.store")
						: route("crm.discuss-request-settings.advices.update", formData.id)
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "discuss_requests.advices.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "discuss_requests.advices.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="name"
										value={formData.name}
										onChange={inputHandler}
										label="discuss_requests.advices.fields.name"
										required
									/>
								</div>
							</div>
							<div className="form-full">
								<div className="form-1-3">
									<Checkmark
										onChange={inputHandler}
										name="positive"
										checked={formData.positive}
										label={uctrans("discuss_requests.advices.fields.positive")}
									/>
								</div>
							</div>
							<div className="form-full">
								<Checkmark
									onChange={inputHandler}
									name="is_active"
									checked={formData.is_active}
									label={uctrans("discuss_requests.advices.fields.is_active")}
								/>
							</div>

							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="discuss_requests.advices.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
