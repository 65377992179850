import PropTypes from "prop-types";
import { Fragment } from "react";
import map from "lodash/map";
import size from "lodash/size";
import { uctrans } from "../../../../../../../js/lib/Translator";
import FormattedNumberText from "../../FormattedNumberText";
import LineGraph from "../../graph/LineGraph";

export default function LoanResults({ data, title, staticData, chartData, loanStatuses, perIntermediary }) {
	const months = [...staticData.months, "total"];

	const getForMonth = () =>
		size(data) ? (
			months.map(month =>
				data[month] ? (
					<tr key={month} className={`${month}` === "total" ? "totaal" : undefined}>
						<td>{`${month}` === "total" ? uctrans("report.total") : uctrans(`report.months.${month}`)}</td>
						{map(loanStatuses, (status, index) => (
							<Fragment key={`${month}${index}`}>
								<td className="omzet">
									<FormattedNumberText
										value={
											data[month][status.key] && data[month][status.key].sum ? data[month][status.key].sum / 1000 : 0
										}
										prefix="€ "
									/>
								</td>
								<td className="aantal">
									<FormattedNumberText
										value={data[month][status.key] && data[month][status.key].count ? data[month][status.key].count : 0}
									/>
								</td>
							</Fragment>
						))}
					</tr>
				) : (
					<tr key={month} className={`${month}` === "total" ? "totaal" : undefined}>
						<td>{`${month}` === "total" ? uctrans("report.total") : uctrans(`report.months.${month}`)}</td>
						{map(loanStatuses, (status, index) => (
							<Fragment key={index}>
								<td className="omzet">
									<FormattedNumberText value={0} prefix="€ " />
								</td>
								<td className="aantal">
									<FormattedNumberText value={0} />
								</td>
							</Fragment>
						))}
					</tr>
				),
			)
		) : (
			<tr>
				{/* eslint-disable-next-line no-mixed-operators */}
				<td colSpan={size(loanStatuses) * 2 + 1}>{uctrans("general.no_results")}</td>{" "}
			</tr>
		);

	const getForIntermediary = () =>
		size(data) ? (
			map(data, (intermediary, key) => (
				<tr key={key} className={`${key}` === "total" ? "totaal" : undefined}>
					<td>{`${key}` === "total" ? uctrans("report.total") : key}</td>
					{map(loanStatuses, (status, index) => (
						<Fragment key={`${key}${index}`}>
							<td className="omzet">
								<FormattedNumberText
									value={
										intermediary[status.key] && intermediary[status.key].sum ? intermediary[status.key].sum / 1000 : 0
									}
									prefix="€ "
								/>
							</td>
							<td className="aantal">
								<FormattedNumberText
									value={
										intermediary[status.key] && intermediary[status.key].count ? intermediary[status.key].count : 0
									}
								/>
							</td>
						</Fragment>
					))}
				</tr>
			))
		) : (
			<tr>
				{/* eslint-disable-next-line no-mixed-operators */}
				<td colSpan={size(loanStatuses) * 2 + 1}>{uctrans("general.no_results")}</td>{" "}
			</tr>
		);

	return (
		<>
			<table className="hypotheken_rapportage_table rapportage_table reporttable">
				<thead>
					<tr>
						{/* eslint-disable-next-line no-mixed-operators */}
						<th colSpan={size(loanStatuses) * 2 + 1}>{title ? title : uctrans("report.singular")}</th>
					</tr>
					<tr className="head1">
						<td colSpan="1" />
						{map(loanStatuses, (status, key) => (
							<td colSpan="2" className="status_titel" key={key}>
								<span>
									<strong>{status.key}</strong> - {status.label}
								</span>
								<br />
								<span>(€ x 1.000)</span>
							</td>
						))}
					</tr>
					<tr className="head2">
						<td>{perIntermediary ? uctrans("intermediaries.singular") : uctrans("report.month")}</td>
						{map(loanStatuses, (status, index) => (
							<Fragment key={index}>
								<td className="omzet">Omzet</td>
								<td className="aantal">Aantal</td>
							</Fragment>
						))}
					</tr>
				</thead>
				<tbody>{perIntermediary ? getForIntermediary() : getForMonth()}</tbody>
			</table>
			{size(data) && chartData.chartOptions ? (
				<div className="mt-5">
					<LineGraph
						hideTitle
						series={chartData.series}
						title={chartData.title}
						chartOptions={chartData.chartOptions}
					/>
				</div>
			) : null}
		</>
	);
}

LoanResults.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	loanStatuses: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	staticData: PropTypes.object,
	chartData: PropTypes.any,
	perIntermediary: PropTypes.bool,
	title: PropTypes.string,
};
