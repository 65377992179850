import { route, yesNoOptions } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import Dialog from "../../../../../js/react/components/general/Dialog";
import CheckboxMultiFilter from "../../../../../js/react/components/general/overview/CheckboxMultiFilter";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import TextFilter from "../../../../../js/react/components/general/overview/TextFilter";
import Nav from "../../../../../js/lib/Nav";
import LeadIcon from "../../../../../js/react/components/clientConsents/LeadIcon";
import LeadOverviewItem from "../../../../../js/react/components/clientConsents/LeadOverviewItem";
import LeadDialogContent from "./LeadDialogContent";
import LeadsNavigation from "./LeadsNavigation";
import { useState } from "react";

export default function ClientLeadOverview() {
	const overviewData = { ...window.data.overview };
	const [dialogLead, setDialogLead] = useState(null);

	const handleExport = overviewParameters => {
		Nav.go(route(`crm.client-leads.export`, overviewParameters));
	};

	return (
		<>
			<LeadsNavigation selectedTab="leads_overview" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("client_consents.overview_leads")}</h1>
			</div>
			<AbnOverview
				initialData={overviewData}
				indexUrl={route("crm.client-leads.data")}
				modelTranslations={{
					plural: trans("client_consents.leads.plural"),
					singular: trans("client_consents.leads.singular"),
				}}
				renderFilters={overview => (
					<Filters>
						<TextFilter
							hideIcon
							className="form-1-2"
							name="search"
							label={uctrans("general.search")}
							placeholder={uctrans("client_consents.search_placeholder")}
						/>
						<TextFilter
							hideIcon
							className="form-1-2"
							name="consent_company_name"
							label={uctrans("intermediaries.singular")}
							placeholder={uctrans("general.search_:item", {}, { item: "intermediaries.singular" })}
						/>
						<CheckboxMultiFilter
							name="lead_types"
							label={uctrans("client_consents.leads.lead_type_filter")}
							className="overview-filter form-1-2"
							checkMarkClassName="q-full"
							options={overviewData.metadata.filterdata.lead_type_options}
							renderCheckmarkLabel={option => (
								<div className="flex">
									<LeadIcon
										iconUrl={route("crm.files.public-download", { idtoken: option.icon.id_token })}
										type={option.string_key}
										className="-mt-3"
									/>
									{option.label}
								</div>
							)}
							useSelectAll
						/>
						<CheckboxMultiFilter
							name="lead_statuses"
							label={uctrans("client_consents.leads.lead_status_filter")}
							className="overview-filter form-1-2"
							checkMarkClassName="q-full"
							options={overviewData.metadata.filterdata.lead_status_options}
							useSelectAll
						/>

						<SelectFilter
							className="form-1-3"
							name="consent_view_status"
							label={uctrans("client_consents.client_consent_view_status")}
							options={overview.data.metadata.filterdata.view_status_options}
							collapsible
						/>
						<SelectFilter
							className="form-1-3"
							name="expired"
							label={uctrans("client_consents.leads.show_expired_leads")}
							options={yesNoOptions()}
							collapsible
						/>
						<SelectFilter
							className="form-1-3"
							name="deactivated"
							label={uctrans("client_consents.leads.show_deactivated_leads")}
							options={yesNoOptions()}
							collapsible
						/>
						<SelectFilter
							className="form-1-2"
							name="consent_team_id"
							label={uctrans("intermediaries.fields.team")}
							options={overview.data.metadata.filterdata.teams}
							collapsible
						/>
						<SelectFilter
							className="form-1-2"
							name="consent_relationmanager_id"
							label={uctrans("intermediaries.fields.relationmanager")}
							options={overview.data.metadata.filterdata.relationmanagers}
							collapsible
						/>
						<SelectFilter
							className="form-1-2"
							name="consent_segment_id"
							label={uctrans("intermediaries.fields.segment")}
							options={overview.data.metadata.filterdata.segments}
							collapsible
						/>
						<SelectFilter
							className="form-1-2"
							name="consent_organisation_id"
							label={uctrans("intermediaries.fields.organisation")}
							options={overview.data.metadata.filterdata.organisations}
							collapsible
						/>
					</Filters>
				)}
				renderBody={() => (
					<div className="text-xs">
						<DataTable>
							<DataColumn
								width={4}
								sortable="consent_company_label"
								renderHeaderContent={() => uctrans("client_consents.fields.company")}
								renderCellContent={({ consent }) => (consent.company_label ? consent.company_label : "-")}
							/>
							<DataColumn
								width={4}
								sortable="consent_name_first_applicant"
								renderHeaderContent={() => uctrans("client_consents.fields.client_name")}
								renderCellContent={({ consent }) =>
									consent.name_second_applicant
										? `${consent.name_first_applicant} & ${consent.name_second_applicant}`
										: consent.name_first_applicant
								}
							/>
							<DataColumn
								width={2}
								sortable="consent_loan_number"
								renderHeaderContent={() => uctrans("client_consents.fields.loan_number")}
								renderCellContent={({ consent }) => (consent.loan_number ? consent.loan_number : "-")}
							/>
							<DataColumn
								width={2}
								sortable="consent_starts_at"
								renderHeaderContent={() => uctrans("client_consents.fields.starts_at")}
								renderCellContent={({ consent }) => (consent.starts_at ? format(consent.starts_at, "dd-MM-y") : "-")}
							/>
							<DataColumn
								width={2}
								sortable="consent_expires_at"
								renderHeaderContent={() => uctrans("client_consents.fields.expires_at")}
								renderCellContent={({ consent }) => (consent.expires_at ? format(consent.expires_at, "dd-MM-y") : "-")}
							/>
							<DataColumn
								width={3}
								sortable="consent_view_status"
								renderHeaderContent={() => uctrans("client_consents.client_consent_view_status")}
								renderCellContent={({ consent }) => consent.view_status_label}
							/>
							<DataColumn
								width={1}
								renderHeaderContent={() => uctrans("client_consents.leads.singular")}
								renderCellContent={lead => <LeadOverviewItem lead={lead} onClick={() => setDialogLead(lead)} />}
							/>

							<DataColumn
								width={2}
								sortable="created_at"
								renderHeaderContent={() => uctrans("client_consents.leads.fields.created_at")}
								renderCellContent={lead => (lead.created_at ? format(lead.created_at, "dd-MM-y") : "-")}
							/>

							<DataColumn
								width={2}
								sortable="expired_at"
								renderHeaderContent={() => uctrans("client_consents.leads.fields.expired_at")}
								renderCellContent={lead => (lead.expired_at ? format(lead.expired_at, "dd-MM-y") : "-")}
							/>

							<DataColumn
								width={2}
								sortable="deactivated_at"
								renderHeaderContent={() => uctrans("client_consents.leads.fields.deactivated_at")}
								renderCellContent={lead => (lead.deactivated_at ? format(lead.deactivated_at, "dd-MM-y") : "-")}
							/>
						</DataTable>
					</div>
				)}
				renderFooter={overview => (
					<a className="button button-primary mt-10" onClick={() => handleExport(overview.getParameters())}>
						{uctrans("client_consents.export")}
					</a>
				)}
			/>

			<Dialog width={800} isOpen={dialogLead != null} onClose={() => setDialogLead(null)}>
				<LeadDialogContent lead={dialogLead} forWebsite={false} />
			</Dialog>
		</>
	);
}
