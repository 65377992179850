import head from "lodash/head";
import some from "lodash/some";
import map from "lodash/map";
import includes from "lodash/includes";
import remove from "lodash/remove";
import find from "lodash/find";
import PropTypes from "prop-types";
import { uctrans } from "../../../../../js/lib/Translator";
import {
	checkQuestionDependency,
	removeAnswerLogic,
	replaceTags,
	setAnswerLogic,
} from "../../../../../js/questionnairehelpers";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import Question from "../../../../../website/js/react/components/Question";
import QuestionnaireContext from "../../../../../website/js/react/components/QuestionnaireContext";
import FormattedNumberText from "../report/FormattedNumberText";
import ContactmomentSubjectContent from "./ContactmomentSubjectContent";

export default function ContactmomentSubjects(props) {
	const removeAnswer = (question, value) => {
		const answers = removeAnswerLogic(
			props.profileQuestionnaireAnswers,
			question,
			value,
			props.profileQuestionnaire.question_types,
		);

		props.onProfileQuestionnaireAnswerChange(answers);
	};

	const setAnswer = (question, value) => {
		if (value === "") {
			return removeAnswer(question, null);
		}

		const answers = setAnswerLogic(
			props.profileQuestionnaireAnswers,
			question,
			value,
			props.profileQuestionnaire.question_types,
		);

		props.onProfileQuestionnaireAnswerChange(answers);
	};

	const checkQuestionVisibility = question => {
		if (!question.active || (props.hideInCommercialProfileQuestions && question.hide_in_commercial_profile)) {
			question.visible = false;
		} else {
			checkQuestionDependency(question, props.profileQuestionnaireAnswers, props.profileQuestionnaire.dependency_types);
			if (!question.visible && props.profileQuestionnaireAnswers) {
				const invisibleQuestionWithAnswer = find(props.profileQuestionnaireAnswers, ["question_id", question.id]);
				if (invisibleQuestionWithAnswer) {
					if (invisibleQuestionWithAnswer.answer_options && invisibleQuestionWithAnswer.answer_options > 0) {
						removeAnswer(question, head(invisibleQuestionWithAnswer.answer_options));
					} else {
						removeAnswer(question, null);
					}
				}
			}
		}
		return question;
	};

	const checkIfPageIsVisible = page => {
		if (props.creating === true) {
			return false;
		}
		if (props.discussedFinance && page.commercial_subject) {
			return true;
		}
		const questionIdsInPage = map(page.questions, "id");
		const questionIdsThatAreAnswered = map(props.profileQuestionnaireAnswers, "question_id");
		return some(questionIdsThatAreAnswered, questionIdThatIsAnswered =>
			includes(questionIdsInPage, questionIdThatIsAnswered),
		);
	};

	const pageCollapseStateChanged = (page, collapsedState) => {
		const newVisiblePageIds = props.visiblePageIds ? [...props.visiblePageIds] : [];
		if (collapsedState) {
			remove(newVisiblePageIds, pageId => pageId === page.id);
		} else {
			newVisiblePageIds.push(page.id);
		}
		props.onContactmomentFormChange("visiblePageIds", newVisiblePageIds);
	};

	const calculatePercentage = (part, total) => {
		if (part > 0 && total > 0) {
			return (100 * part) / total;
		}
		return "-";
	};

	return (
		<QuestionnaireContext.Provider
			value={{
				questionnaire: props.profileQuestionnaire,
				// for now dont allow tags in profile questionnaire
				replaceTags: content => replaceTags(content, []),
				switchPage: () => {},
				hasNextPage: () => {},
				nextPage: () => {},
				horizontalAnswerOptions: true,
				isLoading: false,
				currentPage: () => {},
				setAnswer,
				removeAnswer,
				answers: props.profileQuestionnaireAnswers,
			}}>
			<div className="mb-8">
				{props.profileQuestionnaire.pages.map(page => (
					<div key={page.id} className="px-5 pt-2 pb-1 mb-2" style={{ backgroundColor: "#F5F6F8" }}>
						<ContactmomentSubjectContent
							initialCollapseState={!checkIfPageIsVisible(page)}
							onCollapse={pageCollapseStateChanged}
							page={page}>
							{page.questions.map(question => (
								<Question key={question.id} question={checkQuestionVisibility(question)} />
							))}

							{page.commercial_subject && (
								<>
									<div className="flex mb-4">
										<div className="w-32">
											<label>{uctrans("contactmoments.commercial_production")}</label>
											<p>
												<small className="block text-grey">{uctrans("contactmoments.commercial_explanation")}</small>
											</p>
										</div>
										<div className="flex-1 flex-wrap ml-8">
											<RadioGroup
												name="discussed_finance"
												value={props.discussedFinance}
												onChange={value => props.onContactmomentFormChange("discussed_finance", value)}
												horizontal>
												<Radio label={uctrans("general.no")} value={false} />
												<Radio label={uctrans("general.yes")} value />
											</RadioGroup>
										</div>
									</div>
									{props.discussedFinance && (
										<>
											<div className="flex ml-32 pl-8">
												<div className="mr-8 flex-1">
													<label>{uctrans("contactmoments.fields.year_sum")}</label>
													<input
														type="number"
														value={props.yearSum ? props.yearSum : ""}
														onChange={event =>
															props.onContactmomentFormChange("year_sum", parseInt(event.target.value))
														}
													/>
												</div>
												{props.totalWallet && (
													<div className="flex-1">
														<label>{uctrans("contactmoments.commercial_year_sum")}</label>
														<div>
															<FormattedNumberText
																value={calculatePercentage(
																	props.totalWallet.sum,
																	props.yearSum ? props.yearSum * 1000 : 0,
																)}
																decimalScale={1}
																percentage
															/>
														</div>
													</div>
												)}
											</div>
											<div className="flex ml-32 pl-8 mt-2 mb-4">
												<div className="mr-8 flex-1">
													<label>{uctrans("contactmoments.fields.loan_count")}</label>
													<input
														type="number"
														value={props.loanCount ? props.loanCount : ""}
														onChange={event =>
															props.onContactmomentFormChange("loan_count", parseInt(event.target.value))
														}
													/>
												</div>
												{props.totalWallet && (
													<div className="flex-1">
														<label>{uctrans("contactmoments.commercial_loan_count")}</label>
														<div>
															<FormattedNumberText
																value={calculatePercentage(
																	props.totalWallet.count,
																	props.loanCount ? props.loanCount : 0,
																)}
																decimalScale={1}
																percentage
															/>
														</div>
													</div>
												)}
											</div>
										</>
									)}
								</>
							)}
						</ContactmomentSubjectContent>
					</div>
				))}
			</div>
		</QuestionnaireContext.Provider>
	);
}

ContactmomentSubjects.propTypes = {
	discussedFinance: PropTypes.bool,
	yearSum: PropTypes.number,
	loanCount: PropTypes.number,
	profileQuestionnaire: PropTypes.object,
	profileQuestionnaireAnswers: PropTypes.array,
	onProfileQuestionnaireAnswerChange: PropTypes.func,
	onContactmomentFormChange: PropTypes.func,
	creating: PropTypes.bool,
	visiblePageIds: PropTypes.array,
	hideInCommercialProfileQuestions: PropTypes.bool,
	totalWallet: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

ContactmomentSubjects.defaultProps = {
	creating: false,
	hideInCommercialProfileQuestions: false,
};
