import Dialog from "../../../../../js/react/components/general/Dialog";

export default function CompanyAlerts() {
	const companyAlerts = window.data.company_alerts ? window.data.company_alerts : [];
	return (
		<>
			{companyAlerts.map(companyAlert => (
				<Dialog isOpen key={companyAlert.id}>
					<div className="company_alert_content">
						<h4>{companyAlert.title}</h4>
						<div dangerouslySetInnerHTML={{ __html: companyAlert.content }} />
					</div>
				</Dialog>
			))}
		</>
	);
}
