import { useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import Translate from "../../../../../js/react/components/general/Translate";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import IconButton from "../../../../../js/react/components/general/IconButton";
import DeleteColumnContent from "../../../../../js/react/components/general/overview/DeleteColumnContent";
import TabLinks from "../general/tabs/TabLinks";

export default function ContactmomentTypeOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
		create_link: window.data.contactmoment_type_create_link,
		index_link: window.data.contactmoment_type_index_link,
		permissions: window.data.contactmoment_type_permissions,
	});

	return (
		<>
			{window.data.nav_data && (
				<>
					<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
					<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
				</>
			)}
			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="contactmoments.types.plural" />
				</h1>
				{overviewData.create_link && (
					<div>
						<IconButton
							href={overviewData.create_link}
							content={uctrans("general.add_:item", {}, { item: "contactmoments.types.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={overviewData.index_link}
				modelTranslations={{
					plural: uctrans("contactmoments.types.plural"),
					singular: uctrans("contactmoments.types.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("contactmoments.types.fields.name")}
							renderCellContent={contactmomentType => (
								<a href={contactmomentType.edit_link} className="row-title link">
									{contactmomentType.name}
								</a>
							)}
						/>
						<DataColumn
							width={1}
							renderHeaderContent={() => uctrans("contactmoments.types.fields.has_base_type_message")}
							renderCellContent={contactmomentType =>
								contactmomentType.has_base_type_message ? uctrans("general.yes") : uctrans("general.no")
							}
						/>
						<DataColumn
							width={1}
							renderHeaderContent={() => uctrans("contactmoments.types.fields.has_base_type_conversation")}
							renderCellContent={contactmomentType =>
								contactmomentType.has_base_type_conversation ? uctrans("general.yes") : uctrans("general.no")
							}
						/>
						<DataColumn
							width={1}
							renderHeaderContent={() => uctrans("contactmoments.types.fields.is_valuable")}
							renderCellContent={contactmomentType =>
								contactmomentType.is_valuable ? uctrans("general.yes") : uctrans("general.no")
							}
						/>
						<DataColumn
							width={1}
							renderHeaderContent={() => uctrans("contactmoments.types.fields.contactperson_required")}
							renderCellContent={contactmomentType =>
								contactmomentType.contactperson_required ? uctrans("general.yes") : uctrans("general.no")
							}
						/>
						{overviewData.permissions.delete && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(contactmomentType, overview) =>
									contactmomentType.delete_link && (
										<DeleteColumnContent onClick={() => overview.askDelete(contactmomentType)} />
									)
								}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
