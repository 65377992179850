import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import { can, route } from "../../../../../js/helpers";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import QuestionnaireCompletionStatisticsContext from "../questionnaires/statistic_display/QuestionnaireCompletionStatisticsContext";
import QuestionStatisticsDisplay from "../questionnaires/statistic_display/QuestionStatisticsDisplay";
import Navigation from "./Navigation";

export default function ClientTreatmentQuestionnaireStatisticsForm(props) {
	const [staticData, setStaticData] = useState(
		typeof props.QuestionnaireStaticData === "undefined"
			? cloneDeep(window.data.static_data)
			: props.QuestionnaireStaticData,
	);

	useEffect(() => {
		if (typeof props.QuestionnaireStaticData !== "undefined") {
			setStaticData(props.QuestionnaireStaticData);
		}
	}, [props.QuestionnaireStaticData]);

	const [statisticsData] = useState({
		questionnaire: window.data.questionnaire,
		pie_chart_config: window.data.pie_chart_config,
		column_chart_config: window.data.column_chart_config,
		questionnaire_completions: window.data.questionnaire_completions,
		questionnaire_completion_statistics_data: window.data.questionnaire_completion_statistics_data,
		question_types: window.data.question_types,
		collegues: window.data.collegues,
	});

	return (
		<>
			<Navigation
				selectedTab="client-treatment-evaluations"
				clientTreatment={staticData.client_treatment}
				clientTreatmentTypes={staticData.client_treatment_types}
				loggedInInviter={
					window.data.user
						? staticData.client_treatment.inviters.find(
								inviter => Number(inviter.user_id) === Number(window.data.user.id),
						  )
						: null
				}
				canUpdate={can("client_treatment.client_treatment", "update")}
			/>

			<div className="page-title-container">
				<h1 className="page-title">
					{statisticsData.collegues
						? uctrans("client_treatment.evaluations.fields.statistics_collegues")
						: uctrans("client_treatment.evaluations.fields.statistics_not_collegues")}
				</h1>

				<div>
					<a
						className="link"
						href={route("crm.client-treatments.evaluations", {
							client_treatment: staticData.client_treatment.id,
						})}>
						{uctrans("general.back_to_overview_:page", {}, { page: "client_treatment.evaluations.plural" })}
					</a>
				</div>
			</div>

			{/* eslint-disable-next-line react/jsx-no-target-blank */}

			<div className="float-right">
				<a
					className="button button-primary mb-4"
					href={route(
						statisticsData.collegues
							? "crm.client-treatments.evaluations.statistics-collegues-as-pdf"
							: "crm.client-treatments.evaluations.statistics-not-collegues-as-pdf",
						{
							client_treatment: staticData.client_treatment.id,
						},
					)}
					target="_blank"
					rel="noopener noreferrer">
					<EvaIcon type="download-outline" height="16" width="25" fill="#25282e" />
					{uctrans("questionnaires.export_to_pdf")}
				</a>
			</div>

			<QuestionnaireCompletionStatisticsContext.Provider
				value={{
					questionnaire_completions: statisticsData.questionnaire_completions,
					questionnaire_completion_statistics_data: statisticsData.questionnaire_completion_statistics_data,
					question_types: statisticsData.question_types,
					pie_chart_config: statisticsData.pie_chart_config,
					column_chart_config: statisticsData.column_chart_config,
				}}>
				{statisticsData.questionnaire_completion_statistics_data.map((questionData, index) => (
					<div key={index}>
						<QuestionStatisticsDisplay questionData={questionData} />
					</div>
				))}
			</QuestionnaireCompletionStatisticsContext.Provider>

			<div className="mb-20" />
		</>
	);
}
ClientTreatmentQuestionnaireStatisticsForm.propTypes = {
	QuestionnaireStaticData: PropTypes.object,
};
