import PropTypes from "prop-types";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";

export default function Navigation(props) {
	return (
		<>
			<BreadCrumbs breadCrumbs={props.breadCrumbs} />
			{props.tabs && <TabLinks tabs={props.tabs} activeTab={props.selectedTab} />}
		</>
	);
}
Navigation.propTypes = {
	selectedTab: PropTypes.string.isRequired,
	breadCrumbs: PropTypes.array,
	tabs: PropTypes.array,
};
