import find from "lodash/find";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { CmsTypesContext } from "../../cms-types-context";
import Boolean from "../attributes/Boolean";
import Html from "../attributes/Html";
import Image from "./Image";
import Link from "./Link";

export default function CampaignFoot(props) {
	const [values, setValues] = useState(props.values ? props.values : {});
	useEffect(() => {
		setValues(props.values ? props.values : "");
	}, [props.values]);
	const handleValuesChange = newValues => {
		setValues(newValues);
		props.onChange(newValues);
	};
	const handleValueChange = (name, newValue) => {
		const newValues = { ...values };
		newValues[name] = newValue;
		handleValuesChange(newValues);
	};

	const { partTypes } = useContext(CmsTypesContext);
	const partType = find(partTypes, partType => partType.key === "campaign_foot");

	if (partType) {
		return (
			<div className="mt-5">
				<h5>{props.label}</h5>
				<div className="pl-6 mt-3  border-l border-grey-light ">
					<Boolean
						label={partType.attributes.use_campaign_foot.label}
						value={values.use_campaign_foot}
						onChange={value => {
							handleValueChange("use_campaign_foot", value);
						}}
					/>
					{!!values.use_campaign_foot && (
						<>
							<Html
								label={partType.attributes.content.label}
								value={values.content}
								onChange={value => {
									handleValueChange("content", value);
								}}
							/>
							<Image
								label={partType.attributes.image.label}
								values={values.image}
								onChange={value => {
									handleValueChange("image", value);
								}}
							/>
							<Boolean
								label={partType.attributes.use_link.label}
								value={values.use_link}
								onChange={value => {
									handleValueChange("use_link", value);
								}}
							/>
							{!!values.use_link && (
								<Link
									values={values.link ? values.link : {}}
									onChange={value => {
										handleValueChange("link", value);
									}}
									useText={false}
								/>
							)}
						</>
					)}
				</div>
			</div>
		);
	}
}

CampaignFoot.propTypes = {
	label: PropTypes.string,
	values: PropTypes.shape({
		use_campaign_foot: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
		content: PropTypes.string,
		image: PropTypes.object,
	}),
	onChange: PropTypes.func.isRequired,
};
