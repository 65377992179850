import PropTypes from "prop-types";
import classNames from "classnames";
import Translate from "../../../../../js/react/components/general/Translate";
import { uctrans } from "../../../../../js/lib/Translator";
import Tippy from "@tippyjs/react";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";

export default function InterestValuesTable({ values, interestRateClasses, fixedInterestPeriods, onValuesChange }) {
	const handleValueChange = (value, fixedInterestPeriodId, rateClassId) => {
		{
			let parsedValue = value.replace(/[^0-9,-]/g, "");
			const commaPosition = parsedValue.indexOf(",");

			if (commaPosition !== -1) {
				const partBeforeComma = parsedValue.substring(0, parsedValue.indexOf(","));
				let partAfterComma = parsedValue.substring(parsedValue.indexOf(","));
				partAfterComma = partAfterComma.replace(/,/g, "");
				parsedValue = `${partBeforeComma},${partAfterComma}`;
			}

			const currentValues = { ...values };

			if (parsedValue === "") {
				delete currentValues[`${fixedInterestPeriodId}-${rateClassId}`];
			} else {
				currentValues[`${fixedInterestPeriodId}-${rateClassId}`] = {
					fixed_interest_period_id: fixedInterestPeriodId,
					rate_class_id: rateClassId,
					value: parsedValue,
				};
			}

			onValuesChange(currentValues);
		}
	};

	const removeArchivedValues = (type, id) => {
		const currentValues = { ...values };

		Object.keys(currentValues).forEach(valueKey => {
			const value = currentValues[valueKey];
			if (type === "interest_rate_class" && value.rate_class_id === id) {
				delete currentValues[`${value.fixed_interest_period_id}-${value.rate_class_id}`];
			} else if (type === "fixed_interest_period" && value.fixed_interest_period_id === id) {
				delete currentValues[`${value.fixed_interest_period_id}-${value.rate_class_id}`];
			}
		});

		onValuesChange(currentValues);
	};

	return (
		<div className="table w-full">
			<div className="table-row bg-primary">
				<div className="table-cell p-3">
					<span className="text-white">
						<Translate content="interest.interest_rate_classes.plural" />
					</span>
				</div>
				{interestRateClasses.map(interestRateClass => (
					<div className="table-cell p-3" key={interestRateClass.id}>
						<span className="text-white">
							{interestRateClass.name}{" "}
							{interestRateClass.archived === true && (
								<>
									(<Translate content="interest.fields.archived" />){" "}
									<Tippy content={uctrans("interest.sentences.delete_archived_value")}>
										<span
											className="relative cursor-pointer"
											style={{ top: "2px" }}
											onClick={() => removeArchivedValues("interest_rate_class", interestRateClass.id)}>
											<EvaIcon type="trash-2-outline" fill="#fff" height="14" width="14" />
										</span>
									</Tippy>
								</>
							)}
						</span>
						<br />
						<span className="text-white text-sm">{interestRateClass.description}</span>
					</div>
				))}
			</div>

			{fixedInterestPeriods.map((fixedInterestPeriod, index) => (
				<div
					className={classNames("table-row", {
						"bg-grey-lighter": index % 2,
					})}
					key={fixedInterestPeriod.id}>
					<div className="table-cell p-3">
						{fixedInterestPeriod.name}
						{fixedInterestPeriod.archived === true && (
							<>
								(<Translate content="interest.fields.archived" />){" "}
								<Tippy content={uctrans("interest.sentences.delete_archived_value")}>
									<span
										className="relative cursor-pointer"
										style={{ top: "2px" }}
										onClick={() => removeArchivedValues("fixed_interest_period", fixedInterestPeriod.id)}>
										<EvaIcon type="trash-2-outline" fill="#f9acaa" height="14" width="14" />
									</span>
								</Tippy>
							</>
						)}
					</div>
					{interestRateClasses.map(interestRateClass => (
						<div className="table-cell p-3" key={interestRateClass.id}>
							<input
								className={classNames("w-12 border border-grey rounded p-2 mr-2", {
									"bg-grey-lighter": index % 2,
								})}
								value={
									typeof values[`${fixedInterestPeriod.id}-${interestRateClass.id}`] !== "undefined"
										? values[`${fixedInterestPeriod.id}-${interestRateClass.id}`].value
										: ""
								}
								onChange={event => handleValueChange(event.target.value, fixedInterestPeriod.id, interestRateClass.id)}
								placeholder="0,00"
							/>
							%
						</div>
					))}
				</div>
			))}
		</div>
	);
}

InterestValuesTable.propTypes = {
	values: PropTypes.object.isRequired,
	interestRateClasses: PropTypes.array.isRequired,
	fixedInterestPeriods: PropTypes.array.isRequired,
	onValuesChange: PropTypes.func.isRequired,
};
