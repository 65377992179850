import map from "lodash/map";
import { useState } from "react";
import { route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import { createLoader } from "../../../../../js/react/components/general/notifications";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { CmsTypesContext } from "../cms/cms-types-context";
import Attribute from "../cms/general/attributes/Attribute";
import PartContent from "../cms/general/parts/PartContent";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import axios from "axios";

export default function BirthdaySettingsForm() {
	const [formData, setFormData] = useState({ ...window.data.form_data });
	const staticData = { ...window.data.static_data };

	const handleContentChange = (key, values) => {
		const newFormData = { ...formData };
		newFormData.contents[key] = values;
		setFormData(newFormData);
	};

	const resetPopupMailSeenData = async () => {
		if (!window.data.is_live) {
			const loader = createLoader(uctrans("birthday.dates_are_being_reset"));
			try {
				await axios.get(route("crm.birthday-settings.reset-dates-today"));
				loader.success(uctrans("birthday.dates_successfully_reset"));
			} catch (error) {
				console.error(error);
				loader.failure(error.message);
			}
		}
	};

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("birthday.settings") }]} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("general.edit_:item", {}, { item: "birthday.settings" })}</h1>
			</div>

			<div className="min-h-screen md:flex">
				<div className="flex-1">
					<AjaxForm
						method="PUT"
						submitUrl={route("crm.birthday-settings.update")}
						loaderText={uctrans("general.:item_is_being_saved", {}, { item: "birthday.settings" })}
						successText={uctrans("general.saved_:item", {}, { item: "birthday.settings" })}
						onSuccess={() => window.location.reload()}
						data={{ ...formData }}
						useFlashMessage>
						<CmsTypesContext.Provider value={staticData.cmsTypes}>
							<div className="cms-attribute grid-container">
								<div className="form-full w-2/3">
									{map(formData.attributes, (attribute, key) => {
										const attributeValues =
											formData.contents != null && Object.prototype.hasOwnProperty.call(formData.contents, key)
												? formData.contents[key]
												: null;
										if (attribute.type === "part") {
											return (
												<div className="mt-5">
													<PartContent
														key={key}
														label={attribute.label}
														parttype={attribute.parttype}
														attribute={attribute}
														values={attributeValues}
														onChange={values => {
															handleContentChange(key, values);
														}}
													/>
												</div>
											);
										} else {
											return (
												<div className="mt-5">
													<Attribute
														key={key}
														attribute={attribute}
														values={attributeValues}
														onChange={values => {
															handleContentChange(key, values);
														}}
													/>
												</div>
											);
										}
									})}
								</div>
								<div className="form-full">
									<SubmitBar item="birthday.settings" />
								</div>
							</div>
						</CmsTypesContext.Provider>
					</AjaxForm>
				</div>
				<div className="flex-none w-1/3 border-l ml-2 pl-12">
					{!window.data.is_live && (
						<>
							<h2>{uctrans("birthday.reset_title")}</h2>
							<p>{uctrans("birthday.reset_explanation")}</p>
							<div className="flex w-full justify-start mt-6">
								<a className="button button-tertiary" onClick={() => resetPopupMailSeenData()}>
									{uctrans("birthday.reset_data_for_today")}
								</a>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}
