import PropTypes from "prop-types";
import map from "lodash/map";
import { uctrans, trans } from "../../../../../../../js/lib/Translator";
import FormattedNumberText from "../../FormattedNumberText";

export default function QuantitativeResults({ data, title, periodInfo }) {
	const { relationmanagers, total } = data;

	return (
		<table className="gesprek_rapportage_table rapportage_table reporttable">
			<thead>
				<tr>
					<th
						colSpan={
							periodInfo.period_type === "month"
								? 9
								: periodInfo.period_type === "week"
								  ? 8
								  : periodInfo.period_type === "year"
								    ? 6
								    : 5
						}>
						{title ? title : uctrans("report.singular")}
					</th>
				</tr>
				<tr className="head1">
					<td />
					<td className="aantal_tphead" />
					{periodInfo.period_type !== "year" && (
						<>
							<td className="weekhead">
								In portefeuille
								{periodInfo.period_type === "week" && (
									<span>
										<br />
										(Week {periodInfo.week} {periodInfo.year})
									</span>
								)}
								{periodInfo.period_type === "month" && (
									<span>
										<br />({trans(`report.short_months.${periodInfo.month}`)} {periodInfo.year})
									</span>
								)}
							</td>
							<td className="weekhead" colSpan={periodInfo.period_type === "week" ? 1 : 2}>
								Door relatiemanager
								{periodInfo.period_type === "week" && (
									<span>
										<br />
										(Week {periodInfo.week} {periodInfo.year})
									</span>
								)}
								{periodInfo.period_type === "month" && (
									<span>
										<br />({trans(`report.short_months.${periodInfo.month}`)} {periodInfo.year})
									</span>
								)}
							</td>
						</>
					)}
					{(periodInfo.period_type === "year" ||
						periodInfo.period_type === "month" ||
						periodInfo.period_type === "week") && (
						<>
							<td className="jaarhead" colSpan="2">
								In portefeuille{" "}
								<span>
									({periodInfo.year}
									{periodInfo.year === periodInfo.current_year ? " YTD" : ""})
								</span>
							</td>
							<td className="jaarhead" colSpan="2">
								Door relatiemanager{" "}
								<span>
									({periodInfo.year}
									{periodInfo.year === periodInfo.current_year ? " YTD" : ""})
								</span>
							</td>
						</>
					)}
				</tr>
				<tr className="head2">
					<td>Naam</td>
					<td className="aantal_tp">Aantal intermediairs</td>
					{periodInfo.period_type !== "year" && (
						<>
							<td className="gerealiseerd">Gesprekken</td>
							<td className="gerealiseerd">Gesprekken</td>
							{periodInfo.period_type !== "week" && <td className="weekgemiddelde">Weekgemiddelde</td>}
						</>
					)}
					{(periodInfo.period_type === "year" ||
						periodInfo.period_type === "month" ||
						periodInfo.period_type === "week") && (
						<>
							<td className="gerealiseerd">Gesprekken</td>
							<td className="percentage">Gespreks%</td>
							<td className="gerealiseerd">Gesprekken</td>
							<td className="weekgemiddelde">Weekgemiddelde</td>
						</>
					)}
				</tr>
			</thead>
			<tbody>
				{map(relationmanagers, (relationmanager, key) => (
					<tr key={key}>
						<td>{relationmanager.short_name}</td>
						<td className="aantal_tp">{relationmanager.company_count}</td>
						{periodInfo.period_type !== "year" && (
							<>
								<td className="gerealiseerd">
									<FormattedNumberText value={relationmanager.wallet_conversation_count} />
								</td>
								<td className="gerealiseerd">
									<FormattedNumberText value={relationmanager.rm_conversation_count} />
								</td>
								{periodInfo.period_type !== "week" && (
									<td className="weekgemiddelde">
										<FormattedNumberText value={relationmanager.rm_conversation_weekavg} decimalScale={1} />
									</td>
								)}
							</>
						)}
						{(periodInfo.period_type === "year" ||
							periodInfo.period_type === "month" ||
							periodInfo.period_type === "week") && (
							<>
								<td className="gerealiseerd">
									<FormattedNumberText value={relationmanager.wallet_conversation_year_count} />
								</td>
								<td className="percentage">
									<FormattedNumberText
										value={relationmanager.wallet_conversation_year_progress}
										decimalScale={1}
										percentage
									/>
								</td>
								<td className="gerealiseerd">
									<FormattedNumberText value={relationmanager.rm_conversation_year_count} />
								</td>
								<td className="weekgemiddelde">
									<FormattedNumberText value={relationmanager.rm_conversation_year_weekavg} decimalScale={1} />
								</td>
							</>
						)}
					</tr>
				))}
				<tr className="totaal">
					<td>Totaal</td>
					<td className="aantal_tp">
						{" "}
						<FormattedNumberText value={total.company_count} />
					</td>
					{periodInfo.period_type !== "year" && (
						<>
							<td className="gerealiseerd">
								<FormattedNumberText value={total.wallet_conversation_count} />
							</td>
							<td className="gerealiseerd">
								<FormattedNumberText value={total.rm_conversation_count} />
							</td>
							{periodInfo.period_type !== "week" && (
								<td className="weekgemiddelde">
									<FormattedNumberText value={total.rm_conversation_weekavg} decimalScale={1} />
								</td>
							)}
						</>
					)}
					{(periodInfo.period_type === "year" ||
						periodInfo.period_type === "month" ||
						periodInfo.period_type === "week") && (
						<>
							<td className="gerealiseerd">
								<FormattedNumberText value={total.wallet_conversation_year_count} />
							</td>
							<td className="percentage">
								<FormattedNumberText value={total.wallet_conversation_year_progress} decimalScale={1} percentage />
							</td>
							<td className="gerealiseerd">
								<FormattedNumberText value={total.rm_conversation_year_count} />
							</td>
							<td className="weekgemiddelde">
								<FormattedNumberText value={total.rm_conversation_year_weekavg} decimalScale={1} />
							</td>
						</>
					)}
				</tr>
			</tbody>
		</table>
	);
}

QuantitativeResults.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	title: PropTypes.string,
	periodInfo: PropTypes.object,
};
