import { useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import Translate from "../../../../../js/react/components/general/Translate";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import IconButton from "../../../../../js/react/components/general/IconButton";
import DeleteColumnContent from "../../../../../js/react/components/general/overview/DeleteColumnContent";

export default function InterestcategoryOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
		create_link: window.data.interestcategory_create_link,
		index_link: window.data.interestcategory_index_link,
		permissions: window.data.interestcategory_permissions,
	});

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("general.general_settings") }]} />

			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="interestcategories.plural" />
				</h1>
				{overviewData.create_link && (
					<div>
						<IconButton
							href={overviewData.create_link}
							content={uctrans("general.add_:item", {}, { item: "interestcategories.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={overviewData.index_link}
				useDragAndDrop
				modelTranslations={{
					plural: uctrans("interestcategories.plural"),
					singular: uctrans("interestcategories.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={1}
							className="draggable-overview-item-handle"
							renderHeaderContent={() => ""}
							renderCellContent={() => ""}
						/>
						<DataColumn
							width={4}
							renderHeaderContent={() => uctrans("interestcategories.fields.name")}
							renderCellContent={interestCategory => (
								<a href={interestCategory.edit_link} className="row-title link">
									{interestCategory.name}
								</a>
							)}
						/>
						{overviewData.permissions.delete && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(interestCategory, overview) =>
									interestCategory.delete_link && (
										<DeleteColumnContent onClick={() => overview.askDelete(interestCategory)} />
									)
								}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
