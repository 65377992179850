import { useState } from "react";
import { format } from "../../../../../../../js/lib/Date";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../../js/react/components/general/overview/DataColumn";
import { can, route } from "../../../../../../../js/helpers";
import Tippy from "@tippyjs/react";
import Navigation from "../Navigation";

export default function CooperationRequestOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<Navigation selectedTab="cooperation-request" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("cooperation_check.plural")}</h1>
				{can("questionnaire.cooperation_check", "create") && (
					<div>
						<IconButton
							href={route("crm.agenda-settings.cooperation-check.create")}
							content={uctrans("general.add_:item", {}, { item: "cooperation_check.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.agenda-settings.cooperation-check.index")}
				deleteRouteName="crm.agenda-settings.cooperation-check.delete"
				modelTranslations={{
					plural: trans("cooperation_check.plural"),
					singular: trans("cooperation_check.singular"),
				}}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={2}
							sortable="name"
							renderHeaderContent={() => uctrans("questionnaires.fields.name")}
							renderCellContent={questionnaire => {
								if (can("questionnaire.cooperation_check", "update")) {
									return (
										<a
											href={route("crm.agenda-settings.cooperation-check.edit", questionnaire.id)}
											className="questionnaire-title link">
											{questionnaire.name}
										</a>
									);
								} else {
									return questionnaire.name;
								}
							}}
						/>

						<DataColumn
							width={4}
							renderHeaderContent={() => uctrans("questionnaires.fields.notes")}
							renderCellContent={questionnaire => questionnaire.notes}
						/>

						<DataColumn
							width={1}
							sortable="version"
							renderHeaderContent={() => uctrans("versions.fields.version")}
							renderCellContent={questionnaire => questionnaire.version}
						/>

						<DataColumn
							width={1}
							sortable="updated_at"
							renderHeaderContent={() => uctrans("versions.fields.updated_at")}
							renderCellContent={questionnaire => format(questionnaire.updated_at, "dd-MM-y")}
						/>

						<DataColumn
							width={1}
							renderHeaderContent={() => uctrans("versions.fields.status")}
							renderCellContent={questionnaire => uctrans(`versions.status.${questionnaire.version_status}`)}
						/>

						<DataColumn
							width={1}
							renderHeaderContent={() => uctrans("general.actions")}
							renderCellContent={(questionnaire, overview) =>
								questionnaire.version_status === "1" && (
									<div className="flex justify-start">
										<a onClick={() => overview.askDelete(questionnaire)}>
											<Tippy
												placement="right"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("general.delete")}>
												<span>
													<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
												</span>
											</Tippy>
										</a>
									</div>
								)
							}
						/>
					</DataTable>
				)}
			/>
		</>
	);
}
