import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import PropTypes from "prop-types";
import { useState } from "react";
import { route } from "../../../../../js/helpers";
import { format, parse } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import Select from "../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../js/react/components/general/Translate";
import CollapsibleContent from "../general/CollapsibleContent";
import QuestionnaireCompletionAnswersForm from "./QuestionnaireCompletionAnswersForm";

export default function QuestionnaireCompletionForm(props) {
	const { readOnly } = props;

	const [formData, setFormData] = useState(cloneDeep(props.questionnaireCompletion));

	const handleStatusChange = status => {
		const newFormData = { ...formData };
		newFormData.status = status;
		setFormData(newFormData);
	};

	const handleCreatedAtChange = created_at => {
		const newFormData = { ...formData };
		newFormData.created_at = created_at;
		setFormData(newFormData);
	};

	const handleOnSuccess = response => {
		setFormData(response.data.questionnaire_completion);
		props.questionnaireCompletionChanged(response.data.questionnaire_completion);
	};

	const onQuestionnaireCompletionAnswersChanged = answers => {
		const newFormData = { ...formData };
		newFormData.answers = answers;
		setFormData(newFormData);
	};

	const backToOverview = () => {
		props.questionnaireCompletionChanged(null);
	};

	return (
		<AjaxForm
			method="PUT"
			submitUrl={route("crm.questionnaire_completions.update", {
				questionnaire: props.questionnaire.id,
				questionnaire_completion: formData.id,
			})}
			loaderText={uctrans(
				"general.:item_is_being_saved",
				{},
				{ item: "questionnaires.questionnaire_completion.singular" },
			)}
			successText={uctrans("general.saved_:item", {}, { item: "questionnaires.questionnaire_completion.singular" })}
			onSuccess={handleOnSuccess}
			data={{ ...formData }}>
			<div className="grid-container">
				<div className={props.fullscreen ? "" : "grid-3-4"}>
					<div className="min-h-screen md:flex">
						<div className="flex-1 w-2/3 mr-12">
							<div className="form-container">
								<div className="form-full mb-10">
									<div className="float-right">
										<a className="link" onClick={backToOverview}>
											{uctrans(
												"general.back_to_overview_:page",
												{},
												{ page: "questionnaires.questionnaire_completion.plural" },
											)}
										</a>
									</div>
								</div>
								<div className="form-full">
									<div className="form-1-3">
										<label htmlFor="created_at">
											<Translate content="questionnaires.questionnaire_completion.fields.created_at" />
										</label>
										<DatePicker
											initialValue={formData.created_at ? parse(formData.created_at) : null}
											showTimeSelect
											disabled={readOnly}
											onChange={date => handleCreatedAtChange(date ? format(date) : null)}
										/>
									</div>
									<div className="form-1-3">
										<label htmlFor="created_at">
											<Translate content="questionnaires.questionnaire_completion.fields.status" />
										</label>
										<Select
											placeholder={uctrans("questionnaires.questionnaire_completion.fields.status")}
											value={find(props.questionnaireCompletionStatusses, ["value", formData.status])}
											options={props.questionnaireCompletionStatusses}
											name="status"
											isClearable={false}
											disabled={readOnly}
											onChange={selectedOption => handleStatusChange(selectedOption.value)}
										/>
									</div>
								</div>

								<CollapsibleContent
									title={uctrans("questionnaires.questionnaire_completion.show_edit_answers")}
									initialCollapseState={false}>
									<QuestionnaireCompletionAnswersForm
										disabled={readOnly}
										questionnaire={props.questionnaire}
										questionnaireCompletionAnswerData={formData}
										questionnaireCompletionAnswersChanged={onQuestionnaireCompletionAnswersChanged}
									/>
								</CollapsibleContent>

								{!readOnly && (
									<div className="form-full">
										<div className="row">
											<div className="w-full md:w-1/2 lg:w-1/3 column">
												<SubmitBar
													item="questionnaires.questionnaire_completion.singular"
													addAnotherAvailable={false}
												/>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</AjaxForm>
	);
}

QuestionnaireCompletionForm.propTypes = {
	readOnly: PropTypes.bool,
	fullscreen: PropTypes.bool,
	questionnaire: PropTypes.object,
	questionnaireCompletion: PropTypes.object.isRequired,
	questionnaireCompletionChanged: PropTypes.func,
	questionnaireCompletionStatusses: PropTypes.array,
};

QuestionnaireCompletionForm.defaultProps = {
	readOnly: false,
	fullscreen: false,
};
