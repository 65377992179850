import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import LabeledTextarea from "../../../../../js/react/components/general/form/LabeledTextarea";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import ContactmomentSubjects from "../contactmoments/ContactmomentSubjects";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import CollapsibleContent from "../general/CollapsibleContent";
import TabLinks from "../general/tabs/TabLinks";
import cloneDeep from "lodash/cloneDeep";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import { route } from "../../../../../js/helpers";
import AppointmentForm from "./AppointmentForm";
import CommerialProductionBlock from "./CommerialProductionBlock";

export default function IntermediaryCommercialForm(props) {
	const [formData, setFormData] = useState(
		typeof props.companyFormData === "undefined" ? cloneDeep(window.data.company_form_data) : props.companyFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.companyStaticData === "undefined"
			? cloneDeep(window.data.company_static_data)
			: props.companyStaticData,
	);

	useEffect(() => {
		if (typeof props.companyFormData !== "undefined") {
			setFormData(props.companyFormData);
		}
	}, [props.companyFormData]);

	useEffect(() => {
		if (typeof props.companyStaticData !== "undefined") {
			setStaticData(props.companyStaticData);
		}
	}, [props.companyStaticData]);

	const onSuccess = () => {
		if (window.data.agendaNamespace) {
			Nav.go(route("crm.agenda-items.index"));
		} else {
			window.location.reload();
		}
	};

	const showAppointmentForm = () => {
		handleFormValueChange("appointmentFormIsOpen", true);
	};

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	const handleProfileQuestionnaireAnswerChange = newAnswers => {
		const newFormData = { ...formData };
		if (!newFormData.profile_questionnaire_completion) {
			newFormData.profile_questionnaire_completion = { answers: null };
		}
		newFormData.profile_questionnaire_completion.answers = newAnswers;
		setFormData(newFormData);
	};

	const getOrganisationName = () => {
		if (!formData.organisation) {
			return uctrans("intermediaries.no_organisation");
		}
		return `<a class="link" href="${route("crm.organisations.edit-administrative", {
			organisation: formData.parent_id,
		})}">${formData.organisation.name}</a>`;
	};

	const getVisitingAddress = () => {
		if (!formData.visitingAddress) {
			return uctrans("intermediaries.no_visiting_address");
		}
		return `${formData.visitingAddress.street || ""} ${formData.visitingAddress.number || ""}${
			formData.visitingAddress.addition
		}<br />
			${formData.visitingAddress.zipcode || ""} ${formData.visitingAddress.city || ""}`;
	};

	return (
		<>
			<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
			<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
			<div className="page-title-container">
				<h1 className="page-title">
					{staticData.company_name
						? uctrans(
								"general.:item1 from :item2",
								{ item2: staticData.company_name },
								{ item1: "companies.commercial_profile" },
						  )
						: uctrans(
								staticData.creating ? "general.add_:item" : "general.edit_:item",
								{},
								{ item: "intermediaries.singular" },
						  )}
				</h1>
				{window.data.agendaNamespace ? (
					<a className="link" href={route("crm.agenda-items.index")}>
						{uctrans("general.back_to_:page", {}, { page: "agendaitems.accountsupport" })}
					</a>
				) : (
					<a className="link" href={route("crm.intermediaries.index")}>
						{uctrans("general.back_to_overview_:page", {}, { page: "intermediaries.plural" })}
					</a>
				)}
			</div>
			<AjaxForm
				method={staticData.save_method}
				submitUrl={staticData.save_link}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "intermediaries.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "intermediaries.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage={staticData.creating || window.data.agendaNamespace}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<CollapsibleContent title={uctrans("intermediaries.fields.description")} initialCollapseState={false}>
							<div className="form-full">
								<LabeledTextarea
									label="intermediaries.fields.description"
									value={formData.description}
									name="description"
									onChange={e => handleFormValueChange("description", e.target.value)}
								/>
							</div>
						</CollapsibleContent>
						<CollapsibleContent title={uctrans("intermediaries.profile_subjects")} initialCollapseState={false}>
							<ContactmomentSubjects
								discussedFinance
								hideInCommercialProfileQuestions
								loanCount={formData.loan_count}
								yearSum={formData.year_sum}
								totalWallet={staticData.total_status_320}
								onContactmomentFormChange={handleFormValueChange}
								profileQuestionnaire={staticData.profile_questionnaire}
								profileQuestionnaireAnswers={
									formData.profile_questionnaire_completion ? formData.profile_questionnaire_completion.answers : []
								}
								onProfileQuestionnaireAnswerChange={newAnswers => handleProfileQuestionnaireAnswerChange(newAnswers)}
							/>
						</CollapsibleContent>
					</div>
					<div className="flex-none w-1/3 border-l pl-12">
						<div className="form-container -mr-0">
							<CollapsibleContent
								title={uctrans("intermediaries.intermediary_information")}
								initialCollapseState={false}>
								<span className="form-1-2">{uctrans("intermediaries.fields.company_number")}:</span>
								<span className="form-1-2">{formData.company_number}</span>
								<span className="form-1-2">{uctrans("intermediaries.fields.organisation")}:</span>
								<span className="form-1-2" dangerouslySetInnerHTML={{ __html: getOrganisationName() }} />
								<span className="form-1-2">{uctrans("intermediaries.fields.segment")}:</span>
								<span className="form-1-2">
									{formData.segment ? formData.segment.name : uctrans("intermediaries.no_segment")}
								</span>
								<span className="form-1-2">{uctrans("intermediaries.fields.software_package_id")}:</span>
								<span className="form-1-2">
									{formData.softwarePackage
										? formData.softwarePackage.name
										: uctrans("intermediaries.no_software_package")}
								</span>
								<span className="form-1-2">{uctrans("intermediaries.fields.phonenumber")}:</span>
								<span className="form-1-2">{formData.phonenumber ? formData.phonenumber : "-"}</span>
								<span className="form-1-2">{uctrans("addresses.fields.visiting_address")}:</span>
								<span className="form-1-2" dangerouslySetInnerHTML={{ __html: getVisitingAddress() }} />
							</CollapsibleContent>

							<CollapsibleContent title={uctrans("intermediaries.contact_information")} initialCollapseState={false}>
								<span className="form-1-2">{uctrans("intermediaries.fields.last_contactmoment")}:</span>
								<span className="form-1-2">{formData.last_contactmoment ? formData.last_contactmoment : "-"}</span>
								<span className="form-full">
									<a className="button button-primary mr-6" onClick={showAppointmentForm} rel="noopener noreferrer">
										{uctrans("intermediaries.appointment.make_appointment")}
									</a>
								</span>
							</CollapsibleContent>

							<CollapsibleContent title={uctrans("intermediaries.production")} initialCollapseState={false}>
								<CommerialProductionBlock companyId={formData.id} />
							</CollapsibleContent>
						</div>
					</div>
				</div>
				<div className="form-full">
					<div className="row">
						<div className="w-full md:w-1/2 lg:w-1/3 column">
							<SubmitBar item="intermediaries.singular" addAnotherAvailable={false} />
						</div>
					</div>
				</div>
				<AppointmentForm
					companyName={formData.name}
					companyId={formData.id}
					isOpen={!!formData.appointmentFormIsOpen}
					selectableContactpersons={formData.contactpersons}
					dialogClosedItself={() => {
						handleFormValueChange("appointmentFormIsOpen", false);
					}}
				/>
			</AjaxForm>
		</>
	);
}
IntermediaryCommercialForm.propTypes = {
	companyFormData: PropTypes.object,
	companyStaticData: PropTypes.object,
};
