import PropTypes from "prop-types";
import { useEffect } from "react";
import SelectField from "../../../../../../../js/react/components/general/Select";

export default function Select(props) {
	let selectedValue = props.options.find(({ value }) => value === props.value);
	let autoSelected = false;
	if (!selectedValue) {
		selectedValue = props.options.find(() => true);
		autoSelected = true;
	}

	const { onChange } = props;

	useEffect(() => {
		if (autoSelected) {
			setTimeout(() => {
				onChange(selectedValue.value);
			}, 0);
		}
	}, [autoSelected, onChange, selectedValue]);

	return (
		<>
			{props.label && <label>{props.label}</label>}
			<SelectField
				value={selectedValue}
				options={props.options}
				onChange={selected => props.onChange(selected.value)}
				isClearable={false}
			/>
		</>
	);
}

Select.propTypes = {
	label: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
			label: PropTypes.string.isRequired,
		}),
	).isRequired,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func,
};
