import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import Dialog from "../../../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import PartContent from "./PartContent";
import PartListLabel from "../part-lists/PartListLabel";

export default function ClearablePart(props) {
	const [partValues, setPartValues] = useState(props.values ? props.values : {});
	const [displayDialog, setDisplayDialog] = useState(false);
	const [dialogValues, setDialogValues] = useState(null);
	useEffect(() => {
		setPartValues(props.values ? props.values : {});
		setDialogValues(null);
	}, [props.values]);

	const openEditDialog = () => {
		setDisplayDialog(true);
		setDialogValues(cloneDeep(partValues));
	};

	const clearItem = () => {
		setPartValues(null);
		setDialogValues(null);
		props.onChange(null);
	};

	const handleDialogSave = () => {
		const newPartValues = cloneDeep(dialogValues);
		setPartValues(newPartValues);
		setDisplayDialog(false);
		setDialogValues(null);
		props.onChange(newPartValues);
	};
	const handleDialogCancel = () => {
		setDisplayDialog(false);
		setDialogValues(null);
	};

	const onDialogChange = newValues => {
		setDialogValues(newValues);
	};

	return (
		<>
			{props.label && <label>{props.label}</label>}
			<div className="bg-primary-lightest items-center px-3 py-3 my-2 flex justify-between">
				<div className="flex justify-between w-full items-center">
					<div className="w-11/12 break-all">
						<PartListLabel values={partValues} parttype={props.parttype} />
					</div>
					<a
						onClick={() => {
							openEditDialog();
						}}>
						<EvaIcon className="flex items-center" type="edit-outline" width="20" height="20" fill="#009286" />
					</a>
				</div>
				{partValues && Object.entries(partValues).length > 0 && (
					<div className="list-item-controls flex justify-between ml-3">
						<span
							onClick={() => {
								clearItem();
							}}>
							<EvaIcon className="flex items-center" type="trash-2-outline" width="20" height="20" fill="#009286" />
						</span>
					</div>
				)}
			</div>

			<Dialog
				isOpen={displayDialog}
				onClose={handleDialogCancel}
				shouldCloseOnOverlayClick={false}
				width={850}
				title={props.label ? props.label : ""}>
				<PartContent parttype={props.parttype} onChange={onDialogChange} values={dialogValues} />
				<div className="mt-6">
					<button type="button" className="button button-primary button-dialog" onClick={handleDialogSave}>
						{uctrans("general.ok")}
					</button>
					<a onClick={handleDialogCancel}>{uctrans("general.cancel")}</a>
				</div>
			</Dialog>
		</>
	);
}

ClearablePart.propTypes = {
	label: PropTypes.string,
	parttype: PropTypes.string.isRequired,
	values: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func.isRequired,
};
