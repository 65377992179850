import PropTypes from "prop-types";
import { uctrans } from "../../../../../js/lib/Translator";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";

const ContactpersonSelector = props => {
	const onChangeHandler = (contactpersonId, checked) => {
		const newSelectedContactpersonIds = [...props.selectedContactpersonIds];
		const contactpersonIdIndex = newSelectedContactpersonIds.indexOf(contactpersonId);
		if (checked && contactpersonIdIndex === -1) {
			newSelectedContactpersonIds.push(contactpersonId);
		} else {
			if (contactpersonIdIndex !== -1) {
				newSelectedContactpersonIds.splice(contactpersonIdIndex, 1);
			}
		}
		props.onSelectedContactpersonIdsChange(newSelectedContactpersonIds);
	};

	return (
		<div className="mb-10">
			{props.contactpersons && props.contactpersons.length > 0 && props.showLabel && (
				<label>
					{uctrans("contactmoments.fields.contactpersons")}
					{props.required && <span className="text-orange"> *</span>}
				</label>
			)}
			<div className="flow-root">
				{props.contactpersons &&
					props.contactpersons.map(contactperson => (
						<div key={contactperson.id} className="float-left w-1/2">
							<Checkmark
								label={contactperson.name}
								checked={props.selectedContactpersonIds.includes(contactperson.id)}
								onChecked={() => onChangeHandler(contactperson.id, 1)}
								onUnchecked={() => onChangeHandler(contactperson.id, 0)}
							/>
						</div>
					))}
			</div>
		</div>
	);
};

ContactpersonSelector.propTypes = {
	selectedContactpersonIds: PropTypes.array,
	contactpersons: PropTypes.array,
	onSelectedContactpersonIdsChange: PropTypes.func,
	required: PropTypes.bool,
	showLabel: PropTypes.bool,
};

ContactpersonSelector.defaultProps = {
	required: true,
	showLabel: true,
};

export default ContactpersonSelector;
