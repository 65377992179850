import Tippy from "@tippyjs/react";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import { can, route } from "../../../../../js/helpers";

import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";

export default function ClientTreatmentBudgetYearOverview() {
	const overviewData = { ...window.data.overview };

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("client_treatment.budget_years.plural") }]} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("client_treatment.budget_years.plural")}</h1>
				{can("client_treatment.client_treatment_budget_year", "create") && (
					<div>
						<IconButton
							href={route("crm.client-treatment-budget-years.create")}
							content={uctrans("general.add_:item", {}, { item: "client_treatment.budget_years.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.client-treatment-budget-years.index")}
				deleteRouteName="crm.client-treatment-budget-years.delete"
				modelTranslations={{
					plural: trans("client_treatment.budget_years.plural"),
					singular: trans("client_treatment.budget_years.singular"),
				}}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							sortable="year"
							renderHeaderContent={() => uctrans("client_treatment.budget_years.fields.year")}
							renderCellContent={clientTreatmentBudgetYear => {
								if (can("client_treatment.client_treatment_budget_year", "update")) {
									return (
										<a
											href={route("crm.client-treatment-budget-years.edit", clientTreatmentBudgetYear.id)}
											className="row-title link">
											{clientTreatmentBudgetYear.year}
										</a>
									);
								} else if (can("client_treatment.client_treatment_budget_year", "view")) {
									return (
										<a
											href={route("crm.client-treatment-budget-years.show", clientTreatmentBudgetYear.id)}
											className="row-title link">
											{clientTreatmentBudgetYear.year}
										</a>
									);
								} else {
									return clientTreatmentBudgetYear.year;
								}
							}}
						/>
						{(can("client_treatment.client_treatment_budget_year", "delete") ||
							can("client_treatment.client_treatment_budget_year", "create")) && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(clientTreatmentBudgetYear, overview) => (
									<div className="overview-actions">
										{can("client_treatment.client_treatment_budget_year", "delete") && (
											<a
												onClick={() => {
													overview.askDelete(clientTreatmentBudgetYear);
												}}>
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("general.delete")}>
													<span>
														<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
													</span>
												</Tippy>
											</a>
										)}
									</div>
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
