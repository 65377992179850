import { uctrans } from "../../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../../js/react/components/general/abn-overview/AbnOverview";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import DataTable from "../../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../../js/react/components/general/overview/DataColumn";
import { can, route } from "../../../../../../../js/helpers";
import Filters from "../../../../../../../js/react/components/general/overview/Filters";
import SelectFilter from "../../../../../../../js/react/components/general/overview/SelectFilter";
import Navigation from "../Navigation";

export default function AfmPermitOverview() {
	const overviewData = { ...window.data.overview };

	return (
		<>
			<Navigation selectedTab="afm_permits" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("afm_permits.plural")}</h1>
			</div>

			<AbnOverview
				initialData={overviewData}
				indexUrl={route("crm.agenda-settings.afm-permits.index")}
				modelTranslations={{
					plural: uctrans("afm_permits.plural"),
					singular: uctrans("afm_permits.singular"),
				}}
				renderFilters={overview => (
					<Filters>
						<SelectFilter
							className="form-1-2"
							name="afm_product"
							label={uctrans("afm_permits.fields.afm_products")}
							options={overview.data.filterdata.afm_products}
						/>
						<SelectFilter
							className="form-1-2"
							name="afm_service"
							label={uctrans("afm_permits.fields.afm_services")}
							options={overview.data.filterdata.afm_services}
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={5}
							sortable="afm_product"
							renderHeaderContent={() => uctrans("afm_permits.fields.afm_product")}
							renderCellContent={item =>
								item.afm_product && item.afm_product.label !== null ? item.afm_product.label : "-"
							}
						/>
						<DataColumn
							width={5}
							sortable="afm_service"
							renderHeaderContent={() => uctrans("afm_permits.fields.afm_service")}
							renderCellContent={item =>
								item.afm_service && item.afm_service.label !== null ? item.afm_service.label : "-"
							}
						/>
						<DataColumn
							width={5}
							sortable="required_type"
							renderHeaderContent={() => uctrans("afm_permits.fields.required_type")}
							renderCellContent={item => (item.required_type ? item.required_type : "-")}
						/>
						{can("agenda.settings.afm_permit", "update") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={item => (
									<a href={route("crm.agenda-settings.afm-permits.edit", item.id)} className="row-title link">
										<span>
											<EvaIcon type="edit-outline" width="24" height="24" fill="#009286" />
										</span>
									</a>
								)}
							/>
						)}
						{can("agenda.settings.afm_permit", "update") && (
							<DataColumn width={1} renderHeaderContent={() => ""} renderCellContent={() => ""} />
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
