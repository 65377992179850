import PropTypes from "prop-types";
import { uctrans } from "../../../../../js/lib/Translator";
import { format } from "../../../../../js/lib/Date";

export default function LastUpdatesBlock({ lastUpdates }) {
	return (
		<div className="bg-grey-lightest shadow rounded flex flex-col w-full">
			<div className="shadow rounded-t-lg flex">
				<div className="px-6 py-6 mr-auto">
					<h5 className="mb-0">{uctrans("dashboard.last_updates")}</h5>
				</div>
			</div>
			<div className="p-6 flex">
				<div className="w-full">
					<table className="table text-xs">
						<tbody>
							{lastUpdates.map(type => (
								<tr key={`type_${type.key}`}>
									<td className="text-left">{type.label}</td>
									<td className="text-left">
										{type.lastSuccesfullImport
											? uctrans("general.:date_at_:time", {
													date: format(type.lastSuccesfullImport, "dd-MM-y"),
													time: format(type.lastSuccesfullImport, "HH:mm"),
											  })
											: "-"}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

LastUpdatesBlock.propTypes = {
	lastUpdates: PropTypes.array,
};

LastUpdatesBlock.defaultProps = {
	lastUpdates: [],
};
