import Tippy from "@tippyjs/react";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import { can, route } from "../../../../../js/helpers";

import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";

export default function TargetYearOverview() {
	const overviewData = { ...window.data.overview };

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("targets.plural") }]} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("targets.target_year.plural")}</h1>
				{can("targets.target_year", "create") && (
					<div>
						<IconButton
							href={route("crm.targets.target-years.create")}
							content={uctrans("general.add_:item", {}, { item: "targets.target_year.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.targets.target-years.index.json")}
				deleteRouteName="crm.targets.target-years.delete"
				modelTranslations={{
					plural: trans("targets.target_year.plural"),
					singular: trans("targets.target_year.singular"),
				}}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							sortable="year"
							renderHeaderContent={() => uctrans("targets.target_year.fields.year")}
							renderCellContent={targetYear => {
								if (can("targets.target_year", "update")) {
									return (
										<a href={route("crm.targets.target-years.edit", targetYear.id)} className="row-title link">
											{targetYear.year}
										</a>
									);
								} else if (can("targets.target_year", "view")) {
									return (
										<a href={route("crm.targets.target-years.show", targetYear.id)} className="row-title link">
											{targetYear.year}
										</a>
									);
								} else {
									return targetYear.year;
								}
							}}
						/>
						{(can("targets.target_year", "delete") || can("targets.target_year", "create")) && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(targetYear, overview) => (
									<div className="overview-actions">
										{can("targets.targetYear", "delete") && (
											<a
												onClick={() => {
													overview.askDelete(targetYear);
												}}>
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("general.delete")}>
													<span>
														<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
													</span>
												</Tippy>
											</a>
										)}
									</div>
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
