import Tippy from "@tippyjs/react";
import { useState } from "react";
import { can, route } from "../../../../../../js/helpers";
import { format } from "../../../../../../js/lib/Date";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import Dialog from "../../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import SelectFilter from "../../../../../../js/react/components/general/overview/SelectFilter";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../../js/react/components/general/overview/SearchBarFilter";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../../general/tabs/TabLinks";

export default function CommunicationLogOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});
	const [currentWebviewCommunicationLog, setCurrentWebviewCommunicationLog] = useState(null);

	return (
		<>
			{window.data.nav_data && (
				<>
					<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
					<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
				</>
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.company_name
						? uctrans(
								"general.:item1 from :item2",
								{ item2: window.data.company_name },
								{ item1: "communication.communication_log.singular" },
						  )
						: uctrans("communication.communication_log.singular")}
				</h1>
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={window.data.index_url}
				modelTranslations={{
					plural: trans("communication.communication_log.plural"),
					singular: trans("communication.communication_log.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
						<SelectFilter
							className="form-1-3"
							name="type"
							label={uctrans("communication.communication_log.fields.type")}
							options={overviewData.overview.metadata.filterdata.communication_types}
							collapsible
						/>
						<SelectFilter
							className="form-1-3"
							name="receiver_type"
							label={uctrans("communication.communication_log.fields.receiver_type")}
							options={overviewData.overview.metadata.filterdata.receiver_types}
							collapsible
						/>
						<SelectFilter
							className="form-1-3"
							name="last_flowmailer_status_id"
							label={uctrans("communication.communication_log.fields.last_flowmailer_status_id")}
							options={overviewData.overview.metadata.filterdata.event_types}
							collapsible
						/>

						{overviewData.overview.metadata.filterdata.contactpersons && (
							<SelectFilter
								className="form-1-3"
								name="contactperson_id"
								label={uctrans("contactpersons.singular")}
								options={overviewData.overview.metadata.filterdata.contactpersons}
								collapsible
							/>
						)}
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("communication.communication_log.fields.datetime")}
							renderCellContent={log => (log.when ? format(log.when, "dd-MM-y HH:mm") : null)}
							sortable="when"
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("communication.communication_log.fields.receiver_type")}
							renderCellContent={log => log.receiver_type}
							sortable="object_type"
						/>
						<DataColumn
							width={5}
							renderHeaderContent={() => uctrans("communication.communication_log.fields.email")}
							renderCellContent={log => (log.email_address ? log.email_address : "")}
							sortable="email_address"
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("communication.communication_log.fields.type")}
							renderCellContent={log => log.type}
							sortable="communication_type"
						/>
						<DataColumn
							width={5}
							renderHeaderContent={() => uctrans("communication.communication_log.fields.title")}
							renderCellContent={log => (log.title ? log.title : "")}
							sortable="title"
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("communication.communication_log.fields.last_flowmailer_status_id")}
							renderCellContent={log => (
								<div>
									{log.last_flowmailer_status_id && log.last_event_hint ? (
										<Tippy placement="top" arrow animation="perspective" duration="400" content={log.last_event_hint}>
											<span>
												{log.last_flowmailer_status_id}
												<EvaIcon type="info" fill="#249286" height="16" width="16" />
											</span>
										</Tippy>
									) : (
										"-"
									)}
								</div>
							)}
							sortable="last_flowmailer_status_id"
						/>
						<DataColumn
							width={1}
							renderHeaderContent={() => ""}
							renderCellContent={log => (
								<div className="flex">
									{can("communication.communication_log", "view_all") && (
										<a
											rel="noopener noreferrer"
											className="mr-2"
											onClick={() => {
												setCurrentWebviewCommunicationLog(log);
											}}
											target="_blank">
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("communication.automated_messages.view_on_website")}>
												<span>
													<EvaIcon type="eye-outline" fill="#249286" height="18" width="18" />
												</span>
											</Tippy>
										</a>
									)}
								</div>
							)}
						/>
					</DataTable>
				)}
			/>
			<Dialog
				isOpen={!!currentWebviewCommunicationLog}
				onClose={() => {
					setCurrentWebviewCommunicationLog(null);
				}}
				shouldCloseOnOverlayClick
				width={850}>
				{!!currentWebviewCommunicationLog && (
					<>
						<IconButton
							className="mb-3 float-right"
							href={route("crm.communication-log.webview.pdf", currentWebviewCommunicationLog.id_token)}
							content={uctrans("contactmoments.download_pdf")}
							icon="download"
							primary
						/>
						<iframe
							id="mail_iframe"
							width="100%"
							height="500px"
							src={route("crm.communication-log.webview", currentWebviewCommunicationLog.id_token)}
						/>
					</>
				)}
			</Dialog>
		</>
	);
}
