import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Tippy from "@tippyjs/react";
import { uctrans } from "../../../lib/Translator";
import EvaIcon from "../general/EvaIcon";
import Checkmark from "../general/form/checkmark/Checkmark";

export default function NotificationPreferences(props) {
	const [preferences, setPreferences] = useState(props.preferences);

	const isChecked = (type, medium) => !!(preferences && preferences[type] && preferences[type][medium]);
	const changePreference = (type, medium, value) => {
		const newPreferences = { ...preferences };

		if (!newPreferences[type]) {
			newPreferences[type] = {};
		}

		newPreferences[type][medium] = value;

		setPreferences(newPreferences);
		props.onChange(newPreferences);
	};

	useEffect(() => {
		if (typeof props.preferences !== "undefined") {
			setPreferences(props.preferences);
		}
	}, [props.preferences]);

	return props.notifictionTypesPerCategory.map((category, categoryIndex) => (
		<div key={categoryIndex}>
			<h5>{category.label}</h5>
			<div>
				{category.types.map((type, typeIndex) => (
					<div key={typeIndex} className="flex justify-start">
						<div className="w-1/2 mr-2">
							<span>{type.label}</span>
						</div>
						<div className="w-8 mr-2">
							{type.description || type.description.length !== 0 ? (
								<Tippy
									placement="top"
									arrow
									animation="perspective"
									duration="400"
									content={<span dangerouslySetInnerHTML={{ __html: type.description }} />}>
									<span>
										<EvaIcon type="info-outline" fill="#249286" height="18" width="18" />
									</span>
								</Tippy>
							) : null}
						</div>
						<div className="mr-2">
							<Checkmark
								label={uctrans("notifications.preferences.fields.dashboard")}
								value={1}
								checked={isChecked(type.key, "dashboard")}
								onChange={e => {
									changePreference(type.key, "dashboard", e.target.checked);
								}}
								disabled={props.disabled}
							/>
						</div>
						<div>
							<Checkmark
								label={uctrans("notifications.preferences.fields.mail")}
								value={1}
								checked={isChecked(type.key, "mail")}
								onChange={e => {
									changePreference(type.key, "mail", e.target.checked);
								}}
								disabled={props.disabled}
							/>
						</div>
					</div>
				))}
			</div>
		</div>
	));
}

NotificationPreferences.proptypes = {
	notifictionTypesPerCategory: PropTypes.array.isRequired,
	preferences: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};
NotificationPreferences.defaultProps = {
	disabled: false,
};
