import PropTypes from "prop-types";
import { createLoader } from "../../../../../js/react/components/general/notifications";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import cloneDeep from "lodash/cloneDeep";
import findIndex from "lodash/findIndex";
import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Dialog from "../../../../../js/react/components/general/Dialog";
import DeleteDialog from "../../../../../js/react/components/general/DeleteDialog";
import Navigation from "../agenda/settings/Navigation";
import TextEditor from "../forms/textEditor/TextEditor";
import QuestionList from "../questionnaires/QuestionList";
import PageForm from "../questionnaires/PageForm";
import { route } from "../../../../../js/helpers";
import QuestionsCopyDialog from "./QuestionsCopyDialog";
import classNames from "classnames";
import axios from "axios";

export default function QuestionnaireQuestionForm(props) {
	const [formData, setFormData] = useState(
		typeof props.questionnaireFormData === "undefined"
			? cloneDeep(window.data.questionnaire_question_form_data)
			: props.questionnaireFormData,
	);

	const [showDeletePageDialog, setShowDeletePageDialog] = useState(false);
	const [showEditPageDialog, setShowEditPageDialog] = useState(false);
	const [showAddPageDialog, setShowAddPageDialog] = useState(false);
	const [showQuestionsCopyDialog, setShowQuestionsCopyDialog] = useState(false);
	const [currentPage, setCurrentPage] = useState({});
	const [currentPageIndex, setCurrentPageIndex] = useState(
		window.data.questionnaire_question_form_data.pages
			? window.data.questionnaire_question_form_data.pages.length - 1
			: 0,
	);
	const [currentPageDeleteData] = useState({
		errors: [],
		warnings: [{ text: uctrans("questionnaires.page.delete_warning") }],
	});

	const staticData = window.data.questionnaire_static_data;
	const formReference = useRef(null);

	const frontEndUrl = useMemo(() => {
		if (staticData.cooperation_check) {
			return route("website.cooperation-request");
		}
		return formData.front_end_url;
	}, [formData.front_end_url, staticData.cooperation_check]);

	const openDeletePageDialog = (page, index) => {
		setShowDeletePageDialog(true);
		setCurrentPage(page);
		setCurrentPageIndex(index);
	};

	const openEditPageDialog = (page, index) => {
		setShowEditPageDialog(true);
		setCurrentPage(page);
		setCurrentPageIndex(index);
	};

	const openAddPageDialog = () => {
		setShowAddPageDialog(true);
		setCurrentPage({
			id: null,
			title: uctrans("questionnaires.page.page_:number", { number: currentPageIndex + 2 }),
		});
	};

	const handleFormChangeAndSave = (attributeKey, values) => {
		handleFormChange(attributeKey, values, true);
	};

	const handleFormChange = (attributeKey, values, save = false) => {
		const newFormData = { ...formData };
		newFormData[attributeKey] = values;
		if (save) {
			newFormData.save = true;
		}
		setFormData(newFormData);
	};

	const closePageDialog = () => {
		setShowDeletePageDialog(false);
		setShowEditPageDialog(false);
		setShowAddPageDialog(false);
	};

	useEffect(() => {
		if (formData.save) {
			formReference.current?.handleForm();

			setFormData({
				...formData,
				save: false,
			});
		}
	}, [formData]);

	const handlePageDeleteConfirm = () => {
		const newFormData = { ...formData };
		const { pages } = newFormData;
		pages.splice(currentPageIndex, 1);
		newFormData.pages = pages;
		newFormData.save = true;
		setFormData(newFormData);
		closePageDialog();
	};

	const handleOnSuccess = response => {
		if (staticData.creating && response && response.data && response.data.success) {
			window.location.replace(response.data.redirect_route);
		}
	};

	const handlePageSubmit = page => {
		const indexPageThatWasEdited = findIndex(formData.pages, p => p.id === page.id);
		if (indexPageThatWasEdited !== -1) {
			formData.pages.splice(currentPageIndex, 1, page);
		} else {
			// page is new
			formData.pages.push(page);
			setCurrentPageIndex(currentPageIndex + 1);
		}
		closePageDialog();
	};

	const openQuestionsCopyDialog = () => {
		setShowQuestionsCopyDialog(true);
	};

	const closeQuestionsCopyDialog = () => {
		setShowQuestionsCopyDialog(false);
	};

	const handleQuestionsCopyConfirm = () => {
		window.onbeforeunload = null;
		window.location.reload();
	};

	const handleQuestionsCopyFailed = () => {
		window.onbeforeunload = null;
		window.location.reload();
	};

	const movePage = (page, index, amount) => {
		const newFormData = { ...formData };
		const { pages } = newFormData;

		const newIndex = index + amount;
		if (newIndex < pages.length && pages[newIndex]) {
			const temp = pages[newIndex];
			pages[newIndex] = page;
			pages[index] = temp;
		}

		newFormData.pages = pages;
		setFormData(newFormData);
	};

	const showCopyQuestionDialog = type => {
		if (type === staticData.questionnaire_types.CUSTOM.key || type === staticData.questionnaire_types.SURVEY.key) {
			return true;
		}
		return false;
	};

	const getSubmitUrl = () => {
		if (staticData.creating) {
			return route("crm.questionnaires.store");
		}
		return route("crm.questionnaires.update-questionlist", { questionnaire: formData.id });
	};

	const handlePublish = useCallback(
		async event => {
			event.preventDefault();

			const loader = createLoader(uctrans("versions.publish.pending", { item: trans("cooperation_check.singular") }));
			try {
				const response = await axios.put(staticData.publish_url);
				loader.success(uctrans("versions.publish.success", { item: trans("cooperation_check.singular") }));

				if (response.data && response.data.success && response.data.redirect_route) {
					window.location.replace(response.data.redirect_route);
				}
			} catch (e) {
				console.error(e);
				loader.failure(uctrans("versions.publish.failure", { item: trans("cooperation_check.singular") }));
			}
		},
		[staticData.publish_url],
	);

	return (
		<>
			{staticData.cooperation_check && <Navigation selectedTab="cooperation-request" />}
			{staticData.annually_cooperation_check && <Navigation selectedTab="annually-cooperation-check" />}
			{(staticData.cooperation_check || staticData.annually_cooperation_check) && (
				<h1>{`${
					staticData.cooperation_check
						? uctrans("cooperation_check.singular")
						: uctrans("cooperation_check.annually_cooperation_check")
				} V${formData.version.version}`}</h1>
			)}

			<AjaxForm
				method={staticData.creating ? "POST" : "PUT"}
				ref={formReference}
				submitUrl={getSubmitUrl()}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "questionnaires.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "questionnaires.singular" })}
				onSuccess={handleOnSuccess}
				data={{ ...formData }}
				useFlashMessage={staticData.creating}>
				<div className="grid-container">
					{!staticData.creating && (
						<div className="float-right">
							{showCopyQuestionDialog(formData.type) && (
								<>
									<a className="button button-primary mr-6" onClick={openQuestionsCopyDialog} rel="noopener noreferrer">
										{uctrans("questionnaires.button_copy_questions")}
									</a>
									<br />
									<QuestionsCopyDialog
										onConfirmQuestionsCopyDialog={handleQuestionsCopyConfirm}
										onFailedQuestionsCopyDialog={handleQuestionsCopyFailed}
										onCloseQuestionsCopyDialog={closeQuestionsCopyDialog}
										showQuestionsCopyDialog={showQuestionsCopyDialog}
										targetQuestionnaireId={formData.id}
									/>
								</>
							)}
							{!props.hideUrlButton && (
								<a className="button button-primary mr-6" href={frontEndUrl} target="_blank" rel="noopener noreferrer">
									{uctrans("questionnaires.questionnaire_front_url")}
								</a>
							)}
						</div>
					)}
					<div className="grid-3-3">
						<div className="min-h-screen md:flex">
							<div className="flex-1 w-2/3 mr-12">
								{!props.hideNotes && (
									<div className="form-container">
										<div className="row">
											<label htmlFor="notes">{uctrans("cooperation_check.fields.notes")}</label>
											<TextEditor
												value={formData.notes}
												onChange={content => handleFormChange("notes", content)}
												hideToolbar
											/>
										</div>
									</div>
								)}

								<div className="form-container">
									{!staticData.creating && (
										<>
											{Number(formData.type) === Number(staticData.questionnaire_types.RELATIONMANAGER_SURVEY.key) && (
												<>
													<div className="form-full">
														<h4 className="inline-block">{uctrans("questionnaires.fields.name")}</h4>
														<input
															type="text"
															value={formData.name}
															onChange={e => handleFormChange("name", e.target.value)}
														/>
													</div>
													{!!props.changeDescription && (
														<div className="form-full">
															<h4 className="inline-block">{uctrans("questionnaires.fields.description")}</h4>
															<TextEditor
																value={formData.description}
																onChange={selectedValue => handleFormChange("description", selectedValue)}
															/>
														</div>
													)}

													<div className="form-full">
														<h4 className="inline-block">{uctrans("questionnaires.fields.start_button_text")}</h4>
														<input
															type="text"
															value={formData.start_button_text}
															onChange={e => handleFormChange("start_button_text", e.target.value)}
														/>
													</div>
												</>
											)}

											<div className="form-full mt-5">
												<QuestionList
													questions={formData.questions}
													pages={formData.pages}
													questionnaireType={formData.type}
													questionTypes={staticData.question_types}
													dependencyTypes={staticData.dependency_types}
													questionColors={staticData.question_colors}
													administrativeOptions={staticData.administrative_options}
													questionFieldTypes={staticData.question_field_types}
													questionnaireTypes={staticData.questionnaire_types}
													annuallyCooperationCheckTags={staticData.annually_cooperation_check_tags}
													relationManagerQuestionnaireTags={staticData.relation_manager_questionnaire_tags}
													onQuestionsChange={questions => handleFormChangeAndSave("questions", questions)}
													onPagesChange={pages => handleFormChangeAndSave("pages", pages)}
													openEditPageDialog={openEditPageDialog}
													openDeletePageDialog={openDeletePageDialog}
													baseQuestionStoreUrl={route("crm.questionnaires.question.store", {
														questionnaire: formData.id,
													}).toString()}
													movePage={movePage}
												/>
											</div>

											<div className="form-full">
												<a className="add-page-button button button-secondary" onClick={() => openAddPageDialog()}>
													{uctrans("questionnaires.add_page")}
												</a>
											</div>
										</>
									)}
								</div>

								<div className="form-container">
									<div className="row">
										<div className="w-full md:w-1/2 lg:w-1/3 column">
											<SubmitBar item="questionnaires.singular" addAnotherAvailable={false} />
											<button
												className={classNames("button background-color-success button-primary mr-5", {
													disabled: formData.version && formData.version.status !== 1,
												})}
												onClick={handlePublish}>
												{uctrans("versions.publish.action")}
											</button>
										</div>
									</div>
								</div>

								<Dialog
									isOpen={showAddPageDialog || showEditPageDialog}
									onClose={closePageDialog}
									shouldCloseOnOverlayClick={false}
									className={currentPage.id === null ? "page_add_dialog" : "page_edit_dialog"}
									width={850}
									title={uctrans(currentPage.id === null ? "questionnaires.add_page" : "questionnaires.edit_page")}>
									<PageForm
										formType={currentPage.id === null ? "add" : "edit"}
										page={currentPage}
										onCloseDialog={closePageDialog}
										basePageStoreUrl={staticData.base_page_store_url}
										onPageSubmit={page => handlePageSubmit(page)}
										isProfilePage={formData.type === staticData.questionnaire_types.PROFILE.key}
									/>
								</Dialog>

								<DeleteDialog
									onConfirm={() => handlePageDeleteConfirm(currentPage)}
									onCancel={closePageDialog}
									isOpen={showDeletePageDialog}
									deleteData={currentPageDeleteData}
								/>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}

QuestionnaireQuestionForm.propTypes = {
	questionnaireFormData: PropTypes.object,
	changeDescription: PropTypes.bool,
	hideUrlButton: PropTypes.bool,
	hideNotes: PropTypes.bool,
};

QuestionnaireQuestionForm.defaultProps = {
	changeDescription: false,
	hideUrlButton: false,
	hideNotes: window.data?.questionnaire_static_data?.hide_notes ?? true,
};
