import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { format, parse } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import Translate from "../../../../../js/react/components/general/Translate";
import cloneDeep from "lodash/cloneDeep";
import Select from "../../../../../js/react/components/general/Select";

export default function AgendaItemFormFields(props) {
	const [formData, setFormData] = useState(
		typeof props.agendaItemFormData === "undefined"
			? cloneDeep(window.data.agenda_item_form_data)
			: props.agendaItemFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.agendaItemStaticData === "undefined"
			? cloneDeep(window.data.agenda_item_static_data)
			: props.agendaItemStaticData,
	);

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
		props.onChange(newFormData);
	};

	useEffect(() => {
		if (typeof props.agendaItemFormData !== "undefined") {
			setFormData(props.agendaItemFormData);
		}
	}, [props.agendaItemFormData]);

	useEffect(() => {
		if (typeof props.agendaItemStaticData !== "undefined") {
			setStaticData(props.agendaItemStaticData);
		}
	}, [props.agendaItemStaticData]);

	return (
		<div className="form-full">
			<div className="form-1-2">
				<label htmlFor="user_id">
					<Translate content="agendaitems.fields.requested_by" />
					<span className="text-orange"> *</span>
				</label>
				<Select
					placeholder={uctrans("general.select_:item", {}, { item: "agendaitems.fields.user" })}
					value={staticData.users.filter(({ value }) => Number(value) === Number(formData.user_id))}
					options={staticData.users}
					name="user_id"
					onChange={value => handleFormValueChange("user_id", value ? value.value : null)}
					disabled={!props.editable}
				/>
			</div>
			{props.showAgendaDate && props.editable && (
				<div className="form-1-4">
					<label htmlFor="agenda_date">
						<Translate content="agendaitems.fields.agenda_date" />
						<span className="text-orange"> *</span>
					</label>
					<DatePicker
						initialValue={formData.agenda_date ? parse(formData.agenda_date) : null}
						onChange={date => handleFormValueChange("agenda_date", date ? format(date) : null)}
					/>
				</div>
			)}
		</div>
	);
}

AgendaItemFormFields.propTypes = {
	agendaItemFormData: PropTypes.object,
	agendaItemStaticData: PropTypes.object,
	editable: PropTypes.bool,
	showAgendaDate: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

AgendaItemFormFields.defaultProps = {
	editable: true,
	showAgendaDate: false,
};
