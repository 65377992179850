import { useState } from "react";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route } from "../../../../../../js/helpers";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import Tippy from "@tippyjs/react";

export default function DefinitionOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("general.cms") }]} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("cms.definitions.plural")}</h1>
				{can("cms.definition", "create") && (
					<div>
						<IconButton
							href={route("crm.cms.definitions.create")}
							content={uctrans("general.add_:item", {}, { item: "cms.definitions.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.cms.definitions.index")}
				deleteRouteName="crm.cms.definitions.delete"
				modelTranslations={{
					plural: trans("cms.definitions.plural"),
					singular: trans("cms.definitions.singular"),
				}}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={10}
							renderHeaderContent={() => uctrans("cms.definitions.fields.name")}
							renderCellContent={definition => {
								if (can("cms.definition", "update")) {
									return (
										<a href={route("crm.cms.definitions.edit", definition.id)} className="row-title link">
											{definition.name}
										</a>
									);
								} else {
									return definition.name;
								}
							}}
						/>

						<DataColumn
							width={1}
							renderHeaderContent={() => ""}
							renderCellContent={definition => (
								<Tippy
									placement="auto"
									arrow
									interactive
									content={<span dangerouslySetInnerHTML={{ __html: definition.content }} />}>
									<span>
										<EvaIcon type="info" fill="#249286" height="20" width="20" />
									</span>
								</Tippy>
							)}
						/>

						{can("cms.definition", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(status, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(status)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
