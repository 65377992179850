import axios from "axios";
import { useState, useEffect } from "react";
import { format, parse } from "../../../../../js/lib/Date";
import Nav from "../../../../../js/lib/Nav";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import DebouncedInput from "../../../../../js/react/components/general/form/DebouncedInput";
import LabeledAutosizeTextarea from "../../../../../js/react/components/general/form/LabeledAutosizeTextarea";
import Select from "../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Translate from "../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../js/react/hooks/useGenericHandler";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import { route } from "../../../../../js/helpers";
import useSelectHandler from "../../../../../js/react/hooks/useSelectHandler";
import FileInput from "../forms/FileInput";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";

export default function GiveawayForm() {
	const [formData, setFormData] = useState({ ...window.data.giveaway_values });
	const [staticData] = useState({ ...window.data.static_data });
	const [originialTriggersOverride] = useState(formData.triggersoverride);
	const [exceededCosts, setExceededCosts] = useState(originialTriggersOverride);
	const [loading, setLoading] = useState(false);
	const inputHandler = useInputHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);
	const selectHandler = useSelectHandler(setFormData);
	const { intermediary } = window.data;
	const creating = formData.id === -1;

	const getRoute = (action = null) =>
		intermediary
			? route(`crm.intermediaries.giveaways.${action ? action : "index"}`, window.data.company_id)
			: route(`crm.organisations.giveaways.${action ? action : "index"}`, window.data.company_id);

	useEffect(() => {
		if (!originialTriggersOverride && formData.contactperson_id) {
			setLoading(true);
			const params = { contactperson: formData.contactperson_id };

			if (!creating) {
				params.without_giveaway_id = formData.id;
			}

			if (intermediary) {
				params.intermediary = window.data.company_id;
			} else {
				params.organisation = window.data.company_id;
			}

			axios
				.patch(
					route(
						intermediary ? "crm.intermediaries.giveaways.budget-costs" : "crm.organisations.giveaways.budget-costs",
						params,
					),
				)
				.then(response => {
					if (response && response.data) {
						let totalcosts = Number(formData.costs);

						if (response.data.costs !== 0) {
							totalcosts = Number(formData.costs) + Number(response.data.costs);
						}

						if (totalcosts > staticData.budget_year.budget_per_contactperson) {
							setExceededCosts(true);
							setFormData({
								...formData,
								triggersoverride: true,
							});
						} else {
							setExceededCosts(false);
							setFormData({
								...formData,
								triggersoverride: false,
							});
						}

						setLoading(false);
					}
				})
				.catch(error => {
					console.error(`Errors during fetching costs: ${error}`);
					setLoading(false);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData.contactperson_id, formData.costs]);

	return (
		<>
			{window.data.nav_data && (
				<>
					<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
					<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
				</>
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.company_name
						? uctrans(
								creating ? "general.new :item1 by :item2" : "general.:item1 from :item2",
								{
									item2: window.data.company_name,
								},
								{ item1: "giveaways.singular" },
						  )
						: uctrans(creating ? "general.add_:item" : "general.edit_:item", {
								item: uctrans("giveaways.singular"),
						  })}
				</h1>

				<a className="link" href={getRoute()}>
					{uctrans("general.back_to_overview_:page", {}, { page: "giveaways.plural" })}
				</a>
			</div>
			<AjaxForm
				method={creating ? "POST" : "PUT"}
				submitUrl={
					creating
						? getRoute("store")
						: intermediary
						  ? route(`crm.intermediaries.giveaways.update`, {
									giveaway: formData.id,
									intermediary: window.data.company_id,
						    })
						  : route(`crm.organisations.giveaways.update`, {
									giveaway: formData.id,
									organisation: window.data.company_id,
						    })
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "giveaways.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "giveaways.singular" })}
				onSuccess={() => Nav.go(getRoute())}
				data={{ ...formData }}
				useFlashMessage={creating}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-1/3 mr-12">
						<div className="form-container">
							<div className="form-1-2">
								<LabeledTextInput
									name="name"
									label="giveaways.fields.name"
									value={formData.name}
									onChange={inputHandler}
									required
								/>
							</div>

							<div className="form-1-2">
								<label htmlFor="costs">
									{uctrans("giveaways.fields.costs")} <span className="text-orange"> *</span>
								</label>
								<DebouncedInput
									type="number"
									step=".01"
									name="costs"
									debounceTime={300}
									value={formData.costs}
									onChange={inputHandler}
								/>
							</div>

							<div className="form-1-2">
								<label htmlFor="date">
									{uctrans("giveaways.fields.date")} <span className="text-orange"> *</span>
								</label>
								<DatePicker
									initialValue={formData.date ? parse(formData.date) : null}
									onChange={date => genericHandler(date ? format(date) : null, "date")}
								/>
							</div>

							<div className="form-1-2">
								<label htmlFor="by_user_id">
									<Translate content="giveaways.fields.by_user_id" />
									<span className="text-orange"> *</span>
								</label>
								<Select
									placeholder={uctrans("general.select_:item", {}, { item: "agendaitems.fields.user" })}
									value={staticData.users.filter(({ value }) => Number(value) === Number(formData.by_user_id))}
									options={staticData.users}
									name="by_user_id"
									onChange={selectHandler}
								/>
							</div>

							<div className="form-1-2">
								<label htmlFor="contactperson_id">
									<Translate content="giveaways.fields.contactperson_id" />
									<span className="text-orange"> *</span>
								</label>
								<Select
									placeholder={uctrans("general.select_:item", {}, { item: "contactpersons.singular" })}
									value={staticData.contactpersons.filter(
										({ value }) => Number(value) === Number(formData.contactperson_id),
									)}
									options={staticData.contactpersons}
									name="contactperson_id"
									onChange={selected => genericHandler(selected ? selected.value : null, "contactperson_id")}
								/>
							</div>

							{!!exceededCosts && (
								<>
									<div className="form-full">
										<br />
										<h3>{uctrans("giveaways.fields.yearly_limit_has_been_exceeded")}</h3>
										<div className="form-1-2">
											<LabeledAutosizeTextarea
												label="giveaways.fields.override_description"
												required
												value={formData.override_description}
												name="override_description"
												onChange={inputHandler}
											/>
										</div>
									</div>
									<div className="form-full">
										<div className="form-1-2">
											<label>
												{uctrans("giveaways.fields.attachment_agreement")} <span className="text-orange"> *</span>
											</label>
											<FileInput
												initialFiles={formData.attachment_agreement}
												onChange={files => genericHandler(files, "attachment_agreement")}
												additionalFileDownloadRouteProps={
													intermediary
														? { intermediary: window.data.company_id }
														: { organisation: window.data.company_id }
												}
												existingFileDownloadRoute={
													intermediary
														? "crm.intermediaries.giveaways.download-attachment-agreement"
														: "crm.organisations.giveaways.download-attachment-agreement"
												}
												maxFiles={10}
											/>
										</div>
									</div>
								</>
							)}

							<div className="form-full">
								<div className="row">
									<div className="w-full md:w-1/2 lg:w-1/3 column">
										<SubmitBar
											translateLabel
											item="giveaways.singular"
											submitDisabled={loading}
											addAnotherAvailable={false}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
