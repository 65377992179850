import { useState } from "react";
import DeleteDialog from "../../../../../js/react/components/general/DeleteDialog";
import IconButton from "../../../../../js/react/components/general/IconButton";
import { createLoader, error } from "../../../../../js/react/components/general/notifications";
import Select from "../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Nav from "../../../../../js/lib/Nav";
import { can, route } from "../../../../../js/helpers";
import useGenericHandler from "../../../../../js/react/hooks/useGenericHandler";
import useSelectHandler from "../../../../../js/react/hooks/useSelectHandler";
import CollapsibleContent from "../general/CollapsibleContent";
import StatutoryFields from "../intermediaries/statutory/StatutoryFields";
import axios from "axios";
import AllEstablishments from "./AllEstablishments";

export default function StatutoryCompanyForm() {
	const [formData, setFormData] = useState({ ...window.data.form_data });
	const [staticData, setStaticData] = useState({ ...window.data.static_data });
	const [deleteData, setDeleteData] = useState(null);
	const genericHandler = useGenericHandler(setFormData);
	const selectHandler = useSelectHandler(setFormData);

	const onSuccess = response => {
		if (staticData.creating) {
			Nav.go(route("crm.statutory-companies.edit", response.data.id));
		} else {
			setFormData(response.data.form_data);
			setStaticData(response.data.static_data);
		}
	};

	const handleStatutoryCompanyChange = (key, value) => {
		genericHandler(value, key);
	};

	const doDelete = async () => {
		const result = await axios.get(route("crm.statutory-companies.delete", staticData.id));

		if (result.status === 200) {
			setDeleteData(result.data);
		}
	};

	const askDelete = async () => {
		try {
			await axios.get(route("crm.statutory-companies.check-agenda-items", staticData.id));
			error(uctrans("companies.errors.would_leave_orphaned_agenda_items"));
		} catch (error) {
			if (error.response && error.response.status === 404) {
				await doDelete();

				return;
			}

			throw error;
		}
	};

	const submitDelete = async () => {
		const loader = createLoader(
			uctrans(
				"general.:item_is_being_deleted",
				{},
				{
					item: "statutory_companies.singular",
				},
			),
		);
		const result = await axios.post(deleteData.destroy_link, { _method: "delete" });

		if (result.status === 200) {
			loader.success(
				uctrans(
					"general.deleted_:item",
					{},
					{
						item: "statutory_companies.singular",
					},
				),
			);
			setDeleteData(null);
			Nav.go(route("crm.statutory-companies.index"));
		} else {
			loader.failure("something went wrong deleting");
		}
	};

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(
						staticData.creating ? "general.add_:item" : "general.edit_:item",
						{},
						{
							item: "statutory_companies.singular",
						},
					)}
				</h1>

				<a className="link" href={route("crm.statutory-companies.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "statutory_companies.plural" })}
				</a>
			</div>
			<AjaxForm
				method={staticData.creating ? "POST" : "PUT"}
				submitUrl={
					staticData.creating
						? route("crm.statutory-companies.store")
						: route("crm.statutory-companies.update", staticData.id)
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "statutory_companies.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "statutory_companies.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage={!!staticData.creating}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<StatutoryFields statutoryCompany={formData} handleStatutoryCompanyChange={handleStatutoryCompanyChange} />

						{!staticData.creating && staticData.mainCompanyOptions.length ? (
							<>
								<div className="w-1/3">
									<label htmlFor="main_company_id">
										{uctrans("statutory_companies.fields.main_company_id")}
										<span className="text-orange"> *</span>
									</label>
									<Select
										name="main_company_id"
										value={staticData.mainCompanyOptions.filter(({ value }) => value === formData.main_company_id)}
										onChange={selectHandler}
										options={staticData.mainCompanyOptions}
										required
										isClearable={false}
									/>
								</div>
								<div className="w-1/3">
									<label htmlFor="main_company_type">
										{uctrans("statutory_companies.fields.main_company_type")}
										<span className="text-orange"> *</span>
									</label>
									<Select
										name="main_company_type"
										value={staticData.statutoryCompanyCompanyTypeOptions.filter(
											({ value }) => value === formData.main_company_type,
										)}
										onChange={selectHandler}
										options={staticData.statutoryCompanyCompanyTypeOptions}
										required
										isClearable={false}
									/>
								</div>
							</>
						) : null}

						<div className="form-full">
							<div className="row">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar translateLabel item="statutory_companies.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
					{!staticData.creating ? (
						<div className="flex-none w-1/3 border-l pl-12">
							<CollapsibleContent title={uctrans("intermediaries.establishments")} initialCollapseState={false}>
								<AllEstablishments statutoryCompanyId={staticData.id} />
							</CollapsibleContent>
							{can("statutory_company", "delete") && (
								<CollapsibleContent title={uctrans("general.actions")}>
									<IconButton
										className="button button-tertiary"
										onClick={askDelete}
										icon="trash-2-outline"
										content={uctrans("general.delete_:item", {}, { item: "statutory_companies.singular" })}
									/>
									{deleteData ? (
										<DeleteDialog
											onConfirm={submitDelete}
											onCancel={() => setDeleteData(null)}
											isOpen
											deleteData={deleteData}
										/>
									) : null}
								</CollapsibleContent>
							)}
						</div>
					) : null}
				</div>
			</AjaxForm>
		</>
	);
}
