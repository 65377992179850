import IconButton from "../../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../../js/react/components/general/overview/DataColumn";
import DeleteColumnContent from "../../../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route } from "../../../../../../../js/helpers";
import Navigation from "../Navigation";

export default function provinceOverview() {
	const overviewData = { ...window.data.overview };

	return (
		<>
			<Navigation selectedTab="provinces" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("location.provinces.plural")}</h1>
				{can("location.province", "create") && (
					<div>
						<IconButton
							href={route("crm.location-settings.provinces.create")}
							content={uctrans("general.add_:item", {}, { item: "location.provinces.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.location-settings.provinces.index")}
				deleteRouteName="crm.location-settings.provinces.delete"
				modelTranslations={{
					plural: trans("location.provinces.plural"),
					singular: trans("location.provinces.singular"),
				}}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							sortable="name"
							renderHeaderContent={() => uctrans("location.provinces.fields.name")}
							renderCellContent={province => {
								if (can("location.province", "update")) {
									return (
										<a href={route("crm.location-settings.provinces.edit", province.id)} className="row-title link">
											{province.name}
										</a>
									);
								} else {
									return province.name;
								}
							}}
						/>
						{can("location.province", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(province, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(province)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
