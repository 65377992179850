import { format } from "../../../../../../js/lib/Date";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import { can, route } from "../../../../../../js/helpers";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";

import Navigation from "./Navigation";

export default function NewsletterMailingOverview() {
	const overviewData = { ...window.data.overview };
	const staticData = { ...window.data.static_data };

	return (
		<>
			<Navigation selectedTab="mailings" newsletter={staticData.newsletter} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("communication.newsletter_mailings.plural")}</h1>
				{can("communication.newsletter_mailing", "create") && (
					<div>
						<IconButton
							href={route("crm.communication.newsletter-mailings.create", { newsletter: staticData.newsletter.id })}
							content={uctrans("general.add_:item", {}, { item: "communication.newsletter_mailings.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.communication.newsletter-mailings.index.json", { newsletter: staticData.newsletter.id })}
				deleteRouteName="crm.communication.newsletter-mailings.delete"
				modelTranslations={{
					plural: trans("communication.newsletter_mailings.plural"),
					singular: trans("communication.newsletter_mailings.singular"),
				}}
				hideControls
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={1}
							renderHeaderContent={() => uctrans("communication.newsletter_mailings.fields.number")}
							renderCellContent={newsletterMailing => newsletterMailing.number}
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("communication.newsletter_mailings.fields.created_at")}
							renderCellContent={newsletterMailing =>
								newsletterMailing.created_at ? format(newsletterMailing.created_at, "dd-MM-y HH:mm") : null
							}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("communication.newsletter_mailings.fields.sender")}
							renderCellContent={newsletterMailing => newsletterMailing.sender}
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("communication.newsletter_mailings.fields.type")}
							renderCellContent={newsletterMailing => newsletterMailing.type_label}
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("communication.newsletter_mailings.fields.planned_at")}
							renderCellContent={newsletterMailing =>
								newsletterMailing.planned_at && newsletterMailing.type === 2
									? format(newsletterMailing.planned_at, "dd-MM-y HH:mm")
									: null
							}
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("communication.newsletter_mailings.fields.status")}
							renderCellContent={newsletterMailing => newsletterMailing.status_label}
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("communication.newsletters.sent_at")}
							renderCellContent={newsletter =>
								newsletter.queue_handling_finished_at
									? format(newsletter.queue_handling_finished_at, "dd-MM-y HH:mm")
									: "-"
							}
						/>
						{(can("communication.newsletter_mailing", "update") ||
							can("communication.newsletter_mailing", "delete")) && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(newsletterMailing, overview) => (
									<div className="flex">
										{can("communication.newsletter_mailing", "update") && (
											<a
												href={route("crm.communication.newsletter-mailings.edit", {
													newsletter: staticData.newsletter.id,
													newsletter_mailing: newsletterMailing.id,
												})}
												className="row-title link">
												<EvaIcon
													className="flex blocks-center"
													type={newsletterMailing.read_only ? "eye-outline" : "edit-outline"}
													width="20"
													height="20"
													fill="#009286"
												/>
											</a>
										)}
										{can("communication.newsletter_mailing", "delete") && !newsletterMailing.read_only && (
											<DeleteColumnContent onClick={() => overview.askDelete(newsletterMailing)} />
										)}
									</div>
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
