import axios from "axios";
import { useMemo, useState } from "react";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import Dialog from "../../../../../js/react/components/general/Dialog";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import { createLoader } from "../../../../../js/react/components/general/notifications";
import Translate from "../../../../../js/react/components/general/Translate";
import TextEditor from "../forms/textEditor/TextEditor";
import CollapsibleContent from "../general/CollapsibleContent";
import QuestionnaireCompletionAnswersForm from "../questionnaires/QuestionnaireCompletionAnswersForm";
import AgendaItemCompanyFields from "./AgendaItemCompanyFields";
import AgendaItemDateFields from "./AgendaItemDateFields";
import AgendaItemFormFields from "./AgendaItemFormFields";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import map from "lodash/map";
import find from "lodash/find";
import filter from "lodash/filter";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import { can, route, validateEmail } from "../../../../../js/helpers";
import Navigation from "./Navigation";

export default function AnnuallyCooperationCompletionForm() {
	const readOnly = window.data.read_only;

	const [formData, setFormData] = useState({
		...window.data.annually_cooperation_completion_form_data,
	});
	const [staticData] = useState({ ...window.data.annually_cooperation_completion_static_data });

	const [questionnaireCompletionFinished] = useState(
		Number(staticData.questionnaire_completion_statuses.FINISHED.key) ===
			Number(formData.questionnaire_completion.status),
	);

	const [sendEmailFormActive, setSendEmailFormActive] = useState(false);
	const [alternativeEmail, setAlternativeEmail] = useState(staticData.company ? staticData.company.email_address : "");

	const [statusFinal] = useState(
		find(staticData.handled_statuses, status => Number(status.key) === Number(formData.handled_status)),
	);

	const disabled = statusFinal && statusFinal.non_editable ? true : readOnly;

	const onSuccess = () => {
		if (window.data.agendaNamespace || staticData.creating) {
			Nav.go(route("crm.agenda-items.index"));
		} else {
			if (staticData.intermediary) {
				Nav.go(route("crm.intermediaries.agenda-items.index", { intermediary: formData.agenda_item.company_id }));
			} else if (staticData.organisation) {
				Nav.go(route("crm.organisations.agenda-items.index", { organisation: formData.agenda_item.company_id }));
			}
		}
	};

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	const changeQuestionnaireCompletionValues = (key, value) => {
		const newFormData = { ...formData };
		newFormData.questionnaire_completion[key] = value;
		setFormData(newFormData);
	};

	const submitURL = () => {
		if (staticData.intermediary) {
			return route(`crm.agenda.intermediaries.annually-cooperation-completions.update`, [
				formData.agenda_item.company_id,
				formData.id,
			]);
		} else if (staticData.organisation) {
			return route(`crm.agenda.organisations.annually-cooperation-completions.update`, [
				formData.agenda_item.company_id,
				formData.id,
			]);
		}
	};

	const previousURL = () => {
		if (window.data.agendaNamespace) {
			return route("crm.agenda-items.index");
		}
		if (staticData.intermediary) {
			return route("crm.intermediaries.agenda-items.index", { intermediary: formData.agenda_item.company_id });
		} else if (staticData.organisation) {
			return route("crm.organisations.agenda-items.index", { organisation: formData.agenda_item.company_id });
		}
	};

	const colorCode = () => {
		switch (formData.color_code) {
			case staticData.color_codes.GREEN.key:
				return <span style={{ color: "green" }}>{uctrans("cooperation_check.color_codes.green")}</span>;
			case staticData.color_codes.RED.key:
				return <span style={{ color: "red" }}>{uctrans("cooperation_check.color_codes.red")}</span>;
			case staticData.color_codes.ORANGE.key:
				return <span style={{ color: "orange" }}>{uctrans("cooperation_check.color_codes.orange")}</span>;
			default:
				return uctrans("cooperation_check.color_codes.unknown_color_code");
		}
	};

	const getResult = () => (
		<div className="form-full">
			<label htmlFor="color_code">
				<Translate content="cooperation_check.fields.result" />
			</label>
			{questionnaireCompletionFinished ? colorCode() : uctrans("cooperation_check.handled_statuses.not_finished")}
			{formData.needs_administration && <span>{` (${uctrans("cooperation_check.fields.needs_administration")})`}</span>}
		</div>
	);

	const resendEmailLink = staticData.intermediary
		? route("crm.intermediaries.annually-cooperation-completions.resend-invitation", {
				annually_cooperation_completion: formData.id,
				intermediary: formData.company_id,
		  })
		: route("crm.organisations.annually-cooperation-completions.resend-invitation", {
				annually_cooperation_completion: formData.id,
				organisation: formData.company_id,
		  });

	const openSendEmailDialog = () => {
		setAlternativeEmail(staticData.company ? staticData.company.email_address : "");
		setSendEmailFormActive(true);
	};

	const handleResendEmailLink = () => {
		const loader = createLoader(uctrans("general.:item_is_being_mailed", {}, { item: "general.email" }));

		if (!validateEmail(alternativeEmail)) {
			loader.failure(uctrans("cooperation_check.annually.no_valid_email"));
			return false;
		}

		axios({
			method: "POST",
			url: resendEmailLink,
			data: {
				alternativeEmail,
			},
		})
			.then(() => {
				loader.success(uctrans("general.mailed_:item", {}, { item: "general.email" }));
			})
			.catch(error => {
				console.warn(error);
				loader.failure(uctrans("general.error"));
			})
			.then(() => {
				setSendEmailFormActive(false);
			});
	};

	const getRelevantCompletionStatuses = () => {
		if (questionnaireCompletionFinished) {
			if (statusFinal && statusFinal.administration_needed) {
				return filter(staticData.handled_statuses, status => status.administration_needed);
			}
		} else {
			return filter(staticData.handled_statuses, status => status.selectable_not_finished);
		}
	};

	const onQuestionnaireCompletionAnswersChanged = answers => {
		const newFormData = { ...formData };
		newFormData.answers = answers;
		setFormData(newFormData);
	};

	const versionString = useMemo(() => `v${formData.version.version}`, [formData]);

	return (
		<>
			{window.data.nav_data && (
				<Navigation
					selectedTab={window.data.nav_data.activeTabItem}
					tabs={window.data.nav_data.tabs}
					breadCrumbs={window.data.nav_data.breadcrumbs}
				/>
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.company_name
						? uctrans(
								"general.:item1 from :item2",
								{ item2: window.data.company_name },
								{ item1: "cooperation_check.annually_cooperation_check" },
						  )
						: uctrans("cooperation_check.annually_cooperation_check")}
					{` (${versionString})`}
				</h1>

				{window.data.agendaNamespace ? (
					<a className="link" href={route("crm.agenda-items.index")}>
						{uctrans("general.back_to_:page", {}, { page: "agendaitems.accountsupport" })}
					</a>
				) : (
					<a className="link" href={previousURL()}>
						{uctrans("general.back_to_overview_:page", {}, { page: "agendaitems.plural" })}
					</a>
				)}
			</div>

			<AjaxForm
				method="PUT"
				submitUrl={submitURL()}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "cooperation_check.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "cooperation_check.singular" })}
				data={{ ...formData }}
				onSuccess={onSuccess}
				useFlashMessage={staticData.creating || window.data.agendaNamespace}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<AgendaItemFormFields
								onChange={value => handleFormValueChange("agenda_item", value)}
								agendaItemFormData={formData.agenda_item}
								agendaItemStaticData={staticData}
								showAgendaDate
								editable={!disabled}
							/>

							<div className="form-full">
								<label htmlFor="name">{uctrans("cooperation_check.annually.notes")}</label>
								<TextEditor
									value={formData.questionnaire_completion.notes}
									onChange={content => changeQuestionnaireCompletionValues("notes", content)}
									hideToolbar
								/>
							</div>

							{getResult()}

							<div className="form-full">
								<label htmlFor="color_code">
									<Translate content="cooperation_check.fields.resulting" />
								</label>
								{statusFinal && statusFinal.non_editable ? (
									<span dangerouslySetInnerHTML={{ __html: statusFinal.finished_label }} />
								) : (
									<RadioGroup
										name="handled_status"
										value={formData.handled_status}
										disabled={disabled}
										onChange={value => handleFormValueChange("handled_status", value)}>
										{map(getRelevantCompletionStatuses(), status => (
											<Radio inline key={status.key} value={status.key}>
												<span dangerouslySetInnerHTML={{ __html: status.label }} />
											</Radio>
										))}
									</RadioGroup>
								)}
							</div>

							{formData.questionnaire_completion && formData.questionnaire_completion.participant_label && (
								<div className="form-full">
									<label>{uctrans("questionnaires.questionnaire_completion.singular")}</label>

									{formData.questionnaire_completion.participant_url ? (
										<a className="link" href={formData.questionnaire_completion.participant_url}>
											{formData.questionnaire_completion.participant_label}
										</a>
									) : (
										<span> {formData.questionnaire_completion.participant_label} </span>
									)}
								</div>
							)}

							{formData.questionnaire_completion && formData.questionnaire_completion.participant_phone_number && (
								<div className="form-full">
									<label>{uctrans("general.phone")}</label>
									<span> {formData.questionnaire_completion.participant_phone_number} </span>
								</div>
							)}

							<CollapsibleContent title={uctrans("cooperation_check.fields.questionsanswers")}>
								<QuestionnaireCompletionAnswersForm
									questionnaire={formData.questionnaire}
									questionnaireCompletionAnswerData={formData.questionnaire_completion}
									questionnaireCompletionAnswersChanged={onQuestionnaireCompletionAnswersChanged}
									questionnaireTags={{ ...staticData.questionnairetags, ...staticData.questionnaire_tags }}
									disabled={disabled}
									colors={staticData.color_codes}
									showColorsAndAdministration
								/>
							</CollapsibleContent>

							<div className="form-full">
								<div className="row">
									<div className="w-full md:w-1/2 lg:w-1/3 column">
										<SubmitBar item="cooperation_check.annually_cooperation_check" addAnotherAvailable={false} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="flex-none w-1/3 border-l pl-12">
						<AgendaItemDateFields agendaItem={formData.agenda_item} />

						<AgendaItemCompanyFields companyId={formData.agenda_item.company_id} />

						<CollapsibleContent initialCollapseState={false} title={uctrans("general.general")}>
							{!questionnaireCompletionFinished && can("agenda.annually_cooperation_completion", "resend") && (
								<>
									<div className="form-full">
										<a className="button button-primary" onClick={openSendEmailDialog}>
											{uctrans("cooperation_check.annually.send_invitation_again")}
										</a>
									</div>
									<Dialog
										isOpen={sendEmailFormActive}
										onClose={() => setSendEmailFormActive(false)}
										title={uctrans("cooperation_check.annually.send_invitation_again")}>
										<span>{uctrans("cooperation_check.annually.send_invitation_again_description")}</span>
										<div className="form-container">
											<span className="form-full">
												<LabeledTextInput
													disabled={false}
													name="alternative-mail"
													type="email"
													value={alternativeEmail}
													onChange={event => setAlternativeEmail(event.target.value)}
													label="cooperation_check.annually.send_invitation_to"
												/>
											</span>
										</div>
										<br />
										<div className="grid-x confirm-dialog-buttons">
											<a className="button button-primary" onClick={handleResendEmailLink}>
												{uctrans("cooperation_check.annually.send_invitation_again")}
											</a>
										</div>
									</Dialog>
								</>
							)}

							{getResult()}

							<div className="form-full">
								<label htmlFor="resulting">
									<Translate content="cooperation_check.fields.resulting" />
								</label>
								{statusFinal && statusFinal.non_editable ? (
									<span>{statusFinal.finished_label}</span>
								) : (
									<span>{uctrans("cooperation_check.handled_statuses.not_finished")}</span>
								)}
							</div>
						</CollapsibleContent>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
