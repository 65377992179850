import DebouncedNumberFormattedInput from "../../../../../js/react/components/general/form/DebouncedNumberFormattedInput";
import { uctrans, trans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import { route, numberFormat } from "../../../../../js/helpers";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import axios from "axios";
import FormattedNumberText from "../report/FormattedNumberText";

import Navigation from "./Navigation";

export default function CompanyTargetOverview() {
	const overviewData = { ...window.data.overview };
	const staticData = { ...window.data.static_data };

	const handleInputChanged = async (name, value, companyId, overview) => {
		const { data } = overview;
		const index = data.items.findIndex(item => `${item.id}` === `${companyId}`);
		data.items[index][name] = value;
		overview.setData(data, false);

		await axios.put(
			route("crm.targets.company-targets.update-value", { target_year: staticData.targetYear, company: companyId }),
			{ name, value },
		);
		overview.loadData();
	};

	return (
		<>
			<Navigation selectedTab="companyTargets" targetYear={staticData.targetYear} />
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("targets.company_target.plural")}</h1>
			</div>

			<div className="text-sm">
				<AbnOverview
					initialData={overviewData.overview}
					indexUrl={route("crm.targets.company-targets.index.json", { target_year: staticData.targetYear })}
					modelTranslations={{
						plural: trans("targets.company_target.plural"),
						singular: trans("targets.company_target.singular"),
					}}
					renderFilters={overview => (
						<div className="flex justify-between">
							<div className="w-1/2">
								<Filters>
									<SearchBarFilter type="text" name="search" />
									<SelectFilter
										className="form-full"
										name="relationmanager"
										label={uctrans("intermediaries.fields.relationmanager_id")}
										options={overviewData.metadata.filterdata.relation_managers}
									/>
									<SelectFilter
										className="form-1-3"
										name="company_status_type"
										label={uctrans("intermediaries.fields.classification")}
										options={overviewData.metadata.filterdata.company_status_types}
										collapsible
									/>
								</Filters>
							</div>
							<div className="w-1/2">
								{overview.data.metadata.total && (
									<table className="rapportage_table totaal_rapportage_table reporttable mt-2 ">
										<thead>
											<tr>
												<th colSpan="4">{`${uctrans("report.total")} ${staticData.targetYear.year}`}</th>
											</tr>
											<tr className="head2">
												<td />
												<td>{uctrans("targets.company_target.fields.sum")}</td>
												<td>{uctrans("targets.company_target.fields.count")}</td>
												<td>{uctrans("targets.company_target.fields.contactmoments")}</td>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>{uctrans("targets.distributed")}</td>
												<td>
													<FormattedNumberText prefix="€ " value={overview.data.metadata.total.company_targets.sum} />
												</td>
												<td>
													<FormattedNumberText value={overview.data.metadata.total.company_targets.count} />
												</td>
												<td>
													<FormattedNumberText value={overview.data.metadata.total.company_targets.contactmoments} />
												</td>
											</tr>
											<tr>
												<td>{uctrans("targets.goal")}</td>
												<td>
													<FormattedNumberText
														prefix="€ "
														value={overview.data.metadata.total.relationmanager_targets.sum}
													/>
												</td>
												<td>
													<FormattedNumberText value={overview.data.metadata.total.relationmanager_targets.count} />
												</td>
												<td>
													<FormattedNumberText
														value={overview.data.metadata.total.relationmanager_targets.contactmoments}
													/>
												</td>
											</tr>
											<tr>
												<td>{uctrans("targets.difference")}</td>
												<td className={overview.data.metadata.total.diff.sum < 0 ? "text-red" : "text-green"}>
													<FormattedNumberText
														prefix={overview.data.metadata.total.diff.sum > 0 ? "€ +" : ""}
														value={overview.data.metadata.total.diff.sum}
													/>
												</td>
												<td className={overview.data.metadata.total.diff.count < 0 ? "text-red" : "text-green"}>
													<FormattedNumberText
														prefix={overview.data.metadata.total.diff.count > 0 ? "+" : ""}
														value={overview.data.metadata.total.diff.count}
													/>
												</td>
												<td
													className={overview.data.metadata.total.diff.contactmoments < 0 ? "text-red" : "text-green"}>
													<FormattedNumberText
														prefix={overview.data.metadata.total.diff.contactmoments > 0 ? "+" : ""}
														value={overview.data.metadata.total.diff.contactmoments}
													/>
												</td>
											</tr>
										</tbody>
									</table>
								)}
							</div>
						</div>
					)}
					renderBody={() => (
						<>
							<div className="w-full mt-2 text-white flex bg-primary p-2">
								<span className="ml-1">
									{uctrans("targets.company_target.plural")} {staticData.targetYear.year}
								</span>
							</div>
							<div className="w-full  bg-white flex ">
								<div className="w-7/24 p-2">{uctrans("intermediaries.singular")}</div>
								<div className="w-8/24 p-2 border-l">{`Realisatie ${staticData.targetYear.year - 1}`}</div>
								<div className="w-9/24 p-2 border-l">{`Doel ${staticData.targetYear.year}`}</div>
							</div>
							<DataTable>
								<DataColumn
									width={3}
									sortable="company_name"
									renderHeaderContent={() => uctrans("intermediaries.fields.name")}
									renderCellContent={companyTarget => companyTarget.company_name}
								/>
								<DataColumn
									width={2}
									sortable="company_number"
									renderHeaderContent={() => uctrans("intermediaries.fields.company_number")}
									renderCellContent={companyTarget => companyTarget.company_number}
								/>
								<DataColumn
									width={2}
									sortable="relationmanager"
									renderHeaderContent={() => "RM"}
									renderCellContent={companyTarget => companyTarget.relationmanager}
								/>
								<DataColumn
									width={3}
									sortable="last_year_sum"
									renderHeaderContent={() => `${uctrans("targets.company_target.fields.sum")} (220)`}
									renderCellContent={companyTarget => `€ ${numberFormat(companyTarget.last_year_sum, 0, ",", ".")}`}
									borderLeft
								/>
								<DataColumn
									width={3}
									sortable="last_year_count"
									renderHeaderContent={() => `${uctrans("targets.company_target.fields.count_short")} (220)`}
									renderCellContent={companyTarget => `${numberFormat(companyTarget.last_year_count, 0, ",", ".")}`}
								/>
								<DataColumn
									width={2}
									sortable="last_year_contactmoments"
									renderHeaderContent={() => `${uctrans("targets.company_target.fields.contactmoments_short")}`}
									renderCellContent={companyTarget =>
										`${numberFormat(companyTarget.last_year_contactmoments, 0, ",", ".")}`
									}
								/>
								<DataColumn
									width={3}
									sortable="company_target_sum"
									renderHeaderContent={() => `${uctrans("targets.company_target.fields.sum")} (220)`}
									renderCellContent={(companyTarget, overview) => (
										<DebouncedNumberFormattedInput
											showEuroSign
											value={companyTarget.company_target_sum}
											onChange={value => handleInputChanged("company_target_sum", value, companyTarget.id, overview)}
										/>
									)}
									borderLeft
								/>
								<DataColumn
									width={3}
									sortable="company_target_count"
									renderHeaderContent={() => `${uctrans("targets.company_target.fields.count_short")} (220)`}
									renderCellContent={(companyTarget, overview) => (
										<DebouncedNumberFormattedInput
											value={companyTarget.company_target_count}
											onChange={value => handleInputChanged("company_target_count", value, companyTarget.id, overview)}
										/>
									)}
								/>
								<DataColumn
									width={3}
									sortable="company_target_contactmoments"
									renderHeaderContent={() => uctrans("targets.company_target.fields.contactmoments_short")}
									renderCellContent={(companyTarget, overview) => (
										<DebouncedNumberFormattedInput
											value={companyTarget.company_target_contactmoments}
											onChange={value =>
												handleInputChanged("company_target_contactmoments", value, companyTarget.id, overview)
											}
										/>
									)}
								/>
							</DataTable>
						</>
					)}
				/>
			</div>
		</>
	);
}
