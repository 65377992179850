import PropTypes from "prop-types";
import { useContext } from "react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import Checkmark from "../../../../../../../js/react/components/general/form/checkmark/Checkmark";
import CompanyDossierContext from "../CompanyDossierContext";

export default function DossierPartOrganisationIsCentral({ completionData }) {
	const { isOrganisation, handleFormChange, readOnly } = useContext(CompanyDossierContext);

	return isOrganisation ? (
		<div className="dossier-row">
			<label>{uctrans("dossiers.parts.fields.central_by_organisation")}</label>
			<Checkmark
				onChange={e =>
					handleFormChange(Number(completionData.dossier_part_id), "organisation_is_central", e.target.checked)
				}
				name={`${completionData.dossier_part_id}_organisation_is_central`}
				checked={completionData.organisation_is_central}
				disabled={readOnly}
			/>
		</div>
	) : null;
}

DossierPartOrganisationIsCentral.propTypes = {
	completionData: PropTypes.object,
};
