import { useState } from "react";
import IconButton from "../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DeleteColumnContent from "../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route } from "../../../../../js/helpers";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";

export default function IdentificationVerificationOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("general.general_settings") }]} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("identificationverification.plural")}</h1>
				{can("identification_verification", "create") && (
					<div>
						<IconButton
							href={route("crm.identification-verification.create")}
							content={uctrans("general.add_:item", {}, { item: "identificationverification.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.identification-verification.index")}
				deleteRouteName="crm.identification-verification.delete"
				moveRouteName="crm.identification-verification.move"
				useDragAndDrop={can("identification_verification", "move")}
				hideControls
				modelTranslations={{
					plural: trans("identificationverification.plural"),
					singular: trans("identificationverification.singular"),
				}}
				renderBody={() => (
					<DataTable>
						{can("identification_verification", "move") && (
							<DataColumn
								width={1}
								className="draggable-overview-item-handle"
								renderHeaderContent={() => ""}
								renderCellContent={() => ""}
							/>
						)}
						<DataColumn
							width={10}
							sortable="name"
							renderHeaderContent={() => uctrans("identificationverification.fields.name")}
							renderCellContent={status => {
								if (can("identification_verification", "update")) {
									return (
										<a href={route("crm.identification-verification.edit", status.id)} className="row-title link">
											{status.name}
										</a>
									);
								} else {
									return status.name;
								}
							}}
						/>
						{can("identification_verification", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(status, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(status)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
