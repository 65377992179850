import axios from "axios";
import classNames from "classnames";
import cloneDeep from "lodash/cloneDeep";
import { useState, useEffect } from "react";
import Nav from "../../../../../../js/lib/Nav";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import { route, yesNoOptions } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import Select from "../../../../../../js/react/components/general/Select";
import useSelectHandler from "../../../../../../js/react/hooks/useSelectHandler";
import FormattedNumberText from "../FormattedNumberText";

export default function SegmentationReport() {
	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);
	const [overviewUpdatedAt, setOverviewUpdatedAt] = useState(new Date().getTime());
	const [titleData, setTitleData] = useState(null);
	const [YesNoOptions] = useState(yesNoOptions(false));
	const formInfo = window.data.formInfo ? cloneDeep(window.data.formInfo) : [];

	const selectHandler = useSelectHandler(setFormValues);

	useEffect(() => {
		setOverviewUpdatedAt(new Date().getTime());
	}, [formValues]);

	const setTitle = response => {
		if (response) {
			if (response.title) {
				setTitleData(response.title);
			}
		}
	};

	const generateCsv = async () => {
		const result = await axios.post(route("crm.report.segmentation.generate-csv"), {
			...formValues,
		});

		if (result && result.data.success) {
			Nav.go(
				route("crm.report.segmentation.download-csv", {
					download_key: result.data.download_key,
				}),
			);
		}
	};

	const getClassName = row => {
		if (row.expected_segment_name && row.current_segment_name) {
			return classNames("text-sm", {
				"font-bold": `${row.expected_segment_name}` !== `${row.current_segment_name}`,
			});
		}
		return classNames("text-sm");
	};

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans("report.report")} - {uctrans("report.report_types.segmentation")}
				</h1>
			</div>
			<div className="w-full flex">
				<div className="w-2/3 flex border-gray mb-6 mr-3">
					<div className="w-1/3">
						<div className="w-full pr-2">
							<label htmlFor="variant_segmentation">{uctrans("report.segmentation.variant_segmentation")}</label>
							<Select
								name="variant_segmentation"
								value={YesNoOptions.filter(({ value }) => `${value}` === `${formValues.variant_segmentation}`)}
								options={YesNoOptions}
								onChange={selectHandler}
							/>
						</div>
						<div className="w-full pr-2">
							<label htmlFor="current_segment">{uctrans("report.segmentation.current_segment")}</label>
							<Select
								name="current_segment"
								value={formInfo.segmentOptions.filter(({ value }) => `${value}` === `${formValues.current_segment}`)}
								options={formInfo.segmentOptions}
								onChange={selectHandler}
							/>
						</div>
						<div className="w-full pr-2">
							<label htmlFor="expected_segment">{uctrans("report.segmentation.expected_segment")}</label>
							<Select
								name="expected_segment"
								value={formInfo.segmentOptions.filter(({ value }) => `${value}` === `${formValues.expected_segment}`)}
								options={formInfo.segmentOptions}
								onChange={selectHandler}
							/>
						</div>
					</div>
					<div className="w-1/3">
						<div className="w-full pr-2">
							<label htmlFor="relationmanager">{uctrans("intermediaries.fields.relationmanager")}</label>
							<Select
								name="relation_manager"
								value={formInfo.relationmanagerOptions.filter(
									({ value }) => `${value}` === `${formValues.relation_manager}`,
								)}
								options={formInfo.relationmanagerOptions}
								onChange={selectHandler}
							/>
						</div>
						<div className="w-full pr-2">
							<label htmlFor="organisation">{uctrans("organisations.singular")}</label>
							<Select
								name="organisation"
								value={formInfo.organisationOptions.filter(({ value }) => `${value}` === `${formValues.organisation}`)}
								options={formInfo.organisationOptions}
								onChange={selectHandler}
							/>
						</div>
						<div className="w-full pr-2">
							<label htmlFor="classification">{uctrans("intermediaries.fields.classification")}</label>
							<Select
								name="classification"
								value={formInfo.classificationOptions.filter(
									({ value }) => `${value}` === `${formValues.classification}`,
								)}
								options={formInfo.classificationOptions}
								onChange={selectHandler}
							/>
						</div>
					</div>
					<div className="w-1/3">
						<div className="w-full mt-5 pr-2">
							<IconButton
								icon="file-text-outline"
								onClick={() => generateCsv()}
								content={uctrans("report.generate_csv")}
								primary
							/>
						</div>
					</div>
				</div>
			</div>

			<AbnOverview
				indexUrl={route("crm.report.segmentation.data")}
				dataUpdatedAt={overviewUpdatedAt}
				extraQuerystringParameters={formValues}
				reloadedData={response => setTitle(response)}
				modelTranslations={{
					plural: uctrans("report.results"),
					singular: uctrans("report.results"),
				}}
				renderBody={() => (
					<>
						<div className="w-full mt-2 text-white flex bg-primary p-2">
							<span className="ml-1">{titleData ? titleData : ""}</span>
						</div>
						<div className="w-full  bg-white flex ">
							<div className="w-13/24 p-2">{uctrans("intermediaries.singular")}</div>
							<div className="w-5/24 p-2 border-l">
								<span>{uctrans("report.segmentation.loans_last_year")}</span>
							</div>
							<div className="w-3/24 p-2 border-l">{uctrans("report.segmentation.singular")}</div>
						</div>
						<DataTable>
							<DataColumn
								width={2}
								sortable="company_number"
								renderHeaderContent={() => uctrans("report.segmentation.tpnr")}
								renderCellContent={row =>
									row.company_number ? <span className={getClassName(row)}>{row.company_number}</span> : "-"
								}
							/>
							<DataColumn
								width={5}
								sortable="company_name"
								renderHeaderContent={() => uctrans("intermediaries.fields.name")}
								renderCellContent={row =>
									row.company_name ? <span className={getClassName(row)}>{row.company_name}</span> : "-"
								}
							/>
							<DataColumn
								width={3}
								sortable="relationmanager_short_name"
								renderHeaderContent={() => uctrans("report.segmentation.rm")}
								renderCellContent={row =>
									row.relationmanager_short_name ? (
										<span className={getClassName(row)}>{row.relationmanager_short_name}</span>
									) : (
										"-"
									)
								}
							/>
							<DataColumn
								width={2}
								borderLeft
								sortable="count_loans_last_year"
								renderHeaderContent={() => uctrans("report.segmentation.count")}
								renderCellContent={row => (
									<span className={getClassName(row)}>
										<FormattedNumberText value={row.count_loans_last_year ? row.count_loans_last_year : 0} />
									</span>
								)}
							/>
							<DataColumn
								width={3}
								sortable="sum_last_year"
								renderHeaderContent={() => uctrans("report.segmentation.sum")}
								renderCellContent={row => (
									<span className={getClassName(row)}>
										<FormattedNumberText prefix="€ " value={row.sum_last_year ? row.sum_last_year : 0} />
									</span>
								)}
							/>
							<DataColumn
								width={3}
								borderLeft
								sortable="current_segment_name"
								renderHeaderContent={() => uctrans("report.segmentation.current")}
								renderCellContent={row => (
									<span className={getClassName(row)}>{row.current_segment_name ? row.current_segment_name : "-"}</span>
								)}
							/>
							<DataColumn
								width={3}
								sortable="expected_segment_name"
								renderHeaderContent={() => uctrans("report.segmentation.expected")}
								renderCellContent={row => (
									<span className={getClassName(row)}>
										{row.expected_segment_name ? row.expected_segment_name : "-"}
									</span>
								)}
							/>
						</DataTable>
					</>
				)}
			/>
		</>
	);
}
