import { useRef } from "react";
import PropTypes from "prop-types";

export default function ClickToSelect(props) {
	const elementRef = useRef(null);
	const Element = props.containerElement;

	const select = e => {
		e.preventDefault();
		const range = document.createRange();
		const sel = window.getSelection();
		range.selectNodeContents(elementRef.current);
		sel.removeAllRanges();
		sel.addRange(range);
	};

	return (
		<Element ref={elementRef} onClick={e => select(e)}>
			{props.children}
		</Element>
	);
}

ClickToSelect.propTypes = {
	children: PropTypes.any.isRequired,
	containerElement: PropTypes.oneOf(["span", "div"]),
};

ClickToSelect.defaultProps = {
	containerElement: "span",
};
