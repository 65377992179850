import Tippy from "@tippyjs/react";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import { can, route } from "../../../../../../js/helpers";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../../js/react/components/general/overview/SearchBarFilter";
import SelectFilter from "../../../../../../js/react/components/general/overview/SelectFilter";

import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";

export default function ThemeOverview() {
	const overviewData = { ...window.data.overview };

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("marketing.singular") }]} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("marketing.themes.plural")}</h1>
				{can("marketing.theme", "create") && (
					<div>
						<IconButton
							href={route("crm.marketing.themes.create")}
							content={uctrans("general.add_:item", {}, { item: "marketing.themes.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.marketing.themes.index")}
				deleteRouteName="crm.marketing.themes.delete"
				modelTranslations={{
					plural: trans("marketing.themes.plural"),
					singular: trans("marketing.themes.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
						<SelectFilter
							className="form-1-4"
							name="active"
							label={uctrans("marketing.themes.fields.active")}
							options={[
								{ value: "", label: uctrans("overview.show_all") },
								{ value: 1, label: uctrans("general.yes") },
								{ value: 0, label: uctrans("general.no") },
							]}
							collapsible
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							sortable="name"
							renderHeaderContent={() => uctrans("marketing.themes.fields.name")}
							renderCellContent={theme => {
								if (can("marketing.theme", "update")) {
									return (
										<a href={route("crm.marketing.themes.edit", theme.id)} className="row-title link">
											{theme.name}
										</a>
									);
								} else if (can("marketing.theme", "view")) {
									return (
										<a href={route("crm.marketing.themes.show", theme.id)} className="row-title link">
											{theme.name}
										</a>
									);
								} else {
									return theme.name;
								}
							}}
						/>
						<DataColumn
							width={3}
							sortable="active"
							renderHeaderContent={() => uctrans("marketing.themes.fields.active")}
							renderCellContent={theme => {
								if (theme.active) {
									return <EvaIcon type="checkmark-circle-2" fill="#6FAD2B" height="18" width="18" />;
								} else {
									return <EvaIcon type="close-circle" fill="#BF3030" height="18" width="18" />;
								}
							}}
						/>
						{can("marketing.theme", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(themeName, overview) => (
									<div className="overview-actions">
										{can("marketing.theme", "delete") && (
											<a
												onClick={() => {
													overview.askDelete(themeName);
												}}>
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("general.delete")}>
													<span>
														<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
													</span>
												</Tippy>
											</a>
										)}
									</div>
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
