import PropTypes from "prop-types";
import { route, can } from "../../../../.././../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import TabLinks from "../../general/tabs/TabLinks";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";

export default function Navigation(props) {
	const tabs = [];
	if (can("client_consent.client_lead_setting", "view")) {
		tabs.push({
			key: "client_lead_settings",
			label: uctrans("client_consents.leads_settings.plural"),
			route: route("crm.client-lead-settings.index"),
		});

		tabs.push({
			key: "client_lead_sustainability_settings",
			label: uctrans("client_consents.sustainability_settings.plural"),
			route: route("crm.client-lead-sustainability-settings.index"),
		});

		tabs.push({
			key: "client_lead_segment_settings",
			label: uctrans("client_consents.segment_settings.plural"),
			route: route("crm.client-lead-segment-settings.index"),
		});
	}
	if (can("client_consent.aow_setting", "view")) {
		tabs.push({
			key: "client_lead_aow_settings",
			label: uctrans("client_consents.aow_settings.plural"),
			route: route("crm.client-lead-aow-settings.index"),
		});
		if (can("client_consent.client_lead_setting", "view")) {
			tabs.push({
				key: "permission_duration",
				label: uctrans("client_consents.permission_duration.plural"),
				route: route("crm.permission-duration-settings.index"),
			});
		}
	}
	return (
		<>
			<BreadCrumbs
				breadCrumbs={[{ label: `${uctrans("client_consents.plural")} - ${uctrans("client_consents.leads.plural")}` }]}
			/>
			<TabLinks tabs={tabs} activeTab={props.selectedTab} />
		</>
	);
}
Navigation.propTypes = {
	selectedTab: PropTypes.string.isRequired,
};
