import { useState } from "react";
import { numberFormat } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import FormattedNumberText from "../report/FormattedNumberText";
import RmDashboardBlock from "./RmDashboardBlock";

export default function SalesFunnelBlock() {
	const [openContent, setOpenContent] = useState(null);

	const toggleSubBlock = id => {
		if (openContent === id) {
			return setOpenContent(null);
		} else if (openContent !== null) {
			setOpenContent(null);
		}
		setOpenContent(id);
	};

	const generateSubData = selectedData => (
		<div className="bg-grey-light p-2 mt-2">
			<ul>
				<li>
					<div className="flex justify-between w-full">
						<small>{uctrans("dashboard.amount_sum")}</small>
						<small className="float-right">
							<FormattedNumberText value={selectedData.count} />
						</small>
					</div>
				</li>
				<li>
					<div className="w-full">
						<small>{uctrans("dashboard.amount_money")}</small>
						<small className="float-right">
							{"€ "}
							{numberFormat(selectedData.sum ? selectedData.sum / 1000 : 0)}
						</small>
					</div>
				</li>
				{!!selectedData.pipeline && (
					<li>
						<div className="w-full">
							<small>{uctrans("dashboard.amount_pipeline")}</small>
							<small className="float-right">
								<FormattedNumberText value={selectedData.pipeline} />
							</small>
						</div>
					</li>
				)}
				{!!selectedData.loss && (
					<li>
						<div className="w-full">
							<small>{uctrans("dashboard.amount_fallout")}</small>
							<small className="float-right">
								<FormattedNumberText value={selectedData.loss} />
							</small>
						</div>
					</li>
				)}
			</ul>
		</div>
	);

	return (
		<RmDashboardBlock
			blockKey="sales-funnel"
			useFetching
			renderHeader={() => (
				<div className="flex w-full">
					<h5 className="mr-2 mb-0">{uctrans("dashboard.sales_funnel")}</h5>|
					<span className="text-sm ml-2 mt-1">{uctrans("dashboard.last_12_months")}</span>
				</div>
			)}
			renderContent={blockData => (
				<div style={{ minHeight: "95px" }}>
					<ul>
						{blockData.selected && (
							<>
								<li className="flex">
									{blockData.selected[110] && (
										<div className="w-6/12">
											<span>{blockData.selected[110].name}</span>
											<a className="float-right" onClick={() => toggleSubBlock(110)}>
												{numberFormat(blockData.selected[110].percentage, 1)}%
												{openContent === 110 ? (
													<EvaIcon type="arrow-ios-upward-outline" fill="#4b4d4b" height="17" width="17" />
												) : (
													<EvaIcon type="arrow-ios-downward-outline" fill="#4b4d4b" height="17" width="17" />
												)}
											</a>
											{openContent === 110 && generateSubData(blockData.selected[110])}
										</div>
									)}
									{blockData.selected[215] && (
										<div className="w-6/12 ml-6">
											<span>{blockData.selected[215].name}</span>
											<a className="float-right" onClick={() => toggleSubBlock(215)}>
												{numberFormat(blockData.selected[215].percentage, 1)}%
												{openContent === 215 ? (
													<EvaIcon type="arrow-ios-upward-outline" fill="#4b4d4b" height="17" width="17" />
												) : (
													<EvaIcon type="arrow-ios-downward-outline" fill="#4b4d4b" height="17" width="17" />
												)}
											</a>
											{openContent === 215 && generateSubData(blockData.selected[215])}
										</div>
									)}
								</li>
								<li className="flex mt-3">
									{blockData.selected[210] && (
										<div className="w-6/12">
											<span>{blockData.selected[210].name}</span>
											<a className="float-right" onClick={() => toggleSubBlock(210)}>
												{numberFormat(blockData.selected[210].percentage, 1)}%
												{openContent === 210 ? (
													<EvaIcon type="arrow-ios-upward-outline" fill="#4b4d4b" height="17" width="17" />
												) : (
													<EvaIcon type="arrow-ios-downward-outline" fill="#4b4d4b" height="17" width="17" />
												)}
											</a>
											{openContent === 210 && generateSubData(blockData.selected[210])}
										</div>
									)}
									{blockData.selected[220] && (
										<div className="w-6/12 ml-6">
											<span>{blockData.selected[220].name}</span>
											<a className="float-right" onClick={() => toggleSubBlock(220)}>
												{numberFormat(blockData.selected[220].percentage, 1)}%
												{openContent === 220 ? (
													<EvaIcon type="arrow-ios-upward-outline" fill="#4b4d4b" height="17" width="17" />
												) : (
													<EvaIcon type="arrow-ios-downward-outline" fill="#4b4d4b" height="17" width="17" />
												)}
											</a>
											{openContent === 220 && generateSubData(blockData.selected[220])}
										</div>
									)}
								</li>
								<li className="flex mt-3">
									{blockData.selected[212] && (
										<div className="w-6/12">
											<span>{blockData.selected[212].name}</span>
											<a className="float-right" onClick={() => toggleSubBlock(212)}>
												{numberFormat(blockData.selected[212].percentage, 1)}%
												{openContent === 212 ? (
													<EvaIcon type="arrow-ios-upward-outline" fill="#4b4d4b" height="17" width="17" />
												) : (
													<EvaIcon type="arrow-ios-downward-outline" fill="#4b4d4b" height="17" width="17" />
												)}
											</a>
											{openContent === 212 && generateSubData(blockData.selected[212])}
										</div>
									)}
									{blockData.selected[320] && (
										<div className="w-6/12 ml-6 font-bold">
											<span>{blockData.selected[320].name}</span>
											<a className="float-right" onClick={() => toggleSubBlock(320)}>
												{numberFormat(blockData.selected[320].percentage, 1)}%
												{openContent === 320 ? (
													<EvaIcon type="arrow-ios-upward-outline" fill="#4b4d4b" height="17" width="17" />
												) : (
													<EvaIcon type="arrow-ios-downward-outline" fill="#4b4d4b" height="17" width="17" />
												)}
											</a>
											{openContent === 320 && generateSubData(blockData.selected[320])}
										</div>
									)}
								</li>
							</>
						)}
					</ul>
				</div>
			)}
		/>
	);
}
