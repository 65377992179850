import PropTypes from "prop-types";
import { useState } from "react";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import cloneDeep from "lodash/cloneDeep";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import TabLinks from "../general/tabs/TabLinks";

export default function InterestCategoryForm(props) {
	const [formData, setFormData] = useState(
		typeof props.interestCategoryFormData === "undefined"
			? cloneDeep(window.data.interestcategory_form_data)
			: props.interestCategoryFormData,
	);

	const inputHandler = useInputHandler(setFormData);

	const [staticData, setStaticData] = useState(
		typeof props.interestCategoryStaticData === "undefined"
			? cloneDeep(window.data.interestcategory_static_data)
			: props.interestCategoryStaticData,
	);

	const onSuccess = response => {
		if (!staticData.creating) {
			setFormData(response.data.formvalues);
			setStaticData(response.data.interestcategory_static_data);
		} else {
			Nav.go(response.data.redirect_route);
		}
	};

	return (
		<>
			<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
			<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(
						staticData.creating ? "general.add_:item" : "general.edit_:item",
						{},
						{ item: "interestcategories.singular" },
					)}
				</h1>
				{staticData.previous_link && (
					<a className="link" href={staticData.previous_link}>
						{uctrans("general.back_to_overview_:page", {}, { page: "interestcategories.plural" })}
					</a>
				)}
			</div>
			<AjaxForm
				method={staticData.save_method}
				submitUrl={staticData.save_link}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "interestcategories.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "interestcategories.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage={staticData.creating}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<h2>{staticData.form_title}</h2>

							<div className="form-2-3">
								<LabeledTextInput
									name="name"
									value={formData.name}
									onChange={inputHandler}
									label="interestcategories.fields.name"
									required
								/>
							</div>

							<div className="row">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="interestcategories.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
					<div className="flex-none w-1/3">
						<div />
					</div>
				</div>
			</AjaxForm>
		</>
	);
}

InterestCategoryForm.propTypes = {
	interestCategoryFormData: PropTypes.object,
	interestCategoryStaticData: PropTypes.object,
};
