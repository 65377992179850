import PropTypes from "prop-types";
import { useContext } from "react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import { CmsTypesContext } from "../../cms-types-context";
import Part from "./Part";

export default function PageHeadAnimation({ values, handleValueChange }) {
	const { partTypes } = useContext(CmsTypesContext);

	return (
		<div>
			<Part
				label={uctrans("cms.animation_with_header")}
				parttype="animation"
				partTypes={partTypes}
				values={values.animation}
				onChange={value => {
					handleValueChange("animation", value);
				}}
			/>
		</div>
	);
}

PageHeadAnimation.propTypes = {
	values: PropTypes.object.isRequired,
	handleValueChange: PropTypes.func.isRequired,
};
