import PropTypes from "prop-types";
import { useMemo } from "react";
import AddressForm from "../../../../../../js/react/components/addresses/AddressForm";
import KifidTextInput from "../../../../../../js/react/components/general/form/KifidTextInput";
import KvkTextInput from "../../../../../../js/react/components/general/form/KvkTextInput";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";

export default function StatutoryFields({
	statutoryCompany,
	prefix,
	disabled,
	readOnly,
	handleStatutoryCompanyChange,
}) {
	const disabledForReal = useMemo(() => {
		if (readOnly) {
			return true;
		}

		return disabled;
	}, [readOnly, disabled]);

	return (
		<>
			<div className="form-full">
				<div className="form-2-3">
					<LabeledTextInput
						disabled
						name={`${prefix}name`}
						value={statutoryCompany.name ? statutoryCompany.name : ""}
						onChange={e => handleStatutoryCompanyChange("name", e.target.value)}
						label="intermediaries.fields.name"
					/>
				</div>
				<div className="form-1-3">
					<LabeledTextInput
						disabled
						name={`${prefix}legal_form`}
						value={statutoryCompany.legal_form ? statutoryCompany.legal_form : ""}
						onChange={e => handleStatutoryCompanyChange("legal_form", e.target.value)}
						label="intermediaries.fields.legal_form"
					/>
				</div>
			</div>
			<div className="form-full">
				<AddressForm addressFormData={statutoryCompany.address} useAddition disabled />
			</div>
			<div className="form-full">
				<div className="form-1-3 clear-both">
					<LabeledTextInput
						disabled={disabledForReal}
						name={`${prefix}afm_number`}
						value={statutoryCompany.afm_number ? statutoryCompany.afm_number : ""}
						onChange={e => handleStatutoryCompanyChange("afm_number", e.target.value)}
						label="intermediaries.fields.afm_number"
						required
					/>
				</div>
				<div className="form-1-3">
					<KvkTextInput
						disabled={disabledForReal}
						name={`${prefix}kvk_number`}
						value={statutoryCompany.kvk_number ? statutoryCompany.kvk_number : ""}
						onChange={e => handleStatutoryCompanyChange("kvk_number", e.target.value)}
						label="intermediaries.fields.kvk_number"
						required
					/>
				</div>
				<div className="form-1-3">
					<KifidTextInput
						disabled
						name={`${prefix}kifid_number`}
						value={statutoryCompany.kifid_number ? statutoryCompany.kifid_number : ""}
						label="intermediaries.fields.kifid_number"
					/>
				</div>
			</div>
		</>
	);
}

StatutoryFields.propTypes = {
	statutoryCompany: PropTypes.object,
	prefix: PropTypes.string,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	handleStatutoryCompanyChange: PropTypes.func,
};

StatutoryFields.defaultProps = {
	dialog: false,
	disabled: false,
	readOnly: false,
	prefix: "",
};
