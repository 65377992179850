import Tippy from "@tippyjs/react";
import axios from "axios";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { route } from "../../../../../js/helpers";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import map from "lodash/map";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import Dialog from "../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import { notify, success } from "../../../../../js/react/components/general/notifications";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import Select from "../../../../../js/react/components/general/Select";
import Translate from "../../../../../js/react/components/general/Translate";
import ClientTreatmentParticipantForm from "./ClientTreatmentParticipantForm";

export default function ClientTreatmentAddParticipantSearchFilter(props) {
	const { clientTreatment, selectInviter } = props;
	const participantAddTypes = props.staticData.participant_add_types;
	const participantTypes = props.staticData.client_treatment_participant_types;
	const inviterOptions = selectInviter ? props.staticData.client_treatment_inviter_options : [];

	const [overviewResult, setOverviewResult] = useState(null);
	const [overviewDataUpdatedAt, setOverviewDataUpdatedAt] = useState(new Date().getTime());
	const [participantType, setParticipantType] = useState(null);
	const [activeCompany, setActiveCompany] = useState(null);
	const [clientTreatmentInviter, setClientTreatmentInviter] = useState({ ...props.clientTreatmentInviter });
	const [showContactPersonsCompanyDialogOpen, setShowContactPersonsCompanyDialogOpen] = useState(false);
	const [chooseInviter, setChooseInviter] = useState(false);

	const [createParticipantsDialogOpen, setCreateParticipantsDialogOpen] = useState(false);
	const [activeParticipant, setActiveParticipant] = useState(null);

	const handleCreateParticipantsSuccess = useCallback(() => {
		setOverviewDataUpdatedAt(new Date().getTime());
		setCreateParticipantsDialogOpen(false);
		setActiveParticipant(null);
		props.onAddParticipant();
		setShowContactPersonsCompanyDialogOpen(false);
	}, [props]);

	const changeDataTable = async (participantType, clientTreatmentInviter) => {
		try {
			const response = await axios.post(
				route("crm.client-treatments.participants.participant-filter-results", {
					client_treatment: clientTreatment.id,
				}),
				{ participant_type: participantType, inviter: clientTreatmentInviter ? clientTreatmentInviter.id : null },
			);
			setParticipantType(participantType);
			setClientTreatmentInviter(clientTreatmentInviter);
			setOverviewResult(response.data.overview);
			setOverviewDataUpdatedAt(new Date().getTime());
		} catch (e) {
			console.error(e);
		}
	};

	const addParticipant = (participant, type) => {
		setActiveParticipant({
			id: null,
			information: "",
			inviter: selectInviter && !chooseInviter ? null : clientTreatmentInviter,
			nr_of_people: "1",
			status: props.staticData.participant_statuses.INVITED,
			participant_object: participant,
			participant_object_type: type,
			tickets: [],
		});
		setCreateParticipantsDialogOpen(true);
	};

	const getDatatable = () => {
		if (clientTreatmentInviter && participantType !== null && overviewResult) {
			switch (Number(participantType)) {
				case Number(participantTypes.COMPANY.key):
					return (
						<DataTable>
							<DataColumn
								width={8}
								sortable="name"
								renderHeaderContent={() => uctrans("teams.fields.name")}
								renderCellContent={company => (company.name ? company.name : "-")}
							/>
							<DataColumn
								width={3}
								sortable="company_number"
								renderHeaderContent={() => uctrans("intermediaries.fields.company_number")}
								renderCellContent={company => (company.company_number ? company.company_number : "-")}
							/>
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={company => (
									<div className="overview-actions">
										<a
											onClick={() => {
												setActiveCompany(company);
												setShowContactPersonsCompanyDialogOpen(true);
											}}>
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("client_treatment.participants.fields.check_contactpersons")}>
												<span>
													<EvaIcon type="external-link-outline" fill="#009286" height="18" width="18" />
												</span>
											</Tippy>
										</a>
									</div>
								)}
							/>
						</DataTable>
					);
				case Number(participantTypes.COLLEGUE.key):
					return (
						<DataTable>
							<DataColumn
								width={6}
								sortable="last_name"
								renderHeaderContent={() => uctrans("teams.fields.name")}
								renderCellContent={user => (user.label ? user.label : "-")}
							/>
							<DataColumn
								width={6}
								sortable="email"
								renderHeaderContent={() => uctrans("users.fields.email")}
								renderCellContent={user => (user.email ? user.email : "-")}
							/>
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={participant => {
									if (
										Array.isArray(participant.already_selected) &&
										Number(participant.already_selected[0]) === Number(participantAddTypes.ADDED_BY_SOMEONE_ELSE.key)
									) {
										return (
											<div className="overview-actions">
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("client_treatment.participants.fields.added_by_someone_else:person", {
														person: participant.already_selected[1],
													})}>
													<span>
														<EvaIcon type="alert-circle-outline" fill="#3490DC" height="18" width="18" />
													</span>
												</Tippy>
											</div>
										);
									} else if (Number(participant.already_selected) === Number(participantAddTypes.ADDED_BY_ME.key)) {
										return (
											<div className="overview-actions">
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("client_treatment.participants.fields.already_added_by_me")}>
													<span>
														<EvaIcon type="checkmark-outline" fill="#009286" height="18" width="18" />
													</span>
												</Tippy>
											</div>
										);
									} else if (Number(participant.already_selected) === Number(participantAddTypes.NOT_ADDED.key)) {
										return (
											<div className="overview-actions">
												<a onClick={() => addParticipant(participant, props.staticData.object_types.USER.key)}>
													<Tippy
														placement="left"
														arrow
														animation="perspective"
														duration="400"
														content={uctrans("client_treatment.participants.fields.add_participant")}>
														<span>
															<EvaIcon type="plus-outline" fill="#009286" height="18" width="18" />
														</span>
													</Tippy>
												</a>
											</div>
										);
									}
								}}
							/>
						</DataTable>
					);
				case Number(participantTypes.CONTACTPERSON.key):
					return (
						<DataTable>
							<DataColumn
								width={6}
								renderHeaderContent={() => uctrans("client_treatment.participants.fields.companyname")}
								renderCellContent={user => (user.employment ? user.employment : "-")}
							/>
							<DataColumn
								width={5}
								renderHeaderContent={() => uctrans("teams.fields.name")}
								renderCellContent={user => (user.label ? user.label : "-")}
							/>
							<DataColumn
								width={5}
								renderHeaderContent={() => uctrans("users.fields.email")}
								renderCellContent={user => (user.email ? user.email : "-")}
							/>
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={participant => {
									if (
										Array.isArray(participant.already_selected) &&
										Number(participant.already_selected[0]) === Number(participantAddTypes.ADDED_BY_SOMEONE_ELSE.key)
									) {
										return (
											<div className="overview-actions">
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("client_treatment.participants.fields.added_by_someone_else:person", {
														person: participant.already_selected[1],
													})}>
													<span>
														<EvaIcon type="alert-circle-outline" fill="#3490DC" height="18" width="18" />
													</span>
												</Tippy>
											</div>
										);
									} else if (Number(participant.already_selected) === Number(participantAddTypes.ADDED_BY_ME.key)) {
										return (
											<div className="overview-actions">
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("client_treatment.participants.fields.already_added_by_me")}>
													<span>
														<EvaIcon type="checkmark-outline" fill="#009286" height="18" width="18" />
													</span>
												</Tippy>
											</div>
										);
									} else if (Number(participant.already_selected) === Number(participantAddTypes.NOT_ADDED.key)) {
										return (
											<div className="overview-actions">
												<a onClick={() => addParticipant(participant, props.staticData.object_types.CONTACTPERSON.key)}>
													<Tippy
														placement="left"
														arrow
														animation="perspective"
														duration="400"
														content={uctrans("client_treatment.participants.fields.add_participant")}>
														<span>
															<EvaIcon type="plus-outline" fill="#009286" height="18" width="18" />
														</span>
													</Tippy>
												</a>
											</div>
										);
									}
								}}
							/>
						</DataTable>
					);
			}
		}
	};

	const contentButton = participant => {
		if (
			Array.isArray(participant.already_selected) &&
			Number(participant.already_selected[0]) === Number(participantAddTypes.ADDED_BY_SOMEONE_ELSE.key)
		) {
			return (
				<div className="overview-actions">
					<Tippy
						placement="left"
						arrow
						animation="perspective"
						duration="400"
						content={uctrans("client_treatment.participants.fields.added_by_someone_else:person", {
							person: participant.already_selected[1],
						})}>
						<span>
							<EvaIcon type="alert-circle-outline" fill="#3490DC" height="18" width="18" />
						</span>
					</Tippy>
				</div>
			);
		} else if (Number(participant.already_selected) === Number(participantAddTypes.ADDED_BY_ME.key)) {
			return (
				<div className="overview-actions">
					<Tippy
						placement="left"
						arrow
						animation="perspective"
						duration="400"
						content={uctrans("client_treatment.participants.fields.already_added_by_me")}>
						<span>
							<EvaIcon type="checkmark-outline" fill="#009286" height="18" width="18" />
						</span>
					</Tippy>
				</div>
			);
		} else if (Number(participant.already_selected) === Number(participantAddTypes.ADDED_BY_SOMEONE_ELSE.key)) {
			return (
				<div className="overview-actions">
					<Tippy
						placement="left"
						arrow
						animation="perspective"
						duration="400"
						content={uctrans("client_treatment.participants.fields.added_by_someone_else")}>
						<span>
							<EvaIcon type="alert-circle-outline" fill="#3490DC" height="18" width="18" />
						</span>
					</Tippy>
				</div>
			);
		} else if (Number(participant.already_selected) === Number(participantAddTypes.NOT_ADDED.key)) {
			return (
				<div className="overview-actions">
					<a onClick={() => addParticipant(participant, props.staticData.object_types.CONTACTPERSON.key)}>
						<Tippy
							placement="left"
							arrow
							animation="perspective"
							duration="400"
							content={uctrans("client_treatment.participants.fields.add_participant")}>
							<span>
								<EvaIcon type="plus-outline" fill="#009286" height="18" width="18" />
							</span>
						</Tippy>
					</a>
				</div>
			);
		}
	};

	const addAllContactpersonsFromCompany = useCallback(async () => {
		try {
			const contactpersonsToAdd = activeCompany.contactpersons
				.filter(cp => cp.already_selected === 0)
				.map(cp => ({ contactperson_id: cp.id, company_id: cp.company_id }));

			if (contactpersonsToAdd.length) {
				const response = await axios.post(
					route("crm.client-treatments.participants.store-multiple", {
						client_treatment: clientTreatment.id,
					}),
					{
						contactpersons: contactpersonsToAdd,
						inviter: selectInviter && !chooseInviter ? null : clientTreatmentInviter,
					},
				);

				if (response.data && response.data.success) {
					success(uctrans("client_treatment.messages.contactpersons_were_added"));
					handleCreateParticipantsSuccess();
				}
			}
		} catch (error) {
			notify("error", error, null);
		}
	}, [
		activeCompany,
		clientTreatment.id,
		selectInviter,
		chooseInviter,
		clientTreatmentInviter,
		handleCreateParticipantsSuccess,
	]);

	return (
		<>
			<div className="form-container">
				{selectInviter && (
					<div className="form-1-2">
						<label htmlFor="clientTreatmentInviter">
							<Translate content="client_treatment.inviters.singular" />
						</label>
						<Checkmark
							label={uctrans("client_treatment.inviters.fields.choose_inviter")}
							checked={chooseInviter}
							onChange={() => setChooseInviter(!chooseInviter)}
						/>
						{!!chooseInviter && (
							<>
								<label htmlFor="clientTreatmentInviter">
									<Translate content="client_treatment.inviters.singular" />
									<span className="text-orange"> *</span>
								</label>
								<Select
									placeholder={uctrans("general.select_:item", {}, { item: "client_treatment.inviters.singular" })}
									value={inviterOptions.filter(
										({ value }) => Number(value) === Number(clientTreatmentInviter ? clientTreatmentInviter.id : -1),
									)}
									isClearable={false}
									options={inviterOptions}
									name="clientTreatmentInviter"
									onChange={async selectedValue => {
										setClientTreatmentInviter(null);
										await changeDataTable(participantType, selectedValue ? selectedValue.inviter : null);
									}}
								/>
							</>
						)}
					</div>
				)}
				{clientTreatmentInviter && (
					<div className="form-1-2">
						<label htmlFor="participant_type">
							<Translate content="client_treatment.participants.fields.select_participant_types" />
							<span className="text-orange"> *</span>
						</label>
						<RadioGroup
							name="participant_type"
							value={participantType}
							onChange={async value => {
								setParticipantType(null);
								await changeDataTable(value, clientTreatmentInviter);
							}}
							horizontal={false}>
							{map(participantTypes, (type, index) => (
								<Radio key={index} label={type.label} value={type.key} />
							))}
						</RadioGroup>
					</div>
				)}
			</div>

			{clientTreatmentInviter && participantType !== null && overviewResult && (
				<AbnOverview
					initialData={overviewResult.overview}
					dataUpdatedAt={overviewDataUpdatedAt}
					renderFilters={() => (
						<Filters>
							<SearchBarFilter type="text" name="search" />
						</Filters>
					)}
					indexUrlAdditionalParameters={{
						participant_type: participantType,
						search_filter_from_overview: true,
					}}
					modelTranslations={{
						plural: trans("users.plural"),
						singular: trans("users.singular"),
					}}
					indexUrl={route("crm.client-treatments.participants.participant-filter-results", {
						client_treatment: clientTreatment.id,
						inviter: clientTreatmentInviter.id,
					})}
					renderBody={() => getDatatable()}
				/>
			)}

			<Dialog
				width={600}
				isOpen={showContactPersonsCompanyDialogOpen}
				isFullScreen
				onClose={() => setShowContactPersonsCompanyDialogOpen(false)}>
				{activeCompany && activeCompany.contactpersons && (
					<>
						<h2>{`${activeCompany.name} - ${trans("contactpersons.plural")}`}</h2>{" "}
						{activeCompany.contactpersons.length ? (
							<>
								{activeCompany.contactpersons.map((contactPerson, index) => (
									<div key={index} className="flex justify-between">
										<div className="w-1/3">{contactPerson.label}</div>
										<div className="w-1/3">{contactPerson.email}</div>
										{contentButton(contactPerson)}
									</div>
								))}
								{activeCompany.contactpersons.filter(cp => cp.already_selected === 0).length > 0 && (
									<a
										className="button button-tertiary"
										onClick={() => addAllContactpersonsFromCompany(activeCompany.contactpersons)}>
										{uctrans("client_treatment.fields.add_all_contactpersons")}
									</a>
								)}
							</>
						) : (
							<Translate content="client_treatment.participants.fields.no_contactpersons" />
						)}
					</>
				)}
			</Dialog>
			<Dialog
				width={500}
				isOpen={createParticipantsDialogOpen}
				title={uctrans("client_treatment.participants.fields.create_participant")}
				isFullScreen
				onClose={() => setCreateParticipantsDialogOpen(false)}>
				<ClientTreatmentParticipantForm
					formData={activeParticipant}
					staticData={props.staticData}
					onSuccess={() => handleCreateParticipantsSuccess()}
					onCancel={() => setCreateParticipantsDialogOpen(false)}
				/>
			</Dialog>
		</>
	);
}

ClientTreatmentAddParticipantSearchFilter.propTypes = {
	clientTreatment: PropTypes.object,
	clientTreatmentInviter: PropTypes.object,
	staticData: PropTypes.object,
	onAddParticipant: PropTypes.func,
	selectInviter: PropTypes.bool,
};

ClientTreatmentAddParticipantSearchFilter.defaultProps = {
	selectInviter: false,
};
