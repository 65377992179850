import { useState } from "react";
import { route } from "../../../../../../js/helpers";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";

export default function PageAzForm() {
	const [title] = useState(window.data.page_data.staticvalues.title);
	const [id] = useState(window.data.page_data.staticvalues.id);
	const [staticvalues] = useState(window.data.page_data.staticvalues);
	const [azItems, setAzItems] = useState(window.data.page_data.azItems ? window.data.page_data.azItems : []);

	const editAzValue = (key, value) => {
		const newAzItems = [...azItems];
		newAzItems[key] = value;
		setAzItems(newAzItems);
	};

	const addAzValue = () => {
		const newAzItems = [...azItems];
		newAzItems.push("");
		setAzItems(newAzItems);
	};

	const onSuccess = response => {
		setAzItems(response.data.azItems);
	};

	return (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{ label: uctrans("general.website") },
					{ label: uctrans("cms.pages.plural"), route: route("crm.cms.pages.index") },
					{
						label: title,
					},
				]}
			/>
			<ul className="flex tabs shadow -mx-12 -mt-6">
				<li>
					<a href={route("crm.cms.pages.edit", [id])}>{uctrans("cms.pages.live_version")}</a>
				</li>
				{!!staticvalues.has_concept && (
					<li>
						<a href={route("crm.cms.pages.edit-concept", [id])}>{uctrans("cms.pages.concept_version")}</a>
					</li>
				)}
				<li className="active">
					<a href={route("crm.cms.pages.edit-az", [id])}>{uctrans("cms.pages.az")}</a>
				</li>
			</ul>
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("cms.pages.az_items_:page", { page: title })}</h1>
				<a className="link" href={route("crm.cms.pages.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "cms.pages.plural" })}
				</a>
			</div>
			<AjaxForm
				method="PUT"
				submitUrl={route("crm.cms.pages.update-az", id)}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "cms.pages.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "cms.pages.singular" })}
				onSuccess={onSuccess}
				data={{ azItems }}>
				<div className="form-container">
					{!azItems.length && <div className="italic">{uctrans("cms.pages.no_az_items")}</div>}
					<ul className="w-1/2">
						{azItems.map((azItem, key) => (
							<li className="mb-2" key={key}>
								<input
									type="text"
									value={azItem}
									placeholder={uctrans("cms.pages.enter_a_az_item")}
									onChange={e => {
										editAzValue(key, e.target.value);
									}}
								/>
							</li>
						))}
					</ul>
				</div>
				<button type="button" className="button button-secondary" onClick={addAzValue}>
					{uctrans("cms.pages.add_az_item")}
				</button>
				<div className="submit-bar mt-12">
					<div className="submit-container">
						<button className="button background-color-success button-primary" type="submit">
							{uctrans("general.save_:item", {
								item: uctrans("cms.pages.az_items_:page", { page: title }),
							})}
						</button>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}

PageAzForm.propTypes = {};
