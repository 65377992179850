import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import map from "lodash/map";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { uctrans } from "../../../../../../../js/lib/Translator";
import { CmsTypesContext } from "../../cms-types-context";
import Attribute from "./Attribute";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";

export default function AttributeList(props) {
	const [attributelistData, setAttributelistData] = useState(props.values);
	useEffect(() => {
		setAttributelistData(props.values);
	}, [props.values]);

	const changeAttributeValues = (index, attributeValues) => {
		const newAttributelistData = Array.isArray(attributelistData) ? [...attributelistData] : [];
		newAttributelistData[index] = attributeValues;
		setAttributelistData(newAttributelistData);
		props.onChange(newAttributelistData);
	};

	const addListItem = () => {
		const newAttributelistData = Array.isArray(attributelistData) ? [...attributelistData] : [];
		newAttributelistData.push(null);
		setAttributelistData(newAttributelistData);
		props.onChange(newAttributelistData);
	};

	const deleteListItem = index => {
		const newAttributelistData = Array.isArray(attributelistData) ? [...attributelistData] : [];

		newAttributelistData.splice(index, 1);
		setAttributelistData(newAttributelistData);

		props.onChange(newAttributelistData);
	};

	const moveListItem = dragEvent => {
		if (dragEvent.destination) {
			const sourceIndex = dragEvent.source.index;
			const targetIndex = dragEvent.destination.index;

			const newAttributelistData = Array.isArray(attributelistData) ? cloneDeep(attributelistData) : [];

			if (`${sourceIndex}` !== `${targetIndex}`) {
				const item = newAttributelistData[sourceIndex];
				newAttributelistData.splice(sourceIndex, 1);
				newAttributelistData.splice(targetIndex, 0, item);

				setAttributelistData(newAttributelistData);
				props.onChange(newAttributelistData);
			}
		}
	};

	const { attributeTypes } = useContext(CmsTypesContext);
	const listAttributeType = find(attributeTypes, attributeType => attributeType.key === props.listAttributeType);

	if (listAttributeType) {
		switch (listAttributeType.key) {
			// Space for custom attributeslists
			default:
				return (
					<div className="w-full mt-5 mb-12">
						{props.label && <h5>{props.label}</h5>}

						<div className="pl-6 mt-3 border-l border-grey-light ">
							<DragDropContext onDragEnd={dragEvent => moveListItem(dragEvent)}>
								<Droppable droppableId="droppable">
									{provided => (
										<div ref={provided.innerRef} {...provided.droppableProps}>
											{(() =>
												map(attributelistData, (attributeValues, index) => (
													<Draggable key={index} index={index} draggableId={index.toString()}>
														{provided => (
															<div
																{...provided.dragHandleProps}
																ref={provided.innerRef}
																{...provided.draggableProps}
																className="bg-primary-lightest items-center px-3 py-3 my-2 flex justify-between">
																<div className="flex justify-between w-full">
																	<span>
																		<EvaIcon type="move-outline" width="20" height="20" fill="#009286" />
																	</span>
																	<div className="w-full">
																		<Attribute
																			attribute={{ type: listAttributeType.key }}
																			values={attributeValues}
																			onChange={values => {
																				changeAttributeValues(index, values);
																			}}
																		/>
																	</div>
																</div>
																<div className="list-item-controls flex justify-between">
																	<span
																		className="cursor-pointer"
																		onClick={() => {
																			deleteListItem(index);
																		}}>
																		<EvaIcon type="trash-2-outline" width="20" height="20" fill="#009286" />
																	</span>
																</div>
															</div>
														)}
													</Draggable>
												)))()}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						</div>
						<button type="button" className="button button-secondary" onClick={addListItem}>
							{uctrans("general.add_:item", { item: listAttributeType.label })}
						</button>
					</div>
				);
		}
	}
}

AttributeList.propTypes = {
	label: PropTypes.string,
	listAttributeType: PropTypes.string.isRequired,
	values: PropTypes.array,
	onChange: PropTypes.func.isRequired,
};
