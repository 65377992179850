import { useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import Translate from "../../../../../js/react/components/general/Translate";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import { route, yesNoOptions } from "../../../../../js/helpers";

export default function ReplaceTagOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("general.general_settings") }]} />

			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="communication.tags.settings.plural" />
				</h1>
			</div>
			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.replace-tag-settings.index")}
				modelTranslations={{
					plural: uctrans("communication.tags.plural"),
					singular: uctrans("communication.tags.singular"),
				}}
				renderFilters={overview => (
					<Filters>
						<SearchBarFilter type="text" name="search" />

						<SelectFilter
							className="form-1-3"
							name="show_in_overview"
							label={uctrans("communication.tags.settings.fields.show_in_overview")}
							options={yesNoOptions()}
						/>

						<SelectFilter
							className="form-1-3"
							name="category"
							label={uctrans("communication.tags.settings.fields.category")}
							options={overview.data.filterdata.categories}
						/>

						<SelectFilter
							className="form-1-3"
							name="available"
							label={uctrans("communication.tags.settings.fields.available_options")}
							options={overview.data.filterdata.available_options}
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							sortable="tag"
							renderHeaderContent={() => uctrans("communication.tags.settings.fields.tag")}
							renderCellContent={replaceTag => (
								<a href={route("crm.replace-tag-settings.edit", replaceTag.tag)} className="row-title link">
									{replaceTag.tag}
								</a>
							)}
						/>

						<DataColumn
							width={3}
							sortable="title"
							renderHeaderContent={() => uctrans("communication.tags.settings.fields.title")}
							renderCellContent={replaceTag => replaceTag.title}
						/>

						<DataColumn
							width={2}
							sortable="show_in_overview"
							renderHeaderContent={() => uctrans("communication.tags.settings.fields.show_in_overview")}
							renderCellContent={replaceTag =>
								replaceTag.show_in_overview ? uctrans("general.yes") : uctrans("general.no")
							}
						/>
					</DataTable>
				)}
			/>
		</>
	);
}
