import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { can, route } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import Tippy from "@tippyjs/react";
import PersonalBirthdayMessageDialog from "./PersonalBirthdayMessageDialog";

export default function BirthdayBlock(props) {
	const [isCollapsed, setCollapsed] = useState(window.data.collapsed_dashboard_blocks.birthday_block === "1");

	const [birthdayMessageContactperson, setBirthdayMessageContactperson] = useState(null);
	const [personalMessageDialogOpened, setPersonalMessageDialogOpened] = useState(false);

	useEffect(() => {
		if (birthdayMessageContactperson !== null) {
			// open personal birthday message dialog
			setPersonalMessageDialogOpened(true);
		} else {
			// close personal birthday message dialog
			setPersonalMessageDialogOpened(false);
		}
	}, [birthdayMessageContactperson]);

	const onBirthdayMessageUpdated = async () => {
		setBirthdayMessageContactperson(null);
		props.reloadData();
	};

	return (
		<div className="vertical-spacer spacer flex">
			<div className="bg-grey-lightest shadow rounded flex flex-col w-full">
				<div className="p-6 flex">
					<a
						className="no-underline text-grey-darker w-full cursor"
						onClick={() => {
							const collapsed = !isCollapsed;
							if (props.rememberCollapsed) {
								props.rememberCollapsed("birthday_block", collapsed);
							}
							setCollapsed(collapsed);
						}}>
						<div className="w-full flex justify-between">
							<div>
								<h5 className="mb-0">
									{uctrans("dashboard.birthdays_next_:nr_days", { nr: 7 })} ({props.birthdayData.length})
								</h5>
							</div>
							<div>
								<EvaIcon fill="#000000" width="18" height="18" type={isCollapsed ? "plus" : "minus"} />
							</div>
						</div>
					</a>
				</div>
				{!isCollapsed && (
					<div className="p-6 w-full">
						<div>
							<ul>
								<li className="flex font-bold mb-3">
									<div className="w-3/12">{uctrans("contactpersons.singular")}</div>
									<div className="w-5/12">{uctrans("contactpersons.fields.employed_at")}</div>
									<div className="w-2/12">{uctrans("dashboard.birthday-age")}</div>
									<div className="w-1/12">{uctrans("dashboard.to_contactperson")}</div>
									{window.data.birthday_module_enabled && (
										<div className="w-2/12">{uctrans("birthday.personal_message")}</div>
									)}
								</li>
								{props.birthdayData.map((birthdayData, index) => (
									<li className="flex mb-3" key={index}>
										<div className="w-3/12">{`${birthdayData.first_name} ${birthdayData.insertion} ${birthdayData.last_name}`}</div>
										<div className="w-5/12">
											<Tippy
												placement="top"
												arrow
												animation="perspective"
												duration="400"
												content={
													<>
														{birthdayData.employed_companies.map((employedCompany, index) => (
															<div key={index} style={{ display: "block", paddingTop: index === 0 ? 0 : 20 }}>
																<div>{employedCompany.company_name}</div>
																<div>
																	{birthdayData.initials} {birthdayData.last_name}
																</div>

																{employedCompany.visiting_addres &&
																	employedCompany.visiting_address.street !== "" &&
																	employedCompany.visiting_address.number !== "" && (
																		<div>
																			{employedCompany.visiting_address.street}{" "}
																			{employedCompany.visiting_address.number}{" "}
																			{employedCompany.visiting_address.addition}
																		</div>
																	)}

																{employedCompany.visiting_addres &&
																	employedCompany.visiting_address.zipcode !== "" &&
																	employedCompany.visiting_address.city !== "" && (
																		<div>
																			{employedCompany.visiting_address.zipcode} {employedCompany.visiting_address.city}
																		</div>
																	)}
															</div>
														))}
													</>
												}>
												<span style={{ cursor: "pointer" }}>
													{birthdayData.employed_companies.length === 1
														? birthdayData.employed_companies[0].company_name
														: `${birthdayData.employed_companies.length} ${uctrans("companies.plural")}`}
												</span>
											</Tippy>
										</div>
										<div className="w-2/12">
											{birthdayData.dateNextBirthDay ? format(birthdayData.dateNextBirthDay, "dd-MM") : null} (
											{birthdayData.ageNextBirthDay})
										</div>
										<div className="w-1/12">
											{can("contactperson", "update") && (
												<a href={route("crm.contactpersons.edit", birthdayData.id)} className="row-title link">
													<EvaIcon fill="#249286" width="18" height="18" type="arrow-circle-right-outline" />
												</a>
											)}
										</div>
										{window.data.birthday_module_enabled && (
											<div className="w-2/12">
												{birthdayData.birthday_message_seen || birthdayData.editing_disabled ? (
													<Tippy
														placement="left"
														arrow
														animation="perspective"
														duration="400"
														content={uctrans("birthday.contactperson_already_saw_message")}>
														<span>
															<EvaIcon type="checkmark-circle-2-outline" fill="#D04228" height="18" width="18" />
														</span>
													</Tippy>
												) : (
													<a onClick={() => setBirthdayMessageContactperson(birthdayData)} className="row-title link">
														{birthdayData.birthday_message ? (
															<Tippy
																placement="left"
																arrow
																animation="perspective"
																duration="400"
																content={uctrans("birthday.contactperson_edit_message")}>
																<span>
																	<EvaIcon fill="#249286" width="18" height="18" type="edit" />
																</span>
															</Tippy>
														) : (
															<Tippy
																placement="left"
																arrow
																animation="perspective"
																duration="400"
																content={uctrans("birthday.contactperson_add_message")}>
																<span>
																	<EvaIcon fill="#249286" width="18" height="18" type="plus" />{" "}
																</span>
															</Tippy>
														)}
													</a>
												)}
											</div>
										)}
									</li>
								))}
								<PersonalBirthdayMessageDialog
									opened={personalMessageDialogOpened}
									data={birthdayMessageContactperson}
									onClose={() => setBirthdayMessageContactperson(null)}
									onEditSuccess={() => onBirthdayMessageUpdated()}
								/>
							</ul>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

BirthdayBlock.propTypes = {
	rememberCollapsed: PropTypes.func,
	reloadData: PropTypes.func,
	birthdayData: PropTypes.array,
};

BirthdayBlock.defaultProps = {
	birthdayData: [],
};
