import { useState } from "react";
import { can, route, yesNoOptions } from "../../../../../../js/helpers";
import { trans, uctrans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import SelectFilter from "../../../../../../js/react/components/general/overview/SelectFilter";
import Navigation from "../SettingsNavigation";

export default function NewsletterUnsubscribeReasonOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<Navigation selectedTab="newsletterUnsubscribeReasons" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("communication.newsletters.unsubscribe_reasons.plural")}</h1>
				{can("communication.newsletter_unsubscribe_reason", "create") && (
					<div>
						<IconButton
							href={route("crm.communication-settings.newsletter-unsubscribe-reasons.create")}
							content={uctrans(
								"general.add_:item",
								{},
								{ item: "communication.newsletters.unsubscribe_reasons.singular" },
							)}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.communication-settings.newsletter-unsubscribe-reasons.index")}
				deleteRouteName="crm.communication-settings.newsletter-unsubscribe-reasons.delete"
				moveRouteName="crm.communication-settings.newsletter-unsubscribe-reasons.move"
				useDragAndDrop={can("communication.newsletter_unsubscribe_reason", "move")}
				hideControls
				modelTranslations={{
					plural: trans("communication.newsletters.unsubscribe_reasons.plural"),
					singular: trans("communication.newsletters.unsubscribe_reasons.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SelectFilter
							className="form-1-3"
							name="is_active"
							label={uctrans("afm_permits.check_actions.fields.is_active")}
							options={yesNoOptions()}
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						{can("communication.newsletter_unsubscribe_reason", "move") && (
							<DataColumn
								width={1}
								className="draggable-overview-item-handle"
								renderHeaderContent={() => ""}
								renderCellContent={() => ""}
							/>
						)}
						<DataColumn
							width={10}
							renderHeaderContent={() => uctrans("communication.newsletters.unsubscribe_reasons.fields.title")}
							renderCellContent={newsletterUnsubscribeReason => {
								if (can("communication.newsletter_unsubscribe_reason", "update")) {
									return (
										<a
											href={route(
												"crm.communication-settings.newsletter-unsubscribe-reasons.edit",
												newsletterUnsubscribeReason.id,
											)}
											className="row-title link">
											{newsletterUnsubscribeReason.title}
										</a>
									);
								} else {
									return newsletterUnsubscribeReason.title;
								}
							}}
						/>
						<DataColumn
							width={15}
							renderHeaderContent={() => uctrans("communication.newsletters.unsubscribe_reasons.fields.description")}
							renderCellContent={newsletterUnsubscribeReason => newsletterUnsubscribeReason.description}
						/>
						<DataColumn
							width={5}
							renderHeaderContent={() => uctrans("communication.newsletters.unsubscribe_reasons.fields.active")}
							renderCellContent={newsletterUnsubscribeReason =>
								newsletterUnsubscribeReason.is_active ? uctrans("general.yes") : uctrans("general.no")
							}
						/>
						{can("communication.newsletter_unsubscribe_reason", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(newsletterUnsubscribeReason, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(newsletterUnsubscribeReason)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
