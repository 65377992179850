import { useState } from "react";
import IconButton from "../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DeleteColumnContent from "../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route, yesNoOptions } from "../../../../../js/helpers";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";

export default function SoftwarePackageOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("general.general_settings") }]} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("software_packages.plural")}</h1>
				{can("software_package", "create") && (
					<div>
						<IconButton
							href={route("crm.software-packages.create")}
							content={uctrans("general.add_:item", {}, { item: "software_packages.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.software-packages.index")}
				deleteRouteName="crm.software-packages.delete"
				moveRouteName="crm.software-packages.move"
				useDragAndDrop={can("software_package", "move")}
				hideControls
				modelTranslations={{
					plural: trans("software_packages.plural"),
					singular: trans("software_packages.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SelectFilter
							className="form-1-3"
							name="is_active"
							label={uctrans("afm_permits.check_actions.fields.is_active")}
							options={yesNoOptions()}
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						{can("software_package", "move") && (
							<DataColumn
								width={1}
								className="draggable-overview-item-handle"
								renderHeaderContent={() => ""}
								renderCellContent={() => ""}
							/>
						)}
						<DataColumn
							width={10}
							sortable="name"
							renderHeaderContent={() => uctrans("software_packages.fields.name")}
							renderCellContent={softwarePackage => {
								if (can("software_package", "update")) {
									return (
										<a href={route("crm.software-packages.edit", softwarePackage.id)} className="row-title link">
											{softwarePackage.name}
										</a>
									);
								} else {
									return softwarePackage.name;
								}
							}}
						/>
						<DataColumn
							width={5}
							sortable="status"
							renderHeaderContent={() => uctrans("software_packages.fields.is_open")}
							renderCellContent={softwarePackage =>
								softwarePackage.is_open ? uctrans("general.yes") : uctrans("general.no")
							}
						/>
						<DataColumn
							width={5}
							renderHeaderContent={() => uctrans("afm_permits.check_actions.fields.is_active")}
							renderCellContent={softwarePackage =>
								softwarePackage.is_active ? uctrans("general.yes") : uctrans("general.no")
							}
						/>
						{can("software_package", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(status, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(status)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
