import classNames from "classnames";
import PropTypes from "prop-types";
import { memo } from "react";

const TabLinks = memo(({ tabs, activeTab }) => {
	const getClassForTab = tab =>
		classNames("tab-item", {
			active: tab.key === activeTab,
		});
	if (!tabs) {
		return null;
	}

	const tabsLineOne = tabs.filter(tab => tab.line === 1 || !tab.line);

	const tabsLineTwo = tabs.filter(tab => tab.line === 2);

	const shadowClass = classNames("flex tabs -mt-6", tabsLineTwo.length > 0 ? "" : "shadow");

	return (
		<>
			<ul className={shadowClass}>
				{tabsLineOne.map(tab => (
					<li key={tab.key} className={getClassForTab(tab)}>
						<a style={tab.color ? { color: tab.color } : null} href={tab.route}>
							{tab.label}
						</a>
					</li>
				))}
			</ul>
			{tabsLineTwo.length > 0 && (
				<ul className="flex tabs shadow -mt-6">
					{tabsLineTwo.map(tab => (
						<li key={tab.key} className={getClassForTab(tab)}>
							<a style={tab.color ? { color: tab.color } : null} href={tab.route}>
								{tab.label}
							</a>
						</li>
					))}
				</ul>
			)}
		</>
	);
});

TabLinks.displayName = "TabLinks";

export default TabLinks;

TabLinks.propTypes = {
	tabs: PropTypes.array,
	activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
