import { useCallback, useState } from "react";
import { route } from "../../../../../../js/helpers";
import Nav from "../../../../../../js/lib/Nav";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import ImageThumbnailFileInput from "../../../../../../js/react/components/general/form/ImageThumbnailFileInput";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../../js/react/components/general/Translate";
import Navigation from "../Navigation";

export default function AppSplashscreenForm() {
	const { creating, form_data } = window.data;

	const [appSplashscreen, setAppSplashscreen] = useState(form_data);

	const setAppSplashscreenField = useCallback(
		(key, value) => {
			setAppSplashscreen({
				...appSplashscreen,
				[key]: value,
			});
		},
		[appSplashscreen],
	);

	const onSuccess = useCallback(() => {
		Nav.go(route("crm.app-splash-screen.index"));
	}, []);

	return (
		<>
			<Navigation selectedTab="splashscreen" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("general.edit_:item", {}, { item: "app.splash_screen.singular" })}</h1>
			</div>

			<AjaxForm
				method={creating ? "POST" : "PUT"}
				submitUrl={
					creating
						? route("crm.app-splash-screen.store")
						: route("crm.app-splash-screen.update", { app_splashscreen: appSplashscreen.id })
				}
				data={appSplashscreen}
				onSuccess={onSuccess}
				useFlashMessage
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "app.splash_screen.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "app.splash_screen.singular" })}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<label htmlFor="duration">{uctrans("app.splash_screen.fields.duration")}</label>
									<input
										name="duration"
										type="number"
										value={appSplashscreen.duration}
										onChange={e => setAppSplashscreenField(e.target.name, e.target.value)}
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-3">
									<label htmlFor="avatar">
										<Translate content="app.splash_screen.fields.splashscreen_image" />{" "}
										<span className="text-orange">*</span>
									</label>
									<ImageThumbnailFileInput
										existingFileDownloadRoute="crm.app-splash-screen.download-image"
										maxFiles={1}
										image={appSplashscreen.splashscreen_image ? appSplashscreen.splashscreen_image : []}
										onChange={files => setAppSplashscreenField("splashscreen_image", files)}
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="app.splash_screen.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
