import find from "lodash/find";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { CmsTypesContext } from "../cms-types-context";
import PartList from "../general/part-lists/PartList";
import Select from "../general/attributes/Select";
import ClearablePart from "../general/parts/ClearablePart";
import SubmenuPromo from "../general/parts/SubmenuPromo";

export default function MenuitemAttributes(props) {
	const [type, setType] = useState(props.values.type ? props.values.type : "link");
	const handleTypeChange = newValue => {
		setType(newValue);
		props.onChange({ type: newValue, link, submenu_items: submenuItems, submenu_promo: submenuPromo });
	};
	const [link, setLink] = useState(props.values.link ? props.values.link : {});
	const handleLinkChange = newValue => {
		setLink(newValue);
		props.onChange({ type, link: newValue, submenu_items: submenuItems, submenu_promo: submenuPromo });
	};
	const [submenuItems, setSubmenuItems] = useState(props.values.submenu_items ? props.values.submenu_items : []);
	const handleSubmenuItemsChange = newValue => {
		setSubmenuItems(newValue);
		props.onChange({ type, link, submenu_items: newValue, submenu_promo: submenuPromo });
	};
	const [submenuPromo, setSubmenuPromo] = useState(props.values.submenu_promo ? props.values.submenu_promo : {});
	const handleSubmenuPromoChange = newValue => {
		setSubmenuPromo(newValue);
		props.onChange({ type, link, submenu_items: submenuItems, submenu_promo: newValue });
	};

	useEffect(() => {
		setType(props.values.type ? props.values.type : "link");
		setLink(props.values.link ? props.values.link : {});
		setSubmenuItems(props.values.submenu_items ? props.values.submenu_items : []);
		setSubmenuPromo(props.values.submenu_promo ? props.values.submenu_promo : {});
	}, [props.values]);

	const { resourceTypes } = useContext(CmsTypesContext);
	const resourceType = find(resourceTypes, resourceType => resourceType.key === "menuitem");

	if (resourceType) {
		return (
			<>
				<Select
					label={resourceType.attributes.type.label}
					value={type}
					options={resourceType.attributes.type.options}
					onChange={handleTypeChange}
				/>
				{type === "link" && (
					<ClearablePart
						parttype="linktarget"
						attribute={resourceType.attributes.link}
						values={link}
						onChange={handleLinkChange}
					/>
				)}
				{type === "submenu" && (
					<>
						<PartList
							label={resourceType.attributes.submenu_items.label}
							listPartType={resourceType.attributes.submenu_items.parttype}
							values={submenuItems}
							onChange={handleSubmenuItemsChange}
						/>
						<SubmenuPromo
							label={resourceType.attributes.submenu_promo.label}
							values={submenuPromo}
							onChange={handleSubmenuPromoChange}
						/>
					</>
				)}
			</>
		);
	}
}

MenuitemAttributes.propTypes = {
	values: PropTypes.object,
	onChange: PropTypes.func.isRequired,
};
