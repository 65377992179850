import { useContext, useEffect, useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import Tippy from "@tippyjs/react";
import Spinner from "../../../../../js/react/components/general/Spinner";
import EngagementScore from "../engagementScore/EngagementScore";
import TitleBlock from "./components/TitleBlock";
import { RmDashboardContext } from "./RmDashboardContext";

export default function EngagementScoreBlock() {
	const { wallet, fetchBlockData } = useContext(RmDashboardContext);
	const [dashboardData, setDashboardData] = useState(null);
	const walletType = wallet ? wallet.type : "total";

	useEffect(() => {
		setDashboardData(null);
		fetchBlockData &&
			fetchBlockData("engagement-score")
				.then(data => {
					const dashboardData = {
						totalRelativeScore: 0,
						walletRelativeScore: 0,
					};

					if (data && data.total) {
						dashboardData.totalRelativeScore = data.total.score_relative || 0;
					}

					if (data && data[walletType]) {
						dashboardData.walletRelativeScore = data[walletType].score_relative || 0;
						dashboardData.walletScoreToCompare = data[walletType].score_to_compare || 0;
					}

					setDashboardData(dashboardData);
				})
				.catch(e => {
					console.error(e);
				});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wallet]);

	return (
		<div className="flex w-full">
			<div className="flex">
				<h6>
					<Tippy
						placement="left"
						arrow
						animation="perspective"
						duration="400"
						content={
							<span>
								<p>{uctrans("engagement_score.dashboard_mouse_over")}</p>
								<br />
								<p>{uctrans("engagement_score.score_to_compare_mouse_over")}</p>
							</span>
						}>
						<span>
							<TitleBlock
								walletType={walletType}
								dashboardLink="#samenwerking"
								title={uctrans("engagement_score.singular")}
							/>
						</span>
					</Tippy>
				</h6>
			</div>

			<div className="pl-8">
				{!dashboardData ? (
					<Spinner width={25} />
				) : (
					<div className="flex">
						<EngagementScore
							wallet={wallet}
							totalScore={dashboardData.totalRelativeScore}
							walletScore={dashboardData.walletRelativeScore}
							scoreToCompare={dashboardData.walletScoreToCompare}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
