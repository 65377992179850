import { useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import Translate from "../../../../../js/react/components/general/Translate";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import IconButton from "../../../../../js/react/components/general/IconButton";
import DeleteColumnContent from "../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route } from "../../../../../js/helpers";

export default function RoleOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("general.security_settings") }]} />

			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="roles.plural" />
				</h1>
				{can("role", "create") && (
					<div>
						<IconButton
							href={route("crm.roles.create")}
							content={uctrans("general.add_:item", {}, { item: "roles.singular" })}
							primary
						/>
					</div>
				)}
			</div>
			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.roles.index")}
				deleteRouteName="crm.roles.delete"
				modelTranslations={{ plural: uctrans("roles.plural"), singular: uctrans("roles.singular") }}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							sortable="name"
							renderHeaderContent={() => uctrans("users.fields.name")}
							renderCellContent={role => (
								<a href={route("crm.roles.edit", role.id)} className="row-title link">
									{role.name}
								</a>
							)}
						/>
						{can("role", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(role, overview) => <DeleteColumnContent onClick={() => overview.askDelete(role)} />}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
