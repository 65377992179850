import find from "lodash/find";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { CmsTypesContext } from "../../cms-types-context";
import Boolean from "../attributes/Boolean";
import Text from "../attributes/Text";

export default function PromoGroup(props) {
	const [values, setValues] = useState(props.values ? props.values : {});
	useEffect(() => {
		setValues(props.values ? props.values : {});
	}, [props.values]);

	const handleValueChange = (key, value) => {
		const newValues = { ...values };
		newValues[key] = value;
		setValues(newValues);
		props.onChange(newValues);
	};

	const { partTypes } = useContext(CmsTypesContext);
	const partType = find(partTypes, partType => partType.key === "promo_group");

	if (partType) {
		return (
			<>
				{props.label && <label>{props.label}</label>}

				<div className="mt-3">
					<Boolean
						label={partType.attributes.part_of_promo_group.label}
						value={values.part_of_promo_group}
						onChange={value => {
							handleValueChange("part_of_promo_group", value);
						}}
					/>
					{!!values.part_of_promo_group && (
						<Text
							label={partType.attributes.promo_group_reference.label}
							value={values.promo_group_reference}
							onChange={value => {
								handleValueChange("promo_group_reference", value);
							}}
						/>
					)}
				</div>
			</>
		);
	}
}

PromoGroup.propTypes = {
	label: PropTypes.string,
	values: PropTypes.shape({
		part_of_promo_group: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
		promo_group_reference: PropTypes.string,
	}),
	onChange: PropTypes.func.isRequired,
};
