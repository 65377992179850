import cloneDeep from "lodash/cloneDeep";
import { useState } from "react";
import { route } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import Select from "../../../../../../js/react/components/general/Select";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import useSelectHandler from "../../../../../../js/react/hooks/useSelectHandler";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import PeriodicFilter from "../../general/PeriodicFilter";
import QuestionnaireCompletionStatistics from "../../questionnaires/QuestionnaireCompletionStatistics";
import axios from "axios";
import RelationManagerQuestionnaireTabs from "./RelationManagerQuestionnaireTabs";

export default function RelationManagerQuestionnaireCompletionStatistics() {
	const [loading, setLoading] = useState(false);
	const [formInfo] = useState(window.data.formInfo ? cloneDeep(window.data.formInfo) : []);
	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);
	const selectHandler = useSelectHandler(setFormValues);

	const [statisticsData, setStatisticsData] = useState({
		questionnaire: window.data.questionnaire,
		pie_chart_config: window.data.pie_chart_config,
		column_chart_config: window.data.column_chart_config,
		questionnaire_completions: window.data.questionnaire_completions,
		questionnaire_completion_statistics_data: window.data.questionnaire_completion_statistics_data,
		question_types: window.data.question_types,
	});

	async function fetchDefaultResult() {
		setLoading(true);

		try {
			const { data } = await axios.get(route("crm.surveys.relationmanager-questionnaire.statistics.index"), {
				params: formValues,
				headers: {
					"Content-Type": "application/json",
				},
			});

			if (data) {
				const oldData = { ...statisticsData };
				oldData.questionnaire_completions = data.questionnaire_completions;
				oldData.questionnaire_completion_statistics_data = data.questionnaire_completion_statistics_data;
				setStatisticsData(oldData);
			}
		} catch (err) {
			console.error(err);
		}

		setLoading(false);
	}

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("survey.plural") }]} />
			<RelationManagerQuestionnaireTabs initialActiveTabIndex={2} />

			{formInfo && (
				<div className="form-full">
					<div className="form-1-3">
						<label htmlFor="relationmanager">{uctrans("survey.relationmanager.fields.relationmanager_id")}</label>

						<Select
							id="relationmanager"
							value={formInfo.relationmanagers.filter(({ value }) => `${value}` === `${formValues.relationmanager}`)}
							options={formInfo.relationmanagers}
							name="relationmanager"
							onChange={selectHandler}
						/>
					</div>
					<div className="form-2-3">
						<PeriodicFilter formValues={formValues} setFormValuesFunc={setFormValues} formInfo={formInfo} />
					</div>
				</div>
			)}

			<div className="form-full">
				<button
					type="submit"
					onClick={() => fetchDefaultResult()}
					disabled={loading}
					className="button button-primary leading-normal">
					{uctrans("report.show_report")}
				</button>

				<div className="float-right">
					<a
						className="button button-primary mb-4"
						href={route("crm.surveys.relationmanager-questionnaire.statistics.download", {
							// url paramaters
							_query: {
								...formValues,
							},
						})}
						rel="noopener noreferrer">
						<EvaIcon type="download-outline" height="16" width="25" fill="#25282e" />
						{uctrans("questionnaires.export_to_pdf")}
					</a>
				</div>
			</div>

			<div className="form-full">
				{loading ? (
					<div className="flex justify-center">
						<Spinner width={250} />
					</div>
				) : (
					<QuestionnaireCompletionStatistics statisticsData={statisticsData} />
				)}
			</div>
		</>
	);
}
