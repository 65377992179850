import { Component } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import CommunicationPreferences from "../../../../../js/react/components/communication/CommunicationPreferences";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../js/react/components/general/Translate";
import AppDeviceOverview from "../../../../../js/react/components/app/AppDeviceOverview";
import NotificationPreferences from "../../../../../js/react/components/notifications/NotificationPreferences";
import CollapsibleContent from "../general/CollapsibleContent";
import { can, route } from "../../../../../js/helpers";

class UserProfileForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user: window.data.profile_form_data,
		};

		this.updateUserProperty = this.updateUserProperty.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	handleInputChange(e) {
		const { target } = e;
		const value = target.type === "checkbox" ? target.checked : target.value;

		this.updateUserProperty([target.name], value);
	}

	updateUserProperty(property, value) {
		const newUser = { ...this.state.user };
		newUser.formvalues[property] = value;

		this.setState({
			user: newUser,
		});
	}

	render() {
		return (
			<AjaxForm
				method="PUT"
				submitUrl={route("crm.profile.update")}
				successUrl={route("crm.profile.edit")}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "general.profile" })}
				successText={uctrans("general.saved_:item", {}, { item: "general.profile" })}
				data={{ ...this.state.user.formvalues }}
				useFlashMessage>
				<div className="max-w-2xl">
					<div className="form-container">
						<div className="form-1-3">
							<label htmlFor="first_name">
								<Translate content="users.fields.first_name" />
							</label>
							<input
								id="first_name"
								type="text"
								name="first_name"
								value={this.state.user.formvalues.first_name}
								onChange={this.handleInputChange}
								autoComplete="off"
							/>
						</div>

						<div className="form-1-6">
							<label htmlFor="insertion">
								<Translate content="users.fields.insertion" />
							</label>
							<input
								id="insertion"
								type="text"
								name="insertion"
								value={this.state.user.formvalues.insertion}
								onChange={this.handleInputChange}
								autoComplete="off"
							/>
						</div>

						<div className="form-1-3">
							<label htmlFor="last_name">
								<Translate content="users.fields.last_name" />
							</label>
							<input
								id="last_name"
								type="text"
								name="last_name"
								value={this.state.user.formvalues.last_name}
								onChange={this.handleInputChange}
								autoComplete="off"
							/>
						</div>
					</div>
					<div className="form-container">
						<div className="form-1-3">
							<LabeledTextInput
								name="email"
								label="users.fields.email"
								value={this.state.user.formvalues.email}
								onChange={this.handleInputChange}
								autoComplete="off"
								required
							/>
						</div>

						<div className="form-1-3">
							<label htmlFor="mobile">
								<Translate content="users.fields.mobile" />
							</label>
							<input
								id="mobile"
								type="text"
								name="mobile"
								value={this.state.user.formvalues.mobile}
								onChange={this.handleInputChange}
								autoComplete="off"
							/>
						</div>
					</div>
					<label htmlFor="current_password">
						<Translate content="users.current_password_explanation" />
					</label>
					<div className="form-container">
						<div className="form-1-3">
							<label htmlFor="current_password">
								<Translate content="passwords.current-password" />
							</label>
							<input
								id="current_password"
								type="password"
								name="current_password"
								value={this.state.user.formvalues.current_password}
								onChange={this.handleInputChange}
								autoComplete="off"
							/>
						</div>
					</div>
					<div className="form-container">
						<div className="form-1-3">
							<label htmlFor="new_password">
								<Translate content="passwords.new-password" />
							</label>
							<input
								id="raw_password"
								type="password"
								name="raw_password"
								onChange={this.handleInputChange}
								autoComplete="off"
							/>
						</div>
						<div className="form-1-3">
							<label htmlFor="raw_password_confirmation">
								<Translate content="passwords.confirm-new-password" />
							</label>
							<input
								id="raw_password_confirmation"
								type="password"
								name="raw_password_confirmation"
								onChange={this.handleInputChange}
								autoComplete="off"
							/>
						</div>
					</div>

					{!this.state.user.is_admin && (
						<CollapsibleContent title={uctrans("contactpersons.fields.communication_preferences")}>
							<div className="form-full mb-4">
								<Translate content="users.profile_communication_preferences_description" />
							</div>

							<div className="form-full mt-4">
								<CommunicationPreferences
									communicationPreferenceData={this.state.user.formvalues.unsubscribe_preferences}
									onPreferenceChange={value => {
										this.updateUserProperty("unsubscribe_preferences", value);
									}}
									staticNewsLetterSubscriptionTypes={this.state.user.newsletter_subscription_types}
									unsubscribeReasons={this.state.user.unsubscribe_reasons}
									forCrm
								/>
							</div>
						</CollapsibleContent>
					)}

					{can("notifications.notification", "update_preferences") && !this.state.user.is_admin && (
						<CollapsibleContent title={uctrans("users.notification_preferences")}>
							<NotificationPreferences
								preferences={this.state.user.formvalues.notification_preferences}
								onChange={value => {
									this.updateUserProperty("notification_preferences", value);
								}}
								notifictionTypesPerCategory={this.state.user.notification_types_per_category}
							/>
						</CollapsibleContent>
					)}

					{this.state.user.formvalues && this.state.user.formvalues.app_devices && (
						<div>
							<h4>
								<Translate content="app.register_app" />
							</h4>
							<p>
								<Translate content="app.register_app_explanation" />
							</p>
							<strong className="text-2xl">{this.state.user.formvalues.generated_app_activation_code}</strong>

							<AppDeviceOverview
								devices={this.state.user.formvalues.app_devices}
								deleteRoute="crm.profile.appdevices.delete"
								deleteRouteParams={{}}
							/>
						</div>
					)}

					<div className="row">
						<div className="w-full md:w-1/2 lg:w-1/3 column">
							<SubmitBar item="profiel" addAnotherAvailable={false} onSubmit={this.handleSubmit} />
						</div>
					</div>
				</div>
			</AjaxForm>
		);
	}
}

export default UserProfileForm;

UserProfileForm.propTypes = {};
