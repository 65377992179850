import { useState } from "react";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route } from "../../../../../../js/helpers";
import Navigation from "../Navigation";

export default function BadPressCheckTermOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<Navigation selectedTab="bad-press-check-terms" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("dossiers.bad_press_checks_terms.plural")}</h1>
				{can("dossier.bad_press_check_term", "create") && (
					<div>
						<IconButton
							href={route("crm.dossier-settings.bad-press-check-terms.create")}
							content={uctrans("general.add_:item", {}, { item: "dossiers.bad_press_checks_terms.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.dossier-settings.bad-press-check-terms.index")}
				deleteRouteName="crm.dossier-settings.bad-press-check-terms.delete"
				moveRouteName="crm.dossier-settings.bad-press-check-terms.move"
				useDragAndDrop={can("dossier.bad_press_check_term", "move")}
				hideControls
				modelTranslations={{
					plural: trans("dossiers.bad_press_checks_terms.plural"),
					singular: trans("dossiers.bad_press_checks_terms.singular"),
				}}
				renderBody={() => (
					<DataTable>
						{can("dossier.bad_press_check_term", "move") && (
							<DataColumn
								width={1}
								className="draggable-overview-item-handle"
								renderHeaderContent={() => ""}
								renderCellContent={() => ""}
							/>
						)}
						<DataColumn
							width={10}
							sortable="name"
							renderHeaderContent={() => uctrans("dossiers.bad_press_checks_terms.fields.name")}
							renderCellContent={part => {
								if (can("dossier.bad_press_check_term", "update")) {
									return (
										<a
											href={route("crm.dossier-settings.bad-press-check-terms.edit", part.id)}
											className="row-title link">
											{part.name}
										</a>
									);
								} else {
									return part.name;
								}
							}}
						/>
						{can("dossier.bad_press_check_term", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(status, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(status)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
