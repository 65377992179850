import Tippy from "@tippyjs/react";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import axios from "axios";
import filter from "lodash/filter";
import { useEffect, useState } from "react";
import { route } from "../../../../../../../js/helpers";
import { format } from "../../../../../../../js/lib/Date";
import Nav from "../../../../../../../js/lib/Nav";
import { uctrans } from "../../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../../js/react/components/general/abn-overview/AbnOverview";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import Checkmark from "../../../../../../../js/react/components/general/form/checkmark/Checkmark";
import IconButton from "../../../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../../js/react/components/general/overview/DataTable";
import Select from "../../../../../../../js/react/components/general/Select";
import useSelectHandler from "../../../../../../../js/react/hooks/useSelectHandler";
import useGenericHandler from "../../../../../../../js/react/hooks/useGenericHandler";
import FormattedNumberText from "../../FormattedNumberText";

export default function WalletAnalysisObjectiveReport({ formInfo, formValues, setFormValues }) {
	const [qualityMonitorResults, setQualityMonitorResults] = useState(false);
	const [referenceDate, setReferenceDate] = useState(
		formValues.reference_date_csv ? cloneDeep(formValues.reference_date_csv) : null,
	);
	const [overviewUpdatedAt, setOverviewUpdatedAt] = useState(new Date().getTime());
	const [totalData, setTotalData] = useState(null);
	const [title, setTitle] = useState(null);

	const selectHandler = useSelectHandler(setFormValues);
	const genericHandler = useGenericHandler(setFormValues);

	const isSumTypeReport = overview => overview.data.metadata.filterdata.objectiveType === "sum";

	useEffect(() => {
		setOverviewUpdatedAt(new Date().getTime());
	}, [formValues]);

	const onOverviewChange = response => {
		if (response) {
			if (response.total) {
				setTotalData(response.total);
			}
			if (response.title) {
				setTitle(response.title);
			}
		}
	};

	const generatePdf = async () => {
		const result = await axios.post(route("crm.report.wallet-analysis.generate-pdf"), {
			...formValues,
		});

		if (result && result.data.success) {
			Nav.go(
				route("crm.report.wallet-analysis.download-pdf", {
					download_key: result.data.download_key,
					reportType: formValues.reportType,
				}),
			);
		}
	};

	const generateCsv = async () => {
		const result = await axios.post(route("crm.report.wallet-analysis.generate-csv"), {
			...formValues,
			quality_monitor_results: qualityMonitorResults,
			reference_date: referenceDate,
		});

		if (result && result.data.success) {
			Nav.go(
				route("crm.report.wallet-analysis.download-csv", {
					download_key: result.data.download_key,
				}),
			);
		}
	};

	return (
		<div>
			<div className="w-full flex -mb-4">
				<div className="w-1/2 flex border-gray mr-3">
					<div className="w-1/2">
						<div className="w-full pr-2">
							<label htmlFor="year">{uctrans("report.year")}</label>
							<Select
								name="year"
								value={formInfo.objectiveYears.filter(({ value }) => `${value}` === `${formValues.year}`)}
								options={formInfo.objectiveYears}
								isClearable={false}
								onChange={selectHandler}
							/>
						</div>
						<div className="w-full pr-2">
							<label htmlFor="relationmanager">{uctrans("intermediaries.fields.relationmanager")}</label>
							<Select
								name="relationmanager"
								value={formInfo.relationmanagerOptions.filter(
									({ value }) => `${value}` === `${formValues.relationmanager}`,
								)}
								options={formInfo.relationmanagerOptions}
								onChange={selectHandler}
							/>
						</div>
						<div className="w-full pr-2">
							<label htmlFor="classification">{uctrans("intermediaries.fields.classification")}</label>
							<Select
								name="classification"
								value={formInfo.classificationOptions.filter(
									({ value }) => `${value}` === `${formValues.classification}`,
								)}
								options={formInfo.classificationOptions}
								onChange={selectHandler}
							/>
						</div>
					</div>
					<div className="w-1/2">
						<div className="w-full pr-2">
							<label htmlFor="organisation">{uctrans("organisations.singular")}</label>
							<Select
								name="organisation"
								value={formInfo.organisationOptions.filter(({ value }) => `${value}` === `${formValues.organisation}`)}
								options={formInfo.organisationOptions}
								onChange={selectHandler}
							/>
						</div>
						<div className="w-full pr-2">
							<label htmlFor="segment">{uctrans("segments.singular")}</label>
							<Select
								name="segment"
								value={formInfo.segmentOptions.filter(({ value }) => `${value}` === `${formValues.segment}`)}
								options={formInfo.segmentOptions}
								onChange={selectHandler}
							/>
						</div>
						<div className="w-full pr-2">
							<label htmlFor="team">{uctrans("teams.singular")}</label>
							<Select
								name="team"
								value={formInfo.teamOptions.filter(({ value }) => `${value}` === `${formValues.team}`)}
								options={formInfo.teamOptions}
								onChange={selectHandler}
							/>
						</div>
					</div>
				</div>

				<div className="w-1/2 border-gray p-3 my-6 mt-4">
					{totalData && (
						<table className="rapportage_table totaal_rapportage_table reporttable">
							<thead>
								<tr>
									<th colSpan="5">
										<Tippy
											placement="right"
											arrow
											animation="perspective"
											duration="400"
											content={uctrans("report.wallet_analysis.explained")}>
											<span>
												<EvaIcon fill="white" width="18" height="18" type="info-outline" />
											</span>
										</Tippy>
										<span className="ml-1">
											{" "}
											{uctrans("report.total")}
											{title ? `- ${title}` : ""}{" "}
										</span>
									</th>
								</tr>
								<tr className="head2 text-sm">
									<td className="gesprekken min1">{uctrans("report.wallet_analysis.status_320")}</td>
									<td className="gesprekken min1">{uctrans("report.wallet_analysis.ytd_target")}</td>
									<td className="gesprekken min1">{uctrans("report.wallet_analysis.ytd_percentage")}</td>
									<td className="gesprekken min1">{uctrans("report.wallet_analysis.year_target")}</td>
									<td className="gesprekken min1">{uctrans("report.wallet_analysis.nr_of_conversations")}</td>
								</tr>
							</thead>
							<tbody>
								<tr className="waarden text-sm">
									<td className="count_320 min1 status">
										{formValues.objectiveType === "sum" ? (
											<FormattedNumberText prefix="€ " value={totalData.sum_320 ? totalData.sum_320 / 1000 : 0} />
										) : (
											<FormattedNumberText value={totalData.count_320 ? totalData.count_320 : 0} />
										)}
									</td>
									<td className="ytd_sum_target min1">
										<span>
											{formValues.objectiveType === "sum" ? (
												<FormattedNumberText
													prefix="€ "
													value={totalData.ytd_sum_target ? totalData.ytd_sum_target / 1000 : 0}
												/>
											) : (
												<FormattedNumberText value={totalData.ytd_count_target ? totalData.ytd_count_target : 0} />
											)}
										</span>
									</td>
									<td className="ytd_count_percentage min1">
										<span
											style={{
												color:
													formValues.objectiveType === "sum"
														? totalData.ytd_sum_percentage >= 100
															? "#009286"
															: "#D04228"
														: totalData.ytd_count_percentage >= 100
														  ? "#009286"
														  : "#D04228",
											}}>
											{formValues.objectiveType === "sum" ? (
												<FormattedNumberText
													percentage
													value={totalData.ytd_sum_percentage ? totalData.ytd_sum_percentage : 0}
												/>
											) : (
												<FormattedNumberText
													percentage
													value={totalData.ytd_count_percentage ? totalData.ytd_count_percentage : 0}
												/>
											)}
										</span>
									</td>
									<td className="company_target min1">
										<span>
											{formValues.objectiveType === "sum" ? (
												<FormattedNumberText
													prefix="€ "
													value={totalData.company_target_sum ? totalData.company_target_sum / 1000 : 0}
												/>
											) : (
												<FormattedNumberText
													value={totalData.company_target_count ? totalData.company_target_count : 0}
												/>
											)}
										</span>
									</td>
									<td className="nr_of_conversations min1">
										<FormattedNumberText value={totalData.nr_of_conversations || 0} />
									</td>
								</tr>
							</tbody>
						</table>
					)}
					<div className="flex">
						<div className="w-2/5 mb-3">
							<IconButton
								className="report_download_button"
								icon="file-text-outline"
								onClick={() => generatePdf()}
								content={uctrans("report.generate_pdf_report")}
								primary
							/>

							<div className="mt-4">
								<a
									className={`${
										formValues.objectiveType === "sum" ? "cursor-default font-bold text-grey-darkest" : "link"
									}`}
									onClick={() => {
										genericHandler("sum", "objectiveType");
									}}>
									{uctrans("dashboard.sum_x_1000")}
								</a>
								{" | "}
								<a
									className={`${
										formValues.objectiveType === "count" ? "cursor-default font-bold text-grey-darkest" : "link"
									}`}
									onClick={() => {
										genericHandler("count", "objectiveType");
									}}>
									{uctrans("dashboard.count")}
								</a>
							</div>
						</div>
						<div className="w-3/5 mb-3">
							<IconButton
								className="report_download_button"
								icon="file-text-outline"
								onClick={() => generateCsv()}
								content={uctrans("report.generate_csv")}
								primary
							/>

							<div className="mt-4">
								<Checkmark
									onChange={() => setQualityMonitorResults(!qualityMonitorResults)}
									name="qualitymonitor_results"
									checked={qualityMonitorResults}
									label={uctrans("report.wallet_analysis.with_qualitymonitor_results")}
								/>
								{qualityMonitorResults && (
									<div className="w-full">
										<Select
											isClearable={false}
											placeholder={uctrans("general.select_:item", {}, { item: "report.reference_date" })}
											value={filter(formInfo.referenceDates, ({ value }) => `${value}` === `${referenceDate}`)}
											options={formInfo.referenceDates}
											name="reference_date_csv"
											onChange={value => (value ? setReferenceDate(value.value) : null)}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<AbnOverview
					indexUrl={route("crm.report.wallet-analysis.data")}
					dataUpdatedAt={overviewUpdatedAt}
					extraQuerystringParameters={formValues}
					reloadedData={response => onOverviewChange(response)}
					modelTranslations={{
						plural: uctrans("report.results"),
						singular: uctrans("report.results"),
					}}
					renderBody={() => (
						<>
							<div className="w-full text-white flex bg-primary p-2">
								<Tippy
									placement="right"
									arrow
									animation="perspective"
									duration="400"
									content={uctrans("report.wallet_analysis.explained")}>
									<span className="ml-1">
										<EvaIcon fill="white" width="18" height="18" type="info-outline" />
									</span>
								</Tippy>
								<span className="ml-1">{title ? title : ""}</span>
							</div>
							<div className="w-full  bg-white flex ">
								<div className="w-7/24 p-2">{uctrans("intermediaries.singular")}</div>
								<div className="w-6/24 p-2 border-l">{uctrans("report.wallet_analysis.conversations")}</div>
								<div className="w-11/24 p-2 border-l">{uctrans("report.wallet_analysis.status_320")}</div>
							</div>
							<DataTable>
								<DataColumn
									width={2}
									sortable="company_number"
									renderHeaderContent={() => uctrans("intermediaries.fields.company_number")}
									renderCellContent={row => (row.company_number ? <span>{row.company_number}</span> : "-")}
								/>
								<DataColumn
									width={5}
									sortable="company_name"
									renderHeaderContent={() => uctrans("intermediaries.fields.name")}
									renderCellContent={row => (row.company_name ? <span>{row.company_name}</span> : "-")}
								/>
								<DataColumn
									width={3}
									sortable="last_conversation"
									renderHeaderContent={() => uctrans("report.wallet_analysis.last_conversation")}
									renderCellContent={row =>
										row.last_conversation && format(row.last_conversation, "dd-MM-y") ? (
											<span>{format(row.last_conversation, "dd-MM-y")}</span>
										) : (
											"-"
										)
									}
									borderLeft
								/>
								<DataColumn
									width={3}
									sortable="nr_of_conversations"
									renderHeaderContent={() => uctrans("report.wallet_analysis.nr_of_conversations")}
									renderCellContent={row => (
										<span>
											<FormattedNumberText value={row.nr_of_conversations ? row.nr_of_conversations : 0} />
										</span>
									)}
								/>
								<DataColumn
									width={3}
									sortable="status_320"
									renderHeaderContent={() => uctrans("report.wallet_analysis.status_320")}
									renderCellContent={(row, rowOverview) =>
										isSumTypeReport(rowOverview) ? (
											<span>
												<FormattedNumberText prefix="€ " value={row.sum_320 ? row.sum_320 / 1000 : 0} />
											</span>
										) : (
											<span>
												<FormattedNumberText value={row.count_320 ? row.count_320 : 0} />
											</span>
										)
									}
									borderLeft
								/>

								<DataColumn
									width={3}
									sortable="ytd_target"
									renderHeaderContent={() => uctrans("report.wallet_analysis.ytd_target")}
									renderCellContent={(row, rowOverview) =>
										isSumTypeReport(rowOverview) ? (
											<span>
												<FormattedNumberText prefix="€ " value={row.ytd_sum_target ? row.ytd_sum_target / 1000 : 0} />
											</span>
										) : (
											<span>
												<FormattedNumberText value={row.ytd_count_target ? row.ytd_count_target : 0} />
											</span>
										)
									}
								/>

								<DataColumn
									width={2}
									sortable="ytd_percentage"
									renderHeaderContent={() => uctrans("report.wallet_analysis.ytd_percentage")}
									renderCellContent={(row, rowOverview) =>
										isSumTypeReport(rowOverview) ? (
											<span
												style={{
													color: row.ytd_sum_percentage >= 100 ? "#009286" : "#D04228",
												}}>
												<FormattedNumberText percentage value={row.ytd_sum_percentage ? row.ytd_sum_percentage : 0} />
											</span>
										) : (
											<span
												style={{
													color: row.ytd_count_percentage >= 100 ? "#009286" : "#D04228",
												}}>
												<FormattedNumberText
													percentage
													value={row.ytd_count_percentage ? row.ytd_count_percentage : 0}
												/>
											</span>
										)
									}
								/>

								<DataColumn
									width={3}
									sortable="year_target"
									renderHeaderContent={() => uctrans("report.wallet_analysis.year_target")}
									renderCellContent={(row, rowOverview) =>
										isSumTypeReport(rowOverview) ? (
											<span>
												<FormattedNumberText
													prefix="€ "
													value={row.company_target_sum ? row.company_target_sum / 1000 : 0}
												/>
											</span>
										) : (
											<span>
												<FormattedNumberText value={row.company_target_count ? row.company_target_count : 0} />
											</span>
										)
									}
								/>
							</DataTable>
						</>
					)}
				/>
			</div>
		</div>
	);
}

WalletAnalysisObjectiveReport.propTypes = {
	formInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	formValues: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	setFormValues: PropTypes.func.isRequired,
};
