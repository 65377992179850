import axios from "axios";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { route, numberFormat } from "../../../../../js/helpers";
import Spinner from "../../../../../js/react/components/general/Spinner";

export default function CommercialProductionBlock(props) {
	const [loading, setLoading] = useState(false);
	const [productionYear, setProductionYear] = useState([]);
	const [previousProductionYear, setPreviousProductionYear] = useState([]);
	const [productionData, setProductionData] = useState([]);
	useEffect(() => {
		loadData(props.companyId);
	}, [props.companyId]);

	const loadData = companyId => {
		setLoading(true);
		axios
			.get(route("crm.intermediaries.commercial.production-data", companyId))
			.then(response => {
				setProductionYear(response.data.production_year ? response.data.production_year : []);
				setPreviousProductionYear(response.data.previous_production_year ? response.data.previous_production_year : []);
				setProductionData(response.data.production_per_status ? response.data.production_per_status : []);
				setLoading(false);
			})
			.catch(error => {
				console.error(`Errors fetching commercial data - ${error}`);
			});
	};

	return (
		<div className="w-full">
			{!!loading && <Spinner width={25} />}
			{!loading && productionData && (
				<table className="w-full text-xs">
					<tr className="border-b">
						<td className="p-2 italic">(&euro;x 1.000)</td>
						<td className="border-l p-2">{`< 12mnd`}</td>
						<td className="p-2">#</td>
						<td className="border-l p-2">{productionYear}</td>
						<td className="p-2">#</td>
						<td className="border-l p-2">{previousProductionYear}</td>
						<td className="p-2">#</td>
					</tr>
					{productionData.map(production => (
						<tr key={production.statusCode}>
							<td className="p-2">
								<b>{production.statusCode}</b> - {production.statusLabel}
							</td>
							<td className="border-l p-2">{`€ ${numberFormat(production.last_twelve_months_sum ?? 0 / 1000, 0)}`}</td>
							<td className="p-2">{numberFormat(production.last_twelve_months_count ?? 0, 0)}</td>
							<td className="border-l p-2">{`€ ${numberFormat(production.current_year_sum ?? 0 / 1000, 0)}`}</td>
							<td className=" p-2">{numberFormat(production.current_year_count ?? 0, 0)}</td>
							<td className="border-l p-2">{`€ ${numberFormat(production.previous_year_sum ?? 0 / 1000, 0)}`}</td>
							<td className=" p-2">{numberFormat(production.previous_year_count ?? 0, 0) ?? 0}</td>
						</tr>
					))}
				</table>
			)}
		</div>
	);
}

CommercialProductionBlock.propTypes = {
	companyId: PropTypes.number,
};
