import PropTypes from "prop-types";
import { uctrans } from "../../../../../../js/lib/Translator";

export default function MediaSelectorDocument({ document, onRemove }) {
	return (
		<div className="w-full">
			<span className="w-3/4 break-words" title={document.name}>
				{document.name}
			</span>
			<br />
			<a className="link" onClick={onRemove}>
				{uctrans("media.change_document")}
			</a>
		</div>
	);
}

MediaSelectorDocument.propTypes = {
	document: PropTypes.object.isRequired,
	onRemove: PropTypes.func.isRequired,
};
