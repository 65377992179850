import PropTypes from "prop-types";

export default function Url(props) {
	return (
		<>
			{props.label && <label>{props.label}</label>}
			<input
				type="text"
				value={props.value == null ? "" : props.value}
				onChange={e => {
					props.onChange(e.target.value);
				}}
			/>
		</>
	);
}

Url.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};
