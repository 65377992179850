import cloneDeep from "lodash/cloneDeep";
import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";
import { Component } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DeleteDialog from "../../../../../js/react/components/general/DeleteDialog";
import Translate from "../../../../../js/react/components/general/Translate";
import CollapsibleContent from "../general/CollapsibleContent";
import QuestionDialog from "./QuestionDialog";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import findIndex from "lodash/findIndex";

class QuestionList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pages: this.props.pages,
			showDeleteQuestionDialog: false,
			showEditQuestionDialog: false,
			showAddQuestionDialog: false,
			currentQuestion: {},
			currentQuestionIndex: null,
			currentSelectableQuestionsForDependencies: [],
			currentQuestionDeleteData: {
				errors: [],
				warnings: [{ text: uctrans("questionnaires.question.delete_warning") }],
			},
		};

		this.openDeleteQuestionDialog = this.openDeleteQuestionDialog.bind(this);
		this.openEditQuestionDialog = this.openEditQuestionDialog.bind(this);
		this.openAddQuestionDialog = this.openAddQuestionDialog.bind(this);
		this.handleQuestionSubmit = this.handleQuestionSubmit.bind(this);
		this.handleQuestionDeleteConfirm = this.handleQuestionDeleteConfirm.bind(this);
		this.getSelectableQuestionsForDependencies = this.getSelectableQuestionsForDependencies.bind(this);
		this.closeQuestionDialog = this.closeQuestionDialog.bind(this);
		this.duplicateQuestion = this.duplicateQuestion.bind(this);
		this.updateQuestions = this.updateQuestions.bind(this);
	}

	openDeleteQuestionDialog(question) {
		this.setState({
			showDeleteQuestionDialog: true,
			currentQuestion: question,
		});
	}

	openEditQuestionDialog(question) {
		const selectableQuestionsForDependencies = this.getSelectableQuestionsForDependencies(
			findIndex(this.props.questions, ["id", question.id]),
		);

		this.setState({
			showEditQuestionDialog: true,
			currentQuestion: question,
			currentSelectableQuestionsForDependencies: selectableQuestionsForDependencies,
		});
	}

	openAddQuestionDialog(pageId) {
		const calculatedNewIndex = this.calculateQuestionIndex(pageId);
		const newQuestion = {
			id: null,
			page_id: pageId,
			content: "",
			description: "",
			required: false,
			active: true,
		};

		this.setState({
			showAddQuestionDialog: true,
			currentQuestionIndex: calculatedNewIndex,
			currentSelectableQuestionsForDependencies: this.getSelectableQuestionsForDependencies(calculatedNewIndex),
			currentQuestion: newQuestion,
		});
	}

	getSelectableQuestionsForDependencies(questionIndex) {
		const selectableQuestions = [];
		this.props.questions.forEach((propQuestion, propQuestionIndex) => {
			if (
				propQuestion.type === this.props.questionTypes.MC_SELECT.key ||
				propQuestion.type === this.props.questionTypes.MC_OPTIONS_SINGLE.key ||
				propQuestion.type === this.props.questionTypes.MC_OPTIONS_MULTI.key
			) {
				if (propQuestionIndex > questionIndex) {
					propQuestion.dependency_warning = true;
				} else if (propQuestionIndex === questionIndex) {
					return;
				} else {
					propQuestion.dependency_warning = false;
				}
				selectableQuestions.push(propQuestion);
			}
		});

		return selectableQuestions;
	}

	duplicateQuestion(question) {
		const copiedQuestion = cloneDeep(question);
		const calculatedNewIndex = this.calculateQuestionIndex(question.page_id);

		copiedQuestion.id = null;

		this.setState({
			showAddQuestionDialog: true,
			currentQuestionIndex: calculatedNewIndex,
			currentQuestion: copiedQuestion,
			currentSelectableQuestionsForDependencies: this.getSelectableQuestionsForDependencies(calculatedNewIndex),
		});
	}

	updateQuestions(questions) {
		this.props.onQuestionsChange(questions);
	}

	updatePages(pages) {
		this.props.onPagesChange(pages);
	}

	handleQuestionSubmit(question) {
		const questions = this.props.questions.slice(0);
		const indexQuestionThatWasEdited = findIndex(questions, q => q.id === question.id);
		if (indexQuestionThatWasEdited !== -1) {
			questions.splice(indexQuestionThatWasEdited, 1, question);
		} else {
			// question is new, insert it the correct position
			questions.splice(this.state.currentQuestionIndex, 0, question);
		}
		this.updateQuestions(questions);
		this.closeQuestionDialog();
	}

	handleQuestionDeleteConfirm(question) {
		const questions = this.props.questions.slice();
		const indexQuestionThatWillBeDeleted = findIndex(questions, q => q.id === question.id);

		if (indexQuestionThatWillBeDeleted !== -1) {
			questions.splice(indexQuestionThatWillBeDeleted, 1);
		}

		this.updateQuestions(questions);
		this.closeQuestionDialog();
	}

	closeQuestionDialog() {
		this.setState({
			showDeleteQuestionDialog: false,
			showEditQuestionDialog: false,
			showAddQuestionDialog: false,
		});
	}

	onDragEnd(dragEvent) {
		const { source, destination } = dragEvent;

		// dropped outside the list
		if (!destination) {
			return;
		}

		// get the question
		const sourceIndex = dragEvent.source.index;
		const targetIndex = dragEvent.destination.index;
		const questions = this.props.questions.slice(0);
		const questionsOnSourcePage = questions.filter(question => question.page_id === parseInt(source.droppableId));
		const sourceQuestion = questionsOnSourcePage[sourceIndex];

		if (!sourceQuestion) {
			// Cant find source question, no good
			return;
		}

		if (`${source.droppableId}` === `${destination.droppableId}`) {
			const targetQuestion = questionsOnSourcePage[targetIndex];

			if (!targetQuestion) {
				// Cant find target question, no good
				return;
			}

			if (`${sourceIndex}` !== `${targetIndex}`) {
				const sourceIndexInQuestionArray = questions.findIndex(q => q.id === sourceQuestion.id);
				const targetIndexInQuestionArray = questions.findIndex(q => q.id === targetQuestion.id);

				questions.splice(sourceIndexInQuestionArray, 1);
				questions.splice(targetIndexInQuestionArray, 0, sourceQuestion);
				this.updateQuestions(questions);
			}
		} else {
			// dropped on different page splice it in the correct position in the question array and update the page_id
			const targetPageId = parseInt(destination.droppableId);
			const questionsOnTargetPage = questions.filter(question => question.page_id === targetPageId);
			const targetQuestion = questionsOnTargetPage[targetIndex];
			let targetIndexInQuestionArray = 0;
			if (!targetQuestion) {
				// It was moved to the last position of the page. now find the position of that page in correlation to the questions
				targetIndexInQuestionArray = this.calculateQuestionIndex(targetPageId);
			} else {
				targetIndexInQuestionArray = questions.findIndex(q => q.id === targetQuestion.id);
			}
			sourceQuestion.page_id = targetPageId;

			const sourceIndexInQuestionArray = questions.findIndex(q => q.id === sourceQuestion.id);

			questions.splice(sourceIndexInQuestionArray, 1);
			questions.splice(targetIndexInQuestionArray, 0, sourceQuestion);
			this.updateQuestions(questions);
		}
	}

	calculateQuestionIndex(targetPageId) {
		let questionPosition = 0;
		for (let i = 0; i < this.props.pages.length; i++) {
			const page = this.props.pages[i];
			const questionsOnPage = this.props.questions.filter(question => question.page_id === page.id);
			if (questionsOnPage.length > 0) {
				questionPosition += questionsOnPage.length;
			}
			if (targetPageId === page.id) {
				// targetPage is current page so we can just add it to the end of this one
				return questionPosition;
			}
		}
	}

	getQuestionsByPageId(pageId) {
		return this.props.questions.filter(question => question.page_id === pageId);
	}

	static getDraggingClassNames(isDragging) {
		if (isDragging) {
			return "active";
		}

		return "inactive";
	}

	static getListStyle(isDraggingOver) {
		return {
			background: isDraggingOver ? "lightblue" : "lightgrey",
		};
	}

	render() {
		return (
			<>
				<h4 className="inline-block">{uctrans("questionnaires.question.plural")}</h4>
				<div className="page-list ml-3">
					<DragDropContext onDragEnd={dragEvent => this.onDragEnd(dragEvent)}>
						{this.state.pages.map(({ ...page }, pageIndex) => (
							<div key={pageIndex} id={`page-item-${page.id}`}>
								<CollapsibleContent initialCollapseState={false} title={page.title} className="mb-12">
									<Droppable droppableId={page.id.toString()}>
										{(provided, snapshot) => (
											<div
												className="question-list overview-item"
												ref={provided.innerRef}
												{...provided.droppableProps}
												{...provided.dragHandleProps}
												style={QuestionList.getListStyle(snapshot.isDraggingOver)}>
												{this.getQuestionsByPageId(page.id).map((question, index) => (
													<Draggable
														key={question.id}
														draggableId={question.id.toString()}
														index={index}
														isDragDisabled={this.props.questions.length <= 1}>
														{(provided, snapshot) => (
															<div
																{...provided.dragHandleProps}
																ref={provided.innerRef}
																{...provided.draggableProps}
																className={`question-list-item form-container draggable-overview-item draggable-item dragging-${QuestionList.getDraggingClassNames(
																	snapshot.isDragging,
																)}`}>
																<div className="draggable-overview-item-handle mr-3" />
																<div className="answer-number">
																	<p>{index + 1}. &nbsp; </p>
																</div>
																<div className="question">
																	<a className="link" onClick={() => this.openEditQuestionDialog(question, index)}>
																		{!question.active && (
																			<>
																				<EvaIcon type="eye-off-outline" height="18" width="18" />
																				&nbsp;
																			</>
																		)}
																		<div
																			dangerouslySetInnerHTML={{ __html: question.content }}
																			style={{ float: "left" }}
																		/>
																		{!question.required && (
																			<div style={{ float: "left" }}>
																				&nbsp;(
																				<Translate content="general.optional" ucfirst={false} />)
																			</div>
																		)}
																	</a>
																</div>
																<div className="actions ml-auto mb-0 mr-6">
																	{question.dependency_questions && (
																		<Tippy
																			placement="right"
																			arrow
																			animation="perspective"
																			duration="400"
																			content={uctrans("questionnaires.dependency.is_active_on_question")}>
																			<span className="ml-5">
																				<EvaIcon type="eye-off-outline" height="18" width="18" fill="#009286" />
																			</span>
																		</Tippy>
																	)}
																	<span className="ml-5" onClick={() => this.duplicateQuestion(question)}>
																		<EvaIcon type="copy-outline" height="18" width="18" fill="#009286" />
																	</span>
																	<span className="ml-5" onClick={() => this.openDeleteQuestionDialog(question, index)}>
																		<EvaIcon type="trash-2-outline" height="18" width="18" fill="#009286" />
																	</span>
																</div>
															</div>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
									<a
										className="add-question-button button button-secondary"
										onClick={() => this.openAddQuestionDialog(page.id)}>
										{uctrans("questionnaires.add_question")}
									</a>
									<a
										className="edit-page-button button button-tertiary ml-5"
										onClick={() => this.props.openEditPageDialog(page, pageIndex)}>
										{uctrans("questionnaires.edit_page")}
									</a>
									<a
										className="delete-page-button button button-tertiary ml-5"
										onClick={() => this.props.openDeletePageDialog(page, pageIndex)}>
										{uctrans("questionnaires.delete_page")}
									</a>

									<a className="button button-tertiary ml-5" onClick={() => this.props.movePage(page, pageIndex, -1)}>
										<EvaIcon type="arrow-upward-outline" fill="#009286" height="18" width="18" />
									</a>

									<a className="button button-tertiary ml-5" onClick={() => this.props.movePage(page, pageIndex, 1)}>
										<EvaIcon type="arrow-downward-outline" fill="#009286" height="18" width="18" />
									</a>
								</CollapsibleContent>
							</div>
						))}
					</DragDropContext>
				</div>

				<QuestionDialog
					questionnaireType={this.props.questionnaireType}
					questionnaireTypes={this.props.questionnaireTypes}
					closeQuestionDialog={this.closeQuestionDialog}
					showAddQuestionDialog={this.state.showAddQuestionDialog}
					showEditQuestionDialog={this.state.showEditQuestionDialog}
					currentQuestion={this.state.currentQuestion}
					currentSelectableQuestionsForDependencies={this.state.currentSelectableQuestionsForDependencies}
					questionTypes={this.props.questionTypes}
					dependencyTypes={this.props.dependencyTypes}
					questionFieldTypes={this.props.questionFieldTypes}
					questionColors={this.props.questionColors}
					administrativeOptions={this.props.administrativeOptions}
					handleQuestionSubmit={question => this.handleQuestionSubmit(question)}
					baseQuestionStoreUrl={this.props.baseQuestionStoreUrl}
					annuallyCooperationCheckTags={this.props.annuallyCooperationCheckTags}
					relationManagerQuestionnaireTags={this.props.relationManagerQuestionnaireTags}
				/>

				<DeleteDialog
					onConfirm={() => this.handleQuestionDeleteConfirm(this.state.currentQuestion)}
					onCancel={this.closeQuestionDialog}
					isOpen={this.state.showDeleteQuestionDialog}
					deleteData={this.state.currentQuestionDeleteData}
				/>
			</>
		);
	}
}

export default QuestionList;

QuestionList.propTypes = {
	questions: PropTypes.array.isRequired,
	pages: PropTypes.array.isRequired,
	questionColors: PropTypes.array,
	administrativeOptions: PropTypes.array,
	questionTypes: PropTypes.object.isRequired,
	dependencyTypes: PropTypes.array.isRequired,
	questionFieldTypes: PropTypes.object.isRequired,
	onQuestionsChange: PropTypes.func.isRequired,
	onPagesChange: PropTypes.func.isRequired,
	openEditPageDialog: PropTypes.func.isRequired,
	openDeletePageDialog: PropTypes.func.isRequired,
	baseQuestionStoreUrl: PropTypes.string.isRequired,
	questionnaireType: PropTypes.number.isRequired,
	questionnaireTypes: PropTypes.object.isRequired,
	annuallyCooperationCheckTags: PropTypes.object.isRequired,
	relationManagerQuestionnaireTags: PropTypes.object,
	movePage: PropTypes.func.isRequired,
};
