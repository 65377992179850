import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import cloneDeep from "lodash/cloneDeep";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";

export default function OccupationForm(props) {
	const [formData, setFormData] = useState(
		typeof props.occupationFormData === "undefined"
			? cloneDeep(window.data.occupation_form_data)
			: props.occupationFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.occupationStaticData === "undefined"
			? cloneDeep(window.data.occupation_static_data)
			: props.occupationStaticData,
	);

	const inputHandler = useInputHandler(setFormData);

	useEffect(() => {
		if (typeof props.occupationFormData !== "undefined") {
			setFormData(props.occupationFormData);
		}
	}, [props.occupationFormData]);

	useEffect(() => {
		if (typeof props.occupationStaticData !== "undefined") {
			setStaticData(props.occupationStaticData);
		}
	}, [props.occupationStaticData]);

	const onSuccess = response => {
		if (!staticData.creating) {
			setFormData(response.data.formvalues);
			setStaticData(response.data.occupation_static_data);
		} else {
			Nav.go(response.data.redirect_route);
		}
	};

	return (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{ label: uctrans("general.general_settings") },
					{ label: uctrans("occupations.plural"), route: staticData.previous_link },
					{
						label: staticData.creating
							? uctrans("general.add_:item", {}, { item: "occupations.singular" })
							: formData.name,
					},
				]}
			/>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(
						staticData.creating ? "general.add_:item" : "general.edit_:item",
						{},
						{ item: "occupations.singular" },
					)}
				</h1>
				{staticData.previous_link && (
					<a className="link" href={staticData.previous_link}>
						{uctrans("general.back_to_overview_:page", {}, { page: "occupations.plural" })}
					</a>
				)}
			</div>
			<AjaxForm
				method={staticData.save_method}
				submitUrl={staticData.save_link}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "occupations.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "occupations.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage={staticData.creating}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-1-3">
								<LabeledTextInput
									name="name"
									value={formData.name}
									onChange={inputHandler}
									label="occupations.fields.name"
									required
								/>
							</div>

							<div className="form-full">
								<div className="form-1-3">
									<Checkmark
										onChange={inputHandler}
										name="is_open"
										checked={formData.is_open}
										label={uctrans("occupations.fields.is_open_example")}
									/>
								</div>
							</div>

							{formData.is_open && (
								<div className="form-full">
									<div className="form-1-3">
										<LabeledTextInput
											name="open_title"
											value={formData.open_title}
											onChange={inputHandler}
											label="occupations.fields.title_is_open"
											required
										/>
									</div>
								</div>
							)}

							<div className="form-full">
								<Checkmark
									onChange={inputHandler}
									name="is_director"
									checked={formData.is_director}
									label={uctrans("occupations.fields.is_director_jfm")}
								/>
							</div>

							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="segments.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
OccupationForm.propTypes = {
	occupationFormData: PropTypes.object,
	occupationStaticData: PropTypes.object,
};
