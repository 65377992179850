import PropTypes from "prop-types";

export default function SimpleTableColumn() {}
SimpleTableColumn.displayName = "SimpleTableColumn";

SimpleTableColumn.propTypes = {
	cellWidth: PropTypes.string.isRequired,
	renderBodyContent: PropTypes.func.isRequired,
	renderHeaderContent: PropTypes.func.isRequired,
};
