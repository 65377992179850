import UpperCaseTextInput from "../../../../../js/react/components/general/form/UpperCaseTextInput";
import ZipCodeTextInput from "../../../../../js/react/components/general/form/ZipCodeTextInput";
import { error } from "../../../../../js/react/components/general/notifications";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import Dialog from "../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";

export default function ClientTreatmentLocations(props) {
	const [locations, setLocations] = useState(props.locations);
	const [activeLocation, setActiveLocation] = useState(null);

	useEffect(() => {
		if (typeof props.locations !== "undefined") {
			setLocations(props.locations);
		}
	}, [props.locations]);

	const handleChange = useCallback(
		locations => {
			props.onChange(locations);
			setLocations(locations);
		},
		[props],
	);

	const handleDialogSave = () => {
		if (activeLocation !== null) {
			if (activeLocation.values.name && activeLocation.values.name !== "") {
				const newLocations = Array.isArray(locations) ? [...locations] : [];
				if (activeLocation.creating) {
					newLocations.push(activeLocation.values);
				} else {
					newLocations[activeLocation.index] = activeLocation.values;
				}
				handleChange(newLocations);
				setActiveLocation(null);
			} else {
				error(uctrans("client_treatment.fields.no_name_filled"));
			}
		}
	};

	const handleDialogCancel = () => {
		setActiveLocation(null);
	};

	const openCreateDialog = () => {
		setActiveLocation({
			creating: true,
			index: -1,
			label: uctrans("general.add_:item", {}, { item: "client_treatment.fields.location" }),
			values: {
				address: {},
			},
		});
	};

	const changeActiveLocationName = (key, value) => {
		if (activeLocation !== null) {
			const newActiveLocation = { ...activeLocation };
			newActiveLocation.values[key] = value;
			setActiveLocation(newActiveLocation);
		}
	};

	const changeActiveLocationAddresValue = (key, value) => {
		if (activeLocation !== null) {
			const newActiveLocation = { ...activeLocation };
			newActiveLocation.values.address[key] = value;
			setActiveLocation(newActiveLocation);
		}
	};

	const openEditDialog = (index, values) => {
		setActiveLocation({
			creating: false,
			index,
			label: uctrans("general.edit_:item", {}, { item: "client_treatment.fields.location" }),
			values: cloneDeep(values),
		});
	};

	const deleteLocation = deleteIndex => {
		let newLocations = [...locations];
		newLocations = newLocations.filter((location, index) => Number(index) !== Number(deleteIndex));
		handleChange(newLocations);
	};

	const checkLocation = () => {
		const location = `${activeLocation.values.address.street} ${activeLocation.values.address.number} ${activeLocation.values.address.addition} ${activeLocation.values.address.zipcode} ${activeLocation.values.address.city}`;
		const url = `https://www.google.com/maps/dir//${encodeURI(location)}/`;
		window.open(url, "_blank");
	};

	return (
		<>
			{locations.map((location, index) => (
				<div key={index} className="bg-primary-lightest blocks-center px-3 py-3 my-2 ">
					<div className="flex justify-between">
						<span>{location.name}</span>

						<div className="flex blocks-center">
							<span
								className="cursor-pointer"
								onClick={() => {
									openEditDialog(index, location);
								}}>
								<EvaIcon className="flex blocks-center" type="edit-outline" width="20" height="20" fill="#009286" />
							</span>
							{!props.disabled && (
								<span
									className="cursor-pointer"
									onClick={() => {
										deleteLocation(index);
									}}>
									<EvaIcon
										className="flex blocks-center"
										type="trash-2-outline"
										width="20"
										height="20"
										fill="#009286"
									/>
								</span>
							)}
						</div>
					</div>
				</div>
			))}
			{!props.disabled && (
				<button type="button" className="button button-secondary" onClick={openCreateDialog}>
					{uctrans("general.add_:item", {}, { item: "client_treatment.fields.location" })}
				</button>
			)}

			<Dialog
				isOpen={activeLocation !== null}
				onClose={handleDialogCancel}
				shouldCloseOnOverlayClick={false}
				width={300}
				title={activeLocation && activeLocation.label}>
				{activeLocation !== null && (
					<>
						<div className="form-full">
							<LabeledTextInput
								label="client_treatment.fields.name"
								value={activeLocation.values.name}
								name="name"
								required
								onChange={e => changeActiveLocationName("name", e.target.value)}
								disabled={props.disabled}
							/>
						</div>
						<div className="form-full">
							<LabeledTextInput
								name="street"
								label="addresses.fields.street"
								value={activeLocation.values.address.street}
								onChange={e => changeActiveLocationAddresValue("street", e.target.value)}
								disabled={props.disabled}
							/>
						</div>
						<div className="form-full">
							<div className="form-2-3">
								<LabeledTextInput
									name="number"
									label="addresses.fields.number"
									value={activeLocation.values.address.number}
									onChange={e => changeActiveLocationAddresValue("number", e.target.value)}
									disabled={props.disabled}
								/>
							</div>
							<div className="form-1-3">
								<LabeledTextInput
									name="addition"
									label="addresses.fields.addition"
									value={activeLocation.values.address.addition}
									onChange={e => changeActiveLocationAddresValue("addition", e.target.value)}
									disabled={props.disabled}
								/>
							</div>
						</div>
						<div className="form-full">
							<ZipCodeTextInput
								name="zipcode"
								label="addresses.fields.zipcode"
								value={activeLocation.values.address.zipcode}
								onChange={e => changeActiveLocationAddresValue("zipcode", e.target.value)}
								disabled={props.disabled}
							/>
						</div>
						<div className="form-full">
							<UpperCaseTextInput
								name="city"
								label="addresses.fields.city"
								value={activeLocation.values.address.city}
								onChange={e => changeActiveLocationAddresValue("city", e.target.value)}
								disabled={props.disabled}
							/>
						</div>

						<a className="link" onClick={() => checkLocation()}>
							{uctrans("client_treatment.locations.check_location")}
						</a>

						<div className="form-full">
							<div className="mt-6">
								{!props.disabled && (
									<button type="button" className="button button-primary button-dialog" onClick={handleDialogSave}>
										{uctrans("general.ok")}
									</button>
								)}

								<a onClick={handleDialogCancel}>{uctrans("general.cancel")}</a>
							</div>
						</div>
					</>
				)}
			</Dialog>
		</>
	);
}
ClientTreatmentLocations.propTypes = {
	locations: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
};

ClientTreatmentLocations.defaultProps = {
	disabled: false,
};
