import { useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DeleteColumnContent from "../../../../../js/react/components/general/overview/DeleteColumnContent";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import Translate from "../../../../../js/react/components/general/Translate";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import IconButton from "../../../../../js/react/components/general/IconButton";

export default function OccupationOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
		create_link: window.data.occupation_create_link,
		index_link: window.data.occupation_index_link,
		permissions: window.data.occupation_permissions,
	});

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("general.general_settings") }]} />

			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="occupations.plural" />
				</h1>
				{overviewData.create_link && (
					<div>
						<IconButton
							href={overviewData.create_link}
							content={uctrans("general.add_:item", {}, { item: "occupations.singular" })}
							primary
						/>
					</div>
				)}
			</div>
			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={overviewData.index_link}
				modelTranslations={{ plural: uctrans("occupations.plural"), singular: uctrans("occupations.singular") }}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
						<SelectFilter
							className="form-1-3"
							name="is_director"
							label={uctrans("occupations.fields.is_director")}
							options={overviewData.overview.filterdata.director_options}
							collapsible
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={13}
							sortable="name"
							renderHeaderContent={() => uctrans("occupations.fields.name")}
							renderCellContent={occupation => (
								<a href={occupation.edit_link} className="row-title link">
									{occupation.name}
								</a>
							)}
						/>
						<DataColumn
							width={3}
							sortable="is_open"
							renderHeaderContent={() => uctrans("occupations.fields.is_open")}
							renderCellContent={occupation => (occupation.is_open ? uctrans("general.yes") : uctrans("general.no"))}
						/>
						{overviewData.permissions.delete && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(occupation, overview) =>
									occupation.delete_link && <DeleteColumnContent onClick={() => overview.askDelete(occupation)} />
								}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
