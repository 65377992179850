import PropTypes from "prop-types";
import { uctrans } from "../../../../lib/Translator";
import DatePickerFilter from "./DatePickerFilter";
import Filter from "./Filter";
import SelectFilter from "./SelectFilter";
import classNames from "classnames";

export default class PeriodicOverviewFilter extends Filter {
	constructor(props) {
		super(props);
		this.handleFilterChange = this.handleFilterChange.bind(this);
		this.onPeriodTypesChange = this.onPeriodTypesChange.bind(this);
	}

	handleFilterChange(context, data) {
		data.metadata.pagination.page = 1;
		context.setData(data);
	}

	onPeriodTypesChange(context, value) {
		const formValues = this.props.formValues || {};

		const clearDateType = (data, key, type) => {
			const dateKey = `${key}_${type}`;
			const yearKey = `${key}_year`;
			data.metadata.filtering.filters[key] = null;
			data.metadata.filtering.filters[dateKey] = null;
			data.metadata.filtering.filters[yearKey] = null;
			return data;
		};

		const setDefaultDateType = (data, key, type) => {
			const dateKey = `${key}_${type}`;
			const yearKey = `${key}_year`;
			data.metadata.filtering.filters[key] = `${formValues.year}${formValues[type]}`;
			data.metadata.filtering.filters[dateKey] = formValues[type];
			data.metadata.filtering.filters[yearKey] = formValues.year;
			return data;
		};

		let data = { ...context.data };
		data.metadata.filtering.filters.periodType = value;

		// Clear old filters
		data.metadata.filtering.filters.year = null;
		data = clearDateType(data, "month", "month");
		data = clearDateType(data, "week", "week");
		data = clearDateType(data, "fromMonth", "month");
		data = clearDateType(data, "toMonth", "month");
		data = clearDateType(data, "fromWeek", "week");
		data = clearDateType(data, "toWeek", "week");
		data.metadata.filtering.filters.fromDate = null;
		data.metadata.filtering.filters.toDate = null;

		// set default filter
		switch (value) {
			case "year":
				data.metadata.filtering.filters.year = formValues.year;
				break;
			case "month":
				data = setDefaultDateType(data, "month", "month");
				break;
			case "week":
				data = setDefaultDateType(data, "week", "week");
				break;
			case "month_range":
				data = setDefaultDateType(data, "fromMonth", "week");
				data = setDefaultDateType(data, "toMonth", "week");
				break;
			case "week_range":
				data = setDefaultDateType(data, "fromWeek", "week");
				data = setDefaultDateType(data, "toWeek", "week");
				break;
			case "date_range":
				data.metadata.filtering.filters.fromDate = formValues.fromDate;
				data.metadata.filtering.filters.toDate = formValues.toDate;
				break;
			default:
				break;
		}

		this.handleFilterChange(context, data);
	}

	renderAs(context) {
		const { formInfo } = this.props;

		const { filters } = context.data.metadata.filtering;

		const dateTypeWithYearFilter = (key, type, dateLabel = null) => {
			const dateKey = `${key}_${type}`;
			const yearKey = `${key}_year`;

			return (
				<>
					<div className="w-1/8 pr-2">
						<SelectFilter
							name={dateKey}
							label={uctrans(`report.${dateLabel || type}`)}
							options={formInfo[`${type}s`]}
							handleChange={value => {
								const data = { ...context.data };
								const year = filters[yearKey] || "";
								data.metadata.filtering.filters[key] = `${year}${value}`;
								data.metadata.filtering.filters[dateKey] = value;
								this.handleFilterChange(context, data);
							}}
						/>
					</div>
					<div className="w-1/8 pr-2">
						<SelectFilter
							name={yearKey}
							label={uctrans("report.year")}
							options={formInfo.years}
							handleChange={value => {
								const data = { ...context.data };
								if (value !== "") {
									const date = filters[dateKey] || "";
									data.metadata.filtering.filters[key] = `${value}${date}`;
								}
								data.metadata.filtering.filters[yearKey] = value;
								this.handleFilterChange(context, data);
							}}
						/>
					</div>
				</>
			);
		};

		return (
			<div className={classNames("overview-filter", this.props.className)}>
				<div className="flex w-full">
					{formInfo && (
						<>
							<div className="w-1/4 pr-2">
								<SelectFilter
									name="periodType"
									label={uctrans("report.period_type")}
									options={formInfo.periodTypes}
									handleChange={value => this.onPeriodTypesChange(context, value)}
								/>
							</div>
							{filters.periodType === "week" && dateTypeWithYearFilter("week", "week")}
							{filters.periodType === "month" && dateTypeWithYearFilter("month", "month")}
							{filters.periodType === "year" && (
								<div className="w-1/8 pr-2">
									<SelectFilter name="year" label={uctrans("report.year")} options={formInfo.years} />
								</div>
							)}
							{filters.periodType === "week_range" && (
								<>
									{dateTypeWithYearFilter("fromWeek", "week", "from")}
									{dateTypeWithYearFilter("toWeek", "week", "to")}
								</>
							)}
							{filters.periodType === "month_range" && (
								<>
									{dateTypeWithYearFilter("fromMonth", "month", "from")}
									{dateTypeWithYearFilter("toMonth", "month", "to")}
								</>
							)}
							{filters.periodType === "date_range" && (
								<>
									<div className="w-1/8 pr-2">
										<DatePickerFilter name="fromDate" label={uctrans("report.from")} />
									</div>
									<div className="w-1/8 pr-2">
										<DatePickerFilter name="toDate" label={uctrans("report.to")} />
									</div>
								</>
							)}
						</>
					)}
				</div>
			</div>
		);
	}
}

PeriodicOverviewFilter.propTypes = {
	formInfo: PropTypes.object.isRequired,
	formValues: PropTypes.object,
};
