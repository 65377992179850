import { useState } from "react";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import Select from "../../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import Translate from "../../../../../../js/react/components/general/Translate";
import useInputHandler from "../../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import Nav from "../../../../../../js/lib/Nav";
import { route } from "../../../../../../js/helpers";
import find from "lodash/find";
import Navigation from "../Navigation";

export default function DossierPartForm() {
	const [formData, setFormData] = useState({ ...window.data.dossier_part_values });
	const dossierPartFieldTypesObjects = window.data.dossier_part_types_objects;
	const dossierPartFieldTypes = window.data.dossier_part_types;
	const dossierPartEstablishmentTypes = window.data.dossier_part_establishment_types;

	const creating = window.data.dossier_part_values.id === -1;
	const inputHandler = useInputHandler(setFormData);

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	const currentSelectedFieldType = find(
		dossierPartFieldTypesObjects,
		type => Number(type.key) === Number(formData.type),
	);

	const onSuccess = () => {
		Nav.go(route("crm.dossier-settings.dossier-parts.index"));
	};

	return (
		<>
			<Navigation selectedTab="dossier-parts" />

			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(creating ? "general.add_:item" : "general.edit_:item", {}, { item: "dossiers.parts.singular" })}
				</h1>

				<a className="link" href={route("crm.dossier-settings.dossier-parts.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "dossiers.parts.plural" })}
				</a>
			</div>
			<AjaxForm
				method={creating ? "POST" : "PUT"}
				submitUrl={
					creating
						? route("crm.dossier-settings.dossier-parts.store")
						: route("crm.dossier-settings.dossier-parts.update", formData.id)
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "dossiers.parts.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "dossiers.parts.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="name"
										value={formData.name}
										onChange={inputHandler}
										label="dossiers.parts.fields.name"
										required
									/>
								</div>
							</div>
							<div className="form-full">
								<div className="form-1-4">
									<label htmlFor="parent_id">
										<Translate content="dossiers.parts.fields.type" />
										<span className="text-orange"> *</span>
									</label>
									<Select
										isClearable
										name="type"
										options={dossierPartFieldTypes}
										placeholder={uctrans("general.select_:item", {}, { item: "dossiers.parts.fields.type" })}
										value={dossierPartFieldTypes.filter(({ value }) => Number(value) === Number(formData.type))}
										onChange={value => handleFormValueChange("type", value ? value.value : "")}
									/>
								</div>
								{currentSelectedFieldType && currentSelectedFieldType.field_name && (
									<div className="form-1-4">
										<LabeledTextInput
											name="field_name"
											value={formData.field_name}
											onChange={inputHandler}
											label="dossiers.parts.fields.field_name"
										/>
									</div>
								)}
							</div>
							{currentSelectedFieldType &&
								(currentSelectedFieldType.establishmentType === dossierPartEstablishmentTypes.STATUTORY.key ||
									currentSelectedFieldType.establishmentType === dossierPartEstablishmentTypes.BOTH.key) && (
									<div className="form-full">
										<div className="form-1-4">
											<Checkmark
												label={uctrans("dossiers.parts.fields.statutory")}
												name="statutory"
												checked={formData.statutory}
												onChange={e => handleFormValueChange(e.target.name, e.target.checked)}
											/>
										</div>
									</div>
								)}

							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="dossiers.parts.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
