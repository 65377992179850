import PropTypes from "prop-types";

export default function ClusterMarker(props) {
	return (
		<div
			style={{
				color: "white",
				background: "#2B9286",
				width: "40px",
				height: "40px",
				display: "inline-flex",
				textAlign: "center",
				alignItems: "center",
				justifyContent: "center",
				borderRadius: "100%",
				transform: "translate(-50%, -50%)",
			}}
			onClick={props.onClick}>
			{props.nrOfItems}
		</div>
	);
}

ClusterMarker.propTypes = {
	nrOfItems: PropTypes.number,
	onClick: PropTypes.func,
};
