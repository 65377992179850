import axios from "axios";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import SingleSelectAutoComplete from "../../../../../js/react/components/general/autocomplete/SingleSelectAutoComplete";
import Dialog from "../../../../../js/react/components/general/Dialog";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import { createLoader } from "../../../../../js/react/components/general/notifications";

export default function CopyCompanyDataDialog(props) {
	const [loading, setLoading] = useState(false);
	const [sourceCompanyId, setSourceCompanyId] = useState(null);
	const [contactDataChecked, setContactDataChecked] = useState(true);
	const [extraInfoChecked, setExtraInfoChecked] = useState(true);
	const [communicationPreferencesChecked, setCommunicationPreferencesChecked] = useState(true);
	const [contactpersonsChecked, setContactpersonsChecked] = useState(true);

	useEffect(() => {}, []);

	const handleCopyCompanyData = () => {
		const loader = createLoader(uctrans("intermediaries.company_data_being_copied"));
		if (!sourceCompanyId) {
			loader.failure(uctrans("intermediaries.company_source_required"));
			return;
		}
		setLoading(true);
		axios
			.post(route("crm.intermediaries.copy-company-data", [props.targetCompanyId]), {
				contactDataChecked,
				extraInfoChecked,
				communicationPreferencesChecked,
				contactpersonsChecked,
				sourceCompanyId,
			})
			.then(() => {
				loader.success(uctrans("intermediaries.company_data_copied"));
			})
			.catch(error => {
				console.warn(error);
				loader.failure(uctrans("general.something_went_wrong"));
			})
			.then(() => {
				setLoading(false);
				props.onSuccess();
			});
	};

	return (
		<Dialog isOpen={props.isOpen} onClose={props.onClose}>
			<div>
				<h2>{uctrans("intermediaries.copy_company_data")}</h2>
				<div className="form-full">
					<p>{uctrans("intermediaries.copy_company_data_from")}</p>
				</div>
				<div className="form-full">
					<SingleSelectAutoComplete
						name="company_id"
						dataSource={route("crm.intermediaries.search-fallen").toString()}
						item={sourceCompanyId}
						initialItem={sourceCompanyId}
						onChange={item => setSourceCompanyId(item ? item.value : null)}
						isClearable
						placeholder={uctrans("general.type_to_add_:item", {}, { item: "companies.singular" })}
						async
					/>
				</div>
				<div className="form-full">
					<p>{uctrans("intermediaries.subjects_to_be_copied")}</p>
				</div>
				<div className="form-full">
					<Checkmark
						checked={contactDataChecked}
						onChecked={() => setContactDataChecked(true)}
						onUnchecked={() => setContactDataChecked(false)}
						label={uctrans("intermediaries.copy_subjects.contact_data")}
					/>
				</div>
				<div className="form-full">
					<Checkmark
						checked={extraInfoChecked}
						onChecked={() => setExtraInfoChecked(true)}
						onUnchecked={() => setExtraInfoChecked(false)}
						label={uctrans("intermediaries.copy_subjects.extra_info")}
					/>
				</div>
				<div className="form-full">
					<Checkmark
						checked={communicationPreferencesChecked}
						onChecked={() => setCommunicationPreferencesChecked(true)}
						onUnchecked={() => setCommunicationPreferencesChecked(false)}
						label={uctrans("intermediaries.copy_subjects.communication_preferences")}
					/>
				</div>
				<div className="form-full">
					<Checkmark
						checked={contactpersonsChecked}
						onChecked={() => setContactpersonsChecked(true)}
						onUnchecked={() => setContactpersonsChecked(false)}
						label={uctrans("intermediaries.copy_subjects.contactpersons")}
					/>
				</div>
				<div className="form-full">
					<button
						type="button"
						className={`button button-primary button-dialog ${loading ? "disabled" : ""}`}
						onClick={() => handleCopyCompanyData()}>
						{uctrans("intermediaries.copy_company_data")}
					</button>
				</div>
			</div>
		</Dialog>
	);
}
CopyCompanyDataDialog.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	targetCompanyId: PropTypes.number.isRequired,
	onSuccess: PropTypes.func,
};

CopyCompanyDataDialog.defaultProps = {
	isOpen: false,
};
