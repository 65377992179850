import find from "lodash/find";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import { CmsTypesContext } from "../../cms-types-context";
import CTLanding from "../attributes/CTLanding";
import Document from "../attributes/Document";
import Newsitem from "../attributes/Newsitem";
import ProfileFilter from "../attributes/ProfileFilter";
import QuestionnaireSelector from "../attributes/QuestionnaireSelector";
import Text from "../attributes/Text";
import Url from "../attributes/Url";
import Select from "../attributes/Select";
import Boolean from "../attributes/Boolean";
import Page from "../attributes/Page";

export default function Link(props) {
	const [linkvalues, setLinkValues] = useState(props.values ? props.values : {});
	useEffect(() => {
		setLinkValues(props.values ? props.values : {});
	}, [props.values]);

	const handleLinkValueChange = (key, value) => {
		const newLinkvalues = { ...linkvalues };
		newLinkvalues[key] = value;
		setLinkValues(newLinkvalues);
		props.onChange(newLinkvalues);
	};

	const { partTypes } = useContext(CmsTypesContext);
	const partType = find(partTypes, partType => partType.key === (props.useText ? "link" : "linktarget"));

	if (partType) {
		return (
			<>
				{props.label && <h6>{props.label}</h6>}

				{props.useText && (
					<div className="form-container">
						<span className="form-full">
							<Text
								label={partType.attributes.text.label}
								value={linkvalues.text}
								onChange={value => {
									handleLinkValueChange("text", value);
								}}
							/>
						</span>
					</div>
				)}
				<div className="form-container">
					<span className="form-1-3">
						<Select
							label={partType.attributes.target.label}
							value={linkvalues.target}
							options={partType.attributes.target.options}
							onChange={value => {
								handleLinkValueChange("target", value);
							}}
						/>
					</span>
					<span className="form-2-3">
						{(linkvalues.target === "internal" || linkvalues.target === null) && (
							<Page
								label={partType.attributes.page.label}
								value={linkvalues.page}
								onChange={value => {
									handleLinkValueChange("page", value);
								}}
							/>
						)}
						{linkvalues.target === "news" && (
							<Newsitem
								label={partType.attributes.newsitem.label}
								value={linkvalues.newsitem}
								onChange={value => {
									handleLinkValueChange("newsitem", value);
								}}
							/>
						)}
						{linkvalues.target === "clienttreatment_landing" && (
							<CTLanding
								label={partType.attributes.ct_landing.label}
								value={linkvalues.ct_landing}
								onChange={value => {
									handleLinkValueChange("ct_landing", value);
								}}
							/>
						)}
						{linkvalues.target === "questionnaires" && (
							<QuestionnaireSelector
								label={partType.attributes.questionnaires.label}
								value={linkvalues.questionnaires}
								onChange={value => {
									handleLinkValueChange("questionnaires", value);
								}}
							/>
						)}
						{(linkvalues.target === "document" || linkvalues.target === null) && (
							<Document
								label={partType.attributes.document.label}
								value={linkvalues.document}
								onChange={value => {
									handleLinkValueChange("document", value);
								}}
							/>
						)}
						{linkvalues.target === "external" && (
							<Url
								label={uctrans("cms.attributes.url")}
								value={linkvalues.url}
								onChange={value => {
									handleLinkValueChange("url", value);
								}}
							/>
						)}
						{linkvalues.target === "mailto" && (
							<Url
								label={uctrans("cms.attributes.mailto_emailaddress")}
								value={linkvalues.url}
								onChange={value => {
									handleLinkValueChange("url", value);
								}}
							/>
						)}
					</span>
				</div>
				<div className="form-container">
					<div className="form-full">
						{(linkvalues.target === "internal" ||
							linkvalues.target === "external" ||
							linkvalues.target === "rm_ms_bookings" ||
							linkvalues.target === "team_ms_bookings" ||
							linkvalues.target === null) && (
							<Boolean
								label={partType.attributes.newtab.label}
								value={linkvalues.newtab}
								onChange={value => {
									handleLinkValueChange("newtab", value);
								}}
							/>
						)}
					</div>
				</div>
				<ProfileFilter
					label={uctrans("cms.attributes.filter_profile")}
					value={linkvalues.filter_profile}
					onChange={value => handleLinkValueChange("filter_profile", value)}
				/>
			</>
		);
	}
}

Link.propTypes = {
	label: PropTypes.string,
	values: PropTypes.shape({
		text: PropTypes.string,
		newtab: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
		target: PropTypes.string,
		page: PropTypes.number,
		newsitem: PropTypes.number,
		url: PropTypes.string,
		filter_profile: PropTypes.string,
	}),
	onChange: PropTypes.func.isRequired,
	useText: PropTypes.bool,
};
Link.defaultProps = {
	useText: true,
};
