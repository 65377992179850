import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import AddressForm from "../../../../../js/react/components/addresses/AddressForm";
import CommunicationPreferences from "../../../../../js/react/components/communication/CommunicationPreferences";
import IbanTextInput from "../../../../../js/react/components/general/form/IbanTextInput";
import ImageThumbnailFileInput from "../../../../../js/react/components/general/form/ImageThumbnailFileInput";
import PincodeTextInput from "../../../../../js/react/components/general/form/PincodeTextInput";
import Translate from "../../../../../js/react/components/general/Translate";
import cloneDeep from "lodash/cloneDeep";
import Select from "../../../../../js/react/components/general/Select";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import CompanyFormContext from "../company/CompanyFormContext";
import CollapsibleContent from "../general/CollapsibleContent";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import { can } from "../../../../../js/helpers";
import StatutoryCompanyForm from "./statutory/StatutoryCompanyForm";

export default function IntermediaryFormFields(props) {
	const [formData, setFormData] = useState(
		typeof props.companyFormData === "undefined" ? cloneDeep(window.data.company_form_data) : props.companyFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.companyStaticData === "undefined"
			? cloneDeep(window.data.company_static_data)
			: props.companyStaticData,
	);

	const [isProspect] = useState(Number(formData.status_id) === Number(staticData.company_statuses.PROSPECT.key));
	const [originalMainCompany] = useState(
		formData.statutory_company ? `${formData.statutory_company.main_company_id}` === `${formData.id}` : false,
	);
	const [hasStatutoryCompanyOriginal] = useState(formData.statutory_company_id !== null);

	const [companyStatutoryBranches] = useState(window.data.company_statutory_branches);

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
		props.onChange(newFormData);
	};

	const handleAddressInputChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData.addresses[key] = value;
		setFormData(newFormData);
		props.onChange(newFormData);
	};

	const readonly = useMemo(() => {
		if (!can("intermediary", "update_administrative") && !can("intermediary", "update_all")) {
			return true;
		}
	}, []);

	useEffect(() => {
		if (typeof props.companyFormData !== "undefined") {
			setFormData(props.companyFormData);
		}
	}, [props.companyFormData]);

	useEffect(() => {
		if (typeof props.companyStaticData !== "undefined") {
			setStaticData(props.companyStaticData);
		}
	}, [props.companyStaticData]);

	const softwarePackageOptions = staticData.software_packages.map(action => ({
		value: action.id,
		label: action.name,
	}));

	const selectedSoftwarePackage =
		Number(formData.software_package_id) !== Number(0)
			? staticData.software_packages.find(action => Number(action.id) === Number(formData.software_package_id))
			: null;

	return (
		<CompanyFormContext.Provider
			value={{
				handleCompanyValueChange: data => {
					setFormData(data);
					props.onChange(data);
				},
				company: formData,
				handleCompanyFormValueChange: handleFormValueChange,
				hasStatutoryCompanyOriginal,
				originalMainCompany,
				companyStatutoryBranches,
			}}>
			<h2>{staticData.form_title}</h2>
			<div className="form-full">
				<CollapsibleContent initialCollapseState={false} title={uctrans("companies.statutory.statutory_company")}>
					<StatutoryCompanyForm />
				</CollapsibleContent>

				<CollapsibleContent initialCollapseState={false} title={uctrans("companies.administrative")}>
					<div className="form-full">
						<div className="form-2-3">
							<label>
								<Translate content="companies.statutory.establishment_type" />
								<span className="text-orange"> *</span>
							</label>

							<RadioGroup
								disabled={formData.id !== -1 ? originalMainCompany : false}
								name="establishment_type"
								value={formData.establishment_type}
								onChange={value => handleFormValueChange("establishment_type", value)}>
								<Radio label={uctrans(`companies.statutory.statutory_company__company_type.${1}`)} value={1} />
								<Radio label={uctrans(`companies.statutory.statutory_company__company_type.${2}`)} value={2} />
								<Radio label={uctrans(`companies.statutory.statutory_company__company_type.${3}`)} value={3} />
							</RadioGroup>
						</div>

						<div className="form-1-3">
							<LabeledTextInput
								required
								name="kvk_location_number"
								value={formData.kvk_location_number}
								onChange={e => handleFormValueChange(e.target.name, e.target.value)}
								label="companies.statutory.kvk_location_number"
								disabled={readonly}
							/>
						</div>
					</div>
					<div className="form-2-3">
						<LabeledTextInput
							disabled={readonly}
							name="name"
							value={formData.name}
							onChange={e => handleFormValueChange(e.target.name, e.target.value)}
							label="intermediaries.fields.name"
							required
						/>
					</div>

					<div className="form-1-3">
						<LabeledTextInput
							disabled={readonly}
							name="company_number"
							value={formData.company_number}
							onChange={e => handleFormValueChange(e.target.name, e.target.value)}
							label="intermediaries.fields.company_number"
							required
						/>
					</div>
					<div className="form-1-2">
						<label htmlFor="parent_id">
							<Translate content="intermediaries.fields.parent_id" />
						</label>
						<Select
							disabled={readonly}
							placeholder={uctrans("general.select_:item", {}, { item: "intermediaries.fields.parent_id" })}
							value={staticData.organisations.filter(({ value }) => Number(value) === Number(formData.parent_id))}
							options={staticData.organisations}
							name="parent_id"
							onChange={(value, metadata) => handleFormValueChange(metadata.name, value ? value.value : "")}
						/>
					</div>

					<div className="form-1-2">
						<IbanTextInput
							disabled={readonly}
							name="iban"
							value={formData.iban}
							onChange={e => handleFormValueChange(e.target.name, e.target.value)}
							label="intermediaries.fields.bank_number"
						/>
					</div>

					<div className="form-1-2">
						<label htmlFor="relationmanager_id">
							<Translate content="intermediaries.fields.relationmanager_id" />
							<span className="text-orange">{props.fromTakeOn ? "*" : ""}</span>
						</label>
						<Select
							disabled={readonly}
							placeholder={uctrans("general.select_:item", {}, { item: "intermediaries.fields.relationmanager_id" })}
							value={staticData.relationmanagers.filter(
								({ value }) => Number(value) === Number(formData.relationmanager_id),
							)}
							options={staticData.relationmanagers}
							name="relationmanager_id"
							onChange={(value, metadata) => handleFormValueChange(metadata.name, value ? value.value : "")}
						/>
					</div>

					<div className="form-1-2">
						<label htmlFor="team_id">
							<Translate content="intermediaries.fields.team_id" />
							{!isProspect && <span className="text-orange">*</span>}
						</label>
						<Select
							disabled={readonly}
							placeholder={uctrans("general.select_:item", {}, { item: "intermediaries.fields.team_id" })}
							value={staticData.teams.filter(({ value }) => Number(value) === Number(formData.team_id))}
							options={staticData.teams}
							name="team_id"
							onChange={(value, metadata) => handleFormValueChange(metadata.name, value ? value.value : "")}
						/>
					</div>

					<div className="form-1-4">
						<PincodeTextInput
							disabled={readonly}
							name="pincode"
							value={formData.pincode}
							onChange={e => handleFormValueChange(e.target.name, e.target.value)}
							label="intermediaries.fields.pincode"
						/>
					</div>

					<div className="form-1-2">
						<label htmlFor="segment_id">
							<Translate content="intermediaries.fields.segment_id" />{" "}
							{!isProspect && <span className="text-orange">*</span>}
						</label>
						<Select
							disabled={readonly}
							placeholder={uctrans("general.select_:item", {}, { item: "intermediaries.fields.segment_id" })}
							value={staticData.segments.filter(({ value }) => Number(value) === Number(formData.segment_id))}
							options={staticData.segments}
							name="segment_id"
							onChange={(value, metadata) => handleFormValueChange(metadata.name, value ? value.value : "")}
						/>
					</div>

					<div className="form-full">
						<div className="form-1-2">
							<label htmlFor="identification_type">
								{`${uctrans("intermediaries.fields.identification")} & ${uctrans(
									"intermediaries.fields.verification",
								)}`}
							</label>
							<Select
								disabled={readonly}
								placeholder={uctrans("general.select_:item", {}, { item: "intermediaries.fields.verification" })}
								value={staticData.company_identification_types.filter(
									({ value }) => Number(value) === Number(formData.identification_type),
								)}
								options={staticData.company_identification_types}
								name="identification_type"
								onChange={(value, metadata) => handleFormValueChange(metadata.name, value ? value.value : "")}
							/>
						</div>
						<div className="form-1-2">
							<label htmlFor="software_package_id">
								<Translate content="intermediaries.fields.software_package_id" />
							</label>
							<Select
								placeholder={uctrans("general.select_:item", {}, { item: "intermediaries.fields.software_package_id" })}
								value={softwarePackageOptions.filter(
									({ value }) => Number(value) === Number(formData.software_package_id),
								)}
								options={softwarePackageOptions}
								name="software_package_id"
								onChange={(value, metadata) => handleFormValueChange(metadata.name, value ? value.value : "")}
								disabled={readonly}
							/>
							{selectedSoftwarePackage && selectedSoftwarePackage.is_open ? (
								<div className="form-full">
									<LabeledTextInput
										translateLabel={false}
										label={selectedSoftwarePackage.open_title}
										name="software_package_value"
										value={formData.software_package_value}
										onChange={e => handleFormValueChange(e.target.name, e.target.value)}
										disabled={readonly}
									/>
								</div>
							) : null}
						</div>
					</div>
					<div className="form-full">
						<div className="form-1-2">
							<label htmlFor="company_logo">
								<Translate content="intermediaries.fields.company_logo" />
							</label>
							<ImageThumbnailFileInput
								storeRoute="crm.files.store"
								newFileDownloadRoute="crm.files.download"
								existingFileDownloadRoute="crm.intermediaries.company-logo-download"
								maxFiles={1}
								acceptedExtensions={["jpg", "png"]}
								image={formData.company_logo}
								onChange={files => handleFormValueChange("company_logo", files)}
							/>
						</div>
					</div>
					<div className="form-full">
						<div className="form-1-4">
							<label htmlFor="sustainable">
								<Translate content="intermediaries.fields.sustainable" />
								<span className="text-orange"> *</span>
							</label>
							<RadioGroup
								name="sustainable"
								value={formData.sustainable}
								onChange={value => handleFormValueChange("sustainable", value)}
								disabled={readonly}>
								<Radio label={uctrans("general.yes")} value={1} />
								<Radio label={uctrans("general.no")} value={0} />
							</RadioGroup>
						</div>
					</div>
				</CollapsibleContent>
			</div>

			<div className="form-full">
				<CollapsibleContent initialCollapseState={false} title={uctrans("addresses.fields.visiting_address")}>
					<AddressForm
						addressFormData={formData.addresses[staticData.address_types.VISITING]}
						staticData={staticData}
						onChange={value => handleAddressInputChange(staticData.address_types.VISITING, value)}
						useCopyButton
						useAddition
						copyLabel={uctrans("addresses.fields.copy_to_postal_address")}
						onCopy={value => handleAddressInputChange(staticData.address_types.POSTAL, value)}
						required
						disabled={readonly}
					/>
				</CollapsibleContent>
			</div>
			<div className="form-full">
				<CollapsibleContent initialCollapseState={false} title={uctrans("addresses.fields.postal_address")}>
					<AddressForm
						addressFormData={formData.addresses[staticData.address_types.POSTAL]}
						staticData={staticData}
						onChange={value => handleAddressInputChange(staticData.address_types.POSTAL, value)}
						useAddition
						onCopy={value => handleAddressInputChange(staticData.address_types.BUSINESS, value)}
						required
						disabled={readonly}
					/>
				</CollapsibleContent>
			</div>
			<div className="form-full">
				<CollapsibleContent initialCollapseState={false} title={uctrans("intermediaries.fields.contact")}>
					<div className="form-1-2">
						<LabeledTextInput
							disabled={readonly}
							name="email_address"
							label="intermediaries.fields.email_address"
							value={formData.email_address}
							onChange={e => handleFormValueChange(e.target.name, e.target.value)}
							required
						/>
					</div>
					<div className="form-1-2">
						<LabeledTextInput
							disabled={readonly}
							name="phonenumber"
							label="intermediaries.fields.phonenumber"
							value={formData.phonenumber}
							onChange={e => handleFormValueChange(e.target.name, e.target.value)}
							required
						/>
					</div>
					<div className="form-1-2">
						<LabeledTextInput
							disabled={readonly}
							name="website"
							label="general.website"
							value={formData.website}
							onChange={e => handleFormValueChange(e.target.name, e.target.value)}
							required
						/>
					</div>
				</CollapsibleContent>
			</div>
			<div className="form-full">
				<CollapsibleContent title={uctrans("contactpersons.fields.communication_preferences")}>
					<div className="form-full mb-4">
						<Translate content="intermediaries.communication_preferences_description" />
					</div>

					<div className="form-full mt-4">
						<CommunicationPreferences
							communicationPreferenceData={formData.unsubscribe_preferences}
							onPreferenceChange={value => handleFormValueChange("unsubscribe_preferences", value)}
							staticNewsLetterSubscriptionTypes={staticData.newsletter_subscription_types}
							unsubscribeReasons={staticData.unsubscribe_reasons}
							forCrm
						/>
					</div>
				</CollapsibleContent>
			</div>
		</CompanyFormContext.Provider>
	);
}

IntermediaryFormFields.propTypes = {
	companyFormData: PropTypes.object,
	companyStaticData: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	fromTakeOn: PropTypes.bool,
};

IntermediaryFormFields.defaultProps = {
	fromTakeOn: false,
};
