import PropTypes from "prop-types";
import { can, route } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../../general/tabs/TabLinks";

export default function Navigation(props) {
	const tabs = [];
	if (!props.automatedMessage && can("communication.automated_message", "create")) {
		tabs.push({
			key: "content",
			label: uctrans("communication.newsletters.content"),
			route: route("crm.communication.automated-messages.create"),
		});
	}
	if (!!props.automatedMessage && can("communication.automated_message", "update")) {
		tabs.push({
			key: "content",
			label: uctrans("communication.newsletters.content"),
			route: route("crm.communication.automated-messages.edit", { automated_message: props.automatedMessage.id }),
		});
	}
	if (!!props.automatedMessage && can("communication.communication_log", "view_all")) {
		tabs.push({
			key: "communication_log",
			label: uctrans("communication.communication_log.singular"),
			route: route("crm.communication.automated-messages.communication-log", {
				automated_message: props.automatedMessage.id,
			}),
		});
	}
	if (!!props.automatedMessage && can("communication.automated_message", "view")) {
		tabs.push({
			key: "events",
			label: uctrans("communication.events.plural"),
			route: route("crm.communication.automated-messages.communication-events", {
				automated_message: props.automatedMessage.id,
			}),
		});
	}
	if (!!props.automatedMessage && can("communication.automated_message", "view")) {
		tabs.push({
			key: "statistics",
			label: uctrans("communication.newsletters.statistics"),
			route: route("crm.communication.automated-messages.statistics", {
				automated_message: props.automatedMessage.id,
			}),
		});
	}
	return (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{ label: uctrans("communication.singular") },
					{
						label: uctrans("communication.automated_messages.plural"),
						route: route("crm.communication.automated-messages.index"),
					},
					{
						label: !props.automatedMessage
							? uctrans("general.add_:item", [], { item: "communication.automated_messages.singular" })
							: props.automatedMessage.title,
					},
				]}
			/>
			<TabLinks tabs={tabs} activeTab={props.selectedTab} />
		</>
	);
}
Navigation.propTypes = {
	selectedTab: PropTypes.string.isRequired,
	automatedMessage: PropTypes.object,
};
