import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";
import { can, urlHttpPrefix } from "../../../../../../../js/helpers";
import { uctrans } from "../../../../../../../js/lib/Translator";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";

export default function CompanyMarker(props) {
	return (
		<Tippy
			theme="light"
			trigger="click"
			interactive
			placement="top"
			arrow
			content={
				<div>
					<h5>{props.company.name}</h5>
					{uctrans("intermediaries.fields.company_number")}: {props.company.company_number}
					<br />
					{uctrans("intermediaries.fields.relationmanager")}: {props.company.relationmanagerLabel}
					<br />
					<br />
					{!!props.company.visitingAddress &&
						`${props.company.visitingAddress.street} ${props.company.visitingAddress.number}${props.company.visitingAddress.addition}, ${props.company.visitingAddress.zipcode}, ${props.company.visitingAddress.city}`}
					{!!props.company.phonenumber && (
						<>
							<br />
							Tel: {props.company.phonenumber}
						</>
					)}
					<div className="mt-3 flex justify-center w-full">
						{can("intermediary", "update_administrative") && !!props.company.profile_url && (
							<>
								<a
									target="_blank"
									href={urlHttpPrefix(props.company.profile_url)}
									className="link"
									rel="noopener noreferrer">
									Profiel
								</a>
								<span className="px-2">|</span>{" "}
							</>
						)}
						{!!props.company.website && (
							<>
								<a
									target="_blank"
									href={urlHttpPrefix(props.company.website)}
									className="link"
									rel="noopener noreferrer">
									Webadres
								</a>
								<span className="px-2">|</span>{" "}
							</>
						)}
						{!!props.company.directions_url && (
							<>
								<a
									target="_blank"
									href={urlHttpPrefix(props.company.directions_url)}
									className="link"
									rel="noopener noreferrer">
									Routebeschrijving
								</a>
								<span className="px-2">|</span>
							</>
						)}
						{!!props.onStreetviewClick && (
							<a onClick={props.onStreetviewClick} className="link">
								Street View
							</a>
						)}
					</div>
				</div>
			}>
			<div
				style={{
					color: "white",
					background: "#005e5d",
					width: "30px",
					height: "30px",
					display: "inline-flex",
					textAlign: "center",
					alignItems: "center",
					justifyContent: "center",
					borderRadius: "100%",
					transform: "translate(-50%, -50%)",
				}}>
				<EvaIcon type="home-outline" width="20" height="20" fill="white" />
			</div>
		</Tippy>
	);
}

CompanyMarker.propTypes = {
	onStreetviewClick: PropTypes.func,
	company: PropTypes.object,
};
