import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import SingleSelectAutoComplete from "../../../../../js/react/components/general/autocomplete/SingleSelectAutoComplete";
import AutosizeTextarea from "../../../../../js/react/components/general/form/AutosizeTextarea";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import Translate from "../../../../../js/react/components/general/Translate";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import { route } from "../../../../../js/helpers";
import AgendaItemCompanyFields from "./AgendaItemCompanyFields";
import AgendaItemContactmomentLink from "./AgendaItemContactmomentLink";
import AgendaItemDateFields from "./AgendaItemDateFields";
import AgendaItemFormFields from "./AgendaItemFormFields";
import Navigation from "./Navigation";

export default function FollowupActionForm(props) {
	const readOnly = window.data.read_only;

	const [formData, setFormData] = useState(
		typeof props.followupActionFormData === "undefined"
			? cloneDeep(window.data.followup_action_form_data)
			: props.followupActionFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.followupActionStaticData === "undefined"
			? cloneDeep(window.data.followup_action_static_data)
			: props.followupActionStaticData,
	);

	useEffect(() => {
		if (typeof props.followupActionFormData !== "undefined") {
			setFormData(props.followupActionFormData);
		}
	}, [props.followupActionFormData]);

	useEffect(() => {
		if (typeof props.followupActionStaticData !== "undefined") {
			setStaticData(props.followupActionStaticData);
		}
	}, [props.followupActionStaticData]);

	const onSuccess = () => {
		if (window.data.agendaNamespace || staticData.creating) {
			Nav.go(route("crm.agenda-items.index"));
		} else {
			if (staticData.intermediary) {
				Nav.go(route("crm.intermediaries.agenda-items.index", { intermediary: formData.agenda_item.company_id }));
			} else if (staticData.organisation) {
				Nav.go(route("crm.organisations.agenda-items.index", { organisation: formData.agenda_item.company_id }));
			}
		}
	};

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	const submitURL = () => {
		if (!staticData.creating) {
			if (staticData.intermediary) {
				return route(`crm.agenda.intermediaries.followup-actions.update`, [
					formData.agenda_item.company_id,
					formData.id,
				]);
			} else if (staticData.organisation) {
				return route(`crm.agenda.organisations.followup-actions.update`, [
					formData.agenda_item.company_id,
					formData.id,
				]);
			}
		}

		return route("crm.followup-actions.store");
	};

	const previousURL = () => {
		if (window.data.agendaNamespace) {
			return route("crm.agenda-items.index");
		}

		if (staticData.intermediary) {
			return route("crm.intermediaries.agenda-items.index", { intermediary: formData.agenda_item.company_id });
		} else if (staticData.organisation) {
			return route("crm.organisations.agenda-items.index", { organisation: formData.agenda_item.company_id });
		}
	};

	useEffect(() => {
		if (staticData && staticData.creating && window.data.user && window.data.user.id) {
			if (staticData.users.some(e => Number(e.value) === Number(window.data.user.id))) {
				handleFormValueChange("user_id", window.data.user.id.toString());
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{!staticData.creating && (
				<Navigation
					selectedTab={window.data.nav_data.activeTabItem}
					tabs={window.data.nav_data.tabs}
					breadCrumbs={window.data.nav_data.breadcrumbs}
				/>
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.company_name
						? uctrans(
								"general.:item1 from :item2",
								{ item2: window.data.company_name },
								{ item1: "followupactions.singular" },
						  )
						: uctrans("followupactions.singular")}
				</h1>
				{window.data.agendaNamespace || staticData.creating ? (
					<a className="link" href={route("crm.agenda-items.index")}>
						{uctrans("general.back_to_:page", {}, { page: "agendaitems.accountsupport" })}
					</a>
				) : (
					<a className="link" href={previousURL()}>
						{uctrans("general.back_to_overview_:page", {}, { page: "agendaitems.plural" })}
					</a>
				)}
			</div>

			<AgendaItemContactmomentLink contactmoment={formData.contactmoment} />

			<AjaxForm
				method={staticData.save_method}
				submitUrl={submitURL()}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "followupactions.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "followupactions.singular" })}
				data={{ ...formData }}
				onSuccess={onSuccess}
				useFlashMessage={staticData.creating || window.data.agendaNamespace}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<AgendaItemFormFields
								onChange={value => setFormData(value)}
								agendaItemFormData={formData}
								agendaItemStaticData={staticData}
								editable={!readOnly}
								showAgendaDate
							/>
							<div className="form-1-2">
								<label htmlFor="user_id">
									<Translate content="companies.singular" />
									<span className="text-orange"> *</span>
								</label>
								<SingleSelectAutoComplete
									disabled={readOnly}
									name="company_id"
									dataSource={route("crm.companies.search").toString()}
									item={formData.company_id}
									initialItem={formData.company}
									onChange={value => handleFormValueChange("company_id", value ? value.value : null)}
									placeholder={uctrans("general.type_to_add_:item", {}, { item: "companies.singular" })}
									async
								/>
							</div>
							<div className="form-full">
								<label htmlFor="description">
									<Translate content="followupactions.fields.description" />
								</label>
								<AutosizeTextarea
									id="description"
									name="description"
									disabled={readOnly}
									value={formData.description}
									onChange={e => handleFormValueChange(e.target.name, e.target.value)}
								/>
							</div>
							<div className="form-full">
								<Checkmark
									disabled={readOnly}
									label={uctrans("followupactions.fields.status")}
									name="status"
									checked={formData.status}
									onChange={e => handleFormValueChange(e.target.name, e.target.checked)}
								/>
							</div>
							{formData.status && (
								<div className="form-full">
									<label htmlFor="explanation">
										<Translate content="followupactions.fields.explanation" />
									</label>
									<textarea
										id="explanation"
										name="explanation"
										value={formData.explanation}
										onChange={e => handleFormValueChange(e.target.name, e.target.value)}
										autoComplete="off"
									/>
								</div>
							)}
							<div className="form-full">
								<div className="row">
									<div className="w-full md:w-1/2 lg:w-1/3 column">
										<SubmitBar item="followupactions.singular" addAnotherAvailable={false} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="flex-none w-1/3 border-l pl-12">
						<AgendaItemDateFields agendaItem={formData.agenda_item} />

						{!staticData.creating && <AgendaItemCompanyFields companyId={formData.agenda_item.company_id} />}
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
FollowupActionForm.propTypes = {
	followupActionFormData: PropTypes.object,
	followupActionStaticData: PropTypes.object,
};
