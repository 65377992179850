import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { route } from "../../../../../../js/helpers";
import { trans, uctrans } from "../../../../../../js/lib/Translator";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import Translate from "../../../../../../js/react/components/general/Translate";
import PeriodicFilter from "../../general/PeriodicFilter";
import AppStatisticDateInfo from "./AppStatisticDateInfo";
import AppStatisticGraph from "./AppStatisticGraph";
import cloneDeep from "lodash/cloneDeep";

export default function AppStatistics() {
	const [loading, setLoading] = useState(true);
	const [graphData, setGraphData] = useState(null);
	const [overviewStatistics] = useState(
		window.data.overviewStatistics ? cloneDeep(window.data.overviewStatistics) : {},
	);
	const [formInfo] = useState(window.data.formInfo ? cloneDeep(window.data.formInfo) : []);
	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);

	const retrieveGraphdata = useCallback(async () => {
		setLoading(true);
		try {
			const result = await axios.get(route(`crm.statistics.app.graphdata`), {
				params: formValues,
			});
			setGraphData(result.data);
		} catch (e) {
			console.error(e);
		}
		setLoading(false);
	}, [formValues]);

	useEffect(() => {
		retrieveGraphdata();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formValues]);

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="statistics.app.menu" />
				</h1>
			</div>
			<h2>
				<Translate content="statistics.app.overview" />
			</h2>

			<br />
			<hr className="w-full" />
			<br />

			<div className="w-full">
				<h4>
					<Translate content="statistics.app.registrations" />
				</h4>

				<table className="w-full text-center">
					<tr>
						<th />
						<th className="border-l">
							<Translate content="statistics.app.total" />
						</th>
						<th className="border-l">
							<Translate content="statistics.app.percentage_of_total" />
						</th>
						<AppStatisticDateInfo content="statistics.app.last_week" startDate={formInfo.last_week_start_date} />
						<AppStatisticDateInfo content="statistics.app.last_month" startDate={formInfo.last_month_start_date} />
					</tr>
					<tr className="border-t">
						<th className="text-left">
							<Translate content="statistics.app.intermediaries" />
						</th>
						<td className="border-l">{overviewStatistics.registered_intermediaries}</td>
						<td className="border-l">{overviewStatistics.registered_intermediaries_percentage}%</td>
						<td className="border-l">{overviewStatistics.registered_intermediaries_week}</td>
						<td className="border-l">{overviewStatistics.registered_intermediaries_month}</td>
					</tr>
					<tr className="border-t">
						<th className="text-left">
							<Translate content="statistics.app.contactpersons" />
						</th>
						<td className="border-l">{overviewStatistics.registered_contactpersons}</td>
						<td className="border-l">{overviewStatistics.registered_contactpersons_percentage}%</td>
						<td className="border-l">{overviewStatistics.registered_contactpersons_week}</td>
						<td className="border-l">{overviewStatistics.registered_contactpersons_month}</td>
					</tr>
					<tr className="border-t">
						<th className="text-left">
							<Translate content="statistics.app.devices" />
						</th>
						<td className="border-l">{overviewStatistics.registered_devices}</td>
						<td className="border-l">-</td>
						<td className="border-l">{overviewStatistics.registered_devices_week}</td>
						<td className="border-l">{overviewStatistics.registered_devices_month}</td>
					</tr>
				</table>

				<br />
				<h4>
					<Translate content="statistics.app.activity" />
				</h4>

				<table className="w-full text-center">
					<tr>
						<th />
						<th className="border-l">
							<Translate content="statistics.app.yesterday_unique" />
						</th>
						<th className="border-l">
							<Translate content="statistics.app.yesterday" />
						</th>
						<AppStatisticDateInfo content="statistics.app.last_week_unique" startDate={formInfo.last_week_start_date} />
						<AppStatisticDateInfo content="statistics.app.last_week" startDate={formInfo.last_week_start_date} />
						<AppStatisticDateInfo
							content="statistics.app.last_month_unique"
							startDate={formInfo.last_month_start_date}
						/>
						<AppStatisticDateInfo content="statistics.app.last_month" startDate={formInfo.last_month_start_date} />
					</tr>

					{["active_devices", "active_intermediaries", "active_contactpersons"].map(statistic => (
						<tr key={statistic} className="border-t">
							<th className="text-left">
								<Translate content={`statistics.app.${statistic}`} />
							</th>
							<td className="border-l">{trans("statistics.app.not_applicable")}</td>
							<td className="border-l">{overviewStatistics[`${statistic}_day`]}</td>
							<td className="border-l">{trans("statistics.app.not_applicable")}</td>
							<td className="border-l">{overviewStatistics[`${statistic}_week`]}</td>
							<td className="border-l">{trans("statistics.app.not_applicable")}</td>
							<td className="border-l">{overviewStatistics[`${statistic}_month`]}</td>
						</tr>
					))}

					{[
						"succeeded_qr_login",
						"requests_init",
						"requests_dossiers",
						"requests_interest",
						"requests_dossier_details",
						"requests_contact",
					].map(statistic => (
						<tr key={statistic} className="border-t">
							<th className="text-left">
								<Translate content={`statistics.app.${statistic}`} />
							</th>
							<td className="border-l">{overviewStatistics[statistic].day_unique}</td>
							<td className="border-l">{overviewStatistics[statistic].day}</td>
							<td className="border-l">{overviewStatistics[statistic].week_unique}</td>
							<td className="border-l">{overviewStatistics[statistic].week}</td>
							<td className="border-l">{overviewStatistics[statistic].month_unique}</td>
							<td className="border-l">{overviewStatistics[statistic].month}</td>
						</tr>
					))}
				</table>

				<br />
				<h4>
					<Translate content="statistics.app.file_delivery" />
				</h4>

				<table className="w-full text-center">
					<tr>
						<th />
						<th className="border-l">
							<Translate content="statistics.app.last_delivered" />
						</th>
					</tr>
					<tr className="border-t">
						<th className="text-left">
							<Translate content="statistics.app.file_dossiers" />
						</th>
						<td className="border-l">{overviewStatistics.dossiers_last_read}</td>
					</tr>
					<tr className="border-t">
						<th className="text-left">
							<Translate content="statistics.app.file_documents" />
						</th>
						<td className="border-l">{overviewStatistics.documents_last_read}</td>
					</tr>
					<tr className="border-t">
						<th className="text-left">
							<Translate content="statistics.app.file_sx" />
						</th>
						<td className="border-l">{overviewStatistics.sx_documents_last_read}</td>
					</tr>
				</table>
			</div>
			<br />
			<hr className="w-full" />
			<br />
			<div className="w-full">
				<PeriodicFilter formValues={formValues} setFormValuesFunc={setFormValues} formInfo={formInfo} />
			</div>
			<br />
			{loading ? (
				<div className="flex justify-center p-6">
					<Spinner width={100} />
				</div>
			) : (
				<div className="w-full">
					{graphData && (
						<>
							<br />
							<h2>
								<Translate content="statistics.app.registered_devices" />
							</h2>

							<AppStatisticGraph
								title={uctrans("statistics.app.registered_devices")}
								includesPercentage={false}
								lastMeasuredNumber={overviewStatistics.registered_devices}
								data={graphData.registered_devices}
							/>

							<AppStatisticGraph
								title={uctrans("statistics.app.registered_intermediaries")}
								includesPercentage
								lastMeasuredNumber={overviewStatistics.registered_intermediaries}
								lastMeasuredPercentage={overviewStatistics.registered_intermediaries_percentage}
								data={graphData.registered_intermediaries}
								percentageData={graphData.registered_intermediaries_percentage}
							/>

							<AppStatisticGraph
								title={uctrans("statistics.app.registered_contactpersons")}
								includesPercentage
								lastMeasuredNumber={overviewStatistics.registered_contactpersons}
								lastMeasuredPercentage={overviewStatistics.registered_contactpersons_percentage}
								data={graphData.registered_contactpersons}
								percentageData={graphData.registered_contactpersons_percentage}
							/>

							<br />
							<h2>
								<Translate content="statistics.app.active_devices" />
							</h2>

							<AppStatisticGraph
								title={uctrans("statistics.app.active_devices")}
								includesPercentage
								lastMeasuredNumber={overviewStatistics.active_devices_day}
								lastMeasuredPercentage={overviewStatistics.active_devices_percentage}
								data={graphData.active_devices}
								percentageData={graphData.active_devices_percentage}
							/>

							<AppStatisticGraph
								title={uctrans("statistics.app.active_intermediaries_graph")}
								includesPercentage
								lastMeasuredNumber={overviewStatistics.active_intermediaries_day}
								lastMeasuredPercentage={overviewStatistics.active_intermediaries_percentage}
								data={graphData.active_intermediaries}
								percentageData={graphData.active_intermediaries_percentage}
							/>

							<AppStatisticGraph
								title={uctrans("statistics.app.active_contactpersons_graph")}
								includesPercentage
								lastMeasuredNumber={overviewStatistics.active_contactpersons_day}
								lastMeasuredPercentage={overviewStatistics.active_contactpersons_percentage}
								data={graphData.active_contactpersons}
								percentageData={graphData.active_contactpersons_percentage}
							/>

							<br />
							<h2>
								<Translate content="statistics.app.requests" />
							</h2>

							<AppStatisticGraph
								title={uctrans("statistics.app.requests_init_graph")}
								includesPercentage={false}
								lastMeasuredNumber={overviewStatistics.requests_init_day}
								data={graphData.active_devices}
							/>

							<AppStatisticGraph
								title={uctrans("statistics.app.requests_dossiers_graph")}
								includesPercentage={false}
								lastMeasuredNumber={overviewStatistics.requests_dossiers_day}
								data={graphData.requests_dossiers}
							/>

							<AppStatisticGraph
								title={uctrans("statistics.app.requests_news_graph")}
								includesPercentage={false}
								lastMeasuredNumber={overviewStatistics.requests_news_day}
								data={graphData.requests_news}
							/>

							<AppStatisticGraph
								title={uctrans("statistics.app.requests_interest_graph")}
								includesPercentage={false}
								lastMeasuredNumber={overviewStatistics.requests_interest_day}
								data={graphData.requests_interest}
							/>

							<AppStatisticGraph
								title={uctrans("statistics.app.requests_dossier_details")}
								includesPercentage={false}
								lastMeasuredNumber={overviewStatistics.requests_dossier_details_day}
								data={graphData.requests_dossier_detail}
							/>

							<AppStatisticGraph
								title={uctrans("statistics.app.requests_contact")}
								includesPercentage={false}
								lastMeasuredNumber={overviewStatistics.requests_contact_day}
								data={graphData.requests_contact}
							/>

							<AppStatisticGraph
								title={uctrans("statistics.app.succeeded_qr_login")}
								includesPercentage={false}
								lastMeasuredNumber={overviewStatistics.succeeded_qr_login_day}
								data={graphData.succeeded_qr_login}
							/>
						</>
					)}
				</div>
			)}
		</>
	);
}
