import map from "lodash/map";
import { useState } from "react";
import { can, route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../js/react/hooks/useGenericHandler";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import { CmsTypesContext } from "../cms/cms-types-context";
import Attribute from "../cms/general/attributes/Attribute";
import PartContent from "../cms/general/parts/PartContent";
import TextEditor from "../forms/textEditor/TextEditor";
import CollapsibleContent from "../general/CollapsibleContent";
import Navigation from "./Navigation";

export default function ClientTreatmentLandingPageForm() {
	const [formData, setFormData] = useState({ ...window.data.landing_page_form_data });
	const [staticData] = useState({ ...window.data.landing_page_static_data });
	const inputHandler = useInputHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);

	const handleContentChange = (attributeKey, values) => {
		const newContentValues = { ...formData.contents };
		newContentValues[attributeKey] = values;
		setFormData({
			...formData,
			contents: newContentValues,
		});
	};

	return (
		<>
			<Navigation
				selectedTab="client-treatment-landing-page"
				canUpdate={can("client_treatment.landing_page", "update")}
				loggedInInviter={
					window.data.user
						? staticData.client_treatment.inviters.find(
								inviter => Number(inviter.user_id) === Number(window.data.user.id),
						  )
						: null
				}
				clientTreatment={staticData.client_treatment}
				clientTreatmentTypes={staticData.client_treatment_types}
			/>

			<AjaxForm
				method="PUT"
				submitUrl={route("crm.client-treatments.landing-page.update", {
					client_treatment: formData.client_treatment_id,
					landing_page: formData.id,
				})}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "client_treatment.landingpage.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "client_treatment.landingpage.singular" })}
				onSuccess={() => window.location.reload()}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-1/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-2">
									<Checkmark
										label={uctrans("client_treatment.landingpage.fields.use_alternative_email")}
										name="use_alternative_email"
										checked={formData.use_alternative_email}
										onChange={e => genericHandler(e.target.checked, e.target.name)}
									/>
								</div>
							</div>

							{!!formData.use_alternative_email && (
								<>
									<div className="form-full">
										<div className="form-1-2">
											<LabeledTextInput
												name="alternative_email_title"
												label="client_treatment.landingpage.fields.alternative_email_title"
												value={formData.alternative_email_title}
												onChange={inputHandler}
												required
											/>
										</div>
									</div>
									<div className="form-full">
										<div className="form-1-2">
											<label>
												<Translate content="client_treatment.landingpage.fields.alternative_email_explanation" />
												<span className="text-orange"> *</span>
											</label>
											<TextEditor
												value={formData.alternative_email_explanation}
												onChange={value => genericHandler(value, "alternative_email_explanation")}
											/>
										</div>
									</div>
								</>
							)}

							<div className="form-full">
								<div className="form-1-2">
									<Checkmark
										label={uctrans("client_treatment.landingpage.fields.use_content_explanation")}
										name="use_content_explanation"
										checked={formData.use_content_explanation}
										onChange={e => genericHandler(e.target.checked, e.target.name)}
									/>
								</div>
							</div>

							{!!formData.use_content_explanation && (
								<>
									<div className="form-full">
										<div className="form-1-2">
											<LabeledTextInput
												name="content_explanation_title"
												label="client_treatment.landingpage.fields.content_explanation_title"
												value={formData.content_explanation_title}
												onChange={inputHandler}
												required
											/>
										</div>
									</div>
									<div className="form-full">
										<div className="form-1-2">
											<label>
												<Translate content="client_treatment.landingpage.fields.content_explanation" />
												<span className="text-orange"> *</span>
											</label>
											<TextEditor
												value={formData.content_explanation}
												onChange={value => genericHandler(value, "content_explanation")}
											/>
										</div>
									</div>
								</>
							)}

							<div className="form-full">
								<div className="form-1-2">
									<LabeledTextInput
										name="present_text"
										label="client_treatment.landingpage.fields.present_text"
										value={formData.present_text}
										onChange={inputHandler}
										required
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-2">
									<LabeledTextInput
										name="absent_text"
										label="client_treatment.landingpage.fields.absent_text"
										value={formData.absent_text}
										onChange={inputHandler}
										required
									/>
								</div>
							</div>

							<CmsTypesContext.Provider value={staticData.cmsTypes}>
								<div className="cms-attribute grid-container">
									<div className="form-full w-2/3">
										{(() =>
											map(formData.attributes, (attribute, key) => {
												const attributeValues =
													formData.contents != null && Object.prototype.hasOwnProperty.call(formData.contents, key)
														? formData.contents[key]
														: null;
												if (attribute.type === "part") {
													return (
														<PartContent
															key={key}
															label={attribute.label}
															parttype={attribute.parttype}
															attribute={attribute}
															values={attributeValues}
															onChange={values => {
																handleContentChange(key, values);
															}}
														/>
													);
												} else {
													return (
														<Attribute
															key={key}
															attribute={attribute}
															values={attributeValues}
															onChange={values => {
																handleContentChange(key, values);
															}}
														/>
													);
												}
											}))()}
									</div>
									<div className="w-full md:w-1/2 lg:w-1/3 column">
										<SubmitBar item="client_treatment.landingpage.singular" addAnotherAvailable={false} />
									</div>
								</div>
							</CmsTypesContext.Provider>
						</div>
					</div>
					<div className="flex-none w-1/3 border-l pl-12">
						{!!staticData.client_treatment.self_enrollment && (
							<CollapsibleContent title={uctrans("client_treatment.fields.landing_page")} initialCollapseState={false}>
								<div className="mt-3">
									<p>{`${uctrans("client_treatment.fields.non_personal_landing_page_url")}:`}</p>
									<a
										className="link"
										href={route("website.client-treatment-landing.index", {
											client_treatment_id_token: staticData.client_treatment.id_token,
										})}>
										{route("website.client-treatment-landing.index", {
											client_treatment_id_token: staticData.client_treatment.id_token,
										}).toString()}
									</a>
								</div>
							</CollapsibleContent>
						)}
						{!!staticData.client_treatment_related_newsletters &&
							!!staticData.client_treatment_related_newsletters.length && (
								<CollapsibleContent
									title={uctrans("client_treatment.fields.related_newsletters")}
									initialCollapseState={false}>
									<ul>
										{staticData.client_treatment_related_newsletters.map(newsletter => (
											<li key={newsletter.id}>
												<a className="link" href={route("crm.communication.newsletters.edit", newsletter.id)}>
													{newsletter.title}
												</a>
											</li>
										))}
									</ul>
								</CollapsibleContent>
							)}
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
