import { useContext } from "react";
import { numberFormat } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import TitleBlock from "./components/TitleBlock";
import ProcessLineChart from "./graph/ProcessLineChart";
import RmDashboardHorizontalBarchart from "./graph/RmDashboardHorizontalBarchart";
import RmDashboardPiechart from "./graph/RmDashboardPiechart";
import RmDashboardBlock from "./RmDashboardBlock";
import { RmDashboardContext } from "./RmDashboardContext";

export default function ProcessBlock() {
	const { wallet } = useContext(RmDashboardContext);

	return (
		<RmDashboardBlock
			blockKey="process"
			useFetching
			renderHeader={() => <h5 className="mb-0">{uctrans("dashboard.process")}</h5>}
			renderContent={blockData => (
				<>
					<div className="flex w-full">
						{!!blockData.discuss_request && (
							<>
								<div className="w-1/2">
									<h6>
										<TitleBlock
											title={uctrans("discuss_requests.rm_dashboard.discuss_requests_development")}
											dashboardLink={blockData.discuss_request.dashboard_link}
											walletType={wallet.type}
										/>
									</h6>
									<ProcessLineChart year={blockData.year} data={blockData.discuss_request.per_month[wallet.type]} />
								</div>

								<div className="w-1/2">
									<h6>
										<TitleBlock
											title={uctrans("discuss_requests.rm_dashboard.discuss_requests_categories")}
											dashboardLink={blockData.discuss_request.dashboard_link}
											walletType={wallet.type}
										/>
									</h6>

									{!!blockData.discuss_request.category &&
										!!blockData.discuss_request.category[wallet.type] &&
										!!blockData.discuss_request.category.total && (
											<RmDashboardPiechart
												width={500}
												height={250}
												walletType={wallet.type}
												walletData={blockData.discuss_request.category[wallet.type]}
												totalData={blockData.discuss_request.category.total}
											/>
										)}
								</div>
							</>
						)}
					</div>
					<div className="mt-6 flex w-full">
						{!!blockData.discuss_request && (
							<div className="w-2/5">
								<h6>
									<TitleBlock
										title={uctrans("discuss_requests.rm_dashboard.discuss_requests_results")}
										dashboardLink={blockData.discuss_request.dashboard_link}
										walletType={wallet.type}
									/>
								</h6>

								{!!blockData.discuss_request.advice &&
									!!blockData.discuss_request.advice[wallet.type] &&
									!!blockData.discuss_request.advice.total && (
										<RmDashboardPiechart
											walletType={wallet.type}
											walletData={[
												{ label: "Positief", value: blockData.discuss_request.advice[wallet.type].positive },
												{ label: "Negatief", value: blockData.discuss_request.advice[wallet.type].negative },
												{ label: "Geen advies", value: blockData.discuss_request.advice[wallet.type].no_advice },
											]}
											totalData={[
												{ label: "Positief", value: blockData.discuss_request.advice.total.positive },
												{ label: "Negatief", value: blockData.discuss_request.advice.total.negative },
												{ label: "Geen advies", value: blockData.discuss_request.advice.total.no_advice },
											]}
										/>
									)}
							</div>
						)}

						{!!blockData.quality_monitor_info && blockData.quality_monitor_info[wallet.type] && (
							<>
								<div className="w-3/5">
									<h6>
										<TitleBlock
											title={uctrans("dashboard.ratios")}
											dashboardLink={blockData.quality_monitor_info.dashboard_link}
											walletType={wallet.type}
										/>
									</h6>

									{!!blockData.quality_monitor_info[wallet.type].quality_monitor &&
										!!blockData.quality_monitor_info.total &&
										!!blockData.quality_monitor_info.total.quality_monitor && (
											<RmDashboardHorizontalBarchart
												walletType={wallet.type}
												walletLabel={wallet.label}
												walletData={blockData.quality_monitor_info[wallet.type].quality_monitor}
												totalData={blockData.quality_monitor_info.total.quality_monitor}
												addPecentageSignAfterDataLabel
											/>
										)}
								</div>

								<div className="w-1/5">
									<h6>
										<TitleBlock
											title={uctrans("dashboard.interest_discount_per_loan")}
											dashboardLink={blockData.quality_monitor_info.dashboard_link}
											walletType={wallet.type}
										/>
									</h6>

									{!!blockData.quality_monitor_info[wallet.type].average_offers_per_loan &&
										!!blockData.quality_monitor_info.total &&
										!!blockData.quality_monitor_info.total.average_offers_per_loan && (
											<div className="flex justify-start">
												<div className="rounded-full dashboard-circle flex items-center justify-center">
													{numberFormat(blockData.quality_monitor_info[wallet.type].average_offers_per_loan, 1)}
												</div>

												{wallet.type !== "total" && (
													<div className="flex items-center ml-5">
														<ul className="border-l p-2 text-sm">
															<li>{uctrans("dashboard.nationwide")}</li>
															<li>{numberFormat(blockData.quality_monitor_info.total.average_offers_per_loan, 1)}</li>
														</ul>
													</div>
												)}
											</div>
										)}
								</div>
							</>
						)}
					</div>
				</>
			)}
		/>
	);
}
