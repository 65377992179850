import { useCallback, useEffect, useMemo, useState } from "react";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import SingleSelectAutoComplete from "../../../../../js/react/components/general/autocomplete/SingleSelectAutoComplete";
import Dialog from "../../../../../js/react/components/general/Dialog";
import AutosizeTextarea from "../../../../../js/react/components/general/form/AutosizeTextarea";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import Select from "../../../../../js/react/components/general/Select";
import Translate from "../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../js/react/hooks/useGenericHandler";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import useRadioHandler from "../../../../../js/react/hooks/useRadioHandler";
import useSelectHandler from "../../../../../js/react/hooks/useSelectHandler";
import WarningGroups from "../general/warnings/WarningGroups";
import AgendaItemCompanyFields from "./AgendaItemCompanyFields";
import AgendaItemContactmomentLink from "./AgendaItemContactmomentLink";
import AgendaItemDateFields from "./AgendaItemDateFields";
import AgendaItemFormFields from "./AgendaItemFormFields";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import { route } from "../../../../../js/helpers";
import Navigation from "./Navigation";
import axios from "axios";

export default function CooperationTerminationForm() {
	const creating = window.data.form_data.id === -1;
	const disabled = !!window.data.read_only;
	const [formData, setFormData] = useState({ ...window.data.form_data });
	const staticData = window.data.static_data;
	const inputHandler = useInputHandler(setFormData);
	const selectHandler = useSelectHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);
	const radioHandler = useRadioHandler(setFormData);
	const [deactivationWarnings, setDeactivationWarnings] = useState(null);
	const [showWarningsDialog, setShowWarningsDialog] = useState(false);

	const onSuccess = () => {
		if (window.data.agendaNamespace || creating) {
			Nav.go(route("crm.agenda-items.index"));
		} else {
			if (staticData.intermediary) {
				Nav.go(route("crm.intermediaries.agenda-items.index", { intermediary: formData.agenda_item.company_id }));
			} else if (staticData.organisation) {
				Nav.go(route("crm.organisations.agenda-items.index", { organisation: formData.agenda_item.company_id }));
			}
		}
	};

	const submitURL = () => {
		if (!creating) {
			if (staticData.intermediary) {
				return route(`crm.agenda.intermediaries.cooperation-terminations.update`, [
					formData.agenda_item.company_id,
					formData.id,
				]);
			} else if (staticData.organisation) {
				return route(`crm.agenda.organisations.cooperation-terminations.update`, [
					formData.agenda_item.company_id,
					formData.id,
				]);
			}
		}
		return route("crm.cooperation-terminations.store");
	};

	const previousURL = () => {
		if (window.data.agendaNamespace) {
			return route("crm.agenda-items.index");
		}

		if (staticData.intermediary) {
			return route("crm.intermediaries.agenda-items.index", { intermediary: formData.agenda_item.company_id });
		} else if (staticData.organisation) {
			return route("crm.organisations.agenda-items.index", { organisation: formData.agenda_item.company_id });
		}
	};

	const companyAlertRoute = useMemo(
		() =>
			staticData.intermediary
				? route("crm.intermediaries.company-alerts.index", formData.agenda_item.company_id)
				: route("crm.organisations.company-alerts.index", formData.agenda_item.company_id),
		[staticData.intermediary, formData.agenda_item.company_id],
	);

	const changeClassification = newClassificiation => {
		if (Number(newClassificiation) === staticData.actions.DEACTIVATE_COMPANY.key && !showWarningsDialog) {
			setShowWarningsDialog(true);
		} else {
			radioHandler(newClassificiation, "action");
		}
	};

	const fetchDeactivationWarnings = useCallback(async () => {
		try {
			const { data } = await axios.get(route("crm.companies.deactivation-warnings", formData.agenda_item.company_id));
			setDeactivationWarnings(data.groups);
		} catch (error) {
			console.error(error);
		}
	}, [formData.agenda_item.company_id]);

	useEffect(() => {
		// fetch deactivation warnings
		fetchDeactivationWarnings();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{!creating && (
				<Navigation
					selectedTab={window.data.nav_data.activeTabItem}
					tabs={window.data.nav_data.tabs}
					breadCrumbs={window.data.nav_data.breadcrumbs}
				/>
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.company_name
						? uctrans(
								creating ? "general.new :item1 by :item2" : "general.:item1 from :item2",
								{ item2: window.data.company_name },
								{ item1: "cooperation_terminations.singular" },
						  )
						: creating
						  ? uctrans("general.add_:item", {}, { item: "cooperation_terminations.singular" })
						  : uctrans("cooperation_terminations.singular")}
				</h1>

				{window.data.agendaNamespace || creating ? (
					<a className="link" href={route("crm.agenda-items.index")}>
						{uctrans("general.back_to_:page", {}, { page: "agendaitems.accountsupport" })}
					</a>
				) : (
					<a className="link" href={previousURL()}>
						{uctrans("general.back_to_overview_:page", {}, { page: "agendaitems.plural" })}
					</a>
				)}
			</div>

			<AgendaItemContactmomentLink contactmoment={formData.contactmoment} />

			<AjaxForm
				method={staticData.save_method}
				submitUrl={submitURL()}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "cooperation_terminations.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "cooperation_terminations.singular" })}
				data={{ ...formData }}
				onSuccess={onSuccess}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<AgendaItemFormFields
								onChange={value => genericHandler(value, "agenda_item")}
								agendaItemFormData={formData.agenda_item}
								agendaItemStaticData={staticData}
								editable={!disabled}
								showAgendaDate
							/>
							{creating ? (
								<div className="form-full">
									<div className="form-1-2">
										<label htmlFor="{">
											<Translate content="companies.singular" />
											<span className="text-orange"> *</span>
										</label>
										<SingleSelectAutoComplete
											name="company_id"
											disabled={disabled}
											dataSource={route("crm.companies.search").toString()}
											item={formData.company_id}
											initialItem={formData.company}
											onChange={({ value }) => genericHandler(value, "company_id")}
											placeholder={uctrans("general.type_to_add_:item", {}, { item: "companies.singular" })}
											async
										/>
									</div>
								</div>
							) : null}
							<div className="form-full">
								<div className="form-1-2">
									<label htmlFor="reason_id">
										<Translate content="cooperation_terminations.fields.reason" />{" "}
										<span className="text-orange"> *</span>
									</label>
									<Select
										isClearable
										disabled={disabled}
										value={staticData.reasons.filter(({ value }) => Number(value) === Number(formData.reason_id))}
										options={staticData.reasons}
										name="reason_id"
										onChange={selectHandler}
										placeholder={uctrans(
											"general.select_:item",
											{},
											{ item: "cooperation_terminations.fields.reason" },
										)}
									/>
								</div>
							</div>
							<div className="form-full">
								<label htmlFor="description">
									<Translate content="cooperation_terminations.fields.notes" />
								</label>
								<AutosizeTextarea
									id="notes"
									name="notes"
									value={formData.notes ?? ""}
									autoComplete={false}
									onChange={inputHandler}
									disabled={disabled}
								/>
							</div>
							<div className="form-full">
								<Checkmark
									disabled={disabled}
									label={uctrans("cooperation_terminations.fields.handled")}
									name="handled"
									checked={formData.handled}
									onChange={inputHandler}
								/>
							</div>
							{formData.handled && (
								<div className="form-full">
									<label>{uctrans("cooperation_terminations.fields.action")}</label>
									<RadioGroup disabled={disabled} name="action" value={formData.action} onChange={changeClassification}>
										<Radio
											label={staticData.actions.DEACTIVATE_COMPANY.label}
											value={staticData.actions.DEACTIVATE_COMPANY.key}
										/>
										<Radio label={staticData.actions.OTHER.label} value={staticData.actions.OTHER.key} />
									</RadioGroup>
									{formData.action === staticData.actions.OTHER.key && (
										<AutosizeTextarea
											id="action_description"
											name="action_description"
											value={formData.action_description ?? ""}
											autoComplete={false}
											onChange={inputHandler}
											disabled={disabled}
										/>
									)}
								</div>
							)}
							<div className="form-full">
								<SubmitBar item="cooperation_terminations.singular" addAnotherAvailable={false} />
							</div>
						</div>
					</div>
					<div className="flex-none w-1/3 border-l pl-12">
						<AgendaItemDateFields agendaItem={formData.agenda_item} />

						{!creating && <AgendaItemCompanyFields companyId={formData.agenda_item.company_id} />}

						<a className="button button-secondary" href={companyAlertRoute}>
							Naar pop-up overzicht
						</a>
					</div>
					{deactivationWarnings && (
						<Dialog
							isOpen={showWarningsDialog}
							title={uctrans("companies.change_status_to_:status", {
								status: "niet actief",
							})}
							onClose={() => setShowWarningsDialog(false)}>
							<WarningGroups
								groups={deactivationWarnings}
								onApprove={() => {
									radioHandler(staticData.actions.DEACTIVATE_COMPANY.key, "action");
									setShowWarningsDialog(false);
								}}
								warningsAsList={false}
							/>
						</Dialog>
					)}
				</div>
			</AjaxForm>
		</>
	);
}
