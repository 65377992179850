import Dialog from "../../../../../../js/react/components/general/Dialog";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import Radio from "../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../js/react/components/general/form/radio/RadioGroup";
import { createLoader, error, queueNotification } from "../../../../../../js/react/components/general/notifications";
import axios from "axios";
import find from "lodash/find";
import forEach from "lodash/forEach";
import map from "lodash/map";
import upperFirst from "lodash/upperFirst";
import { useState, useCallback, useEffect, useMemo } from "react";
import { format, parse } from "../../../../../../js/lib/Date";
import ConfirmDialog from "../../../../../../js/react/components/general/ConfirmDialog";
import DatePicker from "../../../../../../js/react/components/general/DatePicker";
import Select from "../../../../../../js/react/components/general/Select";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import Nav from "../../../../../../js/lib/Nav";
import { route } from "../../../../../../js/helpers";
import Translate from "../../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";
import useInputHandler from "../../../../../../js/react/hooks/useInputHandler";
import useSelectHandler from "../../../../../../js/react/hooks/useSelectHandler";
import Navigation from "./Navigation";
import NewsletterRecipientOverview from "./NewsletterRecipientOverview";

export default function NewsletterMailingForm() {
	const [formData, setFormData] = useState({ ...window.data.newsletter_mailing.form_data });
	const [staticData, setStaticData] = useState({ ...window.data.newsletter_mailing.static_data });
	const [sidebarData, setSidebarData] = useState({ ...window.data.newsletter_mailing.sidebar_data });
	const [addingMailingToQueue, setAddingMailingToQueue] = useState(false);
	const [showConflictingTagsWarning, setShowConflictingTagsWarning] = useState(false);
	const [loadingSidebarData, setLoadingSidebarData] = useState(false);
	const selectHandler = useSelectHandler(setFormData);
	const inputHandler = useInputHandler(setFormData);
	const formhandler = useGenericHandler(setFormData);
	const conflictingTags = useMemo(() => {
		if (sidebarData.conflicting_tags) {
			if (sidebarData.conflicting_tags instanceof Array) {
				return sidebarData.conflicting_tags;
			} else if (typeof sidebarData.conflicting_tags === "object") {
				return Object.values(sidebarData.conflicting_tags);
			}
		}
		return [];
	}, [sidebarData.conflicting_tags]);

	const readOnly = staticData.read_only;

	const selectedSenderType = formData.sender_type
		? find(staticData.sender_types, type => Number(type.value) === Number(formData.sender_type))
		: null;

	const addMailingToQueue = useCallback(async () => {
		if (addingMailingToQueue) {
			const loader = createLoader(uctrans("communication.newsletter_mailings.sidebar_adding_to_queue"));

			try {
				await axios.post(
					route(`crm.communication.newsletter-mailings.add-to-queue`, {
						newsletter: staticData.newsletter.id,
						newsletter_mailing: staticData.id,
					}),
				);
				loader.destroy();
				queueNotification("success", uctrans("communication.newsletter_mailings.sidebar_added_to_queue"), null);
				window.location.reload();
			} catch (exception) {
				if (exception.response && exception.response.status === 422) {
					if (Object.prototype.hasOwnProperty.call(exception.response.data, "errors")) {
						let first = true;
						forEach(exception.response.data.errors, message => {
							if (first && loader) {
								loader.failure(upperFirst(message[0]));
								first = false;
							} else {
								error(upperFirst(message[0]));
							}
						});
					}
				} else if (exception.message) {
					loader.failure(exception.message);
				}
				setAddingMailingToQueue(false);
			}
		}
	}, [addingMailingToQueue, staticData.id, staticData.newsletter.id]);
	const onSuccess = response => {
		if (staticData.creating) {
			Nav.go(
				route("crm.communication.newsletter-mailings.edit", {
					newsletter: staticData.newsletter.id,
					newsletter_mailing: response.data.id,
				}),
			);
		} else {
			setFormData(response.data.form_data);
			setStaticData(response.data.static_data);
			mailingOrRecieversChanged();
		}
	};

	const mailingOrRecieversChanged = async () => {
		await reloadSidebarData();
	};

	const reloadSidebarData = useCallback(async () => {
		try {
			setLoadingSidebarData(true);
			const response = await axios.get(
				route("crm.communication.newsletter-mailings.sidebar-data", {
					newsletter: staticData.newsletter.id,
					newsletter_mailing: staticData.id,
				}),
			);
			setSidebarData(response.data.sidebar_data);
			setLoadingSidebarData(false);
		} catch (error) {
			console.error(error);
			setSidebarData({});
		}
	}, [staticData.id, staticData.newsletter.id]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => reloadSidebarData(), []);

	return (
		<>
			<Navigation selectedTab="mailings" newsletter={staticData.newsletter} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("communication.newsletters.send_newsletter")}</h1>

				<a
					className="link"
					href={route("crm.communication.newsletters.index", { newsletter: staticData.newsletter.id })}>
					{uctrans("general.back_to_overview_:page", {}, { page: "communication.newsletters.plural" })}
				</a>
			</div>

			<div className="min-h-screen md:flex">
				<div className="flex-1 w-2/3 mr-12">
					<AjaxForm
						method={staticData.creating ? "POST" : "PUT"}
						submitUrl={
							staticData.creating
								? route("crm.communication.newsletter-mailings.store", { newsletter: staticData.newsletter.id })
								: route("crm.communication.newsletter-mailings.update", {
										newsletter: staticData.newsletter.id,
										newsletter_mailing: staticData.id,
								  })
						}
						loaderText={uctrans(
							"general.:item_is_being_saved",
							{},
							{ item: "communication.newsletter_mailings.singular" },
						)}
						successText={uctrans("general.saved_:item", {}, { item: "communication.newsletter_mailings.singular" })}
						onSuccess={onSuccess}
						data={{ ...formData }}
						useFlashMessage={!!staticData.creating}>
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										disabled={readOnly}
										name="message"
										label="communication.newsletter_mailings.fields.message"
										value={formData.message}
										onChange={inputHandler}
									/>
								</div>
							</div>

							<div className="form-1-3">
								<label htmlFor="type">
									{uctrans("communication.newsletter_mailings.fields.type")} <span className="text-orange">*</span>
								</label>
								<Select
									value={staticData.newsletter_mailing_types.filter(
										({ value }) => Number(value) === Number(formData.type ? formData.type : null),
									)}
									options={staticData.newsletter_mailing_types}
									name="type"
									onChange={selectHandler}
									isClearable={false}
									disabled={readOnly}
									required
								/>
							</div>
							{formData.type === 2 && (
								<div className="form-1-3">
									<label htmlFor="start_date_time">
										<Translate content="communication.newsletter_mailings.fields.planned_at" />
									</label>
									<DatePicker
										initialValue={formData.planned_at ? parse(formData.planned_at) : null}
										onChange={date => formhandler(date ? format(date) : null, "planned_at")}
										showTimeSelect
										disabled={readOnly}
									/>
								</div>
							)}
						</div>
						<div className="form-container">
							<div className="form-full">
								<label htmlFor="sender_type">
									{uctrans("communication.newsletters.fields.sender_type")} <span className="text-orange">*</span>
								</label>
								<RadioGroup
									name="sender_type"
									value={formData.sender_type}
									onChange={value => formhandler(value, "sender_type")}
									disabled={readOnly}
									horizontal>
									{map(staticData.sender_types, type => (
										<Radio key={type.value} label={type.label} value={type.value} disabled={readOnly} />
									))}
								</RadioGroup>
							</div>
						</div>
						{selectedSenderType && selectedSenderType.custom && (
							<div className="form-container">
								<div className="form-1-2">
									<LabeledTextInput
										name="sender_name"
										value={formData.sender_name ? `${formData.sender_name}` : ""}
										onChange={e => formhandler(e.target.value, "sender_name")}
										label="communication.newsletters.fields.sender_name"
										disabled={readOnly}
									/>
								</div>
								<div className="form-1-2">
									<LabeledTextInput
										name="email"
										value={formData.sender_email ? `${formData.sender_email}` : ""}
										onChange={e => formhandler(e.target.value, "sender_email")}
										label="communication.newsletters.fields.sender_email"
										required
										placeholder={uctrans("communication.newsletters.sender_email_description")}
										autoComplete
										disabled={readOnly}
									/>
								</div>
							</div>
						)}
						<div className="form-full">
							<div className="row">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar
										submitDisabled={readOnly}
										translateLabel
										item="communication.newsletter_mailings.newsletter_mailing_info"
										addAnotherAvailable={false}
									/>
								</div>
							</div>
						</div>
					</AjaxForm>

					{!staticData.creating && (
						<div className="form-full">
							<NewsletterRecipientOverview
								newsletter={staticData.newsletter}
								mailing={formData}
								onCountChange={mailingOrRecieversChanged}
								readOnly={readOnly}
							/>
						</div>
					)}
				</div>
				<div className="flex-none w-1/3 border-l pl-12">
					<div className="form-container -mr-0">
						{!staticData.creating &&
							(loadingSidebarData ? (
								<Spinner width={25} />
							) : (
								<>
									<h5>Status</h5>
									<p>
										{sidebarData.status_label}
										<br />
										<br />
									</p>
									{sidebarData.status === 1 && ( // Not send
										<>
											<h5>Verzenden</h5>
											{sidebarData.type === 1 && ( // Manual
												<>
													{sidebarData.recipient_count === 0 && (
														<div>
															{uctrans("communication.newsletter_mailings.sidebar_description_manual_no_recipients")}
														</div>
													)}
													{sidebarData.recipient_count > 0 && (
														<>
															<div>
																{uctrans("communication.newsletter_mailings.sidebar_description_manual_:cnt", {
																	cnt: sidebarData.recipient_count,
																})}
															</div>
															<div>
																<button
																	type="button"
																	className="button button-secondary"
																	onClick={() => {
																		if (conflictingTags.length !== 0) {
																			setShowConflictingTagsWarning(true);
																		} else {
																			setAddingMailingToQueue(true);
																		}
																	}}>
																	{uctrans("communication.newsletter_mailings.sidebar_send_newsletter")}
																</button>
															</div>
															<ConfirmDialog
																onConfirm={addMailingToQueue}
																onCancel={() => {
																	setAddingMailingToQueue(false);
																}}
																isOpen={!!addingMailingToQueue}
																title={uctrans("general.confirm")}
																confirmButtonLabel={uctrans(
																	"communication.newsletter_mailings.add_newsletter_to_queue",
																)}
																cancelButtonLabel={uctrans("general.cancel")}>
																<div className="form-container">
																	{uctrans("communication.newsletter_mailings.add_newsletter_to_queue_description")}
																</div>
															</ConfirmDialog>

															<Dialog
																onConfirm={showConflictingTagsWarning}
																onClose={() => {
																	setShowConflictingTagsWarning(false);
																	setAddingMailingToQueue(true);
																}}
																isOpen={!!showConflictingTagsWarning}
																title={uctrans("communication.newsletter_mailings.conflicting_tags_detected")}>
																<div className="form-container">
																	<span>
																		{uctrans("communication.newsletter_mailings.conflicting_tags_were_detected")}
																	</span>
																	<br />
																	<br />
																	<ul className="list-disc">
																		{conflictingTags.map((conflictingTag, index) => (
																			<li key={index}>{conflictingTag}</li>
																		))}
																	</ul>
																</div>
															</Dialog>
														</>
													)}
												</>
											)}
											{sidebarData.type === 2 && ( // Planned
												<>
													{sidebarData.recipient_count === 0 && (
														<div>
															{uctrans("communication.newsletter_mailings.sidebar_description_planned_no_recipients", {
																date: sidebarData.planned_at ? format(sidebarData.planned_at, "dd-MM-y") : "-",
																time: sidebarData.planned_at ? format(sidebarData.planned_at, "HH:mm") : "-",
															})}
														</div>
													)}
													{sidebarData.recipient_count > 0 && (
														<div>
															{uctrans("communication.newsletter_mailings.sidebar_description_planned_:cnt", {
																cnt: sidebarData.recipient_count,
																date: sidebarData.planned_at ? format(sidebarData.planned_at, "dd-MM-y") : "-",
																time: sidebarData.planned_at ? format(sidebarData.planned_at, "HH:mm") : "-",
															})}
														</div>
													)}
												</>
											)}
										</>
									)}
								</>
							))}
					</div>
				</div>
			</div>
		</>
	);
}
