import find from "lodash/find";
import map from "lodash/map";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { CmsTypesContext } from "../../cms-types-context";
import Animation from "./Animation";
import Attribute from "../attributes/Attribute";
import Document from "../attributes/Document";
import ButtonSet from "./ButtonSet";
import CampaignFoot from "./CampaignFoot";
import HeaderSet from "./HeaderSet";
import HomepageHead from "./HomepageHead";
import ContentpageHead from "./ContentpageHead";
import Image from "./Image";
import InterestTable from "./InterestTable";
import Link from "./Link";
import SubmenuItem from "./SubmenuItem";
import SubmenuPromo from "./SubmenuPromo";
import ColumnsBlock from "./ColumnsBlock";
import DashboardBlock from "./DashboardBlock";
import Table from "./Table";
import Video from "./Video";

export default function PartContent(props) {
	const [partvaluesData, setPartValuesData] = useState(props.values);
	useEffect(() => {
		setPartValuesData(props.values);
	}, [props.values]);

	const handlePartValuesChange = partValues => {
		setPartValuesData(partValues);
		props.onChange(partValues);
	};

	const handlePartAttributeValuesChange = (partAttributeKey, partAttributeValues) => {
		const newPartvaluesData = { ...partvaluesData };
		newPartvaluesData[partAttributeKey] = partAttributeValues;
		setPartValuesData(newPartvaluesData);
		props.onChange(newPartvaluesData);
	};

	const { partTypes } = useContext(CmsTypesContext);
	const partType = find(partTypes, partType => partType.key === props.parttype);

	if (partType) {
		switch (partType.key) {
			case "link":
			case "linktarget":
				return (
					<Link
						label={props.label}
						values={partvaluesData ? partvaluesData : {}}
						onChange={handlePartValuesChange}
						useText={partType.key === "link"}
					/>
				);
			case "image":
				return (
					<Image label={props.label} values={partvaluesData ? partvaluesData : {}} onChange={handlePartValuesChange} />
				);
			case "video":
				return (
					<Video label={props.label} values={partvaluesData ? partvaluesData : {}} onChange={handlePartValuesChange} />
				);
			case "animation":
				return (
					<Animation
						label={props.label}
						values={partvaluesData ? partvaluesData : {}}
						onChange={handlePartValuesChange}
					/>
				);
			case "document":
				return (
					<Document
						label={props.label}
						values={partvaluesData ? partvaluesData : {}}
						onChange={handlePartValuesChange}
					/>
				);
			case "submenuitem":
				return (
					<SubmenuItem
						label={props.label}
						values={partvaluesData ? partvaluesData : {}}
						onChange={handlePartValuesChange}
					/>
				);
			case "submenupromo":
				return (
					<SubmenuPromo
						label={props.label}
						values={partvaluesData ? partvaluesData : {}}
						onChange={handlePartValuesChange}
					/>
				);
			case "homepage_head":
				return (
					<HomepageHead
						label={props.label}
						values={partvaluesData ? partvaluesData : {}}
						onChange={handlePartValuesChange}
					/>
				);
			case "contentpage_head":
				return (
					<ContentpageHead
						label={props.label}
						values={partvaluesData ? partvaluesData : {}}
						onChange={handlePartValuesChange}
					/>
				);
			case "campaign_foot":
				return (
					<CampaignFoot
						label={props.label}
						values={partvaluesData ? partvaluesData : {}}
						onChange={handlePartValuesChange}
					/>
				);
			case "button_set":
				return (
					<ButtonSet
						label={props.label}
						values={partvaluesData ? partvaluesData : {}}
						onChange={handlePartValuesChange}
					/>
				);
			case "header_set":
				return (
					<HeaderSet
						label={props.label}
						values={partvaluesData ? partvaluesData : {}}
						onChange={handlePartValuesChange}
					/>
				);
			case "two_columns_block":
			case "three_columns_block":
				return (
					<ColumnsBlock
						parttype={partType.key}
						label={props.label}
						values={partvaluesData ? partvaluesData : {}}
						onChange={handlePartValuesChange}
					/>
				);
			case "dashboard_block":
				return (
					<DashboardBlock
						parttype={partType.key}
						label={props.label}
						values={partvaluesData ? partvaluesData : {}}
						onChange={handlePartValuesChange}
					/>
				);
			case "interest_table":
				return (
					<InterestTable
						parttype={partType.key}
						label={props.label}
						values={partvaluesData ? partvaluesData : {}}
						onChange={handlePartValuesChange}
					/>
				);
			case "table":
			case "content_table":
				return (
					<Table
						parttype={partType.key}
						label={props.label}
						values={partvaluesData ? partvaluesData : {}}
						onChange={handlePartValuesChange}
					/>
				);
			default:
				return (
					<div className="mt-5 mb-12">
						{props.label && <h5>{props.label}</h5>}
						<div
							className={partType.attributes.length > 1 || props.label ? "pl-6 mt-3  border-l border-grey-light " : ""}>
							{(() =>
								map(partType.attributes, (partAttribute, partAttributeKey) => {
									const subValues =
										partvaluesData != null && Object.prototype.hasOwnProperty.call(partvaluesData, partAttributeKey)
											? partvaluesData[partAttributeKey]
											: null;
									return (
										<Attribute
											key={partAttributeKey}
											attribute={partAttribute}
											values={subValues}
											onChange={values => {
												handlePartAttributeValuesChange(partAttributeKey, values);
											}}
										/>
									);
								}))()}
						</div>
					</div>
				);
		}
	}
}

PartContent.propTypes = {
	label: PropTypes.string,
	parttype: PropTypes.string.isRequired,
	values: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func.isRequired,
};
