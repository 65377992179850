import { useState } from "react";
import { route } from "../../../../../js/helpers";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../js/react/hooks/useGenericHandler";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import TextEditor from "../forms/textEditor/TextEditor";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";

export default function ReplaceTagForm() {
	const [formData, setFormData] = useState({ ...window.data.replace_tag });
	const inputHandler = useInputHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);

	const textEditorSetting = {
		height: 250,
		forced_root_block: false, // dont start with the <p> tag
		extended_valid_elements: "img[src|alt|title|width|height|style]", // accept img for preview
	};

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("general.general_settings") }]} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("general.edit_:item", {}, { item: "communication.tags.singular" })}</h1>

				<a className="link" href={route("crm.replace-tag-settings.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "communication.tags.plural" })}
				</a>
			</div>
			<AjaxForm
				method="PUT"
				submitUrl={route("crm.replace-tag-settings.update", formData.tag)}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "communication.tags.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "communication.tags.singular" })}
				onSuccess={() => {
					Nav.go(route("crm.replace-tag-settings.index"));
				}}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<LabeledTextInput
									name="title"
									value={formData.title}
									onChange={inputHandler}
									label="communication.tags.settings.fields.title"
									required
								/>
							</div>

							<div className="form-full">
								<LabeledTextInput
									name="description"
									value={formData.description}
									onChange={inputHandler}
									label="communication.tags.settings.fields.description"
								/>
							</div>

							{formData.is_html ? (
								<>
									<div className="form-full">
										<label>
											<Translate content="communication.tags.settings.fields.default" />
										</label>
										<TextEditor
											type="mail"
											value={formData.default}
											overrideConfig={textEditorSetting}
											onChange={value => {
												genericHandler(value, "default");
											}}
										/>
									</div>
									<div className="form-full">
										<label>
											<Translate content="communication.tags.settings.fields.preview" />
										</label>
										<TextEditor
											type="mail"
											value={formData.preview}
											overrideConfig={textEditorSetting}
											onChange={value => {
												genericHandler(value, "preview");
											}}
										/>
									</div>
								</>
							) : (
								<>
									<div className="form-full">
										<LabeledTextInput
											name="default"
											value={formData.default}
											onChange={inputHandler}
											label="communication.tags.settings.fields.default"
										/>
									</div>
									<div className="form-full">
										<LabeledTextInput
											name="preview"
											value={formData.preview}
											onChange={inputHandler}
											label="communication.tags.settings.fields.preview"
										/>
									</div>
								</>
							)}

							<div className="form-full">
								<Checkmark
									label={uctrans("communication.tags.settings.fields.show_in_overview")}
									name="show_in_overview"
									checked={formData.show_in_overview}
									onChange={inputHandler}
								/>
							</div>

							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="communication.tags.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
