import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import { useEffect, useRef, useState } from "react";
import { route } from "../../../../../../js/helpers";
import { parse } from "../../../../../../js/lib/Date";
import Nav from "../../../../../../js/lib/Nav";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import DatePicker from "../../../../../../js/react/components/general/DatePicker";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import Select from "../../../../../../js/react/components/general/Select";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import useDateHandler from "../../../../../../js/react/hooks/useDateHandler";
import useSelectHandler from "../../../../../../js/react/hooks/useSelectHandler";
import QualitativeResults from "./components/QualitativeResults";
import QuantitativeResults from "./components/QuantitativeResults";

export default function ConversationReport() {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);
	const formRef = useRef(null);
	const formInfo = window.data.formInfo ? cloneDeep(window.data.formInfo) : [];
	const reportTypes = Object.values(formInfo.conversationReportTypes);
	const selectHandler = useSelectHandler(setFormValues);
	const datePickerHandler = useDateHandler(setFormValues);

	const periodTypeOptions = formInfo.periodTypes;

	const onSuccess = response => {
		if (response.data) {
			setData(response.data);
			setLoading(false);
		}
	};

	const onError = response => {
		console.error(response.error);
	};

	const getComponent = data => {
		if (data) {
			switch (data.type) {
				case "conversation_quantitative":
					return (
						<QuantitativeResults
							data={data.result}
							title={data.title}
							periodInfo={data.periodInfo}
							formValues={formValues}
						/>
					);
				case "conversation_qualitative":
					return (
						<QualitativeResults
							data={data.result}
							title={data.title}
							periodInfo={data.periodInfo}
							formValues={formValues}
						/>
					);
				default:
					break;
			}
		}
		return null;
	};

	async function fetchDefaultResult() {
		setLoading(true);
		const response = await axios.post(route(`crm.report.conversation.data`, { ...formValues }));
		if (response) {
			setData(response.data);
			setLoading(false);
		}
	}

	useEffect(() => {
		fetchDefaultResult();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<AjaxForm
				method="POST"
				submitUrl={route("crm.report.conversation.data")}
				onSubmit={() => {
					setData(null);
				}}
				onSuccess={onSuccess}
				onError={onError}
				data={{ ...formValues }}
				ref={formRef}
				useFlashMessage>
				<div className="page-title-container">
					<h1 className="page-title">
						{uctrans("report.report")} - {uctrans("report.report_types.conversation")}
					</h1>
				</div>
				<div className="flex w-full justify-between">
					<div className="w-1/3 pr-2">
						<label htmlFor="conversationReportType">{uctrans("report.type")}</label>
						<Select
							name="conversationReportType"
							value={reportTypes.filter(({ value }) => `${value}` === `${formValues.conversationReportType}`)}
							options={reportTypes}
							isClearable={false}
							onChange={selectHandler}
						/>
					</div>
					<div className="flex w-1/2 justify-end">
						<div className="w-1/3 mt-2">
							<Select
								name="totalResultYear"
								value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.totalResultYear}`)}
								options={formInfo.years}
								isClearable={false}
								onChange={selectHandler}
							/>
						</div>
						<div className="w-1/3 ml-2">
							<IconButton
								icon="file-text-outline"
								onClick={() => Nav.go(route("crm.report.conversation.pdf", formValues.totalResultYear))}
								content={uctrans("report.generate_total_report")}
								primary
							/>
						</div>
					</div>
				</div>
				<div className="flex w-full">
					<div className="w-1/4 pr-2">
						<label htmlFor="periodType">{uctrans("report.period_type")}</label>
						<Select
							name="periodType"
							value={periodTypeOptions.filter(({ value }) => `${value}` === `${formValues.periodType}`)}
							options={periodTypeOptions}
							isClearable={false}
							onChange={selectHandler}
						/>
					</div>
					{formValues.periodType === "week" && (
						<div className="w-1/8 pr-2">
							<label htmlFor="week">{uctrans("report.week")}</label>
							<Select
								name="week"
								value={formInfo.weeks.filter(({ value }) => `${value}` === `${formValues.week}`)}
								options={formInfo.weeks}
								isClearable={false}
								onChange={selectHandler}
							/>
						</div>
					)}
					{formValues.periodType === "month" && (
						<div className="w-1/8 pr-2">
							<label htmlFor="month">{uctrans("report.month")}</label>
							<Select
								name="month"
								value={formInfo.months.filter(({ value }) => `${value}` === `${formValues.month}`)}
								options={formInfo.months}
								isClearable={false}
								onChange={selectHandler}
							/>
						</div>
					)}
					{(formValues.periodType === "year" ||
						formValues.periodType === "month" ||
						formValues.periodType === "week") && (
						<div className="w-1/8 pr-2">
							<label htmlFor="year">{uctrans("report.year")}</label>
							<Select
								name="year"
								value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.year}`)}
								options={formInfo.years}
								isClearable={false}
								onChange={selectHandler}
							/>
						</div>
					)}
					{formValues.periodType === "week_range" && (
						<>
							<div className="w-1/8 pr-2">
								<label htmlFor="fromWeek">{uctrans("report.from")}</label>
								<Select
									name="fromWeek"
									value={formInfo.weeks.filter(({ value }) => `${value}` === `${formValues.fromWeek}`)}
									options={formInfo.weeks}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
							<div className="w-1/8 pr-2">
								<label htmlFor="fromWeekYear">&nbsp;</label>
								<Select
									name="fromWeekYear"
									value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.fromWeekYear}`)}
									options={formInfo.years}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
							<div className="w-1/8 pr-2">
								<label htmlFor="toWeek">{uctrans("report.to")}</label>
								<Select
									name="toWeek"
									value={formInfo.weeks.filter(({ value }) => `${value}` === `${formValues.toWeek}`)}
									options={formInfo.weeks}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
							<div className="w-1/8 pr-2">
								<label htmlFor="toWeekYear">&nbsp;</label>
								<Select
									name="toWeekYear"
									value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.toWeekYear}`)}
									options={formInfo.years}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
						</>
					)}
					{formValues.periodType === "month_range" && (
						<>
							<div className="w-1/8 pr-2">
								<label htmlFor="fromMonth">{uctrans("report.from")}</label>
								<Select
									name="fromMonth"
									value={formInfo.months.filter(({ value }) => `${value}` === `${formValues.fromMonth}`)}
									options={formInfo.months}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
							<div className="w-1/8 pr-2">
								<label htmlFor="fromMonthYear">&nbsp;</label>
								<Select
									name="fromMonthYear"
									value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.fromMonthYear}`)}
									options={formInfo.years}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
							<div className="w-1/8 pr-2">
								<label htmlFor="toMonth">{uctrans("report.to")}</label>
								<Select
									name="toMonth"
									value={formInfo.months.filter(({ value }) => `${value}` === `${formValues.toMonth}`)}
									options={formInfo.months}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
							<div className="w-1/8 pr-2">
								<label htmlFor="toMonthYear">&nbsp;</label>
								<Select
									name="toMonthYear"
									value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.toMonthYear}`)}
									options={formInfo.years}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
						</>
					)}
					{formValues.periodType === "date_range" && (
						<>
							<div className="w-1/8 pr-2">
								<label htmlFor="fromDate">{uctrans("report.from")}</label>
								<DatePicker
									initialValue={formValues.fromDate ? parse(formValues.fromDate) : null}
									onChange={date => datePickerHandler(date, "fromDate")}
								/>
							</div>
							<div className="w-1/8 pr-2">
								<label htmlFor="toDate">{uctrans("report.to")}</label>
								<DatePicker
									initialValue={formValues.toDate ? parse(formValues.toDate) : null}
									onChange={date => datePickerHandler(date, "toDate")}
								/>
							</div>
						</>
					)}
				</div>
				<div className="flex w-full justify-start">
					<button
						type="submit"
						onClick={() => fetchDefaultResult()}
						disabled={loading}
						className="button button-primary leading-normal">
						{uctrans("report.show_report")}
					</button>
				</div>
			</AjaxForm>
			<div className="mt-5">
				{loading ? (
					<div className="flex justify-center">
						<Spinner width={50} />
					</div>
				) : (
					getComponent(data)
				)}
			</div>
		</>
	);
}
