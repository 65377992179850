import PropTypes from "prop-types";
import { useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import useInputHandler from "../../../../../../js/react/hooks/useInputHandler";
import { route } from "../../../../../../js/helpers";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import Translate from "../../../../../../js/react/components/general/Translate";
import RadioGroup from "../../../../../../js/react/components/general/form/radio/RadioGroup";
import Radio from "../../../../../../js/react/components/general/form/radio/Radio";
import TextEditor from "../../forms/textEditor/TextEditor";

export default function InterestRateClassForm({ interestRateClass, onSuccess, onCancel }) {
	const [formData, setFormData] = useState({ ...interestRateClass });

	const inputHandler = useInputHandler(setFormData);

	const setPropertyFromFormData = (property, value) => {
		const newFormData = Object.assign({}, formData);
		newFormData[property] = value;
		setFormData(newFormData);
	};

	const handleSuccess = () => {
		onSuccess();
	};

	const handleCancel = () => {
		onCancel();
	};

	return (
		<AjaxForm
			method={formData.id === -1 ? "POST" : "PUT"}
			submitUrl={
				formData.id === -1
					? route("crm.interest.interest-rate-classes.store")
					: route("crm.interest.interest-rate-classes.update", formData.id)
			}
			loaderText={uctrans("general.:item_is_being_saved", {}, { item: "interest.interest_rate_classes.singular" })}
			successText={uctrans("general.saved_:item", {}, { item: "interest.interest_rate_classes.singular" })}
			onSuccess={handleSuccess}
			data={{ ...formData }}>
			<div className="md:flex">
				<div className="flex-1 w-2/3 mr-12">
					<div className="form-container">
						<div className="form-full">
							<div className="form-2-3">
								<LabeledTextInput
									name="name"
									value={formData.name}
									onChange={inputHandler}
									label="interest.fields.name"
									required
								/>
							</div>
						</div>
						<div className="form-full">
							<label>
								<Translate content="interest.fields.description" />
							</label>
							<TextEditor
								value={formData.description}
								onChange={value => setPropertyFromFormData("description", value)}
							/>
						</div>
						<div className="form-full">
							<label>
								<Translate content="interest.fields.archived" />
							</label>
							<RadioGroup
								name="archived"
								value={formData.archived}
								onChange={value => setPropertyFromFormData("archived", value)}>
								<Radio label={uctrans("general.no")} value={0} />
								<Radio label={uctrans("general.yes")} value={1} />
								<div className="callout-neutral p-4 mb-4">
									<Translate
										content="interest.sentences.archive_explanation"
										transReplaces={{ type: "interest.interest_rate_classes.singular" }}
									/>
								</div>
							</RadioGroup>
						</div>
						<div className="form-full">
							<div className="w-full md:w-1/2 lg:w-2/3 column">
								<SubmitBar
									item="interest.interest_rate_classes.singular"
									addAnotherAvailable={false}
									onCancel={handleCancel}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AjaxForm>
	);
}
InterestRateClassForm.propTypes = {
	interestRateClass: PropTypes.object.isRequired,
	onSuccess: PropTypes.func,
	onCancel: PropTypes.func,
};
