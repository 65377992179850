import PropTypes from "prop-types";
import { useContext } from "react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import { CmsTypesContext } from "../../cms-types-context";
import Part from "./Part";

export default function PageHeadVideo(props) {
	const { values, handleValueChange } = props;
	const { partTypes } = useContext(CmsTypesContext);

	return (
		<div>
			<Part
				label={uctrans("cms.video_with_header")}
				parttype="video"
				partTypes={partTypes}
				values={values.video}
				onChange={value => {
					handleValueChange("video", value);
				}}
			/>
		</div>
	);
}

PageHeadVideo.propTypes = {
	values: PropTypes.object.isRequired,
	handleValueChange: PropTypes.func.isRequired,
};
