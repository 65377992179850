import PropTypes from "prop-types";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import Radio from "../../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../../js/react/components/general/form/radio/RadioGroup";

export default function Icon(props) {
	const iconOptions = [
		"message-square",
		"message-circle",
		"book-open",
		"list",
		"options-2",
		"info",
		"download",
		"abn360-calculator",
		"person-done",
	];

	const onchange = selectedIcon => {
		props.onChange(selectedIcon);
	};

	return (
		<>
			{props.label && <label>{props.label}</label>}
			<RadioGroup name="icon" onChange={onchange} value={props.value ? props.value : ""} horizontal>
				{iconOptions.map(icon => (
					<Radio value={icon} key={icon} inline horizontal>
						<EvaIcon type={icon} width="16" height="16" fill="#1A7370" />
					</Radio>
				))}
			</RadioGroup>
		</>
	);
}

Icon.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
};
