import { useEffect, useState } from "react";
import { can, route } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import PersonalBirthdayMessageDialog from "../dashboard/PersonalBirthdayMessageDialog";
import RmDashboardBlock from "./RmDashboardBlock";
import Tippy from "@tippyjs/react";

export default function BirthdayBlock() {
	const [birthdayMessageContactperson, setBirthdayMessageContactperson] = useState(null);
	const [personalMessageDialogOpened, setPersonalMessageDialogOpened] = useState(false);
	const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date().getTime());

	useEffect(() => {
		if (birthdayMessageContactperson !== null) {
			// open personal birthday message dialog
			setPersonalMessageDialogOpened(true);
		} else {
			// close personal birthday message dialog
			setPersonalMessageDialogOpened(false);
		}
	}, [birthdayMessageContactperson]);

	const onBirthdayMessageUpdated = async () => {
		setBirthdayMessageContactperson(null);
		setLastUpdatedAt(new Date().getTime());
	};

	return (
		<RmDashboardBlock
			defaultCollapsed
			useFetching
			useFetchingWhenCollapsed
			lastUpdatedAt={lastUpdatedAt}
			blockKey="birthdays"
			renderHeader={blockData => (
				<h5 className="mb-0">
					{uctrans("dashboard.birthdays_next_:nr_days", { nr: 7 })} {blockData ? ` (${blockData.length})` : ""}
				</h5>
			)}
			renderContent={blockData => (
				<ul>
					<li className="flex font-bold mb-3">
						<div className="w-3/12">{uctrans("contactpersons.singular")}</div>
						<div className="w-5/12">{uctrans("contactpersons.fields.employed_at")}</div>
						<div className="w-2/12">{uctrans("dashboard.birthday-age")}</div>
						<div className="w-1/12">{uctrans("dashboard.to_contactperson")}</div>
						{window.data.birthday_module_enabled && (
							<div className="w-2/12">{uctrans("birthday.personal_message")}</div>
						)}
					</li>
					{blockData.map(birthdayData => (
						<li className="flex mb-3" key={birthdayData.id}>
							<div className="w-3/12">{`${birthdayData.first_name} ${birthdayData.insertion} ${birthdayData.last_name}`}</div>
							<div className="w-5/12">
								<Tippy
									placement="top"
									arrow
									animation="perspective"
									duration="400"
									content={
										<>
											{birthdayData.employed_companies.map((employedCompany, index) => (
												<div key={index} style={{ display: "block", paddingTop: index === 0 ? 0 : 20 }}>
													<div>{employedCompany.company_name}</div>
													<div>
														{birthdayData.initials} {birthdayData.last_name}
													</div>

													{employedCompany.visiting_address &&
														employedCompany.visiting_address.street !== "" &&
														employedCompany.visiting_address.number !== "" && (
															<div>
																{employedCompany.visiting_address.street} {employedCompany.visiting_address.number}{" "}
																{employedCompany.visiting_address.addition}
															</div>
														)}

													{employedCompany.visiting_addres &&
														employedCompany.visiting_address.zipcode !== "" &&
														employedCompany.visiting_address.city !== "" && (
															<div>
																{employedCompany.visiting_address.zipcode} {employedCompany.visiting_address.city}
															</div>
														)}
												</div>
											))}
										</>
									}>
									<span style={{ cursor: "pointer" }}>
										{birthdayData.employed_companies.length === 1
											? birthdayData.employed_companies[0].company_name
											: `${birthdayData.employed_companies.length} ${uctrans("companies.plural")}`}
									</span>
								</Tippy>
							</div>
							<div className="w-2/12">
								{birthdayData.dateNextBirthDay ? format(birthdayData.dateNextBirthDay, "dd-MM") : null} (
								{birthdayData.ageNextBirthDay})
							</div>
							<div className="w-1/12">
								{can("contactperson", "update") && (
									<a href={route("crm.contactpersons.edit", birthdayData.id)} className="row-title link">
										<EvaIcon fill="#249286" width="18" height="18" type="arrow-circle-right-outline" />
									</a>
								)}
							</div>
							{window.data.birthday_module_enabled && (
								<div className="w-2/12">
									{birthdayData.birthday_message_seen || birthdayData.editing_disabled ? (
										<Tippy
											placement="left"
											arrow
											animation="perspective"
											duration="400"
											content={uctrans("birthday.contactperson_already_saw_message")}>
											<span>
												<EvaIcon type="checkmark-circle-2-outline" fill="#D04228" height="18" width="18" />
											</span>
										</Tippy>
									) : (
										<a onClick={() => setBirthdayMessageContactperson(birthdayData)} className="row-title link">
											{birthdayData.birthday_message ? (
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("birthday.contactperson_edit_message")}>
													<span>
														<EvaIcon fill="#249286" width="18" height="18" type="edit" />{" "}
													</span>
												</Tippy>
											) : (
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("birthday.contactperson_add_message")}>
													<span>
														<EvaIcon fill="#249286" width="18" height="18" type="plus" />{" "}
													</span>
												</Tippy>
											)}
										</a>
									)}
								</div>
							)}
						</li>
					))}
					<PersonalBirthdayMessageDialog
						opened={personalMessageDialogOpened}
						data={birthdayMessageContactperson}
						onClose={() => setBirthdayMessageContactperson(null)}
						onEditSuccess={() => onBirthdayMessageUpdated()}
					/>
				</ul>
			)}
		/>
	);
}
