import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import Dialog from "../../../../../js/react/components/general/Dialog";
import { createLoader } from "../../../../../js/react/components/general/notifications";
import Select from "../../../../../js/react/components/general/Select";
import Spinner from "../../../../../js/react/components/general/Spinner";

export default function QuestionsCopyDialog(props) {
	const [selectedSourceQuestionnaire, setSelectedSourceQuestionnaire] = useState(null);
	const [questionnaireCopyCandidates, setQuestionnaireCopyCandidates] = useState([]);
	const [loading, setLoading] = useState(false);

	const onSelectChange = selectedOption => {
		setSelectedSourceQuestionnaire(selectedOption);
	};

	const onQuestionsCopySubmit = () => {
		if (!selectedSourceQuestionnaire || !selectedSourceQuestionnaire.value) {
			return;
		}

		const loader = createLoader(
			uctrans("questionnaires.:item_is_being_copied", { item: selectedSourceQuestionnaire.label }),
		);
		axios
			.post(
				route(`crm.questionnaires.copy-questions`, {
					targetQuestionnaireId: props.targetQuestionnaireId,
					sourceQuestionnaireId: selectedSourceQuestionnaire.value,
				}),
			)
			.then(() => {
				props.onConfirmQuestionsCopyDialog();
			})
			.catch(error => {
				console.warn(error);
				loader.failure(uctrans("questionnaires.questionnaire_copy_failed"));
				props.onFailedQuestionsCopyDialog();
			})
			.then(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		// only load if dialog is opened.
		if (props.showQuestionsCopyDialog) {
			const fetchData = async () => {
				setLoading(true);
				const result = await axios.get(route(`crm.questionnaires.copy-candidates`, props.targetQuestionnaireId));
				if (result.data) {
					setQuestionnaireCopyCandidates(result.data);
					setLoading(false);
				}
			};
			fetchData();
		}
	}, [props.showQuestionsCopyDialog, props.targetQuestionnaireId]);

	return (
		<Dialog
			isOpen={props.showQuestionsCopyDialog}
			onClose={props.onCloseQuestionsCopyDialog}
			shouldCloseOnOverlayClick={false}
			title={uctrans("questionnaires.copy_questions")}>
			<div>
				<div className="form-container">
					<div className="row">
						<p>
							<u>{uctrans("questionnaires.warning_copy_questions")}</u>
						</p>
					</div>
					<div className="row">
						<p>{uctrans("questionnaires.copy_questions_description")}</p>
					</div>

					{loading && (
						<div className="mt-5">
							<Spinner width={50} />
						</div>
					)}

					{!loading && (
						<div className="row mt-4 mb-4">
							<Select
								placeholder={uctrans("general.select_:item", {}, { item: "questionnaires.singular" })}
								value={
									questionnaireCopyCandidates && selectedSourceQuestionnaire
										? questionnaireCopyCandidates.filter(
												({ value }) => Number(value) === Number(selectedSourceQuestionnaire.value),
										  )
										: null
								}
								options={questionnaireCopyCandidates}
								name="questionnaire_to_be_copied"
								onChange={selectedOption => onSelectChange(selectedOption)}
								isClearable={false}
							/>
						</div>
					)}

					<div className="row">
						<a
							className={`button button-primary mr-6 ${selectedSourceQuestionnaire ? "" : "disabled"}`}
							onClick={onQuestionsCopySubmit}
							rel="noopener noreferrer">
							{uctrans("questionnaires.copy_questions")}
						</a>
						<a
							className="button button-primary mr-6"
							onClick={props.onCloseQuestionsCopyDialog}
							rel="noopener noreferrer">
							{uctrans("general.cancel")}
						</a>
					</div>
				</div>
			</div>
		</Dialog>
	);
}

QuestionsCopyDialog.propTypes = {
	onCloseQuestionsCopyDialog: PropTypes.func,
	onConfirmQuestionsCopyDialog: PropTypes.func,
	onFailedQuestionsCopyDialog: PropTypes.func,
	showQuestionsCopyDialog: PropTypes.bool,
	targetQuestionnaireId: PropTypes.number,
};
