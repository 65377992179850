import { useState } from "react";
import { route } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import ClientConsentManage from "../../../../../js/react/components/clientConsents/ClientConsentManage";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import Dialog from "../../../../../js/react/components/general/Dialog";
import CheckboxMultiFilter from "../../../../../js/react/components/general/overview/CheckboxMultiFilter";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import TextFilter from "../../../../../js/react/components/general/overview/TextFilter";
import Nav from "../../../../../js/lib/Nav";
import LeadIcon from "../../../../../js/react/components/clientConsents/LeadIcon";
import LeadOverviewItem from "../../../../../js/react/components/clientConsents/LeadOverviewItem";
import LeadsNavigation from "./LeadsNavigation";

export default function ClientConsentWithLeadsOverview() {
	const overviewData = { ...window.data.overview };
	const [manageDialogConsent, setManageDialogConsent] = useState(null);

	const handleExport = overviewParameters => {
		Nav.go(route(`crm.client-consents-with-leads.export`, overviewParameters));
	};

	return (
		<>
			<LeadsNavigation selectedTab="client_consent_with_leads_overview" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("client_consents.consents_with_leads")}</h1>
			</div>
			<AbnOverview
				initialData={overviewData}
				indexUrl={route("crm.client-consents-with-leads.data")}
				modelTranslations={{
					plural: trans("client_consents.plural"),
					singular: trans("client_consents.singular"),
				}}
				renderFilters={overview => (
					<Filters>
						<TextFilter
							hideIcon
							className="form-1-2"
							name="search"
							label={uctrans("general.search")}
							placeholder={uctrans("client_consents.search_placeholder")}
						/>
						<TextFilter
							hideIcon
							className="form-1-2"
							name="company_name"
							label={uctrans("intermediaries.singular")}
							placeholder={uctrans("general.search_:item", {}, { item: "intermediaries.singular" })}
						/>
						<CheckboxMultiFilter
							name="lead_types"
							label={uctrans("client_consents.leads.lead_type_filter")}
							className="overview-filter form-1-2"
							checkMarkClassName="q-full"
							options={overviewData.metadata.filterdata.lead_type_options}
							renderCheckmarkLabel={option => (
								<div className="flex">
									<LeadIcon
										iconUrl={route("crm.files.public-download", { idtoken: option.icon.id_token })}
										type={option.string_key}
										className="-mt-3"
									/>
									{option.label}
								</div>
							)}
							useSelectAll
						/>
						<CheckboxMultiFilter
							name="lead_statuses"
							label={uctrans("client_consents.leads.lead_status_filter")}
							className="overview-filter form-1-2"
							checkMarkClassName="q-full"
							options={overviewData.metadata.filterdata.lead_status_options}
							useSelectAll
						/>
						<SelectFilter
							className="form-1-2"
							name="team_id"
							label={uctrans("intermediaries.fields.team")}
							options={overview.data.metadata.filterdata.teams}
							collapsible
						/>
						<SelectFilter
							className="form-1-2"
							name="relationmanager_id"
							label={uctrans("intermediaries.fields.relationmanager")}
							options={overview.data.metadata.filterdata.relationmanagers}
							collapsible
						/>
						<SelectFilter
							className="form-1-2"
							name="segment_id"
							label={uctrans("intermediaries.fields.segment")}
							options={overview.data.metadata.filterdata.segments}
							collapsible
						/>
						<SelectFilter
							className="form-1-2"
							name="organisation_id"
							label={uctrans("intermediaries.fields.organisation")}
							options={overview.data.metadata.filterdata.organisations}
							collapsible
						/>
					</Filters>
				)}
				renderBody={() => (
					<>
						<DataTable>
							<DataColumn
								width={6}
								sortable="company_label"
								renderHeaderContent={() => uctrans("client_consents.fields.company")}
								renderCellContent={clientConsent => (clientConsent.company_label ? clientConsent.company_label : "-")}
							/>
							<DataColumn
								width={5}
								sortable="name_first_applicant"
								renderHeaderContent={() => uctrans("client_consents.fields.client_name")}
								renderCellContent={clientConsent => clientConsent.overview_label}
							/>
							<DataColumn
								width={3}
								sortable="loan_number"
								renderHeaderContent={() => uctrans("client_consents.fields.loan_number")}
								renderCellContent={clientConsent => (clientConsent.loan_number ? clientConsent.loan_number : "-")}
							/>
							<DataColumn
								width={3}
								sortable="starts_at"
								renderHeaderContent={() => uctrans("client_consents.fields.starts_at")}
								renderCellContent={clientConsent =>
									clientConsent.starts_at ? format(clientConsent.starts_at, "dd-MM-y") : "-"
								}
							/>
							<DataColumn
								width={3}
								sortable="expires_at"
								renderHeaderContent={() => uctrans("client_consents.fields.expires_at")}
								renderCellContent={clientConsent =>
									clientConsent.expires_at ? format(clientConsent.expires_at, "dd-MM-y") : "-"
								}
							/>
							<DataColumn
								width={4}
								renderHeaderContent={() => uctrans("client_consents.leads.plural")}
								renderCellContent={clientConsent =>
									!!clientConsent.active && (
										<div className="w-full flex justify-center">
											{clientConsent.leads.map((lead, i) => (
												<LeadOverviewItem key={i} lead={lead} onClick={() => setManageDialogConsent(clientConsent)} />
											))}
										</div>
									)
								}
							/>
						</DataTable>
						<Dialog width={800} isOpen={manageDialogConsent != null} onClose={() => setManageDialogConsent(null)}>
							<ClientConsentManage clientConsent={manageDialogConsent} forWebsite={false} />
						</Dialog>
					</>
				)}
				renderFooter={overview => (
					<a className="button button-primary mt-10" onClick={() => handleExport(overview.getParameters())}>
						{uctrans("client_consents.export")}
					</a>
				)}
			/>
		</>
	);
}
