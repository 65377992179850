import map from "lodash/map";
import { useState } from "react";
import { route } from "../../../../../../js/helpers";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import Attribute from "../general/attributes/Attribute";
import PartContent from "../general/parts/PartContent";
import { CmsTypesContext } from "../cms-types-context";

export default function FixedContentForm() {
	const { key } = window.data.values;
	const { label } = window.data.values;
	const { attributes } = window.data.values;
	const [cmsTypes] = useState(window.data.cms_types);
	const [contents, setContents] = useState(window.data.values.contents);

	const handleContentChange = (attributeKey, values) => {
		const newContentValues = { ...contents };
		newContentValues[attributeKey] = values;
		setContents(newContentValues);
	};

	const onSuccess = response => {
		setContents(response.data.values.contents);
	};

	return (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{ label: uctrans("general.cms") },
					{ label: uctrans("cms.fixed_content.plural"), route: route("crm.cms.fixed-content.index") },
					{ label },
				]}
			/>
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("general.edit_:item", {}, { item: "cms.fixed_content.singular" })}</h1>
				<a className="link" href={route("crm.cms.fixed-content.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "cms.fixed_content.plural" })}
				</a>
			</div>
			<h3>{label}</h3>
			<AjaxForm
				method="PUT"
				submitUrl={route("crm.cms.fixed-content.update", { fixedContentTypeKey: key })}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "cms.fixed_content.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "cms.fixed_content.singular" })}
				onSuccess={onSuccess}
				data={{ contents }}>
				<CmsTypesContext.Provider value={cmsTypes}>
					<div className="cms-attribute grid-container">
						<div className="grid-1-2">
							{(() =>
								map(attributes, (attribute, key) => {
									const attributeValues =
										contents != null && Object.prototype.hasOwnProperty.call(contents, key) ? contents[key] : null;
									if (attribute.type === "part") {
										return (
											<PartContent
												key={key}
												label={attribute.label}
												parttype={attribute.parttype}
												attribute={attribute}
												values={attributeValues}
												onChange={values => {
													handleContentChange(key, values);
												}}
											/>
										);
									} else {
										return (
											<Attribute
												key={key}
												attribute={attribute}
												values={attributeValues}
												onChange={values => {
													handleContentChange(key, values);
												}}
											/>
										);
									}
								}))()}
						</div>
					</div>
					<div>
						<SubmitBar item={uctrans("cms.fixed_content.singular")} translateLabel={false} />
					</div>
				</CmsTypesContext.Provider>
			</AjaxForm>
		</>
	);
}

FixedContentForm.propTypes = {};
