import { useState } from "react";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import Nav from "../../../../../../js/lib/Nav";
import { route } from "../../../../../../js/helpers";
import TabLinks from "../../general/tabs/TabLinks";
import { getInterestManagementTabs, BRIDGING_LOAN_TAB } from "../InterestManagementTabs";
import Translate from "../../../../../../js/react/components/general/Translate";
import Select from "../../cms/general/attributes/Select";
import classNames from "classnames";
import axios from "axios";
import { queueNotification } from "../../../../../../js/react/components/general/notifications";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";

export default function BridgingLoanForm() {
	const bridgingLoan = { ...window.data.bridging_loan };
	const [selectedInterestType, setSelectedInterestType] = useState(
		bridgingLoan.id === -1 ? -1 : bridgingLoan.selected_type_id,
	);
	const [selectedFixedInterestPeriod, setSelectedFixedInterestPeriod] = useState(
		bridgingLoan.id === -1 ? -1 : bridgingLoan.fixed_interest_period_id,
	);
	const [selectedInterestRateClass, setSelectedInterestRateClass] = useState(
		bridgingLoan.id === -1 ? -1 : bridgingLoan.rate_class_id,
	);

	const removeBridgingLoan = async () => {
		await axios.delete(route("crm.interest.bridging-loans.destroy", bridgingLoan.id));
		queueNotification("success", trans("interest.sentences.bridging_loan_removed"), null);
		Nav.go(route("crm.interest.interest-types.edit", bridgingLoan.interest_type_id));
	};

	const handleSelectedInterestType = interestType => {
		setSelectedFixedInterestPeriod(-1);
		setSelectedInterestRateClass(-1);
		setSelectedInterestType(interestType);
	};

	const getFilteredFixedInterestPeriods = () =>
		window.data.fixed_interest_periods
			.filter(fixedInterestPeriod => fixedInterestPeriod.interest_type_id === selectedInterestType)
			.map(fixedInterestPeriod => ({
				label: fixedInterestPeriod.name,
				value: fixedInterestPeriod.id,
			}));

	const getFilteredInterestRateClasses = () =>
		window.data.interest_rate_classes
			.filter(interestRateClass => interestRateClass.interest_type_id === selectedInterestType)
			.map(interestRateClass => ({
				label: interestRateClass.name,
				value: interestRateClass.id,
			}));

	const submitIsDisabled = () =>
		selectedInterestType === -1 || selectedFixedInterestPeriod === -1 || selectedInterestRateClass === -1;

	const onSuccess = () => {
		Nav.go(route("crm.interest.bridging-loans.index", window.data.interest_type_id));
	};

	const getBreadCrumbs = () => [
		{
			label: uctrans("interest.interest_types.plural"),
			route: route("crm.interest.interest-types.index"),
		},
		{
			label: window.data.current_interest_type.name,
			route: route("crm.interest.interest-types.edit", window.data.current_interest_type.id),
		},
		{
			label: uctrans("interest.bridging_loans.singular"),
		},
	];

	return (
		<>
			<BreadCrumbs breadCrumbs={getBreadCrumbs()} />
			<TabLinks
				tabs={Object.values(getInterestManagementTabs(window.data.interest_type_id))}
				activeTab={BRIDGING_LOAN_TAB}
			/>
			<h1 className="inline-block">
				{uctrans("general.edit_:item", {}, { item: "interest.bridging_loans.singular" })}
			</h1>
			<AjaxForm
				method={bridgingLoan.id === -1 ? "POST" : "PUT"}
				submitUrl={
					bridgingLoan.id === -1
						? route("crm.interest.bridging-loans.store")
						: route("crm.interest.bridging-loans.update", bridgingLoan.id)
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "interest.bridging_loans.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "interest.bridging_loans.singular" })}
				onSuccess={onSuccess}
				data={{
					interest_type_id: bridgingLoan.interest_type_id,
					selected_type_id: selectedInterestType,
					fixed_interest_period_id: selectedFixedInterestPeriod,
					rate_class_id: selectedInterestRateClass,
				}}
				useFlashMessage>
				<div className="md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<label>
										<Translate content="interest.interest_types.singular" />
									</label>
									<Select
										value={selectedInterestType}
										options={[
											{
												label: uctrans("interest.sentences.pick_interest_type"),
												value: -1,
											},
											...window.data.interest_types.map(interestType => ({
												label: interestType.name,
												value: interestType.id,
											})),
										]}
										name="interest_type_id"
										onChange={value => handleSelectedInterestType(value)}
									/>
								</div>
							</div>
							{selectedInterestType !== -1 &&
								(getFilteredInterestRateClasses().length > 0 && getFilteredFixedInterestPeriods().length > 0 ? (
									<>
										<div className="form-full">
											<div className="form-1-3">
												<label>
													<Translate content="interest.fixed_interest_periods.singular" />
												</label>
												<Select
													value={selectedFixedInterestPeriod}
													options={getFilteredFixedInterestPeriods()}
													name="fixed_interest_period_id"
													onChange={value => setSelectedFixedInterestPeriod(value)}
												/>
											</div>
										</div>
										<div className="form-full">
											<div className="form-1-3">
												<label>
													<Translate content="interest.interest_rate_classes.singular" />
												</label>
												<Select
													value={selectedInterestRateClass}
													options={getFilteredInterestRateClasses()}
													name="interest_rate_class_id"
													onChange={value => setSelectedInterestRateClass(value)}
												/>
											</div>
										</div>
									</>
								) : (
									<p className="pb-4">{trans("interest.sentences.bridging_loan_error")}</p>
								))}
							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<button
										className={classNames("button background-color-success button-primary mr-4", {
											disabled: submitIsDisabled(),
										})}
										type="submit"
										disabled={submitIsDisabled()}>
										<Translate
											content={
												bridgingLoan.id === -1
													? "interest.sentences.add_bridging_loan"
													: "interest.sentences.edit_bridging_loan"
											}
										/>
									</button>
									{bridgingLoan.id !== -1 && (
										<a className="link" onClick={() => removeBridgingLoan()}>
											<Translate content="interest.sentences.remove_bridging_loan" />
										</a>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
