import IconButton from "../../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../../js/react/components/general/overview/DataColumn";
import DeleteColumnContent from "../../../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route } from "../../../../../../../js/helpers";
import Navigation from "../Navigation";

export default function townshipOverview() {
	const overviewData = { ...window.data.overview };

	return (
		<>
			<Navigation selectedTab="townships" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("location.townships.plural")}</h1>
				{can("location.township", "create") && (
					<div>
						<IconButton
							href={route("crm.location-settings.townships.create")}
							content={uctrans("general.add_:item", {}, { item: "location.townships.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.location-settings.townships.index")}
				deleteRouteName="crm.location-settings.townships.delete"
				modelTranslations={{
					plural: trans("location.townships.plural"),
					singular: trans("location.townships.singular"),
				}}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							sortable="name"
							renderHeaderContent={() => uctrans("location.townships.fields.name")}
							renderCellContent={township => {
								if (can("location.township", "update")) {
									return (
										<a href={route("crm.location-settings.townships.edit", township.id)} className="row-title link">
											{township.name}
										</a>
									);
								} else {
									return township.name;
								}
							}}
						/>
						<DataColumn
							width={3}
							sortable="province"
							renderHeaderContent={() => uctrans("location.provinces.singular")}
							renderCellContent={township => township.province}
						/>
						{can("location.township", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(township, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(township)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
