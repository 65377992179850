import classNames from "classnames";
import PropTypes from "prop-types";
import map from "lodash/map";
import Tippy from "@tippyjs/react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import FormattedNumberText from "../../FormattedNumberText";

export default function LoanResults({ data, title, staticData }) {
	const getClassForTd = (tab, totaal) =>
		classNames(tab, {
			totaal,
		});

	return (
		<table className="omzet_rapportage_table rapportage_table reporttable">
			<thead>
				<tr>
					<th colSpan="17">{title ? title : uctrans("report.singular")}</th>
				</tr>
				<tr className="head1">
					<td colSpan="1" />
					{map(staticData.loan_statuses, (status, key) => (
						<td colSpan={`${key}` === `0` ? "4" : "3"} className="status_titel" key={key}>
							<span>
								<strong>{status.key}</strong> - {status.label}
							</span>
							<br />
							<span>(€ x 1.000)</span>
						</td>
					))}
				</tr>
				<tr className="head2">
					<td>Naam</td>
					<td className="realisatie">Realisatie</td>
					<td>Doel</td>
					<td>%</td>
					<td>Aantal</td>
					<td className="realisatie">Realisatie</td>
					<td>Doel</td>
					<td>%</td>
					<td className="realisatie">Realisatie</td>
					<td>Doel</td>
					<td>%</td>
					<td className="realisatie">Realisatie</td>
					<td>Doel</td>
					<td>%</td>
					<td className="realisatie">Realisatie</td>
					<td>Doel</td>
					<td>%</td>
				</tr>
			</thead>
			<tbody>
				{map(data, (row, key) => (
					<tr key={key} className={getClassForTd(null, `${key}`.toLowerCase() === `totaal`)}>
						<td>{key}</td>
						{map(staticData.loan_statuses, status => (
							<>
								<td className={getClassForTd("realisatie", `${key}`.toLowerCase() === `totaal`)}>
									<FormattedNumberText
										value={row[status.key] && row[status.key].sum ? row[status.key].sum / 1000 : 0}
									/>
								</td>

								<td className={getClassForTd("doel", `${key}`.toLowerCase() === `totaal`)}>
									<FormattedNumberText
										value={row[status.key] && row[status.key].target ? row[status.key].target / 1000 : 0}
									/>
								</td>

								{Number(status.key) === 210 ? (
									<td className={getClassForTd("percentage", `${key}`.toLowerCase() === `totaal`)}>
										<FormattedNumberText
											value={row[status.key] && row[status.key].percentage ? row[status.key].percentage : 0}
											percentage
										/>
									</td>
								) : (
									<Tippy
										placement="top"
										arrow
										animation="perspective"
										duration="400"
										content={`${uctrans("report.commercial.number_loans")} ${
											row[status.key] && row[status.key].count ? row[status.key].count : 0
										}`}>
										<td className={getClassForTd("percentage", `${key}`.toLowerCase() === `totaal`)}>
											<FormattedNumberText
												value={row[status.key] && row[status.key].percentage ? row[status.key].percentage : 0}
												percentage
											/>
										</td>
									</Tippy>
								)}
								{Number(status.key) === 210 ? (
									<td className={getClassForTd("aantal", `${key}`.toLowerCase() === `totaal`)}>
										<FormattedNumberText value={row[status.key] && row[status.key].count ? row[status.key].count : 0} />
									</td>
								) : null}
							</>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
}

LoanResults.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	staticData: PropTypes.object,
	title: PropTypes.string,
};
