import PropTypes from "prop-types";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import KvkTreeNaturalPersonNotification from "./KvkTreeNaturalPersonNotification";

export default function KvkTree({
	treeData,
	depth,
	ownerFunction,
	ownerIcon,
	showDocument,
	currentSelectedKvkNumber,
	dossierDirectors,
}) {
	return (
		<div className={`${depth !== 0 ? "pl-12" : ""} `}>
			<ul>
				{depth === 0 && <h4 className="mb-1">{uctrans("statutory_companies.singular")}</h4>}
				{ownerFunction ? (
					<div className="flex mb-1" style={{ height: 20 }}>
						<EvaIcon type={ownerIcon} fill="#009286" height="20" width="20" />
						<h6 className="ml-1 mt-1 flex" style={{ color: "#009286" }}>
							<span>{ownerFunction}</span>
							{treeData.date_of_birth ? (
								<KvkTreeNaturalPersonNotification
									name={treeData.name}
									dateOfBirth={treeData.date_of_birth}
									ownerFunction={ownerFunction}
									dossierDirectors={dossierDirectors}
								/>
							) : null}
						</h6>
					</div>
				) : null}
				<li className="flex ml-1">
					<span style={{ width: "100px" }} className="text-sm font-bold">
						{uctrans("statutory_companies.fields.name")}
					</span>
					<span className="text-sm">{treeData.name}</span>
				</li>
				{treeData.date_of_birth ? (
					<li className="flex ml-1">
						<span style={{ width: "100px" }} className="text-sm font-bold">
							{uctrans("statutory_companies.owners.date_of_birth")}
						</span>
						<span className="text-sm">{`${treeData.date_of_birth.substring(6, 8)}-${treeData.date_of_birth.substring(
							4,
							6,
						)}-${treeData.date_of_birth.substring(0, 4)}`}</span>
					</li>
				) : null}
				{treeData.authorization ? (
					<li className="flex ml-1">
						<span style={{ width: "100px" }} className="text-sm font-bold">
							{uctrans("statutory_companies.owners.authorization")}
						</span>
						<span className="text-sm">{treeData.authorization}</span>
					</li>
				) : null}
				{treeData.kvk_number ? (
					<li className="flex ml-1">
						<span style={{ width: "100px" }} className="text-sm font-bold">
							{uctrans("statutory_companies.fields.kvk_number")}
						</span>
						{showDocument ? (
							<a
								className={`text-sm kvk-link ${
									currentSelectedKvkNumber === treeData.kvk_number && "bg-yellow-lighter"
								}`}
								onClick={() => showDocument(treeData.kvk_number)}>
								{treeData.kvk_number}
							</a>
						) : (
							<span className="text-sm">{treeData.kvk_number}</span>
						)}
					</li>
				) : null}
				{treeData.visiting_address ? (
					<li className="flex ml-1">
						<span style={{ width: "100px" }} className="text-sm font-bold">
							{uctrans("statutory_companies.owners.visiting_address")}
						</span>
						<span className="text-sm">{treeData.visiting_address}</span>
					</li>
				) : null}
				{treeData.owners ? (
					<li>
						<ul className="border-l border-grey-dark pl-2">
							{treeData.owners.map((owner, index) => (
								<li key={index} className="mt-2">
									<KvkTree
										treeData={owner}
										depth={depth + 1}
										currentSelectedKvkNumber={currentSelectedKvkNumber}
										ownerFunction={owner.function}
										ownerIcon={owner.date_of_birth ? "person-outline" : "home-outline"}
										showDocument={showDocument}
										dossierDirectors={dossierDirectors}
									/>
								</li>
							))}
						</ul>
					</li>
				) : null}
			</ul>
		</div>
	);
}

KvkTree.propTypes = {
	treeData: PropTypes.shape({
		name: PropTypes.string,
		authorization: PropTypes.string,
		kvk_number: PropTypes.string,
		visiting_address: PropTypes.string,
		date_of_birth: PropTypes.string,
		owners: PropTypes.array,
	}),
	depth: PropTypes.number,
	ownerFunction: PropTypes.string,
	ownerIcon: PropTypes.string,
	currentSelectedKvkNumber: PropTypes.string,
	showDocument: PropTypes.func,
	dossierDirectors: PropTypes.arrayOf(
		PropTypes.shape({
			date_of_birth: PropTypes.string,
			first_name: PropTypes.string,
			has_files: PropTypes.bool,
			id: PropTypes.number,
			initials: PropTypes.string,
			insertion: PropTypes.string,
			last_name: PropTypes.string,
			salutation: PropTypes.number,
		}),
	),
};
KvkTree.defaultProps = {
	depth: 0,
	ownerFunction: null,
};
