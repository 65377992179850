import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import Select from "../../../../../js/react/components/general/Select";
import Translate from "../../../../../js/react/components/general/Translate";
import AgendaItemCompanyFields from "./AgendaItemCompanyFields";
import AgendaItemDateFields from "./AgendaItemDateFields";
import AgendaItemFormFields from "./AgendaItemFormFields";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import cloneDeep from "lodash/cloneDeep";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import { route } from "../../../../../js/helpers";
import Navigation from "./Navigation";

export default function KifidCheckForm(props) {
	const disabled = !window.data.kifidcheck_form_data.status ? window.data.read_only : true;

	const [formData, setFormData] = useState(
		typeof props.KifidCheckFormData === "undefined"
			? cloneDeep(window.data.kifidcheck_form_data)
			: props.KifidCheckFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.KifidCheckStaticData === "undefined"
			? cloneDeep(window.data.kifidcheck_static_data)
			: props.KifidCheckStaticData,
	);

	const kifidCheckActionOptions = staticData.kifid_check_actions.map(action => ({
		value: action.id,
		label: action.name,
	}));

	const selectedKifidCheckAction =
		Number(formData.action_id) !== Number(0)
			? staticData.kifid_check_actions.find(action => Number(action.id) === Number(formData.action_id))
			: null;

	useEffect(() => {
		if (typeof props.KifidCheckFormData !== "undefined") {
			setFormData(props.KifidCheckFormData);
		}
	}, [props.KifidCheckFormData]);

	useEffect(() => {
		if (typeof props.KifidCheckStaticData !== "undefined") {
			setStaticData(props.KifidCheckStaticData);
		}
	}, [props.KifidCheckStaticData]);

	const onSuccess = () => {
		if (window.data.agendaNamespace || staticData.creating) {
			Nav.go(route("crm.agenda-items.index"));
		} else {
			if (staticData.statutory) {
				Nav.go(route("crm.intermediaries.agenda-items.index", { intermediary: staticData.main_company.id }));
			} else if (staticData.intermediary) {
				Nav.go(route("crm.intermediaries.agenda-items.index", { intermediary: formData.company.id }));
			} else if (staticData.organisation) {
				Nav.go(route("crm.organisations.agenda-items.index", { organisation: formData.company.id }));
			}
		}
	};

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	const previousURL = () => {
		if (window.data.agendaNamespace) {
			return route("crm.agenda-items.index");
		}

		if (staticData.statutory) {
			return route("crm.intermediaries.agenda-items.index", { intermediary: staticData.main_company.id });
		} else if (staticData.intermediary) {
			return route("crm.intermediaries.agenda-items.index", { intermediary: formData.company.id });
		} else if (staticData.organisation) {
			return route("crm.organisations.agenda-items.index", { organisation: formData.company.id });
		}
	};

	return (
		<>
			{!staticData.creating && (
				<Navigation
					selectedTab={window.data.nav_data.activeTabItem}
					tabs={window.data.nav_data.tabs}
					breadCrumbs={window.data.nav_data.breadcrumbs}
				/>
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.company_name
						? uctrans(
								"general.:item1 from :item2",
								{ item2: window.data.company_name },
								{ item1: "kifid_checks.singular" },
						  )
						: uctrans("kifid_checks.singular")}
				</h1>
				{window.data.agendaNamespace || staticData.creating ? (
					<a className="link" href={route("crm.agenda-items.index")}>
						{uctrans("general.back_to_:page", {}, { page: "agendaitems.accountsupport" })}
					</a>
				) : (
					<a className="link" href={previousURL()}>
						{uctrans("general.back_to_overview_:page", {}, { page: "agendaitems.plural" })}
					</a>
				)}
			</div>

			<AjaxForm
				method={staticData.creating ? "POST" : "PUT"}
				submitUrl={
					staticData.statutory
						? route(`crm.statutory-companies.kifid-checks.update`, [
								formData.agenda_item.statutory_company_id,
								formData.id,
						  ])
						: route(`crm.intermediaries.kifid-checks.update`, [formData.agenda_item.company_id, formData.id])
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "kifid_checks.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "kifid_checks.singular" })}
				data={{ ...formData }}
				onSuccess={onSuccess}
				useFlashMessage={staticData.creating || window.data.agendaNamespace}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<AgendaItemFormFields
								onChange={value => handleFormValueChange("agenda_item", value)}
								agendaItemFormData={formData.agenda_item}
								agendaItemStaticData={staticData}
								editable={!disabled}
								showAgendaDate
							/>

							<div className="form-full">
								<label htmlFor="kifid_binding_status">
									<Translate content="kifid_checks.kifid_status" />
								</label>
								{staticData.kifid_types && formData.statutory_company ? (
									formData.statutory_company.kifid_type ? (
										<span>
											{
												staticData.kifid_types.find(
													type => Number(type.value) === Number(formData.statutory_company.kifid_type),
												).label
											}
										</span>
									) : (
										<span>-</span>
									)
								) : (
									<span>-</span>
								)}
							</div>

							<div className="form-full">
								<label htmlFor="description">
									<Translate content="kifid_checks.fields.description" />
								</label>
								<textarea
									id="notes"
									name="notes"
									value={formData.notes}
									onChange={e => handleFormValueChange(e.target.name, e.target.value)}
									placeholder={uctrans("kifid_checks.fields.description_placeholder")}
									autoComplete="off"
								/>
							</div>

							<div className="form-full">
								<Checkmark
									label={uctrans("kifid_checks.fields.handled")}
									name="status"
									disabled={disabled}
									checked={formData.status}
									onChange={e => handleFormValueChange(e.target.name, e.target.checked)}
								/>
							</div>

							{Number(formData.status) === Number(1) && (
								<>
									<div className="form-full">
										<label htmlFor="action_id">
											<Translate content="kifid_checks.check_actions.singular" />{" "}
											<span className="text-orange"> *</span>
										</label>
										<Select
											isClearable
											disabled={disabled}
											value={kifidCheckActionOptions.filter(
												({ value }) => Number(value) === Number(formData.action_id),
											)}
											options={kifidCheckActionOptions}
											name="action_id"
											onChange={selectedValue =>
												handleFormValueChange("action_id", selectedValue ? selectedValue.value : null)
											}
											placeholder={uctrans("general.select_:item", {}, { item: "kifid_checks.check_actions.singular" })}
										/>
									</div>
									{selectedKifidCheckAction && selectedKifidCheckAction.is_open && (
										<div className="form-full">
											<label htmlFor="action_value">{selectedKifidCheckAction.open_title}</label>
											<textarea
												disabled={disabled}
												id="action_value"
												name="action_value"
												value={formData.action_value}
												onChange={e => handleFormValueChange(e.target.name, e.target.value)}
												autoComplete="off"
											/>
										</div>
									)}
								</>
							)}
							<div className="form-full">
								<div className="row">
									<div className="w-full md:w-1/2 lg:w-1/3 column">
										<SubmitBar item="kifid_checks.singular" addAnotherAvailable={false} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="flex-none w-1/3 border-l pl-12">
						<AgendaItemDateFields agendaItem={formData.agenda_item} />

						<AgendaItemCompanyFields statutory companyId={formData.agenda_item.statutory_company_id} />
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
KifidCheckForm.propTypes = {
	KifidCheckFormData: PropTypes.object,
	KifidCheckStaticData: PropTypes.object,
};
