import { useState } from "react";
import { route, yesNoOptions } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import { uctrans, trans } from "../../../../../js/lib/Translator";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";
import AccountSupportActions from "./AccountSupportActions";

export default function AccountSupportAgendaOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	const updateLink = () => {
		if (window.data.organisation) {
			return route("crm.organisations.agenda-items.index", { organisation: window.data.organisation_id });
		} else if (window.data.intermediary) {
			return route("crm.intermediaries.agenda-items.index", { intermediary: window.data.intermediary_id });
		}
		return route("crm.agenda-items.index");
	};

	return (
		<>
			{window.data.nav_data && (
				<>
					<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
					<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
				</>
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.company_name
						? uctrans(
								"general.:item1 from :item2",
								{ item2: window.data.company_name },
								{ item1: "companies.agenda_items" },
						  )
						: uctrans("agendaitems.accountsupport")}
				</h1>

				{window.data.showCreateActions ? <AccountSupportActions /> : null}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={updateLink()}
				modelTranslations={{
					plural: trans("agendaitems.plural"),
					singular: trans("agendaitems.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
						<SelectFilter
							className="form-2-3"
							name="user"
							label={uctrans("agendaitems.fields.user")}
							options={overviewData.overview.filterdata.users}
						/>
						<SelectFilter
							className="form-2-3"
							name="type"
							label={uctrans("agendaitems.fields.type")}
							options={overviewData.overview.filterdata.types}
						/>
						<SelectFilter
							className="form-1-4"
							name="organisation"
							label={uctrans("agendaitems.fields.organisation")}
							options={overviewData.overview.filterdata.organisations}
							collapsible
						/>
						<SelectFilter
							className="form-1-4"
							name="status"
							label={uctrans("agendaitems.fields.handled")}
							options={overviewData.overview.filterdata.agendaitem_statuses}
							collapsible
						/>
						<SelectFilter
							className="form-1-4"
							name="within_processing_sla"
							label={uctrans("agendaitems.fields.within_processing_sla")}
							options={overviewData.overview.filterdata.within_processing_sla}
							collapsible
						/>
						<SelectFilter
							className="form-1-4"
							name="within_sla"
							label={uctrans("agendaitems.fields.within_sla")}
							options={overviewData.overview.filterdata.within_sla}
							collapsible
						/>
						{!!window.data.allow_statutory_filters && (
							<SelectFilter
								className="form-1-3"
								name="statutory"
								label={uctrans("agendaitems.fields.statutory")}
								options={yesNoOptions()}
								collapsible
							/>
						)}
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("agendaitems.fields.agenda_date")}
							renderCellContent={agendaItem => (
								<a
									href={
										window.data.agendaNamespace
											? route("crm.agenda.agenda-items.open", agendaItem)
											: route("crm.agenda-items.open", agendaItem)
									}
									className="row-title link">
									{agendaItem.agenda_date ? format(agendaItem.agenda_date, "dd-MM-y") : "-"}
								</a>
							)}
							sortable="agenda_date"
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("agendaitems.fields.servicelevel_processing_date")}
							renderCellContent={agendaItem =>
								agendaItem.servicelevel_processing_date
									? format(agendaItem.servicelevel_processing_date, "dd-MM-y")
									: "-"
							}
							sortable="servicelevel_processing_date"
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("agendaitems.fields.servicelevel_date")}
							renderCellContent={agendaItem =>
								agendaItem.servicelevel_date ? format(agendaItem.servicelevel_date, "dd-MM-y") : "-"
							}
							sortable="servicelevel_date"
						/>

						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("agendaitems.fields.type")}
							renderCellContent={agendaItem =>
								agendaItem.type && (
									<span>
										{agendaItem.type}
										{agendaItem.is_alert && <span className="text-red-dark font-black ml-2">!</span>}
									</span>
								)
							}
							sortable="type"
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("agendaitems.fields.intermediary")}
							renderCellContent={agendaItem =>
								agendaItem.intermediary && (agendaItem.intermediary.label !== null) !== "undefined" ? (
									<span>{agendaItem.intermediary.label}</span>
								) : (
									"-"
								)
							}
							sortable="intermediary"
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("agendaitems.fields.organisation")}
							renderCellContent={agendaItem =>
								agendaItem.organisation && (agendaItem.organisation.label !== null) !== "undefined"
									? agendaItem.organisation.label
									: "-"
							}
							sortable="organisation"
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("agendaitems.fields.info")}
							renderCellContent={agendaItem => (agendaItem.info ? agendaItem.info : "-")}
							sortable="info"
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("agendaitems.fields.relationmanager")}
							renderCellContent={agendaItem =>
								agendaItem.relationmanager && (agendaItem.relationmanager.label !== null) !== "undefined"
									? agendaItem.relationmanager.label
									: "-"
							}
							sortable="relationmanager"
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("agendaitems.fields.user")}
							renderCellContent={agendaItem =>
								agendaItem.user && (agendaItem.user.label !== null) !== "undefined" ? agendaItem.user.label : "-"
							}
							sortable="user"
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("agendaitems.fields.handled")}
							renderCellContent={agendaItem => agendaItem.status}
							sortable="status"
						/>
					</DataTable>
				)}
			/>
		</>
	);
}
