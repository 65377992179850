import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { route } from "../../../../../js/helpers";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import cloneDeep from "lodash/cloneDeep";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";

export default function DepartmentForm(props) {
	const [formData, setFormData] = useState(
		typeof props.departmentFormData === "undefined"
			? cloneDeep(window.data.department_form_data)
			: props.departmentFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.departmentStaticData === "undefined"
			? cloneDeep(window.data.department_static_data)
			: props.departmentStaticData,
	);

	const inputHandler = useInputHandler(setFormData);

	useEffect(() => {
		if (typeof props.departmentFormData !== "undefined") {
			setFormData(props.departmentFormData);
		}
	}, [props.departmentFormData]);

	useEffect(() => {
		if (typeof props.departmentStaticData !== "undefined") {
			setStaticData(props.departmentStaticData);
		}
	}, [props.departmentStaticData]);

	const onSuccess = () => {
		Nav.go(route("crm.departments.index"));
	};

	return (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{ label: uctrans("general.general_settings") },
					{ label: uctrans("departments.plural"), route: route("crm.departments.index") },
					{
						label: staticData.creating
							? uctrans("general.add_:item", {}, { item: "departments.singular" })
							: formData.name,
					},
				]}
			/>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(
						staticData.creating ? "general.add_:item" : "general.edit_:item",
						{},
						{ item: "departments.singular" },
					)}
				</h1>
				<a className="link" href={route("crm.departments.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "departments.plural" })}
				</a>
			</div>
			<AjaxForm
				method={staticData.creating ? "POST" : "PUT"}
				submitUrl={staticData.creating ? route("crm.departments.store") : route("crm.departments.update", formData.id)}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "departments.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "departments.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="name"
										value={formData.name}
										onChange={inputHandler}
										label="departments.fields.name"
										required
									/>
								</div>
							</div>

							<div className="form-full">
								<Checkmark
									label={uctrans("departments.fields.is_abn")}
									name="is_abn"
									checked={formData.is_abn}
									onChange={inputHandler}
								/>
							</div>

							{!!formData.is_abn && (
								<>
									<div className="form-full">
										<Checkmark
											label={uctrans("departments.fields.is_imd")}
											name="is_imd"
											checked={formData.is_imd}
											onChange={inputHandler}
										/>
									</div>
									<div className="form-full">
										<Checkmark
											label={uctrans("departments.fields.is_aahg")}
											name="is_aahg"
											checked={formData.is_aahg}
											onChange={inputHandler}
										/>
									</div>
								</>
							)}

							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="departments.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
DepartmentForm.propTypes = {
	departmentFormData: PropTypes.object,
	departmentStaticData: PropTypes.object,
};
