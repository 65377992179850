import find from "lodash/find";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { CmsTypesContext } from "../../cms-types-context";
import Number from "../attributes/Number";
import Select from "../attributes/Select";
import TableRowList from "./TableRowList";

export default function Table(props) {
	const [values, setValues] = useState(props.values ? props.values : {});
	useEffect(() => {
		setValues(props.values ? props.values : "");
	}, [props.values]);
	const handleValuesChange = newValues => {
		setValues(newValues);
		props.onChange(newValues);
	};
	const handleValueChange = (name, newValue) => {
		const newValues = { ...values };
		newValues[name] = newValue;
		handleValuesChange(newValues);
	};

	const { partTypes } = useContext(CmsTypesContext);
	const partType = find(partTypes, partType => partType.key === props.parttype);

	if (partType) {
		return (
			<div className="mt-5 mb-12">
				<h5>{props.label}</h5>
				<div className="form-container">
					<span className="form-2-3">
						<Select
							label={partType.attributes.type.label}
							value={values.type}
							options={partType.attributes.type.options}
							onChange={value => {
								handleValueChange("type", value);
							}}
						/>
					</span>
					<span className="form-1-3">
						<Number
							label={partType.attributes.nr_of_columns.label}
							value={values.nr_of_columns ? parseInt(values.nr_of_columns) : ""}
							onChange={value => {
								handleValueChange("nr_of_columns", value);
							}}
							max={10}
						/>
					</span>
				</div>
				<TableRowList
					tableType={props.parttype}
					label={partType.attributes.rows.label}
					values={values.rows}
					onChange={value => {
						handleValueChange("rows", value);
					}}
					nrOfColumns={values.nr_of_columns}
				/>
			</div>
		);
	}
}

Table.propTypes = {
	parttype: PropTypes.oneOf(["table", "content_table"]).isRequired,
	label: PropTypes.string,
	values: PropTypes.shape({
		type: PropTypes.string,
		nr_of_columns: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		rows: PropTypes.arrayOf(
			PropTypes.shape({
				cells: PropTypes.arrayOf(
					PropTypes.shape({
						content: PropTypes.string,
					}),
				),
			}),
		),
	}),
	onChange: PropTypes.func.isRequired,
};
