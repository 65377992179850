import PropTypes from "prop-types";
import { route, can } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";

export default function Navigation(props) {
	const tabs = [];
	if (can("dossier.dossier_part", "view")) {
		tabs.push({
			key: "dossier-parts",
			label: uctrans("dossiers.parts.plural"),
			route: route("crm.dossier-settings.dossier-parts.index"),
		});
	}
	if (can("dossier.bad_press_check_term", "view")) {
		tabs.push({
			key: "bad-press-check-terms",
			label: uctrans("dossiers.bad_press_checks_terms.plural"),
			route: route("crm.dossier-settings.bad-press-check-terms.index"),
		});
	}
	if (can("dossier.cooperation_agreement", "update")) {
		tabs.push({
			key: "cooperation-agreements",
			label: uctrans("dossiers.cooperation_agreements.singular"),
			route: route("crm.dossier-settings.cooperation-agreements.current"),
		});
	}
	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("dossiers.settings") }]} />
			<TabLinks tabs={tabs} activeTab={props.selectedTab} />
		</>
	);
}
Navigation.propTypes = {
	selectedTab: PropTypes.string.isRequired,
};
