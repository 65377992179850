import PropTypes from "prop-types";
import { useContext, useCallback } from "react";
import { format, parse, default_date_format } from "../../../../../../../js/lib/Date";
import { uctrans } from "../../../../../../../js/lib/Translator";
import DatePicker from "../../../../../../../js/react/components/general/DatePicker";
import Radio from "../../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../../js/react/components/general/form/radio/RadioGroup";
import CompanyDossierContext from "../CompanyDossierContext";
import DossierFileInput from "../fileInput/DossierFileInput";
import DossierPartOrganisationIsCentral from "./DossierPartOrganisationIsCentral";

export default function DefaultDossierPart({ completionData, partData }) {
	const { dossierPartTypes, handleFormChange, readOnly } = useContext(CompanyDossierContext);

	const getLabel = useCallback(
		() => (partData.field_name !== <label /> ? <label>{partData.field_name}</label> : null),
		[partData.field_name],
	);

	const getInputElement = () => {
		switch (Number(partData.type)) {
			case dossierPartTypes.NUMBER.key:
				return (
					<>
						{getLabel()}
						<input
							type="number"
							disabled={readOnly}
							value={completionData.field_value || ""}
							onChange={e => handleFormChange(partData.id, "field_value", e.target.value)}
						/>
					</>
				);
			case dossierPartTypes.TEXT.key:
				return (
					<>
						{getLabel()}
						<input
							disabled={readOnly}
							type="text"
							value={completionData.field_value || ""}
							onChange={e => handleFormChange(partData.id, "field_value", e.target.value)}
						/>
					</>
				);
			case dossierPartTypes.TEXT_MULTIPLE.key:
				return (
					<>
						{getLabel()}
						<textarea
							disabled={readOnly}
							value={completionData.field_value || ""}
							onChange={e => handleFormChange(partData.id, "field_value", e.target.value)}
						/>
					</>
				);
			case dossierPartTypes.DATE.key:
				return (
					<>
						{getLabel()}
						<DatePicker
							disabled={readOnly}
							initialValue={completionData.field_value ? parse(completionData.field_value, default_date_format) : null}
							showClearButton
							onChange={date =>
								handleFormChange(partData.id, "field_value", date ? format(date, default_date_format) : null)
							}
						/>
					</>
				);
			case dossierPartTypes.YES_NO.key:
				return (
					<>
						{getLabel()}
						<RadioGroup
							disabled={readOnly}
							name={partData.type}
							value={completionData.field_value}
							onChange={value => handleFormChange(partData.id, "field_value", value)}>
							<Radio label={uctrans("general.yes")} value="1" />
							<Radio label={uctrans("general.no")} value="0" />
						</RadioGroup>
					</>
				);
			default:
				return <div>{uctrans("dossiers.fields.unkown_part_type")}</div>;
		}
	};

	return (
		<>
			<div className="w-full">
				<div className="dossier-row">{getInputElement()}</div>
			</div>
			<DossierPartOrganisationIsCentral completionData={completionData} />
			<div className="w-full mt-4">
				<div className="dossier-row">
					<label htmlFor="type">{uctrans("dossiers.fields.files")}</label>
					<DossierFileInput
						disabled={readOnly}
						existingFileDownloadRoute="crm.intermediaries.dossier.download-completion-file"
						initialFiles={completionData ? completionData.files.files : []}
						initialArchivedFiles={completionData ? completionData.files.archivedFiles : []}
						onChange={files => handleFormChange(partData.id, "files", files)}
					/>
				</div>
			</div>
		</>
	);
}

DefaultDossierPart.propTypes = {
	partData: PropTypes.object,
	completionData: PropTypes.object,
};
