import { useState } from "react";
import { route } from "../../../../../../js/helpers";
import Nav from "../../../../../../js/lib/Nav";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import useInputHandler from "../../../../../../js/react/hooks/useInputHandler";
import Navigation from "../SettingsNavigation";

export default function FlowmailerWhitelistRecipientsForm() {
	const [formData, setFormData] = useState({ ...window.data.flowMailer_whitelist_recipient });
	const inputHandler = useInputHandler(setFormData);

	const onSuccess = () => {
		Nav.go(route("crm.communication-settings.flow-mailer-whitelist-recipients.index"));
	};

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	return (
		<>
			<Navigation selectedTab="flowMailerWhitelistRecipients" />

			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans("general.edit_:item", {}, { item: "flowmailer_whitelist_recipients.singular" })}
				</h1>

				<a className="link" href={route("crm.communication-settings.flow-mailer-whitelist-recipients.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "flowmailer_whitelist_recipients.plural" })}
				</a>
			</div>
			<AjaxForm
				method={formData.id === -1 ? "POST" : "PUT"}
				submitUrl={
					formData.id === -1
						? route("crm.communication-settings.flow-mailer-whitelist-recipients.store", formData)
						: route("crm.communication-settings.flow-mailer-whitelist-recipients.update", formData.id)
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "flowmailer_whitelist_recipients.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "flowmailer_whitelist_recipients.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="name"
										value={formData.name}
										onChange={inputHandler}
										label="flowmailer_whitelist_recipients.fields.name"
										required
									/>
								</div>
							</div>
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="email"
										value={formData.email}
										onChange={inputHandler}
										label="flowmailer_whitelist_recipients.fields.email"
										required
									/>
								</div>
							</div>
							<div className="form-full">
								<Checkmark
									label={uctrans("flowmailer_whitelist_recipients.fields.default_fallback")}
									name="default_fallback"
									checked={formData.default_fallback}
									onChange={e => handleFormValueChange(e.target.name, e.target.checked)}
								/>
							</div>
							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="flowmailer_whitelist_recipients.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
