import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import map from "lodash/map";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import Dialog from "../../../../../../../js/react/components/general/Dialog";
import { CmsTypesContext } from "../../cms-types-context";
import PartContent from "../parts/PartContent";
import PartListLabel from "./PartListLabel";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export default function PartList(props) {
	const [partlistData, setPartlistData] = useState(props.values);
	const [openItem, setOpenItem] = useState(null);

	const { partTypes } = useContext(CmsTypesContext);
	const listPartType = find(partTypes, partType => partType.key === props.listPartType);

	const changeOpenItemValues = values => {
		if (openItem !== null) {
			const newOpenItem = { ...openItem };
			newOpenItem.values = values;
			setOpenItem(newOpenItem);
		}
	};

	const openEditDialog = (index, label, values) => {
		setOpenItem({
			creating: false,
			index,
			label,
			values: cloneDeep(values),
		});
	};
	const openCreateDialog = () => {
		setOpenItem({
			creating: true,
			index: -1,
			label: uctrans("general.add_:item", { item: listPartType.label }),
			values: {},
		});
	};

	const handleDialogSave = () => {
		if (openItem !== null) {
			const newPartlistData = Array.isArray(partlistData) ? [...partlistData] : [];
			if (openItem.creating) {
				newPartlistData.push(openItem.values);
			} else {
				newPartlistData[openItem.index] = openItem.values;
			}
			setPartlistData(newPartlistData);
			props.onChange(newPartlistData);
			setOpenItem(null);
		}
	};
	const handleDialogCancel = () => {
		setOpenItem(null);
	};

	const deleteListItem = index => {
		const newPartlistData = Array.isArray(partlistData) ? [...partlistData] : [];
		newPartlistData.splice(index, 1);
		setPartlistData(newPartlistData);
		props.onChange(newPartlistData);
	};

	const moveListItem = dragEvent => {
		if (dragEvent.destination) {
			const sourceIndex = dragEvent.source.index;
			const targetIndex = dragEvent.destination.index;

			const newPartlistData = Array.isArray(partlistData) ? cloneDeep(partlistData) : [];

			if (`${sourceIndex}` !== `${targetIndex}`) {
				const item = newPartlistData[sourceIndex];
				newPartlistData.splice(sourceIndex, 1);
				newPartlistData.splice(targetIndex, 0, item);

				setPartlistData(newPartlistData);
				props.onChange(newPartlistData);
			}
		}
	};

	if (listPartType) {
		return (
			<div className="w-fill mt-5 mb-12">
				{props.label && <h5>{props.label}</h5>}
				<div>
					<DragDropContext onDragEnd={dragEvent => moveListItem(dragEvent)}>
						<Droppable droppableId="droppable">
							{provided => (
								<div ref={provided.innerRef} {...provided.droppableProps}>
									{(() =>
										map(partlistData, (values, index) => (
											<Draggable key={index} index={index} draggableId={index.toString()}>
												{provided => (
													<div
														{...provided.dragHandleProps}
														ref={provided.innerRef}
														{...provided.draggableProps}
														className="bg-primary-lightest items-center px-3 py-3 my-2 flex justify-between">
														<div className="flex w-full items-center">
															<EvaIcon
																className="flex items-center"
																type="move-outline"
																width="20"
																height="20"
																fill="#009286"
															/>
															<span className="ml-3 w-full">
																<PartListLabel values={values} number={index + 1} parttype={listPartType.key} />
															</span>
														</div>
														<div className="flex items-center">
															<span
																className="cursor-pointer"
																onClick={() => {
																	openEditDialog(
																		index,
																		uctrans("general.edit_:item", { item: listPartType.label }),
																		values,
																	);
																}}>
																<EvaIcon
																	className="flex items-center"
																	type="edit-outline"
																	width="20"
																	height="20"
																	fill="#009286"
																/>
															</span>
															<span
																className="cursor-pointer ml-3"
																onClick={() => {
																	deleteListItem(index);
																}}>
																<EvaIcon
																	className="flex items-center"
																	type="trash-2-outline"
																	width="20"
																	height="20"
																	fill="#009286"
																/>
															</span>
														</div>
													</div>
												)}
											</Draggable>
										)))()}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
				<button type="button" className="button button-secondary" onClick={openCreateDialog}>
					{uctrans("general.add_:item", { item: listPartType.label })}
				</button>
				<Dialog
					isOpen={openItem !== null}
					onClose={handleDialogCancel}
					shouldCloseOnOverlayClick={false}
					width={850}
					title={openItem && openItem.label ? openItem.label : ""}>
					{openItem !== null && (
						<>
							<PartContent
								parttype={listPartType.key}
								values={openItem.values}
								onChange={values => {
									changeOpenItemValues(values);
								}}
							/>
							<div className="mt-6">
								<button type="button" className="button button-primary button-dialog" onClick={handleDialogSave}>
									{uctrans("general.ok")}
								</button>
								<a onClick={handleDialogCancel}>{uctrans("general.cancel")}</a>
							</div>
						</>
					)}
				</Dialog>
			</div>
		);
	}
}

PartList.propTypes = {
	label: PropTypes.string,
	listPartType: PropTypes.string.isRequired,
	values: PropTypes.array,
	onChange: PropTypes.func.isRequired,
};
