import PropTypes from "prop-types";
import { route, can } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import TabLinks from "../general/tabs/TabLinks";

export default function Tabs(props) {
	const tabs = [];
	if (can("relationmanagement-dashboard")) {
		tabs.push({
			key: "relationmanagement-dashboard",
			label: uctrans("dashboard.relationmanagement"),
			route: route("crm.relationmanagement-dashboard.index"),
		});
	}
	if (can("accountsupport-dashboard")) {
		tabs.push({
			key: "accountsupport-dashboard",
			label: uctrans("dashboard.accountsupport"),
			route: route("crm.dashboard.accountsupport"),
		});
	}
	return <TabLinks tabs={tabs} activeTab={props.selectedTab} />;
}
Tabs.propTypes = {
	selectedTab: PropTypes.string.isRequired,
};
