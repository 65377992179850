import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import useSelectHandler from "../../../../../js/react/hooks/useSelectHandler";
import { route } from "../../../../../js/helpers";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import Select from "../../../../../js/react/components/general/Select";
import Translate from "../../../../../js/react/components/general/Translate";
import axios from "axios";

export default function RelationmanagerTargetForm({ relationmanagerTarget, targetYear, onSuccess, onCancel }) {
	const [formData, setFormData] = useState({ ...relationmanagerTarget });

	const [relationmanagers, setRelationmanagers] = useState([]);
	const [initialized, setInitialized] = useState(false);

	const inputHandler = useInputHandler(setFormData);
	const selectHandler = useSelectHandler(setFormData);

	const handleSuccess = () => {
		onSuccess();
	};

	const handleCancel = () => {
		onCancel();
	};

	const fetchRelationmanagers = useCallback(async () => {
		const response = await axios.get(
			route("crm.targets.relationmanager-targets.relationmanager-options", targetYear.id),
		);
		setRelationmanagers(response.data);
	}, [targetYear.id]);

	useEffect(() => {
		if (!initialized) {
			fetchRelationmanagers();
			setInitialized(true);
		}
	}, [fetchRelationmanagers, formData.id, initialized]);

	return (
		<AjaxForm
			method={formData.id === -1 ? "POST" : "PUT"}
			submitUrl={
				formData.id === -1
					? route("crm.targets.relationmanager-targets.store", { target_year: targetYear.id })
					: route("crm.targets.relationmanager-targets.update", {
							target_year: targetYear.id,
							relationmanager_target: formData.id,
					  })
			}
			loaderText={uctrans("general.:item_is_being_saved", {}, { item: "targets.relationmanager_target.singular" })}
			successText={uctrans("general.saved_:item", {}, { item: "targets.relationmanager_target.singular" })}
			onSuccess={handleSuccess}
			data={{ ...formData }}>
			<div className="md:flex">
				<div className="flex-1 w-2/3 mr-12">
					<div className="form-container">
						{formData.id === -1 && (
							<div className="form-full">
								<div className="form-1-2">
									<label htmlFor="relationmanager_id">
										<Translate content="targets.relationmanager_target.fields.relationmanager" />
									</label>
									<Select
										placeholder={uctrans(
											"general.select_:item",
											{},
											{ item: "targets.relationmanager_target.fields.relationmanager" },
										)}
										value={relationmanagers.filter(
											({ value }) => Number(value) === Number(formData.relationmanager_id),
										)}
										options={relationmanagers}
										name="relationmanager_id"
										onChange={selectHandler}
									/>
								</div>
							</div>
						)}
						<div className="form-full">
							<div className="form-1-2">
								<LabeledTextInput
									type="number"
									name="ambition_sum"
									value={formData.ambition_sum}
									onChange={inputHandler}
									label={`${uctrans("targets.relationmanager_target.fields.ambition")} (320)`}
									translateLabel={false}
									required
								/>
							</div>
							<div className="form-1-2">
								<LabeledTextInput
									type="number"
									name="sum"
									value={formData.sum}
									onChange={inputHandler}
									label={`${uctrans("targets.relationmanager_target.fields.sum")} (320)`}
									translateLabel={false}
									required
								/>
							</div>
						</div>
						<div className="form-full">
							<div className="form-1-2">
								<LabeledTextInput
									type="number"
									name="ambition_count"
									value={formData.ambition_count}
									onChange={inputHandler}
									label="targets.relationmanager_target.fields.ambition_count"
									required
								/>
							</div>
							<div className="form-1-2">
								<LabeledTextInput
									type="number"
									name="count"
									value={formData.count}
									onChange={inputHandler}
									label="targets.relationmanager_target.fields.count"
									required
								/>
							</div>
						</div>
						<div className="form-full">
							<div className="form-1-2">
								<LabeledTextInput
									type="number"
									name="contactmoments"
									value={formData.contactmoments}
									onChange={inputHandler}
									label="targets.relationmanager_target.fields.contactmoments"
									required
								/>
							</div>
						</div>
						<div className="form-full">
							<div className="w-full md:w-1/2 lg:w-2/3 column">
								<SubmitBar
									item="targets.relationmanager_target.singular"
									addAnotherAvailable={false}
									onCancel={handleCancel}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AjaxForm>
	);
}
RelationmanagerTargetForm.propTypes = {
	relationmanagerTarget: PropTypes.object.isRequired,
	targetYear: PropTypes.object.isRequired,
	onSuccess: PropTypes.func,
	onCancel: PropTypes.func,
};
