import KvkTextInput from "../../../../../js/react/components/general/form/KvkTextInput";
import IconButton from "../../../../../js/react/components/general/IconButton";
import { notify } from "../../../../../js/react/components/general/notifications";
import axios from "axios";
import { useState, useCallback } from "react";
import { route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import ReactJson from "react-json-view";
import Spinner from "../../../../../js/react/components/general/Spinner";

export default function KvkSearchForm() {
	const [kvkNumber, setKvkNumber] = useState("");
	const [kvkData, setKvkData] = useState(null);
	const [loading, setLoading] = useState(false);

	const searchKvkNumber = useCallback(async () => {
		if (!kvkNumber) {
			return;
		}
		try {
			setLoading(true);
			const nr = `${kvkNumber}`;
			const response = await axios.get(route(`crm.kvk.search`), {
				params: { kvk_number: nr },
			});

			if (response.data && response.data.kvk_data && Object.keys(response.data.kvk_data).length) {
				setKvkData(response.data.kvk_data);
				setLoading(false);
			} else {
				notify("error", uctrans("intermediaries.messages.kvk_:nr_not_found", { nr }), null);
				setLoading(false);
				setKvkData(null);
			}
		} catch (error) {
			notify("error", error, null);
			setLoading(false);
		}
	}, [kvkNumber]);

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans("general.search_:items", {}, { items: "intermediaries.fields.kvk_number" })}
				</h1>
			</div>
			<div className="form-container">
				<div className="form-1-3">
					<KvkTextInput
						label="intermediaries.fields.kvk_number"
						value={kvkNumber}
						name="kvk_number"
						disabled={loading}
						onChange={e => setKvkNumber(e.target.value)}
					/>
				</div>
			</div>
			<div className="form-container">
				<IconButton
					onClick={searchKvkNumber}
					content={uctrans("general.search_:item", {}, { item: "intermediaries.fields.kvk_number" })}
					primary
					disabled={kvkNumber.length !== 8 || loading}
					icon="search-outline"
				/>
			</div>

			{kvkData && !loading ? (
				<div className="form-full">
					<br />
					<ReactJson
						src={kvkData}
						name={false}
						displayObjectSize={false}
						displayDataTypes={false}
						enableClipboard={false}
					/>
				</div>
			) : loading ? (
				<div className="form-full">
					<Spinner width={75} />
				</div>
			) : null}
		</>
	);
}
