import find from "lodash/find";
import { toLower } from "lodash/string";
import PropTypes from "prop-types";
import { useEffect, useState, useContext } from "react";
import { CmsTypesContext } from "../../cms-types-context";
import ClearablePart from "../parts/ClearablePart";
import PartContent from "../parts/PartContent";
import Anchor from "./Anchor";
import AttributeList from "./AttributeList";
import Boolean from "./Boolean";
import ContentList from "./ContentList";
import DateTime from "./DateTime";
import Document from "./Document";
import FixedInterestPeriod from "./FixedInterestPeriod";
import Html from "./Html";
import Icon from "./Icon";
import InterestDiscount from "./InterestDiscount";
import InterestRateClass from "./InterestRateClass";
import InterestType from "./InterestType";
import Number from "./Number";
import ProfileFilter from "./ProfileFilter";
import Url from "./Url";
import Part from "../parts/Part";
import PartList from "../part-lists/PartList";
import Page from "./Page";
import Text from "./Text";
import Select from "./Select";
import Image from "./Image";
import Video from "./Video";
import Animation from "./Animation";

export default function Attribute(props) {
	const additionalClassName = toLower(window.data.page_data?.pagevalues.title);
	const [attributevaluesData, setAttributeValuesData] = useState(props.values);
	useEffect(() => {
		setAttributeValuesData(props.values);
	}, [props.values]);

	const changeAttributevalues = values => {
		setAttributeValuesData(values);
		props.onChange(values);
	};

	const { attributeTypes, partTypes } = useContext(CmsTypesContext);
	const attributeType = find(attributeTypes, attributeType => attributeType.key === props.attribute.type);

	if (attributeType) {
		switch (attributeType.key) {
			case "anchor":
				return <Anchor label={props.attribute.label} value={attributevaluesData} onChange={changeAttributevalues} />;
			case "part":
				switch (props.attribute.parttype) {
					case "link":
					case "link_list":
					case "linktarget":
						return (
							<ClearablePart
								label={props.attribute.label}
								parttype={props.attribute.parttype}
								values={attributevaluesData}
								onChange={changeAttributevalues}
							/>
						);
					case "image":
					case "video":
						return (
							<PartContent
								label={props.attribute.label}
								parttype={props.attribute.parttype}
								values={attributevaluesData}
								onChange={changeAttributevalues}
							/>
						);
					default:
						return (
							<Part
								label={props.attribute.label}
								parttype={props.attribute.parttype}
								partTypes={partTypes}
								values={attributevaluesData}
								onChange={changeAttributevalues}
							/>
						);
				}
			case "part_list":
				return (
					<PartList
						label={props.attribute.label}
						listPartType={props.attribute.parttype}
						values={attributevaluesData}
						onChange={changeAttributevalues}
					/>
				);
			case "attribute_list":
				return (
					<AttributeList
						label={props.attribute.label}
						listAttributeType={props.attribute.attributetype}
						values={attributevaluesData}
						onChange={changeAttributevalues}
					/>
				);
			case "content_list":
				return (
					<ContentList
						label={props.attribute.label}
						allowedAttributetypes={props.attribute.allowed_attributetypes}
						allowedParttypes={props.attribute.allowed_parttypes}
						values={attributevaluesData}
						onChange={changeAttributevalues}
					/>
				);
			case "text":
			case "block_title":
				return (
					<Text
						label={props.attribute.label}
						additionalClass={`${additionalClassName}text`}
						value={attributevaluesData}
						onChange={changeAttributevalues}
					/>
				);
			case "url":
				return <Url label={props.attribute.label} value={attributevaluesData} onChange={changeAttributevalues} />;
			case "number":
				return <Number label={props.attribute.label} value={attributevaluesData} onChange={changeAttributevalues} />;
			case "image":
				return <Image label={props.attribute.label} value={attributevaluesData} onChange={changeAttributevalues} />;
			case "video":
				return <Video label={props.attribute.label} value={attributevaluesData} onChange={changeAttributevalues} />;
			case "animation":
				return <Animation label={props.attribute.label} value={attributevaluesData} onChange={changeAttributevalues} />;
			case "document":
				return <Document label={props.attribute.label} value={attributevaluesData} onChange={changeAttributevalues} />;
			case "page":
				return <Page label={props.attribute.label} value={attributevaluesData} onChange={changeAttributevalues} />;
			case "select":
				return (
					<Select
						label={props.attribute.label}
						options={Object.prototype.hasOwnProperty.call(props.attribute, "options") ? props.attribute.options : []}
						value={attributevaluesData}
						onChange={changeAttributevalues}
					/>
				);
			case "html_mail":
				return (
					<Html
						type="mail"
						label={props.attribute.label}
						value={attributevaluesData}
						onChange={changeAttributevalues}
					/>
				);
			case "html":
				return <Html label={props.attribute.label} value={attributevaluesData} onChange={changeAttributevalues} />;
			case "boolean":
				return <Boolean label={props.attribute.label} value={attributevaluesData} onChange={changeAttributevalues} />;
			case "date_time":
				return <DateTime label={props.attribute.label} value={attributevaluesData} onChange={changeAttributevalues} />;
			case "icon":
				return <Icon label={props.attribute.label} value={attributevaluesData} onChange={changeAttributevalues} />;
			case "interest_type":
				return (
					<InterestType label={props.attribute.label} value={attributevaluesData} onChange={changeAttributevalues} />
				);
			case "interest_rate_class":
				return (
					<InterestRateClass
						label={props.attribute.label}
						value={attributevaluesData}
						onChange={changeAttributevalues}
					/>
				);
			case "fixed_interest_period":
				return (
					<FixedInterestPeriod
						label={props.attribute.label}
						value={attributevaluesData}
						onChange={changeAttributevalues}
					/>
				);
			case "interest_discount":
				return (
					<InterestDiscount
						label={props.attribute.label}
						value={attributevaluesData}
						onChange={changeAttributevalues}
					/>
				);
			case "filter_profile":
				return (
					<ProfileFilter label={props.attribute.label} value={attributevaluesData} onChange={changeAttributevalues} />
				);
			default:
				console.error(`unknown type:  ${attributeType.key}`);
				return <div />;
		}
	}
}

Attribute.propTypes = {
	attribute: PropTypes.shape({
		type: PropTypes.string,
		label: PropTypes.string,
		allowed_attributetypes: PropTypes.array,
		allowed_parttypes: PropTypes.array,
		parttype: PropTypes.string,
		attributetype: PropTypes.string,
		options: PropTypes.array,
	}),
	values: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number, PropTypes.string, PropTypes.bool]),
	onChange: PropTypes.func.isRequired,
};
