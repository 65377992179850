import { notify } from "../../../../../js/react/components/general/notifications";
import axios from "axios";
import { useState, useCallback } from "react";
import { route } from "../../../../../js/helpers";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";

export default function LoanSearchForm() {
	const [loanNumber, setloanNumber] = useState("");

	const searchLoan = useCallback(async () => {
		if (!loanNumber) {
			return;
		}
		try {
			const nr = `${loanNumber}`;
			const response = await axios.get(route(`crm.loans.search`), { params: { loanNumber: nr } });
			if (response.data.loanFound) {
				Nav.go(route("crm.loans.show", { loan: response.data.loanId }));
			} else {
				notify("error", uctrans("loans.messages.loan_:nr_not_found", { nr }), null);
			}
		} catch (error) {
			console.error(error);
			notify("error", error, null);
		}
	}, [loanNumber]);

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("general.search_:items", {}, { items: "loans.plural" })}</h1>
			</div>
			<div className="form-container">
				<div className="form-1-3">
					<LabeledTextInput
						name="loan_number"
						type="number"
						value={loanNumber}
						onChange={event => {
							const { target } = event;
							setloanNumber(target.value);
						}}
						label="loans.fields.number"
					/>
				</div>
			</div>
			<div className="form-container">
				<button className="button button-primary" onClick={searchLoan} type="button">
					<EvaIcon type="search-outline" height="18" width="18" />
					{uctrans("general.search_:item", {}, { item: "loans.singular" })}
				</button>
			</div>
		</>
	);
}
