import PropTypes from "prop-types";
import { uctrans } from "../../../../../../js/lib/Translator";
import HighChart from "../../report/graph/HighChart";

export default function EngagementScoreLineChart(props) {
	const parseData = data =>
		data.map(data => [Date.parse(data.date), props.showAbsolute ? data.score_absolute : data.score_relative]);

	const series = [];
	if (props.walletData) {
		series.push({
			name: props.wallet.label,
			data: parseData(props.walletData),
			color: "#227370",
		});
	}

	if (props.wallet.type !== "total" && props.totalData) {
		series.push({
			name: uctrans("dashboard.nationwide"),
			data: parseData(props.totalData),
			color: "#F6BC34",
		});
	}

	return (
		<HighChart
			chartConfig={{
				chart: {
					height: "250px",
					backgroundColor: null,
				},
				title: {
					text: null,
				},
				legend: {
					enabled: true,
					align: "center",
					verticalAlign: "top",
					floating: true,
					itemStyle: {
						fontSize: "10px",
						fontWeight: "normal",
					},
				},
				yAxis: {
					min: 0,
					title: {
						text: null,
					},
				},
				xAxis: {
					min: Date.parse(props.startDate),
					max: Date.parse(props.endDate),
					type: "datetime",
					pointInterval: 30 * 24 * 3600 * 1000 * 7, // one day,
					dateTimeLabelFormats: {
						month: "%b",
					},
				},
				tooltip: {
					xDateFormat: "%A, %d %b '%y",
				},
				series,
			}}
		/>
	);
}

EngagementScoreLineChart.propTypes = {
	startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
	endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
	walletData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	totalData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	wallet: PropTypes.object,
	showAbsolute: PropTypes.bool,
};
