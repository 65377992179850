import PropTypes from "prop-types";

export default function Anchor(props) {
	const validateAnchor = text => {
		const newAnchor = text.replace(/[^0-9a-z]/g, "");
		props.onChange(newAnchor);
	};

	return (
		<>
			{props.label && <label>{props.label}</label>}
			<input
				type="text"
				value={props.value == null ? "" : props.value}
				onChange={e => validateAnchor(e.target.value)}
			/>
		</>
	);
}

Anchor.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};
