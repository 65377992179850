import axios from "axios";
import { can, route, yesNoOptions } from "../../../../../js/helpers";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import IconButton from "../../../../../js/react/components/general/IconButton";
import { error } from "../../../../../js/react/components/general/notifications";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import DeleteColumnContent from "../../../../../js/react/components/general/overview/DeleteColumnContent";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import Tippy from "@tippyjs/react";

export const companyStatus = {
	PROSPECT: 1,
	ACTIVE: 2,
	NOT_ACTIVE: 3,
	PROSPECT_REJECTED: 4,
};

export default function StatutoryCompanyOverview() {
	const overviewData = { ...window.data.overview };

	const askDeleteIfNoPendingAgendaItems = async (statutoryCompany, overview) => {
		try {
			await axios.get(route("crm.statutory-companies.check-agenda-items", { id: statutoryCompany.id }));
			error(uctrans("companies.errors.would_leave_orphaned_agenda_items"));
		} catch (error) {
			if (error.response && error.response.status === 404) {
				overview.askDelete(statutoryCompany);

				return;
			}

			throw error;
		}
	};

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("statutory_companies.plural") }]} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("statutory_companies.plural")}</h1>
				{can("statutory_company", "create") && (
					<div>
						<IconButton
							href={route("crm.statutory-companies.create")}
							content={uctrans("general.add_:item", {}, { item: "statutory_companies.singular" })}
							primary
						/>
					</div>
				)}
			</div>
			<AbnOverview
				initialData={overviewData}
				indexUrl={route("crm.statutory-companies.data")}
				deleteRouteName="crm.statutory-companies.delete"
				modelTranslations={{
					plural: trans("statutory_companies.plural"),
					singular: trans("statutory_companies.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
						<SelectFilter
							className="form-1-4"
							name="has_active_companies"
							label={uctrans("statutory_companies.has_active_companies")}
							options={yesNoOptions()}
							collapsible
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={4}
							sortable="name"
							renderHeaderContent={() => uctrans("statutory_companies.fields.name")}
							renderCellContent={({ name, id }) =>
								can("statutory_company", "update") ? (
									<a href={route("crm.statutory-companies.edit", id)} className="row-title link">
										{name ?? <i className="italic">{uctrans("general.unknown")}</i>}
									</a>
								) : (
									name ?? <i className="italic">{uctrans("general.unknown")}</i>
								)
							}
						/>
						<DataColumn
							width={2}
							sortable="kvk_number"
							renderHeaderContent={() => uctrans("statutory_companies.fields.kvk_number")}
							renderCellContent={({ kvk_number }) => kvk_number}
						/>
						<DataColumn
							width={2}
							sortable="afm_number"
							renderHeaderContent={() => uctrans("statutory_companies.fields.afm_number")}
							renderCellContent={({ afm_number }) => afm_number}
						/>
						<DataColumn
							width={2}
							sortable="kifid_number"
							renderHeaderContent={() => uctrans("statutory_companies.fields.kifid_number")}
							renderCellContent={({ kifid_number }) => kifid_number}
						/>
						<DataColumn
							width={4}
							sortable="companies"
							renderHeaderContent={() => uctrans("statutory_companies.establishments.plural")}
							renderCellContent={({ companies, main_company_id }) => {
								if (companies.count === 0) {
									return (
										<i className="italic">
											{uctrans(
												"general.:count_:item",
												{ count: 0 },
												{ item: "statutory_companies.establishments.plural" },
											)}
										</i>
									);
								}
								if (companies.count === 1) {
									const [company] = companies.list;
									return can("intermediary", "update_administration") &&
										can("organisation", "update_administration") ? (
										<a
											href={route(
												company.type === 1
													? "crm.intermediaries.edit-administrative"
													: "crm.organisations.edit-administrative",
												company.id,
											)}
											className={`row-title link ${company.status !== companyStatus.ACTIVE ? "italic" : ""}`}>
											{company.overview_label}
										</a>
									) : (
										company.overview_label
									);
								}
								if (companies.count > 1) {
									return (
										<Tippy
											theme="light"
											arrow
											interactive
											maxWidth={600}
											content={
												can("intermediary", "update_administration") && can("organisation", "update_administration") ? (
													<ul>
														{companies.list.map(company => (
															<li key={company.id}>
																<a
																	href={route(
																		company.type === 1
																			? "crm.intermediaries.edit-administrative"
																			: "crm.organisations.edit-administrative",
																		company.id,
																	)}
																	className={`row-title link ${company.id === main_company_id ? "font-bold" : ""} ${
																		company.status !== companyStatus.ACTIVE ? "italic" : ""
																	}`}>
																	{company.overview_label}
																</a>
															</li>
														))}
													</ul>
												) : (
													<ul>
														{companies.list.map(company => (
															<li
																className={`row-title link ${company.id === main_company_id ? "font-bold" : ""} ${
																	company.status !== companyStatus.ACTIVE ? "italic" : ""
																}`}
																key={company.id}>
																{company.overview_label}
															</li>
														))}
													</ul>
												)
											}>
											<span>
												{uctrans(
													"general.:count_:item",
													{ count: companies.count },
													{ item: "statutory_companies.establishments.plural" },
												)}
											</span>
										</Tippy>
									);
								}
							}}
						/>
						{can("statutory_company", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(statutoryCompany, overview) => (
									<DeleteColumnContent onClick={() => askDeleteIfNoPendingAgendaItems(statutoryCompany, overview)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
