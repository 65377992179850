import classNames from "classnames";
import PropTypes from "prop-types";
import map from "lodash/map";
import { uctrans } from "../../../../../../../js/lib/Translator";
import FormattedNumberText from "../../FormattedNumberText";

export default function LoanWeekResults({ data, title, staticData }) {
	const getClassForTd = (tab, bold) =>
		classNames(tab, {
			"font-bold": bold,
		});

	return (
		<table className="weekomzet_rapportage_table rapportage_table reporttable">
			<thead>
				<tr>
					<th colSpan="11">{title ? title : uctrans("report.singular")}</th>
				</tr>
				<tr className="head1">
					<td colSpan="1" />
					{map(staticData.loan_statuses, (status, key) => (
						<td colSpan="2" className="status_titel" key={key}>
							<span>
								<strong>{status.key}</strong> - {status.label}
							</span>
							<br />
							<small>(€ x 1.000)</small>
						</td>
					))}
				</tr>
				<tr className="head2">
					<td>Week</td>
					<td className="omzet">Omzet</td>
					<td className="aantal">Aantal</td>
					<td className="omzet">Omzet</td>
					<td className="aantal">Aantal</td>
					<td className="omzet">Omzet</td>
					<td className="aantal">Aantal</td>
					<td className="omzet">Omzet</td>
					<td className="aantal">Aantal</td>
					<td className="omzet">Omzet</td>
					<td>Aantal</td>
				</tr>
			</thead>
			<tbody>
				{map(data, (weekData, weekKey) => (
					<tr
						className={getClassForTd(`${weekKey}` === `total` ? "totaal" : null, `${weekKey}` === `Totaal`)}
						key={weekKey}>
						<td className={getClassForTd(`${weekKey}` === `Totaal` ? "totaal" : null, `${weekKey}` === `Totaal`)}>
							{weekKey}
						</td>
						{map(staticData.loan_statuses, status => (
							<>
								<td className={getClassForTd("omzet", `${weekKey}` === `total`)}>
									<FormattedNumberText
										value={weekData[status.key] && weekData[status.key].sum ? weekData[status.key].sum / 1000 : 0}
										prefix="€ "
									/>
								</td>
								<td className={getClassForTd("aantal", `${weekKey}` === `total`)}>
									<FormattedNumberText
										value={weekData[status.key] && weekData[status.key].count ? weekData[status.key].count : 0}
									/>
								</td>
							</>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
}

LoanWeekResults.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	staticData: PropTypes.object,
	title: PropTypes.string,
};
