import PropTypes from "prop-types";
import classNames from "classnames";
import { useMemo } from "react";
import { uctrans } from "../../../../../js/lib/Translator";

export const companyStatus = {
	RED: 0,
	ORANGE: 1,
	GREEN: 2,
	YELLOW: 3,
};

export default function CompanyStatusLabel({ link, colorStatus, label, showExplanationLabels }) {
	const extraText = useMemo(() => {
		switch (colorStatus) {
			case companyStatus.RED:
				return `(${uctrans("statutory_companies.establishments.deactivated")})`;
			case companyStatus.ORANGE:
				return `(${uctrans("statutory_companies.establishments.cooperation_termination")})`;
			case companyStatus.YELLOW:
				return `(${uctrans("statutory_companies.establishments.prospect")})`;
			default:
				return "";
		}
	}, [colorStatus]);

	return (
		<a
			href={link}
			className={classNames("row-title", {
				link: colorStatus === companyStatus.GREEN || colorStatus === companyStatus.YELLOW,
				"link-yellow": colorStatus === companyStatus.ORANGE,
				"link-red": colorStatus === companyStatus.RED,
			})}>
			{`${label} ${showExplanationLabels ? extraText : ""}`}
		</a>
	);
}

CompanyStatusLabel.propTypes = {
	colorStatus: PropTypes.number.isRequired,
	link: PropTypes.string,
	label: PropTypes.string,
	showExplanationLabels: PropTypes.bool,
};

CompanyStatusLabel.defaultProps = {
	showExplanationLabels: false,
	colorStatus: 2,
};
