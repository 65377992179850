import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import isObject from "lodash/isObject";
import map from "lodash/map";
import size from "lodash/size";
import { useState, useRef } from "react";
import { route } from "../../../../../../js/helpers";
import { format, parse } from "../../../../../../js/lib/Date";
import Nav from "../../../../../../js/lib/Nav";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import { trans, uctrans } from "../../../../../../js/lib/Translator";
import DatePicker from "../../../../../../js/react/components/general/DatePicker";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import Select from "../../../../../../js/react/components/general/Select";
import Translate from "../../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";
import useSelectHandler from "../../../../../../js/react/hooks/useSelectHandler";

export default function AfmReport() {
	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);
	const [title, setTitle] = useState(null);

	const [formInfo] = useState(window.data.formInfo ? cloneDeep(window.data.formInfo) : []);
	const [overviewUpdatedAt, setOverviewUpdatedAt] = useState(new Date().getTime());
	const formRef = useRef(null);

	const selectHandler = useSelectHandler(setFormValues);
	const genericHandler = useGenericHandler(setFormValues);

	const generateCsv = async () => {
		const result = await axios.post(route("crm.report.afm.generate-csv"), {
			...formRef.current.props.data,
			csv: 1,
		});

		if (result && result.data.success) {
			Nav.go(
				route("crm.report.afm.download-csv", {
					download_key: result.data.download_key,
				}),
			);
		}
	};

	const generatePdf = async () => {
		const result = await axios.post(route("crm.report.afm.generate-pdf"), {
			...formRef.current.props.data,
			pdf: 1,
		});

		if (result && result.data.success) {
			Nav.go(
				route("crm.report.afm.download-pdf", {
					download_key: result.data.download_key,
				}),
			);
		}
	};

	const getIllegalitiesAsString = illegalities => {
		let returnString = "";
		let first = true;
		map(illegalities, (product, service) => {
			returnString += `${!first ? "<br>" : ""}<b>${service}:</b> `;
			first = false;
			map(product, (productvalues, index) => {
				returnString += `${productvalues.product_name}${index !== size(product - 1) ? ", " : ""}`;
			});
		});
		return returnString;
	};

	return (
		<AjaxForm
			method="POST"
			submitUrl={route("crm.report.account-support.data")}
			data={{ ...formValues }}
			ref={formRef}
			useFlashMessage>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans("report.report")} - {uctrans("report.report_types.afm")}
				</h1>
			</div>
			<div className="w-full flex">
				<div className="w-1/4 pr-2">
					<label htmlFor="from_date">
						<Translate content="report.account_support.from" />
					</label>
					<DatePicker
						showClearButton
						initialValue={formValues.from_date ? parse(formValues.from_date) : null}
						onChange={date => genericHandler(date ? format(date) : null, "from_date")}
					/>
				</div>
				<div className="w-1/4 pr-2">
					<label htmlFor="to_date">
						<Translate content="report.account_support.until" />
					</label>
					<DatePicker
						showClearButton
						initialValue={formValues.to_date ? parse(formValues.to_date) : null}
						onChange={date => genericHandler(date ? format(date) : null, "to_date")}
					/>
				</div>
				<div className="w-1/4 pr-2">
					<label htmlFor="classification">
						<Translate content="report.classification" />
					</label>
					<Select
						placeholder={uctrans("general.select_:item", {}, { item: "report.classification" })}
						value={formInfo.classificationOptions.filter(({ value }) => `${value}` === `${formValues.classification}`)}
						options={formInfo.classificationOptions}
						name="classification"
						onChange={selectHandler}
					/>
				</div>
			</div>
			<div className="flex w-full justify-start">
				<button
					type="submit"
					onClick={e => {
						setOverviewUpdatedAt(new Date().getTime());
						e.preventDefault();
					}}
					className="button button-primary leading-normal">
					{uctrans("report.show_report")}
				</button>
				<IconButton
					className="ml-5"
					icon="file-text-outline"
					onClick={() => generatePdf()}
					content={uctrans("report.generate_pdf")}
					primary
				/>
				<IconButton
					className="ml-5"
					icon="file-text-outline"
					onClick={() => generateCsv()}
					content={uctrans("report.generate_csv")}
					primary
				/>
			</div>

			<AbnOverview
				indexUrl={route("crm.report.afm.data")}
				dataUpdatedAt={overviewUpdatedAt}
				extraQuerystringParameters={formValues}
				reloadedData={data => setTitle(data.title)}
				modelTranslations={{
					plural: trans("afm_permits.plural"),
					singular: trans("afm_permits.singular"),
				}}
				renderBody={() => (
					<>
						<div className="w-full mt-2 text-white flex bg-primary p-2">
							<span className="ml-1 font-bold">{title ? title : ""}</span>
						</div>
						<DataTable>
							<DataColumn
								width={3}
								renderHeaderContent={() => uctrans("report.afm.name_check")}
								renderCellContent={() => <span className="text-sm">ABN AMRO BANK N.V.</span>}
							/>
							<DataColumn
								width={2}
								renderHeaderContent={() => uctrans("report.afm.check_date")}
								sortable="check_date"
								renderCellContent={afmCheck => (
									<span className="text-sm">{afmCheck.afm_check_date ? afmCheck.afm_check_date : "-"}</span>
								)}
							/>
							<DataColumn
								width={2}
								renderHeaderContent={() => uctrans("report.afm.permit_number")}
								renderCellContent={afmCheck => (
									<span className="text-sm">{afmCheck.afm_number ? afmCheck.afm_number : "-"}</span>
								)}
							/>
							<DataColumn
								width={2}
								renderHeaderContent={() => uctrans("report.afm.kvk_number")}
								renderCellContent={afmCheck => (
									<span className="text-sm">{afmCheck.kvk_number ? afmCheck.kvk_number : "-"}</span>
								)}
							/>
							<DataColumn
								width={2}
								renderHeaderContent={() => uctrans("report.afm.tpnr")}
								sortable="company_number"
								renderCellContent={afmCheck => (
									<span className="text-sm">{afmCheck.company_number ? afmCheck.company_number : "-"}</span>
								)}
							/>
							<DataColumn
								width={4}
								renderHeaderContent={() => uctrans("report.afm.name")}
								sortable="company_name"
								renderCellContent={afmCheck => (
									<span className="text-sm">{afmCheck.company_name ? afmCheck.company_name : "-"}</span>
								)}
							/>
							<DataColumn
								width={4}
								renderHeaderContent={() => uctrans("report.afm.illegality")}
								renderCellContent={afmCheck =>
									afmCheck.illegalities ? (
										isObject(afmCheck.illegalities) ? (
											<span
												className="text-sm"
												dangerouslySetInnerHTML={{ __html: getIllegalitiesAsString(afmCheck.illegalities) }}
											/>
										) : (
											<span className="text-sm">{afmCheck.illegalities}</span>
										)
									) : (
										<span className="text-sm">-</span>
									)
								}
							/>
							<DataColumn
								width={3}
								renderHeaderContent={() => uctrans("report.afm.taken_actions")}
								renderCellContent={afmCheck => (
									<span className="text-sm">{afmCheck.action ? afmCheck.action : "-"}</span>
								)}
							/>
						</DataTable>
					</>
				)}
			/>
		</AjaxForm>
	);
}
