import { useState } from "react";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import Translate from "../../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";
import useInputHandler from "../../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import Nav from "../../../../../../js/lib/Nav";
import { route } from "../../../../../../js/helpers";
import TextEditor from "../../forms/textEditor/TextEditor";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";

export default function DefinitionForm() {
	const [formData, setFormData] = useState({ ...window.data.definition_values });
	const creating = window.data.definition_values.id === -1;
	const inputHandler = useInputHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);

	const onSuccess = () => {
		Nav.go(route("crm.cms.definitions.index"));
	};

	return (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{ label: uctrans("general.cms") },
					{ label: uctrans("cms.definitions.plural"), route: route("crm.cms.definitions.index") },
					{
						label: creating ? uctrans("general.add_:item", {}, { item: "cms.menuitems.singular" }) : formData.name,
					},
				]}
			/>
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("cms.definitions.singular")}</h1>

				<a className="link" href={route("crm.cms.definitions.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "cms.definitions.plural" })}
				</a>
			</div>
			<AjaxForm
				method={creating ? "POST" : "PUT"}
				submitUrl={creating ? route("crm.cms.definitions.store") : route("crm.cms.definitions.update", formData.id)}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "cms.definitions.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "cms.definitions.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="name"
										value={formData.name}
										onChange={inputHandler}
										label="cms.definitions.fields.name"
										required
									/>
								</div>
							</div>
							<div className="form-full">
								<label>
									<Translate content="cms.definitions.fields.content" />
									<span className="text-orange"> *</span>
								</label>
								<TextEditor value={formData.content} onChange={value => genericHandler(value, "content")} />
							</div>
							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="cms.definitions.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
