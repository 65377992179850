import PropTypes from "prop-types";
import { format } from "../../../../../../js/lib/Date";
import { uctrans } from "../../../../../../js/lib/Translator";
import Tippy from "@tippyjs/react";

export default function AppStatisticDateInfo({ content, startDate }) {
	return (
		<Tippy
			placement="top"
			arrow
			animation="perspective"
			duration="400"
			content={uctrans("app.start_date_end_date", {
				start_date: startDate,
				end_date: format(new Date(), "dd-MM-yyyy"),
			})}>
			<th className="border-l">{uctrans(content)}</th>
		</Tippy>
	);
}

AppStatisticDateInfo.propTypes = {
	startDate: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
};
