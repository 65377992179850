import PropTypes from "prop-types";
import { useContext } from "react";
import SelectField from "../../../../../../../js/react/components/general/Select";
import { CmsTypesContext } from "../../cms-types-context";

export default function QuestionnaireSelector(props) {
	const onchange = selectedQuestionnaire => {
		props.onChange(selectedQuestionnaire.value);
	};

	const { questionnaireOptions } = useContext(CmsTypesContext);
	const selectedQuestionnaire = questionnaireOptions.find(({ value }) => value === props.value);
	return (
		<>
			{props.label && <label>{props.label}</label>}
			<SelectField
				value={selectedQuestionnaire}
				options={questionnaireOptions}
				onChange={onchange}
				isClearable={false}
			/>
		</>
	);
}

QuestionnaireSelector.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
};
