import { yesNoOptions } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import Dialog from "../../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import Select from "../../../../../../js/react/components/general/Select";
import { error } from "../../../../../../js/react/components/general/notifications";
import map from "lodash/map";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";

export default function TriggerMomentFilters(props) {
	const [extraFilters, setExtraFilters] = useState(props.extraFilters);
	const [activeExtraFilter, setActiveExtraFilter] = useState(null);
	const [YesNoOptions] = useState(yesNoOptions(false));

	const filterOptions = map(props.triggerMomentTypes, type => ({
		value: type.key,
		label: type.label,
	}));

	const selectedFilter = activeExtraFilter
		? find(props.triggerMomentTypes, type => Number(type.key) === Number(activeExtraFilter.values.type))
		: null;

	useEffect(() => {
		if (typeof props.extraFilters !== "undefined") {
			setExtraFilters(props.extraFilters);
		}
	}, [props.extraFilters]);

	const handleChange = useCallback(
		locations => {
			props.onChange(locations);
			setExtraFilters(locations);
		},
		[props],
	);

	const handleDialogSave = () => {
		if (activeExtraFilter !== null) {
			if (activeExtraFilter.values.type) {
				const newLocations = Array.isArray(extraFilters) ? [...extraFilters] : [];
				if (activeExtraFilter.creating) {
					newLocations.push(activeExtraFilter.values);
				} else {
					newLocations[activeExtraFilter.index] = activeExtraFilter.values;
				}
				handleChange(newLocations);
				setActiveExtraFilter(null);
			} else {
				error(uctrans("communication.triggers.fields.no_type_filled"));
			}
		}
	};

	const handleDialogCancel = () => {
		setActiveExtraFilter(null);
	};

	const openCreateDialog = () => {
		setActiveExtraFilter({
			creating: true,
			index: -1,
			label: uctrans("general.add_:item", {}, { item: "client_treatment.fields.location" }),
			values: {
				type: null,
			},
		});
	};

	const changeExtraFilterValue = (key, value) => {
		if (activeExtraFilter !== null) {
			const newActiveExtraFilter = { ...activeExtraFilter };
			newActiveExtraFilter.values[key] = value;
			setActiveExtraFilter(newActiveExtraFilter);
		}
	};

	const openEditDialog = (index, values) => {
		setActiveExtraFilter({
			creating: false,
			index,
			label: uctrans("general.edit_:item", {}, { item: "client_treatment.fields.location" }),
			values: cloneDeep(values),
		});
	};

	const deleteExtraFilter = deleteIndex => {
		const newActiveExtraFilters = [...extraFilters];
		newActiveExtraFilters[deleteIndex] = {
			...newActiveExtraFilters[deleteIndex],
			deleted: true,
		};

		handleChange(newActiveExtraFilters);
	};

	const selectedFilterAttributeOptions = () => {
		if (selectedFilter) {
			switch (Number(selectedFilter.key)) {
				case props.triggerMomentTypes.CONNECTED_TO_TEAM.key:
					return props.teams;
				case props.triggerMomentTypes.CONNECTED_TO_ORGANISATION.key:
					return props.organisations;
				case props.triggerMomentTypes.CONNECTED_TO_RELATION_MANAGER.key:
					return props.relationManagers;
				case props.triggerMomentTypes.CONNECTED_TO_SEGMENT.key:
				case props.triggerMomentTypes.NOT_CONNECTED_TO_SEGMENT.key:
					return props.segments;
				case props.triggerMomentTypes.WORKING_FOR_ACTIVE_COMPANIES.key:
					return props.activeCompanyOptions;
				case props.triggerMomentTypes.ACTION_PERFORMED_BY.key:
					return props.actionPerformedByOptions;
				case props.triggerMomentTypes.DOSSIER_LATEST_PASS_DATE_KNOWN.key:
					return YesNoOptions;
			}
		}

		return [];
	};

	const labelForExtraFilter = type => {
		const filter = filterOptions.find(({ value }) => Number(value) === Number(type));

		if (filter) {
			return filter.label;
		}
		return "";
	};

	const getExtraAttributeType = type => {
		const filter = find(props.triggerMomentTypes, momentType => Number(momentType.key) === Number(type));

		if (filter) {
			return filter.key;
		}
		return null;
	};

	const extraAttributeTypeLabel = (attributeType, extraAttribute) => {
		let item = null;

		switch (Number(attributeType)) {
			case props.triggerMomentTypes.CONNECTED_TO_TEAM.key:
				item = props.teams.find(team => Number(team.value) === Number(extraAttribute));
				break;
			case props.triggerMomentTypes.CONNECTED_TO_ORGANISATION.key:
				item = props.organisations.find(company => Number(company.value) === Number(extraAttribute));
				break;
			case props.triggerMomentTypes.CONNECTED_TO_RELATION_MANAGER.key:
				item = props.relationManagers.find(user => Number(user.value) === Number(extraAttribute));
				break;
			case props.triggerMomentTypes.CONNECTED_TO_SEGMENT.key:
			case props.triggerMomentTypes.NOT_CONNECTED_TO_SEGMENT.key:
				item = props.segments.find(segment => Number(segment.value) === Number(extraAttribute));
				break;
			case props.triggerMomentTypes.WORKING_FOR_ACTIVE_COMPANIES.key:
				item = props.activeCompanyOptions.find(option => Number(option.value) === Number(extraAttribute));
				break;
			case props.triggerMomentTypes.ACTION_PERFORMED_BY.key:
				item = props.actionPerformedByOptions.find(option => Number(option.value) === Number(extraAttribute));
				break;
			case props.triggerMomentTypes.DOSSIER_LATEST_PASS_DATE_KNOWN.key:
				item = YesNoOptions.find(option => Number(option.value) === Number(extraAttribute));
				break;
		}

		if (item) {
			return item.label;
		}
		return "";
	};

	return (
		<>
			{extraFilters.map((extraFilter, index) =>
				!extraFilter.deleted ? (
					<div key={index} className="bg-primary-lightest blocks-center px-3 py-3 my-2 ">
						<div className="flex justify-between">
							<span>
								{getExtraAttributeType(extraFilter.type)
									? `${labelForExtraFilter(extraFilter.type)} - ${extraAttributeTypeLabel(
											getExtraAttributeType(extraFilter.type),
											extraFilter.extra_attribute,
									  )}`
									: labelForExtraFilter(extraFilter.type)}
							</span>

							<div className="flex blocks-center">
								<span
									className="cursor-pointer"
									onClick={() => {
										openEditDialog(index, extraFilter);
									}}>
									<EvaIcon className="flex blocks-center" type="edit-outline" width="20" height="20" fill="#009286" />
								</span>
								{!props.disabled && (
									<span
										className="cursor-pointer"
										onClick={() => {
											deleteExtraFilter(index);
										}}>
										<EvaIcon
											className="flex blocks-center"
											type="trash-2-outline"
											width="20"
											height="20"
											fill="#009286"
										/>
									</span>
								)}
							</div>
						</div>
					</div>
				) : null,
			)}
			{!props.disabled && (
				<button type="button" className="button button-secondary" onClick={openCreateDialog}>
					{uctrans("general.add_:item", {}, { item: "communication.triggers.fields.extra_filter_requirement" })}
				</button>
			)}

			<Dialog
				isOpen={activeExtraFilter !== null}
				onClose={handleDialogCancel}
				shouldCloseOnOverlayClick={false}
				width={400}
				title={activeExtraFilter && activeExtraFilter.title}>
				{activeExtraFilter !== null && (
					<>
						<div className="form-full">
							<label htmlFor="type">
								{uctrans("communication.triggers.fields.type")} <span className="text-orange">*</span>
							</label>
							<Select
								value={filterOptions.filter(({ value }) => Number(value) === Number(activeExtraFilter.values.type))}
								options={filterOptions}
								name="type"
								onChange={(value, metaData) => {
									if (value.value !== activeExtraFilter.values.type) {
										changeExtraFilterValue("extra_attribute", null);
									}
									changeExtraFilterValue(metaData.name, value.value);
								}}
								isClearable={false}
								required
							/>
						</div>

						{selectedFilter && selectedFilter.requiresAttribute ? (
							<div className="form-full">
								<label htmlFor="type">
									{uctrans("communication.triggers.fields.extra_attribute")} <span className="text-orange">*</span>
								</label>
								<Select
									value={selectedFilterAttributeOptions().filter(
										({ value }) => Number(value) === Number(activeExtraFilter.values.extra_attribute),
									)}
									options={selectedFilterAttributeOptions()}
									name="extra_attribute"
									onChange={(value, metaData) => changeExtraFilterValue(metaData.name, value.value)}
									isClearable={false}
									required
								/>
							</div>
						) : null}

						<div className="form-full">
							<div className="mt-6">
								{!props.disabled && (
									<button type="button" className="button button-primary button-dialog" onClick={handleDialogSave}>
										{uctrans("general.ok")}
									</button>
								)}

								<a onClick={handleDialogCancel}>{uctrans("general.cancel")}</a>
							</div>
						</div>
					</>
				)}
			</Dialog>
		</>
	);
}
TriggerMomentFilters.propTypes = {
	extraFilters: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	triggerMomentTypes: PropTypes.object,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	organisations: PropTypes.array,
	segments: PropTypes.array,
	teams: PropTypes.array,
	relationManagers: PropTypes.array,
	activeCompanyOptions: PropTypes.array,
	actionPerformedByOptions: PropTypes.array,
};

TriggerMomentFilters.defaultProps = {
	disabled: false,
};
