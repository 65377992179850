import find from "lodash/find";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { CmsTypesContext } from "../../cms-types-context";
import Attribute from "../attributes/Attribute";
import Text from "../attributes/Text";
import Select from "../attributes/Select";
import ClearablePart from "./ClearablePart";
import SubmenuPromo from "./SubmenuPromo";

export default function SubmenuItem(props) {
	const [values, setValues] = useState(props.values ? props.values : {});
	useEffect(() => {
		setValues(props.values ? props.values : "");
	}, [props.values]);
	const handleValuesChange = newValues => {
		setValues(newValues);
		props.onChange(newValues);
	};
	const handleValueChange = (name, newValue) => {
		const newValues = { ...values };
		newValues[name] = newValue;
		handleValuesChange(newValues);
	};
	const { partTypes } = useContext(CmsTypesContext);
	const partType = find(partTypes, partType => partType.key === "submenuitem");

	if (partType) {
		return (
			<div>
				{props.label && <h5>{props.label}</h5>}
				<div className="form-container">
					<span className="form-2-3">
						<Text
							label={partType.attributes.title.label}
							value={values.title}
							onChange={value => {
								handleValueChange("title", value);
							}}
						/>
					</span>
					<span className="form-1-3">
						<Select
							label={partType.attributes.type.label}
							value={values.type}
							options={partType.attributes.type.options}
							onChange={value => {
								handleValueChange("type", value);
							}}
						/>
					</span>
				</div>
				{(values.type === "link" || values.type == null) && (
					<ClearablePart
						parttype="linktarget"
						label={partType.attributes.link.label}
						values={values.link ? values.link : {}}
						onChange={value => {
							handleValueChange("link", value);
						}}
					/>
				)}
				{values.type === "extra_submenu" && (
					<>
						<div className="form-container">
							<span className="form-1-2">
								<Text
									label={partType.attributes.first_extra_submenu_title.label}
									value={values.first_extra_submenu_title}
									onChange={value => {
										handleValueChange("first_extra_submenu_title", value);
									}}
								/>
								<Attribute
									attribute={partType.attributes.first_extra_submenu_items}
									values={values.first_extra_submenu_items ? values.first_extra_submenu_items : []}
									onChange={value => {
										handleValueChange("first_extra_submenu_items", value);
									}}
								/>
							</span>
							<span className="form-1-2">
								<Text
									label={partType.attributes.second_extra_submenu_title.label}
									value={values.second_extra_submenu_title}
									onChange={value => {
										handleValueChange("second_extra_submenu_title", value);
									}}
								/>
								<Attribute
									attribute={partType.attributes.second_extra_submenu_items}
									values={values.second_extra_submenu_items ? values.second_extra_submenu_items : []}
									onChange={value => {
										handleValueChange("second_extra_submenu_items", value);
									}}
								/>
							</span>
						</div>
						<SubmenuPromo
							label={partType.attributes.extra_submenu_promo.label}
							values={values.extra_submenu_promo ? values.extra_submenu_promo : {}}
							onChange={value => {
								handleValueChange("extra_submenu_promo", value);
							}}
							showParentOption
						/>
					</>
				)}
			</div>
		);
	}
}

SubmenuItem.propTypes = {
	label: PropTypes.string,
	values: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func.isRequired,
};
