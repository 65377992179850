import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import Spinner from "../../../../../js/react/components/general/Spinner";
import { RmDashboardContext } from "./RmDashboardContext";

export default function RmDashboardBlock(props) {
	const { rememberCollapsedDashboadBlocks, rememberCollapsedDashboadBlock, fetchBlockData, wallet } =
		useContext(RmDashboardContext);

	const [lastUpdatedAt, setLastUpdatedAt] = useState(props.lastUpdatedAt);

	const [collapsed, setCollapsed] = useState(
		!props.collapsable
			? false
			: props.rememberCollapsed && rememberCollapsedDashboadBlocks.hasOwnProperty(props.blockKey)
			  ? rememberCollapsedDashboadBlocks[props.blockKey] === "1"
			  : props.defaultCollapsed,
	);

	const changeCollapsed = () => {
		const newCollapsed = !collapsed;
		setCollapsed(newCollapsed);
		if (props.rememberCollapsed) {
			rememberCollapsedDashboadBlock(props.blockKey, newCollapsed);
		}
	};

	const [initialized, setInitialized] = useState(!props.useFetching);
	const [blockData, setBlockData] = useState(props.blockData);

	useEffect(() => {
		if (props.useFetching && (props.useFetchingWhenCollapsed || !collapsed)) {
			if (!initialized || (props.lastUpdatedAt !== null && props.lastUpdatedAt !== lastUpdatedAt)) {
				fetchBlockData(props.blockKey).then(data => {
					setBlockData(data);
					setInitialized(true);
					setLastUpdatedAt(props.lastUpdatedAt);
				});
			}
		}
	}, [
		fetchBlockData,
		initialized,
		props.blockKey,
		props.useFetching,
		props.useFetchingWhenCollapsed,
		collapsed,
		props.lastUpdatedAt,
		lastUpdatedAt,
	]);

	useEffect(() => {
		if (props.useFetching) {
			setInitialized(false);
		}
	}, [wallet, props.useFetching]);

	return (
		<div className={`bg-grey-lightest shadow rounded flex flex-col w-full ${!collapsed ? "h-full" : ""}`}>
			<div className="shadow p-6 flex">
				<span className="text-grey-darker w-full">
					<div className="w-full flex justify-between">
						{props.renderHeader(blockData)}
						{props.collapsable && (
							<div className="cursor-pointer" onClick={changeCollapsed}>
								<EvaIcon fill="#000000" width="18" height="18" type={collapsed ? "plus" : "minus"} />
							</div>
						)}
					</div>
				</span>
			</div>
			{!collapsed && (
				<div className="p-6 w-full">{initialized ? props.renderContent(blockData) : <Spinner width={25} />}</div>
			)}
		</div>
	);
}

RmDashboardBlock.propTypes = {
	blockKey: PropTypes.string.isRequired,
	renderHeader: PropTypes.func.isRequired,
	renderContent: PropTypes.func.isRequired,
	rememberCollapsed: PropTypes.bool,
	defaultCollapsed: PropTypes.bool,
	blockData: PropTypes.object,
	useFetching: PropTypes.bool,
	useFetchingWhenCollapsed: PropTypes.bool,
	collapsable: PropTypes.bool,
	lastUpdatedAt: PropTypes.number,
};
RmDashboardBlock.defaultProps = {
	collapsable: true,
	rememberCollapsed: true,
	defaultCollapsed: false,
	useFetching: false,
	useFetchingWhenCollapsed: false,
	lastUpdatedAt: null,
};
