import PropTypes from "prop-types";
import { useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import Translate from "../../../../../js/react/components/general/Translate";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import Dialog from "../../../../../js/react/components/general/Dialog";
import { route } from "../../../../../js/helpers";

export default function RandomCheckDashboardBlock({ rememberCollapsed, randomChecks }) {
	const [currentRandomCheck, setCurrentRandomCheck] = useState(null);
	const [isCollapsed, setCollapsed] = useState(window.data.collapsed_dashboard_blocks.random_check_block === "1");

	const onCheckConfirm = () => {
		window.location = route("crm.random-checks.export", currentRandomCheck.id);
		setCurrentRandomCheck(null);
	};

	return (
		<>
			<div className=" vertical-spacer spacer flex">
				<div className="bg-grey-lightest shadow rounded flex flex-col w-full">
					<div className="p-6 flex">
						<a
							className="no-underline text-grey-darker w-full cursor"
							onClick={() => {
								const collapsed = !isCollapsed;
								if (rememberCollapsed) {
									rememberCollapsed("random_check_block", collapsed);
								}
								setCollapsed(collapsed);
							}}>
							<div className="w-full flex justify-between">
								<div>
									<h5 className="mb-0">{uctrans("random_checks.title")}</h5>
								</div>
								<div>
									<EvaIcon fill="#000000" width="18" height="18" type={isCollapsed ? "plus" : "minus"} />
								</div>
							</div>
						</a>
					</div>
					{!isCollapsed && (
						<div className="px-6 pb-6">
							{randomChecks.map(randomCheck => (
								<button
									key={randomCheck.id}
									className="button button-primary"
									onClick={() => setCurrentRandomCheck(randomCheck)}>
									{randomCheck.label}: {randomCheck.amount}{" "}
									<Translate ucfirst={false} content="intermediaries.plural" />
								</button>
							))}
						</div>
					)}
				</div>
			</div>
			{currentRandomCheck !== null && (
				<Dialog
					isOpen={currentRandomCheck !== null}
					isFullScreen
					title={currentRandomCheck.label}
					onClose={() => setCurrentRandomCheck(null)}>
					<div>
						<p className="pb-4">
							<Translate content="random_checks.start_check_text" />
						</p>
						<SubmitBar
							item="random_checks.singular"
							onSubmit={() => onCheckConfirm()}
							buttonContent={uctrans("random_checks.start_check")}
							addAnotherAvailable={false}
							onCancel={() => setCurrentRandomCheck(null)}
						/>
					</div>
				</Dialog>
			)}
		</>
	);
}

RandomCheckDashboardBlock.propTypes = {
	rememberCollapsed: PropTypes.func,
	randomChecks: PropTypes.array.isRequired,
};
