import Tippy from "@tippyjs/react";
import { useState } from "react";
import { format } from "../../../../../js/lib/Date";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import { can, route, yesNoOptions } from "../../../../../js/helpers";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DatePickerFilter from "../../../../../js/react/components/general/overview/DatePickerFilter";
import DeleteColumnContent from "../../../../../js/react/components/general/overview/DeleteColumnContent";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import TextFilter from "../../../../../js/react/components/general/overview/TextFilter";
import Translate from "../../../../../js/react/components/general/Translate";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import IconButton from "../../../../../js/react/components/general/IconButton";

export default function ClientTreatmentOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});
	const [YesNoOptions] = useState(yesNoOptions());

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="client_treatment.plural" />
				</h1>
				{can("client_treatment.client_treatment", "create") && (
					<div>
						<IconButton
							href={route("crm.client-treatments.create")}
							content={uctrans("general.add_:item", {}, { item: "client_treatment.singular" })}
							primary
						/>
					</div>
				)}
			</div>
			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.client-treatments.index")}
				deleteRouteName="crm.client-treatments.delete"
				modelTranslations={{ plural: trans("client_treatment.plural"), singular: trans("client_treatment.singular") }}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
						<TextFilter
							hideIcon
							name="costs_per_unit"
							className="form-1-4"
							collapsible
							label={uctrans("client_treatment.fields.costs_per_unit")}
						/>
						<TextFilter
							hideIcon
							name="maximum_amount"
							className="form-1-4"
							collapsible
							label={uctrans("client_treatment.fields.maximum_amount")}
						/>
						<DatePickerFilter
							className="form-1-4"
							name="start_date_from"
							label={uctrans("client_treatment.fields.start_date_from")}
							collapsible
						/>
						<DatePickerFilter
							className="form-1-4"
							name="start_date_until"
							label={uctrans("client_treatment.fields.start_date_until")}
							collapsible
						/>
						<DatePickerFilter
							className="form-1-4"
							name="end_date_from"
							label={uctrans("client_treatment.fields.end_date_from")}
							collapsible
						/>
						<DatePickerFilter
							className="form-1-4"
							name="end_date_until"
							label={uctrans("client_treatment.fields.end_date_until")}
							collapsible
						/>
						<SelectFilter
							className="form-1-4"
							name="finished"
							label={uctrans("client_treatment.fields.finished")}
							options={YesNoOptions}
							collapsible
						/>
						<SelectFilter
							className="form-1-4"
							name="is_active"
							label={uctrans("client_treatment.fields.is_active")}
							options={YesNoOptions}
							collapsible
						/>
						<SelectFilter
							className="form-1-4"
							name="usefull_content_character"
							label={uctrans("client_treatment.fields.usefull_content_character")}
							options={YesNoOptions}
							collapsible
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={4}
							sortable="name"
							renderHeaderContent={() => uctrans("client_treatment.fields.name")}
							renderCellContent={treatment => {
								if (can("client_treatment.client_treatment", "update")) {
									return (
										<a href={route("crm.client-treatments.edit", treatment.id)} className="row-title link">
											{treatment.name}
										</a>
									);
								} else if (can("client_treatment.client_treatment", "view")) {
									return (
										<a href={route("crm.client-treatments.show", treatment.id)} className="row-title link">
											{treatment.name}
										</a>
									);
								} else {
									return treatment.name;
								}
							}}
						/>
						<DataColumn
							width={3}
							sortable="type"
							renderHeaderContent={() => uctrans("client_treatment.fields.type")}
							renderCellContent={treatment => (treatment.type ? treatment.type : "-")}
						/>
						<DataColumn
							width={3}
							sortable="start"
							renderHeaderContent={() => uctrans("client_treatment.fields.start_date")}
							renderCellContent={treatment => (treatment.start ? format(treatment.start, "dd-MM-y HH:mm") : "-")}
						/>
						<DataColumn
							width={3}
							sortable="end"
							renderHeaderContent={() => uctrans("client_treatment.fields.end_date")}
							renderCellContent={treatment => (treatment.end ? format(treatment.end, "dd-MM-y HH:mm") : "-")}
						/>
						<DataColumn
							width={3}
							sortable="max_amount"
							renderHeaderContent={() => uctrans("client_treatment.fields.total_amount")}
							renderCellContent={treatment => (treatment.max_amount ? treatment.max_amount : "-")}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("client_treatment.fields.amount_divided")}
							renderCellContent={treatment => (treatment.divided_amount !== null ? treatment.divided_amount : "-")}
						/>

						{can("client_treatment.client_treatment", "create") && (
							<a
								className="mr-2"
								onClick={() => {
									console.warn("COPY");
								}}>
								<Tippy
									placement="left"
									arrow
									animation="perspective"
									duration="400"
									content={uctrans("general.add_:item", {
										item: trans("general.copy_of_:item", {}, { item: "client_treatment.singular" }),
									})}>
									<span>
										<EvaIcon type="copy-outline" fill="#3490DC" height="18" width="18" />
									</span>
								</Tippy>
							</a>
						)}
						{can("client_treatment.client_treatment", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(status, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(status)} />
								)}
							/>
						)}
						{!can("client_treatment.client_treatment", "delete") &&
							!can("client_treatment.client_treatment", "create") && (
								<DataColumn width={1} renderHeaderContent={() => ""} renderCellContent={() => ""} />
							)}
					</DataTable>
				)}
			/>
		</>
	);
}
