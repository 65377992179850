import { useMemo, useState } from "react";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import SingleSelectAutoComplete from "../../../../../js/react/components/general/autocomplete/SingleSelectAutoComplete";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import Translate from "../../../../../js/react/components/general/Translate";
import TextEditor from "../forms/textEditor/TextEditor";
import CollapsibleContent from "../general/CollapsibleContent";
import AFMPermits from "../intermediaries/statutory/AFMPermits";
import KifidChecks from "../intermediaries/statutory/KifidChecks";
import QuestionnaireCompletionAnswersForm from "../questionnaires/QuestionnaireCompletionAnswersForm";
import AgendaItemDateFields from "./AgendaItemDateFields";
import AgendaItemFormFields from "./AgendaItemFormFields";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import map from "lodash/map";
import find from "lodash/find";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import { can, route } from "../../../../../js/helpers";
import CooperationRequestCompletionAnswersList from "./CooperationRequestCompletionAnswersList";
import DuplicateKVK from "./DuplicateKVK";
import Navigation from "./Navigation";

export default function CooperationRequestCompletionForm() {
	const readOnly = window.data.read_only;

	const [formData, setFormData] = useState({ ...window.data.cooperation_request_completion_form_data });
	const [staticData] = useState({ ...window.data.cooperation_request_completion_static_data });

	const [originalStatus] = useState(
		find(staticData.handled_statuses, status => Number(status.key) === Number(formData.handled_status)),
	);

	const disabled = originalStatus && originalStatus.is_final ? true : readOnly;

	const currentSelectedStatus = find(
		staticData.handled_statuses,
		status => Number(status.key) === Number(formData.handled_status),
	);

	const onSuccess = response => {
		if (response.data.redirect_link) {
			Nav.go(response.data.redirect_link);
		} else {
			Nav.go(route("crm.agenda-items.index"));
		}
	};

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	const changeQuestionnaireCompletionValues = (key, value) => {
		const newFormData = { ...formData };
		newFormData.questionnaire_completion[key] = value;
		setFormData(newFormData);
	};

	const previousURL = () => {
		if (window.data.agendaNamespace) {
			if (staticData.intermediary) {
				return route("crm.agenda.intermediaries.agenda-items.index", { intermediary: staticData.id });
			} else if (staticData.organisation) {
				return route("crm.agenda.organisations.agenda-items.index", { organisation: staticData.id });
			}
		}

		if (staticData.intermediary) {
			return route("crm.intermediaries.agenda-items.index", { intermediary: formData.agenda_item.company_id });
		} else if (staticData.organisation) {
			return route("crm.organisations.agenda-items.index", { organisation: formData.agenda_item.company_id });
		}
	};

	const colorCode = () => {
		switch (formData.color_code) {
			case staticData.color_codes.GREEN.key:
				return <span style={{ color: "green" }}>{uctrans("cooperation_check.color_codes.green")}</span>;
			case staticData.color_codes.RED.key:
				return <span style={{ color: "red" }}>{uctrans("cooperation_check.color_codes.red")}</span>;
			case staticData.color_codes.ORANGE.key:
				return <span style={{ color: "orange" }}>{uctrans("cooperation_check.color_codes.orange")}</span>;
			default:
				return "unkown color code";
		}
	};

	const getLinkedFieldName = linked_type => {
		const linkedField = find(staticData.linked_field_types, type => Number(type.key) === Number(linked_type));

		if (typeof linkedField === "undefined") {
			return "unkown field";
		}

		return linkedField.label;
	};

	const getAnswerForQuestion = answer => {
		const givenAnswer = answer.open_content
			? answer.open_content
			: answer.numeric_open_content
			  ? answer.numeric_open_content
			  : "";

		if (Number(answer.linked_field_type) === Number(staticData.linked_field_types.ORGANISATION.key)) {
			const organisation = find(
				staticData.questionnairetags.organisations,
				type => Number(type.value) === Number(givenAnswer),
			);

			if (typeof organisation === "undefined") {
				return givenAnswer;
			}

			return organisation.label;
		} else if (
			Number(answer.linked_field_type) === Number(staticData.linked_field_types.IDENTIFICATION_VERIFICATION.key)
		) {
			const identificationType = find(
				staticData.questionnairetags.identification_verification,
				type => Number(type.id) === Number(givenAnswer),
			);

			if (typeof identificationType === "undefined") {
				return givenAnswer;
			}

			return identificationType.name;
		} else if (Number(answer.linked_field_type) === Number(staticData.linked_field_types.SOFTWARE_PACKAGE.key)) {
			if (givenAnswer.includes(";")) {
				const softwarepackageValues = givenAnswer.split(";");
				const softwarePackage = find(
					staticData.questionnairetags.software_packages,
					type => Number(type.id) === Number(softwarepackageValues[0]),
				);
				return `${softwarePackage.name} - ${softwarepackageValues[1]}`;
			}

			const softwarePackage = find(
				staticData.questionnairetags.software_packages,
				type => Number(type.id) === Number(givenAnswer),
			);

			if (typeof softwarePackage === "undefined") {
				return givenAnswer;
			}

			return softwarePackage.name;
		}

		return givenAnswer;
	};

	const onQuestionnaireCompletionAnswersChanged = answers => {
		const newFormData = { ...formData };
		newFormData.answers = answers;
		setFormData(newFormData);
	};

	const versionString = useMemo(() => `v${staticData.cooperation_request.version.version}`, [staticData]);

	return (
		<>
			{window.data.nav_data ? (
				<Navigation
					selectedTab={window.data.nav_data.activeTabItem}
					breadCrumbs={window.data.nav_data.breadcrumbs}
					tabs={window.data.nav_data.tabs}
				/>
			) : (
				<Navigation
					selectedTab="agendaitems"
					breadCrumbs={[{ label: uctrans("agendaitems.accountsupport"), route: route("crm.agenda-items.index") }]}
				/>
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.company_name
						? uctrans(
								"general.:item1 from :item2",
								{ item2: window.data.company_name },
								{ item1: "cooperation_check.singular" },
						  )
						: uctrans("cooperation_check.singular_versioned", { version: versionString })}
				</h1>
				{window.data.agendaNamespace || staticData.creating ? (
					<a className="link" href={route("crm.agenda-items.index")}>
						{uctrans("general.back_to_:page", {}, { page: "agendaitems.accountsupport" })}
					</a>
				) : (
					<a className="link" href={previousURL()}>
						{uctrans("general.back_to_overview_:page", {}, { page: "agendaitems.plural" })}
					</a>
				)}
			</div>

			<div className="min-h-screen md:flex">
				<div className="flex-1 w-2/3 mr-12">
					<AjaxForm
						method="PUT"
						submitUrl={route("crm.agenda.cooperation-request-completions.update", [formData.id])}
						loaderText={uctrans("general.:item_is_being_saved", {}, { item: "cooperation_check.singular" })}
						successText={uctrans("general.saved_:item", {}, { item: "cooperation_check.singular" })}
						data={{ ...formData }}
						onSuccess={onSuccess}
						useFlashMessage={staticData.creating || window.data.agendaNamespace}
						showConfirm={{
							when: currentSelectedStatus.is_final && currentSelectedStatus !== originalStatus,
							confirmText: uctrans("general.yes"),
							cancelText: uctrans("general.cancel"),
							title: uctrans("general.are_you_sure"),
							description: uctrans("general.are_you_sure_you_want_to_proceed_with_this_action"),
						}}>
						<div className="form-container">
							<AgendaItemFormFields
								onChange={value => handleFormValueChange("agenda_item", value)}
								agendaItemFormData={formData.agenda_item}
								agendaItemStaticData={staticData}
								showAgendaDate
								editable={!disabled}
							/>

							<div className="form-full">
								<label htmlFor="name">{uctrans("cooperation_check.annually.notes")}</label>
								<TextEditor
									value={formData.questionnaire_completion.notes}
									onChange={content => changeQuestionnaireCompletionValues("notes", content)}
									hideToolbar
								/>
							</div>

							<div className="form-full">
								<label htmlFor="color_code">
									<Translate content="cooperation_check.fields.color_code" />
								</label>
								{colorCode()}
							</div>

							<div className="form-full">
								<label htmlFor="color_code">
									<Translate content="cooperation_check.fields.resulting" />
								</label>
								{originalStatus && originalStatus.is_final ? (
									<span dangerouslySetInnerHTML={{ __html: originalStatus.finished_label }} />
								) : (
									<RadioGroup
										disabled={disabled}
										name="handled_status"
										value={formData.handled_status}
										onChange={value => handleFormValueChange("handled_status", value)}>
										{map(staticData.handled_statuses, status => {
											if (
												Number(status.key) ===
													Number(staticData.handled_statuses.HANDLED_LINK_TO_EXISTING_TAKEON.key) &&
												Object.entries(staticData.takeon_companies).length === 0
											) {
												return null;
											} else {
												return (
													<Radio inline key={status.key} value={status.key}>
														<span dangerouslySetInnerHTML={{ __html: status.label }} />
													</Radio>
												);
											}
										})}
									</RadioGroup>
								)}
							</div>

							{!originalStatus.is_final && (
								<>
									{Number(currentSelectedStatus.key) ===
										Number(staticData.handled_statuses.HANDLED_LINK_TO_EXISTING_TAKEON.key) && (
										<div className="form-full">
											<label htmlFor="takeon_company_id">
												<Translate content="cooperation_check.fields.link_to_takeon" />
												<span className="text-orange"> *</span>
											</label>
											<SingleSelectAutoComplete
												name="takeon_company_id"
												dataSource={route("crm.companies.search-prospect").toString()}
												item={formData.company_id}
												onChange={value => handleFormValueChange("takeon_company_id", value ? value.value : null)}
												placeholder={uctrans("general.type_to_add_:item", {}, { item: "companies.singular" })}
												async
											/>
										</div>
									)}

									{Number(currentSelectedStatus.key) ===
										Number(staticData.handled_statuses.HANDLED_LINK_AS_EXTRA_COOPERATION_REQUEST.key) && (
										<div className="form-full">
											<label htmlFor="existing_company_id">
												<Translate content="cooperation_check.fields.link_to_existing_company" />
												<span className="text-orange"> *</span>
											</label>
											<SingleSelectAutoComplete
												name="existing_company_id"
												dataSource={route("crm.companies.search").toString()}
												item={formData.company_id}
												onChange={value => handleFormValueChange("existing_company_id", value ? value.value : null)}
												placeholder={uctrans("general.type_to_add_:item", {}, { item: "companies.singular" })}
												async
											/>
										</div>
									)}
								</>
							)}

							<CollapsibleContent title={uctrans("cooperation_check.fields.questionsanswers")}>
								{staticData.profile_answers_questionnaire &&
									Object.keys(staticData.profile_answers_questionnaire).length > 0 && (
										<CooperationRequestCompletionAnswersList answers={staticData.profile_answers_questionnaire} />
									)}

								<QuestionnaireCompletionAnswersForm
									questionnaire={formData.questionnaire}
									questionnaireCompletionAnswerData={formData.questionnaire_completion}
									questionnaireCompletionAnswersChanged={onQuestionnaireCompletionAnswersChanged}
									questionnaireTags={staticData.questionnairetags}
									disabled={disabled}
									colors={staticData.color_codes}
									showColorsAndAdministration
								/>
							</CollapsibleContent>

							<div className="form-full">
								<div className="row">
									<div className="w-full md:w-1/2 lg:w-1/3 column">
										<SubmitBar item="cooperation_check.singular" addAnotherAvailable={false} />
									</div>
								</div>
							</div>
						</div>
					</AjaxForm>
				</div>
				<div className="flex-none w-1/3 border-l pl-12">
					<AgendaItemDateFields agendaItem={formData.agenda_item} />

					<AFMPermits
						afmNumber={formData.afm_number}
						afmPermits={staticData.afm_existing_permits}
						missingPermits={staticData.afm_missing_permits}
						requiredPermits={staticData.afm_required_permits}
						afmShowChangeLog={false}
						afmChangeLogs={staticData.afm_changelogs}
						afmChangeLogTypes={staticData.afm_changelog_types}
					/>
					<KifidChecks kifidTypes={staticData.kifid_types} kifidNumber={formData.kifid_number} />

					{staticData.kvk_duplicate_check.has_duplicates && (
						<DuplicateKVK kvk_duplicate_check={staticData.kvk_duplicate_check} enums={staticData.enums} />
					)}

					<CollapsibleContent initialCollapseState={false} title={uctrans("general.general")}>
						<div className="form-full">
							<label htmlFor="color_code">
								<Translate content="cooperation_check.fields.color_code" />
							</label>
							{colorCode()}
						</div>
						{originalStatus && originalStatus.is_final && (
							<>
								<div className="form-full">
									<label htmlFor="color_code">
										<Translate content="cooperation_check.fields.resulting" />
									</label>
									<span dangerouslySetInnerHTML={{ __html: originalStatus.finished_label }} />
								</div>

								{can("agenda.cooperation_request_completion", "detach") ? (
									<div className="form-full">
										<AjaxForm
											method="PUT"
											submitUrl={route("crm.agenda.cooperation-request-completions.detach", [formData.id])}
											loaderText={uctrans(
												"general.:item_is_being_saved",
												{},
												{ item: "intermediaries.fields.status_change" },
											)}
											successText={uctrans("general.saved_:item", {}, { item: "intermediaries.fields.status_change" })}
											onSuccess={() => window.location.reload()}
											showConfirm={{
												when: true,
												confirmText: uctrans("general.yes"),
												cancelText: uctrans("general.cancel"),
												title: uctrans("general.are_you_sure"),
												description: uctrans("cooperation_check.fields.are_you_sure_you_want_to_detach"),
											}}
											useFlashMessage>
											<div className="form-full">
												<SubmitBar
													buttonContent="cooperation_check.fields.undo_cooperation_check"
													addAnotherAvailable={false}
												/>
											</div>
										</AjaxForm>
									</div>
								) : null}
							</>
						)}

						<div className="form-full">
							<label htmlFor="profile_answers">
								<Translate content="cooperation_check.fields.profile_answers_questionnaire" />
							</label>
							{map(
								staticData.profile_answers_questionnaire,
								(answer, key) =>
									answer && (
										<div key={key}>
											<label>{uctrans(`cooperation_check.fields.${key}`)}:</label>
											{answer}
										</div>
									),
							)}
						</div>

						<div className="form-full">
							<label htmlFor="profile_answers">
								<Translate content="cooperation_check.fields.profile_answers_system_questionnaire" />
							</label>
							{map(staticData.profile_answers_system_questionnaire, (answer, linked_type) => (
								<div key={linked_type}>
									<label>{getLinkedFieldName(linked_type)}:</label>
									{getAnswerForQuestion(answer)}
								</div>
							))}
						</div>
					</CollapsibleContent>
				</div>
			</div>
		</>
	);
}
