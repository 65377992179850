import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import map from "lodash/map";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import { removeAnswerLogic, setAnswerLogic, checkQuestionDependency } from "../../../../../js/questionnairehelpers";
import Translate from "../../../../../js/react/components/general/Translate";
import QuestionList from "../../../../../website/js/react/components/QuestionList";
import QuestionnaireContext from "../../../../../website/js/react/components/QuestionnaireContext";

export default function QuestionnaireCompletionAnswersForm(props) {
	const initialAnswers =
		props.questionnaireCompletionAnswerData && props.questionnaireCompletionAnswerData.answers
			? cloneDeep(props.questionnaireCompletionAnswerData).answers
			: [];
	const [answerData, setAnswerData] = useState(initialAnswers);
	const [changesOnPage, setChangesOnPage] = useState(false);

	const setAnswer = (question, value) => {
		setChangesOnPage(true);
		if (value === "") {
			return removeAnswer(question, null);
		}
		const newAnswerData = [...answerData];
		const newState = setAnswerLogic(newAnswerData, question, value, props.questionnaire.question_types);
		setAnswerData(newState);
	};

	const replaceTags = content => {
		const matches = content.match(/\{.*?\}/g);

		if (matches !== null) {
			// get first match only
			const tagToReplace = find(
				props.questionnaireTags,
				(tag, key) => key === matches[0].substring(1, matches[0].length - 1),
			);

			if (typeof tagToReplace !== "undefined") {
				if (tagToReplace !== null) {
					return content.replace(matches[0], tagToReplace);
				} else {
					return content.replace(matches[0], trans("companies.tag_unknown"));
				}
			}
		}

		return content;
	};

	const removeAnswer = (question, value) => {
		setChangesOnPage(true);
		const newAnswerData = [...answerData];
		const newState = removeAnswerLogic(newAnswerData, question, value, props.questionnaire.question_types);
		setAnswerData(newState);
	};

	useEffect(() => {
		props.questionnaireCompletionAnswersChanged(answerData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [answerData]);

	let questionsForQuestionnaire = props.questionnaire.pages.map(page => page.questions).flat();

	questionsForQuestionnaire = map(questionsForQuestionnaire, question => {
		checkQuestionDependency(question, answerData, props.questionnaire.dependency_types);
		return question;
	});

	const colorCode = option => {
		switch (Number(option.color_code)) {
			case props.colors.GREEN.key:
				return <span style={{ color: "green" }}>({uctrans("cooperation_check.color_codes.green")})</span>;
			case props.colors.RED.key:
				return <span style={{ color: "red" }}>({uctrans("cooperation_check.color_codes.red")})</span>;
			case props.colors.ORANGE.key:
				return <span style={{ color: "orange" }}>({uctrans("cooperation_check.color_codes.orange")})</span>;
			default:
				return "";
		}
	};
	return (
		<>
			{changesOnPage && (
				<b>
					<Translate content="questionnaires.questionnaire_completion.answers_changed_dont_forget_save" />
				</b>
			)}
			{!changesOnPage && <div style={{ height: "18px" }} />}
			<div className="min-h-screen md:flex">
				<div className="flex-1 w-2/3 mr-12">
					<div className="form-container">
						<QuestionnaireContext.Provider
							value={{
								questionnaire: props.questionnaire,
								currentPage: null,
								setAnswer,
								removeAnswer,
								replaceTags,
								allQuestions: questionsForQuestionnaire,
								answers: answerData,
								disabled: props.disabled,
								colors: props.colors,
								answerOptionWithColorCode: colorCode,
								showColorsAndAdministration: props.showColorsAndAdministration,
								questionnaireTags: props.questionnaireTags,
							}}>
							<div className="form-full">
								<QuestionList />
							</div>
						</QuestionnaireContext.Provider>
					</div>
				</div>
			</div>
		</>
	);
}

QuestionnaireCompletionAnswersForm.propTypes = {
	questionnaire: PropTypes.object,
	questionnaireCompletionAnswerData: PropTypes.object,
	questionnaireTags: PropTypes.object,
	colors: PropTypes.object,
	questionnaireCompletionAnswersChanged: PropTypes.func,
	disabled: PropTypes.bool,
	showColorsAndAdministration: PropTypes.bool,
};

QuestionnaireCompletionAnswersForm.defaultProps = {
	disabled: false,
	showColorsAndAdministration: false,
};
