import map from "lodash/map";
import PropTypes from "prop-types";
import { trans, uctrans } from "../../../../../js/lib/Translator";

export default function ClientTreatmentDividationsSum(props) {
	const { dividationData } = props;

	return (
		dividationData &&
		Number(dividationData.total_to_divide) > 0 && (
			<>
				<label>{uctrans("general.total")}</label>
				{dividationData.total_to_divide ? (
					<>
						<span>{`${dividationData.total_to_divide} ${trans("client_treatment.fields.places")}`}</span> <br />
					</>
				) : null}
				{dividationData.total_divided ? (
					<>
						<span>{`${dividationData.total_divided} ${trans("client_treatment.fields.divided")}`}</span> <br />
					</>
				) : null}
				{dividationData.total_statuses ? (
					<>
						{map(dividationData.total_statuses, (amount, key) => `${amount} ${key}`).join(", ")}
						<br />
					</>
				) : null}
				{dividationData.inviters
					? map(dividationData.inviters, (inviter, index) => (
							<div key={index} className="mt-3">
								<label>{inviter.name}</label>
								<span>{`${inviter.to_divide} ${trans("client_treatment.fields.to_divide")}`}</span>
								<br />
								<span>{`${inviter.divided} ${trans("client_treatment.fields.divided")}`}</span>
								<br />
								{!!inviter.statuses && map(inviter.statuses, (amount, key) => `${amount} ${key}`).join(", ")}
							</div>
					  ))
					: null}{" "}
			</>
		)
	);
}
ClientTreatmentDividationsSum.propTypes = {
	dividationData: PropTypes.object,
};
