import { format } from "../../../../../js/lib/Date";
import { createLoader } from "../../../../../js/react/components/general/notifications";
import { useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import Tippy from "@tippyjs/react";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import Translate from "../../../../../js/react/components/general/Translate";
import axios from "axios";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import DeleteDialog from "../../../../../js/react/components/general/DeleteDialog";
import { can, route } from "../../../../../js/helpers";
import findIndex from "lodash/findIndex";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import QuestionnaireCompletionForm from "./QuestionnaireCompletionForm";
import QuestionnaireTabs from "./QuestionnaireTabs";

export default function QuestionnaireCompletionOverview() {
	const [overviewData, setOverviewData] = useState({
		overview: { ...window.data.overview },
		questionnaire: window.data.questionnaire,
		questionnaire_completion_statusses: window.data.questionnaire_completion_statusses,
		resultData: {
			errors: [],
			warnings: [],
		},
		dialogOpen: false,
		requestKind: "",
		participant_column_available: window.data.participant_column_available,
		company_column_available: window.data.company_column_available,
		label_column_available: window.data.label_column_available,
	});

	const [activeQuestionnaireCompletion, setActiveQuestionnaireCompletion] = useState(null);
	const deleteQuestionnaireCompletion = async questionnaireCompletion => {
		const result = await axios.get(
			route("crm.questionnaire_completions.delete", {
				questionnaire: overviewData.questionnaire.id,
				questionnaire_completion: questionnaireCompletion.id,
			}),
		);
		if (result.status === 200) {
			toggleDecisionDialog(result.data, "deleted");
		}
	};

	const toggleDecisionDialog = async (resultData = null, requestKind = null) => {
		setOverviewData({
			...overviewData,
			resultData,
			dialogOpen: !overviewData.dialogOpen,
			requestKind,
		});
	};

	const handleDialogSubmit = async overview => {
		const loader = createLoader(
			uctrans(
				`general.:item_is_being_${overviewData.requestKind}`,
				{},
				{ item: "questionnaires.questionnaire_completion.singular" },
			),
		);
		const result =
			overviewData.requestKind === "deleted"
				? await axios.post(overviewData.resultData.destroy_link, { _method: "delete" })
				: await axios.put(overviewData.resultData.destroy_link);

		if (result.status === 200) {
			loader.success(
				uctrans(
					`general.${overviewData.requestKind}_:item`,
					{},
					{ item: "questionnaires.questionnaire_completion.singular" },
				),
			);
			toggleDecisionDialog();
			overview.loadData();
		} else {
			loader.failure("something went wrong deleting the questionnaire_completion TODO -> show this error message");
		}
	};

	const selectQuestionnaireCompletion = questionnaireCompletion => {
		setActiveQuestionnaireCompletion(questionnaireCompletion);
	};

	const handleQuestionnaireCompletionChanged = questionnaireCompletion => {
		if (questionnaireCompletion) {
			const index = findIndex(overviewData.overview.items, ["id", questionnaireCompletion.id]);
			if (index !== -1) {
				overviewData.overview.items.splice(index, 1, questionnaireCompletion);
			}
		}

		setActiveQuestionnaireCompletion(questionnaireCompletion);
	};

	return (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{ label: uctrans("questionnaires.plural"), route: route("crm.questionnaires.index") },
					{
						label: overviewData.questionnaire.name,
					},
				]}
			/>
			<QuestionnaireTabs questionnaireId={overviewData.questionnaire.id} initialActiveTabIndex={3} />

			{!activeQuestionnaireCompletion && (
				<>
					<div className="page-title-container">
						<h1 className="page-title">
							<Translate
								content="questionnaires.questionnaire_completion.completions_:item"
								replaces={{ item: overviewData.questionnaire.name }}
							/>
						</h1>
					</div>

					<AbnOverview
						initialData={overviewData.overview}
						indexUrl={route("crm.questionnaire_completions.index", { questionnaire: overviewData.questionnaire.id })}
						renderFilters={() => (
							<Filters>
								<SearchBarFilter type="text" name="search" />
								<SelectFilter
									className="form-1-3"
									name="status"
									label={uctrans("questionnaires.questionnaire_completion.fields.status")}
									options={overviewData.questionnaire_completion_statusses}
								/>
							</Filters>
						)}
						renderBody={() => (
							<DataTable>
								<DataColumn
									width={2}
									sortable="created_at"
									renderHeaderContent={() => uctrans("questionnaires.questionnaire_completion.fields.created_at")}
									renderCellContent={questionnaireCompletion => (
										<a
											className="row-title link"
											onClick={() => selectQuestionnaireCompletion(questionnaireCompletion)}>
											{questionnaireCompletion.created_at ? format(questionnaireCompletion.created_at, "dd-MM-y") : "-"}
										</a>
									)}
								/>

								{overviewData.participant_column_available && (
									<DataColumn
										width={3}
										renderHeaderContent={() =>
											uctrans("questionnaires.questionnaire_completion.fields.participant_label")
										}
										renderCellContent={questionnaireCompletion => {
											if (questionnaireCompletion.participant_label) {
												return questionnaireCompletion.participant_url ? (
													<a className="link" href={questionnaireCompletion.participant_url}>
														{questionnaireCompletion.participant_label}{" "}
													</a>
												) : (
													questionnaireCompletion.participant_label
												);
											}
											return "-";
										}}
									/>
								)}

								{overviewData.company_column_available && (
									<DataColumn
										width={3}
										renderHeaderContent={() => uctrans("questionnaires.questionnaire_completion.fields.company_name")}
										renderCellContent={questionnaireCompletion => {
											if (questionnaireCompletion.company_name) {
												return questionnaireCompletion.company_name;
											}
											return "-";
										}}
									/>
								)}

								{overviewData.company_column_available && (
									<DataColumn
										width={3}
										renderHeaderContent={() => uctrans("questionnaires.questionnaire_completion.fields.company_number")}
										renderCellContent={questionnaireCompletion => {
											if (questionnaireCompletion.company_number) {
												return questionnaireCompletion.company_number;
											}
											return "-";
										}}
									/>
								)}

								{overviewData.label_column_available && (
									<DataColumn
										width={3}
										renderHeaderContent={() => uctrans("questionnaires.questionnaire_completion.fields.label")}
										renderCellContent={questionnaireCompletion => {
											if (questionnaireCompletion.label) {
												return questionnaireCompletion.label;
											}
											return "-";
										}}
									/>
								)}

								<DataColumn
									width={2}
									sortable="status"
									renderHeaderContent={() => uctrans("questionnaires.questionnaire_completion.fields.status")}
									renderCellContent={questionnaireCompletion =>
										questionnaireCompletion.status_label
											? questionnaireCompletion.status_label
											: uctrans("general.unknown")
									}
								/>
								{can("questionnaire.questionnaire", "delete") ? (
									<DataColumn
										width={1}
										renderHeaderContent={() => ""}
										renderCellContent={(questionnaireCompletion, overview) => (
											<div className="overview-actions ">
												<a onClick={() => deleteQuestionnaireCompletion(questionnaireCompletion, overview)}>
													<Tippy
														placement="right"
														arrow
														animation="perspective"
														duration="400"
														content={uctrans("general.delete")}>
														<span>
															<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
														</span>
													</Tippy>
												</a>
											</div>
										)}
									/>
								) : (
									<DataColumn width={1} renderHeaderContent={() => ""} renderCellContent={() => ""} />
								)}
							</DataTable>
						)}
						renderNoResults={() => (
							<Translate
								content="overview.no_:items_to_show"
								transReplaces={{ items: "questionnaires.questionnaire_completion.plural" }}
							/>
						)}
						renderDeleteDialog={overview => (
							<DeleteDialog
								onConfirm={() => handleDialogSubmit(overview)}
								onCancel={() => setOverviewData({ ...overviewData, dialogOpen: false })}
								isOpen={overviewData.dialogOpen}
								deleteData={overviewData.resultData}
							/>
						)}
						renderFooter={() =>
							can("questionnaire.questionnaire", "update") ? (
								<a
									className="button button-primary"
									href={route("crm.questionnaire_completions.download-csv", {
										questionnaire: overviewData.questionnaire,
									})}>
									<EvaIcon type="download-outline" height="16" width="25" fill="#25282e" />
									{uctrans("questionnaires.export_to_csv")}
								</a>
							) : (
								""
							)
						}
					/>
				</>
			)}
			{activeQuestionnaireCompletion && (
				<QuestionnaireCompletionForm
					readOnly={window.data.read_only}
					questionnaire={overviewData.questionnaire}
					questionnaireCompletion={activeQuestionnaireCompletion}
					questionnaireCompletionChanged={handleQuestionnaireCompletionChanged}
					questionnaireCompletionStatusses={overviewData.questionnaire_completion_statusses}
				/>
			)}
		</>
	);
}
