import { route, can } from "../../../../../js/helpers";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import Select from "../../../../../js/react/components/general/Select";

export default function AccountSupportActions() {
	const accountSupportActions = [];

	if (can("agenda.followup_action", "create")) {
		accountSupportActions.push({
			value: route("crm.followup-actions.create"),
			label: uctrans("general.add_:item", {}, { item: "followupactions.singular" }),
		});
	}

	if (can("agenda.provisioncorrection", "create")) {
		accountSupportActions.push({
			value: route("crm.provision-corrections.create"),
			label: uctrans("general.add_:item", {}, { item: "provisioncorrections.singular" }),
		});
	}

	if (can("intermediary", "create")) {
		accountSupportActions.push({
			value: route("crm.takeon-intermediary.create"),
			label: uctrans("general.add_:item", {}, { item: "agendaitems.appointment_request_intermediary" }),
		});
	}

	if (can("organisation", "create")) {
		accountSupportActions.push({
			value: route("crm.takeon-organisation.create"),
			label: uctrans("general.add_:item", {}, { item: "agendaitems.appointment_request_organisation" }),
		});
	}

	if (can("agenda.cooperation_termination", "create")) {
		accountSupportActions.push({
			value: route("crm.cooperation-terminations.create"),
			label: uctrans("general.add_:item", {}, { item: "cooperation_terminations.singular" }),
		});
	}

	return accountSupportActions.length ? (
		<div style={{ width: 300 }}>
			<Select
				options={accountSupportActions}
				onChange={({ value }) => Nav.go(value)}
				placeholder={uctrans("general.select_:item", {}, { item: "general.action" })}
				isClearable={false}
			/>
		</div>
	) : null;
}
