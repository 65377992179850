import { useState } from "react";
import { parse } from "../../../../../js/lib/Date";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import Select from "../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Translate from "../../../../../js/react/components/general/Translate";
import useDateHandler from "../../../../../js/react/hooks/useDateHandler";
import useGenericHandler from "../../../../../js/react/hooks/useGenericHandler";
import { route } from "../../../../../js/helpers";
import FileInput from "../forms/FileInput";

export default function DataimportForm() {
	const [formData, setFormData] = useState({ ...window.data.dataimport.form_data });
	const [type, setType] = useState(null);
	const staticData = { ...window.data.dataimport.static_data };
	const genericHandler = useGenericHandler(setFormData);
	const dateHandler = useDateHandler(setFormData);

	const typeHandler = selectedValue => {
		let newValue = null;

		if (selectedValue !== null) {
			newValue = selectedValue.value;
		} else {
			newValue = "";
		}
		setFormData(state => ({
			...state,
			type: newValue,
		}));
		setType(selectedValue);
	};

	const onSuccess = () => {
		window.location.reload();
	};

	return (
		<AjaxForm
			method="POST"
			submitUrl={route("crm.dataimports.store")}
			loaderText={uctrans("general.:item_is_being_saved", {}, { item: "dataimports.singular" })}
			successText={uctrans("general.saved_:item", {}, { item: "dataimports.singular" })}
			onSuccess={onSuccess}
			data={{ ...formData }}
			useFlashMessage>
			<h3>{uctrans("dataimports.deliver_file")}</h3>
			<div>
				<label htmlFor="type">
					<Translate content="dataimports.fields.type" /> <span className="text-orange"> *</span>
				</label>
				<Select
					placeholder={uctrans("general.select_:item", {}, { item: "dataimports.fields.type" })}
					value={staticData.types.filter(({ value }) => Number(value) === Number(formData.type))}
					options={staticData.types}
					name="type"
					onChange={typeHandler}
				/>
			</div>
			{type && (
				<>
					<div>
						<label htmlFor="file">
							{uctrans("dataimports.fields.inputfile")} ({type.label})<span className="text-orange"> *</span>
						</label>
						<FileInput maxFiles={1} onChange={files => genericHandler(files, "file")} />
					</div>
					{type.useReferenceDate && (
						<div>
							<label htmlFor="reference_date">
								{uctrans("dataimports.fields.reference_date")}
								<span className="text-orange"> *</span>
							</label>
							<DatePicker
								name="reference_date"
								label="dataimports.reference_date"
								initialValue={formData.reference_date ? parse(formData.reference_date) : ""}
								required
								onChange={date => dateHandler(date, "reference_date")}
							/>
						</div>
					)}
					<SubmitBar
						translateLabel={false}
						buttonContent={uctrans("dataimports.upload_selected_file")}
						addAnotherAvailable={false}
					/>
				</>
			)}
		</AjaxForm>
	);
}
