import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import map from "lodash/map";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import Dialog from "../../../../../../../js/react/components/general/Dialog";
import { CmsTypesContext } from "../../cms-types-context";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TableRow from "./TableRow";

export default function TableRowList(props) {
	const [rows, setRows] = useState(props.values);
	const [openRow, setOpenRow] = useState(null);

	const { partTypes } = useContext(CmsTypesContext);
	const listPartType = find(partTypes, partType => partType.key === "table_row");

	const changeOpenRowCellValues = values => {
		if (openRow !== null) {
			const newOpenRow = { ...openRow };
			newOpenRow.values = { cells: values };
			setOpenRow(newOpenRow);
		}
	};

	const openEditDialog = (index, label, values) => {
		setOpenRow({
			creating: false,
			index,
			label,
			values: cloneDeep(values),
		});
	};
	const openCreateDialog = () => {
		setOpenRow({
			creating: true,
			index: -1,
			label: uctrans("general.add_:item", { item: listPartType.label }),
			values: { cells: [] },
		});
	};
	const openCopyDialog = values => {
		setOpenRow({
			creating: true,
			index: -1,
			label: uctrans("general.add_:item", { item: listPartType.label }),
			values,
		});
	};

	const handleDialogSave = () => {
		if (openRow !== null) {
			const newRows = Array.isArray(rows) ? [...rows] : [];
			if (openRow.creating) {
				newRows.push(openRow.values);
			} else {
				newRows[openRow.index] = openRow.values;
			}
			setRows(newRows);
			props.onChange(newRows);
			setOpenRow(null);
		}
	};
	const handleDialogCancel = () => {
		setOpenRow(null);
	};

	const deleteRow = index => {
		const newRows = Array.isArray(rows) ? [...rows] : [];
		newRows.splice(index, 1);
		setRows(newRows);
		props.onChange(newRows);
	};

	const moveRow = dragEvent => {
		if (dragEvent.destination) {
			const sourceIndex = dragEvent.source.index;
			const targetIndex = dragEvent.destination.index;

			const newRows = Array.isArray(rows) ? cloneDeep(rows) : [];

			if (`${sourceIndex}` !== `${targetIndex}`) {
				const item = newRows[sourceIndex];
				newRows.splice(sourceIndex, 1);
				newRows.splice(targetIndex, 0, item);

				setRows(newRows);
				props.onChange(newRows);
			}
		}
	};

	if (listPartType) {
		return (
			<div className="w-fill mt-5 mb-12">
				{props.label && <h5>{props.label}</h5>}
				<div>
					<DragDropContext onDragEnd={dragEvent => moveRow(dragEvent)}>
						<Droppable droppableId="droppable">
							{provided => (
								<div ref={provided.innerRef} {...provided.droppableProps}>
									{(() =>
										map(rows, (values, index) => (
											<Draggable key={index} index={index} draggableId={index.toString()}>
												{provided => (
													<div
														{...provided.dragHandleProps}
														ref={provided.innerRef}
														{...provided.draggableProps}
														className="bg-primary-lightest items-center px-3 py-3 my-2 flex justify-between">
														<div className="flex items-center">
															<EvaIcon
																className="flex items-center"
																type="move-outline"
																width="20"
																height="20"
																fill="#009286"
															/>
															<span className="ml-3">
																{listPartType.label} {index + 1}
															</span>
														</div>
														<div className="flex items-center">
															{props.nrOfColumns > 0 && props.nrOfColumns <= 10 && (
																<span
																	className="cursor-pointer"
																	onClick={() => {
																		openEditDialog(
																			index,
																			uctrans("general.edit_:item", { item: listPartType.label }),
																			values,
																		);
																	}}>
																	<EvaIcon
																		className="flex items-center"
																		type="edit-outline"
																		width="20"
																		height="20"
																		fill="#009286"
																	/>
																</span>
															)}
															<span
																className="cursor-pointer ml-3"
																onClick={() => {
																	openCopyDialog(values);
																}}>
																<EvaIcon
																	className="flex items-center"
																	type="copy-outline"
																	width="20"
																	height="20"
																	fill="#009286"
																/>
															</span>
															<span
																className="cursor-pointer ml-3"
																onClick={() => {
																	deleteRow(index);
																}}>
																<EvaIcon
																	className="flex items-center"
																	type="trash-2-outline"
																	width="20"
																	height="20"
																	fill="#009286"
																/>
															</span>
														</div>
													</div>
												)}
											</Draggable>
										)))()}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
				{props.nrOfColumns > 0 && props.nrOfColumns <= 10 && (
					<button type="button" className="button button-secondary" onClick={openCreateDialog}>
						{uctrans("general.add_:item", { item: listPartType.label })}
					</button>
				)}
				<Dialog
					isOpen={openRow !== null}
					onClose={handleDialogCancel}
					shouldCloseOnOverlayClick={false}
					width={850}
					title={openRow && openRow.label ? openRow.label : ""}>
					{openRow !== null && (
						<>
							<TableRow
								tableType={props.tableType}
								values={openRow.values ? openRow.values.cells : []}
								onChange={cellValues => {
									changeOpenRowCellValues(cellValues);
								}}
								nrOfColumns={props.nrOfColumns}
							/>
							<div className="mt-6">
								<button type="button" className="button button-primary button-dialog" onClick={handleDialogSave}>
									{uctrans("general.ok")}
								</button>
								<a onClick={handleDialogCancel}>{uctrans("general.cancel")}</a>
							</div>
						</>
					)}
				</Dialog>
			</div>
		);
	}
}

TableRowList.propTypes = {
	tableType: PropTypes.oneOf(["table", "content_table"]).isRequired,
	label: PropTypes.string,
	values: PropTypes.arrayOf(
		PropTypes.shape({
			cells: PropTypes.arrayOf(
				PropTypes.shape({
					content: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number, PropTypes.string]),
				}),
			),
		}),
	),
	onChange: PropTypes.func.isRequired,
	nrOfColumns: PropTypes.number,
};

TableRowList.defaultProps = {
	nrOfColumns: 0,
};
