import { useState } from "react";
import { route } from "../../../../../../js/helpers";
import Nav from "../../../../../../js/lib/Nav";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import AutosizeTextarea from "../../../../../../js/react/components/general/form/AutosizeTextarea";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../../js/react/components/general/Translate";
import useInputHandler from "../../../../../../js/react/hooks/useInputHandler";
import Navigation from "../SettingsNavigation";

export default function NewsletterUnsubscribeReasonForm() {
	const [formData, setFormData] = useState({ ...window.data.form_values });
	const creating = window.data.form_values.id === -1;
	const inputHandler = useInputHandler(setFormData);

	const onSuccess = () => {
		Nav.go(route("crm.communication-settings.newsletter-unsubscribe-reasons.index"));
	};

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	return (
		<>
			<Navigation selectedTab="newsletterUnsubscribeReasons" />

			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(
						creating ? "general.add_:item" : "general.edit_:item",
						{},
						{ item: "communication.newsletters.unsubscribe_reasons.singular" },
					)}
				</h1>

				<a className="link" href={route("crm.communication-settings.newsletter-unsubscribe-reasons.index")}>
					{uctrans(
						"general.back_to_overview_:page",
						{},
						{ page: "communication.newsletters.unsubscribe_reasons.plural" },
					)}
				</a>
			</div>

			<AjaxForm
				method={creating ? "POST" : "PUT"}
				submitUrl={
					creating
						? route("crm.communication-settings.newsletter-unsubscribe-reasons.store")
						: route("crm.communication-settings.newsletter-unsubscribe-reasons.update", formData.id)
				}
				loaderText={uctrans(
					"general.:item_is_being_saved",
					{},
					{ item: "communication.newsletters.unsubscribe_reasons.singular" },
				)}
				successText={uctrans(
					"general.saved_:item",
					{},
					{ item: "communication.newsletters.unsubscribe_reasons.singular" },
				)}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="title"
										value={formData.title ? formData.title : ""}
										onChange={inputHandler}
										label="communication.newsletters.unsubscribe_reasons.fields.title"
										required
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-3">
									<label htmlFor="description">
										<Translate content="communication.newsletters.unsubscribe_reasons.fields.description" />
									</label>
									<AutosizeTextarea
										id="description"
										name="description"
										value={formData.description}
										onChange={e => handleFormValueChange(e.target.name, e.target.value)}
									/>
								</div>
							</div>

							<div className="form-full">
								<Checkmark
									onChange={inputHandler}
									name="is_active"
									checked={formData.is_active}
									label={uctrans("communication.newsletters.unsubscribe_reasons.fields.active")}
								/>
							</div>

							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar
										item="communication.newsletters.unsubscribe_reasons.singular"
										addAnotherAvailable={false}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
