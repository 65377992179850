import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";
import { useState } from "react";
import { format } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import { route } from "../../../../../js/helpers";

export default function NotificationsBlock(props) {
	const [isCollapsed, setCollapsed] = useState(window.data.collapsed_dashboard_blocks.notifications_block === "1");

	return (
		<div className="vertical-spacer spacer flex">
			<div className="bg-grey-lightest shadow rounded flex flex-col w-full">
				<div className="p-6 flex">
					<a
						className="no-underline text-grey-darker w-full cursor"
						onClick={() => {
							const collapsed = !isCollapsed;
							if (props.rememberCollapsed) {
								props.rememberCollapsed("notifications_block", collapsed);
							}
							setCollapsed(collapsed);
						}}>
						<div className="w-full flex justify-between">
							<div>
								<h5 className="mb-0">
									{uctrans("notifications.recent_notifications")} ({props.notifications.length})
								</h5>
							</div>
							<div>
								<EvaIcon fill="#000000" width="18" height="18" type={isCollapsed ? "plus" : "minus"} />
							</div>
						</div>
					</a>
				</div>
				{!isCollapsed && (
					<div className="p-6 w-full">
						<div>
							<ul>
								<li className="flex font-bold mb-3">
									<div className="w-4/12">{uctrans("notifications.fields.datetime")}</div>
									<div className="w-8/12">{uctrans("notifications.fields.message")}</div>
								</li>
								{props.notifications.map((notification, key) => (
									<li className="flex mb-3" key={key}>
										<div className="w-4/12">
											{notification.datetime ? format(notification.datetime, "dd-MM-y HH:mm") : "-"}
										</div>
										<div className="w-7/12">{notification.title}</div>
										<div className="w-1/12">
											{!!notification.content && (
												<Tippy
													placement="top"
													arrow
													animation="perspective"
													duration="400"
													content={<div dangerouslySetInnerHTML={{ __html: notification.content }} />}>
													<span>
														<EvaIcon fill="#249286" width="18" height="18" type="info-outline" />
													</span>
												</Tippy>
											)}
										</div>
									</li>
								))}
							</ul>
						</div>
						<a href={route("crm.notifications.index")} rel="noreferrer noopener" className="link">
							{uctrans("notifications.show_all_notifications")}
						</a>
					</div>
				)}
			</div>
		</div>
	);
}

NotificationsBlock.propTypes = {
	rememberCollapsed: PropTypes.func,
	notifications: PropTypes.array,
};

NotificationsBlock.defaultProps = {
	notifications: [],
};
