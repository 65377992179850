import Dialog from "../../../../../../js/react/components/general/Dialog";
import { createLoader, error } from "../../../../../../js/react/components/general/notifications";
import axios from "axios";
import filter from "lodash/filter";
import map from "lodash/map";
import find from "lodash/find";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";
import kebabCase from "lodash/kebabCase";
import forEach from "lodash/forEach";
import upperFirst from "lodash/upperFirst";
import { Component } from "react";
import { can, route } from "../../../../../../js/helpers";
import { format, parse } from "../../../../../../js/lib/Date";
import Nav from "../../../../../../js/lib/Nav";
import ConfirmDialog from "../../../../../../js/react/components/general/ConfirmDialog";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import Translate from "../../../../../../js/react/components/general/Translate";
import { trans, uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import Select from "../../../../../../js/react/components/general/Select";
import DatePicker from "../../../../../../js/react/components/general/DatePicker";
import Attribute from "../general/attributes/Attribute";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import PartContent from "../general/parts/PartContent";
import { CmsTypesContext } from "../cms-types-context";
import Radio from "../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../js/react/components/general/form/radio/RadioGroup";
import PageAliasForm from "./page_alias/PageAliasForm";

class PageForm extends Component {
	constructor(props) {
		super(props);

		this.id = window.data.page_data.pagevalues.id;
		this.title = window.data.page_data.pagevalues.title;
		this.staticvalues = window.data.page_data.staticvalues;
		this.state = {
			pageType: find(
				this.staticvalues.cmsTypes.pageTypes,
				pageType => pageType.key === window.data.page_data.pagevalues.page_type,
			),
			pagevalues: window.data.page_data.pagevalues,
			selectedAlias: null,
			currentAliasIndex: null,
		};
		this.pageTypeOptions = map(
			filter(
				this.staticvalues.cmsTypes.pageTypes,
				pageType =>
					!pageType.only_one ||
					!some(
						this.staticvalues.cmsTypes.pageOptions,
						pageOption => pageOption.page_type === pageType.key && pageOption.value !== this.state.pagevalues.id,
					),
			),
			pageType => ({ value: pageType.key, label: pageType.label, pageType }),
		);

		this.handlePageTypeChange = this.handlePageTypeChange.bind(this);
		this.updatePagevalue = this.updatePagevalue.bind(this);
		this.handleContentChange = this.handleContentChange.bind(this);
		this.onSuccess = this.onSuccess.bind(this);
		this.generateUrl = this.generateUrl.bind(this);

		this.openAliasDialog = this.openAliasDialog.bind(this);
		this.closeAliasDialog = this.closeAliasDialog.bind(this);
		this.openDeleteConceptDialog = this.openDeleteConceptDialog.bind(this);
		this.openPublishConceptDialog = this.openPublishConceptDialog.bind(this);
	}

	handlePageTypeChange(selectedValue) {
		this.setState({
			pageType: selectedValue.pageType,
		});
		const newPagevalues = { ...this.state.pagevalues };
		newPagevalues.page_type = selectedValue.value;
		if (selectedValue.pageType.login_required) {
			newPagevalues.login_required = 1;
		}
		this.setState({
			pagevalues: newPagevalues,
		});
	}

	updatePagevalue(property, value) {
		const newPagevalues = { ...this.state.pagevalues };
		newPagevalues[property] = value;
		this.setState({
			pagevalues: newPagevalues,
		});
	}

	handleChangeAlias(alias) {
		const tempAliases = [...this.state.pagevalues.aliases];
		let currentIndex = this.state.currentAliasIndex;

		if (currentIndex === null) {
			currentIndex = tempAliases.length;
		}

		tempAliases[currentIndex] = alias;
		this.updatePagevalue("aliases", tempAliases);
	}

	removeAlias(alias) {
		let currentAliases = [...this.state.pagevalues.aliases];
		if (currentAliases.some(e => e.slug === alias.slug)) {
			currentAliases = currentAliases.filter(item => item.slug !== alias.slug);
			this.updatePagevalue("aliases", currentAliases);
		}
	}

	handleContentChange(attributeKey, values) {
		const newContentValues = { ...this.state.pagevalues.contents };
		newContentValues[attributeKey] = values;
		this.updatePagevalue("contents", newContentValues);
	}

	onSuccess(response) {
		if (!this.staticvalues.creating) {
			this.setState({
				pagevalues: response.data.page_data.pagevalues,
			});
		} else {
			Nav.go(response.data.redirect_route);
		}
	}

	openDeleteConceptDialog() {
		this.setState({
			confirmData: {
				description: uctrans("general.confirm_delete_:item", {}, { item: "cms.pages.concept" }),
				onConfirm: async () => {
					const loader = createLoader(
						uctrans("general.:item_is_being_deleted", {}, { item: "cms.pages.concept" }),
						null,
						7e7,
						1,
					);
					try {
						await axios.delete(route(`crm.cms.pages.destroy_concept`, this.id));
						Nav.go(route(`crm.cms.pages.edit`, this.id));
					} catch (error) {
						console.error(error);
						loader.failure(error.message);
					}
				},
			},
		});
	}

	openPublishConceptDialog() {
		this.setState({
			confirmData: {
				description: uctrans("cms.pages.confirm_publish_concept"),
				onConfirm: async () => {
					const loader = createLoader(uctrans("cms.pages.concept_is_being_published"), null, 7e7, 1);
					try {
						await axios.put(route(`crm.cms.pages.publish-concept`, this.id), this.state.pagevalues);
						Nav.go(route(`crm.cms.pages.edit`, this.id));
					} catch (e) {
						if (e.response && e.response.status === 422) {
							if (Object.prototype.hasOwnProperty.call(e.response.data, "errors")) {
								let first = true;
								forEach(e.response.data.errors, message => {
									if (first && loader) {
										loader.failure(upperFirst(message[0]));
										first = false;
									} else {
										error(upperFirst(message[0]));
									}
								});
							}
						} else {
							loader.failure(e.message);
						}
					}
				},
			},
		});
	}

	openAliasDialog(selectedAlias) {
		this.setState({ selectedAlias, openAliasDialog: true });
	}

	closeAliasDialog() {
		this.setState({ openAliasDialog: false });
	}

	generateUrl() {
		this.updatePagevalue("slug", kebabCase(`${this.state.pagevalues.title}`));
	}

	render() {
		return (
			<>
				<BreadCrumbs
					breadCrumbs={[
						{ label: uctrans("general.website") },
						{ label: uctrans("cms.pages.plural"), route: route("crm.cms.pages.index") },
						{
							label: this.staticvalues.creating
								? uctrans("general.add_:item", {
										item: this.staticvalues.is_copy
											? trans("general.copy_of_:item", {}, { item: "cms.pages.singular" })
											: trans("cms.pages.singular"),
								  })
								: this.title,
						},
					]}
				/>
				{!this.staticvalues.creating && (
					<ul className="flex tabs shadow -mt-6">
						<li className={this.staticvalues.is_concept ? "" : "active"}>
							<a href={route("crm.cms.pages.edit", [this.id])}>{uctrans("cms.pages.live_version")}</a>
						</li>
						{!!this.staticvalues.has_concept && (
							<li className={this.staticvalues.is_concept ? "active" : ""}>
								<a href={route("crm.cms.pages.edit-concept", [this.id])}>{uctrans("cms.pages.concept_version")}</a>
							</li>
						)}
						<li>
							<a href={route("crm.cms.pages.edit-az", [this.id])}>{uctrans("cms.pages.az")}</a>
						</li>
						<li className="ml-auto">
							<ul>
								{!!this.staticvalues.has_concept && !!this.staticvalues.is_concept && (
									<>
										<li className="tabs-button">
											<a
												className="button button-concept"
												href={route("crm.cms.pages.preview-concept", [this.id])}
												target="_blank"
												rel="noopener noreferrer">
												<EvaIcon
													className="inline-flex items-center mr-1"
													type="flash"
													width="14"
													height="14"
													fill="#3490dc"
												/>
												{uctrans("cms.pages.preview_concept")}
											</a>
										</li>
										<li className="tabs-button">
											<a className="button button-delete" onClick={this.openDeleteConceptDialog}>
												<EvaIcon
													className="inline-flex items-center mr-1"
													type="trash-2"
													width="14"
													height="14"
													fill="#e3342f"
												/>
												{uctrans("general.delete_:item", {}, { item: "cms.pages.concept" })}
											</a>
										</li>
									</>
								)}
							</ul>
						</li>
						<li className="ml-auto tabs-button">
							<div className="flex flex-row">
								<a
									rel="noopener noreferrer"
									className="button button-concept"
									href={route("crm.cms.pages.invalid_links", this.id)}
									target="_blank">
									<EvaIcon
										className="inline-flex items-center mr-1"
										type="link-2"
										width="14"
										height="14"
										fill="#F9A8B4"
									/>
									{uctrans("cms.pages.invalid_links.plural")}
								</a>

								{!this.staticvalues.has_concept && (
									<a href={route("crm.cms.pages.create-concept", [this.id])} className="button button-concept">
										<EvaIcon
											className="inline-flex items-center mr-1"
											type="plus"
											width="14"
											height="14"
											fill="#3490dc"
										/>
										{uctrans("cms.pages.create_concept")}
									</a>
								)}
								{can("cms.page", "view") && (
									<>
										<a
											rel="noopener noreferrer"
											className="button button-concept"
											href={route("crm.cms.pages.preview", this.id)}
											target="_blank">
											<EvaIcon
												className="inline-flex items-center mr-1"
												type="flash"
												width="14"
												height="14"
												fill="#3490dc"
											/>
											{uctrans("cms.pages.preview")}
										</a>
										<a
											rel="noopener noreferrer"
											className="button button-concept"
											href={this.staticvalues.url}
											target="_blank">
											<EvaIcon
												className="inline-flex items-center mr-1"
												type="external-link"
												width="14"
												height="14"
												fill="#2B9286"
											/>
											{uctrans("cms.pages.go_to_page")}
										</a>
									</>
								)}
							</div>
						</li>
					</ul>
				)}
				<div className="page-title-container">
					<h1 className="page-title">
						{uctrans(this.staticvalues.creating ? "general.add_:item" : "general.edit_:item", {
							item: this.staticvalues.creating
								? this.staticvalues.is_copy
									? trans("general.copy_of_:item", {}, { item: "cms.pages.singular" })
									: trans("cms.pages.singular")
								: trans(this.staticvalues.is_concept ? "cms.pages.concept" : "cms.pages.singular"),
						})}
					</h1>
					<a className="link" href={route("crm.cms.pages.index")}>
						{uctrans("general.back_to_overview_:page", {}, { page: "cms.pages.plural" })}
					</a>
				</div>
				{this.staticvalues.is_concept && this.state.pagevalues.concept_last_updated_by_user && (
					<div className="form-full">
						<ul>
							<li>
								<span className="font-bold">{uctrans("cms.pages.last_updated_by")}:</span>{" "}
								{this.state.pagevalues.concept_last_updated_by_user &&
									this.state.pagevalues.concept_last_updated_by_user.full_name}{" "}
								<span className="font-bold">{trans("general.at")}:</span>{" "}
								{format(this.state.pagevalues.concept_updated_at, "dd-MM-y HH:mm")}
							</li>
						</ul>
					</div>
				)}
				<AjaxForm
					method={this.staticvalues.creating ? "POST" : "PUT"}
					submitUrl={
						this.staticvalues.creating
							? route("crm.cms.pages.store")
							: this.staticvalues.is_concept
							  ? route("crm.cms.pages.update-concept", this.id)
							  : route("crm.cms.pages.update", this.id)
					}
					loaderText={uctrans(
						"general.:item_is_being_saved",
						{},
						{ item: this.staticvalues.is_concept ? "cms.pages.concept" : "cms.pages.singular" },
					)}
					successText={uctrans(
						"general.saved_:item",
						{},
						{ item: this.staticvalues.is_concept ? "cms.pages.concept" : "cms.pages.singular" },
					)}
					onSuccess={this.onSuccess}
					data={this.state.pagevalues}>
					{!this.staticvalues.is_concept && (
						<div className="max-w-2xl mb-12">
							<div className="grid-container">
								<div className="grid-1-2">
									<LabeledTextInput
										name="title"
										label="cms.pages.fields.title"
										value={this.state.pagevalues.title}
										onChange={e => {
											this.updatePagevalue("title", e.target.value);
										}}
										required
									/>
								</div>
								<div className="form-1-2">
									<label htmlFor="role_id">
										<Translate content="cms.pages.fields.page_type" /> <span className="text-orange"> *</span>
									</label>
									{this.staticvalues.creating ? (
										<Select
											name="page_type"
											value={this.pageTypeOptions.filter(({ value }) => value === this.state.pagevalues.page_type)}
											onChange={this.handlePageTypeChange}
											label="pages.fields.page_type"
											options={this.pageTypeOptions}
											required
											isClearable={false}
										/>
									) : (
										<p>{this.pageTypeOptions.find(({ value }) => value === this.state.pagevalues.page_type).label}</p>
									)}
								</div>
							</div>
							<div className="form-container">
								<div className="form-1-3">
									<label htmlFor="name">
										{uctrans("cms.pages.fields.slug")}
										<span className="text-orange"> *</span>
									</label>
									<input
										id="slug"
										type="text"
										name="slug"
										value={this.state.pagevalues.slug}
										placeholder={`${this.staticvalues.base_url}/...`}
										onChange={e => {
											this.updatePagevalue("slug", e.target.value);
										}}
									/>
									<a className="link" onClick={this.generateUrl}>
										<small>{uctrans("cms.pages.fields.generate_url")}</small>
									</a>
								</div>

								<div className="form-1-3">
									<label htmlFor="selected_menuitem_id">
										<Translate content="cms.pages.fields.selected_menuitem" />
									</label>
									<Select
										id="selected_menuitem_id"
										name="selected_menuitem_id"
										value={this.staticvalues.menuitems.filter(
											({ value }) => `${value}` === `${this.state.pagevalues.selected_menuitem_id}`,
										)}
										onChange={selectedOption => {
											this.updatePagevalue("selected_menuitem_id", selectedOption ? selectedOption.value : null);
										}}
										options={this.staticvalues.menuitems}
									/>
								</div>
							</div>

							<div className="form-container">
								<div className="form-1-3">
									<label htmlFor="name">{uctrans("cms.pages.page_aliases.plural")}</label>
									{this.state.pagevalues.aliases &&
										this.state.pagevalues.aliases.map((alias, index) => (
											<div className="flex w-full mt-3" key={index}>
												<span className="flex w-1/2">{alias.slug}</span>
												<span className="flex w-1/2">
													<span
														className="cursor-pointer"
														onClick={() => {
															this.openAliasDialog(alias);
															this.setState({ currentAliasIndex: index });
														}}>
														<EvaIcon
															className="flex items-center"
															type="edit-outline"
															width="20"
															height="20"
															fill="#009286"
														/>
													</span>
													<span
														className="cursor-pointer ml-2"
														onClick={() => {
															this.removeAlias(alias);
														}}>
														<EvaIcon
															className="flex items-center"
															type="trash-2-outline"
															width="20"
															height="20"
															fill="#009286"
														/>
													</span>
												</span>
											</div>
										))}
								</div>
								<Dialog
									isOpen={!!this.state.openAliasDialog}
									title={
										this.state.currentAliasIndex === null
											? uctrans("cms.pages.page_aliases.alias_dialog")
											: uctrans("cms.pages.page_aliases.alias_dialog_edit")
									}
									onClose={this.closeAliasDialog}>
									<PageAliasForm
										onSuccess={alias => {
											this.handleChangeAlias(alias);
											this.closeAliasDialog();
										}}
										currentAlias={this.state.selectedAlias}
									/>
								</Dialog>
							</div>

							<div className="form-container">
								<div className="form-1-3-">
									<div className="form-1-3">
										<button
											type="button"
											className="button button-primary button-dialog"
											onClick={() => {
												this.openAliasDialog(null);
												this.setState({ currentAliasIndex: null });
											}}>
											{uctrans("cms.pages.page_aliases.alias_button")}
										</button>
									</div>
								</div>
								<div className="form-1-3">
									<label htmlFor="show_in_search">
										<Translate content="cms.pages.fields.show_in_search" /> <span className="text-orange"> *</span>
									</label>
									<RadioGroup
										name="show_in_search"
										horizontal
										value={this.state.pagevalues.show_in_search}
										onChange={value => this.updatePagevalue("show_in_search", value)}>
										<Radio label={uctrans("general.yes")} value />
										<Radio label={uctrans("general.no")} value={false} />
									</RadioGroup>
								</div>
							</div>

							<div className="form-container">
								<div className="form-1-3">
									<label htmlFor="login_required">
										<Translate content="cms.pages.fields.availability" /> <span className="text-orange"> *</span>
									</label>
									<RadioGroup
										name="login_required"
										value={this.state.pagevalues.login_required}
										onChange={value => this.updatePagevalue("login_required", value)}>
										{!this.state.pageType.login_required && (
											<Radio label={uctrans("cms.pages.fields.public")} value={0} />
										)}

										<Radio label={uctrans("cms.pages.fields.only_when_logged_in")} value={1} />
									</RadioGroup>
								</div>
								{!!this.state.pagevalues.login_required && (
									<div className="form-1-3">
										<label htmlFor="publish_status">
											<Translate content="cms.pages.fields.filter_profile" />
										</label>
										<Select
											id="filter_profile_id"
											name="filter_profile_id"
											value={this.staticvalues.filter_profiles.filter(
												({ value }) => `${value}` === `${this.state.pagevalues.filter_profile_id}`,
											)}
											onChange={selectedOption => {
												this.updatePagevalue("filter_profile_id", selectedOption ? selectedOption.value : null);
											}}
											options={this.staticvalues.filter_profiles}
										/>
									</div>
								)}
							</div>
							<div className="form-container">
								<div className="form-1-3">
									<label htmlFor="publish_status">
										<Translate content="cms.pages.fields.publish_status" /> <span className="text-orange"> *</span>
									</label>
									<Select
										id="publish_status"
										name="publish_status"
										value={this.staticvalues.publish_statuses.filter(
											({ value }) => value === this.state.pagevalues.publish_status,
										)}
										onChange={selectedOption => {
											this.updatePagevalue("publish_status", selectedOption.value);
										}}
										options={this.staticvalues.publish_statuses}
										required
										isClearable={false}
									/>
								</div>
								{this.state.pagevalues.publish_status === 3 && (
									<div className="form-2-3">
										<div className="flex ">
											<div>
												<label htmlFor="published_from">
													<Translate content="cms.pages.fields.published_from" />
												</label>
												<DatePicker
													initialValue={
														this.state.pagevalues.published_from ? parse(this.state.pagevalues.published_from) : null
													}
													onChange={date => {
														this.updatePagevalue("published_from", date ? format(date) : null);
													}}
													showTimeSelect
												/>
											</div>
											<div className="ml-2">
												<label htmlFor="published_to">
													<Translate content="cms.pages.fields.published_to" />
												</label>
												<DatePicker
													initialValue={
														this.state.pagevalues.published_to ? parse(this.state.pagevalues.published_to) : null
													}
													onChange={date => this.updatePagevalue("published_to", date ? format(date) : null)}
													showTimeSelect
												/>
											</div>
										</div>
									</div>
								)}
							</div>
							{this.staticvalues.marketing_themes && (
								<div className="form-container">
									<div className="form-1-3">
										<label htmlFor="marketing_theme_id">
											<Translate content="marketing.themes.singular" />
										</label>
										<Select
											name="marketing_theme_id"
											value={this.staticvalues.marketing_themes.filter(
												({ value }) =>
													Number(value) ===
													Number(
														this.state.pagevalues.marketing_theme_id ? this.state.pagevalues.marketing_theme_id : null,
													),
											)}
											onChange={selectedOption => {
												this.updatePagevalue("marketing_theme_id", selectedOption ? selectedOption.value : null);
											}}
											label="marketing.themes.singular"
											options={this.staticvalues.marketing_themes}
										/>
									</div>
								</div>
							)}
						</div>
					)}
					{this.state.pageType && (
						<CmsTypesContext.Provider value={this.staticvalues.cmsTypes}>
							{!isEmpty(this.state.pageType.attributes) && !this.staticvalues.is_concept && (
								<h2>{uctrans("cms.pages.fields.pagecontent")}</h2>
							)}
							<div className={`cms-attribute grid-container ${`${this.state.pageType.key}block`}`}>
								<div className="grid-full">
									{(() => {
										switch (this.state.pageType.key) {
											// Space for custom pagetypes
											default:
												return map(this.state.pageType.attributes, (attribute, key) => {
													const attributeValues =
														this.state.pagevalues.contents != null &&
														Object.prototype.hasOwnProperty.call(this.state.pagevalues.contents, key)
															? this.state.pagevalues.contents[key]
															: null;

													const headers = [
														"team_title",
														"team_contact_title",
														"team_name",
														"as_name",
														"as_head_title",
														"as_team_name",
													];

													if (attribute.type === "part") {
														return (
															<PartContent
																key={key}
																label={attribute.label}
																parttype={attribute.parttype}
																attribute={attribute}
																values={attributeValues}
																onChange={values => {
																	this.handleContentChange(key, values);
																}}
															/>
														);
													} else {
														return (
															<>
																{headers.map(
																	header =>
																		header === key && (
																			<p className="contact_block mt-2.5 font-semibold text-lg" key={key}>
																				{uctrans(`cms.attributes.${key}_header`)}
																			</p>
																		),
																)}
																<Attribute
																	key={key}
																	attribute={attribute}
																	values={attributeValues}
																	onChange={values => {
																		this.handleContentChange(key, values);
																	}}
																/>
															</>
														);
													}
												});
										}
									})()}
								</div>
							</div>
							<div>
								<div className="submit-bar">
									<div className="submit-container">
										<button className="button background-color-success button-primary" type="submit">
											{uctrans("general.save_:item", {
												item: this.staticvalues.creating
													? this.staticvalues.is_copy
														? trans("general.copy_of_:item", {}, { item: "cms.pages.singular" })
														: trans("cms.pages.singular")
													: trans(this.staticvalues.is_concept ? "cms.pages.concept" : "cms.pages.singular"),
											})}
										</button>
										{!!this.staticvalues.is_concept && (
											<button
												className="button background-color-success button-primary ml-2"
												type="button"
												onClick={this.openPublishConceptDialog}>
												{uctrans("cms.pages.save_concept_and_publish")}
											</button>
										)}
									</div>
								</div>
							</div>
						</CmsTypesContext.Provider>
					)}
				</AjaxForm>
				{!!this.state.confirmData && (
					<ConfirmDialog
						isOpen
						onConfirm={() => {
							this.state.confirmData.onConfirm();
							this.setState({ confirmData: null });
						}}
						onCancel={() => {
							this.setState({ confirmData: null });
						}}
						confirmButtonLabel={this.state.confirmData.confirmText}
						cancelButtonLabel={uctrans("general.cancel")}
						title={this.state.confirmData.title}>
						{this.state.confirmData.description}
					</ConfirmDialog>
				)}
			</>
		);
	}
}

export default PageForm;

PageForm.propTypes = {};
