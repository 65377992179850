import { useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DeleteColumnContent from "../../../../../js/react/components/general/overview/DeleteColumnContent";
import Translate from "../../../../../js/react/components/general/Translate";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import IconButton from "../../../../../js/react/components/general/IconButton";

export default function SegmentOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
		create_link: window.data.segment_create_link,
		index_link: window.data.segment_index_link,
		permissions: window.data.segment_permissions,
	});

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("general.general_settings") }]} />
			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="segments.plural" />
				</h1>
				{overviewData.create_link && (
					<div>
						<IconButton
							href={overviewData.create_link}
							content={uctrans("general.add_:item", {}, { item: "segments.singular" })}
							primary
						/>
					</div>
				)}
			</div>
			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={overviewData.index_link}
				modelTranslations={{ plural: uctrans("segments.plural"), singular: uctrans("segments.singular") }}
				useDragAndDrop
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={1}
							className="draggable-overview-item-handle"
							renderHeaderContent={() => ""}
							renderCellContent={() => ""}
						/>
						<DataColumn
							width={12}
							renderHeaderContent={() => uctrans("segments.fields.name")}
							renderCellContent={segment => (
								<a href={segment.edit_link} className="row-title link">
									{segment.name}
								</a>
							)}
						/>
						<DataColumn
							width={10}
							renderHeaderContent={() => uctrans("segments.fields.criterion")}
							renderCellContent={segment =>
								segment.criterion && (segment.criterion.label !== null) !== "undefined" ? segment.criterion.label : "-"
							}
						/>
						{overviewData.permissions.delete && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(segment, overview) =>
									segment.delete_link && <DeleteColumnContent onClick={() => overview.askDelete(segment)} />
								}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
