import PropTypes from "prop-types";
import { useContext } from "react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import CompanyDossierContext from "../CompanyDossierContext";
import DossierFileInput from "../fileInput/DossierFileInput";
import DossierPartOrganisationIsCentral from "./DossierPartOrganisationIsCentral";

export default function NoneFieldDossierPart({ partData, completionData }) {
	const { handleFormChange, readOnly } = useContext(CompanyDossierContext);

	return (
		<div className="w-full">
			<DossierPartOrganisationIsCentral completionData={completionData} />
			<div className="dossier-row">
				<label htmlFor="type">{uctrans("dossiers.fields.files")}</label>
				<DossierFileInput
					disabled={readOnly}
					existingFileDownloadRoute="crm.intermediaries.dossier.download-completion-file"
					initialFiles={completionData ? completionData.files.files : []}
					initialArchivedFiles={completionData ? completionData.files.archivedFiles : []}
					onChange={files => handleFormChange(partData.id, "files", files)}
				/>
			</div>
		</div>
	);
}

NoneFieldDossierPart.propTypes = {
	partData: PropTypes.object,
	completionData: PropTypes.object,
};
