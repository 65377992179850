import PropTypes from "prop-types";
import { useState } from "react";
import { can, route } from "../../../../../../js/helpers";
import Nav from "../../../../../../js/lib/Nav";
import { uctrans } from "../../../../../../js/lib/Translator";

export default function RelationManagerQuestionnaireTabs({ initialActiveTabIndex }) {
	const [activeTab] = useState(initialActiveTabIndex);

	const switchTab = url => {
		Nav.go(url);
	};

	const tabIsActive = tabIndex => (tabIndex === activeTab ? "active" : "");

	return (
		<div className="-mt-6">
			<ul className="flex tabs shadow -mx-12">
				{can("relation-manager-survey-questions") && (
					<li
						className={`tab-item ${tabIsActive(0)}`}
						onClick={() => switchTab(route("crm.surveys.relationmanager-questionnaire.index"), 0)}>
						<a className="tab-link">{uctrans("survey.singular")}</a>
					</li>
				)}

				<li className="tab-item">
					<a className="tab-link" />
				</li>
				<li
					className={`tab-item ${tabIsActive(1)}`}
					onClick={() => switchTab(route("crm.surveys.relationmanager-questionnaire.completions.index"), 1)}>
					<a className="tab-link">{uctrans("questionnaires.questionnaire_completion.plural")}</a>
				</li>
				<li
					className={`tab-item ${tabIsActive(2)}`}
					onClick={() => switchTab(route("crm.surveys.relationmanager-questionnaire.statistics.index"), 2)}>
					<a className="tab-link">{uctrans("questionnaires.statistics")}</a>
				</li>
			</ul>
		</div>
	);
}

RelationManagerQuestionnaireTabs.propTypes = {
	initialActiveTabIndex: PropTypes.number,
};
