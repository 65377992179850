import { useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import Nav from "../../../../../../js/lib/Nav";
import { route } from "../../../../../../js/helpers";
import Navigation from "./Navigation";
import NewsletterFormFields from "../shared/NewsletterFormFields";
import NewsletterSideBarContent from "../shared/NewsletterSideBarContent";

export default function AutomatedMessageForm() {
	const [formData, setFormData] = useState({ ...window.data.automated_message.form_data });
	const [staticData, setStaticData] = useState({ ...window.data.automated_message.static_data });

	const onSuccess = response => {
		if (staticData.creating) {
			Nav.go(route("crm.communication.automated-messages.edit", { automated_message: response.data.id }));
		} else {
			setFormData(response.data.form_data);
			setStaticData(response.data.static_data);
		}
	};

	return (
		<>
			<Navigation selectedTab="content" automatedMessage={staticData.creating ? null : staticData} />

			<div className="page-title-container">
				<h1 className="page-title">
					{staticData.is_copy
						? uctrans("general.copy_of_:item", {
								item: uctrans("communication.automated_messages.singular"),
						  })
						: uctrans(staticData.creating ? "general.add_:item" : "general.edit_:item", {
								item: uctrans("communication.automated_messages.singular"),
						  })}
				</h1>

				<a className="link" href={route("crm.communication.automated-messages.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "communication.automated_messages.plural" })}
				</a>
			</div>
			<AjaxForm
				method={staticData.creating ? "POST" : "PUT"}
				submitUrl={
					staticData.creating
						? route("crm.communication.automated-messages.store")
						: route("crm.communication.automated-messages.update", staticData.id)
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "communication.automated_messages.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "communication.automated_messages.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage={!!staticData.creating}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<NewsletterFormFields
							newsletterFormData={formData}
							newsletterStaticData={staticData}
							onChange={data => setFormData(data)}
						/>
					</div>
					<div className="flex-none w-1/3 border-l pl-12">
						<NewsletterSideBarContent newsletter={formData} newsletterStaticData={staticData} />
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
