import PropTypes from "prop-types";
import { useContext } from "react";
import SelectField from "../../../../../../../js/react/components/general/Select";
import { CmsTypesContext } from "../../cms-types-context";

export default function Newsitem(props) {
	const onchange = selectedNewsitemOption => {
		props.onChange(selectedNewsitemOption.value);
	};

	const { newsitemOptions } = useContext(CmsTypesContext);
	const selectedNewsitemOption = newsitemOptions.find(({ value }) => value === props.value);

	return (
		<>
			{props.label && <label>{props.label}</label>}
			<SelectField value={selectedNewsitemOption} options={newsitemOptions} onChange={onchange} isClearable={false} />
		</>
	);
}

Newsitem.propTypes = {
	label: PropTypes.string,
	value: PropTypes.number,
	onChange: PropTypes.func,
};
