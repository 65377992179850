import PropTypes from "prop-types";
import { route, can } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";

export default function Navigation({ reportTypes, selectedTab }) {
	const tabs = [];

	if (can("view-report-commercial")) {
		tabs.push({
			key: reportTypes.COMMERCIAL.key,
			label: reportTypes.COMMERCIAL.label,
			route: route("crm.report.commercial.index"),
		});
	}
	if (can("view-report-conversation")) {
		tabs.push({
			key: reportTypes.CONVERSATION.key,
			label: reportTypes.CONVERSATION.label,
			route: route("crm.report.conversation.index"),
		});
	}
	if (can("view-report-intermediaries")) {
		tabs.push({
			key: reportTypes.INTERMEDIARIES_ORGANISATIONS.key,
			label: reportTypes.INTERMEDIARIES_ORGANISATIONS.label,
			route: route("crm.report.intermediaries.index"),
		});
	}
	if (can("view-report-wallet-analysis")) {
		tabs.push({
			key: reportTypes.WALLET_ANALYSIS.key,
			label: reportTypes.WALLET_ANALYSIS.label,
			route: route("crm.report.wallet-analysis.index"),
		});
	}
	if (can("view-report-market-scan")) {
		tabs.push({
			key: reportTypes.MARKET_SCAN.key,
			label: reportTypes.MARKET_SCAN.label,
			route: route("crm.report.market-scan.index"),
		});
	}
	if (can("view-report-client-treatment")) {
		tabs.push({
			key: reportTypes.CLIENT_TREATMENT.key,
			label: reportTypes.CLIENT_TREATMENT.label,
			route: route("crm.report.client-treatments.index"),
		});
	}
	if (can("view-report-account-support")) {
		tabs.push({
			key: reportTypes.ACCOUNT_SUPPORT.key,
			label: reportTypes.ACCOUNT_SUPPORT.label,
			route: route("crm.report.account-support.index"),
		});
	}
	if (can("view-report-segmentation")) {
		tabs.push({
			key: reportTypes.SEGMENTATION.key,
			label: reportTypes.SEGMENTATION.label,
			route: route("crm.report.segmentation.index"),
		});
	}
	if (can("view-report-afm")) {
		tabs.push({
			key: reportTypes.AFM.key,
			label: reportTypes.AFM.label,
			route: route("crm.report.afm.index"),
		});
	}
	if (can("view-report-cooperation-termination")) {
		tabs.push({
			key: reportTypes.COOPERATION_TERMINATION.key,
			label: reportTypes.COOPERATION_TERMINATION.label,
			route: route("crm.report.cooperation-termination.index"),
		});
	}
	if (can("view-report-overrule")) {
		tabs.push({
			key: reportTypes.OVERRULE.key,
			label: reportTypes.OVERRULE.label,
			route: route("crm.report.overrule.index"),
		});
	}
	if (can("view-report-giveaway")) {
		tabs.push({
			key: reportTypes.GIVEAWAY.key,
			label: reportTypes.GIVEAWAY.label,
			route: route("crm.report.giveaway.index"),
		});
	}
	if (can("view-report-marketing")) {
		tabs.push({
			key: reportTypes.MARKETING.key,
			label: reportTypes.MARKETING.label,
			route: route("crm.report.marketing.index"),
		});
	}
	if (can("view-report-applications")) {
		tabs.push({
			key: reportTypes.APPLICATIONS.key,
			label: reportTypes.APPLICATIONS.label,
			route: route("crm.report.applications.index"),
		});
	}
	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("report.plural") }]} />
			<TabLinks tabs={tabs} activeTab={selectedTab} />
		</>
	);
}
Navigation.propTypes = {
	selectedTab: PropTypes.string.isRequired,
	reportTypes: PropTypes.object,
};
