import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import GoogleMapReact from "google-map-react";
// import { fitBounds } from "google-map-react/utils";
// import { fitBounds } from "newsitem";

import pointsCluster from "points-cluster";

import CompanyMarker from "./markers/CompanyMarker";
import ClusterMarker from "./markers/ClusterMarker";

export default function Map(props) {
	const [mapProps, setMapProps] = useState({
		zoom: props.zoom,
		center: props.center,
		bounds: null,
	});
	const [markers, setMarkers] = useState(props.items);
	const [clusters, setClusters] = useState([]);
	const [googleMaps, setGoogleMaps] = useState(null);
	const [googleMap, setGoogleMap] = useState(null);

	useEffect(() => {
		setMarkers(props.items);
		if (props.items.length) {
			scaleMapToMarkers(googleMap, googleMaps, props.items, 0.002);
		} else {
			setMapProps({
				zoom: props.zoom,
				center: props.center,
				bounds: null,
			});
		}
	}, [props.items, googleMap, googleMaps, props.zoom, props.center]);

	useEffect(() => {
		setClusters(calculateClusters(markers, mapProps, props.clusterOptions));
	}, [markers, mapProps, props.clusterOptions]);

	const calculateClusters = (markers, mapProps, clusterOptions) => {
		const clusterer = mapProps.bounds ? pointsCluster(markers, clusterOptions) : null;
		return (clusterer ? clusterer(mapProps) : []).map(({ wx, wy, numPoints, points }) => ({
			id: points[0].id,
			lat: wy,
			lng: wx,
			nrOfItems: numPoints,
			items: points,
		}));
	};
	const onMapChange = ({ center, zoom, bounds }) => {
		setMapProps({ center, zoom, bounds });
	};

	const itemStreetviewClicked = item => {
		const latlng = new googleMaps.LatLng(item.lat, item.lng);
		const sv = new googleMaps.StreetViewService();
		const pano = googleMap.getStreetView();
		sv.getPanoramaByLocation(latlng, 50, (data, status) => {
			if (status === googleMaps.StreetViewStatus.OK) {
				const heading = googleMaps.geometry.spherical.computeHeading(data.location.latLng, latlng);
				pano.setPano(data.location.pano);
				pano.setPov({
					heading,
					pitch: 0,
					zoom: 1,
				});
				pano.setVisible(true);
			}
		});
	};

	const clusterClicked = cluster => {
		scaleMapToMarkers(googleMap, googleMaps, cluster.items, 0.002);
	};

	const scaleMapToMarkers = (map, maps, markers, offset) => {
		if (maps && markers) {
			const bounds = new maps.LatLngBounds();
			markers.forEach(marker => {
				bounds.extend({ lat: marker.lat, lng: marker.lng });
			});
			if (offset) {
				const center = bounds.getCenter();
				bounds.extend(new maps.LatLng(center.lat() + offset, center.lng() + offset));
				bounds.extend(new maps.LatLng(center.lat() - offset, center.lng() - offset));
			}

			map.fitBounds(bounds);
		}
	};

	return (
		<div style={{ width: props.width, height: props.height }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: window.data.maps_api_key, language: "nl" }}
				center={mapProps.center}
				zoom={mapProps.zoom}
				onChange={onMapChange}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map, maps }) => {
					setGoogleMap(map);
					setGoogleMaps(maps);
				}}>
				{clusters.map(mapItem => {
					if (mapItem.nrOfItems === 1) {
						const [item] = mapItem.items;
						return (
							<CompanyMarker
								key={item.id}
								lat={item.lat}
								lng={item.lng}
								onStreetviewClick={() => {
									itemStreetviewClicked(item);
								}}
								company={item.company}
							/>
						);
					} else {
						return (
							<ClusterMarker
								key={mapItem.id}
								lat={mapItem.lat}
								lng={mapItem.lng}
								nrOfItems={mapItem.nrOfItems}
								onClick={() => {
									clusterClicked(mapItem);
								}}
							/>
						);
					}
				})}
			</GoogleMapReact>
		</div>
	);
}

Map.propTypes = {
	items: PropTypes.array,
	width: PropTypes.string,
	height: PropTypes.string,
	center: PropTypes.object,
	zoom: PropTypes.number,
	clusterOptions: PropTypes.object,
};
Map.defaultProps = {
	width: "100%",
	center: { lat: 52.092876, lng: 5.10448 },
	zoom: 7,
	clusterOptions: { minZoom: 3, maxZoom: 13, radius: 60 },
};
