import { useState } from "react";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route, yesNoOptions } from "../../../../../../js/helpers";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import SelectFilter from "../../../../../../js/react/components/general/overview/SelectFilter";
import Navigation from "../Navigation";

export default function CategoryOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<Navigation selectedTab="categories" />

			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans("discuss_requests.singular")} {trans("discuss_requests.categories.plural")}
				</h1>
				{can("discuss_request.category", "create") && (
					<div>
						<IconButton
							href={route("crm.discuss-request-settings.categories.create")}
							content={uctrans("general.add_:item", {}, { item: "discuss_requests.categories.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.discuss-request-settings.categories.index")}
				deleteRouteName="crm.discuss-request-settings.categories.delete"
				moveRouteName="crm.discuss-request-settings.categories.move"
				useDragAndDrop={can("discuss_request.category", "move")}
				hideControls
				modelTranslations={{
					plural: trans("discuss_requests.categories.plural"),
					singular: trans("discuss_requests.categories.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SelectFilter
							className="form-1-6"
							name="is_active"
							label={uctrans("discuss_requests.categories.fields.is_active")}
							options={yesNoOptions()}
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						{can("discuss_request.category", "move") && (
							<DataColumn
								width={1}
								className="draggable-overview-item-handle"
								renderHeaderContent={() => ""}
								renderCellContent={() => ""}
							/>
						)}
						<DataColumn
							width={10}
							sortable="name"
							renderHeaderContent={() => uctrans("discuss_requests.categories.fields.name")}
							renderCellContent={category => {
								if (can("discuss_request.category", "update")) {
									return (
										<a
											href={route("crm.discuss-request-settings.categories.edit", category.id)}
											className="row-title link">
											{category.name}
										</a>
									);
								} else {
									return category.name;
								}
							}}
						/>
						<DataColumn
							width={5}
							sortable="non_residents_policy"
							renderHeaderContent={() => uctrans("discuss_requests.categories.fields.non_residents_policy")}
							renderCellContent={category =>
								category.non_residents_policy ? uctrans("general.yes") : uctrans("general.no")
							}
						/>
						{can("discuss_request.category", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(category, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(category)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
