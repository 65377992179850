import { useState } from "react";
import { can, route } from "../../../../../../js/helpers";
import { default_date_format, format, parse } from "../../../../../../js/lib/Date";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import DatePicker from "../../../../../../js/react/components/general/DatePicker";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import Navigation from "./Navigation";

export default function AowSettingsForm() {
	const [aowSettings, setAowSettings] = useState([...window.data.aow_settings]);

	const canEdit = can("client_consent.aow_setting", "update");
	const onSuccess = response => {
		setAowSettings(response.data.aow_settings);
	};

	const addAowSetting = () => {
		const newAowSettings = aowSettings.slice(0);
		newAowSettings.push({
			date_of_birth_min: null,
			date_of_birth_max: null,
			age_years: null,
			age_months: null,
		});
		setAowSettings(newAowSettings);
	};

	const editAowSettingField = (index, field, value) => {
		const newAowSettings = aowSettings.slice(0);
		newAowSettings[index][field] = value === "" ? null : value;
		setAowSettings(newAowSettings);
	};

	const deleteAowSetting = index => {
		const newAowSettings = aowSettings.slice(0);
		newAowSettings.splice(index, 1);
		setAowSettings(newAowSettings);
	};

	return (
		<AjaxForm
			method="PUT"
			submitUrl={route("crm.client-lead-aow-settings.update")}
			loaderText={uctrans("general.:items_are_being_saved", {}, { items: "client_consents.aow_settings.plural" })}
			successText={uctrans("general.saved_:items", {}, { items: "client_consents.aow_settings.plural" })}
			data={{ aow_settings: aowSettings }}
			onSuccess={onSuccess}>
			<Navigation selectedTab="aowSettings" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("client_consents.aow_settings.plural")}</h1>
			</div>
			<div className="form-full">
				<table className="table overview">
					<thead>
						<tr>
							<th>{uctrans("client_consents.aow_settings.fields.date_of_birth_min")}</th>
							<th>{uctrans("client_consents.aow_settings.fields.date_of_birth_max")}</th>
							<th>{uctrans("client_consents.aow_settings.fields.age_years")}</th>
							<th>{uctrans("client_consents.aow_settings.fields.age_months")}</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{aowSettings.map((aowSetting, index) => (
							<tr key={index}>
								<td>
									<DatePicker
										initialValue={
											aowSetting.date_of_birth_min ? parse(aowSetting.date_of_birth_min, default_date_format) : null
										}
										onChange={date =>
											editAowSettingField(index, "date_of_birth_min", date ? format(date, default_date_format) : null)
										}
										disabled={!canEdit}
										showClearButton
									/>
								</td>
								<td>
									<DatePicker
										initialValue={
											aowSetting.date_of_birth_max ? parse(aowSetting.date_of_birth_max, default_date_format) : null
										}
										onChange={date =>
											editAowSettingField(index, "date_of_birth_max", date ? format(date, default_date_format) : null)
										}
										disabled={!canEdit}
										showClearButton
									/>
								</td>
								<td>
									<input
										type="number"
										name="age_years"
										value={aowSetting.age_years === null ? "" : aowSetting.age_years}
										onChange={e => editAowSettingField(index, "age_years", e.target.value)}
										disabled={!canEdit}
										min={0}
										max={100}
									/>
								</td>
								<td>
									<input
										type="number"
										name="age_months"
										value={aowSetting.age_months === null ? "" : aowSetting.age_months}
										onChange={e => editAowSettingField(index, "age_months", e.target.value)}
										disabled={!canEdit}
										min={0}
										max={11}
									/>
								</td>
								<td>
									{canEdit && (
										<span className="float-right delete-button" onClick={() => deleteAowSetting(index)}>
											<EvaIcon type="trash-2-outline" height="18" width="18" fill="#f9acaa" />
										</span>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
				{canEdit && (
					<>
						<a className="link" onClick={addAowSetting}>
							{uctrans("general.add_:item", {}, { item: "client_consents.aow_settings.singular" })}
						</a>
						<br />
						<br />
						<span className="italic">{uctrans("client_consents.aow_settings.save_warning")}</span>
						<SubmitBar item="client_consents.aow_settings.plural" />
					</>
				)}
			</div>
		</AjaxForm>
	);
}
