import * as PropTypes from "prop-types";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";

export default function TitleBlock(props) {
	return props.dashboardLink && (props.withNoRelationmanager || props.walletType !== "no_relationmanager") ? (
		<a href={props.dashboardLink}>
			{props.title}
			{props.useIcon && (
				<span className="inline-block ml-1 align-middle">
					<EvaIcon type="arrow-circle-right-outline" fill="#009286" height="18" width="18" />
				</span>
			)}
		</a>
	) : (
		<> {props.title} &nbsp;</>
	);
}

TitleBlock.propTypes = {
	walletType: PropTypes.string.isRequired,
	dashboardLink: PropTypes.string,
	title: PropTypes.string.isRequired,
	useIcon: PropTypes.bool,
	withNoRelationmanager: PropTypes.bool,
};

TitleBlock.defaultProps = {
	useIcon: true,
};
