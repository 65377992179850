import cloneDeep from "lodash/cloneDeep";
import { useState } from "react";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";
import QuestionnaireQuestionForm from "../questionnaires/QuestionnaireQuestionForm";

export default function ProfileQuestionnaireForm() {
	const [formData] = useState(cloneDeep(window.data.profile_questionnaire_data));
	const staticData = window.data.questionnaire_static_data;

	return (
		<>
			<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
			<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
			<QuestionnaireQuestionForm questionnaireFormData={formData} questionnaireStaticData={staticData} />
		</>
	);
}
