import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import PropTypes from "prop-types";
import { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Select from "../../../../../js/react/components/general/Select";
import Translate from "../../../../../js/react/components/general/Translate";

class AnswerOptionsList extends Component {
	constructor(props) {
		super(props);

		this.addAnswerOption = this.addAnswerOption.bind(this);
		this.editAnswerField = this.editAnswerField.bind(this);
		this.deleteAnswerOption = this.deleteAnswerOption.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.updateAnswerOptions = this.updateAnswerOptions.bind(this);
	}

	addAnswerOption() {
		const answerOptions = this.props.answerOptions.slice(0);
		answerOptions.push({
			content: "",
			min: 0,
			max: 1,
		});
		this.updateAnswerOptions(answerOptions);
	}

	editAnswerField(index, field, value) {
		const answerOptions = this.props.answerOptions.slice(0);
		answerOptions[index][field] = value;
		this.updateAnswerOptions(answerOptions);
	}

	deleteAnswerOption(index) {
		const answerOptions = this.props.answerOptions.slice(0);
		answerOptions.splice(index, 1);
		this.updateAnswerOptions(answerOptions);
	}

	onDragEnd(dragEvent) {
		if (dragEvent.destination) {
			const sourceIndex = dragEvent.source.index;
			const targetIndex = dragEvent.destination.index;

			if (`${sourceIndex}` !== `${targetIndex}`) {
				const answerOptions = this.props.answerOptions.slice(0);
				const answerOption = answerOptions[sourceIndex];
				answerOptions.splice(sourceIndex, 1);
				answerOptions.splice(targetIndex, 0, answerOption);
				this.updateAnswerOptions(answerOptions);
			}
		}
	}

	updateAnswerOptions(answerOptions) {
		this.props.onAnswerOptionsChange(answerOptions);
	}

	static getDraggingClassNames(isDragging) {
		if (isDragging) {
			return "active";
		}

		return "inactive";
	}

	static getListStyle(isDraggingOver) {
		return {
			background: isDraggingOver ? "lightblue" : "lightgrey",
		};
	}

	render() {
		return (
			<div>
				<DragDropContext onDragEnd={dragEvent => this.onDragEnd(dragEvent)}>
					<Droppable droppableId="answeroptions">
						{(provided, snapshot) => (
							<div
								className="answer-option-list"
								ref={provided.innerRef}
								style={AnswerOptionsList.getListStyle(snapshot.isDraggingOver)}
								{...provided.droppableProps}>
								{this.props.answerOptions.map((answerOption, index) => (
									<Draggable
										key={index}
										draggableId={index.toString()}
										index={index}
										isDragDisabled={this.props.answerOptions.length <= 1}>
										{(provided, snapshot) => (
											<div
												{...provided.dragHandleProps}
												ref={provided.innerRef}
												{...provided.draggableProps}
												className={`answer-option-list-item form-container q-draggable-overview-item q-draggable-item q-dragging-${AnswerOptionsList.getDraggingClassNames(
													snapshot.isDragging,
												)}`}>
												<br />

												<div className="form-full answer-value">
													<div className="answer-number form-1-4">
														<h6 className="block">
															<Translate content="questionnaires.question.answer_option.singular" /> {index + 1}
														</h6>
													</div>

													<div className="mt-2 form-1-6">
														<label>
															<Translate content="questionnaires.question.fields.value" />
														</label>
													</div>
													<div className="form-1-6">
														<input
															type="number"
															name="value"
															value={answerOption.value || ""}
															onChange={event => this.editAnswerField(index, "value", event.target.value)}
														/>
													</div>
													{this.props.questionColors && (
														<>
															<div className="mt-2 form-1-6">
																<label>
																	<Translate content="questionnaires.question.fields.color" />
																</label>
															</div>
															<div className="form-1-6">
																<Select
																	placeholder={uctrans(
																		"general.select_:item",
																		{},
																		{ item: "questionnaires.question.fields.color" },
																	)}
																	value={this.props.questionColors.filter(
																		({ value }) => Number(value) === Number(answerOption.color_code),
																	)}
																	options={this.props.questionColors}
																	name="color_code"
																	onChange={selectedColor =>
																		this.editAnswerField(index, "color_code", selectedColor.value)
																	}
																	isClearable={false}
																/>
															</div>
														</>
													)}

													{this.props.administrativeOptions && (
														<>
															<div className="mt-2 form-1-6">
																<label>
																	<Translate content="questionnaires.question.fields.administrative" />
																</label>
															</div>
															<div className="form-1-6">
																<Select
																	placeholder={uctrans(
																		"general.select_:item",
																		{},
																		{ item: "questionnaires.question.fields.administrative" },
																	)}
																	value={this.props.administrativeOptions.filter(
																		({ value }) => Number(value) === Number(answerOption.administrative),
																	)}
																	options={this.props.administrativeOptions}
																	name="adiministrative"
																	onChange={administrative =>
																		this.editAnswerField(index, "administrative", administrative.value)
																	}
																	isClearable={false}
																/>
															</div>
														</>
													)}

													<div className="answer-action ml-auto">
														<span className="float-right delete-button" onClick={() => this.deleteAnswerOption(index)}>
															<EvaIcon type="trash-2-outline" height="18" width="18" fill="#f9acaa" />
														</span>
													</div>
												</div>

												<div className="form-full answer-content">
													<div className="form-1-6">
														<Translate content="questionnaires.question.answer_option.fields.content" />
													</div>
													<div className="form-3-4">
														<input
															type="text"
															name="content"
															value={answerOption.content || ""}
															onChange={event => this.editAnswerField(index, "content", event.target.value)}
														/>
													</div>
												</div>

												<br />
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				<br />

				<div className="float-right">
					<a onClick={() => this.addAnswerOption()} className="button button-secondary">
						<Translate content="questionnaires.question.add_answer_option" />
					</a>
				</div>
			</div>
		);
	}
}

export default AnswerOptionsList;

AnswerOptionsList.propTypes = {
	answerOptions: PropTypes.array,
	onAnswerOptionsChange: PropTypes.func.isRequired,
	questionColors: PropTypes.array,
	administrativeOptions: PropTypes.array,
	questionTypeKey: PropTypes.number.isRequired,
	questionTypes: PropTypes.object,
};

AnswerOptionsList.defaultProps = {
	answerOptions: [],
};
