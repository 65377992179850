import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import { useState, useRef } from "react";
import { route } from "../../../../../../js/helpers";
import { format, parse } from "../../../../../../js/lib/Date";
import Nav from "../../../../../../js/lib/Nav";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import { trans, uctrans } from "../../../../../../js/lib/Translator";
import DatePicker from "../../../../../../js/react/components/general/DatePicker";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import Select from "../../../../../../js/react/components/general/Select";
import Translate from "../../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";
import useSelectHandler from "../../../../../../js/react/hooks/useSelectHandler";

export default function CooperationTerminationReport() {
	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);
	const [title, setTitle] = useState(null);

	const [formInfo] = useState(window.data.formInfo ? cloneDeep(window.data.formInfo) : []);
	const [overviewUpdatedAt, setOverviewUpdatedAt] = useState(new Date().getTime());
	const formRef = useRef(null);

	const selectHandler = useSelectHandler(setFormValues);
	const genericHandler = useGenericHandler(setFormValues);

	const generateCsv = async () => {
		const result = await axios.post(route("crm.report.cooperation-termination.generate-csv"), {
			...formRef.current.props.data,
			csv: 1,
		});

		if (result && result.data.success) {
			Nav.go(
				route("crm.report.cooperation-termination.download-csv", {
					download_key: result.data.download_key,
				}),
			);
		}
	};

	return (
		<AjaxForm
			method="POST"
			submitUrl={route("crm.report.account-support.data")}
			data={{ ...formValues }}
			ref={formRef}
			useFlashMessage>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans("report.report")} - {uctrans("report.report_types.cooperation_termination")}
				</h1>
			</div>
			<div className="w-full flex">
				<div className="w-1/4 pr-2">
					<label htmlFor="from_date">
						<Translate content="report.account_support.from" />
					</label>
					<DatePicker
						showClearButton
						initialValue={formValues.from_date ? parse(formValues.from_date) : null}
						onChange={date => genericHandler(date ? format(date) : null, "from_date")}
					/>
				</div>
				<div className="w-1/4 pr-2">
					<label htmlFor="to_date">
						<Translate content="report.account_support.until" />
					</label>
					<DatePicker
						showClearButton
						initialValue={formValues.to_date ? parse(formValues.to_date) : null}
						onChange={date => genericHandler(date ? format(date) : null, "to_date")}
					/>
				</div>
				<div className="w-1/4 pr-2">
					<label htmlFor="reason">
						<Translate content="cooperation_terminations.reasons.singular" />
					</label>
					<Select
						placeholder={uctrans("general.select_:item", {}, { item: "cooperation_terminations.reasons.singular" })}
						value={formInfo.reasonOptions.filter(({ value }) => `${value}` === `${formValues.reason}`)}
						options={formInfo.reasonOptions}
						name="reason"
						onChange={selectHandler}
					/>
				</div>
			</div>
			<div className="flex w-full justify-start">
				<button
					type="submit"
					onClick={e => {
						setOverviewUpdatedAt(new Date().getTime());
						e.preventDefault();
					}}
					className="button button-primary leading-normal">
					{uctrans("report.show_report")}
				</button>
				<IconButton
					className="ml-5"
					icon="file-text-outline"
					onClick={() => generateCsv()}
					content={uctrans("report.generate_csv")}
					primary
				/>
			</div>

			<AbnOverview
				indexUrl={route("crm.report.cooperation-termination.data")}
				dataUpdatedAt={overviewUpdatedAt}
				extraQuerystringParameters={formValues}
				reloadedData={data => setTitle(data.title)}
				modelTranslations={{
					plural: trans("cooperation_terminations.plural"),
					singular: trans("cooperation_terminations.singular"),
				}}
				renderBody={() => (
					<>
						<div className="w-full mt-2 text-white flex bg-primary p-2">
							<span className="ml-1 font-bold">{title ? title : ""}</span>
						</div>
						<DataTable>
							<DataColumn
								width={3}
								renderHeaderContent={() => uctrans("report.cooperation_termination.agendaitem_finish_date")}
								renderCellContent={({ agendaItem }) => (
									<a className="text-sm link" href={route("crm.agenda-items.open", agendaItem)}>
										{agendaItem.finish_date ? format(agendaItem.finish_date, "dd-MM-yyyy") : "-"}
									</a>
								)}
							/>
							<DataColumn
								width={2}
								renderHeaderContent={() => uctrans("report.cooperation_termination.permit_number")}
								renderCellContent={cooperationTermination => (
									<span className="text-sm">{cooperationTermination.statutory_company.afm_number ?? "-"}</span>
								)}
							/>
							<DataColumn
								width={2}
								renderHeaderContent={() => uctrans("report.cooperation_termination.kvk_number")}
								renderCellContent={cooperationTermination => (
									<span className="text-sm">{cooperationTermination.statutory_company.kvk_number ?? "-"}</span>
								)}
							/>
							<DataColumn
								width={2}
								renderHeaderContent={() => uctrans("report.cooperation_termination.tpnr")}
								renderCellContent={cooperationTermination => (
									<span className="text-sm">{cooperationTermination.company.company_number ?? "-"}</span>
								)}
							/>
							<DataColumn
								width={4}
								renderHeaderContent={() => uctrans("report.cooperation_termination.name")}
								renderCellContent={cooperationTermination => (
									<span className="text-sm">{cooperationTermination.statutory_company.name ?? "-"}</span>
								)}
							/>
							<DataColumn
								width={3}
								renderHeaderContent={() => uctrans("report.cooperation_termination.check_date")}
								renderCellContent={cooperationTermination => (
									<span className="text-sm">
										{cooperationTermination.check_date ? format(cooperationTermination.check_date, "dd-MM-yyyy") : "-"}
									</span>
								)}
							/>
							<DataColumn
								width={3}
								renderHeaderContent={() => uctrans("report.cooperation_termination.reason")}
								renderCellContent={cooperationTermination => (
									<span className="text-sm">{cooperationTermination.reason ?? "-"}</span>
								)}
							/>
						</DataTable>
					</>
				)}
			/>
		</AjaxForm>
	);
}
