import PropTypes from "prop-types";
import { useState } from "react";
import IconButton from "../../../../../../../js/react/components/general/IconButton";
import { uctrans } from "../../../../../../../js/lib/Translator";

export default function PageAliasForm({ currentAlias, onSuccess }) {
	const [alias, setAlias] = useState(currentAlias || { slug: "" });

	const updateAliasValue = (property, value) => {
		const newAlias = { ...alias };
		newAlias[property] = value;
		setAlias(newAlias);
	};

	return (
		<div className="md:flex">
			<div className="flex-1 w-2/3 mr-12">
				<div className="form-container">
					<div className="form-full">
						<div className="form-1-3">
							<label htmlFor="name">
								{uctrans("cms.pages.page_aliases.singular")}
								<span className="text-orange"> *</span>
							</label>
							<input
								id="slug"
								type="text"
								name="slug"
								value={alias.slug}
								onChange={e => {
									updateAliasValue("slug", e.target.value);
								}}
							/>
						</div>
					</div>
					<div className="form-full">
						<div className="w-full column">
							<IconButton
								onClick={() => {
									onSuccess(alias);
								}}
								disabled={alias.slug === ""}
								content={
									alias.id
										? uctrans("cms.pages.page_aliases.alias_edit")
										: uctrans("cms.pages.page_aliases.alias_button")
								}
								primary
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

PageAliasForm.propTypes = {
	onSuccess: PropTypes.func,
	currentAlias: PropTypes.object,
};
