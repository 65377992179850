import PropTypes from "prop-types";
import { useContext, useState } from "react";
import Nav from "../../../../../../../js/lib/Nav";
import { uctrans } from "../../../../../../../js/lib/Translator";
import Dialog from "../../../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import CompanyDossierContext from "../CompanyDossierContext";
import DossierFileInput from "../fileInput/DossierFileInput";

export default function BadPressCheckDossierPart({ partData, completionData }) {
	const {
		handleFormChange,
		badPressCheckCompanyUrl,
		badPressCheckCompanyDirectorsUrl,
		badPressCheckStatutoryCompanyUrl,
		badPressCheckStatutoryCompanyDirectorsUrl,
		activeDossierDirectors,
		readOnly,
		statutory,
	} = useContext(CompanyDossierContext);

	const [toLessDirectors, setToLessDirectors] = useState(false);

	const checkForDirectors = () => {
		const count = activeDossierDirectors.filter(director => director.id).length;
		if (count > 0) {
			Nav.go(statutory ? badPressCheckStatutoryCompanyDirectorsUrl : badPressCheckCompanyDirectorsUrl, true);
		} else {
			setToLessDirectors(true);
		}
	};

	return (
		<div className="w-full">
			<div className="dossier-row">
				<label />
				<a
					className="button button-secondary inline-block relative"
					href={statutory ? badPressCheckStatutoryCompanyUrl : badPressCheckCompanyUrl}
					rel="noopener noreferrer"
					target="_blank">
					<EvaIcon type="activity-outline" fill="#ffffff" height="20" width="20" />
					&nbsp;
					{uctrans("dossiers.bad_press_checks_terms.fields.run_bad_press_check_company")}
				</a>
			</div>
			{!!statutory && (
				<div className="dossier-row">
					<label />
					<a className="button button-secondary inline-block relative" onClick={() => checkForDirectors()}>
						<EvaIcon type="activity-outline" fill="#ffffff" height="20" width="20" />
						&nbsp;
						{uctrans("dossiers.bad_press_checks_terms.fields.run_bad_press_check_company_and_directors")}
					</a>
					<br />
				</div>
			)}
			<div className="dossier-row">
				<label />
				<p>
					<small>* {uctrans("dossiers.fields.make_sure_all_directors_are_saved")}</small>
				</p>
			</div>

			<div className="dossier-row">
				<label htmlFor="type">{uctrans("dossiers.fields.files")}</label>
				<DossierFileInput
					existingFileDownloadRoute="crm.intermediaries.dossier.download-completion-file"
					initialFiles={completionData ? completionData.files.files : []}
					initialArchivedFiles={completionData ? completionData.files.archivedFiles : []}
					onChange={files => handleFormChange(partData.id, "files", files)}
					disabled={readOnly}
				/>
			</div>
			<Dialog
				isOpen={toLessDirectors}
				onClose={() => setToLessDirectors(false)}
				width={500}
				title={uctrans("dossiers.fields.no_bad_press_check_possible")}>
				<div className="form-full">
					<span>{uctrans("dossiers.fields.cannot_execute_bad_press_checks_for_directors")}</span>
				</div>
			</Dialog>
		</div>
	);
}

BadPressCheckDossierPart.propTypes = {
	partData: PropTypes.object,
	completionData: PropTypes.object,
};
