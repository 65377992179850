import { uctrans } from "../../../../../js/lib/Translator";
import IconButton from "../../../../../js/react/components/general/IconButton";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";
import BirthdayBlock from "./BirthdayBlock";
import ActiveManagementBlock from "./ActiveManagementBlock";
import CooperationBlock from "./CooperationBlock";
import EngagementScoreBlock from "./EngagementScoreBlock";
import IntermediariesToSpeakBlock from "./IntermediariesToSpeakBlock";
import LoanDetailsBlock from "./LoanDetailsBlock";
import LoanRequestBlock from "./LoanRequestBlock";
import LoanResultsBlock from "./LoanResultsBlock";
import ProcessBlock from "./ProcessBlock";
import { RmDashboardContext } from "./RmDashboardContext";
import RmDashboardManager from "./RmDashboardManager";
import SalesFunnelBlock from "./SalesFunnelBlock";

export default function OrgDashboard() {
	return (
		<>
			{window.data.nav_data && (
				<>
					<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
					<TabLinks tabs={window.data.nav_data.organisation_tabs} activeTab={window.data.nav_data.activeTabItem} />
				</>
			)}

			<RmDashboardManager>
				<div className="w-full flex mb-4 mt-3">
					<div className="w-1/2">
						<div className="page-title-container">
							<h1 className="page-title">
								{window.data.company_name
									? uctrans(
											"general.:item1 from :item2",
											{ item2: window.data.company_name },
											{ item1: "dashboard.singular" },
									  )
									: uctrans("dashboard.singular")}
							</h1>
						</div>
					</div>
					<div className="w-1/2">
						<div className="w-full flex">
							<div className="dashboard-pdf-row w-3/4 mb-2">
								<EngagementScoreBlock />
							</div>
							<div className="w-1/4 flex justify-end">
								<RmDashboardContext.Consumer>
									{({ printDashboardPDF }) => (
										<div>
											<IconButton
												icon="file-text-outline"
												onClick={() => printDashboardPDF()}
												content={uctrans("dashboard.export_pdf")}
												primary
												className="mb-2"
											/>
										</div>
									)}
								</RmDashboardContext.Consumer>
							</div>
						</div>
					</div>
				</div>

				<div className="dashboard-pdf-row w-full flex pb-5">
					<div className="w-1/2 pr-5">
						<LoanRequestBlock />
					</div>
					<div className="w-1/2 ">
						<SalesFunnelBlock />
					</div>
				</div>
				<div className="dashboard-pdf-row pb-5">
					<LoanResultsBlock />
				</div>
				<div className="dashboard-pdf-row pb-5">
					<LoanDetailsBlock />
				</div>
				<div className="dashboard-pdf-row pb-5">
					<ProcessBlock />
				</div>
				<div className="dashboard-pdf-row pb-5">
					<ActiveManagementBlock />
				</div>
				<div className="dashboard-pdf-row w-full flex pb-5">
					<div className="w-full" id="samenwerking">
						<CooperationBlock />
					</div>
				</div>
				<div className="pb-5">
					<IntermediariesToSpeakBlock />
				</div>
				<div className="pb-5">
					<BirthdayBlock />
				</div>
			</RmDashboardManager>
		</>
	);
}
