import { useState } from "react";
import { route, yesNoOptions } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import AppointmentForm from "../intermediaries/AppointmentForm";
import Tabs from "./Tabs";
import Tippy from "@tippyjs/react";

export default function ConversationsOverview() {
	const overviewData = { ...window.data.overview };

	const [appointmentFormData, setAppointmentFormData] = useState({});

	const closeAppointmentForm = () => {
		setAppointmentFormData({
			isOpen: false,
		});
	};

	const openAppointmentForm = company => {
		setAppointmentFormData({
			isOpen: true,
			id: company.id,
			name: company.name,
			selectableContactpersons: company.contactpersons,
		});
	};

	return (
		<>
			<Tabs selectedTab="relationmanagement-dashboard" />

			<div className="container flex flex-col md:flex-row flex-wrap">
				<div className="two-third-blocks vertical-spacer flex pr-3 ">
					<h1>{uctrans("intermediaries.to_speak")}</h1>
				</div>
				<AppointmentForm
					selectableContactpersons={appointmentFormData ? appointmentFormData.selectableContactpersons : []}
					isOpen={!!appointmentFormData.isOpen}
					companyId={appointmentFormData ? appointmentFormData.id : null}
					companyName={appointmentFormData ? appointmentFormData.name : null}
					dialogClosedItself={closeAppointmentForm}
				/>

				<div className="w-full">
					<AbnOverview
						initialData={overviewData}
						indexUrl={route("crm.conversations.index")}
						modelTranslations={{
							plural: uctrans("intermediaries.plural"),
							singular: uctrans("intermediaries.singular"),
						}}
						renderFilters={() => (
							<Filters>
								<SearchBarFilter type="text" name="search" />
								<SelectFilter
									className="form-1-3"
									name="behind_conversation_schedule"
									label={uctrans("intermediaries.conversations.behind_schedule")}
									options={yesNoOptions()}
								/>
								<SelectFilter
									className="form-1-3"
									name="relationmanager"
									label={uctrans("intermediaries.fields.relationmanager_id")}
									options={overviewData.metadata.filterdata.relationmanagers}
									collapsible
								/>
								<SelectFilter
									className="form-1-3"
									name="classification"
									label={uctrans("intermediaries.fields.classification")}
									options={overviewData.metadata.filterdata.classification_options}
									collapsible
								/>
							</Filters>
						)}
						renderBody={() => (
							<DataTable>
								<DataColumn
									width={3}
									sortable="relationmanager"
									renderHeaderContent={() => uctrans("intermediaries.fields.relationmanager_id")}
									renderCellContent={company => (company.relationmanager ? company.relationmanager : "-")}
								/>
								<DataColumn
									width={6}
									sortable="name"
									renderHeaderContent={() => uctrans("intermediaries.fields.name")}
									renderCellContent={company => (
										<a href={route("crm.intermediaries.edit-administrative", company.id)} className="row-title link">
											{company.name}
										</a>
									)}
								/>
								<DataColumn
									width={3}
									sortable="company_number"
									renderHeaderContent={() => uctrans("intermediaries.fields.company_number")}
									renderCellContent={company => company.company_number}
								/>
								<DataColumn
									width={3}
									sortable="segment"
									renderHeaderContent={() => uctrans("intermediaries.fields.segment_id")}
									renderCellContent={company =>
										company.segment && company.segment.label !== null ? company.segment.label : "-"
									}
								/>
								<DataColumn
									width={3}
									sortable="nr_of_conversations"
									renderHeaderContent={() => uctrans("intermediaries.fields.conversations_spoken")}
									renderCellContent={company =>
										`${company.nr_of_conversations ? company.nr_of_conversations : 0}/${
											company.conversation_target_count ? company.conversation_target_count : 0
										}`
									}
								/>
								<DataColumn
									width={3}
									sortable="expected_nr_of_conversations"
									renderHeaderContent={() => uctrans("intermediaries.fields.conversations_expected")}
									renderCellContent={company =>
										`${company.expected_nr_of_conversations ? company.expected_nr_of_conversations : 0}/${
											company.conversation_target_count ? company.conversation_target_count : 0
										}`
									}
								/>
								<DataColumn
									width={4}
									sortable="last_contact"
									renderHeaderContent={() => uctrans("intermediaries.fields.last_conversation")}
									renderCellContent={company => (company.last_contact ? company.last_contact : "")}
								/>
								<DataColumn
									width={4}
									sortable="sum_this_year"
									renderHeaderContent={() => uctrans("intermediaries.fields.status_220")}
									renderCellContent={company => `€ ${company.sum_this_year}`}
								/>
								<DataColumn
									width={1}
									renderHeaderContent={() => ""}
									renderCellContent={company => (
										<a href={route("crm.intermediaries.contactmoments.create", { intermediary: company.id })}>
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("intermediaries.fields.contact")}>
												<span>
													<EvaIcon type="plus-outline" height="18" width="18" />
												</span>
											</Tippy>
										</a>
									)}
								/>
								<DataColumn
									width={1}
									renderHeaderContent={() => ""}
									renderCellContent={company => (
										<a>
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("intermediaries.fields.appointment")}>
												<span onClick={() => openAppointmentForm(company)}>
													<EvaIcon type="calendar-outline" height="18" width="18" />
												</span>
											</Tippy>
										</a>
									)}
								/>
							</DataTable>
						)}
					/>
				</div>
			</div>

			<div className="mt-5">
				<a className="link" href={route("crm.blank")}>
					{uctrans("intermediaries.back_to_dashboard")}
				</a>
			</div>
		</>
	);
}
