import find from "lodash/find";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { CmsTypesContext } from "../../cms-types-context";
import { InterestTypeContext } from "../../interest-type-context";
import Attribute from "../attributes/Attribute";

export default function InterestTable(props) {
	const [values, setValues] = useState(props.values ? props.values : {});

	const { partTypes, interestTypes } = useContext(CmsTypesContext);

	useEffect(() => {
		setValues(props.values ? props.values : "");
	}, [props.values]);
	const handleValuesChange = newValues => {
		setValues(newValues);
		props.onChange(newValues);
	};
	const handleValueChange = (name, newValue) => {
		const newValues = { ...values };
		newValues[name] = newValue;
		handleValuesChange(newValues);
	};

	const partType = find(partTypes, partType => partType.key === "interest_table");

	if (partType) {
		return (
			<>
				{props.label && <h5>{props.label}</h5>}
				<div className="pl-6 mt-3  border-l border-grey-light">
					<div className="form-container">
						<span className="form-1-3">
							<Attribute
								attribute={partType.attributes.interest_type}
								values={values.interest_type ? values.interest_type : null}
								onChange={value => {
									handleValueChange("interest_type", value);
								}}
							/>
						</span>
					</div>
					{values.interest_type > 0 && (
						<InterestTypeContext.Provider
							value={find(interestTypes, interestType => interestType.value === values.interest_type)}>
							<Attribute
								attribute={partType.attributes.interest_rate_classes}
								values={values.interest_rate_classes ? values.interest_rate_classes : []}
								onChange={value => {
									handleValueChange("interest_rate_classes", value);
								}}
							/>
							<Attribute
								attribute={partType.attributes.fixed_interest_periods}
								values={values.fixed_interest_periods ? values.fixed_interest_periods : []}
								onChange={value => {
									handleValueChange("fixed_interest_periods", value);
								}}
							/>
							<Attribute
								attribute={partType.attributes.interest_discounts}
								values={values.interest_discounts ? values.interest_discounts : []}
								onChange={value => {
									handleValueChange("interest_discounts", value);
								}}
							/>
							<Attribute
								attribute={partType.attributes.display_interest_rate_classes}
								values={values.display_interest_rate_classes ? values.display_interest_rate_classes : 0}
								onChange={value => {
									handleValueChange("display_interest_rate_classes", value);
								}}
							/>
							<Attribute
								attribute={partType.attributes.display_fixed_interest_periods}
								values={values.display_fixed_interest_periods ? values.display_fixed_interest_periods : 0}
								onChange={value => {
									handleValueChange("display_fixed_interest_periods", value);
								}}
							/>
							<Attribute
								attribute={partType.attributes.use_from_prefix}
								values={values.use_from_prefix ? values.use_from_prefix : 0}
								onChange={value => {
									handleValueChange("use_from_prefix", value);
								}}
							/>
						</InterestTypeContext.Provider>
					)}
				</div>
			</>
		);
	}
}

InterestTable.propTypes = {
	label: PropTypes.string,
	values: PropTypes.shape({
		type: PropTypes.string,
		title: PropTypes.string,
	}),
	onChange: PropTypes.func.isRequired,
};
