import PropTypes from "prop-types";
import { format } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";
import CollapsibleContent from "../general/CollapsibleContent";

export default function AgendaItemDateFields({ agendaItem, initialCollapseState }) {
	const getColorText = (dateStr, slaDateStr) => {
		if (dateStr && slaDateStr) {
			const date = new Date(dateStr).setHours(0, 0, 0, 0);
			const slaDate = new Date(slaDateStr).setHours(0, 0, 0, 0);
			return date > slaDate ? "red" : "green";
		}
		return "black";
	};

	return agendaItem ? (
		<CollapsibleContent initialCollapseState={initialCollapseState} title={uctrans("agendaitems.dates")}>
			<div className="form-full">
				<div className="form-1-2">
					<label>{uctrans("agendaitems.fields.creation_date")}</label>
					<p>{agendaItem.creation_date ? format(agendaItem.creation_date, "dd-MM-y") : "-"}</p>
				</div>
			</div>
			<div className="form-full">
				<div className="form-1-2">
					<label>{uctrans("agendaitems.fields.initial_agenda_date")}</label>
					<p>{agendaItem.initial_agenda_date ? format(agendaItem.initial_agenda_date, "dd-MM-y") : "-"}</p>
				</div>
				{agendaItem.last_agenda_date && (
					<div className="form-1-2">
						<label>{uctrans("agendaitems.fields.last_agenda_date")}</label>
						<p>{agendaItem.last_agenda_date ? format(agendaItem.last_agenda_date, "dd-MM-y") : "-"}</p>
					</div>
				)}
			</div>
			<div className="form-full">
				<div className="form-1-2">
					<label>{uctrans("agendaitems.fields.initial_processing_date")}</label>
					<p
						style={{
							color: getColorText(agendaItem.initial_processing_date, agendaItem.servicelevel_processing_date),
						}}>
						{agendaItem.initial_processing_date ? format(agendaItem.initial_processing_date, "dd-MM-y") : "-"}
					</p>
				</div>
				<div className="form-1-2">
					<label>{uctrans("agendaitems.fields.servicelevel_processing_date")}</label>
					<p>
						{agendaItem.servicelevel_processing_date ? format(agendaItem.servicelevel_processing_date, "dd-MM-y") : "-"}
					</p>
				</div>
			</div>
			<br />
			<div className="form-full">
				<div className="form-1-2">
					<label>{uctrans("agendaitems.fields.finish_date")}</label>
					<p style={{ color: getColorText(agendaItem.finish_date, agendaItem.servicelevel_date) }}>
						{agendaItem.finish_date ? format(agendaItem.finish_date, "dd-MM-y") : "-"}
					</p>
				</div>
				<div className="form-1-2">
					<label>{uctrans("agendaitems.fields.servicelevel_date")}</label>
					<p>{agendaItem.servicelevel_date ? format(agendaItem.servicelevel_date, "dd-MM-y") : "-"}</p>
				</div>
			</div>
		</CollapsibleContent>
	) : null;
}

AgendaItemDateFields.propTypes = {
	agendaItem: PropTypes.object,
	initialCollapseState: PropTypes.bool,
};

AgendaItemDateFields.defaultProps = {
	initialCollapseState: false,
};
