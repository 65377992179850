import { useState } from "react";
import PropTypes from "prop-types";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import { can, route } from "../../../../../../js/helpers";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import Translate from "../../../../../../js/react/components/general/Translate";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import DiscountForm from "./DiscountForm";
import Dialog from "../../../../../../js/react/components/general/Dialog";

export default function DiscountOverview({ interestType }) {
	const [currentDiscount, setCurrentDiscount] = useState(null);
	const [overviewDataUpdatedAt, setOverviewDataUpdatedAt] = useState(new Date().getTime());

	const addDiscount = () => {
		setCurrentDiscount({
			id: -1,
			name: "",
			description: "",
			interest_type_id: interestType.id,
		});
	};

	const handleSuccess = () => {
		setOverviewDataUpdatedAt(new Date().getTime());
		setCurrentDiscount(null);
	};

	return (
		<>
			<h3>
				<Translate content="interest.discounts.plural" />
			</h3>

			<AbnOverview
				indexUrl={route("crm.interest.discounts.index", interestType.id)}
				deleteRouteName="crm.interest.discounts.delete"
				moveRouteName="crm.interest.discounts.move"
				useDragAndDrop
				modelTranslations={{
					plural: trans("interest.discounts.plural"),
					singular: trans("interest.discounts.singular"),
				}}
				dataUpdatedAt={overviewDataUpdatedAt}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={1}
							className="draggable-overview-item-handle"
							renderHeaderContent={() => ""}
							renderCellContent={() => ""}
						/>
						<DataColumn
							width={10}
							sortable="name"
							renderHeaderContent={() => uctrans("interest.fields.name")}
							renderCellContent={discount => (
								<a onClick={() => setCurrentDiscount(discount)} className="row-title link">
									{discount.name}
								</a>
							)}
						/>
						{can("interest.discount", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(discount, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(discount)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
			<IconButton
				onClick={addDiscount}
				content={uctrans("general.add_:item", {}, { item: "interest.discounts.singular" })}
				primary
			/>
			{currentDiscount !== null && (
				<Dialog
					isOpen={currentDiscount !== null}
					title={
						currentDiscount.id === -1
							? uctrans("general.add_:item", {}, { item: "interest.discounts.singular" })
							: uctrans("general.edit_:item", {}, { item: "interest.discounts.singular" })
					}
					isFullScreen
					width={900}
					onClose={() => setCurrentDiscount(null)}>
					<DiscountForm
						discount={currentDiscount}
						onSuccess={() => handleSuccess()}
						onCancel={() => setCurrentDiscount(null)}
					/>
				</Dialog>
			)}
		</>
	);
}

DiscountOverview.propTypes = {
	interestType: PropTypes.object.isRequired,
};
