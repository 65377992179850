import PropTypes from "prop-types";
import map from "lodash/map";
import { uctrans } from "../../../../../js/lib/Translator";
import ClickToSelect from "../../../../../js/react/components/general/ClickToSelect";
import Dialog from "../../../../../js/react/components/general/Dialog";
import CollapsibleContent from "../general/CollapsibleContent";
import QuestionForm from "./QuestionForm";

export default function QuestionDialog(props) {
	return (
		<Dialog
			isOpen={props.showAddQuestionDialog || props.showEditQuestionDialog}
			onClose={props.closeQuestionDialog}
			shouldCloseOnOverlayClick={false}
			className={props.currentQuestion.id === null ? "question_add_dialog" : "question_edit_dialog"}
			width={
				Number(props.questionnaireType) === Number(props.questionnaireTypes.ANNUALLY_COOPERATION_CHECK.key) ? 1000 : 850
			}
			title={uctrans(
				props.currentQuestion.id === null ? "questionnaires.add_question" : "questionnaires.edit_question",
			)}>
			{Number(props.questionnaireType) === Number(props.questionnaireTypes.ANNUALLY_COOPERATION_CHECK.key) ||
			Number(props.questionnaireType) === Number(props.questionnaireTypes.RELATIONMANAGER_SURVEY.key) ? (
				<div className="min-h-screen md:flex">
					<div className="flex-none w-2/3 mr-8">
						<QuestionForm
							formType={props.currentQuestion.id === null ? "add" : "edit"}
							question={props.currentQuestion}
							selectableDependencyQuestions={props.currentSelectableQuestionsForDependencies}
							questionTypes={props.questionTypes}
							dependencyTypes={props.dependencyTypes}
							questionFieldTypes={props.questionFieldTypes}
							questionColors={props.questionColors}
							administrativeOptions={props.administrativeOptions}
							onCloseDialog={props.closeQuestionDialog}
							onQuestionSubmit={props.handleQuestionSubmit}
							baseQuestionStoreUrl={props.baseQuestionStoreUrl}
							questionnaireType={props.questionnaireType}
							questionnaireTypes={props.questionnaireTypes}
						/>
					</div>
					<div className="flex-none w-1/3 border-l pl-8">
						<CollapsibleContent title={uctrans("general.general")} initialCollapseState={false}>
							<label>{uctrans("general.content")}</label>
							{props.currentQuestion && props.currentQuestion.content && <span>{props.currentQuestion.content}</span>}
						</CollapsibleContent>
						<CollapsibleContent title={uctrans("cooperation_check.annually.tag_info")} initialCollapseState={false}>
							<span>{uctrans("cooperation_check.annually.tag_info_explanation")}</span>

							<label>{uctrans("cooperation_check.annually.default_values")}</label>
							<span>{uctrans("cooperation_check.annually.default_values_explanation")}</span>
						</CollapsibleContent>
						{Number(props.questionnaireType) === Number(props.questionnaireTypes.RELATIONMANAGER_SURVEY.key) ? (
							<CollapsibleContent
								title={uctrans("cooperation_check.annually.available_tags")}
								initialCollapseState={false}>
								{map(props.relationManagerQuestionnaireTags, ({ tag }) => (
									<div key={tag}>
										<ClickToSelect>{`{${tag}}`}</ClickToSelect>
										<br />
									</div>
								))}
							</CollapsibleContent>
						) : (
							<CollapsibleContent
								title={uctrans("cooperation_check.annually.available_tags")}
								initialCollapseState={false}>
								{map(props.annuallyCooperationCheckTags, ({ tag }) => (
									<div key={tag}>
										<ClickToSelect>{`{${tag}}`}</ClickToSelect>
										<br />
									</div>
								))}
							</CollapsibleContent>
						)}
					</div>
				</div>
			) : (
				<QuestionForm
					formType={props.currentQuestion.id === null ? "add" : "edit"}
					question={props.currentQuestion}
					selectableDependencyQuestions={props.currentSelectableQuestionsForDependencies}
					questionTypes={props.questionTypes}
					dependencyTypes={props.dependencyTypes}
					questionFieldTypes={props.questionFieldTypes}
					questionColors={props.questionColors}
					administrativeOptions={props.administrativeOptions}
					onCloseDialog={props.closeQuestionDialog}
					onQuestionSubmit={props.handleQuestionSubmit}
					baseQuestionStoreUrl={props.baseQuestionStoreUrl}
					isProfileQuestionnaire={Number(props.questionnaireType) === Number(props.questionnaireTypes.PROFILE.key)}
					questionnaireType={props.questionnaireType}
					questionnaireTypes={props.questionnaireTypes}
				/>
			)}
		</Dialog>
	);
}

QuestionDialog.propTypes = {
	showAddQuestionDialog: PropTypes.bool,
	showEditQuestionDialog: PropTypes.bool,
	closeQuestionDialog: PropTypes.func,
	handleQuestionSubmit: PropTypes.func,
	questionColors: PropTypes.array,
	administrativeOptions: PropTypes.array,
	questionnaireTypes: PropTypes.object,
	questionFieldTypes: PropTypes.object,
	questionTypes: PropTypes.object,
	dependencyTypes: PropTypes.array,
	questionnaireType: PropTypes.number.isRequired,
	baseQuestionStoreUrl: PropTypes.string,
	currentQuestion: PropTypes.object.isRequired,
	currentSelectableQuestionsForDependencies: PropTypes.array.isRequired,
	annuallyCooperationCheckTags: PropTypes.object.isRequired,
	relationManagerQuestionnaireTags: PropTypes.object,
};

QuestionDialog.defaultProps = {
	formType: "add",
};
