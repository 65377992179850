import { format } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";

export default function DataimportSummary() {
	const summaryData = { ...window.data.dataimport.summary };
	return (
		<div className=" w-full">
			<h3>{uctrans("dataimports.summary")}</h3>
			<table className="table text-xs">
				<thead>
					<tr>
						<td className="font-bold text-left">{uctrans("dataimports.fields.inputfile")}</td>
						<td className="font-bold text-left">{uctrans("dataimports.last_succesfull_import")}</td>
						<td className="font-bold text-left">{uctrans("dataimports.frequency")}</td>
						<td className="font-bold text-left">{uctrans("dataimports.notification")}</td>
					</tr>
				</thead>
				<tbody>
					{summaryData.normal.map(type => (
						<tr key={`type_${type.key}`}>
							<td className="text-left">{type.label}</td>
							<td className="text-left">
								{type.lastSuccesfullImport
									? uctrans("general.:date_at_:time", {
											date: format(type.lastSuccesfullImport, "dd-MM-y"),
											time: format(type.lastSuccesfullImport, "HH:mm"),
									  })
									: "-"}
							</td>
							<td className="text-left">{type.frequency}</td>
							<td className="text-left">
								{type.notificationHours > 0
									? uctrans("dataimports.after_:nr_hours", { nr: type.notificationHours })
									: "-"}
							</td>
						</tr>
					))}
					<tr>
						<td colSpan={4}>
							<br />
						</td>
					</tr>
					{summaryData.special.map(type => (
						<tr key={`type_${type.key}`}>
							<td className="text-left">{type.label}</td>
							<td className="text-left">
								{type.lastSuccesfullImport
									? uctrans("general.:date_at_:time", {
											date: format(type.lastSuccesfullImport, "dd-MM-y"),
											time: format(type.lastSuccesfullImport, "HH:mm"),
									  })
									: "-"}
							</td>
							<td className="text-left">{type.frequency}</td>
							<td className="text-left">
								{type.notificationHours > 0
									? uctrans("dataimports.after_:nr_hours", { nr: type.notificationHours })
									: "-"}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}
