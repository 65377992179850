import PropTypes from "prop-types";
import { uctrans } from "../../../../../js/lib/Translator";
import getEngagementScoreColor from "../engagementScore/EngagementScoreColor";
import EngagementScoreDifference from "./EngagementScoreDifference";

export default function EngagementScore(props) {
	return (
		<div className="flex w-full">
			<div>
				<div
					className="rounded-full dashboard-circle-engagement-score flex items-center justify-center"
					style={{
						background: getEngagementScoreColor(props.totalScore, props.walletScore),
					}}>
					<span className="font-medium">{props.walletScore}</span>
				</div>
			</div>

			{props.scoreToCompare !== undefined && (
				<EngagementScoreDifference score={props.walletScore} scoreToCompare={props.scoreToCompare} />
			)}

			{props.wallet && props.wallet.type !== "total" && (
				<div className="flex items-center ml-5">
					<ul className="border-l p-2 text-sm">
						<li>{uctrans("dashboard.nationwide")}</li>
						<li>{props.totalScore}</li>
					</ul>
				</div>
			)}
		</div>
	);
}

EngagementScore.propTypes = {
	wallet: PropTypes.object,
	totalScore: PropTypes.number,
	walletScore: PropTypes.number,
	scoreToCompare: PropTypes.number,
};

EngagementScore.defaultProps = {
	showTotal: true,
	totalScore: 0,
	walletScore: 0,
};
