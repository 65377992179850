import { renderComponent } from "../../../../js/componentRenderer";
import CooperationTerminationForm from "../components/agenda/CooperationTerminationForm";
import CooperationTerminationReasonForm from "../components/agenda/settings/cooperationTerminationReasons/CooperationTerminationReasonForm";
import ApiUserForm from "../components/apiUsers/ApiUserForm";
import AppSplashscreenForm from "../components/app/splashScreen/AppSplashscreenForm";
import BirthdaySettingsForm from "../components/birthday/BirthdaySettingsForm";
import AowSettingsForm from "../components/clientConsents/settings/AowSettingsForm";
import ClientLeadSettingsForm from "../components/clientConsents/settings/ClientLeadSettingsForm";
import ClientLeadSegmentSettingsForm from "../components/clientConsents/settings/ClientLeadSegmentSettingsForm";
import DefinitionForm from "../components/cms/definitions/DefinitionForm";
import FilterProfileForm from "../components/cms/filter_profiles/FilterProfileForm";
import ClientConsentCreateForm from "../components/clientConsents/ClientConsentCreateForm";
import ClientConsentEditForm from "../components/clientConsents/ClientConsentEditForm";
import FlowmailerStatusForm from "../components/communication/flowmailer/FlowmailerStatusForm";
import FlowmailerStatusOverview from "../components/communication/flowmailer/FlowmailerStatusOverview";
import FlowmailerWhitelistRecipientsForm from "../components/communication/flowmailer/FlowmailerWhitelistRecipientsForm";
import FlowmailerWhitelistRecipientsOverview from "../components/communication/flowmailer/FlowmailerWhitelistRecipientsOverview";
import MailingListRecipientForm from "../components/communication/mailingListRecipients/MailingListRecipientForm";
import CommunicationNewsletterForm from "../components/communication/newsletters/NewsletterForm";
import CommunicationNewsletterMailingForm from "../components/communication/newsletters/NewsletterMailingForm";
import CommunicationNewsletterSubscriptionTypeForm from "../components/communication/newsletterSubscriptionTypes/NewsletterSubscriptionTypeForm";
import CommunicationNewsletterSubscriptionCategoryForm from "../components/communication/newsletterSubscriptionCategories/NewsletterSubscriptionCategoryForm";
import CommunicationNewsletterFooterForm from "../components/communication/newslettersFooter/NewsletterFooterForm";
import CommunicationNewsletterUnsubscribePageForm from "../components/communication/newsletterUnsubscribePage/NewsletterUnsubscribePageForm";
import AppOnboardingPageForm from "../components/app/onboardingpage/AppOnboardingPageForm";
import NewsletterUnsubscribeReasonForm from "../components/communication/newsletterUnsubscribeReasons/NewsletterUnsubscribeReasonForm";
import NewsletterUnsubscribeReasonOverview from "../components/communication/newsletterUnsubscribeReasons/NewsletterUnsubscribeReasonOverview";
import CompanyAlertForm from "../components/companyAlerts/companyAlertForm";
import CooperationAgreementForm from "../components/dossier/cooperationAgreements/CooperationAgreementForm";
import SbiCodeForm from "../components/kvk/SbiCodeForm";
import QuestionnaireCompletionOverview from "../components/questionnaires/QuestionnaireCompletionOverview";
import ReplaceTagForm from "../components/replaceTags/ReplaceTagForm";
import StatutoryCompanyForm from "../components/statutoryCompanies/StatutoryCompanyForm";
import RelationManagerQuestionnaireCompletionOverviewPage from "../components/surveys/relationmanager/RelationManagerQuestionnaireCompletionOverviewPage";
import RelationManagerQuestionnaireCompletionStatistics from "../components/surveys/relationmanager/RelationManagerQuestionnaireCompletionStatistics";
import RelationManagerSurveyCompanyView from "../components/surveys/relationmanager/RelationManagerSurveyCompanyView";
import ContactmomentTypeForm from "../components/contactmoments/ContactmomentTypeForm";
import ContactmomentCategoryForm from "../components/contactmoments/ContactmomentCategoryForm";
import RelationManagerSurveyQuestionnaireForm from "../components/surveys/relationmanager/RelationManagerSurveyQuestionnaireForm";
import LoanSearchForm from "../components/loans/LoanSearchForm";
import KvkSearchForm from "../components/kvk/KvkSearchForm";
import LocationTownshipForm from "../components/location/settings/townships/TownshipForm";
import LocationProvinceForm from "../components/location/settings/provinces/ProvinceForm";
import LocationZipcodeAreaForm from "../components/location/settings/zipcodeAreas/ZipcodeAreaForm";
import ThemeForm from "../components/marketing/themes/ThemeForm";
import UserProfileForm from "../components/profile/UserProfileForm";
import TargetYearForm from "../components/targets/TargetYearForm";
import AahgUserForm from "../components/teams/aahgUsers/AahgUserForm";
import ProcessTimeActivityForm from "../components/teams/processTimeActivities/ProcessTimeActivityForm";
import TeamGroupForm from "../components/teams/teamGroups/TeamGroupForm";
import UserForm from "../components/users/UserForm";
import RoleForm from "../components/roles/RoleForm";
import IntermediaryForm from "../components/intermediaries/IntermediaryForm";
import IntermediaryCommercialForm from "../components/intermediaries/IntermediaryCommercialForm";
import OrganisationForm from "../components/organisations/OrganisationForm";
import QuestionnaireForm from "../components/questionnaires/QuestionnaireForm";
import QuestionnaireCompletionStatisticsPage from "../components/questionnaires/QuestionnaireCompletionStatisticsPage";
import SegmentForm from "../components/segments/SegmentForm";
import CmsPageForm from "../components/cms/pages/PageForm";
import CmsPageAzForm from "../components/cms/pages/PageAzForm";
import CmsFixedContentForm from "../components/cms/fixed_content/FixedContentForm";
import CmsMenuitemForm from "../components/cms/menuitems/MenuitemForm";
import CmsNewsitemForm from "../components/cms/newsitems/NewsitemForm";
import ContactpersonForm from "../components/contactpersons/ContactpersonForm";
import ContactmomentForm from "../components/contactmoments/ContactmomentForm";
import ProfileQuestionnaireForm from "../components/contactmoments/ProfileQuestionnaireForm";
import OccupationForm from "../components/occupations/OccupationForm";
import InterestCategoryForm from "../components/contactpersons/InterestCategoryForm";
import InteresttypeForm from "../components/contactpersons/InterestTypeForm";
import InterestTypeForm from "../components/interest/interesttypes/InterestInterestTypeForm";
import InterestPeriodForm from "../components/interest/interestPeriods/InterestPeriodForm";
import TeamForm from "../components/teams/TeamForm";
import DepartmentForm from "../components/departments/DepartmentForm";
import IntermediaryTakeonForm from "../components/intermediaries/IntermediaryTakeonForm";
import ProvisioncorrectionForm from "../components/agenda/ProvisioncorrectionForm";
import FollowupActionForm from "../components/agenda/FollowupActionForm";
import DossierForm from "../components/dossier/DossierForm";
import OrganisationTakeonForm from "../components/organisations/OrganisationTakeonForm";
import TakeonRequestStatusForm from "../components/agenda/settings/takeonStatuses/TakeonStatusForm";
import DiscussRequestCategoryForm from "../components/discussRequests/categories/CategoryForm";
import DiscussRequestAdviceForm from "../components/discussRequests/advices/AdviceForm";
import DiscussRequestForm from "../components/discussRequests/DiscussRequestForm";
import QuestionnaireQuestionForm from "../components/questionnaires/QuestionnaireQuestionForm";
import CooperationRequestCompletionForm from "../components/agenda/CooperationRequestCompletionForm";
import ServiceLevelForm from "../components/agenda/settings/serviceLevels/ServiceLevelForm";
import AnnuallyCooperationCompletionForm from "../components/agenda/AnnuallyCooperationCompletionForm";
import DossierCheckActionForm from "../components/agenda/settings/dossierCheckActions/DossierCheckActionForm";
import DossierCheckForm from "../components/agenda/DossierCheckForm";
import BridgingLoanForm from "../components/interest/bridgingloans/BridgingLoanForm";
import DossierPartForm from "../components/dossier/dossierParts/DossierPartForm";
import BadPressCheckTermForm from "../components/dossier/badPressCheckTerms/BadPressCheckTermForm";
import KifidCheckActionForm from "../components/agenda/settings/kifidCheckActions/KifidCheckActionForm";
import KifidCheckForm from "../components/agenda/KifidCheckForm";
import AfmPermitForm from "../components/agenda/settings/afmPermits/AfmPermitForm";
import AfmCheckActionForm from "../components/agenda/settings/afmCheckActions/AfmCheckActionForm";
import AfmCheckForm from "../components/agenda/AfmCheckForm";
import ClientTreatmentForm from "../components/clienttreatment/ClientTreatmentForm";
import ClientTreatmentCompletionsForm from "../components/clienttreatment/ClientTreatmentCompletions";
import ClientTreatmentQuestionnaireForm from "../components/clienttreatment/ClientTreatmentQuestionnaireForm";
import ClientTreatmentQuestionnaireAnswersForm from "../components/clienttreatment/ClientTreatmentQuestionnaireAnswersForm";
import ClientTreatmentQuestionnaireStatisticsForm from "../components/clienttreatment/ClientTreatmentQuestionnaireStatisticsForm";
import ClientTreatmentBadgesForm from "../components/clienttreatment/ClientTreatmentBadgesForm";
import SoftwarePackageForm from "../components/softwarePackage/SoftwarePackageForm";
import IdentificationVerificationForm from "../components/identificationVerification/IdentificationVerificationForm";
import AutomatedMessageForm from "../components/communication/automatedMessages/AutomatedMessageForm";
import ClientTreatmentBudgetYearForm from "../components/clienttreatment/ClientTreatmentBudgetYearForm";
import GiveawayForm from "../components/giveaways/GiveawayForm";
import ClientTreatmentLandingPageForm from "../components/clienttreatment/ClientTreatmentLandingPageForm";
import PermissionDurationSettingsForm from "../components/clientConsents/settings/PermissionDurationSettingsForm";
import InterestPeriodSettingsForm from "../components/cms/interest/InterestPeriodSettingsForm";
/**
 * Entry file for forms, all forms are rendered here
 */
renderComponent(UserProfileForm, "user_profile_form");
renderComponent(UserForm, "user_form");
renderComponent(RoleForm, "role_form");
renderComponent(IntermediaryForm, "intermediary_form");
renderComponent(IntermediaryCommercialForm, "intermediary_commercial_form");
renderComponent(OrganisationForm, "organisation_form");
renderComponent(QuestionnaireForm, "questionnaire_form");
renderComponent(QuestionnaireCompletionOverview, "questionnaire_completion_overview");
renderComponent(QuestionnaireCompletionStatisticsPage, "questionnaire_completion_statistics");
renderComponent(
	RelationManagerQuestionnaireCompletionOverviewPage,
	"relationmanager_questionnaire_completion_overview",
);
renderComponent(RelationManagerSurveyCompanyView, "relationmanager_questionnaire_company_view");
renderComponent(
	RelationManagerQuestionnaireCompletionStatistics,
	"relationmanager_questionnaire_completion_statistics",
);
renderComponent(SegmentForm, "segment_form");
renderComponent(CmsPageForm, "cms_page_form");
renderComponent(CmsPageAzForm, "cms_page_az_form");
renderComponent(CmsFixedContentForm, "cms_fixed_content_form");
renderComponent(ContactpersonForm, "contactperson_form");
renderComponent(ContactmomentForm, "contactmoment_form");
renderComponent(ProfileQuestionnaireForm, "profile_questionnaire_form");
renderComponent(RelationManagerSurveyQuestionnaireForm, "relationmanager_survey_questionnaire_form");
renderComponent(ContactmomentTypeForm, "contactmoment_type_form");
renderComponent(ContactmomentCategoryForm, "contactmoment_category_form");
renderComponent(InterestCategoryForm, "interestcategory_form");
renderComponent(InteresttypeForm, "interesttype_form");
renderComponent(OccupationForm, "occupation_form");
renderComponent(CmsMenuitemForm, "cms_menuitem_form");
renderComponent(CmsNewsitemForm, "cms_newsitem_form");
renderComponent(FilterProfileForm, "filter_profile_form");
renderComponent(DefinitionForm, "definition_form");
renderComponent(TeamForm, "team_form");
renderComponent(AahgUserForm, "aahg_user_form");
renderComponent(ProcessTimeActivityForm, "process_time_acivity_form");
renderComponent(TeamGroupForm, "team_group_form");
renderComponent(DepartmentForm, "department_form");
renderComponent(InterestTypeForm, "interest_type_form");
renderComponent(InterestPeriodForm, "interest_period_form");
renderComponent(InterestPeriodSettingsForm, "interest_period_settings_form");
renderComponent(IntermediaryTakeonForm, "intermediary_takeon_form");
renderComponent(LoanSearchForm, "loan_search_form");
renderComponent(KvkSearchForm, "kvk_search_form");
renderComponent(ProvisioncorrectionForm, "provisioncorrection_form");
renderComponent(FollowupActionForm, "followup_action_form");
renderComponent(DossierForm, "dossier_form");
renderComponent(OrganisationTakeonForm, "organisation_takeon_form");
renderComponent(TakeonRequestStatusForm, "takeon_request_status_form");
renderComponent(DiscussRequestCategoryForm, "discuss_request_category_form");
renderComponent(DiscussRequestAdviceForm, "discuss_request_advice_form");
renderComponent(DiscussRequestForm, "discuss_request_form");
renderComponent(QuestionnaireQuestionForm, "questionnaire_question_form");
renderComponent(CooperationRequestCompletionForm, "cooperation_request_completion_form");
renderComponent(LocationZipcodeAreaForm, "location_zipcode_area_form");
renderComponent(LocationTownshipForm, "location_township_form");
renderComponent(LocationProvinceForm, "location_province_form");
renderComponent(CommunicationNewsletterForm, "communication_newsletter_form");
renderComponent(CommunicationNewsletterMailingForm, "communication_newsletter_mailing_form");
renderComponent(CommunicationNewsletterSubscriptionTypeForm, "communication_newsletter_subscription_type_form");
renderComponent(CommunicationNewsletterSubscriptionCategoryForm, "communication_newsletter_subscription_category_form");
renderComponent(CommunicationNewsletterFooterForm, "communication_newsletter_footer");
renderComponent(BirthdaySettingsForm, "birthday_message_form");
renderComponent(FlowmailerStatusOverview, "communication_flow_mailer_event_status_overview");
renderComponent(FlowmailerStatusForm, "communication_flow_mailer_event_status_form");
renderComponent(FlowmailerWhitelistRecipientsOverview, "communication_flow_mailer_whitelist_recipient_overview");
renderComponent(FlowmailerWhitelistRecipientsForm, "communication_flow_mailer_whitelist_recipient_form");
renderComponent(CommunicationNewsletterUnsubscribePageForm, "communication_unsubscribe_page");
renderComponent(NewsletterUnsubscribeReasonForm, "communication_unsubscribe_reasons_form");
renderComponent(NewsletterUnsubscribeReasonOverview, "communication_unsubscribe_reasons_overview");
renderComponent(MailingListRecipientForm, "communication_mailing_list_recipient_form");
renderComponent(ServiceLevelForm, "servicelevel_edit_form");
renderComponent(AppOnboardingPageForm, "apponboardingpage_form");
renderComponent(AppSplashscreenForm, "app_splashscreen_form");
renderComponent(AnnuallyCooperationCompletionForm, "annually_cooperation_completion_form");
renderComponent(DossierCheckActionForm, "dossier_check_action_form");
renderComponent(DossierCheckForm, "dossier_check_form");
renderComponent(BridgingLoanForm, "bridging_loan_form");
renderComponent(DossierPartForm, "dossier_part_form");
renderComponent(BadPressCheckTermForm, "bad_press_check_term_form");
renderComponent(CooperationAgreementForm, "cooperation_agreement_form");
renderComponent(KifidCheckActionForm, "kifid_check_action_form");
renderComponent(KifidCheckForm, "kifid_check_form");
renderComponent(AfmPermitForm, "afm_permit_form");
renderComponent(AfmCheckActionForm, "afm_check_action_form");
renderComponent(AfmCheckForm, "afm_check_form");
renderComponent(ApiUserForm, "api_user_form");
renderComponent(ClientConsentCreateForm, "client_consent_create_form");
renderComponent(ClientConsentEditForm, "client_consent_detail");
renderComponent(ClientTreatmentForm, "client_treatment_form");
renderComponent(ClientTreatmentCompletionsForm, "client_treatment_completions_form");
renderComponent(ClientTreatmentQuestionnaireForm, "client_treatment_evaluation_questionnaire_form");
renderComponent(ClientTreatmentQuestionnaireAnswersForm, "client_treatment_evaluation_questionnaire_answers_form");
renderComponent(
	ClientTreatmentQuestionnaireStatisticsForm,
	"client_treatment_evaluation_questionnaire_statistics_form",
);
renderComponent(ClientTreatmentBadgesForm, "client_treatment_badges_form");
renderComponent(SoftwarePackageForm, "software_package_form");
renderComponent(AutomatedMessageForm, "automated_message_form");
renderComponent(TargetYearForm, "target_year_form");
renderComponent(ClientTreatmentBudgetYearForm, "client_treatment_budget_year_form");
renderComponent(IdentificationVerificationForm, "identification_verification_form");
renderComponent(GiveawayForm, "giveaway_form");
renderComponent(ClientTreatmentLandingPageForm, "client_treatment_landing_page_form");
renderComponent(ThemeForm, "themes_form");
renderComponent(CompanyAlertForm, "companyalert_form");
renderComponent(ClientLeadSettingsForm, "client_lead_settings_form");
renderComponent(AowSettingsForm, "aow_settings_form");
renderComponent(ClientLeadSegmentSettingsForm, "client_lead_segment_settings_form");
renderComponent(ReplaceTagForm, "replace_tag_setting_form");
renderComponent(StatutoryCompanyForm, "statutory_company_form");
renderComponent(SbiCodeForm, "sbi_code_form");
renderComponent(CooperationTerminationReasonForm, "cooperation_termination_reason_form");
renderComponent(CooperationTerminationForm, "cooperation_termination_form");
renderComponent(PermissionDurationSettingsForm, "permission_duration_form");
