import { useState } from "react";
import { format, parse } from "../../../../../../js/lib/Date";
import Nav from "../../../../../../js/lib/Nav";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../../js/react/components/general/Translate";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import Select from "../../../../../../js/react/components/general/Select";
import DatePicker from "../../../../../../js/react/components/general/DatePicker";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";
import { CmsTypesContext } from "../cms-types-context";
import Radio from "../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../js/react/components/general/form/radio/RadioGroup";
import MenuitemAttributes from "./MenuitemAttributes";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";

export default function MenuitemForm() {
	const [title] = useState(window.data.menuitem_data.menuitemvalues.title);
	const [staticValues] = useState(window.data.menuitem_data.staticvalues);
	const [menuItemValues, setMenuItemValues] = useState(window.data.menuitem_data.menuitemvalues);
	const genericHandler = useGenericHandler(setMenuItemValues);

	const onSuccess = response => {
		if (!staticValues.creating) {
			setMenuItemValues(response.data.menuitem_data.menuitemvalues);
		} else {
			Nav.go(response.data.redirect_route);
		}
	};

	return (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{ label: uctrans("general.cms") },
					{ label: uctrans("cms.menuitems.plural"), route: staticValues.previous_link },
					{
						label: staticValues.creating ? uctrans("general.add_:item", {}, { item: "cms.menuitems.singular" }) : title,
					},
				]}
			/>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(
						staticValues.creating ? "general.add_:item" : "general.edit_:item",
						{},
						{ item: "cms.menuitems.singular" },
					)}
				</h1>
				{staticValues.previous_link && (
					<a className="link" href={staticValues.previous_link}>
						{uctrans("general.back_to_overview_:page", {}, { page: "cms.menuitems.plural" })}
					</a>
				)}
			</div>
			<AjaxForm
				method={staticValues.save_method}
				submitUrl={staticValues.save_link}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "cms.menuitems.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "cms.menuitems.singular" })}
				onSuccess={onSuccess}
				data={menuItemValues}>
				<div className="max-w-2xl">
					<div className="grid-container">
						<div className="grid-1-2">
							<LabeledTextInput
								name="title"
								label="cms.menuitems.fields.title"
								value={title}
								onChange={e => genericHandler(e.target.value, "title")}
							/>
						</div>
						<div className="form-1-2">
							<label htmlFor="login_required">
								<Translate content="cms.menuitems.fields.section" />
							</label>
							<RadioGroup
								name="login_required"
								value={menuItemValues.login_required}
								onChange={value => genericHandler(value, "login_required")}>
								<Radio label={uctrans("cms.menuitems.fields.open_section")} value={0} />
								<Radio label={uctrans("cms.menuitems.fields.closed_section")} value={1} />
							</RadioGroup>
						</div>
					</div>

					<div className="form-container">
						<div className="form-1-3">
							<label htmlFor="publish_status">
								<Translate content="cms.menuitems.fields.publish_status" />
							</label>
							<Select
								id="publish_status"
								name="publish_status"
								value={staticValues.publish_statuses.filter(({ value }) => value === menuItemValues.publish_status)}
								onChange={selectedOption => {
									genericHandler(selectedOption.value, "publish_status");
								}}
								options={staticValues.publish_statuses}
								required
								isClearable={false}
							/>
						</div>
						{menuItemValues.publish_status === 3 && (
							<div className="form-1-3">
								<div className="flex justify-between">
									<div>
										<label htmlFor="published_from">
											<Translate content="cms.menuitems.fields.published_from" />
										</label>
										<DatePicker
											initialValue={menuItemValues.published_from ? parse(menuItemValues.published_from) : null}
											onChange={date => {
												genericHandler(date ? format(date) : null, "published_from");
											}}
											showTimeSelect
										/>
									</div>
									<div className="ml-2">
										<label htmlFor="published_to">
											<Translate content="cms.menuitems.fields.published_to" />
										</label>
										<DatePicker
											initialValue={menuItemValues.published_to ? parse(menuItemValues.published_to) : null}
											onChange={date => genericHandler(date ? format(date) : null, "published_to")}
											showTimeSelect
										/>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<CmsTypesContext.Provider value={staticValues.cmsTypes}>
					<h2>{uctrans("cms.menuitems.fields.menuitemcontent")}</h2>
					<div className="cms-attribute grid-container">
						<div className="grid-1-2">
							<MenuitemAttributes
								values={menuItemValues.contents ? menuItemValues.contents : {}}
								onChange={newValues => {
									genericHandler(newValues, "contents");
								}}
							/>
						</div>
					</div>
					<div>
						<SubmitBar item={uctrans("cms.menuitems.singular")} translateLabel={false} />
					</div>
				</CmsTypesContext.Provider>
			</AjaxForm>
		</>
	);
}

MenuitemForm.propTypes = {};
