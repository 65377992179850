import map from "lodash/map";
import PropTypes from "prop-types";
import { uctrans } from "../../../../../../../js/lib/Translator";
import FormattedNumberText from "../../FormattedNumberText";

export default function MarketingThemesReport({ reportData }) {
	const { data, title, total, scoring_types } = reportData;

	const colSpan = scoring_types ? scoring_types.length * 2 + 4 : 17;

	return reportData ? (
		<div className="mt-5 form-full">
			<table className="omzet_rapportage_table rapportage_table reporttable">
				<thead>
					<tr>
						<th colSpan={colSpan}>{title ? title : uctrans("report.singular")}</th>
					</tr>
					<tr className="head1">
						<td colSpan="2" />
						{map(scoring_types, (type, key) => (
							<td colSpan="2" className="status_titel" key={key}>
								<span>{uctrans(`report.marketing.scoring.types.${type}`)}</span>
							</td>
						))}
						<td colSpan="2" className="status_titel font-weight-bold">
							<span>{uctrans("report.total")}</span>
						</td>
					</tr>
				</thead>
				{data && data.length > 0 ? (
					<tbody>
						{map(data, (row, key) => (
							<tr key={key}>
								<td colSpan="2">{row.label}</td>
								{map(scoring_types, (scoringType, key) => (
									<td colSpan="2" key={key}>
										<FormattedNumberText value={row[scoringType] ? row[scoringType] : 0} />
									</td>
								))}
								<td colSpan="2" className="totaal">
									<FormattedNumberText value={row.nr_of_total ? row.nr_of_total : 0} />
								</td>
							</tr>
						))}

						{!!total && (
							<tr className="totaal">
								<td colSpan="2">{uctrans("report.total")}</td>
								{map(scoring_types, (scoringType, key) => (
									<td colSpan="2" key={key}>
										<FormattedNumberText value={total[scoringType] ? total[scoringType] : 0} />
									</td>
								))}
								<td colSpan="2">
									<FormattedNumberText value={total.nr_of_total ? total.nr_of_total : 0} />
								</td>
							</tr>
						)}
					</tbody>
				) : (
					<tbody>
						<tr>
							<td colSpan={colSpan}>{uctrans("report.no_results")}</td>
						</tr>
					</tbody>
				)}
			</table>
		</div>
	) : null;
}

MarketingThemesReport.propTypes = {
	reportData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
