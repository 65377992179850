import { useContext } from "react";
import { numberFormat, route } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";
import TitleBlock from "./components/TitleBlock";
import RmDashboardBlock from "./RmDashboardBlock";
import { RmDashboardContext } from "./RmDashboardContext";

export default function LoanRequestBlock() {
	const { wallet } = useContext(RmDashboardContext);
	const walletType = wallet ? wallet.type : "total";

	return (
		<RmDashboardBlock
			blockKey="loan-requests"
			useFetching
			renderHeader={blockData => (
				<div className="flex">
					<h5 className="mr-2 mb-0">
						{walletType === "intermediary" ? (
							<>{uctrans("dashboard.loan_requests")}</>
						) : (
							<TitleBlock
								walletType={walletType}
								dashboardLink={route("crm.report.applications.index")}
								title={uctrans("dashboard.loan_requests")}
							/>
						)}
					</h5>
					{!!blockData && blockData.last_app_import && (
						<>
							{" | "}
							<span className="text-sm ml-2 mt-1 mr-2">
								{uctrans("dashboard.last_update_:date_on_:time", {
									date: blockData.last_app_import ? format(blockData.last_app_import, "dd-MM-y") : "",
									time: blockData.last_app_import ? format(blockData.last_app_import, "HH:mm") : "",
								})}
							</span>
						</>
					)}
				</div>
			)}
			renderContent={blockData => (
				<div style={{ minHeight: "95px" }} className="table-styling">
					<div className="w-full flex border-b pb-2">
						<div className="w-1/3" />
						<div className="w-1/4 text-center">{uctrans("general.today")}</div>
						<div className="w-1/4 text-center">
							{uctrans("general.week_:number", {
								number: blockData.current_week,
							})}
						</div>
						<div className="w-1/4 text-center">{blockData.current_month}</div>
					</div>
					<div className="w-full flex table-tr table-tr-first">
						<div className="w-1/3">
							<span className="font-bold">{uctrans("dashboard.hdn_messages_app")}</span>
						</div>
						<div className="w-1/4 mt-2 text-center">
							{blockData.app_applications_day ? numberFormat(blockData.app_applications_day) : 0}
						</div>
						<div className="w-1/4 mt-2 text-center">
							{blockData.app_applications_week ? numberFormat(blockData.app_applications_week) : 0}
						</div>
						<div className="w-1/4 mt-2 text-center">
							{blockData.app_applications_month ? numberFormat(blockData.app_applications_month) : 0}
						</div>
					</div>
				</div>
			)}
		/>
	);
}
