import PropTypes from "prop-types";
import { parse } from "@/lib/Date";
import { uctrans } from "@/lib/Translator";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import Select from "../../../../../js/react/components/general/Select";
import useDateHandler from "../../../../../js/react/hooks/useDateHandler";
import useSelectHandler from "../../../../../js/react/hooks/useSelectHandler";

export default function PeriodicFilter(props) {
	const formValues = props.formValues ?? [];
	const formInfo = props.formInfo ?? [];
	const periodTypeOptions = props.periodTypeOptions || formInfo.periodTypes;

	const selectHandler = useSelectHandler(props.setFormValuesFunc);
	const datePickerHandler = useDateHandler(props.setFormValuesFunc);

	return (
		<div className="flex w-full">
			{formValues && formInfo && periodTypeOptions && (
				<>
					<div className="w-1/4 pr-2">
						<label htmlFor="periodType">{uctrans("report.period_type")}</label>
						<Select
							name="periodType"
							value={periodTypeOptions.filter(({ value }) => `${value}` === `${formValues.periodType}`)}
							options={periodTypeOptions}
							isClearable={false}
							onChange={selectHandler}
						/>
					</div>
					{formValues.periodType === "week" && (
						<div className="w-1/8 pr-2">
							<label htmlFor="week">{uctrans("report.week")}</label>
							<Select
								name="week"
								value={formInfo.weeks.filter(({ value }) => `${value}` === `${formValues.week}`)}
								options={formInfo.weeks}
								isClearable={false}
								onChange={selectHandler}
							/>
						</div>
					)}
					{formValues.periodType === "month" && (
						<div className="w-1/8 pr-2">
							<label htmlFor="month">{uctrans("report.month")}</label>
							<Select
								name="month"
								value={formInfo.months.filter(({ value }) => `${value}` === `${formValues.month}`)}
								options={formInfo.months}
								isClearable={false}
								onChange={selectHandler}
							/>
						</div>
					)}
					{(formValues.periodType === "year" ||
						formValues.periodType === "month" ||
						formValues.periodType === "week") && (
						<div className="w-1/8 pr-2">
							<label htmlFor="year">{uctrans("report.year")}</label>
							<Select
								name="year"
								value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.year}`)}
								options={formInfo.years}
								isClearable={false}
								onChange={selectHandler}
							/>
						</div>
					)}
					{formValues.periodType === "week_range" && (
						<>
							<div className="w-1/8 pr-2">
								<label htmlFor="fromWeek">{uctrans("report.from")}</label>
								<Select
									name="fromWeek"
									value={formInfo.weeks.filter(({ value }) => `${value}` === `${formValues.fromWeek}`)}
									options={formInfo.weeks}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
							<div className="w-1/8 pr-2">
								<label htmlFor="fromWeekYear">&nbsp;</label>
								<Select
									name="fromWeekYear"
									value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.fromWeekYear}`)}
									options={formInfo.years}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
							<div className="w-1/8 pr-2">
								<label htmlFor="toWeek">{uctrans("report.to")}</label>
								<Select
									name="toWeek"
									value={formInfo.weeks.filter(({ value }) => `${value}` === `${formValues.toWeek}`)}
									options={formInfo.weeks}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
							<div className="w-1/8 pr-2">
								<label htmlFor="toWeekYear">&nbsp;</label>
								<Select
									name="toWeekYear"
									value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.toWeekYear}`)}
									options={formInfo.years}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
						</>
					)}
					{formValues.periodType === "month_range" && (
						<>
							<div className="w-1/8 pr-2">
								<label htmlFor="fromMonth">{uctrans("report.from")}</label>
								<Select
									name="fromMonth"
									value={formInfo.months.filter(({ value }) => `${value}` === `${formValues.fromMonth}`)}
									options={formInfo.months}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
							<div className="w-1/8 pr-2">
								<label htmlFor="fromMonthYear">&nbsp;</label>
								<Select
									name="fromMonthYear"
									value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.fromMonthYear}`)}
									options={formInfo.years}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
							<div className="w-1/8 pr-2">
								<label htmlFor="toMonth">{uctrans("report.to")}</label>
								<Select
									name="toMonth"
									value={formInfo.months.filter(({ value }) => `${value}` === `${formValues.toMonth}`)}
									options={formInfo.months}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
							<div className="w-1/8 pr-2">
								<label htmlFor="toMonthYear">&nbsp;</label>
								<Select
									name="toMonthYear"
									value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.toMonthYear}`)}
									options={formInfo.years}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
						</>
					)}
					{formValues.periodType === "date_range" && (
						<>
							<div className="w-1/8 pr-2">
								<label htmlFor="fromDate">{uctrans("report.from")}</label>
								<DatePicker
									initialValue={formValues.fromDate ? parse(formValues.fromDate) : null}
									onChange={date => datePickerHandler(date, "fromDate")}
								/>
							</div>
							<div className="w-1/8 pr-2">
								<label htmlFor="toDate">{uctrans("report.to")}</label>
								<DatePicker
									initialValue={formValues.toDate ? parse(formValues.toDate) : null}
									onChange={date => datePickerHandler(date, "toDate")}
								/>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
}

PeriodicFilter.propTypes = {
	formValues: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
	formInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
	periodTypeOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	setFormValuesFunc: PropTypes.func,
};
