import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import HighChart from "./HighChart";

const LineGraph = props => {
	const [series, setSeries] = useState([]);

	useEffect(() => {
		setSeries(props.series);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.series]);

	return !props.hideTitle ? (
		<table className="reporttable hypotheekjaarlijn_rapportage rapportage_table">
			<tbody>
				<tr>
					<th>{props.title}</th>
				</tr>
				<tr>
					<td>
						<HighChart
							chartConfig={{
								...props.chartOptions,
								series,
							}}
						/>
					</td>
				</tr>
			</tbody>
		</table>
	) : (
		<HighChart
			chartConfig={{
				...props.chartOptions,
				series,
			}}
		/>
	);
};

LineGraph.propTypes = {
	chartOptions: PropTypes.object,
	series: PropTypes.array,
	title: PropTypes.string,
	hideTitle: PropTypes.bool,
};

LineGraph.defaultProps = {
	hideTitle: false,
};

export default LineGraph;
