import map from "lodash/map";
import PropTypes from "prop-types";
import { uctrans } from "../../../../../../../js/lib/Translator";
import FormattedNumberText from "../../FormattedNumberText";

export default function QualityMonitorResults({ data, title, qualityMonitorType }) {
	const tableData = data.table_data || [];
	const tableNames = data.table_names || [];

	const colSpan =
		3 + (tableData.relation_manager ? 1 : 0) + (tableData.parent ? 1 : 0) + (tableData.commercial ? 1 : 0);

	const getRow = (row, key) => {
		switch (`${key}`) {
			case "application_count":
			case "quotation_count":
			case "loan_count":
				return (
					<tr key={key}>
						<td>{row.label}</td>
						<td className="toelichting">{row.description}</td>
						{tableData.company ? (
							tableData.company[key] ? (
								<td className="value">
									<FormattedNumberText decimalScale={1} value={tableData.company[key]} />
								</td>
							) : (
								<td className="value" />
							)
						) : null}
						{tableData.parent ? (
							tableData.parent[key] ? (
								<td className="value">
									<FormattedNumberText decimalScale={1} value={tableData.parent[key]} />
								</td>
							) : (
								<td className="value" />
							)
						) : null}
						{tableData.relation_manager ? <td className="value" /> : null}
						{tableData.commercial ? <td className="value" /> : null}
					</tr>
				);
			case "average_offers_per_loan":
				return (
					<tr key={key}>
						<td>{row.label}</td>
						<td className="toelichting">{row.description}</td>

						{tableData.company ? (
							tableData.company[key] ? (
								<td className="value">
									<FormattedNumberText decimalScale={1} value={tableData.company[key]} />
								</td>
							) : (
								<td className="value">
									<FormattedNumberText decimalScale={1} value={0} />
								</td>
							)
						) : null}

						{tableData.parent ? (
							tableData.parent[key] ? (
								<td className="value">
									<FormattedNumberText decimalScale={1} value={tableData.parent[key]} />
								</td>
							) : (
								<td className="value">
									<FormattedNumberText value={0} />
								</td>
							)
						) : null}

						{tableData.relation_manager ? (
							tableData.relation_manager[key] ? (
								<td className="value">
									<FormattedNumberText decimalScale={1} value={tableData.relation_manager[key]} />
								</td>
							) : (
								<td className="value">
									<FormattedNumberText decimalScale={1} value={0} />
								</td>
							)
						) : null}

						{tableData.commercial ? (
							tableData.commercial[key] ? (
								<td className="value">
									<FormattedNumberText decimalScale={1} value={tableData.commercial[key]} />
								</td>
							) : (
								<td className="value">
									<FormattedNumberText decimalScale={1} value={0} />
								</td>
							)
						) : null}
					</tr>
				);
			default:
				return (
					<tr key={key}>
						<td>{row.label}</td>
						<td className="toelichting">{row.description}</td>
						{tableData.company ? (
							tableData.company[key] ? (
								<td className="value">
									<FormattedNumberText percentage decimalScale={1} value={tableData.company[key] * 100} />
								</td>
							) : (
								<td className="value">
									<FormattedNumberText percentage decimalScale={1} value={0} />
								</td>
							)
						) : null}

						{tableData.parent ? (
							tableData.parent[key] ? (
								<td className="value">
									<FormattedNumberText percentage decimalScale={1} value={tableData.parent[key] * 100} />
								</td>
							) : (
								<td className="value">
									<FormattedNumberText percentage decimalScale={1} value={0} />
								</td>
							)
						) : null}

						{tableData.relation_manager ? (
							tableData.relation_manager[key] ? (
								<td className="value">
									<FormattedNumberText percentage decimalScale={1} value={tableData.relation_manager[key] * 100} />
								</td>
							) : (
								<td className="value">
									<FormattedNumberText percentage decimalScale={1} value={0} />
								</td>
							)
						) : null}

						{tableData.commercial ? (
							tableData.commercial[key] ? (
								<td className="value">
									<FormattedNumberText percentage decimalScale={1} value={tableData.commercial[key] * 100} />
								</td>
							) : (
								<td className="value">
									<FormattedNumberText percentage decimalScale={1} value={0} />
								</td>
							)
						) : null}
					</tr>
				);
		}
	};

	return qualityMonitorType === "credit_risk" ? (
		<table className="kredietrisico_rapportage_table rapportage_table reporttable">
			<thead>
				<tr>
					<th colSpan={colSpan}>{title ? title : uctrans("report.singular")}</th>
				</tr>
			</thead>
			<tbody>
				<tr className="head1">
					<td className="kop" colSpan="2">
						<br />
						Renteaanbiedingen
					</td>
					{map(tableData, (entry, key) => (
						<td className="groeptitel" key={key}>
							{entry ? entry.label : ""}
						</td>
					))}
				</tr>

				{map(tableNames.quotation, (row, key) => getRow(row, key))}

				<tr className="head2">
					<td colSpan="2">
						<br />
						Lopende hypotheken
					</td>
					<td colSpan={colSpan - 2} />
				</tr>

				{map(tableNames.loan, (row, key) => getRow(row, key))}
			</tbody>
		</table>
	) : (
		<table className="proces_rapportage_table rapportage_table reporttable">
			<thead>
				<tr>
					<th colSpan={colSpan}>{title ? title : uctrans("report.singular")}</th>
				</tr>
				<tr className="head1">
					<td className="kop" colSpan="2">
						<br />
						Aanvragen
					</td>
					{map(tableData, (entry, key) => (
						<td className="groeptitel organisatie" key={key}>
							{entry ? entry.label : ""}
						</td>
					))}
				</tr>
			</thead>
			<tbody>{map(tableNames, (row, key) => getRow(row, key))}</tbody>
		</table>
	);
}

QualityMonitorResults.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	title: PropTypes.string,
	qualityMonitorType: PropTypes.string,
};
