import Translate from "../../../../../../js/react/components/general/Translate";
import { route } from "../../../../../../js/helpers";
import { trans, uctrans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";

export default function InterestPeriodSettingsOverview() {
	const table = () => (
		<DataTable>
			<DataColumn
				width={10}
				renderHeaderContent={() => uctrans("interest.fields.start_date")}
				renderCellContent={setting => (
					<a href={route("crm.interest.interest-period-settings.edit", setting.id)} className="row-title link">
						{setting.interestPeriod}
					</a>
				)}
			/>
			<DataColumn
				width={30}
				renderHeaderContent={() => uctrans("interest.fields.description")}
				renderCellContent={setting => setting.message}
			/>
		</DataTable>
	);

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="interest.interest_periods.settings" />
				</h1>
			</div>
			<AbnOverview
				indexUrl={route("crm.interest.interest-period-settings.index")}
				modelTranslations={{
					plural: trans("interest.interest_periods.settings"),
					singular: trans("interest.interest_periods.settings"),
				}}
				useDragAndDrop={false}
				renderBody={table}
			/>
		</>
	);
}
