import { useState } from "react";
import { route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import { createLoader } from "../../../../../js/react/components/general/notifications";
import { RmDashboardContext } from "./RmDashboardContext";
import axios from "axios";
import PropTypes from "prop-types";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";

export default function RmDashboardManager(props) {
	const windowRmDashboardData = window.data.rm_dashboard ? window.data.rm_dashboard : {};
	const walletOptions = windowRmDashboardData.wallet_options ? windowRmDashboardData.wallet_options : [];

	const rememberCollapsedDashboadBlocks = windowRmDashboardData.collapsed_dashboard_blocks
		? windowRmDashboardData.collapsed_dashboard_blocks
		: [];
	const rememberCollapsedDashboadBlock = async (blockKey, collapsed) => {
		try {
			await axios.put(route(`crm.relationmanagement-dashboard.collapse-block`), {
				blockKey,
				collapsed,
			});
		} catch (err) {
			console.error(err);
		}
	};

	const [wallet, setWallet] = useState(
		windowRmDashboardData.wallet
			? windowRmDashboardData.wallet
			: { type: "total", id: null, label: uctrans("dashboard.nationwide") },
	);

	const fetchBlockData = async type => {
		const response = await axios.get(
			route("crm.relationmanagement-dashboard.block-data", {
				type,
				wallet_type: wallet.type,
				wallet_id: wallet.id,
			}),
		);
		return response.data;
	};

	const printDashboardPDF = async () => {
		const loader = createLoader("PDF wordt gegenereerd");
		try {
			const images = [];
			const imageWidth = 180;
			const margin = 15;

			const rowElements = document.getElementsByClassName("dashboard-pdf-row");
			for (let i = 0; i < rowElements.length; i++) {
				const rowElement = rowElements[i];
				if (rowElement) {
					images.push(await domtoimage.toPng(rowElement));
				}
			}
			const pdf = new jsPDF({
				orientation: "portrait",
			});
			const pageHeight = pdf.internal.pageSize.getHeight();
			let yMagin = margin;

			pdf.text(`${uctrans("dashboard.singular")} ${wallet.label}`, margin, yMagin);
			yMagin += 10;

			images.forEach(image => {
				const imgProps = pdf.getImageProperties(image);
				const imageHeight = imgProps.height * (imageWidth / imgProps.width);
				if (imageHeight + margin * 2 > pageHeight) {
					console.error("image to tall");
					return;
				}
				if (yMagin + imageHeight + margin > pageHeight) {
					yMagin = margin;
					pdf.addPage();
				}
				pdf.addImage(image, "PNG", margin, yMagin, imageWidth, imageHeight);
				yMagin += imageHeight;
			});
			pdf.save(`dashboard_${new Date().valueOf()}.pdf`);
			loader.destroy();
		} catch (error) {
			console.error("Error generating PDF", error);
			loader.failure("PDF kon niet worden gegenereerd");
		}
	};

	return (
		<RmDashboardContext.Provider
			value={{
				rememberCollapsedDashboadBlock,
				rememberCollapsedDashboadBlocks,
				wallet,
				setWallet,
				walletOptions,
				fetchBlockData,
				printDashboardPDF,
			}}>
			{props.children}
		</RmDashboardContext.Provider>
	);
}

RmDashboardManager.propTypes = {
	children: PropTypes.node.isRequired,
};
