import "../../js/bootstrap";

import * as notifier from "../../js/react/components/general/notifications";
import { trans } from "../../js/lib/Translator";

if (window.data.environment === "local") {
	// eslint-disable-next-line no-console
	console.info("window-data", window.data);
}

/**
 * Styling
 */
import "../scss/app.scss";

document.addEventListener("caughtException", ({ detail }) => {
	try {
		if (typeof window.data.environment === "undefined" || window.data.environment !== "live") {
			notifier.debug(detail.value, detail.type);
		} else {
			notifier.error(trans("general.developers_have_been_notified"), trans("general.something_went_wrong"));
		}
	} catch (e) {
		console.warn(e);
	}
});

notifier.parseSessionFlasherNotifications();

import "./react/entries/detailPages";
import "./react/entries/default";
import "./react/entries/forms";
import "./react/entries/overviews";
window.eva.replace();
