import PropTypes from "prop-types";
import { route, can } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";

export default function Navigation(props) {
	const tabs = [];
	if (can("discuss_request.category", "view")) {
		tabs.push({
			key: "categories",
			label: uctrans("discuss_requests.categories.plural"),
			route: route("crm.discuss-request-settings.categories.index"),
		});
	}
	if (can("discuss_request.advice", "view")) {
		tabs.push({
			key: "advices",
			label: uctrans("discuss_requests.advices.plural"),
			route: route("crm.discuss-request-settings.advices.index"),
		});
	}
	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("discuss_requests.discuss_request_settings") }]} />
			<TabLinks tabs={tabs} activeTab={props.selectedTab} />
		</>
	);
}
Navigation.propTypes = {
	selectedTab: PropTypes.string.isRequired,
};
