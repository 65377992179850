import EstablishmentList from "@crm/react/components/statutoryCompanies/EstablishmentList";
import axios from "axios";
import { route } from "@/helpers";
import { uctrans } from "@/lib/Translator";
import PropTypes from "prop-types";
import { notify } from "@/react/components/general/notifications";
import Spinner from "../../../../../js/react/components/general/Spinner";
import { useEffect, useMemo, useState } from "react";

export default function AllEstablishments({ statutoryCompanyId, currentCompanyId }) {
	const [loading, setLoading] = useState(false);
	const [establishments, setEstablishments] = useState([]);
	const [companyStatusEnum] = useState(window.data.company_static_data.company_statuses);

	useEffect(
		() => {
			async function fetchData() {
				setLoading(true);

				try {
					const { data } = await axios.get(
						route("crm.statutory-companies.establishments", { statutory_company: statutoryCompanyId }),
					);
					if (Object.keys(data).length > 0) {
						setLoading(false);
						setEstablishments(data.establishments);
					} else {
						setEstablishments([]);
						setLoading(false);
					}
				} catch (error) {
					console.error(error);
					notify("error", uctrans("statutory_companies.messages.establishments_not_found"), null);
					setLoading(false);
				}
			}

			fetchData();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const activeEstablishments = useMemo(
		() => establishments.filter(establishment => establishment.status === companyStatusEnum.ACTIVE.key),
		[companyStatusEnum, establishments],
	);

	const inactiveEstablishments = useMemo(
		() => establishments.filter(establishment => establishment.status !== companyStatusEnum.ACTIVE.key),
		[companyStatusEnum, establishments],
	);

	return (
		<>
			{loading && <Spinner width={25} />}
			{!loading && establishments.length ? (
				<>
					<EstablishmentList establishments={activeEstablishments} current={currentCompanyId} />
					{inactiveEstablishments.length > 0 && (
						<>
							<p className="font-bold mt-2 border-bottom">{uctrans("general.inactive")}</p>
							<EstablishmentList establishments={inactiveEstablishments} current={currentCompanyId} />
						</>
					)}
				</>
			) : (
				<i className="italic">{uctrans("general.no_:item", {}, { item: "intermediaries.establishments" })}</i>
			)}
		</>
	);
}
AllEstablishments.propTypes = {
	currentCompanyId: PropTypes.number,
	statutoryCompanyId: PropTypes.number.isRequired,
};
