import ImageFingerprint from "../../../../../../static/img/app_onboarding_page/fingerprint.svg";
import ImageBell from "../../../../../../static/img/app_onboarding_page/bell.svg";
import ImageInfo from "../../../../../../static/img/app_onboarding_page/info.svg";
import ImageSettings from "../../../../../../static/img/app_onboarding_page/settings.svg";
import ImageInterest from "../../../../../../static/img/app_onboarding_page/mortgage-interest-rates.svg";
import ImageKlantupdates from "../../../../../../static/img/app_onboarding_page/mobile-alert.svg";
import ImageBuilder from "../../../../../../static/img/app_onboarding_page/builder.svg";
import ImageQrlogin from "../../../../../../static/img/app_onboarding_page/mobile-qr.svg";

export default [
	{
		key: 1,
		img: ImageInfo,
	},
	{
		key: 2,
		img: ImageSettings,
	},
	{
		key: 3,
		img: ImageBuilder,
	},
	{
		key: 4,
		img: ImageBell,
	},
	{
		key: 5,
		img: ImageFingerprint,
	},
	{
		key: 6,
		img: ImageQrlogin,
	},
	{
		key: 7,
		img: ImageKlantupdates,
	},
	{
		key: 8,
		img: ImageInterest,
	},
];
