import { useState } from "react";
import { route } from "../../../../../../js/helpers";
import Nav from "../../../../../../js/lib/Nav";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import LabeledAutosizeTextarea from "../../../../../../js/react/components/general/form/LabeledAutosizeTextarea";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import useInputHandler from "../../../../../../js/react/hooks/useInputHandler";
import Navigation from "../SettingsNavigation";

export default function FlowmailerStatusForm() {
	const [formData, setFormData] = useState({ ...window.data.flowmailer_status_values });
	const inputHandler = useInputHandler(setFormData);

	const onSuccess = () => {
		Nav.go(route("crm.communication-settings.flow-mailer-statuses.index"));
	};

	return (
		<>
			<Navigation selectedTab="flowMailerStatus" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("general.edit_:item", {}, { item: "flowmailer_status.singular" })}</h1>

				<a className="link" href={route("crm.communication-settings.flow-mailer-statuses.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "flowmailer_status.plural" })}
				</a>
			</div>
			<AjaxForm
				method="PUT"
				submitUrl={route("crm.communication-settings.flow-mailer-statuses.update", formData.id)}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "flowmailer_status.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "flowmailer_status.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="name"
										value={formData.name}
										onChange={inputHandler}
										label="flowmailer_status.fields.name"
										required
										disabled
									/>
								</div>
							</div>
							<div className="form-full">
								<div className="form-1-3">
									<LabeledAutosizeTextarea
										name="description"
										value={formData.description}
										onChange={inputHandler}
										label="flowmailer_status.fields.description"
										required
									/>
								</div>
							</div>
							<div className="form-full">
								<div className="form-1-3">
									<Checkmark
										onChange={inputHandler}
										name="display_in_overview"
										checked={formData.display_in_overview}
										label={uctrans("flowmailer_status.fields.display_in_overview")}
									/>
								</div>
								<div className="form-1-3">
									<Checkmark
										onChange={inputHandler}
										name="display_in_statistics"
										checked={formData.display_in_statistics}
										label={uctrans("flowmailer_status.fields.display_in_statistics")}
									/>
								</div>
								<div className="form-1-3">
									<Checkmark
										onChange={inputHandler}
										name="display_in_unique_statistics"
										checked={formData.display_in_unique_statistics}
										label={uctrans("flowmailer_status.fields.display_in_unique_statistics")}
									/>
								</div>
							</div>
							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="flowmailer_status.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
