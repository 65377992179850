import { useState } from "react";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route } from "../../../../../../js/helpers";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";

export default function FilterProfileOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("general.cms") }]} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("cms.filter_profiles.plural")}</h1>
				{can("cms.filter_profile", "create") && (
					<div>
						<IconButton
							href={route("crm.cms.filter-profiles.create")}
							content={uctrans("general.add_:item", {}, { item: "cms.filter_profiles.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.cms.filter-profiles.index")}
				deleteRouteName="crm.cms.filter-profiles.delete"
				moveRouteName="crm.cms.filter-profiles.move"
				useDragAndDrop={can("cms.filter_profile", "move")}
				hideControls
				modelTranslations={{
					plural: trans("cms.filter_profiles.plural"),
					singular: trans("cms.filter_profiles.singular"),
				}}
				renderBody={() => (
					<DataTable>
						{can("cms.filter_profile", "move") && (
							<DataColumn
								width={1}
								className="draggable-overview-item-handle"
								renderHeaderContent={() => ""}
								renderCellContent={() => ""}
							/>
						)}
						<DataColumn
							width={10}
							renderHeaderContent={() => uctrans("cms.filter_profiles.fields.name")}
							renderCellContent={filterProfile => {
								if (can("cms.filter_profile", "update")) {
									return (
										<a href={route("crm.cms.filter-profiles.edit", filterProfile.id)} className="row-title link">
											{filterProfile.name}
										</a>
									);
								} else {
									return filterProfile.name;
								}
							}}
						/>
						<DataColumn
							width={5}
							renderHeaderContent={() => uctrans("cms.filter_profiles.fields.filter_type")}
							renderCellContent={({ filter_type }) => filter_type}
						/>
						<DataColumn
							width={5}
							renderHeaderContent={() => uctrans("cms.filter_profiles.fields.active_count")}
							renderCellContent={filter_type => filter_type.active_count}
						/>
						<DataColumn
							width={5}
							renderHeaderContent={() => uctrans("cms.filter_profiles.fields.positive_negative")}
							renderCellContent={filter_type =>
								filter_type.positive
									? uctrans("cms.filter_profiles.fields.positive")
									: uctrans("cms.filter_profiles.fields.negative")
							}
						/>
						{can("cms.filter_profile", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(filter_type, overview) =>
									filter_type.active_count === 0 ? (
										<DeleteColumnContent onClick={() => overview.askDelete(filter_type)} />
									) : null
								}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
