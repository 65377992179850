import PropTypes from "prop-types";
import { useContext } from "react";
import QuestionnaireCompletionStatisticsContext from "./QuestionnaireCompletionStatisticsContext";
import CollapsibleContent from "../../general/CollapsibleContent";
import QuestionStatisticsPieChart from "./QuestionStatisticsPieChart";
import QuestionStatisticsColumnChart from "./QuestionStatisticsColumnChart";
import { uctrans } from "../../../../../../js/lib/Translator";

const QuestionStatisticsDisplay = props => {
	const { question, statistics_data, answers_data, average_score } = props.questionData;

	const { question_types } = useContext(QuestionnaireCompletionStatisticsContext);

	return (
		<CollapsibleContent title={question.content} initialCollapseState={false}>
			{statistics_data === null && answers_data === null && (
				<span>{uctrans("questionnaires.no_statistics_data_for_question")}</span>
			)}
			{answers_data !== null &&
				(question.type === question_types.OPEN_SINGLE_LINE.key ||
					question.type === question_types.OPEN_MULTI_LINE.key ||
					question.type === question_types.MC_OPTIONS_MULTI.key) && (
					<div className="overflow-hidden">
						{answers_data &&
							answers_data.map(answerData => (
								<div key={answerData.id} className="w-1/2 float-left pr-8 mb-2">
									<p>
										<strong>{answerData.label}: </strong>
										{answerData.value}
									</p>
								</div>
							))}
					</div>
				)}
			{statistics_data !== null && (
				<>
					{(question.type === question_types.MC_SELECT.key ||
						question.type === question_types.MC_OPTIONS_SINGLE.key) && (
						<QuestionStatisticsPieChart
							question={question}
							questionnaire_completion_statistics_data={statistics_data}
						/>
					)}
					{question.type === question_types.MC_OPTIONS_MULTI.key && (
						<QuestionStatisticsColumnChart
							question={question}
							questionnaire_completion_statistics_data={statistics_data}
						/>
					)}
				</>
			)}
			{question.type === question_types.MATRIX.key &&
				question.question_rows &&
				question.question_rows.map((questionRow, index) => (
					<div key={index}>
						<div>
							<h4> {questionRow.content}</h4>
						</div>
						<div className="mt-2 pr-2">
							{statistics_data && statistics_data.hasOwnProperty(questionRow.content) && (
								<QuestionStatisticsPieChart
									question={question}
									questionnaire_completion_statistics_data={statistics_data[questionRow.content]}
								/>
							)}
						</div>
						{average_score && average_score.hasOwnProperty(questionRow.content) && (
							<div>
								<h5>
									{uctrans("questionnaires.average_score")}: {average_score[questionRow.content]}
								</h5>
							</div>
						)}
					</div>
				))}

			{question.type !== question_types.MATRIX.key && (average_score || average_score === 0) && (
				<div>
					<h5>
						{uctrans("questionnaires.average_score")}: {average_score}
					</h5>
				</div>
			)}
		</CollapsibleContent>
	);
};

QuestionStatisticsDisplay.propTypes = {
	questionData: PropTypes.object,
};

export default QuestionStatisticsDisplay;
