import PropTypes from "prop-types";
import { useContext } from "react";
import MediaLibrary from "../../../media/library/MediaLibrary";
import MediaSelector from "../../../media/selector/MediaSelector";
import { CmsTypesContext } from "../../cms-types-context";

export default function Video(props) {
	const { videoOptions } = useContext(CmsTypesContext);

	const onchange = selectedVideoOption => {
		if (selectedVideoOption) {
			const existingOption = videoOptions.find(({ id }) => id === props.value);
			if (!existingOption) {
				videoOptions.push(selectedVideoOption);
			}
		}
		props.onChange(selectedVideoOption ? selectedVideoOption.id : null);
	};

	const selectedVideoOption = videoOptions.find(({ id }) => id === props.value);
	return (
		<>
			{props.label && <label>{props.label}</label>}
			<MediaSelector selected={selectedVideoOption} type={MediaLibrary.TYPE_VIDEO} onSelect={onchange} />
		</>
	);
}

Video.propTypes = {
	label: PropTypes.string,
	value: PropTypes.number,
	onChange: PropTypes.func,
};
