import PropTypes from "prop-types";
import { route, can } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";

export default function LeadsNavigation(props) {
	const tabs = [];
	if (can("client_consent.client_consent", "lead_view")) {
		tabs.push({
			key: "leads_overview",
			label: uctrans("client_consents.overview_leads"),
			route: route("crm.client-leads.index"),
		});
		tabs.push({
			key: "client_consent_with_leads_overview",
			label: uctrans("client_consents.consents_with_leads"),
			route: route("crm.client-consents-with-leads.index"),
		});
	}
	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("client_consents.active_management_leads") }]} />
			<TabLinks tabs={tabs} activeTab={props.selectedTab} />
		</>
	);
}
LeadsNavigation.propTypes = {
	selectedTab: PropTypes.string.isRequired,
};
