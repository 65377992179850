import axios from "axios";
import PropTypes from "prop-types";
import { useState, useLayoutEffect } from "react";
import { can, route } from "../../../../../js/helpers";
import { parse } from "../../../../../js/lib/Date";
import Nav from "../../../../../js/lib/Nav";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import SingleSelectAutoComplete from "../../../../../js/react/components/general/autocomplete/SingleSelectAutoComplete";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import CheckmarkGroup from "../../../../../js/react/components/general/form/checkmark/CheckmarkGroup";
import LabeledTextarea from "../../../../../js/react/components/general/form/LabeledTextarea";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import IconButton from "../../../../../js/react/components/general/IconButton";
import Select from "../../../../../js/react/components/general/Select";
import Spinner from "../../../../../js/react/components/general/Spinner";
import Translate from "../../../../../js/react/components/general/Translate";
import useDateHandler from "../../../../../js/react/hooks/useDateHandler";
import FileInput from "../forms/FileInput";
import TextEditor from "../forms/textEditor/TextEditor";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import cloneDeep from "lodash/cloneDeep";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import CollapsibleContent from "../general/CollapsibleContent";
import TabLinks from "../general/tabs/TabLinks";
import AgendaItemQuickAdd from "./AgendaItemQuickAdd";
import ContactmomentSubjects from "./ContactmomentSubjects";
import ContactpersonSelector from "./ContactpersonSelector";
import GiveawayQuickAdd from "./GiveawayQuickAdd";

export default function ContactmomentForm(props) {
	const [formData, setFormData] = useState(
		typeof props.contactmomentFormData === "undefined"
			? cloneDeep(window.data.contactmoment_form_data)
			: props.contactmomentFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.contactmomentStaticData === "undefined"
			? cloneDeep(window.data.contactmoment_static_data)
			: props.contactmomentStaticData,
	);

	const [loading, setLoading] = useState(false);

	const datePickerHandler = useDateHandler(setFormData);

	const handleCompanyFormChange = (attributeKey, company) => {
		const newFormData = { ...formData };
		if (company !== null) {
			newFormData.company = company;
			newFormData.company_id = company.value;
			newFormData.selected_contactperson_ids = [];
		} else {
			newFormData.company = null;
			newFormData.company_id = null;
			newFormData.selected_contactperson_ids = [];
		}
		setFormData(newFormData);
	};

	const fetchExtraCompanyData = company_id => {
		if (!company_id) {
			return;
		}
		setLoading(true);
		axios({
			method: "GET",
			url: route("crm.contactmoments.fetch-extra-company-data", {
				company: company_id,
			}),
		})
			.then(response => {
				if (response && response.data) {
					handleFetchExtraCompanyData(response.data);
				}
			})
			.then(() => {
				setLoading(false);
			});
	};

	const handleFetchExtraCompanyData = data => {
		const newStaticData = { ...staticData };
		newStaticData.selectable_contactpersons = data.selectable_contactpersons;
		setStaticData(newStaticData);

		const newFormData = { ...formData };
		newFormData.year_sum = data.year_sum;
		newFormData.loan_count = data.loan_count;
		newFormData.profile_questionnaire_completion = data.profile_questionnaire_completion;
		setFormData(newFormData);
	};

	useLayoutEffect(() => {
		if (staticData.creating) {
			fetchExtraCompanyData(formData.company_id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData.company_id]);

	const handleFormChange = (attributeKey, value) => {
		const newFormData = { ...formData };
		newFormData[attributeKey] = value;
		if (attributeKey === "base_type") {
			newFormData.type_id = null;
		}
		setFormData(newFormData);
	};

	const selectableTypes = () =>
		staticData.types
			.filter(
				type =>
					(formData.base_type === staticData.base_types.CONVERSATION.key && type.has_base_type_conversation) ||
					(formData.base_type === staticData.base_types.MESSAGE.key && type.has_base_type_message),
			)
			.map(type => ({ label: type.name, value: type.id }));

	const handleProfileQuestionnaireAnswerChange = newAnswers => {
		const newFormData = { ...formData };
		if (!newFormData.profile_questionnaire_completion) {
			newFormData.profile_questionnaire_completion = { answers: null };
		}
		newFormData.profile_questionnaire_completion.answers = newAnswers;
		setFormData(newFormData);
	};

	const getContentLabelName = () => {
		switch (formData.base_type) {
			case staticData.base_types.CONVERSATION.key:
				return "contactmoments.fields.content_conversation";
			case staticData.base_types.MESSAGE.key:
				return "contactmoments.fields.content_message";
			case staticData.base_types.EMAIL.key:
				return "contactmoments.fields.content_email";
			case staticData.base_types.SERVICE_CALL.key:
				return "contactmoments.fields.content_servicecall";
			case staticData.base_types.TROUBLESHOOTING.key:
				return "contactmoments.fields.content_troubleshooting";
		}
		return null;
	};

	const onSuccess = () => {
		if (window.data.activeMenuItem === "intermediaries") {
			Nav.go(route("crm.intermediaries.contactmoments.index", { intermediary: formData.company_id }));
		} else if (window.data.activeMenuItem === "organisations") {
			Nav.go(route("crm.organisations.contactmoments.index", { organisation: formData.company_id }));
		} else {
			Nav.go(route("crm.contactmoments.index"));
		}
	};

	const getSubmitUrl = () => {
		if (staticData.creating) {
			return route("crm.contactmoments.store");
		}
		return route("crm.contactmoments.update", { contactmoment: formData.id });
	};

	return (
		<>
			{window.data.nav_data && (
				<>
					<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
					<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
				</>
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.page_title
						? window.data.page_title
						: uctrans(
								staticData.creating ? "general.add_:item" : "general.edit_:item",
								{},
								{ item: "contactmoments.singular" },
						  )}
				</h1>

				<a className="link" href={staticData.previous_link}>
					{uctrans("general.back_to_overview_:page", {}, { page: "contactmoments.plural" })}
				</a>
			</div>

			<AjaxForm
				method={staticData.creating ? "POST" : "PUT"}
				submitUrl={getSubmitUrl()}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "contactmoments.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "contactmoments.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage={staticData.creating}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-full mr-8">
						<div className="flex mb-4">
							{!staticData.company_is_set && (
								<div className="flex-1 mr-4">
									<label htmlFor="user_id">
										<Translate content="companies.singular" />
										<span className="text-orange"> *</span>
									</label>
									<SingleSelectAutoComplete
										name="company_id"
										dataSource={route("crm.companies.search").toString()}
										item={formData.company_id}
										initialItem={formData.company}
										onChange={company => handleCompanyFormChange("company_id", company)}
										placeholder={uctrans("general.type_to_add_:item", {}, { item: "companies.singular" })}
										async
									/>
								</div>
							)}
							<div className="flex-1 mr-4">
								<label htmlFor="user">
									<Translate content="contactmoments.fields.user" />
									<span className="text-orange"> *</span>
								</label>
								<Select
									isClearable={false}
									placeholder={uctrans("general.select_:item", {}, { item: "contactmoments.fields.user" })}
									value={staticData.users.filter(({ value }) => Number(value) === Number(formData.user_id))}
									options={staticData.users}
									name="user_id"
									onChange={(selectedValue, metaData) => handleFormChange(metaData.name, selectedValue.value)}
								/>
							</div>
							<div className="flex-1 mr-4">
								<label htmlFor="datetime">
									<Translate content="contactmoments.fields.datetime" />
									<span className="text-orange"> *</span>
								</label>
								<DatePicker
									initialValue={formData.datetime ? parse(formData.datetime) : null}
									onChange={date => datePickerHandler(date, "datetime")}
								/>
							</div>
						</div>
						<div className={`w-full text-center ${!loading ? "hidden" : ""}`}>
							<Spinner width={75} />
						</div>
						{!!formData.company_id && loading === false && (
							<div>
								<div>
									<ContactpersonSelector
										contactpersons={staticData.selectable_contactpersons ? staticData.selectable_contactpersons : null}
										selectedContactpersonIds={formData.selected_contactperson_ids}
										onSelectedContactpersonIdsChange={value => handleFormChange("selected_contactperson_ids", value)}
									/>
									<h4 className="border-b border-primary mt-8 clear-right mb-4">
										<Translate content="contactmoments.content" />
									</h4>
									<div className="flex mb-4">
										<div className="flex-1 mr-4">
											<label>
												<Translate content="contactmoments.fields.base_type" />
												<span className="text-orange"> *</span>
											</label>
											{formData.base_type !== staticData.base_types.EMAIL.key && (
												<RadioGroup
													name="base_type"
													value={formData.base_type}
													onChange={value => handleFormChange("base_type", value)}
													horizontal>
													<Radio
														label={staticData.base_types.CONVERSATION.label}
														value={staticData.base_types.CONVERSATION.key}
													/>
													<Radio
														label={staticData.base_types.MESSAGE.label}
														value={staticData.base_types.MESSAGE.key}
													/>
													<Radio
														label={staticData.base_types.SERVICE_CALL.label}
														value={staticData.base_types.SERVICE_CALL.key}
													/>
													<Radio
														label={staticData.base_types.TROUBLESHOOTING.label}
														value={staticData.base_types.TROUBLESHOOTING.key}
													/>
												</RadioGroup>
											)}
											{formData.base_type === staticData.base_types.EMAIL.key && (
												<span>{staticData.base_types.EMAIL.label}</span>
											)}
										</div>
										{formData.base_type !== staticData.base_types.EMAIL.key &&
											formData.base_type !== staticData.base_types.SERVICE_CALL.key &&
											formData.base_type !== staticData.base_types.TROUBLESHOOTING.key && (
												<div className="flex-1">
													<label>
														<Translate content="contactmoments.fields.type" />
														<span className="text-orange"> *</span>
													</label>
													<Select
														isClearable={false}
														disabled={!formData.base_type}
														placeholder={uctrans("general.select_:item", {}, { item: "contactmoments.fields.type" })}
														value={selectableTypes().filter(({ value }) => Number(value) === Number(formData.type_id))}
														options={selectableTypes()}
														name="type_id"
														onChange={(selectedValue, metaData) => handleFormChange(metaData.name, selectedValue.value)}
													/>
												</div>
											)}
									</div>
									{(formData.base_type === staticData.base_types.MESSAGE.key ||
										formData.base_type === staticData.base_types.EMAIL.key) && (
										<>
											<div className="form-full">
												<label>
													<Translate content="contactmoments.fields.category_id" />
													<span className="text-orange"> *</span>
												</label>
												<Select
													isClearable={false}
													placeholder={uctrans(
														"general.select_:item",
														{},
														{ item: "contactmoments.fields.category_id" },
													)}
													value={staticData.contactmoment_categories.filter(
														({ value }) => Number(value) === Number(formData.category_id),
													)}
													options={staticData.contactmoment_categories}
													name="category_id"
													onChange={(selectedValue, metaData) => handleFormChange(metaData.name, selectedValue.value)}
												/>
											</div>
											<div className="form-full">
												<LabeledTextInput
													name="subject"
													label={uctrans("contactmoments.fields.subject")}
													value={formData.subject}
													onChange={event => handleFormChange("subject", event.target.value)}
													required
												/>
											</div>

											{formData.mail_sender && formData.mail_receiver && (
												<>
													{formData.mail_datetime && (
														<div className="flex-1 mr-4">
															<label htmlFor="datetime">
																<Translate content="contactmoments.fields.mail_datetime" />
															</label>
															<DatePicker
																initialValue={formData.mail_datetime ? parse(formData.mail_datetime) : null}
																onChange={date => datePickerHandler(date, "mail_datetime")}
															/>
														</div>
													)}
													<div className="form-1-2">
														<LabeledTextInput
															label="dashboard.external_mail_sender"
															value={formData.mail_sender}
															name="sender"
															onChange={e => handleFormChange("mail_sender", e.target.value)}
														/>
													</div>

													<div className="form-1-2">
														<LabeledTextInput
															label="dashboard.external_mail_receiver"
															value={formData.mail_receiver}
															name="receiver"
															onChange={e => handleFormChange("mail_receiver", e.target.value)}
														/>
													</div>
												</>
											)}
										</>
									)}
									{formData.base_type === staticData.base_types.SERVICE_CALL.key && (
										<div className="form-full">
											<LabeledTextInput
												name="subject"
												label={uctrans("contactmoments.fields.subject")}
												value={formData.subject}
												onChange={event => handleFormChange("subject", event.target.value)}
												required
											/>
										</div>
									)}
									{formData.base_type === staticData.base_types.TROUBLESHOOTING.key && (
										<div className="form-full">
											<LabeledTextInput
												name="subject"
												label={uctrans("contactmoments.fields.subject_troubleshooting")}
												value={formData.subject}
												onChange={event => handleFormChange("subject", event.target.value)}
												required
											/>
										</div>
									)}
									<div className="form-full">
										<label>
											<Translate content={getContentLabelName()} />
											<span className="text-orange"> *</span>
										</label>
										<TextEditor
											type="mail"
											value={formData.content}
											onChange={value => handleFormChange("content", value)}
											autoResize
										/>
									</div>
									{formData.base_type === staticData.base_types.EMAIL.key && (
										<div className="form-full">
											<LabeledTextarea
												name="extra_info"
												label={uctrans("contactmoments.fields.extra_info")}
												value={formData.extra_info}
												onChange={event => handleFormChange("extra_info", event.target.value)}
											/>
										</div>
									)}
									{formData.base_type === staticData.base_types.CONVERSATION.key && (
										<>
											<CollapsibleContent initialCollapseState={false} title={uctrans("contactmoments.subjects")}>
												<ContactmomentSubjects
													discussedFinance={formData.discussed_finance}
													loanCount={formData.loan_count}
													yearSum={formData.year_sum}
													totalWallet={staticData.total_status_320}
													creating={staticData.creating}
													visiblePageIds={formData.visiblePageIds ? formData.visiblePageIds : []}
													onVisiblePageIdChange={handleFormChange}
													onContactmomentFormChange={handleFormChange}
													profileQuestionnaire={staticData.profile_questionnaire}
													profileQuestionnaireAnswers={
														formData.profile_questionnaire_completion
															? formData.profile_questionnaire_completion.answers
															: []
													}
													onProfileQuestionnaireAnswerChange={newAnswers =>
														handleProfileQuestionnaireAnswerChange(newAnswers)
													}
												/>
											</CollapsibleContent>
											<div className="form-container mt-5">
												<label>
													<Translate content="contactmoments.fields.confidential_content" />
												</label>
												<span className="block italic text-grey">{trans("contactmoments.confidential_explained")}</span>
												<textarea
													value={formData.confidential_content}
													onChange={event => handleFormChange("confidential_content", event.target.value)}
												/>
											</div>
										</>
									)}
									<div className="form-full">
										<label>
											<Translate content="contactmoments.fields.files" />
										</label>
										<FileInput
											initialFiles={formData.files}
											onChange={files => handleFormChange("files", files)}
											existingFileDownloadRoute="crm.contactmoments.file.download"
											maxFiles={10}
											public={false}
										/>
									</div>
									<div className="form-full">
										<AgendaItemQuickAdd
											agendaItems={formData.followup_actions || []}
											agendaItemType={staticData.agenda_item_types.FOLLOWUP_ACTION.key}
											agendaItemTypes={staticData.agenda_item_types}
											agendaItemTypePlural={trans("followupactions.plural")}
											agendaItemTypeSingular={trans("followupactions.singular")}
											users={staticData.users}
											onAgendaItemChange={agendaItems => handleFormChange("followup_actions", agendaItems)}
										/>
									</div>
									{formData.base_type !== staticData.base_types.TROUBLESHOOTING.key && (
										<>
											{can("agenda.cooperation_termination", "create") &&
											(formData.company.active || formData.cooperationTerminations?.length) ? (
												<div className="form-full">
													<AgendaItemQuickAdd
														agendaItems={formData.cooperationTerminations || []}
														agendaItemType={staticData.agenda_item_types.COOPERATION_TERMINATION.key}
														agendaItemTypes={staticData.agenda_item_types}
														agendaItemTypePlural={trans("cooperation_terminations.plural")}
														agendaItemTypeSingular={trans("cooperation_terminations.singular")}
														users={staticData.account_support_users}
														onAgendaItemChange={agendaItems => handleFormChange("cooperationTerminations", agendaItems)}
														cooperationTerminationReasons={staticData.cooperation_termination_reasons}
													/>
												</div>
											) : null}

											<div className="form-full">
												<AgendaItemQuickAdd
													agendaItems={formData.provisioncorrections || []}
													agendaItemType={staticData.agenda_item_types.PROVISION_CORRECTION.key}
													agendaItemTypes={staticData.agenda_item_types}
													agendaItemTypePlural={trans("provisioncorrections.plural")}
													agendaItemTypeSingular={trans("provisioncorrections.singular")}
													users={staticData.account_support_users}
													onAgendaItemChange={agendaItems => handleFormChange("provisioncorrections", agendaItems)}
												/>
											</div>
											<div className="form-full">
												<GiveawayQuickAdd
													users={staticData.users}
													contactpersons={staticData.selectable_contactpersons}
													staticData={staticData}
													contactmomentId={formData.id || null}
													giveaways={formData.giveaways || []}
													onGiveawayChange={giveaways => handleFormChange("giveaways", giveaways)}
												/>
											</div>
											<div className="form-full">
												<AgendaItemQuickAdd
													agendaItems={formData.dossier_checks || []}
													agendaItemType={staticData.agenda_item_types.DOSSIER_CHECK.key}
													agendaItemTypes={staticData.agenda_item_types}
													agendaItemTypePlural={trans("dossiers.fields.dossier_checks")}
													agendaItemTypeSingular={trans("dossiers.fields.dossier_check")}
													dossierCheckActions={staticData.dossier_check_actions}
													users={staticData.account_support_users}
													onAgendaItemChange={agendaItems => handleFormChange("dossier_checks", agendaItems)}
												/>
											</div>
										</>
									)}
								</div>
							</div>
						)}

						<CollapsibleContent initialCollapseState title={uctrans("contactmoments.share_with_other_users")}>
							<span className="italic">{uctrans("contactmoments.share_with_other_users_description")}</span>
							<div className=" items-center px-3 py-3 my-2 inline-block w-full">
								<div className="form-full">
									<div className=" w-full">
										<CheckmarkGroup
											name="mailUsers"
											initialChecked={formData.mailUsers}
											onChange={mailUsers => handleFormChange("mailUsers", mailUsers)}>
											{staticData.users.map((user, index) => (
												<div className=" w-1/2 float-left block" key={index}>
													<Checkmark key={index} value={user.value} name={user.value} label={user.label} />
												</div>
											))}
										</CheckmarkGroup>
									</div>
								</div>
							</div>
						</CollapsibleContent>

						<div className="row">
							<div className="w-full md:w-1/2 column">
								<SubmitBar item="contactmoments.singular" addAnotherAvailable={false} />
							</div>
						</div>
					</div>
					{staticData.company_is_set && (
						<div className="w-1/4 mr-12">
							<IconButton
								href={
									formData.company.is_organisation
										? route("crm.organisations.contactmoments.download-pdf", {
												organisation: formData.company_id,
												contactmoment: formData.id,
										  })
										: route("crm.intermediaries.contactmoments.download-pdf", {
												intermediary: formData.company_id,
												contactmoment: formData.id,
										  })
								}
								content={uctrans("contactmoments.download_pdf")}
								icon="download"
								primary
							/>
							<CollapsibleContent
								initialCollapseState={false}
								title={
									formData.company.is_organisation
										? uctrans("organisations.singular")
										: uctrans("intermediaries.singular")
								}>
								<div className="form-full">
									<label>
										<Translate content="intermediaries.fields.name" />
									</label>

									{formData.company.url ? (
										<a href={formData.company.url} className="link">
											<span>{formData.company.label}</span>
										</a>
									) : (
										<span>{formData.company.label}</span>
									)}
								</div>
								<br />
								{formData.company.company_number && (
									<div className="form-full">
										<label>
											<Translate content="intermediaries.fields.company_number" />
										</label>
										<span>{formData.company.company_number}</span>
									</div>
								)}

								{formData.company.organisation_number && (
									<div className="form-full">
										<label>
											<Translate content="organisations.fields.organisation_number" />
										</label>
										<span>{formData.company.organisation_number}</span>
									</div>
								)}

								{/* <a className="button button-primary block">{uctrans("contactmoments.download_pdf")}</a>*/}
							</CollapsibleContent>

							{staticData.relationManagerSurveyResults > 0 && (
								<CollapsibleContent initialCollapseState={false} title={uctrans("survey.relationmanager.plural")}>
									<div className="form-full">
										<label>
											<Translate content="survey.relationmanager.amount_of_results" />
										</label>
										<span>{staticData.relationManagerSurveyResults}</span>
									</div>
									<br />
									<div className="form-full">
										<a className="button button-primary" href={staticData.relationManagerSurveyLink}>
											<Translate content="survey.relationmanager.show_results" />
										</a>
									</div>
								</CollapsibleContent>
							)}
						</div>
					)}
				</div>
			</AjaxForm>
		</>
	);
}

ContactmomentForm.propTypes = {
	contactmomentFormData: PropTypes.object,
	contactmomentStaticData: PropTypes.object,
};
