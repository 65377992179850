import PropTypes from "prop-types";
import { useContext } from "react";
import MediaLibrary from "../../../media/library/MediaLibrary";
import MediaSelector from "../../../media/selector/MediaSelector";
import { CmsTypesContext } from "../../cms-types-context";

export default function Animation(props) {
	const { animationOptions } = useContext(CmsTypesContext);

	const onchange = selectedAnimationOption => {
		if (selectedAnimationOption) {
			const existingOption = animationOptions.find(({ id }) => id === props.value);
			if (!existingOption) {
				animationOptions.push(selectedAnimationOption);
			}
		}
		props.onChange(selectedAnimationOption ? selectedAnimationOption.id : null);
	};

	const selectedAnimationOption = animationOptions.find(({ id }) => id === props.value);
	return (
		<>
			{props.label && <label>{props.label}</label>}
			<MediaSelector selected={selectedAnimationOption} type={MediaLibrary.TYPE_ANIMATION} onSelect={onchange} />
		</>
	);
}

Animation.propTypes = {
	label: PropTypes.string,
	value: PropTypes.number,
	onChange: PropTypes.func,
};
