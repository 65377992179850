import find from "lodash/find";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import { CmsTypesContext } from "../../cms-types-context";
import PartContent from "./PartContent";

export default function ButtonSet(props) {
	const [values, setValues] = useState(props.values ? props.values : {});
	useEffect(() => {
		setValues(props.values ? props.values : "");
	}, [props.values]);
	const handleValuesChange = newValues => {
		setValues(newValues);
		props.onChange(newValues);
	};
	const handleValueChange = (name, newValue) => {
		const newValues = { ...values };
		newValues[name] = newValue;
		handleValuesChange(newValues);
	};

	const swapContents = (name1, name2) => {
		const newValues = { ...values };
		newValues[name1] = values[name2];
		newValues[name2] = values[name1];
		handleValuesChange(newValues);
	};

	const { partTypes } = useContext(CmsTypesContext);
	const partType = find(partTypes, partType => partType.key === "button_set");

	if (partType) {
		return (
			<div className="mt-5">
				<h5>{props.label}</h5>
				<PartContent
					label={partType.attributes.button_one.label}
					parttype="button"
					values={values.button_one}
					onChange={value => {
						handleValueChange("button_one", value);
					}}
				/>
				<div className="swap-columns">
					<button
						style={{ transform: "rotate(90deg)" }}
						onClick={() => {
							swapContents("button_one", "button_two");
						}}>
						<EvaIcon type="swap-outline" height="16" width="16" fill="#9c9c9c" />
					</button>
				</div>
				<PartContent
					label={partType.attributes.button_two.label}
					parttype="button"
					values={values.button_two}
					onChange={value => {
						handleValueChange("button_two", value);
					}}
				/>
			</div>
		);
	}
}

ButtonSet.propTypes = {
	label: PropTypes.string,
	values: PropTypes.shape({
		button_one: PropTypes.object,
		button_two: PropTypes.object,
	}),
	onChange: PropTypes.func.isRequired,
};
