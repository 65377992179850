import PropTypes from "prop-types";
import { useContext } from "react";
import each from "lodash/each";
import cloneDeep from "lodash/cloneDeep";
import HighChart from "../../report/graph/HighChart";
import QuestionnaireCompletionStatisticsContext from "./QuestionnaireCompletionStatisticsContext";

const QuestionStatisticsPieChart = props => {
	// const [calculatedAverage, setCalculatedAverage] = useState(null);

	const { pie_chart_config } = useContext(QuestionnaireCompletionStatisticsContext);

	const convertQuestionnaireCompletionToSeries = () => {
		const serieData = [];
		each(props.questionnaire_completion_statistics_data, stat_data => {
			serieData.push(stat_data);
		});

		return serieData;
	};

	const options = cloneDeep(pie_chart_config);
	options.series[0].data = convertQuestionnaireCompletionToSeries();
	options.tooltip = {
		shared: false,
		useHTML: true, // for SCSS
		formatter() {
			let tooltipText = `<b>${this.point.name} (${this.point.y})</b>:<br/>`;
			this.point.labels.forEach((label, index, array) => {
				tooltipText += label;
				if (index !== array.length - 1) {
					tooltipText += ", ";
				}
			});
			return `<span> ${tooltipText} <div/>`;
		},
	};

	return <HighChart chartConfig={options} />;
};

QuestionStatisticsPieChart.propTypes = {
	questionnaire_completion_statistics_data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default QuestionStatisticsPieChart;
