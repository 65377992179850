import { useState } from "react";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { route } from "../../../../../js/helpers";
import UserFormFields from "./UserFormFields";

export default function UserForm() {
	const [formData, setFormData] = useState({ ...window.data.user_form_data });
	const staticData = { ...window.data.user_static_data };
	const readOnly = window.data.read_only;

	const onSuccess = () => {
		Nav.go(staticData.is_active ? route("crm.users.index") : route("crm.users.deactivated"));
	};

	return (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{ label: uctrans("general.security_settings") },
					staticData.is_active
						? { label: uctrans("users.plural"), route: route("crm.users.index") }
						: { label: uctrans("users.deactivated_plural"), route: route("crm.users.deactivated") },
					{
						label: staticData.creating
							? uctrans("general.add_:item", {}, { item: "users.singular" })
							: formData.short_name,
					},
				]}
			/>
			<div className="page-title-container">
				<h1 className="page-title">
					{staticData.creating ? uctrans("general.add_:item", {}, { item: "users.singular" }) : staticData.label}
				</h1>
				<a className="link" href={staticData.is_active ? route("crm.users.index") : route("crm.users.deactivated")}>
					{uctrans(
						"general.back_to_overview_:page",
						{},
						{ page: staticData.is_active ? "users.plural" : "users.deactivated_plural" },
					)}
				</a>
			</div>
			<AjaxForm
				method={staticData.creating ? "POST" : "PUT"}
				submitUrl={staticData.creating ? route("crm.users.store") : route("crm.users.update", staticData.id)}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "users.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "users.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<UserFormFields
								formData={formData}
								setFormData={setFormData}
								staticData={staticData}
								readOnly={readOnly}
								existingFileDownloadRoute="crm.users.avatar.download"
							/>

							{!readOnly && (
								<div className="form-full">
									<div className="w-full md:w-1/2 lg:w-1/3 column">
										<SubmitBar item="users.singular" addAnotherAvailable={false} />
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
