import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { can, route } from "../../../../../../../js/helpers";
import { uctrans } from "../../../../../../../js/lib/Translator";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../../../js/react/components/general/IconButton";
import Spinner from "../../../../../../../js/react/components/general/Spinner";
import Translate from "../../../../../../../js/react/components/general/Translate";
import BreadCrumbs from "../../../general/breadcrumbs/BreadCrumbs";
import PageLibraryContext from "./PageLibraryContext";
import PageLibraryItems from "./PageLibraryItems";
import PageLibraryFilters from "./PageLibraryFilters";
import PageLibraryFolders from "./PageLibraryFolders";
import PageLibraryMoveButton from "./PageLibraryMoveButton";

const PageLibrary = function () {
	const getFilteredPages = useCallback((query, sort, folder, pages) => {
		let filteredPages = [...pages];

		if (query.length) {
			filteredPages = filteredPages.filter(page => page.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
		}

		if (folder) {
			filteredPages = filteredPages.filter(page => page.cms_folder_id === (folder === -1 ? null : folder));
		}

		if (sort) {
			const desc = sort === "created_at" || sort === "updated_at";
			filteredPages = filteredPages.sort((a, b) => {
				const valA = a[sort].toLowerCase();
				const valB = b[sort].toLowerCase();
				if (valA < valB) {
					return desc ? 1 : -1;
				}
				if (valA > valB) {
					return desc ? -1 : 1;
				}
				return 0;
			});
		}

		return filteredPages;
	}, []);
	const [loading, setLoading] = useState(false);
	const [filters, setFilters] = useState({ query: "", sort: "title" });

	const [folders, setFolders] = useState(window.data.cms_folders);
	const [folderCounts, setFolderCounts] = useState(window.data.cms_folder_counts);
	const [currentFolder, setCurrentFolder] = useState(-1);

	const [pages, setPages] = useState(window.data.pages);
	const [filteredPages, setFilteredPages] = useState(getFilteredPages("", "title", null, window.data.pages));
	const [selected, setSelected] = useState([]);

	const refreshFolders = useCallback(async () => {
		try {
			const response = await axios.get(route(`crm.cms.cms-folders.index`), { params: { type: "page" } });
			setFolders(response.data.cms_folders);
			setFolderCounts(response.data.cms_folder_counts);
		} catch (error) {
			console.error(error);
			setFolders([]);
		}
	}, []);

	useEffect(() => {
		setFilteredPages(getFilteredPages(filters.query, filters.sort, currentFolder, pages));
	}, [filters.query, filters.sort, currentFolder, pages, getFilteredPages]);

	const refreshPages = useCallback(async () => {
		try {
			setLoading(true);
			const params = {};
			if (currentFolder) {
				params.folder = currentFolder;
			}
			refreshFolders();
			let { data } = await axios.get(route(`crm.cms.pages.index`), { params });
			if (!Array.isArray(data)) {
				data = [];
			}
			setSelected([]);
			setPages(data);
			setLoading(false);
		} catch (error) {
			console.error(error);
			setPages([]);
		}
	}, [currentFolder, refreshFolders]);

	const toggleSelect = useCallback(
		id => {
			const selectedCopy = [...selected];

			if (selectedCopy.indexOf(id) === -1) {
				selectedCopy.push(id);
			} else {
				selectedCopy.splice(selectedCopy.indexOf(id), 1);
			}
			setSelected(selectedCopy);
		},
		[selected],
	);

	const onSelectMarkClick = useCallback(
		page => {
			toggleSelect(page.id);
		},
		[toggleSelect],
	);

	const setFilter = useCallback(
		(name, value) => {
			setFilters({ ...filters, [name]: value });
		},
		[filters],
	);

	return (
		<PageLibraryContext.Provider
			value={{
				type: "page",
				filters,
				setFilter,
				folders,
				folderCounts,
				currentFolder,
				setCurrentFolder,
				refreshFolders,
				pages: filteredPages,
				refreshPages,
				selected,
				onSelectMarkClick,
			}}>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("general.cms") }]} />

			<ul className="flex tabs shadow -mt-6 h-16">
				<li className="ml-auto tabs-button">
					<div className="flex flex-row">
						<a
							rel="noopener noreferrer"
							className="button button-concept"
							href={route("crm.cms.pages.all_invalid_links")}
							target="_blank">
							<EvaIcon className="inline-flex items-center mr-1" type="link-2" width="14" height="14" fill="#F9A8B4" />
							{uctrans("cms.pages.invalid_links.plural")}
						</a>
					</div>
				</li>
			</ul>

			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="cms.pages.plural" />
				</h1>

				{can("cms.page", "create") && (
					<IconButton
						href={route("crm.cms.pages.create")}
						content={uctrans("general.add_:item", {}, { item: "cms.pages.singular" })}
						primary
					/>
				)}
			</div>
			{selected.length > 0 && folders.length > 0 && <PageLibraryMoveButton />}

			<div className="flex">
				<div className="flex-1 mr-6 lg:mr-12">
					<PageLibraryFilters />

					{loading ? (
						<div className="text-center flex-grow">
							<Spinner width={25} />
							<div>
								<Translate content="cms.retrieving_pages" />
							</div>
						</div>
					) : pages.length > 0 ? (
						<PageLibraryItems />
					) : (
						<div className="text-center flex-grow">
							<Translate content="general.no_:items_found" transReplaces={{ items: `cms.pages.plural` }} />
						</div>
					)}
				</div>
				<div className="w-1/4">
					<PageLibraryFolders />
				</div>
			</div>
		</PageLibraryContext.Provider>
	);
};
export default PageLibrary;
