import PropTypes from "prop-types";
import { useContext } from "react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import { CmsTypesContext } from "../../cms-types-context";
import Boolean from "../attributes/Boolean";
import Select from "../attributes/Select";
import Text from "../attributes/Text";
import PartList from "../part-lists/PartList";
import Part from "./Part";

export default function PageHeadImage({ values, partType, handleValueChange }) {
	const { partTypes } = useContext(CmsTypesContext);

	return (
		<>
			<Part
				label={uctrans("cms.image_full_with_header")}
				parttype="image"
				partTypes={partTypes}
				values={values.image}
				onChange={value => {
					handleValueChange("image", value);
				}}
			/>
			<Part
				label={uctrans("cms.image_mobile")}
				parttype="image"
				partTypes={partTypes}
				values={values.mobile_image}
				onChange={value => {
					handleValueChange("mobile_image", value);
				}}
			/>

			<i className="text-sm italic">{uctrans("cms.image_mobile_description")}</i>
			<div className="mt-8">
				<Boolean
					label={partType.attributes.use_head_text.label}
					value={values.use_head_text}
					onChange={value => {
						handleValueChange("use_head_text", value);
					}}
				/>
			</div>

			{values.use_head_text && (
				<>
					<Select
						label={partType.attributes.text_align_type.label}
						value={values.text_align_type}
						options={partType.attributes.text_align_type.options}
						onChange={value => {
							handleValueChange("text_align_type", value);
						}}
					/>
					<Text
						label={partType.attributes.title.label}
						value={values.title}
						onChange={value => {
							handleValueChange("title", value);
						}}
					/>
					<PartList
						listPartType="link"
						label={partType.attributes.links.label}
						values={values.links}
						onChange={value => {
							handleValueChange("links", value);
						}}
					/>
					<PartList
						listPartType="icon_link"
						label={partType.attributes.icon_links.label}
						values={values.icon_links}
						onChange={value => {
							handleValueChange("icon_links", value);
						}}
					/>
					<PartList
						listPartType="button"
						label={partType.attributes.buttons.label}
						values={values.buttons}
						onChange={value => {
							handleValueChange("buttons", value);
						}}
					/>
				</>
			)}
		</>
	);
}

PageHeadImage.propTypes = {
	values: PropTypes.object.isRequired,
	partType: PropTypes.object.isRequired,
	handleValueChange: PropTypes.func.isRequired,
};
