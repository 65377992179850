import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import Dialog from "../../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import { createLoader } from "../../../../../../js/react/components/general/notifications";
import Translate from "../../../../../../js/react/components/general/Translate";
import MediaLibrary from "../library/MediaLibrary";
import MediaSelectorAnimation from "./MediaSelectorAnimation";
import MediaSelectorDocument from "./MediaSelectorDocument";
import MediaSelectorImage from "./MediaSelectorImage";
import MediaSelectorVideo from "./MediaSelectorVideo";
import axios from "axios";
import { route } from "../../../../../../js/helpers";

export default function MediaSelector(props) {
	const [selected, setSelected] = useState(props.selected);
	const [isOpen, setIsOpen] = useState(false);

	const fetchFileSize = fileId =>
		axios.get(route("crm.files.size", { file: fileId })).then(response => response.data.size);

	const handleSelect = useCallback(
		async selected => {
			// Prevent default behaviour if the selected media is the file size exceeds the maximum file size

			if (selected && props.maxFileSizeInBytes) {
				const loader = createLoader(uctrans("file.check_file_size"));
				let size;

				try {
					size = await fetchFileSize(selected.file.id);
				} catch (e) {
					loader.failure(uctrans("file.file_size_check_failed"));
					return;
				}

				if (size > props.maxFileSizeInBytes) {
					loader.failure(uctrans("file.file_size_check_file_too_large"));

					return;
				}

				loader.destroy();
			}

			setIsOpen(false);
			setSelected(selected);
			props.onSelect(selected);
		},
		[props],
	);

	useEffect(() => {
		setSelected(props.selected);
	}, [props.selected]);

	const getMediaRenderer = () => {
		switch (props.type) {
			case MediaLibrary.TYPE_IMAGE:
				return <MediaSelectorImage image={selected} onRemove={() => handleSelect(null)} />;
			case MediaLibrary.TYPE_DOCUMENT:
				return <MediaSelectorDocument document={selected} onRemove={() => handleSelect(null)} />;
			case MediaLibrary.TYPE_VIDEO:
				return <MediaSelectorVideo video={selected} onRemove={() => handleSelect(null)} />;
			case MediaLibrary.TYPE_ANIMATION:
				return <MediaSelectorAnimation animation={selected} onRemove={() => handleSelect(null)} />;
		}
		return null;
	};

	return (
		<>
			{!selected && (
				<label
					onClick={() => {
						if (!props.disabled) {
							setIsOpen(true);
						}
					}}
					className={`button button-secondary button-upload inline-block relative ${props.disabled ? "disabled" : ""}`}>
					<EvaIcon type="file-outline" className="absolute left-0 ml-3" fill="#ffffff" height="20" width="20" />
					{props.buttonLabel || (
						<Translate content="media.select_:media" transReplaces={{ media: `media.${props.type}.singular` }} />
					)}
				</label>
			)}

			{selected && getMediaRenderer()}

			<Dialog
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				title={uctrans("media.select_:media", {}, { media: `media.${props.type}.singular` })}
				width={1000}>
				<MediaLibrary mode={MediaLibrary.MODE_SELECTOR} type={props.type} onSelect={handleSelect} />
			</Dialog>
		</>
	);
}

MediaSelector.propTypes = {
	type: PropTypes.oneOf([
		MediaLibrary.TYPE_IMAGE,
		MediaLibrary.TYPE_VIDEO,
		MediaLibrary.TYPE_DOCUMENT,
		MediaLibrary.TYPE_ANIMATION,
	]),
	selected: PropTypes.object,
	onSelect: PropTypes.func.isRequired,
	buttonLabel: PropTypes.string,
	disabled: PropTypes.bool,
	maxFileSizeInBytes: PropTypes.number,
};

MediaSelector.defaultProps = {
	type: MediaLibrary.TYPE_IMAGE,
	selected: null,
	buttonLabel: null,
	disabled: false,
};
