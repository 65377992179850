import { useState } from "react";
import { can, route } from "../../../../../../js/helpers";
import { format } from "../../../../../../js/lib/Date";
import { trans, uctrans } from "../../../../../../js/lib/Translator";
import Tippy from "@tippyjs/react";
import SelectFilter from "../../../../../../js/react/components/general/overview/SelectFilter";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import Translate from "../../../../../../js/react/components/general/Translate";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../../js/react/components/general/overview/SearchBarFilter";
import IconButton from "../../../../../../js/react/components/general/IconButton";

export default function NewsitemOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
		create_link: window.data.newsitem_data.create_link,
		index_link: window.data.newsitem_data.index_link,
		permissions: window.data.newsitem_data.permissions,
	});

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("general.cms") }]} />

			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="cms.newsitems.plural" />
				</h1>
				{overviewData.create_link && (
					<div>
						<IconButton
							href={overviewData.create_link}
							content={uctrans("general.add_:item", {}, { item: "cms.newsitems.singular" })}
							primary
						/>
					</div>
				)}
			</div>
			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={overviewData.index_link}
				modelTranslations={{ plural: uctrans("cms.newsitems.plural"), singular: uctrans("cms.newsitems.singular") }}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
						<SelectFilter
							className="form-1-4"
							name="marketing_theme_id"
							label={uctrans("marketing.themes.singular")}
							options={overviewData.overview.metadata.filterdata.marketing_themes_options}
							collapsible
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={1}
							renderHeaderContent={() => ""}
							renderCellContent={newsitem => {
								switch (newsitem.publish_status) {
									case 1:
										return (
											<Tippy
												placement="right"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("cms.publish_statuses.1")}>
												<span>
													<EvaIcon type="checkmark-circle-2" fill="#6FAD2B" height="18" width="18" />
												</span>
											</Tippy>
										);
									case 2:
										return (
											<Tippy
												placement="right"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("cms.publish_statuses.2")}>
												<span>
													<EvaIcon type="close-circle" fill="#BF3030" height="18" width="18" />
												</span>
											</Tippy>
										);
									case 3:
										if (newsitem.published_now) {
											return (
												<Tippy
													placement="right"
													arrow
													animation="perspective"
													duration="400"
													content={`${uctrans("cms.publish_statuses.3")} ${
														newsitem.published_from ? format(newsitem.published_from, "dd-MM-y") : "..."
													} - ${newsitem.published_to ? format(newsitem.published_to, "dd-MM-y") : "..."}`}>
													<span>
														<EvaIcon type="clock" fill="#34D0B6" height="18" width="18" />
													</span>
												</Tippy>
											);
										} else {
											return (
												<Tippy
													placement="right"
													arrow
													animation="perspective"
													duration="400"
													content={`${uctrans("cms.publish_statuses.3")} ${
														newsitem.published_from ? format(newsitem.published_from, "dd-MM-y") : "..."
													} - ${newsitem.published_to ? format(newsitem.published_to, "dd-MM-y") : "..."}`}>
													<span>
														<EvaIcon type="clock" fill="#FF9D40" height="18" width="18" />
													</span>
												</Tippy>
											);
										}
									default:
										return <EvaIcon type="question-mark-circle" fill="#c3c3c3" height="18" width="18" />;
								}
							}}
						/>
						<DataColumn
							width={4}
							sortable="date"
							renderHeaderContent={() => uctrans("cms.newsitems.fields.date")}
							renderCellContent={newsitem => (newsitem.date ? format(newsitem.date, "dd-MM-y") : "...")}
						/>
						<DataColumn
							width={15}
							sortable="title"
							renderHeaderContent={() => uctrans("cms.newsitems.fields.title")}
							renderCellContent={newsitem => (
								<a href={newsitem.edit_link} className="row-title link">
									{newsitem.title}
								</a>
							)}
						/>
						<DataColumn
							width={3}
							sortable="marketing_theme_id"
							renderHeaderContent={() => uctrans("marketing.themes.singular")}
							renderCellContent={newsletter => (newsletter.marketing_theme ? newsletter.marketing_theme : "-")}
						/>
						{can("cms.newsitem", "view") && (
							<DataColumn
								width={3}
								renderHeaderContent={() => ""}
								renderCellContent={(newsitem, overview) => (
									<div className="overview-actions flex">
										<a
											rel="noopener noreferrer"
											className="mr-2"
											href={route("crm.cms.newsitems.preview", newsitem.id)}
											target="_blank">
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("cms.pages.preview")}>
												<span>
													<EvaIcon type="navigation-2" fill="#249286" height="17" width="17" />
												</span>
											</Tippy>
										</a>
										{can("cms.newsitem", "create") && (
											<a className="mr-2" href={route("crm.cms.newsitems.create-copy", newsitem.id)}>
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("general.add_:item", {
														item: trans("general.copy_of_:item", {}, { item: "cms.newsitems.singular" }),
													})}>
													<span>
														<EvaIcon className="p-1 flex" type="copy-outline" fill="#6cb2eb" height="17" width="17" />
													</span>
												</Tippy>
											</a>
										)}
										{can("cms.newsitem", "delete") && newsitem.delete_link && (
											<DeleteColumnContent onClick={() => overview.askDelete(newsitem)} />
										)}
									</div>
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
