import Tippy from "@tippyjs/react";
import axios from "axios";
import { Component } from "react";
import classNames from "classnames";
import { route } from "../../../../../js/helpers";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import ABNLogo from "../../../../../static/img/abn-amro-logo.svg";
import ABNLogoSmall from "../../../../../static/img/abn-amro-logo-small.svg";

class Menu extends Component {
	constructor(props) {
		super(props);

		this.breakOnWidth = 1200;
		this.menuState = window.data.menuState;
		this.menuClosed = this.menuState === "closed";

		const collapsed =
			window.data.menuState !== null
				? window.innerWidth < this.breakOnWidth || this.menuClosed
				: window.innerWidth < this.breakOnWidth;

		let parentMenuItemWithActiveMenuItem = null;
		if (window.data.menuItems) {
			window.data.menuItems.forEach(menuHead => {
				if (menuHead.items) {
					menuHead.items.forEach(menuItem => {
						if (this.hasMenuItemActiveSubMenuItem(menuItem)) {
							parentMenuItemWithActiveMenuItem = menuItem;
						}
					});
				}
			});
		}
		const openedMenuItem = collapsed ? null : parentMenuItemWithActiveMenuItem;

		this.state = {
			menuItems: window.data.menuItems,
			parentMenuItemWithActiveMenuItem,
			openedMenuItem,
			collapsed,
		};

		this.handleWindowResize = this.handleWindowResize.bind(this);
		this.onLinkClickHandler = this.onLinkClickHandler.bind(this);
		this.setMenuState = this.setMenuState.bind(this);
	}

	handleWindowResize() {
		if (
			window.innerWidth >= this.breakOnWidth &&
			(this.menuState !== null ? this.state.collapsed && !this.menuClosed : this.state.collapsed)
		) {
			this.setState({
				collapsed: false,
				openedMenuItem: this.state.parentMenuItemWithActiveMenuItem,
			});
		} else if (
			window.innerWidth < this.breakOnWidth &&
			(this.menuState !== null ? !this.state.collapsed && this.menuClosed : !this.state.collapsed)
		) {
			this.setState({
				collapsed: true,
				openedMenuItem: null,
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.handleWindowResize);
	}

	componentDidMount() {
		window.toggleMenuCollapse = async () => {
			if (this.state.collapsed) {
				this.setState({
					collapsed: false,
					openedMenuItem: this.state.parentMenuItemWithActiveMenuItem,
				});
				this.menuState = await this.setMenuState("open");
				this.menuClosed = this.menuState === "closed";
			} else {
				this.setState({
					collapsed: true,
					openedMenuItem: null,
				});
				this.menuState = await this.setMenuState("closed");
				this.menuClosed = this.menuState === "closed";
			}
		};

		window.addEventListener("resize", this.handleWindowResize);
	}

	async setMenuState(newState) {
		try {
			const result = await axios.post(
				route("crm.menu-state", {
					newState,
				}),
			);

			if (typeof result.data.menuState !== "undefined") {
				return result.data.menuState;
			}
		} catch (error) {
			console.error(error);
		}
	}

	onLinkClickHandler(menuItem) {
		if (menuItem === this.state.openedMenuItem) {
			this.setState({
				openedMenuItem: null,
			});
		} else {
			this.setState({
				openedMenuItem: menuItem,
			});
		}
	}

	active(menuItem) {
		if (typeof window.data.agendaNamespace !== "undefined" && menuItem.key === "agendaitems") {
			return true;
		} else if (typeof window.data.agendaNamespace === "undefined") {
			return menuItem.key === window.data.activeMenuItem;
		}
	}

	menuItemClassNames(menuItem) {
		return classNames("menu-item", {
			active: this.active(menuItem),
			"has-submenu": menuItem.subMenuItems && menuItem.subMenuItems.length > 1,
			"has-active-submenu": this.hasMenuItemActiveSubMenuItem(menuItem),
			"is-opened": this.state.openedMenuItem === menuItem,
		});
	}

	hasMenuItemActiveSubMenuItem(menuItem) {
		if (menuItem.subMenuItems && menuItem.subMenuItems.length) {
			return menuItem.subMenuItems.some(submenuItem => submenuItem.key === window.data.activeMenuItem);
		}
		return false;
	}

	submenuItemClassNames(menuItem) {
		return classNames("submenu-item", {
			active: menuItem.key === window.data.activeMenuItem,
		});
	}

	render() {
		const badgeStyling =
			"inline-flex items-center justify-center ml-2 px-2 py-1 text-xs font-bold leading-normal text-red-100 bg-red-dark rounded-full";
		const sidebarClassNames = classNames("sidebar", {
			collapsed: this.state.collapsed,
		});

		this.state.collapsed
			? document.getElementById("menu-toggle").classList.add("collapsed")
			: document.getElementById("menu-toggle").classList.remove("collapsed");

		return (
			<div className={sidebarClassNames}>
				<a href="/" className="inline-block p-6 logo">
					<div className="small-logo">
						<img src={ABNLogoSmall} />
					</div>
					<div className="full-logo">
						<img src={ABNLogo} />
					</div>
				</a>
				<div className="container mx-auto relative main-menu">
					{this.state.menuItems.map(menuHead => (
						<div key={menuHead.head}>
							<h6 className="menu-chapter">{menuHead.head}</h6>
							<ul>
								{menuHead.items.map((menuItem, index) => (
									<li className={this.menuItemClassNames(menuItem)} key={index}>
										<div className="relative overflow-hidden">
											<Tippy
												key={index}
												placement="right"
												arrow
												animation="perspective"
												duration="400"
												content={menuItem.name}
												disabled={!this.state.collapsed}>
												{"subMenuItems" in menuItem ? (
													menuItem.subMenuItems.length > 1 ? (
														<a className="inline-block" onClick={() => this.onLinkClickHandler(menuItem)}>
															<EvaIcon
																type={menuItem.icon}
																className="inline-block absolute left-0 pl-8"
																fill="#ffffff75"
																height="22"
																width="22"
															/>

															<div className="menu-item-text flex-shrink-0 align-middle ">
																{menuItem.name}
																{menuItem.count > 0 && <span className={badgeStyling}>{menuItem.count}</span>}
															</div>
														</a>
													) : (
														<a
															className="inline-block"
															target={menuItem.new_tab ? "_blank" : "_self"}
															href={menuItem.subMenuItems[0].route}
															rel="noreferrer">
															<EvaIcon
																type={menuItem.subMenuItems[0].icon}
																className="inline-block absolute left-0 pl-8"
																fill="#ffffff75"
																height="22"
																width="22"
															/>
															<div className="menu-item-text flex-shrink-0 align-middle ">
																{menuItem.subMenuItems[0].name}
																{menuItem.count > 0 && <span className={badgeStyling}>{menuItem.count}</span>}
															</div>
														</a>
													)
												) : (
													<a
														className="inline-block"
														href={menuItem.route}
														target={menuItem.new_tab ? "_blank" : "_self"}
														rel="noreferrer">
														<EvaIcon
															type={menuItem.icon}
															className="inline-block absolute left-0 pl-8"
															fill="#ffffff75"
															height="22"
															width="22"
														/>
														<div className="menu-item-text flex-shrink-0 align-middle ">
															{menuItem.name}
															{menuItem.count > 0 && <span className={badgeStyling}>{menuItem.count}</span>}
														</div>
													</a>
												)}
											</Tippy>
										</div>
										{menuItem === this.state.openedMenuItem &&
											menuItem.subMenuItems &&
											menuItem.subMenuItems.length > 1 && (
												<div className="submenu">
													<ul>
														{menuItem.subMenuItems.map((subMenuItem, index) => (
															<li className={this.submenuItemClassNames(subMenuItem)} key={index}>
																<a
																	href={subMenuItem.route}
																	target={subMenuItem.new_tab ? "_blank" : "_self"}
																	rel="noreferrer">
																	{!!subMenuItem.icon && (
																		<EvaIcon
																			type={subMenuItem.icon}
																			className="inline-block mr-4 pt-2"
																			fill="#ffffff75"
																			height="15"
																			width="15"
																		/>
																	)}
																	{subMenuItem.name}
																</a>
															</li>
														))}
													</ul>
												</div>
											)}
									</li>
								))}
							</ul>
							<br />
						</div>
					))}
				</div>
			</div>
		);
	}
}

export default Menu;

Menu.propTypes = {};
