import { useState } from "react";
import { can, route } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import Navigation from "./Navigation";

export default function PermissionDurationSettingsForm() {
	const [permissionDuration, setPermissionDuration] = useState(window.data.permission_duration || []);

	const canEdit = can("client_consent.client_lead_setting", "update");
	const onSuccess = response => {
		setPermissionDuration(response.data.permission_duration);
	};

	return (
		<AjaxForm
			method="PUT"
			submitUrl={route("crm.permission-duration-settings.update")}
			loaderText={uctrans(
				"general.:items_are_being_saved",
				{},
				{ items: "client_consents.permission_duration.plural" },
			)}
			successText={uctrans("general.saved_:items", {}, { items: "client_consents.permission_duration.plural" })}
			data={{ permission_duration: permissionDuration }}
			onSuccess={onSuccess}>
			<Navigation selectedTab="permissionDuration" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("client_consents.permission_duration.plural")}</h1>
			</div>
			<div className="form-full">
				<LabeledTextInput
					name="permission_duration"
					value={permissionDuration}
					onChange={event => {
						const { target } = event;
						setPermissionDuration(target.value);
					}}
					label="client_consents.permission_duration.fields.permission_duration"
					required
				/>

				{canEdit && (
					<>
						<br />
						<br />
						<SubmitBar item="client_consents.permission_duration.plural" />
					</>
				)}
			</div>
		</AjaxForm>
	);
}
