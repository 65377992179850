import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";

export default function ClientTreatmentInvitersSelector(props) {
	const [inviters, setInviters] = useState(props.inviters);

	useEffect(() => {
		if (typeof props.inviters !== "undefined") {
			setInviters(props.inviters);
		}
	}, [props.inviters]);

	const handleChange = useCallback(
		inviters => {
			props.onChange(inviters);
			setInviters(inviters);
		},
		[props],
	);

	const handleSelectChange = (userId, selected) => {
		if (selected) {
			// selected, add
			const newInviters = [
				...inviters,
				{
					user_id: userId,
				},
			];
			handleChange(newInviters);
		} else {
			// unselected, so remove
			const newInviters = [...inviters];
			handleChange(newInviters.filter(inviter => Number(inviter.user_id) !== Number(userId)));
		}
	};

	const handleAmountChange = (userId, amount) => {
		const newInviters = [...inviters];
		const index = inviters.findIndex(inviter => Number(inviter.user_id) === Number(userId));
		newInviters[index] = {
			...newInviters[index],
			amount,
		};
		handleChange(newInviters);
	};

	const findUser = userId => inviters.find(inviter => Number(inviter.user_id) === Number(userId));
	const inviterAmount = userId => {
		const inviter = inviters.find(inviter => Number(inviter.user_id) === Number(userId));
		if (typeof inviter !== "undefined") {
			return inviter.amount;
		}
		return null;
	};

	const disabled = user => {
		if (typeof findUser(user.value) === "undefined") {
			return true;
		} else if (props.disabled) {
			return true;
		}
		return false;
	};

	return (
		<div className="bg-primary-lightest items-center px-3 py-3 my-2 inline-block w-full">
			<div className="form-full">
				<div className=" w-full">
					{props.users.map(user => (
						<div key={user.value} className=" w-1/2 float-left block">
							<div key={user.value} className="  flex justify-between">
								<Checkmark
									disabled={props.disabled}
									key={user.value}
									value={user.value}
									name={user.value}
									label={user.label}
									checked={typeof findUser(user.value) !== "undefined"}
									onChange={e => handleSelectChange(user.value, e.target.checked)}
								/>
								{!props.freeToInvite && (
									<div className="w-3/12 pr-2">
										<input
											type="number"
											value={inviterAmount(user.value) || ""}
											disabled={disabled(user)}
											onChange={e => handleAmountChange(user.value, e.target.value)}
										/>
									</div>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
ClientTreatmentInvitersSelector.propTypes = {
	inviters: PropTypes.array,
	onChange: PropTypes.func,
	users: PropTypes.array,
	freeToInvite: PropTypes.number,
	disabled: PropTypes.bool,
};

ClientTreatmentInvitersSelector.defaultProps = {
	disabled: false,
};
