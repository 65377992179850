import cloneDeep from "lodash/cloneDeep";
import { useState, useEffect } from "react";
import Nav from "../../../../../../js/lib/Nav";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import SingleSelectAutoComplete from "../../../../../../js/react/components/general/autocomplete/SingleSelectAutoComplete";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import { route } from "../../../../../../js/helpers";
import { trans, uctrans } from "../../../../../../js/lib/Translator";
import Select from "../../../../../../js/react/components/general/Select";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import Translate from "../../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";
import useSelectHandler from "../../../../../../js/react/hooks/useSelectHandler";
import { format } from "../../../../../../js/lib/Date";
import FormattedNumberText from "../FormattedNumberText";

export default function GiveawayReport() {
	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);
	const [overviewUpdatedAt, setOverviewUpdatedAt] = useState(new Date().getTime());
	const [titleData, setTitleData] = useState(null);
	const [totalData, setTotalData] = useState(null);
	const [loading, setLoading] = useState(false);

	const formInfo = window.data.formInfo ? cloneDeep(window.data.formInfo) : [];

	const selectHandler = useSelectHandler(setFormValues);
	const genericHandler = useGenericHandler(setFormValues);

	useEffect(() => {
		setOverviewUpdatedAt(new Date().getTime());
	}, [formValues]);

	const setExternalData = response => {
		setLoading(true);
		if (response) {
			if (response.title) {
				setLoading(false);
				setTitleData(response.title);
			}
			if (response.total) {
				setLoading(false);
				setTotalData(response.total);
			}
		}
	};

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans("report.report")} - {uctrans("report.report_types.giveaway")}
				</h1>
			</div>
			<div className="w-full flex">
				<div className="w-1/2 flex border-gray mb-6 mr-3">
					<div className="w-1/2">
						<div className="w-full pr-2">
							<label htmlFor="year">{uctrans("report.year")}</label>
							<Select
								name="year"
								value={formInfo.yearOptions.filter(({ value }) => `${value}` === `${formValues.year}`)}
								options={formInfo.yearOptions}
								isClearable
								onChange={selectHandler}
							/>
						</div>
					</div>
					<div className="w-1/2">
						<div className="w-full pr-2">
							<label htmlFor="relationmanager">{uctrans("intermediaries.fields.relationmanager")}</label>
							<Select
								name="relationmanager"
								value={formInfo.relationmanagerOptions.filter(
									({ value }) => `${value}` === `${formValues.relationmanager}`,
								)}
								options={formInfo.relationmanagerOptions}
								onChange={selectHandler}
							/>
						</div>
					</div>
				</div>
				<div className="w-1/4 border-gray" />
				<div className="w-1/4 border-gray">
					{totalData && !loading && (
						<table className="rapportage_table totaal_rapportage_table reporttable mt-2">
							<thead>
								<tr>
									<th colSpan="1">{uctrans("report.total")}</th>
								</tr>
								<tr className="head2">
									<td style={{ textAlign: "center" }}>
										<span>{uctrans("report.giveaway.total_costs")}</span>
									</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="font-bold" style={{ textAlign: "center" }}>
										<FormattedNumberText prefix="€ " value={totalData ? totalData.total_costs : 0} decimalScale={2} />
									</td>
								</tr>
							</tbody>
						</table>
					)}
				</div>
			</div>
			<div className="flex w-1/2">
				<div className="w-2/3">
					<label htmlFor="user_id">
						<Translate content={`${uctrans("intermediaries.singular")}`} />
					</label>
					<SingleSelectAutoComplete
						name="intermediary"
						dataSource={route("crm.companies.search-active").toString()}
						item={formValues.intermediary}
						initialItem={formValues.intermediary}
						isClearable
						onChange={value => genericHandler(value ? value.value : null, "intermediary")}
						placeholder={uctrans("general.type_to_select_:item", {
							item: trans("intermediaries.singular"),
						})}
						async
					/>
				</div>
				<div className="w-1/3 ml-5 mt-5"></div>
			</div>

			<AbnOverview
				indexUrl={route("crm.report.giveaway.data")}
				dataUpdatedAt={overviewUpdatedAt}
				reloadedData={setExternalData}
				extraQuerystringParameters={formValues}
				modelTranslations={{
					plural: uctrans("report.results"),
					singular: uctrans("report.results"),
				}}
				renderHeader={overview => (
					<IconButton
						icon="file-text-outline"
						onClick={() =>
							Nav.go(
								route("crm.report.giveaway.generate-csv", { ...overview.getParameters(), ...formValues, limit: 0 }),
							)
						}
						content={uctrans("report.generate_csv")}
						primary
					/>
				)}
				renderBody={() => (
					<>
						{!!loading && (
							<div className="flex justify-center">
								<Spinner width={50} />
							</div>
						)}
						{!loading && (
							<div>
								<div className="w-full mt-2 text-white flex bg-primary p-2">
									<span className="ml-1">{titleData ? titleData : ""}</span>
								</div>
								<DataTable>
									<DataColumn
										width={3}
										sortable="contactperson"
										renderHeaderContent={() => uctrans("contactpersons.singular")}
										renderCellContent={row =>
											row.contactperson ? <span className="text-sm">{row.contactperson}</span> : "-"
										}
									/>
									<DataColumn
										width={3}
										sortable="intermediary"
										renderHeaderContent={() => uctrans("intermediaries.singular")}
										renderCellContent={row =>
											row.intermediary ? <span className="text-sm">{row.intermediary}</span> : "-"
										}
									/>
									<DataColumn
										width={2}
										sortable="user"
										renderHeaderContent={() => uctrans("users.singular")}
										renderCellContent={row => (row.user ? <span className="text-sm">{row.user}</span> : "-")}
									/>
									<DataColumn
										width={2}
										sortable="relationmanager"
										renderHeaderContent={() => uctrans("intermediaries.fields.relationmanager_id")}
										renderCellContent={row =>
											row.relationmanager ? <span className="text-sm">{row.relationmanager}</span> : "-"
										}
									/>
									<DataColumn
										width={2}
										sortable="date"
										renderHeaderContent={() => uctrans("report.giveaway.date")}
										renderCellContent={row =>
											row.date ? <span className="text-sm">{format(row.date, "dd-MM-y")}</span> : "-"
										}
									/>
									<DataColumn
										width={3}
										sortable="name"
										renderHeaderContent={() => uctrans("report.giveaway.name")}
										renderCellContent={row => (row.name ? <span className="text-sm">{row.name}</span> : "-")}
									/>
									<DataColumn
										width={1}
										sortable="costs"
										renderHeaderContent={() => uctrans("report.giveaway.costs")}
										renderCellContent={row =>
											row.costs ? (
												<span className="text-sm">
													<FormattedNumberText prefix="€ " value={row.costs} decimalScale={2} />
												</span>
											) : (
												"-"
											)
										}
									/>
								</DataTable>
							</div>
						)}
					</>
				)}
			/>
		</>
	);
}
