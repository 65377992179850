import axios from "axios";
import { useEffect, useState } from "react";
import { route } from "../../../../../js/helpers";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import Spinner from "../../../../../js/react/components/general/Spinner";
import AccountSupportActions from "../accountsupport/AccountSupportActions";
import AsExportsDashboardBlock from "./AsExportsBlock";
import BirthdayBlock from "./BirthdayBlock";
import BounceEmailsBlock from "./BounceEmailsBlock";
import ExternalMailBlock from "./ExternalMailBlock";
import LastUpdatesBlock from "./LastUpdatesBlock";
import NotificationsBlock from "./NotificationsBlock";
import SignalBlock from "./SignalBlock";
import Tabs from "./Tabs";
import RandomCheckDashboardBlock from "../randomChecks/RandomCheckDashboardBlock";

export default function AccountsupportDashboard() {
	const [loading, setLoading] = useState(false);
	const { dashboardScope } = window.data;

	const clientConsentsData = window.data.clientConsents ? window.data.clientConsents : [];
	const agendaItemData = window.data.agendaItemData
		? window.data.agendaItemData
		: { takeonTraject: [], otherBusiness: [] };
	const randomChecks = window.data.randomChecks ? window.data.randomChecks : [];

	const [birthdayData, setBirthdayData] = useState([]);
	const [externalMailsData, setExternalMailsData] = useState({});
	const [signals, setSignals] = useState([]);
	const [notifications, setNotifications] = useState([]);
	const [lastUpdates, setLastUpdates] = useState([]);

	useEffect(() => {
		loadData(dashboardScope);
	}, [dashboardScope]);

	const loadData = dashboardScope => {
		setLoading(true);
		axios
			.post(route("crm.dashboard.accountsupport.data"), dashboardScope)
			.then(response => {
				setBirthdayData(response.data.birthdayData ? response.data.birthdayData : []);
				setExternalMailsData(response.data.externalMailsData ? response.data.externalMailsData : []);
				setSignals(response.data.signals ? response.data.signals : []);
				setNotifications(response.data.notifications ? response.data.notifications : []);
				setLastUpdates(response.data.lastUpdates ? response.data.lastUpdates : []);

				setLoading(false);
			})
			.catch(error => {
				console.error(`Errors fetching dashboard data - ${error}`);
			});
	};

	const rememberCollapsed = async (collapsedKey, collapsed) => {
		try {
			await axios.put(route(`crm.dashboard.collapsed-dashboard-blocks`), {
				blockKey: collapsedKey,
				collapsed,
			});
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<>
			<Tabs selectedTab="accountsupport-dashboard" />

			<div className="page-title-container spacer">
				<h1 className="page-title">
					{uctrans("dashboard.singular")} {!!loading && <Spinner width={25} />}
				</h1>
				<AccountSupportActions />
			</div>

			<div className="container flex flex-col md:flex-row justify-between flex-wrap">
				<div className="two-blocks vertical-spacer spacer flex">
					<div className="bg-grey-lightest shadow rounded flex flex-col w-full">
						<div className="shadow rounded-t-lg px-6 py-6 flex justify-between">
							<div>
								<h5 className="mb-0">{uctrans("dashboard.takeon_traject")}</h5>
							</div>
						</div>
						<div className="p-6">
							<div className="mb-2 p-3 flex items-center">
								<div className="w-4/12" />
								<div className="w-2/12 text-center">
									<small>{uctrans("agendaitems.to_do")}</small>
								</div>
								<div className="w-2/12 text-center">
									<small>{uctrans("agendaitems.in_treatment")}</small>
								</div>
								<div className="w-2/12 text-center">
									<small> &#62; {uctrans("agendaitems.sla_in_treatment")}</small>
								</div>
								<div className="w-2/12 text-center">
									<small>&#62; {uctrans("agendaitems.sla_finished")}</small>
								</div>
							</div>

							{agendaItemData.takeonTraject.map((typeData, index) => (
								<div className={`mb-2 p-3 flex items-center ${index % 2 ? "bg-grey-light" : ""}`} key={typeData.type}>
									<div className="w-4/12">
										<h6>
											<a
												className="link font-normal"
												href={`${route("crm.agenda-items.index")}?filters[type]=${typeData.type}&filters[user]=`}>
												{typeData.label}
											</a>
										</h6>
									</div>
									<div className="w-2/12 text-center">{typeData.nrOfNewItems}</div>
									<div className="w-2/12 text-center">{typeData.nrOfItemsInTreatment}</div>
									<div className="w-2/12 text-center">{typeData.nrOfProcessingItems}</div>
									<div className="w-2/12 text-center">{typeData.nrOfOverdueItems}</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="two-blocks vertical-spacer spacer flex">
					<div className="bg-grey-lightest shadow rounded flex flex-col w-full">
						<div className="shadow rounded-t-lg px-6 py-6 flex justify-between">
							<div>
								<h5 className="mb-0">{uctrans("dashboard.other_business")}</h5>
							</div>
						</div>
						<div className="p-6">
							<div className="mb-2 p-3 flex items-center">
								<div className="w-4/12" />
								<div className="w-2/12 text-center">
									<small>{uctrans("agendaitems.to_do")}</small>
								</div>
								<div className="w-2/12 text-center">
									<small>{uctrans("agendaitems.in_treatment")}</small>
								</div>
								<div className="w-2/12 text-center">
									<small> &#62; {uctrans("agendaitems.sla_in_treatment")}</small>
								</div>
								<div className="w-2/12 text-center">
									<small>&#62; {uctrans("agendaitems.sla_finished")}</small>
								</div>
							</div>
							{agendaItemData.otherBusiness.map((typeData, index) => (
								<div className={`mb-2 p-3 flex items-center ${index % 2 ? "bg-grey-light" : ""}`} key={typeData.type}>
									<div className="w-4/12 break-words">
										<h6>
											<a
												className="link font-normal"
												href={`${route("crm.agenda-items.index")}?filters[type]=${typeData.type}&filters[user]=`}>
												{typeData.label}
											</a>
											{typeData.accountSupport === 1 &&
												typeData.nrOfNewItems > 0 &&
												(typeData.type === 8 || typeData.type === 9) && (
													<span className="inline-flex items-center justify-center ml-2 px-2 py-1 text-xs font-bold leading-normal text-white bg-red-dark rounded-full">
														{typeData.nrOfNewItems}
													</span>
												)}
										</h6>
									</div>

									<div className="w-2/12 text-center">{typeData.nrOfNewItems}</div>
									<div className="w-2/12 text-center">{typeData.nrOfItemsInTreatment}</div>
									<div className="w-2/12 text-center">{typeData.nrOfProcessingItems}</div>
									<div className="w-2/12 text-center">{typeData.nrOfOverdueItems}</div>
								</div>
							))}
							<div className="mb-2 p-3 flex items-center" key={clientConsentsData.type}>
								<div className="w-4/12">
									<h6>
										<a
											className="link font-normal"
											href={`${route("crm.client-consents.index")}?filters[view_status]=${
												window.data.client_consent_as_verification_status
											}`}>
											{clientConsentsData.label}
										</a>
									</h6>
								</div>
								<div className="w-2/12 text-center">{clientConsentsData.nrOfOpenItems}</div>
								<div className="w-2/12 text-center">{trans("general.nvt")}</div>
								<div className="w-2/12 text-center">{clientConsentsData.nrOfProcessingItems}</div>
								<div className="w-2/12 text-center">{clientConsentsData.nrOfOverdueItems}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container flex flex-col md:flex-row justify-between flex-wrap">
				<div className="two-third-blocks">
					{!!externalMailsData && (
						<ExternalMailBlock externalMailsData={externalMailsData} rememberCollapsed={rememberCollapsed} />
					)}
					{!!birthdayData.length && (
						<BirthdayBlock
							birthdayData={birthdayData}
							reloadData={() => loadData(dashboardScope)}
							rememberCollapsed={rememberCollapsed}
						/>
					)}
					{!!notifications.length && (
						<NotificationsBlock notifications={notifications} rememberCollapsed={rememberCollapsed} />
					)}
					{!!signals.length && <SignalBlock signals={signals} rememberCollapsed={rememberCollapsed} />}
				</div>
				<div className="three-blocks">
					{randomChecks.length > 0 && (
						<RandomCheckDashboardBlock randomChecks={randomChecks} rememberCollapsed={rememberCollapsed} />
					)}
					<AsExportsDashboardBlock rememberCollapsed={rememberCollapsed} />
					<BounceEmailsBlock rememberCollapsed={rememberCollapsed} />
					{!!lastUpdates.length && (
						<div className=" vertical-spacer spacer flex">
							<LastUpdatesBlock lastUpdates={lastUpdates} />
						</div>
					)}
				</div>
			</div>
		</>
	);
}
