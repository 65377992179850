import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import { uctrans } from "../../../../../js/lib/Translator";
import Select from "../../../../../js/react/components/general/Select";
import Translate from "../../../../../js/react/components/general/Translate";
import find from "lodash/find";
import findIndex from "lodash/findIndex";

const DependencyQuestionSelector = props => {
	const answerOptionIsSelected = answerOptionId => {
		if (!props.selectedQuestion.selected_answer_options) {
			return false;
		}
		return findIndex(props.selectedQuestion.selected_answer_options, ["id", answerOptionId]) !== -1;
	};

	const selectedQuestionHasWarning = question => {
		const selectedQuestion = find(props.selectableQuestions, ["id", question.id]);
		return selectedQuestion && selectedQuestion.dependency_warning;
	};

	const selectedQuestion = () => ({
		value: props.selectedQuestion.id,
		label: props.selectedQuestion.content.replace(/<\/?[^>]+(>|$)/g, ""),
		dependency_warning: props.selectedQuestion.dependency_warning,
		answer_options: props.selectedQuestion.answer_options,
	});

	const selectableQuestions = () =>
		props.selectableQuestions.map(question => ({
			value: question.id,
			label: question.content.replace(/<\/?[^>]+(>|$)/g, ""),
			dependency_warning: question.dependency_warning,
			answer_options: question.answer_options,
		}));

	const updateSelectedDependencyQuestion = selectedDependencyQuestionValue => {
		if (!selectedDependencyQuestionValue) {
			return props.handleSelectedQuestionChange(null);
		}
		const selectedDependencyQuestion = find(props.selectableQuestions, ["id", selectedDependencyQuestionValue.value]);
		if (selectedDependencyQuestion) {
			return props.handleSelectedQuestionChange(selectedDependencyQuestion);
		}
	};

	const handleSelectAnswerOption = (selectedAnswerOptionId, selectedQuestion) => {
		let newSelectedAnswerOptions = cloneDeep(selectedQuestion.selected_answer_options);
		if (!newSelectedAnswerOptions) {
			newSelectedAnswerOptions = [];
		}
		const answerOption = find(selectedQuestion.answer_options, ["id", selectedAnswerOptionId]);
		const alreadySelectedAnswerOptionIndex = findIndex(newSelectedAnswerOptions, ["id", selectedAnswerOptionId]);
		// if its already present remove the answer
		if (alreadySelectedAnswerOptionIndex !== -1) {
			newSelectedAnswerOptions.splice(alreadySelectedAnswerOptionIndex, 1);
		} else {
			newSelectedAnswerOptions.push(answerOption);
		}
		return props.handleSelectedAnswerOptionChange(newSelectedAnswerOptions);
	};

	return (
		<div className="mb-12">
			<Select
				placeholder={uctrans("questionnaires.dependency.select_question")}
				value={selectedQuestion()}
				options={selectableQuestions()}
				onChange={selectedValue => updateSelectedDependencyQuestion(selectedValue)}
			/>
			{props.selectedQuestion && selectedQuestionHasWarning(props.selectedQuestion) && (
				<span className="block mt-3" style={{ color: "red" }}>
					<Translate content="questionnaires.dependency.dependency_warning" />
				</span>
			)}

			{props.selectedQuestion &&
				props.selectedQuestion.answer_options.map(({ ...answer_option }, index) => (
					<div key={index} className="flex md:flex-row mt-3">
						<label className="container-checkbox">
							{answer_option.content}
							<input
								type="checkbox"
								value={answer_option.id}
								checked={answerOptionIsSelected(answer_option.id)}
								onChange={event => handleSelectAnswerOption(answer_option.id, props.selectedQuestion, event)}
							/>
							<span className="checkmark" />
						</label>
					</div>
				))}
		</div>
	);
};

DependencyQuestionSelector.propTypes = {
	selectableQuestions: PropTypes.array,
	selectedQuestion: PropTypes.object,
	handleSelectedQuestionChange: PropTypes.func,
	handleSelectedAnswerOptionChange: PropTypes.func,
};

export default DependencyQuestionSelector;
