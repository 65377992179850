import { useContext } from "react";
import map from "lodash/map";
import reduce from "lodash/reduce";
import { numberFormat } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import TitleBlock from "./components/TitleBlock";
import DossierMutationsLineChart from "./graph/DossierMutationsLineChart";
import RmDashboardHorizontalBarchart from "./graph/RmDashboardHorizontalBarchart";
import RmDashboardPiechart from "./graph/RmDashboardPiechart";
import RmDashboardBlock from "./RmDashboardBlock";
import { RmDashboardContext } from "./RmDashboardContext";
import Tippy from "@tippyjs/react";

export default function ActiveManagementBlock() {
	const { wallet } = useContext(RmDashboardContext);

	const getColumnHeaderName = key => (key === "as" ? uctrans("dashboard.via_as") : key);

	return (
		<RmDashboardBlock
			blockKey="active-management"
			useFetching
			renderHeader={() => (
				<h5 className="mb-0">
					<TitleBlock title={uctrans("dashboard.active_management")} walletType={wallet.type} useIcon={false} />
				</h5>
			)}
			renderContent={blockData =>
				!!blockData[wallet.type] && (
					<>
						<div className="flex w-full">
							<div className="w-10/24 table-styling">
								<div className="w-full p-2 bg-primary text-white font-bold">
									<a href={blockData.client_consents_dashboard_link}>
										{uctrans("dashboard.consent_approved")}
										<span className="inline-block ml-1 mt-1 align-middle">
											<EvaIcon type="arrow-circle-right-outline" fill="#ffffff" height="16" width="16" />
										</span>
									</a>
								</div>
								<div className="flex border-b pb-2">
									<div className="w-4/24 " />
									{map(
										blockData[wallet.type].obtained[blockData.current_year],
										(count, columnName) =>
											columnName !== "total" && (
												<div className="w-6/24 text-center font-bold p-2" key={count}>
													{getColumnHeaderName(columnName)}
												</div>
											),
									)}
									<div className="w-6/24 text-center font-bold p-2">{uctrans("general.total")}</div>
								</div>
								{blockData[wallet.type] &&
									blockData[wallet.type].obtained &&
									map(Object.keys(blockData[wallet.type].obtained).sort().reverse(), year => (
										<div className="flex table-tr" key={year}>
											<div className="w-4/24 text-center ">{year}</div>
											{map(
												blockData[wallet.type].obtained[year],
												(count, columnName) =>
													columnName !== "total" && <div className="w-6/24 text-center">{numberFormat(count)}</div>,
											)}
											<div className="w-6/24 text-center">
												{numberFormat(blockData[wallet.type].obtained[year].total)}
											</div>
										</div>
									))}
							</div>
							<div className="w-7/24 pl-2 -mb-8">
								{!!blockData[wallet.type] && !!blockData[wallet.type].obtained && !!blockData.total.obtained && (
									<RmDashboardPiechart
										walletType={wallet.type}
										walletLabel={wallet.label}
										height={250}
										walletData={map(blockData[wallet.type].obtained[blockData.current_year], (count, columnName) => ({
											label: getColumnHeaderName(columnName),
											value: count,
										})).filter(entity => entity.label !== "total")}
										totalData={map(blockData.total.obtained[blockData.current_year], (count, columnName) => ({
											label: getColumnHeaderName(columnName),
											value: count,
										})).filter(entity => entity.label !== "total")}
									/>
								)}
							</div>
							<div className="w-7/24">
								<h6>{uctrans("dashboard.active_consents")}</h6>

								<div className="flex justify-start">
									<div className="rounded-full dashboard-circle flex items-center justify-center">
										{numberFormat(blockData[wallet.type].currently_active, 0)}
									</div>

									{wallet.type !== "total" && (
										<div className="flex items-center ml-5">
											<ul className="border-l p-2 text-sm">
												<li>{uctrans("dashboard.nationwide")}</li>
												<li>{numberFormat(blockData.total.currently_active, 0)}</li>
											</ul>
										</div>
									)}
								</div>
							</div>
						</div>
						{blockData[wallet.type] &&
							blockData[wallet.type].leads_per_status &&
							blockData[wallet.type].leads_per_type &&
							blockData[wallet.type].leads_per_status_per_type &&
							blockData.lead_types &&
							blockData.lead_statuses && (
								<div className="flex w-full mt-4">
									<div className="w-10/24 table-styling">
										<div className="w-full p-2 bg-primary text-white font-bold">
											<Tippy
												placement="auto"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("client_consents.leads_active_management_description")}>
												<a href={blockData.client_leads_dashboard_link}>
													{uctrans("client_consents.leads_active_management")}
													<span className="inline-block ml-1 mt-1 align-middle">
														<EvaIcon type="arrow-circle-right-outline" fill="#ffffff" height="16" width="16" />
													</span>
												</a>
											</Tippy>
										</div>
										<div className="flex border-b pb-2">
											<div className="w-8/24 " />
											{blockData.lead_statuses.map(status => (
												<div key={status.value} className="w-4/24 text-center font-bold p-2">
													{status.label}
												</div>
											))}
											<div className="w-4/24 text-center font-bold p-2">{uctrans("general.total")}</div>
										</div>
										{blockData.lead_types.map(type => (
											<div className="flex table-tr" key={type.value}>
												<div className="w-8/24 text-center ">{type.label}</div>
												{blockData.lead_statuses.map(status => (
													<div key={status.value} className="w-4/24 text-center">
														{numberFormat(
															blockData[wallet.type].leads_per_status_per_type[type.value]
																? blockData[wallet.type].leads_per_status_per_type[type.value][status.value] ?? 0
																: 0,
														)}
													</div>
												))}
												<div className="w-4/24 text-center">
													{numberFormat(blockData[wallet.type].leads_per_type[type.value] ?? 0)}
												</div>
											</div>
										))}
										<div className="flex table-tr font-bold">
											<div className="w-8/24 text-center ">{uctrans("general.total")}</div>
											{blockData.lead_statuses.map(status => (
												<div key={status.value} className="w-4/24 text-center">
													{numberFormat(blockData[wallet.type].leads_per_status[status.value] ?? 0)}
												</div>
											))}
											<div className="w-4/24 text-center">
												{numberFormat(
													blockData[wallet.type].leads_per_status
														? reduce(blockData[wallet.type].leads_per_status, (a, b) => a + b, 0)
														: 0,
												)}
											</div>
										</div>
									</div>
									<div className="w-7/24 pl-2 -mb-8">
										<RmDashboardPiechart
											walletType={wallet.type}
											walletLabel={wallet.label}
											walletData={blockData.lead_statuses.map(status => ({
												label: status.label,
												value: blockData[wallet.type].leads_per_status[status.value] ?? 0,
											}))}
											totalData={blockData.lead_statuses.map(status => ({
												label: status.label,
												value: blockData.total.leads_per_status[status.value] ?? 0,
											}))}
											colors={["#F3C000", "#009286", "#FF6600"]}
										/>
									</div>
									<div className="w-7/24">
										<RmDashboardHorizontalBarchart
											walletType={wallet.type}
											walletLabel={wallet.label}
											walletData={blockData.lead_types.map(type => ({
												id: type.value,
												label: type.label,
												value: blockData[wallet.type].leads_per_type[type.value] ?? 0,
											}))}
											totalData={blockData.lead_types.map(type => ({
												id: type.value,
												label: type.label,
												value: blockData.total.leads_per_type[type.value] ?? 0,
											}))}
											height={250}
											addPecentageSignAfterDataLabel
											calculatePercentages
											tooltipPointformat="{point.val:,.0f}"
										/>
									</div>
								</div>
							)}

						{blockData[wallet.type] &&
						blockData[wallet.type].dossier_mutations_top5 &&
						blockData[wallet.type].dossier_mutations_top5.total_count &&
						blockData[wallet.type].dossier_mutations_top5.types &&
						blockData[wallet.type].dossier_mutations.types &&
						blockData.total.dossier_mutations.types &&
						blockData[wallet.type].dossier_mutations_per_month ? (
							<div className="flex w-full mt-4">
								<div className="w-10/24 table-styling">
									<div className="w-full p-2 bg-primary text-white font-bold">
										<a href={blockData.dossier_mutations_dashboard_link}>
											{uctrans("dashboard.mutations_top_:x", { x: 5 })}
											<span className="inline-block ml-1 mt-1 align-middle">
												<EvaIcon type="arrow-circle-right-outline" fill="#ffffff" height="16" width="16" />
											</span>
										</a>
									</div>
									{blockData[wallet.type].dossier_mutations_top5.types.map(type => (
										<div className="flex table-tr" key={type.type_id}>
											<div className="w-19/24 text-left ">{type.type_name}</div>
											<div className="w-5/24 text-center ">{numberFormat(type.count)}</div>
										</div>
									))}
									<div className="flex table-tr ">
										<div className="w-19/24 text-left font-bold">{uctrans("general.total")}</div>
										<div className="w-5/24 text-center font-bold">
											{numberFormat(blockData[wallet.type].dossier_mutations_top5.total_count)}
										</div>
									</div>
								</div>
								<div className="w-7/24 pl-2 -mb-8">
									<RmDashboardHorizontalBarchart
										walletType={wallet.type}
										walletLabel={wallet.label}
										walletData={blockData[wallet.type].dossier_mutations.types.map(type => ({
											id: type.type_id,
											label: type.type_name,
											value: type.percentage ?? 0,
										}))}
										totalData={blockData.total.dossier_mutations.types.map(type => ({
											id: type.type_id,
											label: type.type_name,
											value: type.percentage ?? 0,
										}))}
										height={250}
										addPecentageSignAfterDataLabel
										maxItems={6}
										calculatePercentages={false}
										tooltipPointformat="{point.val:,.0f}"
									/>
								</div>
								<div className="w-7/24">
									<DossierMutationsLineChart data={blockData[wallet.type].dossier_mutations_per_month} />
								</div>
							</div>
						) : null}
					</>
				)
			}></RmDashboardBlock>
	);
}
