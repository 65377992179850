import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import { can, route } from "../../../../../js/helpers";
import CollapsibleContent from "../general/CollapsibleContent";
import QuestionnaireCompletionAnswersForm from "../questionnaires/QuestionnaireCompletionAnswersForm";
import Navigation from "./Navigation";

export default function ClientTreatmentQuestionnaireAnswersForm(props) {
	const [formData, setFormData] = useState(
		typeof props.QuestionnaireFormData === "undefined"
			? cloneDeep(window.data.questionnaire_form_data)
			: props.QuestionnaireFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.QuestionnaireStaticData === "undefined"
			? cloneDeep(window.data.questionnaire_static_data)
			: props.QuestionnaireStaticData,
	);

	useEffect(() => {
		if (typeof props.QuestionnaireStaticData !== "undefined") {
			setStaticData(props.QuestionnaireStaticData);
		}
	}, [props.QuestionnaireStaticData]);

	useEffect(() => {
		if (typeof props.QuestionnaireFormData !== "undefined") {
			setStaticData(props.QuestionnaireFormData);
		}
	}, [props.QuestionnaireFormData]);

	const onQuestionnaireCompletionAnswersChanged = answers => {
		const newFormData = { ...formData };
		newFormData.answers = answers;
		setFormData(newFormData);
	};

	return (
		<>
			<Navigation
				selectedTab="client-treatment-evaluations"
				clientTreatment={staticData.client_treatment}
				clientTreatmentTypes={staticData.client_treatment_types}
				loggedInInviter={
					window.data.user
						? staticData.client_treatment.inviters.find(
								inviter => Number(inviter.user_id) === Number(window.data.user.id),
						  )
						: null
				}
				canUpdate={can("client_treatment.client_treatment", "update")}
			/>

			<div className="page-title-container">
				<h1 className="page-title">{`${uctrans("client_treatment.evaluations.fields.evaluation_of_:participant", {
					participant: staticData.participant_label,
				})}`}</h1>

				<div>
					<a
						className="link"
						href={route("crm.client-treatments.evaluations", {
							client_treatment: staticData.client_treatment.id,
						})}>
						{uctrans("general.back_to_overview_:page", {}, { page: "client_treatment.evaluations.plural" })}
					</a>
				</div>
			</div>

			{staticData.participant_id_token && can("client_treatment.client_treatment", "update") && (
				<div>
					<label>{uctrans("client_treatment.evaluations.fields.evaluation_link")}</label>
					<a
						className="link"
						href={route("website.client-treatment-evaluation", {
							participant_id_token: staticData.participant_id_token,
						})}>
						{uctrans("client_treatment.evaluations.fields.to_participant_evaluation")}
					</a>
				</div>
			)}

			<CollapsibleContent initialCollapseState={false} title={uctrans("cooperation_check.fields.questionsanswers")}>
				<QuestionnaireCompletionAnswersForm
					questionnaire={formData.questionnaire}
					questionnaireCompletionAnswerData={formData.questionnaire_completion}
					questionnaireCompletionAnswersChanged={onQuestionnaireCompletionAnswersChanged}
					disabled
				/>
			</CollapsibleContent>
		</>
	);
}
ClientTreatmentQuestionnaireAnswersForm.propTypes = {
	QuestionnaireStaticData: PropTypes.object,
	QuestionnaireFormData: PropTypes.object,
};
