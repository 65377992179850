import Cleave from "cleave.js/dist/cleave-react-node";
import PropTypes from "prop-types";
import { Component } from "react";

class DebouncedNumberFormattedInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			bouncing: false,
		};

		this.timeoutIdentifier = null;

		this.handleChange = this.handleChange.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.bouncing) {
			return prevState;
		} else {
			return {
				...nextProps,
				bouncing: false,
			};
		}
	}

	handleChange(event) {
		this.setState({
			bouncing: true,
		});

		const copiedEvent = Object.assign({}, event);

		if (this.timeoutIdentifier) {
			clearTimeout(this.timeoutIdentifier);
			this.timeoutIdentifier = null;
		}

		this.timeoutIdentifier = setTimeout(() => {
			if (this.props.onChange) {
				this.props.onChange(copiedEvent.target.rawValue);
			}

			this.setState({
				bouncing: false,
			});
		}, this.props.debounceTime);
	}

	render() {
		return (
			<Cleave
				options={{
					numeral: true,
					prefix: this.props.showEuroSign && "€ ",
					rawValueTrimPrefix: true,
					numeralThousandsGroupStyle: "thousand",
					numeralDecimalMark: ",",
					delimiter: ".",
				}}
				id={typeof this.props.id === "undefined" ? this.props.name : this.props.id}
				name={this.props.name}
				value={this.props.value}
				onChange={this.handleChange}
				disabled={this.props.disabled}
				autoComplete={this.props.autocomplete ? "on" : "off"}
				placeholder={this.props.placeholder}
			/>
		);
	}
}

export default DebouncedNumberFormattedInput;

DebouncedNumberFormattedInput.propTypes = {
	name: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	debounceTime: PropTypes.number,
	autocomplete: PropTypes.bool,
	id: PropTypes.string,
	disabled: PropTypes.bool,
	showEuroSign: PropTypes.bool,
};

DebouncedNumberFormattedInput.defaultProps = {
	debounceTime: 400,
	autocomplete: false,
	showEuroSign: false,
	disabled: false,
};
