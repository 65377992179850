import PropTypes from "prop-types";
import { uctrans } from "../../../../../../js/lib/Translator";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import ImageThumbnailFileInput from "../../../../../../js/react/components/general/form/ImageThumbnailFileInput";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";
import FileInput from "../../forms/FileInput";
import MediaLibrary from "../library/MediaLibrary";
import MediaSelector from "./MediaSelector";

export default function CopyImageSelector({
	imageKey,
	formData,
	setFormData,
	maxFiles,
	asThumbnail,
	existingFileDownloadRoute,
	storeRoute,
	newFileDownloadRoute,
	forNewsletter,
	disabled,
}) {
	const imageObjectKey = `${imageKey}_object`;

	const fileExists = formData[imageKey] && formData[imageKey].length > 0;
	const objectExists = !!formData[imageObjectKey];

	const genericHandler = useGenericHandler(setFormData);

	const onFileChanged = files => {
		const newFormData = { ...formData };
		newFormData[`show_copy_${imageKey}_to_folder`] = files.length > 0;
		newFormData[imageKey] = files;
		setFormData(newFormData);
	};

	return (
		<div className={objectExists || fileExists ? "" : "inline-flex"}>
			{!objectExists && (
				<div className="mr-3">
					{asThumbnail ? (
						<ImageThumbnailFileInput
							public
							storeRoute={storeRoute}
							newFileDownloadRoute={newFileDownloadRoute}
							existingFileDownloadRoute={existingFileDownloadRoute}
							maxFiles={maxFiles}
							image={formData[imageKey]}
							onChange={onFileChanged}
							onAfterUpload={onFileChanged}
							disabled={disabled}
							small={forNewsletter}
						/>
					) : (
						<FileInput
							public
							storeRoute={storeRoute}
							newFileDownloadRoute={newFileDownloadRoute}
							existingFileDownloadRoute={existingFileDownloadRoute}
							maxFiles={maxFiles}
							initialFiles={formData[imageKey]}
							onChange={onFileChanged}
							onAfterUpload={onFileChanged}
							fileType={`${forNewsletter ? "small_" : ""}image`}
							disabled={disabled}
						/>
					)}

					{formData[`show_copy_${imageKey}_to_folder`] && (
						<Checkmark
							disabled={disabled}
							label={uctrans("media.copy_image_to_folder")}
							checked={!!formData[`copy_${imageKey}_to_folder`]}
							onChange={e => {
								genericHandler(e.target.checked, `copy_${imageKey}_to_folder`);
							}}
						/>
					)}
				</div>
			)}

			{!fileExists && (
				<div>
					<MediaSelector
						buttonLabel={uctrans("media.select_existing_file")}
						type={MediaLibrary.TYPE_IMAGE}
						selected={formData[imageObjectKey]}
						onSelect={mediaSelected => {
							genericHandler(mediaSelected, imageObjectKey);
						}}
						disabled={disabled}
						smaller={forNewsletter}
						maxFileSizeInBytes={1_000_000}
					/>
				</div>
			)}
		</div>
	);
}

CopyImageSelector.propTypes = {
	// routes
	storeRoute: PropTypes.string,
	newFileDownloadRoute: PropTypes.string,
	existingFileDownloadRoute: PropTypes.string,
	// image
	imageKey: PropTypes.string.isRequired,
	formData: PropTypes.object.isRequired,
	setFormData: PropTypes.func.isRequired,
	// options
	maxFiles: PropTypes.number,
	asThumbnail: PropTypes.bool,
	disabled: PropTypes.bool,
	forNewsletter: PropTypes.bool,
};

CopyImageSelector.defaultProps = {
	storeRoute: "crm.files.store",
	newFileDownloadRoute: "crm.files.download",
	asThumbnail: false,
	maxFiles: 1,
	disabled: false,
	forNewsletter: false,
};
