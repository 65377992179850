import { useState } from "react";
import { route } from "../../../../../../js/helpers";
import Nav from "../../../../../../js/lib/Nav";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import useInputHandler from "../../../../../../js/react/hooks/useInputHandler";
import TextEditor from "../../forms/textEditor/TextEditor";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";

export default function ThemeForm() {
	const [formData, setFormData] = useState({ ...window.data.form_data });
	const [staticData, setStaticData] = useState({ ...window.data.static_data });
	const readOnly = window.data.read_only;

	const inputHandler = useInputHandler(setFormData);

	const onSuccess = response => {
		if (staticData.creating) {
			Nav.go(route("crm.marketing.themes.index"));
		} else {
			setFormData(response.data.form_data);
			setStaticData(response.data.static_data);
		}
	};

	const handleFormChange = (attributeKey, value) => {
		const newFormData = { ...formData };
		newFormData[attributeKey] = value;
		setFormData(newFormData);
	};

	return (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{
						label: `${uctrans("marketing.singular")}`,
					},
					{
						label: `${uctrans("marketing.themes.plural")}`,
						route: route("crm.marketing.themes.index"),
					},
				]}
			/>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(staticData.creating ? "general.add_:item" : "general.edit_:item", {
						item: uctrans("marketing.themes.singular"),
					})}
				</h1>

				<a className="link" href={route("crm.marketing.themes.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "marketing.themes.plural" })}
				</a>
			</div>
			<AjaxForm
				method={staticData.creating ? "POST" : "PUT"}
				submitUrl={
					staticData.creating
						? route("crm.marketing.themes.store")
						: route("crm.marketing.themes.update", staticData.id)
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "marketing.themes.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "marketing.themes.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage={!!staticData.creating}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-1-4">
								<LabeledTextInput
									name="name"
									type="text"
									value={formData.name ? `${formData.name}` : ""}
									onChange={inputHandler}
									label="marketing.themes.fields.name"
									disabled={readOnly}
									required
								/>
							</div>
						</div>
						<div className="form-container">
							<div className="form-2-4">
								<label>{uctrans("marketing.themes.fields.description")}</label>
								<TextEditor
									value={formData.description}
									onChange={value => handleFormChange("description", value)}
									disabled={readOnly}
								/>
							</div>
							<div className="form-1-4">
								<Checkmark
									disabled={readOnly}
									label={uctrans("marketing.themes.fields.active")}
									name="active"
									checked={formData.active}
									onChange={e => handleFormChange(e.target.name, e.target.checked)}
								/>
							</div>
						</div>
						{!readOnly && (
							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="marketing.themes.singular" />
								</div>
							</div>
						)}
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
