import PropTypes from "prop-types";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";

export default function EngagementScoreDifference({ iconSize, score, scoreToCompare }) {
	return (
		<div className="flex justify-center items-center py-2">
			{score > scoreToCompare && (
				<EvaIcon type="arrow-upward-outline" width={`${iconSize}`} height={`${iconSize}`} fill="#009286" />
			)}

			{/* {score === scoreToCompare && <EvaIcon type="minus-outline" width={iconSize} height={iconSize} fill="#ffce39" />}*/}

			{score < scoreToCompare && (
				<EvaIcon type="arrow-downward-outline" width={`${iconSize}`} height={`${iconSize}`} fill="#ff6600" />
			)}
		</div>
	);
}

EngagementScoreDifference.propTypes = {
	iconSize: PropTypes.number,
	score: PropTypes.number,
	scoreToCompare: PropTypes.number,
};

EngagementScoreDifference.defaultProps = {
	iconSize: 30,
	score: 0,
	scoreToCompare: 0,
};
