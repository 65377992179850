import map from "lodash/map";
import PropTypes from "prop-types";
import { uctrans } from "../../../../../../../js/lib/Translator";
import FormattedNumberText from "../../FormattedNumberText";
import PieChart from "../../graph/PieChart";

export default function LoanDetailResults({
	tableNames,
	tableData,
	title,
	detailType,
	intermediaryChartData,
	ivChartData,
}) {
	const colSpan = 4 + (tableNames.parent ? 2 : 0) + (tableNames.relationmanager ? 1 : 0);

	return (
		<>
			<table className="details_rapportage_table rapportage_table reporttable">
				<thead>
					<tr>
						{/* eslint-disable-next-line no-mixed-operators */}
						<th colSpan={colSpan}>{title ? title : uctrans("report.singular")}</th>
					</tr>
					<tr className="head1">
						<td colSpan="1" />
						{map(tableNames, (name, key) =>
							`${key}` === "relationmanager" || `${key}` === "commercial" ? (
								<td className="groeptitel" key={key}>
									<span>{name}</span>
								</td>
							) : (
								<td colSpan="2" className="groeptitel" key={key}>
									<span>{name}</span>
								</td>
							),
						)}
					</tr>
					<tr className="head2">
						<td>{uctrans(`report.intermediaries.type.${detailType}`)}</td>
						<td className="omzet tussenpersoon">
							Omzet <span>(x1.000)</span>
						</td>
						<td className="percentage tussenpersoon">%</td>

						{!!tableNames.parent && (
							<>
								{" "}
								<td className="omzet organisatie">
									Omzet <span>(x1.000)</span>
								</td>
								<td className="percentage organisatie">%</td>
							</>
						)}
						{!!tableNames.relationmanager && <td className="percentage accountmanager">%</td>}

						<td className="percentage iv">%</td>
					</tr>
				</thead>
				<tbody>
					{tableData ? (
						map(tableData, (row, key) =>
							`${key}` === "total" ? (
								<tr className="totaal">
									<td>{uctrans("report.total")}</td>
									<td className="omzet tussenpersoon">
										<FormattedNumberText
											prefix="€ "
											value={
												tableData.total && tableData.total.company && tableData.total.company.sum
													? tableData.total.company.sum / 1000
													: 0
											}
										/>
									</td>
									<td className="percentage tussenpersoon">100%</td>

									{!!tableNames.parent && (
										<>
											<td className="omzet organisatie">
												<FormattedNumberText
													prefix="€ "
													value={
														tableData.total && tableData.total.parent && tableData.total.parent.sum
															? tableData.total.parent.sum / 1000
															: 0
													}
												/>
											</td>
											<td className="percentage organisatie">100%</td>
										</>
									)}
									{!!tableNames.relationmanager && <td className="percentage accountmanager">100%</td>}
									<td className="percentage iv">100%</td>
								</tr>
							) : (
								<tr>
									<td>{row.label ? row.label : ""}</td>
									<td className="omzet tussenpersoon">
										<FormattedNumberText
											prefix="€ "
											value={
												row.groups && row.groups.company && row.groups.company.sum ? row.groups.company.sum / 1000 : 0
											}
										/>
									</td>
									<td className="percentage tussenpersoon">
										<FormattedNumberText
											percentage
											decimalScale={1}
											value={
												row.groups && row.groups.company && row.groups.company.percentage
													? row.groups.company.percentage
													: 0
											}
										/>
									</td>
									{!!tableNames.parent && (
										<>
											<td className="omzet organisatie">
												<FormattedNumberText
													prefix="€ "
													value={
														row.groups && row.groups.parent && row.groups.parent.sum ? row.groups.parent.sum / 1000 : 0
													}
												/>
											</td>
											<td className="percentage organisatie">
												<FormattedNumberText
													percentage
													decimalScale={1}
													value={
														row.groups && row.groups.parent && row.groups.parent.percentage
															? row.groups.parent.percentage
															: 0
													}
												/>
											</td>
										</>
									)}
									{!!tableNames.relationmanager && (
										<td className="percentage accountmanager">
											<FormattedNumberText
												percentage
												decimalScale={1}
												value={
													row.groups && row.groups.relationmanager && row.groups.relationmanager.percentage
														? row.groups.relationmanager.percentage
														: 0
												}
											/>
										</td>
									)}
									<td className="percentage iv">
										<FormattedNumberText
											percentage
											decimalScale={1}
											value={
												row.groups && row.groups.commercial && row.groups.commercial.percentage
													? row.groups.commercial.percentage
													: 0
											}
										/>
									</td>
								</tr>
							),
						)
					) : (
						<tr>
							<td colSpan={colSpan}>{uctrans("report.no_results")}</td>
						</tr>
					)}
				</tbody>
			</table>

			{!!tableData && (
				<table className="w-full text-center table-fixed mt-5">
					<thead>
						<tr className="head1">
							<td className="font-bold">{title ? title : uctrans("report.singular")}</td>
							<td className="font-bold">{uctrans("report.intermediaries.from_other_intermediaries")}</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<PieChart chartData={intermediaryChartData} />
							</td>
							<td>
								<PieChart chartData={ivChartData} />
							</td>
						</tr>
					</tbody>
				</table>
			)}
		</>
	);
}

LoanDetailResults.propTypes = {
	title: PropTypes.string,
	tableNames: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	tableData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	intermediaryChartData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	ivChartData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	detailType: PropTypes.string,
};
