import PropTypes from "prop-types";
import { useState } from "react";
import map from "lodash/map";
import size from "lodash/size";
import find from "lodash/find";
import matchesProperty from "lodash/matchesProperty";
import { uctrans } from "../../../../../../js/lib/Translator";

export default function CompanyPermits(props) {
	const [permitData] = useState(props.permitData);

	const requiredString = permit => {
		if (find(props.requiredPermits, matchesProperty("id", Number(permit.id)))) {
			return <span className="text-red"> *</span>;
		}
		return null;
	};

	const currentIllegality = () => {
		if (props.missingPermits.length > 0) {
			return (
				<>
					<br />
					<span className="w-full text-md text-red">
						{uctrans(
							"afm_permits.:company_has_not_all_permits",
							{},
							{ company: "companies.statutory.statutory_company" },
						)}
					</span>
					{props.missingPermits.map((permit, key) => (
						<div key={key} className="flex justify-start text-red text-xs">
							<label className="w-1/3">{permit.afm_product.name}</label>
							<div className="mt-1">
								<span>{permit.afm_service.name}</span>
							</div>
						</div>
					))}
				</>
			);
		} else {
			return (
				size(permitData) > 0 && (
					<>
						<br />
						<span style={{ color: "green" }}>
							{uctrans(
								"afm_permits.:company_has_all_permits",
								{},
								{ company: "companies.statutory.statutory_company" },
							)}
						</span>
					</>
				)
			);
		}
	};

	return (
		<>
			{size(permitData) > 0 ? (
				map(permitData, (permits, product) => (
					<div key={product} className="flex justify-start text-xs">
						<label className="flex-1 mr-2">{product}</label>
						<div className="mt-1 flex-1">
							{permits.map((permit, key) => (
								<span key={key}>
									{permit.hasOwnProperty("service") && typeof permit.service === "string"
										? permit.service
										: permit.afm_service.name}
									{requiredString(permit)}
									{Number(key + 1) !== Number(size(permits)) && ", "}
								</span>
							))}
						</div>
					</div>
				))
			) : (
				<span>
					{uctrans(
						"afm_permits.:company_has_no_permits_at_all",
						{},
						{ company: "companies.statutory.statutory_company" },
					)}
				</span>
			)}
			{currentIllegality()}
		</>
	);
}

CompanyPermits.propTypes = {
	permitData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	requiredPermits: PropTypes.array,
	missingPermits: PropTypes.array,
};
