import { useState } from "react";
import { uctrans, trans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import { route } from "../../../../../js/helpers";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";

export default function LoanOverview() {
	const [overview] = useState({ ...window.data.overview });
	const [intermediary] = useState(window.data.intermediary ? { ...window.data.intermediary } : null);
	const [organisation] = useState(window.data.organisation ? { ...window.data.organisation } : null);

	const indexUrl = () => {
		if (intermediary) {
			return route("crm.intermediaries.loans.index", intermediary.id);
		} else if (organisation) {
			return route("crm.organisations.loans.index", organisation.id);
		} else {
			return null;
		}
	};

	return (
		<>
			{intermediary && (
				<>
					<BreadCrumbs
						breadCrumbs={[
							{ label: uctrans("intermediaries.plural"), route: route("crm.intermediaries.index") },
							{ label: intermediary.label },
						]}
					/>
					<TabLinks tabs={intermediary.tabs} activeTab="loans" />
				</>
			)}
			{organisation && (
				<>
					<BreadCrumbs
						breadCrumbs={[
							{ label: uctrans("organisations.plural"), route: route("crm.organisations.index") },
							{ label: organisation.label },
						]}
					/>
					<TabLinks tabs={organisation.tabs} activeTab="loans" />
				</>
			)}
			<div className="page-title-container">
				<h1 className="page-title">
					{intermediary
						? uctrans("general.:item1 from :item2", { item2: intermediary.label }, { item1: "loans.plural" })
						: organisation
						  ? uctrans("general.:item1 from :item2", { item2: organisation.label }, { item1: "loans.plural" })
						  : uctrans("loans.plural")}
				</h1>
			</div>

			<AbnOverview
				initialData={overview}
				indexUrl={indexUrl()}
				modelTranslations={{ plural: trans("loans.plural"), singular: trans("loans.singular") }}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={2}
							sortable="last_mutation"
							renderHeaderContent={() => uctrans("loans.fields.last_mutation")}
							renderCellContent={loan => {
								if (intermediary) {
									return (
										<a
											href={route("crm.intermediaries.loans.show", {
												intermediary: intermediary.id,
												loan: loan.id,
											})}
											className="row-title link">
											{loan.last_mutation}
										</a>
									);
								} else if (organisation) {
									return (
										<a
											href={route("crm.organisations.loans.show", {
												organisation: organisation.id,
												loan: loan.id,
											})}
											className="row-title link">
											{loan.last_mutation}
										</a>
									);
								}
							}}
						/>
						{organisation && (
							<DataColumn
								width={2}
								sortable="company_name"
								renderHeaderContent={() => uctrans("intermediaries.singular")}
								renderCellContent={loan => loan.company_name}
							/>
						)}
						<DataColumn
							width={2}
							sortable="number"
							renderHeaderContent={() => uctrans("loans.fields.number")}
							renderCellContent={loan => (loan.number ? loan.number : "-")}
						/>
					</DataTable>
				)}
			/>
		</>
	);
}
