import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import cloneDeep from "lodash/cloneDeep";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import FormDivider from "../../../../../js/react/components/general/FormDivider";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import UseRadioHandler from "../../../../../js/react/hooks/useRadioHandler";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import Translate from "../../../../../js/react/components/general/Translate";

export default function SegmentForm(props) {
	const [formData, setFormData] = useState(
		typeof props.segmentFormData === "undefined" ? cloneDeep(window.data.segment_form_data) : props.segmentFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.segmentStaticData === "undefined"
			? cloneDeep(window.data.segment_static_data)
			: props.segmentStaticData,
	);

	const inputHandler = useInputHandler(setFormData);
	const radioHandler = UseRadioHandler(setFormData);

	useEffect(() => {
		if (typeof props.segmentFormData !== "undefined") {
			setFormData(props.segmentFormData);
		}
	}, [props.segmentFormData]);

	useEffect(() => {
		if (typeof props.segmentStaticData !== "undefined") {
			setStaticData(props.segmentStaticData);
		}
	}, [props.segmentStaticData]);

	const onSuccess = response => {
		if (!staticData.creating) {
			setFormData(response.data.formvalues);
			setStaticData(response.data.segment_static_data);
		} else {
			Nav.go(response.data.redirect_route);
		}
	};

	return (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{ label: uctrans("general.general_settings") },
					{ label: uctrans("segments.plural"), route: staticData.previous_link },
					{
						label: staticData.creating
							? uctrans("general.add_:item", {}, { item: "segments.singular" })
							: formData.name,
					},
				]}
			/>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(staticData.creating ? "general.add_:item" : "general.edit_:item", {}, { item: "segments.singular" })}
				</h1>
				{staticData.previous_link && (
					<a className="link" href={staticData.previous_link}>
						{uctrans("general.back_to_overview_:page", {}, { page: "segments.plural" })}
					</a>
				)}
			</div>
			<AjaxForm
				method={staticData.save_method}
				submitUrl={staticData.save_link}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "segments.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "segments.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage={staticData.creating}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-1-3">
								<LabeledTextInput
									name="name"
									value={formData.name}
									onChange={inputHandler}
									label="segments.fields.name"
									required
								/>
							</div>

							<FormDivider label={uctrans("segments.fields.criterion")} />

							<div className="form-1-4">
								<label htmlFor="criterion">
									<Translate content="segments.signal_list_based_on_expectation" />
								</label>
								<RadioGroup
									name="criterion"
									value={formData.criterion}
									onChange={value => radioHandler(value, "criterion")}>
									<Radio
										label={staticData.criterion_types.MANUAL.label}
										value={staticData.criterion_types.MANUAL.key}
									/>
									<Radio
										label={staticData.criterion_types.LOAN_COUNT_PER_YEAR.label}
										value={staticData.criterion_types.LOAN_COUNT_PER_YEAR.key}
									/>
								</RadioGroup>
							</div>

							{Number(formData.criterion) === Number(staticData.criterion_types.LOAN_COUNT_PER_YEAR.key) && (
								<>
									<div className="form-full mb-0">
										<div className="form-1-4">
											<LabeledTextInput
												name="mortgage_min_amount"
												value={formData.mortgage_min_amount}
												onChange={inputHandler}
												label="segments.fields.mortgage_min_amount"
												required
											/>
										</div>
									</div>
									<div className="form-full mb-0">
										<div className="form-1-4">
											<LabeledTextInput
												name="mortgage_max_amount"
												value={formData.mortgage_max_amount}
												onChange={inputHandler}
												label="segments.fields.mortgage_max_amount"
												required
											/>
										</div>
									</div>
								</>
							)}

							<FormDivider label={uctrans("segments.fields.description")} />
							<div className="form-1-2">
								<label htmlFor="description">
									<Translate content="segments.fields.description" />
								</label>
								<textarea
									id="description"
									name="description"
									value={formData.description}
									onChange={inputHandler}
									placeholder={uctrans("segments.fields.description")}
									autoComplete="off"
								/>
							</div>

							<div className="form-full">
								<Checkmark
									label={uctrans("segments.fields.show_in_locator")}
									name="show_in_locator"
									checked={formData.show_in_locator}
									onChange={inputHandler}
								/>
							</div>

							<div className="form-full">
								<Checkmark
									label={uctrans("segments.fields.show_in_api")}
									name="show_in_api"
									checked={formData.show_in_api}
									onChange={inputHandler}
								/>
							</div>

							<div className="form-full">
								<Checkmark
									label={uctrans("segments.fields.show_in_newsletter_filter")}
									name="show_in_newsletter_filter"
									checked={formData.show_in_newsletter_filter}
									onChange={inputHandler}
								/>
							</div>

							<div className="form-full">
								<Checkmark
									label={uctrans("segments.fields.show_in_yearly_invites")}
									name="show_in_yearly_invites"
									checked={formData.show_in_yearly_invites}
									onChange={inputHandler}
								/>
							</div>

							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="segments.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
SegmentForm.propTypes = {
	segmentFormData: PropTypes.object,
	segmentStaticData: PropTypes.object,
};
