import { useState } from "react";
import { can, route } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import Translate from "../../../../../../js/react/components/general/Translate";

export default function FixedContentOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("general.cms") }]} />

			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="cms.fixed_content.plural" />
				</h1>
			</div>
			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={overviewData.index_link}
				modelTranslations={{ plural: uctrans("cms.pages.plural"), singular: uctrans("cms.pages.singular") }}
				hideControls
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={20}
							renderHeaderContent={() => uctrans("cms.fixed_content.fields.name")}
							renderCellContent={type =>
								can("cms.fixed_content", "update") ? (
									<a
										href={route("crm.cms.fixed-content.edit", { fixedContentTypeKey: type.key })}
										className="row-title link">
										{type.label}
									</a>
								) : (
									type.label
								)
							}
						/>
					</DataTable>
				)}
			/>
		</>
	);
}
