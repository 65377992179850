import PropTypes from "prop-types";
import { uctrans } from "../../../../../../js/lib/Translator";
import Translate from "../../../../../../js/react/components/general/Translate";
import HighChart from "../../report/graph/HighChart";

const parseDataArray = dataArray => {
	const series = [];
	for (const item in dataArray) {
		if (dataArray.hasOwnProperty(item)) {
			const date = Date.UTC(
				parseInt(item.substring(0, 4), 10),
				parseInt(item.substring(5, 7)) - 1,
				parseInt(item.substring(8, 10), 10),
			);
			series.push([date, parseFloat(dataArray[item])]);
		}
	}
	return series;
};

export default function AppStatisticGraph(props) {
	const getChart = () => {
		const seriesCount = parseDataArray(props.data);
		if (!seriesCount.length) {
			return (
				<div className="font-bold m-2">
					<Translate content="statistics.app.no_graph_data" />
				</div>
			);
		}

		let seriesPercentage = [];
		if (props.includesPercentage) {
			seriesPercentage = parseDataArray(props.percentageData);
		}

		const series = [];
		const yAxis = [];
		series.push({
			name: uctrans("statistics.app.number"),
			data: seriesCount,
			yAxis: 0,
			tooltip: {
				dateTimeLabelFormats: { day: "%A, %e %B %Y" },
				valueDecimals: 0,
			},
		});
		yAxis.push({
			min: 0,
			allowDecimals: false,
			title: { text: uctrans("statistics.app.number") },
		});

		if (props.includesPercentage) {
			series.push({
				name: uctrans("statistics.app.percentage"),
				data: seriesPercentage,
				yAxis: 1,
				tooltip: {
					dateTimeLabelFormats: { day: "%A, %e %B %Y" },
					valueDecimals: 2,
					valueSuffix: "%",
				},
			});
			yAxis.push({
				min: 0,
				max: 100,
				title: { text: uctrans("statistics.app.percentage") },
				labels: {
					formatter() {
						return `${this.value}%`;
					},
				},
				opposite: true,
				gridLineWidth: 0,
			});
		}

		return (
			<HighChart
				chartConfig={{
					colors: ["#009286", "#FFB310"],
					chart: { alignTicks: false },
					xAxis: {
						type: "datetime",
						dateTimeLabelFormats: { day: "%e %b" },
					},
					yAxis,
					series,
					plotOptions: {
						line: {
							marker: { enabled: seriesCount.length <= 31 },
						},
					},
					title: { text: null },
					legend: { enabled: false },
					credits: { enabled: false },
				}}
			/>
		);
	};

	return (
		<div className="w-full text-center">
			<div className="text-primary font-bold text-xl">{props.title}</div>
			<div>
				<Translate content="statistics.app.last_measured" />: <Translate content="statistics.app.number" />:{" "}
				<span className="text-primary font-bold">{props.lastMeasuredNumber}</span>
				{props.includesPercentage && (
					<span>
						{" "}
						- <Translate content="statistics.app.percentage" />:{" "}
						<span className="text-secondary font-bold">{props.lastMeasuredPercentage}%</span>
					</span>
				)}
			</div>
			{getChart()}
		</div>
	);
}

AppStatisticGraph.propTypes = {
	title: PropTypes.string.isRequired,
	includesPercentage: PropTypes.bool.isRequired,
	lastMeasuredNumber: PropTypes.number.isRequired,
	lastMeasuredPercentage: PropTypes.number,
	data: PropTypes.object.isRequired,
	percentageData: PropTypes.object,
};
