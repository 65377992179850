import { useState } from "react";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route, yesNoOptions } from "../../../../../../js/helpers";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../../js/react/components/general/overview/SearchBarFilter";
import SelectFilter from "../../../../../../js/react/components/general/overview/SelectFilter";
import Navigation from "../Navigation";

export default function DossierPartOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<Navigation selectedTab="dossier-parts" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("dossiers.parts.plural")}</h1>
				{can("dossier.dossier_part", "create") && (
					<div>
						<IconButton
							href={route("crm.dossier-settings.dossier-parts.create")}
							content={uctrans("general.add_:item", {}, { item: "dossiers.parts.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.dossier-settings.dossier-parts.index")}
				deleteRouteName="crm.dossier-settings.dossier-parts.delete"
				moveRouteName="crm.dossier-settings.dossier-parts.move"
				useDragAndDrop={can("dossier.dossier_part", "move")}
				hideControls
				modelTranslations={{
					plural: trans("dossiers.check_actions.plural"),
					singular: trans("dossiers.check_actions.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
						<SelectFilter
							className="form-1-4"
							name="statutory"
							label={uctrans("dossiers.parts.fields.statutory")}
							options={yesNoOptions()}
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						{can("dossier.dossier_part", "move") && (
							<DataColumn
								width={1}
								className="draggable-overview-item-handle"
								renderHeaderContent={() => ""}
								renderCellContent={() => ""}
							/>
						)}
						<DataColumn
							width={10}
							sortable="name"
							renderHeaderContent={() => uctrans("dossiers.parts.fields.name")}
							renderCellContent={part => {
								if (can("dossier.dossier_part", "update")) {
									return (
										<a href={route("crm.dossier-settings.dossier-parts.edit", part.id)} className="row-title link">
											{part.name}
										</a>
									);
								} else {
									return part.name;
								}
							}}
						/>
						<DataColumn
							width={5}
							sortable="type"
							renderHeaderContent={() => uctrans("dossiers.parts.fields.type")}
							renderCellContent={part => part.type}
						/>
						<DataColumn
							width={5}
							sortable="field_name"
							renderHeaderContent={() => uctrans("dossiers.parts.fields.field_name")}
							renderCellContent={part => part.field_name}
						/>
						<DataColumn
							width={5}
							sortable="establishment_type"
							renderHeaderContent={() => uctrans("dossiers.parts.fields.statutory")}
							renderCellContent={part => part.statuory}
						/>
						{can("dossier.dossier_part", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(status, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(status)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
