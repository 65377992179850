import { useState } from "react";
import { can, route } from "../../../../../../js/helpers";
import { format } from "../../../../../../js/lib/Date";
import { uctrans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import PeriodicOverviewFilter from "../../../../../../js/react/components/general/overview/PeriodicOverviewFilter";
import SearchBarFilter from "../../../../../../js/react/components/general/overview/SearchBarFilter";
import SelectFilter from "../../../../../../js/react/components/general/overview/SelectFilter";
import Translate from "../../../../../../js/react/components/general/Translate";
import QuestionnaireCompletionForm from "../../questionnaires/QuestionnaireCompletionForm";
import PropTypes from "prop-types";

export default function RelationManagerQuestionnaireCompletionOverview({ overviewData }) {
	const [activeRelationmanagerSurvey, setActiveRelationmanagerSurvey] = useState(null);

	return (
		<>
			{!activeRelationmanagerSurvey && (
				<AbnOverview
					initialData={overviewData.overview}
					indexUrl={overviewData.overviewRoute}
					modelTranslations={{
						plural: uctrans("survey.relationmanager.plural"),
						singular: uctrans("survey.relationmanager.singular"),
					}}
					renderFilters={() => (
						<Filters>
							<SearchBarFilter type="text" name="search" />

							{overviewData.formInfo.questionnaire_completion_statusses && (
								<SelectFilter
									className="form-1-3"
									name="status"
									label={uctrans("questionnaires.questionnaire_completion.fields.status")}
									options={overviewData.formInfo.questionnaire_completion_statusses}
								/>
							)}

							{overviewData.formInfo.relationmanagers && (
								<SelectFilter
									className="form-1-3"
									name="relationmanager"
									label={uctrans("survey.relationmanager.fields.relationmanager_id")}
									options={overviewData.formInfo.relationmanagers}
								/>
							)}

							{overviewData.formInfo && overviewData.formValues && (
								<PeriodicOverviewFilter formInfo={overviewData.formInfo} formValues={overviewData.formValues} />
							)}
						</Filters>
					)}
					renderBody={() => (
						<DataTable>
							<DataColumn
								width={2}
								sortable="created_at"
								renderHeaderContent={() => uctrans("survey.relationmanager.fields.fill_date")}
								renderCellContent={relationManagerSurvey => (
									<a className="row-title link" onClick={() => setActiveRelationmanagerSurvey(relationManagerSurvey)}>
										{relationManagerSurvey.created_at ? format(relationManagerSurvey.created_at, "dd-MM-y") : "-"}
									</a>
								)}
							/>

							<DataColumn
								width={2}
								renderHeaderContent={() => uctrans("survey.relationmanager.fields.relationmanager_id")}
								renderCellContent={relationManagerSurvey => relationManagerSurvey.relationmanager || "-"}
							/>

							<DataColumn
								width={2}
								renderHeaderContent={() => uctrans("questionnaires.questionnaire_completion.fields.contactperson")}
								renderCellContent={relationManagerSurvey => {
									if (relationManagerSurvey.contactperson_label) {
										return relationManagerSurvey.contactperson_url ? (
											<a className="link" href={relationManagerSurvey.contactperson_url}>
												{relationManagerSurvey.contactperson_label}
											</a>
										) : (
											relationManagerSurvey.contactperson_label
										);
									}
									return "-";
								}}
							/>

							<DataColumn
								width={2}
								renderHeaderContent={() => uctrans("questionnaires.questionnaire_completion.fields.company_name")}
								renderCellContent={relationManagerSurvey => {
									if (relationManagerSurvey.company_name) {
										return relationManagerSurvey.company_name;
									}
									return "-";
								}}
							/>

							<DataColumn
								width={2}
								renderHeaderContent={() => uctrans("questionnaires.questionnaire_completion.fields.company_number")}
								renderCellContent={relationManagerSurvey => {
									if (relationManagerSurvey.company_number) {
										return relationManagerSurvey.company_number;
									}
									return "-";
								}}
							/>

							<DataColumn
								width={2}
								renderHeaderContent={() => uctrans("survey.relationmanager.fields.contact_moment_id")}
								renderCellContent={relationManagerSurvey => {
									if (relationManagerSurvey.contactmoment_date) {
										return relationManagerSurvey.contactmoment_url ? (
											<a className="link" href={relationManagerSurvey.contactmoment_url}>
												{format(relationManagerSurvey.contactmoment_date, "dd-MM-y")}
											</a>
										) : (
											relationManagerSurvey.contactmoment_date
										);
									}
									return "-";
								}}
							/>

							<DataColumn
								width={2}
								sortable="status"
								renderHeaderContent={() => uctrans("questionnaires.questionnaire_completion.fields.status")}
								renderCellContent={relationManagerSurvey =>
									relationManagerSurvey.status_label ? relationManagerSurvey.status_label : uctrans("general.unknown")
								}
							/>

							{!overviewData.readOnly && can("questionnaire.relation_manager_survey", "delete") && (
								<DataColumn
									width={1}
									renderHeaderContent={() => ""}
									renderCellContent={(relationManagerSurvey, overview) => (
										<DeleteColumnContent onClick={() => overview.askDelete(relationManagerSurvey)} />
									)}
								/>
							)}
						</DataTable>
					)}
					renderNoResults={() => (
						<Translate
							content="overview.no_:items_to_show"
							transReplaces={{ items: "questionnaires.questionnaire_completion.plural" }}
						/>
					)}
					renderFooter={overview => {
						const filters = overview.getParameters();
						return can("questionnaire.relation_manager_survey", "update") ? (
							<a
								className="button button-primary"
								href={route("crm.surveys.relationmanager-questionnaire.completions.download", {
									_query: {
										...filters,
										company_id: overviewData.company ? overviewData.company.id : null,
									},
								})}>
								<EvaIcon type="download-outline" height="16" width="25" fill="#25282e" />
								{uctrans("questionnaires.export_to_csv")}
							</a>
						) : (
							""
						);
					}}
				/>
			)}
			{activeRelationmanagerSurvey && (
				<QuestionnaireCompletionForm
					fullscreen
					readOnly={overviewData.readOnly}
					questionnaire={overviewData.questionnaire}
					questionnaireCompletion={activeRelationmanagerSurvey}
					questionnaireCompletionChanged={setActiveRelationmanagerSurvey}
					questionnaireCompletionStatusses={overviewData.formInfo.questionnaire_completion_statusses}
				/>
			)}
		</>
	);
}

RelationManagerQuestionnaireCompletionOverview.propTypes = {
	overviewData: PropTypes.object,
};
