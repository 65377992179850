import { createContext } from "react";

export const RmDashboardContext = createContext({
	wallet: {
		type: null,
		id: null,
		label: null,
	},
	setWallet: null,
	walletOptions: [],
	rememberCollapsedDashboadBlocks: {},
	rememberCollapsedDashboadBlock: null,
	fetchBlockData: null,
	printDashboardPDF: null,
});
