import { useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import { route } from "../../../../../../js/helpers";
import { format } from "../../../../../../js/lib/Date";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import LabeledTextarea from "../../../../../../js/react/components/general/form/LabeledTextarea";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../../js/react/components/general/Translate";
import Nav from "../../../../../../js/lib/Nav";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";

export default function InterestPeriodSettingsForm() {
	const [formData, setFormData] = useState({ ...window.data.interestPeriodSettings });

	const getBreadCrumbs = () => {
		const breadCrumbs = [
			{
				label: uctrans("interest.interest_periods.settings"),
				route: route("crm.interest.interest-period-settings.index"),
			},
		];

		if (formData.id !== -1) {
			breadCrumbs.push({
				label: format(window.data.date_label, "dd-MM-yyyy"),
			});
		}

		return breadCrumbs;
	};

	const onSuccess = () => {
		Nav.go(route("crm.interest.interest-period-settings.index"));
	};

	return (
		<>
			<BreadCrumbs breadCrumbs={getBreadCrumbs()} />
			<h1 className="inline-block">
				{uctrans("general.edit_:item", {}, { item: "interest.interest_periods.settings" })}
			</h1>
			<div className="float-right">
				<a className="link" href={route("crm.interest.interest-period-settings.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "interest.interest_periods.settings" })}
				</a>
			</div>
			<AjaxForm
				method="PUT"
				submitUrl={route("crm.interest.interest-period-settings.update", formData.id)}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "interest.interest_periods.settings" })}
				successText={uctrans("general.saved_:items", {}, { item: "interest.interest_periods.settings" })}
				onSuccess={onSuccess}
				useFlashMessage
				data={{ ...formData }}>
				<div className="form-full">
					<LabeledTextarea
						name="message"
						label="interest.fields.message"
						value={formData.message ?? ""}
						onChange={event => {
							setFormData({
								...formData,
								message: event.target.value,
							});
						}}
					/>
				</div>

				<div className="form-full">
					<label htmlFor="periods">
						<Translate content="interest.fields.shown_periods" />
					</label>
					<div className="inline-block">
						{Object.keys(formData.shown_periods).map(period => (
							<div className="form-1-4" key={period}>
								<Checkmark
									name={`periods[${period}]`}
									label={period}
									value={formData.shown_periods[period]}
									checked={formData.shown_periods[period]}
									onChange={() => {
										setFormData({
											...formData,
											shown_periods: {
												...formData.shown_periods,
												[period]: !formData.shown_periods[period],
											},
										});
									}}
								/>
							</div>
						))}
					</div>
				</div>
				<div className="form-full">
					<label htmlFor="tables">
						<Translate content="interest.fields.shown_tables" />
					</label>
					<div className="inline-block">
						{Object.keys(formData.shown_tables).map(table => (
							<div className="form-1-2" key={table}>
								<Checkmark
									name={`tables[${table}]`}
									label={table}
									value={formData.shown_tables[table]}
									checked={formData.shown_tables[table]}
									onChange={() => {
										setFormData({
											...formData,
											shown_tables: {
												...formData.shown_tables,
												[table]: !formData.shown_tables[table],
											},
										});
									}}
								/>
							</div>
						))}
					</div>
				</div>
				<div className="form-full">
					<div className="w-full md:w-1/2 lg:w-2/3 column">
						<SubmitBar item="interest.interest_periods.settings" addAnotherAvailable={false} />
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
