import PropTypes from "prop-types";
import { route, can } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";

export default function SettingsNavigation(props) {
	const tabs = [];
	if (can("communication.newsletter_subscription_type", "view")) {
		tabs.push({
			key: "newsletterSubscriptionCategories",
			label: uctrans("communication.newsletter_subscription_categories.plural"),
			route: route("crm.communication-settings.newsletter-subscription-categories.index"),
		});
	}
	if (can("communication.newsletter_subscription_type", "view")) {
		tabs.push({
			key: "newsletterSubscriptionTypes",
			label: uctrans("communication.newsletter_subscription_types.plural"),
			route: route("crm.communication-settings.newsletter-subscription-types.index"),
		});

		tabs.push({
			key: "newsletterUnsubscribeReasons",
			label: uctrans("communication.newsletters.unsubscribe_reasons.plural"),
			route: route("crm.communication-settings.newsletter-unsubscribe-reasons.index"),
		});
	}
	tabs.push({
		key: "newsletterUnsubscribePage",
		label: uctrans("communication.newsletters.unsubscribe_page"),
		route: route("crm.communication-settings.newsletter-unsubscribe-page"),
	});

	tabs.push({
		key: "newsletterFooter",
		label: uctrans("communication.newsletters.footer"),
		route: route("crm.communication-settings.newsletter-footer"),
	});

	tabs.push({
		key: "flowMailerStatus",
		label: uctrans("communication.flowmailer.status"),
		route: route("crm.communication-settings.flow-mailer-statuses.index"),
	});

	window.data.environment !== "live" &&
		tabs.push({
			key: "flowMailerWhitelistRecipients",
			label: uctrans("communication.flowmailer.whitelist_recipients"),
			route: route("crm.communication-settings.flow-mailer-whitelist-recipients.index"),
		});

	tabs.push({
		key: "mailingListRecipient",
		label: uctrans("communication.mailing_list_recipients.plural"),
		route: route("crm.communication-settings.mailing-list-recipient.index"),
	});

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("communication.communication_settings") }]} />
			<TabLinks tabs={tabs} activeTab={props.selectedTab} />
		</>
	);
}
SettingsNavigation.propTypes = {
	selectedTab: PropTypes.string.isRequired,
};
