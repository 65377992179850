import { useState } from "react";
import PropTypes from "prop-types";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";

export default function CollapsibleContent(props) {
	const [collapsed, setCollapsed] = useState(props.initialCollapseState);

	return (
		<div className={props.className}>
			<ul>
				<li
					className="border-b border-primary my-5 cursor-pointer clear-right"
					onClick={() => setCollapsed(!collapsed)}>
					<h4 className="inline-block">{props.title}</h4> {props.required && <span className="text-orange">*</span>}
					<EvaIcon
						className="float-right mt-2"
						type={collapsed ? "arrow-ios-downward" : "arrow-ios-upward"}
						fill="#009286"
						height="16"
						width="16"
					/>
				</li>
			</ul>

			{!collapsed && <div className="mt-3">{props.children}</div>}
		</div>
	);
}

CollapsibleContent.propTypes = {
	initialCollapseState: PropTypes.bool,
	title: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.any,
	required: PropTypes.bool,
};

CollapsibleContent.defaultProps = {
	initialCollapseState: true,
};
