import Tippy from "@tippyjs/react";
import { useState, lazy, Suspense } from "react";
import ClickToSelect from "../../../../../../js/react/components/general/ClickToSelect";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import Translate from "../../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";
import Nav from "../../../../../../js/lib/Nav";
import { route } from "../../../../../../js/helpers";
import { CmsTypesContext } from "../../cms/cms-types-context";
import CollapsibleContent from "../../general/CollapsibleContent";
import Navigation from "../Navigation";
import map from "lodash/map";

const TextEditor = lazy(() => import(/* webpackChunkName: "tinymce" */ "../../forms/textEditor/TextEditor"));

export default function CooperationAgreementForm() {
	const [formData, setFormData] = useState({ ...window.data.cooperation_agreement_values });
	const creating = window.data.cooperation_agreement_values.id === -1;
	const genericHandler = useGenericHandler(setFormData);
	const categorizedTags = window.data.cooperation_agreement_tags;
	const tags = { tagTypes: Object.values(window.data.cooperation_agreement_tags).flat() };

	const onSuccess = () => {
		Nav.go(route("crm.dossier-settings.cooperation-agreements.current"));
	};

	return (
		<>
			<Navigation selectedTab="cooperation-agreements" />

			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(
						creating ? "general.add_:item" : "general.edit_:item",
						{},
						{ item: "dossiers.cooperation_agreements.singular" },
					)}
				</h1>
			</div>

			<div className="min-h-screen md:flex">
				<div className="flex-1 w-2/3 mr-6">
					<AjaxForm
						method={creating ? "POST" : "PUT"}
						submitUrl={
							creating
								? route("crm.dossier-settings.cooperation-agreement.store")
								: route("crm.dossier-settings.cooperation-agreements.update", formData.id)
						}
						loaderText={uctrans(
							"general.:item_is_being_saved",
							{},
							{ item: "dossiers.cooperation_agreements.singular" },
						)}
						successText={uctrans("general.saved_:item", {}, { item: "dossiers.cooperation_agreements.singular" })}
						onSuccess={onSuccess}
						data={{ ...formData }}
						useFlashMessage>
						<div className="min-h-screen md:flex">
							<div className="flex-1 w-2/3 mr-12">
								<div className="form-container">
									<div className="form-full">
										<label htmlFor="description">
											<Translate content="dossiers.cooperation_agreements.fields.description" />
										</label>
										<Suspense
											fallback={
												<div style={{ width: 32, height: 32 }}>
													<Spinner />
												</div>
											}>
											<CmsTypesContext.Provider value={tags}>
												<TextEditor
													value={formData.description}
													onChange={content => genericHandler(content, "description")}
													height={450}
													type="document"
												/>
											</CmsTypesContext.Provider>
										</Suspense>
									</div>

									<div className="form-full">
										<div className="w-full md:w-1/2 lg:w-1/3 column">
											<SubmitBar item="dossiers.cooperation_agreements.singular" addAnotherAvailable={false} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</AjaxForm>
				</div>
				<div className="flex-none w-1/3 border-l pl-12">
					<h2>Tags</h2>
					{map(categorizedTags, (tags, category) => (
						<CollapsibleContent key={category} title={uctrans(`communication.tags.categories.${category}`)}>
							<div className="text-xs">
								{map(tags, (tag, i) => (
									<div className="flex justify-between" key={i}>
										<ClickToSelect>{`{${tag.tag}}`}</ClickToSelect>
										<Tippy
											placement="right"
											arrow
											animation="perspective"
											duration="400"
											content={
												<p>
													{tag.description}
													<br />
													<strong>{uctrans("communication.tags.settings.fields.default")}:</strong>
													<br />
													{tag.is_html ? <span dangerouslySetInnerHTML={{ __html: tag.default }} /> : tag.default}
													<br />
													<strong>{uctrans("communication.tags.settings.fields.preview")}:</strong>
													<br />
													{tag.is_html ? <span dangerouslySetInnerHTML={{ __html: tag.preview }} /> : tag.preview}
												</p>
											}>
											<span>
												<EvaIcon type="info-outline" fill="#249286" height="18" width="18" />
											</span>
										</Tippy>
									</div>
								))}
							</div>
						</CollapsibleContent>
					))}
				</div>
			</div>
		</>
	);
}
