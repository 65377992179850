import { useContext } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import RmDashboardHorizontalBarchart from "./graph/RmDashboardHorizontalBarchart";
import RmDashboardPiechart from "./graph/RmDashboardPiechart";
import RmDashboardBlock from "./RmDashboardBlock";
import { RmDashboardContext } from "./RmDashboardContext";
export default function LoanDetailsBlock() {
	const { wallet } = useContext(RmDashboardContext);
	return (
		<RmDashboardBlock
			blockKey="loan-details"
			useFetching
			renderHeader={() => <h5 className="mb-0">{uctrans("dashboard.loan_details")}</h5>}
			renderContent={blockData => (
				<>
					<div className="flex w-full">
						{!!blockData.loan_type && !!blockData.loan_type[wallet.type] && !!blockData.loan_type.total && (
							<div className="w-1/3">
								<RmDashboardPiechart
									walletType={wallet.type}
									walletData={blockData.loan_type[wallet.type]}
									totalData={blockData.loan_type.total}
									title={uctrans("dashboard.loan_type")}
									link={blockData.loan_type_dashboard_link ?? null}
									charBeforeTooltip="€ "
									divideTooltipValueBy={1000}
								/>
							</div>
						)}
						{!!blockData.national_warranty &&
							!!blockData.national_warranty[wallet.type] &&
							!!blockData.national_warranty.total && (
								<div className="w-1/3">
									<RmDashboardPiechart
										walletType={wallet.type}
										walletData={blockData.national_warranty[wallet.type]}
										totalData={blockData.national_warranty.total}
										title={uctrans("dashboard.national_warranty_short")}
										link={blockData.national_warranty_dashboard_link ?? null}
										charBeforeTooltip="€ "
										divideTooltipValueBy={1000}
									/>
								</div>
							)}
						{!!blockData.house_bank_discount &&
							!!blockData.house_bank_discount[wallet.type] &&
							!!blockData.house_bank_discount.total && (
								<div className="w-1/3">
									<RmDashboardPiechart
										walletType={wallet.type}
										walletData={blockData.house_bank_discount[wallet.type]}
										totalData={blockData.house_bank_discount.total}
										title={uctrans("dashboard.house_bank_discount")}
										link={blockData.house_bank_discount_dashboard_link ?? null}
										charBeforeTooltip="€ "
										divideTooltipValueBy={1000}
									/>
								</div>
							)}
					</div>
					<div className="flex w-full">
						{!!blockData.repay_type && !!blockData.repay_type[wallet.type] && !!blockData.repay_type.total && (
							<div className="w-1/3">
								<RmDashboardHorizontalBarchart
									walletType={wallet.type}
									walletLabel={wallet.label}
									walletData={blockData.repay_type[wallet.type]}
									totalData={blockData.repay_type.total}
									title={uctrans("dashboard.repay_type")}
									link={blockData.repay_type_dashboard_link ?? null}
									height={250}
									addPecentageSignAfterDataLabel
									calculatePercentages
								/>
							</div>
						)}
						{!!blockData.interest_period &&
							!!blockData.interest_period[wallet.type] &&
							!!blockData.interest_period.total && (
								<div className="w-1/3">
									<RmDashboardHorizontalBarchart
										walletType={wallet.type}
										walletLabel={wallet.label}
										walletData={blockData.interest_period[wallet.type]}
										totalData={blockData.interest_period.total}
										title={uctrans("dashboard.interest_period")}
										link={blockData.interest_period_dashboard_link ?? null}
										height={250}
										addPecentageSignAfterDataLabel
										calculatePercentages
									/>
								</div>
							)}
					</div>
				</>
			)}
		/>
	);
}
