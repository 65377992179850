import map from "lodash/map";
import PropTypes from "prop-types";
import { uctrans } from "../../../../../../../js/lib/Translator";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import FormattedNumberText from "../../FormattedNumberText";
import { Fragment, useState } from "react";

export default function MarketingNewsletterUnsubscribeReasonReport({ reportData, formValues }) {
	const { data, title, reasons, total } = reportData;

	const colSpan = reasons ? Object.keys(reasons).length * 2 + 6 : 6;

	const showData = value => {
		switch (formValues.newsletterUnsubscribeReasonType) {
			case "amount":
				return value;
			case "percentage":
				return <FormattedNumberText percentage value={value} decimalScale={1} />;
			default:
				break;
		}
		return uctrans("report.nvt");
	};

	const [expandedCategories, setExpandedCategories] = useState([]);

	const adjustExpandedCategories = rowId => {
		if (expandedCategories.some(id => id === rowId)) {
			// remove it
			setExpandedCategories(expandedCategories.filter(id => id !== rowId));
		} else {
			// add it
			setExpandedCategories([...expandedCategories, rowId]);
		}
	};

	return (
		<table className="mt-5 omzet_rapportage_table rapportage_table reporttable">
			<thead>
				<tr>
					<th colSpan={colSpan}>{title ? title : uctrans("report.singular")}</th>
				</tr>
				<tr>
					<td colSpan="4" className="status_titel font-weight-bold">
						<span>{uctrans("interestcategories.fields.categories")}</span>
					</td>

					{reasons &&
						Object.keys(reasons).length > 0 &&
						map(reasons, (reason, reasonKey) => (
							<td key={reasonKey} colSpan="2" className="status_titel font-weight-bold">
								<span>{reason.label}</span>
							</td>
						))}

					<td colSpan="2" className="status_titel font-weight-bold">
						<span>{uctrans("report.total")}</span>
					</td>
				</tr>
			</thead>
			{data && Object.keys(data).length > 0 && reasons && Object.keys(reasons).length > 0 ? (
				<tbody>
					{map(data, (row, categoryKey) => (
						<Fragment key={categoryKey}>
							<tr key={`${categoryKey}_total`}>
								<td
									colSpan="4"
									className="status_titel font-bold cursor-pointer"
									onClick={row && row.types ? () => adjustExpandedCategories(categoryKey) : null}>
									{row && row.types && (
										<EvaIcon
											type={expandedCategories.some(id => id === categoryKey) ? "arrow-down" : "arrow-right"}
											fill="#249286"
											height="20"
											width="20"
										/>
									)}
									<span className="mb-2">{row.label}</span>
								</td>

								{map(reasons, (reason, reasonKey) => (
									<td key={reasonKey} colSpan="2" className="status_titel font-bold">
										{row.reasons && row.reasons.hasOwnProperty(reasonKey) && showData(row.reasons[reasonKey].value)}
									</td>
								))}

								<td colSpan="2" className="status_titel font-bold">
									{showData(row.total)}
								</td>
							</tr>
							{expandedCategories.includes(categoryKey) &&
								Object.keys(row.types).length > 0 &&
								map(row.types, (type, typeKey) => (
									<tr key={typeKey}>
										<td colSpan="4" className="status_titel font-weight-bold">
											<span className="ml-5 italic">{type.label}</span>
										</td>

										{map(reasons, (reason, reasonKey) => (
											<td key={reasonKey} colSpan="2" className="status_titel">
												{type.reasons &&
													type.reasons.hasOwnProperty(reasonKey) &&
													showData(type.reasons[reasonKey].value)}
											</td>
										))}

										<td colSpan="2" className="status_titel">
											{showData(type.total)}
										</td>
									</tr>
								))}
						</Fragment>
					))}

					{!!reasons && (
						<tr className="totaal">
							<td colSpan="4">{uctrans("report.total")}</td>

							{map(reasons, (reason, reasonKey) => (
								<td key={reasonKey} colSpan="2" className="status_titel font-bold">
									{showData(reason.value)}
								</td>
							))}

							<td colSpan="2" className="status_titel font-bold">
								{showData(total)}
							</td>
						</tr>
					)}
				</tbody>
			) : (
				<tbody>
					<tr>
						<td colSpan="10">{uctrans("report.no_results")}</td>
					</tr>
				</tbody>
			)}
		</table>
	);
}

MarketingNewsletterUnsubscribeReasonReport.propTypes = {
	reportData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	formValues: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
