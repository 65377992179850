import { useState } from "react";
import { uctrans, trans } from "../../../../../js/lib/Translator";
import Navigation from "./Navigation";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DeleteColumnContent from "../../../../../js/react/components/general/overview/DeleteColumnContent";
import Translate from "../../../../../js/react/components/general/Translate";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import IconButton from "../../../../../js/react/components/general/IconButton";

export default function TeamOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
		create_link: window.data.team_create_link,
		index_link: window.data.team_index_link,
		permissions: window.data.team_permissions,
	});

	return (
		<>
			<Navigation selectedTab="teams" />

			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="teams.plural" />
				</h1>

				{overviewData.create_link && (
					<div>
						<IconButton
							href={overviewData.create_link}
							content={uctrans("general.add_:item", {}, { item: "teams.singular" })}
							primary
						/>
					</div>
				)}
			</div>
			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={overviewData.index_link}
				modelTranslations={{ plural: trans("teams.plural"), singular: trans("teams.singular") }}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={1}
							sortable="name"
							renderHeaderContent={() => uctrans("teams.fields.name")}
							renderCellContent={team => (
								<a href={team.edit_link} className="row-title link">
									{team.name}
								</a>
							)}
						/>
						<DataColumn
							width={1}
							sortable="team_number"
							renderHeaderContent={() => uctrans("teams.fields.team_number")}
							renderCellContent={({ team_number }) => team_number}
						/>
						{overviewData.permissions.delete && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(team, overview) =>
									team.delete_link && <DeleteColumnContent onClick={() => overview.askDelete(team)} />
								}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
