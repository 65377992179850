import { useState } from "react";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route } from "../../../../../../js/helpers";
import Navigation from "../Navigation";

export default function ProcessTimeActivityOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<Navigation selectedTab="process-time-activities" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("teams.process_time_activities.plural")}</h1>
				{can("teams.process_time_activity", "create") && (
					<div>
						<IconButton
							href={route("crm.process-time-activities.create")}
							content={uctrans("general.add_:item", {}, { item: "teams.process_time_activities.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.process-time-activities.index")}
				deleteRouteName="crm.process-time-activities.delete"
				moveRouteName="crm.process-time-activities.move"
				useDragAndDrop={can("teams.process_time_activity", "move")}
				hideControls
				modelTranslations={{
					plural: trans("teams.process_time_activities.plural"),
					singular: trans("teams.process_time_activities.singular"),
				}}
				renderBody={() => (
					<DataTable>
						{can("teams.process_time_activity", "move") && (
							<DataColumn
								width={1}
								className="draggable-overview-item-handle"
								renderHeaderContent={() => ""}
								renderCellContent={() => ""}
							/>
						)}
						<DataColumn
							width={10}
							sortable="name"
							renderHeaderContent={() => uctrans("teams.process_time_activities.fields.name")}
							renderCellContent={processTimeActivity => {
								if (can("teams.process_time_activity", "update")) {
									return (
										<a
											href={route("crm.process-time-activities.edit", processTimeActivity.id)}
											className="row-title link">
											{processTimeActivity.name}
										</a>
									);
								} else {
									return processTimeActivity.name;
								}
							}}
						/>
						{can("teams.process_time_activity", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(category, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(category)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
