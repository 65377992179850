import PropTypes from "prop-types";
import { TableContext } from "./table-context";

function SimpleTable({ data, children }) {
	return <TableContext.Provider value={data}>{children}</TableContext.Provider>;
}

export default SimpleTable;

SimpleTable.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
	children: PropTypes.node.isRequired,
};
