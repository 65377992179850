import { can, route } from "../../../../../js/helpers";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import IconButton from "../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import DeleteColumnContent from "../../../../../js/react/components/general/overview/DeleteColumnContent";
import Navigation from "./Navigation";

export default function SbiCodeOverview() {
	const overviewData = { ...window.data.overview };

	return (
		<>
			<Navigation selectedTab="sbiCodes" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("kvk.sbi_codes.plural")}</h1>
				{can("kvk.notification_sbi_code", "create") && (
					<div>
						<IconButton
							href={route("crm.kvk-settings.notification-sbi-codes.create")}
							content={uctrans("general.add_:item", {}, { item: "kvk.sbi_codes.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.kvk-settings.notification-sbi-codes.index")}
				deleteRouteName="crm.kvk-settings.notification-sbi-codes.delete"
				modelTranslations={{
					plural: trans("kvk.sbi_codes.plural"),
					singular: trans("kvk.sbi_codes.singular"),
				}}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							sortable="value"
							renderHeaderContent={() => uctrans("kvk.sbi_codes.singular")}
							renderCellContent={sbiCode => {
								if (can("kvk.notification_sbi_code", "update")) {
									return (
										<a
											href={route("crm.kvk-settings.notification-sbi-codes.edit", sbiCode.id)}
											className="row-title link">
											{sbiCode.value}
										</a>
									);
								} else {
									return sbiCode.value;
								}
							}}
						/>
						{can("kvk.notification_sbi_code", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(sbiCode, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(sbiCode)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
