import PropTypes from "prop-types";
import { uctrans } from "../../../../../../../js/lib/Translator";
import MultiSelectAutoComplete from "../../../../../../../js/react/components/general/autocomplete/MultiSelectAutoComplete";
import Select from "../../../../../../../js/react/components/general/Select";
import Translate from "../../../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../../../js/react/hooks/useGenericHandler";
import useSelectHandler from "../../../../../../../js/react/hooks/useSelectHandler";
import PeriodicFilter from "../../../general/PeriodicFilter";

export default function MarketingThemesReportFilters({ formInfo, formValues, setFormValues }) {
	const genericHandler = useGenericHandler(setFormValues);
	const selectHandler = useSelectHandler(setFormValues);

	return (
		<>
			<div className="w-1/4 pr-2">
				<label htmlFor="report_type">
					<Translate content="report.type_report" />
				</label>
				<Select
					isClearable={false}
					placeholder={uctrans("general.select_:item", {}, { item: "report.type_report" })}
					value={formInfo.reportTypes.filter(({ value }) => `${value}` === `${formValues.report_type}`)}
					options={formInfo.reportTypes}
					name="report_type"
					onChange={(selectedValue, metaData) => {
						selectHandler(selectedValue, metaData);
					}}
				/>
			</div>

			<div className="flex w-full mt-5">
				{formValues.themeOptions && (
					<div className="w-1/4 pr-2">
						<label htmlFor="themeTypes">{uctrans("report.theme_type")}</label>
						<MultiSelectAutoComplete
							options={formInfo.themeOptions}
							name="themeOptions"
							items={formValues.themeOptions}
							onChange={selectedThemes => genericHandler(selectedThemes, "themeOptions")}
							placeholder={uctrans("general.type_to_add_:item", {}, { item: "marketing.themes.singular" })}
							showItemsOnFirstClick
						/>
					</div>
				)}

				{formValues.scoringTypes && (
					<div className="w-1/4 pr-2">
						<label htmlFor="scoringTypes">{uctrans("report.scoring_type")}</label>
						<MultiSelectAutoComplete
							options={formInfo.scoringTypes}
							name="scoringTypes"
							items={formValues.scoringTypes}
							onChange={selectedScoringTypes => genericHandler(selectedScoringTypes, "scoringTypes")}
							placeholder={uctrans("general.type_to_add_:item", {}, { item: "report.scoring_type" })}
							showItemsOnFirstClick
						/>
					</div>
				)}

				<div className="w-2/4 pr-2">
					<PeriodicFilter formValues={formValues} formInfo={formInfo} setFormValuesFunc={setFormValues} />
				</div>
			</div>
		</>
	);
}

MarketingThemesReportFilters.propTypes = {
	formValues: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	formInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	setFormValues: PropTypes.func,
};
