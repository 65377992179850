import { useState } from "react";
import { format } from "../../../../../../js/lib/Date";
import { trans, uctrans } from "../../../../../../js/lib/Translator";
import { route } from "../../../../../../js/helpers";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../../general/tabs/TabLinks";

export default function ClientTreatmentOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	const { intermediary } = window.data;

	const indexRoute = () =>
		intermediary
			? route("crm.intermediaries.client-treatments", window.data.company_id)
			: route("crm.organisations.client-treatments", window.data.company_id);

	return (
		<>
			{window.data.nav_data && (
				<>
					<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
					<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
				</>
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.company_name
						? uctrans(
								"general.:item1 from :item2",
								{ item2: window.data.company_name },
								{ item1: "client_treatment.singular" },
						  )
						: uctrans("client_treatment.singular")}
				</h1>
			</div>
			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={indexRoute()}
				modelTranslations={{ plural: trans("client_treatment.plural"), singular: trans("client_treatment.singular") }}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("client_treatment.evaluations.fields.date")}
							renderCellContent={treatment => (treatment.date ? format(treatment.date, "dd-MM-y") : "-")}
						/>
						<DataColumn
							width={6}
							renderHeaderContent={() => uctrans("client_treatment.fields.name")}
							renderCellContent={treatment => treatment.name}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("client_treatment.fields.inviter")}
							renderCellContent={treatment => (treatment.inviter ? treatment.inviter : "-")}
						/>
						<DataColumn
							width={6}
							renderHeaderContent={() => ""}
							renderCellContent={person => {
								if (!person.hasOwnProperty("contactperson")) {
									return "";
								}

								return person.has_photo ? (
									<div className="flex justify-start">
										<div className="avatar-img mr-2">
											<img
												className="max-h-full self-center"
												src={route("crm.contactpersons.photo-thumbnail", person.contactperson.id)}
												alt={person.contactperson.label}
											/>
										</div>
										{person.contactperson.label}
									</div>
								) : (
									<div className="flex justify-start">
										<div className="avatar-img mr-2">
											<EvaIcon type="person" fill="#249286" height="35" width="35" />
										</div>
										{person.contactperson.label}
									</div>
								);
							}}
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("client_treatment.inviters.fields.nr_of_people")}
							renderCellContent={treatment => (treatment.nr_of_people ? treatment.nr_of_people : "-")}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("client_treatment.fields.status")}
							renderCellContent={treatment => (treatment.status ? treatment.status : "-")}
						/>
						<DataColumn width={1} renderHeaderContent={() => ""} renderCellContent={() => ""} />
					</DataTable>
				)}
			/>
		</>
	);
}
