import PropTypes from "prop-types";
import { can, route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";

export default function Navigation(props) {
	const tabs = [];

	if (can("kvk.notification_sbi_code", "view")) {
		tabs.push({
			key: "sbiCodes",
			label: uctrans("kvk.sbi_codes.plural"),
			route: route("crm.location-settings.zipcode-areas.index"),
		});
	}
	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("kvk.kvk_settings") }]} />
			<TabLinks tabs={tabs} activeTab={props.selectedTab} />
		</>
	);
}
Navigation.propTypes = {
	selectedTab: PropTypes.string.isRequired,
};
