import { route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";

const GENERAL_TAB = 0;
const DISCOUNTS_TAB = 1;
const BRIDGING_LOAN_TAB = 2;

const getInterestManagementTabs = interestTypeId => ({
	0: {
		key: 0,
		label: uctrans("general.general"),
		route: route("crm.interest.interest-types.edit", interestTypeId),
	},
	1: {
		key: 1,
		label: uctrans("interest.discounts.plural"),
		route: route("crm.interest.discounts.index", interestTypeId),
	},
	2: {
		key: 2,
		label: uctrans("interest.bridging_loans.singular"),
		route: route("crm.interest.bridging-loans.index", interestTypeId),
	},
});

export { GENERAL_TAB, DISCOUNTS_TAB, BRIDGING_LOAN_TAB, getInterestManagementTabs };
