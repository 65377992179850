import { createLoader } from "../../../../../../js/react/components/general/notifications";
import axios from "axios";
import { useCallback, useState } from "react";
import { can, route } from "../../../../../../js/helpers";
import { trans, uctrans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import ConfirmDialog from "../../../../../../js/react/components/general/ConfirmDialog";
import DeleteDialog from "../../../../../../js/react/components/general/DeleteDialog";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import Translate from "../../../../../../js/react/components/general/Translate";
import Navigation from "../Navigation";

export default function AppOnboardingPageOverview() {
	const { type } = window.data;
	const translationKey = type === 1 ? "onboarding" : "announcement";

	const [deleting, setDeleting] = useState(null);
	const [showResetDialog, setShowResetDialog] = useState(false);

	const handleDelete = useCallback(async () => {
		const loader = createLoader(
			uctrans("general.:item_is_being_deleted", {}, { item: `app_onboarding_pages.${translationKey}.singular` }),
		);
		try {
			await axios.delete(route("crm.app-onboarding-pages.destroy", { type, app_onboarding_page: deleting }));

			loader.success(uctrans("general.deleted_:item", {}, { item: `app_onboarding_pages.${translationKey}.singular` }));
			window.location.reload();
		} catch (error) {
			console.error(error);
			loader.failure(error.message);
		}
	}, [deleting, translationKey, type]);

	const handleReset = useCallback(async () => {
		setShowResetDialog(false);
		const loader = createLoader(uctrans("app_onboarding_pages.reset_onboarding"));
		try {
			await axios.put(route("crm.app-onboarding-pages.reset", { type }));
			loader.success(uctrans("app_onboarding_pages.reset_onboarding_complete"));
		} catch (error) {
			console.error(error);
			loader.failure(error.message);
		}
	}, [type]);

	return (
		<>
			<Navigation selectedTab={type === 1 ? "onboarding" : "announcement"} />

			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content={`app_onboarding_pages.${translationKey}.plural`} />
				</h1>

				{can("app.app_onboarding_page", "create") && (
					<div>
						{type === 1 && (
							<IconButton
								onClick={() => setShowResetDialog(true)}
								content={uctrans("app_onboarding_pages.reset_onboarding")}
								className="mr-2"
								icon="refresh"
							/>
						)}
						<IconButton
							href={route("crm.app-onboarding-pages.create", { type })}
							content={uctrans("general.add_:item", {}, { item: `app_onboarding_pages.${translationKey}.singular` })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={window.data.overview}
				indexUrl={route("crm.app-onboarding-pages.index", { type })}
				modelTranslations={{
					plural: trans(`app_onboarding_pages.${translationKey}.plural`),
					singular: trans(`app_onboarding_pages.${translationKey}.singular`),
				}}
				useDragAndDrop={can("app.app_onboarding_page", "move")}
				moveRouteName="crm.app-onboarding-pages.move"
				moveRouteAdditionalParameters={{ type }}
				renderBody={() => (
					<DataTable>
						{can("app.app_onboarding_page", "move") && (
							<DataColumn
								width={1}
								className="draggable-overview-item-handle"
								renderHeaderContent={() => ""}
								renderCellContent={() => ""}
							/>
						)}
						<DataColumn
							width={11}
							renderHeaderContent={() => uctrans("app_onboarding_pages.fields.title")}
							renderCellContent={onboardingpage =>
								can("app.app_onboarding_page", "update") ? (
									<a
										href={route("crm.app-onboarding-pages.edit", { type, app_onboarding_page: onboardingpage.id })}
										className="row-title link">
										{onboardingpage.title}
									</a>
								) : (
									onboardingpage.title
								)
							}
						/>
						{type === 1 && (
							<DataColumn
								width={11}
								renderHeaderContent={() => uctrans("app_onboarding_pages.fields.active")}
								renderCellContent={onboardingpage => uctrans(`general.${onboardingpage.active ? "yes" : "no"}`)}
							/>
						)}
						{type === 2 && (
							<DataColumn
								width={6}
								renderHeaderContent={() => uctrans("app_onboarding_pages.fields.active_from")}
								renderCellContent={onboardingpage => onboardingpage.active_from}
							/>
						)}
						{type === 2 && (
							<DataColumn
								width={6}
								renderHeaderContent={() => uctrans("app_onboarding_pages.fields.active_until")}
								renderCellContent={onboardingpage => onboardingpage.active_until}
							/>
						)}
						{can("app.app_onboarding_page", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={onboardingpage => (
									<DeleteColumnContent onClick={() => setDeleting(onboardingpage.id)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>

			<DeleteDialog
				deleteData={{ errors: [], warnings: [] }}
				onConfirm={handleDelete}
				onCancel={() => setDeleting(null)}
				isOpen={deleting !== null}
			/>

			<ConfirmDialog
				onConfirm={handleReset}
				onCancel={() => setShowResetDialog(false)}
				isOpen={showResetDialog}
				title={uctrans("app_onboarding_pages.reset_onboarding")}>
				<strong>
					<Translate content="general.warning" />
					:&nbsp;
				</strong>
				<Translate content="app_onboarding_pages.reset_onboarding_warning" />
			</ConfirmDialog>
		</>
	);
}
