import pickBy from "lodash/pickBy";
import { useMemo, useCallback } from "react";
import { route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import CompanyStatusLabel from "../company/CompanyStatusLabel";
import CollapsibleContent from "../general/CollapsibleContent";
import PropTypes from "prop-types";

export default function DuplicateKVK(props) {
	const enums = useMemo(() => props.enums, [props.enums]);
	const kvkDuplicateCheck = useMemo(() => props.kvk_duplicate_check, [props.kvk_duplicate_check]);
	const getEnum = useCallback(
		(section, key) => Object.values(pickBy(enums[section], value => value.key === key))[0],
		[enums],
	);

	const cooperationRequestStatus = useCallback(
		cooperationRequest => {
			const handledEnum = getEnum("cooperation_request_handled_statuses", cooperationRequest.handled_status);

			return handledEnum.is_final
				? uctrans("cooperation_check.handled_statuses.simplified.handled")
				: uctrans("cooperation_check.handled_statuses.simplified.pending");
		},
		[getEnum],
	);

	const takeonStatus = useCallback(
		intermediairy => {
			const [takeonEnum] = Object.values(
				pickBy(enums.takeon_statuses, value => value.id === parseInt(intermediairy.takeon_status_id)),
			);

			return takeonEnum.is_final
				? uctrans("cooperation_check.handled_statuses.simplified.handled")
				: uctrans("cooperation_check.handled_statuses.simplified.pending");
		},
		[enums],
	);

	return (
		<CollapsibleContent title={uctrans("dossiers.kvk_duplicates.title")} className="kvk-duplicates">
			{kvkDuplicateCheck.cooperation_requests.length > 0 && (
				<div className="form-full">
					<strong>{uctrans("dossiers.kvk_duplicates.cooperation_request")}</strong>
					<ul>
						{kvkDuplicateCheck.cooperation_requests.map(cooperation_request => (
							<li key={cooperation_request.id}>
								<CompanyStatusLabel
									label={cooperation_request.statutory_company_name}
									link={route("crm.agenda.cooperation-request-completions.edit", cooperation_request.id)}
								/>
								({cooperationRequestStatus(cooperation_request)})
							</li>
						))}
					</ul>
				</div>
			)}

			{kvkDuplicateCheck.take_ons.length > 0 && (
				<div className="form-full">
					<strong>{uctrans("dossiers.kvk_duplicates.take_on")}</strong>
					<ul>
						{kvkDuplicateCheck.take_ons.map(take_on => (
							<li key={take_on.id}>
								{take_on.hasOwnProperty("intermediary:full") && take_on["intermediary:full"] !== null ? (
									<>
										<CompanyStatusLabel
											label={take_on["intermediary:full"].name}
											link={route("crm.agenda.intermediaries.dossier.edit", take_on["intermediary:full"].id)}
										/>
										({takeonStatus(take_on["intermediary:full"])})
									</>
								) : (
									<>
										<CompanyStatusLabel
											label={take_on.organisation.name}
											link={route("crm.agenda.organisations.dossier.edit", take_on.organisation.id)}
										/>
										({takeonStatus(take_on.organisation)})
									</>
								)}
							</li>
						))}
					</ul>
				</div>
			)}

			{kvkDuplicateCheck.intermediaries.length > 0 && (
				<div className="form-full">
					<strong>{uctrans("dossiers.kvk_duplicates.intermediary")}</strong>
					<ul>
						{kvkDuplicateCheck.intermediaries.map(intermediary => (
							<li key={intermediary.id}>
								<CompanyStatusLabel
									colorStatus={intermediary.color_status}
									showExplanationLabels
									label={intermediary.name}
									link={route("crm.intermediaries.dossier.edit", intermediary.id)}
								/>
							</li>
						))}
					</ul>
				</div>
			)}
		</CollapsibleContent>
	);
}

DuplicateKVK.propTypes = {
	kvk_duplicate_check: PropTypes.object,
	enums: PropTypes.object,
};
