import Tippy from "@tippyjs/react";
import { useState } from "react";
import { can, route } from "../../../../../../js/helpers";
import { format } from "../../../../../../js/lib/Date";
import Nav from "../../../../../../js/lib/Nav";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import Dialog from "../../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import SelectFilter from "../../../../../../js/react/components/general/overview/SelectFilter";
import Translate from "../../../../../../js/react/components/general/Translate";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../../js/react/components/general/overview/SearchBarFilter";
import Navigation from "./Navigation";
import AutomatedMessageNavications from "../automatedMessages/Navigation";

export default function NewsletterCommunicationLogOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});
	const staticData = { ...window.data.static_data };
	const [currentWebviewCommunicationLog, setCurrentWebviewCommunicationLog] = useState(null);

	const handleExport = overviewParameters => {
		if (staticData.newsletter.type === 1) {
			Nav.go(
				route("crm.communication.newsletters.communication-log.export", {
					newsletter: staticData.newsletter,
					...overviewParameters,
				}),
			);
		}

		if (staticData.newsletter.type === 2) {
			Nav.go(
				route("crm.communication.automated-messages.communication-log.export", {
					automated_message: staticData.newsletter,
					...overviewParameters,
				}),
			);
		}
	};

	return (
		<>
			{staticData.newsletter.type === 1 ? (
				<Navigation selectedTab="communication_log" newsletter={staticData.newsletter} />
			) : (
				<AutomatedMessageNavications selectedTab="communication_log" automatedMessage={staticData.newsletter} />
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="communication.communication_log.singular" />
				</h1>
			</div>
			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={
					staticData.newsletter.type === 1
						? route("crm.communication.newsletters.communication-log", staticData.newsletter.id)
						: route("crm.communication.automated-messages.communication-log", staticData.newsletter.id)
				}
				modelTranslations={{
					plural: trans("communication.communication_log.plural"),
					singular: trans("communication.communication_log.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" placeholder={uctrans("communication.search_for_email")} />

						<SelectFilter
							className="form-1-3"
							name="receiver_type"
							label={uctrans("communication.communication_log.fields.receiver_type")}
							options={overviewData.overview.metadata.filterdata.receiver_types}
						/>
						<SelectFilter
							className="form-1-3"
							name="last_flowmailer_status_id"
							label={uctrans("communication.communication_log.fields.last_flowmailer_status_id")}
							options={overviewData.overview.metadata.filterdata.event_types}
						/>
						{overviewData.overview.metadata.filterdata.mailings.length && (
							<SelectFilter
								className="form-1-3"
								name="mailing"
								label={uctrans("communication.communication_log.fields.mailing")}
								options={overviewData.overview.metadata.filterdata.mailings}
							/>
						)}
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("communication.communication_log.fields.datetime")}
							renderCellContent={log => (log.when ? format(log.when, "dd-MM-y HH:mm") : null)}
							sortable="when"
						/>
						{staticData.newsletter.type === 1 && (
							<DataColumn
								width={2}
								renderHeaderContent={() => uctrans("communication.newsletter_mailings.singular")}
								renderCellContent={log => log.mailing}
								sortable="mailing"
							/>
						)}
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("communication.communication_log.fields.receiver_type")}
							renderCellContent={log => log.receiver_type}
							sortable="object_type"
						/>
						<DataColumn
							width={5}
							renderHeaderContent={() => uctrans("communication.communication_log.fields.email")}
							renderCellContent={log => (log.email_address ? log.email_address : "")}
							sortable="email_address"
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("communication.communication_log.fields.last_flowmailer_status_id")}
							renderCellContent={log => (
								<div>
									{log.last_flowmailer_status_id && log.last_event_hint ? (
										<Tippy
											placement="top"
											arrow
											animation="perspective"
											duration="400"
											content={log.last_flowmailer_status_id}>
											<span>
												{log.last_flowmailer_status_id}
												<EvaIcon type="info" fill="#249286" height="16" width="16" />
											</span>
										</Tippy>
									) : (
										"-"
									)}
								</div>
							)}
							sortable="last_flowmailer_status_id"
						/>
						<DataColumn
							width={1}
							renderHeaderContent={() => ""}
							renderCellContent={log => (
								<div className="flex">
									{can("communication.communication_log", "view_all") && (
										<a
											rel="noopener noreferrer"
											className="mr-2"
											onClick={() => {
												setCurrentWebviewCommunicationLog(log);
											}}
											target="_blank">
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("communication.automated_messages.view_on_website")}>
												<span>
													<EvaIcon type="eye-outline" fill="#249286" height="18" width="18" />
												</span>
											</Tippy>
										</a>
									)}
								</div>
							)}
						/>
					</DataTable>
				)}
				renderFooter={overview =>
					can("communication.communication_log", "export") && (
						<a className="button button-primary" onClick={() => handleExport(overview.getParameters())}>
							{uctrans("communication.communication_log.export")}
						</a>
					)
				}
			/>
			<Dialog
				isOpen={!!currentWebviewCommunicationLog}
				onClose={() => {
					setCurrentWebviewCommunicationLog(null);
				}}
				shouldCloseOnOverlayClick
				width={850}>
				{!!currentWebviewCommunicationLog && (
					<iframe
						id="mail_iframe"
						width="100%"
						height="500px"
						src={route("crm.communication-log.webview", currentWebviewCommunicationLog.id_token)}
					/>
				)}
			</Dialog>
		</>
	);
}
