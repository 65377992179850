import Tippy from "@tippyjs/react";
import { format } from "../../../../../js/lib/Date";
import { uctrans, trans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import CheckboxMultiFilter from "../../../../../js/react/components/general/overview/CheckboxMultiFilter";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DeleteColumnContent from "../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route } from "../../../../../js/helpers";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import DataimportForm from "./DataimportForm";
import DataimportSummary from "./DataimportSummary";

export default function DataimportOverview() {
	const overviewData = { ...window.data.overview };

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("general.tools") }]} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("dataimports.plural")}</h1>
			</div>
			<div className="w-full flex">
				{can("dataimport", "create") && (
					<div className="w-1/3 bg-primary-lightest border-gray p-3 mb-6 mr-3">
						<DataimportForm />
					</div>
				)}
				<div className="w-2/3 bg-primary-lightest border-gray p-3 mb-6">
					<DataimportSummary />
				</div>
			</div>

			<hr className="w-full" />
			<h2>{uctrans("dataimports.overview")}</h2>

			<div className="text-xs">
				<AbnOverview
					initialData={overviewData}
					indexUrl={route("crm.dataimports.index")}
					deleteRouteName="crm.dataimports.delete"
					modelTranslations={{
						plural: trans("dataimports.plural"),
						singular: trans("dataimports.singular"),
					}}
					renderFilters={() => (
						<Filters>
							<CheckboxMultiFilter name="type" options={overviewData.metadata.filterdata.types} />
						</Filters>
					)}
					renderBody={() => (
						<DataTable>
							<DataColumn width={1} renderHeaderContent={() => "#"} renderCellContent={dataimport => dataimport.id} />
							<DataColumn
								width={2}
								renderHeaderContent={() => uctrans("dataimports.fields.type")}
								renderCellContent={dataimport => dataimport.type_label}
							/>
							<DataColumn
								width={3}
								renderHeaderContent={() => uctrans("dataimports.fields.status")}
								renderCellContent={dataimport => dataimport.status_label}
							/>

							<DataColumn
								width={3}
								renderHeaderContent={() => uctrans("dataimports.input")}
								renderCellContent={dataimport => (
									<>
										{uctrans("dataimports.start")}:{" "}
										{dataimport.input_started_at
											? uctrans("general.:date_at_:time", {
													date: format(dataimport.input_started_at, "dd-MM-y"),
													time: format(dataimport.input_started_at, "HH:mm"),
											  })
											: "-"}
										<br />
										{uctrans("dataimports.end")}:{" "}
										{dataimport.input_finished_at
											? uctrans("general.:date_at_:time", {
													date: format(dataimport.input_finished_at, "dd-MM-y"),
													time: format(dataimport.input_finished_at, "HH:mm"),
											  })
											: "-"}
									</>
								)}
							/>
							<DataColumn
								width={3}
								renderHeaderContent={() => uctrans("dataimports.import")}
								renderCellContent={dataimport => (
									<>
										{uctrans("dataimports.start")}:{" "}
										{dataimport.import_started_at
											? uctrans("general.:date_at_:time", {
													date: format(dataimport.import_started_at, "dd-MM-y"),
													time: format(dataimport.import_started_at, "HH:mm"),
											  })
											: "-"}
										<br />
										{uctrans("dataimports.end")}:{" "}
										{dataimport.import_finished_at
											? uctrans("general.:date_at_:time", {
													date: format(dataimport.import_finished_at, "dd-MM-y"),
													time: format(dataimport.import_finished_at, "HH:mm"),
											  })
											: "-"}
									</>
								)}
							/>
							<DataColumn
								width={3}
								renderHeaderContent={() => uctrans("dataimports.migration")}
								renderCellContent={dataimport => (
									<>
										{uctrans("dataimports.start")}:{" "}
										{dataimport.migration_started_at
											? uctrans("general.:date_at_:time", {
													date: format(dataimport.migration_started_at, "dd-MM-y"),
													time: format(dataimport.migration_started_at, "HH:mm"),
											  })
											: "-"}
										<br />
										{uctrans("dataimports.end")}:{" "}
										{dataimport.migration_finished_at
											? uctrans("general.:date_at_:time", {
													date: format(dataimport.migration_finished_at, "dd-MM-y"),
													time: format(dataimport.migration_finished_at, "HH:mm"),
											  })
											: "-"}
									</>
								)}
							/>

							<DataColumn
								width={1}
								renderHeaderContent={() => uctrans("dataimports.fields.message")}
								renderCellContent={dataimport =>
									dataimport.message && (
										<Tippy placement="right" arrow duration="400" content={dataimport.message}>
											<span>
												<EvaIcon type="alert-triangle-outline" fill="#249286" height="18" width="18" />
											</span>
										</Tippy>
									)
								}
							/>

							<DataColumn
								width={1}
								renderHeaderContent={() => uctrans("dataimports.download_import_file")}
								renderCellContent={dataimport =>
									!!dataimport.download_link && (
										<a className="link" href={dataimport.download_link}>
											<EvaIcon type="download-outline" fill="#249286" height="18" width="18" />
										</a>
									)
								}
							/>

							{can("dataimport", "delete") && (
								<DataColumn
									width={1}
									renderHeaderContent={() => ""}
									renderCellContent={(dataimport, overview) => (
										<DeleteColumnContent onClick={() => overview.askDelete(dataimport)} />
									)}
								/>
							)}
						</DataTable>
					)}
				/>
			</div>
			{can("dataimport", "run_tasks") && (
				<div className="mt-12">
					<a href={route("crm.dataimports.run-inputhandler")} className="button button-secondary mr-2">
						Run Input Handler
					</a>
					<a href={route("crm.dataimports.run-importhandler")} className="button button-secondary mr-2">
						Run Import Handler
					</a>
					<a href={route("crm.dataimports.run-migrationhandler")} className="button button-secondary mr-2">
						Run Migration Handler
					</a>
					<a href={route("crm.dataimports.run-cleanuphandler")} className="button button-tertiary">
						Run clean-up Handler
					</a>
				</div>
			)}
			{window.data.environment !== "live" &&
				window.data.production_sync_data &&
				can("dataimport", "request_production_sync") && (
					<div className="mt-12 border-t">
						<h3>Data syncroniseren met productie omgeving</h3>

						{!!window.data.production_sync_data.requested && (
							<>
								<p>
									Productie-synchronisatie staat gepland voor aankomende nacht. Het systeem zal dan automatisch de
									productie-database dupliceren naar deze acceptatie omgeving
								</p>
								<a href={window.data.production_sync_data.remove_link} className="button button-tertiary mr-2">
									Annuleer productie-synchronisatie
								</a>
							</>
						)}
						{!window.data.production_sync_data.requested && (
							<>
								<p>
									Geen productie-synchronisatie gepland. Klik op de onderstaande knop op een synchronisatie aan te
									vragen. De komende nacht zal dan het systeem automatisch de productie-database dupliceren naar deze
									acceptatie omgeving
								</p>
								<a href={window.data.production_sync_data.request_link} className="button button-secondary mr-2">
									Vraag productie-synchronisatie aan
								</a>
							</>
						)}
					</div>
				)}
		</>
	);
}
