import axios from "axios";
import PropTypes from "prop-types";
import { useState } from "react";
import { route } from "../../../../../js/helpers";
import { format, parse } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import Dialog from "../../../../../js/react/components/general/Dialog";
import LabeledTextarea from "../../../../../js/react/components/general/form/LabeledTextarea";
import ContactpersonSelector from "../contactmoments/ContactpersonSelector";

export default function AppointmentForm(props) {
	const getFreshFormState = () => ({
		startDateTime: new Date(),
		// eslint-disable-next-line no-mixed-operators
		endDateTime: new Date(Date.now() + 3600 * 1000),
		selectedContactpersonIds: [],
		topic: "",
	});

	const [formData, setFormData] = useState(getFreshFormState());

	const closeForm = () => {
		setFormData(getFreshFormState());
		props.dialogClosedItself();
	};

	const submitForm = () => {
		axios({
			method: "POST",
			url: route("crm.intermediaries.download-appointment", { intermediary: props.companyId }),
			data: formData,
		}).then(result => {
			closeForm();
			const blob = new Blob([result.data]);
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(blob);
			link.download = result.headers["file-name"];
			link.click();
		});
	};

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	return (
		<Dialog
			width={1000}
			isOpen={props.isOpen}
			onClose={() => closeForm()}
			title={uctrans("intermediaries.appointment.make_appointment")}>
			<>
				<h2>{props.companyName}</h2>
				<hr />

				<div className="form-container mt-8">
					<div className="form-full">
						<div className="form-1-2">
							<label>{uctrans("intermediaries.appointment.start_datetime")}</label>
							<DatePicker
								showTimeSelect
								initialValue={formData.startDateTime ? parse(formData.startDateTime) : null}
								onChange={date => {
									handleFormValueChange("startDateTime", date ? format(date) : null);
								}}
							/>
						</div>
						<div className="form-1-2">
							<label>{uctrans("intermediaries.appointment.end_datetime")}</label>
							<DatePicker
								showTimeSelect
								initialValue={formData.endDateTime ? parse(formData.endDateTime) : null}
								onChange={date => {
									handleFormValueChange("endDateTime", date ? format(date) : null);
								}}
							/>
						</div>
					</div>
					<div className="form-full">
						<ContactpersonSelector
							contactpersons={props.selectableContactpersons ? props.selectableContactpersons : []}
							selectedContactpersonIds={formData.selectedContactpersonIds ? formData.selectedContactpersonIds : []}
							onSelectedContactpersonIdsChange={value => handleFormValueChange("selectedContactpersonIds", value)}
						/>
					</div>
					<div className="form-full">
						<div className="form-3-4">
							<LabeledTextarea
								label="intermediaries.appointment.topic"
								value={formData.topic ? formData.topic : ""}
								name="topic"
								onChange={e => handleFormValueChange("topic", e.target.value)}
							/>
						</div>
					</div>
					<div className="form-full">
						<a className="button button-primary" rel="noopener noreferrer" onClick={submitForm}>
							{uctrans("intermediaries.appointment.download_appointment")}
						</a>
					</div>
				</div>
			</>
		</Dialog>
	);
}

AppointmentForm.propTypes = {
	companyName: PropTypes.string,
	companyId: PropTypes.number,
	selectableContactpersons: PropTypes.array,
	isOpen: PropTypes.bool,
	dialogClosedItself: PropTypes.func,
};
