import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import { can, route } from "../../../../../js/helpers";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import CompanyKvkData from "../company/CompanyKvkData";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import SideBarChangelog from "../general/SideBarChangelog";
import SideBarStatusButton from "../general/SideBarStatusButton";
import TabLinks from "../general/tabs/TabLinks";
import cloneDeep from "lodash/cloneDeep";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import CollapsibleContent from "../general/CollapsibleContent";
import AllEstablishments from "../statutoryCompanies/AllEstablishments";
import OrganisationFormFields from "./OrganisationFormFields";

export default function OrganisationForm(props) {
	const [formData, setFormData] = useState(
		typeof props.companyFormData === "undefined" ? cloneDeep(window.data.company_form_data) : props.companyFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.companyStaticData === "undefined"
			? cloneDeep(window.data.company_static_data)
			: props.companyStaticData,
	);

	useEffect(() => {
		if (typeof props.companyFormData !== "undefined") {
			setFormData(props.companyFormData);
		}
	}, [props.companyFormData]);

	useEffect(() => {
		if (typeof props.companyStaticData !== "undefined") {
			setStaticData(props.companyStaticData);
		}
	}, [props.companyStaticData]);

	const onSuccess = () => {
		if (window.data.agendaNamespace) {
			Nav.go(route("crm.agenda-items.index"));
		} else {
			window.location.reload();
		}
	};

	const handleFormValuesChange = useCallback(formData => setFormData(formData), [setFormData]);

	const handleFormValueChange = useCallback(
		(key, value) => {
			const newFormData = { ...formData };
			newFormData[key] = value;
			setFormData(newFormData);
		},
		[formData],
	);

	return (
		<>
			<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
			<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />

			<div className="page-title-container">
				<h1 className="page-title">
					{staticData.company_name
						? uctrans(
								"general.:item1 from :item2",
								{ item2: staticData.company_name },
								{ item1: "companies.administrative_profile" },
						  )
						: uctrans(
								staticData.creating ? "general.add_:item" : "general.edit_:item",
								{},
								{ item: "organisations.singular" },
						  )}
				</h1>

				{window.data.agendaNamespace ? (
					<a className="link" href={route("crm.agenda-items.index")}>
						{uctrans("general.back_to_:page", {}, { page: "agendaitems.accountsupport" })}
					</a>
				) : (
					<a className="link" href={staticData.previous_link}>
						{uctrans("general.back_to_overview_:page", {}, { page: "organisations.plural" })}
					</a>
				)}
			</div>

			<AjaxForm
				method={staticData.save_method}
				submitUrl={staticData.save_link}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "organisations.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "organisations.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage={staticData.creating || window.data.agendaNamespace}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<OrganisationFormFields
							companyFormData={formData}
							companyStaticData={staticData}
							onChange={handleFormValuesChange}
						/>
					</div>
					<div className="flex-none w-1/3 border-l pl-12">
						<div>
							{!staticData.creating && (
								<CollapsibleContent title={uctrans("intermediaries.establishments")}>
									<CompanyKvkData
										changeStatutoryCompanyFields={handleFormValueChange}
										statutoryCompany={formData.statutory_company}
										companyId={formData.id}
										mainCompany={formData.is_main_company}
									/>
									<br />
									<AllEstablishments statutoryCompanyId={formData.statutory_company.id} />
								</CollapsibleContent>
							)}

							{staticData.changelog_changes_statutory && staticData.changelog_changes_statutory.afm_number && (
								<CollapsibleContent
									title={uctrans("general.:item_changes", {}, { item: "intermediaries.fields.afm_number" })}>
									<SideBarChangelog
										changelogData={staticData.changelog_changes_statutory.afm_number}
										changelogChangeTypes={staticData.changelog_change_types}
									/>
								</CollapsibleContent>
							)}

							{staticData.changelog_changes_statutory && staticData.changelog_changes_statutory.kifid_number && (
								<CollapsibleContent
									title={uctrans("general.:item_changes", {}, { item: "intermediaries.fields.kifid_number" })}>
									<SideBarChangelog
										changelogData={staticData.changelog_changes_statutory.kifid_number}
										changelogChangeTypes={staticData.changelog_change_types}
									/>
								</CollapsibleContent>
							)}

							<CollapsibleContent title={uctrans("intermediaries.fields.actions")}>
								{can("organisation", "update_status") && (
									<SideBarStatusButton
										company_id={formData.id}
										company_status={formData.status}
										company_statuses={staticData.company_statuses}
										organisation
									/>
								)}
							</CollapsibleContent>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="w-full md:w-1/2 lg:w-1/3 column">
						<SubmitBar
							item="organisations.singular"
							addAnotherAvailable={false}
							submitDisabled={!can("organisation", "update_administrative")}
						/>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}

OrganisationForm.propTypes = {
	companyFormData: PropTypes.object,
	companyStaticData: PropTypes.object,
};
