import PropTypes from "prop-types";
import map from "lodash/map";
import { uctrans } from "../../../../../../../js/lib/Translator";
import FormattedNumberText from "../../FormattedNumberText";
import PieChart from "../../graph/PieChart";

export default function CadastreReport({ data }) {
	let colSpan = 5;
	if (data && data.extra_data) {
		if (data.extra_data.province) {
			colSpan += 3;
			if (data.extra_data.township) {
				colSpan += 3;
				if (data.extra_data.zipcode_area_from || data.extra_data.zipcode_area_until) {
					colSpan += 3;
				}
			}
		}
	}

	const postCodeLabel = () => {
		let postCodeLabel = "";
		if (data.extra_data.zipcode_area_from != null && data.extra_data.zipcode_area_until == null) {
			postCodeLabel = `Postcodes vanaf ${data.extra_data.zipcode_area_from.number}`;
		} else if (data.extra_data.zipcode_area_until != null && data.extra_data.zipcode_area_from == null) {
			postCodeLabel = `Postcodes t/m ${data.extra_data.zipcode_area_until.number}`;
		} else if (data.extra_data.zipcode_area_from != null && data.extra_data.zipcode_area_until != null) {
			if (data.extra_data.zipcode_area_from === data.extra_data.zipcode_area_until) {
				postCodeLabel = `Postcode ${data.extra_data.zipcode_area_from.number}`;
			} else {
				postCodeLabel = `Postcode ${data.extra_data.zipcode_area_from.number} t/m ${data.extra_data.zipcode_area_until.number}`;
			}
		}

		return postCodeLabel;
	};

	const firstChartLabel = () => {
		if (data.graph_data) {
			if (data.graph_data.zipcode_chart) {
				return postCodeLabel();
			} else if (data.graph_data.township_chart) {
				return `Gemeente ${data.extra_data.township.name}`;
			} else if (data.graph_data.province_chart) {
				return `Provincie ${data.extra_data.province.name}`;
			}
			return null;
		}
		return null;
	};

	const firstChartData = () => {
		if (data.graph_data) {
			if (data.graph_data.zipcode_chart) {
				return data.graph_data.zipcode_chart;
			} else if (data.graph_data.township_chart) {
				return data.graph_data.township_chart;
			} else if (data.graph_data.province_chart) {
				return data.graph_data.province_chart;
			}
			return null;
		}
		return null;
	};

	const getRow = (entry, total = false) => (
		<tr className={total ? "totaal" : undefined}>
			<td colSpan="2">{entry.label}</td>
			{data.extra_data &&
				(data.extra_data.zipcode_area_from != null || data.extra_data.zipcode_area_until != null ? (
					<>
						<td className="bedrag postcode">
							<FormattedNumberText
								value={
									entry.group && entry.group.zipcode && entry.group.zipcode.sum ? entry.group.zipcode.sum / 1000 : 0
								}
								prefix="€ "
							/>
						</td>
						<td className="aantal postcode">
							<FormattedNumberText
								value={entry.group && entry.group.zipcode && entry.group.zipcode.count ? entry.group.zipcode.count : 0}
							/>
						</td>
						<td className="percentage postcode">
							<FormattedNumberText
								value={
									entry.group && entry.group.zipcode && entry.group.zipcode.percentage
										? entry.group.zipcode.percentage
										: 0
								}
								decimalScale={1}
								percentage
							/>
						</td>
					</>
				) : null)}
			{data.extra_data && data.extra_data.township && (
				<>
					<td className="bedrag gemeente">
						<FormattedNumberText
							value={
								entry.group && entry.group.township && entry.group.township.sum ? entry.group.township.sum / 1000 : 0
							}
							prefix="€ "
						/>
					</td>
					<td className="aantal gemeente">
						<FormattedNumberText
							value={entry.group && entry.group.township && entry.group.township.count ? entry.group.township.count : 0}
						/>
					</td>
					<td className="percentage gemeente">
						<FormattedNumberText
							value={
								entry.group && entry.group.township && entry.group.township.percentage
									? entry.group.township.percentage
									: 0
							}
							decimalScale={1}
							percentage
						/>
					</td>
				</>
			)}
			{data.extra_data && data.extra_data.province && (
				<>
					<td className="bedrag provincie">
						<FormattedNumberText
							value={
								entry.group && entry.group.province && entry.group.province.sum ? entry.group.province.sum / 1000 : 0
							}
							prefix="€ "
						/>
					</td>
					<td className="aantal provincie">
						<FormattedNumberText
							value={entry.group && entry.group.province && entry.group.province.count ? entry.group.province.count : 0}
						/>
					</td>
					<td className="percentage provincie">
						<FormattedNumberText
							value={
								entry.group && entry.group.province && entry.group.province.percentage
									? entry.group.province.percentage
									: 0
							}
							decimalScale={1}
							percentage
						/>
					</td>
				</>
			)}

			<td className="bedrag land">
				<FormattedNumberText
					value={entry.group && entry.group.country && entry.group.country.sum ? entry.group.country.sum / 1000 : 0}
					prefix="€ "
				/>
			</td>
			<td className="aantal land">
				<FormattedNumberText
					value={entry.group && entry.group.country && entry.group.country.count ? entry.group.country.count : 0}
				/>
			</td>
			<td className="percentage land">
				<FormattedNumberText
					value={
						entry.group && entry.group.country && entry.group.country.percentage ? entry.group.country.percentage : 0
					}
					decimalScale={1}
					percentage
				/>
			</td>
		</tr>
	);

	return (
		<>
			<table className="marktscan_rapportage_table_kadaster rapportage_table reporttable">
				<thead>
					<tr>
						<th colSpan={colSpan}>{data.title ? data.title : ""}</th>
					</tr>
					<tr className="head1">
						<td colSpan="2" />
						{data.extra_data && (data.extra_data.zipcode_area_from || data.extra_data.zipcode_area_until) && (
							<td colSpan="3" className="groeptitel postcode">
								{postCodeLabel()}
							</td>
						)}
						{data.extra_data && data.extra_data.township && (
							<td colSpan="3" className="groeptitel gemeente">
								{data.extra_data.township.name}
							</td>
						)}
						{data.extra_data && data.extra_data.province && (
							<td colSpan="3" className="groeptitel provincie">
								Provincie {data.extra_data.province.name}
							</td>
						)}
						<td colSpan="3" className="groeptitel">
							Land
						</td>
					</tr>

					<tr className="head2">
						<td colSpan="2" className="kop">
							{uctrans(`report.market_scan.type.${data.type}`)}
						</td>
						{data.extra_data && (data.extra_data.zipcode_area_from || data.extra_data.zipcode_area_until) && (
							<>
								<td className="bedrag postcode">
									Bedrag <span>(x1.000)</span>
								</td>
								<td className="aantal postcode">Aantal</td>
								<td className="percentage postcode">%</td>
							</>
						)}
						{data.extra_data && data.extra_data.township && (
							<>
								<td className="bedrag gemeente">
									Bedrag <span>(x1.000)</span>
								</td>
								<td className="aantal gemeente">Aantal</td>
								<td className="percentage gemeente">%</td>
							</>
						)}
						{data.extra_data && data.extra_data.province && (
							<>
								<td className="bedrag gemeente">
									Bedrag <span>(x1.000)</span>
								</td>
								<td className="aantal gemeente">Aantal</td>
								<td className="percentage gemeente">%</td>
							</>
						)}

						<td className="bedrag land">
							Bedrag <span>(x1.000)</span>
						</td>
						<td className="aantal land">Aantal</td>
						<td className="percentage land">%</td>
					</tr>
				</thead>
				<tbody>
					{map(data.data, entry => getRow(entry))} {data.total && getRow(data.total, true)}
				</tbody>
			</table>

			{!!data && (
				<table className="w-full text-center table-fixed mt-5">
					<thead>
						<tr className="head1">
							{firstChartLabel() ? <td className="font-bold">{firstChartLabel()}</td> : null}
							{data.graph_data && data.graph_data.country_chart ? (
								<td className="font-bold">{uctrans("report.market_scan.country")}</td>
							) : null}
						</tr>
					</thead>
					<tbody>
						<tr>
							{firstChartData() ? (
								<td>
									<PieChart chartData={firstChartData()} />
								</td>
							) : null}
						</tr>
					</tbody>
				</table>
			)}
		</>
	);
}

CadastreReport.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
