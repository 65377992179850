import PropTypes from "prop-types";
import { useCallback, useContext, useMemo } from "react";
import size from "lodash/size";
import Checkmark from "../../../../../../../js/react/components/general/form/checkmark/Checkmark";
import CompanyDossierContext from "../CompanyDossierContext";
import DossierPartContent from "./DossierPartContent";

export default function DossierPart(props) {
	const {
		isPartSelected,
		activeDossierParts,
		selectPart,
		unSelectPart,
		dossierPartTypes,
		activeDossierDirectors,
		readOnly,
	} = useContext(CompanyDossierContext);

	const getDossierPartCompletionContent = useCallback(
		id => activeDossierParts.find(activeDossierPart => Number(activeDossierPart.dossier_part_id) === Number(id)),
		[activeDossierParts],
	);

	const disabled = useMemo(
		() =>
			Boolean(
				readOnly
					? true
					: Number(props.data.type) === Number(dossierPartTypes.DOSSIER_DIRECTOR.key)
					  ? getDossierPartCompletionContent(props.data.id) &&
					    getDossierPartCompletionContent(props.data.id).id &&
					    size(activeDossierDirectors.filter(director => !director.deleted)) > 0
					  : getDossierPartCompletionContent(props.data.id) &&
					    getDossierPartCompletionContent(props.data.id).id &&
					    (size(getDossierPartCompletionContent(props.data.id).files.files) > 0 ||
								size(getDossierPartCompletionContent(props.data.id).files.archivedFiles)),
			),
		[
			readOnly,
			props.data.type,
			props.data.id,
			dossierPartTypes.DOSSIER_DIRECTOR.key,
			getDossierPartCompletionContent,
			activeDossierDirectors,
		],
	);

	return (
		<div className="form-full flex justify-between">
			<Checkmark
				onChange={e => (e.target.checked ? selectPart(props.data.id) : unSelectPart(props.data.id))}
				name={props.data.name}
				checked={isPartSelected(props.data.id)}
				disabled={disabled}
				label={props.data.name}
			/>
			{isPartSelected(props.data.id) && (
				<div className="ml-8 dossier-parts">
					<DossierPartContent partData={props.data} completionData={getDossierPartCompletionContent(props.data.id)} />
				</div>
			)}
		</div>
	);
}

DossierPart.propTypes = {
	data: PropTypes.object,
};
