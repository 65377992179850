import { useState } from "react";
import { format } from "../../../../../../js/lib/Date";
import { uctrans } from "../../../../../../js/lib/Translator";
import Tippy from "@tippyjs/react";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import Translate from "../../../../../../js/react/components/general/Translate";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../../js/react/components/general/overview/SearchBarFilter";
import IconButton from "../../../../../../js/react/components/general/IconButton";

export default function MenuitemOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
		create_link: window.data.menuitem_data.create_link,
		index_link: window.data.menuitem_data.index_link,
		permissions: window.data.menuitem_data.permissions,
	});

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("general.cms") }]} />

			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="cms.menuitems.plural" />
				</h1>
				{overviewData.create_link && (
					<div>
						<IconButton
							href={overviewData.create_link}
							content={uctrans("general.add_:item", {}, { item: "cms.menuitems.singular" })}
							primary
						/>
					</div>
				)}
			</div>
			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={overviewData.index_link}
				modelTranslations={{ plural: uctrans("cms.menuitems.plural"), singular: uctrans("cms.menuitems.singular") }}
				useDragAndDrop
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={1}
							className="draggable-overview-item-handle"
							renderHeaderContent={() => ""}
							renderCellContent={() => ""}
						/>
						<DataColumn
							width={1}
							sortable="title"
							renderHeaderContent={() => ""}
							renderCellContent={menuitem => {
								switch (menuitem.publish_status) {
									case 1:
										return (
											<Tippy
												placement="right"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("cms.publish_statuses.1")}>
												<span>
													<EvaIcon type="checkmark-circle-2" fill="#6FAD2B" height="18" width="18" />
												</span>
											</Tippy>
										);
									case 2:
										return (
											<Tippy
												placement="right"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("cms.publish_statuses.2")}>
												<span>
													<EvaIcon type="close-circle" fill="#BF3030" height="18" width="18" />
												</span>
											</Tippy>
										);
									case 3:
										if (menuitem.published_now) {
											return (
												<Tippy
													placement="right"
													arrow
													animation="perspective"
													duration="400"
													content={`${uctrans("cms.publish_statuses.3")} ${
														menuitem.published_from ? format(menuitem.published_from, "dd-MM-y") : "..."
													} - ${menuitem.published_to ? format(menuitem.published_to, "dd-MM-y") : "..."}`}>
													<span>
														<EvaIcon type="clock" fill="#34D0B6" height="18" width="18" />
													</span>
												</Tippy>
											);
										} else {
											return (
												<Tippy
													placement="right"
													arrow
													animation="perspective"
													duration="400"
													content={`${uctrans("cms.publish_statuses.3")} ${
														menuitem.published_from ? format(menuitem.published_from, "dd-MM-y") : "..."
													} - ${menuitem.published_to ? format(menuitem.published_to, "dd-MM-y") : "..."}`}>
													<span>
														<EvaIcon type="clock" fill="#FF9D40" height="18" width="18" />
													</span>
												</Tippy>
											);
										}
									default:
										return <EvaIcon type="question-mark-circle" fill="#c3c3c3" height="18" width="18" />;
								}
							}}
						/>
						<DataColumn
							width={1}
							sortable="title"
							renderHeaderContent={() => ""}
							renderCellContent={menuitem => {
								if (menuitem.login_required) {
									return (
										<Tippy
											placement="right"
											arrow
											animation="perspective"
											duration="400"
											content={uctrans("cms.menuitems.fields.closed_section")}>
											<span>
												<EvaIcon type="lock" fill="#9c9c9c" height="18" width="18" />
											</span>
										</Tippy>
									);
								}
							}}
						/>
						<DataColumn
							width={20}
							sortable="title"
							renderHeaderContent={() => uctrans("cms.menuitems.fields.title")}
							renderCellContent={menuitem => (
								<a href={menuitem.edit_link} className="row-title link">
									{menuitem.title}
								</a>
							)}
						/>
						{overviewData.permissions.delete && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(menuitem, overview) =>
									menuitem.delete_link && <DeleteColumnContent onClick={() => overview.askDelete(menuitem)} />
								}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
