import { uctrans } from "../../../../../js/lib/Translator";
import PropTypes from "prop-types";
import Select from "../../../../../js/react/components/general/Select";
import filter from "lodash/filter";
import map from "lodash/map";

const QuestionTypeSelector = ({ questionTypes, questionFieldTypes, selectedType, selectedFieldType, onChange }) => {
	const filteredQuestionTypes = filter(questionTypes, questionType => questionType.isAvailable).map(type => ({
		label: type.name,
		value: type.key,
	}));

	const filteredQuestionFieldTypes = map(questionFieldTypes, type => ({ label: type.label, value: type.key }));

	const getSelectedQuestionType = () => {
		let selectedSelectOption = "";
		if (selectedType) {
			selectedSelectOption = filter(filteredQuestionTypes, ["value", selectedType]);
		}
		return selectedSelectOption;
	};

	const getSelectedQuestionFieldType = () => {
		let selectedSelectOption = "";
		if (selectedFieldType) {
			selectedSelectOption = filter(filteredQuestionFieldTypes, ["value", selectedFieldType]);
		}
		return selectedSelectOption;
	};

	const onSelectChange = (selectedOption, linkedFieldOption = null) => {
		onChange(selectedOption, linkedFieldOption);
	};

	const LinkedFieldTypeChosen =
		getSelectedQuestionType() !== "" &&
		typeof questionTypes.LINKED_FIELD !== "undefined" &&
		Number(getSelectedQuestionType()[0].value) === Number(questionTypes.LINKED_FIELD.key);

	return (
		<div className="form-question-type-selector mb-12">
			<Select
				placeholder={uctrans("general.select_:item", {}, { item: "questionnaires.question.fields.type" })}
				value={getSelectedQuestionType()}
				options={filteredQuestionTypes}
				name="question_type"
				onChange={selectedOption => onSelectChange(selectedOption.value, null)}
				isClearable={false}
			/>

			{LinkedFieldTypeChosen && (
				<Select
					placeholder={uctrans(
						"general.select_:item",
						{},
						{ item: "questionnaires.question.fields.linked_field_type" },
					)}
					value={getSelectedQuestionFieldType()}
					options={filteredQuestionFieldTypes}
					name="question_linked_field_type"
					onChange={selectedOption => onSelectChange(getSelectedQuestionType()[0].value, selectedOption.value)}
					isClearable={false}
				/>
			)}
		</div>
	);
};

QuestionTypeSelector.propTypes = {
	questionTypes: PropTypes.object.isRequired,
	questionFieldTypes: PropTypes.object.isRequired,
	selectedType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	selectedFieldType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func,
};

export default QuestionTypeSelector;
