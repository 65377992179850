import PropTypes from "prop-types";
import map from "lodash/map";
import { uctrans } from "../../../../../../../js/lib/Translator";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import FormattedNumberText from "../../FormattedNumberText";

export default function QualitativeResults({ data, title }) {
	const { relationmanagers, total, survey_link } = data;

	const checkForFloat = value => Number(value) === value && value % 1 !== 0;

	return (
		<table className="gesprek_rapportage_table rapportage_table reporttable">
			<thead>
				<tr>
					<th colSpan={data.colSpan}>{title ? title : uctrans("report.singular")}</th>
				</tr>
				<tr className="head2">
					{total &&
						map(total, (value, key) =>
							key === "questionnaire" ? (
								map(value, (subData, subKey) => (
									<td className="gerealiseerd" key={subKey}>
										{subData.label}
									</td>
								))
							) : (
								<td className="gerealiseerd" key={key}>
									{key}
									{key === "Enquetes" && (
										<a href={survey_link}>
											<span className="inline-block ml-1 align-middle">
												<EvaIcon type="arrow-circle-right-outline" fill="#009286" height="18" width="18" />
											</span>
										</a>
									)}
								</td>
							),
						)}
				</tr>
			</thead>
			<tbody>
				{relationmanagers &&
					map(relationmanagers, (relationManagerData, key) => (
						<tr key={key}>
							{map(relationManagerData, (value, dataKey) =>
								dataKey === "questionnaire" ? (
									map(value, (subData, subKey) => (
										<td className="gerealiseerd" key={subKey}>
											<FormattedNumberText value={subData.value} decimalScale={1} />
										</td>
									))
								) : (
									<td className="gerealiseerd" key={dataKey}>
										{checkForFloat(value) ? <FormattedNumberText value={value} decimalScale={1} /> : value}
									</td>
								),
							)}
						</tr>
					))}
				<tr className="totaal">
					{total &&
						map(total, (value, key) =>
							key === "questionnaire" ? (
								map(value, (subData, subKey) => (
									<td className="gerealiseerd" key={subKey}>
										<b>
											<FormattedNumberText value={subData.value} decimalScale={1} />
										</b>
									</td>
								))
							) : (
								<td className="gerealiseerd" key={key}>
									<b> {checkForFloat(value) ? <FormattedNumberText value={value} decimalScale={1} /> : value}</b>
								</td>
							),
						)}
				</tr>
			</tbody>
		</table>
	);
}

QualitativeResults.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	title: PropTypes.string,
};
