import { useState } from "react";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route, yesNoOptions } from "../../../../../../js/helpers";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../../js/react/components/general/overview/SearchBarFilter";
import SelectFilter from "../../../../../../js/react/components/general/overview/SelectFilter";
import Navigation from "../SettingsNavigation";

export default function NewsletterSubscriptionTypeOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<Navigation selectedTab="newsletterSubscriptionTypes" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("communication.newsletter_subscription_types.plural")}</h1>
				{can("communication.newsletter_subscription_type", "create") && (
					<div>
						<IconButton
							href={route("crm.communication-settings.newsletter-subscription-types.create")}
							content={uctrans(
								"general.add_:item",
								{},
								{ item: "communication.newsletter_subscription_types.singular" },
							)}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.communication-settings.newsletter-subscription-types.index")}
				deleteRouteName="crm.communication-settings.newsletter-subscription-types.delete"
				moveRouteName="crm.communication-settings.newsletter-subscription-types.move"
				useDragAndDrop={can("communication.newsletter_subscription_type", "move")}
				hideControls
				modelTranslations={{
					plural: trans("communication.newsletter_subscription_types.plural"),
					singular: trans("communication.newsletter_subscription_types.singular"),
				}}
				renderFilters={overview => (
					<Filters>
						<SearchBarFilter type="text" name="search" />

						<SelectFilter
							className="form-1-3"
							name="is_active"
							label={uctrans("afm_permits.check_actions.fields.is_active")}
							options={yesNoOptions()}
							collapsible
						/>
						<SelectFilter
							className="form-1-3"
							name="for_automated_messages"
							label={uctrans("communication.newsletter_subscription_types.fields.for_automated_messages")}
							options={yesNoOptions()}
							collapsible
						/>
						<SelectFilter
							className="form-1-3"
							name="for_newsletters"
							label={uctrans("communication.newsletter_subscription_types.fields.for_newsletters")}
							options={yesNoOptions()}
							collapsible
						/>

						<SelectFilter
							className="form-1-3"
							name="contactpersons_can_unsubscribe"
							label={uctrans("communication.newsletter_subscription_types.fields.contactpersons_can_unsubscribe")}
							options={yesNoOptions()}
							collapsible
						/>

						<SelectFilter
							className="form-1-3"
							name="companies_can_unsubscribe"
							label={uctrans("communication.newsletter_subscription_types.fields.companies_can_unsubscribe")}
							options={yesNoOptions()}
							collapsible
						/>

						<SelectFilter
							className="form-1-3"
							name="users_can_unsubscribe"
							label={uctrans("communication.newsletter_subscription_types.fields.users_can_unsubscribe")}
							options={yesNoOptions()}
							collapsible
						/>

						<SelectFilter
							className="form-1-3"
							name="for_mailing_list"
							label={uctrans("communication.newsletter_subscription_types.fields.for_mailing_list")}
							options={yesNoOptions()}
							collapsible
						/>

						{`${overview.data.metadata.filtering.filters.for_mailing_list}` === "1" && (
							<SelectFilter
								className="form-1-3"
								name="mailing_list_can_unsubscribe"
								label={uctrans("communication.newsletter_subscription_types.fields.mailing_list_can_unsubscribe")}
								options={yesNoOptions()}
								collapsible
							/>
						)}
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						{can("communication.newsletter_subscription_type", "move") && (
							<DataColumn
								width={1}
								className="draggable-overview-item-handle"
								renderHeaderContent={() => ""}
								renderCellContent={() => ""}
							/>
						)}
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("communication.newsletter_subscription_types.fields.name")}
							renderCellContent={newsletterSubscriptionType => {
								if (can("communication.newsletter_subscription_type", "update")) {
									return (
										<a
											href={route(
												"crm.communication-settings.newsletter-subscription-types.edit",
												newsletterSubscriptionType.id,
											)}
											className="row-title link">
											{newsletterSubscriptionType.name}
										</a>
									);
								} else {
									return newsletterSubscriptionType.name;
								}
							}}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("communication.newsletter_subscription_types.fields.category")}
							renderCellContent={({ category }) => (category ? category.name : "-")}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("communication.newsletter_subscription_types.fields.active")}
							renderCellContent={softwarePackage =>
								softwarePackage.is_active ? uctrans("general.yes") : uctrans("general.no")
							}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("communication.newsletter_subscription_types.fields.for_newsletters")}
							renderCellContent={newsletterSubscriptionType =>
								newsletterSubscriptionType.for_newsletters ? uctrans("general.yes") : uctrans("general.no")
							}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() =>
								uctrans("communication.newsletter_subscription_types.fields.for_automated_messages")
							}
							renderCellContent={newsletterSubscriptionType =>
								newsletterSubscriptionType.for_automated_messages ? uctrans("general.yes") : uctrans("general.no")
							}
						/>
						{can("communication.newsletter_subscription_type", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(newsletterSubscriptionType, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(newsletterSubscriptionType)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
