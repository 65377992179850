import PropTypes from "prop-types";
import { format, parse } from "../../../../../../../js/lib/Date";
import DatePicker from "../../../../../../../js/react/components/general/DatePicker";

export default function DateTime(props) {
	const onchange = e => {
		props.onChange(e);
	};
	return (
		<>
			{props.label && <label>{props.label}</label>}
			<DatePicker
				showClearButton
				showTimeSelect
				initialValue={props.value ? parse(props.value) : null}
				onChange={date => onchange(date ? format(date) : null)}
			/>
		</>
	);
}

DateTime.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	onChange: PropTypes.func,
};
