import { renderInputProps, renderRootProps } from "@crm/lib/fileInputHelpers";
import Dropzone from "react-dropzone";
import DeleteDialog from "../../../../../../../js/react/components/general/DeleteDialog";
import { createLoader, error } from "@/react/components/general/notifications";
import axios from "axios";
import PropTypes from "prop-types";
import forEach from "lodash/forEach";
import upperFirst from "lodash/upperFirst";
import map from "lodash/map";
import { useCallback, useState, useEffect } from "react";
import { uctrans } from "@/lib/Translator";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import Translate from "../../../../../../../js/react/components/general/Translate";
import { route } from "@/helpers";
import DossierFileInputTrackData from "./DossierFileInputTrackData";

export default function GeneralDossierFileInput(props) {
	const [files, setFiles] = useState(props.initialFiles);
	const [deletedFile, setDeleteFile] = useState(null);
	const [archivedFiles, setArchivedFiles] = useState(props.initialArchivedFiles);
	const [showArchivedFiles, setShowArchivedFiles] = useState(false);
	const handleChange = useCallback(
		(files, archiveFiles) => {
			props.onChange({
				files,
				archivedFiles: archiveFiles,
			});

			setArchivedFiles(archiveFiles);
			if (files.length > 0 && props.clearAfterUpload) {
				setFiles([]);
			} else {
				setFiles(files);
			}
		},
		[props],
	);

	useEffect(() => {
		if (typeof props.initialFiles !== "undefined") {
			setFiles(props.initialFiles);
		}
	}, [props.initialFiles]);

	useEffect(() => {
		if (typeof props.initialArchivedFiles !== "undefined") {
			setArchivedFiles(props.initialArchivedFiles);
		}
	}, [props.initialArchivedFiles]);

	const handleUpload = useCallback(
		async event => {
			const count = event.length;
			if (!count) {
				return;
			}

			if (props.onBeforeUpload) {
				props.onBeforeUpload();
			}

			let loader = null;
			if (props.useLoaders) {
				loader = createLoader(uctrans("file.file_uploading"));
			}

			if (count > props.maxFiles - files.length) {
				if (loader) {
					loader.failure(uctrans("file.too_many_files_selected"));
				}
				return;
			}

			let iteration = 0;
			let success = true;
			const uploadedFiles = [];

			for (const file of event) {
				if (count > 1 && loader) {
					loader.update(uctrans("file.file_:number_of_:total_uploading", { number: ++iteration, total: count }));
				}

				try {
					const data = new FormData();
					data.append("file", file);
					data.append("public", props.public ? "1" : "0");
					const result = await axios.post(route(props.storeRoute), data);
					if (result.data) {
						uploadedFiles.push({
							id: result.data.id,
							filename: result.data.filename,
							id_token: result.data.id_token,
						});
					}
				} catch (exception) {
					success = false;

					if (exception.response && exception.response.status === 422) {
						if (Object.prototype.hasOwnProperty.call(exception.response.data, "errors")) {
							forEach(exception.response.data.errors, message => {
								error(upperFirst(message[0]));
							});
						}
					} else if (exception.message) {
						error(exception.message);
					}
				}
			}

			if (success) {
				handleChange([...files, ...uploadedFiles], archivedFiles);
				if (loader) {
					loader.success(uctrans("file.file_uploading_complete"));
				}
			} else {
				if (loader) {
					loader.failure(uctrans("file.file_uploading_failed"));
				}
			}
		},
		[files, archivedFiles, handleChange, props],
	);

	const deleteFile = useCallback(
		id => {
			handleChange(
				files.filter(file => file.id !== id),
				archivedFiles.filter(file => file.id !== id),
			);
		},
		[files, archivedFiles, handleChange],
	);

	const archiveFile = useCallback(
		file => {
			handleChange(
				files.filter(filterFile => filterFile.id !== file.id),
				[...archivedFiles, file],
			);
		},
		[handleChange, archivedFiles, files],
	);

	const unArchiveFile = useCallback(
		file => {
			handleChange(
				[...files, file],
				archivedFiles.filter(filesFile => filesFile.id !== file.id),
			);
		},
		[handleChange, archivedFiles, files],
	);

	return (
		<>
			{(files.length > 0 || archivedFiles.length > 0) && (
				<div className="flex-1 pr-2">
					{map(files, file => (
						<div key={file.id} className="border border-grey mb-2 p-3 flex">
							{!file.object_id && !!props.newFileDownloadRoute ? (
								<a className="link flex-1" href={route(props.newFileDownloadRoute, { idtoken: file.id_token })}>
									{file.filename}
								</a>
							) : !!file.object_id && !!props.existingFileDownloadRoute ? (
								<DossierFileInputTrackData file={file}>
									<a className="link flex-1" href={route(props.existingFileDownloadRoute, file.id)}>
										{file.filename}
									</a>
								</DossierFileInputTrackData>
							) : (
								<DossierFileInputTrackData file={file}>
									<span>{file.filename}</span>
								</DossierFileInputTrackData>
							)}
							{!props.disabled && (
								<div className="push">
									<a onClick={() => setDeleteFile(file)}>
										<EvaIcon className="ml-4" type="trash-2-outline" fill="#de751f" width="20" height="20" />
									</a>
									<a onClick={() => archiveFile(file)}>
										<EvaIcon className="ml-4" type="arrow-downward-outline" fill="#239386" width="20" height="20" />
									</a>
								</div>
							)}
						</div>
					))}

					{archivedFiles.length > 0 &&
						(showArchivedFiles ? (
							<>
								<a className="link flex-1 inline-block mb-4" onClick={() => setShowArchivedFiles(false)}>
									<EvaIcon type="eye-off-outline" fill="#239386" height="17" width="30" />
									<small>{uctrans("dossiers.fields.hide_archived_files")}</small>
								</a>
								<br />
								{map(archivedFiles, file => (
									<div key={file.id} className="border border-grey mb-2 flex-1 p-3 w-full flex">
										{!file.object_id && !!props.newFileDownloadRoute ? (
											<a className="link flex-1" href={route(props.newFileDownloadRoute, { idtoken: file.id_token })}>
												{file.filename}
											</a>
										) : !!file.object_id && !!props.existingFileDownloadRoute ? (
											<DossierFileInputTrackData file={file}>
												<a className="link flex-1" href={route(props.existingFileDownloadRoute, file.id)}>
													{file.filename}
												</a>
											</DossierFileInputTrackData>
										) : (
											<DossierFileInputTrackData file={file}>
												<span>{file.filename}</span>
											</DossierFileInputTrackData>
										)}
										{!props.disabled && (
											<div className="push">
												<a onClick={() => setDeleteFile(file)}>
													<EvaIcon className="ml-4" type="trash-2-outline" fill="#de751f" width="20" height="20" />
												</a>
												<a onClick={() => unArchiveFile(file)}>
													<EvaIcon className="ml-4" type="arrow-upward-outline" fill="#239386" width="20" height="20" />
												</a>
											</div>
										)}
									</div>
								))}
							</>
						) : (
							<>
								<a className="link flex-1" onClick={() => setShowArchivedFiles(true)}>
									<EvaIcon type="eye-outline" fill="#239386" height="17" width="30" />
									<small>{uctrans("dossiers.fields.show_archived_files")}</small>
								</a>
								<br />
							</>
						))}
				</div>
			)}

			{!props.disabled && files.length < props.maxFiles && !!props.storeRoute && (
				<div>
					<label className={`button button-upload inline-block relative ${props.disabled ? "disabled" : ""}`}>
						<Dropzone onDrop={handleUpload} disabled={props.disabled}>
							{({ getRootProps, getInputProps }) => (
								<section>
									<div {...renderRootProps(getRootProps(), props)}>
										<input {...renderInputProps(getInputProps(), props, files)} />
										<p>
											<EvaIcon
												type="file-add-outline"
												className="absolute left-0 ml-3"
												fill="#ffffff"
												height="20"
												width="20"
											/>
											{files.length === 0 && archivedFiles.length === 0 && (
												<Translate content="file.drag_and_drop_a_file" />
											)}
										</p>
									</div>
								</section>
							)}
						</Dropzone>
					</label>
				</div>
			)}

			<DeleteDialog
				isOpen={deletedFile !== null}
				deleteData={{
					errors: [],
					warnings: [],
					message: `${uctrans(
						"general.confirm_delete_:item_:label",
						{ label: deletedFile ? deletedFile.filename : "" },
						{ item: "cms.options.document" },
					)}
					${uctrans("dossiers.warnings.delete_document")}`,
				}}
				onConfirm={() => {
					deleteFile(deletedFile.id);
					setDeleteFile(null);
				}}
				onCancel={() => setDeleteFile(null)}
			/>
		</>
	);
}

GeneralDossierFileInput.propTypes = {
	storeRoute: PropTypes.string,
	newFileDownloadRoute: PropTypes.string,
	existingFileDownloadRoute: PropTypes.string,
	maxFiles: PropTypes.number,
	acceptedExtensions: PropTypes.array,
	initialFiles: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				filename: PropTypes.string.isRequired,
			}),
		),
		PropTypes.objectOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				filename: PropTypes.string.isRequired,
			}),
		),
	]),
	initialArchivedFiles: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				filename: PropTypes.string.isRequired,
			}),
		),
		PropTypes.objectOf(
			PropTypes.shape({
				id: PropTypes.number.isRequired,
				filename: PropTypes.string.isRequired,
			}),
		),
	]),
	public: PropTypes.bool,
	onChange: PropTypes.func,
	onBeforeUpload: PropTypes.func,
	clearAfterUpload: PropTypes.bool,
	useLoaders: PropTypes.bool,
	disabled: PropTypes.bool,
};

GeneralDossierFileInput.defaultProps = {
	initialFiles: [],
	initialArchivedFiles: [],
	public: false,
	clearAfterUpload: false,
	useLoaders: true,
	disabled: false,
};
