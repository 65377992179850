import cloneDeep from "lodash/cloneDeep";
import { useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import Select from "../../../../../../js/react/components/general/Select";
import WalletAnalysisObjectiveReport from "./components/WalletAnalysisObjectiveReport";
import WalletAnalysisPeriodReport from "./components/WalletAnalysisPeriodReport";

export default function WalletAnalysisReport() {
	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);
	const formInfo = window.data.formInfo ? cloneDeep(window.data.formInfo) : [];

	const getReport = () => {
		if (formValues && formValues.reportType) {
			switch (formValues.reportType) {
				case "objective":
					return (
						<WalletAnalysisObjectiveReport formInfo={formInfo} formValues={formValues} setFormValues={setFormValues} />
					);
				case "period":
					return (
						<WalletAnalysisPeriodReport formInfo={formInfo} formValues={formValues} setFormValues={setFormValues} />
					);
				default:
					break;
			}
		}
		return null;
	};

	const onReportTypeChange = type => {
		const newFormValues = { ...formValues };
		if (type) {
			newFormValues.reportType = type.value;
		} else {
			newFormValues.reportType = null;
		}
		newFormValues.periodType = "year";
		newFormValues.year = new Date().getFullYear();
		setFormValues(newFormValues);
	};

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans("report.report")} - {uctrans("report.report_types.wallet_analysis")}
				</h1>
			</div>
			<div className="w-full flex mb-4">
				<div className="w-1/2 flex border-gray mr-3">
					<div className="w-1/2">
						<div className="w-full pr-2">
							<label htmlFor="reportType">{uctrans("report.type_report")}</label>
							<Select
								name="reportType"
								value={formInfo.reportTypes.filter(({ value }) => `${value}` === `${formValues.reportType}`)}
								options={formInfo.reportTypes}
								onChange={value => onReportTypeChange(value)}
							/>
						</div>
					</div>
				</div>
			</div>
			{getReport()}
		</>
	);
}
