import PropTypes from "prop-types";
import { useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";

export default function SignalBlock(props) {
	const [isCollapsed, setCollapsed] = useState(window.data.collapsed_dashboard_blocks.signal_block === "1");

	return (
		<div className="vertical-spacer spacer flex">
			<div className="bg-grey-lightest shadow rounded flex flex-col w-full">
				<div className="p-6 flex">
					<a
						className="no-underline text-grey-darker w-full cursor"
						onClick={() => {
							const collapsed = !isCollapsed;
							if (props.rememberCollapsed) {
								props.rememberCollapsed("signal_block", collapsed);
							}
							setCollapsed(collapsed);
						}}>
						<div className="w-full flex justify-between">
							<div>
								<h5 className="mb-0">
									{uctrans("notifications.signals.plural")} ({props.signals.length})
								</h5>
							</div>
							<div>
								<EvaIcon fill="#000000" width="18" height="18" type={isCollapsed ? "plus" : "minus"} />
							</div>
						</div>
					</a>
				</div>
				{!isCollapsed && (
					<div className="p-6 w-full">
						<div>
							<ul>
								<li className="flex font-bold mb-3">
									<div className="w-1/2">{uctrans("companies.singular")}</div>
									<div className="w-1/2">{uctrans("notifications.signals.singular")}</div>
								</li>
								{props.signals.map((signal, key) => (
									<li className="flex mb-3" key={key}>
										<div className="w-1/2">
											{signal.route ? (
												<a className="link" href={signal.route} rel="noreferrer noopener">
													{signal.company_label}
												</a>
											) : (
												<span>{signal.company_label}</span>
											)}
										</div>
										<div className="w-1/2">{signal.message}</div>
									</li>
								))}
							</ul>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

SignalBlock.propTypes = {
	rememberCollapsed: PropTypes.func,
	signals: PropTypes.array,
};

SignalBlock.defaultProps = {
	signals: [],
};
