import PropTypes from "prop-types";
import { useState } from "react";
import { format } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";
import Dialog from "../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import find from "lodash/find";

export default function SideBarChangelog(props) {
	const [activeChangelogItem, setActiveChangelogItem] = useState(null);
	const [dialogOpen, setDialogOpen] = useState(false);

	const handleClick = changelogItem => {
		setActiveChangelogItem(changelogItem);
		setDialogOpen(true);
	};

	const handleClose = () => {
		setActiveChangelogItem(null);
		setDialogOpen(false);
	};

	const getCommentForActiveChangelogItem = () => {
		if (activeChangelogItem && activeChangelogItem.comment) {
			return activeChangelogItem.comment;
		}
		return "";
	};

	const getNewValueForChangelogItem = changelogItem => {
		if (!changelogItem) {
			return "";
		}

		let value = "";
		switch (changelogItem.field_type) {
			case props.changelogChangeTypes.STRING:
				value = changelogItem.new_value_string;
				break;
			case props.changelogChangeTypes.TEXT:
				value = changelogItem.new_value_text;
				break;
			case props.changelogChangeTypes.ENCRYPTED:
				value = changelogItem.new_value_encrypted;
				break;
			case props.changelogChangeTypes.DATETIME:
				value = changelogItem.new_value_datetime;
				break;
			case props.changelogChangeTypes.NUMERIC:
				if (props.changelogRelations) {
					let relation = null;
					if (changelogItem.new_value_numeric !== null) {
						relation = find(
							props.changelogRelations,
							item => Number(item.value) === Number(changelogItem.new_value_numeric),
						);
					}
					if (relation) {
						value = relation.label;
					} else {
						value = "-";
					}
				} else {
					value = changelogItem.new_value_numeric;
				}
				break;
			case props.changelogChangeTypes.DECIMAL:
				value = changelogItem.new_value_decimal;
				break;
		}

		return value === null ? "" : value;
	};

	const getOldValueForChangelogItem = changelogItem => {
		if (!changelogItem) {
			return "";
		}

		let value = "";
		switch (changelogItem.field_type) {
			case props.changelogChangeTypes.STRING:
				value = changelogItem.old_value_string;
				break;
			case props.changelogChangeTypes.TEXT:
				value = changelogItem.old_value_text;
				break;
			case props.changelogChangeTypes.ENCRYPTED:
				value = changelogItem.old_value_encrypted;
				break;
			case props.changelogChangeTypes.DATETIME:
				value = changelogItem.old_value_datetime;
				break;
			case props.changelogChangeTypes.NUMERIC:
				if (props.changelogRelations) {
					let relation = null;
					if (changelogItem.old_value_numeric !== null) {
						relation = find(
							props.changelogRelations,
							item => Number(item.value) === Number(changelogItem.old_value_numeric),
						);
					}
					if (relation) {
						value = relation.label;
					} else {
						value = "-";
					}
				} else {
					value = changelogItem.old_value_numeric;
				}
				break;
			case props.changelogChangeTypes.DECIMAL:
				value = changelogItem.old_value_decimal;
				break;
		}

		return value === null ? "" : value;
	};

	const getChangedOnValueForChangeLogItem = changelogItem => {
		if (!changelogItem) {
			return "";
		}

		return changelogItem.updated_at ? format(changelogItem.updated_at, "dd-MM-y, HH:mm:ss") : "-";
	};

	return (
		<div>
			<Dialog
				isOpen={dialogOpen}
				title={uctrans("general.changelog_changes_dialog_title")}
				onClose={() => handleClose()}>
				<div>
					{uctrans("general.:changed_from_to", {
						from: getOldValueForChangelogItem(activeChangelogItem),
						to: getNewValueForChangelogItem(activeChangelogItem),
					})}
				</div>
				<div>
					{uctrans("general.:changed_on", {
						date: getChangedOnValueForChangeLogItem(activeChangelogItem),
					})}
				</div>
				{getCommentForActiveChangelogItem() && (
					<textarea
						disabled
						value={getCommentForActiveChangelogItem()}
						placeholder={uctrans("general.comment")}
						autoComplete="off"
					/>
				)}
				<button className="button button-secondary" onClick={() => handleClose()}>
					{uctrans("general.cancel")}
				</button>
			</Dialog>
			{props.changelogData.map((changelogItem, key) => (
				<div key={key} className="flex w-full justify-start text-xs">
					<span className="font-bold mr-5 w-1/3">
						{changelogItem.updated_at ? format(changelogItem.updated_at, "dd-MM-y") : "-"}
					</span>
					<div className="flex w-full justify-between">
						{getNewValueForChangelogItem(changelogItem)}

						<a onClick={() => handleClick(changelogItem)} className="row-title link">
							<EvaIcon className="float-right" type="eye-outline" fill="#009286" height="18" width="18" />
						</a>
					</div>
				</div>
			))}
		</div>
	);
}

SideBarChangelog.propTypes = {
	changelogData: PropTypes.array,
	changelogChangeTypes: PropTypes.object,
	changelogRelations: PropTypes.oneOfType([PropTypes.array]),
};
