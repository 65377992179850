import { useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import Nav from "../../../../../js/lib/Nav";
import { route } from "../../../../../js/helpers";
import Navigation from "./Navigation";

export default function TargetYearForm() {
	const [formData, setFormData] = useState({ ...window.data.form_data });
	const [staticData, setStaticData] = useState({ ...window.data.static_data });
	const readOnly = window.data.read_only;

	const inputHandler = useInputHandler(setFormData);

	const onSuccess = response => {
		if (staticData.creating) {
			Nav.go(route("crm.targets.target-years.edit", { target_year: response.data.id }));
		} else {
			setFormData(response.data.form_data);
			setStaticData(response.data.static_data);
		}
	};

	return (
		<>
			<Navigation selectedTab="targetYear" targetYear={staticData.creating ? null : staticData} />

			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(staticData.creating ? "general.add_:item" : "general.edit_:item", {
						item: uctrans("targets.target_year.singular"),
					})}
				</h1>

				<a className="link" href={route("crm.targets.target-years.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "targets.target_year.plural" })}
				</a>
			</div>
			<AjaxForm
				method={staticData.creating ? "POST" : "PUT"}
				submitUrl={
					staticData.creating
						? route("crm.targets.target-years.store")
						: route("crm.targets.target-years.update", staticData.id)
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "targets.target_year.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "targets.target_year.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage={!!staticData.creating}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-1-4">
								<LabeledTextInput
									name="year"
									type="number"
									value={formData.year ? `${formData.year}` : ""}
									onChange={inputHandler}
									label="targets.target_year.fields.year"
									disabled={readOnly}
								/>
							</div>
						</div>

						<div className="form-container">
							<div className="form-1-4">
								<LabeledTextInput
									name="ratio_210_212"
									type="number"
									value={formData.ratio_210_212 ? `${formData.ratio_210_212}` : ""}
									onChange={inputHandler}
									label="targets.target_year.fields.ratio_210_212"
									disabled={readOnly}
								/>
							</div>
							<div className="form-1-4">
								<LabeledTextInput
									name="ratio_212_215"
									type="number"
									value={formData.ratio_212_215 ? `${formData.ratio_212_215}` : ""}
									onChange={inputHandler}
									label="targets.target_year.fields.ratio_212_215"
									disabled={readOnly}
								/>
							</div>
							<div className="form-1-4">
								<LabeledTextInput
									name="ratio_215_220"
									type="number"
									value={formData.ratio_215_220 ? `${formData.ratio_215_220}` : ""}
									onChange={inputHandler}
									label="targets.target_year.fields.ratio_215_220"
									disabled={readOnly}
								/>
							</div>
							<div className="form-1-4">
								<LabeledTextInput
									name="ratio_220_320"
									type="number"
									value={formData.ratio_220_320 ? `${formData.ratio_220_320}` : ""}
									onChange={inputHandler}
									label="targets.target_year.fields.ratio_220_320"
									disabled={readOnly}
								/>
							</div>
						</div>
						<div className="form-container">
							<div className="form-1-4">
								<LabeledTextInput
									name="enquete_average"
									type="number"
									value={formData.enquete_average ? `${formData.enquete_average}` : ""}
									onChange={inputHandler}
									label="targets.target_year.fields.enquete_average"
									disabled={readOnly}
								/>
							</div>
						</div>
						{!readOnly && (
							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="targets.target_year.singular" addAnotherAvailable={false} />
								</div>
							</div>
						)}
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
