import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import { useState, useRef, useEffect } from "react";
import { route, yesNoOptions } from "../../../../../../js/helpers";
import { format, parse } from "../../../../../../js/lib/Date";
import Nav from "../../../../../../js/lib/Nav";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import { trans, uctrans } from "../../../../../../js/lib/Translator";
import DatePicker from "../../../../../../js/react/components/general/DatePicker";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import Select from "../../../../../../js/react/components/general/Select";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import Translate from "../../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";
import useSelectHandler from "../../../../../../js/react/hooks/useSelectHandler";
import ServiceLevelQuantitatively from "./components/ServiceLevelQuantitatively";

export default function AccountSupportReport() {
	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);
	const [loading, setLoading] = useState(false);
	const [formInfo] = useState(window.data.formInfo ? cloneDeep(window.data.formInfo) : []);
	const [staticData] = useState(window.data.staticData ? cloneDeep(window.data.staticData) : []);
	const [overviewUpdatedAt, setOverviewUpdatedAt] = useState(new Date().getTime());
	const formRef = useRef(null);
	const [result, setResult] = useState(null);
	const [YesNoOptions] = useState(yesNoOptions(false));

	const selectHandler = useSelectHandler(setFormValues);
	const genericHandler = useGenericHandler(setFormValues);

	const onSuccess = response => {
		if (response.data) {
			setLoading(false);
			setResult(response.data);
		}
	};

	const onError = error => {
		console.error(error);
		setLoading(false);
	};

	const generateCsv = async () => {
		const result = await axios.post(route("crm.report.account-support.generate-csv"), {
			...formRef.current.props.data,
			csv: 1,
		});

		if (result && result.data.success) {
			Nav.go(
				route("crm.report.account-support.download-csv", {
					download_key: result.data.download_key,
				}),
			);
		}
	};

	async function fetchDefaultResult() {
		setLoading(true);
		const response = await axios.post(route(`crm.report.account-support.data`, { ...formValues }));
		if (response) {
			setLoading(false);
			setResult(response.data);
		}
	}

	useEffect(() => {
		fetchDefaultResult();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setResult(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formValues.report_type]);

	return (
		<AjaxForm
			method="POST"
			submitUrl={route("crm.report.account-support.data")}
			onSubmit={() => setLoading(true)}
			onSuccess={onSuccess}
			onError={onError}
			data={{ ...formValues }}
			ref={formRef}
			useFlashMessage>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans("report.report")} - {uctrans("report.report_types.account_support")}
				</h1>
			</div>

			<div className="w-full flex">
				<div className="w-1/4 pr-2">
					<label htmlFor="report_type">
						<Translate content="report.type_report" />
					</label>
					<Select
						isClearable={false}
						placeholder={uctrans("general.select_:item", {}, { item: "report.report_type" })}
						value={formInfo.reportTypes.filter(({ value }) => `${value}` === `${formValues.report_type}`)}
						options={formInfo.reportTypes}
						name="report_type"
						onChange={(selectedValue, metaData) => {
							selectHandler(selectedValue, metaData);
						}}
					/>
				</div>
			</div>
			{formValues.report_type === "servicelevel_qualitatively" && (
				<div className="w-full flex">
					<div className="w-1/4 pr-2">
						<label htmlFor="agenda_item_type">
							<Translate content="report.account_support.activity" />
						</label>
						<Select
							placeholder={uctrans("general.select_:item", {}, { item: "report.account_support.activity" })}
							value={staticData.agenda_item_types.filter(
								({ value }) => `${value}` === `${formValues.agenda_activity_type}`,
							)}
							options={formInfo.activityOptions}
							name="agenda_activity_type"
							onChange={selectHandler}
						/>
					</div>
					<div className="w-1/4 pr-2">
						<label htmlFor="finished">
							<Translate content="report.account_support.finished" />
						</label>
						<Select
							placeholder={uctrans("general.select_:item", {}, { item: "report.type" })}
							value={YesNoOptions.filter(({ value }) => `${value}` === `${formValues.finished}`)}
							options={YesNoOptions}
							name="finished"
							onChange={selectHandler}
						/>
					</div>
				</div>
			)}
			<div className="w-full flex">
				<div className="w-1/4 pr-2">
					<label htmlFor="from_date">
						<Translate content="report.account_support.from" />
					</label>
					<DatePicker
						showClearButton
						initialValue={formValues.from_date ? parse(formValues.from_date) : null}
						onChange={date => genericHandler(date ? format(date) : null, "from_date")}
					/>
				</div>
				<div className="w-1/4 pr-2">
					<label htmlFor="to_date">
						<Translate content="report.account_support.until" />
					</label>
					<DatePicker
						showClearButton
						initialValue={formValues.to_date ? parse(formValues.to_date) : null}
						onChange={date => genericHandler(date ? format(date) : null, "to_date")}
					/>
				</div>
				<div className="w-1/4 pr-2">
					<label htmlFor="to_date">
						<Translate content="report.account_support.account_supporter" />
					</label>
					<Select
						placeholder={uctrans("general.select_:item", {}, { item: "report.account_support.account_supporter" })}
						value={formInfo.accountSupporterOptions.filter(
							({ value }) => `${value}` === `${formValues.account_supporter}`,
						)}
						options={formInfo.accountSupporterOptions}
						name="account_supporter"
						onChange={selectHandler}
					/>
				</div>
			</div>
			{formValues.report_type === "servicelevel_qualitatively" && (
				<div className="w-full flex">
					<div className="w-1/4 pr-2">
						<label htmlFor="relation_manager">
							<Translate content="intermediaries.fields.relationmanager" />
						</label>
						<Select
							placeholder={uctrans("general.select_:item", {}, { item: "intermediaries.fields.relationmanager" })}
							value={formInfo.relationmanagerOptions.filter(
								({ value }) => `${value}` === `${formValues.relation_manager}`,
							)}
							options={formInfo.relationmanagerOptions}
							name="relation_manager"
							onChange={selectHandler}
						/>
					</div>
					<div className="w-1/4 pr-2">
						<label htmlFor="organisation">
							<Translate content="organisations.singular" />
						</label>
						<Select
							placeholder={uctrans("general.select_:item", {}, { item: "organisations.singular" })}
							value={formInfo.organisationOptions.filter(({ value }) => `${value}` === `${formValues.organisation}`)}
							options={formInfo.organisationOptions}
							name="organisation"
							onChange={selectHandler}
						/>
					</div>
				</div>
			)}
			<div className="flex w-full justify-start">
				<button
					type="submit"
					onClick={e => {
						if (formValues.report_type !== "servicelevel_quantitative") {
							setOverviewUpdatedAt(new Date().getTime());
							e.preventDefault();
						}
					}}
					className="button button-primary leading-normal">
					{uctrans("report.show_report")}
				</button>
				<IconButton
					className="ml-5"
					icon="file-text-outline"
					onClick={() => generateCsv()}
					content={uctrans("report.generate_csv")}
					primary
				/>
			</div>

			{formValues.report_type !== "servicelevel_quantitative" ? (
				<AbnOverview
					indexUrl={route("crm.report.account-support.data-overview")}
					dataUpdatedAt={overviewUpdatedAt}
					extraQuerystringParameters={formValues}
					modelTranslations={{
						plural: trans("agendaitems.plural"),
						singular: trans("agendaitems.singular"),
					}}
					renderBody={() => (
						<DataTable>
							<DataColumn
								width={3}
								renderHeaderContent={() => (
									<span className="text-sm">{uctrans("report.account_support.agenda_type")}</span>
								)}
								sortable="type"
								renderCellContent={agendaItem => (
									<span className="text-sm">
										<a href={route("crm.agenda-items.open", agendaItem.id)} className="row-title link">
											{agendaItem.type ? agendaItem.type : "-"}
										</a>
									</span>
								)}
							/>
							<DataColumn
								width={3}
								renderHeaderContent={() => (
									<span className="text-sm">{uctrans("report.account_support.employee_short")}</span>
								)}
								renderCellContent={agendaItem =>
									agendaItem.short_name ? <span className="text-sm">{agendaItem.short_name}</span> : "-"
								}
								sortable="short_name"
							/>
							<DataColumn
								width={3}
								borderLeft
								renderHeaderContent={() => <span className="text-sm">{uctrans("general.name")}</span>}
								renderCellContent={agendaItem =>
									agendaItem.company_label ? <span className="text-sm">{agendaItem.company_label}</span> : "-"
								}
								sortable="company_label"
							/>
							<DataColumn
								width={3}
								renderHeaderContent={() => (
									<span className="text-sm">{uctrans("report.account_support.company_number")}</span>
								)}
								renderCellContent={agendaItem =>
									agendaItem.company_number ? <span className="text-sm">{agendaItem.company_number}</span> : "-"
								}
								sortable="company_number"
							/>
							<DataColumn
								width={3}
								borderLeft
								renderHeaderContent={() => (
									<span className="text-sm">{uctrans("report.account_support.start_date")}</span>
								)}
								renderCellContent={agendaItem =>
									agendaItem.creation_date ? <span className="text-sm">{agendaItem.creation_date}</span> : "-"
								}
								sortable="creation_date"
							/>
							<DataColumn
								width={3}
								renderHeaderContent={() => (
									<span className="text-xs">{uctrans("report.account_support.initial_agendadate")}</span>
								)}
								renderCellContent={agendaItem =>
									agendaItem.creation_date ? <span className="text-sm">{agendaItem.initial_agenda_date}</span> : "-"
								}
								sortable="initial_agenda_date"
							/>
							<DataColumn
								width={3}
								renderHeaderContent={() => (
									<span className="text-sm">{uctrans("report.account_support.initial_processing_date")}</span>
								)}
								renderCellContent={agendaItem =>
									agendaItem.initial_processing_date ? (
										<span className="text-sm">{agendaItem.initial_processing_date}</span>
									) : (
										"-"
									)
								}
								sortable="initial_processing_date"
							/>
							<DataColumn
								width={3}
								renderHeaderContent={() => (
									<span className="text-xs">{uctrans("report.account_support.servicelevel_processing_date")}</span>
								)}
								renderCellContent={agendaItem =>
									agendaItem.servicelevel_processing_date ? (
										<span className="text-sm">{agendaItem.servicelevel_processing_date}</span>
									) : (
										"-"
									)
								}
								sortable="servicelevel_processing_date"
							/>
							<DataColumn
								width={3}
								renderHeaderContent={() => (
									<span className="text-xs">{uctrans("report.account_support.last_agendadate")}</span>
								)}
								renderCellContent={agendaItem =>
									agendaItem.agenda_date ? <span className="text-sm">{agendaItem.agenda_date}</span> : "-"
								}
								sortable="agenda_date"
							/>
							<DataColumn
								width={3}
								renderHeaderContent={() => (
									<span className="text-sm">{uctrans("report.account_support.servicelevel_date")}</span>
								)}
								renderCellContent={agendaItem =>
									agendaItem.servicelevel_date ? <span className="text-sm">{agendaItem.servicelevel_date}</span> : "-"
								}
								sortable="servicelevel_date"
							/>
							<DataColumn
								width={2}
								borderLeft
								renderHeaderContent={() => uctrans("report.account_support.finished")}
								renderCellContent={agendaItem => (
									<span className="text-sm">
										{agendaItem.finished_label ? (
											<span className={agendaItem.finished_after_sla_date ? "font-bold" : undefined}>
												{agendaItem.finished_label}
											</span>
										) : (
											"-"
										)}
									</span>
								)}
								sortable="status"
							/>
						</DataTable>
					)}
				/>
			) : (
				<div className="mt-5">
					{!!loading && (
						<div className="flex justify-center">
							<Spinner width={50} />
						</div>
					)}
					{result && !loading ? <ServiceLevelQuantitatively data={result} /> : null}
				</div>
			)}
		</AjaxForm>
	);
}
