import find from "lodash/find";
import upperFirst from "lodash/upperFirst";
import PropTypes from "prop-types";
import { useContext } from "react";
import { route } from "../../../../../../../js/helpers";
import { uctrans } from "../../../../../../../js/lib/Translator";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import Animation from "../../../media/Animation";
import { CmsTypesContext } from "../../cms-types-context";
import Tippy from "@tippyjs/react";

export default function PartListLabel(props) {
	const values = props.values ? props.values : {};
	const {
		partTypes,
		filterProfiles,
		pageOptions,
		CTLandingOptions,
		imageOptions,
		videoOptions,
		animationOptions,
		documentOptions,
		newsitemOptions,
		questionnaireOptions,
	} = useContext(CmsTypesContext);
	const partType = find(partTypes, partType => partType.key === props.parttype);
	const findFilterProfile = filterProfileId =>
		find(filterProfiles, filterProfile => `${filterProfile.value}` === `${filterProfileId}`);

	let label = `${upperFirst(partType.label)}${props.number ? ` ${props.number}` : ""}`;
	let href = null;
	let subtype = null;
	let sublabel = null;
	switch (partType.key) {
		case "link":
		case "linktarget":
			label = uctrans("general.no_:item", {}, { item: "cms.attributes.link" });
			if ((values.target === "internal" || values.target === null) && values.page) {
				const selectedPage = pageOptions.find(({ value }) => value === values.page);
				if (selectedPage) {
					href = selectedPage.url;
					label = `${selectedPage.label}`;
				}
			} else if (values.target === "news" && values.newsitem) {
				const selectedNewsitem = newsitemOptions.find(({ value }) => value === values.newsitem);
				if (selectedNewsitem) {
					href = selectedNewsitem.url;
					label = `${selectedNewsitem.label}`;
				}
			} else if (values.target === "document" && values.document) {
				const selectedDocument = documentOptions.find(({ id }) => id === values.document);
				if (selectedDocument) {
					href = `/download-document/${selectedDocument.id}`;
					label = `${selectedDocument.name}`;
				}
			} else if (values.target === "external" && values.url) {
				href = values.url;
				label = values.url;
			} else if (values.target === "mailto" && values.url) {
				href = `mailto:${values.url}`;
				label = values.url;
			} else if (values.target === "clienttreatment_landing" && values.ct_landing) {
				const selectedCTLanding = CTLandingOptions.find(({ value }) => value === values.ct_landing);
				if (selectedCTLanding) {
					href = `/client-treatment-landing/${selectedCTLanding.value}`;
					label = `${selectedCTLanding.label}`;
				}
			} else if (values.target === "questionnaires" && values.questionnaires) {
				const selectedQuestionnaire = questionnaireOptions.find(({ value }) => value === values.questionnaires);
				if (selectedQuestionnaire) {
					href = `/vragenlijst/${selectedQuestionnaire.value}`;
					label = `${selectedQuestionnaire.label}`;
				}
			} else if (values.target === "team_ms_bookings") {
				href = route("website.team-microsoft-bookings");
				label = uctrans("cms.options.team_ms_bookings");
			} else if (values.target === "rm_ms_bookings") {
				href = route("website.microsoft-bookings-relationmanager-appointment");
				label = uctrans("cms.options.rm_ms_bookings");
			}
			label = partType.key === "link" && values.text ? values.text : label;
			break;
		case "image":
			if (values.image_is_rm_photo) {
				label = uctrans("cms.attributes.rm_photo");
			} else {
				if (values.image) {
					const selectedImage = imageOptions.find(({ id }) => id === values.image);
					if (selectedImage) {
						return (
							<div className="h-32 w-full">
								<img
									className="max-h-full self-center"
									src={selectedImage.thumbnail_keep_url}
									alt={values.alt ? values.alt : selectedImage.name}
								/>
							</div>
						);
					}
				}
				label = uctrans("general.no_:item", {}, { item: "cms.attributes.image" });
			}
			break;
		case "video":
			if (values.video) {
				const selectedVideo = videoOptions.find(({ id }) => id === values.video);
				if (selectedVideo) {
					return (
						<div className="h-32 w-full">
							<img
								className="max-h-full self-center"
								src={selectedVideo.thumbnail_url}
								alt={values.videoname ? values.videoname : selectedVideo.name}
							/>
						</div>
					);
				}
			}
			label = uctrans("general.no_:item", {}, { item: "cms.attributes.video" });
			break;
		case "animation":
			if (values.animation) {
				const selectedAnimation = animationOptions.find(({ id }) => id === values.animation);
				if (selectedAnimation) {
					return (
						<div className="h-32">
							<Animation animation={selectedAnimation} />
						</div>
					);
				}
			}
			break;
		case "submenuitem":
			label = values.title;
			subtype = partType.attributes.type.options.find(({ value }) => value === values.type);
			if (!subtype) {
				subtype = partType.attributes.type.options.find(() => true);
			}
			if (subtype) {
				sublabel = subtype.label;
			}
			break;
		case "collapsible_item":
		case "icon_link":
		case "popup":
			label = values.title;
			break;
		case "process_item":
			label = `${values.number} - ${values.title}`;
			break;
		case "button":
		case "header":
		case "text_and_image_block":
		case "dashboard_block":
		case "content_block":
		case "icon_header":
			if (partType.key === "header" || partType.key === "button") {
				if (values.text) {
					label = values.text;
				}
			} else if (partType.key === "dashboard_block") {
				if (values.title) {
					label = values.title;
				}
			}
			if (values?.header && values?.header.text) {
				label = values.header.text;
			}
			subtype = partType.attributes.type?.options.find(({ value }) => value === values.type);
			if (!subtype) {
				subtype = partType.attributes.type?.options.find(() => true);
			}
			if (subtype) {
				sublabel = subtype.label;
			}

			return (
				<div className="flex w-full">
					<span className="w-1/2">
						{label}
						{sublabel && <em> ({sublabel})</em>}
					</span>
					<span className="flex w-1/2">
						<span className="w-5/12 mr-3">
							{values.promo_group && !!values.promo_group.part_of_promo_group && (
								<>
									{uctrans("cms.attributes.promo_group")}: {values.promo_group.promo_group_reference}
								</>
							)}
						</span>
						<span className="w-5/12 mr-3">
							{values.filter_profile && (
								<>
									{uctrans("cms.attributes.filter_profile")}: {findFilterProfile(values.filter_profile).label}
								</>
							)}
						</span>
						{values.click_remember && !!values.click_remember.hide_after_click && (
							<Tippy
								placement="bottom"
								arrow
								animation="perspective"
								duration="400"
								content={uctrans("cms.attributes.hide_after_click")}>
								<span>
									<EvaIcon className="flex item-center" type="eye-outline" height="20" width="20" />
								</span>
							</Tippy>
						)}
					</span>
				</div>
			);
		case "column":
			label = uctrans("general.no_:item", {}, { item: "cms.attributes.title" });
			if (Array.isArray(values.parts)) {
				const titlePart = values.parts.find(({ attributetype }) => attributetype === "block_title");
				if (titlePart) {
					label = titlePart.value;
				}
			}
			break;
		case "center_column_block":
			if (Array.isArray(values.parts)) {
				const firstHeaderPart = values.parts.find(({ parttype }) => parttype === "header");
				if (firstHeaderPart) {
					const firstHeaderPartContents = firstHeaderPart.contents ? firstHeaderPart.contents : {};
					if (firstHeaderPartContents.text) {
						sublabel = firstHeaderPartContents.text;
					}
				}
			}
			break;
		case "vimeo":
			if (values.vimeo_embed_url) {
				label = values.vimeo_embed_url;
				href = values.vimeo_embed_url;
			}
	}
	return (
		<span>
			{href ? (
				<a className="link" href={href} target="_blank" rel="noopener noreferrer">
					{label}
				</a>
			) : (
				label
			)}
			{sublabel && <em> ({sublabel})</em>}
		</span>
	);
}

PartListLabel.propTypes = {
	parttype: PropTypes.string,
	number: PropTypes.number,
	values: PropTypes.object,
};
