import axios from "axios";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import DebouncedInput from "../../../../../js/react/components/general/form/DebouncedInput";
import LabeledAutosizeTextarea from "../../../../../js/react/components/general/form/LabeledAutosizeTextarea";
import LabeledTextarea from "../../../../../js/react/components/general/form/LabeledTextarea";
import Select from "../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Translate from "../../../../../js/react/components/general/Translate";
import FileInput from "../forms/FileInput";
import { route } from "../../../../../js/helpers";
import map from "lodash/map";

export default function ClientTreatmentParticipantForm(props) {
	const [formData, setFormData] = useState(props.formData);
	const [staticData] = useState(props.staticData);
	const [originialTriggersOverride] = useState(formData.triggersoverride);
	const [exceededCosts, setExceededCosts] = useState(originialTriggersOverride);
	const [loading, setLoading] = useState(false);
	const [clientTreatment] = useState(staticData.client_treatment);
	const [budgetYear] = useState(staticData.budget_year);
	const [positiveStatuses] = useState(staticData.positive_participant_statuses);

	const participantStatuses = map(staticData.participant_statuses, status => ({
		value: status.key,
		label: status.label,
	}));

	if (formData.status) {
		let hasCurrentStatus = false;
		participantStatuses.forEach(item => {
			if (item.value === formData.status.key) {
				hasCurrentStatus = true;
			}
		});
		if (!hasCurrentStatus) {
			participantStatuses.push({
				value: formData.status.key,
				label: formData.status.label,
			});
		}
	}
	const onSuccess = () => {
		props.onSuccess();
	};

	const handleCancel = () => {
		props.onCancel();
	};

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	useEffect(() => {
		checkCosts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData.status, formData.nr_of_people]);

	const checkCosts = () => {
		if (!originialTriggersOverride && formData.participant_object.id && formData.participant_object.object_type === 6) {
			setLoading(true);
			const params = {
				contactperson: formData.participant_object.id,
				client_treatment: staticData.client_treatment.id,
			};

			if (staticData.client_treatment.id) {
				params.without_client_treatment_id = staticData.client_treatment.id;
			}

			axios
				.patch(route("crm.client-treatments.participants.costs", params))
				.then(response => {
					if (response && response.data) {
						let totalcosts = Number(clientTreatment.costs_per_unit * formData.nr_of_people);

						if (response.data.costs !== 0) {
							totalcosts = Number(clientTreatment.costs_per_unit * formData.nr_of_people) + Number(response.data.costs);
						}

						if (
							totalcosts > budgetYear.budget_per_contactperson &&
							formData.status &&
							positiveStatuses.includes(formData.status.key)
						) {
							setExceededCosts(true);
							setFormData({
								...formData,
								triggersoverride: true,
							});
						} else {
							setExceededCosts(false);
							setFormData({
								...formData,
								triggersoverride: false,
							});
						}

						setLoading(false);
					}
				})
				.catch(error => {
					console.error(`Errors during fetching costs: ${error}`);
					setLoading(false);
				});
		}
	};

	return (
		<>
			<h2>{formData.participant_object.label}</h2>
			<AjaxForm
				method={formData.id ? "PUT" : "POST"}
				submitUrl={
					formData.id
						? route("crm.client-treatments.participants.update", {
								client_treatment: staticData.client_treatment.id,
								participant: formData.id,
						  })
						: route("crm.client-treatments.participants.store", {
								client_treatment: staticData.client_treatment.id,
						  })
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "client_treatment.participants.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "client_treatment.participants.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}>
				<div className="form-container">
					<div className="form-full">
						<label htmlFor="status">
							<Translate content="client_treatment.participants.fields.nr_of_people" />{" "}
							<span className="text-orange"> *</span>
						</label>
						<DebouncedInput
							type="text"
							name="nr_of_people"
							debounceTime={400}
							step=".01"
							value={formData.nr_of_people}
							onChange={e => handleFormValueChange(e.target.name, e.target.value)}
						/>
					</div>

					<div className="form-full">
						<label htmlFor="status">
							<Translate content="client_treatment.participants.fields.status" />{" "}
							<span className="text-orange"> *</span>
						</label>
						<Select
							placeholder={uctrans("general.select_:item", {}, { item: "client_treatment.participants.fields.status" })}
							value={participantStatuses.filter(
								({ value }) => Number(value) === (formData.status ? Number(formData.status.key) : null),
							)}
							options={participantStatuses}
							name="status"
							onChange={selectedValue => {
								handleFormValueChange(
									"status",
									selectedValue ? { key: selectedValue.value, label: selectedValue.label } : null,
								);
							}}
						/>
					</div>

					<div className="form-full">
						<LabeledTextarea
							name="information"
							value={formData.information}
							onChange={e => handleFormValueChange(e.target.name, e.target.value)}
							label="client_treatment.participants.fields.information"
						/>
					</div>

					<div className="form-full">
						<label htmlFor="status">
							<Translate content="client_treatment.participants.fields.tickets" />
						</label>
						<FileInput
							initialFiles={formData.tickets}
							onChange={files => handleFormValueChange("tickets", files)}
							existingFileDownloadRoute="crm.client-treatments.participants.download-tickets"
							maxFiles={10}
						/>
					</div>

					{!!exceededCosts && (
						<>
							<div className="form-full">
								<br />
								<h3>{uctrans("giveaways.fields.yearly_limit_has_been_exceeded")}</h3>
								<LabeledAutosizeTextarea
									label="giveaways.fields.override_description"
									required
									value={formData.override_description || ""}
									name="override_description"
									onChange={e => handleFormValueChange("override_description", e.target.value)}
								/>
							</div>
							<div className="form-full">
								<label>
									{uctrans("giveaways.fields.attachment_agreement")} <span className="text-orange"> *</span>
								</label>
								<FileInput
									initialFiles={formData.attachment_agreement}
									onChange={files => handleFormValueChange("attachment_agreement", files)}
									existingFileDownloadRoute="crm.client-treatments.participants.download-attachment-agreement"
								/>
							</div>
						</>
					)}

					<div className="form-full">
						<SubmitBar
							submitDisabled={loading}
							item="users.singular"
							addAnotherAvailable={false}
							onCancel={handleCancel}
						/>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
ClientTreatmentParticipantForm.propTypes = {
	formData: PropTypes.object,
	staticData: PropTypes.object,
	onSuccess: PropTypes.func,
	onCancel: PropTypes.func,
};
