import { useState } from "react";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import Nav from "../../../../../../js/lib/Nav";
import { route } from "../../../../../../js/helpers";
import TextEditor from "../../forms/textEditor/TextEditor";
import InterestRateClassOverview from "../interestrateclasses/InterestRateClassOverview";
import FixedInterestPeriodOverview from "../fixedinterestperiods/FixedInterestPeriodOverview";
import TabLinks from "../../general/tabs/TabLinks";
import { getInterestManagementTabs, GENERAL_TAB } from "../InterestManagementTabs";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import Translate from "../../../../../../js/react/components/general/Translate";

export default function InterestInterestTypeForm() {
	const [formData, setFormData] = useState({ ...window.data.interest_type_form_data });

	const onSuccess = () => {
		Nav.go(route("crm.interest.interest-types.index"));
	};

	const onNameChange = name => {
		setFormData({
			...formData,
			name,
		});
	};

	const onDescriptionChange = description => {
		setFormData({
			...formData,
			description,
		});
	};

	const getBreadCrumbs = () => {
		const breadCrumbs = [
			{
				label: uctrans("interest.interest_types.plural"),
				route: route("crm.interest.interest-types.index"),
			},
		];

		if (formData.id !== -1) {
			breadCrumbs.push({
				label: formData.name,
			});
		}

		return breadCrumbs;
	};

	return (
		<>
			<BreadCrumbs breadCrumbs={getBreadCrumbs()} />
			{formData.id !== -1 && (
				<TabLinks tabs={Object.values(getInterestManagementTabs(formData.id))} activeTab={GENERAL_TAB} />
			)}
			{formData.id === -1 ? (
				<h1 className="inline-block">
					{uctrans("general.add_:item", {}, { item: "interest.interest_types.singular" })}
				</h1>
			) : (
				<h1 className="inline-block">
					{uctrans("general.edit_:item", {}, { item: "interest.interest_types.singular" })}
				</h1>
			)}
			<div className="float-right">
				<a className="link" href={route("crm.interest.interest-types.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "interest.interest_types.plural" })}
				</a>
			</div>
			<AjaxForm
				method={formData.id === -1 ? "POST" : "PUT"}
				submitUrl={
					formData.id === -1
						? route("crm.interest.interest-types.store")
						: route("crm.interest.interest-types.update", formData.id)
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "interest.interest_types.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "interest.interest_types.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="name"
										value={formData.name}
										onChange={event => onNameChange(event.target.value)}
										label="interest.fields.name"
										required
									/>
								</div>
							</div>
							<div className="form-full">
								<div className="form-1-3">
									<div className="mb-8">
										<label>
											<Translate content="interest.fields.description" />
										</label>
										<TextEditor value={formData.description} onChange={text => onDescriptionChange(text)} />
									</div>
								</div>
							</div>
							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="interest.interest_types.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
			{formData.id !== -1 && (
				<>
					<InterestRateClassOverview interestType={formData} />
					<br />
					<br />
					<FixedInterestPeriodOverview interestType={formData} />
				</>
			)}
		</>
	);
}
