import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import { useCallback, useEffect, useState } from "react";
import { route } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import PeriodicFilter from "../../general/PeriodicFilter";
import NewsletterStatisticsEventColumnChart from "../newsletters/stats/NewsletterStatisticsEventColumnChart";
import NewsletterStatisticsEventTimelineChart from "../newsletters/stats/NewsletterStatisticsEventTimelineChart";
import Navigation from "./Navigation";

export default function AutomatedMessageStatistics() {
	const automatedMessage = { ...window.data.automated_message };
	const columnChartConfig = { ...window.data.column_chart_config };
	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);
	const formInfo = window.data.formInfo ? cloneDeep(window.data.formInfo) : [];
	const [graphData, setGraphData] = useState(null);

	const retrieveGraphData = useCallback(async () => {
		try {
			const result = await axios.get(
				route(`crm.communication.automated-messages.statistics-data`, {
					automated_message: automatedMessage.id,
				}),
				{
					params: formValues,
				},
			);
			setGraphData(result.data);
		} catch (e) {
			console.error(e);
		}
	}, [automatedMessage.id, formValues]);

	useEffect(() => {
		retrieveGraphData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formValues]);

	return (
		<>
			<Navigation selectedTab="statistics" automatedMessage={automatedMessage} />
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("communication.newsletters.statistics")}</h1>
			</div>
			<PeriodicFilter formValues={formValues} formInfo={formInfo} setFormValuesFunc={setFormValues} />
			{graphData && (
				<>
					<h3>Aantal verzonden en aantal events</h3>
					<NewsletterStatisticsEventColumnChart config={columnChartConfig} event_counts={graphData.event_counts} />
					<h3>Opens per datum</h3>
					<NewsletterStatisticsEventTimelineChart
						open_events_per_date={graphData.open_events_per_date}
						unique_open_events_per_date={graphData.unique_open_events_per_date}
						delivered_events_per_date={graphData.delivered_events_per_date}
						automatedMessage={automatedMessage}
					/>
				</>
			)}
		</>
	);
}
