import PropTypes from "prop-types";
import { useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import SingleSelectAutoComplete from "../../../../../js/react/components/general/autocomplete/SingleSelectAutoComplete";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import { route } from "../../../../../js/helpers";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import Translate from "../../../../../js/react/components/general/Translate";

export default function CompanyTargetForm({ selectedCompany, companyTarget, targetYear, onSuccess, onCancel }) {
	const [formData, setFormData] = useState({ ...companyTarget });

	const inputHandler = useInputHandler(setFormData);

	const handleSuccess = () => {
		let difference = 0;

		if (companyTarget.id !== -1) {
			difference = parseInt(formData.sum) - parseInt(companyTarget.sum);
		} else {
			difference = parseInt(formData.sum);
		}

		onSuccess(difference);
	};

	const handleCancel = () => {
		onCancel();
	};

	const handleCompanyFormChange = (attributeKey, company) => {
		const newFormData = { ...formData };
		if (company !== null) {
			newFormData.company_name = company.label;
			newFormData.company_id = company.value;
		} else {
			newFormData.company_name = null;
			newFormData.company_id = null;
		}
		setFormData(newFormData);
	};

	return (
		<AjaxForm
			method={formData.id === -1 ? "POST" : "PUT"}
			submitUrl={
				formData.id === -1
					? route("crm.targets.company-targets.store", { target_year: targetYear.id })
					: route("crm.targets.company-targets.update", {
							target_year: targetYear.id,
							company_target: formData.id,
					  })
			}
			loaderText={uctrans("general.:item_is_being_saved", {}, { item: "targets.company_target.singular" })}
			successText={uctrans("general.saved_:item", {}, { item: "targets.company_target.singular" })}
			onSuccess={handleSuccess}
			data={{ ...formData }}>
			<div className="md:flex">
				<div className="flex-1 w-2/3 mr-12">
					<div className="form-container">
						{formData.id === -1 && selectedCompany === null && (
							<div className="form-full">
								<div className="form-1-2">
									<label htmlFor="user_id">
										<Translate content="companies.singular" />
										<span className="text-orange"> *</span>
									</label>
									<SingleSelectAutoComplete
										name="company_id"
										dataSource={route("crm.targets.company-targets.search-company-options", {
											target_year: targetYear,
										}).toString()}
										item={formData.company_id}
										initialItem={formData.company_name}
										onChange={company => handleCompanyFormChange("company_id", company)}
										placeholder={uctrans("general.type_to_add_:item", {}, { item: "companies.singular" })}
										async
									/>
								</div>
							</div>
						)}
						{selectedCompany !== null && (
							<div className="form-full">
								<div className="form-1-2">
									<label htmlFor="user_id">
										<Translate content="companies.singular" />
									</label>
									<span>{selectedCompany.name}</span>
								</div>
							</div>
						)}
						<div className="form-container">
							<div className="form-1-3">
								<LabeledTextInput
									type="number"
									name="sum"
									value={formData.sum}
									onChange={inputHandler}
									label="targets.company_target.fields.sum"
									required
								/>
							</div>
							<div className="form-1-3">
								<LabeledTextInput
									type="number"
									name="count"
									value={formData.count}
									onChange={inputHandler}
									label="targets.company_target.fields.count"
									required
								/>
							</div>
							<div className="form-1-3">
								<LabeledTextInput
									type="number"
									name="contactmoments"
									value={formData.contactmoments}
									onChange={inputHandler}
									label="targets.company_target.fields.contactmoments"
									required
								/>
							</div>
						</div>
						<div className="form-full">
							<div className="w-full md:w-1/2 lg:w-2/3 column">
								<SubmitBar item="targets.company_target.singular" addAnotherAvailable={false} onCancel={handleCancel} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</AjaxForm>
	);
}
CompanyTargetForm.propTypes = {
	selectedCompany: PropTypes.object,
	companyTarget: PropTypes.object.isRequired,
	targetYear: PropTypes.object.isRequired,
	onSuccess: PropTypes.func,
	onCancel: PropTypes.func,
};

CompanyTargetForm.defaultProps = {
	selectedCompany: null,
};
