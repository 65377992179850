import { useState } from "react";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route } from "../../../../../../js/helpers";
import Navigation from "../Navigation";

export default function TeamGroupOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<Navigation selectedTab="team-groups" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("teams.team_groups.plural")}</h1>
				{can("teams.team_group", "create") && (
					<div>
						<IconButton
							href={route("crm.team-groups.create")}
							content={uctrans("general.add_:item", {}, { item: "teams.team_groups.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.team-groups.index")}
				deleteRouteName="crm.team-groups.delete"
				moveRouteName="crm.team-groups.move"
				s
				modelTranslations={{
					plural: trans("teams.team_groups.plural"),
					singular: trans("teams.team_groups.singular"),
				}}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={10}
							sortable="name"
							renderHeaderContent={() => uctrans("teams.team_groups.fields.name")}
							renderCellContent={teamGroup => {
								if (can("teams.team_group", "update")) {
									return (
										<a href={route("crm.team-groups.edit", teamGroup.id)} className="row-title link">
											{teamGroup.name}
										</a>
									);
								} else {
									return teamGroup.name;
								}
							}}
						/>
						{can("teams.team_group", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(category, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(category)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
