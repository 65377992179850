import axios from "axios";
import PropTypes from "prop-types";
import { useState } from "react";
import { route } from "../../../../../js/helpers";
import { parse } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";
import SingleSelectAutoComplete from "../../../../../js/react/components/general/autocomplete/SingleSelectAutoComplete";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import Dialog from "../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import { createLoader } from "../../../../../js/react/components/general/notifications";
import Select from "../../../../../js/react/components/general/Select";
import Translate from "../../../../../js/react/components/general/Translate";
import useDateHandler from "../../../../../js/react/hooks/useDateHandler";
import ContactpersonSelector from "../contactmoments/ContactpersonSelector";
import TextEditor from "../forms/textEditor/TextEditor";
import DeleteDialog from "../../../../../js/react/components/general/DeleteDialog";
import remove from "lodash/remove";
import findIndex from "lodash/findIndex";

export default function ExternalMailBlock(props) {
	const [isCollapsed, setCollapsed] = useState(window.data.collapsed_dashboard_blocks.external_mail_block === "1");
	const [loading, setLoading] = useState(false);
	const [activeExternalMail, setActiveExternalMail] = useState(null);
	const [deletingExternalMail, setDeletingExternalMail] = useState(null);
	const [contactmomentFormData, setContactmomentFormData] = useState([]);
	const external_mails = props.externalMailsData.external_mails ? props.externalMailsData.external_mails : [];
	const base_types = props.externalMailsData.base_types ? props.externalMailsData.base_types : [];
	const types = props.externalMailsData.types ? props.externalMailsData.types : [];
	const categories = props.externalMailsData.categories ? props.externalMailsData.categories : [];
	const base_types_select = props.externalMailsData.base_types
		? Object.values(props.externalMailsData.base_types).map(base_type => ({
				value: base_type.key,
				label: base_type.label,
		  }))
		: [];

	const handleDialogCancel = () => {
		setActiveExternalMail(null);
	};

	const datePickerHandler = useDateHandler(setContactmomentFormData);

	const removeExternalMail = () => {
		setLoading(true);
		const loader = createLoader(uctrans("dashboard.external_mail_is_being_remove"));
		axios({
			method: "PUT",
			url: route("crm.dashboard.remove-external-mail"),
			data: deletingExternalMail,
		})
			.then(() => {
				window.onbeforeunload = null;
				location.reload();
				loader.success(uctrans("dashboard.external_mail_removed"));
			})
			.catch(error => {
				console.warn(error);
				loader.failure(uctrans("dashboard.external_mail_removed_error"));
			})
			.then(() => {
				setLoading(false);
			});

		return false;
	};

	const handleDialogSave = redirect => {
		if (loading) {
			return;
		}
		setLoading(true);
		const loader = createLoader(uctrans("dashboard.contactmoment_is_saving"));
		axios({
			method: "POST",
			url: route("crm.dashboard.create-contactmoment"),
			data: contactmomentFormData,
		})
			.then(response => {
				if (response.data) {
					window.onbeforeunload = null;
					if (redirect) {
						window.location.replace(response.data.redirect_url);
					} else {
						setActiveExternalMail(null);
						setContactmomentFormData(null);
						location.reload();
					}
				}
				loader.success(uctrans("dashboard.contactmoment_is_saved"));
			})
			.catch(error => {
				console.warn(error);
				loader.failure(uctrans("dashboard.contactmoment_not_saved"));
			})
			.then(() => {
				setLoading(false);
			});

		return true;
	};
	const activateActiveExternalMail = activeExternalMail => {
		setActiveExternalMail(activeExternalMail);

		const newContactmomentFormData = {};
		newContactmomentFormData.base_type = base_types.EMAIL.key;
		newContactmomentFormData.user_id = props.externalMailsData.user_id;
		newContactmomentFormData.subject = activeExternalMail.subject;
		newContactmomentFormData.mail_receiver = activeExternalMail.receiver;
		newContactmomentFormData.mail_sender = activeExternalMail.sender;
		newContactmomentFormData.mail_datetime = activeExternalMail.date;
		newContactmomentFormData.content = activeExternalMail.content;
		newContactmomentFormData.externalMailId = activeExternalMail.id;

		setContactmomentFormData(newContactmomentFormData);
	};

	const handleFormChange = (key, value) => {
		const newContactmomentFormData = { ...contactmomentFormData };
		newContactmomentFormData[key] = value;
		setContactmomentFormData(newContactmomentFormData);
	};

	const handleCompanyFormChange = company => {
		const newContactmomentFormData = { ...contactmomentFormData };
		if (!newContactmomentFormData.companies) {
			newContactmomentFormData.companies = [];
		}
		const possibleCompanyIndex = findIndex(
			newContactmomentFormData.companies,
			iteratedCompany => iteratedCompany.value === company.value,
		);
		if (possibleCompanyIndex === -1) {
			newContactmomentFormData.companies.push(company);
		}
		setContactmomentFormData(newContactmomentFormData);
	};

	const removeSelectedCompany = company => {
		const newContactmomentFormData = { ...contactmomentFormData };
		if (!newContactmomentFormData.companies) {
			return;
		}
		remove(newContactmomentFormData.companies, iteratedCompany => company.value === iteratedCompany.value);
		setContactmomentFormData(newContactmomentFormData);
	};

	const handleSelectedContactpersonsForCompany = (company, selectedContactpersonIds) => {
		const newContactmomentFormData = { ...contactmomentFormData };
		if (!newContactmomentFormData.companies) {
			return;
		}
		company.selected_contactperson_ids = selectedContactpersonIds;
		const indexCompanyToUpdate = findIndex(
			newContactmomentFormData.companies,
			iteratedCompany => iteratedCompany.value === company.value,
		);
		if (indexCompanyToUpdate !== -1) {
			newContactmomentFormData.companies.splice(indexCompanyToUpdate, 1, company);
		}

		setContactmomentFormData(newContactmomentFormData);
	};

	return Object.entries(props.externalMailsData).length === 0 ? (
		<div />
	) : (
		<div className="vertical-spacer spacer flex">
			<div className="bg-grey-lightest shadow rounded flex flex-col w-full">
				<div className="p-6 flex">
					<a
						className="no-underline text-grey-darker w-full cursor"
						onClick={() => {
							const collapsed = !isCollapsed;
							if (props.rememberCollapsed) {
								props.rememberCollapsed("external_mail_block", collapsed);
							}
							setCollapsed(collapsed);
						}}>
						<div className="w-full flex justify-between">
							<div>
								<h5 className="mb-0">
									{uctrans("dashboard.external_mails")} ({external_mails.length})
								</h5>
							</div>
							<div>
								<EvaIcon fill="#000000" width="18" height="18" type={isCollapsed ? "plus" : "minus"} />
							</div>
						</div>
					</a>
				</div>
				{!isCollapsed && (
					<div className="p-6 w-full">
						<div>
							<ul>
								<li className="flex font-bold mb-3">
									<div className="w-4/12">{uctrans("dashboard.external_mail_sender")}</div>
									<div className="w-3/12">{uctrans("dashboard.external_mail_subject")}</div>
									<div className="w-3/12">{uctrans("dashboard.external_mail_date")}</div>
									<div className="w-1/12" />
									<div className="w-1/12" />
								</li>
								{external_mails.map(externalMailData => (
									<li className="flex mb-3" key={externalMailData.id}>
										<div className="w-4/12">{externalMailData.sender}</div>
										<div className="w-3/12">{externalMailData.subject}</div>
										<div className="w-3/12">{externalMailData.date}</div>
										<div className="w-1/12">
											<a onClick={() => activateActiveExternalMail(externalMailData)} className="row-title link">
												<EvaIcon fill="#249286" width="18" height="18" type="edit" />
											</a>
										</div>
										<div className="w-1/12">
											<a onClick={() => setDeletingExternalMail(externalMailData)} className="row-title link">
												<EvaIcon fill="#249286" width="18" height="18" type="trash-2-outline" />
											</a>
										</div>
									</li>
								))}
							</ul>
						</div>
					</div>
				)}
			</div>

			<DeleteDialog
				onConfirm={() => removeExternalMail()}
				onCancel={() => setDeletingExternalMail(null)}
				isOpen={!!deletingExternalMail}
				deleteData={{ message: uctrans("dashboard.external_mail_remove"), errors: [], warnings: [] }}
			/>

			<Dialog
				isOpen={activeExternalMail !== null}
				onClose={handleDialogCancel}
				shouldCloseOnOverlayClick={false}
				width={1000}
				title={uctrans("dashboard.create_contactmoment")}>
				{activeExternalMail !== null && (
					<>
						<div className="form-1-3">
							<label>
								<Translate content="contactmoments.fields.base_type" />
								<span className="text-orange"> *</span>
							</label>
							<Select
								isClearable={false}
								placeholder={uctrans("general.select_:item", {}, { item: "contactmoments.fields.base_type" })}
								value={base_types_select.filter(
									({ value }) => Number(value) === Number(contactmomentFormData.base_type),
								)}
								options={base_types_select}
								name="base_type"
								onChange={selectedValue => handleFormChange("base_type", selectedValue.value)}
							/>
						</div>
						{contactmomentFormData.base_type === base_types.CONVERSATION.key && (
							<div className="form-1-2">
								<label>
									<Translate content="contactmoments.fields.type" />
									<span className="text-orange"> *</span>
								</label>
								<Select
									isClearable={false}
									placeholder={uctrans("general.select_:item", {}, { item: "contactmoments.fields.type" })}
									value={types.filter(({ value }) => Number(value) === Number(contactmomentFormData.type_id))}
									options={types}
									name="type_id"
									onChange={selectedValue => handleFormChange("type_id", selectedValue.value)}
								/>
							</div>
						)}
						{contactmomentFormData.base_type === base_types.EMAIL.key && (
							<>
								<div className="form-1-2">
									<label>
										<Translate content="contactmoments.fields.category_id" />
										<span className="text-orange"> *</span>
									</label>
									<Select
										isClearable={false}
										placeholder={uctrans("general.select_:item", {}, { item: "contactmoments.fields.category_id" })}
										value={categories.filter(
											({ value }) => Number(value) === Number(contactmomentFormData.category_id),
										)}
										options={categories}
										name="category_id"
										onChange={selectedValue => handleFormChange("category_id", selectedValue.value)}
									/>
								</div>

								<div className="form-full">
									<LabeledTextInput
										label="dashboard.external_mail_subject"
										value={contactmomentFormData.subject}
										name="subject"
										required
										onChange={e => handleFormChange("subject", e.target.value)}
									/>
								</div>

								<div className="form-1-2">
									<LabeledTextInput
										label="dashboard.external_mail_sender"
										value={contactmomentFormData.mail_sender}
										name="sender"
										required
										onChange={e => handleFormChange("mail_sender", e.target.value)}
									/>
								</div>

								<div className="form-1-2">
									<LabeledTextInput
										label="dashboard.external_mail_receiver"
										value={contactmomentFormData.mail_receiver}
										name="receiver"
										required
										onChange={e => handleFormChange("mail_receiver", e.target.value)}
									/>
								</div>

								<div className="form-full">
									<label>
										<Translate content="contactmoments.fields.mail_datetime" />
									</label>
									<DatePicker
										initialValue={
											contactmomentFormData.mail_datetime ? parse(contactmomentFormData.mail_datetime) : null
										}
										onChange={date => datePickerHandler(date, "mail_datetime")}
									/>
								</div>
							</>
						)}
						<div className="form-full">
							<label>
								<Translate content="general.content" />
								<span className="text-orange"> *</span>
							</label>
							<TextEditor
								type="mail"
								value={contactmomentFormData.content}
								onChange={value => handleFormChange("content", value)}
							/>
						</div>

						<div className="form-full">
							<label>
								<Translate content="dashboard.create_contactmoment_at" />
								<span className="text-orange"> *</span>
							</label>
							<SingleSelectAutoComplete
								name="company"
								dataSource={route("crm.companies.searchcontactpersons").toString()}
								item={contactmomentFormData.company}
								isClearable={false}
								onChange={company => handleCompanyFormChange(company)}
								placeholder={uctrans("general.type_to_add_:item", {}, { item: "companies.singular" })}
								clearOnSelect
								async
							/>
						</div>

						{contactmomentFormData.companies &&
							contactmomentFormData.companies.map(company => (
								<div className="form-full mt-2" key={company.value}>
									<label className="text-lg">
										{company.label}{" "}
										<a onClick={() => removeSelectedCompany(company)} className="row-title link">
											<EvaIcon fill="#249286" width="18" height="18" type="trash-2-outline" />
										</a>
									</label>
									<div>
										<ContactpersonSelector
											contactpersons={company.contactpersons ? company.contactpersons : null}
											showLabel={false}
											required={false}
											selectedContactpersonIds={
												company.selected_contactperson_ids ? company.selected_contactperson_ids : []
											}
											onSelectedContactpersonIdsChange={value => handleSelectedContactpersonsForCompany(company, value)}
										/>
									</div>
								</div>
							))}

						<div className="form-full">
							<div className="mt-6">
								<button
									type="button"
									className={`button button-primary button-dialog ${loading ? "disabled" : ""}`}
									onClick={() => {
										handleDialogSave(false);
									}}>
									{uctrans("dashboard.create_contactmoment")}
								</button>
								<button
									type="button"
									className={`button button-primary button-dialog ${loading ? "disabled" : ""}`}
									onClick={() => {
										handleDialogSave(true);
									}}>
									{uctrans("dashboard.create_contactmoment_and_edit")}
								</button>

								<a onClick={handleDialogCancel}>{uctrans("general.cancel")}</a>
							</div>
						</div>
					</>
				)}
			</Dialog>
		</div>
	);
}

ExternalMailBlock.propTypes = {
	rememberCollapsed: PropTypes.func,
	externalMailsData: PropTypes.object,
};

ExternalMailBlock.defaultProps = {
	externalMailsData: [],
};
