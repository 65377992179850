import PropTypes from "prop-types";
import { memo } from "react";

const FormDivider = memo(({ label }) => (
	<div className="form-full mt-6">
		<h3>{label && label}</h3>
		<hr className="w-full" />
	</div>
));

FormDivider.displayName = "FormDivider";

export default FormDivider;

FormDivider.propTypes = {
	label: PropTypes.string,
};
