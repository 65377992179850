import PropTypes from "prop-types";
import { useContext } from "react";
import SelectField from "../../../../../../../js/react/components/general/Select";
import { CmsTypesContext } from "../../cms-types-context";

export default function ProfileFilter(props) {
	const { filterProfiles } = useContext(CmsTypesContext);

	const onchange = selectedDefinitionOption => {
		props.onChange(selectedDefinitionOption ? selectedDefinitionOption.value : null);
	};

	const selectedProfileFilter = filterProfiles.find(({ value }) => `${value}` === `${props.value}`);

	return (
		<>
			{props.label && <label>{props.label}</label>}
			<SelectField value={selectedProfileFilter} options={filterProfiles} onChange={onchange} isClearable />
		</>
	);
}

ProfileFilter.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
};
