import { useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import TabLinks from "../general/tabs/TabLinks";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import IconButton from "../../../../../js/react/components/general/IconButton";
import DeleteColumnContent from "../../../../../js/react/components/general/overview/DeleteColumnContent";

export default function InteresttypeOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
		create_link: window.data.interesttype_create_link,
		index_link: window.data.interesttype_index_link,
		permissions: window.data.interesttype_permissions,
		previous_link: window.data.previous_link,
		category: window.data.category_name,
	});

	return (
		<>
			{window.data.nav_data && (
				<>
					<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
					<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
				</>
			)}
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("interesttypes.plural")}</h1>
				{overviewData.create_link && (
					<div>
						<IconButton
							href={overviewData.create_link}
							content={uctrans("general.add_:item", {}, { item: "interesttypes.singular" })}
							primary
						/>
					</div>
				)}
			</div>
			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={overviewData.index_link}
				useDragAndDrop
				modelTranslations={{
					plural: uctrans("interesttypes.plural"),
					singular: uctrans("interesttypes.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={4}
							sortable="name"
							renderHeaderContent={() => uctrans("interesttypes.fields.name")}
							renderCellContent={interestType => (
								<a href={interestType.edit_link} className="row-title link">
									{interestType.name}
								</a>
							)}
						/>
						{overviewData.permissions.delete && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(interestType, overview) =>
									interestType.delete_link && <DeleteColumnContent onClick={() => overview.askDelete(interestType)} />
								}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
