import PropTypes from "prop-types";
import { route, can } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../../general/tabs/TabLinks";

export default function Navigation(props) {
	const tabs = [];
	if (can("agenda.agenda_item", "view")) {
		tabs.push({
			key: "statuses",
			label: uctrans("takeon_requests.status.plural"),
			route: route("crm.agenda-settings.takeon-statuses.index"),
		});
	}
	if (can("agenda.settings.service_level", "view")) {
		tabs.push({
			key: "servicelevels",
			label: uctrans("servicelevels.plural"),
			route: route("crm.agenda-settings.servicelevels.index"),
		});
	}
	if (can("agenda.settings.takeon_status", "view")) {
		tabs.push({
			key: "cooperation-request",
			label: `${uctrans("cooperation_check.singular")}`,
			route: route("crm.agenda-settings.cooperation-check.index"),
		});
	}
	if (can("agenda.annually_cooperation_completion", "view")) {
		tabs.push({
			key: "annually-cooperation-check",
			label: uctrans("cooperation_check.annually_cooperation_check"),
			route: route("crm.agenda-settings.annually-cooperation-check.index"),
		});
	}
	if (can("agenda.settings.dossier_check_action", "view")) {
		tabs.push({
			key: "dossier_check_actions",
			label: uctrans("dossiers.check_actions.plural"),
			route: route("crm.agenda-settings.dossier-check-actions.index"),
		});
	}
	if (can("agenda.settings.kifid_check_action", "view")) {
		tabs.push({
			key: "kifid_check_actions",
			label: uctrans("kifid_checks.check_actions.plural"),
			route: route("crm.agenda-settings.kifid-check-actions.index"),
		});
	}
	if (can("agenda.settings.afm_check_action", "view")) {
		tabs.push({
			key: "afm_check_actions",
			label: uctrans("afm_permits.check_actions.plural"),
			route: route("crm.agenda-settings.afm-check-actions.index"),
		});
	}
	if (can("agenda.settings.afm_permit", "view")) {
		tabs.push({
			key: "afm_permits",
			label: uctrans("afm_permits.plural"),
			route: route("crm.agenda-settings.afm-permits.index"),
		});
	}
	if (can("agenda.settings.cooperation_termination_reason", "view")) {
		tabs.push({
			key: "cooperation_termination_reasons",
			label: uctrans("cooperation_terminations.reasons.plural"),
			route: route("crm.agenda-settings.cooperation-termination-reasons.index"),
		});
	}
	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("agendaitems.settings") }]} />
			<TabLinks tabs={tabs} activeTab={props.selectedTab} />
		</>
	);
}
Navigation.propTypes = {
	selectedTab: PropTypes.string.isRequired,
};
