import cloneDeep from "lodash/cloneDeep";
import { useState } from "react";
import { format, default_date_format } from "../../../../../../js/lib/Date";
import { trans, uctrans } from "../../../../../../js/lib/Translator";
import { numberFormat, route } from "../../../../../../js/helpers";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DatePickerFilter from "../../../../../../js/react/components/general/overview/DatePickerFilter";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import SelectFilter from "../../../../../../js/react/components/general/overview/SelectFilter";
import SingleSelectAutoCompleteFilter from "../../../../../../js/react/components/general/overview/SingleSelectAutoCompleteFilter";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";

export default function ApplicationsReport() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);
	const genericHandler = useGenericHandler(setFormValues);

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans("report.report")} - {uctrans("report.report_types.applications")}
				</h1>
			</div>

			<div>
				<AbnOverview
					initialData={overviewData.overview}
					indexUrl={route("crm.report.applications.index")}
					modelTranslations={{
						plural: trans("applications.plural"),
						singular: trans("applications.singular"),
					}}
					renderFilters={overview => (
						<Filters>
							<div className="form-1-3">
								<SelectFilter
									name="period_type"
									options={overviewData.overview.metadata.filterdata.period_types}
									isClearable={false}
									label={uctrans("report.period_type")}
									defaultSelectFirstOption={false}
								/>

								{overview.data.metadata.filtering.filters?.period_type !== "day" && (
									<SelectFilter
										name="year"
										options={overviewData.overview.metadata.filterdata.years}
										isClearable={false}
										label={uctrans("report.year")}
									/>
								)}
								{overview.data.metadata.filtering.filters?.period_type === "month" && (
									<SelectFilter
										name="month"
										options={overviewData.overview.metadata.filterdata.months}
										isClearable={false}
										label={uctrans("report.month")}
									/>
								)}
								{overview.data.metadata.filtering.filters?.period_type === "week" && (
									<SelectFilter
										name="week"
										options={overviewData.overview.metadata.filterdata.weeks}
										isClearable={false}
										label={uctrans("report.week")}
									/>
								)}
								{overview.data.metadata.filtering.filters?.period_type === "day" && (
									<DatePickerFilter
										name="day"
										label={uctrans("report.day")}
										onChange={date => genericHandler(date ? format(date, default_date_format) : null, "day")}
									/>
								)}
							</div>

							<SelectFilter
								name="relationmanager"
								className="form-1-3"
								label={uctrans("intermediaries.fields.relationmanager")}
								options={overviewData.overview.metadata.filterdata.relationmanagers}
							/>

							<SingleSelectAutoCompleteFilter
								name="intermediary"
								className="form-1-3"
								label={uctrans("intermediaries.singular")}
								dataSource={route("crm.companies.search-active").toString()}
								item={formValues.intermediary}
								initialItem={formValues.intermediary}
								isClearable
								placeholder={uctrans("general.type_to_select_:item", {
									item: trans("intermediaries.singular"),
								})}
								async
							/>
						</Filters>
					)}
					renderBody={() => (
						<DataTable>
							<DataColumn
								width={3}
								sortable="company_number"
								renderHeaderContent={() => (
									<span className="text-sm">{uctrans("report.applications.company_number")}</span>
								)}
								renderCellContent={row =>
									row.name ? (
										<a href={route("crm.intermediaries.dashboard", { intermediary: row.id })} className="link">
											{row.company_number}
										</a>
									) : (
										"-"
									)
								}
							/>
							<DataColumn
								width={3}
								sortable="name"
								renderHeaderContent={() => (
									<span className="text-sm">{uctrans("report.applications.company_name")}</span>
								)}
								renderCellContent={row => (row.name ? <span className="text-sm">{row.name}</span> : "-")}
							/>
							<DataColumn
								width={3}
								sortable="relationmanager"
								renderHeaderContent={() => (
									<span className="text-sm">{uctrans("report.applications.relation_manager")}</span>
								)}
								renderCellContent={row => (row.name ? <span className="text-sm">{row.relationmanager}</span> : "-")}
							/>
							<DataColumn
								width={3}
								sortable="count"
								renderHeaderContent={() => (
									<span className="text-sm">{uctrans("report.applications.applications")}</span>
								)}
								renderCellContent={row => (row.name ? <span className="text-sm">{row.count}</span> : "-")}
							/>
							<DataColumn
								width={3}
								sortable="sum"
								renderHeaderContent={() => <span className="text-sm">{uctrans("report.applications.volume")}</span>}
								renderCellContent={row =>
									row.name ? (
										<span className="text-sm">
											{numberFormat(row.sum)}
											<a href={route("crm.report.applications.track-trace", row.id)} target="_blank" rel="noreferrer">
												<span className="inline-block ml-1 align-middle">
													<EvaIcon type="arrow-circle-right-outline" fill="#009286" height="18" width="18" />
												</span>
											</a>
										</span>
									) : (
										"-"
									)
								}
							/>
						</DataTable>
					)}
				/>
			</div>
		</>
	);
}
