import Tippy from "@tippyjs/react";
import { useState } from "react";
import { route, yesNoOptions } from "../../../../../js/helpers";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import Translate from "../../../../../js/react/components/general/Translate";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import IconButton from "../../../../../js/react/components/general/IconButton";
import DeleteColumnContent from "../../../../../js/react/components/general/overview/DeleteColumnContent";
import getEngagementScoreColor from "../engagementScore/EngagementScoreColor";
import EngagementScoreDifference from "../engagementScore/EngagementScoreDifference";

export default function OrganisationOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
		organisation_types: window.data.organisation_types,
		create_link: window.data.create_link,
		index_link: window.data.index_link,
		permissions: window.data.permissions,
	});

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("organisations.plural")}</h1>
				{overviewData.create_link && (
					<div>
						<IconButton
							href={overviewData.create_link}
							content={uctrans("general.add_:item", {}, { item: "organisations.singular" })}
							primary
						/>
					</div>
				)}
			</div>
			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={overviewData.index_link}
				modelTranslations={{ plural: uctrans("organisations.plural"), singular: uctrans("organisations.singular") }}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
						<SelectFilter
							className="form-1-3"
							name="relationmanager"
							label={uctrans("intermediaries.fields.relationmanager_id")}
							options={overviewData.overview.filterdata.relation_managers}
							collapsible={!window.data.relation_manager}
						/>
						<SelectFilter
							className="form-1-3"
							name="company_status_type"
							label={uctrans("intermediaries.fields.classification")}
							options={overviewData.overview.filterdata.company_status_types}
							collapsible
						/>
						<SelectFilter
							className="form-1-3"
							name="main_company"
							label={uctrans("intermediaries.fields.main_company")}
							options={yesNoOptions()}
							collapsible
						/>
						<SelectFilter
							className="form-1-3"
							name="kvk_location_number"
							label={uctrans("intermediaries.fields.kvk_location_number")}
							options={yesNoOptions()}
							collapsible
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={7}
							sortable="name"
							renderHeaderContent={() => uctrans("organisations.fields.name")}
							renderCellContent={company => (
								<a href={company.edit_link} className="row-title link">
									{company.name}
								</a>
							)}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("organisations.fields.organisation_number")}
							renderCellContent={company =>
								company.organisation_number && company.organisation_number !== null ? company.organisation_number : "-"
							}
						/>
						<DataColumn
							width={4}
							sortable="name"
							renderHeaderContent={() => uctrans("organisations.fields.organisation_type")}
							renderCellContent={company =>
								company.organisation_type && company.organisation_type !== null
									? overviewData.organisation_types.find(
											organisationType => organisationType.key === company.organisation_type,
									  ).label
									: "-"
							}
						/>
						<DataColumn
							width={2}
							sortable="consent"
							renderHeaderContent={() => uctrans("organisations.fields.consent")}
							renderCellContent={company => (company.consent ? uctrans("general.yes") : uctrans("general.no"))}
						/>
						<DataColumn
							width={3}
							sortable="relationmanager"
							renderHeaderContent={() => uctrans("organisations.fields.relationmanager_id")}
							renderCellContent={company =>
								company.relationmanager && company.relationmanager.label !== null ? company.relationmanager.label : "-"
							}
						/>
						<DataColumn
							width={3}
							sortable="engagement_score"
							renderHeaderContent={() => (
								<Tippy
									placement="right"
									arrow
									animation="perspective"
									duration="400"
									content={<span>{uctrans("engagement_score.dashboard_mouse_over")}</span>}>
									<span>{uctrans("engagement_score.short")}</span>
								</Tippy>
							)}
							renderCellContent={company => (
								<div className="flex items-center justify-end">
									<div>
										<div
											className="rounded-full small-circle-engagement-score flex items-center justify-center"
											style={{
												background: getEngagementScoreColor(
													overviewData.overview.filterdata.total_relative_engagement_score,
													company.engagement_score,
												),
											}}>
											<span className="font-medium">{company.engagement_score}</span>
										</div>
									</div>
								</div>
							)}
						/>
						<DataColumn
							width={1}
							sortable="engagement_score_to_compare"
							renderHeaderContent={() => (
								<Tippy
									placement="right"
									arrow
									animation="perspective"
									duration="400"
									content={uctrans("engagement_score.score_to_compare_mouse_over")}>
									<span>
										<EvaIcon type="trending-up-outline" width={20} height={20} fill="white" />
									</span>
								</Tippy>
							)}
							renderCellContent={company => (
								<EngagementScoreDifference
									score={company.engagement_score}
									scoreToCompare={company.engagement_score_to_compare}
									iconSize={20}
								/>
							)}
						/>
						{overviewData.permissions.delete && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(company, overview) =>
									company.delete_link && <DeleteColumnContent onClick={() => overview.askDelete(company)} />
								}
							/>
						)}
					</DataTable>
				)}
				renderNoResults={() => (
					<Translate content="overview.no_:items_to_show" transReplaces={{ items: "organisations.plural" }} />
				)}
				renderFooter={overview => (
					<>
						<a
							className="button button-primary"
							onClick={() => Nav.go(route(`crm.organisations.download-csv`, overview.getParameters()))}>
							{uctrans("general.export")}
						</a>
						<a
							className="button button-primary ml-5"
							onClick={() => Nav.go(route(`crm.organisations.download-contact-data-csv`, overview.getParameters()))}>
							{uctrans("intermediaries.export_contactdata")}
						</a>
					</>
				)}
			/>
		</>
	);
}
