import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import { useState, useEffect } from "react";
import Nav from "../../../../../../js/lib/Nav";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import { route } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import Select from "../../../../../../js/react/components/general/Select";
import useSelectHandler from "../../../../../../js/react/hooks/useSelectHandler";
import { format } from "../../../../../../js/lib/Date";
import FormattedNumberText from "../FormattedNumberText";

export default function OverruleReport() {
	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);
	const [overviewUpdatedAt, setOverviewUpdatedAt] = useState(new Date().getTime());
	const [titleData, setTitleData] = useState(null);
	const [totalData, setTotalData] = useState(null);

	const formInfo = window.data.formInfo ? cloneDeep(window.data.formInfo) : [];

	const selectHandler = useSelectHandler(setFormValues);

	useEffect(() => {
		setOverviewUpdatedAt(new Date().getTime());
	}, [formValues]);

	const setExternalData = response => {
		if (response) {
			if (response.title) {
				setTitleData(response.title);
			}
			if (response.total) {
				setTotalData(response.total);
			}
		}
	};

	const generateCsv = async () => {
		const result = await axios.post(route("crm.report.overrule.generate-csv"), {
			...formValues,
		});

		if (result && result.data.success) {
			Nav.go(
				route("crm.report.overrule.download-csv", {
					download_key: result.data.download_key,
				}),
			);
		}
	};

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans("report.report")} - {uctrans("report.report_types.overrule")}
				</h1>
			</div>
			<div className="w-full flex">
				<div className="w-1/2 flex border-gray p-3 mb-6 mr-3">
					<div className="w-1/2">
						<div className="w-full pr-2">
							<label htmlFor="year">{uctrans("report.year")}</label>
							<Select
								name="year"
								value={formInfo.yearOptions.filter(({ value }) => `${value}` === `${formValues.year}`)}
								options={formInfo.yearOptions}
								isClearable={false}
								onChange={selectHandler}
							/>
						</div>
					</div>
					<div className="w-1/2">
						<div className="w-full pr-2">
							<label htmlFor="relationmanager">{uctrans("intermediaries.fields.relationmanager")}</label>
							<Select
								name="relationmanager"
								value={formInfo.relationmanagerOptions.filter(
									({ value }) => `${value}` === `${formValues.relationmanager}`,
								)}
								options={formInfo.relationmanagerOptions}
								onChange={selectHandler}
							/>
						</div>
					</div>
				</div>
				<div className="w-1/2 border-gray p-3 my-6">
					{totalData && (
						<table className="rapportage_table totaal_rapportage_table reporttable mt-2">
							<thead>
								<tr>
									<th colSpan="2">{`${uctrans("report.total")} ${formValues.year}`}</th>
								</tr>
								<tr className="head2">
									<td style={{ textAlign: "center" }}>
										<span>{uctrans("report.overrule.expenses")}</span>
									</td>
									<td>
										<span>{uctrans("report.overrule.count")}</span>
									</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="font-bold" style={{ textAlign: "center" }}>
										<FormattedNumberText
											prefix="€ "
											value={totalData.total_expenses ? totalData.total_expenses : 0}
											decimalScale={2}
										/>
									</td>
									<td className="font-bold">
										<FormattedNumberText value={totalData.total_overrides || 0} />
									</td>
								</tr>
							</tbody>
						</table>
					)}
					<div className="flex">
						<div className="w-1/2 mb-3">
							<IconButton
								icon="file-text-outline"
								onClick={() => generateCsv()}
								content={uctrans("report.generate_csv")}
								primary
							/>
						</div>
					</div>
				</div>
			</div>

			<AbnOverview
				indexUrl={route("crm.report.overrule.data")}
				dataUpdatedAt={overviewUpdatedAt}
				reloadedData={setExternalData}
				extraQuerystringParameters={formValues}
				modelTranslations={{
					plural: uctrans("report.results"),
					singular: uctrans("report.results"),
				}}
				renderBody={() => (
					<>
						<div className="w-full mt-2 text-white flex bg-primary p-2">
							<span className="ml-1">{titleData ? titleData : ""}</span>
						</div>
						<DataTable>
							<DataColumn
								width={3}
								sortable="cp_name"
								renderHeaderContent={() => uctrans("contactpersons.singular")}
								renderCellContent={row => (row.cp_name ? <span className="text-sm">{row.cp_name}</span> : "-")}
							/>
							<DataColumn
								width={3}
								sortable="relationmanager_name"
								renderHeaderContent={() => uctrans("intermediaries.fields.relationmanager")}
								renderCellContent={row =>
									row.relationmanager_name ? <span className="text-sm">{row.relationmanager_name}</span> : "-"
								}
							/>
							<DataColumn
								width={2}
								sortable="last_override_date"
								renderHeaderContent={() => uctrans("report.overrule.last_date")}
								renderCellContent={row =>
									row.last_override_date ? (
										<span className="text-sm">{format(row.last_override_date, "dd-MM-y")}</span>
									) : (
										"-"
									)
								}
							/>
							<DataColumn
								width={2}
								sortable="last_override_user"
								renderHeaderContent={() => uctrans("users.singular")}
								renderCellContent={row =>
									row.last_override_user ? <span className="text-sm">{row.last_override_user}</span> : "-"
								}
							/>
							<DataColumn
								width={3}
								renderHeaderContent={() => uctrans("report.overrule.last_description")}
								renderCellContent={row =>
									row.last_override_description ? <span className="text-sm">{row.last_override_description}</span> : "-"
								}
							/>
							<DataColumn
								width={2}
								sortable="overridden_expenses"
								renderHeaderContent={() => uctrans("report.overrule.total_expenses")}
								renderCellContent={row =>
									row.overridden_expenses ? <span className="text-sm">{row.overridden_expenses}</span> : "-"
								}
							/>
							<DataColumn
								width={1}
								sortable="nr_of_overrides"
								renderHeaderContent={() => uctrans("report.overrule.count")}
								renderCellContent={row =>
									row.nr_of_overrides ? <span className="text-sm">{row.nr_of_overrides}</span> : "-"
								}
							/>
						</DataTable>
					</>
				)}
			/>
		</>
	);
}
