import { useState } from "react";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import Select from "../../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import useInputHandler from "../../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import Nav from "../../../../../../js/lib/Nav";
import { route } from "../../../../../../js/helpers";
import useSelectHandler from "../../../../../../js/react/hooks/useSelectHandler";
import Navigation from "../SettingsNavigation";

export default function NewsletterSubscriptionTypeForm() {
	const [formData, setFormData] = useState({ ...window.data.form_values });
	const creating = window.data.form_values.id === -1;
	const inputHandler = useInputHandler(setFormData);
	const selectHandler = useSelectHandler(setFormData);
	const newsletterSubscriptionCategories = window.data.newsletter_subscription_categories;

	const onSuccess = () => {
		Nav.go(route("crm.communication-settings.newsletter-subscription-types.index"));
	};

	return (
		<>
			<Navigation selectedTab="newsletterSubscriptionTypes" />

			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(
						creating ? "general.add_:item" : "general.edit_:item",
						{},
						{ item: "communication.newsletter_subscription_types.singular" },
					)}
				</h1>

				<a className="link" href={route("crm.communication-settings.newsletter-subscription-types.index")}>
					{uctrans(
						"general.back_to_overview_:page",
						{},
						{ page: "communication.newsletter_subscription_types.plural" },
					)}
				</a>
			</div>
			<AjaxForm
				method={creating ? "POST" : "PUT"}
				submitUrl={
					creating
						? route("crm.communication-settings.newsletter-subscription-types.store")
						: route("crm.communication-settings.newsletter-subscription-types.update", formData.id)
				}
				loaderText={uctrans(
					"general.:item_is_being_saved",
					{},
					{ item: "communication.newsletter_subscription_types.singular" },
				)}
				successText={uctrans(
					"general.saved_:item",
					{},
					{ item: "communication.newsletter_subscription_types.singular" },
				)}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="name"
										value={formData.name ? formData.name : ""}
										onChange={inputHandler}
										label="communication.newsletter_subscription_types.fields.name"
										required
									/>
								</div>
								<div className="form-1-3">
									<label htmlFor="category_id">
										{uctrans("communication.newsletter_subscription_types.fields.category")}
									</label>
									<Select
										value={newsletterSubscriptionCategories.filter(
											({ value }) => Number(value) === Number(formData.category_id ? formData.category_id : 0),
										)}
										options={newsletterSubscriptionCategories}
										name="category_id"
										onChange={selectHandler}
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="description"
										value={formData.description ? formData.description : ""}
										onChange={inputHandler}
										label="communication.newsletter_subscription_types.fields.description"
										required
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-3">
									<Checkmark
										onChange={inputHandler}
										name="contactpersons_can_unsubscribe"
										checked={formData.contactpersons_can_unsubscribe || false}
										label={uctrans("communication.newsletter_subscription_types.fields.contactpersons_can_unsubscribe")}
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-3">
									<Checkmark
										onChange={inputHandler}
										name="companies_can_unsubscribe"
										checked={formData.companies_can_unsubscribe || false}
										label={uctrans("communication.newsletter_subscription_types.fields.companies_can_unsubscribe")}
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-3">
									<Checkmark
										onChange={inputHandler}
										name="users_can_unsubscribe"
										checked={formData.users_can_unsubscribe || false}
										label={uctrans("communication.newsletter_subscription_types.fields.users_can_unsubscribe")}
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-3">
									<Checkmark
										onChange={inputHandler}
										name="is_active"
										checked={formData.is_active}
										label={uctrans("communication.newsletter_subscription_types.fields.active")}
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-3">
									<Checkmark
										onChange={inputHandler}
										name="for_automated_messages"
										checked={formData.for_automated_messages}
										label={uctrans("communication.newsletter_subscription_types.fields.for_automated_messages")}
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-3">
									<Checkmark
										onChange={inputHandler}
										name="for_newsletters"
										checked={formData.for_newsletters}
										label={uctrans("communication.newsletter_subscription_types.fields.for_newsletters")}
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-3">
									<Checkmark
										onChange={inputHandler}
										name="for_mailing_list"
										checked={formData.for_mailing_list}
										label={uctrans("communication.newsletter_subscription_types.fields.for_mailing_list")}
									/>
								</div>
							</div>

							{!!formData.for_mailing_list && (
								<div className="form-full">
									<div className="form-1-3">
										<Checkmark
											onChange={inputHandler}
											name="mailing_list_can_unsubscribe"
											checked={formData.mailing_list_can_unsubscribe}
											label={uctrans("communication.newsletter_subscription_types.fields.mailing_list_can_unsubscribe")}
										/>
									</div>
								</div>
							)}

							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="communication.newsletter_subscription_types.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
