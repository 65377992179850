import cloneDeep from "lodash/cloneDeep";
import { useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../../general/tabs/TabLinks";
import RelationManagerQuestionnaireCompletionOverview from "./RelationManagerQuestionnaireCompletionOverview";
import RelationManagerSurveySummaryBlock from "./RelationManagerSurveySummaryBlock";

export default function RelationManagerSurveyCompanyView() {
	const [data] = useState({
		readOnly: window.data.read_only || true,
		company: { ...window.data.company },
		overview: { ...window.data.overview },
		questionnaire: window.data.questionnaire,
		formInfo: window.data.formInfo ? cloneDeep(window.data.formInfo) : [],
		formValues: window.data.formValues ? cloneDeep(window.data.formValues) : [],
		overviewRoute: window.data.overview_route,
		summaryRoute: window.data.summary_route,
		collepseRoute: window.data.collepse_route,
	});

	return (
		<div>
			{window.data.nav_data && (
				<>
					<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
					<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
				</>
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.page_title ? window.data.page_title : uctrans("survey.relationmanager.plural")}
				</h1>
			</div>

			{window.data.summary_data && <RelationManagerSurveySummaryBlock summaryData={data} />}
			<RelationManagerQuestionnaireCompletionOverview overviewData={data} />
		</div>
	);
}
