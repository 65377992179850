import PropTypes from "prop-types";
import map from "lodash/map";
import { trans, uctrans } from "../../../../../../../js/lib/Translator";
import FormattedNumberText from "../../FormattedNumberText";

export default function ServiceLevelQuantitatively({ data }) {
	return (
		<div>
			<table className="servicelevel_kwantitatief_table rapportage_table reporttable">
				<thead>
					<tr>
						<th colSpan="10">{data.title ? data.title : uctrans("report.singular")}</th>
					</tr>
					<tr className="head1">
						<td colSpan="3" className="servicelevel" />
						<td colSpan="4" className="aantal" />
						<td colSpan="2" className="resultaat">
							Resultaat
						</td>
						<td className="gemiddelde_doorlooptijd" />
					</tr>
					<tr className="head2">
						<td className="naam">Onderdeel</td>
						<td className="servicelevel_in_behandeling">SLA in behandeling </td>
						<td className="servicelevel">SLA afgehandeld</td>
						<td className="aantal">Aanbod</td>
						<td className="aantal_in_behandeling_sla">In behandeling binnen SLA</td>
						<td className="aantal_sla">Afgehandeld binnen SLA</td>
						<td className="percentage_sla">% afgehandeld</td>
						<td className="aantal_positief">Positief</td>
						<td className="aantal_negatief">Negatief</td>
						<td className="gemiddelde_doorlooptijd">Gemiddelde doorlooptijd</td>
					</tr>
				</thead>
				<tbody>
					{map(data.data.data, (entry, index) => (
						<tr key={index}>
							<td className="naam">{entry.name ? entry.name : ""}</td>
							<td className="servicelevel_in_behandeling">
								{`${
									entry.servicelevel_processing_date_unit_value ? entry.servicelevel_processing_date_unit_value : "-"
								} ${
									entry.servicelevel_processing_date_unit_type ? entry.servicelevel_processing_date_unit_type : ""
								}`}{" "}
							</td>
							<td className="servicelevel">
								{`${entry.servicelevel_unit_value ? entry.servicelevel_unit_value : "-"} ${
									entry.servicelevel_unit_type ? entry.servicelevel_unit_type : ""
								}`}{" "}
							</td>
							<td className="aantal">{entry.count ? entry.count : 0}</td>
							<td className="aantal_in_behandeling_sla">
								{entry.count_processing_sla ? entry.count_processing_sla : 0}
							</td>
							<td className="aantal_sla">{entry.count_sla ? entry.count_sla : 0}</td>
							<td className="percentage_sla">
								<FormattedNumberText percentage value={entry.percentage_sla ? entry.percentage_sla : 0} />
							</td>
							{entry.count_positive ? (
								<td className="aantal_positief">{entry.count_positive}</td>
							) : (
								<td className="aantal_positief leeg" />
							)}
							{entry.count_negative ? (
								<td className="aantal_negatief">{entry.count_negative}</td>
							) : (
								<td className="aantal_negatief leeg" />
							)}
							{entry.average_lead_time ? (
								<td className="gemiddelde_doorlooptijd">
									<FormattedNumberText decimalScale={1} value={entry.average_lead_time} />{" "}
									{`${trans("report.account_support.days")}`}
								</td>
							) : (
								<td className="gemiddelde_doorlooptijd leeg" />
							)}
						</tr>
					))}
					{!!data.data.total && (
						<tr className="totaal">
							<td className="naam">{uctrans("report.total")}</td>
							<td className="servicelevel_in_behandeling" />
							<td className="servicelevel" />
							<td className="aantal">{data.data.total.count ? data.data.total.count : 0}</td>
							<td className="aantal_in_behandeling_sla">
								{data.data.total.count_processing_sla ? data.data.total.count_processing_sla : 0}
							</td>
							<td className="aantal_sla">{data.data.total.count_sla ? data.data.total.count_sla : 0}</td>
							<td className="percentage_sla">
								<FormattedNumberText
									percentage
									value={data.data.total.percentage_sla ? data.data.total.percentage_sla : 0}
								/>
							</td>
							{data.data.total.count_positive ? (
								<td className="aantal_positief">{data.data.total.count_positive}</td>
							) : (
								<td className="aantal_positief leeg" />
							)}
							{data.data.total.count_negative ? (
								<td className="aantal_negatief">{data.data.total.count_negative}</td>
							) : (
								<td className="aantal_negatief leeg" />
							)}
							<td className="gemiddelde_doorlooptijd" />
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}

ServiceLevelQuantitatively.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	title: PropTypes.string,
};
