import { useState } from "react";
import PropTypes from "prop-types";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import { can } from "../../../../../../js/helpers";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import Translate from "../../../../../../js/react/components/general/Translate";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import Dialog from "../../../../../../js/react/components/general/Dialog";
import NewsletterBlockForm from "./NewsletterBlockForm";

export default function NewsletterBlockOverview({ newsletter, staticData, indexRoute, readOnly }) {
	const [currentNewsletterBlock, setCurrentNewsletterBlock] = useState(null);
	const [overviewDataUpdatedAt, setOverviewDataUpdatedAt] = useState(new Date().getTime());

	const moveUrl = () => "crm.communication.newsletter-blocks.move";

	const deleteUrl = () => {
		if (Number(newsletter.type) === Number(staticData.message_communication_types.AUTOMATED_MESSAGE)) {
			return "crm.communication.automated-messages.blocks.delete";
		} else if (Number(newsletter.type) === Number(staticData.message_communication_types.NEWSLETTER)) {
			return "crm.communication.newsletter-blocks.delete";
		}
	};

	const addNewsletterBlock = () => {
		const type = staticData.newsletter_block_types.find(() => true);
		setCurrentNewsletterBlock({
			id: -1,
			title: "",
			newsletter_id: newsletter.id,
			type: type ? type.value : null,
			starts_new_section: 1,
		});
	};

	const handleSuccess = () => {
		setOverviewDataUpdatedAt(new Date().getTime());
		setCurrentNewsletterBlock(null);
	};

	return (
		<>
			<br />
			<hr />
			<br />
			<div className="flex justify-between">
				<h3>
					<Translate content="communication.newsletter_blocks.plural" />
				</h3>
				<IconButton
					onClick={addNewsletterBlock}
					content={uctrans("general.add_:item", {}, { item: "communication.newsletter_blocks.singular" })}
					primary
					disabled={readOnly}
				/>
			</div>
			<AbnOverview
				indexUrl={indexRoute}
				deleteRouteName={deleteUrl()}
				moveRouteName={moveUrl()}
				hideControls
				useDragAndDrop={!readOnly}
				modelTranslations={{
					plural: trans("communication.newsletter_blocks.plural"),
					singular: trans("communication.newsletter_blocks.singular"),
				}}
				dataUpdatedAt={overviewDataUpdatedAt}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={1}
							className="draggable-overview-item-handle"
							renderHeaderContent={() => ""}
							renderCellContent={() => ""}
						/>
						<DataColumn
							width={10}
							renderHeaderContent={() => uctrans("communication.newsletter_blocks.singular")}
							renderCellContent={newsletterBlock => {
								const type = staticData.newsletter_block_types.find(
									({ value }) => Number(value) === Number(newsletterBlock.type ? newsletterBlock.type : 0),
								);
								return (
									<>
										<span className="font-bold">
											{newsletterBlock.title
												? newsletterBlock.title
												: uctrans("general.no_:item", {}, { item: "communication.newsletter_blocks.fields.title" })}
										</span>
										<span className="italic">
											{" "}
											({type ? type.label : "-"}
											{!newsletterBlock.starts_new_section && newsletterBlock.is_column
												? `, ${trans("communication.newsletter_blocks.fields.is_column")}`
												: ""}
											{newsletterBlock.starts_new_section
												? `, ${trans("communication.newsletter_blocks.starts_new_section")}`
												: ""}
											)
										</span>
										<p className="text-xs">
											{newsletterBlock.content
												? newsletterBlock.content.replace(/(<([^>]+)>)/gi, "").substring(0, 200)
												: ""}
										</p>
									</>
								);
							}}
						/>
						{(can("communication.newsletter_block", "update") || can("communication.newsletter_block", "delete")) && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(newsletterBlock, overview) => (
									<div className="flex">
										{can("communication.newsletter_block", "update") && (
											<a onClick={() => setCurrentNewsletterBlock(newsletterBlock)} className="mr-2">
												<EvaIcon
													className="flex blocks-center"
													type={`${readOnly ? "eye" : "edit"}-outline`}
													width="20"
													height="20"
													fill="#009286"
												/>
											</a>
										)}
										{can("communication.newsletter_block", "delete") && !readOnly && (
											<DeleteColumnContent onClick={() => overview.askDelete(newsletterBlock)} />
										)}
									</div>
								)}
							/>
						)}
					</DataTable>
				)}
			/>

			{currentNewsletterBlock !== null && (
				<Dialog
					width={1200}
					isOpen={currentNewsletterBlock !== null}
					title={
						currentNewsletterBlock.id === -1
							? uctrans("general.add_:item", {}, { item: "communication.newsletter_blocks.singular" })
							: uctrans("general.edit_:item", {}, { item: "communication.newsletter_blocks.singular" })
					}
					isFullScreen
					onClose={() => setCurrentNewsletterBlock(null)}>
					<NewsletterBlockForm
						newsletter={newsletter}
						staticData={staticData}
						newsletterBlock={currentNewsletterBlock}
						onSuccess={() => handleSuccess()}
						onCancel={() => setCurrentNewsletterBlock(null)}
						readOnly={readOnly}
					/>
				</Dialog>
			)}
		</>
	);
}

NewsletterBlockOverview.propTypes = {
	newsletter: PropTypes.object.isRequired,
	staticData: PropTypes.object.isRequired,
	indexRoute: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
	readOnly: PropTypes.bool,
};
