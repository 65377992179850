import find from "lodash/find";
import kebabCase from "lodash/kebabCase";
import map from "lodash/map";
import { useMemo, useState } from "react";
import { format, parse } from "../../../../../../js/lib/Date";
import Nav from "../../../../../../js/lib/Nav";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../../js/react/components/general/Translate";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import Select from "../../../../../../js/react/components/general/Select";
import DatePicker from "../../../../../../js/react/components/general/DatePicker";
import { CmsTypesContext } from "../cms-types-context";
import Attribute from "../general/attributes/Attribute";
import PartContent from "../general/parts/PartContent";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";

export default function NewsitemForm() {
	const [newsitemValues, setNewsitemValues] = useState({ ...window.data.newsitem_data.newsitemvalues });
	const [title] = useState(window.data.newsitem_data.newsitemvalues.title);
	const [staticvalues] = useState({ ...window.data.staticvalues });

	const resourceType = useMemo(
		() => find(staticvalues.cmsTypes.resourceTypes, resourceType => resourceType.key === "newsitem"),
		[staticvalues.cmsTypes.resourceTypes],
	);

	const updateNewsitemvalue = (property, value) => {
		setNewsitemValues({ ...newsitemValues, [property]: value });
	};

	const handleContentChange = (attributeKey, values) => {
		const newContentValues = { ...newsitemValues.contents, [attributeKey]: values };
		setNewsitemValues({ ...newsitemValues, contents: newContentValues });
	};

	return (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{ label: uctrans("general.cms") },
					{ label: uctrans("cms.newsitems.plural"), route: staticvalues.previous_link },
					{
						label: staticvalues.creating ? uctrans("general.add_:item", {}, { item: "cms.newsitems.singular" }) : title,
					},
				]}
			/>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(
						staticvalues.creating ? "general.add_:item" : "general.edit_:item",
						{},
						{ item: "cms.newsitems.singular" },
					)}
				</h1>
				{staticvalues.previous_link && (
					<a className="link" href={staticvalues.previous_link}>
						{uctrans("general.back_to_overview_:page", {}, { page: "cms.newsitems.plural" })}
					</a>
				)}
			</div>
			<AjaxForm
				method={staticvalues.save_method}
				submitUrl={staticvalues.save_link}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "cms.newsitems.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "cms.newsitems.singular" })}
				onSuccess={response =>
					!staticvalues.creating
						? setNewsitemValues(response.data.newsitem_data.newsitemvalues)
						: Nav.go(response.data.redirect_route)
				}
				data={newsitemValues}>
				<div className="max-w-2xl">
					<div className="grid-container">
						<div className="grid-1-2">
							<LabeledTextInput
								name="title"
								label="cms.newsitems.fields.title"
								value={newsitemValues.title}
								onChange={e => updateNewsitemvalue("title", e.target.value)}
							/>
						</div>
						<div className="form-1-2">
							<label htmlFor="date">
								<Translate content="cms.newsitems.fields.date" />
							</label>
							<DatePicker
								initialValue={newsitemValues.date ? parse(newsitemValues.date) : null}
								onChange={date => updateNewsitemvalue("date", date ? format(date) : null)}
							/>
						</div>
					</div>

					<div className="form-container">
						<div className="form-1-3">
							<label htmlFor="publish_status">
								<Translate content="cms.newsitems.fields.publish_status" />
							</label>
							<Select
								id="publish_status"
								name="publish_status"
								value={staticvalues.publish_statuses.filter(({ value }) => value === newsitemValues.publish_status)}
								onChange={selectedOption => updateNewsitemvalue("publish_status", selectedOption.value)}
								options={staticvalues.publish_statuses}
								required
								isClearable={false}
							/>
						</div>
						<div className="form-1-3">
							<label htmlFor="type">{uctrans("marketing.themes.singular")}</label>
							<Select
								value={staticvalues.marketing_themes.filter(
									({ value }) =>
										Number(value) ===
										Number(newsitemValues.marketing_theme_id ? newsitemValues.marketing_theme_id : null),
								)}
								options={staticvalues.marketing_themes}
								name="marketing_theme_id"
								onChange={(value, metadata) => updateNewsitemvalue(metadata.name, value ? value.value : null)}
							/>
						</div>
						{newsitemValues.publish_status === 3 && (
							<div className="form-1-3">
								<div className="flex justify-between">
									<div>
										<label htmlFor="published_from">
											<Translate content="cms.newsitems.fields.published_from" />
										</label>
										<DatePicker
											initialValue={newsitemValues.published_from ? parse(newsitemValues.published_from) : null}
											onChange={date => updateNewsitemvalue("published_from", date ? format(date) : null)}
											showTimeSelect
										/>
									</div>
									<div className="ml-2">
										<label htmlFor="published_to">
											<Translate content="cms.newsitems.fields.published_to" />
										</label>
										<DatePicker
											initialValue={newsitemValues.published_to ? parse(newsitemValues.published_to) : null}
											onChange={date => updateNewsitemvalue("published_to", date ? format(date) : null)}
											showTimeSelect
										/>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="form-container">
					<div className="form-1-2">
						<label htmlFor="name">
							{uctrans("cms.newsitems.fields.slug")}
							<span className="text-orange"> *</span>
						</label>
						<input
							id="slug"
							type="text"
							name="slug"
							value={newsitemValues.slug}
							placeholder={`${staticvalues.base_url}/...`}
							onChange={e => updateNewsitemvalue("slug", e.target.value)}
						/>
						<button
							className="link"
							onClick={() =>
								updateNewsitemvalue(
									"slug",
									kebabCase(
										`${newsitemValues.date ? format(newsitemValues.date, "dd-MM-y") : ""} ${newsitemValues.title}`,
									),
								)
							}>
							{uctrans("cms.newsitems.fields.generate_url")}
						</button>
					</div>
				</div>
				<CmsTypesContext.Provider value={staticvalues.cmsTypes}>
					<div className="cms-attribute grid-container">
						<div className="grid-1-2">
							{resourceType &&
								(() =>
									map(resourceType.attributes, (attribute, key) => {
										const attributeValues =
											newsitemValues.contents != null && Object.hasOwn(newsitemValues.contents, key)
												? newsitemValues.contents[key]
												: null;
										if (attribute.type === "part") {
											return (
												<PartContent
													key={key}
													label={attribute.label}
													parttype={attribute.parttype}
													attribute={attribute}
													values={attributeValues}
													onChange={values => handleContentChange(key, values)}
												/>
											);
										} else {
											return (
												<Attribute
													key={key}
													attribute={attribute}
													values={attributeValues}
													onChange={values => handleContentChange(key, values)}
												/>
											);
										}
									}))()}
						</div>
					</div>
					<div>
						<SubmitBar item={uctrans("cms.newsitems.singular")} translateLabel={false} />
					</div>
				</CmsTypesContext.Provider>
			</AjaxForm>
		</>
	);
}

NewsitemForm.propTypes = {};
