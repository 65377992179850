import PropTypes from "prop-types";
import { uctrans } from "../../../../../../../js/lib/Translator";
import MultiSelectAutoComplete from "../../../../../../../js/react/components/general/autocomplete/MultiSelectAutoComplete";
import Radio from "../../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../../js/react/components/general/form/radio/RadioGroup";
import Select from "../../../../../../../js/react/components/general/Select";
import Translate from "../../../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../../../js/react/hooks/useGenericHandler";
import useSelectHandler from "../../../../../../../js/react/hooks/useSelectHandler";
import PeriodicFilter from "../../../general/PeriodicFilter";

export default function NewsletterOptinOutReportFilters({ formInfo, formValues, setFormValues }) {
	const selectHandler = useSelectHandler(setFormValues);
	const genericHandler = useGenericHandler(setFormValues);

	return (
		<>
			<div className="w-1/4 pr-2">
				<label htmlFor="report_type">
					<Translate content="report.type_report" />
				</label>
				<Select
					isClearable={false}
					placeholder={uctrans("general.select_:item", {}, { item: "report.type_report" })}
					value={formInfo.reportTypes.filter(({ value }) => `${value}` === `${formValues.report_type}`)}
					options={formInfo.reportTypes}
					name="report_type"
					onChange={(selectedValue, metaData) => {
						selectHandler(selectedValue, metaData);
					}}
				/>
			</div>

			<div className="flex w-full mt-5">
				{formValues.categoryOptions && (
					<div className="w-1/5 pr-2">
						<label htmlFor="categoryOptions">{uctrans("report.category_type")}</label>
						<MultiSelectAutoComplete
							options={formInfo.categoryOptions}
							name="categoryOptions"
							items={formValues.categoryOptions}
							onChange={selectedCategoryOptions => genericHandler(selectedCategoryOptions, "categoryOptions")}
							placeholder={uctrans("general.type_to_add")}
							showItemsOnFirstClick
						/>
					</div>
				)}

				{formValues.subscriptionTypes && (
					<div className="w-1/5 pr-2">
						<label htmlFor="scoringTypes">{uctrans("report.subscription_type")}</label>
						<MultiSelectAutoComplete
							options={formInfo.subscriptionTypes}
							name="subscriptionTypes"
							items={formValues.subscriptionTypes}
							onChange={selectedSubscriptionTypes => genericHandler(selectedSubscriptionTypes, "subscriptionTypes")}
							placeholder={uctrans("general.type_to_add")}
							showItemsOnFirstClick
						/>
					</div>
				)}

				<div className="w-3/5 pr-2">
					<PeriodicFilter
						formValues={formValues}
						formInfo={formInfo}
						setFormValuesFunc={setFormValues}
						periodTypeOptions={formInfo ? formInfo.optinPeriodTypes : []}
					/>
				</div>
			</div>

			<div className="flex w-full mt-5">
				<div className="w-1/6 pr-2">
					<label htmlFor="relationmanager">{uctrans("report.group_types.relationmanager")}</label>
					<Select
						name="relationmanager"
						value={formInfo.relationmanagers.filter(({ value }) => `${value}` === `${formValues.relationmanager}`)}
						options={formInfo.relationmanagers}
						onChange={selectHandler}
					/>
				</div>
				<div className="w-1/6 pr-2">
					<label htmlFor="segment">{uctrans("report.group_types.segment")}</label>
					<Select
						name="segment"
						value={formInfo.segments.filter(({ value }) => `${value}` === `${formValues.segment}`)}
						options={formInfo.segments}
						onChange={selectHandler}
					/>
				</div>
				<div className="w-1/6 pr-2">
					<label htmlFor="organisation">{uctrans("report.group_types.organisation")}</label>
					<Select
						name="organisation"
						value={formInfo.organisations.filter(({ value }) => `${value}` === `${formValues.organisation}`)}
						options={formInfo.organisations}
						onChange={selectHandler}
					/>
				</div>
				<div className="w-1/6 pr-2">
					<label htmlFor="team">{uctrans("teams.singular")}</label>
					<Select
						name="team"
						value={formInfo.teams.filter(({ value }) => `${value}` === `${formValues.team}`)}
						options={formInfo.teams}
						onChange={selectHandler}
					/>
				</div>
				{formInfo.newsletterUnsubscribeObjectTypes && (
					<div className="w-1/6 pr-2">
						<label htmlFor="company_or_contactperson">
							{uctrans("report.marketing.company_contactperson_export_only")}
						</label>
						<RadioGroup
							name="newsletterUnsubscribeObjectType"
							value={formValues.newsletterUnsubscribeObjectType}
							onChange={selectedCategoryOptions =>
								genericHandler(selectedCategoryOptions, "newsletterUnsubscribeObjectType")
							}>
							{formInfo.newsletterUnsubscribeObjectTypes.map(objectType => (
								<Radio key={objectType.value} label={objectType.label} value={objectType.value} />
							))}
						</RadioGroup>
					</div>
				)}
			</div>
		</>
	);
}

NewsletterOptinOutReportFilters.propTypes = {
	formValues: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	formInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	setFormValues: PropTypes.func,
};
