import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Translate from "../../../../../../js/react/components/general/Translate";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import axios from "axios";
import { route } from "../../../../../../js/helpers";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import Dialog from "../../../../../../js/react/components/general/Dialog";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import { uctrans } from "../../../../../../js/lib/Translator";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import AutoComplete from "../../../../../../js/react/components/general/autocomplete/AutoComplete";
import ConfirmDialog from "../../../../../../js/react/components/general/ConfirmDialog";
import SingleSelectAutoComplete from "../../../../../../js/react/components/general/autocomplete/SingleSelectAutoComplete";
import { createLoader } from "../../../../../../js/react/components/general/notifications";
import Tippy from "@tippyjs/react";

export default function FilterProfileBlocks({ filterProfile }) {
	const filterBlockTypes = window.data.filter_block_types;
	const filterBlockTypesConstants = window.data.filter_block_types_constants;

	const typesToBeFetchedLazy = new Set([
		filterBlockTypesConstants.COMPANY,
		filterBlockTypesConstants.ORGANISATION,
		filterBlockTypesConstants.TEAM,
		filterBlockTypesConstants.RELATIONMANAGER,
		filterBlockTypesConstants.ROLE_PERMISSIONGROUP,
		filterBlockTypesConstants.ROLE,
	]);

	const addAction = 1;
	const deleteAction = 2;

	const [entryOptions, setEntryOptions] = useState([]);
	const [currentFilterBlock, setcurrentFilterBlock] = useState(null);
	const [currentObject, setCurrentObject] = useState(null);
	const [actionType, setActionType] = useState(null);
	const [loadingEntries, setLoadingEntries] = useState(false);

	const initialFilterBlocks = filterBlockTypes.map(filterBlockType => ({
		key: filterBlockType.key,
		label: filterBlockType.label,
		positive_description: filterBlockType.positive_description,
		negative_description: filterBlockType.negative_description,
		loading: true,
		entries: [],
	}));

	const [filterBlocks, setFilterBlocks] = useState(initialFilterBlocks);

	const fetchBlockEntries = async (filterProfileId, filterBlockType) => {
		const entries = await axios.get(
			route("crm.cms.filter-profile-blocks.show", {
				filter_profile: filterProfileId,
				filter_type: filterBlockType,
			}),
		);

		return entries;
	};

	const fetchEntryOptions = async filterBlockType => {
		const entryOptions = await axios.get(
			route("crm.cms.filter-profile-blocks.options", {
				filter_profile: filterProfile.id,
				filter_type: filterBlockType,
			}),
		);

		return entryOptions.data;
	};

	const onBlockFetched = useCallback(
		(filterBlockType, data) => {
			const currentFilterBlocks = filterBlocks.slice(0);

			const foundFilterBlock = currentFilterBlocks.find(foundFilterBlock => foundFilterBlock.key === filterBlockType);
			foundFilterBlock.entries = data;
			foundFilterBlock.loading = false;

			setFilterBlocks(currentFilterBlocks);
		},
		[filterBlocks],
	);

	const loadInitialData = useCallback(() => {
		filterBlockTypes.forEach(filterBlockType => {
			fetchBlockEntries(filterProfile.id, filterBlockType.key).then(response =>
				onBlockFetched(filterBlockType.key, response.data),
			);
		});
	}, [filterProfile.id, filterBlockTypes, onBlockFetched]);

	useEffect(() => {
		loadInitialData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addFilterBlockData = async filterBlock => {
		setLoadingEntries(true);
		setActionType(addAction);
		setcurrentFilterBlock(filterBlock);

		if (typesToBeFetchedLazy.has(filterBlock.key) === false) {
			const entryOptions = await fetchEntryOptions(filterBlock.key);
			setEntryOptions(entryOptions);
		}
		setLoadingEntries(false);
	};

	const removeFilterBlockData = (filterBlock, object) => {
		setActionType(deleteAction);
		setcurrentFilterBlock(filterBlock);
		setCurrentObject(object);
	};

	const handleFilterAddition = () => {
		fetchBlockEntries(filterProfile.id, currentFilterBlock.key).then(response =>
			onBlockFetched(currentFilterBlock.key, response.data),
		);
		setCurrentObject(null);
		setcurrentFilterBlock(null);
	};

	const handleCancel = () => {
		setActionType(null);
		setcurrentFilterBlock(null);
	};

	const removeEntry = async (filterProfileId, filterBlockType, objectId) => {
		const entries = await axios.delete(
			route("crm.cms.filter-profile-blocks.destroy", {
				filter_profile: filterProfileId,
				filter_type: filterBlockType.key,
				object_id: objectId,
			}),
		);
		return entries;
	};

	const handleFilterRemoval = async () => {
		const loader = createLoader(uctrans("cms.filter_blocks.removing_filter", {}, { item: currentObject.label }));

		await removeEntry(filterProfile.id, currentFilterBlock, currentObject.object_id);
		fetchBlockEntries(filterProfile.id, currentFilterBlock.key).then(response =>
			onBlockFetched(currentFilterBlock.key, response.data),
		);

		loader.success(uctrans("cms.filter_blocks.filter_removed", {}, { item: currentObject.label }));

		setCurrentObject(null);
		setcurrentFilterBlock(null);
	};

	return (
		<>
			<hr className="mt-4" />
			<h3 className="mt-4">
				<Translate content="cms.filter_blocks.title" />
				<Tippy
					placement="right"
					arrow
					animation="perspective"
					duration="400"
					content={uctrans("cms.filter_profiles.description")}>
					<span className="mt-1 ml-2">
						<EvaIcon type="info" fill="#249286" height="20" width="20" />
					</span>
				</Tippy>
			</h3>

			{filterBlocks.map(filterBlock => (
				<div key={filterBlock.key} className="mb-5" style={{ maxWidth: "600px" }}>
					<div className="flex justify-between align-center">
						<div>
							<h4 className="flex mb-0">
								{filterBlock.label}
								<Tippy
									placement="right"
									arrow
									animation="perspective"
									duration="400"
									content={
										filterProfile.positive ? filterBlock.positive_description : filterBlock.negative_description
									}>
									<span className="mt-1 ml-2">
										<EvaIcon type="info-outline" fill="#249286" height="18" width="18" />
									</span>
								</Tippy>
							</h4>
							<small>
								{uctrans("general.amount")}: {filterBlock.entries.length}
							</small>
						</div>
						<a onClick={() => addFilterBlockData(filterBlock)} className="mt-1">
							<EvaIcon type="plus" height="18" width="18" />
						</a>
					</div>
					{filterBlock.loading ? (
						<div className="text-left flex-grow mt-2">
							<Spinner width={25} />
						</div>
					) : (
						<ul className="linked-items">
							{filterBlock.entries.map((entry, index) => (
								<li className="bg-grey-light px-3 py-2 my-2" key={index}>
									<div className="flex">
										<span className="linked-item-title">{entry.label}</span>
										<span className="ml-auto"></span>
										<a onClick={() => removeFilterBlockData(filterBlock, entry)}>
											<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
										</a>
									</div>
								</li>
							))}
						</ul>
					)}
				</div>
			))}
			{currentFilterBlock !== null && actionType === addAction && (
				<Dialog isOpen title={currentFilterBlock.label} isFullScreen onClose={() => setcurrentFilterBlock(null)}>
					<AjaxForm
						method="POST"
						submitUrl={route("crm.cms.filter-profile-blocks.store", {
							filter_profile: filterProfile.id,
							filter_type: currentFilterBlock.key,
						})}
						loaderText={uctrans("cms.filter_blocks.saving_filter_change")}
						successText={uctrans("cms.filter_blocks.filter_change_saved")}
						onSuccess={handleFilterAddition}
						data={{
							object_id: currentObject === null ? null : currentObject.object_id,
						}}>
						<div className="md:flex">
							<div className="flex-1 w-2/3 h-2/3 mr-12">
								<div className="form-container" style={{ marginBottom: "150px" }}>
									<div className="form-2-3">
										{loadingEntries ? (
											<div className="text-left flex-grow mt-2">
												<Spinner width={25} />
											</div>
										) : typesToBeFetchedLazy.has(currentFilterBlock.key) ? (
											<SingleSelectAutoComplete
												name="company_id"
												dataSource={route("crm.cms.filter-profile-blocks.options", {
													filter_profile: filterProfile.id,
													filter_type: currentFilterBlock.key,
												})}
												item={null}
												onChange={object => setCurrentObject(object)}
												placeholder="Zoeken"
												async
												isClearable
											/>
										) : entryOptions.length > 0 ? (
											<AutoComplete
												name="object_id"
												options={entryOptions}
												onChange={object => setCurrentObject(object)}
												items={[]}
												showItemsOnFirstClick
												placeholder="Zoeken"
												isClearable
											/>
										) : (
											<p>
												<Translate content="cms.filter_blocks.all_options_used" />
											</p>
										)}
									</div>
									<div className="form-full">
										<div className="w-full md:w-1/2 lg:w-2/3 column">
											<SubmitBar
												item="cms.filter_blocks.filter.singular"
												submitDisabled={currentObject === null}
												addAnotherAvailable={false}
												buttonContent="toevoegen"
												onCancel={handleCancel}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</AjaxForm>
				</Dialog>
			)}
			{currentObject !== null && actionType === deleteAction && (
				<ConfirmDialog
					onConfirm={() => handleFilterRemoval()}
					onCancel={handleCancel}
					isOpen
					title={uctrans("cms.filter_blocks.delete_filter_row_title", { item: currentObject.label })}></ConfirmDialog>
			)}
		</>
	);
}

FilterProfileBlocks.propTypes = {
	filterProfile: PropTypes.object.isRequired,
};
