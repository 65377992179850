import Tippy from "@tippyjs/react";
import { useContext } from "react";
import { numberFormat } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import EngagementScore from "../engagementScore/EngagementScore";
import TitleBlock from "./components/TitleBlock";
import EngagementScoreLineChart from "./graph/EngagementScoreLineChart";
import RmDashboardBlock from "./RmDashboardBlock";
import { RmDashboardContext } from "./RmDashboardContext";

export default function CooperationBlock() {
	const { wallet } = useContext(RmDashboardContext);

	return (
		<RmDashboardBlock
			blockKey="cooperation"
			useFetching
			renderHeader={() => <h5 className="mb-0">{uctrans("dashboard.cooperation")}</h5>}
			renderContent={blockData => {
				if (blockData[wallet.type]) {
					const conversationsTarget = blockData[wallet.type].conversations_target;
					const conversationsAchieved = blockData[wallet.type].conversations_achieved;
					const conversationsPercentage =
						conversationsTarget > 0 ? (conversationsAchieved / conversationsTarget) * 100 : 0;

					const gradeTarget = blockData[wallet.type].target_grade;
					const gradeAchieved = blockData[wallet.type].grade;

					const engagementScores = {
						totalLastScore: 0,
						walletLastScore: 0,
						walletScoreToCompare: 0,
						nrOfContactpersons: 0,
						totalData: [],
						walletData: [],
					};

					if (blockData[wallet.type].last_engagement_score) {
						engagementScores.nrOfContactpersons =
							blockData[wallet.type].last_engagement_score.nr_of_contactpersons || 0;
						engagementScores.walletLastScore = blockData[wallet.type].last_engagement_score.score_relative || 0;
						engagementScores.walletScoreToCompare = blockData[wallet.type].last_engagement_score.score_to_compare || 0;
					}

					if (blockData[wallet.type].engagement_scores) {
						engagementScores.walletData = blockData[wallet.type].engagement_scores || [];
					}

					if (blockData.total) {
						if (blockData.total.last_engagement_score) {
							engagementScores.totalLastScore = blockData.total.last_engagement_score.score_relative || 0;
						}
						engagementScores.totalData = blockData.total.engagement_scores || [];
					}

					return (
						<div className="w-full">
							<div className="flex w-full">
								{wallet.type !== "intermediary" && (
									<div className="w-1/5">
										<h6>
											<TitleBlock
												title={uctrans("report.conversation.total_intermediairies")}
												dashboardLink={blockData.total_conversation_dashboard_link}
												walletType={wallet.type}
											/>
										</h6>

										<div className="rounded-full dashboard-circle flex items-center justify-center">
											{numberFormat(blockData[wallet.type].nr_of_intermediaries)}
										</div>
									</div>
								)}
								<div className={`w-1/${wallet.type === "intermediary" ? 4 : 5}`}>
									<h6>{uctrans("engagement_score.fields.nr_of_contactpersons")}</h6>
									<div className="rounded-full dashboard-circle flex items-center justify-center">
										{engagementScores.nrOfContactpersons}
									</div>
								</div>

								{wallet.type === "no_relationmanager" ? (
									uctrans("dashboard.no_rm_dashboard")
								) : (
									<>
										<div className={`w-1/${wallet.type === "intermediary" ? 4 : 5}`}>
											<h6>
												<TitleBlock
													title={uctrans("dashboard.conversations")}
													dashboardLink={blockData.total_conversation_dashboard_link}
													walletType={wallet.type}
												/>
											</h6>
											<div>
												<div className="h-5 relative max-w-xl rounded-full overflow-hidden mb-2">
													<div className="w-full h-full bg-grey absolute" />
													<div
														className="h-full bg-primary absolute"
														style={{ width: `${conversationsPercentage > 100 ? 100 : conversationsPercentage}%` }}
													/>
												</div>
											</div>
											<ul>
												<li>
													{uctrans("dashboard.yeartarget")}: {numberFormat(conversationsTarget)}
												</li>
												<li>
													{uctrans("dashboard.target_ytd")}:{" "}
													{numberFormat(blockData[wallet.type].conversations_target_ytd)}
												</li>
												<li>
													{uctrans("dashboard.achieved_percentage_ytd")}
													{`: ${conversationsAchieved} (${numberFormat(
														blockData[wallet.type].conversations_precentage_ytd,
													)}%)`}
												</li>
											</ul>
										</div>
										<div className={`pl-8 w-1/${wallet.type === "intermediary" ? 4 : 5}`}>
											<h6>
												<TitleBlock
													title={uctrans("dashboard.surveys")}
													dashboardLink={blockData.questionnaire_average_dashboard_link}
													walletType={wallet.type}
												/>
											</h6>

											<div className="rounded-full dashboard-circle flex items-center justify-center">
												<span>{numberFormat(blockData[wallet.type].survey_count)}</span>
											</div>
										</div>
										<div className={`w-1/${wallet.type === "intermediary" ? 4 : 5}`}>
											<h6>
												<TitleBlock
													title={uctrans("questionnaires.average_score")}
													dashboardLink={blockData.questionnaire_average_dashboard_link}
													walletType={wallet.type}
												/>
											</h6>
											<div>
												<div className="h-5 relative max-w-xl rounded-full overflow-hidden mb-2">
													<div className="w-full h-full bg-grey absolute" />
													<div
														className="h-full bg-primary absolute"
														style={{ width: `${gradeAchieved > 10 ? 100 : gradeAchieved * 10}%` }}
													/>
												</div>
												<div
													className="h-full bg-transparent border-r border-black relative h-5 t"
													style={{
														width: `${gradeTarget > 10 ? 100 : gradeTarget * 10}%`,
														height: 21,
														top: -25,
														marginBottom: -20,
													}}
												/>
											</div>
											<ul>
												<li>
													{uctrans("dashboard.target")}: {numberFormat(gradeTarget, 1)}
												</li>
												<li>
													{uctrans("dashboard.achieved_percentage_ytd")}: {numberFormat(gradeAchieved, 1)}
												</li>
												{wallet.type !== "total" && (
													<li>
														{uctrans("dashboard.nationwide")}: {numberFormat(blockData.total.grade, 1)}
													</li>
												)}
											</ul>
										</div>
									</>
								)}
							</div>
							<div className="w-full mt-2">
								<div className="flex w-full">
									<h6>
										<Tippy
											placement="right"
											arrow
											animation="perspective"
											duration="400"
											content={
												<span>
													<p>{uctrans("engagement_score.dashboard_mouse_over")}</p>
													<br />
													<p>{uctrans("engagement_score.score_to_compare_mouse_over")}</p>
												</span>
											}>
											<span>{uctrans("engagement_score.singular")}</span>
										</Tippy>
									</h6>
								</div>
								<div className="flex w-full">
									<div className={wallet.type === "intermediary" ? "w-1/4" : "w-1/5"}>
										<div className="h-1/2">
											<EngagementScore
												wallet={wallet}
												totalScore={engagementScores.totalLastScore}
												walletScore={engagementScores.walletLastScore}
												scoreToCompare={engagementScores.walletScoreToCompare}
											/>
										</div>
									</div>

									{blockData.total.engagement_scores && blockData[wallet.type].engagement_scores && (
										<div className={wallet.type === "intermediary" ? "w-3/4" : "w-4/5"}>
											<EngagementScoreLineChart
												startDate={blockData.engagement_score_start_date}
												endDate={blockData.engagement_score_end_date}
												totalData={engagementScores.totalData}
												walletData={engagementScores.walletData}
												wallet={wallet}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					);
				}
			}}
		/>
	);
}
