import PropTypes from "prop-types";
import { useContext } from "react";
import SelectField from "../../../../../../../js/react/components/general/Select";
import { CmsTypesContext } from "../../cms-types-context";

export default function InterestType(props) {
	const onchange = selectedOption => {
		props.onChange(selectedOption ? selectedOption.value : null);
	};

	const { interestTypes } = useContext(CmsTypesContext);
	const selectedOption = interestTypes.find(({ value }) => value === props.value);

	return (
		<>
			{props.label && <label>{props.label}</label>}
			<SelectField value={selectedOption} options={interestTypes} onChange={onchange} />
		</>
	);
}

InterestType.propTypes = {
	label: PropTypes.string,
	value: PropTypes.number,
	onChange: PropTypes.func,
};
