import Tippy from "@tippyjs/react";
import { useState } from "react";
import { route } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import { uctrans, trans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DatePickerFilter from "../../../../../js/react/components/general/overview/DatePickerFilter";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import Translate from "../../../../../js/react/components/general/Translate";
import Filters from "../../../../../js/react/components/general/overview/Filters";

export default function NotificationOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});
	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="notifications.plural" />
				</h1>
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.notifications.index")}
				modelTranslations={{
					plural: trans("notifications.plural"),
					singular: trans("notifications.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="company_search" placeholder={uctrans("notifications.company_search")} />

						<SelectFilter
							className="form-1-2"
							name="notification_type"
							label={uctrans("notifications.fields.type")}
							options={overviewData.overview.metadata.filterdata.notification_types}
						/>
						<SelectFilter
							className="form-1-4"
							name="relationmanager"
							label={uctrans("intermediaries.fields.relationmanager_id")}
							options={overviewData.overview.metadata.filterdata.relation_managers}
							collapsible={!window.data.relation_manager}
						/>
						<SelectFilter
							className="form-1-4"
							name="organisation"
							label={uctrans("intermediaries.fields.parent_id")}
							options={overviewData.overview.metadata.filterdata.organisations}
							collapsible
						/>
						<DatePickerFilter
							className="form-1-4"
							name="created_at_date_at_from"
							label={uctrans("notifications.created_at_date_at_from")}
							collapsible
						/>
						<DatePickerFilter
							className="form-1-4"
							name="created_at_date_at_until_and_with"
							label={uctrans("notifications.created_at_date_at_until_and_with")}
							collapsible
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("notifications.fields.datetime")}
							renderCellContent={notification =>
								notification.created_at ? format(notification.created_at, "dd-MM-y HH:ii") : null
							}
							sortable="created_at"
						/>
						<DataColumn
							width={10}
							renderHeaderContent={() => uctrans("notifications.fields.message")}
							renderCellContent={notification => notification.dashboard_title}
						/>
						<DataColumn
							width={1}
							renderHeaderContent={() => ""}
							renderCellContent={notification =>
								!!notification.dashboard_content && (
									<Tippy
										placement="top"
										arrow
										animation="perspective"
										duration="400"
										content={<div dangerouslySetInnerHTML={{ __html: notification.dashboard_content }} />}>
										<span>
											<EvaIcon fill="#249286" width="18" height="18" type="info-outline" />
										</span>
									</Tippy>
								)
							}
						/>
					</DataTable>
				)}
			/>
		</>
	);
}
