import PropTypes from "prop-types";
import { useContext } from "react";
import SelectField from "../../../../../../../js/react/components/general/Select";
import { CmsTypesContext } from "../../cms-types-context";

export default function Page(props) {
	const onchange = selectedPageOption => {
		props.onChange(selectedPageOption ? selectedPageOption.value : null);
	};

	const { pageOptions } = useContext(CmsTypesContext);
	const selectedPageOption = pageOptions.find(({ value }) => value === props.value);

	return (
		<>
			{props.label && <label>{props.label}</label>}
			<SelectField value={selectedPageOption} options={pageOptions} onChange={onchange} />
		</>
	);
}

Page.propTypes = {
	label: PropTypes.string,
	value: PropTypes.number,
	onChange: PropTypes.func,
};
