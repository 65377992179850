import { useState } from "react";
import { can, route } from "../../../../../../js/helpers";
import { trans, uctrans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import Navigation from "../SettingsNavigation";

export default function FlowmailerStatusOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<Navigation selectedTab="flowMailerStatus" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("flowmailer_status.plural")}</h1>
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.communication-settings.flow-mailer-statuses.index")}
				hideControls
				modelTranslations={{
					plural: trans("flowmailer_status.plural"),
					singular: trans("flowmailer_status.singular"),
				}}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={5}
							sortable="name"
							renderHeaderContent={() => uctrans("flowmailer_status.fields.name")}
							renderCellContent={status => {
								if (can("flowmailer-status", "update")) {
									return (
										<a
											href={route("crm.communication-settings.flow-mailer-statuses.edit", status.id)}
											className="row-title link">
											{status.name}
										</a>
									);
								} else {
									return status.name;
								}
							}}
						/>
						<DataColumn
							width={8}
							sortable="description"
							renderHeaderContent={() => uctrans("flowmailer_status.fields.description")}
							renderCellContent={status => status.description}
						/>
						<DataColumn
							width={5}
							sortable="display_in_overview"
							renderHeaderContent={() => uctrans("flowmailer_status.fields.display_in_overview")}
							renderCellContent={status =>
								status.display_in_overview ? uctrans("general.yes") : uctrans("general.no")
							}
						/>
						<DataColumn
							width={5}
							sortable="display_in_statistics"
							renderHeaderContent={() => uctrans("flowmailer_status.fields.display_in_statistics")}
							renderCellContent={status =>
								status.display_in_statistics ? uctrans("general.yes") : uctrans("general.no")
							}
						/>
						<DataColumn
							width={5}
							sortable="display_in_unique_statistics"
							renderHeaderContent={() => uctrans("flowmailer_status.fields.display_in_unique_statistics")}
							renderCellContent={status =>
								status.display_in_unique_statistics ? uctrans("general.yes") : uctrans("general.no")
							}
						/>
					</DataTable>
				)}
			/>
		</>
	);
}
