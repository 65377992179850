import { useState } from "react";
import Select from "../../../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../../js/react/components/general/AjaxForm";
import Translate from "../../../../../../../js/react/components/general/Translate";
import useInputHandler from "../../../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../../../js/react/components/general/form/LabeledTextInput";
import Nav from "../../../../../../../js/lib/Nav";
import { route } from "../../../../../../../js/helpers";
import useSelectHandler from "../../../../../../../js/react/hooks/useSelectHandler";
import Navigation from "../Navigation";

export default function TownshipForm() {
	const [formData, setFormData] = useState({ ...window.data.township_values });
	const creating = window.data.township_values.id === -1;
	const inputHandler = useInputHandler(setFormData);
	const selectHandler = useSelectHandler(setFormData);

	const onSuccess = () => {
		Nav.go(route("crm.location-settings.townships.index"));
	};

	return (
		<>
			<Navigation selectedTab="townships" />

			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(creating ? "general.add_:item" : "general.edit_:item", {}, { item: "location.townships.singular" })}
				</h1>

				<a className="link" href={route("crm.location-settings.townships.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "location.townships.plural" })}
				</a>
			</div>
			<AjaxForm
				method={creating ? "POST" : "PUT"}
				submitUrl={
					creating
						? route("crm.location-settings.townships.store")
						: route("crm.location-settings.townships.update", formData.id)
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "location.townships.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "location.townships.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="name"
										value={formData.name ? `${formData.name}` : ""}
										onChange={inputHandler}
										label="location.townships.fields.name"
										required
									/>
								</div>
							</div>
							<div className="form-full">
								<div className="form-1-3">
									<label htmlFor="province_id">
										<Translate content="location.provinces.singular" />
									</label>
									<Select
										placeholder={uctrans("general.select_:item", {}, { item: "location.provinces.singular" })}
										value={window.data.provinces.filter(({ value }) => Number(value) === Number(formData.province_id))}
										options={window.data.provinces}
										name="province_id"
										onChange={selectHandler}
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="location.townships.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
