import { useState } from "react";
import Translate from "../../../../../js/react/components/general/Translate";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import { route } from "../../../../../js/helpers";
import RandomCheckForm from "./RandomCheckForm";
import Dialog from "../../../../../js/react/components/general/Dialog";

export default function RandomCheckOverview() {
	const [currentRandomCheck, setCurrentRandomCheck] = useState(null);
	const [overviewDataUpdatedAt, setOverviewDataUpdatedAt] = useState(new Date().getTime());

	const onSuccess = () => {
		setOverviewDataUpdatedAt(new Date().getTime());
		setCurrentRandomCheck(null);
	};

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="random_checks.plural" />
				</h1>
			</div>
			<AbnOverview
				indexUrl={route("crm.random-checks.index")}
				modelTranslations={{
					plural: trans("random_checks.plural"),
					singular: trans("random_checks.singular"),
				}}
				dataUpdatedAt={overviewDataUpdatedAt}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={10}
							renderHeaderContent={() => uctrans("random_checks.fields.name")}
							renderCellContent={randomCheck => (
								<a onClick={() => setCurrentRandomCheck(randomCheck)} className="row-title link">
									{randomCheck.name}
								</a>
							)}
						/>
						<DataColumn
							width={10}
							renderHeaderContent={() => uctrans("random_checks.fields.type")}
							renderCellContent={randomCheck => randomCheck.type}
						/>
						<DataColumn
							width={10}
							renderHeaderContent={() => uctrans("random_checks.fields.amount")}
							renderCellContent={randomCheck => randomCheck.amount}
						/>
					</DataTable>
				)}
			/>
			{currentRandomCheck !== null && (
				<Dialog
					isOpen={currentRandomCheck !== null}
					isFullScreen
					title={uctrans("general.edit_:item", {}, { item: "random_checks.singular" })}
					onClose={() => setCurrentRandomCheck(null)}>
					<div>
						<RandomCheckForm
							randomCheck={currentRandomCheck}
							onSuccess={() => onSuccess()}
							onCancel={() => setCurrentRandomCheck(null)}
						/>
					</div>
				</Dialog>
			)}
		</>
	);
}
