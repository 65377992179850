import classNames from "classnames";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { format, parse, default_date_format } from "../../../../../js/lib/Date";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import Select from "../../../../../js/react/components/general/Select";
import Translate from "../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../js/react/hooks/useGenericHandler";
import AddressForm from "../../../../../js/react/components/addresses/AddressForm";
import MultiSelectAutoComplete from "../../../../../js/react/components/general/autocomplete/MultiSelectAutoComplete";
import TextEditor from "../forms/textEditor/TextEditor";
import Navigation from "./Navigation";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import cloneDeep from "lodash/cloneDeep";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import FormDivider from "../../../../../js/react/components/general/FormDivider";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";

export default function TeamForm(props) {
	const [formData, setFormData] = useState(
		typeof props.teamFormData === "undefined" ? cloneDeep(window.data.team_form_data) : props.teamFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.teamStaticData === "undefined" ? cloneDeep(window.data.team_static_data) : props.teamStaticData,
	);

	const inputHandler = useInputHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);

	const changeProcessTimeValue = (activityId, field, value) => {
		const newFormData = { ...formData };
		newFormData.process_times[activityId][field] = value;
		setFormData(newFormData);
	};

	useEffect(() => {
		if (typeof props.teamFormData !== "undefined") {
			setFormData(props.teamFormData);
		}
	}, [props.teamFormData]);

	useEffect(() => {
		if (typeof props.teamStaticData !== "undefined") {
			setStaticData(props.teamStaticData);
		}
	}, [props.teamStaticData]);

	const onSuccess = response => {
		if (response.data.redirect_route) {
			Nav.go(response.data.redirect_route);
		}
	};
	const getIconByProcessTimeData = processTimeData => (
		<span
			style={{
				height: "16px",
				width: "16px",
				backgroundColor:
					(processTimeData.process_time_type === 1
						? processTimeData.process_time_value
						: processTimeData.process_time_value * 24) >
					(processTimeData.servicelevel_type === 1
						? processTimeData.servicelevel_value
						: processTimeData.servicelevel_value * 24)
						? "#f58a00"
						: "#96C147",
				borderRadius: "50%",
				display: "inline-block",
			}}
		/>
	);

	return (
		<>
			<Navigation selectedTab="teams" />

			<div className="page-title-container">
				<h1 className="page-title">
					{staticData.creating ? uctrans("general.add_:item", {}, { item: "teams.singular" }) : formData.name}
				</h1>
				{staticData.previous_link && (
					<a className="link" href={staticData.previous_link}>
						{uctrans("general.back_to_overview_:page", {}, { page: "teams.plural" })}
					</a>
				)}
			</div>
			<AjaxForm
				method={staticData.save_method}
				submitUrl={staticData.save_link}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "teams.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "teams.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage={staticData.creating}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-1-3">
								<LabeledTextInput
									name="name"
									value={formData.name}
									onChange={inputHandler}
									label="teams.fields.name"
									required
								/>
							</div>
							<div className="form-1-3">
								<LabeledTextInput
									name="team_number"
									value={formData.team_number}
									onChange={inputHandler}
									label="teams.fields.team_number"
								/>
							</div>
						</div>

						<FormDivider label={uctrans("addresses.singular")} />
						<div className="form-container">
							<div className="form-1-3">
								<AddressForm
									addressFormData={formData.address}
									onChange={value => genericHandler(value, "address")}
									useAddition
								/>
							</div>

							<FormDivider label={uctrans("teams.fields.contact")} />

							<div className="form-1-3">
								<LabeledTextInput
									name="email"
									value={formData.email}
									onChange={inputHandler}
									label="teams.fields.email"
								/>
							</div>
							<div className="form-1-3">
								<LabeledTextInput
									name="phonenumber"
									value={formData.phonenumber}
									onChange={inputHandler}
									label="teams.fields.phonenumber"
								/>
							</div>
							<div className="form-1-3">
								<LabeledTextInput
									name="phone_option"
									value={formData.phone_option}
									onChange={inputHandler}
									label="teams.fields.phone_option"
								/>
							</div>
							<div className="form-1-3">
								<LabeledTextInput
									name="ms_bookings_url"
									value={formData.ms_bookings_url}
									onChange={inputHandler}
									label="teams.fields.ms_bookings_url"
								/>
							</div>

							<FormDivider label={uctrans("teams.fields.team_users")} />

							<div className="form-1-3">
								<MultiSelectAutoComplete
									options={staticData.team_users}
									name="users_in_team"
									items={formData.users}
									onChange={selectedUsers => genericHandler(selectedUsers, "users_in_team")}
									placeholder={uctrans("general.type_to_add_:item", {}, { item: "users.singular" })}
									showItemsOnFirstClick
								/>
							</div>

							{!staticData.creating && (
								<>
									<FormDivider label={uctrans("teams.process_times.plural")} />
									<div className="form-1-3">
										<label htmlFor="process_time_date">
											<Translate content="teams.fields.process_time_date" />
										</label>
										<DatePicker
											initialValue={
												formData.process_time_date ? parse(formData.process_time_date, default_date_format) : null
											}
											onChange={date =>
												genericHandler(date ? format(date, default_date_format) : null, "process_time_date")
											}
										/>
									</div>
									<div className="table w-full mb-8">
										<div className="table-row bg-primary">
											<div className="table-cell p-3">
												<span className="text-white">
													<Translate content="teams.process_times.fields.activity" />
												</span>
											</div>
											<div className="table-cell p-3">
												<span className="text-white">
													<Translate content="teams.process_times.fields.process_time" />
												</span>
											</div>
											<div className="table-cell p-3">
												<span className="text-white">
													<Translate content="teams.process_times.fields.servicelevel" />
												</span>
											</div>
										</div>

										{staticData.processTimeActivities.map((activity, index) => (
											<div
												className={classNames("table-row", {
													"bg-grey-lighter": index % 2,
												})}
												key={activity.id}>
												<div className="table-cell p-3">{activity.name}</div>
												<div className="table-cell p-3 ">
													<div className="flex justify-left ">
														<input
															className="w-12 border border-grey rounded p-2 mr-2"
															value={formData.process_times[activity.id].process_time_value}
															onChange={event => {
																changeProcessTimeValue(activity.id, "process_time_value", event.target.value);
															}}
														/>
														<span className="w-full">
															<Select
																value={staticData.processTimeActivityValueTypes.filter(
																	({ value }) =>
																		Number(value) === Number(formData.process_times[activity.id].process_time_type),
																)}
																options={staticData.processTimeActivityValueTypes}
																onChange={option => {
																	changeProcessTimeValue(activity.id, "process_time_type", option.value);
																}}
																isClearable={false}
															/>
														</span>
														<div className="ml-2 mt-4">
															{getIconByProcessTimeData(formData.process_times[activity.id])}
														</div>
													</div>
												</div>
												<div className="table-cell p-3 ">
													<div className="flex">
														<input
															className="w-12 border border-grey rounded p-2 mr-2"
															value={formData.process_times[activity.id].servicelevel_value}
															onChange={event => {
																changeProcessTimeValue(activity.id, "servicelevel_value", event.target.value);
															}}
														/>
														<span className="w-full">
															<Select
																value={staticData.processTimeActivityValueTypes.filter(
																	({ value }) =>
																		Number(value) === Number(formData.process_times[activity.id].servicelevel_type),
																)}
																options={staticData.processTimeActivityValueTypes}
																onChange={option => {
																	changeProcessTimeValue(activity.id, "servicelevel_type", option.value);
																}}
																isClearable={false}
															/>
														</span>
													</div>
												</div>
											</div>
										))}
									</div>
									<div>
										<label htmlFor="status_text">{uctrans("teams.fields.status_text")}</label>
										<TextEditor
											value={formData.status_text}
											onChange={content => genericHandler(content, "status_text")}
										/>
									</div>
								</>
							)}

							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="teams.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
TeamForm.propTypes = {
	teamFormData: PropTypes.object,
	teamStaticData: PropTypes.object,
};
