import PropTypes from "prop-types";
import QuestionnaireCompletionStatisticsContext from "./statistic_display/QuestionnaireCompletionStatisticsContext";
import QuestionStatisticsDisplay from "./statistic_display/QuestionStatisticsDisplay";

export default function QuestionnaireCompletionStatistics({ statisticsData }) {
	return (
		<>
			<QuestionnaireCompletionStatisticsContext.Provider
				value={{
					questionnaire_completions: statisticsData.questionnaire_completions,
					questionnaire_completion_statistics_data: statisticsData.questionnaire_completion_statistics_data,
					question_types: statisticsData.question_types,
					pie_chart_config: statisticsData.pie_chart_config,
					column_chart_config: statisticsData.column_chart_config,
				}}>
				{statisticsData.questionnaire_completion_statistics_data.map((questionData, index) => (
					<div key={index}>
						<QuestionStatisticsDisplay questionData={questionData} />
					</div>
				))}
			</QuestionnaireCompletionStatisticsContext.Provider>

			<div className="mb-20" />
		</>
	);
}

QuestionnaireCompletionStatistics.propTypes = {
	statisticsData: PropTypes.object.isRequired,
};
