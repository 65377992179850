import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";
import { memo } from "react";
import { uctrans } from "../../../../lib/Translator";
import EvaIcon from "../EvaIcon";

function DeleteColumnContent({ onClick }) {
	return (
		<div className="overview-actions">
			<a onClick={onClick}>
				<Tippy placement="left" arrow animation="perspective" duration="400" content={uctrans("general.delete")}>
					<span>
						<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
					</span>
				</Tippy>
			</a>
		</div>
	);
}

export default memo(DeleteColumnContent);

DeleteColumnContent.propTypes = {
	onClick: PropTypes.func.isRequired,
};
