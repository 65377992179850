import map from "lodash/map";
import PropTypes from "prop-types";
import { uctrans } from "../../../../../js/lib/Translator";

export default function CooperationRequestCompletionAnswersList({ answers }) {
	return map(answers, (answer, key) => (
		<div key={key} className="container flex flex-col-reverse md:flex-row">
			<span className="form-full">
				<label>
					<span className="question-title">{uctrans(`cooperation_check.fields.${key}`)}</span>{" "}
				</label>
				<input disabled type="text" name={key} value={answer} />
			</span>
		</div>
	));
}

CooperationRequestCompletionAnswersList.propTypes = {
	answers: PropTypes.object,
};
