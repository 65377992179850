import PropTypes from "prop-types";
import { useState } from "react";
import RSelect from "react-select";
import { uctrans } from "../../../../lib/Translator";

export default function AutoComplete(props) {
	const [value, setValue] = useState("");
	const [searching, setSearching] = useState(false);

	const styles = {
		control: (base, state) => ({
			...base,
			minHeight: 47,
			lineHeight: 2.3,
			borderRadius: 0,
			boxShadow: "none",
			borderColor: state.isSelected ? "#9c9c9c" : "#cccccc" && state.isFocused ? "#9c9c9c" : "#cccccc",
			"&:hover": {
				borderColor: "#9c9c9c",
			},
		}),
		indicatorSeparator: base => ({
			...base,
			display: "none",
		}),
		dropdownIndicator: base => ({
			...base,
			display: "none",
		}),
		menu: base => ({
			...base,
			display: searching || props.showItemsOnFirstClick ? "block" : "none",
		}),
		clearIndicator: base => ({
			...base,
			display: props.isClearable ? "block" : "none",
		}),
		option: (base, state) => ({
			...base,
			color: state.isSelected ? "#ffffff" : "#31353e",
			background: state.isSelected ? "#009286" : "#ffffff" && state.isFocused ? "#D7F0EE" : "#ffffff",
			"&:active": {
				background: "#D7F0EE",
			},
		}),
	};

	const onChange = data => {
		setValue(data);

		if (props.onChange) {
			props.onChange(data);
		}

		if (props.clearOnSelect) {
			setValue(null);
		}
	};

	return (
		<RSelect
			placeholder={props.placeholder}
			options={props.options}
			onInputChange={input => (input.length ? setSearching(true) : setSearching(false))}
			noOptionsMessage={() => uctrans("general.no_results")}
			loadingMessage={() => uctrans("general.loading")}
			onChange={onChange}
			value={value}
			styles={styles}
			isClearable={props.isClearable}
			isDisabled={props.disabled}
		/>
	);
}

AutoComplete.propTypes = {
	placeholder: PropTypes.string,
	options: PropTypes.array,
	onChange: PropTypes.func,
	clearOnSelect: PropTypes.bool,
	isClearable: PropTypes.bool,
	showItemsOnFirstClick: PropTypes.bool,
	disabled: PropTypes.bool,
};

AutoComplete.defaultProps = {
	placeholder: uctrans("general.choose"),
	clearOnSelect: false,
	showItemsOnFirstClick: false,
};
