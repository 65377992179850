import { trans } from "@/lib/Translator";
import CompanyStatusLabel from "@crm/react/components/company/CompanyStatusLabel";
import PropTypes from "prop-types";

export default function EstablishmentList({ establishments, currentCompanyId }) {
	return establishments.map(establishment => (
		<div key={establishment.id} className="flex w-full justify-start">
			<div className="flex w-full justify-start">
				<span>
					{establishment.establishment_type}{" "}
					{establishment.id === currentCompanyId ? (
						<span className="text-sm font-italic">({trans("companies.statutory.current")}) - </span>
					) : (
						" - "
					)}
					<CompanyStatusLabel showExplanationLabels link={establishment.url} label={establishment.name} />
				</span>
			</div>
		</div>
	));
}

EstablishmentList.propTypes = {
	establishments: PropTypes.array,
	current: PropTypes.number,
};
