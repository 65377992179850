import axios from "axios";
import { useCallback, useState } from "react";
import { route } from "../../../../../../../js/helpers";
import { uctrans } from "../../../../../../../js/lib/Translator";
import { failure } from "../../../../../../../js/react/components/general/notifications";
import Select from "../../../../../../../js/react/components/general/Select";
import Translate from "../../../../../../../js/react/components/general/Translate";
import Navigation from "../Navigation";
import NewsletterStatisticsEventColumnChart from "./NewsletterStatisticsEventColumnChart";
import NewsletterStatisticsEventTimelineChart from "./NewsletterStatisticsEventTimelineChart";
import map from "lodash/map";
import find from "lodash/find";

export default function NewsletterStatistics() {
	const newsletter = { ...window.data.newsletter };
	const columnChartConfig = { ...window.data.column_chart_config };

	const [eventCounts, setEventCounts] = useState({ ...window.data.event_counts });
	const [openEventsPerDate, setOpenEventsPerDate] = useState({ ...window.data.open_events_per_date });
	const [uniqueOpenEventsPerDate, setUniqueOpenEventsPerDate] = useState({
		...window.data.unique_open_events_per_date,
	});

	const [mailingId, setMailingId] = useState(null);
	const mailings = { ...window.data.mailings };

	const isWebmail = newsletter => newsletter.site_display && !newsletter.mail_display;

	const fetchStatistics = useCallback(
		async mailingId => {
			try {
				setMailingId(mailingId);

				const { data } = await axios.get(
					route("crm.communication.newsletters.statistics", {
						newsletter: newsletter.id,
						newsletterMailing: mailingId,
					}),
				);

				setEventCounts(data.event_counts);
				setOpenEventsPerDate(data.open_events_per_date);
				setUniqueOpenEventsPerDate(data.unique_open_events_per_date);
			} catch (error) {
				console.error(error);
				failure(uctrans("communication.newsletters.statistics_could_not_be_fetched"));
			}
		},
		[newsletter.id],
	);

	return (
		<>
			<Navigation selectedTab="statistics" newsletter={newsletter} />

			<div className="form-full">
				<label htmlFor="newsletter_mailing">
					<Translate content="communication.newsletters.filter_newsletter_mailing" />{" "}
				</label>
				<div className="form-1-3">
					<Select
						onChange={({ value }) => fetchStatistics(value)}
						value={find(mailings, mailing => mailing.value === mailingId)}
						options={map(mailings, mailing => ({ value: mailing.value, label: mailing.label }))}
						isClearable={false}
					/>
				</div>
			</div>

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("communication.newsletters.statistics")}</h1>
			</div>
			<h3>Aantal verzonden en aantal events</h3>

			<NewsletterStatisticsEventColumnChart config={columnChartConfig} event_counts={eventCounts} />
			{!isWebmail(newsletter) && (
				<>
					<h3>Opens per datum</h3>
					<NewsletterStatisticsEventTimelineChart
						open_events_per_date={openEventsPerDate}
						unique_open_events_per_date={uniqueOpenEventsPerDate}
					/>
				</>
			)}
		</>
	);
}
