import find from "lodash/find";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import Radio from "../../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../../js/react/components/general/form/radio/RadioGroup";
import { CmsTypesContext } from "../../cms-types-context";
import Text from "../attributes/Text";
import Select from "../attributes/Select";
import PartList from "../part-lists/PartList";
import ClearablePart from "./ClearablePart";
import PageHeadAnimation from "./PageHeadAnimation";
import PageHeadVideo from "./PageHeadVideo";
import Part from "./Part";

export default function HomepageHead(props) {
	const [values, setValues] = useState(props.values ? props.values : {});
	useEffect(() => {
		setValues(props.values ? props.values : "");
	}, [props.values]);
	const handleValuesChange = newValues => {
		setValues(newValues);
		props.onChange(newValues);
	};
	const handleValueChange = (name, newValue) => {
		const newValues = { ...values };
		newValues[name] = newValue;
		handleValuesChange(newValues);
	};

	const { partTypes } = useContext(CmsTypesContext);
	const partType = find(partTypes, partType => partType.key === "homepage_head");

	const [headerType, setHeaderType] = useState(values.video ? 1 : values.animation ? 2 : 0);

	const changeHeaderType = value => {
		const newValues = { ...values };

		if (value === 0) {
			setHeaderType(0);
			newValues.video = null;
			newValues.animation = null;
		} else if (value === 1) {
			setHeaderType(1);
			newValues.image = null;
			newValues.animation = null;
			newValues.mobile_image = null;
			newValues.use_head_text = null;
		} else {
			setHeaderType(2);
			newValues.image = null;
			newValues.mobile_image = null;
			newValues.video = null;
		}

		handleValuesChange(newValues);
	};

	const headerTypeRenderer = () => {
		switch (headerType) {
			case 0:
				return (
					<>
						<Text
							label={partType.attributes.title.label}
							value={values.title}
							onChange={value => {
								handleValueChange("title", value);
							}}
						/>
						<Select
							label={partType.attributes.text_align_type.label}
							value={values.text_align_type}
							options={partType.attributes.text_align_type.options}
							onChange={value => {
								handleValueChange("text_align_type", value);
							}}
						/>
						<Part
							label={uctrans("cms.image_full_with_header")}
							parttype="image"
							partTypes={partTypes}
							values={values.image}
							onChange={value => {
								handleValueChange("image", value);
							}}
						/>
						<Part
							label={uctrans("cms.image_mobile")}
							parttype="image"
							partTypes={partTypes}
							values={values.mobile_image}
							onChange={value => {
								handleValueChange("mobile_image", value);
							}}
						/>
						<i className="text-sm italic">{uctrans("cms.image_mobile_description")}</i>
						<ClearablePart
							parttype="link"
							partTypes={partTypes}
							label={partType.attributes.link.label}
							values={values.link}
							options={partType.attributes.link.options}
							onChange={value => {
								handleValueChange("link", value);
							}}
						/>
					</>
				);
			case 1:
				return <PageHeadVideo values={values} handleValueChange={handleValueChange} />;
			case 2:
				return (
					<>
						<PageHeadAnimation values={values} handleValueChange={handleValueChange} />
						<Text
							label={partType.attributes.title.label}
							value={values.title}
							onChange={value => {
								handleValueChange("title", value);
							}}
						/>
					</>
				);
		}
	};

	if (partType) {
		return (
			<>
				<div className="mt-5 mb-12">
					<h5>{props.label}</h5>

					<RadioGroup name="use_head_type" value={headerType} onChange={value => changeHeaderType(value)}>
						<Radio label={uctrans("cms.attributes.use_head_image")} value={0} />
						<Radio label={uctrans("cms.attributes.use_head_video")} value={1} />
						<Radio label={uctrans("cms.attributes.use_head_animation")} value={2} />
					</RadioGroup>

					<div className="pl-6 mt-3  border-l border-grey-light ">{headerTypeRenderer()}</div>
				</div>
				<div className="mt-5 mb-12">
					<h5>{uctrans("cms.attributes.header_column_block")}</h5>
					<div className="pl-6 mt-3  border-l border-grey-light ">
						<div className="mb-3">
							<PartList
								label={partType.attributes.columns.label}
								listPartType={partType.attributes.columns.parttype}
								values={values.columns}
								onChange={value => {
									handleValueChange("columns", value);
								}}
							/>
						</div>
						<Part
							partTypes={partTypes}
							label={partType.attributes.usp.label}
							parttype={partType.attributes.usp.parttype}
							values={values.usp}
							onChange={value => {
								handleValueChange("usp", value);
							}}
						/>
					</div>
				</div>
			</>
		);
	}
}

HomepageHead.propTypes = {
	label: PropTypes.string,
	values: PropTypes.shape({
		title: PropTypes.string,
		text_align_type: PropTypes.string,
		image: PropTypes.object,
		link: PropTypes.object,
		columns: PropTypes.array,
		usp: PropTypes.object,
	}),
	onChange: PropTypes.func.isRequired,
};
