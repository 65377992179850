import Tippy from "@tippyjs/react";
import { lazy, useState, Suspense } from "react";
import { can, route } from "../../../../../../js/helpers";
import { format } from "../../../../../../js/lib/Date";
import Nav from "../../../../../../js/lib/Nav";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import Dialog from "../../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import SelectFilter from "../../../../../../js/react/components/general/overview/SelectFilter";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import Translate from "../../../../../../js/react/components/general/Translate";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../../js/react/components/general/overview/SearchBarFilter";

const Navigation = lazy(
	async () => import(window.data.communication_type === 1 ? "./Navigation" : "../automatedMessages/Navigation"),
);

export default function NewsletterCommunicationEventOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});
	const staticData = { ...window.data.static_data };
	const [currentWebviewCommunicationLog, setCurrentWebviewCommunicationLog] = useState(null);

	return (
		<>
			<Suspense
				fallback={
					<div className="text-left flex-grow mt-2">
						<Spinner width={25} />
					</div>
				}>
				{window.data.communication_type === 1 ? (
					<Navigation selectedTab="events" newsletter={staticData.newsletter} />
				) : (
					<Navigation selectedTab="events" automatedMessage={staticData.newsletter} />
				)}
			</Suspense>

			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="communication.events.plural" />
				</h1>
			</div>
			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.communication.newsletters.communication-events", staticData.newsletter.id)}
				modelTranslations={{
					plural: trans("communication.events.plural"),
					singular: trans("communication.events.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" placeholder={uctrans("communication.search_for_email")} />

						<SelectFilter
							className="form-1-3"
							name="receiver_type"
							label={uctrans("communication.communication_log.fields.receiver_type")}
							options={overviewData.overview.metadata.filterdata.receiver_types}
						/>
						<SelectFilter
							className="form-1-3"
							name="flowmailer_event_type_id"
							label={uctrans("communication.events.fields.flowmailer_event_type_id")}
							options={overviewData.overview.metadata.filterdata.event_types}
						/>
						{overviewData.overview.metadata.filterdata.mailings.length && (
							<SelectFilter
								className="form-1-3"
								name="mailing"
								label={uctrans("communication.communication_log.fields.mailing")}
								options={overviewData.overview.metadata.filterdata.mailings}
							/>
						)}
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("communication.events.fields.datetime")}
							renderCellContent={event => (event.when ? format(event.when, "dd-MM-y HH:mm") : null)}
							sortable="when"
						/>

						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("communication.events.fields.flowmailer_event_type_id")}
							renderCellContent={event => (
								<div>
									{event.flowmailer_event_type_id && event.event_type_hint ? (
										<Tippy placement="top" arrow animation="perspective" duration="400" content={event.event_type_hint}>
											<span>
												{event.flowmailer_event_type_id}
												<EvaIcon type="info" fill="#249286" height="16" width="16" />
											</span>
										</Tippy>
									) : (
										"-"
									)}
								</div>
							)}
							sortable="flowmailer_event_type_id"
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("communication.newsletter_mailings.singular")}
							renderCellContent={event => (event.log && event.log.mailing ? event.log.mailing : "-")}
							sortable="mailing"
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("communication.communication_log.fields.receiver_type")}
							renderCellContent={event => (event.log && event.log.receiver_type ? event.log.receiver_type : "-")}
							sortable="object_type"
						/>
						<DataColumn
							width={5}
							renderHeaderContent={() => uctrans("communication.communication_log.fields.email")}
							renderCellContent={event => (event.log && event.log.email_address ? event.log.email_address : "-")}
							sortable="email_address"
						/>
						<DataColumn
							width={1}
							renderHeaderContent={() => ""}
							renderCellContent={event => (
								<div className="flex">
									{can("communication.communication_log", "view_all") && (
										<a
											rel="noopener noreferrer"
											className="mr-2"
											onClick={() => {
												setCurrentWebviewCommunicationLog(event.log);
											}}
											target="_blank">
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("communication.automated_messages.view_on_website")}>
												<span>
													<EvaIcon type="eye-outline" fill="#249286" height="18" width="18" />
												</span>
											</Tippy>
										</a>
									)}
								</div>
							)}
						/>
					</DataTable>
				)}
				renderFooter={overview => (
					<a
						className="button button-primary mr-1"
						onClick={() =>
							Nav.go(
								route(`crm.communication.newsletters.communication-events.export`, {
									...overview.getParameters(),
									communication_id: staticData.newsletter.id,
								}),
							)
						}>
						{uctrans("communication.newsletters.export-events")}
					</a>
				)}
			/>
			<Dialog
				isOpen={!!currentWebviewCommunicationLog}
				onClose={() => {
					setCurrentWebviewCommunicationLog(null);
				}}
				shouldCloseOnOverlayClick
				width={850}>
				{!!currentWebviewCommunicationLog && (
					<iframe
						id="mail_iframe"
						width="100%"
						height="500px"
						src={route("crm.communication-log.webview", currentWebviewCommunicationLog.id_token)}
					/>
				)}
			</Dialog>
		</>
	);
}
