import PropTypes from "prop-types";
import { format, parse } from "../../../../../js/lib/Date";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../js/lib/Translator";
import cloneDeep from "lodash/cloneDeep";
import { useState } from "react";
import Select from "../../../../../js/react/components/general/Select";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import TextEditor from "../forms/textEditor/TextEditor";
import CollapsibleContent from "../general/CollapsibleContent";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import Translate from "../../../../../js/react/components/general/Translate";
import { route } from "../../../../../js/helpers";

export default function QuestionnaireDetailForm(props) {
	const readOnly = window.data.read_only;

	const [formData, setFormData] = useState(
		typeof props.questionnaireFormData === "undefined"
			? cloneDeep(window.data.questionnaire_detail_form_data)
			: props.questionnaireFormData,
	);

	const staticData = window.data.questionnaire_static_data;

	const handleFormChange = (attributeKey, values) => {
		const newFormData = { ...formData };
		newFormData[attributeKey] = values;
		setFormData(newFormData);
	};

	const handleOnSuccess = response => {
		if (staticData.creating && response && response.data && response.data.success) {
			window.location.replace(response.data.redirect_route);
		}
	};

	const getSubmitUrl = () => {
		if (staticData.creating) {
			return route("crm.questionnaires.store");
		}
		return route("crm.questionnaires.update", { questionnaire: formData.id });
	};

	return (
		<AjaxForm
			method={staticData.creating ? "POST" : "PUT"}
			submitUrl={getSubmitUrl()}
			loaderText={uctrans("general.:item_is_being_saved", {}, { item: "questionnaires.singular" })}
			successText={uctrans("general.saved_:item", {}, { item: "questionnaires.singular" })}
			onSuccess={handleOnSuccess}
			data={{ ...formData }}
			useFlashMessage={staticData.creating}>
			<div className="grid-container">
				<div className="grid-3-4">
					<div className="min-h-screen md:flex">
						<div className="flex-1 w-3/4 mr-12">
							<div className="form-container">
								<div className="form-1-2">
									<LabeledTextInput
										disabled={readOnly}
										name="name"
										value={formData.name}
										onChange={event => handleFormChange(event.target.name, event.target.value)}
										label="questionnaires.fields.name"
										required
									/>
								</div>

								<div className="form-1-2">
									<label htmlFor="creator_id">
										<Translate content="questionnaires.fields.creator_id" />
									</label>
									<Select
										disabled={readOnly}
										placeholder={uctrans("general.select_:item", {}, { item: "questionnaires.fields.creator_id" })}
										value={staticData.creators.filter(({ value }) => Number(value) === Number(formData.creator_id))}
										options={staticData.creators}
										name="creator_id"
										onChange={(selectedValue, metaData) => handleFormChange(metaData.name, selectedValue.value)}
										isClearable={false}
									/>
								</div>

								<div className="form-full">
									<label>
										<Translate content="questionnaires.fields.description" />
									</label>
									<TextEditor
										disabled={readOnly}
										value={formData.description}
										onChange={selectedValue => handleFormChange("description", selectedValue)}
									/>
								</div>

								<div className="form-full">
									<h4 className="inline-block">{uctrans("questionnaires.fields.start_button_text")}</h4>
									<input
										type="text"
										value={formData.start_button_text}
										onChange={e => handleFormChange("start_button_text", e.target.value)}
									/>
								</div>

								<div className="form-full">
									<CollapsibleContent initialCollapseState={false} title={uctrans("questionnaires.availability")}>
										<div className="form-1-3">
											<div className="form-full">
												<div className="form-1-2">
													<label htmlFor="active">
														<Translate content="questionnaires.fields.active" />
													</label>
													<RadioGroup
														name="active"
														value={formData.active || 0}
														onChange={selectedValue => handleFormChange("active", selectedValue)}
														disabled={!staticData.permissions.update}>
														<Radio label={uctrans("general.yes")} value={1} />
														<Radio label={uctrans("general.no")} value={0} />
													</RadioGroup>
												</div>
												<div className="form-1-2">
													<label htmlFor="identification_needed">
														<Translate content="questionnaires.fields.identification_needed" />
													</label>
													<RadioGroup
														name="identification_needed"
														value={formData.identification_needed || 0}
														onChange={selectedValue => handleFormChange("identification_needed", selectedValue)}
														disabled={!staticData.permissions.update}>
														<Radio label={uctrans("general.yes")} value={1} />
														<Radio label={uctrans("general.no")} value={0} />
													</RadioGroup>
												</div>
											</div>
										</div>
										<div className="form-1-3">
											<label htmlFor="start_date_time">
												<Translate content="questionnaires.fields.start_date_time" />
											</label>
											<DatePicker
												disabled={readOnly}
												initialValue={formData.start_date_time ? parse(formData.start_date_time) : null}
												onChange={date => handleFormChange("start_date_time", date ? format(date) : null)}
											/>
										</div>
										<div className="form-1-3">
											<label htmlFor="end_date_time">
												<Translate content="questionnaires.fields.end_date_time" />
											</label>
											<DatePicker
												disabled={readOnly}
												initialValue={formData.end_date_time ? parse(formData.end_date_time) : null}
												onChange={date => handleFormChange("end_date_time", date ? format(date) : null)}
											/>
										</div>
									</CollapsibleContent>
								</div>
							</div>

							{!readOnly && (
								<div className="form-container">
									<div className="row">
										<div className="w-full md:w-1/2 lg:w-1/3 column">
											<SubmitBar item="questionnaires.singular" addAnotherAvailable={false} />
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="flex-none w-1/4 border-l pl-12">
							{!staticData.creating && (
								<>
									{" "}
									<div className="form-full">
										<label>{uctrans("questionnaires.fields.name")}</label>
										<span>{formData.name}</span>
									</div>
									<div className="form-full">
										<a
											className="button button-primary"
											href={
												staticData.cooperation_check ? route("website.cooperation-request") : formData.front_end_url
											}
											target="_blank"
											rel="noopener noreferrer">
											{uctrans("questionnaires.questionnaire_front_url")}
										</a>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</AjaxForm>
	);
}

QuestionnaireDetailForm.propTypes = {
	questionnaireFormData: PropTypes.object,
};
