import find from "lodash/find";
import map from "lodash/map";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { CmsTypesContext } from "../../cms-types-context";
import Attribute from "../attributes/Attribute";
import ClickRemember from "./ClickRemember";
import PromoGroup from "./PromoGroup";

export default function DashboardBlock(props) {
	const [values, setValues] = useState(props.values ? props.values : {});
	useEffect(() => {
		setValues(props.values ? props.values : "");
	}, [props.values]);
	const handleValuesChange = newValues => {
		setValues(newValues);
		props.onChange(newValues);
	};
	const handleValueChange = (name, newValue) => {
		const newValues = { ...values };
		newValues[name] = newValue;
		handleValuesChange(newValues);
	};

	const { partTypes } = useContext(CmsTypesContext);
	const partType = find(partTypes, partType => partType.key === "dashboard_block");

	if (partType) {
		return (
			<div className="mt-5">
				{props.label && <h5>{props.label}</h5>}
				<div>
					{(() =>
						map(partType.attributes, (partAttribute, partAttributeKey) => {
							const subValues =
								values != null && Object.prototype.hasOwnProperty.call(values, partAttributeKey)
									? values[partAttributeKey]
									: null;
							if (partAttributeKey === "click_remember" && values.type !== "custom") {
								return <span key={partAttributeKey} />;
							}
							if (partAttributeKey === "parts" && values.type !== "custom" && values.type !== "client_leads") {
								return <span key={partAttributeKey} />;
							}
							if (partAttributeKey === "click_remember") {
								return (
									<ClickRemember
										key={partAttributeKey}
										values={values[partAttributeKey]}
										onChange={value => {
											handleValueChange(partAttributeKey, value);
										}}
									/>
								);
							}
							if (partAttributeKey === "promo_group") {
								return (
									<PromoGroup
										key={partAttributeKey}
										values={values[partAttributeKey]}
										onChange={value => {
											handleValueChange(partAttributeKey, value);
										}}
									/>
								);
							}
							return (
								<Attribute
									key={partAttributeKey}
									attribute={partAttribute}
									values={subValues}
									onChange={values => {
										handleValueChange(partAttributeKey, values);
									}}
								/>
							);
						}))()}
				</div>
			</div>
		);
	}
}

DashboardBlock.propTypes = {
	label: PropTypes.string,
	values: PropTypes.shape({
		type: PropTypes.string,
		title: PropTypes.string,
	}),
	onChange: PropTypes.func.isRequired,
};
