import Tippy from "@tippyjs/react";
import { useEffect, useState } from "react";
import { format, parse } from "../../../../../js/lib/Date";
import SingleSelectAutoComplete from "../../../../../js/react/components/general/autocomplete/SingleSelectAutoComplete";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import DebouncedInput from "../../../../../js/react/components/general/form/DebouncedInput";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Translate from "../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../js/react/hooks/useGenericHandler";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import Nav from "../../../../../js/lib/Nav";
import { route } from "../../../../../js/helpers";
import FileInput from "../forms/FileInput";

export default function ClientConsentCreateForm() {
	const [formData, setFormData] = useState({ ...window.data.client_consent.form_data });
	const minCSDate = window.data.client_consent && new Date(window.data.client_consent.min_date);
	const inputHandler = useInputHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);

	const [showLoanLengthMessage, setShowLoanLengthMessage] = useState(false);

	const onSuccess = response => {
		Nav.go(route("crm.client-consents.edit", response.data.id));
	};

	useEffect(() => {
		if (formData.loan_number !== "" && formData.loan_number !== null) {
			if (formData.loan_number.length < 8 || formData.loan_number.length > 9) {
				setShowLoanLengthMessage(true);
			} else {
				setShowLoanLengthMessage(false);
			}
		} else {
			setShowLoanLengthMessage(false);
		}
	}, [formData.loan_number]);

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("general.add_:item", {}, { item: "client_consents.singular" })}</h1>

				<a className="link" href={route("crm.client-consents.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "client_consents.plural" })}
				</a>
			</div>
			<AjaxForm
				method="POST"
				submitUrl={route("crm.client-consents.store")}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "client_consents.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "client_consents.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="form-container">
					<div className="form-1-2">
						<label htmlFor="user_id">
							<Translate content="companies.singular" /> <span className="text-orange">*</span>
						</label>
						<SingleSelectAutoComplete
							name="company_id"
							dataSource={route("crm.companies.search-active").toString()}
							item={formData.company_id}
							onChange={value => genericHandler(value ? value.value : null, "company_id")}
							placeholder={uctrans("general.type_to_add_:item", {}, { item: "companies.singular" })}
							async
						/>
					</div>
				</div>
				<div className="form-container">
					<div className="form-1-2">
						<label htmlFor="loan_number">
							<Translate content="client_consents.fields.loan_number" /> <span className="text-orange">*</span>
						</label>
						<DebouncedInput
							type="number"
							name="loan_number"
							autocomplete={false}
							placeholder={uctrans("client_consents.loan_number_placeholder")}
							value={formData.loan_number ? formData.loan_number : ""}
							onChange={inputHandler}
						/>
						{showLoanLengthMessage && (
							<span className="italic mt-1 text-red">{uctrans("client_consents.loan_length_message")}</span>
						)}
					</div>
				</div>
				<div className="form-container mb-3">
					<div className="form-1-2">
						<label htmlFor="file">
							{uctrans("client_consents.fields.declaration")}
							<span className="text-orange"> *</span>
						</label>
						<FileInput maxFiles={1} onChange={files => genericHandler(files, "declaration")} />
					</div>
				</div>

				<div className="form-container mb-3">
					<div className="form-1-4">
						<label htmlFor="starts_at">
							{uctrans("client_consents.fields.start_date_consent")}
							<span className="text-orange"> *</span>
							<Tippy
								arrow
								animation="perspective"
								duration="400"
								content={uctrans("client_consents.start_date_content_explain")}>
								<span className="ml-2">
									<EvaIcon type="info" fill="#249286" height="19" width="19" />
								</span>
							</Tippy>
						</label>
						<DatePicker
							initialValue={formData.starts_at ? parse(formData.starts_at) : null}
							onChange={date => genericHandler(date ? format(date) : null, "starts_at")}
							maxDate={new Date()}
							minDate={minCSDate}
						/>
					</div>
				</div>

				<div className="form-full">
					<div className="row">
						<div className="w-full md:w-1/2 lg:w-1/3 column">
							<SubmitBar
								translateLabel
								item="client_consents.singular"
								addAnotherAvailable={false}
								submitDisabled={formData.loan_number === "" || formData.loan_number === null || showLoanLengthMessage}
							/>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
