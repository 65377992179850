import { useState, useMemo } from "react";
import { format } from "../../../../../js/lib/Date";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import { can, route } from "../../../../../js/helpers";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";
import Tippy from "@tippyjs/react";

export default function CompanyAlertOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	const { intermediary } = window.data;

	const indexRoute = useMemo(
		() =>
			intermediary
				? route("crm.intermediaries.company-alerts.index", window.data.company_id)
				: route("crm.organisations.company-alerts.index", window.data.company_id),
		[intermediary],
	);

	const createRoute = useMemo(
		() =>
			intermediary
				? route("crm.intermediaries.company-alerts.create", window.data.company_id)
				: route("crm.organisations.company-alerts.create", window.data.company_id),
		[intermediary],
	);

	const deleteRoute = companyalertId =>
		intermediary
			? route("crm.intermediaries.company-alerts.delete", {
					intermediary: window.data.company_id,
					company_alert: companyalertId,
			  })
			: route("crm.organisations.company-alerts.delete", {
					organisation: window.data.company_id,
					company_alert: companyalertId,
			  });

	const editRoute = companyalertId =>
		intermediary
			? route("crm.intermediaries.company-alerts.edit", {
					company_alert: companyalertId,
					intermediary: window.data.company_id,
			  })
			: route("crm.organisations.company-alerts.edit", {
					company_alert: companyalertId,
					organisation: window.data.company_id,
			  });

	const showRoute = companyalertId =>
		intermediary
			? route("crm.intermediaries.company-alerts.show", {
					company_alert: companyalertId,
					intermediary: window.data.company_id,
			  })
			: route("crm.organisations.company-alerts.show", {
					company_alert: companyalertId,
					organisation: window.data.company_id,
			  });

	return (
		<>
			{window.data.nav_data && (
				<>
					<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
					<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
				</>
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.company_name
						? uctrans(
								"general.:item1 from :item2",
								{ item2: window.data.company_name },
								{ item1: "company_alerts.plural" },
						  )
						: uctrans("company_alerts.plural")}
				</h1>
				{can("company_alert.company_alert", "create") && (
					<div>
						<IconButton
							href={createRoute}
							content={uctrans("general.add_:item", {}, { item: "company_alerts.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={indexRoute}
				modelTranslations={{ plural: trans("company_alerts.plural"), singular: trans("company_alerts.singular") }}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
						<SelectFilter
							className="form-1-3"
							name="active"
							label={uctrans("company_alerts.fields.active")}
							options={[
								{ value: "", label: uctrans("overview.show_all") },
								{ value: "1", label: uctrans("general.yes") },
								{ value: "0", label: uctrans("general.no") },
							]}
							collapsible
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={4}
							sortable="created_at"
							renderHeaderContent={() => uctrans("company_alerts.fields.created_at")}
							renderCellContent={companyalert =>
								companyalert.created_at ? format(companyalert.created_at, "dd-MM-y HH:mm") : "-"
							}
						/>
						<DataColumn
							width={4}
							renderHeaderContent={() => uctrans("company_alerts.fields.user")}
							renderCellContent={companyalert => (companyalert.user ? companyalert.user.label : "-")}
						/>
						<DataColumn
							width={6}
							renderHeaderContent={() => uctrans("company_alerts.fields.title")}
							sortable="title"
							renderCellContent={companyalert => companyalert.title}
						/>
						<DataColumn
							width={8}
							renderHeaderContent={() => uctrans("company_alerts.fields.content")}
							renderCellContent={companyalert => (
								<Tippy
									arrow
									interactive
									content={
										<span
											dangerouslySetInnerHTML={{
												__html: `<div class="company_alert_content">${companyalert.content}</div>`,
											}}></span>
									}>
									<span className="border-b border-dotted">{companyalert.content_overview}</span>
								</Tippy>
							)}
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => ""}
							renderCellContent={(companyalert, overview) => (
								<div className="overview-actions">
									{can("company_alert.company_alert", "update") ? (
										<a href={editRoute(companyalert.id)}>
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("general.edit")}>
												<span>
													<EvaIcon type="edit-outline" fill="#009286" height="20" width="20" />
												</span>
											</Tippy>
										</a>
									) : can("company_alert.company_alert", "view") ? (
										<a href={showRoute(companyalert.id)}>
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("general.show")}>
												<span>
													<EvaIcon type="eye-outline" fill="#009286" height="20" width="20" />
												</span>
											</Tippy>
										</a>
									) : null}
									{can("company_alert.company_alert", "delete") && (
										<a
											onClick={() => {
												overview.askDelete(companyalert, deleteRoute(companyalert.id));
											}}>
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("general.delete")}>
												<span>
													<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
												</span>
											</Tippy>
										</a>
									)}
								</div>
							)}
						/>
					</DataTable>
				)}
			/>
		</>
	);
}
