import cloneDeep from "lodash/cloneDeep";
import { useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import QuestionnaireQuestionForm from "../../questionnaires/QuestionnaireQuestionForm";
import RelationManagerQuestionnaireTabs from "./RelationManagerQuestionnaireTabs";

export default function RelationManagerSurveyQuestionnaireForm() {
	const [formData] = useState(cloneDeep(window.data.questionnaire_question_form_data));
	const staticData = window.data.questionnaire_static_data;

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("survey.plural") }]} />
			<RelationManagerQuestionnaireTabs initialActiveTabIndex={0} />

			<div className="page-title-container">
				<h1 className="page-title">{formData.name}</h1>
			</div>

			<QuestionnaireQuestionForm
				questionnaireFormData={formData}
				questionnaireStaticData={staticData}
				changeDescription
				hideUrlButton
			/>
		</>
	);
}
