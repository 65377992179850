import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import find from "lodash/find";
import CompanyDossierContext from "./CompanyDossierContext";
import DossierPart from "./parts/DossierPart";

export default function CompanyDossier(props) {
	const { formData, staticData, onDossierChange, onDirectorChange, readOnly, statutory } = props;

	const [activeDossierDirectors, setActiveDossierDirectors] = useState(statutory ? formData.dossier_directors : []);
	const [conflictingDossierDirectors] = useState(statutory ? formData.conflicting_dossier_directors : []);
	const [activeDossierParts, setActiveDossierParts] = useState(
		statutory ? formData.statutory_dossier_completions : formData.dossier_completions,
	);
	const dossierPartTypes = staticData.dossier_part_types;
	const availableDossierParts = statutory
		? staticData.available_statutory_dossier_parts
		: staticData.available_establishment_dossier_parts;

	const handleDossierChange = useCallback(
		dossierParts => {
			onDossierChange(dossierParts);
			setActiveDossierParts(dossierParts);
		},
		[onDossierChange],
	);

	const handleDirectorChange = useCallback(
		dossierDirectors => {
			onDirectorChange(dossierDirectors);
			setActiveDossierDirectors(dossierDirectors);
		},
		[onDirectorChange],
	);

	const getPartType = useCallback(
		type => find(dossierPartTypes, partType => Number(partType.key) === Number(type)),
		[dossierPartTypes],
	);

	const getCompanyDossierValue = useCallback(
		name => {
			const value = find(formData.company_values, (value, key) => key.toString() === name.toString());

			return typeof value === "undefined" ? "" : value;
		},
		[formData.company_values],
	);

	const selectPart = useCallback(
		id => {
			const key = activeDossierParts.findIndex(dossierPartId => Number(dossierPartId.dossier_part_id) === Number(id));

			if (Number(key) !== Number(-1)) {
				const newActiveDossierParts = [...activeDossierParts];
				const key = newActiveDossierParts.findIndex(
					dossierPartId => Number(dossierPartId.dossier_part_id) === Number(id),
				);
				newActiveDossierParts[key] = {
					...newActiveDossierParts[key],
					selected: true,
				};
				handleDossierChange(newActiveDossierParts);
			} else {
				const newActiveDossierParts = [...activeDossierParts];

				const parttype = find(availableDossierParts, partType => Number(partType.id) === Number(id));

				let field_value = "";

				if (typeof parttype !== "undefined") {
					switch (Number(parttype.type)) {
						case dossierPartTypes.EARCHIVE.key:
							field_value = getCompanyDossierValue("bc_number");
							break;
						case dossierPartTypes.IBAN.key:
							field_value = getCompanyDossierValue("iban");
							break;
						case dossierPartTypes.KVK.key:
							field_value = getCompanyDossierValue("kvk_number");
							break;
					}
				}

				const newActiveDossierPart = {
					dossier_part_id: id,
					selected: true,
					field_value,
					files: [],
				};

				newActiveDossierParts.push(newActiveDossierPart);

				handleDossierChange(newActiveDossierParts);
			}
		},
		[activeDossierParts, availableDossierParts, dossierPartTypes, getCompanyDossierValue, handleDossierChange],
	);

	const unSelectPart = useCallback(
		id => {
			const newActiveDossierParts = [...activeDossierParts];
			const key = newActiveDossierParts.findIndex(
				dossierPartId => Number(dossierPartId.dossier_part_id) === Number(id),
			);
			newActiveDossierParts[key] = {
				...newActiveDossierParts[key],
				selected: false,
			};
			handleDossierChange(newActiveDossierParts);
		},
		[activeDossierParts, handleDossierChange],
	);

	const isPartSelected = useCallback(
		id =>
			activeDossierParts.some(activeDossierPart => {
				if (Number(activeDossierPart.dossier_part_id) === Number(id)) {
					return activeDossierPart.selected;
				}
				return false;
			}),
		[activeDossierParts],
	);

	const handleFormChange = useCallback(
		(id, field_name, value) => {
			const newActiveDossierParts = [...activeDossierParts];
			const key = newActiveDossierParts.findIndex(
				dossierPartId => Number(dossierPartId.dossier_part_id) === Number(id),
			);
			newActiveDossierParts[key] = {
				...newActiveDossierParts[key],
				[field_name]: value,
			};
			handleDossierChange(newActiveDossierParts);
		},
		[activeDossierParts, handleDossierChange],
	);

	return (
		<CompanyDossierContext.Provider
			value={{
				companyId: formData.id,
				mainCompany: staticData.company_data ? staticData.company_data.is_main_company : false,
				readOnly,
				statutory,
				badPressCheckCompanyUrl: staticData.bad_press_check_url_company,
				badPressCheckCompanyDirectorsUrl: staticData.bad_press_check_url_company_directors,
				badPressCheckStatutoryCompanyUrl: staticData.bad_press_check_url_statutory_company,
				badPressCheckStatutoryCompanyDirectorsUrl: staticData.bad_press_check_url_statutory_company_with_directors,
				isIntermediary: staticData.intermediary,
				isOrganisation: staticData.organisation,
				availableDossierParts,
				activeDossierParts,
				activeDossierDirectors,
				setActiveDossierDirectors,
				conflictingDossierDirectors,
				dossierPartTypes,
				eArchiveUrl: staticData.earchive_url,
				salutationTypes: staticData.salutation_types,
				getCompanyDossierValue,
				isPartSelected,
				getPartType,
				handleFormChange,
				selectPart,
				unSelectPart,
				handleDirectorChange,
			}}>
			{availableDossierParts.map((part, index) => (
				<DossierPart key={index} data={part} />
			))}
		</CompanyDossierContext.Provider>
	);
}

CompanyDossier.propTypes = {
	formData: PropTypes.object,
	staticData: PropTypes.object,
	onDossierChange: PropTypes.func,
	onDirectorChange: PropTypes.func,
	readOnly: PropTypes.bool,
	statutory: PropTypes.bool,
};

CompanyDossier.defaultProps = {
	readOnly: false,
	statutory: false,
};
