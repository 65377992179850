import { useState } from "react";
import PropTypes from "prop-types";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import { can, route } from "../../../../../../js/helpers";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import Translate from "../../../../../../js/react/components/general/Translate";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import Dialog from "../../../../../../js/react/components/general/Dialog";
import InterestRateClassForm from "./InterestRateClassForm";

export default function InterestRateClassOverview({ interestType }) {
	const [currentInterestRateClass, setCurrentInterestRateClass] = useState(null);
	const [overviewDataUpdatedAt, setOverviewDataUpdatedAt] = useState(new Date().getTime());

	const addInterestRateClass = () => {
		setCurrentInterestRateClass({
			id: -1,
			name: "",
			description: "",
			interest_type_id: interestType.id,
		});
	};

	const handleSuccess = () => {
		setOverviewDataUpdatedAt(new Date().getTime());
		setCurrentInterestRateClass(null);
	};

	return (
		<>
			<br />
			<hr />
			<br />
			<h3>
				<Translate content="interest.interest_rate_classes.plural" />
			</h3>

			<AbnOverview
				indexUrl={route("crm.interest.interest-rate-classes.index", interestType.id)}
				deleteRouteName="crm.interest.interest-rate-classes.delete"
				moveRouteName="crm.interest.interest-rate-classes.move"
				useDragAndDrop
				modelTranslations={{
					plural: trans("interest.interest_rate_classes.plural"),
					singular: trans("interest.interest_rate_classes.singular"),
				}}
				dataUpdatedAt={overviewDataUpdatedAt}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={1}
							className="draggable-overview-item-handle"
							renderHeaderContent={() => ""}
							renderCellContent={() => ""}
						/>
						<DataColumn
							width={10}
							sortable="name"
							renderHeaderContent={() => uctrans("interest.fields.name")}
							renderCellContent={interestRateClass => (
								<a onClick={() => setCurrentInterestRateClass(interestRateClass)} className="row-title link">
									{interestRateClass.name}
									{interestRateClass.archived === 1 && (
										<>
											{" "}
											(<Translate content="interest.fields.archived" />)
										</>
									)}
								</a>
							)}
						/>
						{can("interest.interest_rate_class", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(interestRateClass, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(interestRateClass)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
			<IconButton
				onClick={addInterestRateClass}
				content={uctrans("general.add_:item", {}, { item: "interest.interest_rate_classes.singular" })}
				primary
			/>
			{currentInterestRateClass !== null && (
				<Dialog
					isOpen={currentInterestRateClass !== null}
					title={
						currentInterestRateClass.id === -1
							? uctrans("general.add_:item", {}, { item: "interest.interest_rate_classes.singular" })
							: uctrans("general.edit_:item", {}, { item: "interest.interest_rate_classes.singular" })
					}
					isFullScreen
					onClose={() => setCurrentInterestRateClass(null)}>
					<InterestRateClassForm
						interestRateClass={currentInterestRateClass}
						onSuccess={() => handleSuccess()}
						onCancel={() => setCurrentInterestRateClass(null)}
					/>
				</Dialog>
			)}
		</>
	);
}

InterestRateClassOverview.propTypes = {
	interestType: PropTypes.object.isRequired,
};
