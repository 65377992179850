import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import AutosizeTextarea from "../../../../../js/react/components/general/form/AutosizeTextarea";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import Select from "../../../../../js/react/components/general/Select";
import Translate from "../../../../../js/react/components/general/Translate";
import AgendaItemCompanyFields from "./AgendaItemCompanyFields";
import AgendaItemContactmomentLink from "./AgendaItemContactmomentLink";
import AgendaItemDateFields from "./AgendaItemDateFields";
import AgendaItemFormFields from "./AgendaItemFormFields";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import cloneDeep from "lodash/cloneDeep";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import { route } from "../../../../../js/helpers";
import Navigation from "./Navigation";

export default function DossierCheckForm(props) {
	const disabled = !window.data.dossiercheck_form_data.status ? window.data.read_only : true;

	const [formData, setFormData] = useState(
		typeof props.dossierCheckFormData === "undefined"
			? cloneDeep(window.data.dossiercheck_form_data)
			: props.dossierCheckFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.dossierCheckStaticData === "undefined"
			? cloneDeep(window.data.dossiercheck_static_data)
			: props.dossierCheckStaticData,
	);

	const dossierCheckActionOptions = staticData.dossier_check_actions.map(action => ({
		value: action.id,
		label: action.name,
	}));

	const selectedDossierCheckAction =
		Number(formData.dossier_action_id) !== Number(0)
			? staticData.dossier_check_actions.find(action => Number(action.id) === Number(formData.dossier_action_id))
			: null;

	useEffect(() => {
		if (typeof props.dossierCheckFormData !== "undefined") {
			setFormData(props.dossierCheckFormData);
		}
	}, [props.dossierCheckFormData]);

	useEffect(() => {
		if (typeof props.dossierCheckStaticData !== "undefined") {
			setStaticData(props.dossierCheckStaticData);
		}
	}, [props.dossierCheckStaticData]);

	const onSuccess = () => {
		if (staticData.creating) {
			if (staticData.intermediary) {
				Nav.go(route("crm.intermediaries.dossier.show", { intermediary: formData.company_id }));
			} else if (staticData.organisation) {
				Nav.go(route("crm.organisations.dossier.show", { organisation: formData.company_id }));
			}
		} else if (!staticData.creating) {
			Nav.go(route("crm.agenda-items.index"));
		}
	};

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	const submitURL = () => {
		if (!staticData.creating) {
			if (staticData.intermediary) {
				return route(`crm.intermediaries.dossier-checks.update`, [formData.agenda_item.company_id, formData.id]);
			} else if (staticData.organisation) {
				return route(`crm.organisations.dossier-checks.update`, [formData.agenda_item.company_id, formData.id]);
			}
		} else if (staticData.creating) {
			if (staticData.intermediary) {
				return route(`crm.intermediaries.dossier-checks.store`, [formData.company_id]);
			} else if (staticData.organisation) {
				return route(`crm.organisations.dossier-checks.store`, [formData.company_id]);
			}
		}
	};

	const previousURL = () => {
		if (window.data.agendaNamespace) {
			if (staticData.intermediary) {
				return route("crm.agenda.intermediaries.agenda-items.index", { intermediary: staticData.id });
			} else if (staticData.organisation) {
				return route("crm.agenda.organisations.agenda-items.index", { organisation: staticData.id });
			}
		}

		if (staticData.intermediary) {
			return route("crm.intermediaries.agenda-items.index", { intermediary: formData.agenda_item.company_id });
		} else if (staticData.organisation) {
			return route("crm.organisations.agenda-items.index", { organisation: formData.agenda_item.company_id });
		}
	};

	return (
		<>
			{!staticData.creating && (
				<Navigation
					selectedTab={window.data.nav_data.activeTabItem}
					tabs={window.data.nav_data.tabs}
					breadCrumbs={window.data.nav_data.breadcrumbs}
				/>
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.company_name
						? uctrans(
								staticData.creating ? "general.new :item1 by :item2" : "general.:item1 from :item2",
								{ item2: window.data.company_name },
								{ item1: "dossiers.fields.dossier_check" },
						  )
						: staticData.creating
						  ? uctrans("general.add_:item", {}, { item: "dossiers.fields.dossier_check" })
						  : uctrans("dossiers.fields.dossier_check")}
				</h1>

				{window.data.agendaNamespace || staticData.creating ? (
					<a className="link" href={route("crm.agenda-items.index")}>
						{uctrans("general.back_to_:page", {}, { page: "agendaitems.accountsupport" })}
					</a>
				) : (
					<a className="link" href={previousURL()}>
						{uctrans("general.back_to_overview_:page", {}, { page: "agendaitems.plural" })}
					</a>
				)}
			</div>

			<AgendaItemContactmomentLink contactmoment={formData.contactmoment} />

			<AjaxForm
				method={staticData.creating ? "POST" : "PUT"}
				submitUrl={submitURL()}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "dossiers.fields.dossier_check" })}
				successText={uctrans("general.saved_:item", {}, { item: "dossiers.fields.dossier_check" })}
				data={{ ...formData }}
				onSuccess={onSuccess}
				useFlashMessage={staticData.creating || window.data.agendaNamespace}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<AgendaItemFormFields
								onChange={value => handleFormValueChange("agenda_item", value)}
								agendaItemFormData={formData.agenda_item}
								agendaItemStaticData={staticData}
								editable={!disabled}
								showAgendaDate
							/>
							<div className="form-ful">
								<label htmlFor="description">
									<Translate content="dossiers.fields.description" />
								</label>
								<AutosizeTextarea
									id="description"
									name="description"
									value={formData.description}
									onChange={e => handleFormValueChange(e.target.name, e.target.value)}
									placeholder={uctrans("dossiers.fields.description_placeholder")}
									disabled={disabled}
								/>
							</div>

							<div className="form-full">
								<Checkmark
									disabled={disabled}
									label={uctrans("dossiers.fields.handled")}
									name="status"
									checked={formData.status}
									onChange={e => handleFormValueChange(e.target.name, e.target.checked)}
								/>
							</div>

							{Number(formData.status) === Number(1) && (
								<>
									<div className="form-full">
										<Select
											isClearable
											value={dossierCheckActionOptions.filter(
												({ value }) => Number(value) === Number(formData.dossier_action_id),
											)}
											options={dossierCheckActionOptions}
											name="dossier_action_id"
											disabled={disabled}
											onChange={selectedValue =>
												handleFormValueChange("dossier_action_id", selectedValue ? selectedValue.value : null)
											}
											placeholder={uctrans("general.select_:item", {}, { item: "dossiers.check_actions.singular" })}
										/>
									</div>

									{selectedDossierCheckAction !== null && selectedDossierCheckAction.is_open && (
										<div className="form-full">
											<label htmlFor="description">
												<Translate content="dossiers.fields.description" />
											</label>
											<textarea
												disabled={disabled}
												id="dossier_action_value"
												name="dossier_action_value"
												value={formData.dossier_action_value}
												onChange={e => handleFormValueChange(e.target.name, e.target.value)}
												placeholder={uctrans("dossiers.fields.description_placeholder")}
												autoComplete="off"
											/>
										</div>
									)}
								</>
							)}

							<div className="form-full">
								<div className="row">
									<SubmitBar
										item="dossiers.fields.dossier_check"
										addAnotherAvailable={false}
										submitDisabled={disabled}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="flex-none w-1/3 border-l pl-12">
						<AgendaItemDateFields agendaItem={formData.agenda_item} />

						{!staticData.creating && <AgendaItemCompanyFields companyId={formData.agenda_item.company_id} />}
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
DossierCheckForm.propTypes = {
	dossierCheckFormData: PropTypes.object,
	dossierCheckStaticData: PropTypes.object,
};
