import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import { useState } from "react";
import DossierForm from "../dossier/DossierForm";

import IntermediaryForm from "./IntermediaryForm";
import IntermediaryCommercialForm from "./IntermediaryCommercialForm";

export default function IntermediaryDetail(props) {
	const [formData, setFormData] = useState(
		typeof props.companyFormData === "undefined" ? cloneDeep(window.data.company_form_data) : props.companyFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.companyStaticData === "undefined"
			? cloneDeep(window.data.company_static_data)
			: props.companyStaticData,
	);

	const loadData = response => {
		setFormData(response.data.formvalues);
		setStaticData(response.data.company_static_data);
	};

	if (window.data.component_name) {
		switch (window.data.component_name) {
			case "dossier_form":
				return <DossierForm companyFormData={formData} companyStaticData={staticData} />;
			case "intermediary_form":
				return <IntermediaryForm companyFormData={formData} companyStaticData={staticData} />;
			case "intermediary_commercial_form":
				return (
					<IntermediaryCommercialForm reloadData={loadData} companyFormData={formData} companyStaticData={staticData} />
				);
			default:
				console.error(`unknown type:  ${window.data.component_name}`);
				return <div />;
		}
	}
}

IntermediaryDetail.propTypes = {
	companyFormData: PropTypes.object,
	companyStaticData: PropTypes.object,
};
