export default function getEngagementScoreColor(total = 0, wallet = 0) {
	const yellowColor = {
		red: 255,
		green: 206,
		blue: 57,
	};

	const greenColor = {
		red: 0,
		green: 146,
		blue: 134,
	};

	const redColor = {
		red: 255,
		green: 102,
		blue: 0,
	};

	let percentage = (100 * wallet) / total / 2; // divide by two for less gradient
	if (percentage >= 100) {
		percentage = 100;
	} else if (percentage <= 1) {
		percentage = 0;
	}

	let fade = percentage / 100;

	let color1 = redColor;
	let color2 = yellowColor;

	fade = fade * 2;
	if (fade >= 1) {
		fade -= 1;
		color1 = yellowColor;
		color2 = greenColor;
	}

	const diffRed = color2.red - color1.red;
	const diffGreen = color2.green - color1.green;
	const diffBlue = color2.blue - color1.blue;

	const gradient = {
		red: parseInt(Math.floor(color1.red + diffRed * fade), 10),
		green: parseInt(Math.floor(color1.green + diffGreen * fade), 10),
		blue: parseInt(Math.floor(color1.blue + diffBlue * fade), 10),
	};

	return `rgb(${gradient.red},${gradient.green},${gradient.blue})`;
}
