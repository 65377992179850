import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import { route } from "../../../../../../js/helpers";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../../js/react/components/general/Translate";
import axios from "axios";
import Spinner from "../../../../../../js/react/components/general/Spinner";

export default function DiscountExceptionForm({ discountException, onSuccess, onCancel }) {
	const formData = { ...discountException };
	const [initialized, setInitialized] = useState(false);
	const [discounts, setDiscounts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectedDiscounts, setSelectedDiscounts] = useState(
		new Set(discountException.id === -1 ? [] : discountException.discounts.map(discount => discount.id)),
	);

	const onDiscountSelection = discountId => {
		const value = parseInt(discountId);
		const newSelectedDiscounts = new Set([...selectedDiscounts]);
		newSelectedDiscounts.has(value) ? newSelectedDiscounts.delete(value) : newSelectedDiscounts.add(value);

		setSelectedDiscounts(newSelectedDiscounts);
	};

	const handleSuccess = () => {
		onSuccess();
	};

	const handleCancel = () => {
		onCancel();
	};

	const fetchDiscounts = useCallback(async () => {
		const response = await axios.get(route("crm.interest.discounts.index", formData.interest_type_id));
		return response.data.overview.items;
	}, [formData.interest_type_id]);

	useEffect(() => {
		if (!initialized) {
			setInitialized(true);
			fetchDiscounts().then(discounts => {
				setDiscounts(discounts);
				setLoading(false);
			});
		}
	}, [initialized, fetchDiscounts]);

	return (
		<AjaxForm
			method={formData.id === -1 ? "POST" : "PUT"}
			submitUrl={
				formData.id === -1
					? route("crm.interest.discount-exceptions.store")
					: route("crm.interest.discount-exceptions.update", formData.id)
			}
			loaderText={uctrans("general.:item_is_being_saved", {}, { item: "interest.discount_exceptions.singular" })}
			successText={uctrans("general.saved_:item", {}, { item: "interest.discount_exceptions.singular" })}
			onSuccess={handleSuccess}
			data={{ selected_discounts: [...selectedDiscounts], interest_type_id: formData.interest_type_id }}>
			<div className="md:flex">
				<div className="flex-1 w-2/3 mr-12">
					<div className="form-container">
						<div className="form-full">
							{loading ? (
								<div className="flex-grow">
									<Spinner width={25} />
									<div>
										<Translate content="interest.sentences.loading_discounts" />
									</div>
								</div>
							) : (
								<>
									<h6 className="mb-0">
										<Translate content="interest.discounts.plural" />
									</h6>
									{discounts.length > 1 ? (
										<>
											{discounts.map(discount => (
												<div key={discount.id}>
													<input
														type="checkbox"
														id={`discount-${discount.id}`}
														value={discount.id}
														checked={selectedDiscounts.has(discount.id)}
														onChange={event => onDiscountSelection(event.target.value)}
													/>
													&nbsp;{" "}
													<label className="inline m-0 font-normal" htmlFor={`discount-${discount.id}`}>
														{discount.name}
													</label>
													<span className="checkmark" />
												</div>
											))}
										</>
									) : (
										<p>
											<Translate content="interest.sentences.not_enough_discounts" />
										</p>
									)}
								</>
							)}
						</div>
						<div className="form-full">
							<div className="w-full md:w-1/2 lg:w-2/3 column">
								<SubmitBar
									item="interest.discount_exceptions.singular"
									submitDisabled={discounts.length < 2 || loading || selectedDiscounts.size < 2}
									addAnotherAvailable={false}
									onCancel={handleCancel}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AjaxForm>
	);
}
DiscountExceptionForm.propTypes = {
	discountException: PropTypes.object.isRequired,
	onSuccess: PropTypes.func,
	onCancel: PropTypes.func,
};
