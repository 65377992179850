import find from "lodash/find";
import PropTypes from "prop-types";
import { useState, useEffect, useCallback, useMemo } from "react";
import { format } from "../../../../../js/lib/Date";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import CompanyKvkData from "../company/CompanyKvkData";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import CollapsibleContent from "../general/CollapsibleContent";
import SideBarChangelog from "../general/SideBarChangelog";
import SideBarStatusButton from "../general/SideBarStatusButton";
import TabLinks from "../general/tabs/TabLinks";
import cloneDeep from "lodash/cloneDeep";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import AllEstablishments from "../statutoryCompanies/AllEstablishments";
import IntermediaryFormFields from "./IntermediaryFormFields";
import { route, can } from "../../../../../js/helpers";
import AFMPermits from "./statutory/AFMPermits";
import KifidChecks from "./statutory/KifidChecks";

export default function IntermediaryForm(props) {
	const [formData, setFormData] = useState(
		typeof props.companyFormData === "undefined" ? cloneDeep(window.data.company_form_data) : props.companyFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.companyStaticData === "undefined"
			? cloneDeep(window.data.company_static_data)
			: props.companyStaticData,
	);

	const afmData = useMemo(() => staticData.statutory_company.afm, [staticData.statutory_company]);

	useEffect(() => {
		if (typeof props.companyFormData !== "undefined") {
			setFormData(props.companyFormData);
		}
	}, [props.companyFormData]);

	useEffect(() => {
		if (typeof props.companyStaticData !== "undefined") {
			setStaticData(props.companyStaticData);
		}
	}, [props.companyStaticData]);

	const onSuccess = () => {
		if (window.data.agendaNamespace) {
			Nav.go(route("crm.agenda-items.index"));
		} else {
			window.location.reload();
		}
	};

	const handleFormValuesChange = useCallback(formData => setFormData(formData), [setFormData]);

	const handleFormValueChange = useCallback(
		(key, value) => {
			const newFormData = { ...formData };
			newFormData[key] = value;
			setFormData(newFormData);
		},
		[formData],
	);

	const agendaItemType = itemType => {
		const agendaItemType = find(staticData.agenda_item_types, type => Number(type.value) === Number(itemType));

		if (typeof agendaItemType === "undefined") {
			return "unknown type";
		}

		return agendaItemType.label;
	};

	const changeLogOrganisations = [...staticData.organisations, { value: -1, label: "geen organisatie" }];

	return (
		<>
			<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
			<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
			<div className="page-title-container">
				<h1 className="page-title">
					{staticData.company_name
						? uctrans(
								"general.:item1 from :item2",
								{ item2: staticData.company_name },
								{ item1: "companies.administrative_profile" },
						  )
						: uctrans(
								staticData.creating ? "general.add_:item" : "general.edit_:item",
								{},
								{ item: "intermediaries.singular" },
						  )}
				</h1>

				{window.data.agendaNamespace ? (
					<a className="link" href={route("crm.agenda-items.index")}>
						{uctrans("general.back_to_:page", {}, { page: "agendaitems.accountsupport" })}
					</a>
				) : (
					<a className="link" href={route("crm.intermediaries.index")}>
						{uctrans("general.back_to_overview_:page", {}, { page: "intermediaries.plural" })}
					</a>
				)}
			</div>
			<AjaxForm
				method={staticData.save_method}
				submitUrl={staticData.save_link}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "intermediaries.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "intermediaries.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage={staticData.creating || window.data.agendaNamespace}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<IntermediaryFormFields
							companyFormData={formData}
							companyStaticData={staticData}
							onChange={handleFormValuesChange}
						/>
					</div>
					<div className="flex-none w-1/3 border-l pl-12">
						<CollapsibleContent title={uctrans("intermediaries.establishments")}>
							<CompanyKvkData
								changeStatutoryCompanyFields={handleFormValueChange}
								statutoryCompany={formData.statutory_company}
								companyId={formData.id}
								mainCompany={formData.is_main_company}
							/>
							<br />
							<AllEstablishments statutoryCompanyId={formData.statutory_company.id} />
						</CollapsibleContent>

						{!!formData.statutory_company && !!staticData.statutory_company && (
							<>
								{formData.status === 1 ? (
									<AFMPermits
										afmNumber={afmData.number}
										afmPermits={afmData.existing_permits}
										statutoryCompanyStaticData={staticData.statutory_company}
										missingPermits={afmData.missing_permits}
										requiredPermits={afmData.required_permits}
										afmChangeLogs={afmData.changelogs}
										afmChangeLogTypes={staticData.afm_changelog_types}
									/>
								) : (
									<AFMPermits
										afmNumber={formData.statutory_company.afm_number}
										afmPermits={formData.statutory_company.actual_afm_permits}
										statutoryCompanyStaticData={staticData.statutory_company}
										missingPermits={staticData.statutory_company.missing_permits}
										requiredPermits={staticData.statutory_company.required_permits}
										afmChangeLogs={staticData.statutory_company.afm_changelogs}
										afmChangeLogTypes={staticData.afm_changelog_types}
									/>
								)}

								<KifidChecks
									kifidNumber={formData.statutory_company.kifid_number}
									kifidType={formData.statutory_company.kifid_type}
									kifidTypes={staticData.kifid_types}
								/>
							</>
						)}

						{staticData.changelog_changes_statutory && staticData.changelog_changes_statutory.afm_number && (
							<CollapsibleContent
								title={uctrans("general.:item_changes", {}, { item: "intermediaries.fields.afm_number" })}>
								<SideBarChangelog
									changelogData={staticData.changelog_changes_statutory.afm_number}
									changelogChangeTypes={staticData.changelog_change_types}
								/>
							</CollapsibleContent>
						)}

						{staticData.changelog_changes_statutory && staticData.changelog_changes_statutory.kifid_number && (
							<CollapsibleContent
								title={uctrans("general.:item_changes", {}, { item: "intermediaries.fields.kifid_number" })}>
								<SideBarChangelog
									changelogData={staticData.changelog_changes_statutory.kifid_number}
									changelogChangeTypes={staticData.changelog_change_types}
								/>
							</CollapsibleContent>
						)}

						<CollapsibleContent
							title={uctrans("general.:item_changes", {}, { item: "intermediaries.fields.parent_id" })}>
							{staticData.changelog_changes_intermediary && staticData.changelog_changes_intermediary.parent_id && (
								<SideBarChangelog
									changelogData={staticData.changelog_changes_intermediary.parent_id}
									changelogChangeTypes={staticData.changelog_change_types}
									changelogRelations={changeLogOrganisations}
								/>
							)}
						</CollapsibleContent>

						<CollapsibleContent title={uctrans("intermediaries.fields.classification")}>
							{staticData.company_status_label && (
								<div className="font-bold mb-2 border-bottom">{staticData.company_status_label}</div>
							)}
							{staticData.activation_date && (
								<div className="italic mb-2 border-bottom">
									{uctrans("intermediaries.activation_date")}: {format(staticData.activation_date, "dd-MM-y")}
								</div>
							)}
							{staticData.changelog_changes_intermediary && staticData.changelog_changes_intermediary.status && (
								<>
									<hr className="w-full mb-3" />
									<h5>{uctrans("general.:item_changes", {}, { item: "intermediaries.fields.classification" })}</h5>
									<SideBarChangelog
										changelogData={staticData.changelog_changes_intermediary.status}
										changelogChangeTypes={staticData.changelog_change_types}
										changelogRelations={staticData.changelog_company_statuses}
									/>
								</>
							)}
						</CollapsibleContent>

						<CollapsibleContent title={uctrans("intermediaries.fields.actions")}>
							{can("intermediary", "update_status") && (
								<SideBarStatusButton
									company_id={formData.id}
									company_status={formData.status}
									company_statuses={staticData.company_statuses}
									intermediary
								/>
							)}
						</CollapsibleContent>

						{formData.open_agenda_items && formData.open_agenda_items.length > 0 && (
							<CollapsibleContent title={uctrans("agendaitems.open_agenda_items_company")} initialCollapseState={false}>
								{formData.open_agenda_items.map(agendaItem => (
									<div key={agendaItem.id}>
										<a
											href={
												window.data.agendaNamespace
													? route("crm.agenda.agenda-items.open", agendaItem)
													: route("crm.agenda-items.open", agendaItem)
											}
											className="row-title link">
											{agendaItem.agenda_date ? format(agendaItem.agenda_date, "dd-MM-y") : uctrans("general.no_date")}
										</a>{" "}
										- {agendaItemType(agendaItem.type)}
									</div>
								))}
							</CollapsibleContent>
						)}
					</div>
				</div>
				<div className="form-full">
					<div className="row">
						<div className="w-full md:w-1/2 lg:w-1/3 column">
							<SubmitBar
								item="intermediaries.singular"
								addAnotherAvailable={false}
								submitDisabled={!can("intermediary", "update_administrative")}
							/>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
IntermediaryForm.propTypes = {
	companyFormData: PropTypes.object,
	companyStaticData: PropTypes.object,
};
