import PropTypes from "prop-types";

export default function Number(props) {
	return (
		<>
			{props.label && <label>{props.label}</label>}
			<input
				type="number"
				value={props.value == null ? "" : props.value}
				onChange={e => {
					props.onChange(parseInt(e.target.value));
				}}
				max={props.max}
			/>
		</>
	);
}

Number.propTypes = {
	label: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func.isRequired,
	max: PropTypes.number,
};
