import { uctrans } from "../../../../../../js/lib/Translator";
import CollapsibleContent from "../../general/CollapsibleContent";
import PropTypes from "prop-types";

export default function KifidChecks(props) {
	const { kifidTypes, kifidNumber, kifidType } = props;

	return (
		<CollapsibleContent title={uctrans("intermediaries.kifid_warrant_statutory")}>
			<label>KIFID-nummer</label>
			<span>{kifidNumber}</span>
			<label>Status</label>
			{kifidTypes ? (
				<span>{kifidType ? kifidTypes.find(type => Number(type.value) === Number(kifidType)).label : "-"}</span>
			) : (
				<span>-</span>
			)}
		</CollapsibleContent>
	);
}

KifidChecks.propTypes = {
	kifidTypes: PropTypes.array,
	kifidNumber: PropTypes.string,
	kifidType: PropTypes.number,
};

KifidChecks.defaultProps = {
	kifidType: null,
};
