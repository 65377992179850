import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import { uctrans } from "../../../../../js/lib/Translator";
import cloneDeep from "lodash/cloneDeep";
import { useState } from "react";
import QuestionnaireDetailForm from "./QuestionnaireDetailForm";
import QuestionnaireQuestionForm from "./QuestionnaireQuestionForm";
import QuestionnaireTabs from "./QuestionnaireTabs";

export default function QuestionnaireForm() {
	const questionFormData = cloneDeep(window.data.questionnaire_question_form_data);
	const detailFormData = cloneDeep(window.data.questionnaire_detail_form_data);

	const getTabFromHash = () => {
		let newTabIndex = 0;
		if (window.location.hash.indexOf("#tab-") === 0) {
			newTabIndex = parseInt(window.location.hash.substr("#tab-".length));
		}
		return newTabIndex;
	};

	const [activeTab, setActiveTab] = useState(getTabFromHash());

	const staticData = window.data.questionnaire_static_data;

	const onActiveTabChange = newActiveTabIndex => {
		setActiveTab(newActiveTabIndex);
	};

	return (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{ label: uctrans("questionnaires.plural"), route: staticData.previous_link },
					{
						label: staticData.creating
							? uctrans("general.add_:item", {}, { item: "questionnaires.singular" })
							: detailFormData.name,
					},
				]}
			/>

			{!staticData.creating && (
				<QuestionnaireTabs
					questionnaireId={detailFormData.id}
					initialActiveTabIndex={activeTab}
					onActiveTabChange={onActiveTabChange}
				/>
			)}

			{staticData.creating ? (
				<h1 className="inline-block">{uctrans("general.add_:item", {}, { item: "questionnaires.singular" })}</h1>
			) : (
				<h1 className="inline-block">{detailFormData.name}</h1>
			)}
			{staticData.previous_link && (
				<div className="float-right">
					<a className="link" href={staticData.previous_link}>
						{uctrans("general.back_to_overview_:page", {}, { page: "questionnaires.plural" })}
					</a>
				</div>
			)}

			<div style={activeTab === 0 ? {} : { display: "none" }}>
				<QuestionnaireDetailForm questionnaireFormData={detailFormData} questionnaireStaticData={staticData} />
			</div>
			<div style={activeTab === 1 ? {} : { display: "none" }}>
				<QuestionnaireQuestionForm questionnaireFormData={questionFormData} questionnaireStaticData={staticData} />
			</div>
		</>
	);
}
