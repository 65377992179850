import Tippy from "@tippyjs/react";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { can, route } from "../../../../../js/helpers";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import Dialog from "../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import FormDivider from "../../../../../js/react/components/general/FormDivider";
import IconButton from "../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import CollapsibleContent from "../general/CollapsibleContent";
import ClientTreatmentAddParticipantSearchFilter from "./ClientTreatmentAddParticipantSearchFilter";
import ClientTreatmentDividationsSum from "./ClientTreatmentDividationsSum";
import ClientTreatmentParticipantForm from "./ClientTreatmentParticipantForm";
import Navigation from "./Navigation";

export default function ClientTreatmentCompletionsOverview(props) {
	const [overviewDataUpdatedAt, setOverviewDataUpdatedAt] = useState(new Date().getTime());
	const [editParticipantsDialogOpen, setEditParticipantsDialogOpen] = useState(false);
	const [activeParticipant, setActiveParticipant] = useState(null);
	const [addParticipantsDialogOpen, setAddParticipantsDialogOpen] = useState(false);

	const handleAddParticipantsSuccess = () => {
		setOverviewDataUpdatedAt(new Date().getTime());
		setAddParticipantsDialogOpen(false);
	};

	const handleEditParticipantsSuccess = () => {
		setOverviewDataUpdatedAt(new Date().getTime());
		setEditParticipantsDialogOpen(false);
		setActiveParticipant(null);
	};

	const [staticData, setStaticData] = useState(
		typeof props.ClientTreatmentStaticData === "undefined"
			? cloneDeep(window.data.client_treatment_static_data)
			: props.ClientTreatmentStaticData,
	);

	useEffect(() => {
		if (typeof props.ClientTreatmentStaticData !== "undefined") {
			setStaticData(props.ClientTreatmentStaticData);
		}
	}, [props.ClientTreatmentStaticData]);

	return (
		<>
			<Navigation
				selectedTab="client-treatment-inviters"
				clientTreatment={staticData.client_treatment}
				clientTreatmentTypes={staticData.client_treatment_types}
				canUpdate={can("client_treatment.client_treatment", "update")}
				loggedInInviter={
					window.data.user
						? staticData.client_treatment.inviters.find(
								inviter => Number(inviter.user_id) === Number(window.data.user.id),
						  )
						: null
				}
			/>
			<div className="page-title-container">
				<h1 className="page-title">{`${staticData.client_treatment.name} (${uctrans(
					"client_treatment.inviters.fields.on_behalf_of",
				)} ${staticData.client_treatment_inviter.user_label})`}</h1>

				{can("client_treatment.client_treatment", "view") && (
					<div>
						<IconButton
							onClick={() => {
								setAddParticipantsDialogOpen(true);
							}}
							content={uctrans("client_treatment.participants.add_intermediary_contactpersons")}
							primary
						/>
					</div>
				)}
			</div>

			<div className="min-h-screen md:flex">
				<div className="flex-1 w-2/3 mr-12">
					<AbnOverview
						indexUrl={route("crm.client-treatments.inviters.participants.index", {
							client_treatment: staticData.client_treatment.id,
							client_treatment_inviter: staticData.client_treatment_inviter.id,
						})}
						modelTranslations={{
							plural: trans("client_treatment.participants.plural"),
							singular: trans("client_treatment.participants.singular"),
						}}
						dataUpdatedAt={overviewDataUpdatedAt}
						renderFilters={() => (
							<div>
								<h3>{uctrans("client_treatment.participants.fields.divided_by_me")}</h3>
								<Filters>
									<SearchBarFilter type="text" name="search" />
								</Filters>
							</div>
						)}
						renderBody={() => (
							<DataTable>
								<DataColumn
									width={4}
									renderHeaderContent={() => uctrans("contactpersons.singular")}
									renderCellContent={participant =>
										participant.participant_object ? participant.participant_object.label : "-"
									}
								/>
								<DataColumn
									width={6}
									renderHeaderContent={() => uctrans("client_treatment.completions.type")}
									renderCellContent={participant => (participant.type ? participant.type.label : "-")}
								/>
								<DataColumn
									width={2}
									renderHeaderContent={() => uctrans("client_treatment.inviters.fields.nr_of_people")}
									renderCellContent={participant => (participant.nr_of_people ? participant.nr_of_people : "-")}
								/>
								<DataColumn
									width={4}
									renderHeaderContent={() => uctrans("client_treatment.inviters.fields.status")}
									renderCellContent={participant => (participant.status ? participant.status.label : "-")}
								/>
								<DataColumn
									width={4}
									renderHeaderContent={() => uctrans("client_treatment.inviters.fields.extra_info")}
									renderCellContent={participant =>
										participant.information ? <span className="text-xs italic">{participant.information}</span> : ""
									}
								/>
								<DataColumn
									width={1}
									renderHeaderContent={() => ""}
									renderCellContent={participant =>
										participant.override ? (
											<Tippy
												placement="top"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("giveaways.fields.this_giveaway_triggered_override")}>
												<span className="text-lg" style={{ color: "red" }}>
													€
												</span>
											</Tippy>
										) : (
											""
										)
									}
								/>
								{can("client_treatment.participant", "update") && (
									<DataColumn
										width={1}
										renderHeaderContent={() => ""}
										renderCellContent={(participant, overview) => (
											<div className="overview-actions">
												{can("client_treatment.participant", "update") && (
													<a
														className="link"
														onClick={() => {
															setActiveParticipant(participant);
															setEditParticipantsDialogOpen(true);
														}}>
														<EvaIcon type="edit-outline" width="18" height="18" fill="#009286" />
													</a>
												)}
												{can("client_treatment.participant", "delete") && (
													<a
														onClick={() => {
															overview.askDelete(participant);
														}}>
														<Tippy
															placement="left"
															arrow
															animation="perspective"
															duration="400"
															content={uctrans("general.delete")}>
															<span>
																<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
															</span>
														</Tippy>
													</a>
												)}
											</div>
										)}
									/>
								)}
							</DataTable>
						)}
					/>

					<FormDivider label={uctrans("client_treatment.completions.divided_by_collegues")} />

					<AbnOverview
						indexUrl={route("crm.client-treatments.inviters.participants-without-collegue", {
							client_treatment: staticData.client_treatment.id,
							client_treatment_inviter: staticData.client_treatment_inviter.id,
						})}
						modelTranslations={{
							plural: trans("client_treatment.participants.plural"),
							singular: trans("client_treatment.participants.singular"),
						}}
						hideControls
						renderBody={() => (
							<DataTable>
								<DataColumn
									width={4}
									renderHeaderContent={() => uctrans("contactpersons.singular")}
									renderCellContent={participant =>
										participant.participant_object ? participant.participant_object.label : "-"
									}
								/>
								<DataColumn
									width={5}
									renderHeaderContent={() => uctrans("client_treatment.completions.type")}
									renderCellContent={participant => (participant.type ? participant.type.label : "-")}
								/>
								<DataColumn
									width={4}
									renderHeaderContent={() => uctrans("client_treatment.inviters.fields.by_who")}
									renderCellContent={treatment => (treatment.inviter ? treatment.inviter.label : "-")}
								/>
								<DataColumn
									width={2}
									renderHeaderContent={() => uctrans("client_treatment.inviters.fields.nr_of_people")}
									renderCellContent={participant => (participant.nr_of_people ? participant.nr_of_people : "-")}
								/>
								<DataColumn
									width={4}
									renderHeaderContent={() => uctrans("client_treatment.inviters.fields.status")}
									renderCellContent={participant => (participant.status ? participant.status.label : "-")}
								/>
								<DataColumn
									width={1}
									renderHeaderContent={() => ""}
									renderCellContent={participant =>
										participant.override ? (
											<Tippy
												placement="top"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("giveaways.fields.this_giveaway_triggered_override")}>
												<span className="text-lg" style={{ color: "red" }}>
													€
												</span>
											</Tippy>
										) : (
											""
										)
									}
								/>
							</DataTable>
						)}
					/>

					<a className="link" href={route("crm.client-treatments.inviters", staticData.client_treatment.id)}>
						{uctrans("general.back_to_overview_:page", {}, { page: "client_treatment.dividations" })}
					</a>
				</div>

				<div className="flex-none w-1/3 border-l pl-12">
					<CollapsibleContent title={uctrans("client_treatment.fields.dividation")} initialCollapseState={false}>
						<ClientTreatmentDividationsSum dividationData={staticData.client_treatment_dividations_data} />
					</CollapsibleContent>
				</div>
			</div>

			<Dialog
				width={500}
				isOpen={editParticipantsDialogOpen}
				title={uctrans("client_treatment.participants.fields.edit_participant")}
				isFullScreen
				onClose={() => setEditParticipantsDialogOpen(false)}>
				<ClientTreatmentParticipantForm
					formData={activeParticipant}
					staticData={staticData}
					onSuccess={() => handleEditParticipantsSuccess()}
					onCancel={() => setEditParticipantsDialogOpen(false)}
				/>
			</Dialog>
			<Dialog
				width={900}
				isOpen={addParticipantsDialogOpen}
				title={uctrans("general.add_:item", {}, { item: "client_treatment.participants.plural" })}
				isFullScreen
				onClose={() => setAddParticipantsDialogOpen(false)}>
				<ClientTreatmentAddParticipantSearchFilter
					clientTreatment={staticData.client_treatment}
					clientTreatmentInviter={staticData.client_treatment_inviter}
					staticData={staticData}
					onSuccess={() => handleAddParticipantsSuccess()}
					onCancel={() => setAddParticipantsDialogOpen(false)}
					onAddParticipant={() => setOverviewDataUpdatedAt(new Date().getTime())}
				/>
			</Dialog>
		</>
	);
}
ClientTreatmentCompletionsOverview.propTypes = {
	ClientTreatmentStaticData: PropTypes.object,
};
