import { route } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import TextFilter from "../../../../../js/react/components/general/overview/TextFilter";
import Nav from "../../../../../js/lib/Nav";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
export default function DosierMutationOverview() {
	const overviewData = { ...window.data.overview };

	const handleExport = overviewParameters => {
		Nav.go(route(`crm.dossier-mutations.export`, overviewParameters));
	};

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("dossier_mutations.plural") }]} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("dossier_mutations.plural")}</h1>
			</div>
			<AbnOverview
				initialData={overviewData}
				indexUrl={route("crm.dossier-mutations.data")}
				modelTranslations={{
					plural: trans("dossier_mutations.plural"),
					singular: trans("dossier_mutations.singular"),
				}}
				renderFilters={overview => (
					<Filters>
						<TextFilter
							hideIcon
							className="form-1-3"
							name="loan_number"
							label={uctrans("dossier_mutations.fields.loan_number")}
							placeholder={uctrans("dossier_mutations.search_placeholder")}
						/>
						<TextFilter
							hideIcon
							className="form-1-3"
							name="company"
							label={uctrans("intermediaries.singular")}
							placeholder={uctrans("general.search_:item", {}, { item: "intermediaries.singular" })}
						/>
						<SelectFilter
							className="form-1-3"
							name="type"
							label={uctrans("dossier_mutations.types.singular")}
							options={overview.data.metadata.filterdata.types}
						/>
						<SelectFilter
							className="form-1-2"
							name="team_id"
							label={uctrans("intermediaries.fields.team")}
							options={overview.data.metadata.filterdata.teams}
							collapsible
						/>
						<SelectFilter
							className="form-1-2"
							name="relationmanager_id"
							label={uctrans("intermediaries.fields.relationmanager")}
							options={overview.data.metadata.filterdata.relationmanagers}
							collapsible
						/>
						<SelectFilter
							className="form-1-2"
							name="segment_id"
							label={uctrans("intermediaries.fields.segment")}
							options={overview.data.metadata.filterdata.segments}
							collapsible
						/>
						<SelectFilter
							className="form-1-2"
							name="organisation_id"
							label={uctrans("intermediaries.fields.organisation")}
							options={overview.data.metadata.filterdata.organisations}
							collapsible
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={4}
							sortable="company"
							renderHeaderContent={() => uctrans("dossier_mutations.fields.company")}
							renderCellContent={({ company }) => company}
						/>
						<DataColumn
							width={2}
							sortable="loan_number"
							renderHeaderContent={() => uctrans("dossier_mutations.fields.loan_number")}
							renderCellContent={({ loan_number }) => loan_number}
						/>
						<DataColumn
							width={2}
							sortable="process_date"
							renderHeaderContent={() => uctrans("dossier_mutations.fields.process_date")}
							renderCellContent={({ process_date }) => format(process_date, "dd-MM-y")}
						/>
						<DataColumn
							width={3}
							sortable="type"
							renderHeaderContent={() => uctrans("dossier_mutations.fields.type")}
							renderCellContent={({ type }) => type}
						/>
					</DataTable>
				)}
				renderFooter={overview => (
					<a className="button button-primary mt-10" onClick={() => handleExport(overview.getParameters())}>
						{uctrans("dossier_mutations.export")}
					</a>
				)}
			/>
		</>
	);
}
