import PropTypes from "prop-types";
import { useState } from "react";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import cloneDeep from "lodash/cloneDeep";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import Translate from "../../../../../js/react/components/general/Translate";

export default function InteresttypeForm(props) {
	const [formData, setFormData] = useState(
		typeof props.interestTypeFormData === "undefined"
			? cloneDeep(window.data.interesttype_form_data)
			: props.interestTypeFormData,
	);

	const inputHandler = useInputHandler(setFormData);

	const [staticData, setStaticData] = useState(
		typeof props.interestTypeStaticData === "undefined"
			? cloneDeep(window.data.interesttype_static_data)
			: props.interestTypeStaticData,
	);

	const onSuccess = response => {
		if (!staticData.creating) {
			setFormData(response.data.formvalues);
			setStaticData(response.data.interesttype_static_data);
		} else {
			Nav.go(response.data.redirect_route);
		}
	};

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(
						staticData.creating ? "general.add_:item" : "general.edit_:item",
						{},
						{ item: "interesttypes.singular" },
					)}
				</h1>
				{staticData.previous_link && (
					<a className="link" href={staticData.previous_link}>
						{uctrans("general.back_to_overview_:page", {}, { page: "interesttypes.plural" })}
					</a>
				)}
			</div>
			<AjaxForm
				method={staticData.save_method}
				submitUrl={staticData.save_link}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "interesttypes.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "interesttypes.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage={staticData.creating}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<h2>{staticData.form_title}</h2>

							<div className="form-2-3">
								<LabeledTextInput
									name="name"
									value={formData.name}
									onChange={inputHandler}
									label="interesttypes.fields.name"
									required
								/>
							</div>
							<div className="form-full">
								<Checkmark
									label={uctrans("interesttypes.fields.requires_explanation")}
									name="requires_explanation"
									checked={formData.requires_explanation}
									onChange={inputHandler}
								/>
							</div>
							{formData.requires_explanation && (
								<div className="form-full">
									<label htmlFor="explanation_description">
										<Translate content="general.placeholder" />
									</label>
									<textarea
										value={formData.explanation_description}
										onChange={inputHandler}
										placeholder={uctrans("interesttypes.fields.explanation_description")}
										name="explanation_description"
										autoComplete="off"
									/>
								</div>
							)}

							<div className="row">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="interesttypes.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
					<div className="flex-none w-1/3">
						<div />
					</div>
				</div>
			</AjaxForm>
		</>
	);
}

InteresttypeForm.propTypes = {
	interestTypeFormData: PropTypes.object,
	interestTypeStaticData: PropTypes.object,
};
