import { useState } from "react";
import Translate from "../../../../../../js/react/components/general/Translate";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../../js/react/components/general/overview/SearchBarFilter";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route } from "../../../../../../js/helpers";

export default function InterestInterestTypeOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
		permissions: [],
	});

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="interest.interest_types.plural" />
				</h1>
				<div>
					<IconButton
						href={route("crm.interest.interest-types.create")}
						content={uctrans("general.add_:item", {}, { item: "interest.interest_types.singular" })}
						primary
					/>
				</div>
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.interest.interest-types.index")}
				deleteRouteName="crm.interest.interest-types.delete"
				moveRouteName="crm.interest.interest-types.move"
				useDragAndDrop
				modelTranslations={{
					plural: trans("interest.interest_types.plural"),
					singular: trans("interest.interest_types.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={1}
							className="draggable-overview-item-handle"
							renderHeaderContent={() => ""}
							renderCellContent={() => ""}
						/>
						<DataColumn
							width={10}
							sortable="name"
							renderHeaderContent={() => uctrans("interest.fields.name")}
							renderCellContent={interestType => (
								<a href={route("crm.interest.interest-types.edit", interestType.id)} className="row-title link">
									{interestType.name}
								</a>
							)}
						/>
						{can("interest.interest_type", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(interestType, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(interestType)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
