import PropTypes from "prop-types";
import { useContext, useMemo } from "react";
import Tippy from "@tippyjs/react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import CompanyDossierContext from "../CompanyDossierContext";

export default function ConflictingDossierDirector({ dossierDirector }) {
	const { conflictingDossierDirectors } = useContext(CompanyDossierContext);

	const conflictingDirectors = useMemo(
		() =>
			conflictingDossierDirectors.filter(
				director => Number(director.conflicting_director_id) === Number(dossierDirector.id),
			),
		[dossierDirector, conflictingDossierDirectors],
	);

	return conflictingDirectors.length ? (
		<Tippy
			placement="top"
			arrow
			animation="perspective"
			duration="400"
			content={
				<ul>
					{conflictingDirectors.map((conflictingDirector, index) => (
						<li key={index}>
							{uctrans("dossiers.directors.fields.director_already_exists_at_company", {
								director_name: conflictingDirector.name,
								company_name: conflictingDirector.company_name,
							})}
						</li>
					))}
				</ul>
			}>
			<a href={conflictingDirectors[0].url} className="mr-3">
				<EvaIcon type="alert-circle-outline" fill="#f9ACAA" height="18" width="18" />
			</a>
		</Tippy>
	) : null;
}

ConflictingDossierDirector.propTypes = {
	dossierDirector: PropTypes.object,
};
