import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import axios from "axios";
import filter from "lodash/filter";
import { format } from "../../../../../../../js/lib/Date";
import Nav from "../../../../../../../js/lib/Nav";
import AbnOverview from "../../../../../../../js/react/components/general/abn-overview/AbnOverview";
import Checkmark from "../../../../../../../js/react/components/general/form/checkmark/Checkmark";
import IconButton from "../../../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../../js/react/components/general/overview/DataTable";
import { route } from "../../../../../../../js/helpers";
import { uctrans } from "../../../../../../../js/lib/Translator";
import Select from "../../../../../../../js/react/components/general/Select";
import useSelectHandler from "../../../../../../../js/react/hooks/useSelectHandler";
import FormattedNumberText from "../../FormattedNumberText";

export default function WalletAnalysisPeriodReport({ formInfo, formValues, setFormValues }) {
	const [qualityMonitorResults, setQualityMonitorResults] = useState(false);
	const [referenceDate, setReferenceDate] = useState(
		formValues.reference_date_csv ? cloneDeep(formValues.reference_date_csv) : null,
	);
	const [overviewUpdatedAt, setOverviewUpdatedAt] = useState(new Date().getTime());
	const [totalData, setTotalData] = useState(null);
	const [title, setTitle] = useState(null);

	const selectHandler = useSelectHandler(setFormValues);

	useEffect(() => {
		setOverviewUpdatedAt(new Date().getTime());
	}, [formValues]);

	const onOverviewChange = response => {
		if (response) {
			if (response.total) {
				setTotalData(response.total);
			}
			if (response.title) {
				setTitle(response.title);
			}
		}
	};

	const generatePdf = async () => {
		const result = await axios.post(route("crm.report.wallet-analysis.generate-pdf"), {
			...formValues,
		});

		if (result && result.data.success) {
			Nav.go(
				route("crm.report.wallet-analysis.download-pdf", {
					download_key: result.data.download_key,
					reportType: formValues.reportType,
				}),
			);
		}
	};

	const generateCsv = async () => {
		const result = await axios.post(route("crm.report.wallet-analysis.generate-csv"), {
			...formValues,
			quality_monitor_results: qualityMonitorResults,
			reference_date: referenceDate,
		});

		if (result && result.data.success) {
			Nav.go(
				route("crm.report.wallet-analysis.download-csv", {
					download_key: result.data.download_key,
				}),
			);
		}
	};

	return (
		<div>
			<div className="w-full flex -mb-2">
				<div className="w-1/2 flex border-gray mr-3">
					<div className="w-1/2">
						<div className="w-full pr-2">
							<label htmlFor="periodType">{uctrans("report.period_type")}</label>
							<Select
								name="periodType"
								value={formInfo.periodPeriodTypes.filter(({ value }) => `${value}` === `${formValues.periodType}`)}
								options={formInfo.periodPeriodTypes}
								isClearable={false}
								onChange={selectHandler}
							/>
						</div>
						<div className="w-full pr-2">
							<label htmlFor="year">{uctrans("report.years")}</label>
							<Select
								name="year"
								value={formInfo.periodYears.filter(({ value }) => `${value}` === `${formValues.year}`)}
								options={formInfo.periodYears}
								isClearable={false}
								onChange={selectHandler}
							/>
						</div>
						{formValues.periodType === "month" && (
							<div className="w-full pr-2">
								<label htmlFor="month">{uctrans("report.month")}</label>
								<Select
									name="month"
									value={formInfo.months.filter(({ value }) => `${value}` === `${formValues.month}`)}
									options={formInfo.months}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
						)}
						{formValues.periodType === "week" && (
							<div className="w-full pr-2">
								<label htmlFor="week">{uctrans("report.week")}</label>
								<Select
									name="week"
									value={formInfo.weeks.filter(({ value }) => `${value}` === `${formValues.week}`)}
									options={formInfo.weeks}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
						)}
						{formValues.periodType === "month_range" && (
							<>
								<div className="w-full pr-2">
									<label htmlFor="fromMonth">{uctrans("report.from_month")}</label>
									<Select
										name="fromMonth"
										value={formInfo.months.filter(({ value }) => `${value}` === `${formValues.fromMonth}`)}
										options={formInfo.months}
										isClearable={false}
										onChange={selectHandler}
									/>
								</div>
								<div className="w-full pr-2">
									<label htmlFor="toMonth">{uctrans("report.to_month")}</label>
									<Select
										name="toMonth"
										value={formInfo.months.filter(({ value }) => `${value}` === `${formValues.toMonth}`)}
										options={formInfo.months}
										isClearable={false}
										onChange={selectHandler}
									/>
								</div>
							</>
						)}
						{formValues.periodType === "week_range" && (
							<>
								<div className="w-full pr-2">
									<label htmlFor="fromWeek">{uctrans("report.from_week")}</label>
									<Select
										name="fromWeek"
										value={formInfo.weeks.filter(({ value }) => `${value}` === `${formValues.fromWeek}`)}
										options={formInfo.weeks}
										isClearable={false}
										onChange={selectHandler}
									/>
								</div>
								<div className="w-full pr-2">
									<label htmlFor="toWeek">{uctrans("report.to_week")}</label>
									<Select
										name="toWeek"
										value={formInfo.weeks.filter(({ value }) => `${value}` === `${formValues.toWeek}`)}
										options={formInfo.weeks}
										isClearable={false}
										onChange={selectHandler}
									/>
								</div>
							</>
						)}
						<div className="w-full pr-2">
							<label htmlFor="classification">{uctrans("intermediaries.fields.classification")}</label>
							<Select
								name="classification"
								value={formInfo.classificationOptions.filter(
									({ value }) => `${value}` === `${formValues.classification}`,
								)}
								options={formInfo.classificationOptions}
								onChange={selectHandler}
							/>
						</div>
					</div>
					<div className="w-1/2">
						<div className="w-full pr-2">
							<label htmlFor="relationmanager">{uctrans("intermediaries.fields.relationmanager")}</label>
							<Select
								name="relationmanager"
								value={formInfo.relationmanagerOptions.filter(
									({ value }) => `${value}` === `${formValues.relationmanager}`,
								)}
								options={formInfo.relationmanagerOptions}
								onChange={selectHandler}
							/>
						</div>
						<div className="w-full pr-2">
							<label htmlFor="organisation">{uctrans("organisations.singular")}</label>
							<Select
								name="organisation"
								value={formInfo.organisationOptions.filter(({ value }) => `${value}` === `${formValues.organisation}`)}
								options={formInfo.organisationOptions}
								onChange={selectHandler}
							/>
						</div>
						<div className="w-full pr-2">
							<label htmlFor="segment">{uctrans("segments.singular")}</label>
							<Select
								name="segment"
								value={formInfo.segmentOptions.filter(({ value }) => `${value}` === `${formValues.segment}`)}
								options={formInfo.segmentOptions}
								onChange={selectHandler}
							/>
						</div>
						<div className="w-full pr-2">
							<label htmlFor="team">{uctrans("teams.singular")}</label>
							<Select
								name="team"
								value={formInfo.teamOptions.filter(({ value }) => `${value}` === `${formValues.team}`)}
								options={formInfo.teamOptions}
								onChange={selectHandler}
							/>
						</div>
					</div>
				</div>

				<div className="w-1/2 border-gray p-3 my-6 mt-4">
					{totalData && (
						<>
							<table className="rapportage_table totaal_rapportage_table reporttable">
								<thead>
									<tr>
										<th colSpan="4">{`${uctrans("report.total")} ${formValues.year - 1}`}</th>
									</tr>
									<tr className="head2 text-sm">
										{map(formInfo.loanStatusOptions, (option, key) => (
											<td key={key} className="gesprekken huidig">
												<span>{key} (x1.000)</span>
											</td>
										))}
										<td className="gesprekken min1">Gespr.</td>
									</tr>
								</thead>
								<tbody>
									<tr className="waarden text-sm">
										<td className="omzet min1 status">
											<FormattedNumberText prefix="€ " value={totalData.sum_0_210 ? totalData.sum_0_210 / 1000 : 0} />
										</td>
										<td className="omzet min1 status">
											<FormattedNumberText prefix="€ " value={totalData.sum_0_220 ? totalData.sum_0_220 / 1000 : 0} />
										</td>
										<td className="omzet min1 status">
											<FormattedNumberText prefix="€ " value={totalData.sum_0_320 ? totalData.sum_0_320 / 1000 : 0} />
										</td>
										<td className="gesprekken min1">
											<FormattedNumberText value={totalData.c_0_nr_of_conversations || 0} />
										</td>
									</tr>
								</tbody>
							</table>
							<table className="rapportage_table totaal_rapportage_table reporttable mt-3">
								<thead>
									<tr>
										<th colSpan="4">{`${uctrans("report.total")} ${formValues.year}`}</th>
									</tr>
									<tr className="head2 text-sm">
										{map(formInfo.loanStatusOptions, (option, key) => (
											<td className="gesprekken huidig">
												<span className="text-italic">{key} (x1.000)</span>
											</td>
										))}
										<td className="gesprekken huidig">Gespr.</td>
									</tr>
								</thead>
								<tbody>
									<tr className="waarden text-sm">
										<td className="omzet status">
											<FormattedNumberText prefix="€ " value={totalData.sum_1_210 ? totalData.sum_1_210 / 1000 : 0} />
										</td>
										<td className="omzet status">
											<FormattedNumberText prefix="€ " value={totalData.sum_1_220 ? totalData.sum_1_220 / 1000 : 0} />
										</td>
										<td className="omzet status">
											<FormattedNumberText prefix="€ " value={totalData.sum_1_320 ? totalData.sum_1_320 / 1000 : 0} />
										</td>
										<td className="gesprekken huidig">
											<FormattedNumberText value={totalData.c_1_nr_of_conversations || 0} />
										</td>
									</tr>
								</tbody>
							</table>
						</>
					)}
					<div className="flex">
						<div className="w-1/2 mb-3">
							<IconButton
								icon="file-text-outline"
								onClick={() => generatePdf()}
								content={uctrans("report.generate_pdf_report")}
								primary
							/>
						</div>
						<div className="w-1/2 mb-3">
							<IconButton
								icon="file-text-outline"
								className="report_download_button"
								onClick={() => generateCsv()}
								content={uctrans("report.generate_csv")}
								primary
							/>
							<Checkmark
								onChange={() => setQualityMonitorResults(!qualityMonitorResults)}
								name="qualitymonitor_results"
								checked={qualityMonitorResults}
								label={uctrans("report.wallet_analysis.with_qualitymonitor_results")}
							/>
							{qualityMonitorResults && (
								<div className="w-full">
									<Select
										isClearable={false}
										placeholder={uctrans("general.select_:item", {}, { item: "report.reference_date" })}
										value={filter(formInfo.referenceDates, ({ value }) => `${value}` === `${referenceDate}`)}
										options={formInfo.referenceDates}
										name="reference_date_csv"
										onChange={value => (value ? setReferenceDate(value.value) : null)}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div>
				<AbnOverview
					indexUrl={route("crm.report.wallet-analysis.data")}
					dataUpdatedAt={overviewUpdatedAt}
					extraQuerystringParameters={formValues}
					reloadedData={response => onOverviewChange(response)}
					modelTranslations={{
						plural: uctrans("report.results"),
						singular: uctrans("report.results"),
					}}
					renderBody={() => (
						<>
							<div className="w-full mt-2 text-white flex bg-primary p-2">
								<span className="ml-1">{title ? title : ""}</span>
							</div>
							<div className="w-full  bg-white flex ">
								<div className="w-8/24 p-2">{uctrans("intermediaries.singular")}</div>
								<div className="w-6/24 p-2 border-l">
									{formValues ? formValues.year - 1 : ""} <span className="text-xs italic">(x 1.000)</span>{" "}
								</div>
								<div className="w-2/24 p-2 border-l">{formValues ? formValues.year - 1 : ""}</div>
								<div className="w-6/24 p-2 border-l">
									{formValues ? formValues.year : ""} <span className="text-xs italic">(x 1.000)</span>{" "}
								</div>
								<div className="w-2/24 p-2 border-l">{formValues ? formValues.year : ""}</div>
							</div>
							<DataTable>
								<DataColumn
									width={2}
									sortable="company_number"
									renderHeaderContent={() => uctrans("intermediaries.fields.company_number")}
									renderCellContent={row =>
										row.company_number ? <span className="text-sm">{row.company_number}</span> : "-"
									}
								/>
								<DataColumn
									width={4}
									sortable="company_name"
									renderHeaderContent={() => uctrans("intermediaries.fields.name")}
									renderCellContent={row =>
										row.company_name ? <span className="text-sm">{row.company_name}</span> : "-"
									}
								/>
								<DataColumn
									width={2}
									sortable="last_conversation"
									renderHeaderContent={() => uctrans("report.wallet_analysis.last_conversation_short")}
									renderCellContent={row =>
										row.last_conversation && format(row.last_conversation, "dd-MM-y") ? (
											<span className="text-sm">{format(row.last_conversation, "dd-MM-y")}</span>
										) : (
											"-"
										)
									}
								/>
								<DataColumn
									width={2}
									sortable="sum_0_210"
									renderHeaderContent={() => "210"}
									renderCellContent={row => (
										<span className="text-sm">
											<FormattedNumberText prefix="€ " value={row.sum_0_210 ? row.sum_0_210 / 1000 : 0} />
										</span>
									)}
									borderLeft
								/>
								<DataColumn
									width={2}
									sortable="sum_0_220"
									renderHeaderContent={() => "220"}
									renderCellContent={row => (
										<span className="text-sm">
											<FormattedNumberText prefix="€ " value={row.sum_0_220 ? row.sum_0_220 / 1000 : 0} />
										</span>
									)}
								/>
								<DataColumn
									width={2}
									sortable="sum_0_320"
									renderHeaderContent={() => "320"}
									renderCellContent={row => (
										<span className="text-sm">
											<FormattedNumberText prefix="€ " value={row.sum_0_320 ? row.sum_0_320 / 1000 : 0} />
										</span>
									)}
								/>
								<DataColumn
									width={2}
									sortable="c_0_nr_of_conversations"
									renderHeaderContent={() => uctrans("report.wallet_analysis.nr_of_conversations")}
									renderCellContent={row =>
										row.c_0_nr_of_conversations ? <span className="text-sm">{row.c_0_nr_of_conversations}</span> : "-"
									}
									borderLeft
								/>
								<DataColumn
									width={2}
									sortable="sum_1_210"
									renderHeaderContent={() => "210"}
									renderCellContent={row => (
										<span className="text-sm">
											<FormattedNumberText prefix="€ " value={row.sum_1_210 ? row.sum_1_210 / 1000 : 0} />
										</span>
									)}
									borderLeft
								/>
								<DataColumn
									width={2}
									sortable="sum_1_220"
									renderHeaderContent={() => "220"}
									renderCellContent={row => (
										<span className="text-sm">
											<FormattedNumberText prefix="€ " value={row.sum_1_220 ? row.sum_1_220 / 1000 : 0} />
										</span>
									)}
								/>
								<DataColumn
									width={2}
									sortable="sum_1_320"
									renderHeaderContent={() => "320"}
									renderCellContent={row => (
										<span className="text-sm">
											<FormattedNumberText prefix="€ " value={row.sum_1_320 ? row.sum_1_320 / 1000 : 0} />
										</span>
									)}
								/>
								<DataColumn
									width={2}
									sortable="c_1_nr_of_conversations"
									renderHeaderContent={() => uctrans("report.wallet_analysis.nr_of_conversations")}
									renderCellContent={row =>
										row.c_1_nr_of_conversations ? <span className="text-sm">{row.c_1_nr_of_conversations}</span> : "-"
									}
									borderLeft
								/>
							</DataTable>
						</>
					)}
				/>
			</div>
		</div>
	);
}

WalletAnalysisPeriodReport.propTypes = {
	formInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	formValues: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	setFormValues: PropTypes.func.isRequired,
};
