import PropTypes from "prop-types";
import { useContext, useMemo } from "react";
import { TableContext } from "./table-context";

export default function SimpleTableColumns({ children }) {
	const data = useContext(TableContext);

	const filteredChildrenColumns = useMemo(() => {
		const data = Array.isArray(children) ? children : [children];
		return data.filter(child => child && child.type.displayName === "SimpleTableColumn");
	}, [children]);

	return (
		<div className="overview">
			<div className="overview-header">
				{filteredChildrenColumns.map((header, i) => (
					<div className={`overview-header-cell ${header.props.cellWidth}`} key={i}>
						<span>{header.props.renderHeaderContent(header)}</span>
					</div>
				))}
			</div>
			{data.map((item, i) => (
				<div className="overview-item" key={i}>
					{filteredChildrenColumns.map((column, j) => (
						<div className={`overview-item-cell ${column.props.cellWidth}`} key={j}>
							{column.props.renderBodyContent(item, column)}
						</div>
					))}
				</div>
			))}
		</div>
	);
}

SimpleTableColumns.propTypes = {
	children: PropTypes.node.isRequired,
};
