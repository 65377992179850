import PropTypes from "prop-types";
import { useState } from "react";
import { can, route } from "../../../../../js/helpers";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";

const QuestionnaireTabs = ({ questionnaireId, initialActiveTabIndex, onActiveTabChange }) => {
	const [activeTab, setActiveTab] = useState(initialActiveTabIndex);

	const switchTab = (url, tabIndex) => {
		url = `${url}#tab-${tabIndex}`;
		if (tabIndex !== activeTab) {
			if (
				tabIndex === 3 ||
				tabIndex === 4 ||
				((activeTab === 3 || activeTab === 4) && (tabIndex === 0 || tabIndex === 1))
			) {
				Nav.go(url);
			} else {
				setActiveTab(tabIndex);
				window.location.hash = `tab-${tabIndex}`;
				if (onActiveTabChange) {
					onActiveTabChange(tabIndex);
				}
			}
		} else {
			if (window.location.href === url) {
				location.reload();
			} else {
				Nav.go(url);
			}
		}
	};

	const tabIsActive = tabIndex => (tabIndex === activeTab ? "active" : "");

	return (
		<div className="-mt-6">
			<ul className="flex tabs shadow -mx-12-mt-6">
				<li
					className={`tab-item ${tabIsActive(0)}`}
					onClick={() =>
						switchTab(
							can("questionnaire.questionnaire", "update")
								? route("crm.questionnaires.edit", { questionnaire: questionnaireId })
								: route("crm.questionnaires.show", { questionnaire: questionnaireId }),
							0,
						)
					}>
					<a className="tab-link">{uctrans("questionnaires.singular")}</a>
				</li>
				{can("questionnaire.questionnaire", "update") && (
					<li
						className={`tab-item ${tabIsActive(1)}`}
						onClick={() => switchTab(route("crm.questionnaires.edit", { questionnaire: questionnaireId }), 1)}>
						<a className="tab-link">{uctrans("questionnaires.question.plural")}</a>
					</li>
				)}
				<li className="tab-item">
					<a className="tab-link" />
				</li>
				<li
					className={`tab-item ${tabIsActive(3)}`}
					onClick={() =>
						switchTab(route("crm.questionnaire_completions.index", { questionnaire: questionnaireId }), 3)
					}>
					<a className="tab-link">{uctrans("questionnaires.questionnaire_completion.plural")}</a>
				</li>
				<li
					className={`tab-item ${tabIsActive(4)}`}
					onClick={() =>
						switchTab(route("crm.questionnaire_completions.statistics", { questionnaire: questionnaireId }), 4)
					}>
					<a className="tab-link">{uctrans("questionnaires.statistics")}</a>
				</li>
			</ul>
		</div>
	);
};

QuestionnaireTabs.propTypes = {
	questionnaireId: PropTypes.number.isRequired,
	initialActiveTabIndex: PropTypes.number,
	onActiveTabChange: PropTypes.func,
};

export default QuestionnaireTabs;
