import PropTypes from "prop-types";
import { uctrans } from "../../../../../../js/lib/Translator";
import HighChart from "../../report/graph/HighChart";

const ProcessLineChart = props => (
	<HighChart
		chartConfig={{
			chart: {
				height: "300px",
				backgroundColor: null,
			},
			title: {
				text: null,
			},
			legend: {
				enabled: true,
				align: "center",
				verticalAlign: "top",
				floating: true,
				itemStyle: {
					fontSize: "10px",
					fontWeight: "normal",
				},
			},
			yAxis: {
				title: {
					text: null,
				},
			},
			xAxis: {
				min: Date.UTC(props.year, 0, 0),
				max: Date.UTC(props.year, 11, 31),
				type: "datetime",
				tickInterval: 24 * 3600 * 1000 * 30,
				dateTimeLabelFormats: {
					month: "%b",
				},
			},
			tooltip: {
				xDateFormat: "%b %Y",
				pointFormat: "{point.y:,.0f}",
			},
			series: [
				{
					name: uctrans("discuss_requests.rm_dashboard.amount_of_discuss_requests"),
					data: props.data
						? props.data.map(data => [
								Date.parse(`${props.year}-${data.month < 10 ? "0" : ""}${data.month}-01`),
								data.value,
						  ])
						: [],
					color: "#009286",
				},
			],
		}}
	/>
);

ProcessLineChart.propTypes = {
	year: PropTypes.number,
	data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default ProcessLineChart;
