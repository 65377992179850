import PropTypes from "prop-types";
import { useState } from "react";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";

export default function ContactmomentSubjectContent(props) {
	const [collapsed, setCollapsed] = useState(props.initialCollapseState);

	const handleSetCollapse = () => {
		const newCollapsedState = !collapsed;
		setCollapsed(newCollapsedState);
		props.onCollapse(props.page, newCollapsedState);
	};

	return (
		<div className={props.className}>
			<ul>
				<li className="cursor-pointer clear-right" onClick={handleSetCollapse}>
					<Checkmark checked={!collapsed} />
					<h6 className="inline-block ml-8">{props.page.title}</h6>
					<EvaIcon
						className="float-right"
						type={collapsed ? "arrow-ios-downward" : "arrow-ios-upward"}
						fill="#009286"
						height="16"
						width="16"
					/>
				</li>
			</ul>

			{!collapsed && <div className="mt-3">{props.children}</div>}
		</div>
	);
}

ContactmomentSubjectContent.propTypes = {
	initialCollapseState: PropTypes.bool,
	onCollapse: PropTypes.func,
	page: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.any,
};

ContactmomentSubjectContent.defaultProps = {
	initialCollapseState: true,
};
