import PropTypes from "prop-types";
import { useState } from "react";
import axios from "axios";
import map from "lodash/map";
import Async from "react-select/async";
import { uctrans } from "../../../../lib/Translator";

export default function AsyncAutoComplete(props) {
	const [value, setValue] = useState(props.initialItem ? props.initialItem : "");
	const [searching, setSearching] = useState(false);

	const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

	const styles = {
		control: (base, state) => ({
			...base,
			minHeight: 47,
			lineHeight: 2.3,
			borderRadius: 0,
			boxShadow: "none",
			borderColor: state.isSelected ? "#9c9c9c" : "#cccccc" && state.isFocused ? "#9c9c9c" : "#cccccc",
			"&:hover": {
				borderColor: "#9c9c9c",
			},
		}),
		indicatorSeparator: base => ({
			...base,
			display: "none",
		}),
		dropdownIndicator: base => ({
			...base,
			display: "none",
		}),
		menu: base => ({
			...base,
			display: searching ? "block" : "none",
			zIndex: 20,
		}),
		clearIndicator: base => ({
			...base,
			display: props.isClearable ? "block" : "none",
		}),
		option: (base, state) => ({
			...base,
			color: state.isSelected ? "#ffffff" : "#31353e",
			background: state.isSelected ? "#009286" : "#ffffff" && state.isFocused ? "#D7F0EE" : "#ffffff",
			"&:active": {
				background: "#D7F0EE",
			},
		}),
	};

	const loadOptions = async inputValue => {
		if (props.loadOptions) {
			return props.loadOptions(inputValue);
		} else {
			await wait(400);

			const params = {};
			params[`filters[search]`] = inputValue;
			if (typeof props.exclude !== "undefined" && props.exclude.length) {
				params.exclude = props.exclude;
			}
			const result = await axios.get(props.dataSource, { params });
			if (props.selectedItems) {
				if (Array.isArray(props.selectedItems)) {
					return result.data.filter(
						item => map(props.selectedItems, e => Number(e.value)).indexOf(Number(item.value)) === -1,
					);
				} else if (typeof props.selectedItems === "object") {
					return result.data.filter(item => Number(props.selectedItems.value) !== Number(item.value));
				}
			}

			return result.data;
		}
	};

	const onChange = data => {
		setValue(data);

		if (props.onChange) {
			props.onChange(data);
		}

		if (props.clearOnSelect) {
			setValue(null);
		}
	};

	return (
		<Async
			loadOptions={loadOptions}
			placeholder={props.placeholder}
			onInputChange={input => (input.length ? setSearching(true) : setSearching(false))}
			noOptionsMessage={() => uctrans("general.no_results")}
			loadingMessage={() => uctrans("general.loading")}
			onChange={onChange}
			value={value}
			styles={styles}
			isClearable={props.isClearable}
			isDisabled={props.disabled}
		/>
	);
}

AsyncAutoComplete.propTypes = {
	placeholder: PropTypes.string,
	dataSource: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	loadOptions: PropTypes.func,
	clearOnSelect: PropTypes.bool,
	selectedItems: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.number]),
	isClearable: PropTypes.bool,
	initialItem: PropTypes.any,
	disabled: PropTypes.bool,
	exclude: PropTypes.array,
};

AsyncAutoComplete.defaultProps = {
	placeholder: uctrans("general.choose"),
	clearOnSelect: false,
	showItemsOnFirstClick: false,
	exclude: [],
};
