import { useState } from "react";
import Dialog from "../../../../../js/react/components/general/Dialog";
import { uctrans, trans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import { can, route, numberFormat } from "../../../../../js/helpers";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import classNames from "classnames";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";

import Navigation from "./Navigation";
import CompanyTargetForm from "./CompanyTargetForm";
import Translate from "../../../../../js/react/components/general/Translate";

export default function CompanyTargetDashboardOverview() {
	const overviewData = { ...window.data.overview };
	const staticData = { ...window.data.static_data };

	const targetYears = [...staticData.targetYears];
	const targetYear = { ...staticData.targetYear };
	const yearlyRevenues = [...staticData.yearlyRevenues];

	const [overviewDataUpdatedAt, setOverviewDataUpdatedAt] = useState(new Date().getTime());
	const [currentCompanyTarget, setCurrentCompanyTarget] = useState(null);
	const [currentCompany, setCurrentCompany] = useState(null);
	const [differenceInRevenue, setDifferenceInRevenue] = useState(
		staticData.distributedRevenue - staticData.revenueGoal,
	);
	const [distributedRevenue, setDistributedRevenue] = useState(staticData.distributedRevenue);

	const openCompanyTargetForm = (company, companyTarget) => {
		setCurrentCompany(company);

		setCurrentCompanyTarget({
			id: companyTarget === null ? -1 : companyTarget.id,
			company_id: company.id,
			sum: companyTarget === null ? "" : companyTarget.sum,
			count: companyTarget === null ? "" : companyTarget.count,
			contactmoments: companyTarget === null ? "" : companyTarget.contactmoments,
		});
	};

	const handleSuccess = difference => {
		const newDifferenceInRevenue = differenceInRevenue + difference;
		setDifferenceInRevenue(newDifferenceInRevenue);

		const newDistributedRevenue = distributedRevenue + difference;
		setDistributedRevenue(newDistributedRevenue);

		setOverviewDataUpdatedAt(new Date().getTime());
		setCurrentCompanyTarget(null);
	};

	const getCompanyTargetByYear = (companyTargets, year) => {
		const foundCompanyTarget = companyTargets.find(companyTarget => companyTarget.target_year_id === year.id);

		if (typeof foundCompanyTarget !== "undefined") {
			return foundCompanyTarget;
		}

		return null;
	};

	const getCompanyRevenueByYear = (companyRevenues, year) => {
		const foundCompanyRevenue = companyRevenues.find(companyRevenue => companyRevenue.year === year.year);

		if (typeof foundCompanyRevenue !== "undefined") {
			return `€ ${numberFormat(foundCompanyRevenue.sum, 0)}`;
		}

		return "-";
	};

	const getCompanyTargetByYearValue = (companyTargets, year) => {
		const foundCompanyTarget = getCompanyTargetByYear(companyTargets, year);

		return foundCompanyTarget === null ? "-" : `€ ${numberFormat(foundCompanyTarget.sum, 0)}`;
	};

	return (
		<>
			<Navigation selectedTab="companyTargetsDashboard" targetYear={staticData.targetYear} />

			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans("targets.company_target_dashboard")} {staticData.relationmanager.full_name}
				</h1>
			</div>

			<div className="flex">
				<div className="w-1/4 mr-8">
					<div className="bg-grey-lightest shadow rounded flex flex-col w-full">
						<div className="shadow rounded-t-lg flex">
							<div className="px-4 py-4 mr-auto">
								<h5 className="mb-0">
									<Translate content="targets.total_title" />
								</h5>
							</div>
						</div>
						<div className="p-4 flex">
							<div className="w-full">
								<ul>
									{yearlyRevenues.map(yearlyRevenue => (
										<li className="flex mb-1" key={yearlyRevenue.year}>
											<div className="w-1/2">{yearlyRevenue.year}</div>
											<div className="w-1/2">{`€ ${numberFormat(yearlyRevenue.revenue, 0)}`}</div>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="w-1/4">
					<div className="bg-grey-lightest shadow rounded flex flex-col w-full">
						<div className="shadow rounded-t-lg flex">
							<div className="px-4 py-4 mr-auto">
								<h5 className="mb-0">
									<Translate content="targets.distribution" /> {targetYear.year}
								</h5>
							</div>
						</div>
						<div className="p-4 flex">
							<div className="w-full">
								<ul>
									<li className="flex mb-1">
										<div className="w-1/2">
											<Translate content="targets.goal" /> RM
										</div>
										<div className="w-1/2">{`€ ${numberFormat(staticData.revenueGoal, 0)}`}</div>
									</li>
									<li className="flex mb-1">
										<div className="w-1/2">
											<Translate content="targets.distributed" />
										</div>
										<div className="w-1/2">{`€ ${numberFormat(distributedRevenue, 0)}`}</div>
									</li>
									<li className="flex mb-1">
										<div className="w-1/2">
											<Translate content="targets.difference" />
										</div>
										<div
											className={classNames("w-1/2 error", {
												"text-green": differenceInRevenue >= 0,
												"text-red": differenceInRevenue < 0,
											})}>{`€ ${numberFormat(differenceInRevenue, 0)}`}</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			<AbnOverview
				initialData={overviewData}
				dataUpdatedAt={overviewDataUpdatedAt}
				indexUrl={route("crm.targets.company-targets-dashboard.index", { target_year: staticData.targetYear })}
				modelTranslations={{
					plural: trans("targets.companies.plural"),
					singular: trans("targets.companies.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
						<SelectFilter
							className="form-1-3"
							name="company_status_type"
							label={uctrans("intermediaries.fields.classification")}
							options={overviewData.metadata.filterdata.company_status_types}
							collapsible
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={2}
							sortable="company_number"
							renderHeaderContent={() => uctrans("targets.company_target.fields.company_number")}
							renderCellContent={company => company.company_number}
						/>
						<DataColumn
							width={3}
							sortable="name"
							renderHeaderContent={() => uctrans("targets.company_target.fields.name")}
							renderCellContent={company => company.name}
						/>

						{targetYears.length >= 3 && (
							<DataColumn
								width={1}
								renderHeaderContent={() => `${uctrans("targets.revenue")} ${targetYears[2].year}`}
								renderCellContent={company => getCompanyRevenueByYear(company.yearlyRevenues, targetYears[2])}
							/>
						)}

						{targetYears.length >= 2 && (
							<DataColumn
								width={1}
								renderHeaderContent={() => `${uctrans("targets.revenue")} ${targetYears[1].year}`}
								renderCellContent={company => getCompanyRevenueByYear(company.yearlyRevenues, targetYears[1])}
							/>
						)}

						<DataColumn
							width={1}
							renderHeaderContent={() => `${uctrans("targets.revenue")} ${targetYears[0].year}`}
							renderCellContent={company => getCompanyRevenueByYear(company.yearlyRevenues, targetYears[0])}
						/>

						<DataColumn
							width={1}
							renderHeaderContent={() => `${uctrans("targets.goal")} ${targetYear.year}`}
							renderCellContent={company => getCompanyTargetByYearValue(company.companyTargets, targetYear)}
						/>
						{can("targets.company_target", "create") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => (
									<>
										<Translate content="targets.singular" /> {targetYear.year}
									</>
								)}
								renderCellContent={company => (
									<a
										onClick={() =>
											openCompanyTargetForm(company, getCompanyTargetByYear(company.companyTargets, targetYear))
										}
										className="link">
										{getCompanyTargetByYear(company.companyTargets, targetYear) === null ? (
											<Translate content="general.add_:item" transReplaces={{ item: `targets.singular` }} />
										) : (
											<Translate content="general.edit_:item" transReplaces={{ item: `targets.singular` }} />
										)}
									</a>
								)}
							/>
						)}
					</DataTable>
				)}
			/>
			{currentCompanyTarget !== null && (
				<Dialog
					isOpen={currentCompanyTarget !== null}
					title={
						currentCompanyTarget.id === -1
							? uctrans("general.add_:item", {}, { item: "targets.company_target.singular" })
							: `${uctrans("general.edit_:item", {}, { item: "targets.company_target.singular" })} ${targetYear.year}`
					}
					isFullScreen
					width={900}
					onClose={() => setCurrentCompanyTarget(null)}>
					<CompanyTargetForm
						selectedCompany={currentCompany}
						companyTarget={currentCompanyTarget}
						targetYear={staticData.targetYear}
						onSuccess={difference => handleSuccess(difference)}
						onCancel={() => setCurrentCompanyTarget(null)}
					/>
				</Dialog>
			)}
		</>
	);
}
