import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import HighChart from "../../report/graph/HighChart";
export default function RmDashboardPiechart(props) {
	const [walletSeriesData, setWalletSeriesData] = useState([]);
	const [totalPercentages, setTotalPercentages] = useState([]);

	useEffect(() => {
		if (props.walletData.length) {
			const newWalletSeriesData = [];
			props.walletData.forEach(({ label, value }) => {
				newWalletSeriesData.push({
					name: label,
					y: value / props.divideTooltipValueBy,
				});
			});
			setWalletSeriesData(newWalletSeriesData);
		} else {
			setWalletSeriesData([]);
		}
	}, [props.walletData, props.divideTooltipValueBy]);

	useEffect(() => {
		if (props.totalData.length) {
			let total = 0;
			const newTotalPercentages = [];
			props.totalData.forEach(({ value }) => {
				total += value;
			});
			props.totalData.forEach(({ label, value }) => {
				newTotalPercentages.push({
					label,
					percentage: Math.round((value / total) * 100),
				});
			});
			setTotalPercentages(newTotalPercentages);
		} else {
			setTotalPercentages([]);
		}
	}, [props.totalData]);

	const chartConfig = {
		title: {
			text: null,
		},
		chart: {
			type: "pie",
			marginTop: 0,
			marginRight: 0,
			marginBottom: 0,
			marginLeft: 0,
			spacingTop: 0,
			spacingRight: 0,
			spacingBottom: 0,
			spacingLeft: 0,
			backgroundColor: null,
			height: `${props.height}px`,
		},

		legend: {
			enabled: true,
			align: "center",
			verticalAlign: "top",
			layout: "horizontal",
			floating: true,
			itemStyle: {
				fontSize: "11px",
				fontWeight: "none",
			},
		},
		series: {
			data: walletSeriesData,
		},
		plotOptions: {
			pie: {
				borderWidth: 1,
				showInLegend: true,
				dataLabels: {
					enabled: true,
					distance: -25,
					format: "{point.percentage:.0f}%",
					color: "#31353e",
					style: {
						textOutline: "none",
						fontSize: "13px",
					},
				},
				size: "130",
				tooltip: {
					pointFormat: `${props.charBeforeTooltip}{point.y:,.0f}`,
				},
			},
		},
	};

	if (props.colors) {
		chartConfig.colors = props.colors;
	}

	return (
		<div>
			{props.title && (
				<h6>
					{props.link ? (
						<a href={props.link}>
							{props.title}
							<span className="inline-block ml-1 align-middle">
								<EvaIcon type="arrow-circle-right-outline" fill="#009286" height="18" width="18" />
							</span>
						</a>
					) : (
						props.title
					)}
				</h6>
			)}

			<div className="flex justify-start">
				<div style={{ width: `${props.width}px` }}>
					<HighChart chartConfig={chartConfig} />
				</div>
				{props.walletType !== "total" && (
					<div className="flex items-center">
						{props.totalData && (
							<ul className="border-l p-2 text-sm">
								<li>{uctrans("dashboard.nationwide")}</li>
								{totalPercentages.map(({ percentage, label }) => (
									<li key={label}>
										{percentage}% {label}
									</li>
								))}
							</ul>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

RmDashboardPiechart.propTypes = {
	title: PropTypes.string,
	link: PropTypes.string,
	walletType: PropTypes.string.isRequired,
	walletData: PropTypes.array,
	totalData: PropTypes.array,
	charBeforeTooltip: PropTypes.string,
	divideTooltipValueBy: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number,
	colors: PropTypes.array,
};

RmDashboardPiechart.defaultProps = {
	charBeforeTooltip: "",
	divideTooltipValueBy: 1,
	width: 250,
	height: 200,
};
