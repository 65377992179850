import { map } from "lodash";
import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
import { can } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import AddressForm from "../../../../../js/react/components/addresses/AddressForm";
import CommunicationPreferences from "../../../../../js/react/components/communication/CommunicationPreferences";
import ImageThumbnailFileInput from "../../../../../js/react/components/general/form/ImageThumbnailFileInput";
import Translate from "../../../../../js/react/components/general/Translate";
import cloneDeep from "lodash/cloneDeep";
import Select from "../../../../../js/react/components/general/Select";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import CompanyFormContext from "../company/CompanyFormContext";
import CollapsibleContent from "../general/CollapsibleContent";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import StatutoryCompanyForm from "../intermediaries/statutory/StatutoryCompanyForm";

export default function OrganisationFormFields(props) {
	const [formData, setFormData] = useState(
		typeof props.companyFormData === "undefined" ? cloneDeep(window.data.company_form_data) : props.companyFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.companyStaticData === "undefined"
			? cloneDeep(window.data.company_static_data)
			: props.companyStaticData,
	);

	const [originalMainCompany] = useState(
		formData.statutory_company && `${formData.statutory_company.main_company_id}` === `${formData.id}`,
	);
	const [hasStatutoryCompanyOriginal] = useState(formData.statutory_company_id !== null);

	const readonly = useMemo(() => {
		if (!can("organisation", "update_administrative") && !can("organisation", "update_all")) {
			return true;
		}
	}, []);

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
		props.onChange(newFormData);
	};

	const handleAddressInputChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData.addresses[key] = value;
		setFormData(newFormData);
		props.onChange(newFormData);
	};

	useEffect(() => {
		if (typeof props.companyFormData !== "undefined") {
			setFormData(props.companyFormData);
		}
	}, [props.companyFormData]);

	useEffect(() => {
		if (typeof props.companyStaticData !== "undefined") {
			setStaticData(props.companyStaticData);
		}
	}, [props.companyStaticData]);

	return (
		<CompanyFormContext.Provider
			value={{
				handleCompanyValueChange: data => {
					setFormData(data);
					props.onChange(data);
				},
				company: formData,
				statutoryConnectTypes: staticData.statutory_company_connect_types,
				handleCompanyFormValueChange: handleFormValueChange,
				hasStatutoryCompanyOriginal,
				originalMainCompany,
			}}>
			<h2>{staticData.form_title}</h2>
			<div className="form-full">
				<CollapsibleContent initialCollapseState={false} title={uctrans("companies.statutory.statutory_company")}>
					<StatutoryCompanyForm />
				</CollapsibleContent>

				<CollapsibleContent initialCollapseState={false} title={uctrans("companies.administrative")}>
					<div className="form-full">
						<div className="form-2-3">
							<label>
								<Translate content="companies.statutory.establishment_type" />
								<span className="text-orange"> *</span>
							</label>

							<RadioGroup
								disabled={formData.id !== -1 ? originalMainCompany || !hasStatutoryCompanyOriginal : false}
								name="establishment_type"
								value={formData.establishment_type}
								onChange={value => handleFormValueChange("establishment_type", value)}>
								<Radio label={uctrans(`companies.statutory.statutory_company__company_type.${1}`)} value={1} />
								<Radio label={uctrans(`companies.statutory.statutory_company__company_type.${2}`)} value={2} />
								<Radio label={uctrans(`companies.statutory.statutory_company__company_type.${3}`)} value={3} />
							</RadioGroup>
						</div>

						<div className="form-1-3">
							<LabeledTextInput
								name="kvk_location_number"
								value={formData.kvk_location_number}
								onChange={e => handleFormValueChange(e.target.name, e.target.value)}
								label="companies.statutory.kvk_location_number"
							/>
						</div>
					</div>

					<div className="form-full">
						<div className="form-2-3">
							<LabeledTextInput
								disabled={!can("organisation", "update_administration") && !can("organisation", "update_all")}
								name="name"
								value={formData.name}
								onChange={e => handleFormValueChange(e.target.name, e.target.value)}
								label="organisations.fields.name"
								required
							/>
						</div>

						<div className="form-1-3">
							<LabeledTextInput
								disabled={!can("organisation", "update_all")}
								name="organisation_number"
								value={formData.organisation_number}
								onChange={e => handleFormValueChange(e.target.name, e.target.value)}
								label="organisations.fields.organisation_number"
							/>
						</div>

						<div className="form-1-2">
							<label htmlFor="relation_manager">
								<Translate content="organisations.fields.relation_manager_organisation" />
							</label>
							<Select
								disabled={!can("organisation", "update_administration") && !can("organisation", "update_all")}
								placeholder={uctrans("general.select_:item", {}, { item: "organisations.fields.relationmanager_id" })}
								value={staticData.relationmanagers.filter(
									({ value }) => Number(value) === Number(formData.relationmanager_id),
								)}
								options={staticData.relationmanagers}
								name="relationmanager_id"
								onChange={(value, metadata) => handleFormValueChange(metadata.name, value ? value.value : "")}
							/>
						</div>

						<div className="form-1-2">
							<LabeledTextInput
								disabled={!can("organisation", "update_all")}
								name="company_number"
								value={formData.company_number}
								onChange={e => handleFormValueChange(e.target.name, e.target.value)}
								label="organisations.fields.company_number"
							/>
						</div>

						<div className="form-1-2">
							<label htmlFor="organisation_type">
								<Translate content="organisations.fields.organisation_type" />
							</label>
							<RadioGroup
								disabled={!can("organisation", "update_administration") && !can("organisation", "update_all")}
								name="organisation_type"
								value={formData.organisation_type}
								onChange={value => handleFormValueChange("organisation_type", value)}>
								{map(staticData.organisation_types, organisation_type => (
									<Radio label={organisation_type.label} key={organisation_type.key} value={organisation_type.key} />
								))}
							</RadioGroup>
						</div>

						<div className="form-1-2">
							<label htmlFor="team">
								<Translate content="organisations.fields.team_id" />
							</label>
							<Select
								disabled={!can("organisation", "update_administration") && !can("organisation", "update_all")}
								placeholder={uctrans("general.select_:item", {}, { item: "organisations.fields.team_id" })}
								value={staticData.teams.filter(({ value }) => Number(value) === Number(formData.team_id))}
								options={staticData.teams}
								name="team_id"
								onChange={(value, metadata) => handleFormValueChange(metadata.name, value ? value.value : "")}
							/>
						</div>
					</div>
					<div className="form-full">
						<div className="form-1-2">
							<label htmlFor="company_logo">
								<Translate content="organisations.fields.company_logo" />
							</label>
							<ImageThumbnailFileInput
								storeRoute="crm.files.store"
								newFileDownloadRoute="crm.files.download"
								existingFileDownloadRoute="crm.organisations.company-logo-download"
								maxFiles={1}
								acceptedExtensions={["jpg", "png"]}
								image={formData.company_logo}
								onChange={files => handleFormValueChange("company_logo", files)}
								disabled={readonly}
							/>
						</div>
						<div className="form-1-3">
							<label htmlFor="consent">
								<Translate content="organisations.fields.consent" />
								<span className="text-orange"> *</span>
							</label>
							<RadioGroup
								name="consent"
								value={formData.consent}
								onChange={value => handleFormValueChange("consent", value)}
								disabled={readonly}>
								<Radio label={uctrans("general.yes")} value />
								<Radio label={uctrans("general.no")} value={false} />
							</RadioGroup>
						</div>
					</div>
				</CollapsibleContent>

				<div className="form-full">
					<CollapsibleContent initialCollapseState={false} title={uctrans("addresses.fields.visiting_address")}>
						<AddressForm
							disabled={!can("organisation", "update_administration") && !can("organisation", "update_all")}
							addressFormData={formData.addresses[staticData.address_types.VISITING]}
							onChange={value => handleAddressInputChange(staticData.address_types.VISITING, value)}
							useCopyButton
							useAddition
							copyLabel={uctrans("addresses.fields.copy_to_postal_address")}
							useAdditioncopyLabel={uctrans("addresses.fields.copy_to_postal_address")}
							onCopy={value => handleAddressInputChange(staticData.address_types.POSTAL, value)}
						/>
					</CollapsibleContent>
					<CollapsibleContent initialCollapseState={false} title={uctrans("addresses.fields.postal_address")}>
						<AddressForm
							disabled={!can("organisation", "update_administration") && !can("organisation", "update_all")}
							addressFormData={formData.addresses[staticData.address_types.POSTAL]}
							onChange={value => handleAddressInputChange(staticData.address_types.POSTAL, value)}
							useAddition
						/>
					</CollapsibleContent>

					<CollapsibleContent initialCollapseState={false} title={uctrans("intermediaries.fields.contact")}>
						<div className="form-1-2">
							<LabeledTextInput
								name="email_address"
								label="intermediaries.fields.email_address"
								value={formData.email_address}
								onChange={e => handleFormValueChange(e.target.name, e.target.value)}
							/>
						</div>
						<div className="form-1-2">
							<LabeledTextInput
								name="ishs_email_address"
								label="organisations.fields.ishs_email_address"
								value={formData.ishs_email_address}
								onChange={e => handleFormValueChange(e.target.name, e.target.value)}
							/>
						</div>
						<div className="form-1-2">
							<LabeledTextInput
								name="phonenumber"
								label="intermediaries.fields.phonenumber"
								value={formData.phonenumber}
								onChange={e => handleFormValueChange(e.target.name, e.target.value)}
							/>
						</div>
						<div className="form-1-2">
							<LabeledTextInput
								name="website"
								label="general.website"
								value={formData.website}
								onChange={e => handleFormValueChange(e.target.name, e.target.value)}
							/>
						</div>
					</CollapsibleContent>

					<CollapsibleContent className="form-full" title={uctrans("overige informatie")}>
						<div className="form-full">
							<label htmlFor="organisation_annually_cooperation_check">
								<Translate content="organisations.fields.annually_cooperation_check" />
							</label>
							<RadioGroup
								name="organisation_annually_cooperation_check"
								value={formData.organisation_annually_cooperation_check}
								onChange={value => handleFormValueChange("organisation_annually_cooperation_check", value)}
								disabled={!can("organisation", "update_administration") && !can("organisation", "update_all")}>
								<Radio label={uctrans("general.yes")} value={1} />
								<Radio label={uctrans("general.no")} value={0} />
							</RadioGroup>
						</div>
						<div className="form-full">
							<label htmlFor="organisation_selectable_in_cooperation_request">
								<Translate content="organisations.fields.organisation_selectable_in_cooperation_request" />
							</label>
							<RadioGroup
								name="organisation_selectable_in_cooperation_request"
								value={formData.organisation_selectable_in_cooperation_request}
								onChange={value => handleFormValueChange("organisation_selectable_in_cooperation_request", value)}
								disabled={readonly}>
								<Radio label={uctrans("general.yes")} value={1} />
								<Radio label={uctrans("general.no")} value={0} />
							</RadioGroup>
						</div>
						<div className="form-full">
							<LabeledTextInput
								name="organisation_takeon_email_address"
								label="organisations.fields.organisation_takeon_email_address"
								value={formData.organisation_takeon_email_address}
								onChange={e => handleFormValueChange(e.target.name, e.target.value)}
								disabled={readonly}
							/>
						</div>
					</CollapsibleContent>
					<CollapsibleContent className="form-full" title={uctrans("contactpersons.fields.communication_preferences")}>
						<div className="form-full mb-4">
							<Translate content="organisations.communication_preferences_description" />
						</div>

						<div className="form-full mt-4">
							<CommunicationPreferences
								communicationPreferenceData={formData.unsubscribe_preferences}
								onPreferenceChange={value => handleFormValueChange("unsubscribe_preferences", value)}
								staticNewsLetterSubscriptionTypes={staticData.newsletter_subscription_types}
								unsubscribeReasons={staticData.unsubscribe_reasons}
								disabled={readonly}
								forCrm
							/>
						</div>
					</CollapsibleContent>
				</div>
			</div>
		</CompanyFormContext.Provider>
	);
}

OrganisationFormFields.propTypes = {
	companyFormData: PropTypes.object,
	companyStaticData: PropTypes.object,
	onChange: PropTypes.func.isRequired,
};
