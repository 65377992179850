import PropTypes from "prop-types";
import { format, parse } from "../../../../lib/Date";
import { uctrans } from "../../../../lib/Translator";
import DatePicker from "../DatePicker";
import Filter from "./Filter";
import classNames from "classnames";

class DatePickerFilter extends Filter {
	handleChange(context, value) {
		super.handleChange(context, {
			target: {
				value,
				type: "date",
				name: this.props.name,
				className: this.props.className,
			},
		});
	}

	renderAs(context) {
		const selectedDate = context.data.metadata.filtering.filters[this.props.name] || null;
		const className = classNames("overview-filter filter-select", this.props.className);
		return (
			<div className={className}>
				<label htmlFor="">{this.props.label}</label>
				<DatePicker
					initialValue={selectedDate ? parse(selectedDate) : null}
					name={this.props.name}
					onChange={date => {
						this.handleChange(context, date ? format(date) : null);
					}}
					showClearButton={this.props.showClearButton}
				/>
			</div>
		);
	}
}

export default DatePickerFilter;
DatePickerFilter.propTypes = {
	placeholder: PropTypes.string,
	name: PropTypes.string,
	className: PropTypes.string,
	label: PropTypes.string,
	showClearButton: PropTypes.bool,
};

DatePickerFilter.defaultProps = {
	placeholder: uctrans("general.select_date"),
	showClearButton: true,
};
