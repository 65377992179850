import { useState } from "react";
import IconButton from "../../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../../js/react/components/general/overview/DataColumn";
import DeleteColumnContent from "../../../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route } from "../../../../../../../js/helpers";
import Navigation from "../Navigation";

export default function CooperationTerminationReasonOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<Navigation selectedTab="cooperation_termination_reasons" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("cooperation_terminations.reasons.plural")}</h1>
				{can("agenda.settings.cooperation_termination_reason", "create") && (
					<div>
						<IconButton
							href={route("crm.agenda-settings.cooperation-termination-reasons.create")}
							content={uctrans("general.add_:item", {}, { item: "cooperation_terminations.reasons.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.agenda-settings.cooperation-termination-reasons.index")}
				deleteRouteName="crm.agenda-settings.cooperation-termination-reasons.delete"
				moveRouteName="crm.agenda-settings.cooperation-termination-reasons.move"
				useDragAndDrop={can("agenda.settings.cooperation_termination_reason", "move")}
				hideControls
				modelTranslations={{
					plural: trans("cooperation_terminations.reasons.plural"),
					singular: trans("cooperation_terminations.reasons.singular"),
				}}
				renderBody={() => (
					<DataTable>
						{can("agenda.settings.cooperation_termination_reason", "move") && (
							<DataColumn
								width={1}
								className="draggable-overview-item-handle"
								renderHeaderContent={() => ""}
								renderCellContent={() => ""}
							/>
						)}
						<DataColumn
							width={10}
							sortable="name"
							renderHeaderContent={() => uctrans("cooperation_terminations.reasons.fields.name")}
							renderCellContent={reason => {
								if (can("agenda.settings.cooperation_termination_reason", "update")) {
									return (
										<a
											href={route("crm.agenda-settings.cooperation-termination-reasons.edit", reason.id)}
											className="row-title link">
											{reason.name}
										</a>
									);
								} else {
									return reason.name;
								}
							}}
						/>
						<DataColumn
							width={5}
							sortable="reason"
							renderHeaderContent={() => uctrans("cooperation_terminations.reasons.fields.direct")}
							renderCellContent={reason => (reason.direct ? uctrans("general.yes") : uctrans("general.no"))}
						/>

						{can("agenda.settings.cooperation_termination_reason", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(reason, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(reason)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
