import PropTypes from "prop-types";
import map from "lodash/map";
import { uctrans } from "../../../../../../../js/lib/Translator";
import FormattedNumberText from "../../FormattedNumberText";
import PieChart from "../../graph/PieChart";

export default function ChannelResults({ data, title, staticData, graphData }) {
	const getRow = (entry, total = false) => (
		<tr className={total ? "totaal" : undefined}>
			{total ? <td>{uctrans("report.total")}</td> : <td>{entry.label ? entry.label : "Onbenoemd"}</td>}

			{map(staticData.loan_statuses, status => (
				<>
					<td className="realisatie">
						<FormattedNumberText
							prefix="€ "
							value={
								entry.status && entry.status[status.key] && entry.status[status.key].sum
									? entry.status[status.key].sum / 1000
									: 0
							}
						/>
					</td>
					<td>
						{total ? (
							<FormattedNumberText percentage value={100} />
						) : (
							<FormattedNumberText
								percentage
								value={
									entry.status && entry.status[status.key] && entry.status[status.key].percentage
										? entry.status[status.key].percentage
										: 0
								}
							/>
						)}
					</td>
				</>
			))}
		</tr>
	);

	return (
		<>
			<table className="kanaal_rapportage_table rapportage_table reporttable">
				<thead>
					<tr>
						<th colSpan="11">{title ? title : uctrans("report.singular")}</th>
					</tr>
					<tr className="head1">
						<td colSpan="1" />
						{map(staticData.loan_statuses, (status, key) => (
							<td colSpan="2" className="status_titel" key={key}>
								<span>
									<strong>{status.key}</strong> - {status.label}
								</span>
								<br />
								<span>(€ x 1.000)</span>
							</td>
						))}
					</tr>
					<tr className="head2">
						<td>Naam</td>
						{map(staticData.loan_statuses, () => (
							<>
								<td className="realisatie">Realisatie</td>
								<td className="percentage">%</td>
							</>
						))}
					</tr>
				</thead>
				<tbody>
					{map(data.data, entry => getRow(entry))}
					{data.total && getRow(data.total, true)}
				</tbody>
			</table>

			<table className="merknaam_rapportage_grafiek">
				<tr>
					<td>
						<PieChart chartData={graphData} />
					</td>
				</tr>
			</table>
		</>
	);
}

ChannelResults.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	staticData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	graphData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	title: PropTypes.string,
};
