import PropTypes from "prop-types";
import each from "lodash/each";
import HighChart from "../../../report/graph/HighChart";

const NewsletterStatisticsEventTimelineChart = props => {
	const categories = [];
	const convertEventCountsToSeries = eventCounts => {
		const serieData = [];
		if (Array.isArray(eventCounts)) {
			eventCounts.sort((a, b) => new Date(b.id) - new Date(a.id));
		}
		each(eventCounts, stat_data => {
			serieData.push({
				name: stat_data.label,
				y: stat_data.value,
			});

			if (!categories.includes(stat_data.label)) {
				categories.push(stat_data.label);
			}
		});

		return serieData;
	};

	const series = [
		{ name: "Open", data: convertEventCountsToSeries(props.open_events_per_date) },
		{ name: "Uniek Open", data: convertEventCountsToSeries(props.unique_open_events_per_date) },
	];

	if (props.automatedMessage && props.delivered_events_per_date) {
		series.push({ name: "Delivered", data: convertEventCountsToSeries(props.delivered_events_per_date) });
	}

	return (
		<HighChart
			chartConfig={{
				chart: {
					height: "300px",

					backgroundColor: null,
				},
				credits: {
					enabled: false,
				},
				legend: {
					enabled: true,
				},
				title: {
					text: null,
				},
				yAxis: {
					title: {
						text: "Aantal",
					},
				},
				xAxis: {
					categories,
				},
				series,
			}}
		/>
	);
};

NewsletterStatisticsEventTimelineChart.propTypes = {
	open_events_per_date: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	unique_open_events_per_date: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	delivered_events_per_date: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	automatedMessage: PropTypes.object,
};

NewsletterStatisticsEventTimelineChart.defaultProps = {
	automatedMessage: null,
};

export default NewsletterStatisticsEventTimelineChart;
