import PropTypes from "prop-types";
import { useContext } from "react";
import each from "lodash/each";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import HighChart from "../../report/graph/HighChart";
import QuestionnaireCompletionStatisticsContext from "./QuestionnaireCompletionStatisticsContext";

const QuestionStatisticsColumnChart = props => {
	// const [calculatedAverage, setCalculatedAverage] = useState(null);

	const { column_chart_config } = useContext(QuestionnaireCompletionStatisticsContext);

	const convertQuestionnaireCompletionToSeries = () => {
		const serieData = [];
		each(props.questionnaire_completion_statistics_data, stat_data => {
			serieData.push(stat_data);
		});

		return serieData;
	};

	const options = cloneDeep(column_chart_config);

	options.xAxis.categories = map(props.questionnaire_completion_statistics_data, columnData => columnData.name);
	options.series[0].data = convertQuestionnaireCompletionToSeries();

	return <HighChart chartConfig={options} />;
};

QuestionStatisticsColumnChart.propTypes = {
	questionnaire_completion_statistics_data: PropTypes.object,
};

export default QuestionStatisticsColumnChart;
