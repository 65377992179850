import Tippy from "@tippyjs/react";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import { can, route } from "../../../../../js/helpers";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import CollapsibleContent from "../general/CollapsibleContent";
import ClientTreatmentDividationsSum from "./ClientTreatmentDividationsSum";
import Navigation from "./Navigation";

export default function ClientTreatmentInvitersOverview(props) {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	const [staticData, setStaticData] = useState(
		typeof props.ClietTreatmentStaticData === "undefined"
			? cloneDeep(window.data.client_treatment_static_data)
			: props.ClietTreatmentStaticData,
	);

	useEffect(() => {
		if (typeof props.ClietTreatmentStaticData !== "undefined") {
			setStaticData(props.ClietTreatmentStaticData);
		}
	}, [props.ClietTreatmentStaticData]);

	return (
		<>
			<Navigation
				selectedTab="client-treatment-inviters"
				clientTreatment={staticData.client_treatment}
				clientTreatmentTypes={staticData.client_treatment_types}
				canUpdate={can("client_treatment.client_treatment", "update")}
			/>
			<div className="page-title-container">
				<h1 className="page-title">{`${uctrans("client_treatment.singular")} ${staticData.client_treatment.name}`}</h1>
			</div>

			<div className="min-h-screen md:flex">
				<div className="flex-1 w-2/3 mr-12">
					<AbnOverview
						initialData={overviewData.overview}
						indexUrl={route("crm.client-treatments.inviters", staticData.client_treatment.id)}
						modelTranslations={{
							plural: trans("client_treatment.inviters.plural"),
							singular: trans("client_treatment.inviters.singular"),
						}}
						renderBody={() => (
							<DataTable>
								<DataColumn
									width={5}
									renderHeaderContent={() => uctrans("client_treatment.inviters.fields.by_who")}
									renderCellContent={treatment =>
										treatment.user && (treatment.user.label !== null) !== "undefined" ? treatment.user.label : "-"
									}
								/>
								<DataColumn
									width={5}
									renderHeaderContent={() => uctrans("client_treatment.inviters.fields.amount_to_divide")}
									renderCellContent={treatment => (treatment.amount !== "undefined" ? treatment.amount : "-")}
								/>
								<DataColumn
									width={5}
									renderHeaderContent={() => uctrans("client_treatment.inviters.fields.amount_of_completions")}
									renderCellContent={treatment => {
										if (treatment.divided) {
											return map(treatment.divided, (amount, key) => `${amount} ${key}`).join(", ");
										}

										return uctrans("client_treatment.zero_divided");
									}}
								/>
								{can("client_treatment.participant", "update") && (
									<DataColumn
										width={1}
										renderHeaderContent={() => ""}
										renderCellContent={inviter => (
											<div className="overview-actions">
												{can("client_treatment.inviter", "update") && (
													<a
														href={route("crm.client-treatments.inviters.participants.index", {
															client_treatment: staticData.client_treatment.id,
															client_treatment_inviter: inviter.id,
														})}>
														<Tippy
															placement="left"
															arrow
															animation="perspective"
															duration="400"
															content={uctrans("general.edit")}>
															<span>
																<EvaIcon type="edit-outline" fill="#009286" height="18" width="18" />
															</span>
														</Tippy>
													</a>
												)}
											</div>
										)}
									/>
								)}
							</DataTable>
						)}
					/>
				</div>
				<div className="flex-none w-1/3 border-l pl-12">
					<CollapsibleContent title={uctrans("client_treatment.fields.dividation")} initialCollapseState={false}>
						<ClientTreatmentDividationsSum dividationData={staticData.client_treatment_dividations_data} />
					</CollapsibleContent>
					{!!staticData.client_treatment.self_enrollment && (
						<CollapsibleContent title={uctrans("client_treatment.fields.landing_page")} initialCollapseState={false}>
							<div className="mt-3">
								<p>{`${uctrans("client_treatment.fields.non_personal_landing_page_url")}:`}</p>
								<a
									className="link"
									href={route("website.client-treatment-landing.index", {
										client_treatment_id_token: staticData.client_treatment.id_token,
									})}>
									{route("website.client-treatment-landing.index", {
										client_treatment_id_token: staticData.client_treatment.id_token,
									}).toString()}
								</a>
							</div>
						</CollapsibleContent>
					)}
				</div>
			</div>
		</>
	);
}
ClientTreatmentInvitersOverview.propTypes = {
	ClietTreatmentStaticData: PropTypes.object,
};
