import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { uctrans } from "../../../../../../../js/lib/Translator";
import { CmsTypesContext } from "../../cms-types-context";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import Html from "../attributes/Html";
import PartContent from "./PartContent";
import TableRowList from "./TableRowList";

export default function TableRow(props) {
	const [tableRowData, setTableRowData] = useState(props.values ? props.values : []);
	useEffect(() => {
		if (props.values !== tableRowData) {
			setTableRowData(props.values);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.values]);

	const changeCellContent = (index, content) => {
		const newTableRowData = Array.isArray(tableRowData) ? [...tableRowData] : [];
		if (props.tableType === "table") {
			newTableRowData[index] = { content };
		} else if (props.tableType === "content_table") {
			newTableRowData[index] = content;
		}
		setTableRowData(newTableRowData);
		props.onChange(newTableRowData);
	};

	const moveListItem = dragEvent => {
		if (dragEvent.destination) {
			const sourceIndex = dragEvent.source.index;
			const targetIndex = dragEvent.destination.index;

			const newTableRowData = Array.isArray(tableRowData) ? cloneDeep(tableRowData) : [];

			if (`${sourceIndex}` !== `${targetIndex}`) {
				const item = newTableRowData[sourceIndex];
				newTableRowData.splice(sourceIndex, 1);
				newTableRowData.splice(targetIndex, 0, item);

				setTableRowData(newTableRowData);
				props.onChange(newTableRowData);
			}
		}
	};

	const { partTypes } = useContext(CmsTypesContext);
	const lisPartType = find(partTypes, partType => partType.key === "table_cell");

	if (lisPartType) {
		return (
			<div className="w-full mt-5 mb-12">
				{props.label && <h5>{props.label}</h5>}
				<div className="mt-3 border-l border-grey-light ">
					<DragDropContext onDragEnd={dragEvent => moveListItem(dragEvent)}>
						<Droppable droppableId="droppable">
							{provided => (
								<div ref={provided.innerRef} {...provided.droppableProps}>
									{(() =>
										[...Array(props.nrOfColumns > 6 ? 6 : props.nrOfColumns ? props.nrOfColumns : 0)].map(
											(x, index) => {
												const tableCellData = Object.prototype.hasOwnProperty.call(tableRowData, index)
													? tableRowData[index]
													: null;
												let cellContent;
												if (props.tableType === "table") {
													cellContent = tableCellData ? tableCellData.content : "";
												} else if (props.tableType === "content_table") {
													cellContent = tableCellData;
												}

												return (
													<Draggable key={index} index={index} draggableId={index.toString()}>
														{provided => (
															<div
																{...provided.dragHandleProps}
																ref={provided.innerRef}
																{...provided.draggableProps}
																className="bg-primary-lightest items-center px-3 py-3 my-2 flex justify-between">
																<div className="flex justify-between w-full">
																	<EvaIcon type="move-outline" width="20" height="20" fill="#009286" />
																	<div className="w-full">
																		{props.tableType === "table" && (
																			<Html
																				label={`${uctrans("cms.options.column")} ${index + 1}`}
																				value={cellContent}
																				onChange={cellContent => {
																					changeCellContent(index, cellContent);
																				}}
																			/>
																		)}
																		{props.tableType === "content_table" && (
																			<PartContent
																				label={`${uctrans("cms.options.column")} ${index + 1}`}
																				parttype="content_table_cell"
																				values={cellContent}
																				onChange={cellContent => {
																					changeCellContent(index, cellContent);
																				}}
																			/>
																		)}
																	</div>
																</div>
															</div>
														)}
													</Draggable>
												);
											},
										))()}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</div>
		);
	}
}

TableRow.propTypes = {
	tableType: PropTypes.oneOf(["table", "content_table"]).isRequired,
	label: PropTypes.string,
	values: PropTypes.arrayOf(
		PropTypes.shape({
			content: PropTypes.string,
		}),
	),
	onChange: PropTypes.func.isRequired,
	nrOfColumns: PropTypes.number,
};

TableRowList.defaultProps = {
	nrOfColumns: 0,
};
