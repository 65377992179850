import PropTypes from "prop-types";

export default function Text(props) {
	return (
		<div className={props.additionalClass}>
			{props.label && <label>{props.label}</label>}
			<input
				type="text"
				value={props.value == null ? "" : props.value}
				onChange={e => props.onChange(e.target.value)}
			/>
		</div>
	);
}

Text.propTypes = {
	label: PropTypes.string,
	additionalClass: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};
