import Tippy from "@tippyjs/react";
import { useState } from "react";
import Dialog from "../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import { can, route, numberFormat } from "../../../../../js/helpers";

import Navigation from "./Navigation";
import RelationmanagerTargetForm from "./RelationmanagerTargetForm";

export default function RelationmanagerTargetOverview() {
	const overviewData = { ...window.data.overview };
	const staticData = { ...window.data.static_data };

	const [overviewDataUpdatedAt, setOverviewDataUpdatedAt] = useState(new Date().getTime());
	const [currentRelationmanagerTarget, setCurrentRelationmanagerTarget] = useState(null);

	const addRelationmanagerTarget = () => {
		setCurrentRelationmanagerTarget({
			id: -1,
			relationmanager_id: "",
			sum: "",
			count: "",
			contactmoments: "",
		});
	};

	const handleSuccess = () => {
		setOverviewDataUpdatedAt(new Date().getTime());
		setCurrentRelationmanagerTarget(null);
	};

	return (
		<>
			<Navigation selectedTab="relationmanagerTargets" targetYear={staticData.targetYear} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("targets.relationmanager_target.plural")}</h1>
				{can("targets.relationmanager_target", "create") && (
					<div>
						<IconButton
							onClick={addRelationmanagerTarget}
							content={uctrans("general.add_:item", {}, { item: "targets.relationmanager_target.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				dataUpdatedAt={overviewDataUpdatedAt}
				indexUrl={route("crm.targets.relationmanager-targets.index.json", { target_year: staticData.targetYear })}
				deleteRouteName="crm.targets.relationmanager-targets.delete"
				modelTranslations={{
					plural: trans("targets.relationmanager_target.plural"),
					singular: trans("targets.relationmanager_target.singular"),
				}}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							sortable="relationmanager_name"
							renderHeaderContent={() => uctrans("targets.relationmanager_target.fields.relationmanager")}
							renderCellContent={relationmanagerTarget => {
								if (can("targets.relationmanager_target", "update")) {
									return (
										<a
											onClick={() => setCurrentRelationmanagerTarget(relationmanagerTarget)}
											className="row-title link">
											{relationmanagerTarget.relationmanager_name}
										</a>
									);
								} else {
									return relationmanagerTarget.relationmanager_name;
								}
							}}
						/>
						<DataColumn
							width={3}
							sortable="ambition_sum"
							renderHeaderContent={() => `${uctrans("targets.relationmanager_target.fields.ambition")} (320)`}
							renderCellContent={relationmanagerTarget =>
								`€ ${
									relationmanagerTarget.ambition_sum ? numberFormat(relationmanagerTarget.ambition_sum, 0, ",", ".") : 0
								}`
							}
						/>
						<DataColumn
							width={3}
							sortable="sum"
							renderHeaderContent={() => `${uctrans("targets.relationmanager_target.fields.sum")} (320)`}
							renderCellContent={relationmanagerTarget => `€ ${numberFormat(relationmanagerTarget.sum, 0, ",", ".")}`}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => `${uctrans("targets.relationmanager_target.fields.sum")} (220)`}
							renderCellContent={relationmanagerTarget =>
								`€ ${numberFormat(
									staticData.targetYear.ratio_220_320 > 0
										? (relationmanagerTarget.sum / staticData.targetYear.ratio_220_320) * 100
										: 0,
									0,
									",",
									".",
								)}`
							}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => `${uctrans("targets.relationmanager_target.fields.sum")} (215)`}
							renderCellContent={relationmanagerTarget =>
								`€ ${numberFormat(
									staticData.targetYear.ratio_220_320 > 0 && staticData.targetYear.ratio_215_220 > 0
										? (((relationmanagerTarget.sum / staticData.targetYear.ratio_220_320) * 100) /
												staticData.targetYear.ratio_215_220) *
												100
										: 0,
									0,
									",",
									".",
								)}`
							}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => `${uctrans("targets.relationmanager_target.fields.sum")} (212)`}
							renderCellContent={relationmanagerTarget =>
								`€ ${numberFormat(
									staticData.targetYear.ratio_220_320 > 0 &&
										staticData.targetYear.ratio_215_220 > 0 &&
										staticData.targetYear.ratio_212_215 > 0
										? (((((relationmanagerTarget.sum / staticData.targetYear.ratio_220_320) * 100) /
												staticData.targetYear.ratio_215_220) *
												100) /
												staticData.targetYear.ratio_212_215) *
												100
										: 0,
									0,
									",",
									".",
								)}`
							}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => `${uctrans("targets.relationmanager_target.fields.sum")} (210)`}
							renderCellContent={relationmanagerTarget =>
								`€ ${numberFormat(
									staticData.targetYear.ratio_220_320 > 0 &&
										staticData.targetYear.ratio_215_220 > 0 &&
										staticData.targetYear.ratio_212_215 > 0 &&
										staticData.targetYear.ratio_210_212 > 0
										? (((((((relationmanagerTarget.sum / staticData.targetYear.ratio_220_320) * 100) /
												staticData.targetYear.ratio_215_220) *
												100) /
												staticData.targetYear.ratio_212_215) *
												100) /
												staticData.targetYear.ratio_210_212) *
												100
										: 0,
									0,
									",",
									".",
								)}`
							}
						/>
						<DataColumn
							width={4}
							sortable="ambition_count"
							renderHeaderContent={() => uctrans("targets.relationmanager_target.fields.ambition_count")}
							renderCellContent={relationmanagerTarget =>
								relationmanagerTarget.ambition_count
									? numberFormat(relationmanagerTarget.ambition_count, 0, ",", ".")
									: 0
							}
						/>
						<DataColumn
							width={3}
							sortable="count"
							renderHeaderContent={() => uctrans("targets.relationmanager_target.fields.count")}
							renderCellContent={relationmanagerTarget => numberFormat(relationmanagerTarget.count, 0, ",", ".")}
						/>
						<DataColumn
							width={3}
							sortable="contactmoments"
							renderHeaderContent={() => uctrans("targets.relationmanager_target.fields.contactmoments")}
							renderCellContent={relationmanagerTarget => relationmanagerTarget.contactmoments}
						/>
						{can("targets.relationmanager_target", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(relationmanagerTarget, overview) => (
									<div className="overview-actions">
										<a
											onClick={() => {
												overview.askDelete(relationmanagerTarget);
											}}>
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("general.delete")}>
												<span>
													<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
												</span>
											</Tippy>
										</a>
									</div>
								)}
							/>
						)}
					</DataTable>
				)}
			/>
			{currentRelationmanagerTarget !== null && (
				<Dialog
					isOpen={currentRelationmanagerTarget !== null}
					title={
						currentRelationmanagerTarget.id === -1
							? uctrans("general.add_:item", {}, { item: "targets.relationmanager_target.singular" })
							: `${uctrans("general.edit_:item", {}, { item: "targets.relationmanager_target.singular" })} ${
									currentRelationmanagerTarget.relationmanager_name
							  }`
					}
					isFullScreen
					width={800}
					onClose={() => setCurrentRelationmanagerTarget(null)}>
					<RelationmanagerTargetForm
						relationmanagerTarget={currentRelationmanagerTarget}
						targetYear={staticData.targetYear}
						onSuccess={() => handleSuccess()}
						onCancel={() => setCurrentRelationmanagerTarget(null)}
					/>
				</Dialog>
			)}
		</>
	);
}
