import map from "lodash/map";
import PropTypes from "prop-types";
import { useState, Fragment } from "react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import FormattedNumberText from "../../FormattedNumberText";

export default function NewsletterOptinOutTable({ reportData }) {
	const { average, title, data } = reportData;

	const [expandedCategories, setExpandedCategories] = useState([]);

	const adjustExpandedCategories = rowId => {
		if (expandedCategories.some(id => id === rowId)) {
			// remove it
			setExpandedCategories(expandedCategories.filter(id => id !== rowId));
		} else {
			// add it
			setExpandedCategories([...expandedCategories, rowId]);
		}
	};

	return (
		<table className="mt-5 omzet_rapportage_table rapportage_table reporttable">
			<thead>
				<tr>
					<th colSpan="10">{title ? title : uctrans("report.singular")}</th>
				</tr>
				<tr>
					<td colSpan="4" className="status_titel font-weight-bold">
						<span>{uctrans("interestcategories.fields.categories")}</span>
					</td>
					<td colSpan="2" className="status_titel font-weight-bold">
						<span>{uctrans("intermediaries.plural")}</span>
					</td>
					<td colSpan="2" className="status_titel font-weight-bold">
						<span>{uctrans("contactpersons.plural")}</span>
					</td>
					<td colSpan="2" className="status_titel font-weight-bold">
						<span>{uctrans("report.total")}</span>
					</td>
				</tr>
			</thead>
			{data && Object.keys(data).length > 0 ? (
				<tbody>
					{map(data, (row, key) => (
						<Fragment key={key}>
							<tr key={`${key}_total`}>
								<td
									colSpan="4"
									className="status_titel font-bold cursor-pointer"
									onClick={row && row.types ? () => adjustExpandedCategories(key) : null}>
									{row && row.types && (
										<EvaIcon
											type={expandedCategories.some(id => id === key) ? "arrow-down" : "arrow-right"}
											fill="#249286"
											height="20"
											width="20"
										/>
									)}
									<span className="mb-2">{row.label}</span>
								</td>
								<td colSpan="2" className="status_titel font-bold">
									{row.percentage_intermediary ? (
										<FormattedNumberText percentage value={row.percentage_intermediary} />
									) : (
										uctrans("report.nvt")
									)}
								</td>
								<td colSpan="2" className="status_titel font-bold">
									{row.percentage_contactperson ? (
										<FormattedNumberText percentage value={row.percentage_contactperson} />
									) : (
										uctrans("report.nvt")
									)}
								</td>
								<td colSpan="2" className="totaal font-bold">
									{row.percentage_total ? (
										<FormattedNumberText percentage value={row.percentage_total} />
									) : (
										uctrans("report.nvt")
									)}
								</td>
							</tr>
							{expandedCategories.includes(key) &&
								Object.keys(row.types).length > 0 &&
								map(row.types, (type, typeKey) => (
									<tr key={typeKey}>
										<td colSpan="4" className="status_titel font-weight-bold">
											<span className="ml-5 italic">{type.label}</span>
										</td>
										<td colSpan="2" className="status_titel font-weight-bold">
											<span className="italic">
												{type.percentage_intermediary ? (
													<FormattedNumberText percentage value={type.percentage_intermediary} />
												) : (
													uctrans("report.nvt")
												)}
											</span>
										</td>
										<td colSpan="2" className="status_titel font-weight-bold">
											<span className="italic">
												{type.percentage_contactperson ? (
													<FormattedNumberText percentage value={type.percentage_contactperson} />
												) : (
													uctrans("report.nvt")
												)}
											</span>
										</td>
										<td colSpan="2" className="totaal">
											<span className="italic">
												{type.percentage_total ? (
													<FormattedNumberText percentage value={type.percentage_total} />
												) : (
													uctrans("report.nvt")
												)}
											</span>
										</td>
									</tr>
								))}
						</Fragment>
					))}

					{!!average && (
						<tr className="totaal">
							<td colSpan="4">{uctrans("report.average")}</td>
							<td colSpan="2" className="status_titel font-weight-bold">
								{average.percentage_intermediary ? (
									<FormattedNumberText percentage value={average.percentage_intermediary} />
								) : (
									uctrans("report.nvt")
								)}
							</td>
							<td colSpan="2" className="status_titel font-weight-bold">
								{average.percentage_contactperson ? (
									<FormattedNumberText percentage value={average.percentage_contactperson} />
								) : (
									uctrans("report.nvt")
								)}
							</td>
							<td colSpan="2" className="totaal">
								{average.percentage_total ? (
									<FormattedNumberText percentage value={average.percentage_total} />
								) : (
									uctrans("report.nvt")
								)}
							</td>
						</tr>
					)}
				</tbody>
			) : (
				<tbody>
					<tr>
						<td colSpan="10">{uctrans("report.no_results")}</td>
					</tr>
				</tbody>
			)}
		</table>
	);
}

NewsletterOptinOutTable.propTypes = {
	reportData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
