import PropTypes from "prop-types";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import Animation from "../Animation";

export default function MediaSelectorAnimation({ animation, onRemove }) {
	return (
		<div className="relative mt-2 p-2 shadow bg-white h-32 flex justify-center">
			<Animation animation={animation} />
			<span onClick={onRemove} className="absolute top-0 right-0 bg-grey-darker opacity-75 p-1 pb-0 cursor-pointer">
				<EvaIcon type="trash-2-outline" width="24" height="24" fill="#de751f" />
			</span>
		</div>
	);
}

MediaSelectorAnimation.propTypes = {
	animation: PropTypes.object.isRequired,
	onRemove: PropTypes.func.isRequired,
};
