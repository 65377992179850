import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Nav from "../../../../../../../js/lib/Nav";
import { uctrans } from "../../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../../js/react/components/general/AjaxForm";
import Radio from "../../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../../js/react/components/general/form/radio/RadioGroup";
import SubmitBar from "../../../../../../../js/react/components/general/SubmitBar";
import cloneDeep from "lodash/cloneDeep";
import { route } from "../../../../../../../js/helpers";
import useRadioHandler from "../../../../../../../js/react/hooks/useRadioHandler";
import Navigation from "../Navigation";

export default function AfmPermitForm(props) {
	const [formData, setFormData] = useState(
		typeof props.afmPermitFormData === "undefined"
			? cloneDeep(window.data.afm_permits_values)
			: props.afmPermitFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.afmPermitStaticData === "undefined"
			? cloneDeep(window.data.afm_permits_static_data)
			: props.afmPermitStaticData,
	);

	const radioHandler = useRadioHandler(setFormData);

	const setDossierPart = value => {
		const newFormData = { ...formData };
		newFormData.dossier_part_id = parseInt(value);
		setFormData(newFormData);
	};

	useEffect(() => {
		if (typeof props.afmPermitFormData !== "undefined") {
			setFormData(props.afmPermitFormData);
		}
	}, [props.afmPermitFormData]);

	useEffect(() => {
		if (typeof props.afmPermitStaticData !== "undefined") {
			setStaticData(props.afmPermitStaticData);
		}
	}, [props.afmPermitStaticData]);

	const onSuccess = () => {
		Nav.go(route("crm.agenda-settings.afm-permits.index"));
	};

	return (
		<>
			<Navigation selectedTab="afm_permits" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("afm_permits.singular")}</h1>

				<a className="link" href={route("crm.agenda-settings.afm-permits.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "afm_permits.plural" })}
				</a>
			</div>
			<AjaxForm
				method="PUT"
				submitUrl={route("crm.agenda-settings.afm-permits.update", formData.id)}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "afm_permits.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "afm_permits.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<label>{uctrans("afm_permits.fields.afm_product")}</label>
									<span>{formData && formData.afm_product ? formData.afm_product.name : "-"}</span>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-3">
									<label>{uctrans("afm_permits.fields.afm_service")}</label>
									<span>{formData && formData.afm_service ? formData.afm_service.name : "-"}</span>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-3">
									<label>{uctrans("afm_permits.fields.required_type")}</label>
									<RadioGroup
										name="required_type"
										value={formData.required_type}
										onChange={value => radioHandler(value, "required_type")}>
										<Radio
											label={staticData.afm_required_types.NONE.label}
											value={staticData.afm_required_types.NONE.key}
										/>
										<Radio
											label={staticData.afm_required_types.ALWAYS.label}
											value={staticData.afm_required_types.ALWAYS.key}
										/>
										<Radio
											label={staticData.afm_required_types.REQUIRED_IF_DOSSIER_NOT_CHECKED.label}
											value={staticData.afm_required_types.REQUIRED_IF_DOSSIER_NOT_CHECKED.key}
										/>
									</RadioGroup>
									{formData.required_type === staticData.afm_required_types.REQUIRED_IF_DOSSIER_NOT_CHECKED.key && (
										<select
											value={formData.dossier_part_id ? formData.dossier_part_id : ""}
											name="dossier_part_id"
											onChange={e => {
												setDossierPart(e.target.value);
											}}>
											<option key="" value="" />
											{staticData.dossier_parts.map(dossier_part => (
												<option key={dossier_part.value} value={dossier_part.value}>
													{dossier_part.label}
												</option>
											))}
										</select>
									)}
								</div>
							</div>
							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="afm_permits.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
AfmPermitForm.propTypes = {
	afmPermitFormData: PropTypes.object,
	afmPermitStaticData: PropTypes.object,
};
