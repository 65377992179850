import axios from "axios";
import { useCallback, useContext, useState } from "react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import ConfirmDialog from "../../../../../../../js/react/components/general/ConfirmDialog";
import IconButton from "../../../../../../../js/react/components/general/IconButton";
import Select from "../../../../../../../js/react/components/general/Select";
import PageLibraryContext from "./PageLibraryContext";
import { createLoader, error } from "../../../../../../../js/react/components/general/notifications";
import { route } from "../../../../../../../js/helpers";

export default function PageLibraryMoveButton() {
	const { folders, currentFolder, refreshPages, selected } = useContext(PageLibraryContext);

	const [dialogOpen, setDialogOpen] = useState(false);
	const [moveTo, setMoveTo] = useState(null);

	const handleButtonClick = useCallback(() => {
		setMoveTo(null);
		setDialogOpen(true);
	}, []);

	const handleMove = useCallback(async () => {
		setDialogOpen(false);
		if (moveTo === null) {
			error(uctrans("general.no_:item_selected", {}, { item: "cms.folder.singular" }));
			return;
		}
		let loader;
		if (selected.length > 1) {
			loader = createLoader(uctrans("general.:items_are_being_moved", {}, { items: `cms.pages.plural` }));
		} else {
			loader = createLoader(uctrans("general.:item_is_being_moved", {}, { item: `cms.pages.singular` }));
		}
		try {
			await axios.patch(route(`crm.cms.pages.move`), { folder: moveTo.value, ids: selected });
			if (selected.length > 1) {
				loader.success(uctrans("general.moved_:items", {}, { items: `cms.pages.plural` }));
			} else {
				loader.success(uctrans("general.moved_:item", {}, { item: `cms.pages.singular` }));
			}
			refreshPages();
		} catch (error) {
			console.error(error);
			loader.failure(error.message);
		}
	}, [moveTo, refreshPages, selected]);

	const additionalFolders = [];
	if (currentFolder !== -1) {
		additionalFolders.push({
			value: -1,
			label: uctrans("general.no_:item", {}, { item: "cms.folder.singular" }),
		});
	}

	return (
		<>
			<IconButton
				icon="arrow-forward"
				className="ml-2"
				fill="#ffffff"
				content={uctrans("general.move")}
				onClick={handleButtonClick}
			/>

			<ConfirmDialog
				isOpen={dialogOpen}
				onConfirm={handleMove}
				onCancel={() => setDialogOpen(false)}
				title={uctrans("cms.select_folder_to_move_:count_pages", { count: selected.length })}>
				<Select
					options={[
						...additionalFolders,
						...folders
							.filter(folder => folder.id !== currentFolder)
							.map(folder => ({ value: folder.id, label: folder.name })),
					]}
					value={moveTo}
					onChange={selected => setMoveTo(selected)}
					isClearable={false}
				/>
			</ConfirmDialog>
		</>
	);
}
