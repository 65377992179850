import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import useInputHandler from "../../../../../../js/react/hooks/useInputHandler";
import { route } from "../../../../../../js/helpers";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import Translate from "../../../../../../js/react/components/general/Translate";
import RadioGroup from "../../../../../../js/react/components/general/form/radio/RadioGroup";
import Radio from "../../../../../../js/react/components/general/form/radio/Radio";
import axios from "axios";
import TextEditor from "../../forms/textEditor/TextEditor";
import InterestValuesTable from "../InterestValuesTable";

export default function DiscountForm({ discount, onSuccess, onCancel }) {
	const [formData, setFormData] = useState({ ...discount });

	const [interestRateClasses, setInterestRateClasses] = useState([]);
	const [fixedInterestPeriods, setFixedInterestPeriods] = useState([]);
	const [values, setValues] = useState([]);
	const [initialized, setInitialized] = useState(false);

	const inputHandler = useInputHandler(setFormData);

	const setPropertyFromFormData = (property, value) => {
		const newFormData = Object.assign({}, formData);
		newFormData[property] = value;
		setFormData(newFormData);
	};

	const transformInterestDiscountValues = interestValues => {
		const transformedValues = {};
		interestValues.forEach(interestValue => {
			transformedValues[`${interestValue.fixed_interest_period_id}-${interestValue.rate_class_id}`] = {
				fixed_interest_period_id: interestValue.fixed_interest_period_id,
				rate_class_id: interestValue.rate_class_id,
				value: interestValue.value.toString().replace(/\./g, ","),
			};
		});
		return transformedValues;
	};

	const handleValuesChange = values => {
		setValues(values);
	};

	const handleSuccess = () => {
		onSuccess();
	};

	const handleCancel = () => {
		onCancel();
	};

	const fetchInterestRateClasses = useCallback(async () => {
		const response = await axios.get(route("crm.interest.interest-rate-classes.index", formData.interest_type_id));
		setInterestRateClasses(response.data.overview.items.filter(interestRateClass => interestRateClass.archived === 0));
	}, [formData.interest_type_id]);

	const fetchFixedInterestPeriods = useCallback(async () => {
		const response = await axios.get(route("crm.interest.fixed-interest-periods.index", formData.interest_type_id));
		setFixedInterestPeriods(
			response.data.overview.items.filter(fixedInterestPeriod => fixedInterestPeriod.archived === 0),
		);
	}, [formData.interest_type_id]);

	const fetchValues = useCallback(async () => {
		const response = await axios.get(route("crm.interest.discount.values", formData.id));

		setInterestRateClasses(response.data.interest_rate_classes);
		setFixedInterestPeriods(response.data.fixed_interest_periods);
		setValues(transformInterestDiscountValues(response.data.values));
	}, [formData.id]);

	useEffect(() => {
		if (!initialized) {
			if (formData.id !== -1) {
				fetchValues();
				setInitialized(true);
			} else {
				fetchInterestRateClasses();
				fetchFixedInterestPeriods();
				setInitialized(true);
			}
		}
	}, [fetchFixedInterestPeriods, fetchInterestRateClasses, fetchValues, formData.id, initialized]);

	return (
		<AjaxForm
			method={formData.id === -1 ? "POST" : "PUT"}
			submitUrl={
				formData.id === -1 ? route("crm.interest.discounts.store") : route("crm.interest.discounts.update", formData.id)
			}
			loaderText={uctrans("general.:item_is_being_saved", {}, { item: "interest.discounts.singular" })}
			successText={uctrans("general.saved_:item", {}, { item: "interest.discounts.singular" })}
			onSuccess={handleSuccess}
			data={{ ...formData, interest_values: values }}>
			<div className="md:flex">
				<div className="flex-1 w-2/3 mr-12">
					<div className="form-container">
						{formData.id !== -1 && (
							<div className="callout-warning p-4 mb-4">
								<Translate content="interest.sentences.discount_edit_warning" />
							</div>
						)}
						<div className="form-full">
							<div className="form-2-3">
								<LabeledTextInput
									name="name"
									value={formData.name}
									onChange={inputHandler}
									label="interest.fields.name"
									required
								/>
							</div>
						</div>
						<div className="form-full" style={{ marginBottom: "0px" }}>
							<div className="form-2-3">
								<LabeledTextInput
									name="site_name"
									value={formData.site_name}
									onChange={inputHandler}
									label="interest.fields.site_name"
									required
								/>
							</div>
						</div>
						<div className="form-full">
							<p>
								<Translate content="interest.sentences.site_name_explanation" />
							</p>
						</div>
						<div className="form-full">
							<label>
								<Translate content="interest.fields.description" />
							</label>
							<TextEditor
								value={formData.description}
								onChange={value => setPropertyFromFormData("description", value)}
							/>
						</div>
						<div className="form-full">
							<label>
								<Translate content="interest.fields.active_by_default_cms" />
							</label>
							<RadioGroup
								name="active_by_default_cms"
								value={formData.active_by_default_cms}
								onChange={value => setPropertyFromFormData("active_by_default_cms", value)}>
								<Radio label={uctrans("general.no")} value={0} />
								<Radio label={uctrans("general.yes")} value={1} />
							</RadioGroup>
						</div>
						<div className="form-full">
							<label>
								<Translate content="interest.fields.active_by_default_frontend" />
							</label>
							<RadioGroup
								name="active_by_default_frontend"
								value={formData.active_by_default_frontend}
								onChange={value => setPropertyFromFormData("active_by_default_frontend", value)}>
								<Radio label={uctrans("general.no")} value={0} />
								<Radio label={uctrans("general.yes")} value={1} />
							</RadioGroup>
						</div>
						{
							// Only show it if everything is loaded and when it has enough interestRateClasses and fixedInterestPeriods
							// Otherwise show a notice
						}
						<div className="form-full">
							{interestRateClasses.length > 0 && fixedInterestPeriods.length > 0 && (
								<InterestValuesTable
									values={values}
									interestRateClasses={interestRateClasses}
									fixedInterestPeriods={fixedInterestPeriods}
									onValuesChange={values => handleValuesChange(values)}
								/>
							)}
						</div>
						<div className="form-full">
							<div className="w-full md:w-1/2 lg:w-2/3 column">
								<SubmitBar item="interest.discounts.singular" addAnotherAvailable={false} onCancel={handleCancel} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</AjaxForm>
	);
}
DiscountForm.propTypes = {
	discount: PropTypes.object.isRequired,
	onSuccess: PropTypes.func,
	onCancel: PropTypes.func,
};
