import { uctrans } from "../../../../../../js/lib/Translator";
import { route } from "../../../../../../js/helpers";
import TabLinks from "../../general/tabs/TabLinks";
import { DISCOUNTS_TAB, getInterestManagementTabs } from "../InterestManagementTabs";
import DiscountOverview from "./DiscountOverview";
import DiscountExceptionOverview from "./DiscountExceptionOverview";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";

export default function DiscountOverviewTab() {
	const formData = { ...window.data.interest_type_form_data };

	const getBreadCrumbs = () => [
		{
			label: uctrans("interest.interest_types.plural"),
			route: route("crm.interest.interest-types.index"),
		},
		{
			label: window.data.interest_type_form_data.name,
			route: route("crm.interest.interest-types.edit", window.data.interest_type_form_data.id),
		},
		{
			label: uctrans("interest.discounts.plural"),
		},
	];

	return (
		<>
			<BreadCrumbs breadCrumbs={getBreadCrumbs()} />
			<TabLinks tabs={Object.values(getInterestManagementTabs(formData.id))} activeTab={DISCOUNTS_TAB} />
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("general.edit_:item", {}, { item: "interest.discounts.plural" })}</h1>
				<div>
					<a className="link" href={route("crm.interest.interest-types.index")}>
						{uctrans("general.back_to_overview_:page", {}, { page: "interest.interest_types.plural" })}
					</a>
				</div>
			</div>
			<DiscountOverview interestType={formData} />
			<br />
			<br />
			<DiscountExceptionOverview interestType={formData} />
		</>
	);
}
