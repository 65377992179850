import { createLoader } from "../../../../../js/react/components/general/notifications";
import { useState } from "react";
import Tippy from "@tippyjs/react";
import { format } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import PeriodicOverviewFilter from "../../../../../js/react/components/general/overview/PeriodicOverviewFilter";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import Translate from "../../../../../js/react/components/general/Translate";
import axios from "axios";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../js/react/components/general/IconButton";
import DeleteDialog from "../../../../../js/react/components/general/DeleteDialog";
import { route } from "../../../../../js/helpers";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";

export default function ContactmomentOverview() {
	const [overviewData, setOverviewData] = useState({
		overview: { ...window.data.overview },
		create_link: window.data.contactmoment_create_link,
		index_link: window.data.contactmoment_index_link,
		permissions: window.data.contactmoment_permissions,
		company_is_set: window.data.company_is_set,
		resultData: {
			errors: [],
			warnings: [],
		},
		dialogOpen: false,
		requestKind: "",
	});

	const deleteContactmoment = async contactmoment => {
		const result = await axios.get(route("crm.contactmoments.delete", [contactmoment.id]));
		if (result.status === 200) {
			toggleDecisionDialog(result.data, "deleted");
		}
	};

	const toggleDecisionDialog = async (resultData = null, requestKind = null) => {
		setOverviewData({
			...overviewData,
			resultData,
			dialogOpen: !overviewData.dialogOpen,
			requestKind,
		});
	};

	const handleDialogSubmit = async overview => {
		const loader = createLoader(
			uctrans(`general.:item_is_being_${overviewData.requestKind}`, {}, { item: "contactmoments.singular" }),
		);
		const result =
			overviewData.requestKind === "deleted"
				? await axios.post(overviewData.resultData.destroy_link, { _method: "delete" })
				: await axios.put(overviewData.resultData.destroy_link);

		if (result.status === 200) {
			loader.success(uctrans(`general.${overviewData.requestKind}_:item`, {}, { item: "contactmoments.singular" }));
			toggleDecisionDialog();
			overview.loadData();
		} else {
			loader.failure("Something went wrong deleting the contactmoment");
		}
	};

	return (
		<>
			{window.data.nav_data && (
				<>
					<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
					<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
				</>
			)}
			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.page_title ? window.data.page_title : uctrans("contactmoments.plural")}
				</h1>
				{overviewData.create_link && (
					<div>
						<IconButton
							href={overviewData.create_link}
							content={uctrans("general.add_:item", {}, { item: "contactmoments.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={overviewData.index_link}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" placeholder="" />
						<SelectFilter
							className="form-1-3"
							name="user"
							label={uctrans("contactmoments.fields.user_id")}
							options={overviewData.overview.metadata.filterdata.users}
						/>
						{overviewData.overview.metadata.filterdata.base_types && (
							<SelectFilter
								className="form-1-3"
								name="base_type"
								label={uctrans("contactmoments.fields.base_type")}
								options={overviewData.overview.metadata.filterdata.base_types}
								collapsible
							/>
						)}
						{overviewData.overview.metadata.filterdata.types && (
							<SelectFilter
								className="form-1-3"
								name="type"
								label={uctrans("contactmoments.fields.type")}
								options={overviewData.overview.metadata.filterdata.types}
								collapsible
							/>
						)}
						{overviewData.overview.metadata.filterdata.categories && (
							<SelectFilter
								className="form-1-3"
								name="category_id"
								label={uctrans("contactmoments.fields.category_id")}
								options={overviewData.overview.metadata.filterdata.categories}
								collapsible
							/>
						)}
						{overviewData.overview.metadata.filterdata.relationmanagers && (
							<SelectFilter
								className="form-1-3"
								name="relationmanager"
								label={uctrans("intermediaries.fields.relationmanager_id")}
								options={overviewData.overview.metadata.filterdata.relationmanagers}
								collapsible
							/>
						)}
						{overviewData.overview.metadata.filterdata.organisations && (
							<SelectFilter
								className="form-1-3"
								name="organisation"
								label={uctrans("intermediaries.fields.parent_id")}
								options={overviewData.overview.metadata.filterdata.organisations}
								collapsible
							/>
						)}
						{overviewData.overview.metadata.filterdata.segments && (
							<SelectFilter
								className="form-1-3"
								name="segment"
								label={uctrans("intermediaries.fields.segment_id")}
								options={overviewData.overview.metadata.filterdata.segments}
								collapsible
							/>
						)}
						{overviewData.overview.metadata.filterdata.provinces && (
							<SelectFilter
								className="form-1-3"
								name="province"
								label={uctrans("location.provinces.singular")}
								options={overviewData.overview.metadata.filterdata.provinces}
								collapsible
							/>
						)}
						{overviewData.overview.metadata.filterdata.teams && (
							<SelectFilter
								className="form-1-3"
								name="team"
								label={uctrans("intermediaries.fields.team_id")}
								options={overviewData.overview.metadata.filterdata.teams}
								collapsible
							/>
						)}
						{overviewData.overview.metadata.filterdata.formInfo &&
							overviewData.overview.metadata.filterdata.formValues && (
								<PeriodicOverviewFilter
									name="periodType"
									formInfo={overviewData.overview.metadata.filterdata.formInfo}
									formValues={overviewData.overview.metadata.filterdata.formValues}
									collapsible
								/>
							)}
						<SearchBarFilter
							type="text"
							name="search_content"
							placeholder={uctrans("contactmoments.search_content_placeholder")}
							collapsible
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={overviewData.company_is_set ? 1 : 2}
							// sortable="datetime"
							renderHeaderContent={() => uctrans("contactmoments.fields.datetime")}
							renderCellContent={contactmoment => (
								<a href={contactmoment.edit_link} className="row-title link">
									{contactmoment.datetime ? format(contactmoment.datetime, "dd-MM-y") : "..."}
								</a>
							)}
						/>
						{!overviewData.company_is_set && (
							<DataColumn
								width={3}
								// sortable="intermediary"
								renderHeaderContent={() => uctrans("contactmoments.fields.intermediary")}
								renderCellContent={contactmoment =>
									contactmoment.company && (contactmoment.company.label !== null) !== "undefined"
										? contactmoment.company.label
										: "-"
								}
							/>
						)}
						<DataColumn
							width={2}
							// sortable="type"
							renderHeaderContent={() => uctrans("contactmoments.fields.type")}
							renderCellContent={contactmoment => contactmoment.type}
						/>
						<DataColumn
							width={3}
							// sortable="topic"
							renderHeaderContent={() => uctrans("contactmoments.fields.topic")}
							renderCellContent={contactmoment => contactmoment.subject}
						/>
						<DataColumn
							width={2}
							// sortable="user_id"
							renderHeaderContent={() => uctrans("contactmoments.fields.user")}
							renderCellContent={contactmoment =>
								contactmoment.user && (contactmoment.user.label !== null) !== "undefined"
									? contactmoment.user.label
									: "-"
							}
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("contactmoments.fields.contactpersons")}
							renderCellContent={contactmoment => contactmoment.contactpersons}
						/>
						{overviewData.permissions.delete && (
							<DataColumn
								width={1}
								renderHeaderContent={() => uctrans("general.delete")}
								renderCellContent={(contactmoment, overview) =>
									contactmoment.delete_link && (
										<div className="overview-actions ">
											<a onClick={() => deleteContactmoment(contactmoment, overview)}>
												<Tippy
													placement="right"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("general.delete")}>
													<span>
														<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
													</span>
												</Tippy>
											</a>
										</div>
									)
								}
							/>
						)}
					</DataTable>
				)}
				renderNoResults={() => (
					<Translate content="overview.no_:items_to_show" transReplaces={{ items: "contactmoments.plural" }} />
				)}
				renderDeleteDialog={overview => (
					<DeleteDialog
						onConfirm={() => handleDialogSubmit(overview)}
						onCancel={() => setOverviewData({ ...overviewData, dialogOpen: false })}
						isOpen={overviewData.dialogOpen}
						deleteData={overviewData.resultData}
					/>
				)}
				exportUrls={{ csv: route("crm.contactmoments.csv") }}
				useExport
			/>
		</>
	);
}
