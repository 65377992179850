import { route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import { OverviewContext } from "../../../../../js/react/components/general/overview/overview-context";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import Map from "./map/Map";

export default function MapTool() {
	const overviewData = { ...window.data.overview };

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("location.map-tool")}</h1>
			</div>
			<AbnOverview
				hideControls
				initialData={overviewData}
				indexUrl={route("crm.map-tool.index")}
				modelTranslations={{
					plural: uctrans("intermediaries.plural"),
					singular: uctrans("intermediaries.singular"),
				}}
				renderNoResults={() => <span />}
				renderFilters={overview => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
						<SelectFilter
							className="form-1-3"
							name="relationmanager"
							label={uctrans("intermediaries.fields.relationmanager_id")}
							options={overview.data.metadata.filterdata.relation_managers}
							collapsible
						/>
						<SelectFilter
							className="form-1-3"
							name="organisation"
							label={uctrans("intermediaries.fields.parent_id")}
							options={overview.data.metadata.filterdata.organisations}
							collapsible
						/>
						<SelectFilter
							className="form-1-3"
							name="segment"
							label={uctrans("intermediaries.fields.segment_id")}
							options={overview.data.metadata.filterdata.segments}
							collapsible
						/>
						<SelectFilter
							className="form-1-3"
							name="team"
							label={uctrans("intermediaries.fields.team_id")}
							options={overview.data.metadata.filterdata.teams}
							collapsible
						/>
						<SelectFilter
							className="form-1-3"
							name="province"
							label={uctrans("location.provinces.singular")}
							options={overview.data.metadata.filterdata.provinces}
							collapsible
						/>
						<SelectFilter
							className="form-1-3"
							name="township"
							label={uctrans("location.townships.singular")}
							options={overview.data.metadata.filterdata.townships}
							collapsible
						/>
					</Filters>
				)}
				renderBody={() => (
					<OverviewContext.Consumer>
						{overview => <Map height="700px" items={overview.data.items} />}
					</OverviewContext.Consumer>
				)}
			/>
		</>
	);
}
