import Tippy from "@tippyjs/react";
import axios from "axios";
import forEach from "lodash/forEach";
import upperFirst from "lodash/upperFirst";
import { useState, useCallback } from "react";
import ConfirmDialog from "../../../../../../js/react/components/general/ConfirmDialog";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import { createLoader, error } from "../../../../../../js/react/components/general/notifications";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import { can, route, yesNoOptions } from "../../../../../../js/helpers";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../../js/react/components/general/overview/SearchBarFilter";
import SelectFilter from "../../../../../../js/react/components/general/overview/SelectFilter";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";

export default function AutomatedMessageOverview() {
	const overviewData = { ...window.data.overview };
	const [messageToCopy, setMessageToCopy] = useState(null);
	const [messageToArchive, setMessageToArchive] = useState(null);
	const [overviewDataUpdatedAt, setOverviewDataUpdatedAt] = useState(new Date().getTime());

	const askCopy = newsletter => {
		setMessageToCopy(newsletter);
	};
	const cancelCopy = () => {
		setMessageToCopy(null);
	};
	const handleCopy = useCallback(async () => {
		if (messageToCopy) {
			const loader = createLoader(uctrans("general.create_:item", {}, { item: "general.copy" }));

			try {
				await axios.post(route(`crm.communication.automated-messages.store-copy`, messageToCopy.id));
				loader.success(uctrans("general.created_:item", {}, { item: "general.copy" }));
				setOverviewDataUpdatedAt(new Date().getTime());
				setMessageToCopy(null);
			} catch (exception) {
				if (exception.response && exception.response.status === 422) {
					if (Object.prototype.hasOwnProperty.call(exception.response.data, "errors")) {
						let first = true;
						forEach(exception.response.data.errors, message => {
							if (first && loader) {
								loader.failure(upperFirst(message[0]));
								first = false;
							} else {
								error(upperFirst(message[0]));
							}
						});
					}
				} else if (exception.message) {
					loader.failure(exception.message);
				}
				cancelCopy();
			}
		}
	}, [messageToCopy]);

	const askArchive = newsletter => {
		setMessageToArchive(newsletter);
	};
	const cancelArchive = () => {
		setMessageToArchive(null);
	};
	const handleArchive = useCallback(async () => {
		if (messageToArchive) {
			const loader = createLoader(uctrans("general.archive"));

			try {
				await axios.patch(route("crm.communication.automated-messages.archive", messageToArchive.id));
				loader.success(uctrans("general.archived_:item", {}, { item: messageToArchive.title }));
				setOverviewDataUpdatedAt(new Date().getTime());
				setMessageToCopy(null);
			} catch (exception) {
				if (exception.response && exception.response.status === 422) {
					if (Object.prototype.hasOwnProperty.call(exception.response.data, "errors")) {
						let first = true;
						forEach(exception.response.data.errors, message => {
							if (first && loader) {
								loader.failure(upperFirst(message[0]));
								first = false;
							} else {
								error(upperFirst(message[0]));
							}
						});
					}
				} else if (exception.message) {
					loader.failure(exception.message);
				}
				cancelCopy();
			}
		}
	}, [messageToArchive]);

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("communication.singular") }]} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("communication.automated_messages.plural")}</h1>
				{can("communication.automated_message", "create") && (
					<div>
						<IconButton
							href={route("crm.communication.automated-messages.create")}
							content={uctrans("general.add_:item", {}, { item: "communication.automated_messages.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData}
				indexUrl={route("crm.communication.automated-messages.index.json")}
				deleteRouteName="crm.communication.automated-messages.delete"
				modelTranslations={{
					plural: trans("communication.automated_messages.plural"),
					singular: trans("communication.automated_messages.singular"),
				}}
				dataUpdatedAt={overviewDataUpdatedAt}
				renderFilters={overview => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
						<SelectFilter
							className="form-1-3"
							name="trigger_category"
							label={uctrans("communication.newsletters.fields.trigger_category")}
							options={overview.data.metadata.filterdata.trigger_category_options}
							collapsible
						/>
						<SelectFilter
							className="form-1-3"
							name="trigger_type"
							label={uctrans("communication.newsletters.fields.trigger_type")}
							options={overview.data.metadata.filterdata.trigger_type_options}
							collapsible
						/>
						<SelectFilter
							className="form-1-4"
							name="marketing_theme_id"
							label={uctrans("marketing.themes.singular")}
							options={overview.data.metadata.filterdata.marketing_themes_options}
							collapsible
						/>

						<SelectFilter
							className="form-1-3"
							name="subscription_category_id"
							label={uctrans("communication.newsletters.fields.subscription_category")}
							options={overview.data.metadata.filterdata.subscription_category_options}
							collapsible
						/>

						<SelectFilter
							className="form-1-3"
							name="subscription_type_id"
							label={uctrans("communication.newsletters.fields.subscription_type")}
							options={overview.data.metadata.filterdata.subscription_type_options}
							collapsible
						/>
						{overviewData.enabled_filters.indexOf("is_active") > -1 && (
							<SelectFilter
								className="form-1-4"
								name="is_active"
								label={uctrans("communication.automated_messages.fields.active")}
								options={yesNoOptions()}
								collapsible
							/>
						)}
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							sortable="trigger_type"
							renderHeaderContent={() => uctrans("communication.newsletters.fields.trigger_type")}
							renderCellContent={newsletter => (
								<div>
									<span className="text-xs">
										{newsletter.trigger_type && newsletter.trigger_type.category
											? newsletter.trigger_type.category.label
											: ""}
									</span>
									<br />
									<span>
										{can("communication.automated_message", "update") ? (
											<a
												href={route("crm.communication.automated-messages.edit", newsletter.id)}
												className="row-title link">
												{newsletter.trigger_type ? newsletter.trigger_type.label : "-"}
											</a>
										) : newsletter.trigger_type ? (
											newsletter.trigger_type.label
										) : (
											"-"
										)}
									</span>
								</div>
							)}
						/>
						<DataColumn
							width={1}
							renderHeaderContent={() => ""}
							renderCellContent={newsletter =>
								newsletter.description && (
									<Tippy
										placement="auto"
										arrow
										animation="perspective"
										content={<span dangerouslySetInnerHTML={{ __html: newsletter.description }} />}>
										<span className="ml-2">
											<EvaIcon type="info" fill="#249286" height="19" width="19" />
										</span>
									</Tippy>
								)
							}
						/>

						<DataColumn
							width={3}
							sortable="subject"
							renderHeaderContent={() => uctrans("communication.newsletters.fields.subject")}
							renderCellContent={newsletter => <span className="text-xs">{newsletter.subject}</span>}
						/>
						<DataColumn
							width={4}
							sortable="title"
							renderHeaderContent={() => uctrans("communication.newsletters.fields.subtitle")}
							renderCellContent={newsletter => (
								<div>
									<span className="text-xs">{newsletter.subtitle}</span>
									<br />
									<span>{newsletter.title}</span>
								</div>
							)}
						/>
						<DataColumn
							width={2}
							sortable="marketing_theme"
							renderHeaderContent={() => uctrans("marketing.themes.singular")}
							renderCellContent={newsletter => newsletter.marketing_theme}
						/>
						<DataColumn
							width={2}
							sortable="subscription_type"
							renderHeaderContent={() => uctrans("communication.newsletters.fields.subscription_type")}
							renderCellContent={newsletter =>
								!!newsletter.subscription_type && (
									<div>
										<span className="text-xs">
											{newsletter.subscription_type.category ? newsletter.subscription_type.category.name : ""}
										</span>
										<br />
										<span>{newsletter.subscription_type.name}</span>
									</div>
								)
							}
						/>

						{(can("communication.automated_message", "delete") || can("communication.automated_message", "create")) && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(newsletter, overview) => (
									<div className="overview-actions">
										{can("communication.automated_message", "view") && (
											<a
												rel="noopener noreferrer"
												className="mr-2"
												href={route("crm.communication.automated-messages.preview", newsletter.id)}
												target="_blank">
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("communication.newsletters.preview")}>
													<span>
														<EvaIcon type="navigation-2-outline" fill="#249286" height="18" width="18" />
													</span>
												</Tippy>
											</a>
										)}
										{can("communication.automated_message", "create") && (
											<a
												className="mr-2"
												onClick={() => {
													askCopy(newsletter);
												}}>
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("general.add_:item", {
														item: trans(
															"general.copy_of_:item",
															{},
															{ item: "communication.automated_messages.singular" },
														),
													})}>
													<span>
														<EvaIcon type="copy-outline" fill="#3490DC" height="18" width="18" />
													</span>
												</Tippy>
											</a>
										)}
										{can("communication.automated_message", "delete") && (
											<>
												<a
													className="mr-2"
													onClick={() => {
														askArchive(newsletter);
													}}>
													<Tippy
														placement="left"
														arrow
														animation="perspective"
														duration="400"
														content={uctrans("general.archive")}>
														<span>
															<EvaIcon type="power-outline" fill="#009286" height="18" width="18" />
														</span>
													</Tippy>
												</a>
												{newsletter.communication_logs_count === 0 && (
													<a
														onClick={() => {
															overview.askDelete(newsletter);
														}}>
														<Tippy
															placement="left"
															arrow
															animation="perspective"
															duration="400"
															content={uctrans("general.delete")}>
															<span>
																<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
															</span>
														</Tippy>
													</a>
												)}
											</>
										)}
									</div>
								)}
							/>
						)}
					</DataTable>
				)}
			/>
			<ConfirmDialog
				onConfirm={handleCopy}
				onCancel={cancelCopy}
				isOpen={!!messageToCopy}
				title={uctrans("general.confirm")}
				confirmButtonLabel={uctrans("general.create_:item", {}, { item: "general.copy" })}
				cancelButtonLabel={uctrans("general.cancel")}>
				<div className="form-container">
					<h6>{messageToCopy ? messageToCopy.title : "-"}</h6>
					{uctrans("communication.newsletters.confirm_copy")}
				</div>
			</ConfirmDialog>
			<ConfirmDialog
				onConfirm={handleArchive}
				onCancel={cancelArchive}
				isOpen={!!messageToArchive}
				title={uctrans("general.confirm")}
				confirmButtonLabel={uctrans("general.archive")}
				cancelButtonLabel={uctrans("general.cancel")}>
				<div className="form-container">
					<h6>{messageToArchive ? messageToArchive.title : "-"}</h6>
					{uctrans("communication.automated_messages.confirm_archive")}
				</div>
			</ConfirmDialog>
		</>
	);
}
