import { useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import Translate from "../../../../../../js/react/components/general/Translate";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import RelationManagerQuestionnaireCompletionOverview from "./RelationManagerQuestionnaireCompletionOverview";
import RelationManagerQuestionnaireTabs from "./RelationManagerQuestionnaireTabs";
import cloneDeep from "lodash/cloneDeep";

export default function RelationManagerQuestionnaireCompletionOverviewPage() {
	const [overviewData] = useState({
		readOnly: window.data.read_only || true,
		overview: { ...window.data.overview },
		questionnaire: window.data.questionnaire,
		formInfo: window.data.formInfo ? cloneDeep(window.data.formInfo) : [],
		formValues: window.data.formValues ? cloneDeep(window.data.formValues) : [],
		overviewRoute: window.data.overview_route,
	});

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("survey.plural") }]} />
			<RelationManagerQuestionnaireTabs initialActiveTabIndex={1} />

			<div className="page-title-container">
				<h1 className="page-title">
					<Translate
						content="questionnaires.questionnaire_completion.completions_:item"
						replaces={{ item: overviewData.questionnaire.name }}
					/>
				</h1>
			</div>

			<RelationManagerQuestionnaireCompletionOverview overviewData={overviewData} />
		</>
	);
}
