import find from "lodash/find";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import Radio from "../../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../../js/react/components/general/form/radio/RadioGroup";
import { CmsTypesContext } from "../../cms-types-context";
import Boolean from "../attributes/Boolean";
import PageHeadImage from "./PageHeadImage";
import PageHeadVideo from "./PageHeadVideo";
import PageHeadAnimation from "./PageHeadAnimation";

export default function ContentpageHead(props) {
	const [values, setValues] = useState(props.values ? props.values : {});
	useEffect(() => {
		setValues(props.values ? props.values : "");
	}, [props.values]);

	const handleValuesChange = newValues => {
		setValues(newValues);
		props.onChange(newValues);
	};
	const handleValueChange = (name, newValue) => {
		const newValues = { ...values };
		newValues[name] = newValue;
		handleValuesChange(newValues);
	};

	const { partTypes } = useContext(CmsTypesContext);
	const partType = find(partTypes, partType => partType.key === "contentpage_head");

	const [headerType, setHeaderType] = useState(values.video ? 1 : values.animation ? 2 : 0);

	const changeHeaderType = value => {
		const newValues = { ...values };

		if (value === 0) {
			setHeaderType(0);
			newValues.video = null;
			newValues.animation = null;
		} else if (value === 1) {
			setHeaderType(1);
			newValues.image = null;
			newValues.animation = null;
			newValues.mobile_image = null;
			newValues.use_head_text = null;
		} else {
			setHeaderType(2);
			newValues.image = null;
			newValues.mobile_image = null;
			newValues.use_head_text = null;
			newValues.video = null;
		}

		handleValuesChange(newValues);
	};

	const headerTypeRenderer = () => {
		switch (headerType) {
			case 0:
				return <PageHeadImage values={values} partType={partType} handleValueChange={handleValueChange} />;
			case 1:
				return <PageHeadVideo values={values} handleValueChange={handleValueChange} />;
			case 2:
				return <PageHeadAnimation values={values} handleValueChange={handleValueChange} />;
		}
	};

	if (partType) {
		return (
			<div className="mt-5 mb-12">
				<h5>{props.label}</h5>
				<div className="pl-6 mt-3  border-l border-grey-light ">
					<Boolean
						label={partType.attributes.use_head.label}
						value={values.use_head}
						onChange={value => {
							handleValueChange("use_head", value);
						}}
					/>

					{!!values.use_head && (
						<div className="mt-5">
							<RadioGroup name="use_head_type" value={headerType} onChange={value => changeHeaderType(value)}>
								<Radio label={uctrans("cms.attributes.use_head_image")} value={0} />
								<Radio label={uctrans("cms.attributes.use_head_video")} value={1} />
								<Radio label={uctrans("cms.attributes.use_head_animation")} value={2} />
							</RadioGroup>

							{headerTypeRenderer()}
						</div>
					)}
				</div>
			</div>
		);
	}
}

ContentpageHead.propTypes = {
	label: PropTypes.string,
	values: PropTypes.shape({
		use_head: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
		image: PropTypes.object,
		mobile_image: PropTypes.object,
		use_head_text: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
		title: PropTypes.string,
		links: PropTypes.array,
		icon_links: PropTypes.array,
	}),
	onChange: PropTypes.func.isRequired,
};
