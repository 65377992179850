import PropTypes from "prop-types";
import { useContext } from "react";
import CompanyDossierContext from "../CompanyDossierContext";
import BadPressCheckDossierPart from "./BadPressCheckDossierPart";
import BoundFieldDossierPart from "./BoundFieldDossierPart";
import DefaultDossierPart from "./DefaultDossierPart";
import DossierDirectorPart from "./DossierDirectorPart";
import NoneFieldDossierPart from "./NoneFieldDossierPart";
import RDCviaSSODossierPart from "./RDCviaSSODossierPart";

export default function DossierPartContent({ partData, completionData }) {
	const { dossierPartTypes } = useContext(CompanyDossierContext);

	const fieldName = () => {
		switch (Number(partData.type)) {
			case dossierPartTypes.KVK.key:
				return "kvk_number";
			case dossierPartTypes.IBAN.key:
				return "iban";
			case dossierPartTypes.EARCHIVE.key:
				return "bc_number";
			default:
				return null;
		}
	};

	switch (Number(partData.type)) {
		case dossierPartTypes.KVK.key:
		case dossierPartTypes.IBAN.key:
		case dossierPartTypes.EARCHIVE.key:
			return <BoundFieldDossierPart fieldName={fieldName()} partData={partData} completionData={completionData} />;
		case dossierPartTypes.BAD_PRESS_CHECK.key:
			return <BadPressCheckDossierPart partData={partData} completionData={completionData} />;
		case dossierPartTypes.RDC_VIA_SSO.key:
			return <RDCviaSSODossierPart partData={partData} completionData={completionData} />;
		case dossierPartTypes.NUMBER.key:
		case dossierPartTypes.TEXT.key:
		case dossierPartTypes.TEXT_MULTIPLE.key:
		case dossierPartTypes.DATE.key:
		case dossierPartTypes.YES_NO.key:
			return <DefaultDossierPart partData={partData} completionData={completionData} />;
		case dossierPartTypes.NONE.key:
		case dossierPartTypes.COOPERATION_TERMINATION_LETTER.key:
			return <NoneFieldDossierPart partData={partData} completionData={completionData} />;
		case dossierPartTypes.DOSSIER_DIRECTOR.key:
			return <DossierDirectorPart completionData={completionData} />;
		default:
			return null;
	}
}

DossierPartContent.propTypes = {
	partData: PropTypes.object,
	completionData: PropTypes.object,
};
