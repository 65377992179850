import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import AsyncAutoComplete from "./AsyncAutoComplete";
import EvaIcon from "../EvaIcon";
import AutoComplete from "./AutoComplete";

export default function MultiSelectAutoComplete(props) {
	const [items, setItems] = useState(props.items);

	useEffect(
		() => {
			props.onChange(items);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[items],
	);

	const selectItem = item => {
		setItems([...items, item]);
	};

	const unSelectItem = item => {
		const newItems = [...items];
		const index = newItems.indexOf(item);
		if (index !== -1) {
			newItems.splice(index, 1);
			setItems(newItems);
		}
	};

	const options = () => {
		if (props.options) {
			return props.options.filter(item => items.map(e => String(e.value)).indexOf(String(item.value)) === -1);
		}

		return [];
	};

	return (
		<>
			{props.async ? (
				<AsyncAutoComplete
					name={props.name}
					dataSource={props.dataSource}
					onChange={selectItem}
					selectedItems={items}
					placeholder={props.placeholder}
					showItemsOnFirstClick={props.showItemsOnFirstClick}
					clearOnSelect
				/>
			) : (
				<AutoComplete
					name={props.name}
					options={options()}
					onChange={selectItem}
					placeholder={props.placeholder}
					showItemsOnFirstClick={props.showItemsOnFirstClick}
					clearOnSelect
				/>
			)}
			<ul className="linked-items">
				{items.map(item => (
					<li key={item.value} className="bg-grey-light px-3 py-2 my-2">
						<input type="hidden" name={props.name} value={item.value} />
						<div className="flex">
							<span className="linked-item-title">{item.label}</span>
							<span
								className="ml-auto"
								onClick={() => {
									unSelectItem(item);
								}}>
								<EvaIcon type="trash-2-outline" fill="#17928c" height="18" width="18" />
							</span>
						</div>
					</li>
				))}
			</ul>
		</>
	);
}

MultiSelectAutoComplete.propTypes = {
	placeholder: PropTypes.string,
	dataSource: PropTypes.string,
	options: PropTypes.array,
	items: PropTypes.array.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	async: PropTypes.bool,
	showItemsOnFirstClick: PropTypes.bool,
};

AsyncAutoComplete.defaultProps = {
	async: false,
	showItemsOnFirstClick: false,
};
