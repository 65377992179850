import find from "lodash/find";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { CmsTypesContext } from "../../cms-types-context";
import Text from "../attributes/Text";
import VideoAttribute from "../attributes/Video";

export default function Video(props) {
	const [videovalues, setVideoValues] = useState(props.values ? props.values : {});
	useEffect(() => {
		setVideoValues(props.values ? props.values : {});
	}, [props.values]);

	const handleVideoValueChange = (key, value) => {
		const newVideovalues = { ...videovalues };
		newVideovalues[key] = value;
		setVideoValues(newVideovalues);
		props.onChange(newVideovalues);
	};

	const { partTypes } = useContext(CmsTypesContext);
	const partType = find(partTypes, partType => partType.key === "video");

	if (partType) {
		return (
			<>
				{props.label && <label>{props.label}</label>}

				<div className="flex bg-primary-lightest pr-3 pl-4 py-3 my-2">
					<div className="w-1/2">
						<VideoAttribute
							value={videovalues.video}
							onChange={value => {
								handleVideoValueChange("video", value);
							}}
						/>
					</div>
					<div className="w-1/2 ml-2">
						<Text
							label={partType.attributes.videoname.label}
							value={videovalues.videoname}
							onChange={value => {
								handleVideoValueChange("videoname", value);
							}}
						/>
					</div>
				</div>
			</>
		);
	}
}

Video.propTypes = {
	label: PropTypes.string,
	values: PropTypes.shape({
		videoname: PropTypes.string,
		video: PropTypes.number,
	}),
	onChange: PropTypes.func.isRequired,
};
