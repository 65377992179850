import assign from "lodash/assign";
import Nav from "../../../../../js/lib/Nav";
import { createLoader } from "../../../../../js/react/components/general/notifications";
import Tippy from "@tippyjs/react";
import axios from "axios";
import { useState } from "react";
import { route, yesNoOptions } from "../../../../../js/helpers";
import { uctrans, trans } from "../../../../../js/lib/Translator";
import DeleteDialog from "../../../../../js/react/components/general/DeleteDialog";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DatePickerFilter from "../../../../../js/react/components/general/overview/DatePickerFilter";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import SimpleTable from "../../../../../js/react/components/general/table/SimpleTable";
import SimpleTableColumn from "../../../../../js/react/components/general/table/SimpleTableColumn";
import SimpleTableColumns from "../../../../../js/react/components/general/table/SimpleTableColumns";
import getEngagementScoreColor from "../engagementScore/EngagementScoreColor";
import EngagementScoreDifference from "../engagementScore/EngagementScoreDifference";
import TabLinks from "../general/tabs/TabLinks";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import IconButton from "../../../../../js/react/components/general/IconButton";

export default function ContactpersonOverview() {
	const [overviewData, setOverviewData] = useState({
		overview: { ...window.data.overview },
		create_link: window.data.contactperson_create_link,
		create_existing_link: window.data.contactperson_create_existing_link,
		index_link: window.data.contactperson_index_link,
		permissions: window.data.contactpersons_permissions,
		enabled_filters: window.data.enabled_filters,
		organisation_contactpersons: window.data.organisation_contactpersons,
		page_title: window.data.page_title,
		resultData: {
			errors: [],
			warnings: [],
		},
		dialogOpen: false,
		requestKind: "",
	});

	const deleteContactperson = async contactperson => {
		const result = await axios.get(contactperson.delete_link);

		if (result.status === 200) {
			toggleDecisionDialog(result.data, "deleted");
		}
	};

	const deactivateContactperson = async contactperson => {
		const result = await axios.get(contactperson.deactivate_link);

		if (result.status === 200) {
			toggleDecisionDialog(result.data, "deactivated");
		}
	};

	const toggleDecisionDialog = async (resultData = null, requestKind = null) => {
		setOverviewData({
			...overviewData,
			resultData,
			dialogOpen: !overviewData.dialogOpen,
			requestKind,
		});
	};

	const handleDialogSubmit = async overview => {
		const loader = createLoader(
			uctrans(`general.:item_is_being_${overviewData.requestKind}`, {}, { item: "contactpersons.singular" }),
		);
		const result =
			overviewData.requestKind === "deleted"
				? await axios.post(overviewData.resultData.destroy_link, { _method: "delete" })
				: await axios.put(overviewData.resultData.destroy_link);

		if (result.status === 200) {
			loader.success(uctrans(`general.${overviewData.requestKind}_:item`, {}, { item: "contactpersons.singular" }));
			toggleDecisionDialog();
			overview.loadData();
		} else {
			loader.failure("something went wrong deleting the user TODO -> show this error message");
		}
	};

	const handleExport = (type, overviewParameters) => {
		Nav.go(route(`crm.contactpersons.export`, assign({ type }, overviewParameters)));
	};

	return (
		<>
			{window.data.nav_data && (
				<>
					<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
					<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
				</>
			)}
			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.page_title ? window.data.page_title : uctrans("contactpersons.plural")}
				</h1>
				<div>
					{overviewData.create_existing_link && (
						<IconButton
							href={overviewData.create_existing_link}
							content={uctrans("general.add_:item", {}, { item: "contactpersons.existing.singular" })}
							primary
							icon="person-add-outline"
						/>
					)}
					{overviewData.create_link && (
						<IconButton
							className="ml-5"
							href={overviewData.create_link}
							content={uctrans("general.add_:item", {}, { item: "contactpersons.singular" })}
							primary
							icon="person-add-outline"
						/>
					)}

					{window.data.agendaNamespace && (
						<a className="link ml-5" href={route("crm.agenda-items.index")}>
							{uctrans("general.back_to_:page", {}, { page: "agendaitems.accountsupport" })}
						</a>
					)}
				</div>
			</div>
			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={overviewData.index_link}
				modelTranslations={{ plural: trans("contactpersons.plural"), singular: trans("contactpersons.singular") }}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
						{overviewData.enabled_filters.indexOf("is_active") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="is_active"
								label={uctrans("contactpersons.fields.active")}
								options={yesNoOptions()}
								collapsible={!window.data.relation_manager}
							/>
						)}
						{overviewData.enabled_filters.indexOf("relation_manager") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="relation_manager"
								label={uctrans("contactpersons.fields.relation_manager")}
								options={overviewData.overview.filterdata.relation_managers}
								collapsible={!window.data.relation_manager}
							/>
						)}
						{overviewData.enabled_filters.indexOf("occupation") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="occupation"
								label={uctrans("contactpersons.fields.occupation")}
								options={overviewData.overview.filterdata.occupations}
								collapsible={!window.data.relation_manager}
							/>
						)}
						{overviewData.enabled_filters.indexOf("organisation") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="organisation"
								label={uctrans("intermediaries.fields.organisation")}
								options={overviewData.overview.filterdata.organisations}
								collapsible
							/>
						)}
						{overviewData.enabled_filters.indexOf("segment") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="segment"
								label={uctrans("intermediaries.fields.segment")}
								options={overviewData.overview.filterdata.segments}
								collapsible
							/>
						)}
						{overviewData.enabled_filters.indexOf("team") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="team"
								label={uctrans("intermediaries.fields.team")}
								options={overviewData.overview.filterdata.teams}
								collapsible
							/>
						)}
						{overviewData.enabled_filters.indexOf("interest_type") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="interest_type"
								label={uctrans("contactpersons.fields.interests")}
								options={overviewData.overview.filterdata.interest_types}
								collapsible
							/>
						)}
						{overviewData.enabled_filters.indexOf("website_permissiongroup") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="website_permissiongroup"
								label={uctrans("contactpersons.fields.website_permissiongroup")}
								options={overviewData.overview.filterdata.contactperson_permissiongroups}
								collapsible
							/>
						)}
						{overviewData.enabled_filters.indexOf("uses_app") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="uses_app"
								label={uctrans("contactpersons.uses_app")}
								options={yesNoOptions()}
								collapsible
							/>
						)}

						{overviewData.enabled_filters.indexOf("not_logged_in_since") > -1 && (
							<DatePickerFilter
								className="form-1-4"
								name="not_logged_in_since"
								label={uctrans("contactpersons.not_logged_in_since")}
								collapsible
							/>
						)}
						{overviewData.enabled_filters.indexOf("logged_in_since") > -1 && (
							<DatePickerFilter
								className="form-1-4"
								name="logged_in_since"
								label={uctrans("contactpersons.logged_in_since")}
								collapsible
							/>
						)}
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={1}
							renderHeaderContent={() => ""}
							renderCellContent={person =>
								person.has_photo ? (
									<div className="avatar-img">
										<img
											className="max-h-full self-center"
											src={route("crm.contactpersons.photo-thumbnail", person.id)}
											alt={person.name}
										/>
									</div>
								) : (
									<div className="avatar-img">
										<EvaIcon type="person" fill="#249286" height="35" width="35" />
									</div>
								)
							}
						/>
						<DataColumn
							width={3}
							sortable="last_name"
							renderHeaderContent={() => uctrans("contactpersons.fields.name")}
							renderCellContent={person => (
								<a href={person.edit_link} className="row-title link">
									{person.name}
								</a>
							)}
						/>
						<DataColumn
							width={4}
							sortable="employments"
							renderHeaderContent={() => uctrans("contactpersons.fields.workingby")}
							renderCellContent={person => (
								<span className="small-overview-column"> {person.employments && person.employments.label} </span>
							)}
						/>
						<DataColumn
							width={3}
							sortable="occupation.name"
							renderHeaderContent={() => uctrans("contactpersons.fields.occupation")}
							renderCellContent={person => (
								<span className="small-overview-column"> {person.occupation && person.occupation.label}</span>
							)}
						/>
						<DataColumn
							width={4}
							sortable="email"
							renderHeaderContent={() => uctrans("contactpersons.fields.email")}
							renderCellContent={person => <span className="small-overview-column word-break">{person.email}</span>}
						/>
						<DataColumn
							width={2}
							sortable="mobile"
							renderHeaderContent={() => uctrans("contactpersons.fields.phone")}
							renderCellContent={person => (
								<>
									<span className="small-overview-column">{person.landline}</span>
									<span className="small-overview-column block">{person.mobile}</span>
								</>
							)}
						/>
						<DataColumn
							width={2}
							sortable="website_role"
							renderHeaderContent={() => uctrans("roles.singular")}
							renderCellContent={person => <span className="small-overview-column"> {person.website_role}</span>}
						/>
						<DataColumn
							width={2}
							sortable="engagement_score"
							renderHeaderContent={() => (
								<Tippy
									placement="right"
									arrow
									animation="perspective"
									duration="400"
									content={uctrans("engagement_score.contactperson_overview_mouse_over")}>
									<span>{uctrans("engagement_score.short")}</span>
								</Tippy>
							)}
							renderCellContent={person => (
								<div className="flex items-center justify-end">
									<div>
										<div
											className="rounded-full small-circle-engagement-score flex items-center justify-center"
											style={{
												background: getEngagementScoreColor(
													overviewData.overview.filterdata.total_relative_engagement_score,
													person.engagement_score,
												),
											}}>
											<span className="font-medium">{person.engagement_score}</span>
										</div>
									</div>
								</div>
							)}
						/>
						<DataColumn
							width={1}
							sortable="engagement_score_to_compare"
							renderHeaderContent={() => (
								<Tippy
									placement="right"
									arrow
									animation="perspective"
									duration="400"
									content={uctrans("engagement_score.score_to_compare_mouse_over")}>
									<span>
										<EvaIcon type="trending-up-outline" width={20} height={20} fill="white" />
									</span>
								</Tippy>
							)}
							renderCellContent={person => (
								<EngagementScoreDifference
									score={person.engagement_score}
									scoreToCompare={person.engagement_score_to_compare}
									iconSize={20}
								/>
							)}
						/>
						<DataColumn
							width={1}
							renderHeaderContent={() => ""}
							renderCellContent={(contactperson, overview) => (
								<div className="overview-actions flex">
									{overviewData.permissions.deactivate &&
										contactperson.is_active === 1 &&
										contactperson.deactivate_link && (
											<a onClick={() => deactivateContactperson(contactperson, overview)}>
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("contactpersons.fields.deactivate")}>
													<span>
														<EvaIcon type="power-outline" fill="#009286" height="18" width="18" />
													</span>
												</Tippy>
											</a>
										)}
									{overviewData.permissions.delete && contactperson.delete_link && (
										<a className="ml-5" onClick={() => deleteContactperson(contactperson, overview)}>
											<Tippy
												placement="right"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("users.fields.remove")}>
												<span>
													<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
												</span>
											</Tippy>
										</a>
									)}
								</div>
							)}
						/>
					</DataTable>
				)}
				renderDeleteDialog={overview => (
					<DeleteDialog
						onConfirm={() => handleDialogSubmit(overview)}
						onCancel={() => setOverviewData({ ...overviewData, dialogOpen: false })}
						isOpen={overviewData.dialogOpen}
						deleteData={overviewData.resultData}
					/>
				)}
				renderFooter={overview =>
					window.data.activeMenuItem === "contactpersons" ? (
						<>
							<a
								className="button button-primary mr-1"
								onClick={() => handleExport("default", overview.getParameters())}>
								{uctrans("intermediaries.export")}
							</a>
							<a
								className="button button-primary mr-1"
								onClick={() => handleExport("contactdata", overview.getParameters())}>
								{uctrans("intermediaries.export_contactdata")}
							</a>
						</>
					) : (
						""
					)
				}
			/>
			{overviewData.organisation_contactpersons && (
				<>
					<h1 className="mt-10 mb-5">Contactpersonen van organisatie</h1>
					<SimpleTable data={overviewData.organisation_contactpersons}>
						<SimpleTableColumns>
							<SimpleTableColumn
								renderHeaderContent={() => ""}
								renderBodyContent={person =>
									person.has_photo ? (
										<div className="avatar-img">
											<img
												className="max-h-full self-center"
												src={route("crm.contactpersons.photo-thumbnail", person.id)}
												alt={person.name}
											/>
										</div>
									) : (
										<div className="avatar-img">
											<EvaIcon type="person" fill="#249286" height="35" width="35" />
										</div>
									)
								}
								cellWidth="w-1/24"
							/>
							<SimpleTableColumn
								renderHeaderContent={() => uctrans("contactpersons.fields.name")}
								renderBodyContent={contactperson => <span className="small-overview-column">{contactperson.name}</span>}
								cellWidth="w-3/24"
							/>
							<SimpleTableColumn
								renderHeaderContent={() => uctrans("contactpersons.fields.workingby")}
								renderBodyContent={contactperson => (
									<span className="small-overview-column">{contactperson.work.label}</span>
								)}
								cellWidth="w-4/24"
							/>
							<SimpleTableColumn
								renderHeaderContent={() => uctrans("contactpersons.fields.occupation")}
								renderBodyContent={contactperson => (
									<span className="small-overview-column">{contactperson.occupation_name}</span>
								)}
								cellWidth="w-3/24"
							/>
							<SimpleTableColumn
								renderHeaderContent={() => uctrans("contactpersons.fields.email")}
								renderBodyContent={contactperson => (
									<span className="small-overview-column">{contactperson.email}</span>
								)}
								cellWidth="w-4/24"
							/>
							<SimpleTableColumn
								renderHeaderContent={() => uctrans("contactpersons.fields.phone")}
								renderBodyContent={contactperson => (
									<span className="small-overview-column">{contactperson.mobile}</span>
								)}
								cellWidth="w-2/24"
							/>
							<SimpleTableColumn
								renderHeaderContent={() => uctrans("roles.singular")}
								renderBodyContent={contactperson => <span className="small-overview-column">{contactperson.role}</span>}
								cellWidth="w-2/24"
							/>
						</SimpleTableColumns>
					</SimpleTable>
				</>
			)}
		</>
	);
}
