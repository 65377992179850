import classnames from "classnames";
import PropTypes from "prop-types";
import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import ConfirmDialog from "../../../../../js/react/components/general/ConfirmDialog";
import Dialog from "../../../../../js/react/components/general/Dialog";
import { createLoader } from "../../../../../js/react/components/general/notifications";
import Spinner from "../../../../../js/react/components/general/Spinner";
const TextEditor = lazy(() => import(/* webpackChunkName: "tinymce" */ "../forms/textEditor/TextEditor"));
import axios from "axios";

export default function PersonalBirthdayMessageDialog({ opened, onClose, onEditSuccess, data }) {
	const [message, setMessage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);

	useEffect(() => {
		if (data && data.birthday_message !== null) {
			setMessage(data.birthday_message);
		} else {
			setMessage(null);
		}
	}, [data]);

	const savePersonalMessage = useCallback(async () => {
		setLoading(true);
		const loader = createLoader(uctrans("general.:item_is_being_saved", {}, { item: "birthday.personal_message" }));
		try {
			const response = await axios.put(
				route("crm.contactpersons.update-birthday-message", { message, contactperson: data.id }),
			);

			if (response.data && response.data.success) {
				loader.success(uctrans("general.saved_:item", {}, { item: "birthday.personal_message" }));
			}

			onEditSuccess();
		} catch (error) {
			console.error(error);
			loader.failure(error.message);
		}

		setLoading(false);
	}, [data, message, onEditSuccess]);

	const deletePersonalMessage = useCallback(async () => {
		setLoading(true);
		const loader = createLoader(uctrans("general.:item_is_being_deleted", {}, { item: "birthday.personal_message" }));
		try {
			const response = await axios.delete(route("crm.contactpersons.delete-birthday-message", data.id));

			if (response.data && response.data.success) {
				loader.success(uctrans("general.deleted_:item", {}, { item: "birthday.personal_message" }));
			}

			onEditSuccess();
		} catch (error) {
			console.error(error);
			loader.failure(error.message);
		}

		setLoading(false);
	}, [data, onEditSuccess]);

	const buttonClasses = classnames("button button-primary", {
		disabled: loading,
	});

	return (
		<Dialog
			isOpen={opened}
			onClose={onClose}
			shouldCloseOnOverlayClick={false}
			width={600}
			title={uctrans("birthday.edit_personal_message_for_:contactperson", {
				contactperson: data ? `${data.first_name} ${data.insertion} ${data.last_name}` : "",
			})}>
			<div className="form-container">
				{data && (
					<div className="form-full">
						<label>{uctrans("birthday.age")}</label>
						<p>{data.ageNextBirthDay}</p>

						<label>{uctrans("contactpersons.fields.workingby")}</label>
						<ul>
							{data.employed_companies.map((company, index) => (
								<li key={index} className="flex mb-3">
									{company.company_name}
								</li>
							))}
						</ul>
					</div>
				)}

				<div className="form-full">
					<label htmlFor="content">{uctrans("birthday.content")}</label>
					<Suspense
						fallback={
							<div style={{ width: 32, height: 32 }}>
								<Spinner />
							</div>
						}>
						<TextEditor
							type="birthdayMessage"
							value={message ? `${message}` : ""}
							onChange={value => setMessage(value)}
						/>
					</Suspense>
				</div>

				{data && data.birthday_message && (
					<div className="form-full">
						<a className="link-red" onClick={() => setConfirmOpen(true)}>
							{uctrans("birthday.delete_personal_message")}
						</a>
					</div>
				)}

				<div className="form-full">
					<a className={buttonClasses} onClick={() => savePersonalMessage()}>
						{uctrans("birthday.save_personal_message")}
					</a>
					<a className="link ml-5" onClick={() => onClose()}>
						{uctrans("general.cancel")}
					</a>
				</div>
			</div>

			<ConfirmDialog
				isOpen={confirmOpen}
				onConfirm={() => {
					deletePersonalMessage();
					setConfirmOpen(false);
				}}
				onCancel={() => setConfirmOpen(false)}
				title={uctrans("birthday.are_you_sure_you_want_to_delete_personal_message")}
			/>
		</Dialog>
	);
}
PersonalBirthdayMessageDialog.propTypes = {
	opened: PropTypes.bool,
	onClose: PropTypes.func,
	onEditSuccess: PropTypes.func,
	data: PropTypes.object,
};
