import PropTypes from "prop-types";
import { Fragment, useMemo } from "react";
import { urlHttpPrefix } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import Tippy from "@tippyjs/react";

export default function KvkEstablishment({ establishment, highlightEstablishment, notificationSbiCodes }) {
	const suspectedSbiCodeActivities = useMemo(
		() =>
			establishment.sbi_codes && establishment.sbi_codes.length
				? establishment.sbi_codes
						.map(sbiCode => sbiCode.split("-")[0].trim())
						.filter(code => notificationSbiCodes.indexOf(code) !== -1)
				: [],
		[establishment.sbi_codes, notificationSbiCodes],
	);

	return (
		<li
			className={`establishment-row w-full mt-2 border p-2 ${
				highlightEstablishment ? "bg-primary-lightest" : establishment.unknown ? "bg-red-lightest" : "bg-grey-lighter"
			}`}>
			<ul>
				<li className="flex justify-between">
					<div className="flex">
						<span style={{ width: "150px", minWidth: "150px" }} className="text-sm font-bold">
							{uctrans("statutory_companies.establishments.location_number")}
						</span>
						<span className="text-sm">{establishment.kvk_location_number || "-"}</span>
					</div>
					{establishment.is_main_company && (
						<span className="bg-yellow-lighter text-sm font-bold">
							{uctrans("statutory_companies.fields.main_company_id")}
						</span>
					)}
				</li>
				<li className="flex">
					<span style={{ width: "150px", minWidth: "150px" }} className=" text-sm font-bold">
						{uctrans("statutory_companies.establishments.name")}
					</span>
					<span className="text-sm">
						{Array.isArray(establishment.name) ? establishment.name.join(", ") : establishment.name}
					</span>
				</li>
				<li className="flex">
					<span style={{ width: "150px", minWidth: "150px" }} className=" text-sm font-bold">
						{uctrans("statutory_companies.establishments.address")}
					</span>
					<span className="text-sm">{establishment.address || "-"}</span>
				</li>
				{establishment.email_address ? (
					<li className="flex">
						<span style={{ width: "150px", minWidth: "150px" }} className="text-sm font-bold">
							{uctrans("statutory_companies.establishments.email_address")}
						</span>
						<a className="link text-sm" href={`mailto:${establishment.email_address}`}>
							{establishment.email_address || "-"}
						</a>
					</li>
				) : null}
				{establishment.website && establishment.website.length ? (
					<li className="flex">
						<span style={{ width: "150px", minWidth: "150px" }} className="text-sm font-bold">
							{uctrans("statutory_companies.establishments.website")}
						</span>

						{Array.isArray(establishment.website) ? (
							establishment.website.map((website, index) => (
								<Fragment key={index}>
									<a className="link text-sm">{website}</a>
									{index !== establishment.website.length - 1 && <span className="text-sm">,&nbsp;</span>}
								</Fragment>
							))
						) : (
							<a
								className="link text-sm"
								target="_blank"
								rel="noreferrer noopener"
								href={urlHttpPrefix(establishment.website)}>
								{establishment.website}
							</a>
						)}
					</li>
				) : null}
				{establishment.sbi_codes && establishment.sbi_codes.length ? (
					<li className="flex">
						<span style={{ width: "150px", minWidth: "150px" }} className="text-sm font-bold">
							{uctrans("statutory_companies.activities")}
							{suspectedSbiCodeActivities.length > 0 ? (
								<Tippy
									placement="auto"
									arrow
									animation="perspective"
									duration="400"
									content={uctrans("statutory_companies.establishments.found_notification_sbi_codes", {
										sbi_codes: suspectedSbiCodeActivities.join(", "),
									})}>
									<span className="ml-1 mt-2 text-red">
										<EvaIcon type="alert-triangle-outline" height="18" width="18" fill="#E3342F" />
									</span>
								</Tippy>
							) : null}
						</span>
						<ul>
							{establishment.sbi_codes.map((sbiCode, index) => (
								<li key={index} className="text-sm">
									{sbiCode}
								</li>
							))}
						</ul>
					</li>
				) : null}
			</ul>
		</li>
	);
}

KvkEstablishment.propTypes = {
	establishment: PropTypes.object,
	highlightEstablishment: PropTypes.bool,
	notificationSbiCodes: PropTypes.array,
};

KvkEstablishment.defaultProps = {
	highlightEstablishment: false,
};
