import find from "lodash/find";
import PropTypes from "prop-types";
import { useState } from "react";
import { route, can } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";

export default function Navigation(props) {
	const [clientTreatmentType] = useState(
		find(props.clientTreatmentTypes, type => `${type.key}` === `${props.clientTreatment.type}`),
	);

	const tabs = [];
	if (can("client_treatment.client_treatment", "update")) {
		tabs.push({
			key: "client-treatment",
			label: uctrans("client_treatment.singular"),
			route: route("crm.client-treatments.edit", props.clientTreatment.id),
		});
	} else if (can("client_treatment.client_treatment", "view")) {
		tabs.push({
			key: "client-treatment",
			label: uctrans("client_treatment.singular"),
			route: route("crm.client-treatments.show", props.clientTreatment.id),
		});
	}

	if (can("client_treatment.client_treatment", "view")) {
		tabs.push({
			key: "client-treatment-completions",
			label: uctrans("client_treatment.completion.plural"),
			route: route("crm.client-treatments.completions", props.clientTreatment.id),
		});
	}

	if (props.canUpdate) {
		tabs.push({
			key: "client-treatment-inviters",
			label: uctrans("client_treatment.inviters.plural"),
			route: route("crm.client-treatments.inviters", props.clientTreatment.id),
		});
	} else {
		if (props.loggedInInviter && props.loggedInInviter.id)
			tabs.push({
				key: "client-treatment-inviters",
				label: uctrans("client_treatment.completions.change_completions"),
				route: route("crm.client-treatments.inviters.participants.index", {
					client_treatment: props.clientTreatment.id,
					client_treatment_inviter: props.loggedInInviter.id,
				}),
			});
	}

	if (can("client_treatment.client_treatment", "view")) {
		tabs.push({
			key: "client-treatment-evaluations",
			label: uctrans("client_treatment.evaluations.plural"),
			route: route("crm.client-treatments.evaluations", props.clientTreatment.id),
		});
	}

	if (
		clientTreatmentType &&
		clientTreatmentType.landingpagePossible &&
		can("client_treatment.client_treatment", "update")
	) {
		tabs.push({
			key: "client-treatment-landing-page",
			label: uctrans("client_treatment.landingpage.singular"),
			route: route("crm.client-treatments.landing-page", props.clientTreatment.id),
		});
	}

	return (
		<>
			<BreadCrumbs
				breadCrumbs={[{ label: uctrans("client_treatment.plural"), route: route("crm.client-treatments.index") }]}
			/>
			<TabLinks tabs={tabs} activeTab={props.selectedTab} />
		</>
	);
}
Navigation.propTypes = {
	selectedTab: PropTypes.string.isRequired,
	clientTreatment: PropTypes.object,
	canUpdate: PropTypes.bool,
	clientTreatmentTypes: PropTypes.object,
	loggedInInviter: PropTypes.object,
};
