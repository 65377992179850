import PropTypes from "prop-types";
import { route, can } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../../general/tabs/TabLinks";

export default function Navigation(props) {
	const tabs = [];

	if (can("location.zipcode_area", "view")) {
		tabs.push({
			key: "zipcodeAreas",
			label: uctrans("location.zipcode_areas.plural"),
			route: route("crm.location-settings.zipcode-areas.index"),
		});
	}
	if (can("location.township", "view")) {
		tabs.push({
			key: "townships",
			label: uctrans("location.townships.plural"),
			route: route("crm.location-settings.townships.index"),
		});
	}
	if (can("location.province", "view")) {
		tabs.push({
			key: "provinces",
			label: uctrans("location.provinces.plural"),
			route: route("crm.location-settings.provinces.index"),
		});
	}
	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("location.location_settings") }]} />
			<TabLinks tabs={tabs} activeTab={props.selectedTab} />
		</>
	);
}
Navigation.propTypes = {
	selectedTab: PropTypes.string.isRequired,
};
