import { Component } from "react";
import PropTypes from "prop-types";
import { route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import Translate from "../../../../../js/react/components/general/Translate";
import cloneDeep from "lodash/cloneDeep";

class PageForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			page: cloneDeep(props.page),
			isProfilePage: props.isProfilePage,
		};

		this.updatePageProperty = this.updatePageProperty.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
	}

	updatePageProperty(property, value) {
		const page = Object.assign(this.state.page, {});
		page[property] = value;

		this.setState({
			page,
		});
	}

	handleSuccess(response) {
		if (response.data.success) {
			this.props.onPageSubmit(response.data.page);
		}
	}

	getSubmitUrl() {
		if (this.state.page.id) {
			return route("crm.questionnaires.page.update", {
				questionnaire: this.state.page.questionnaire_id,
				question: this.state.page.id,
			});
		}
		return route("crm.questionnaires.page.store");
	}

	render() {
		return (
			<AjaxForm
				className="grid-x grid-margin-x"
				method={this.state.page.id ? "PUT" : "POST"}
				submitUrl={this.state.page.id ? this.state.page.update_url : this.props.basePageStoreUrl}
				entityName={uctrans("questionnaires.page.singular")}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "questionnaires.page.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "questionnaires.page.singular" })}
				onSuccess={response => this.handleSuccess(response)}
				useFlashMessage={false}
				data={this.state.page}>
				<div className="form-container">
					<div className="form-full">
						<label>
							<Translate content="questionnaires.page.fields.title" />
						</label>
						<input
							type="text"
							name="value"
							value={this.state.page.title || ""}
							onChange={event => this.updatePageProperty("title", event.target.value)}
						/>
					</div>
					<br />
					{this.state.isProfilePage && (
						<>
							<div className="form-full">
								<label>
									<Translate content="questionnaires.page.fields.profile_subject" />
								</label>
								<RadioGroup
									name="profile_subject"
									value={this.state.page.profile_subject}
									onChange={value => this.updatePageProperty("profile_subject", value)}
									horizontal>
									<Radio label={uctrans("general.no")} value={false} />
									<Radio label={uctrans("general.yes")} value />
								</RadioGroup>
							</div>
							<div className="form-full">
								<label>
									<Translate content="questionnaires.page.fields.commercial_subject" />
								</label>
								<RadioGroup
									name="commercial_subject"
									value={this.state.page.commercial_subject}
									onChange={value => this.updatePageProperty("commercial_subject", value)}
									horizontal>
									<Radio label={uctrans("general.no")} value={false} />
									<Radio label={uctrans("general.yes")} value />
								</RadioGroup>
							</div>
						</>
					)}
					<div className="submit-container">
						<button className="button button-primary" type="submit">
							<span>
								<Translate content="general.save" />
							</span>
						</button>
						<a className="ml-5" onClick={this.props.onCloseDialog}>
							<span>
								<Translate content="general.cancel" />
							</span>
						</a>
					</div>
				</div>
			</AjaxForm>
		);
	}
}

export default PageForm;

PageForm.propTypes = {
	onCloseDialog: PropTypes.func,
	onPageSubmit: PropTypes.func,
	page: PropTypes.object,
	basePageStoreUrl: PropTypes.string,
	isProfilePage: PropTypes.bool,
};

PageForm.defaultProps = {
	formType: "add",
};
