import map from "lodash/map";
import last from "lodash/last";
import axios from "axios";
import { useState, useCallback } from "react";
import { format } from "../../../../../js/lib/Date";
import Nav from "../../../../../js/lib/Nav";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import { route } from "../../../../../js/helpers";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import { notify } from "../../../../../js/react/components/general/notifications";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";

export default function LoanDetail() {
	const [loanSearchNumber, setLoanSearchNumber] = useState("");
	const [loan] = useState({ ...window.data.loan });
	const [intermediary] = useState(window.data.intermediary ? { ...window.data.intermediary } : null);
	const [organisation] = useState(window.data.organisation ? { ...window.data.organisation } : null);

	const getColorforPart = part => {
		if (part.role && part.role.for_revenue) {
			if (part.mutations && part.mutations.length > 0) {
				const lastMutation = last(part.mutations);
				if (lastMutation.status) {
					switch (lastMutation.status.code) {
						case 110:
						case 130:
						case 210:
						case 212:
						case 215:
						case 220:
						case 230:
							return "yellow-lightest";
						case 320:
							return "green-lightest";
					}
				}
			}
			return "purple-lightest";
		}
		return "grey-lighter";
	};

	const searchLoan = useCallback(async () => {
		if (!loanSearchNumber) {
			return;
		}
		try {
			const nr = `${loanSearchNumber}`;
			const response = await axios.get(route(`crm.loans.search`), { params: { loanNumber: nr } });
			if (response.data.loanFound) {
				Nav.go(route("crm.loans.show", { loan: response.data.loanId }));
			} else {
				notify("error", uctrans("loans.messages.loan_:nr_not_found", { nr }), null);
			}
		} catch (error) {
			console.error(error);
			notify("error", error, null);
		}
	}, [loanSearchNumber]);

	const returnLoanSearchHTML = () => (
		<>
			<div className="form-container">
				<div className="form-1-3">
					<LabeledTextInput
						name="loan_number"
						type="number"
						value={loanSearchNumber}
						onChange={event => {
							const { target } = event;
							setLoanSearchNumber(target.value);
						}}
						label="loans.fields.number"
					/>
				</div>
			</div>
			<div className="form-container">
				<button className="button button-primary" onClick={searchLoan} type="button">
					<EvaIcon type="search-outline" height="18" width="18" />
					{uctrans("general.search_:item", {}, { item: "loans.singular" })}
				</button>
			</div>
			<br />
		</>
	);

	return (
		<>
			{intermediary && (
				<>
					<BreadCrumbs
						breadCrumbs={[
							{ label: uctrans("intermediaries.plural"), route: route("crm.intermediaries.index") },
							{ label: intermediary.label },
						]}
					/>
					<TabLinks tabs={intermediary.tabs} activeTab="loans" />
					{returnLoanSearchHTML()}
					<div className="page-title-container">
						<h1 className="page-title">
							{uctrans("general.data_from_:item", { item: `${trans("loans.singular")} ${loan.number}` })}
						</h1>
						<a className="link" href={route("crm.intermediaries.loans.index", { intermediary: intermediary.id })}>
							{uctrans("general.back_to_overview_:page", {}, { page: "loans.plural" })}
						</a>
					</div>
				</>
			)}
			{organisation && (
				<>
					<BreadCrumbs
						breadCrumbs={[
							{ label: uctrans("organisations.plural"), route: route("crm.organisations.index") },
							{ label: organisation.label },
						]}
					/>
					<TabLinks tabs={organisation.tabs} activeTab="loans" />
					{returnLoanSearchHTML()}
					<div className="page-title-container">
						<h1 className="page-title">
							{uctrans("general.data_from_:item", { item: `${trans("loans.singular")} ${loan.number}` })}
						</h1>
						<a className="link" href={route("crm.organisations.loans.index", { organisation: organisation.id })}>
							{uctrans("general.back_to_overview_:page", {}, { page: "loans.plural" })}
						</a>
					</div>
				</>
			)}
			{!organisation && !intermediary && (
				<>
					<BreadCrumbs
						breadCrumbs={[
							{
								label: uctrans("general.search_:items", {}, { items: "loans.plural" }),
								route: route("crm.loans.index"),
							},
							{ label: `${uctrans("loans.singular")} ${loan.number}` },
						]}
					/>
					{returnLoanSearchHTML()}

					<div className="page-title-container">
						<h1 className="page-title">
							{uctrans("loans.singular")} {loan.number}
						</h1>
						<a className="link" href={route("crm.loans.index")}>
							{uctrans("general.back_to_:page", {
								page: trans("general.search_:items", {}, { items: "loans.plural" }),
							})}
						</a>
					</div>
				</>
			)}

			{(() =>
				map(loan.versions, version => (
					<div className="w-full border p-5 mb-5 bg-grey-lightest" key={version.id}>
						<h3 className="flex justify-between border-b p-2">
							<span>
								{uctrans("loans.versions.fields.number")} {version.number}
							</span>
							<span>&euro; {parseInt(version.calculated_sum).toLocaleString("nl")}</span>
						</h3>
						<div className="flex items-stretch mb-2">
							<div className="flex-1 border-r p-2">
								<ul>
									<li className="flex">
										<span className="w-1/2 text-sm font-bold">{uctrans("loans.versions.fields.company")}</span>
										<span className="w-1/2 text-sm">{version.company ? version.company.name : "-"}</span>
									</li>
									<li className="flex">
										<span className="w-1/2 text-sm font-bold">{uctrans("loans.versions.fields.brand")}</span>
										<span className="w-1/2 text-sm">{version.brand ? version.brand.name : "-"}</span>
									</li>
									<li className="flex">
										<span className="w-1/2 text-sm font-bold">{uctrans("loans.versions.fields.channel")}</span>
										<span className="w-1/2 text-sm">{version.channel ? version.channel.name : "-"}</span>
									</li>
									<li className="flex">
										<span className="w-1/2 text-sm font-bold">{uctrans("loans.versions.fields.request_type")}</span>
										<span className="w-1/2 text-sm">{version.requestType ? version.requestType.name : "-"}</span>
									</li>
									<li className="flex">
										<span className="w-1/2 text-sm font-bold">{uctrans("loans.versions.fields.calculated_sum")}</span>
										<span className="w-1/2 text-sm">
											&euro; {parseInt(version.calculated_sum).toLocaleString("nl")}
										</span>
									</li>
								</ul>
							</div>
							<div className="flex-1 p-2">
								<ul>
									<li className="flex">
										<span className="w-1/2 text-sm font-bold">{uctrans("loans.versions.fields.zipcode")}</span>
										<span className="w-1/2 text-sm">{version.zipcode ? version.zipcode : "-"}</span>
									</li>
									<li className="flex">
										<span className="w-1/2 text-sm font-bold">{uctrans("location.zipcode_areas.fields.city")}</span>
										<span className="w-1/2 text-sm">
											{version.zipcodeArea && version.zipcodeArea.city ? version.zipcodeArea.city : "-"}
										</span>
									</li>
									<li className="flex">
										<span className="w-1/2 text-sm font-bold">{uctrans("location.provinces.singular")}</span>
										<span className="w-1/2 text-sm">
											{version.zipcodeArea && version.zipcodeArea.township && version.zipcodeArea.township.province
												? version.zipcodeArea.township.province.name
												: "-"}
										</span>
									</li>
									<li className="flex">
										<span className="w-1/2 text-sm font-bold">{uctrans("location.townships.singular")}</span>
										<span className="w-1/2 text-sm">
											{version.zipcodeArea && version.zipcodeArea.township ? version.zipcodeArea.township.name : "-"}
										</span>
									</li>
								</ul>
							</div>
						</div>
						{(() =>
							map(version.parts, part => (
								<div className={`w-full border p-5 mb-5 bg-${getColorforPart(part)}`} key={part.id}>
									<h4 className="flex justify-between border-b p-2">
										<span>
											{uctrans("loans.versions.parts.singular")} #{part.number}
										</span>
										<span>&euro; {parseInt(part.sum).toLocaleString("nl")}</span>
									</h4>
									<div className="flex items-stretch">
										<div className="flex-1 border-r p-2">
											<ul>
												<li className="flex">
													<span className="w-1/2 text-sm font-bold">
														{uctrans("loans.versions.parts.fields.repay_type")}
													</span>
													<span className="w-1/2 text-sm">{part.repayType ? part.repayType.name : "-"}</span>
												</li>
												<li className="flex">
													<span className="w-1/2 text-sm font-bold">
														{uctrans("loans.versions.parts.fields.product")}
													</span>
													<span className="w-1/2 text-sm">{part.product ? part.product.name : "-"}</span>
												</li>
												<li className="flex">
													<span className="w-1/2 text-sm font-bold">
														{uctrans("loans.versions.parts.fields.duration")}
													</span>
													<span className="w-1/2 text-sm">
														{part.duration ? `${part.duration} ${trans("general.months")}` : "-"}
													</span>
												</li>
												<li className="flex">
													<span className="w-1/2 text-sm font-bold">
														{uctrans("loans.versions.parts.fields.fixed_interest_period")}
													</span>
													<span className="w-1/2 text-sm">
														{part.fixed_interest_period
															? `${part.fixed_interest_period} ${trans("general.months")}`
															: "-"}
													</span>
												</li>
											</ul>
										</div>
										<div className="flex-1 border-r p-2">
											<ul>
												<li className="flex">
													<span className="w-1/2 text-sm font-bold">{uctrans("loans.versions.parts.fields.role")}</span>
													<span className="w-1/2 text-sm">{part.role ? part.role.name : "-"}</span>
												</li>
												<li className="flex">
													<span className="w-1/2 text-sm font-bold">{uctrans("loans.versions.parts.fields.sum")}</span>
													<span className="w-1/2 text-sm"> &euro; {parseInt(part.sum).toLocaleString("nl")}</span>
												</li>
												<li className="flex">
													<span className="w-1/2 text-sm font-bold">{uctrans("loans.versions.parts.fields.debt")}</span>
													<span className="w-1/2 text-sm"> &euro; {parseInt(part.debt).toLocaleString("nl")}</span>
												</li>
												<li className="flex">
													<span className="w-1/2 text-sm font-bold">
														{uctrans("loans.versions.parts.fields.guarantee")}
													</span>
													<span className="w-1/2 text-sm">
														{part.guarantee ? uctrans("general.yes") : uctrans("general.no")}
													</span>
												</li>
												<li className="flex">
													<span className="w-1/2 text-sm font-bold">
														{uctrans("loans.versions.parts.fields.housebank")}
													</span>
													<span className="w-1/2 text-sm">
														{part.housebank ? uctrans("general.yes") : uctrans("general.no")}
													</span>
												</li>
											</ul>
										</div>
										<div className="flex-1 p-2">
											<ul>
												{(() =>
													map(part.mutations, mutation => (
														<li className="flex" key={mutation.id}>
															<span className="w-1/2 text-sm font-bold">
																{mutation.when ? format(mutation.when, "dd-MM-y HH:mm:ss") : "-"}
															</span>
															<span className="w-1/2 text-sm">
																{mutation.status ? `${mutation.status.code} - ${mutation.status.label}` : "-"}
															</span>
														</li>
													)))()}
											</ul>
										</div>
									</div>
								</div>
							)))()}
					</div>
				)))()}
		</>
	);
}
