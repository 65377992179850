import map from "lodash/map";
import { useState } from "react";
import { can } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import ClickToSelect from "../../../../../../js/react/components/general/ClickToSelect";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import Select from "../../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import { CmsTypesContext } from "../../cms/cms-types-context";
import FileInput from "../../forms/FileInput";
import TextEditor from "../../forms/textEditor/TextEditor";
import CollapsibleContent from "../../general/CollapsibleContent";
import Navigation from "./Navigation";
import Tippy from "@tippyjs/react";

export default function ClientLeadSettingsForm() {
	const clientLeadSettingTypes = [...window.data.client_lead_setting_types];
	const cmsTypes = { ...window.data.cms_types };
	const newsletterTags = { ...window.data.tags_sidebar };
	const selectOptions = {};

	const [clientLeadSettings, setClientLeadSettings] = useState({ ...window.data.client_lead_settings });

	const canEdit = can("client_consent.client_lead_setting", "update");
	const onSuccess = response => {
		setClientLeadSettings(response.data.client_lead_settings);
	};

	const editClientLeadSettingField = (key, value) => {
		const newClientLeadSettings = { ...clientLeadSettings };
		newClientLeadSettings[key] = value === "" ? null : value;
		setClientLeadSettings(newClientLeadSettings);
	};

	const getFormField = clientLeadSettingType => {
		let options = [];
		const value = clientLeadSettings[clientLeadSettingType.key];
		switch (clientLeadSettingType.fieldType) {
			case "boolean":
				return (
					<Checkmark
						disabled={!canEdit}
						name={clientLeadSettingType.key}
						checked={!!value}
						onChange={e => editClientLeadSettingField(clientLeadSettingType.key, !!e.target.checked)}
					/>
				);
			case "html":
				return (
					<TextEditor
						type="website"
						value={value == null ? "" : value}
						onChange={html => editClientLeadSettingField(clientLeadSettingType.key, html)}
					/>
				);
			case "html_mail":
				return (
					<TextEditor
						type="text"
						value={value == null ? "" : value}
						onChange={html => editClientLeadSettingField(clientLeadSettingType.key, html)}
						height={450}
					/>
				);
			case "int":
				return (
					<input
						type="number"
						name={clientLeadSettingType.key}
						value={value === null ? "" : value}
						onChange={e => editClientLeadSettingField(clientLeadSettingType.key, e.target.value)}
						disabled={!canEdit}
					/>
				);
			case "csv":
				return (
					<input
						type="text"
						name={clientLeadSettingType.key}
						value={value !== null ? value.join(",") : ""}
						onChange={e => editClientLeadSettingField(clientLeadSettingType.key, e.target.value.split(","))}
						disabled={!canEdit}
					/>
				);
			case "string":
				return (
					<input
						type="text"
						name={clientLeadSettingType.key}
						value={value === null ? "" : value}
						onChange={e => editClientLeadSettingField(clientLeadSettingType.key, e.target.value)}
						disabled={!canEdit}
					/>
				);
			case "select":
				if (selectOptions.hasOwnProperty(clientLeadSettingType.optionsName)) {
					options = selectOptions[clientLeadSettingType.optionsName];
				}

				return (
					<Select
						options={options}
						onChange={option =>
							editClientLeadSettingField(clientLeadSettingType.key, option && option.value ? option.value : "")
						}
						value={options.filter(option => `${option.value}` === `${value === null ? "" : value}`)}
						isClearable
					/>
				);
			case "file":
				return (
					<FileInput
						initialFiles={value ? [value] : []}
						onChange={files => editClientLeadSettingField(clientLeadSettingType.key, files)}
						existingFileDownloadRoute="crm.files.download"
						fileType="image"
						public
					/>
				);

			default:
				return "-";
		}
	};

	return (
		<div className="min-h-screen md:flex">
			<div className="flex-1">
				<AjaxForm
					method="PUT"
					submitUrl={window.data.submit_url}
					loaderText={uctrans("general.:items_are_being_saved", {}, { items: "client_consents.leads_settings.plural" })}
					successText={uctrans("general.saved_:items", {}, { items: "client_consents.leads_settings.plural" })}
					data={{ client_lead_settings: clientLeadSettings }}
					onSuccess={onSuccess}>
					<Navigation selectedTab={window.data.selected_tab} />

					<div className="page-title-container">
						<h1 className="page-title">{uctrans("client_consents.leads_settings.plural")}</h1>
					</div>
					<div className="form-full">
						<table className="table overview">
							<thead>
								<tr>
									<th>{uctrans("client_consents.leads_settings.fields.key")}</th>
									<th>{uctrans("client_consents.leads_settings.fields.value")}</th>
									<th />
								</tr>
							</thead>
							<tbody>
								<CmsTypesContext.Provider value={cmsTypes}>
									{clientLeadSettingTypes.map((clientLeadSettingType, index) => {
										const isEmail = clientLeadSettingType.fieldType === "html_mail";
										return (
											<tr key={index}>
												<td colSpan="1">{clientLeadSettingType.label}</td>
												<td colSpan={isEmail ? "1" : "2"}>{getFormField(clientLeadSettingType)}</td>
												{isEmail && (
													<td colSpan="1">
														{map(newsletterTags, (tags, category) => (
															<CollapsibleContent
																key={category}
																title={uctrans(`communication.tags.categories.${category}`)}
																initialCollapseState={false}>
																<div className="text-xs">
																	{map(tags, (tag, i) => (
																		<div className="flex justify-between" key={i}>
																			<ClickToSelect>{`{${tag.tag}}`}</ClickToSelect>
																			<Tippy
																				placement="right"
																				arrow
																				animation="perspective"
																				duration="400"
																				content={
																					<p>
																						{tag.description}
																						<br />
																						<strong>Default:</strong> {tag.default}
																						<br />
																						<strong>Preview:</strong> {tag.preview}
																					</p>
																				}>
																				<span>
																					<EvaIcon type="info-outline" fill="#249286" height="18" width="18" />
																				</span>
																			</Tippy>
																		</div>
																	))}
																</div>
															</CollapsibleContent>
														))}
													</td>
												)}
											</tr>
										);
									})}
								</CmsTypesContext.Provider>
							</tbody>
						</table>
						{canEdit && (
							<>
								<br />
								<br />
								<span className="italic">{uctrans("client_consents.leads_settings.save_warning")}</span>
								<SubmitBar item="client_consents.leads_settings.plural" />
							</>
						)}
					</div>
				</AjaxForm>
			</div>
		</div>
	);
}
