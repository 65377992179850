import { useState } from "react";
import { route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import QuestionnaireCompletionStatistics from "./QuestionnaireCompletionStatistics";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import QuestionnaireTabs from "./QuestionnaireTabs";

export default function QuestionnaireCompletionStatisticsPage() {
	const [statisticsData] = useState({
		questionnaire: window.data.questionnaire,
		pie_chart_config: window.data.pie_chart_config,
		column_chart_config: window.data.column_chart_config,
		questionnaire_completions: window.data.questionnaire_completions,
		questionnaire_completion_statistics_data: window.data.questionnaire_completion_statistics_data,
		question_types: window.data.question_types,
		formValues: window.data.formValues ?? [],
		formInfo: window.data.formInfo ?? [],
	});

	return (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{ label: uctrans("questionnaires.plural"), route: route("crm.questionnaires.index") },
					{
						label: statisticsData.questionnaire.name,
					},
				]}
			/>
			<QuestionnaireTabs questionnaireId={statisticsData.questionnaire.id} initialActiveTabIndex={4} />

			<div className="float-right">
				<a
					className="button button-primary mb-4"
					href={route("crm.questionnaire_completions.statistics_pdf", {
						questionnaire: statisticsData.questionnaire.id,
					})}
					target="_blank"
					rel="noopener noreferrer">
					<EvaIcon type="download-outline" height="16" width="25" fill="#25282e" />
					{uctrans("questionnaires.export_to_pdf")}
				</a>
			</div>

			<QuestionnaireCompletionStatistics statisticsData={statisticsData} />
		</>
	);
}
