import { useState } from "react";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import { route, can } from "../../../../../js/helpers";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DeleteColumnContent from "../../../../../js/react/components/general/overview/DeleteColumnContent";
import Translate from "../../../../../js/react/components/general/Translate";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import IconButton from "../../../../../js/react/components/general/IconButton";

export default function DepartmentOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("general.general_settings") }]} />

			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="departments.plural" />
				</h1>
				{can("department", "create") && (
					<div>
						<IconButton
							href={route("crm.departments.create")}
							content={uctrans("general.add_:item", {}, { item: "departments.singular" })}
							primary
						/>
					</div>
				)}
			</div>
			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.departments.index")}
				deleteRouteName="crm.departments.delete"
				modelTranslations={{ plural: trans("departments.plural"), singular: trans("departments.singular") }}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={10}
							sortable="name"
							renderHeaderContent={() => uctrans("departments.fields.name")}
							renderCellContent={department => (
								<a href={route("crm.departments.edit", department.id)} className="row-title link">
									{department.name}
								</a>
							)}
						/>
						<DataColumn
							width={3}
							sortable="is_abn"
							renderHeaderContent={() => uctrans("departments.fields.is_abn")}
							renderCellContent={department => (department.is_abn ? uctrans("general.yes") : uctrans("general.no"))}
						/>
						<DataColumn
							width={3}
							sortable="is_imd"
							renderHeaderContent={() => uctrans("departments.fields.is_imd")}
							renderCellContent={department => (department.is_imd ? uctrans("general.yes") : uctrans("general.no"))}
						/>
						<DataColumn
							width={3}
							sortable="is_aahg"
							renderHeaderContent={() => uctrans("departments.fields.is_aahg")}
							renderCellContent={department => (department.is_aahg ? uctrans("general.yes") : uctrans("general.no"))}
						/>
						{can("department", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(department, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(department)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
