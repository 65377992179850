import { useState } from "react";
import PropTypes from "prop-types";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import { can, route } from "../../../../../../js/helpers";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import Translate from "../../../../../../js/react/components/general/Translate";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import Dialog from "../../../../../../js/react/components/general/Dialog";
import FixedInterestPeriodForm from "./FixedInterestPeriodForm";

export default function FixedInterestPeriodOverview({ interestType }) {
	const [currentFixedInterestPeriod, setCurrentFixedInterestPeriod] = useState(null);
	const [overviewDataUpdatedAt, setOverviewDataUpdatedAt] = useState(new Date().getTime());

	const addFixedInterestPeriod = () => {
		setCurrentFixedInterestPeriod({
			id: -1,
			name: "",
			description: "",
			interest_type_id: interestType.id,
		});
	};

	const handleSuccess = () => {
		setOverviewDataUpdatedAt(new Date().getTime());
		setCurrentFixedInterestPeriod(null);
	};

	return (
		<>
			<br />
			<hr />
			<br />
			<h3>
				<Translate content="interest.fixed_interest_periods.plural" />
			</h3>

			<AbnOverview
				indexUrl={route("crm.interest.fixed-interest-periods.index", interestType.id)}
				deleteRouteName="crm.interest.fixed-interest-periods.delete"
				moveRouteName="crm.interest.fixed-interest-periods.move"
				useDragAndDrop
				modelTranslations={{
					plural: trans("interest.fixed_interest_periods.plural"),
					singular: trans("interest.fixed_interest_periods.singular"),
				}}
				dataUpdatedAt={overviewDataUpdatedAt}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={1}
							className="draggable-overview-item-handle"
							renderHeaderContent={() => ""}
							renderCellContent={() => ""}
						/>
						<DataColumn
							width={10}
							sortable="name"
							renderHeaderContent={() => uctrans("interest.fields.name")}
							renderCellContent={fixedInterestPeriod => (
								<a onClick={() => setCurrentFixedInterestPeriod(fixedInterestPeriod)} className="row-title link">
									{fixedInterestPeriod.name}
									{fixedInterestPeriod.archived === 1 && (
										<>
											{" "}
											(<Translate content="interest.fields.archived" />)
										</>
									)}
								</a>
							)}
						/>
						{can("interest.fixed_interest_period", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(fixedInterestPeriod, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(fixedInterestPeriod)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
			<IconButton
				onClick={addFixedInterestPeriod}
				content={uctrans("general.add_:item", {}, { item: "interest.fixed_interest_periods.singular" })}
				primary
			/>
			{currentFixedInterestPeriod !== null && (
				<Dialog
					isOpen={currentFixedInterestPeriod !== null}
					title={
						currentFixedInterestPeriod.id === -1
							? uctrans("general.add_:item", {}, { item: "interest.fixed_interest_periods.singular" })
							: uctrans("general.edit_:item", {}, { item: "interest.fixed_interest_periods.singular" })
					}
					isFullScreen
					onClose={() => setCurrentFixedInterestPeriod(null)}>
					<FixedInterestPeriodForm
						fixedInterestPeriod={currentFixedInterestPeriod}
						onSuccess={() => handleSuccess()}
						onCancel={() => setCurrentFixedInterestPeriod(null)}
					/>
				</Dialog>
			)}
		</>
	);
}

FixedInterestPeriodOverview.propTypes = {
	interestType: PropTypes.object.isRequired,
};
