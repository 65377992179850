import PropTypes from "prop-types";
import { route, can } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";

export default function Navigation(props) {
	const tabs = [];
	if (can("teams.team", "view")) {
		tabs.push({
			key: "teams",
			label: uctrans("teams.plural"),
			route: route("crm.teams.index"),
		});
	}
	if (can("teams.process_time_activity", "view")) {
		tabs.push({
			key: "process-time-activities",
			label: uctrans("teams.process_time_activities.plural"),
			route: route("crm.process-time-activities.index"),
		});
	}
	if (can("teams.team_group", "view")) {
		tabs.push({
			key: "team-groups",
			label: uctrans("teams.team_groups.plural"),
			route: route("crm.team-groups.index"),
		});
	}
	if (can("user", "aahg_view")) {
		tabs.push({
			key: "aahg-users",
			label: uctrans("users.aahg_users.plural"),
			route: route("crm.aahg-users.index"),
		});
	}
	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("teams.team_settings") }]} />
			<TabLinks tabs={tabs} activeTab={props.selectedTab} />
		</>
	);
}
Navigation.propTypes = {
	selectedTab: PropTypes.string.isRequired,
};
