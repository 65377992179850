import upperFirst from "lodash/upperFirst";
import findIndex from "lodash/findIndex";
import PropTypes from "prop-types";
import { useState } from "react";
import { format, parse } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import AutosizeTextarea from "../../../../../js/react/components/general/form/AutosizeTextarea";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import Select from "../../../../../js/react/components/general/Select";
import Translate from "../../../../../js/react/components/general/Translate";

export default function AgendaItemQuickAdd(props) {
	const [addIndex, setAddIndex] = useState(-1);
	const [dossierCheckActions] = useState(props.dossierCheckActions || []);
	const [cooperationTerminationReasons] = useState(props.cooperationTerminationReasons || []);
	const [users] = useState(props.users || []);

	const getNewAgendaItem = () => {
		let agendaDate = new Date();
		if (props.agendaItemType === props.agendaItemTypes.FOLLOWUP_ACTION.key) {
			// No service level for followup actions, for now just add one day
			agendaDate.setDate(agendaDate.getDate() + 1);
		}
		agendaDate = format(agendaDate);
		return {
			id: addIndex,
			type: props.agendaItemType,
			agenda_date: agendaDate,
			date: agendaDate,
			in_pdf: false,
			status: false,
			description: "",
		};
	};

	const removeAgendaItem = index => {
		const newAgendaItems = [...props.agendaItems];
		newAgendaItems.splice(index, 1);
		props.onAgendaItemChange(newAgendaItems);
	};

	const addNewAgendaItem = () => {
		const newAgendaItems = [...props.agendaItems];
		newAgendaItems.push(getNewAgendaItem());
		setAddIndex(addIndex - 1);
		props.onAgendaItemChange(newAgendaItems);
	};

	const handleAgendaItemChange = (key, agendaItemId, value) => {
		const newAgendaItems = [...props.agendaItems];
		const agendaItemToBeModifiedIndex = findIndex(newAgendaItems, ["id", agendaItemId]);
		if (agendaItemToBeModifiedIndex !== -1) {
			newAgendaItems[agendaItemToBeModifiedIndex][key] = value;
		}
		props.onAgendaItemChange(newAgendaItems);
	};

	const dossierCheckActionOptions = dossierCheckActions.map(action => ({
		value: action.id,
		label: action.name,
	}));
	const cooperationTerminationReasonOptions = cooperationTerminationReasons.map(action => ({
		value: action.id,
		label: action.name,
	}));

	return (
		<div className="mb-8">
			<ul>
				<li className="border-b border-primary cursor-pointer clear-right">
					<h4 className="inline-block">{upperFirst(props.agendaItemTypePlural)}</h4>
					{(props.agendaItemType !== props.agendaItemTypes.COOPERATION_TERMINATION.key ||
						props.agendaItems.length === 0) && (
						<a className="button button-secondary float-right quick-add-button" onClick={addNewAgendaItem}>
							{uctrans("general.add_:item", { item: props.agendaItemTypeSingular })}
						</a>
					)}
				</li>
			</ul>

			<div className="mt-3">
				{props.agendaItems && props.agendaItems.length === 0 && (
					<span>{uctrans("general.no_:items_added_yet", { items: props.agendaItemTypePlural })}</span>
				)}
				{props.agendaItems.map((agendaItem, agendaItemIndex) => {
					let selectedDossierCheckAction = null;
					if (
						props.agendaItemType === props.agendaItemTypes.DOSSIER_CHECK.key &&
						Number(agendaItem.dossier_action_id) !== Number(0)
					) {
						selectedDossierCheckAction = dossierCheckActions.find(
							action => Number(action.id) === Number(agendaItem.dossier_action_id),
						);
					}
					return (
						<div key={agendaItem.id} className="form-container">
							<div className="form-full">
								<label>
									<Translate content="agendaitems.fields.agenda_date" />
									<span className="text-orange"> *</span>
								</label>
								<DatePicker
									initialValue={agendaItem.agenda_date ? parse(agendaItem.agenda_date) : null}
									onChange={date => handleAgendaItemChange("agenda_date", agendaItem.id, date ? format(date) : null)}
								/>
							</div>
							{props.agendaItemType === props.agendaItemTypes.PROVISION_CORRECTION.key && (
								<div className="form-full">
									<label>
										<Translate content="provisioncorrections.fields.loan_number" />
									</label>
									<input
										value={agendaItem.loan_number}
										type="number"
										onChange={event => handleAgendaItemChange("loan_number", agendaItem.id, event.target.value)}
									/>
								</div>
							)}
							<div className="form-full">
								<label>
									<Translate content="agendaitems.fields.user" />
									<span className="text-orange"> *</span>
								</label>
								<Select
									isClearable={false}
									placeholder={uctrans("general.select_:item", {}, { item: "contactmoments.fields.user" })}
									value={users.filter(({ value }) => Number(value) === Number(agendaItem.user_id))}
									options={users}
									onChange={selectedValue => handleAgendaItemChange("user_id", agendaItem.id, selectedValue.value)}
								/>
							</div>
							{props.agendaItemType === props.agendaItemTypes.COOPERATION_TERMINATION.key ? (
								<div className="form-full">
									<label>
										<Translate content="cooperation_terminations.fields.reason" />
										<span className="text-orange"> *</span>
									</label>
									<Select
										isClearable={false}
										value={cooperationTerminationReasonOptions.filter(
											({ value }) => Number(value) === Number(agendaItem.reason_id),
										)}
										options={cooperationTerminationReasonOptions}
										name="reason_id"
										onChange={selectedValue =>
											handleAgendaItemChange("reason_id", agendaItem.id, selectedValue ? selectedValue.value : null)
										}
										placeholder={uctrans(
											"general.select_:item",
											{},
											{ item: "cooperation_terminations.reasons.singular" },
										)}
									/>
								</div>
							) : null}
							<div className="form-full">
								<label>
									<Translate content="followupactions.fields.description" />
								</label>
								<AutosizeTextarea
									name="description"
									value={agendaItem.description}
									onChange={event => handleAgendaItemChange("description", agendaItem.id, event.target.value)}
								/>
							</div>
							{props.agendaItemType === props.agendaItemTypes.FOLLOWUP_ACTION.key && (
								<div className="form-full">
									<label>
										<Translate content="followupactions.fields.in_pdf" />
									</label>
									<RadioGroup
										name={`in_pdf_${agendaItem.id}`}
										value={agendaItem.in_pdf}
										onChange={value => handleAgendaItemChange("in_pdf", agendaItem.id, value)}
										horizontal>
										<Radio label={uctrans("general.no")} value={false} />
										<Radio label={uctrans("general.yes")} value />
									</RadioGroup>
								</div>
							)}
							{props.agendaItemType !== props.agendaItemTypes.COOPERATION_TERMINATION.key ? (
								<div className="form-full">
									<label>
										<Translate content="followupactions.fields.status" />
									</label>
									<RadioGroup
										name={`status_${props.agendaItemType}_${agendaItem.id}`}
										value={agendaItem.status}
										onChange={value => handleAgendaItemChange("status", agendaItem.id, value)}
										horizontal>
										<Radio label={uctrans("general.no")} value={false} />
										<Radio label={uctrans("general.yes")} value />
									</RadioGroup>
								</div>
							) : null}
							{agendaItem.status && props.agendaItemType === props.agendaItemTypes.FOLLOWUP_ACTION.key && (
								<div className="form-full">
									<label>
										<Translate content="followupactions.fields.explanation" />
									</label>
									<textarea
										value={agendaItem.explanation}
										onChange={event => handleAgendaItemChange("explanation", agendaItem.id, event.target.value)}
									/>
								</div>
							)}
							{props.agendaItemType === props.agendaItemTypes.DOSSIER_CHECK.key &&
								agendaItem.status &&
								Number(agendaItem.status) === Number(1) && (
									<>
										<div className="form-full">
											<Select
												isClearable
												value={dossierCheckActionOptions.filter(
													({ value }) => Number(value) === Number(agendaItem.dossier_action_id),
												)}
												options={dossierCheckActionOptions}
												name="dossier_action_id"
												onChange={selectedValue =>
													handleAgendaItemChange(
														"dossier_action_id",
														agendaItem.id,
														selectedValue ? selectedValue.value : null,
													)
												}
												placeholder={uctrans("general.select_:item", {}, { item: "dossiers.check_actions.singular" })}
											/>
										</div>

										{selectedDossierCheckAction && selectedDossierCheckAction.is_open && (
											<div className="form-full">
												<label htmlFor="description">
													<Translate content="dossiers.fields.description" />
												</label>
												<textarea
													id="dossier_action_value"
													name="dossier_action_value"
													value={agendaItem.dossier_action_value}
													onChange={e => {
														handleAgendaItemChange("dossier_action_value", agendaItem.id, e.target.value);
													}}
													placeholder={uctrans("dossiers.fields.description_placeholder")}
													autoComplete="off"
												/>
											</div>
										)}
									</>
								)}

							<div className="form-full">
								<span className="float-right" onClick={() => removeAgendaItem(agendaItemIndex)}>
									<label>
										<span className="mr-2">
											{uctrans("general.delete_:item", {}, { item: props.agendaItemTypeSingular })}
										</span>
										<span>
											<EvaIcon type="trash-2-outline" height="18" width="18" fill="#009286" />
										</span>
									</label>
								</span>
							</div>
							<div className="form-full">
								<hr style={{ width: "100%" }} />
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

AgendaItemQuickAdd.propTypes = {
	agendaItems: PropTypes.array,
	agendaItemType: PropTypes.number,
	agendaItemTypes: PropTypes.object,
	agendaItemTypeSingular: PropTypes.string,
	agendaItemTypePlural: PropTypes.string,
	dossierCheckActions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	cooperationTerminationReasons: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	users: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	onAgendaItemChange: PropTypes.func,
};
