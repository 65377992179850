import PropTypes from "prop-types";
import { useContext } from "react";
import MediaLibrary from "../../../media/library/MediaLibrary";
import MediaSelector from "../../../media/selector/MediaSelector";
import { CmsTypesContext } from "../../cms-types-context";

export default function Image(props) {
	const { imageOptions } = useContext(CmsTypesContext);

	const onchange = selectedImageOption => {
		if (selectedImageOption) {
			const existingOption = imageOptions.find(({ id }) => id === props.value);
			if (!existingOption) {
				imageOptions.push(selectedImageOption);
			}
		}
		props.onChange(selectedImageOption ? selectedImageOption.id : null);
	};

	const selectedImageOption = imageOptions.find(({ id }) => id === props.value);
	return (
		<>
			{props.label && <label>{props.label}</label>}
			<MediaSelector selected={selectedImageOption} type={MediaLibrary.TYPE_IMAGE} onSelect={onchange} />
		</>
	);
}

Image.propTypes = {
	label: PropTypes.string,
	value: PropTypes.number,
	onChange: PropTypes.func,
};
