import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";

export default function WarningItem({ warning, onChange }) {
	const [checked, setChecked] = useState(false);

	useEffect(
		() => onChange(warning.key, checked),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[checked],
	);

	if (warning.requires_approval) {
		return (
			<div className="form-full">
				<Checkmark
					required
					label={warning.text}
					name={warning.key}
					checked={checked}
					onChange={e => setChecked(e.target.checked)}
				/>
			</div>
		);
	}

	return <p className="font-bold">{warning.text}</p>;
}
WarningItem.propTypes = {
	onChange: PropTypes.func,
	warning: PropTypes.shape({
		text: PropTypes.string.isRequired,
		requires_approval: PropTypes.bool,
		key: PropTypes.string,
	}),
};
