import PropTypes from "prop-types";
import { useMemo } from "react";
import { route } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";
import LeadIcon from "../../../../../js/react/components/clientConsents/LeadIcon";
import { getTagReplaceText } from "../../../../../js/react/components/clientConsents/utils";

export default function LeadDialogContent({ lead }) {
	const clientConsent = lead ? lead.consent : null;
	const leadSettings = window.data.lead_settings;

	const settings = useMemo(() => {
		let tooltipText = "";
		if (lead) {
			switch (lead.type) {
				case "sustainability":
					if (
						leadSettings.sustainability_energylabels &&
						leadSettings.sustainability_energylabels.includes(lead.energylabel.toLowerCase())
					) {
						tooltipText = leadSettings.sustainability_detail_text;
					} else if (
						leadSettings.sustainability_2_energylabels &&
						leadSettings.sustainability_2_energylabels.includes(lead.energylabel.toLowerCase())
					) {
						tooltipText = leadSettings.sustainability_2_detail_text;
					}

					return {
						in_use: !!leadSettings.use_sustainability,
						tooltip_text: tooltipText.replace("{energylabel}", getTagReplaceText(lead)),
					};
				case "seniors_overvalue":
					return {
						in_use: !!leadSettings.use_seniors_overvalue,
						tooltip_text: leadSettings.seniors_overvalue_detail_text,
						icon_idtoken: leadSettings.seniors_overvalue_icon.id_token,
					};
				case "moving_renovating":
					return {
						in_use: !!leadSettings.use_moving_renovating,
						tooltip_text: leadSettings.moving_renovating_detail_text,
						icon_idtoken: leadSettings.moving_renovating_icon.id_token,
					};
				case "interest_renewal":
					return {
						in_use: !!leadSettings.use_interest_renewal,
						tooltip_text: leadSettings.interest_renewal_detail_text,
						icon_idtoken: leadSettings.interest_renewal_icon.id_token,
					};
				default:
					return false;
			}
		}
		return null;
	}, [leadSettings, lead]);

	return clientConsent ? (
		<div className="text-xs">
			<div className="w-full flex p-4 items-start">
				<div className="w-1/4">
					<LeadIcon
						status={lead.status}
						type={lead.type}
						energylabel={lead.energylabel}
						energylabelFinal={lead.energylabel_final}
						width={100}
						iconUrl={
							settings.icon_idtoken ? route("crm.files.public-download", { idtoken: settings.icon_idtoken }) : null
						}
					/>
				</div>
				<div className="w-3/4">
					<strong>{format(lead.created_at, "dd-MM-y")}</strong>

					<div
						className="content-block html-content"
						dangerouslySetInnerHTML={{
							__html: settings.tooltip_text,
						}}
					/>

					{lead.status === "handled_positive" && (
						<strong className="text-green">{uctrans("client_consents.leads.handled_with_success")}</strong>
					)}
					{lead.status === "handled_negative" && (
						<strong style={{ color: "#ff6623" }}>{uctrans("client_consents.leads.handled_without_success")}</strong>
					)}

					{lead.status !== "open" && (
						<div className="w-full flex justify-between">
							<p className="italic whitespace-pre">
								{lead.comments ? lead.comments : uctrans("client_consents.leads.no_comments")}
							</p>
						</div>
					)}
				</div>
			</div>
		</div>
	) : null;
}

LeadDialogContent.propTypes = {
	lead: PropTypes.shape({
		id: PropTypes.number,
		type: PropTypes.string,
		energylabel: PropTypes.string,
		energylabel_final: PropTypes.bool,
		status: PropTypes.string,
		comments: PropTypes.string,
		active: PropTypes.bool,
		created_at: PropTypes.string,
		updated_at: PropTypes.string,
		expired_at: PropTypes.string,
		deactivated_at: PropTypes.string,
		consent: {
			loan_number: PropTypes.number,
			energylabel: PropTypes.string,
			name_first_applicant: PropTypes.string,
			name_second_applicant: PropTypes.string,
			active: PropTypes.bool,
			leads: PropTypes.array,
		},
	}),
};
