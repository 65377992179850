import Tippy from "@tippyjs/react";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../js/lib/Translator";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import { can, route } from "../../../../../js/helpers";

import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";

export default function ApiUserOverview() {
	const overviewData = { ...window.data.overview };

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("general.security_settings") }]} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("api_users.plural")}</h1>
				{can("communication.newsletter", "create") && (
					<div>
						<IconButton
							href={route("crm.api-users.create")}
							content={uctrans("general.add_:item", {}, { item: "api_users.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData}
				indexUrl={route("crm.api-users.index")}
				deleteRouteName="crm.api-users.delete"
				modelTranslations={{
					plural: trans("api_users.plural"),
					singular: trans("api_users.singular"),
				}}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={1}
							renderHeaderContent={() => uctrans("api_users.fields.id")}
							renderCellContent={apiUser => {
								if (can("api.api_user", "update")) {
									return (
										<a href={route("crm.api-users.edit", apiUser.id)} className="row-title link">
											{apiUser.id}
										</a>
									);
								} else {
									return apiUser.id;
								}
							}}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("api_users.fields.username")}
							renderCellContent={apiUser => apiUser.username}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("api_users.fields.permissiongroup")}
							renderCellContent={apiUser => apiUser.permissiongroup}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("api_users.fields.email")}
							renderCellContent={apiUser => apiUser.email}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("api_users.fields.consent_label")}
							renderCellContent={apiUser => (apiUser.consent_label ? apiUser.consent_label : trans("general.n/a"))}
						/>
						{can("api.api_user", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(apiUser, overview) => (
									<div className="overview-actions">
										<a
											onClick={() => {
												overview.askDelete(apiUser);
											}}>
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("general.delete")}>
												<span>
													<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
												</span>
											</Tippy>
										</a>
									</div>
								)}
							/>
						)}
					</DataTable>
				)}
				renderFooter={() =>
					can("api.api_log", "view") && (
						<div className="mt-4">
							<a className="button button-primary " href={route("crm.api-logs.export")}>
								{uctrans("api.export_logs")}
							</a>
						</div>
					)
				}
			/>
		</>
	);
}
