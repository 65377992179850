import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import HighChart from "../../report/graph/HighChart";

export default function RmDashboardHorizontalBarchart(props) {
	const [series, setSeries] = useState([]);
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		if (props.walletData.length && props.totalData.length) {
			const walletMainIds = [];
			let walletOtherValue = 0;
			let walletTotalValue = 0;
			let i = 0;

			[...props.walletData]
				.sort((a, b) => (Number(a.value) < Number(b.value) ? 1 : -1))
				.forEach(({ id, value }) => {
					walletTotalValue += Number(value);
					if (props.walletData.length > props.maxItems && i++ >= props.maxItems - 1) {
						walletOtherValue += Number(value);
					} else {
						walletMainIds.push(id);
					}
				});

			let totalOtherValue = 0;
			let totalTotalValue = 0;
			props.totalData.forEach(({ id, value }) => {
				totalTotalValue += Number(value);
				if (!walletMainIds.includes(id)) {
					totalOtherValue += Number(value);
				}
			});

			const newWalletSeriesData = [];
			const newTotalSeriesData = [];
			const newCategories = [];
			props.walletData.forEach(({ id, value, label }) => {
				if (walletMainIds.includes(id)) {
					newCategories.push(label);
					const totalItem = props.totalData.filter(totalItem => id === totalItem.id);
					newWalletSeriesData.push({
						y: props.calculatePercentages ? (Number(value) / walletTotalValue) * 100 : Number(value),
						val: Number(value),
					});
					newTotalSeriesData.push(
						totalItem.length
							? {
									y: props.calculatePercentages
										? (Number(totalItem[0].value) / totalTotalValue) * 100
										: Number(totalItem[0].value),
									val: Number(totalItem[0].value),
							  }
							: { y: 0, val: 0 },
					);
				}
			});

			if (totalOtherValue > 0 || walletOtherValue > 0) {
				newCategories.push(uctrans("dashboard.other"));
				newWalletSeriesData.push({
					y: props.calculatePercentages ? (walletOtherValue / walletTotalValue) * 100 : walletOtherValue,
					val: walletOtherValue,
				});
				newTotalSeriesData.push({
					y: props.calculatePercentages ? (totalOtherValue / totalTotalValue) * 100 : totalOtherValue,
					val: totalOtherValue,
				});
			}

			const newSeriesData = [{ name: props.walletLabel, data: newWalletSeriesData }];
			if (props.walletType !== "total") {
				newSeriesData.push({ name: uctrans("dashboard.nationwide"), data: newTotalSeriesData });
			}
			setSeries(newSeriesData);
			setCategories(newCategories);
		} else {
			setSeries([]);
			setCategories([]);
		}
	}, [
		props.walletData,
		props.totalData,
		props.walletLabel,
		props.walletType,
		props.maxItems,
		props.calculatePercentages,
	]);

	return (
		<div>
			{props.title && (
				<h6>
					{props.link ? (
						<a href={props.link}>
							{props.title}
							<span className="inline-block ml-1 align-middle">
								<EvaIcon type="arrow-circle-right-outline" fill="#009286" height="18" width="18" />
							</span>
						</a>
					) : (
						props.title
					)}
				</h6>
			)}

			<HighChart
				chartConfig={{
					chart: {
						type: "bar",
						backgroundColor: null,
						height: `${props.height}px`,
						spacingLeft: 0,
						marginTop: 30,
					},
					title: {
						text: null,
					},
					xAxis: {
						categories,
						title: {
							text: null,
						},
						gridLineWidth: 0,
						labels: {
							align: "left",
							reserveSpace: true,
							enabled: true,
							style: {
								color: "#25282E",
								fontSize: "12px",
							},
						},
						minPadding: 0,
						maxPadding: 0,
					},
					yAxis: {
						title: {
							text: null,
						},
						gridLineWidth: 0,
						labels: {
							enabled: false,
						},
					},
					legend: {
						enabled: series.length > 1,
						align: "center",
						verticalAlign: "top",
						floating: true,
						itemStyle: {
							fontSize: "10px",
							fontWeight: "normal",
							color: "#31353e",
						},
					},
					series,
					plotOptions: {
						series: {
							pointWidth: 10,
							groupPadding: 0.2,
						},
						bar: {
							groupPadding: 0.1,
							dataLabels: {
								enabled: true,
								format: `{point.y:.0f}${props.addPecentageSignAfterDataLabel ? "%" : ""}`,
								color: "#31353e",
								style: { textOutline: "none" },
								overflow: "allow",
								crop: false,
								allowOverlap: true,
							},
						},
					},
					tooltip: {
						pointFormat: props.tooltipPointformat,
						enabled: props.calculatePercentages,
					},
					colors: ["#2B928C", "#C1E0DE"],
				}}
			/>
		</div>
	);
}

RmDashboardHorizontalBarchart.propTypes = {
	title: PropTypes.string,
	link: PropTypes.string,
	walletType: PropTypes.string,
	walletLabel: PropTypes.string,
	walletData: PropTypes.array,
	totalData: PropTypes.array,
	maxItems: PropTypes.number,
	height: PropTypes.number,
	calculatePercentages: PropTypes.bool,
	addPecentageSignAfterDataLabel: PropTypes.bool,
	tooltipPointformat: PropTypes.string,
};

RmDashboardHorizontalBarchart.defaultProps = {
	maxItems: 5,
	calculatePercentages: false,
	addPecentageSignAfterDataLabel: false,
	orderByValues: true,
	height: 200,
	tooltipPointformat: "€ {point.val:,.0f}",
};
