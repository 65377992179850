import axios from "axios";
import PropTypes from "prop-types";
import intersection from "lodash/intersection";
import { useState, useEffect } from "react";
import { route } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import CheckmarkGroup from "../../../../../../js/react/components/general/form/checkmark/CheckmarkGroup";
import DebouncedTextarea from "../../../../../../js/react/components/general/form/DebouncedTextarea";
import Radio from "../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../js/react/components/general/form/radio/RadioGroup";
import Translate from "../../../../../../js/react/components/general/Translate";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import FileInput from "../../forms/FileInput";

export default function AddNewsletterRecipientsForm({ newsletter, mailing, onSuccess, onCancel }) {
	const [filterData, setFilterData] = useState({
		filter_type: "none",
		intermediary_company_recipient_types: ["companies"],
		organisation_company_recipient_types: ["companies"],
	});
	const staticData = { ...window.data.newsletter_mailing.static_data.add_recipient_data };
	const [counts, setCounts] = useState({ total: 0 });
	const [extraFilters, setExtraFilters] = useState({});
	const maxCount = 1000;

	const handleSuccess = () => {
		onSuccess();
	};

	const handleCancel = () => {
		onCancel();
	};

	const reloadFilterResults = async filterData => {
		try {
			const response = await axios.post(
				route("crm.communication.newsletter-mailing-recipients.filter-results", {
					newsletter: newsletter.id,
					newsletter_mailing: mailing.id,
				}),
				{
					filterData,
				},
			);
			setCounts(response.data.counts);
			setExtraFilters(response.data.extra_filters);
		} catch (error) {
			console.error(error);
			setCounts([]);
		}
	};

	const handleFilterChange = (key, value) => {
		const newfilterData = { ...filterData };
		newfilterData[key] = value;
		setFilterData(newfilterData);
		reloadFilterResults(newfilterData);
	};

	const isGroupSelected = group =>
		filterData.filter_type === "choose_recipients" &&
		Array.isArray(filterData.filter_groups) &&
		filterData.filter_groups.indexOf(group) !== -1;

	useEffect(() => {
		if (newsletter && newsletter.for_client_treatment && !newsletter.client_treatment_self_enrollment) {
			handleFilterChange("use_client_treatment_participant_status_filter", 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onAfterUpload = (results, key) => {
		if (results && results.length > 0) {
			handleFilterChange(key, results[0].upload_data);
		}
	};

	return (
		<AjaxForm
			method="POST"
			submitUrl={route("crm.communication.newsletter-mailing-recipients.add-recipients", {
				newsletter: newsletter.id,
				newsletter_mailing: mailing.id,
			})}
			loaderText={uctrans("communication.newsletter_recipients.adding_recipients")}
			successText={uctrans("communication.newsletter_recipients.added_recipients")}
			onSuccess={handleSuccess}
			data={{ filterData: { ...filterData } }}>
			{newsletter.for_client_treatment && !newsletter.client_treatment_self_enrollment ? (
				<div className="bg-primary-lightest items-center px-3 py-3 my-2 inline-block w-full">
					<h4>{uctrans("client_treatment.singular")}</h4>
					<div className="form-full">
						<label htmlFor="use_client_treatment_participant_status_filter">
							{uctrans("communication.recipient_filter.filter_on_client_treatment_filled_status")}
						</label>
						<RadioGroup
							name="use_client_treatment_participant_status_filter"
							horizontal
							value={filterData.use_client_treatment_participant_status_filter ? 1 : 0}
							onChange={value => handleFilterChange("use_client_treatment_participant_status_filter", value)}>
							<Radio label={uctrans("general.yes")} value={1} />
							<Radio label={uctrans("general.no")} value={0} />
						</RadioGroup>
					</div>
					{!!filterData.use_client_treatment_participant_status_filter && (
						<div className="form-full">
							<div className="inline-block">
								<CheckmarkGroup
									name="participant_statuses"
									initialChecked={filterData.participant_statuses}
									onChange={roles => handleFilterChange("participant_statuses", roles)}>
									{staticData.client_treatment_status_types.map(status => (
										<Checkmark
											key={status.value}
											value={status.value}
											name={status.value}
											label={status.label}
											className="w-1/2 float-left block"
										/>
									))}
								</CheckmarkGroup>
							</div>
							<div className="flex justify-end text-xs">
								<a
									className="link"
									onClick={() =>
										handleFilterChange(
											"participant_statuses",
											staticData.client_treatment_status_types.map(status => status.value),
										)
									}>
									{uctrans("communication.recipient_filter.select_all")}
								</a>
								<span className="ml-1 mr-1">|</span>
								<a className="link" onClick={() => handleFilterChange("participant_statuses", [])}>
									{uctrans("communication.recipient_filter.deselect_all")}
								</a>
							</div>
						</div>
					)}
					<div className="form-full">
						<label htmlFor="use_client_treatment_participant_evaluation_status_filter">
							{uctrans("communication.recipient_filter.filter_client_treatment_participant_evaluation_status")}
						</label>
						<RadioGroup
							name="use_client_treatment_participant_evaluation_status_filter"
							horizontal
							value={filterData.use_client_treatment_participant_evaluation_status_filter ? 1 : 0}
							onChange={value =>
								handleFilterChange("use_client_treatment_participant_evaluation_status_filter", value)
							}>
							<Radio label={uctrans("general.yes")} value={1} />
							<Radio label={uctrans("general.no")} value={0} />
						</RadioGroup>
					</div>
					{!!filterData.use_client_treatment_participant_evaluation_status_filter && (
						<div className="form-full">
							<div className="inline-block">
								<CheckmarkGroup
									name="survey_statuses"
									initialChecked={filterData.survey_statuses}
									onChange={roles => handleFilterChange("survey_statuses", roles)}>
									{staticData.questionnaire_completion_statuses.map(status => (
										<Checkmark
											key={status.value}
											value={status.value}
											name={status.value}
											label={status.label}
											className="w-1/2 float-left block"
										/>
									))}
								</CheckmarkGroup>
							</div>
							<div className="flex justify-end text-xs">
								<a
									className="link"
									onClick={() =>
										handleFilterChange(
											"survey_statuses",
											staticData.questionnaire_completion_statuses.map(status => status.value),
										)
									}>
									{uctrans("communication.recipient_filter.select_all")}
								</a>
								<span className="ml-1 mr-1">|</span>
								<a className="link" onClick={() => handleFilterChange("survey_statuses", [])}>
									{uctrans("communication.recipient_filter.deselect_all")}
								</a>
							</div>
						</div>
					)}

					{counts.client_treatment_participants === 0 && (
						<span className="italic form-full">
							<Translate
								content="communication.recipient_filter.no_:items_found"
								transReplaces={{ items: "client_treatment.completion.plural" }}
							/>
						</span>
					)}
					{counts.client_treatment_participants > maxCount && (
						<span className="italic form-full">
							<Translate
								content="communication.recipient_filter.to_much_:items_:count_:max"
								transReplaces={{
									items: "client_treatment.completion.plural",
									items2: "client_treatment.completion.plural",
								}}
								replaces={{
									max: maxCount,
									client_treatment_participants: counts.client_treatment_participants,
								}}
							/>
						</span>
					)}
					{counts.client_treatment_participants > 0 && counts.client_treatment_participants < maxCount && (
						<>
							<div className="form-full border-t">
								<label htmlFor="use_user_filter">
									{uctrans(
										"communication.recipient_filter.which_:items?",
										{},
										{ items: "client_treatment.completion.plural" },
									)}
								</label>
								<RadioGroup
									name="use_client_treatment_participant_filter"
									horizontal
									value={filterData.use_client_treatment_participant_filter ? 1 : 0}
									onChange={value => handleFilterChange("use_client_treatment_participant_filter", value)}>
									<Radio
										label={uctrans("communication.recipient_filter.all_:cnt", {
											cnt: counts.client_treatment_participants,
										})}
										value={0}
									/>
									<Radio
										label={uctrans(
											"communication.recipient_filter.select_:items_:cnt",
											{
												cnt: `${
													filterData.client_treatment_participants
														? intersection(
																filterData.client_treatment_participants
																	? filterData.client_treatment_participants
																	: [],
																extraFilters.client_treatment_participants.map(participant => participant.value),
														  ).length
														: 0
												} / ${counts.client_treatment_participants}`,
											},
											{ items: "client_treatment.completion.plural" },
										)}
										value={1}
									/>
								</RadioGroup>
							</div>
							{!!filterData.use_client_treatment_participant_filter && (
								<div className="form-full">
									<div className="inline-block">
										<CheckmarkGroup
											name="client_treatment_participants"
											initialChecked={intersection(
												filterData.client_treatment_participants ? filterData.client_treatment_participants : [],
												extraFilters.client_treatment_participants.map(participant => participant.value),
											)}
											onChange={participants => handleFilterChange("client_treatment_participants", participants)}>
											{extraFilters.client_treatment_participants.map(user => (
												<Checkmark
													key={user.value}
													value={user.value}
													name={user.value}
													label={user.label}
													className="w-1/2 float-left block"
												/>
											))}
										</CheckmarkGroup>
									</div>

									<div className="flex justify-end text-xs">
										<a
											className="link"
											onClick={() =>
												handleFilterChange(
													"client_treatment_participants",
													extraFilters.client_treatment_participants.map(participant => participant.value),
												)
											}>
											{uctrans("communication.recipient_filter.select_all")}
										</a>
										<span className="ml-1 mr-1">|</span>
										<a className="link" onClick={() => handleFilterChange("client_treatment_participants", [])}>
											{uctrans("communication.recipient_filter.deselect_all")}
										</a>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			) : (
				<>
					<div className="form-container">
						<div className="form-full">
							<label htmlFor="use_intermediary_link">
								<Translate content="communication.recipient_filter.filter_type" />
							</label>
							<RadioGroup
								name="filter_type"
								value={filterData.filter_type}
								onChange={value => handleFilterChange("filter_type", value)}>
								{staticData.filter_types.map((filterType, index) => (
									<Radio value={filterType.value} key={index} inline>
										<span>{filterType.label}</span>
									</Radio>
								))}
							</RadioGroup>
						</div>
					</div>
					{filterData.filter_type === "choose_recipients" && (
						<>
							<div className="form-container">
								<hr />
								<div className="form-full">
									<label htmlFor="filter_groups">
										<Translate content="communication.recipient_filter.filter_group" />
									</label>
									<CheckmarkGroup
										name="filter_groups"
										initialChecked={filterData.filter_groups}
										onChange={filterGroups => handleFilterChange("filter_groups", filterGroups)}>
										{staticData.filter_groups.map(filterGroup => (
											<Checkmark
												key={filterGroup.value}
												value={filterGroup.value}
												name={filterGroup.value}
												label={filterGroup.label}
											/>
										))}
									</CheckmarkGroup>
								</div>
							</div>

							{isGroupSelected("intermediaries") && (
								<div className="bg-primary-lightest items-center px-3 py-3 my-2 inline-block w-full">
									<h4>{uctrans("intermediaries.plural")}</h4>
									<div className="form-full">
										<label htmlFor="use_intermediary_segment_filter">
											{uctrans("communication.recipient_filter.choose_:items?", {}, { items: "segments.plural" })}
										</label>
										<RadioGroup
											name="use_intermediary_segment_filter"
											horizontal
											value={filterData.use_intermediary_segment_filter ? 1 : 0}
											onChange={value => handleFilterChange("use_intermediary_segment_filter", value)}>
											<Radio label={uctrans("general.yes")} value={1} />
											<Radio label={uctrans("general.no")} value={0} />
										</RadioGroup>
									</div>
									{!!filterData.use_intermediary_segment_filter && (
										<div className="form-full">
											<div className="inline-block">
												<CheckmarkGroup
													name="intermediary_segments"
													initialChecked={filterData.intermediary_segments}
													onChange={segments => handleFilterChange("intermediary_segments", segments)}>
													{staticData.segments.map(segment => (
														<Checkmark
															key={segment.value}
															value={segment.value}
															name={segment.value}
															label={segment.label}
															className="w-1/2 float-left block"
														/>
													))}
												</CheckmarkGroup>
											</div>
											<div className="flex justify-end text-xs">
												<a
													className="link"
													onClick={() =>
														handleFilterChange(
															"intermediary_segments",
															staticData.segments.map(segment => segment.value),
														)
													}>
													{uctrans("communication.recipient_filter.select_all")}
												</a>
												<span className="ml-1 mr-1">|</span>
												<a className="link" onClick={() => handleFilterChange("intermediary_segments", [])}>
													{uctrans("communication.recipient_filter.deselect_all")}
												</a>
											</div>
										</div>
									)}
									<div className="form-full">
										<label htmlFor="use_intermediary_relationmanager_filter">
											{uctrans(
												"communication.recipient_filter.choose_:items?",
												{},
												{ items: "users.relationmanagers.plural" },
											)}
										</label>
										<RadioGroup
											name="use_intermediary_relationmanager_filter"
											horizontal
											value={filterData.use_intermediary_relationmanager_filter ? 1 : 0}
											onChange={value => handleFilterChange("use_intermediary_relationmanager_filter", value)}>
											<Radio label={uctrans("general.yes")} value={1} />
											<Radio label={uctrans("general.no")} value={0} />
										</RadioGroup>
									</div>
									{!!filterData.use_intermediary_relationmanager_filter && (
										<div className="form-full">
											<div className="inline-block">
												<CheckmarkGroup
													name="intermediary_relationmanagers"
													initialChecked={filterData.intermediary_relationmanagers}
													onChange={relationmanagers =>
														handleFilterChange("intermediary_relationmanagers", relationmanagers)
													}>
													{staticData.relationmanagers.map(relationmanager => (
														<Checkmark
															key={relationmanager.value}
															value={relationmanager.value}
															name={relationmanager.value}
															label={relationmanager.label}
															className="w-1/2 float-left block"
														/>
													))}
												</CheckmarkGroup>
											</div>
											<div className="flex justify-end text-xs">
												<a
													className="link"
													onClick={() =>
														handleFilterChange(
															"intermediary_relationmanagers",
															staticData.relationmanagers.map(relationmanager => relationmanager.value),
														)
													}>
													{uctrans("communication.recipient_filter.select_all")}
												</a>
												<span className="ml-1 mr-1">|</span>
												<a className="link" onClick={() => handleFilterChange("intermediary_relationmanagers", [])}>
													{uctrans("communication.recipient_filter.deselect_all")}
												</a>
											</div>
										</div>
									)}
									<div className="form-full">
										<label htmlFor="use_intermediary_organisation_filter">
											{uctrans("communication.recipient_filter.choose_:items?", {}, { items: "organisations.plural" })}
										</label>
										<RadioGroup
											name="use_intermediary_organisation_filter"
											horizontal
											value={filterData.use_intermediary_organisation_filter ? 1 : 0}
											onChange={value => handleFilterChange("use_intermediary_organisation_filter", value)}>
											<Radio label={uctrans("general.yes")} value={1} />
											<Radio label={uctrans("general.no")} value={0} />
										</RadioGroup>
									</div>
									{!!filterData.use_intermediary_organisation_filter && (
										<div className="form-full">
											<div className="inline-block">
												<CheckmarkGroup
													name="intermediary_organisations"
													initialChecked={filterData.intermediary_organisations}
													onChange={organisations => handleFilterChange("intermediary_organisations", organisations)}>
													{staticData.organisations.map(organisation => (
														<Checkmark
															key={organisation.value}
															value={organisation.value}
															name={organisation.value}
															label={organisation.label}
															className="w-1/2 float-left block"
														/>
													))}
												</CheckmarkGroup>
											</div>
											<div className="flex justify-end text-xs">
												<a
													className="link"
													onClick={() =>
														handleFilterChange(
															"intermediary_organisations",
															staticData.organisations.map(organisation => organisation.value),
														)
													}>
													{uctrans("communication.recipient_filter.select_all")}
												</a>
												<span className="ml-1 mr-1">|</span>
												<a className="link" onClick={() => handleFilterChange("intermediary_organisations", [])}>
													{uctrans("communication.recipient_filter.deselect_all")}
												</a>
											</div>
										</div>
									)}
									<div className="form-full">
										<label htmlFor="use_intermediary_team_filter">
											{uctrans("communication.recipient_filter.choose_:items?", {}, { items: "teams.plural" })}
										</label>
										<RadioGroup
											name="use_intermediary_team_filter"
											horizontal
											value={filterData.use_intermediary_team_filter ? 1 : 0}
											onChange={value => handleFilterChange("use_intermediary_team_filter", value)}>
											<Radio label={uctrans("general.yes")} value={1} />
											<Radio label={uctrans("general.no")} value={0} />
										</RadioGroup>
									</div>
									{!!filterData.use_intermediary_team_filter && (
										<div className="form-full">
											<div className="inline-block">
												<CheckmarkGroup
													name="intermediary_teams"
													initialChecked={filterData.intermediary_teams}
													onChange={teams => handleFilterChange("intermediary_teams", teams)}>
													{staticData.teams.map(team => (
														<Checkmark
															key={team.value}
															value={team.value}
															name={team.value}
															label={team.label}
															className="w-1/2 float-left block"
														/>
													))}
												</CheckmarkGroup>
											</div>

											<div className="flex justify-end text-xs">
												<a
													className="link"
													onClick={() =>
														handleFilterChange(
															"intermediary_teams",
															staticData.teams.map(team => team.value),
														)
													}>
													{uctrans("communication.recipient_filter.select_all")}
												</a>
												<span className="ml-1 mr-1">|</span>
												<a className="link" onClick={() => handleFilterChange("intermediary_teams", [])}>
													{uctrans("communication.recipient_filter.deselect_all")}
												</a>
											</div>
										</div>
									)}
									<div className="form-full">
										<label htmlFor="use_intermediary_software_package_filter">
											{uctrans(
												"communication.recipient_filter.choose_:items?",
												{},
												{ items: "software_packages.plural" },
											)}
										</label>
										<RadioGroup
											name="use_intermediary_software_package_filter"
											horizontal
											value={filterData.use_intermediary_software_package_filter ? 1 : 0}
											onChange={value => handleFilterChange("use_intermediary_software_package_filter", value)}>
											<Radio label={uctrans("general.yes")} value={1} />
											<Radio label={uctrans("general.no")} value={0} />
										</RadioGroup>
									</div>
									{!!filterData.use_intermediary_software_package_filter && (
										<div className="form-full">
											<div className="inline-block">
												<CheckmarkGroup
													name="intermediary_teams"
													initialChecked={filterData.intermediary_software_packages}
													onChange={software_packages =>
														handleFilterChange("intermediary_software_packages", software_packages)
													}>
													{staticData.software_packages.map(softwarePackage => (
														<Checkmark
															key={softwarePackage.value}
															value={softwarePackage.value}
															name={softwarePackage.value}
															label={softwarePackage.label}
															className="w-1/2 float-left block"
														/>
													))}
												</CheckmarkGroup>
											</div>

											<div className="flex justify-end text-xs">
												<a
													className="link"
													onClick={() =>
														handleFilterChange(
															"intermediary_software_packages",
															staticData.software_packages.map(softwarePackage => softwarePackage.value),
														)
													}>
													{uctrans("communication.recipient_filter.select_all")}
												</a>
												<span className="ml-1 mr-1">|</span>
												<a className="link" onClick={() => handleFilterChange("intermediary_software_packages", [])}>
													{uctrans("communication.recipient_filter.deselect_all")}
												</a>
											</div>
										</div>
									)}
									<div className="form-full">
										<label htmlFor="use_intermediary_provinces_filter">
											{uctrans(
												"communication.recipient_filter.choose_:items?",
												{},
												{ items: "location.provinces.plural" },
											)}
										</label>
										<RadioGroup
											name="use_intermediary_provinces_filter"
											horizontal
											value={filterData.use_intermediary_provinces_filter ? 1 : 0}
											onChange={value => handleFilterChange("use_intermediary_provinces_filter", value)}>
											<Radio label={uctrans("general.yes")} value={1} />
											<Radio label={uctrans("general.no")} value={0} />
										</RadioGroup>
									</div>
									{!!filterData.use_intermediary_provinces_filter && (
										<div className="form-full">
											<div className="inline-block">
												<CheckmarkGroup
													name="intermediary_provinces"
													initialChecked={filterData.intermediary_provinces}
													onChange={provinces => handleFilterChange("intermediary_provinces", provinces)}>
													{staticData.provinces.map(province => (
														<Checkmark
															key={province.value}
															value={province.value}
															name={province.value}
															label={province.label}
															className="w-1/2 float-left block"
														/>
													))}
												</CheckmarkGroup>
											</div>

											<div className="flex justify-end text-xs">
												<a
													className="link"
													onClick={() =>
														handleFilterChange(
															"intermediary_provinces",
															staticData.provinces.map(province => province.value),
														)
													}>
													{uctrans("communication.recipient_filter.select_all")}
												</a>
												<span className="ml-1 mr-1">|</span>
												<a className="link" onClick={() => handleFilterChange("intermediary_provinces", [])}>
													{uctrans("communication.recipient_filter.deselect_all")}
												</a>
											</div>
										</div>
									)}
									<div className="form-full">
										<label htmlFor="use_intermediary_id_import">
											{uctrans(
												"communication.recipient_filter.filter_on_:item?",
												{},
												{ item: "communication.newsletter_recipients.import_company_csv" },
											)}
										</label>
										<RadioGroup
											name="use_intermediary_id_import"
											horizontal
											value={filterData.intermediary_company_numbers_uploaded ? 1 : 0}
											onChange={value => {
												handleFilterChange("intermediary_company_numbers_uploaded", value ? [] : null);
											}}>
											<Radio label={uctrans("general.yes")} value={1} />
											<Radio label={uctrans("general.no")} value={0} />
										</RadioGroup>
										{filterData.intermediary_company_numbers_uploaded && (
											<div className="form-full">
												<div className="inline-block">
													<FileInput
														storeRoute="crm.communication.newsletter-mailing-recipients.upload-company-csv"
														newFileDownloadRoute={null} // No download route
														fileType="document"
														maxFiles={1}
														onChange={content => {
															if (content.length < 1) {
																handleFilterChange("intermediary_company_numbers_uploaded", []);
															}
														}}
														onAfterUpload={result => onAfterUpload(result, "intermediary_company_numbers_uploaded")}
														acceptedExtensions={["csv", "xls", "xlsx"]}
													/>
												</div>
											</div>
										)}
									</div>

									{counts.intermediaries === 0 && (
										<span className="italic form-full">
											<Translate
												content="communication.recipient_filter.no_:items_found"
												transReplaces={{ items: "intermediaries.plural" }}
											/>
										</span>
									)}
									{counts.intermediaries > maxCount && (
										<span className="italic form-full">
											<Translate
												content="communication.recipient_filter.to_much_:items_:count_:max"
												transReplaces={{
													items: "intermediaries.plural",
													items2: "intermediaries.plural",
												}}
												replaces={{ max: maxCount, count: counts.intermediaries }}
											/>
										</span>
									)}
									{counts.intermediaries > 0 && counts.intermediaries < maxCount && (
										<>
											<div className="form-full border-t">
												<label htmlFor="use_intermediary_filter">
													{uctrans(
														"communication.recipient_filter.which_:items?",
														{},
														{ items: "intermediaries.plural" },
													)}
												</label>
												<RadioGroup
													name="use_intermediary_filter"
													horizontal
													value={filterData.use_intermediary_filter ? 1 : 0}
													onChange={value => handleFilterChange("use_intermediary_filter", value)}>
													<Radio
														label={uctrans("communication.recipient_filter.all_:cnt", {
															cnt: counts.intermediaries,
														})}
														value={0}
													/>
													<Radio
														label={uctrans(
															"communication.recipient_filter.select_:items_:cnt",
															{
																cnt: `${
																	filterData.intermediaries
																		? intersection(
																				filterData.intermediaries ? filterData.intermediaries : [],
																				extraFilters.intermediaries.map(intermediary => intermediary.value),
																		  ).length
																		: 0
																} / ${counts.intermediaries}`,
															},
															{ items: "intermediaries.plural" },
														)}
														value={1}
													/>
												</RadioGroup>
											</div>
											{!!filterData.use_intermediary_filter && (
												<div className="form-full">
													<div className="inline-block">
														<CheckmarkGroup
															name="intermediaries"
															initialChecked={intersection(
																filterData.intermediaries ? filterData.intermediaries : [],
																extraFilters.intermediaries.map(intermediary => intermediary.value),
															)}
															onChange={intermediaries => handleFilterChange("intermediaries", intermediaries)}>
															{extraFilters.intermediaries.map(intermediary => (
																<Checkmark
																	key={intermediary.value}
																	value={intermediary.value}
																	name={intermediary.value}
																	label={intermediary.label}
																	className="w-1/2 float-left block"
																/>
															))}
														</CheckmarkGroup>
													</div>

													<div className="flex justify-end text-xs">
														<a
															className="link"
															onClick={() =>
																handleFilterChange(
																	"intermediaries",
																	extraFilters.intermediaries.map(intermediary => intermediary.value),
																)
															}>
															{uctrans("communication.recipient_filter.select_all")}
														</a>
														<span className="ml-1 mr-1">|</span>
														<a className="link" onClick={() => handleFilterChange("intermediaries", [])}>
															{uctrans("communication.recipient_filter.deselect_all")}
														</a>
													</div>
												</div>
											)}
										</>
									)}
									<div className="form-full border-b">
										<label htmlFor="intermediary_company_recipient_types">
											{uctrans("communication.recipient_filter.company_recipient_type")}
										</label>
										<div className="form-full">
											<div className="inline-block">
												<CheckmarkGroup
													name="intermediary_company_recipient_types"
													initialChecked={filterData.intermediary_company_recipient_types}
													onChange={teams => handleFilterChange("intermediary_company_recipient_types", teams)}>
													{staticData.company_recipient_types.map(companyRecipientType => (
														<Checkmark
															key={companyRecipientType.value}
															value={companyRecipientType.value}
															name={companyRecipientType.value}
															label={companyRecipientType.label}
														/>
													))}
												</CheckmarkGroup>
											</div>
										</div>
									</div>
									{!!Array.isArray(filterData.intermediary_company_recipient_types) &&
										filterData.intermediary_company_recipient_types.indexOf("contactpersons") !== -1 && (
											<>
												<div className="form-full">
													<label htmlFor="use_intermediary_contactperson_occupation_filter">
														{uctrans(
															"communication.recipient_filter.choose_:items?",
															{},
															{ items: "occupations.plural" },
														)}
													</label>
													<RadioGroup
														name="use_intermediary_contactperson_occupation_filter"
														horizontal
														value={filterData.use_intermediary_contactperson_occupation_filter ? 1 : 0}
														onChange={value =>
															handleFilterChange("use_intermediary_contactperson_occupation_filter", value)
														}>
														<Radio label={uctrans("general.yes")} value={1} />
														<Radio label={uctrans("general.no")} value={0} />
													</RadioGroup>
												</div>
												{!!filterData.use_intermediary_contactperson_occupation_filter && (
													<div className="form-full">
														<div className="inline-block">
															<CheckmarkGroup
																name="intermediary_contactperson_occupations"
																initialChecked={filterData.intermediary_contactperson_occupations}
																onChange={intermediary_contactperson_occupations =>
																	handleFilterChange(
																		"intermediary_contactperson_occupations",
																		intermediary_contactperson_occupations,
																	)
																}>
																{staticData.occupations.map(occupation => (
																	<Checkmark
																		key={occupation.value}
																		value={occupation.value}
																		name={occupation.value}
																		label={occupation.label}
																		className="w-1/2 float-left block"
																	/>
																))}
															</CheckmarkGroup>
														</div>

														<div className="flex justify-end text-xs">
															<a
																className="link"
																onClick={() =>
																	handleFilterChange(
																		"intermediary_contactperson_occupations",
																		staticData.occupations.map(occupation => occupation.value),
																	)
																}>
																{uctrans("communication.recipient_filter.select_all")}
															</a>
															<span className="ml-1 mr-1">|</span>
															<a
																className="link"
																onClick={() => handleFilterChange("intermediary_contactperson_occupations", [])}>
																{uctrans("communication.recipient_filter.deselect_all")}
															</a>
														</div>
													</div>
												)}
												<div className="form-full">
													<label htmlFor="use_intermediary_contactperson_app_filter">
														{uctrans(
															"communication.recipient_filter.filter_on_:item?",
															{},
															{ item: "contactpersons.app_usage" },
														)}
													</label>
													<RadioGroup
														name="use_intermediary_contactperson_app_filter"
														horizontal
														value={filterData.use_intermediary_contactperson_app_filter ? 1 : 0}
														onChange={value => handleFilterChange("use_intermediary_contactperson_app_filter", value)}>
														<Radio label={uctrans("general.yes")} value={1} />
														<Radio label={uctrans("general.no")} value={0} />
													</RadioGroup>
												</div>
												{!!filterData.use_intermediary_contactperson_app_filter && (
													<div className="form-full">
														<div className="inline-block">
															<RadioGroup
																name="intermediary_contactperson_uses_app"
																horizontal
																value={filterData.intermediary_contactperson_uses_app ? 1 : 0}
																onChange={value => handleFilterChange("intermediary_contactperson_uses_app", value)}>
																<Radio label={uctrans("contactpersons.uses_app")} value={1} />
																<Radio label={uctrans("contactpersons.does_not_uses_app")} value={0} />
															</RadioGroup>
														</div>
													</div>
												)}

												<div className="form-full">
													<label htmlFor="use_intermediary_contactperson_id_import">
														{uctrans(
															"communication.recipient_filter.filter_on_:item?",
															{},
															{ item: "communication.newsletter_recipients.import_contactperson_csv" },
														)}
													</label>
													<RadioGroup
														name="use_intermediary_contactperson_id_import"
														horizontal
														value={filterData.intermediary_contactperson_ids_uploaded ? 1 : 0}
														onChange={value => {
															handleFilterChange("intermediary_contactperson_ids_uploaded", value ? [] : null);
														}}>
														<Radio label={uctrans("general.yes")} value={1} />
														<Radio label={uctrans("general.no")} value={0} />
													</RadioGroup>
													{filterData.intermediary_contactperson_ids_uploaded && (
														<div className="form-full">
															<div className="inline-block">
																<FileInput
																	storeRoute="crm.communication.newsletter-mailing-recipients.upload-contact-person-csv"
																	newFileDownloadRoute={null} // No download route
																	fileType="document"
																	maxFiles={1}
																	onChange={content => {
																		if (content.length < 1) {
																			handleFilterChange("intermediary_contactperson_ids_uploaded", []);
																		}
																	}}
																	onAfterUpload={result =>
																		onAfterUpload(result, "intermediary_contactperson_ids_uploaded")
																	}
																	acceptedExtensions={["csv", "xls", "xlsx"]}
																/>
															</div>
														</div>
													)}
												</div>

												{counts.intermediary_contactpersons === 0 && (
													<span className="italic form-full">
														<Translate
															content="communication.recipient_filter.no_:items_found"
															transReplaces={{ items: "contactpersons.plural" }}
														/>
													</span>
												)}
												{counts.intermediary_contactpersons > maxCount && (
													<span className="italic form-full">
														<Translate
															content="communication.recipient_filter.to_much_:items_:count_:max"
															transReplaces={{
																items: "contactpersons.plural",
																items2: "contactpersons.plural",
															}}
															replaces={{
																max: maxCount,
																count: counts.intermediary_contactpersons,
															}}
														/>
													</span>
												)}
												{counts.intermediary_contactpersons > 0 && counts.intermediary_contactpersons < maxCount && (
													<>
														<div className="form-full border-t">
															<label htmlFor="use_intermediary_contactperson_filter">
																{uctrans(
																	"communication.recipient_filter.which_:items?",
																	{},
																	{ items: "contactpersons.plural" },
																)}
															</label>
															<RadioGroup
																name="use_intermediary_contactperson_filter"
																horizontal
																value={filterData.use_intermediary_contactperson_filter ? 1 : 0}
																onChange={value => handleFilterChange("use_intermediary_contactperson_filter", value)}>
																<Radio
																	label={uctrans("communication.recipient_filter.all_:cnt", {
																		cnt: counts.intermediary_contactpersons,
																	})}
																	value={0}
																/>
																<Radio
																	label={uctrans(
																		"communication.recipient_filter.select_:items_:cnt",
																		{
																			cnt: `${
																				filterData.intermediary_contactpersons
																					? intersection(
																							extraFilters.intermediary_contactpersons.map(
																								contactperson => contactperson.value,
																							),
																							filterData.intermediary_contactpersons,
																					  ).length
																					: 0
																			} / ${counts.intermediary_contactpersons}`,
																		},
																		{ items: "contactpersons.plural" },
																	)}
																	value={1}
																/>
															</RadioGroup>
														</div>
														{!!filterData.use_intermediary_contactperson_filter && (
															<div className="form-full">
																<div className="inline-block">
																	<CheckmarkGroup
																		name="intermediary_contactpersons"
																		initialChecked={intersection(
																			extraFilters.intermediary_contactpersons.map(
																				contactperson => contactperson.value,
																			),
																			filterData.intermediary_contactpersons,
																		)}
																		onChange={contactpersons =>
																			handleFilterChange("intermediary_contactpersons", contactpersons)
																		}>
																		{extraFilters.intermediary_contactpersons.map(contactperson => (
																			<Checkmark
																				key={contactperson.value}
																				value={contactperson.value}
																				name={contactperson.value}
																				label={contactperson.label}
																				className="w-1/2 float-left block"
																			/>
																		))}
																	</CheckmarkGroup>
																</div>

																<div className="flex justify-end text-xs">
																	<a
																		className="link"
																		onClick={() =>
																			handleFilterChange(
																				"intermediary_contactpersons",
																				extraFilters.intermediary_contactpersons.map(
																					contactperson => contactperson.value,
																				),
																			)
																		}>
																		{uctrans("communication.recipient_filter.select_all")}
																	</a>
																	<span className="ml-1 mr-1">|</span>
																	<a
																		className="link"
																		onClick={() => handleFilterChange("intermediary_contactpersons", [])}>
																		{uctrans("communication.recipient_filter.deselect_all")}
																	</a>
																</div>
															</div>
														)}
													</>
												)}
											</>
										)}
								</div>
							)}
							{isGroupSelected("organisations") && (
								<div className="bg-primary-lightest items-center px-3 py-3 my-2 inline-block w-full">
									<h4>{uctrans("organisations.plural")}</h4>
									{counts.organisations === 0 && (
										<span className="italic form-full">
											<Translate
												content="communication.recipient_filter.no_:items_found"
												transReplaces={{ items: "organisations.plural" }}
											/>
										</span>
									)}
									{counts.organisations > maxCount && (
										<span className="italic form-full">
											<Translate
												content="communication.recipient_filter.to_much_:items_:count_:max"
												transReplaces={{
													items: "organisations.plural",
													items2: "organisations.plural",
												}}
												replaces={{ max: maxCount, count: counts.organisations }}
											/>
										</span>
									)}
									{counts.organisations > 0 && counts.organisations < maxCount && (
										<>
											<div className="form-full border-t">
												<label htmlFor="use_organisation_filter">
													{uctrans(
														"communication.recipient_filter.which_:items?",
														{},
														{ items: "organisations.plural" },
													)}
												</label>
												<RadioGroup
													name="use_organisation_filter"
													horizontal
													value={filterData.use_organisation_filter ? 1 : 0}
													onChange={value => handleFilterChange("use_organisation_filter", value)}>
													<Radio
														label={uctrans("communication.recipient_filter.all_:cnt", {
															cnt: counts.organisations,
														})}
														value={0}
													/>
													<Radio
														label={uctrans(
															"communication.recipient_filter.select_:items_:cnt",
															{
																cnt: `${
																	filterData.organisations
																		? intersection(
																				filterData.organisations ? filterData.organisations : [],
																				extraFilters.organisations.map(organisation => organisation.value),
																		  ).length
																		: 0
																} / ${counts.organisations}`,
															},
															{ items: "organisations.plural" },
														)}
														value={1}
													/>
												</RadioGroup>
											</div>
											{!!filterData.use_organisation_filter && (
												<div className="form-full">
													<div className="inline-block">
														<CheckmarkGroup
															name="organisations"
															initialChecked={intersection(
																filterData.organisations ? filterData.organisations : [],
																extraFilters.organisations.map(organisation => organisation.value),
															)}
															onChange={organisations => handleFilterChange("organisations", organisations)}>
															{extraFilters.organisations.map(organisation => (
																<Checkmark
																	key={organisation.value}
																	value={organisation.value}
																	name={organisation.value}
																	label={organisation.label}
																	className="w-1/2 float-left block"
																/>
															))}
														</CheckmarkGroup>
													</div>

													<div className="flex justify-end text-xs">
														<a
															className="link"
															onClick={() =>
																handleFilterChange(
																	"organisations",
																	extraFilters.organisations.map(organisation => organisation.value),
																)
															}>
															{uctrans("communication.recipient_filter.select_all")}
														</a>
														<span className="ml-1 mr-1">|</span>
														<a className="link" onClick={() => handleFilterChange("organisations", [])}>
															{uctrans("communication.recipient_filter.deselect_all")}
														</a>
													</div>
												</div>
											)}
										</>
									)}
									<div className="form-full border-b">
										<label htmlFor="organisation_company_recipient_types">
											{uctrans("communication.recipient_filter.company_recipient_type")}
										</label>
										<div className="form-full">
											<div className="inline-block">
												<CheckmarkGroup
													name="organisation_company_recipient_types"
													initialChecked={filterData.organisation_company_recipient_types}
													onChange={types => handleFilterChange("organisation_company_recipient_types", types)}>
													{staticData.company_recipient_types.map(companyRecipientType => (
														<Checkmark
															key={companyRecipientType.value}
															value={companyRecipientType.value}
															name={companyRecipientType.value}
															label={companyRecipientType.label}
														/>
													))}
												</CheckmarkGroup>
											</div>
										</div>
									</div>
									{!!Array.isArray(filterData.organisation_company_recipient_types) &&
										filterData.organisation_company_recipient_types.indexOf("contactpersons") !== -1 && (
											<>
												<div className="form-full">
													<label htmlFor="use_organisation_contactperson_occupation_filter">
														{uctrans(
															"communication.recipient_filter.choose_:items?",
															{},
															{ items: "occupations.plural" },
														)}
													</label>
													<RadioGroup
														name="use_organisation_contactperson_occupation_filter"
														horizontal
														value={filterData.use_organisation_contactperson_occupation_filter ? 1 : 0}
														onChange={value =>
															handleFilterChange("use_organisation_contactperson_occupation_filter", value)
														}>
														<Radio label={uctrans("general.yes")} value={1} />
														<Radio label={uctrans("general.no")} value={0} />
													</RadioGroup>
												</div>
												{!!filterData.use_organisation_contactperson_occupation_filter && (
													<div className="form-full">
														<div className="inline-block">
															<CheckmarkGroup
																name="organisation_contactperson_occupations"
																initialChecked={filterData.organisation_contactperson_occupations}
																onChange={organisation_contactperson_occupations =>
																	handleFilterChange(
																		"organisation_contactperson_occupations",
																		organisation_contactperson_occupations,
																	)
																}>
																{staticData.occupations.map(occupation => (
																	<Checkmark
																		key={occupation.value}
																		value={occupation.value}
																		name={occupation.value}
																		label={occupation.label}
																		className="w-1/2 float-left block"
																	/>
																))}
															</CheckmarkGroup>
														</div>

														<div className="flex justify-end text-xs">
															<a
																className="link"
																onClick={() =>
																	handleFilterChange(
																		"organisation_contactperson_occupations",
																		staticData.occupations.map(occupation => occupation.value),
																	)
																}>
																{uctrans("communication.recipient_filter.select_all")}
															</a>
															<span className="ml-1 mr-1">|</span>
															<a
																className="link"
																onClick={() => handleFilterChange("organisation_contactperson_occupations", [])}>
																{uctrans("communication.recipient_filter.deselect_all")}
															</a>
														</div>
													</div>
												)}
												<div className="form-full">
													<label htmlFor="use_organisation_contactperson_app_filter">
														{uctrans(
															"communication.recipient_filter.filter_on_:item?",
															{},
															{ item: "contactpersons.app_usage" },
														)}
													</label>
													<RadioGroup
														name="use_organisation_contactperson_app_filter"
														horizontal
														value={filterData.use_organisation_contactperson_app_filter ? 1 : 0}
														onChange={value => handleFilterChange("use_organisation_contactperson_app_filter", value)}>
														<Radio label={uctrans("general.yes")} value={1} />
														<Radio label={uctrans("general.no")} value={0} />
													</RadioGroup>
												</div>
												{!!filterData.use_organisation_contactperson_app_filter && (
													<div className="form-full">
														<div className="inline-block">
															<RadioGroup
																name="organisation_contactperson_uses_app"
																horizontal
																value={filterData.organisation_contactperson_uses_app ? 1 : 0}
																onChange={value => handleFilterChange("organisation_contactperson_uses_app", value)}>
																<Radio label={uctrans("contactpersons.uses_app")} value={1} />
																<Radio label={uctrans("contactpersons.does_not_uses_app")} value={0} />
															</RadioGroup>
														</div>
													</div>
												)}

												<div className="form-full">
													<label htmlFor="use_organisation_contactperson_id_import">
														{uctrans(
															"communication.recipient_filter.filter_on_:item?",
															{},
															{ item: "communication.newsletter_recipients.import_contactperson_csv" },
														)}
													</label>
													<RadioGroup
														name="use_organisation_contactperson_id_import"
														horizontal
														value={filterData.organisation_contactperson_ids_uploaded ? 1 : 0}
														onChange={value => {
															handleFilterChange("organisation_contactperson_ids_uploaded", value ? [] : null);
														}}>
														<Radio label={uctrans("general.yes")} value={1} />
														<Radio label={uctrans("general.no")} value={0} />
													</RadioGroup>
													{filterData.organisation_contactperson_ids_uploaded && (
														<div className="form-full">
															<div className="inline-block">
																<FileInput
																	storeRoute="crm.communication.newsletter-mailing-recipients.upload-contact-person-csv"
																	newFileDownloadRoute={null} // No download route
																	fileType="document"
																	maxFiles={1}
																	onChange={content => {
																		if (content.length < 1) {
																			handleFilterChange("organisation_contactperson_ids_uploaded", []);
																		}
																	}}
																	onAfterUpload={result =>
																		onAfterUpload(result, "organisation_contactperson_ids_uploaded")
																	}
																	acceptedExtensions={["csv", "xls", "xlsx"]}
																/>
															</div>
														</div>
													)}
												</div>

												{counts.organisation_contactpersons === 0 && (
													<span className="italic form-full">
														<Translate
															content="communication.recipient_filter.no_:items_found"
															transReplaces={{ items: "contactpersons.plural" }}
														/>
													</span>
												)}
												{counts.organisation_contactpersons > maxCount && (
													<span className="italic form-full">
														<Translate
															content="communication.recipient_filter.to_much_:items_:count_:max"
															transReplaces={{
																items: "contactpersons.plural",
																items2: "contactpersons.plural",
															}}
															replaces={{
																max: maxCount,
																count: counts.organisation_contactpersons,
															}}
														/>
													</span>
												)}
												{counts.organisation_contactpersons > 0 && counts.organisation_contactpersons < maxCount && (
													<>
														<div className="form-full border-t">
															<label htmlFor="use_organisation_contactperson_filter">
																{uctrans(
																	"communication.recipient_filter.which_:items?",
																	{},
																	{ items: "contactpersons.plural" },
																)}
															</label>
															<RadioGroup
																name="use_organisation_contactperson_filter"
																horizontal
																value={filterData.use_organisation_contactperson_filter ? 1 : 0}
																onChange={value => handleFilterChange("use_organisation_contactperson_filter", value)}>
																<Radio
																	label={uctrans("communication.recipient_filter.all_:cnt", {
																		cnt: counts.organisation_contactpersons,
																	})}
																	value={0}
																/>
																<Radio
																	label={uctrans(
																		"communication.recipient_filter.select_:items_:cnt",
																		{
																			cnt: `${
																				filterData.organisation_contactpersons
																					? intersection(
																							extraFilters.organisation_contactpersons.map(
																								contactperson => contactperson.value,
																							),
																							filterData.organisation_contactpersons,
																					  ).length
																					: 0
																			} / ${counts.organisation_contactpersons}`,
																		},
																		{ items: "contactpersons.plural" },
																	)}
																	value={1}
																/>
															</RadioGroup>
														</div>
														{!!filterData.use_organisation_contactperson_filter && (
															<div className="form-full">
																<div className="inline-block">
																	<CheckmarkGroup
																		name="organisation_contactpersons"
																		initialChecked={intersection(
																			extraFilters.organisation_contactpersons.map(
																				contactperson => contactperson.value,
																			),
																			filterData.organisation_contactpersons,
																		)}
																		onChange={contactpersons =>
																			handleFilterChange("organisation_contactpersons", contactpersons)
																		}>
																		{extraFilters.organisation_contactpersons.map(contactperson => (
																			<Checkmark
																				key={contactperson.value}
																				value={contactperson.value}
																				name={contactperson.value}
																				label={contactperson.label}
																				className="w-1/2 float-left block"
																			/>
																		))}
																	</CheckmarkGroup>
																</div>

																<div className="flex justify-end text-xs">
																	<a
																		className="link"
																		onClick={() =>
																			handleFilterChange(
																				"organisation_contactpersons",
																				extraFilters.organisation_contactpersons.map(
																					contactperson => contactperson.value,
																				),
																			)
																		}>
																		{uctrans("communication.recipient_filter.select_all")}
																	</a>
																	<span className="ml-1 mr-1">|</span>
																	<a
																		className="link"
																		onClick={() => handleFilterChange("organisation_contactpersons", [])}>
																		{uctrans("communication.recipient_filter.deselect_all")}
																	</a>
																</div>
															</div>
														)}
													</>
												)}
											</>
										)}
								</div>
							)}

							{isGroupSelected("users") && (
								<div className="bg-primary-lightest items-center px-3 py-3 my-2 inline-block w-full">
									<h4>{uctrans("users.plural")}</h4>
									<div className="form-full">
										<label htmlFor="use_user_role_filter">
											{uctrans("communication.recipient_filter.choose_:items?", {}, { items: "roles.plural" })}
										</label>
										<RadioGroup
											name="use_user_role_filter"
											horizontal
											value={filterData.use_user_role_filter ? 1 : 0}
											onChange={value => handleFilterChange("use_user_role_filter", value)}>
											<Radio label={uctrans("general.yes")} value={1} />
											<Radio label={uctrans("general.no")} value={0} />
										</RadioGroup>
									</div>
									{!!filterData.use_user_role_filter && (
										<div className="form-full">
											<div className="inline-block">
												<CheckmarkGroup
													name="user_roles"
													initialChecked={filterData.user_roles}
													onChange={roles => handleFilterChange("user_roles", roles)}>
													{staticData.roles.map(role => (
														<Checkmark
															key={role.value}
															value={role.value}
															name={role.value}
															label={role.label}
															className="w-1/2 float-left block"
														/>
													))}
												</CheckmarkGroup>
											</div>
											<div className="flex justify-end text-xs">
												<a
													className="link"
													onClick={() =>
														handleFilterChange(
															"user_roles",
															staticData.roles.map(role => role.value),
														)
													}>
													{uctrans("communication.recipient_filter.select_all")}
												</a>
												<span className="ml-1 mr-1">|</span>
												<a className="link" onClick={() => handleFilterChange("user_roles", [])}>
													{uctrans("communication.recipient_filter.deselect_all")}
												</a>
											</div>
										</div>
									)}
									<div className="form-full">
										<label htmlFor="use_user_department_filter">
											{uctrans("communication.recipient_filter.choose_:items?", {}, { items: "departments.plural" })}
										</label>
										<RadioGroup
											name="use_user_department_filter"
											horizontal
											value={filterData.use_user_department_filter ? 1 : 0}
											onChange={value => handleFilterChange("use_user_department_filter", value)}>
											<Radio label={uctrans("general.yes")} value={1} />
											<Radio label={uctrans("general.no")} value={0} />
										</RadioGroup>
									</div>
									{!!filterData.use_user_department_filter && (
										<div className="form-full">
											<div className="inline-block">
												<CheckmarkGroup
													name="user_departments"
													initialChecked={filterData.user_departments}
													onChange={departments => handleFilterChange("user_departments", departments)}>
													{staticData.abn_departments.map(department => (
														<Checkmark
															key={department.value}
															value={department.value}
															name={department.value}
															label={department.label}
															className="w-1/2 float-left block"
														/>
													))}
												</CheckmarkGroup>
											</div>
											<div className="flex justify-end text-xs">
												<a
													className="link"
													onClick={() =>
														handleFilterChange(
															"user_departments",
															staticData.abn_departments.map(department => department.value),
														)
													}>
													{uctrans("communication.recipient_filter.select_all")}
												</a>
												<span className="ml-1 mr-1">|</span>
												<a className="link" onClick={() => handleFilterChange("departments", [])}>
													{uctrans("communication.recipient_filter.deselect_all")}
												</a>
											</div>
										</div>
									)}

									{counts.users === 0 && (
										<span className="italic form-full">
											<Translate
												content="communication.recipient_filter.no_:items_found"
												transReplaces={{ items: "users.plural" }}
											/>
										</span>
									)}
									{counts.users > maxCount && (
										<span className="italic form-full">
											<Translate
												content="communication.recipient_filter.to_much_:items_:count_:max"
												transReplaces={{
													items: "users.plural",
													items2: "users.plural",
												}}
												replaces={{ max: maxCount, count: counts.users }}
											/>
										</span>
									)}
									{counts.users > 0 && counts.users < maxCount && (
										<>
											<div className="form-full border-t">
												<label htmlFor="use_user_filter">
													{uctrans("communication.recipient_filter.which_:items?", {}, { items: "users.plural" })}
												</label>
												<RadioGroup
													name="use_user_filter"
													horizontal
													value={filterData.use_user_filter ? 1 : 0}
													onChange={value => handleFilterChange("use_user_filter", value)}>
													<Radio
														label={uctrans("communication.recipient_filter.all_:cnt", {
															cnt: counts.users,
														})}
														value={0}
													/>
													<Radio
														label={uctrans(
															"communication.recipient_filter.select_:items_:cnt",
															{
																cnt: `${
																	filterData.users
																		? intersection(
																				filterData.users ? filterData.users : [],
																				extraFilters.users.map(user => user.value),
																		  ).length
																		: 0
																} / ${counts.users}`,
															},
															{ items: "users.plural" },
														)}
														value={1}
													/>
												</RadioGroup>
											</div>
											{!!filterData.use_user_filter && (
												<div className="form-full">
													<div className="inline-block">
														<CheckmarkGroup
															name="users"
															initialChecked={intersection(
																filterData.users ? filterData.users : [],
																extraFilters.users.map(user => user.value),
															)}
															onChange={users => handleFilterChange("users", users)}>
															{extraFilters.users.map(user => (
																<Checkmark
																	key={user.value}
																	value={user.value}
																	name={user.value}
																	label={user.label}
																	className="w-1/2 float-left block"
																/>
															))}
														</CheckmarkGroup>
													</div>

													<div className="flex justify-end text-xs">
														<a
															className="link"
															onClick={() =>
																handleFilterChange(
																	"users",
																	extraFilters.users.map(user => user.value),
																)
															}>
															{uctrans("communication.recipient_filter.select_all")}
														</a>
														<span className="ml-1 mr-1">|</span>
														<a className="link" onClick={() => handleFilterChange("users", [])}>
															{uctrans("communication.recipient_filter.deselect_all")}
														</a>
													</div>
												</div>
											)}
										</>
									)}
								</div>
							)}

							{isGroupSelected("other_users") && (
								<div className="bg-primary-lightest items-center px-3 py-3 my-2 inline-block w-full">
									<h4>{uctrans("users.others")}</h4>
									<div className="form-full">
										<label htmlFor="use_other_user_role_filter">
											{uctrans("communication.recipient_filter.choose_:items?", {}, { items: "roles.plural" })}
										</label>
										<RadioGroup
											name="use_other_user_role_filter"
											horizontal
											value={filterData.use_other_user_role_filter ? 1 : 0}
											onChange={value => handleFilterChange("use_other_user_role_filter", value)}>
											<Radio label={uctrans("general.yes")} value={1} />
											<Radio label={uctrans("general.no")} value={0} />
										</RadioGroup>
									</div>
									{!!filterData.use_other_user_role_filter && (
										<div className="form-full">
											<div className="inline-block">
												<CheckmarkGroup
													name="other_user_roles"
													initialChecked={filterData.other_user_roles}
													onChange={roles => handleFilterChange("other_user_roles", roles)}>
													{staticData.roles.map(role => (
														<Checkmark
															key={role.value}
															value={role.value}
															name={role.value}
															label={role.label}
															className="w-1/2 float-left block"
														/>
													))}
												</CheckmarkGroup>
											</div>
											<div className="flex justify-end text-xs">
												<a
													className="link"
													onClick={() =>
														handleFilterChange(
															"other_user_roles",
															staticData.roles.map(role => role.value),
														)
													}>
													{uctrans("communication.recipient_filter.select_all")}
												</a>
												<span className="ml-1 mr-1">|</span>
												<a className="link" onClick={() => handleFilterChange("other_user_roles", [])}>
													{uctrans("communication.recipient_filter.deselect_all")}
												</a>
											</div>
										</div>
									)}
									<div className="form-full">
										<label htmlFor="use_other_user_department_filter">
											{uctrans("communication.recipient_filter.choose_:items?", {}, { items: "departments.plural" })}
										</label>
										<RadioGroup
											name="use_other_user_department_filter"
											horizontal
											value={filterData.use_other_user_department_filter ? 1 : 0}
											onChange={value => handleFilterChange("use_other_user_department_filter", value)}>
											<Radio label={uctrans("general.yes")} value={1} />
											<Radio label={uctrans("general.no")} value={0} />
										</RadioGroup>
									</div>
									{!!filterData.use_other_user_department_filter && (
										<div className="form-full">
											<div className="inline-block">
												<CheckmarkGroup
													name="other_user_departments"
													initialChecked={filterData.other_user_departments}
													onChange={departments => handleFilterChange("other_user_departments", departments)}>
													{staticData.non_abn_departments.map(department => (
														<Checkmark
															key={department.value}
															value={department.value}
															name={department.value}
															label={department.label}
															className="w-1/2 float-left block"
														/>
													))}
												</CheckmarkGroup>
											</div>
											<div className="flex justify-end text-xs">
												<a
													className="link"
													onClick={() =>
														handleFilterChange(
															"other_user_departments",
															staticData.non_abn_departments.map(department => department.value),
														)
													}>
													{uctrans("communication.recipient_filter.select_all")}
												</a>
												<span className="ml-1 mr-1">|</span>
												<a className="link" onClick={() => handleFilterChange("other_user_departments", [])}>
													{uctrans("communication.recipient_filter.deselect_all")}
												</a>
											</div>
										</div>
									)}

									{counts.other_users === 0 && (
										<span className="italic form-full">
											<Translate
												content="communication.recipient_filter.no_:items_found"
												transReplaces={{ items: "users.plural" }}
											/>
										</span>
									)}
									{counts.other_users > maxCount && (
										<span className="italic form-full">
											<Translate
												content="communication.recipient_filter.to_much_:items_:count_:max"
												transReplaces={{
													items: "users.plural",
													items2: "users.plural",
												}}
												replaces={{ max: maxCount, count: counts.other_users }}
											/>
										</span>
									)}
									{counts.other_users > 0 && counts.other_users < maxCount && (
										<>
											<div className="form-full border-t">
												<label htmlFor="use_other_user_filter">
													{uctrans("communication.recipient_filter.which_:items?", {}, { items: "users.plural" })}
												</label>
												<RadioGroup
													name="use_other_user_filter"
													horizontal
													value={filterData.use_other_user_filter ? 1 : 0}
													onChange={value => handleFilterChange("use_other_user_filter", value)}>
													<Radio
														label={uctrans("communication.recipient_filter.all_:cnt", {
															cnt: counts.other_users,
														})}
														value={0}
													/>
													<Radio
														label={uctrans(
															"communication.recipient_filter.select_:items_:cnt",
															{
																cnt: `${
																	filterData.other_users
																		? intersection(
																				filterData.other_users ? filterData.other_users : [],
																				extraFilters.other_users
																					? extraFilters.other_users.map(user => user.value)
																					: [],
																		  ).length
																		: 0
																} / ${counts.other_users}`,
															},
															{ items: "users.plural" },
														)}
														value={1}
													/>
												</RadioGroup>
											</div>
											{!!filterData.use_other_user_filter && (
												<div className="form-full">
													<div className="inline-block">
														<CheckmarkGroup
															name="other_users"
															initialChecked={intersection(
																filterData.other_users ? filterData.other_users : [],
																extraFilters.other_users ? extraFilters.other_users.map(user => user.value) : [],
															)}
															onChange={users => handleFilterChange("other_users", users)}>
															{!!extraFilters.other_users &&
																extraFilters.other_users.map(user => (
																	<Checkmark
																		key={user.value}
																		value={user.value}
																		name={user.value}
																		label={user.label}
																		className="w-1/2 float-left block"
																	/>
																))}
														</CheckmarkGroup>
													</div>

													<div className="flex justify-end text-xs">
														<a
															className="link"
															onClick={() =>
																handleFilterChange(
																	"other_users",
																	extraFilters.other_users ? extraFilters.other_users.map(user => user.value) : [],
																)
															}>
															{uctrans("communication.recipient_filter.select_all")}
														</a>
														<span className="ml-1 mr-1">|</span>
														<a className="link" onClick={() => handleFilterChange("other_users", [])}>
															{uctrans("communication.recipient_filter.deselect_all")}
														</a>
													</div>
												</div>
											)}
										</>
									)}
								</div>
							)}

							{isGroupSelected("extra") && (
								<div className="bg-primary-lightest items-center px-3 py-3 my-2 inline-block w-full">
									<h4>{uctrans("communication.recipient_filter.groups.extra")}</h4>
									<span className="italic">
										{uctrans("communication.recipient_filter.exra_email_addresses_description")}
									</span>
									<div className="form-full">
										<DebouncedTextarea
											name="extra_email_addresses"
											value={filterData.extra_email_addresses}
											onChange={e => handleFilterChange("extra_email_addresses", e.target.value)}
										/>
									</div>
								</div>
							)}

							{isGroupSelected("mailing_list_recipients") && (
								<div className="bg-primary-lightest items-center px-3 py-3 my-2 inline-block w-full">
									<h4>{uctrans("communication.mailing_list_recipients.plural")}</h4>
									{counts.mailing_list_recipients === 0 && (
										<span className="italic form-full">
											<Translate
												content="communication.recipient_filter.no_:items_found"
												transReplaces={{ items: "communication.mailing_list_recipients.plural" }}
											/>
										</span>
									)}
									{counts.mailing_list_recipients > maxCount && (
										<span className="italic form-full">
											<Translate
												content="communication.recipient_filter.to_much_:items_:count_:max"
												transReplaces={{
													items: "communication.mailing_list_recipients.plural",
													items2: "communication.mailing_list_recipients.plural",
												}}
												replaces={{ max: maxCount, count: counts.mailing_list_recipients }}
											/>
										</span>
									)}
									{counts.mailing_list_recipients > 0 && counts.mailing_list_recipients < maxCount && (
										<>
											<div className="form-full border-t">
												<label htmlFor="use_mailing_list_recipient_filter">
													{uctrans(
														"communication.recipient_filter.which_:items?",
														{},
														{ items: "communication.mailing_list_recipients.plural" },
													)}
												</label>
												<RadioGroup
													name="use_mailing_list_recipient_filter"
													horizontal
													value={filterData.use_mailing_list_recipient_filter ? 1 : 0}
													onChange={value => handleFilterChange("use_mailing_list_recipient_filter", value)}>
													<Radio
														label={uctrans("communication.recipient_filter.all_:cnt", {
															cnt: counts.mailing_list_recipients,
														})}
														value={0}
													/>
													<Radio
														label={uctrans(
															"communication.recipient_filter.select_:items_:cnt",
															{
																cnt: `${
																	filterData.mailing_list_recipients
																		? intersection(
																				filterData.mailing_list_recipients ? filterData.mailing_list_recipients : [],
																				extraFilters.mailing_list_recipients.map(
																					mailingListRecipient => mailingListRecipient.value,
																				),
																		  ).length
																		: 0
																} / ${counts.mailing_list_recipients}`,
															},
															{ items: "communication.mailing_list_recipients.plural" },
														)}
														value={1}
													/>
												</RadioGroup>
											</div>
											{!!filterData.use_mailing_list_recipient_filter && (
												<div className="form-full">
													<div className="inline-block w-full">
														<CheckmarkGroup
															name="mailing_list_recipients"
															initialChecked={intersection(
																filterData.mailing_list_recipients ? filterData.mailing_list_recipients : [],
																extraFilters.mailing_list_recipients.map(
																	mailingListRecipient => mailingListRecipient.value,
																),
															)}
															onChange={mailingListRecipients =>
																handleFilterChange("mailing_list_recipients", mailingListRecipients)
															}>
															{extraFilters.mailing_list_recipients.map(mailingListRecipient => (
																<Checkmark
																	key={mailingListRecipient.value}
																	value={mailingListRecipient.value}
																	name={mailingListRecipient.value}
																	label={mailingListRecipient.label}
																	className="w-1/2 float-left block"
																/>
															))}
														</CheckmarkGroup>
													</div>

													<div className="flex justify-end text-xs">
														<a
															className="link"
															onClick={() =>
																handleFilterChange(
																	"mailing_list_recipients",
																	extraFilters.mailing_list_recipients.map(
																		mailingListRecipient => mailingListRecipient.value,
																	),
																)
															}>
															{uctrans("communication.recipient_filter.select_all")}
														</a>
														<span className="ml-1 mr-1">|</span>
														<a className="link" onClick={() => handleFilterChange("mailing_list_recipients", [])}>
															{uctrans("communication.recipient_filter.deselect_all")}
														</a>
													</div>
												</div>
											)}
										</>
									)}
								</div>
							)}
						</>
					)}
				</>
			)}

			<div className="form-full">
				<div className="w-full md:w-1/2 lg:w-2/3 column">
					<SubmitBar
						translateLabel={false}
						buttonContent={`${uctrans("communication.recipient_filter.add_recipients")} (${counts.total})`}
						addAnotherAvailable={false}
						onCancel={handleCancel}
					/>
				</div>
			</div>
		</AjaxForm>
	);
}
AddNewsletterRecipientsForm.propTypes = {
	newsletter: PropTypes.object.isRequired,
	mailing: PropTypes.object.isRequired,
	onSuccess: PropTypes.func,
	onCancel: PropTypes.func,
};
