import axios from "axios";
import forEach from "lodash/forEach";
import upperFirst from "lodash/upperFirst";
import PropTypes from "prop-types";
import { useState, useCallback, useEffect } from "react";
import { route } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import SingleSelectAutoComplete from "../../../../../../js/react/components/general/autocomplete/SingleSelectAutoComplete";
import ConfirmDialog from "../../../../../../js/react/components/general/ConfirmDialog";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import Radio from "../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../js/react/components/general/form/radio/RadioGroup";
import { createLoader, error } from "../../../../../../js/react/components/general/notifications";
import Select from "../../../../../../js/react/components/general/Select";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";
import useInputHandler from "../../../../../../js/react/hooks/useInputHandler";
import TextEditor from "../../forms/textEditor/TextEditor";
import CollapsibleContent from "../../general/CollapsibleContent";

export const TestMailReceivers = {
	EMAIL_ADDRESS: "email_address",
	CONTACTPERSON: "contactperson",
	USER: "user",
};

export default function NewsletterSideBarContent({ newsletter, newsletterStaticData }) {
	const [showTestDialog, setShowTestDialog] = useState(false);

	const [testMailData, setTestMailData] = useState({
		email: window.data.user ? window.data.user.email : "",
		receiver_type: null,
		sender_name: "",
		sender_email: "",
		explanation: newsletter.test_mail_explanation || "",
	});

	const inputHandler = useInputHandler(setTestMailData);
	const genericHandler = useGenericHandler(setTestMailData);
	const handleValueChange = (key, value) => {
		const newTestMailData = { ...testMailData };
		newTestMailData[key] = value;
		setTestMailData(newTestMailData);
	};

	const [newsletterRecipient, setNewsletterRecipient] = useState(
		newsletterStaticData.newsletter_mailings && newsletterStaticData.newsletter_mailings.length
			? newsletterStaticData.newsletter_mailings[0].id
			: null,
	);
	const [newsletterRecipientsOptions] = useState(
		newsletterStaticData.newsletter_mailings && newsletterStaticData.newsletter_mailings.length
			? newsletterStaticData.newsletter_mailings.map(mailing => ({
					value: `${mailing.id}`,
					label: `${mailing.sender_name} - ${mailing.sender_email}`,
			  }))
			: null,
	);

	useEffect(() => {
		if (newsletterStaticData.newsletter_mailings) {
			const recipientFromMailing = newsletterStaticData.newsletter_mailings.find(
				({ id }) => Number(id) === Number(newsletterRecipient),
			);

			const newTestMailData = { ...testMailData };
			if (recipientFromMailing) {
				newTestMailData.sender_name = recipientFromMailing.sender_name;
				newTestMailData.sender_email = recipientFromMailing.sender_email;
			} else {
				newTestMailData.sender_name = "ABN AMRO Intermediaire Distributie";
				newTestMailData.sender_email = "informatie@nl.abnamro.com";
			}
			setTestMailData(newTestMailData);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newsletterRecipient, newsletterStaticData.newsletter_mailings]);

	const isAutomatedMessage =
		Number(newsletter.type) === Number(newsletterStaticData.message_communication_types.AUTOMATED_MESSAGE);

	const sendTestMail = useCallback(async () => {
		setShowTestDialog(false);
		if (testMailData && testMailData.email === null && testMailData.email !== "") {
			error(uctrans("general.no_:item_", {}, { item: "general.email" }));
			return;
		}
		const loader = createLoader(uctrans("communication.newsletters.testmail_is_being_send"));
		try {
			const url =
				Number(newsletter.type) === Number(newsletterStaticData.message_communication_types.AUTOMATED_MESSAGE)
					? route("crm.communication.automated-messages.send-test-mail", { automated_message: newsletterStaticData.id })
					: route("crm.communication.newsletters.send-test-mail", { newsletter: newsletterStaticData.id });

			await axios.patch(url, testMailData);
			loader.success(uctrans(`communication.newsletters.testmail_send_succesfully`));
		} catch (exception) {
			if (exception.response && exception.response.status === 422) {
				if (Object.prototype.hasOwnProperty.call(exception.response.data, "errors")) {
					let first = true;
					forEach(exception.response.data.errors, message => {
						if (first && loader) {
							loader.failure(upperFirst(message[0]));
							first = false;
						} else {
							error(upperFirst(message[0]));
						}
					});
				}
			} else if (exception.message) {
				loader.failure(exception.message);
			}
		}
	}, [
		newsletterStaticData.message_communication_types.AUTOMATED_MESSAGE,
		newsletter.type,
		newsletterStaticData.id,
		testMailData,
	]);

	const selectTestmailReceiver = receiverType => {
		genericHandler(receiverType, "receiver_type");
		genericHandler(null, "email");
	};

	const previewUrl = () => {
		if (isAutomatedMessage) {
			return route("crm.communication.automated-messages.preview", newsletterStaticData.id);
		}
		return route("crm.communication.newsletters.preview", newsletterStaticData.id);
	};

	return (
		<>
			<div>
				{!newsletterStaticData.creating && (
					<CollapsibleContent title={uctrans("general.actions")} initialCollapseState={false}>
						<a rel="noopener noreferrer" className="button button-primary" href={previewUrl()} target="_blank">
							{uctrans("communication.newsletters.preview")}
						</a>
						<br />
						<a
							className="button button-secondary"
							onClick={() => {
								setShowTestDialog(true);
							}}>
							{uctrans("communication.newsletters.send_testmail")}
						</a>
					</CollapsibleContent>
				)}
			</div>
			{!!showTestDialog && (
				<ConfirmDialog
					isOpen
					onConfirm={sendTestMail}
					onCancel={() => {
						setShowTestDialog(false);
					}}
					confirmButtonLabel={uctrans("communication.newsletters.send")}
					cancelButtonLabel={uctrans("general.cancel")}
					title={uctrans("communication.newsletters.testmail")}>
					<>
						{!isAutomatedMessage && (
							<>
								{!!newsletterStaticData.newsletter_mailings && !!newsletterStaticData.newsletter_mailings.length && (
									<div className="form-full">
										<Select
											isClearable
											value={newsletterRecipientsOptions.filter(
												({ value }) => Number(value) === Number(newsletterRecipient),
											)}
											options={newsletterRecipientsOptions}
											onChange={selectedValue => setNewsletterRecipient(selectedValue ? selectedValue.value : null)}
											placeholder={uctrans(
												"general.select_:item",
												{},
												{ item: "communication.newsletter_mailings.fields.sender" },
											)}
										/>
									</div>
								)}

								<div className="form-full">
									<LabeledTextInput
										name="sender_name"
										value={testMailData.sender_name}
										onChange={inputHandler}
										label="communication.newsletters.fields.sender_name"
										required
									/>
								</div>
								<div className="form-full">
									<LabeledTextInput
										name="sender_email"
										value={testMailData.sender_email}
										onChange={inputHandler}
										label="communication.newsletters.fields.sender_email"
										required
										placeholder={uctrans("communication.newsletters.sender_email_description")}
									/>
								</div>
							</>
						)}
						<div className="form-full">
							{isAutomatedMessage ? (
								<>
									<LabeledTextInput
										name="email"
										value={testMailData.email || ""}
										onChange={inputHandler}
										label="communication.newsletters.send_testmail_to"
										required
										autoComplete
									/>
									<em className="font-sm">{uctrans("communication.multple_email_addresses_seperated_possible")}</em>
								</>
							) : (
								<div>
									<label>{uctrans("communication.newsletters.send_testmail_to")}</label>
									<RadioGroup
										name="receiver_type"
										value={testMailData.receiver_type}
										onChange={type => selectTestmailReceiver(type)}>
										{newsletterStaticData.testmail_receivers.map((receiver, index) => (
											<Radio key={index} label={receiver.label} value={receiver.value} />
										))}
									</RadioGroup>
									{testMailData.receiver_type === TestMailReceivers.EMAIL_ADDRESS && (
										<>
											<LabeledTextInput
												name="email"
												value={testMailData.email || ""}
												onChange={inputHandler}
												label="communication.newsletters.send_testmail_to"
												required
												autoComplete
											/>
											<em className="font-sm">{uctrans("communication.multple_email_addresses_seperated_possible")}</em>
										</>
									)}
									{testMailData.receiver_type === TestMailReceivers.USER && (
										<SingleSelectAutoComplete
											name="email"
											dataSource={route("crm.users.search")}
											item={testMailData.email}
											onChange={value => genericHandler(value ? value.value : null, "email")}
											placeholder={uctrans("general.type_to_add_:item", {}, { item: "users.singular" })}
											async
											isClearable
										/>
									)}
									{testMailData.receiver_type === TestMailReceivers.CONTACTPERSON && (
										<SingleSelectAutoComplete
											name="email"
											dataSource={route("crm.contactpersons.search")}
											item={testMailData.email}
											onChange={value => genericHandler(value ? value.value : null, "email")}
											placeholder={uctrans("general.type_to_add_:item", {}, { item: "contactpersons.singular" })}
											async
											isClearable
										/>
									)}
								</div>
							)}
						</div>
						<div className="form-full">
							<label>{uctrans("communication.explanation")}</label>
							<TextEditor
								height={250}
								type="mail"
								value={testMailData.explanation}
								onChange={value => handleValueChange("explanation", value)}
							/>
						</div>
					</>
				</ConfirmDialog>
			)}
		</>
	);
}

NewsletterSideBarContent.propTypes = {
	newsletterStaticData: PropTypes.object.isRequired,
	newsletter: PropTypes.object.isRequired,
};
