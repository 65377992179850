import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { parse, format } from "../../../../../js/lib/Date";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import useSelectHandler from "../../../../../js/react/hooks/useSelectHandler";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../js/react/components/general/Translate";
import cloneDeep from "lodash/cloneDeep";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Select from "../../../../../js/react/components/general/Select";
import { route } from "../../../../../js/helpers";
import TabLinks from "../general/tabs/TabLinks";
import IntermediaryFormFields from "./IntermediaryFormFields";

export default function IntermediaryTakeonForm(props) {
	const [formData, setFormData] = useState(
		typeof props.companyFormData === "undefined" ? cloneDeep(window.data.company_form_data) : props.companyFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.companyStaticData === "undefined"
			? cloneDeep(window.data.company_static_data)
			: props.companyStaticData,
	);

	const selectHandler = useSelectHandler(setFormData);

	useEffect(() => {
		if (typeof props.companyFormData !== "undefined") {
			setFormData(props.companyFormData);
		}
	}, [props.companyFormData]);

	useEffect(() => {
		if (typeof props.companyStaticData !== "undefined") {
			setStaticData(props.companyStaticData);
		}
	}, [props.companyStaticData]);

	const onSuccess = () => {
		Nav.go(route("crm.agenda-items.index"));
	};

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	useEffect(() => {
		if (staticData && staticData.creating && window.data.user && window.data.user.id) {
			if (staticData.users.some(e => Number(e.value) === Number(window.data.user.id))) {
				handleFormValueChange("user_id", window.data.user.id.toString());
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{window.data.nav_data && (
				<>
					<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
					<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
				</>
			)}

			<BreadCrumbs
				breadCrumbs={[{ label: uctrans("agendaitems.accountsupport"), route: route("crm.agenda-items.index") }]}
			/>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(
						staticData.creating ? "general.add_:item" : "general.edit_:item",
						{},
						{ item: "agendaitems.appointment_request" },
					)}
				</h1>

				<a className="link" href={route("crm.agenda-items.index")}>
					{uctrans("general.back_to_:page", {}, { page: "agendaitems.accountsupport" })}
				</a>
			</div>
			<AjaxForm
				method={staticData.save_method}
				submitUrl={
					staticData.creating
						? route("crm.takeon-intermediary.store")
						: route("crm.agenda-items.update", { agenda_item: formData.id })
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "agendaitems.appointment_request" })}
				successText={uctrans("general.saved_:item", {}, { item: "agendaitems.appointment_request" })}
				data={{ ...formData }}
				onSuccess={onSuccess}
				useFlashMessage={staticData.creating}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-2">
									<label htmlFor="user_id">
										<Translate content="agendaitems.fields.requested_by" />
										<span className="text-orange"> *</span>
									</label>
									<Select
										placeholder={uctrans("general.select_:item", {}, { item: "agendaitems.fields.user" })}
										value={staticData.users.filter(({ value }) => Number(value) === Number(formData.user_id))}
										options={staticData.users}
										name="user_id"
										onChange={selectHandler}
									/>
								</div>
								<div className="form-1-4">
									<label htmlFor="agenda_date">
										<Translate content="agendaitems.fields.agenda_date" />
										<span className="text-orange"> *</span>
									</label>
									<DatePicker
										initialValue={formData.agenda_date ? parse(formData.agenda_date) : null}
										onChange={date => handleFormValueChange("agenda_date", date ? format(date) : null)}
									/>
								</div>
							</div>
							<IntermediaryFormFields
								onChange={data => handleFormValueChange("intermediary", data)}
								companyStaticData={staticData}
								companyFormData={formData.intermediary}
								fromTakeOn
							/>
							<div className="form-full">
								<div className="row">
									<div className="w-full md:w-1/2 lg:w-1/3 column">
										<SubmitBar item="agendaitems.appointment_request" addAnotherAvailable={false} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="flex-none w-1/3 border-l pl-12" />
				</div>
			</AjaxForm>
		</>
	);
}
IntermediaryTakeonForm.propTypes = {
	companyFormData: PropTypes.object,
	companyStaticData: PropTypes.object,
};
