import PropTypes from "prop-types";
import { useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import Select from "../../../../../js/react/components/general/Select";
import Translate from "../../../../../js/react/components/general/Translate";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import DependencyQuestionSelector from "./DependencyQuestionSelector";
import find from "lodash/find";
import cloneDeep from "lodash/cloneDeep";
import head from "lodash/head";

const DependencySelector = props => {
	const [hasDependency, setHasDependency] = useState(props.hasDependency);

	const setDependencyActive = selectedValue => {
		setHasDependency(selectedValue);
		if (!selectedValue) {
			props.handleSelectedQuestionsChange(null);
		} else {
			props.handleSelectedDependencyTypeChange(head(props.dependencyTypes).value);
		}
	};

	const updateDependencyAnswerOptions = (newSelectedAnswerOptions, dependencyQuestionIndex) => {
		const dependencyQuestions = cloneDeep(props.selectedQuestions);

		const dependencyQuestionToBeEdited = dependencyQuestions[dependencyQuestionIndex];
		if (dependencyQuestionToBeEdited) {
			dependencyQuestionToBeEdited.selected_answer_options = newSelectedAnswerOptions;
		}

		props.handleSelectedQuestionsChange(dependencyQuestions);
	};

	const updateSelectedDependencyQuestions = (
		updatedSelectedDependencyQuestion,
		updatedSelectedDependencyQuestionIndex,
	) => {
		const dependencyQuestions = cloneDeep(props.selectedQuestions);

		if (updatedSelectedDependencyQuestionIndex !== -1) {
			if (updatedSelectedDependencyQuestion === null) {
				dependencyQuestions.splice(updatedSelectedDependencyQuestionIndex, 1);
			} else {
				dependencyQuestions.splice(updatedSelectedDependencyQuestionIndex, 1, updatedSelectedDependencyQuestion);
			}
		}

		props.handleSelectedQuestionsChange(dependencyQuestions);
	};

	const addDependencyQuestion = () => {
		const currentSelectedQuestions = cloneDeep(props.selectedQuestions);

		if (props.selectableQuestions.length > 0) {
			currentSelectedQuestions.push(props.selectableQuestions[0]);
		}

		props.handleSelectedQuestionsChange(currentSelectedQuestions);
	};

	return (
		<div className="form-container mb-20">
			{props.selectableQuestions && props.selectableQuestions.length === 0 && (
				<div>
					<Translate content="questionnaires.dependency.no_questions_for_dependency" />
				</div>
			)}

			{props.selectableQuestions && props.selectableQuestions.length > 0 && (
				<div>
					<Translate content="questionnaires.dependency.description_text" />
					<RadioGroup
						name="dependency_active"
						value={hasDependency}
						onChange={selectedValue => setDependencyActive(selectedValue)}>
						<Radio label={uctrans("general.no")} value={0} />
						<Radio label={uctrans("general.yes")} value={1} />
					</RadioGroup>

					<div className="mt-6">
						{hasDependency !== 0 && (
							<div className="mb-2">
								<Select
									placeholder={uctrans("questionnaires.dependency.type")}
									value={find(props.dependencyTypes, ["value", props.dependencyType])}
									options={props.dependencyTypes}
									name="selected_dependency_type"
									isClearable={false}
									onChange={selectedOption => props.handleSelectedDependencyTypeChange(selectedOption.value)}
								/>
							</div>
						)}
					</div>

					<div className="mt-6">
						{hasDependency !== 0 && (
							<div>
								{props.selectedQuestions.map(({ ...selectedQuestion }, index) => (
									<DependencyQuestionSelector
										key={index}
										selectedQuestion={selectedQuestion}
										selectableQuestions={props.selectableQuestions}
										handleSelectedQuestionChange={updatedSelectedDependencyQuestion =>
											updateSelectedDependencyQuestions(updatedSelectedDependencyQuestion, index)
										}
										handleSelectedAnswerOptionChange={newSelectedAnswerOptions =>
											updateDependencyAnswerOptions(newSelectedAnswerOptions, index)
										}
									/>
								))}
								<a
									className="add-dependency-question-button button button-secondary"
									onClick={() => addDependencyQuestion()}>
									{uctrans("questionnaires.dependency.add_dependency_question")}
								</a>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

DependencySelector.propTypes = {
	selectableQuestions: PropTypes.array,
	hasDependency: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	selectedQuestions: PropTypes.array,
	dependencyTypes: PropTypes.array,
	dependencyType: PropTypes.number,
	handleSelectedQuestionsChange: PropTypes.func,
	handleSelectedDependencyTypeChange: PropTypes.func,
};

export default DependencySelector;
