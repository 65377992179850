import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";

export default function FormattedNumberText({
	value,
	percentage,
	prefix,
	decimalScale,
	thousandSeparator,
	decimalSeparator,
}) {
	if (percentage) {
		return (
			<NumericFormat displayType="text" value={value} decimalScale={decimalScale} suffix="%" decimalSeparator="," />
		);
	}

	return (
		<NumericFormat
			displayType="text"
			value={value}
			decimalSeparator={decimalSeparator}
			thousandSeparator={thousandSeparator}
			decimalScale={decimalScale}
			prefix={prefix}
		/>
	);
}

FormattedNumberText.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	thousandSeparator: PropTypes.string,
	decimalSeparator: PropTypes.string,
	decimalScale: PropTypes.number,
	percentage: PropTypes.bool,
};

FormattedNumberText.defaultProps = {
	decimalScale: 0,
	thousandSeparator: ".",
	decimalSeparator: ",",
};
