import { useEffect, useState } from "react";
import { route } from "../../../../../../js/helpers";
import Nav from "../../../../../../js/lib/Nav";
import { uctrans } from "../../../../../../js/lib/Translator";
import CommunicationPreferences from "../../../../../../js/react/components/communication/CommunicationPreferences";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import MultiSelectAutoComplete from "../../../../../../js/react/components/general/autocomplete/MultiSelectAutoComplete";
import InitialsTextInput from "../../../../../../js/react/components/general/form/InitialsTextInput";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import Radio from "../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../js/react/components/general/form/radio/RadioGroup";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";
import useInputHandler from "../../../../../../js/react/hooks/useInputHandler";
import UseRadioHandler from "../../../../../../js/react/hooks/useRadioHandler";
import CollapsibleContent from "../../general/CollapsibleContent";
import Navigation from "../SettingsNavigation";

export default function MailingListRecipientForm() {
	const [formData, setFormData] = useState({ ...window.data.form_data });
	const [staticData] = useState({ ...window.data.static_data });
	const [staticNewsLetterSubscriptionTypes, setStaticNewsLetterSubscriptionTypes] = useState(
		staticData.unsubscribe_preferences || [],
	);
	const creating = formData.id === -1;

	const inputHandler = useInputHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);
	const radioHandler = UseRadioHandler(setFormData);

	const onSuccess = () => {
		Nav.go(route("crm.communication-settings.mailing-list-recipient.index"));
	};

	useEffect(() => {
		const typeByCategory = staticData.unsubscribe_preferences ? [...staticData.unsubscribe_preferences] : [];
		const availableTypes = formData.newsletter_subscription_types || [];

		const newTypes = [];
		typeByCategory.forEach(category => {
			const types = (category.types || []).filter(type =>
				availableTypes.some(availableType => `${availableType.value}` === `${type.id}`),
			);

			if (types.length > 0) {
				newTypes.push({
					id: category.id,
					name: category.name,
					types,
				});
			}
		});

		setStaticNewsLetterSubscriptionTypes(newTypes);
	}, [staticData.unsubscribe_preferences, formData.newsletter_subscription_types]);

	return (
		<>
			<Navigation selectedTab="mailingListRecipient" />

			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(
						creating ? "general.add_:item" : "general.edit_:item",
						{},
						{ item: "communication.mailing_list_recipients.singular" },
					)}
				</h1>

				<a className="link" href={route("crm.communication-settings.mailing-list-recipient.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "communication.mailing_list_recipients.plural" })}
				</a>
			</div>
			<AjaxForm
				method={creating ? "POST" : "PUT"}
				submitUrl={
					creating
						? route("crm.communication-settings.mailing-list-recipient.store")
						: route("crm.communication-settings.mailing-list-recipient.update", formData.id)
				}
				loaderText={uctrans(
					"general.:item_is_being_saved",
					{},
					{ item: "communication.mailing_list_recipients.singular" },
				)}
				successText={uctrans("general.saved_:item", {}, { item: "communication.mailing_list_recipients.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<CollapsibleContent title={uctrans("communication.mailing_list_recipients.data")} initialCollapseState={false}>
					<div className="max-w-2xl">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-4">
									<label htmlFor="salutation">
										<Translate content="communication.mailing_list_recipients.fields.salutation" />
										<span className="text-orange"> *</span>
									</label>
									<RadioGroup
										name="salutation"
										value={formData.salutation}
										horizontal
										onChange={value => radioHandler(value, "salutation")}>
										<Radio label={staticData.salutation_types.MR.label} value={staticData.salutation_types.MR.key} />
										<Radio label={staticData.salutation_types.MRS.label} value={staticData.salutation_types.MRS.key} />
										<Radio
											label={staticData.salutation_types.DEPARTMENT.label}
											value={staticData.salutation_types.DEPARTMENT.key}
										/>
									</RadioGroup>
								</div>
							</div>
							<div className="form-full">
								<div className="form-1-4">
									<InitialsTextInput
										name="initials"
										value={formData.initials}
										onChange={inputHandler}
										label="communication.mailing_list_recipients.fields.initials"
									/>
								</div>

								<div className="form-1-4">
									<LabeledTextInput
										name="first_name"
										label="communication.mailing_list_recipients.fields.first_name"
										value={formData.first_name}
										onChange={inputHandler}
									/>
								</div>
								<div className="form-1-6">
									<LabeledTextInput
										name="insertion"
										label="communication.mailing_list_recipients.fields.insertion"
										value={formData.insertion}
										onChange={inputHandler}
									/>
								</div>
								<div className="form-1-3">
									<LabeledTextInput
										name="last_name"
										label="communication.mailing_list_recipients.fields.last_name"
										value={formData.last_name}
										onChange={inputHandler}
										required
									/>
								</div>
							</div>
							<div className="form-full">
								<div className="form-full">
									<LabeledTextInput
										name="email"
										label="communication.mailing_list_recipients.fields.email"
										value={formData.email}
										onChange={inputHandler}
										required
									/>
								</div>
							</div>
						</div>
					</div>
				</CollapsibleContent>

				<CollapsibleContent
					title={uctrans("communication.mailing_list_recipients.newsletter_subscription_types")}
					initialCollapseState={false}>
					<div className="max-w-2xl">
						<div className="form-container">
							<div className="form-full">
								<label htmlFor="categoryOptions">
									{uctrans("communication.mailing_list_recipients.fields.newsletter_subscription_types")}
								</label>
								<MultiSelectAutoComplete
									options={staticData.newsletter_subscription_types || []}
									name="newsletter_subscription_types"
									items={formData.newsletter_subscription_types || []}
									onChange={selectedCategoryOptions =>
										genericHandler(selectedCategoryOptions, "newsletter_subscription_types")
									}
									placeholder={uctrans("general.type_to_add")}
									showItemsOnFirstClick
								/>
							</div>
						</div>
					</div>
				</CollapsibleContent>

				{staticNewsLetterSubscriptionTypes && (
					<CollapsibleContent
						title={uctrans("communication.mailing_list_recipients.communication_preferences")}
						initialCollapseState={false}>
						<div className="form-full mb-4">
							<Translate content="communication.mailing_list_recipients.communication_preferences_description" />
						</div>

						<div className="form-full mt-4">
							<CommunicationPreferences
								communicationPreferenceData={formData.unsubscribe_preferences || []}
								onPreferenceChange={value => genericHandler(value, "unsubscribe_preferences")}
								staticNewsLetterSubscriptionTypes={staticNewsLetterSubscriptionTypes}
								unsubscribeReasons={staticData.unsubscribe_reasons || []}
								forCrm
							/>
						</div>
					</CollapsibleContent>
				)}

				<div className="form-full">
					<div className="w-full md:w-1/2 lg:w-1/3 column">
						<SubmitBar item="communication.mailing_list_recipients.singular" addAnotherAvailable={false} />
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
