import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import HighChart from "./HighChart";

const PieChart = props => {
	const [chartData, setChartData] = useState([]);

	useEffect(() => {
		setChartData(props.chartData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.chartData]);

	return chartData ? <HighChart chartConfig={chartData} /> : null;
};

PieChart.propTypes = {
	chartData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

PieChart.defaultProps = {
	hideTitle: false,
};

export default PieChart;
