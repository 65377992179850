import { useState } from "react";
import MultiSelectAutoComplete from "../../../../../../js/react/components/general/autocomplete/MultiSelectAutoComplete";
import FormDivider from "../../../../../../js/react/components/general/FormDivider";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import useGenericHandler from "../../../../../../js/react/hooks/useGenericHandler";
import useInputHandler from "../../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import Nav from "../../../../../../js/lib/Nav";
import { route } from "../../../../../../js/helpers";
import Navigation from "../Navigation";

export default function TeamGroupForm() {
	const [formData, setFormData] = useState({ ...window.data.team_group_values });
	const creating = window.data.team_group_values.id === -1;
	const inputHandler = useInputHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);

	const teamOptions = window.data.teamOptions ? [...window.data.teamOptions] : [];

	const onSuccess = () => {
		Nav.go(route("crm.team-groups.index"));
	};

	return (
		<>
			<Navigation selectedTab="team-groups" />

			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(creating ? "general.add_:item" : "general.edit_:item", {}, { item: "teams.team_groups.singular" })}
				</h1>

				<a className="link" href={route("crm.team-groups.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "teams.team_groups.plural" })}
				</a>
			</div>
			<AjaxForm
				method={creating ? "POST" : "PUT"}
				submitUrl={creating ? route("crm.team-groups.store") : route("crm.team-groups.update", formData.id)}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "teams.team_groups.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "teams.team_groups.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="name"
										value={formData.name}
										onChange={inputHandler}
										label="teams.team_groups.fields.name"
										required
									/>
								</div>
							</div>

							<FormDivider label={uctrans("teams.plural")} />

							<div className="form-1-3">
								<MultiSelectAutoComplete
									options={teamOptions}
									name="selected_teams"
									items={formData.selected_teams}
									onChange={selectedTeams => genericHandler(selectedTeams, "selected_teams")}
									placeholder={uctrans("general.type_to_add_:item", {}, { item: "teams.singular" })}
									showItemsOnFirstClick
								/>
							</div>

							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="teams.team_groups.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
