import { route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import DiscussRequestEditFormContent from "../../../../../js/react/components/discussRequests/DiscussRequestEditFormContent";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";

export default function DiscussRequestForm() {
	const formType = window.data.discuss_request_formtype;

	return formType === "create" ? (
		<div />
	) : (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{
						label: uctrans("discuss_requests.plural"),
						route: route("crm.discuss-requests.index"),
					},
					{ label: `${uctrans("discuss_requests.singular")} ${window.data.discuss_request_values.number}` },
				]}
			/>
			<DiscussRequestEditFormContent
				existingFileDownloadRoute="crm.discuss-requests.attachments.download"
				indexRoute="crm.discuss-requests.index"
				updateRoute="crm.discuss-requests.update"
			/>
		</>
	);
}
