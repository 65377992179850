import PropTypes from "prop-types";
import { useContext } from "react";
import MediaLibrary from "../../../media/library/MediaLibrary";
import MediaSelector from "../../../media/selector/MediaSelector";
import { CmsTypesContext } from "../../cms-types-context";

export default function Document(props) {
	const { documentOptions } = useContext(CmsTypesContext);

	const onchange = selectedDocumentOption => {
		if (selectedDocumentOption) {
			const existingOption = documentOptions.find(({ id }) => id === props.value);
			if (!existingOption) {
				documentOptions.push(selectedDocumentOption);
			}
		}
		props.onChange(selectedDocumentOption ? selectedDocumentOption.id : null);
	};
	const selectedDocumentOption = documentOptions.find(({ id }) => id === props.value);

	return (
		<>
			{props.label && <label>{props.label}</label>}
			<MediaSelector selected={selectedDocumentOption} type={MediaLibrary.TYPE_DOCUMENT} onSelect={onchange} />
		</>
	);
}

Document.propTypes = {
	label: PropTypes.string,
	value: PropTypes.number,
	onChange: PropTypes.func,
};
