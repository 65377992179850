import find from "lodash/find";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { route } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";
import { notify } from "../../../../../js/react/components/general/notifications";
import Spinner from "../../../../../js/react/components/general/Spinner";
import CollapsibleContent from "../general/CollapsibleContent";
import axios from "axios";

export default function AgendaItemCompanyFields({ initialCollapseState, statutory, companyId }) {
	const [loading, setLoading] = useState(false);
	const [companyData, setCompanyData] = useState(null);
	const [openAgendaItems, setOpenAgendaItems] = useState(null);
	const [agendaItemTypes, setAgendaItemTypes] = useState(null);

	const fetchData = useCallback(async () => {
		setLoading(true);

		try {
			const { data } = await axios.get(route("crm.companies.agenda-data", { companyId, statutory }));
			if (Object.keys(data).length > 0) {
				setLoading(false);
				setCompanyData(data.companyData);
				setOpenAgendaItems(data.openAgendaItems);
				setAgendaItemTypes(data.agendaItemTypes);
			} else {
				notify("error", uctrans("companies.messages.intermediary_data_not_found"), null);
				setLoading(false);
			}
		} catch (error) {
			console.error(error);
			notify("error", uctrans("companies.messages.intermediary_data_not_found"), null);
			setLoading(false);
		}
	}, [companyId, statutory]);

	useEffect(
		() => fetchData(),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const agendaItemType = itemType => {
		if (!agendaItemTypes) return null;

		const agendaItemType = find(agendaItemTypes, type => Number(type.value) === Number(itemType));

		if (typeof agendaItemType === "undefined") {
			return "unknown type";
		}

		return agendaItemType.label;
	};

	return (
		<>
			<CollapsibleContent title={uctrans("intermediaries.singular")} initialCollapseState={initialCollapseState}>
				{loading && <Spinner width={25} />}
				{!loading && companyData ? (
					<>
						<div className="form-full">
							<label>{uctrans("intermediaries.singular")}</label>
							<span>{companyData.label}</span>
						</div>
						<div className="form-full">
							<a className="link" href={companyData.administrative_link}>
								{uctrans(
									"general.go_to_:item",
									{},
									{ item: companyData.intermediary ? "intermediaries.singular" : "organisations.singular" },
								)}
							</a>
						</div>
						<div className="form-full">
							<label>{uctrans("organisations.singular")}</label>
							<span>{companyData.parent_label}</span>
						</div>
						<div className="form-full">
							<label>{uctrans("intermediaries.fields.classification")}</label>
							<span>{companyData.status_label}</span>
						</div>
						<div className="form-full">
							<label>{uctrans("intermediaries.fields.kvk_number")}</label>
							<span>{companyData.kvk_number}</span>
						</div>
						<div className="form-full">
							<label>{uctrans("intermediaries.fields.afm_number")}</label>
							<span>{companyData.afm_number}</span>
						</div>
						<div className="form-full">
							<label>{uctrans("intermediaries.fields.relationmanager")}</label>
							<span>{companyData.relation_manager_label}</span>
						</div>
					</>
				) : null}
			</CollapsibleContent>

			<CollapsibleContent initialCollapseState={false} title={uctrans("agendaitems.open_agenda_items_company")}>
				{loading && <Spinner width={25} />}
				{!loading && openAgendaItems ? (
					<div className="form-full">
						{openAgendaItems.map(agendaItem => (
							<div key={agendaItem.id}>
								<a
									href={
										window.data.agendaNamespace
											? route("crm.agenda.agenda-items.open", agendaItem)
											: route("crm.agenda-items.open", agendaItem)
									}
									className="row-title link">
									{agendaItem.agenda_date ? format(agendaItem.agenda_date, "dd-MM-y") : uctrans("general.no_date")}
								</a>{" "}
								- {agendaItemType(agendaItem.type)}
							</div>
						))}
					</div>
				) : null}
			</CollapsibleContent>
		</>
	);
}

AgendaItemCompanyFields.propTypes = {
	initialCollapseState: PropTypes.bool,
	companyId: PropTypes.number,
	statutory: PropTypes.bool,
};

AgendaItemCompanyFields.defaultProps = {
	initialCollapseState: false,
	statutory: false,
};
