import { useState, useMemo } from "react";
import { format } from "../../../../../js/lib/Date";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import { can, route } from "../../../../../js/helpers";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";
import FormattedNumberText from "../report/FormattedNumberText";
import Tippy from "@tippyjs/react";

export default function GiveawaysOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	const { intermediary } = window.data;

	const indexRoute = useMemo(
		() =>
			intermediary
				? route("crm.intermediaries.giveaways.index", window.data.company_id)
				: route("crm.organisations.giveaways.index", window.data.company_id),
		[intermediary],
	);

	const createRoute = useMemo(
		() =>
			intermediary
				? route("crm.intermediaries.giveaways.create", window.data.company_id)
				: route("crm.organisations.giveaways.create", window.data.company_id),
		[intermediary],
	);

	const editRoute = giveawayId =>
		intermediary
			? route("crm.intermediaries.giveaways.edit", { giveaway: giveawayId, intermediary: window.data.company_id })
			: route("crm.organisations.giveaways.edit", { giveaway: giveawayId, organisation: window.data.company_id });

	const deleteRoute = giveawayId =>
		intermediary
			? route("crm.intermediaries.giveaways.delete", { giveaway: giveawayId, intermediary: window.data.company_id })
			: route("crm.organisations.giveaways.delete", { giveaway: giveawayId, organisation: window.data.company_id });

	return (
		<>
			{window.data.nav_data && (
				<>
					<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
					<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
				</>
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.company_name
						? uctrans("general.:item1 from :item2", { item2: window.data.company_name }, { item1: "giveaways.plural" })
						: uctrans("giveaways.plural")}
				</h1>
				{can("giveaway", "create") && (
					<div>
						<IconButton
							href={createRoute}
							content={uctrans("general.add_:item", {}, { item: "giveaways.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={indexRoute}
				modelTranslations={{ plural: trans("giveaways.plural"), singular: trans("giveaways.singular") }}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={6}
							renderHeaderContent={() => uctrans("giveaways.fields.name")}
							sortable="name"
							renderCellContent={giveaway => {
								if (can("giveaway", "update")) {
									return (
										<a href={editRoute(giveaway.id)} className="row-title link">
											{giveaway.name}
										</a>
									);
								} else {
									return giveaway.name;
								}
							}}
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => uctrans("giveaways.fields.costs")}
							sortable="costs"
							renderCellContent={giveaway => (
								<FormattedNumberText
									prefix="€ "
									value={giveaway.costs ? giveaway.costs : 0}
									decimalSeparator="."
									thousandSeparator=","
									decimalScale={2}
								/>
							)}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("giveaways.fields.by_user")}
							sortable="user"
							renderCellContent={giveaway => (giveaway.by_user ? giveaway.by_user : "-")}
						/>
						<DataColumn
							width={6}
							renderHeaderContent={() => uctrans("contactpersons.singular")}
							sortable="contactperson"
							renderCellContent={giveaway =>
								giveaway.has_photo ? (
									<div className="flex justify-start">
										<div className="avatar-img mr-2">
											<img
												className="max-h-full self-center"
												src={route("crm.contactpersons.photo-thumbnail", giveaway.contactperson.id)}
												alt={giveaway.contactperson.label}
											/>
										</div>
										{giveaway.contactperson.label}
									</div>
								) : (
									<div className="flex justify-start">
										<div className="avatar-img mr-2">
											<EvaIcon type="person" fill="#249286" height="35" width="35" />
										</div>
										{giveaway.contactperson.label}
									</div>
								)
							}
						/>
						<DataColumn
							width={3}
							sortable="date"
							renderHeaderContent={() => uctrans("giveaways.fields.date")}
							renderCellContent={giveaway => (giveaway.date ? format(giveaway.date, "dd-MM-y") : "-")}
						/>
						<DataColumn
							width={1}
							renderHeaderContent={() => ""}
							renderCellContent={giveaway =>
								giveaway.override ? (
									<Tippy
										placement="top"
										arrow
										animation="perspective"
										duration="400"
										content={uctrans("giveaways.fields.this_giveaway_triggered_override")}>
										<span className="text-lg" style={{ color: "red" }}>
											€
										</span>
									</Tippy>
								) : (
									""
								)
							}
						/>
						<DataColumn
							width={2}
							renderHeaderContent={() => ""}
							renderCellContent={(giveaway, overview) => (
								<div className="overview-actions">
									{giveaway.contactmoment ? (
										<a className="mr-3" href={route("crm.contactmoments.edit", giveaway.contactmoment.id)}>
											<Tippy
												placement="top"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("giveaways.fields.go_to_contactmoment")}>
												<span>
													<EvaIcon type="message-circle-outline" fill="#009286" height="20" width="20" />
												</span>
											</Tippy>
										</a>
									) : null}
									{can("giveaway", "delete") ? (
										<a
											onClick={() => {
												overview.askDelete(giveaway, deleteRoute(giveaway.id));
											}}>
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("general.delete")}>
												<span>
													<EvaIcon type="trash-2-outline" fill="#f9acaa" height="20" width="20" />
												</span>
											</Tippy>
										</a>
									) : null}
								</div>
							)}
						/>
					</DataTable>
				)}
			/>
		</>
	);
}
