import axios from "axios";
import PropTypes from "prop-types";
import { useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import ConfirmDialog from "../../../../../js/react/components/general/ConfirmDialog";
import { route } from "../../../../../js/helpers";
import { createLoader } from "../../../../../js/react/components/general/notifications";

export default function BounceEmailsBlock(props) {
	const [isCollapsed, setCollapsed] = useState(window.data.collapsed_dashboard_blocks.bounce_emails_block === "1");

	const [confirmingResolveBounces, setConfirmingResolveBounces] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleResolveBounces = () => {
		if (loading) {
			return;
		}
		setLoading(true);
		const loader = createLoader(uctrans("dashboard.bounces_being_resolved"));
		axios
			.post(route("crm.dashboard.resolve-bounced-emails"))
			.then(() => {
				loader.success(uctrans("dashboard.bounces_resolved"));
			})
			.catch(error => {
				console.warn(error);
				loader.failure(uctrans("general.something_went_wrong"));
			})
			.then(() => {
				setLoading(false);
				setConfirmingResolveBounces(false);
			});

		return true;
	};

	return (
		<div className=" vertical-spacer spacer flex">
			<div className="bg-grey-lightest shadow rounded flex flex-col w-full">
				<div className="p-6 flex">
					<a
						className="no-underline text-grey-darker w-full cursor"
						onClick={() => {
							const collapsed = !isCollapsed;
							if (props.rememberCollapsed) {
								props.rememberCollapsed("bounce_emails_block", collapsed);
							}
							setCollapsed(collapsed);
						}}>
						<div className="w-full flex justify-between">
							<div>
								<h5 className="mb-0">{uctrans("dashboard.bounce_email_export")}</h5>
							</div>
							<div>
								<EvaIcon fill="#000000" width="18" height="18" type={isCollapsed ? "plus" : "minus"} />
							</div>
						</div>
					</a>
				</div>
				{!isCollapsed && (
					<div className="px-6 pb-6 flex justify-between ">
						<a className="button button-primary" href={route("crm.dashboard.bounce-emailaddress-export")}>
							{uctrans("dashboard.bounce_email_export_button")}
						</a>
						<a className="button button-primary" onClick={() => setConfirmingResolveBounces(true)}>
							{uctrans("dashboard.bounce_email_export_clean")}
						</a>
					</div>
				)}
				<ConfirmDialog
					isOpen={confirmingResolveBounces}
					onConfirm={() => {
						handleResolveBounces();
					}}
					onCancel={() => {
						setConfirmingResolveBounces(false);
					}}
					confirmButtonLabel={uctrans("general.ok")}
					cancelButtonLabel={uctrans("general.cancel")}
					title={uctrans("dashboard.bounce_email_export_clean")}>
					{uctrans("dashboard.bounce_removal_warning")}
				</ConfirmDialog>
			</div>
		</div>
	);
}

BounceEmailsBlock.propTypes = {
	rememberCollapsed: PropTypes.func,
};
