import { useState } from "react";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import Select from "../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import Nav from "../../../../../js/lib/Nav";
import { route } from "../../../../../js/helpers";
import useSelectHandler from "../../../../../js/react/hooks/useSelectHandler";

export default function SoftwarePackageForm() {
	const [formData, setFormData] = useState({ ...window.data.software_packages_values });
	const creating = window.data.software_packages_values.id === -1;
	const inputHandler = useInputHandler(setFormData);
	const selectHandler = useSelectHandler(setFormData);

	const onSuccess = () => {
		Nav.go(route("crm.software-packages.index"));
	};

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("software_packages.singular")}</h1>

				<a className="link" href={route("crm.software-packages.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "software_packages.plural" })}
				</a>
			</div>
			<AjaxForm
				method={creating ? "POST" : "PUT"}
				submitUrl={creating ? route("crm.software-packages.store") : route("crm.software-packages.update", formData.id)}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "software_packages.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "software_packages.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="name"
										value={formData.name}
										onChange={inputHandler}
										label="software_packages.fields.name"
										required
									/>
								</div>
							</div>
							<div className="form-1-3">
								<label htmlFor="color_code">
									{uctrans("identificationverification.fields.color_code")}
									<span className="text-orange">*</span>
								</label>
								<Select
									placeholder={uctrans("general.select_:item", {}, { item: "software_packages.fields.color_code" })}
									value={window.data.color_codes.filter(({ value }) => Number(value) === Number(formData.color_code))}
									options={window.data.color_codes}
									name="color_code"
									onChange={(selectedValue, metaData) => selectHandler(selectedValue, metaData)}
									isClearable={false}
									required
								/>
							</div>
							<div className="form-full">
								<Checkmark
									onChange={inputHandler}
									name="is_open"
									checked={formData.is_open}
									label={uctrans("software_packages.fields.is_open")}
								/>
							</div>
							{formData.is_open ? (
								<div className="form-full">
									<div className="form-1-3">
										<LabeledTextInput
											name="open_title"
											value={formData.open_title}
											onChange={inputHandler}
											label="software_packages.fields.open_title"
											required
										/>
									</div>
								</div>
							) : null}
							<div className="form-full">
								<Checkmark
									onChange={inputHandler}
									name="is_active"
									checked={formData.is_active}
									label={uctrans("software_packages.fields.is_active")}
								/>
							</div>
							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="software_packages.singular" addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
