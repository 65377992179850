import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import { useState, useEffect } from "react";
import { route } from "../../../../../../js/helpers";
import Nav from "../../../../../../js/lib/Nav";
import { uctrans } from "../../../../../../js/lib/Translator";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import { error } from "../../../../../../js/react/components/general/notifications";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import MarketingNewsletterUnsubscribeReasonReportFilters from "./components/MarketingNewsletterUnsubscribeReasonReportFilters";
import MarketingThemesReportFilters from "./components/MarketingThemesReportFilters";
import NewsletterOptinOutReport from "./components/NewsletterOptinOutReport";
import MarketingThemesReport from "./components/MarketingThemesReport";
import MarketingNewsletterUnsubscribeReasonReport from "./components/MarketingNewsletterUnsubscribeReasonReport";
import NewsletterOptinOutReportFilters from "./components/NewsletterOptinOutReportFilters";

export default function MarketingReport() {
	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);
	const formInfo = window.data.formInfo ? cloneDeep(window.data.formInfo) : [];
	const [loading, setLoading] = useState(false);
	const [reportData, setReportData] = useState(null);
	const [csvLoading, setCsvLoading] = useState(false);

	const generateCsv = async () => {
		if (formValues && formValues.report_type) {
			switch (formValues.report_type) {
				case "newsletter_optin":
				case "newsletter_unsubscribe_reason":
					if (formValues.newsletterUnsubscribeReasonType === null) {
						return error(uctrans("report.marketing.newsletter.you_must_choose_an_export_type"));
					}
					break;
				default:
					break;
			}
		}

		setCsvLoading(true);
		try {
			const result = await axios.post(route("crm.report.marketing.generate-csv"), { ...formValues });
			if (result && result.data.success) {
				Nav.go(
					route("crm.report.marketing.download-csv", {
						download_key: result.data.download_key,
					}),
				);
			}
		} catch (error) {
			console.error(error);
		}
		setCsvLoading(false);
	};

	const fetch = async () => {
		setLoading(true);
		try {
			const { data } = await axios.post(route(`crm.report.marketing.data`, { ...formValues }));
			if (data) {
				setReportData(data);
			}
		} catch (error) {
			console.error(error);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setReportData(null);
	}, [formValues.report_type, formValues.newsletterUnsubscribeReasonType]);

	const getReport = () => {
		if (reportData) {
			switch (formValues.report_type) {
				case "themes":
					return <MarketingThemesReport reportData={reportData} />;
				case "newsletter_optin":
					return <NewsletterOptinOutReport reportData={reportData} />;
				case "newsletter_unsubscribe_reason":
					return <MarketingNewsletterUnsubscribeReasonReport reportData={reportData} formValues={formValues} />;
				default:
					break;
			}
		}
		return null;
	};

	const getExplanation = () => {
		switch (formValues.report_type) {
			case "themes":
				return <p>{uctrans("report.marketing.scoring.explanation")}</p>;
			case "newsletter_optin":
				return <p>{uctrans("report.marketing.newsletter.explanation")}</p>;
			case "newsletter_unsubscribe_reason":
				return <p>{uctrans("report.marketing.unsubscribe_reason.explanation")}</p>;
			default:
				return null;
		}
	};

	const getReportTypeFilter = () => {
		if (formValues && formInfo) {
			switch (formValues.report_type) {
				case "themes":
					return (
						<MarketingThemesReportFilters formValues={formValues} setFormValues={setFormValues} formInfo={formInfo} />
					);
				case "newsletter_optin":
					return (
						<NewsletterOptinOutReportFilters
							formValues={formValues}
							setFormValues={setFormValues}
							formInfo={formInfo}
						/>
					);
				case "newsletter_unsubscribe_reason":
					return (
						<MarketingNewsletterUnsubscribeReasonReportFilters
							formValues={formValues}
							setFormValues={setFormValues}
							formInfo={formInfo}
						/>
					);
				default:
					break;
			}
		}
		return null;
	};

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans("report.report")} - {uctrans("report.marketing.title")}
				</h1>
			</div>
			<div className="flex w-full justify-between">
				<div className="w-1/2 pr-2">{getExplanation()}</div>
			</div>
			<br />
			{getReportTypeFilter()}
			<br />
			<div className="flex w-full justify-start">
				<div className="pr-2">
					<IconButton
						onClick={() => fetch()}
						icon={null}
						disabled={loading}
						content={uctrans("report.show_count")}
						primary
					/>
				</div>
				<div className="pr-2">
					<IconButton
						icon="file-text-outline"
						onClick={() => generateCsv()}
						disabled={csvLoading}
						content={uctrans("report.generate_csv")}
						primary
					/>
				</div>
			</div>

			{loading ? (
				<div className="flex justify-center">
					<Spinner width={50} />
				</div>
			) : (
				getReport()
			)}
		</>
	);
}
