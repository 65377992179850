import Tippy from "@tippyjs/react";
import { useState } from "react";
import { route } from "../../../../../js/helpers";
import { format, parse, default_date_format } from "../../../../../js/lib/Date";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import AutosizeTextarea from "../../../../../js/react/components/general/form/AutosizeTextarea";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { uctrans, trans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Translate from "../../../../../js/react/components/general/Translate";
import useGenericHandler from "../../../../../js/react/hooks/useGenericHandler";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import useRadioHandler from "../../../../../js/react/hooks/useRadioHandler";
import FileInput from "../forms/FileInput";

export default function ClientConsentEditForm() {
	const [formData, setFormData] = useState({ ...window.data.client_consent.form_data });
	const [staticData, setStaticData] = useState({ ...window.data.client_consent.static_data });

	const minCSDate = window.data.client_consent && new Date(window.data.client_consent.static_data.min_date);

	const radioHandler = useRadioHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);
	const inputHandler = useInputHandler(setFormData);

	const onSuccess = response => {
		setFormData(response.data.form_data);
		setStaticData(response.data.static_data);
	};

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					{!window.data.read_only
						? uctrans("client_consents.edit_revoke_:item", {
								item: trans("client_consents.singular"),
						  })
						: uctrans("client_consents.singular")}
				</h1>

				<a className="link" href={route("crm.client-consents.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "client_consents.plural" })}
				</a>
			</div>
			<AjaxForm
				method="PUT"
				submitUrl={route("crm.client-consents.update", staticData.consent.id)}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "client_consents.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "client_consents.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage={false}>
				<div className="form-container">
					<div className="form-container">
						<div className="form-1-2">
							<ul>
								<li className="flex">
									<span className="w-1/2  font-bold">{uctrans("companies.singular")}</span>
									<span className="w-1/2 ">
										{staticData.consent.company
											? `${staticData.consent.company.name} (${staticData.consent.company.company_number})`
											: "-"}
									</span>
								</li>
								<li className="flex">
									<span className="w-1/2  font-bold">{uctrans("client_consents.fields.client_name")}</span>
									<span className="w-1/2 ">
										{staticData.consent.name_first_applicant
											? staticData.consent.name_second_applicant
												? `${staticData.consent.name_first_applicant} & ${staticData.consent.name_second_applicant}`
												: staticData.consent.name_first_applicant
											: "-"}
									</span>
								</li>
								<li className="flex">
									<span className="w-1/2  font-bold">{uctrans("client_consents.fields.loan_number")}</span>
									<span className="w-1/2 ">
										{staticData.consent.loan_number ? staticData.consent.loan_number : "-"}
									</span>
								</li>
								<li className="flex">
									<span className="w-1/2  font-bold">{uctrans("client_consents.fields.starts_at")}</span>
									<span className="w-1/2 ">
										{staticData.consent.starts_at ? format(staticData.consent.starts_at, "dd-MM-y") : "-"}
									</span>
								</li>
								<li className="flex">
									<span className="w-1/2  font-bold">{uctrans("client_consents.fields.expires_at")}</span>
									<span className="w-1/2 ">
										{staticData.consent.expires_at ? format(staticData.consent.expires_at, "dd-MM-y") : "-"}
									</span>
								</li>
								<li className="flex">
									<span className="w-1/2  font-bold">{uctrans("client_consents.fields.status")}</span>
									<span className="w-1/2 ">
										{staticData.consent.view_status_object ? staticData.consent.view_status_object.label : "-"}
									</span>
								</li>
								{!!staticData.consent.has_declaration && (
									<li className="mt-4">
										<a className="link" href={route("crm.client-consents.download-decleration", staticData.consent.id)}>
											{uctrans("client_consents.download_declaration")}
										</a>
									</li>
								)}
								<li className="flex">
									{staticData.consent.active ? (
										<span className="font-bold text-green">
											<br />
											{uctrans("client_consents.consent_is_active")}
										</span>
									) : (
										<span className="font-bold text-red">
											<br />
											{uctrans("client_consents.consent_is_not_active")}
										</span>
									)}
								</li>
							</ul>
						</div>
					</div>
				</div>

				<h3>{uctrans("client_consents.log")}</h3>
				<div className="form-container">
					<div className="form-1-2">
						{staticData.consent.logs.map(log => (
							<div key={`log_${log.id}`} className="form-container ">
								<label>
									{log.datetime
										? uctrans("general.on_:date_at_:time_by_:who", {
												date: format(log.datetime, "dd-MM-y"),
												time: format(log.datetime, "HH:mm"),
												who:
													log.actor && typeof log.actor.label !== "undefined" && log.actor.type !== "unknown"
														? `${trans("general.by")} ${log.actor.label}`
														: "",
										  })
										: "-"}
									{log.actor && typeof log.actor.email !== "undefined" && (
										<span className="font-normal ml-4">
											<a className="link" href={`mailto:${log.actor.email}`}>
												{log.actor.email}
											</a>
										</span>
									)}
								</label>
								<p>
									{log.type
										? `${log.type.label}${log.sampled ? ` (${uctrans("client_consents.fields.sampled")})` : ""}${
												(log.type.key === staticData.log_types.REVOKED.key ||
													log.type.key === staticData.log_types.REJECTED.key ||
													log.type.key === staticData.log_types.AUTO_DEACTIVATED.key) &&
												log.revoke_reason
													? `: ${log.revoke_reason.label}`
													: ""
										  }${
												log.type.key === staticData.log_types.DATES_CHANGED.key &&
												log.starts_at !== "" &&
												log.expires_at !== ""
													? `: ${format(log.starts_at, "dd-MM-y")} - ${format(log.expires_at, "dd-MM-y")}`
													: ""
										  }`
										: "-"}
								</p>
								<p>
									{log.type &&
										(log.type.key === staticData.log_types.REVOKED.key ||
											log.type.key === staticData.log_types.REJECTED.key) &&
										log.revoke_explanation && (
											<span className="italic">
												{uctrans("client_consents.revoke_explanation")}: {`${log.revoke_explanation}`}
											</span>
										)}
								</p>
							</div>
						))}
					</div>
				</div>
				{!window.data.read_only && (
					<>
						<h3>{uctrans("client_consents.actions")}</h3>

						{staticData.consent.sample_status === staticData.sample_statuses.REQUIRED.key && (
							<span className="font-bold text-blue">{uctrans("client_consents.consent_needs_sample")}</span>
						)}

						<div className="form-container">
							<div className="form-1-2">
								<RadioGroup name="action" value={formData.action} onChange={value => radioHandler(value, "action")}>
									<Radio label={staticData.actions.NO_ACTION.label} value={staticData.actions.NO_ACTION.key} />
									{staticData.consent.status === staticData.statuses.NEEDS_AS_VERIFICATION.key && (
										<Radio label={staticData.actions.ACTIVATE.label} value={staticData.actions.ACTIVATE.key} />
									)}
									{staticData.consent.status === staticData.statuses.NEEDS_AS_VERIFICATION.key && (
										<Radio label={staticData.actions.REJECT.label} value={staticData.actions.REJECT.key} />
									)}
									{staticData.consent.status === staticData.statuses.ACTIVATED.key && (
										<Radio label={staticData.actions.CHANGE_DATES.label} value={staticData.actions.CHANGE_DATES.key} />
									)}
									{staticData.consent.status === staticData.statuses.ACTIVATED.key && (
										<Radio label={staticData.actions.REVOKE.label} value={staticData.actions.REVOKE.key} />
									)}
									{staticData.consent.status === staticData.statuses.REVOKED.key && (
										<Radio label={staticData.actions.REACTIVATE.label} value={staticData.actions.REACTIVATE.key} />
									)}
								</RadioGroup>
							</div>
						</div>

						{(formData.action === staticData.actions.REVOKE.key ||
							formData.action === staticData.actions.REJECT.key) && (
							<>
								<div className="form-container">
									<div className="form-1-2">
										<label htmlFor="revoke_reason">
											<Translate content="client_consents.revoke_reject_reason" />{" "}
											<span className="text-orange">*</span>
										</label>
										<RadioGroup
											name="revoke_reason"
											value={formData.revoke_reason}
											onChange={value => radioHandler(value, "revoke_reason")}>
											{staticData.revoke_reasons.map(revoke_reason => (
												<Radio key={revoke_reason.key} label={revoke_reason.label} value={revoke_reason.key} />
											))}
										</RadioGroup>
									</div>
								</div>
								<div className="form-container">
									<div className="form-1-2">
										<label htmlFor="revoke_explanation">
											<Translate content="client_consents.revoke_explanation" />
										</label>
										<AutosizeTextarea
											value={formData.revoke_explanation}
											onChange={inputHandler}
											name="revoke_explanation"
										/>
									</div>
								</div>
							</>
						)}

						{formData.action === staticData.actions.REACTIVATE.key && (
							<>
								<div className="form-container mb-3">
									<div className="form-1-2">
										<label htmlFor="declaration">{uctrans("client_consents.fields.declaration")}</label>
										<FileInput maxFiles={1} onChange={files => genericHandler(files, "declaration")} />
									</div>
								</div>
								<div className="form-container mb-3">
									<div className="form-1-3">
										<label htmlFor="starts_at">
											{uctrans("client_consents.fields.start_date_consent")}
											<span className="text-orange"> *</span>
											<Tippy
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("client_consents.start_date_content_explain")}>
												<span className="ml-2">
													<EvaIcon type="info" fill="#249286" height="19" width="19" />
												</span>
											</Tippy>
										</label>
										<DatePicker
											initialValue={formData.starts_at ? parse(formData.starts_at, default_date_format) : null}
											onChange={date => genericHandler(date ? format(date, default_date_format) : null, "starts_at")}
											maxDate={new Date()}
										/>
									</div>
								</div>
							</>
						)}

						{formData.action === staticData.actions.CHANGE_DATES.key && (
							<div className="form-container">
								<div className="form-1-3">
									<label htmlFor="starts_at">
										<Translate content="client_consents.fields.starts_at" /> <span className="text-orange">*</span>
									</label>
									<DatePicker
										initialValue={formData.starts_at ? parse(formData.starts_at, default_date_format) : null}
										onChange={date => genericHandler(date ? format(date, default_date_format) : null, "starts_at")}
										minDate={minCSDate}
									/>
								</div>

								<div className="form-1-3">
									<label htmlFor="starts_at">
										<Translate content="client_consents.fields.expires_at" /> <span className="text-orange">*</span>
									</label>
									<DatePicker
										initialValue={formData.expires_at ? parse(formData.expires_at, default_date_format) : null}
										onChange={date => genericHandler(date ? format(date, default_date_format) : null, "expires_at")}
										minDate={minCSDate}
									/>
								</div>
							</div>
						)}

						{formData.action > 0 && (
							<>
								{staticData.consent.sample_status === staticData.sample_statuses.REQUIRED.key && (
									<div className="form-1-4">
										<Checkmark
											name="sampled"
											label={uctrans("client_consents.fields.sampled")}
											onChange={inputHandler}
											checked={!!formData.sampled}
											required
										/>
									</div>
								)}
								<div className="form-full">
									<div className="row">
										<div className="w-full md:w-1/2 lg:w-1/3 column">
											<SubmitBar translateLabel item="client_consents.singular" addAnotherAvailable={false} />
										</div>
									</div>
								</div>
							</>
						)}
					</>
				)}
			</AjaxForm>
		</>
	);
}
