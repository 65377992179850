import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import HighChart from "../../report/graph/HighChart";

export default function RelationManagerSurveyHorizontalBarChart(props) {
	const [series, setSeries] = useState([]);
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		if ((props.currentData || props.currentData.length > 0) && (props.totalData || props.totalData.length > 0)) {
			const newArrayCurrentData = [];
			const newArrayTotalData = [];
			const newCategories = [];

			if (props.currentData) {
				props.currentData.forEach(({ value, label }) => {
					newArrayCurrentData.push({
						y: value,
						name: label,
					});

					if (!newCategories.includes(label)) {
						newCategories.push(label);
					}
				});
			}

			if (props.totalData) {
				props.totalData.forEach(({ value, label }) => {
					newArrayTotalData.push({
						y: value,
						name: label,
					});

					if (!newCategories.includes(label)) {
						newCategories.push(label);
					}
				});
			}

			const newSeriesData = [{ name: props.currentLabel, data: newArrayCurrentData }];
			newSeriesData.push({ name: props.totalLabel, data: newArrayTotalData });
			setSeries(newSeriesData);
			setCategories(newCategories);
		} else {
			setCategories([]);
			setSeries([]);
		}
	}, [props.totalData, props.calculatePercentages, props.currentData, props.totalLabel, props.currentLabel]);

	return (
		<HighChart
			chartConfig={{
				chart: {
					type: "bar",
					backgroundColor: null,
					height: `${props.height}px`,
					spacingLeft: 0,
					marginTop: 30,
				},
				title: {
					text: null,
				},
				xAxis: {
					categories,
					title: {
						text: null,
					},
					gridLineWidth: 0,
					labels: {
						align: "left",
						reserveSpace: true,
						enabled: true,
						style: {
							color: "#25282E",
							fontSize: "12px",
						},
					},
					minPadding: 0,
					maxPadding: 0,
				},
				yAxis: {
					title: {
						text: null,
					},
					gridLineWidth: 0,
					labels: {
						enabled: true,
						step: 2,
					},
					min: props.minXValue,
					max: props.maxXValue,
				},
				legend: {
					enabled: series.length > 1,
					align: "center",
					verticalAlign: "top",
					floating: true,
					itemStyle: {
						fontSize: "12px",
						fontWeight: "normal",
						color: "#31353e",
					},
					padding: 2,
					margin: 10,
				},
				series,
				plotOptions: {
					series: {
						pointWidth: 10,
						groupPadding: 0.2,
					},
					bar: {
						groupPadding: 0.1,
						dataLabels: {
							enabled: true,
							format: `{point.y:.1f}${props.calculatePercentages ? "%" : ""}`,
							color: "#31353e",
							style: { textOutline: "none" },
							overflow: "allow",
							crop: false,
							allowOverlap: true,
						},
					},
				},
				tooltip: {
					pointFormat: "{point.val:,.1f}",
					enabled: true,
				},
				colors: ["#2B928C", "#C1E0DE"],
			}}
		/>
	);
}

RelationManagerSurveyHorizontalBarChart.propTypes = {
	currentData: PropTypes.array,
	currentLabel: PropTypes.string,
	totalData: PropTypes.array,
	totalLabel: PropTypes.string,
	height: PropTypes.number,
	calculatePercentages: PropTypes.bool,
	minXValue: PropTypes.number,
	maxXValue: PropTypes.number,
};

RelationManagerSurveyHorizontalBarChart.defaultProps = {
	calculatePercentages: false,
	height: 200,
	minXValue: 0,
	maxXValue: 101,
};
