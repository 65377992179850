import { useContext } from "react";
import { numberFormat, route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import RmDashboardBlock from "./RmDashboardBlock";
import Tippy from "@tippyjs/react";
import { RmDashboardContext } from "./RmDashboardContext";

export default function IntermediariesToSpeakBlock() {
	const { wallet } = useContext(RmDashboardContext);

	return (
		<RmDashboardBlock
			defaultCollapsed
			useFetching
			useFetchingWhenCollapsed
			blockKey="intermediaries-to-speak"
			renderHeader={blockData => (
				<h5 className="mb-0">
					{uctrans("dashboard.intermediaries_to_speak")}{" "}
					{blockData && blockData.intermediaryCount ? ` (${numberFormat(blockData.intermediaryCount)})` : ""}
				</h5>
			)}
			renderContent={({ conversations }) =>
				wallet.type === "no_relationmanager" ? (
					uctrans("dashboard.no_rm_dashboard")
				) : (
					<>
						<ul>
							<li className="flex font-bold mb-3">
								<div className="w-2/12">{uctrans("dashboard.rm")}</div>
								<div className="w-3/12">{uctrans("dashboard.intermediary")}</div>
								<div className="w-2/12">{uctrans("dashboard.conversations_spoken")}</div>
								<div className="w-2/12">{uctrans("dashboard.conversations_expected")}</div>
								<div className="w-2/12">{uctrans("dashboard.last_contact")}</div>
								<div className="w-1/12" />
							</li>
							{conversations &&
								conversations.map(conversationsData => (
									<li className="flex mb-3" key={conversationsData.id}>
										<div className="w-2/12">{conversationsData.relationmanager}</div>
										<div className="w-3/12">
											<a
												href={route("crm.intermediaries.edit-administrative", conversationsData.id)}
												className="row-title link">
												{conversationsData.name}
											</a>
										</div>
										<div className="w-2/12">{`${
											conversationsData.nr_of_conversations ? conversationsData.nr_of_conversations : 0
										} / ${conversationsData.conversation_target ? conversationsData.conversation_target : 0}`}</div>
										<div className="w-2/12">{`${
											conversationsData.conversations_expected ? conversationsData.conversations_expected : 0
										} / ${conversationsData.conversation_target ? conversationsData.conversation_target : 0}`}</div>
										<div className="w-2/12">{conversationsData.last_contact}</div>
										<div className="w-1/12">
											<a
												href={route("crm.intermediaries.contactmoments.create", {
													intermediary: conversationsData.id,
												})}>
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("intermediaries.fields.contact")}>
													<span>
														<EvaIcon type="plus-outline" height="18" width="18" />
													</span>
												</Tippy>
											</a>
										</div>
									</li>
								))}
						</ul>
						<div className="flex justify-end">
							<a href={route("crm.conversations.index")} className="row-title link">
								{uctrans("dashboard.go_to_conversations_overview")}
							</a>
						</div>
					</>
				)
			}
		/>
	);
}
