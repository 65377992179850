import { useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import Nav from "../../../../../js/lib/Nav";
import { route } from "../../../../../js/helpers";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";

export default function ClientTreatmentBudgetYearForm() {
	const [formData, setFormData] = useState({ ...window.data.form_data });
	const [staticData, setStaticData] = useState({ ...window.data.static_data });
	const readOnly = window.data.read_only;

	const inputHandler = useInputHandler(setFormData);

	const onSuccess = response => {
		if (staticData.creating) {
			Nav.go(route("crm.client-treatment-budget-years.edit", { client_treatment_budget_year: response.data.id }));
		} else {
			setFormData(response.data.form_data);
			setStaticData(response.data.static_data);
		}
	};

	return (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{
						label: uctrans("client_treatment.budget_years.plural"),
						route: route("crm.client-treatment-budget-years.index"),
					},
				]}
			/>

			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(staticData.creating ? "general.add_:item" : "general.edit_:item", {
						item: uctrans("client_treatment.budget_years.singular"),
					})}
				</h1>

				<a className="link" href={route("crm.client-treatment-budget-years.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "client_treatment.budget_years.plural" })}
				</a>
			</div>
			<AjaxForm
				method={staticData.creating ? "POST" : "PUT"}
				submitUrl={
					staticData.creating
						? route("crm.client-treatment-budget-years.store")
						: route("crm.client-treatment-budget-years.update", staticData.id)
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "client_treatment.budget_years.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "client_treatment.budget_years.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage={!!staticData.creating}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-1-4">
								<LabeledTextInput
									name="year"
									type="number"
									value={formData.year ? `${formData.year}` : ""}
									onChange={inputHandler}
									label="client_treatment.budget_years.fields.year"
									disabled={readOnly}
								/>
							</div>
						</div>
						<div className="form-container">
							<div className="form-1-4">
								<LabeledTextInput
									name="budget"
									type="number"
									value={formData.budget ? `${formData.budget}` : ""}
									onChange={inputHandler}
									label={`${uctrans("client_treatment.budget_years.fields.budget")} €`}
									translateLabel={false}
									disabled={readOnly}
								/>
							</div>
							<div className="form-1-4">
								<LabeledTextInput
									name="budget_per_contactperson"
									type="number"
									value={formData.budget_per_contactperson ? `${formData.budget_per_contactperson}` : ""}
									onChange={inputHandler}
									label={`${uctrans("client_treatment.budget_years.fields.budget_per_contactperson")} €`}
									translateLabel={false}
									disabled={readOnly}
								/>
							</div>
						</div>
						{!readOnly && (
							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item="client_treatment.budget_years.singular" addAnotherAvailable={false} />
								</div>
							</div>
						)}
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
