import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import WarningItem from "./WarningItem";

export default function WarningGroups({ groups, onApprove, flat, warningsAsList }) {
	const [currentGroupIndex, setCurrentGroupIndex] = useState(0);

	const currentWarnings = useMemo(() => {
		if (flat) {
			return groups.reduce((acc, group) => acc.concat(group?.warnings ?? []), []);
		}

		return groups[currentGroupIndex].warnings ?? [];
	}, [groups, currentGroupIndex, flat]);

	const currentTexts = useMemo(() => {
		if (flat) {
			return groups.reduce((acc, group) => acc.concat(group?.texts ?? []), []);
		}

		return groups[currentGroupIndex].texts ?? [];
	}, [groups, currentGroupIndex, flat]);

	const currentWarningsNeedingApproval = useMemo(
		() => currentWarnings.filter(warning => warning?.requires_approval).map(warning => warning.key),
		[currentWarnings],
	);
	const [currentWarningsWithApproval, setCurrentWarningsWithApproval] = useState([]);
	const allWarningsApproved = useMemo(
		() => currentWarningsNeedingApproval.filter(k => !currentWarningsWithApproval.includes(k)).length === 0,
		[currentWarningsNeedingApproval, currentWarningsWithApproval],
	);

	const approveWarning = (warningKey, approved) => {
		if (approved) {
			// Add the warning to the list of approved warnings
			setCurrentWarningsWithApproval([...currentWarningsWithApproval, warningKey]);
		} else {
			// Remove the warning from the list of approved warnings
			setCurrentWarningsWithApproval(currentWarningsWithApproval.filter(k => k !== warningKey));
		}
	};

	// Reset the state when the dialog is (re)opened
	useEffect(() => {
		setCurrentGroupIndex(0);
		setCurrentWarningsWithApproval([]);
	}, []);

	useEffect(() => {
		if (!allWarningsApproved) {
			return;
		}

		if (currentGroupIndex < groups.length - 1) {
			// move to the next group
			setCurrentGroupIndex(currentGroupIndex + 1);
			return;
		}

		if (onApprove) {
			onApprove(currentWarningsWithApproval);
		}
	}, [allWarningsApproved, currentGroupIndex, currentWarningsWithApproval, groups.length, onApprove]);

	return (
		<div>
			{/* First display the warnings as a list */}
			{currentWarnings &&
				currentWarnings.length > 0 &&
				(warningsAsList ? (
					<ul className="list-disc ml-8">
						{currentWarnings.map((warning, index) => (
							<li key={`${warning.key}-${index}`}>
								<WarningItem onChange={approveWarning} warning={warning} />
							</li>
						))}
					</ul>
				) : (
					currentWarnings.map((warning, index) => (
						<WarningItem key={`${warning.key}-${index}`} onChange={approveWarning} warning={warning} />
					))
				))}
			{/* Then display the texts as paragraphs */}
			{currentTexts && currentTexts.length > 0 && (
				<div className="mt-5">
					{currentTexts.map((text, index) => (
						<p key={`${text.key}-${index}`}>{text.text}</p>
					))}
				</div>
			)}
		</div>
	);
}

WarningGroups.propTypes = {
	flat: PropTypes.bool,
	groups: PropTypes.arrayOf(
		PropTypes.shape({
			warnings: PropTypes.arrayOf(
				PropTypes.shape({
					text: PropTypes.string.isRequired,
					requires_approval: PropTypes.bool,
					key: PropTypes.string,
				}),
			),
			texts: PropTypes.arrayOf(
				PropTypes.shape({
					text: PropTypes.string.isRequired,
					requires_approval: PropTypes.bool,
					key: PropTypes.string,
				}),
			),
			title: PropTypes.string,
		}),
	),
	onApprove: PropTypes.func,
	warningsAsList: PropTypes.bool,
};

WarningGroups.defaultProps = {
	flat: false,
	onApprove: null,
	warningsAsList: true,
};
