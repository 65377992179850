import Tippy from "@tippyjs/react";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import axios from "axios";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import { can, route, yesNoOptions } from "../../../../../js/helpers";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Dialog from "../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import CheckmarkGroup from "../../../../../js/react/components/general/form/checkmark/CheckmarkGroup";
import FormDivider from "../../../../../js/react/components/general/FormDivider";
import IconButton from "../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import CollapsibleContent from "../general/CollapsibleContent";
import ClientTreatmentAddParticipantSearchFilter from "./ClientTreatmentAddParticipantSearchFilter";
import ClientTreatmentParticipantForm from "./ClientTreatmentParticipantForm";
import Navigation from "./Navigation";

export default function ClientTreatmentCompletions(props) {
	const [overviewDataUpdatedAt, setOverviewDataUpdatedAt] = useState(new Date().getTime());
	const [reminderData, setReminderData] = useState(null);
	const [editParticipantsDialogOpen, setEditParticipantsDialogOpen] = useState(false);
	const [activeParticipant, setActiveParticipant] = useState(null);
	const [addParticipantsDialogOpen, setAddParticipantsDialogOpen] = useState(false);
	const [participantCount, setParticipantCount] = useState(window.data.overview.participant_count);
	const [completionResult, setCompletionResult] = useState(window.data.overview.completion_result);
	const [YesNoOptions] = useState(yesNoOptions);

	const markCompletion = async (completion, asPresent) => {
		try {
			await axios.put(
				route(
					asPresent
						? `crm.client-treatments.completions.mark-as-present`
						: `crm.client-treatments.completions.unmark-as-present`,
					{
						client_treatment: staticData.client_treatment.id,
						participant: completion.id,
					},
				),
			);

			setOverviewDataUpdatedAt(new Date().getTime());
		} catch (error) {
			console.error(error);
		}
	};

	const handleAddParticipantsSuccess = () => {
		setOverviewDataUpdatedAt(new Date().getTime());
		setAddParticipantsDialogOpen(false);
	};

	const handleEditParticipantsSuccess = () => {
		setOverviewDataUpdatedAt(new Date().getTime());
		setEditParticipantsDialogOpen(false);
		setActiveParticipant(null);
	};

	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	const [staticData, setStaticData] = useState(
		typeof props.ClietTreatmentStaticData === "undefined"
			? cloneDeep(window.data.client_treatment_static_data)
			: props.ClietTreatmentStaticData,
	);

	useEffect(() => {
		if (typeof props.ClietTreatmentStaticData !== "undefined") {
			setStaticData(props.ClietTreatmentStaticData);
		}
	}, [props.ClietTreatmentStaticData]);

	return (
		<>
			<Navigation
				selectedTab="client-treatment-completions"
				canUpdate={can("client_treatment.client_treatment", "update")}
				loggedInInviter={
					window.data.user
						? staticData.client_treatment.inviters.find(
								inviter => Number(inviter.user_id) === Number(window.data.user.id),
						  )
						: null
				}
				clientTreatment={staticData.client_treatment}
				clientTreatmentTypes={staticData.client_treatment_types}
			/>
			<div className="page-title-container">
				<h1 className="page-title">{`${staticData.client_treatment.name} `}</h1>

				{(can("client_treatment.client_treatment", "create") || staticData.relationmanager_can_add_participants) && (
					<IconButton
						onClick={() => {
							setAddParticipantsDialogOpen(true);
						}}
						content={uctrans("client_treatment.participants.add_intermediary_contactpersons")}
						primary
					/>
				)}
			</div>
			{Number(participantCount) > 0 && (
				<div className="flex w-full justify-end mb-3">
					{participantCount && (
						<span>{`${participantCount} ${trans("client_treatment.completions.completions")}`}</span>
					)}
					{!!completionResult && `; ${map(completionResult, (amount, key) => `${amount} ${key}`).join(", ")}`}
				</div>
			)}
			<AbnOverview
				hideControls
				hideNrOfResults
				reloadedData={data => {
					setParticipantCount(data.overview.participant_count);
					setCompletionResult(data.overview.completion_result);
				}}
				initialData={overviewData.overview}
				indexUrl={route("crm.client-treatments.completions", staticData.client_treatment.id)}
				modelTranslations={{
					plural: trans("client_treatment.participants.plural"),
					singular: trans("client_treatment.participants.singular"),
				}}
				dataUpdatedAt={overviewDataUpdatedAt}
				renderFilters={() => (
					<Filters>
						<SelectFilter
							className="form-1-4"
							name="relationmanager"
							label={uctrans("intermediaries.fields.relationmanager_id")}
							options={staticData.relationmanagers}
						/>
						<SelectFilter
							className="form-1-4"
							name="participant_status"
							label={uctrans("client_treatment.participants.fields.status")}
							options={staticData.participant_status_options}
						/>
						<SelectFilter
							className="form-1-4"
							name="present"
							label={staticData.participant_statuses.PRESENT.label}
							options={YesNoOptions}
						/>
						<SelectFilter
							className="form-1-4"
							name="declined"
							label={staticData.participant_statuses.DECLINED.label}
							options={YesNoOptions}
						/>
					</Filters>
				)}
				renderBody={overview => (
					<>
						<DataTable>
							<DataColumn
								width={5}
								sortable="participant_object"
								renderHeaderContent={() => uctrans("contactpersons.singular")}
								renderCellContent={completion =>
									completion.participant_object ? (
										<div className="flex">
											<div className="avatar-img mr-2">
												{completion.participant_object.has_photo ? (
													<img
														className="max-h-full self-center"
														src={completion.participant_object.photo_url}
														alt={completion.participant_object.label}
													/>
												) : (
													<EvaIcon type="person" fill="#249286" height="35" width="35" />
												)}
											</div>
											<div>
												{completion.participant_object.label}
												<br />
												<span className="text-xs italic">
													{" "}
													{completion.participant_object.mobile ? completion.participant_object.mobile : ""}
												</span>
											</div>
										</div>
									) : (
										""
									)
								}
							/>
							<DataColumn
								width={6}
								sortable="type"
								renderHeaderContent={() => uctrans("client_treatment.completions.type")}
								renderCellContent={completion =>
									completion.type && (completion.type.label !== null) !== "undefined" ? completion.type.label : "-"
								}
							/>
							<DataColumn
								width={4}
								sortable="inviter"
								renderHeaderContent={() => uctrans("client_treatment.invited_by")}
								renderCellContent={completion =>
									completion.inviter && (completion.inviter.label !== null) !== "undefined"
										? completion.inviter.label
										: "-"
								}
							/>
							<DataColumn
								width={3}
								sortable="nr_of_people"
								renderHeaderContent={() => uctrans("client_treatment.participants.fields.nr_of_people")}
								renderCellContent={completion => (completion.nr_of_people ? completion.nr_of_people : "-")}
							/>
							<DataColumn
								width={4}
								renderHeaderContent={() => uctrans("client_treatment.participants.fields.info")}
								renderCellContent={completion =>
									completion.information ? <span className="text-xs italic">{completion.information}</span> : ""
								}
							/>
							<DataColumn
								width={3}
								renderHeaderContent={() => uctrans("client_treatment.participants.fields.email_filled")}
								renderCellContent={completion => (completion.email ? completion.email : "-")}
							/>
							<DataColumn
								width={5}
								sortable="relationmanager"
								renderHeaderContent={() => uctrans("intermediaries.fields.relationmanager_id")}
								renderCellContent={completion => (completion.relationmanager ? completion.relationmanager : "-")}
							/>
							<DataColumn
								width={4}
								sortable="status"
								renderHeaderContent={() => uctrans("client_treatment.participants.fields.status")}
								renderCellContent={completion => (
									<div className="flex">
										{can("client_treatment.client_treatment", "update") && (
											<a
												className="mr-2"
												onClick={() =>
													markCompletion(
														completion,
														completion.status && completion.status.key !== staticData.participant_statuses.PRESENT.key,
													)
												}>
												<EvaIcon
													type={
														completion.status && completion.status.key === staticData.participant_statuses.PRESENT.key
															? "checkmark-square-2-outline"
															: "square-outline"
													}
													fill="#9c9c9c"
													height="18"
													width="18"
												/>
											</a>
										)}
										<span>{completion.status ? completion.status.label : ""}</span>
									</div>
								)}
							/>
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={participant =>
									participant.triggersoverride ? (
										<Tippy
											placement="top"
											arrow
											animation="perspective"
											duration="400"
											content={uctrans("giveaways.fields.this_giveaway_triggered_override")}>
											<span className="text-lg" style={{ color: "red" }}>
												€
											</span>
										</Tippy>
									) : (
										""
									)
								}
							/>
							{(can("client_treatment.client_treatment", "update") ||
								staticData.relationmanager_can_add_participants) && (
								<DataColumn
									width={1}
									renderHeaderContent={() => ""}
									renderCellContent={participant => (
										<div className="overview-actions">
											<a
												className="link"
												onClick={() => {
													setActiveParticipant(participant);
													setEditParticipantsDialogOpen(true);
												}}>
												<EvaIcon type="edit-outline" width="18" height="18" fill="#009286" />
											</a>
										</div>
									)}
								/>
							)}
						</DataTable>
						<div className="flex w-full justify-start">
							<div className="w-full mt-3">
								{can("client_treatment.client_treatment", "update") && (
									<>
										<a
											className="button button-tertiary"
											href={route("crm.client-treatments.completions.export_completions", {
												client_treatment: staticData.client_treatment.id,
												status: overview.data.metadata.filtering.filters.participant_status
													? overview.data.metadata.filtering.filters.participant_status
													: null,
											})}>
											{uctrans("client_treatment.fields.export_guestlist")}
										</a>
										<a
											className="button button-tertiary ml-6"
											href={route("crm.client-treatments.completions.export_completions", {
												client_treatment: staticData.client_treatment.id,
											})}>
											{uctrans("client_treatment.fields.export_all_completions")}
										</a>
									</>
								)}
								<a
									className="button button-tertiary ml-6"
									href={route("crm.client-treatments.completions.badges", {
										client_treatment: staticData.client_treatment.id,
									})}>
									{uctrans("client_treatment.fields.generate_badges")}
								</a>
							</div>

							<div className="flex w-full justify-end mt-6 mr-3">
								<span>{`Totale plaatsen toegedeeld: ${staticData.participant_count || `-`}/${
									staticData.client_treatment.max_amount || `-`
								}`}</span>
							</div>
						</div>
					</>
				)}
			/>

			<CollapsibleContent
				className="mt-6"
				initialCollapseState={false}
				title={uctrans("client_treatment.fields.total_dividations_per_user")}>
				{staticData.client_treatment.inviters.map((inviter, index) => (
					<div key={index} className="w-1/4 float-left block">
						<span>{inviter.user_label}</span>{" "}
						<span>{`(${Number(inviter.divided_amount)}/${Number(inviter.amount)})`}</span>
					</div>
				))}
			</CollapsibleContent>

			{can("client_treatment.client_treatment", "update") && (
				<>
					<FormDivider label={uctrans("client_treatment.fields.send_email_reminders")} />
					<AjaxForm
						method="POST"
						submitUrl={route("crm.client-treatments.completions.reminders", staticData.client_treatment.id)}
						loaderText={uctrans("client_treatment.mail.reminders_are_being_sent")}
						successText={uctrans("client_treatment.mail.sent_reminders_successfully")}
						data={{ ...reminderData }}
						onSuccess={() => window.location.reload()}
						useFlashMessage>
						<CheckmarkGroup
							initialChecked={reminderData}
							name="reminder_mails"
							onChange={inviters => setReminderData(inviters)}>
							{staticData.client_treatment.inviters.map((inviter, index) => (
								<Checkmark
									key={index}
									value={inviter.user_id}
									name={inviter.user_id}
									label={inviter.user_label}
									className="w-1/4 float-left block"
								/>
							))}
						</CheckmarkGroup>

						<div className="form-full mt-6">
							<div className="row">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar
										buttonContent={uctrans("client_treatment.fields.send_reminder")}
										addAnotherAvailable={false}
									/>
								</div>
							</div>
						</div>
					</AjaxForm>{" "}
				</>
			)}

			<Dialog
				width={500}
				isOpen={editParticipantsDialogOpen}
				title={uctrans("client_treatment.participants.fields.edit_participant")}
				isFullScreen
				onClose={() => setEditParticipantsDialogOpen(false)}>
				<ClientTreatmentParticipantForm
					formData={activeParticipant}
					staticData={staticData}
					onSuccess={() => handleEditParticipantsSuccess()}
					onCancel={() => setEditParticipantsDialogOpen(false)}
				/>
			</Dialog>
			<Dialog
				width={900}
				isOpen={addParticipantsDialogOpen}
				title={uctrans("general.add_:item", {}, { item: "client_treatment.participants.plural" })}
				isFullScreen
				onClose={() => setAddParticipantsDialogOpen(false)}>
				<ClientTreatmentAddParticipantSearchFilter
					clientTreatment={staticData.client_treatment}
					clientTreatmentInviter={staticData.base_inviter}
					staticData={staticData}
					onSuccess={() => handleAddParticipantsSuccess()}
					onCancel={() => setAddParticipantsDialogOpen(false)}
					onAddParticipant={() => setOverviewDataUpdatedAt(new Date().getTime())}
					selectInviter
				/>
			</Dialog>
		</>
	);
}
ClientTreatmentCompletions.propTypes = {
	ClietTreatmentStaticData: PropTypes.object,
};
