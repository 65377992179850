import { useState } from "react";
import IconButton from "../../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../../js/react/components/general/overview/DataColumn";
import DeleteColumnContent from "../../../../../../../js/react/components/general/overview/DeleteColumnContent";
import { can, route, yesNoOptions } from "../../../../../../../js/helpers";
import Filters from "../../../../../../../js/react/components/general/overview/Filters";
import SelectFilter from "../../../../../../../js/react/components/general/overview/SelectFilter";
import Navigation from "../Navigation";

export default function AfmCheckActionOverview() {
	const [overviewData] = useState({
		overview: { ...window.data.overview },
	});

	return (
		<>
			<Navigation selectedTab="afm_check_actions" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("afm_permits.check_actions.plural")}</h1>
				{can("agenda.settings.afm_check_action", "create") && (
					<div>
						<IconButton
							href={route("crm.agenda-settings.afm-check-actions.create")}
							content={uctrans("general.add_:item", {}, { item: "afm_permits.check_actions.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={route("crm.agenda-settings.afm-check-actions.index")}
				deleteRouteName="crm.agenda-settings.afm-check-actions.delete"
				moveRouteName="crm.agenda-settings.afm-check-actions.move"
				useDragAndDrop={can("agenda.settings.afm_check_action", "move")}
				hideControls
				modelTranslations={{
					plural: trans("afm_permits.check_actions.plural"),
					singular: trans("afm_permits.check_actions.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SelectFilter
							className="form-1-3"
							name="is_active"
							label={uctrans("afm_permits.check_actions.fields.is_active")}
							options={yesNoOptions()}
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						{can("agenda.settings.afm_check_action", "move") && (
							<DataColumn
								width={1}
								className="draggable-overview-item-handle"
								renderHeaderContent={() => ""}
								renderCellContent={() => ""}
							/>
						)}
						<DataColumn
							width={10}
							sortable="name"
							renderHeaderContent={() => uctrans("afm_permits.check_actions.fields.name")}
							renderCellContent={status => {
								if (can("agenda.settings.afm_check_action", "update")) {
									return (
										<a href={route("crm.agenda-settings.afm-check-actions.edit", status.id)} className="row-title link">
											{status.name}
										</a>
									);
								} else {
									return status.name;
								}
							}}
						/>
						<DataColumn
							width={5}
							sortable="status"
							renderHeaderContent={() => uctrans("afm_permits.check_actions.fields.is_open")}
							renderCellContent={status => (status.is_open ? uctrans("general.yes") : uctrans("general.no"))}
						/>
						<DataColumn
							width={5}
							sortable="positive"
							renderHeaderContent={() => uctrans("afm_permits.check_actions.fields.positive")}
							renderCellContent={status => (status.positive ? uctrans("general.yes") : uctrans("general.no"))}
						/>
						<DataColumn
							width={5}
							renderHeaderContent={() => uctrans("afm_permits.check_actions.fields.is_active")}
							renderCellContent={status => (status.is_active ? uctrans("general.yes") : uctrans("general.no"))}
						/>
						{can("agenda.settings.afm_check_action", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(status, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(status)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
