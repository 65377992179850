import { renderComponent } from "../../../../js/componentRenderer";
import AutomatedMessageStatistics from "../components/communication/automatedMessages/AutomatedMessageStatistics";
import NewsletterStatistics from "../components/communication/newsletters/stats/NewsletterStatistics";
import CompanyAlerts from "../components/companyAlerts/CompanyAlerts";
import AccountsupportDashboard from "../components/dashboard/AccountsupportDashboard";
import DefaultDashboard from "../components/dashboard/DefaultDashboard";
import CrmMenuSearchBox from "../components/menu/CrmMenuSearchBox";
import Menu from "../components/menu/Menu";
import { Notifications } from "../../../../js/react/components/general/notifications";
import Report from "../components/report/Report";
import ImDashboard from "../components/rmDashboard/ImDashboard";
import OrgDashboard from "../components/rmDashboard/OrgDashboard";
import RelationmanagementDashboard from "../components/rmDashboard/RelationmanagementDashboard";
import AppStatistics from "../components/statistics/app/AppStatistics";

/**
 * Entry file for forms, all forms are rendered here
 */
renderComponent(Menu, "menu");
renderComponent(CrmMenuSearchBox, "crm-search-box");
renderComponent(Notifications, "notifications");
renderComponent(CompanyAlerts, "company-alerts");
renderComponent(AccountsupportDashboard, "accountsupport-dashboard");
renderComponent(DefaultDashboard, "default-dashboard");
renderComponent(RelationmanagementDashboard, "relationmanagement-dashboard");
renderComponent(ImDashboard, "im-dashboard");
renderComponent(OrgDashboard, "org-dashboard");

renderComponent(AppStatistics, "statistics-app");
renderComponent(NewsletterStatistics, "communication-newsletter-statistics");
renderComponent(AutomatedMessageStatistics, "communication-automated-message-statistics");
renderComponent(Report, "report-component");

/**
 * These imports are manually needed for our server-side templates
 * Only add images here if they're needed in those templates
 * If you need one of these in your component, just import it there
 **/
import "../../../../static/img/favicon.ico";
import "../../../../static/img/400-img.svg";
import "../../../../static/img/401-img.svg";
import "../../../../static/img/403-img.svg";
import "../../../../static/img/403-no-company-img.svg";
import "../../../../static/img/404-img.svg";
import "../../../../static/img/419-img.svg";
import "../../../../static/img/429-img.svg";
import "../../../../static/img/500-img.svg";
import "../../../../static/img/403-unsupportedbrowser-img.png";
import "../../../../static/img/503-img.svg";
import "../../../../static/img/abn-amro-grey.svg";
import "../../../../static/img/abn-amro-kantoor.jpg";
import "../../../../static/img/search-icon.svg";
