import each from "lodash/each";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import highchartsGlobalConfig from "../../../../../../js/config/highcharts_global";
import HighChart from "../../report/graph/HighChart";
import { uctrans } from "../../../../../../js/lib/Translator";

const DossierMutationsLineChart = props => {
	const [series, setSeries] = useState([]);

	useEffect(() => {
		const serieData = {};
		each(props.data, (typeData, type) => {
			each(typeData, (count, month) => {
				if (typeof serieData[type] === "undefined") {
					serieData[type] = [];
				}
				serieData[type].push([month, parseInt(count)]);
			});
		});
		const newSeries = [];

		Object.entries(serieData).forEach(([type, data]) => {
			newSeries.push({
				data,
				marker: {
					enabled: true,
				},
				color: type === "total" ? "#227370" : "#F6BC34",
				name: type === "total" ? uctrans(`dashboard.total`) : uctrans(`dashboard.proforma`),
			});
		});

		setSeries(newSeries);
	}, [props.data]);

	return (
		<HighChart
			chartConfig={{
				chart: {
					height: "300px",
					backgroundColor: null,
				},
				title: {
					text: null,
				},
				legend: {
					enabled: true,
					align: "center",
					verticalAlign: "top",
					floating: true,
					itemStyle: {
						fontSize: "10px",
						fontWeight: "normal",
					},
				},
				yAxis: {
					title: {
						text: null,
					},
				},
				xAxis: {
					min: 0,
					max: 12,
					categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
					labels: {
						formatter: data => highchartsGlobalConfig.lang.shortMonths[data.value],
					},
					startOnTick: false,
					endOnTick: false,
					minPadding: 0,
					maxPadding: 0,
					align: "left",
				},
				tooltip: {
					headerFormat: "",
					pointFormat: "{point.y:,.0f}",
				},
				plotOptions: {
					series: {
						marker: {
							enabled: false,
						},
					},
				},

				series,
			}}
		/>
	);
};

DossierMutationsLineChart.propTypes = {
	data: PropTypes.object,
};

export default DossierMutationsLineChart;
