import { useState } from "react";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import useInputHandler from "../../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import Nav from "../../../../../../js/lib/Nav";
import { route } from "../../../../../../js/helpers";
import Navigation from "../SettingsNavigation";

export default function NewsletterSubscriptionCategoryForm() {
	const [formData, setFormData] = useState({ ...window.data.form_values });
	const creating = window.data.form_values.id === -1;
	const inputHandler = useInputHandler(setFormData);

	const onSuccess = () => {
		Nav.go(route("crm.communication-settings.newsletter-subscription-categories.index"));
	};

	return (
		<>
			<Navigation selectedTab="newsletterSubscriptionCategories" />

			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(
						creating ? "general.add_:item" : "general.edit_:item",
						{},
						{ item: "communication.newsletter_subscription_categories.singular" },
					)}
				</h1>

				<a className="link" href={route("crm.communication-settings.newsletter-subscription-categories.index")}>
					{uctrans(
						"general.back_to_overview_:page",
						{},
						{ page: "communication.newsletter_subscription_categories.plural" },
					)}
				</a>
			</div>
			<AjaxForm
				method={creating ? "POST" : "PUT"}
				submitUrl={
					creating
						? route("crm.communication-settings.newsletter-subscription-categories.store")
						: route("crm.communication-settings.newsletter-subscription-categories.update", formData.id)
				}
				loaderText={uctrans(
					"general.:item_is_being_saved",
					{},
					{ item: "communication.newsletter_subscription_categories.singular" },
				)}
				successText={uctrans(
					"general.saved_:item",
					{},
					{ item: "communication.newsletter_subscription_categories.singular" },
				)}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="name"
										value={formData.name ? formData.name : ""}
										onChange={inputHandler}
										label="communication.newsletter_subscription_categories.fields.name"
										required
									/>
								</div>
							</div>
							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar
										item="communication.newsletter_subscription_categories.singular"
										addAnotherAvailable={false}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
