import find from "lodash/find";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { CmsTypesContext } from "../../cms-types-context";
import Boolean from "../attributes/Boolean";
import Text from "../attributes/Text";
import ImageAttribute from "../attributes/Image";
import Link from "./Link";

export default function Image(props) {
	const [imagevalues, setImageValues] = useState(props.values ? props.values : {});
	useEffect(() => {
		setImageValues(props.values ? props.values : {});
	}, [props.values]);

	const handleImageValueChange = (key, value) => {
		const newImagevalues = { ...imagevalues };
		newImagevalues[key] = value;
		setImageValues(newImagevalues);
		props.onChange(newImagevalues);
	};

	const { partTypes } = useContext(CmsTypesContext);
	const partType = find(partTypes, partType => partType.key === "image");

	if (partType) {
		return (
			<>
				{props.label && <label>{props.label}</label>}

				<div className="bg-primary-lightest pr-3 pl-4 py-3 my-2">
					<div className="flex">
						<div className="w-1/2">
							<label>{partType.attributes.image.label}</label>
							{!imagevalues.image_is_rm_photo && (
								<ImageAttribute
									value={imagevalues.image}
									onChange={value => {
										handleImageValueChange("image", value);
									}}
								/>
							)}
							<Boolean
								label={partType.attributes.image_is_rm_photo.label}
								value={imagevalues.image_is_rm_photo}
								onChange={value => {
									handleImageValueChange("image_is_rm_photo", value);
								}}
							/>
						</div>
						<div className="w-1/2 ml-2">
							<Text
								label={partType.attributes.alt.label}
								value={imagevalues.alt}
								onChange={value => {
									handleImageValueChange("alt", value);
								}}
							/>
							<Boolean
								label={partType.attributes.use_image_link.label}
								value={imagevalues.use_image_link}
								onChange={value => {
									handleImageValueChange("use_image_link", value);
								}}
							/>
						</div>
					</div>
					{!!imagevalues.use_image_link && (
						<Link
							values={imagevalues.link ? imagevalues.link : {}}
							onChange={value => {
								handleImageValueChange("link", value);
							}}
							useText={false}
						/>
					)}
				</div>
			</>
		);
	}
}

Image.propTypes = {
	label: PropTypes.string,
	values: PropTypes.shape({
		alt: PropTypes.string,
		image: PropTypes.number,
	}),
	onChange: PropTypes.func.isRequired,
};
