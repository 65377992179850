import { useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import { route } from "../../../../../../js/helpers";
import { format, parse } from "../../../../../../js/lib/Date";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../../js/react/components/general/Translate";
import Nav from "../../../../../../js/lib/Nav";
import axios from "axios";
import Select from "../../cms/general/attributes/Select";
import DatePicker from "../../../../../../js/react/components/general/DatePicker";
import useDateHandler from "../../../../../../js/react/hooks/useDateHandler";
import InterestValuesTable from "../InterestValuesTable";
import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";

export default function FixedInterestPeriodForm() {
	const [formData, setFormData] = useState({ ...window.data.interest_period_form_data });
	const [loadPreviousInterestPeriodEnabled, setLoadPreviousInterestPeriodEnabled] = useState(true);

	const staticData = { ...window.data.interest_period_static_data };

	const onDiscountSelection = discountId => {
		const value = parseInt(discountId);
		const newSelectedDiscounts = new Set([...selectedDiscounts]);
		newSelectedDiscounts.has(value) ? newSelectedDiscounts.delete(value) : newSelectedDiscounts.add(value);

		setSelectedDiscounts(newSelectedDiscounts);
	};

	const transformInterestValues = interestValues => {
		const transformedValues = {};
		interestValues.forEach(interestValue => {
			transformedValues[`${interestValue.fixed_interest_period_id}-${interestValue.rate_class_id}`] = {
				fixed_interest_period_id: interestValue.fixed_interest_period_id,
				rate_class_id: interestValue.rate_class_id,
				value: interestValue.value.toString().replace(/\./g, ","),
			};
		});
		return transformedValues;
	};

	let defaultValues = {};
	if (formData.id !== -1) {
		defaultValues = transformInterestValues(window.data.interest_values);
	}

	const [discounts, setDiscounts] = useState(formData.id === -1 ? [] : window.data.discounts);

	const [selectedDiscounts, setSelectedDiscounts] = useState(
		formData.id === -1 ? new Set([]) : new Set(window.data.selected_discounts),
	);

	const [selectedInterestTypeId, setSelectedInterestTypeId] = useState(
		formData.id === -1 ? -1 : formData.interest_type_id,
	);
	const [interestRateClasses, setInterestRateClasses] = useState(
		formData.id === -1 ? [] : window.data.interest_rate_classes,
	);
	const [fixedInterestPeriods, setFixedInterestPeriods] = useState(
		formData.id === -1 ? [] : window.data.fixed_interest_periods,
	);
	const [values, setValues] = useState(defaultValues);

	const datePickerHandler = useDateHandler(setFormData);

	const handleValuesChange = values => {
		setValues(values);
	};

	const getPreviousInterestPercentages = async () => {
		const response = await axios.get(route("crm.interest.interest-periods.index"), {
			params: {
				interest_type_id: selectedInterestTypeId,
				request_type: "last_interest_period",
			},
		});

		if (response.data.success === true) {
			setValues(transformInterestValues(response.data.interest_values));
		} else {
			setLoadPreviousInterestPeriodEnabled(false);
		}
	};

	const onInterestTypeChange = async typeId => {
		setSelectedInterestTypeId(typeId);

		if (typeId !== -1) {
			const [interestRateClassResponse, fixedInterestPeriodResponse, discountsResponse] = await Promise.all([
				axios.get(route("crm.interest.interest-rate-classes.index", typeId), {}),
				axios.get(route("crm.interest.fixed-interest-periods.index", typeId), {}),
				axios.get(route("crm.interest.discounts.index", typeId), {}),
			]);

			const filteredInterestRateClasses = interestRateClassResponse.data.overview.items.filter(
				interestRateClass => interestRateClass.archived === 0,
			);

			const filteredFixedInterestPeriods = fixedInterestPeriodResponse.data.overview.items.filter(
				fixedInterestPeriod => fixedInterestPeriod.archived === 0,
			);

			const selectedDiscounts = discountsResponse.data.overview.items
				.filter(discount => discount.active_by_default_cms)
				.map(discount => discount.id);

			setInterestRateClasses(filteredInterestRateClasses);
			setFixedInterestPeriods(filteredFixedInterestPeriods);
			setDiscounts(discountsResponse.data.overview.items);
			setSelectedDiscounts(new Set([...selectedDiscounts]));
		} else {
			setInterestRateClasses([]);
			setFixedInterestPeriods([]);
			setDiscounts([]);
		}
	};

	const onStartDateChange = date => {
		datePickerHandler(date, "start_date");

		date.setDate(date.getDate() - 1);
		if (date.getDay() === 6) date.setDate(date.getDate() - 1);
		else if (date.getDay() === 0) date.setDate(date.getDate() - 2);

		datePickerHandler(date, "submit_date");
	};

	const setPropertyFromFormData = (property, value) => {
		const newFormData = Object.assign({}, formData);
		newFormData[property] = value;
		setFormData(newFormData);
	};

	const onSuccess = () => {
		Nav.go(route("crm.interest.interest-periods.index"));
	};

	const getBreadCrumbs = () => {
		const breadCrumbs = [
			{
				label: uctrans("interest.interest_periods.plural"),
				route: route("crm.interest.interest-periods.index"),
			},
		];

		if (formData.id !== -1) {
			breadCrumbs.push({
				label: format(formData.start_date, "dd-MM-yyyy"),
			});
		}

		return breadCrumbs;
	};

	return (
		<>
			<BreadCrumbs breadCrumbs={getBreadCrumbs()} />

			{formData.id === -1 ? (
				<h1 className="inline-block">
					{uctrans("general.add_:item", {}, { item: "interest.interest_periods.singular" })}
				</h1>
			) : (
				<h1 className="inline-block">
					{uctrans("general.edit_:item", {}, { item: "interest.interest_periods.singular" })}
				</h1>
			)}
			<div className="float-right">
				<a className="link" href={route("crm.interest.interest-periods.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "interest.interest_periods.plural" })}
				</a>
			</div>
			<AjaxForm
				method={formData.id === -1 ? "POST" : "PUT"}
				submitUrl={
					formData.id === -1
						? route("crm.interest.interest-periods.store")
						: route("crm.interest.interest-periods.update", formData.id)
				}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "interest.interest_periods.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "interest.interest_periods.singular" })}
				onSuccess={onSuccess}
				useFlashMessage
				data={{
					...formData,
					interest_values: values,
					interest_type_id: selectedInterestTypeId,
					selected_discounts: [...selectedDiscounts],
				}}>
				<div className="form-full">
					<div className="form-1-3">
						<label>
							<Translate content="interest.fields.start_date" /> *
						</label>
						<DatePicker
							initialValue={formData.start_date ? parse(formData.start_date) : null}
							onChange={date => onStartDateChange(date)}
						/>
					</div>
				</div>
				<div className="form-full">
					<div className="form-1-3">
						<label>
							<Translate content="interest.fields.submit_date" />
						</label>
						<DatePicker
							initialValue={formData.submit_date ? parse(formData.submit_date) : null}
							onChange={date => datePickerHandler(date, "submit_date")}
						/>
					</div>
				</div>
				{formData.id === -1 ? (
					<div className="form-1-3">
						<Select
							label="Kies het rente type"
							options={[
								{
									label: "Selecteer een rente type",
									value: -1,
								},
								...staticData.interest_types.map(interestType => ({
									label: interestType.name,
									value: interestType.id,
								})),
							]}
							value={selectedInterestTypeId}
							onChange={value => onInterestTypeChange(value)}
						/>
					</div>
				) : (
					<div className="form-1-3">
						<label>{uctrans("interest.interest_types.singular")}</label>
						{window.data.interest_type.name}
					</div>
				)}
				{selectedInterestTypeId !== -1 && (
					<div className="form-full">
						<br />
						<h2>
							<Translate content="hypotheektarieven" />
						</h2>
						{interestRateClasses.length > 0 && fixedInterestPeriods.length > 0 ? (
							<>
								{formData.id === -1 &&
									(loadPreviousInterestPeriodEnabled ? (
										<a className="link mb-4 inline-block" onClick={getPreviousInterestPercentages}>
											<Translate content="interest.sentences.load_previous_interest_rates" />
										</a>
									) : (
										<p className="mb-4">
											<Translate content="interest.sentences.load_previous_interest_rates_not_available" />
										</p>
									))}
								<InterestValuesTable
									values={values}
									interestRateClasses={interestRateClasses}
									fixedInterestPeriods={fixedInterestPeriods}
									onValuesChange={values => handleValuesChange(values)}
								/>
							</>
						) : (
							<p>
								<Translate content="interest.sentences.no_interest_periods_found" />
							</p>
						)}
					</div>
				)}

				{discounts.length > 0 && (
					<div className="form-full">
						<div className="form-1-3">
							<label>
								<Translate content="interest.discounts.plural" />
							</label>
							<small className="inline-block">
								<Translate content="interest.sentences.discount_explanation" />
							</small>
							{discounts.map(discount => (
								<div key={discount.id} className="mt-2">
									<input
										type="checkbox"
										value={discount.id}
										checked={selectedDiscounts.has(discount.id)}
										onChange={event => onDiscountSelection(event.target.value)}
									/>
									&nbsp; {discount.name}
									<span className="checkmark" />
								</div>
							))}
						</div>
					</div>
				)}

				<div className="form-full">
					<div className="form-1-3">
						<label>
							<Translate content="archief tekst" />
						</label>
						<textarea
							value={formData.archive_text}
							onChange={event => setPropertyFromFormData("archive_text", event.target.value)}
							name="archive_text"
						/>
					</div>
				</div>

				<div className="form-full">
					<div className="w-full md:w-1/2 lg:w-2/3 column">
						<SubmitBar
							item="interest.interest_periods.singular"
							submitDisabled={interestRateClasses.length === 0 || fixedInterestPeriods.length === 0}
							addAnotherAvailable={false}
						/>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
