import PropTypes from "prop-types";
import { can, route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";

export default function Navigation(props) {
	const tabs = [];
	if (!props.targetYear && can("targets.target_year", "create")) {
		tabs.push({
			key: "targetYear",
			label: uctrans("targets.target_year.singular"),
			route: route("crm.targets.target-years.create"),
		});
	}
	if (props.targetYear) {
		if (can("targets.target_year", "update")) {
			tabs.push({
				key: "targetYear",
				label: uctrans("targets.target_year.singular"),
				route: route("crm.targets.target-years.edit", { target_year: props.targetYear.id }),
			});
		} else if (can("targets.target_year", "view")) {
			tabs.push({
				key: "targetYear",
				label: uctrans("targets.target_year.singular"),
				route: route("crm.targets.target-years.show", { target_year: props.targetYear.id }),
			});
		}
	}
	if (!!props.targetYear && can("targets.relationmanager_target", "view")) {
		tabs.push({
			key: "relationmanagerTargets",
			label: uctrans("targets.relationmanager_target.plural"),
			route: route("crm.targets.relationmanager-targets.index", { target_year: props.targetYear.id }),
		});
	}
	if (!!props.targetYear && can("targets.company_target", "view")) {
		tabs.push({
			key: "companyTargets",
			label: uctrans("targets.company_target.plural"),
			route: route("crm.targets.company-targets.index", { target_year: props.targetYear.id }),
		});
	}
	if (!!props.targetYear && can("view-company-targets-dashboard")) {
		tabs.push({
			key: "companyTargetsDashboard",
			label: uctrans("targets.company_target_dashboard"),
			route: route("crm.targets.company-targets-dashboard.index", { target_year: props.targetYear.id }),
		});
	}
	return (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{ label: uctrans("targets.singular") },
					{
						label: uctrans("targets.target_year.plural"),
						route: route("crm.targets.target-years.index"),
					},
					{
						label: !props.targetYear
							? uctrans("general.add_:item", [], { item: "targets.relationmanager_target.singular" })
							: props.targetYear.year,
					},
				]}
			/>
			<TabLinks tabs={tabs} activeTab={props.selectedTab} />
		</>
	);
}
Navigation.propTypes = {
	selectedTab: PropTypes.string.isRequired,
	targetYear: PropTypes.object,
};
