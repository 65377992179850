import axios from "axios";
import findIndex from "lodash/findIndex";
import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { route } from "../../../../../js/helpers";
import { format, parse } from "../../../../../js/lib/Date";
import { trans, uctrans } from "../../../../../js/lib/Translator";
import DatePicker from "../../../../../js/react/components/general/DatePicker";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import LabeledAutosizeTextarea from "../../../../../js/react/components/general/form/LabeledAutosizeTextarea";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import Select from "../../../../../js/react/components/general/Select";
import Translate from "../../../../../js/react/components/general/Translate";
import FileInput from "../forms/FileInput";

export default function AgendaItemQuickAdd(props) {
	const [addIndex, setAddIndex] = useState(-1);

	const mappedContactpersons = useMemo(
		() =>
			props.contactpersons
				? props.contactpersons.map(action => ({
						value: action.id,
						label: action.name,
				  }))
				: [],
		[props.contactpersons],
	);

	const getNewGiveaway = () => ({
		id: addIndex,
		name: "",
		costs: `0.00`,
		date: format(new Date()),
		contactperson_id: -1,
		by_user_id: window.data.user.id,
		is_new: true,
	});

	const removeGiveaway = index => {
		const newGiveaways = [...props.giveaways];
		newGiveaways.splice(index, 1);
		props.onGiveawayChange(newGiveaways);
	};

	const addGiveaway = () => {
		const newGiveaways = [...props.giveaways];
		newGiveaways.push(getNewGiveaway());
		setAddIndex(addIndex - 1);
		props.onGiveawayChange(newGiveaways);
	};

	const checkCosts = (newGiveaways, giveawayid, key) => {
		if (key === "costs" || key === "contactperson_id") {
			const giveawayToBeModifiedIndex = findIndex(newGiveaways, ["id", giveawayid]);
			if (giveawayToBeModifiedIndex !== -1) {
				const giveaway = newGiveaways[giveawayToBeModifiedIndex];
				if (giveaway.contactperson_id !== -1) {
					const params = { contactperson: giveaway.contactperson_id };

					if (props.contactmomentId) {
						params.without_contactmoment_id = props.contactmomentId;
					}

					axios
						.patch(route("crm.contactmoments.contactpersons.budget-costs", params))
						.then(response => {
							if (response && response.data) {
								let totalcosts = Number(giveaway.costs);

								if (response.data.costs !== 0) {
									totalcosts = Number(giveaway.costs) + Number(response.data.costs);
								}

								if (totalcosts > props.staticData.budget_year.budget_per_contactperson) {
									newGiveaways[giveawayToBeModifiedIndex].triggersoverride = true;
									props.onGiveawayChange(newGiveaways);
								} else {
									newGiveaways[giveawayToBeModifiedIndex].triggersoverride = false;
									props.onGiveawayChange(newGiveaways);
								}
							}
						})
						.catch(error => {
							console.error(`Errors during fetching costs: ${error}`);
						});
				}
			}
		} else {
			props.onGiveawayChange(newGiveaways);
		}
	};

	const handleGiveawayChange = (key, giveawayid, value) => {
		const newGiveaways = [...props.giveaways];
		const giveawayToBeModifiedIndex = findIndex(newGiveaways, ["id", giveawayid]);
		if (giveawayToBeModifiedIndex !== -1) {
			newGiveaways[giveawayToBeModifiedIndex][key] = value;
			props.onGiveawayChange(newGiveaways);
		}

		checkCosts(newGiveaways, giveawayid, key);
	};

	return (
		<div className="mb-8">
			<ul>
				<li className="border-b border-primary cursor-pointer clear-right">
					<h4 className="inline-block">{uctrans("giveaways.plural")}</h4>
					<a className="button button-secondary float-right quick-add-button" onClick={addGiveaway}>
						{uctrans("general.add_:item", { item: trans("giveaways.singular") })}
					</a>
				</li>
			</ul>

			<div className="mt-3">
				{props.giveaways && props.giveaways.length === 0 ? (
					<span>{uctrans("general.no_:items_added_yet", { items: trans("giveaways.plural") })}</span>
				) : (
					props.giveaways.map((giveaway, giveawayIndex) => (
						<div key={giveaway.id} className="form-container">
							<div className="form-1-2">
								<LabeledTextInput
									name="name"
									label="giveaways.fields.name"
									value={giveaway.name}
									onChange={e => handleGiveawayChange("name", giveaway.id, e.target.value)}
									required
								/>
							</div>

							<div className="form-1-2">
								<label htmlFor="costs">
									{uctrans("giveaways.fields.costs")} <span className="text-orange"> *</span>
								</label>
								<input
									type="number"
									step=".01"
									name="costs"
									value={giveaway.costs}
									onChange={e => handleGiveawayChange("costs", giveaway.id, e.target.value)}
								/>
							</div>

							<div className="form-1-2">
								<label htmlFor="date">
									{uctrans("giveaways.fields.date")} <span className="text-orange"> *</span>
								</label>
								<DatePicker
									initialValue={giveaway.date ? parse(giveaway.date) : null}
									onChange={date => handleGiveawayChange("date", giveaway.id, date ? format(date) : null)}
								/>
							</div>

							<div className="form-1-2">
								<label htmlFor="by_user_id">
									<Translate content="giveaways.fields.by_user_id" />
									<span className="text-orange"> *</span>
								</label>
								<Select
									placeholder={uctrans("general.select_:item", {}, { item: "agendaitems.fields.user" })}
									value={props.users.filter(({ value }) => Number(value) === Number(giveaway.by_user_id))}
									options={props.users}
									name="by_user_id"
									onChange={selectedValue =>
										handleGiveawayChange("by_user_id", giveaway.id, selectedValue ? selectedValue.value : null)
									}
								/>
							</div>

							<div className="form-1-2">
								<label htmlFor="contactperson_id">
									<Translate content="giveaways.fields.contactperson_id" />
									<span className="text-orange"> *</span>
								</label>
								<Select
									placeholder={uctrans("general.select_:item", {}, { item: "contactpersons.singular" })}
									value={mappedContactpersons.filter(
										({ value }) => Number(value) === Number(giveaway.contactperson_id),
									)}
									options={mappedContactpersons}
									name="contactperson_id"
									onChange={selectedValue =>
										handleGiveawayChange("contactperson_id", giveaway.id, selectedValue ? selectedValue.value : null)
									}
								/>
							</div>

							{!!giveaway.triggersoverride && (
								<>
									<div className="form-full">
										<br />
										<h3>{uctrans("giveaways.fields.yearly_limit_has_been_exceeded")}</h3>
										<div className="form-1-2">
											<LabeledAutosizeTextarea
												label="giveaways.fields.override_description"
												required
												value={giveaway.override_description}
												name="override_description"
												onChange={e => handleGiveawayChange("override_description", giveaway.id, e.target.value)}
											/>
										</div>
									</div>
									<div className="form-full">
										<div className="form-1-2">
											<label>
												{uctrans("giveaways.fields.attachment_agreement")} <span className="text-orange"> *</span>
											</label>
											<FileInput
												initialFiles={giveaway.attachment_agreement}
												onChange={files => handleGiveawayChange("attachment_agreement", giveaway.id, files)}
												existingFileDownloadRoute="crm.contactmoments.giveaways.download-attachment-agreement"
												maxFiles={10}
											/>
										</div>
									</div>
								</>
							)}

							<div className="form-full">
								<span className="float-right" onClick={() => removeGiveaway(giveawayIndex)}>
									<label>
										<span className="mr-2">
											{uctrans("general.delete_:item", {}, { item: trans("giveaways.singular") })}
										</span>
										<span>
											<EvaIcon type="trash-2-outline" height="18" width="18" fill="#009286" />
										</span>
									</label>
								</span>
							</div>
							<div className="form-full">
								<hr style={{ width: "100%" }} />
							</div>
						</div>
					))
				)}
			</div>
		</div>
	);
}

AgendaItemQuickAdd.propTypes = {
	giveaways: PropTypes.array,
	contactpersons: PropTypes.array,
	staticData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	users: PropTypes.array,
	contactmomentId: PropTypes.number,
	onGiveawayChange: PropTypes.func,
};
