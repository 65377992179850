import map from "lodash/map";
import { route } from "../../../../../../js/helpers";
import { useState } from "react";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import ClickToSelect from "../../../../../../js/react/components/general/ClickToSelect";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import Tippy from "@tippyjs/react";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import { CmsTypesContext } from "../../cms/cms-types-context";
import Attribute from "../../cms/general/attributes/Attribute";
import PartContent from "../../cms/general/parts/PartContent";
import CollapsibleContent from "../../general/CollapsibleContent";
import Navigation from "../SettingsNavigation";

export default function NewsletterFooterForm() {
	const [formData, setFormData] = useState({ ...window.data.form_data });
	const staticData = { ...window.data.static_data };

	const handleContentChange = (key, values) => {
		const newFormData = { ...formData };
		newFormData.contents[key] = values;
		setFormData(newFormData);
	};

	return (
		<>
			<Navigation selectedTab="newsletterFooter" />
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans("general.edit_:item", {}, { item: "communication.newsletters.footer" })}
				</h1>
			</div>

			<div className="min-h-screen md:flex">
				<div className="flex-1">
					<AjaxForm
						method="PUT"
						submitUrl={route("crm.communication-settings.newsletter-footer.update")}
						loaderText={uctrans("general.:item_is_being_saved", {}, { item: "communication.newsletters.footer" })}
						successText={uctrans("general.saved_:item", {}, { item: "communication.newsletters.footer" })}
						onSuccess={() => window.location.reload()}
						data={{ ...formData }}
						useFlashMessage>
						<CmsTypesContext.Provider value={staticData.cmsTypes}>
							<div className="cms-attribute grid-container">
								<div className="form-full w-2/3">
									{(() =>
										map(formData.attributes, (attribute, key) => {
											const attributeValues =
												formData.contents != null && Object.prototype.hasOwnProperty.call(formData.contents, key)
													? formData.contents[key]
													: null;
											if (attribute.type === "part") {
												return (
													<PartContent
														key={key}
														label={attribute.label}
														parttype={attribute.parttype}
														attribute={attribute}
														values={attributeValues}
														onChange={values => {
															handleContentChange(key, values);
														}}
													/>
												);
											} else {
												return (
													<Attribute
														key={key}
														attribute={attribute}
														values={attributeValues}
														onChange={values => {
															handleContentChange(key, values);
														}}
													/>
												);
											}
										}))()}
								</div>
								<div className="form-full">
									<SubmitBar item="communication.newsletters.footer" />
								</div>
							</div>
						</CmsTypesContext.Provider>
					</AjaxForm>
				</div>
				<div className="flex-none w-1/3 border-l ml-2 pl-12">
					<h2>Tags</h2>
					{map(staticData.tags_sidebar, (tags, category) => (
						<CollapsibleContent key={category} title={uctrans(`communication.tags.categories.${category}`)}>
							<div className="text-xs">
								{map(tags, (tag, i) => (
									<div className="flex justify-between" key={i}>
										<ClickToSelect>{`{${tag.tag}}`}</ClickToSelect>
										<Tippy
											placement="right"
											arrow
											animation="perspective"
											duration="400"
											content={
												<p>
													{tag.description}
													<br />
													<strong>Default:</strong> {tag.default}
													<br />
													<strong>Preview:</strong> {tag.preview}
												</p>
											}>
											<span>
												<EvaIcon type="info-outline" fill="#249286" height="18" width="18" />
											</span>
										</Tippy>
									</div>
								))}
							</div>
						</CollapsibleContent>
					))}
				</div>
			</div>
		</>
	);
}
