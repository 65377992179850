import PropTypes from "prop-types";
import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import CommunicationPreferences from "../../../../../js/react/components/communication/CommunicationPreferences";
import ImageThumbnailFileInput from "../../../../../js/react/components/general/form/ImageThumbnailFileInput";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import FormDivider from "../../../../../js/react/components/general/FormDivider";
import { notify } from "../../../../../js/react/components/general/notifications";
import Spinner from "../../../../../js/react/components/general/Spinner";
import NotificationPreferences from "../../../../../js/react/components/notifications/NotificationPreferences";
import useGenericHandler from "../../../../../js/react/hooks/useGenericHandler";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import useRadioHandler from "../../../../../js/react/hooks/useRadioHandler";
import useSelectHandler from "../../../../../js/react/hooks/useSelectHandler";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import Select from "../../../../../js/react/components/general/Select";
import Translate from "../../../../../js/react/components/general/Translate";
import CollapsibleContent from "../general/CollapsibleContent";
import axios from "axios";

const TextEditor = lazy(() => import(/* webpackChunkName: "tinymce" */ "../forms/textEditor/TextEditor"));

export default function UserFormFields({
	formData,
	setFormData,
	staticData,
	readOnly,
	existingFileDownloadRoute,
	ignoreAutomaticExclusion,
	useInternalEmailAddress,
}) {
	const inputHandler = useInputHandler(setFormData);
	const genericHandler = useGenericHandler(setFormData);
	const selectHandler = useSelectHandler(setFormData);
	const radioHandler = useRadioHandler(setFormData);
	const [isDepartmentImd, setIsDepartmentImd] = useState(false);
	const [roleIsRelationmanager, setRoleIsRelationmanager] = useState(false);

	const roleChange = roleId => {
		genericHandler(roleId, "role_id");
		if (staticData.creating && roleId) {
			genericHandler(
				staticData.default_notification_preferences_per_role &&
					staticData.default_notification_preferences_per_role[roleId]
					? staticData.default_notification_preferences_per_role[roleId]
					: [],
				"notification_preferences",
			);
		}
	};

	const checkDepartment = useCallback(async () => {
		if (!formData.department_id) {
			return;
		}
		try {
			const response = await axios.get(route(`crm.departments.show`, formData.department_id));

			if (response.data && response.data.department) {
				setIsDepartmentImd(response.data.department.is_imd);
			}
		} catch (error) {
			notify("error", error, null);
		}
	}, [formData.department_id]);

	const checkRole = useCallback(async () => {
		if (!formData.role_id) {
			return;
		}
		const response = await axios.get(route(`crm.roles.show`, formData.role_id));

		if (response.data && response.data.role) {
			setRoleIsRelationmanager(response.data.role.is_relationmanager);
		}
	}, [formData.role_id]);

	useEffect(() => {
		checkDepartment();
	}, [checkDepartment, formData.department_id]);

	useEffect(() => {
		checkRole();
	}, [checkRole, formData.role_id]);

	return (
		<>
			<FormDivider label={uctrans("users.fields.user_data")} />

			<div className="max-w-2xl">
				<div className="form-container">
					<div className="form-full">
						<div className="form-1-4">
							<label htmlFor="salutation">
								<Translate content="contactpersons.fields.salutation" />
								<span className="text-orange"> *</span>
							</label>
							<RadioGroup
								name="salutation"
								value={formData.salutation}
								horizontal
								disabled={readOnly}
								onChange={value => radioHandler(value, "salutation")}>
								<Radio label={staticData.salutation_types.MR.label} value={staticData.salutation_types.MR.key} />
								<Radio label={staticData.salutation_types.MRS.label} value={staticData.salutation_types.MRS.key} />
							</RadioGroup>
						</div>

						<div className="form-1-3">
							<LabeledTextInput
								required
								name="short_name"
								label="users.fields.short_name"
								value={formData.short_name}
								onChange={inputHandler}
								disabled={readOnly}
							/>
						</div>
					</div>
					<div className="form-full">
						<div className="form-1-4">
							<LabeledTextInput
								name="first_name"
								label="users.fields.first_name"
								value={formData.first_name}
								onChange={inputHandler}
								disabled={readOnly}
							/>
						</div>
						<div className="form-1-6">
							<LabeledTextInput
								name="insertion"
								label="users.fields.insertion"
								value={formData.insertion}
								onChange={inputHandler}
								disabled={readOnly}
							/>
						</div>
						<div className="form-1-3">
							<LabeledTextInput
								name="last_name"
								label="users.fields.last_name"
								value={formData.last_name}
								onChange={inputHandler}
								disabled={readOnly}
								required
							/>
						</div>
					</div>
				</div>
			</div>
			<FormDivider label={uctrans("users.fields.inlog_data")} />
			<div className="max-w-2xl">
				<div className="form-1-3">
					<label htmlFor="role_id">
						<Translate content="users.fields.role" />
						<span className="text-orange"> *</span>
					</label>
					<Select
						placeholder={uctrans("general.select_:item", {}, { item: "users.fields.role" })}
						value={staticData.roles.filter(({ value }) => Number(value) === Number(formData.role_id))}
						options={staticData.roles}
						name="role_id"
						onChange={selectedValue => {
							roleChange(selectedValue !== null ? selectedValue.value : "");
						}}
						disabled={readOnly}
						isClearable={false}
					/>
				</div>
				<div className="form-1-4">
					<label htmlFor="in_overviews">
						<Translate content="users.fields.in_overviews" />
					</label>
					<RadioGroup
						name="in_overviews"
						value={formData.in_overviews}
						horizontal
						disabled={readOnly}
						onChange={value => radioHandler(value, "in_overviews")}>
						<Radio label={uctrans("general.yes")} value={1} />
						<Radio label={uctrans("general.no")} value={0} />
					</RadioGroup>
				</div>
				<div className="form-full">
					<div className="form-1-3">
						<LabeledTextInput
							name="email"
							label="users.fields.email"
							value={formData.email}
							onChange={inputHandler}
							disabled={readOnly}
							required
						/>
					</div>
					<div className="1-3">
						<label>{uctrans("my_profile.fields.change_password")}</label>
						<RadioGroup
							name="change_password"
							value={formData.change_password}
							horizontal
							disabled={readOnly}
							onChange={value => genericHandler(value, "change_password")}>
							<Radio label={uctrans("general.yes")} value={1} />
							<Radio label={uctrans("general.no")} value={0} />
						</RadioGroup>
					</div>
					{formData.change_password ? (
						<>
							<div className="form-full">
								<div className="form-1-3">
									<label htmlFor="current_password">
										<Translate content="passwords.your_current_password" />
									</label>
									<input
										id="current_password"
										type="password"
										name="current_password"
										value={formData.current_password}
										onChange={inputHandler}
										autoComplete="off"
										disabled={readOnly}
									/>
								</div>
							</div>
							<div className="form-container">
								<div className="form-1-3">
									<label htmlFor="new_password">
										<Translate content="passwords.users_new_password" />
									</label>
									<input
										id="raw_password"
										type="password"
										name="raw_password"
										onChange={inputHandler}
										autoComplete="off"
										disabled={readOnly}
									/>
								</div>
								<div className="form-1-3">
									<label htmlFor="raw_password_confirmation">
										<Translate content="passwords.users_new_password_confirmation" />
									</label>
									<input
										id="raw_password_confirmation"
										type="password"
										name="raw_password_confirmation"
										onChange={inputHandler}
										autoComplete="off"
										disabled={readOnly}
									/>
								</div>
							</div>
						</>
					) : null}
				</div>

				{!ignoreAutomaticExclusion && (
					<div className="form-1-4">
						<label htmlFor="exclude_from_auto_deactivation">
							<Translate content="users.fields.exclude_from_auto_deactivation" />
						</label>
						<RadioGroup
							name="exclude_from_auto_deactivation"
							value={formData.exclude_from_auto_deactivation}
							horizontal
							disabled={readOnly}
							onChange={value => radioHandler(value, "exclude_from_auto_deactivation")}>
							<Radio label={uctrans("general.yes")} value={1} />
							<Radio label={uctrans("general.no")} value={0} />
						</RadioGroup>
					</div>
				)}
			</div>

			<FormDivider label={uctrans("users.fields.contact_data")} />
			<div className="max-w-2xl">
				<div className="form-full">
					<div className="form-1-3">
						<LabeledTextInput
							name="mobile"
							label="users.fields.mobile"
							value={formData.mobile}
							onChange={inputHandler}
							disabled={readOnly}
						/>
					</div>
					{useInternalEmailAddress && !!isDepartmentImd && (
						<div className="form-1-3">
							<LabeledTextInput
								name="internal_email"
								label="users.fields.internal_email"
								value={formData.internal_email}
								onChange={inputHandler}
								disabled={readOnly}
							/>
						</div>
					)}
				</div>
				<div className="form-container">
					<div className="form-1-3">
						<label htmlFor="department_id">
							<Translate content="departments.singular" />
							<span className="text-orange"> *</span>
						</label>
						<Select
							placeholder={uctrans("general.select_:item", {}, { item: "departments.singular" })}
							value={staticData.departments.filter(({ value }) => Number(value) === Number(formData.department_id))}
							options={staticData.departments}
							name="department_id"
							onChange={selectHandler}
							disabled={readOnly}
							isClearable={false}
						/>
					</div>
					<div className="form-1-3">
						<LabeledTextInput
							name="ms_bookings_url"
							label="users.fields.ms_bookings_url"
							value={formData.ms_bookings_url}
							onChange={inputHandler}
							disabled={readOnly || !roleIsRelationmanager}
						/>
					</div>
				</div>
			</div>

			<div className="form-full">
				<div className="form-1-3">
					<label htmlFor="avatar">
						<Translate content="users.fields.avatar" />
					</label>
					<ImageThumbnailFileInput
						storeRoute="crm.files.store"
						newFileDownloadRoute="crm.files.download"
						existingFileDownloadRoute={existingFileDownloadRoute}
						maxFiles={1}
						image={formData.avatar ? formData.avatar : []}
						onChange={files => genericHandler(files, "avatar")}
						public
						disabled={readOnly}
					/>
				</div>
			</div>
			<div className="form-full">
				<div className="form-1-3">
					<label htmlFor="content">{uctrans("users.fields.personal_info")}</label>
					<Suspense
						fallback={
							<div style={{ width: 32, height: 32 }}>
								<Spinner />
							</div>
						}>
						<TextEditor
							value={formData.personal_info ? `${formData.personal_info}` : ""}
							onChange={value => genericHandler(value, "personal_info")}
						/>
					</Suspense>
				</div>
			</div>

			<CollapsibleContent title={uctrans("contactpersons.fields.communication_preferences")}>
				<div className="form-full mb-4">
					<Translate content="users.communication_preferences_description" />
				</div>

				<div className="form-full mt-4">
					<CommunicationPreferences
						communicationPreferenceData={formData.unsubscribe_preferences}
						onPreferenceChange={value => genericHandler(value, "unsubscribe_preferences")}
						staticNewsLetterSubscriptionTypes={staticData.newsletter_subscription_types}
						unsubscribeReasons={staticData.unsubscribe_reasons}
						forCrm
						disabled={readOnly}
					/>
				</div>
			</CollapsibleContent>

			{!!isDepartmentImd && (
				<CollapsibleContent title={uctrans("users.notification_preferences")}>
					<NotificationPreferences
						preferences={formData.notification_preferences}
						onChange={value => {
							genericHandler(value, "notification_preferences");
						}}
						notifictionTypesPerCategory={staticData.notification_types_per_category}
						disabled={readOnly}
					/>
				</CollapsibleContent>
			)}
		</>
	);
}
UserFormFields.propTypes = {
	formData: PropTypes.object.isRequired,
	setFormData: PropTypes.func.isRequired,
	staticData: PropTypes.object.isRequired,
	readOnly: PropTypes.bool,
	existingFileDownloadRoute: PropTypes.string.isRequired,
	useInternalEmailAddress: PropTypes.bool,
	ignoreAutomaticExclusion: PropTypes.bool,
};

UserFormFields.defaultProps = {
	readOnly: false,
	useInternalEmailAddress: true,
	ignoreAutomaticExclusion: false,
};
