import { yesNoOptions } from "../../../../../js/helpers";
import { format } from "../../../../../js/lib/Date";
import { createLoader } from "../../../../../js/react/components/general/notifications";
import { useState } from "react";
import { uctrans } from "../../../../../js/lib/Translator";
import Tippy from "@tippyjs/react";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import { OverviewContext } from "../../../../../js/react/components/general/overview/overview-context";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import Translate from "../../../../../js/react/components/general/Translate";
import axios from "axios";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../js/react/components/general/IconButton";
import DeleteDialog from "../../../../../js/react/components/general/DeleteDialog";

export default function QuestionnairesOverview() {
	const [overviewData, setOverviewData] = useState({
		overview: { ...window.data.overview },
		create_link: window.data.questionnaire_create_link,
		index_link: window.data.questionnaire_index_link,
		permissions: window.data.questionnaire_permissions,
		resultData: {
			errors: [],
			warnings: [],
		},
		dialogOpen: false,
		requestKind: "",
	});

	const deleteQuestionnaire = async questionnaire => {
		const result = await axios.get(questionnaire.delete_link);
		if (result.status === 200) {
			toggleDecisionDialog(result.data, "deleted");
		}
	};

	const toggleDecisionDialog = async (resultData = null, requestKind = null) => {
		setOverviewData({
			...overviewData,
			resultData,
			dialogOpen: !overviewData.dialogOpen,
			requestKind,
		});
	};

	const handleDialogSubmit = async overview => {
		const loader = createLoader(
			uctrans(`general.:item_is_being_${overviewData.requestKind}`, {}, { item: "questionnaires.singular" }),
		);
		const result =
			overviewData.requestKind === "deleted"
				? await axios.post(overviewData.resultData.destroy_link, { _method: "delete" })
				: await axios.put(overviewData.resultData.destroy_link);

		if (result.status === 200) {
			loader.success(uctrans(`general.${overviewData.requestKind}_:item`, {}, { item: "questionnaires.singular" }));
			toggleDecisionDialog();
			overview.loadData();
		} else {
			loader.failure("something went wrong deleting the questionnaire TODO -> show this error message");
		}
	};

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="questionnaires.plural" />
				</h1>
				{overviewData.create_link && (
					<div>
						<IconButton
							href={overviewData.create_link}
							content={uctrans("general.add_:item", {}, { item: "questionnaires.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={overviewData.index_link}
				renderFilters={() => (
					<OverviewContext.Consumer>
						{() => (
							<Filters>
								<SearchBarFilter type="text" name="search" />
								<SelectFilter
									className="form-2-3"
									name="user"
									label={uctrans("agendaitems.fields.user")}
									options={overviewData.overview.metadata.filterdata.users}
								/>
								<SelectFilter
									className="form-1-3"
									name="active"
									label={uctrans("questionnaires.fields.active")}
									options={yesNoOptions()}
									collapsible
								/>
								<div />
							</Filters>
						)}
					</OverviewContext.Consumer>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={10}
							sortable="name"
							renderHeaderContent={() => uctrans("questionnaires.fields.name")}
							renderCellContent={questionnaire => (
								<a href={questionnaire.edit_link} className="row-title link">
									{questionnaire.name}
								</a>
							)}
						/>
						<DataColumn
							width={6}
							renderHeaderContent={() => uctrans("questionnaires.fields.creator")}
							renderCellContent={questionnaire =>
								questionnaire.creator ? questionnaire.creator.label : uctrans("general.unknown")
							}
						/>
						<DataColumn
							width={1}
							sortable="active"
							renderHeaderContent={() => uctrans("questionnaires.fields.active")}
							renderCellContent={questionnaire =>
								questionnaire.active ? uctrans("general.yes") : uctrans("general.no")
							}
						/>
						<DataColumn
							width={3}
							sortable="start_date_time"
							renderHeaderContent={() => uctrans("questionnaires.fields.start_date_time")}
							renderCellContent={questionnaire =>
								questionnaire.start_date_time ? format(questionnaire.start_date_time, "dd-MM-y") : "-"
							}
						/>
						<DataColumn
							width={3}
							sortable="end_date_time"
							renderHeaderContent={() => uctrans("questionnaires.fields.end_date_time")}
							renderCellContent={questionnaire =>
								questionnaire.end_date_time ? format(questionnaire.end_date_time, "dd-MM-y") : "-"
							}
						/>
						{overviewData.permissions.delete && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(questionnaire, overview) =>
									questionnaire.delete_link && (
										<div className="overview-actions ">
											<a onClick={() => deleteQuestionnaire(questionnaire, overview)}>
												<Tippy
													placement="right"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("general.delete")}>
													<span>
														<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
													</span>
												</Tippy>
											</a>
										</div>
									)
								}
							/>
						)}
					</DataTable>
				)}
				renderNoResults={() => (
					<Translate content="overview.no_:items_to_show" transReplaces={{ items: "questionnaires.plural" }} />
				)}
				renderDeleteDialog={overview => (
					<DeleteDialog
						onConfirm={() => handleDialogSubmit(overview)}
						onCancel={() => setOverviewData({ ...overviewData, dialogOpen: false })}
						isOpen={overviewData.dialogOpen}
						deleteData={overviewData.resultData}
					/>
				)}
			/>
		</>
	);
}
