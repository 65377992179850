import { useCallback, useState } from "react";
import { route } from "../../../../../../js/helpers";
import { format, parse, default_date_format } from "../../../../../../js/lib/Date";
import Nav from "../../../../../../js/lib/Nav";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import DatePicker from "../../../../../../js/react/components/general/DatePicker";
import LabeledTextarea from "../../../../../../js/react/components/general/form/LabeledTextarea";
import LabeledTextInput from "../../../../../../js/react/components/general/form/LabeledTextInput";
import Radio from "../../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../../js/react/components/general/form/radio/RadioGroup";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../../js/react/components/general/Translate";
import FileInput from "../../forms/FileInput";
import Navigation from "../Navigation";
import AppOnboardingPageIcons from "./AppOnboardingPageIcons";

export default function AppOnboardingPageForm() {
	const { type } = window.data;
	const translationKey = type === 1 ? "onboarding" : "announcement";
	const creating = window.data.apponboardpage.id === -1;

	const [onboardPage, setOnboardPage] = useState(window.data.apponboardpage);

	const setOnboardPageField = useCallback(
		(field, value) => {
			setOnboardPage({
				...onboardPage,
				[field]: value,
			});
		},
		[onboardPage],
	);

	const setOnboardPageFieldFromEvent = useCallback(
		event => {
			const { type, checked, value, name } = event.target;
			const newValue = type === "checkbox" ? checked : value;
			setOnboardPageField(name, newValue);
		},
		[setOnboardPageField],
	);

	const onSuccess = useCallback(() => {
		Nav.go(route("crm.app-onboarding-pages.index", type));
	}, [type]);

	return (
		<>
			<Navigation selectedTab={type === 1 ? "onboarding" : "announcement"} />

			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(
						creating ? "general.add_:item" : "general.edit_:item",
						{},
						{ item: `app_onboarding_pages.${translationKey}.singular` },
					)}
				</h1>

				<a className="link" href={route("crm.app-onboarding-pages.index", type)}>
					{uctrans("general.back_to_overview_:page", {}, { page: `app_onboarding_pages.${translationKey}.plural` })}
				</a>
			</div>

			<AjaxForm
				method={creating ? "POST" : "PUT"}
				submitUrl={
					creating
						? route("crm.app-onboarding-pages.store", type)
						: route("crm.app-onboarding-pages.update", { type, app_onboarding_page: onboardPage.id })
				}
				data={onboardPage}
				onSuccess={onSuccess}
				useFlashMessage
				loaderText={uctrans(
					"general.:item_is_being_saved",
					{},
					{ item: `app_onboarding_pages.${translationKey}.singular` },
				)}
				successText={uctrans("general.saved_:item", {}, { item: `app_onboarding_pages.${translationKey}.singular` })}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<div className="form-full">
								<div className="form-1-3">
									<LabeledTextInput
										name="title"
										value={onboardPage.title}
										onChange={setOnboardPageFieldFromEvent}
										label="app_onboarding_pages.fields.title"
										required
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-2">
									<LabeledTextarea
										name="text"
										value={onboardPage.text}
										onChange={setOnboardPageFieldFromEvent}
										label="app_onboarding_pages.fields.text"
										required
									/>
								</div>
							</div>

							<div className="form-full">
								<div className="form-1-3">
									<label>
										<Translate content="app_onboarding_pages.fields.icon" /> <span className="text-orange">*</span>
									</label>
									<RadioGroup
										name="default_image"
										value={onboardPage.default_image}
										onChange={value => setOnboardPageField("default_image", value)}>
										<Radio label={uctrans("app_onboarding_pages.icon_use_default")} value />
										<Radio label={uctrans("app_onboarding_pages.icon_upload")} value={false} />
									</RadioGroup>
								</div>
							</div>

							{onboardPage.default_image ? (
								<div className="form-full">
									<div className="flex content-start">
										{AppOnboardingPageIcons.map(icon => (
											<div
												key={icon.key}
												className={`w-24 h-24 mr-4 flex flex-column content-center cursor-pointer ${
													icon.key === onboardPage.icon ? "border-4 border-secondary" : ""
												}`}
												onClick={() => setOnboardPageField("icon", icon.key)}>
												<img src={icon.img} className="" />
											</div>
										))}
									</div>
								</div>
							) : (
								<>
									<div className="form-full">
										<div className="form-1-3">
											<FileInput
												initialFiles={onboardPage.image}
												onChange={files => setOnboardPageField("image", files)}
												existingFileDownloadRoute="crm.app-onboarding-pages.download-image"
												additionalFileDownloadRouteProps={{ type, app_onboarding_page: onboardPage.id }}
												public
												fileType="image"
											/>
										</div>
									</div>

									<div className="form-full">
										<div className="form-1-3">
											<label className="container-checkbox">
												<input
													type="checkbox"
													name="big_icon"
													checked={onboardPage.big_icon}
													onChange={setOnboardPageFieldFromEvent}
												/>{" "}
												<span className="checkmark" /> <Translate content="app_onboarding_pages.fields.big_icon" />{" "}
											</label>
										</div>
									</div>
								</>
							)}

							<div className="form-full">
								{type === 1 ? (
									<div className="form-1-3">
										<label>
											<Translate content="app_onboarding_pages.fields.active" /> <span className="text-orange">*</span>
										</label>
										<RadioGroup
											name="active"
											value={onboardPage.active}
											onChange={value => setOnboardPageField("active", value)}>
											<Radio label={uctrans("general.yes")} value />
											<Radio label={uctrans("general.no")} value={false} />
										</RadioGroup>
									</div>
								) : (
									<>
										<div className="form-1-4">
											<label>
												<Translate content="app_onboarding_pages.fields.active_from" />{" "}
												<span className="text-orange">*</span>
												<br />
												<DatePicker
													initialValue={
														onboardPage.active_from ? parse(onboardPage.active_from, default_date_format) : null
													}
													onChange={date =>
														setOnboardPageField("active_from", date ? format(date, default_date_format) : null)
													}
												/>
											</label>
										</div>
										<div className="form-1-4">
											<label>
												<Translate content="app_onboarding_pages.fields.active_until" />{" "}
												<span className="text-orange">*</span>
												<br />
												<DatePicker
													initialValue={
														onboardPage.active_until ? parse(onboardPage.active_until, default_date_format) : null
													}
													onChange={date =>
														setOnboardPageField("active_until", date ? format(date, default_date_format) : null)
													}
												/>
											</label>
										</div>
									</>
								)}
							</div>

							<div className="form-full">
								<div className="w-full md:w-1/2 lg:w-1/3 column">
									<SubmitBar item={`app_onboarding_pages.${translationKey}.singular`} addAnotherAvailable={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
