import PropTypes from "prop-types";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";

export default function MediaSelectorVideo({ video, onRemove }) {
	return (
		<div className="relative mt-2 p-2 shadow bg-white h-32 flex justify-center">
			<img className="max-h-full  border border-gray" src={video.thumbnail_url} alt={video.name} />
			<span onClick={onRemove} className="absolute top-0 right-0 bg-grey-darker opacity-75 p-1 pb-0 cursor-pointer">
				<EvaIcon type="trash-2-outline" width="24" height="24" fill="#de751f" />
			</span>
		</div>
	);
}

MediaSelectorVideo.propTypes = {
	video: PropTypes.object.isRequired,
	onRemove: PropTypes.func.isRequired,
};
