import { useState } from "react";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import NotificationPreferences from "../../../../../js/react/components/notifications/NotificationPreferences";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import Translate from "../../../../../js/react/components/general/Translate";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Select from "../../../../../js/react/components/general/Select";
import Tippy from "@tippyjs/react";

export default function RoleForm() {
	const [formData, setFormData] = useState({ ...window.data.form_data });
	const [staticData] = useState({ ...window.data.static_data });

	const handleSelectChange = (selectedValue, metaData) => {
		const { name } = metaData;
		const { value } = selectedValue;

		setFormData({ ...formData, [name]: value });
	};

	const handleInputChange = e => {
		const { target } = e;

		const value = target.type === "checkbox" ? target.checked : target.value;

		setFormData({ ...formData, [target.name]: value });
	};

	return (
		<>
			<BreadCrumbs
				breadCrumbs={[
					{ label: uctrans("general.security_settings") },
					{ label: uctrans("roles.plural"), route: staticData.index_link },
					{
						label:
							staticData.save_method === "POST"
								? uctrans("general.add_:item", {}, { item: "roles.singular" })
								: formData.name,
					},
				]}
			/>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(
						staticData.save_method === "POST" ? "general.add_:item" : "general.edit_:item",
						{},
						{ item: "roles.singular" },
					)}
				</h1>
				{staticData.index_link && (
					<a className="link" href={staticData.index_link}>
						{uctrans("general.back_to_overview_:page", {}, { page: "roles.plural" })}
					</a>
				)}
			</div>
			<AjaxForm
				method={staticData.save_method}
				submitUrl={staticData.save_link}
				successUrl={staticData.index_link}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "roles.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "roles.singular" })}
				data={{ ...formData }}
				useFlashMessage>
				<div className="form-container ">
					<div className="w-1/4">
						<label htmlFor="name">
							<Translate content="roles.fields.name" />
						</label>
						<input
							id="name"
							type="text"
							name="name"
							value={formData.name}
							onChange={handleInputChange}
							autoComplete="off"
						/>
					</div>
				</div>
				<div className="form-container flex mb-10">
					<div className="w-1/3">
						<label htmlFor="crm_permissiongroup_id">
							<Translate content="roles.fields.crm_permissiongroup" />
						</label>
						<Select
							placeholder={uctrans("general.select_:item", {}, { item: "roles.fields.group" })}
							value={staticData.crm_permissiongroup_options.filter(
								({ value }) => Number(value) === Number(formData.crm_permissiongroup_id),
							)}
							options={staticData.crm_permissiongroup_options}
							name="crm_permissiongroup_id"
							onChange={(selectedValue, metaData) => handleSelectChange(selectedValue, metaData)}
							isClearable={false}
						/>
					</div>
					<div className="w-1/3 ml-4">
						<label htmlFor="website_permissiongroup_id">
							<Translate content="roles.fields.website_permissiongroup" />
						</label>
						<Select
							placeholder={uctrans("general.select_:item", {}, { item: "roles.fields.group" })}
							value={staticData.website_permissiongroup_options.filter(
								({ value }) => Number(value) === Number(formData.website_permissiongroup_id),
							)}
							options={staticData.website_permissiongroup_options}
							name="website_permissiongroup_id"
							onChange={(selectedValue, metaData) => handleSelectChange(selectedValue, metaData)}
							isClearable={false}
						/>
					</div>
				</div>

				<h3>{uctrans("roles.properties")}</h3>

				<div className="form-container ">
					<div className="form-full">
						<label className="container-checkbox">
							<Translate content="roles.fields.is_relationmanager" />
							<input
								type="checkbox"
								checked={formData.is_relationmanager}
								name="is_relationmanager"
								onChange={e => handleInputChange(e)}
							/>
							<span className="checkmark" />
						</label>
					</div>
					<div className="form-full">
						<label className="container-checkbox">
							<Translate content="roles.fields.is_account_support" />
							<input
								type="checkbox"
								checked={formData.is_account_support}
								name="is_account_support"
								onChange={e => handleInputChange(e)}
							/>
							<span className="checkmark" />
						</label>
					</div>

					<div className="form-full">
						<label className="container-checkbox">
							<Translate content="roles.fields.aahg_user_selectable" />
							<input
								type="checkbox"
								checked={formData.aahg_user_selectable}
								name="aahg_user_selectable"
								onChange={e => handleInputChange(e)}
							/>
							<span className="checkmark" />
							<Tippy
								placement="top"
								arrow
								animation="perspective"
								duration="400"
								content={uctrans("roles.aahg_user_selectable_description")}>
								<span>
									<EvaIcon type="info-outline" fill="#249286" height="18" width="18" />
								</span>
							</Tippy>
						</label>
					</div>

					<div className="form-full">
						<h3>{uctrans("roles.notification_preferences")}</h3>
						<NotificationPreferences
							preferences={formData.notification_preferences}
							onChange={value => {
								setFormData({ ...formData, notification_preferences: value });
							}}
							notifictionTypesPerCategory={staticData.notification_types_per_category}
						/>
					</div>

					<div className="form-full">
						<SubmitBar item="roles.singular" addAnotherAvailable={false} />
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
