import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import map from "lodash/map";
import HighChart from "../../../report/graph/HighChart";

const NewsletterStatisticsEventColumnChart = props => {
	const convertEventCountsToSeries = () => {
		const serieData = [];
		each(props.event_counts, stat_data => {
			serieData.push({
				name: stat_data.label,
				tooltip: stat_data.hint,
				y: stat_data.value,
			});
		});
		return serieData;
	};

	const options = cloneDeep(props.config);

	options.xAxis.categories = map(props.event_counts, columnData => columnData.label);
	options.series[0].data = convertEventCountsToSeries();
	options.yAxis.title.text = "Aantal";
	options.tooltip = {
		pointFormat: "{point.tooltip} ({point.y:0f})",
	};

	return <HighChart chartConfig={options} />;
};

NewsletterStatisticsEventColumnChart.propTypes = {
	event_counts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	config: PropTypes.object,
};

export default NewsletterStatisticsEventColumnChart;
