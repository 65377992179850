import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { format } from "../../../../../js/lib/Date";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import LabeledTextarea from "../../../../../js/react/components/general/form/LabeledTextarea";
import Radio from "../../../../../js/react/components/general/form/radio/Radio";
import RadioGroup from "../../../../../js/react/components/general/form/radio/RadioGroup";
import Select from "../../../../../js/react/components/general/Select";
import Translate from "../../../../../js/react/components/general/Translate";
import CollapsibleContent from "../general/CollapsibleContent";
import CompanyPermits from "../company/permits/CompanyPermits";
import AgendaItemDateFields from "./AgendaItemDateFields";
import AgendaItemFormFields from "./AgendaItemFormFields";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import cloneDeep from "lodash/cloneDeep";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import { route } from "../../../../../js/helpers";
import AgendaItemCompanyFields from "./AgendaItemCompanyFields";
import Navigation from "./Navigation";

export default function AfmCheckForm(props) {
	const disabled = !window.data.afmcheck_form_data.status ? window.data.read_only : true;

	const [formData, setFormData] = useState(
		typeof props.AfmCheckFormData === "undefined" ? cloneDeep(window.data.afmcheck_form_data) : props.AfmCheckFormData,
	);

	const [staticData, setStaticData] = useState(
		typeof props.AfmCheckStaticData === "undefined"
			? cloneDeep(window.data.afmcheck_static_data)
			: props.AfmCheckStaticData,
	);

	const afmCheckActionOptions = staticData.afm_check_actions.map(action => ({
		value: action.id,
		label: action.name,
	}));

	const selectedAfmCheckAction =
		Number(formData.action_id) !== Number(0)
			? staticData.afm_check_actions.find(action => Number(action.id) === Number(formData.action_id))
			: null;

	useEffect(() => {
		if (typeof props.AfmCheckFormData !== "undefined") {
			setFormData(props.AfmCheckFormData);
		}
	}, [props.AfmCheckFormData]);

	useEffect(() => {
		if (typeof props.AfmCheckStaticData !== "undefined") {
			setStaticData(props.AfmCheckStaticData);
		}
	}, [props.AfmCheckStaticData]);

	const onSuccess = () => {
		if (window.data.agendaNamespace || staticData.creating) {
			Nav.go(route("crm.agenda-items.index"));
		} else {
			if (staticData.statutory) {
				Nav.go(route("crm.intermediaries.agenda-items.index", { intermediary: staticData.main_company.id }));
			} else if (staticData.intermediary) {
				Nav.go(route("crm.intermediaries.agenda-items.index", { intermediary: formData.company.id }));
			} else if (staticData.organisation) {
				Nav.go(route("crm.organisations.agenda-items.index", { organisation: formData.company.id }));
			}
		}
	};

	const handleFormValueChange = (key, value) => {
		const newFormData = { ...formData };
		newFormData[key] = value;
		setFormData(newFormData);
	};

	const previousURL = () => {
		if (window.data.agendaNamespace) {
			return route("crm.agenda-items.index");
		}

		if (staticData.statutory) {
			return route("crm.intermediaries.agenda-items.index", { intermediary: staticData.main_company.id });
		} else if (staticData.intermediary) {
			return route("crm.intermediaries.agenda-items.index", { intermediary: formData.company.id });
		} else if (staticData.organisation) {
			return route("crm.organisations.agenda-items.index", { organisation: formData.company.id });
		}
	};

	const submitURL = () => {
		if (!staticData.creating) {
			if (staticData.statutory) {
				return route(`crm.statutory-companies.afm-checks.update`, [
					formData.agenda_item.statutory_company_id,
					formData.id,
				]);
			} else if (staticData.intermediary) {
				return route(`crm.intermediaries.afm-checks.update`, [formData.agenda_item.company_id, formData.id]);
			} else if (staticData.organisation) {
				return route(`crm.organisations.afm-checks.update`, [formData.agenda_item.company_id, formData.id]);
			}
		} else if (staticData.creating) {
			if (staticData.intermediary) {
				return route(`crm.intermediaries.afm-checks.store`, [formData.company_id]);
			} else if (staticData.organisation) {
				return route(`crm.organisations.afm-checks.store`, [formData.company_id]);
			}
		}
	};

	return (
		<>
			{!staticData.creating && (
				<Navigation
					selectedTab={window.data.nav_data.activeTabItem}
					tabs={window.data.nav_data.tabs}
					breadCrumbs={window.data.nav_data.breadcrumbs}
				/>
			)}

			<div className="page-title-container">
				<h1 className="page-title">
					{window.data.company_name
						? uctrans(
								"general.:item1 from :item2",
								{ item2: window.data.company_name },
								{ item1: "afm_permits.afm_check" },
						  )
						: uctrans("afm_permits.afm_check")}
				</h1>
				{window.data.agendaNamespace || staticData.creating ? (
					<a className="link" href={route("crm.agenda-items.index")}>
						{uctrans("general.back_to_:page", {}, { page: "agendaitems.accountsupport" })}
					</a>
				) : (
					<a className="link" href={previousURL()}>
						{uctrans("general.back_to_overview_:page", {}, { page: "agendaitems.plural" })}
					</a>
				)}
			</div>

			<AjaxForm
				method={staticData.creating ? "POST" : "PUT"}
				submitUrl={submitURL()}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "afm_permits.afm_check" })}
				successText={uctrans("general.saved_:item", {}, { item: "afm_permits.afm_check" })}
				data={{ ...formData }}
				onSuccess={onSuccess}
				useFlashMessage={staticData.creating || window.data.agendaNamespace}>
				<div className="min-h-screen md:flex">
					<div className="flex-1 w-2/3 mr-12">
						<div className="form-container">
							<AgendaItemFormFields
								onChange={value => handleFormValueChange("agenda_item", value)}
								agendaItemFormData={formData.agenda_item}
								agendaItemStaticData={staticData}
								editable={!disabled}
								showAgendaDate
							/>

							<div className="form-full">
								<CollapsibleContent initialCollapseState={false} title={uctrans("afm_permits.current_permits")}>
									<CompanyPermits
										permitData={formData.actual_afm_permits}
										requiredTypes={staticData.afm_permit_required_types}
										requiredPermits={staticData.required_permits}
										missingPermits={staticData.missing_permits}
										intermediary={staticData.intermediary}
									/>
								</CollapsibleContent>
							</div>

							<div className="form-full">
								<CollapsibleContent initialCollapseState={false} title={uctrans("afm_permits.illegality")}>
									<div className="flex justify-start text-xs">
										<label className="flex-1 mr-2">{uctrans("afm_permits.afm_number_during_check")}</label>
										<div className="mt-1 flex-1">
											<span>{formData.afm_number}</span>
										</div>
									</div>
									{formData.missing_afm_permits.length ? (
										<>
											<br />
											<strong>{uctrans("afm_permits.missing_permits")}</strong>
											{formData.missing_afm_permits.map((permit, index) => (
												<div key={index} className="flex justify-start text-xs">
													<label className="flex-1 mr-2">{permit.product_name}</label>
													<div className="mt-1 flex-1">
														<span>{permit.service_name}</span>
													</div>
												</div>
											))}
										</>
									) : null}
									{formData.different_kvk_number || formData.kvk_ended ? (
										<>
											<br />
											<strong className="text-red">{uctrans("afm_permits.different_kvk")}</strong>
											<div className="flex justify-start text-xs">
												<label className="flex-1 mr-2">{uctrans("afm_permits.kvk_number_afm")}</label>
												<div className="mt-1 flex-1">
													<span>
														{formData.kvk_number_afm_count === 1
															? formData.kvk_number_afm
															: uctrans("afm_permits.multiple_:nr_kvk_numbers", { nr: formData.kvk_number_afm_count })}
													</span>
												</div>
											</div>
											<div className="flex justify-start text-xs">
												<label className="flex-1 mr-2">{uctrans("afm_permits.kvk_number_abn")}</label>
												<div className="mt-1 flex-1">
													<span>{formData.kvk_number_abn}</span>
												</div>
											</div>
										</>
									) : null}
								</CollapsibleContent>
							</div>

							<div className="form-full">
								<label htmlFor="description">
									<Translate content="afm_permits.fields.description" />
								</label>
								<textarea
									id="concerns"
									name="concerns"
									value={formData.concerns}
									onChange={e => handleFormValueChange(e.target.name, e.target.value)}
									placeholder={uctrans("afm_permits.fields.description_placeholder")}
									autoComplete="off"
								/>
							</div>

							<div className="form-full">
								<Checkmark
									disabled={disabled}
									label={uctrans("afm_permits.fields.handled")}
									name="status"
									checked={formData.status}
									onChange={e => handleFormValueChange(e.target.name, e.target.checked)}
								/>
							</div>

							{Number(formData.status) === Number(1) && (
								<>
									<div className="form-full">
										<Select
											isClearable
											disabled={disabled}
											value={afmCheckActionOptions.filter(({ value }) => Number(value) === Number(formData.action_id))}
											options={afmCheckActionOptions}
											name="action_id"
											onChange={selectedValue =>
												handleFormValueChange("action_id", selectedValue ? selectedValue.value : null)
											}
											placeholder={uctrans("general.select_:item", {}, { item: "afm_permits.check_actions.singular" })}
										/>
									</div>
									{selectedAfmCheckAction && selectedAfmCheckAction.is_open && (
										<div className="form-full">
											<label htmlFor="action_value">{selectedAfmCheckAction.open_title}</label>
											<textarea
												disabled={disabled}
												id="action_value"
												name="action_value"
												value={formData.action_value}
												onChange={e => handleFormValueChange(e.target.name, e.target.value)}
												autoComplete="off"
											/>
										</div>
									)}
									<div className="form-full">
										<label htmlFor="post">{uctrans("afm_permits.fields.post")}</label>
										<RadioGroup
											name="post"
											disabled={disabled}
											value={formData.post}
											onChange={postValue => handleFormValueChange("post", postValue)}>
											<Radio label={uctrans("general.no")} value={0} />
											<Radio label={uctrans("general.yes")} value={1} />
										</RadioGroup>
									</div>
									{formData.post ? (
										<div className="form-1-2">
											<LabeledTextarea
												disabled={disabled}
												label="afm_permits.fields.post_dates"
												id="post_dates"
												name="post_dates"
												value={formData.post_dates}
												onChange={e => handleFormValueChange(e.target.name, e.target.value)}
											/>
										</div>
									) : null}
								</>
							)}

							<div className="form-full">
								<div className="row">
									<div className="w-full md:w-1/2 lg:w-1/3 column">
										<SubmitBar item="afm_permits.afm_check" addAnotherAvailable={false} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="flex-none w-1/3 border-l pl-12">
						<AgendaItemDateFields agendaItem={formData.agenda_item} />

						<AgendaItemCompanyFields statutory companyId={formData.agenda_item.statutory_company_id} />

						<div className="form-container -mr-0">
							<CollapsibleContent
								title={uctrans("afm_permits.fields.afm_permit_changelog")}
								initialCollapseState={false}>
								{staticData.afm_changelogs.length ? (
									staticData.afm_changelogs.map((changeLog, index) => (
										<div key={index} className="flex w-full justify-start text-xs">
											<span className="font-bold mr-5 w-1/5">{format(changeLog.datetime, "dd-MM-y")}</span>
											<div className="flex w-full justify-start">
												{Number(changeLog.type) === Number(staticData.afm_changelog_types.NEW.key) ? (
													<EvaIcon type="plus-circle-outline" fill="#009286" height="18" width="18" />
												) : (
													<EvaIcon type="minus-circle-outline" fill="#f9ACAA" height="18" width="18" />
												)}
												<span className="ml-5">{`${changeLog.afm_product_label} - ${changeLog.afm_service_label} ${
													changeLog.different_date_from_afm ? ` (${format(changeLog.date_afm, "dd-MM-y")})` : ""
												}`}</span>{" "}
											</div>
										</div>
									))
								) : (
									<span>geen changelog items om te tonen</span>
								)}
							</CollapsibleContent>
						</div>
					</div>
				</div>
			</AjaxForm>
		</>
	);
}
AfmCheckForm.propTypes = {
	AfmCheckFormData: PropTypes.object,
	AfmCheckStaticData: PropTypes.object,
};
