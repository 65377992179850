import PropTypes from "prop-types";
import { useState } from "react";
import { format } from "../../../../../js/lib/Date";
import { uctrans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import { route } from "../../../../../js/helpers";

export default function AsExportsDashboardBlock(props) {
	const [isCollapsed, setCollapsed] = useState(
		window.data.collapsed_dashboard_blocks.account_support_exports_dashboard_block === "1",
	);

	return (
		<div className=" vertical-spacer spacer flex">
			<div className="bg-grey-lightest shadow rounded flex flex-col w-full">
				<div className="p-6 flex">
					<a
						className="no-underline text-grey-darker w-full cursor"
						onClick={() => {
							const collapsed = !isCollapsed;
							if (props.rememberCollapsed) {
								props.rememberCollapsed("account_support_exports_dashboard_block", collapsed);
							}
							setCollapsed(collapsed);
						}}>
						<div className="w-full flex justify-between">
							<div>
								<h5 className="mb-0">{uctrans("dashboard.as_exports")}</h5>
							</div>
							<div>
								<EvaIcon fill="#000000" width="18" height="18" type={isCollapsed ? "plus" : "minus"} />
							</div>
						</div>
					</a>
				</div>
				{!isCollapsed && (
					<div className="px-6 pb-6">
						<a className="button button-primary" href={route("crm.dashboard.dossier-export-all")}>
							{uctrans("dashboard.dossier_export_all")}
						</a>
						<a className="button button-primary" href={route("crm.dashboard.dossier-export")}>
							{uctrans("dashboard.dossier_export")}
						</a>
						<a className="button button-primary" href={route("crm.dashboard.dossier-organisation-export")}>
							{uctrans("dashboard.dossier_organisation_export")}
						</a>
						<div className="pt-6">
							{window.data.lastImport && (
								<p>
									{uctrans("intermediaries.last_intermediaries_import")}:{" "}
									{uctrans("general.:date_at_:time", {
										date: format(window.data.lastImport, "dd-MM-y"),
										time: format(window.data.lastImport, "HH:mm"),
									})}
								</p>
							)}
							<a className="button button-primary" href={route("crm.dashboard.ishs-differences-report")}>
								{uctrans("dashboard.ishs_differences_report")}
							</a>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

AsExportsDashboardBlock.propTypes = {
	rememberCollapsed: PropTypes.func,
};
