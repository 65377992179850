import each from "lodash/each";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import HighChart from "../../report/graph/HighChart";
import { uctrans } from "../../../../../../js/lib/Translator";

const LoanResultsLineChart = props => {
	const [series, setSeries] = useState([]);

	const getColorForStatus = status => {
		switch (status) {
			case "320":
				return "#249286";
		}
		return "";
	};

	useEffect(() => {
		const serieData = {
			320: [],
		};
		each(props.productionData, (statusData, statusCode) => {
			each(statusData, (dayData, date) => {
				const parsedDate = Date.parse(date);
				if (props.productionMode === "sum") {
					serieData[statusCode].push([parsedDate, parseInt(dayData.sum) / props.divideTooltipValueBy]);
				} else {
					serieData[statusCode].push([parsedDate, parseInt(dayData.count)]);
				}
			});
		});
		const newSeries = [];
		if (props.targetStatistics.length && props.year > 0) {
			props.targetStatistics.forEach(targetData => {
				const target =
					props.productionMode === "sum"
						? parseInt(targetData.sum.target) / props.divideTooltipValueBy
						: parseInt(targetData.count.target);

				const ambition =
					props.productionMode === "sum"
						? parseInt(targetData.sum.ambition) / props.divideTooltipValueBy
						: parseInt(targetData.count.ambition);

				if (targetData.statusCode === 320) {
					if (target > 0) {
						newSeries.push({
							data: [
								[Date.parse(`${props.year}-01-01`), 0],
								[Date.parse(`${props.year}-12-31`), target],
							],
							color: "#F3C000",
							name:
								props.productionMode === "sum"
									? uctrans("dashboard.target_year_passed")
									: uctrans("dashboard.target_year_count"),
						});
					}
					if (ambition > 0) {
						newSeries.push({
							data: [
								[Date.parse(`${props.year}-01-01`), 0],
								[Date.parse(`${props.year}-12-31`), ambition],
							],
							color: "#FF6600",
							name:
								props.productionMode === "sum"
									? uctrans("dashboard.ambition_passed")
									: uctrans("dashboard.ambition_count"),
						});
					}
				}
			});
		}

		Object.entries(serieData).forEach(([status, data]) => {
			newSeries.push({
				data,
				color: getColorForStatus(status),
				name: props.productionMode === "sum" ? uctrans(`dashboard.passed`) : uctrans(`dashboard.count`),
			});
		});

		setSeries(newSeries);
	}, [props.productionData, props.targetStatistics, props.year, props.productionMode, props.divideTooltipValueBy]);

	return (
		<HighChart
			chartConfig={{
				chart: {
					height: "300px",
					backgroundColor: null,
				},
				title: {
					text: null,
				},
				legend: {
					enabled: true,
					align: "center",
					verticalAlign: "top",
					floating: true,
					itemStyle: {
						fontSize: "10px",
						fontWeight: "normal",
					},
				},
				yAxis: {
					title: {
						text: null,
					},
				},
				xAxis: {
					min: Date.UTC(props.year, 0, 0),
					max: Date.UTC(props.year, 11, 31),
					type: "datetime",
					tickInterval: 24 * 3600 * 1000 * 30,
					dateTimeLabelFormats: {
						month: "%b",
					},
				},
				tooltip: {
					dateTimeLabelFormats: {
						day: "%A %e %B %Y",
					},
					pointFormat: props.productionMode === "sum" ? "€ {point.y:,.0f}" : "{point.y:,.0f}",
				},
				series,
			}}
		/>
	);
};

LoanResultsLineChart.propTypes = {
	year: PropTypes.number,
	productionMode: PropTypes.string,
	productionData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	targetStatistics: PropTypes.array,
	divideTooltipValueBy: PropTypes.number,
};

LoanResultsLineChart.defaultProps = {
	dashboardScope: {},
	productionMode: "sum",
	divideTooltipValueBy: 1,
};

export default LoanResultsLineChart;
