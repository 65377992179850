import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Nav from "../../../../../js/lib/Nav";
import { uctrans } from "../../../../../js/lib/Translator";
import { can, route } from "../../../../../js/helpers";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import Navigation from "./Navigation";

export default function ClientTreatmentBadgesForm(props) {
	const [badges, setBadges] = useState(
		typeof props.ClientTreatmentFormData === "undefined"
			? cloneDeep(window.data.client_treatment_form_data.badges)
			: props.ClientTreatmentFormData,
	);

	useEffect(() => {
		if (typeof props.ClientTreatmentFormData !== "undefined") {
			setStaticData(props.ClientTreatmentFormData);
		}
	}, [props.ClientTreatmentFormData]);

	const [staticData, setStaticData] = useState(
		typeof props.ClientTreatmentStaticData === "undefined"
			? cloneDeep(window.data.client_treatment_static_data)
			: props.ClientTreatmentStaticData,
	);

	useEffect(() => {
		if (typeof props.ClientTreatmentStaticData !== "undefined") {
			setStaticData(props.ClientTreatmentStaticData);
		}
	}, [props.ClientTreatmentStaticData]);

	const changeBadgeEntry = (index, key, value) => {
		const newBadges = [...badges];
		newBadges[index][key] = value;
		setBadges(newBadges);
	};

	const addBadge = () => {
		setBadges([
			...badges,
			{
				name: "",
				employment: "",
				use_badge: true,
			},
		]);
	};

	const CheckUncheckAll = use_badge => {
		setBadges(map(badges, badge => ({ ...badge, use_badge })));
	};

	const generateBadges = async () => {
		const result = await axios.post(
			route("crm.client-treatments.completions.generate-badges", {
				client_treatment: staticData.client_treatment.id,
			}),
			{
				badges,
			},
		);

		if (result.data.success) {
			Nav.go(
				route("crm.client-treatments.completions.download-badges", {
					client_treatment: staticData.client_treatment.id,
					download_key: result.data.download_key,
				}),
			);
		}
	};

	return (
		<>
			<Navigation
				selectedTab="client-treatment-completions"
				canUpdate={can("client_treatment.client_treatment", "update")}
				loggedInInviter={
					window.data.user
						? staticData.client_treatment.inviters.find(
								inviter => Number(inviter.user_id) === Number(window.data.user.id),
						  )
						: null
				}
				clientTreatment={staticData.client_treatment}
				clientTreatmentTypes={staticData.client_treatment_types}
			/>
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("client_treatment.fields.badges")}</h1>
			</div>

			{map(badges, (participant, index) => (
				<div key={index} className="w-1/2 float-left block mt-5 bg-primary-lightest blocks-center px-3 py-3 my-2 ">
					<input
						type="text"
						className="form-3-4"
						name={`badge${index}-name`}
						value={participant.name}
						onChange={e => changeBadgeEntry(index, "name", e.target.value)}
					/>
					<input
						type="text"
						className="form-3-4"
						name={`badge${index}-company`}
						value={participant.employment}
						onChange={e => changeBadgeEntry(index, "employment", e.target.value)}
					/>
					<Checkmark
						className="form-3-4"
						label={uctrans("client_treatment.fields.use_badge")}
						name={`badge${index}-usebadge`}
						checked={participant.use_badge}
						onChange={e => changeBadgeEntry(index, "use_badge", e.target.checked)}
					/>
				</div>
			))}

			<div className="flex w-full justify-start">
				<div className="w-full mt-3">
					<a className="button button-secondary" onClick={() => generateBadges()}>
						{uctrans("client_treatment.fields.generate_badges")}
					</a>
				</div>

				<div className="flex w-full justify-end mt-6">
					<a className="button button-tertiary" onClick={() => addBadge()}>
						{`+ ${uctrans("client_treatment.fields.add_new_badge")}`}
					</a>
				</div>
			</div>
			<div className="flex justify-end mt-3">
				<a className="link" onClick={() => CheckUncheckAll(true)}>
					{uctrans("client_treatment.fields.use_everything")}
				</a>
				<span className="ml-1 mr-1">|</span>
				<a className="link" onClick={() => CheckUncheckAll(false)}>
					{uctrans("client_treatment.fields.use_nothing")}
				</a>
			</div>
		</>
	);
}
ClientTreatmentBadgesForm.propTypes = {
	ClientTreatmentFormData: PropTypes.object,
	ClientTreatmentStaticData: PropTypes.object,
};
