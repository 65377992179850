import { createLoader } from "../../../../../../js/react/components/general/notifications";
import { useState } from "react";
import { format } from "../../../../../../js/lib/Date";
import Nav from "../../../../../../js/lib/Nav";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import DeleteDialog from "../../../../../../js/react/components/general/DeleteDialog";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import SearchBarFilter from "../../../../../../js/react/components/general/overview/SearchBarFilter";
import SelectFilter from "../../../../../../js/react/components/general/overview/SelectFilter";
import Translate from "../../../../../../js/react/components/general/Translate";
import axios from "axios";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import Tippy from "@tippyjs/react";
import { can, route, yesNoOptions } from "../../../../../../js/helpers";
import Navigation from "../Navigation";

export default function AahgUserOverview() {
	const [overviewData, setOverviewData] = useState({
		overview: { ...window.data.overview },
		deactivated: window.data.deactivated,
		resultData: {
			errors: [],
			warnings: [],
		},
		dialogOpen: false,
		requestKind: "",
	});

	const deleteUser = async user => {
		const result = await axios.get(route("crm.aahg-users.delete", user.id));
		if (result.status === 200) {
			await toggleDecisionDialog(result.data, "deleted");
		}
	};

	const deactivateUser = async user => {
		const result = await axios.get(route("crm.aahg-users.askdeactivate", user.id));
		if (result.status === 200) {
			await toggleDecisionDialog(result.data, "deactivated");
		}
	};

	const reactivateUser = async user => {
		const result = await axios.get(route("crm.aahg-users.askreactivate", user.id));
		if (result.status === 200) {
			await toggleDecisionDialog(result.data, "reactivated");
		}
	};

	const toggleDecisionDialog = async (resultData = null, requestKind = null) => {
		setOverviewData({
			...overviewData,
			resultData,
			dialogOpen: !overviewData.dialogOpen,
			requestKind,
		});
	};

	const handleDialogSubmit = async overview => {
		const loader = createLoader(
			uctrans(`general.:item_is_being_${overviewData.requestKind}`, {}, { item: "users.aahg_users.singular" }),
		);
		const result =
			overviewData.requestKind === "deleted"
				? await axios.post(overviewData.resultData.destroy_link, { _method: "delete" })
				: await axios.put(overviewData.resultData.destroy_link);

		if (result.status === 200) {
			loader.success(uctrans(`general.${overviewData.requestKind}_:item`, {}, { item: "users.aahg_users.singular" }));
			await toggleDecisionDialog();
			overview.loadData();
		} else {
			loader.failure("something went wrong deleting the user TODO -> show this error message");
		}
	};

	const switchOverviewText = () => {
		if (overviewData.deactivated) {
			return uctrans("users.show_overview_activated:item", {}, { item: "users.aahg_users.plural" });
		}
		return uctrans("users.show_overview_deactivated:item", {}, { item: "users.aahg_users.plural" });
	};

	const isSelf = user => window.data.user && user.id === window.data.user.id;
	const isAahgManageble = user => user.role && user.role.aahg_user_selectable;

	return (
		<>
			<Navigation selectedTab="aahg-users" />

			<div className="page-title-container">
				<h1 className="page-title">
					<Translate
						content={overviewData.deactivated ? "users.aahg_users.deactivated_plural" : "users.aahg_users.plural"}
					/>
				</h1>
				{!overviewData.deactivated && can("user", "aahg_create") && (
					<div>
						<IconButton
							href={route("crm.aahg-users.create")}
							content={uctrans("general.add_:item", {}, { item: "users.aahg_users.singular" })}
							primarya
						/>
					</div>
				)}
			</div>
			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={overviewData.deactivated ? route("crm.aahg-users.data-deactivated") : route("crm.aahg-users.data")}
				modelTranslations={{ plural: trans("users.aahg_users.plural"), singular: trans("users.aahg_users.singular") }}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter className="form-full" type="text" name="search" />
						<SelectFilter
							className="form-1-2"
							name="in_overviews"
							label={uctrans("users.fields.in_overviews")}
							options={yesNoOptions()}
							collapsible
						/>
						<SelectFilter
							className="form-1-2"
							name="role_id"
							label={uctrans("roles.singular")}
							options={overviewData.overview.filterdata.roles}
							collapsible
						/>
						<SelectFilter
							className="form-1-2"
							name="website_permission_groups"
							label={uctrans("roles.fields.website_permissiongroup")}
							options={overviewData.overview.filterdata.website_permission_groups}
							collapsible
						/>
						<SelectFilter
							className="form-1-2"
							name="team_id"
							label={uctrans("teams.singular")}
							options={overviewData.overview.filterdata.teams}
							collapsible
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={2}
							renderHeaderContent={() => ""}
							renderCellContent={user =>
								user.has_avatar ? (
									<div className="avatar-img">
										<img
											className="max-h-full self-center"
											src={route("crm.aahg-users.avatar-thumbnail", user.id)}
											alt={user.full_name}
										/>
									</div>
								) : (
									<div className="avatar-img">
										<EvaIcon type="person" fill="#249286" height="35" width="35" />
									</div>
								)
							}
						/>
						<DataColumn
							width={4}
							sortable="last_name"
							renderHeaderContent={() => uctrans("users.fields.name")}
							renderCellContent={user => (
								<a
									href={route(
										can("user", "aahg_update") && !overviewData.deactivated && isAahgManageble(user)
											? "crm.aahg-users.edit"
											: "crm.aahg-users.show",
										user.id,
									)}
									className="row-title link">
									{user.full_name}
									{isSelf(user) && <small>&nbsp;({uctrans("general.yourself")})</small>}
								</a>
							)}
						/>
						<DataColumn
							width={2}
							sortable="short_name"
							renderHeaderContent={() => uctrans("users.fields.short_name")}
							renderCellContent={user => user.short_name && user.short_name}
						/>
						<DataColumn
							width={4}
							sortable="email"
							renderHeaderContent={() => uctrans("users.fields.email")}
							renderCellContent={user => user.email && <span className="word-break">{user.email}</span>}
						/>
						<DataColumn
							width={3}
							sortable="role.name"
							renderHeaderContent={() => uctrans("users.fields.role")}
							renderCellContent={user => user.role && user.role.name}
						/>
						<DataColumn
							width={2}
							sortable="last_crm_login_log"
							renderHeaderContent={() => uctrans("users.im360")}
							renderCellContent={user =>
								user.last_crm_login_log === "n/a"
									? trans("general.n/a")
									: user.last_crm_login_log
									  ? format(user.last_crm_login_log, "dd-MM-y HH:mm")
									  : "-"
							}
						/>
						<DataColumn
							width={2}
							sortable="last_site_login_log"
							renderHeaderContent={() => uctrans("users.website")}
							renderCellContent={user =>
								user.last_site_login_log === "n/a"
									? trans("general.n/a")
									: user.last_site_login_log
									  ? format(user.last_site_login_log, "dd-MM-y HH:mm")
									  : "-"
							}
						/>
						{overviewData.deactivated && can("user", "aahg_reactivate") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(user, overview) =>
									isAahgManageble(user) && (
										<div className="overview-actions text-right">
											<a onClick={() => reactivateUser(user, overview)}>
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("users.fields.reactivate")}>
													<span>
														<EvaIcon type="power-outline" fill="#9c9c9c" height="18" width="18" />
													</span>
												</Tippy>
											</a>
										</div>
									)
								}
							/>
						)}
						{!overviewData.deactivated && can("user", "aahg_deactivate") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(user, overview) =>
									!isSelf(user) &&
									isAahgManageble(user) && (
										<div className="overview-actions text-right">
											<a onClick={() => deactivateUser(user, overview)}>
												<Tippy
													placement="right"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("users.fields.deactivate")}>
													<span>
														<EvaIcon type="power-outline" fill="#009286" height="18" width="18" />
													</span>
												</Tippy>
											</a>
										</div>
									)
								}
							/>
						)}
						{overviewData.deactivated && can("user", "aahg_delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(user, overview) =>
									!isSelf(user) &&
									isAahgManageble(user) && (
										<div className="overview-actions text-right">
											<a onClick={() => deleteUser(user, overview)}>
												<Tippy
													placement="right"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("users.fields.remove")}>
													<span>
														<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
													</span>
												</Tippy>
											</a>
										</div>
									)
								}
							/>
						)}
					</DataTable>
				)}
				renderDeleteDialog={overview => (
					<DeleteDialog
						onConfirm={() => handleDialogSubmit(overview)}
						onCancel={() => setOverviewData({ ...overviewData, dialogOpen: false })}
						isOpen={overviewData.dialogOpen}
						deleteData={overviewData.resultData}
					/>
				)}
			/>

			{can("user", "aahg_deactivate") && (
				<a
					className="link"
					onClick={() =>
						Nav.go(overviewData.deactivated ? route("crm.aahg-users.index") : route("crm.aahg-users.deactivated"))
					}>
					{switchOverviewText()}
				</a>
			)}
		</>
	);
}
