import find from "lodash/find";
import map from "lodash/map";
import { useState } from "react";
import { uctrans } from "../../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../../js/react/components/general/AjaxForm";
import Select from "../../../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../../../js/react/components/general/SubmitBar";
import Nav from "../../../../../../../js/lib/Nav";
import { route } from "../../../../../../../js/helpers";
import CollapsibleContent from "../../../general/CollapsibleContent";
import Navigation from "../Navigation";

export default function ServiceLevelForm() {
	const [formData, setFormData] = useState({ ...window.data.servicelevel_form_data });
	const [staticData] = useState({ ...window.data.servicelevel_static_data });

	const onSuccess = () => {
		Nav.go(route("crm.agenda-settings.servicelevels.index"));
	};

	const handleFormChange = (type, index, value) => {
		const newFormData = { ...formData };
		newFormData[index][type] = value;
		setFormData(newFormData);
	};

	return (
		<>
			<Navigation selectedTab="servicelevels" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("general.edit_:item", {}, { item: "servicelevels.singular" })}</h1>
			</div>
			<br />
			<div>
				<AjaxForm
					method="PUT"
					submitUrl={route("crm.agenda-settings.servicelevels.update")}
					loaderText={uctrans("general.:item_is_being_saved", {}, { item: "servicelevels.plural" })}
					successText={uctrans("general.saved_:item", {}, { item: "servicelevels.plural" })}
					onSuccess={onSuccess}
					data={{ ...formData }}
					useFlashMessage>
					<div className="w-11/12 md:flex">
						{map(staticData.servicelevel_fields, (field, fieldKey) => (
							<div key={fieldKey} className="flex-none w-3/12 border-r mr-5">
								<div>
									<h4>{field.label}</h4>
									<span>{uctrans(`servicelevels.calculated_from.${field.key}`)}</span>
								</div>
								<br />
								<div className="form-container">
									{map(formData, (agendaDate, index) => {
										const type = find(
											staticData.servicelevel_types,
											type => Number(type.key) === Number(agendaDate.type),
										);
										const disabled = type ? type[`${field.key}_disabled`] : true;

										return (
											<div className="form-full" key={`${field.key}-${index}-input`}>
												<label htmlFor={`${index}-input`}>
													{type ? type.label : "unknown field"}
													{!disabled && <span className="text-orange">*</span>}
												</label>
												<div className="form-1-2">
													<input
														id={agendaDate.id}
														type="number"
														name={`${field.key}-${index}-input`}
														value={disabled ? 0 : agendaDate[`${field.key}_value`]}
														onChange={event =>
															!disabled && handleFormChange(`${field.key}_value`, index, event.target.value)
														}
														autoComplete="off"
														disabled={disabled}
													/>
												</div>
												<div className="form-1-2" key={`${index}-select`}>
													<Select
														isClearable={false}
														value={staticData.servicelevel_unit_types.filter(
															({ value }) => Number(value) === Number(agendaDate[`${field.key}_type`]),
														)}
														options={staticData.servicelevel_unit_types}
														name={`${field.key}-${index}-select`}
														onChange={value => !disabled && handleFormChange(`${field.key}_type`, index, value.value)}
														placeholder={uctrans(
															"general.select_:item",
															{},
															{ item: "servicelevels.fields.period_type" },
														)}
														disabled={disabled}
													/>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						))}
						<div className="flex-none w-3/12">
							<CollapsibleContent title={uctrans("servicelevels.fields.information")} initialCollapseState={false}>
								<div>
									<label>{uctrans("servicelevels.agendadate_info.title")}</label>
									<span>{uctrans("servicelevels.agendadate_info.description")}</span>
								</div>
								<br />
								<div>
									<label>{uctrans("servicelevels.servicelevel_info.title")}</label>
									<span>{uctrans("servicelevels.servicelevel_info.description")}</span>
								</div>
							</CollapsibleContent>
						</div>
					</div>
					<div className="form-full">
						<div className="row">
							<div className="w-full md:w-1/2 lg:w-1/3 column">
								<SubmitBar item="servicelevels.fields.periods" addAnotherAvailable={false} />
							</div>
						</div>
					</div>
				</AjaxForm>
			</div>
		</>
	);
}
