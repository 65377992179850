import PropTypes from "prop-types";
import { useState } from "react";
import AsyncAutoComplete from "./AsyncAutoComplete";
import AutoComplete from "./AutoComplete";

export default function SingleSelectAutoComplete(props) {
	const [item, setItem] = useState(props.item);

	const selectItem = item => {
		setItem(item);
		props.onChange(item);
	};

	const options = () => {
		if (props.options) {
			return props.options.filter(item => item.map(e => `${e.value}`.indexOf(`${item.value}`) === -1));
		}

		return [];
	};

	return props.async ? (
		<AsyncAutoComplete
			name={props.name}
			dataSource={props.dataSource}
			onChange={item => selectItem(item)}
			selectedItems={item}
			placeholder={props.placeholder}
			initialItem={props.initialItem}
			showItemsOnFirstClick={props.showItemsOnFirstClick}
			isClearable={props.isClearable}
			disabled={props.disabled}
			clearOnSelect={props.clearOnSelect}
			exclude={props.exclude}
		/>
	) : (
		<AutoComplete
			name={props.name}
			options={options()}
			onChange={selectItem}
			placeholder={props.placeholder}
			showItemsOnFirstClick={props.showItemsOnFirstClick}
			isClearable={props.isClearable}
			disabled={props.disabled}
		/>
	);
}

SingleSelectAutoComplete.propTypes = {
	placeholder: PropTypes.string,
	dataSource: PropTypes.string,
	options: PropTypes.array,
	item: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	async: PropTypes.bool,
	showItemsOnFirstClick: PropTypes.bool,
	initialItem: PropTypes.any,
	disabled: PropTypes.bool,
	isClearable: PropTypes.bool,
	clearOnSelect: PropTypes.bool,
	exclude: PropTypes.array,
};

AsyncAutoComplete.defaultProps = {
	async: false,
	showItemsOnFirstClick: false,
	disabled: false,
	isClearable: true,
	clearOnSelect: false,
	exclude: [],
};
