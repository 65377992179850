import { useState } from "react";
import OrganisationForm from "./OrganisationForm";

export default function OrganisationDetail() {
	const [companyFormData, setCompanyFormData] = useState({ ...window.data.company_form_data });
	const [companyStaticData, setCompanyStaticData] = useState({ ...window.data.company_static_data });

	const reloadData = response => {
		setCompanyFormData(response.data.formvalues);
		setCompanyStaticData(response.data.company_static_data);
	};

	return (
		<OrganisationForm reloadData={reloadData} companyFormData={companyFormData} companyStaticData={companyStaticData} />
	);
}
