import PropTypes from "prop-types";
import TextEditor from "../../../forms/textEditor/TextEditor";

export default function Html(props) {
	return (
		<>
			{props.label && <label>{props.label}</label>}
			<TextEditor type={props.type} value={props.value == null ? "" : props.value} onChange={props.onChange} />
		</>
	);
}

Html.propTypes = {
	type: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
};

Html.defaultProps = {
	type: "website",
};
