import { useContext } from "react";
import PageLibraryContext from "./PageLibraryContext";
import PageLibraryItem from "./PageLibraryItem";

export default function PageLibraryItems() {
	const { pages } = useContext(PageLibraryContext);

	return (
		<div className="overview">
			{pages.map(page => (
				<PageLibraryItem key={page.id} page={page} />
			))}
		</div>
	);
}
