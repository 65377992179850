import find from "lodash/find";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { CmsTypesContext } from "../../cms-types-context";
import Text from "../attributes/Text";
import AnimationAttribute from "../attributes/Animation";

export default function Animation(props) {
	const [animationvalues, setAnimationValues] = useState(props.values ? props.values : {});
	useEffect(() => {
		setAnimationValues(props.values ? props.values : {});
	}, [props.values]);

	const handleAnimationValueChange = (key, value) => {
		const newAnimationvalues = { ...animationvalues };
		newAnimationvalues[key] = value;
		setAnimationValues(newAnimationvalues);
		props.onChange(newAnimationvalues);
	};

	const { partTypes } = useContext(CmsTypesContext);
	const partType = find(partTypes, partType => partType.key === "animation");

	if (partType) {
		return (
			<>
				{props.label && <label>{props.label}</label>}

				<div className="flex bg-primary-lightest pr-3 pl-4 py-3 my-2">
					<div className="w-1/2">
						<AnimationAttribute
							value={animationvalues.animation}
							onChange={value => {
								handleAnimationValueChange("animation", value);
							}}
						/>
					</div>
					<div className="w-1/2 ml-2">
						<Text
							label={partType.attributes.animationname.label}
							value={animationvalues.animationname}
							onChange={value => {
								handleAnimationValueChange("animationname", value);
							}}
						/>
					</div>
				</div>
			</>
		);
	}
}

Animation.propTypes = {
	label: PropTypes.string,
	values: PropTypes.shape({
		animationname: PropTypes.string,
		animation: PropTypes.number,
	}),
	onChange: PropTypes.func.isRequired,
};
