import { Component } from "react";
import PropTypes from "prop-types";
import { route } from "../../../../../js/helpers";
import { uctrans } from "../../../../../js/lib/Translator";
import { typeHasAnswerOptions } from "../../../../../js/questionnairehelpers";
import Checkmark from "../../../../../js/react/components/general/form/checkmark/Checkmark";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import Translate from "../../../../../js/react/components/general/Translate";
import TextEditor from "../forms/textEditor/TextEditor";
import AnswerOptionsList from "./AnswerOptionList";
import DependencySelector from "./DependencySelector";
import MatrixQuestionRowList from "./MatrixQuestionRowList";
import QuestionTypeSelector from "./QuestionTypeSelector";
import cloneDeep from "lodash/cloneDeep";

class QuestionForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			question: cloneDeep(props.question),
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.updateQuestionProperty = this.updateQuestionProperty.bind(this);
		this.handleQuestionTypeChange = this.handleQuestionTypeChange.bind(this);
		this.updateDependencyQuestions = this.updateDependencyQuestions.bind(this);
		this.updateDependencyType = this.updateDependencyType.bind(this);
		this.getSubmitUrl = this.getSubmitUrl.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
	}

	handleInputChange(e) {
		const { target } = e;
		const value = target.type === "checkbox" ? target.checked : target.value;
		this.updateQuestionProperty([target.name], value);
	}

	handleQuestionTypeChange(type, linkedField = null) {
		const question = Object.assign(this.state.question, {});

		let answerOptions = [];

		if (typeHasAnswerOptions(type, this.props.questionTypes)) {
			if (question.answer_options && question.answer_options.length > 0) {
				answerOptions = [...question.answer_options];
			} else {
				const answerOption = {
					content: "",
					min: 0,
					max: 1,
				};

				answerOptions.push(answerOption);
				answerOptions.push(cloneDeep(answerOption));
			}
		}

		if (type === this.props.questionTypes.MATRIX.key) {
			question.question_rows = [];
		}

		question.type = type;
		question.answer_options = answerOptions;

		if (linkedField !== null) {
			question.linked_field_type = linkedField;
		} else {
			question.linked_field_type = null;
		}

		this.setState({
			question,
		});
	}

	updateDependencyQuestions(selectedDependencyQuestions) {
		const question = Object.assign(this.state.question, {});

		question.dependency_questions = selectedDependencyQuestions;

		this.setState({
			question,
		});
	}

	updateDependencyType(dependencyType) {
		const question = Object.assign(this.state.question, {});

		question.dependency_type = dependencyType;

		this.setState({
			question,
		});
	}

	updateQuestionProperty(property, value) {
		const question = Object.assign(this.state.question, {});
		question[property] = value;

		this.setState({
			question,
		});
	}

	handleSuccess(response) {
		if (response.data.success) {
			this.props.onQuestionSubmit(response.data.question);
		}
	}

	getSubmitUrl() {
		if (this.state.question.id) {
			return route("crm.questionnaires.question.update", {
				questionnaire: this.state.question.questionnaire_id,
				question: this.state.question.id,
			});
		}
		return this.props.baseQuestionStoreUrl;
	}

	render() {
		return (
			<AjaxForm
				className="grid-x grid-margin-x"
				method={this.state.question.id ? "PUT" : "POST"}
				submitUrl={this.getSubmitUrl()}
				entityName={uctrans("questionnaires.question.singular")}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "questionnaires.question.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "questionnaires.question.singular" })}
				onSuccess={response => this.handleSuccess(response)}
				useFlashMessage={false}
				data={this.state.question}>
				<div className="form-container">
					{this.props.formType === "edit" &&
						this.state.question.givenAnswers &&
						this.state.question.givenAnswers.length > 0 && (
							<div className="callout">
								<small>
									<Translate content="change_question_meaning_notice" />
								</small>
							</div>
						)}
					<div className="form-full">
						<label>
							<Translate content="questionnaires.question.fields.content" />
						</label>
						<TextEditor
							value={this.state.question.content}
							onChange={content => this.updateQuestionProperty("content", content)}
						/>
					</div>
					<div className="form-full">
						<label>
							<Translate content="questionnaires.question.fields.description" />
						</label>
						<TextEditor
							value={this.state.question.description}
							onChange={description => this.updateQuestionProperty("description", description)}
						/>
					</div>
					<br />
					<div className="form-full">
						{this.state.question.type !== this.props.questionTypes.INFORMATION.key && (
							<div className="form-1-2">
								<Checkmark
									label={uctrans("questionnaires.question.fields.required")}
									onChange={this.handleInputChange}
									name="required"
									checked={this.state.question.required}
								/>
							</div>
						)}
						<div className="form-1-2">
							<Checkmark
								label={uctrans("questionnaires.question.fields.active")}
								onChange={this.handleInputChange}
								name="active"
								checked={this.state.question.active}
							/>
						</div>
						{this.state.question.type !== this.props.questionTypes.INFORMATION.key && (
							<div className="form-1-2">
								<Checkmark
									label={uctrans("questionnaires.question.fields.identifying")}
									onChange={this.handleInputChange}
									name="identifying"
									checked={this.state.question.identifying}
								/>
							</div>
						)}
						{this.props.isProfileQuestionnaire && (
							<div className="form-1-2">
								<Checkmark
									label={uctrans("questionnaires.question.fields.hide_in_commercial_profile")}
									onChange={this.handleInputChange}
									name="hide_in_commercial_profile"
									checked={this.state.question.hide_in_commercial_profile}
								/>
							</div>
						)}
					</div>
					<div className="form-full">
						<ul>
							<li className="border-b border-primary my-5 cursor-pointer clear-right" />
						</ul>
					</div>
					<h4>
						<Translate content="questionnaires.question.fields.type" />
					</h4>
					<div className="form-full">
						<QuestionTypeSelector
							questionTypes={this.props.questionTypes}
							selectedType={this.state.question.type}
							questionFieldTypes={this.props.questionFieldTypes}
							selectedFieldType={this.state.question.linked_field_type}
							onChange={(type, linkedField) => this.handleQuestionTypeChange(type, linkedField)}
						/>
					</div>
					{Number(this.props.questionnaireType) === Number(this.props.questionnaireTypes.RELATIONMANAGER_SURVEY.key) &&
						(this.state.question.type === Number(this.props.questionTypes.MC_SELECT.key) ||
							this.state.question.type === Number(this.props.questionTypes.MC_OPTIONS_SINGLE.key)) && (
							<>
								<h4>
									<Translate content="questionnaires.question.fields.report_label_title" />
								</h4>
								<div className="form-full">
									<LabeledTextInput
										name="report_label"
										value={this.state.question.report_label ? this.state.question.report_label : ""}
										onChange={content => this.updateQuestionProperty("report_label", content.target.value)}
										label="questionnaires.question.fields.report_label"
									/>
								</div>
							</>
						)}
				</div>
				<div>
					{typeHasAnswerOptions(this.state.question.type, this.props.questionTypes) && (
						<>
							<h4>
								<Translate content="questionnaires.question.answer_option.plural" />
							</h4>
							<AnswerOptionsList
								answerOptions={this.state.question.answer_options}
								questionColors={this.props.questionColors}
								administrativeOptions={this.props.administrativeOptions}
								questionTypeKey={this.state.question.type}
								questionTypes={this.props.questionTypes}
								onAnswerOptionsChange={answerOptions => this.updateQuestionProperty("answer_options", answerOptions)}
							/>
							<br />
						</>
					)}
				</div>
				<div className="form-full">
					<ul>
						<li className="border-b border-primary my-5 cursor-pointer clear-right" />
					</ul>
				</div>
				<div>
					{this.state.question.type === this.props.questionTypes.MATRIX.key && (
						<>
							<h4>
								<Translate content="questionnaires.question.question_row.plural" />
							</h4>
							<MatrixQuestionRowList
								questionRows={this.state.question.question_rows}
								onQuestionRowsChange={questionRows => this.updateQuestionProperty("question_rows", questionRows)}
							/>
							<br />
						</>
					)}
				</div>
				<div className="form-full">
					<ul>
						<li className="border-b border-primary my-5 cursor-pointer clear-right" />
					</ul>
				</div>
				<div className="form-full">
					<h4>
						<Translate content="questionnaires.dependency.plural" />
					</h4>
					<DependencySelector
						selectableQuestions={this.props.selectableDependencyQuestions}
						selectedQuestions={this.state.question.dependency_questions ? this.state.question.dependency_questions : []}
						hasDependency={this.state.question.dependency_questions ? 1 : 0}
						dependencyType={this.state.question.dependency_type}
						dependencyTypes={this.props.dependencyTypes}
						handleSelectedQuestionsChange={dependencyQuestions => this.updateDependencyQuestions(dependencyQuestions)}
						handleSelectedDependencyTypeChange={dependencyType => this.updateDependencyType(dependencyType)}
					/>
				</div>
				<div className="submit-container">
					<button className="button button-primary" type="submit">
						<span>
							<Translate content="general.save" />
						</span>
					</button>
					<a className="ml-5" onClick={this.props.onCloseDialog}>
						<span>
							<Translate content="general.cancel" />
						</span>
					</a>
				</div>
			</AjaxForm>
		);
	}
}

export default QuestionForm;

QuestionForm.propTypes = {
	onCloseDialog: PropTypes.func,
	onQuestionSubmit: PropTypes.func,
	question: PropTypes.object,
	questionColors: PropTypes.array,
	administrativeOptions: PropTypes.array,
	selectableDependencyQuestions: PropTypes.array,
	questionFieldTypes: PropTypes.object,
	questionTypes: PropTypes.object,
	dependencyTypes: PropTypes.array,
	formType: PropTypes.string,
	baseQuestionStoreUrl: PropTypes.string,
	isProfileQuestionnaire: PropTypes.bool,
	questionnaireType: PropTypes.number,
	questionnaireTypes: PropTypes.object,
};

QuestionForm.defaultProps = {
	formType: "add",
	isProfileQuestionnaire: false,
};
