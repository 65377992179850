import PropTypes from "prop-types";
import Checkmark from "../../../../../../../js/react/components/general/form/checkmark/Checkmark";

export default function Boolean(props) {
	const onchange = e => {
		props.onChange(!!e.target.checked);
	};
	return <Checkmark label={props.label} checked={!!props.value} onChange={onchange} />;
}

Boolean.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	onChange: PropTypes.func,
};
