import Tippy from "@tippyjs/react";
import { useState } from "react";
import PropTypes from "prop-types";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import { can, route } from "../../../../../../js/helpers";
import Dialog from "../../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../../js/react/components/general/overview/SearchBarFilter";
import SelectFilter from "../../../../../../js/react/components/general/overview/SelectFilter";
import Translate from "../../../../../../js/react/components/general/Translate";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import AddNewsletterRecipientsForm from "./AddNewsletterRecipientsForm";

export default function NewsletterRecipientOverview(props) {
	const { newsletter } = props;
	const { mailing } = props;
	const [overviewDataUpdatedAt, setOverviewDataUpdatedAt] = useState(new Date().getTime());
	const [addRecipientsDialogOpen, setAddRecipientsDialogOpen] = useState(false);
	const [currentWebviewCommunicationLogIdToken, setCurrentWebviewCommunicationLogIdToken] = useState(null);

	const handleAddRecipientsSuccess = () => {
		setOverviewDataUpdatedAt(new Date().getTime());
		setAddRecipientsDialogOpen(false);
		props.onCountChange();
	};
	return (
		<>
			<br />
			<hr />
			<br />
			<div className="flex justify-between ">
				<h3>
					<Translate content="communication.newsletter_recipients.plural" />
				</h3>
				<IconButton
					disabled={props.readOnly}
					onClick={() => {
						setAddRecipientsDialogOpen(true);
					}}
					content={uctrans("general.add_:item", {}, { item: "communication.newsletter_recipients.plural" })}
					primary
				/>
			</div>
			<AbnOverview
				indexUrl={route("crm.communication.newsletter-mailing-recipients.index", {
					newsletter: newsletter.id,
					newsletter_mailing: mailing.id,
				})}
				deleteRouteName="crm.communication.newsletter-mailing-recipients.delete"
				modelTranslations={{
					plural: trans("communication.newsletter_recipients.plural"),
					singular: trans("communication.newsletter_recipients.singular"),
				}}
				dataUpdatedAt={overviewDataUpdatedAt}
				renderFilters={overview => (
					<Filters>
						<SearchBarFilter
							className="form-full"
							type="text"
							name="email"
							placeholder={uctrans("communication.recipient_filter.search_for_email")}
						/>
						<SelectFilter
							className="form-1-2"
							name="recipient_object_type"
							label={uctrans("communication.newsletter_recipients.fields.recipient_object_type")}
							options={overview.data.metadata.filterdata.recipient_object_types}
						/>
						<SelectFilter
							className="form-1-2"
							name="status"
							label={uctrans("communication.newsletter_recipients.fields.status")}
							options={overview.data.metadata.filterdata.statuses}
						/>
						<SelectFilter
							className="form-1-3"
							name="last_flowmailer_status_id"
							label={uctrans("communication.communication_log.fields.last_flowmailer_status_id")}
							options={overview.data.metadata.filterdata.event_types}
							collapsible
						/>
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={5}
							sortable="email"
							renderHeaderContent={() => uctrans("communication.newsletter_recipients.fields.email")}
							renderCellContent={newsletterRecipient => newsletterRecipient.email}
						/>
						<DataColumn
							width={2}
							sortable="type"
							renderHeaderContent={() => uctrans("communication.newsletter_recipients.fields.recipient_object_type")}
							renderCellContent={newsletterRecipient =>
								newsletterRecipient.recipient_object_type ? newsletterRecipient.recipient_object_type.label : "-"
							}
						/>
						<DataColumn
							width={5}
							renderHeaderContent={() => uctrans("communication.newsletter_recipients.singular")}
							renderCellContent={newsletterRecipient =>
								newsletterRecipient.recipient_object ? newsletterRecipient.recipient_object.label : "-"
							}
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("communication.newsletter_recipients.fields.status")}
							renderCellContent={newsletterRecipient =>
								newsletterRecipient.status ? newsletterRecipient.status.label : "-"
							}
							sortable="status"
						/>
						<DataColumn
							width={3}
							renderHeaderContent={() => uctrans("communication.communication_log.fields.last_flowmailer_status_id")}
							renderCellContent={newsletterRecipient =>
								newsletterRecipient.last_flowmailer_status_id ? newsletterRecipient.last_flowmailer_status_id : "-"
							}
							sortable="last_flowmailer_status_id"
						/>
						<DataColumn
							width={1}
							renderHeaderContent={() => ""}
							renderCellContent={(newsletterRecipient, overview) => (
								<div className="flex">
									{can("communication.communication_log", "view_all") && newsletterRecipient.id_token && (
										<a
											rel="noopener noreferrer"
											className="mr-2"
											onClick={() => {
												setCurrentWebviewCommunicationLogIdToken(newsletterRecipient.id_token);
											}}
											target="_blank">
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("communication.automated_messages.view_on_website")}>
												<span>
													<EvaIcon type="eye-outline" fill="#249286" height="18" width="18" />
												</span>
											</Tippy>
										</a>
									)}
									{newsletterRecipient.status &&
										!props.readOnly &&
										newsletterRecipient.status.key === 0 &&
										can("communication.newsletter_recipient", "delete") && (
											<DeleteColumnContent onClick={() => overview.askDelete(newsletterRecipient)} />
										)}
								</div>
							)}
						/>
					</DataTable>
				)}
			/>
			<Dialog
				width={850}
				isOpen={addRecipientsDialogOpen}
				title={uctrans("general.add_:item", {}, { item: "communication.newsletter_recipients.plural" })}
				isFullScreen
				onClose={() => setAddRecipientsDialogOpen(false)}>
				<AddNewsletterRecipientsForm
					newsletter={newsletter}
					mailing={mailing}
					onSuccess={() => handleAddRecipientsSuccess()}
					onCancel={() => setAddRecipientsDialogOpen(false)}
				/>
			</Dialog>
			<Dialog
				isOpen={!!currentWebviewCommunicationLogIdToken}
				onClose={() => {
					setCurrentWebviewCommunicationLogIdToken(null);
				}}
				shouldCloseOnOverlayClick
				width={850}>
				{!!currentWebviewCommunicationLogIdToken && (
					<iframe
						id="mail_iframe"
						width="100%"
						height="500px"
						src={route("crm.communication-log.webview", currentWebviewCommunicationLogIdToken)}
					/>
				)}
			</Dialog>
		</>
	);
}

NewsletterRecipientOverview.propTypes = {
	readOnly: PropTypes.bool,
	newsletter: PropTypes.object.isRequired,
	mailing: PropTypes.object.isRequired,
	onCountChange: PropTypes.func,
};

NewsletterRecipientOverview.defaultProps = {
	readOnly: false,
};
