import { useState } from "react";
import Select from "../../../../../js/react/components/general/Select";
import SubmitBar from "../../../../../js/react/components/general/SubmitBar";
import { uctrans } from "../../../../../js/lib/Translator";
import AjaxForm from "../../../../../js/react/components/general/AjaxForm";
import Translate from "../../../../../js/react/components/general/Translate";
import useInputHandler from "../../../../../js/react/hooks/useInputHandler";
import LabeledTextInput from "../../../../../js/react/components/general/form/LabeledTextInput";
import Nav from "../../../../../js/lib/Nav";
import { route } from "../../../../../js/helpers";
import useSelectHandler from "../../../../../js/react/hooks/useSelectHandler";

export default function ApiUserForm() {
	const [formData, setFormData] = useState({ ...window.data.api_user.form_data });
	const [staticData, setStaticData] = useState({ ...window.data.api_user.static_data });
	const inputHandler = useInputHandler(setFormData);
	const selectHandler = useSelectHandler(setFormData);

	const onSuccess = response => {
		if (staticData.creating) {
			Nav.go(route("crm.api-users.edit", response.data.id));
		} else {
			setFormData(response.data.form_data);
			setStaticData(response.data.static_data);
		}
	};

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					{uctrans(staticData.creating ? "general.add_:item" : "general.edit_:item", {
						item: uctrans("api_users.singular"),
					})}
				</h1>

				<a className="link" href={route("crm.api-users.index")}>
					{uctrans("general.back_to_overview_:page", {}, { page: "api_users.plural" })}
				</a>
			</div>
			<AjaxForm
				method={staticData.creating ? "POST" : "PUT"}
				submitUrl={staticData.creating ? route("crm.api-users.store") : route("crm.api-users.update", staticData.id)}
				loaderText={uctrans("general.:item_is_being_saved", {}, { item: "api_users.singular" })}
				successText={uctrans("general.saved_:item", {}, { item: "api_users.singular" })}
				onSuccess={onSuccess}
				data={{ ...formData }}
				useFlashMessage={!!staticData.creating}>
				<div className="form-1-2">
					<LabeledTextInput
						name="username"
						label="api_users.fields.username"
						value={formData.username}
						onChange={inputHandler}
						required
					/>
				</div>
				<div className="form-1-2">
					<label htmlFor="permissiongroup">
						<Translate content="api_users.fields.permissiongroup" />
						<span className="text-orange"> *</span>
					</label>
					<Select
						placeholder={uctrans("general.select_:item", {}, { item: "api_users.fields.permissiongroup" })}
						value={staticData.api_user_permission_groups.filter(
							({ value }) => Number(value) === Number(formData.permissiongroup),
						)}
						options={staticData.api_user_permission_groups}
						name="permissiongroup"
						onChange={(selectedValue, metaData) => selectHandler(selectedValue, metaData)}
						isClearable={false}
						required
					/>
				</div>
				<div className="form-full">
					<div className="form-1-2">
						<LabeledTextInput
							name="email"
							label="api_users.fields.email"
							value={formData.email}
							onChange={inputHandler}
						/>
					</div>
				</div>

				<div className="form-full">
					<div className="form-1-2">
						<LabeledTextInput
							name="consent_label"
							label="api_users.fields.consent_label"
							value={formData.consent_label}
							onChange={inputHandler}
						/>
					</div>
				</div>

				<div className="form-full">
					<div className="row">
						<div className="w-full md:w-1/2 lg:w-1/3 column">
							<SubmitBar translateLabel item="api_users.singular" addAnotherAvailable={false} />
						</div>
					</div>
				</div>
			</AjaxForm>

			{!staticData.creating && (
				<>
					{staticData.generated_password && (
						<div className="form-full border-t mt-4 pt-4">
							<label htmlFor="password">
								<Translate content="api_users.fields.password" />
							</label>
							<p>{uctrans("api_users.generated_password_explanation")}:</p>
							<br />
							<strong>{staticData.generated_password}</strong>
						</div>
					)}
					{!staticData.generated_password && (
						<div className="form-full border-t mt-4 pt-4">
							<label htmlFor="password">
								<Translate content="api_users.fields.password" />
							</label>
							<p>{uctrans("api_users.unknow_password_explanation")}:</p>
							<AjaxForm
								method="PUT"
								submitUrl={route("crm.api-users.regenerate-password", staticData.id)}
								loaderText={uctrans("general.:item_is_being_saved", {}, { item: "api_users.singular" })}
								successText={uctrans("general.saved_:item", {}, { item: "api_users.singular" })}
								successUrl={route("crm.api-users.edit", staticData.id)}
								useFlashMessage>
								<SubmitBar buttonContent="api_users.regenerate_password" />
							</AjaxForm>
						</div>
					)}
				</>
			)}
		</>
	);
}
