import { uctrans, trans } from "../../../../../../js/lib/Translator";
import { can, route } from "../../../../../../js/helpers";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import Translate from "../../../../../../js/react/components/general/Translate";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DeleteColumnContent from "../../../../../../js/react/components/general/overview/DeleteColumnContent";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../../js/react/components/general/overview/SearchBarFilter";

export default function InterestPeriodOverview() {
	const getInterestTypeName = interestPeriod => {
		const foundInterestType = window.data.interest_types.find(
			interestType => interestType.id === interestPeriod.interest_type_id,
		);
		return foundInterestType.name;
	};

	return (
		<>
			<div className="page-title-container">
				<h1 className="page-title">
					<Translate content="interest.interest_periods.plural" />
				</h1>
				<div>
					<IconButton
						href={route("crm.interest.interest-periods.create")}
						content={uctrans("general.add_:item", {}, { item: "interest.interest_periods.singular" })}
						primary
					/>
				</div>
			</div>
			<AbnOverview
				indexUrl={route("crm.interest.interest-periods.index")}
				deleteRouteName="crm.interest.interest-periods.delete"
				moveRouteName="crm.interest.interest-periods.move"
				useDragAndDrop={false}
				modelTranslations={{
					plural: trans("interest.interest_periods.plural"),
					singular: trans("interest.interest_periods.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={10}
							sortable="start_date"
							renderHeaderContent={() => uctrans("interest.fields.start_date")}
							renderCellContent={interestPeriod => (
								<a href={route("crm.interest.interest-periods.edit", interestPeriod.id)} className="row-title link">
									{interestPeriod.start_date}
								</a>
							)}
						/>
						<DataColumn
							width={10}
							sortable="submit_date"
							renderHeaderContent={() => uctrans("interest.fields.submit_date")}
							renderCellContent={interestPeriod => interestPeriod.submit_date}
						/>
						<DataColumn
							width={10}
							sortable="interest_type"
							renderHeaderContent={() => uctrans("interest.interest_types.singular")}
							renderCellContent={interestPeriod => getInterestTypeName(interestPeriod)}
						/>
						{can("interest.interest_period", "delete") && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(interestPeriod, overview) => (
									<DeleteColumnContent onClick={() => overview.askDelete(interestPeriod)} />
								)}
							/>
						)}
					</DataTable>
				)}
			/>
		</>
	);
}
