import { createLoader, error } from "../../../../../../js/react/components/general/notifications";
import Tippy from "@tippyjs/react";
import axios from "axios";
import forEach from "lodash/forEach";
import upperFirst from "lodash/upperFirst";
import { useState, useCallback } from "react";
import { format } from "../../../../../../js/lib/Date";
import ConfirmDialog from "../../../../../../js/react/components/general/ConfirmDialog";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import IconButton from "../../../../../../js/react/components/general/IconButton";
import { uctrans, trans } from "../../../../../../js/lib/Translator";
import AbnOverview from "../../../../../../js/react/components/general/abn-overview/AbnOverview";
import DataTable from "../../../../../../js/react/components/general/overview/DataTable";
import DataColumn from "../../../../../../js/react/components/general/overview/DataColumn";
import { can, route, yesNoOptions } from "../../../../../../js/helpers";
import DatePickerFilter from "../../../../../../js/react/components/general/overview/DatePickerFilter";
import Filters from "../../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../../js/react/components/general/overview/SearchBarFilter";
import SelectFilter from "../../../../../../js/react/components/general/overview/SelectFilter";

import BreadCrumbs from "../../general/breadcrumbs/BreadCrumbs";

export default function NewsletterOverview() {
	const overviewData = { ...window.data.overview };
	const [newsletterToCopy, setNewsletterToCopy] = useState(null);
	const [overviewDataUpdatedAt, setOverviewDataUpdatedAt] = useState(new Date().getTime());

	const askCopy = newsletter => {
		setNewsletterToCopy(newsletter);
	};
	const cancelCopy = () => {
		setNewsletterToCopy(null);
	};
	const handleCopy = useCallback(async () => {
		if (newsletterToCopy) {
			const loader = createLoader(uctrans("general.create_:item", {}, { item: "general.copy" }));

			try {
				await axios.post(route(`crm.communication.newsletters.store-copy`, newsletterToCopy.id));
				loader.success(uctrans("general.created_:item", {}, { item: "general.copy" }));
				setOverviewDataUpdatedAt(new Date().getTime());
				setNewsletterToCopy(null);
			} catch (exception) {
				if (exception.response && exception.response.status === 422) {
					if (Object.prototype.hasOwnProperty.call(exception.response.data, "errors")) {
						let first = true;
						forEach(exception.response.data.errors, message => {
							if (first && loader) {
								loader.failure(upperFirst(message[0]));
								first = false;
							} else {
								error(upperFirst(message[0]));
							}
						});
					}
				} else if (exception.message) {
					loader.failure(exception.message);
				}
				cancelCopy();
			}
		}
	}, [newsletterToCopy]);

	return (
		<>
			<BreadCrumbs breadCrumbs={[{ label: uctrans("communication.singular") }]} />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("communication.newsletters.plural")}</h1>
				{can("communication.newsletter", "create") && (
					<div>
						<IconButton
							href={route("crm.communication.newsletters.create")}
							content={uctrans("general.add_:item", {}, { item: "communication.newsletters.singular" })}
							primary
						/>
					</div>
				)}
			</div>

			<AbnOverview
				initialData={overviewData}
				indexUrl={route("crm.communication.newsletters.index.json")}
				deleteRouteName="crm.communication.newsletters.delete"
				modelTranslations={{
					plural: trans("communication.newsletters.plural"),
					singular: trans("communication.newsletters.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
						<SelectFilter
							className="form-1-4"
							name="is_sent"
							label={uctrans("communication.newsletters.fields.is_sent")}
							options={yesNoOptions()}
						/>
						<SelectFilter
							className="form-1-4"
							name="subscription_type_id"
							label={uctrans("communication.newsletters.fields.subscription_type")}
							options={overviewData.metadata.filterdata.subscription_type_options}
							collapsible
						/>
						<SelectFilter
							className="form-1-4"
							name="marketing_theme_id"
							label={uctrans("marketing.themes.singular")}
							options={overviewData.metadata.filterdata.marketing_themes_options}
							collapsible
						/>
						<DatePickerFilter
							className="form-1-4"
							name="sent_at_from"
							label={uctrans("communication.newsletters.sent_at_from")}
							collapsible
						/>
						<DatePickerFilter
							className="form-1-4"
							name="sent_at_until"
							label={uctrans("communication.newsletters.sent_at_until")}
							collapsible
						/>
					</Filters>
				)}
				dataUpdatedAt={overviewDataUpdatedAt}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={3}
							sortable="title"
							renderHeaderContent={() => uctrans("communication.newsletters.fields.title")}
							renderCellContent={newsletter => {
								if (can("communication.newsletter", "update")) {
									return (
										<a href={route("crm.communication.newsletters.edit", newsletter.id)} className="row-title link">
											{newsletter.title}
										</a>
									);
								} else {
									return newsletter.title;
								}
							}}
						/>
						<DataColumn
							width={2}
							sortable="subtitle"
							renderHeaderContent={() => uctrans("communication.newsletters.fields.subtitle")}
							renderCellContent={newsletter => <span className="text-sm">{newsletter.subtitle}</span>}
						/>
						<DataColumn
							width={3}
							sortable="description"
							renderHeaderContent={() => uctrans("communication.newsletters.fields.description")}
							renderCellContent={newsletter => <span className="text-xs">{newsletter.description}</span>}
						/>
						<DataColumn
							width={2}
							sortable="created_at"
							renderHeaderContent={() => uctrans("communication.newsletters.fields.created_at")}
							renderCellContent={newsletter => (newsletter.created_at ? format(newsletter.created_at, "dd-MM-y") : "-")}
						/>
						<DataColumn
							width={2}
							sortable="sent_at"
							renderHeaderContent={() => uctrans("communication.newsletters.sent_at")}
							renderCellContent={newsletter => (newsletter.sent_at ? format(newsletter.sent_at, "dd-MM-y HH:mm") : "-")}
						/>
						<DataColumn
							width={2}
							sortable="subscription_type_id"
							renderHeaderContent={() => uctrans("communication.newsletters.fields.subscription_type")}
							renderCellContent={newsletter => newsletter.subscription_type}
						/>
						{(can("communication.newsletter", "delete") || can("communication.newsletter", "create")) && (
							<DataColumn
								width={1}
								renderHeaderContent={() => ""}
								renderCellContent={(newsletter, overview) => (
									<div className="overview-actions">
										{can("communication.newsletter", "view") && (
											<a
												rel="noopener noreferrer"
												className="mr-2"
												href={route("crm.communication.newsletters.preview", newsletter.id)}
												target="_blank">
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("communication.newsletters.preview")}>
													<span>
														<EvaIcon type="navigation-2-outline" fill="#249286" height="18" width="18" />
													</span>
												</Tippy>
											</a>
										)}
										{can("communication.newsletter", "create") && (
											<a
												className="mr-2"
												onClick={() => {
													askCopy(newsletter);
												}}>
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("general.add_:item", {
														item: trans("general.copy_of_:item", {}, { item: "communication.newsletters.singular" }),
													})}>
													<span>
														<EvaIcon type="copy-outline" fill="#3490DC" height="18" width="18" />
													</span>
												</Tippy>
											</a>
										)}

										{can("communication.newsletter", "delete") && !newsletter.sent_at && (
											<a
												onClick={() => {
													overview.askDelete(newsletter);
												}}>
												<Tippy
													placement="left"
													arrow
													animation="perspective"
													duration="400"
													content={uctrans("general.delete")}>
													<span>
														<EvaIcon type="trash-2-outline" fill="#f9acaa" height="18" width="18" />
													</span>
												</Tippy>
											</a>
										)}
									</div>
								)}
							/>
						)}
					</DataTable>
				)}
			/>
			<ConfirmDialog
				onConfirm={handleCopy}
				onCancel={cancelCopy}
				isOpen={!!newsletterToCopy}
				title={uctrans("general.confirm")}
				confirmButtonLabel={uctrans("general.create_:item", {}, { item: "general.copy" })}
				cancelButtonLabel={uctrans("general.cancel")}>
				<div className="form-container">
					<h6>{newsletterToCopy ? newsletterToCopy.title : "-"}</h6>
					{uctrans("communication.newsletters.confirm_copy")}
				</div>
			</ConfirmDialog>
		</>
	);
}
