import ContactpersonCommunicationRequestedBySelect from "../../../../../js/react/components/communication/ContactpersonCommunicationRequestedBySelect";
import ConfirmDialog from "../../../../../js/react/components/general/ConfirmDialog";
import Tippy from "@tippyjs/react";
import DeleteDialog from "../../../../../js/react/components/general/DeleteDialog";
import { createLoader } from "../../../../../js/react/components/general/notifications";
import axios from "axios";
import { useState } from "react";
import { route, yesNoOptions } from "../../../../../js/helpers";
import { uctrans, trans } from "../../../../../js/lib/Translator";
import EvaIcon from "../../../../../js/react/components/general/EvaIcon";
import DataColumn from "../../../../../js/react/components/general/overview/DataColumn";
import DataTable from "../../../../../js/react/components/general/overview/DataTable";
import AbnOverview from "../../../../../js/react/components/general/abn-overview/AbnOverview";
import DatePickerFilter from "../../../../../js/react/components/general/overview/DatePickerFilter";
import Filters from "../../../../../js/react/components/general/overview/Filters";
import SearchBarFilter from "../../../../../js/react/components/general/overview/SearchBarFilter";
import SelectFilter from "../../../../../js/react/components/general/overview/SelectFilter";
import BreadCrumbs from "../general/breadcrumbs/BreadCrumbs";
import TabLinks from "../general/tabs/TabLinks";

export default function ExistingContactpersonOverview() {
	const [overviewData, setOverviewData] = useState({
		overview: { ...window.data.overview },
		index_link: window.data.index_link,
		requested_by_types: window.data.communication_creation_requested_by_type_options || [],
		enabled_filters: window.data.enabled_filters,
		resultData: {
			errors: [],
			warnings: [],
		},
		dialogOpen: false,
	});

	const [contactpersonToAdd, setContactpersonToAdd] = useState(null);
	const [formData, setFormData] = useState({});

	const handleAdd = async overview => {
		const loader = createLoader(uctrans("general.add_:item", {}, { item: "contactpersons.existing.singular" }));
		try {
			await axios.put(contactpersonToAdd.update_employments_link, formData);
			loader.success(uctrans("general.added_:item", {}, { item: "contactpersons.existing.singular" }));
			overview.loadData();
		} catch (error) {
			console.error(error);
			loader.failure(error.message);
		}

		setContactpersonToAdd(null);
	};

	const handleDelete = async person => {
		const result = await axios.get(person.delete_employments_link);
		if (result.status === 200) {
			setOverviewData({
				...overviewData,
				resultData: result.data,
				dialogOpen: true,
			});
		}
	};

	const handleDeleteDialog = async overview => {
		const loader = createLoader(uctrans("general.delete_:item", {}, { item: "contactpersons.existing.singular" }));

		try {
			await axios.delete(overviewData.resultData.destroy_link);
			loader.success(uctrans(`general.deleted_:item`, {}, { item: "contactpersons.existing.singular" }));
			overview.loadData();
		} catch (error) {
			console.error(error);
			loader.failure(error.message);
		}

		setOverviewData({
			...overviewData,
			dialogOpen: false,
		});
	};

	return (
		<>
			{window.data.nav_data && (
				<>
					<BreadCrumbs breadCrumbs={window.data.nav_data.breadcrumbs} />
					<TabLinks tabs={window.data.nav_data.tabs} activeTab={window.data.nav_data.activeTabItem} />
				</>
			)}
			<div className="page-title-container">
				<h1 className="page-title">{uctrans("contactpersons.existing.title")}</h1>

				{window.data.previous_link && (
					<a className="link ml-5" href={window.data.previous_link}>
						{uctrans("general.back_to_overview_:page", {}, { page: "contactpersons.plural" })}
					</a>
				)}
			</div>

			<AbnOverview
				initialData={overviewData.overview}
				indexUrl={overviewData.index_link}
				modelTranslations={{
					plural: trans("contactpersons.existing.plural"),
					singular: trans("contactpersons.existing.plural.singular"),
				}}
				renderFilters={() => (
					<Filters>
						<SearchBarFilter type="text" name="search" />
						{overviewData.enabled_filters.indexOf("is_active") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="is_active"
								label={uctrans("contactpersons.fields.active")}
								options={yesNoOptions()}
								collapsible={!window.data.relation_manager}
							/>
						)}
						{overviewData.enabled_filters.indexOf("relation_manager") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="relation_manager"
								label={uctrans("contactpersons.fields.relation_manager")}
								options={overviewData.overview.filterdata.relation_managers}
								collapsible={!window.data.relation_manager}
							/>
						)}
						{overviewData.enabled_filters.indexOf("occupation") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="occupation"
								label={uctrans("contactpersons.fields.occupation")}
								options={overviewData.overview.filterdata.occupations}
								collapsible={!window.data.relation_manager}
							/>
						)}
						{overviewData.enabled_filters.indexOf("organisation") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="organisation"
								label={uctrans("intermediaries.fields.organisation")}
								options={overviewData.overview.filterdata.organisations}
								collapsible
							/>
						)}
						{overviewData.enabled_filters.indexOf("segment") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="segment"
								label={uctrans("intermediaries.fields.segment")}
								options={overviewData.overview.filterdata.segments}
								collapsible
							/>
						)}
						{overviewData.enabled_filters.indexOf("team") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="team"
								label={uctrans("intermediaries.fields.team")}
								options={overviewData.overview.filterdata.teams}
								collapsible
							/>
						)}
						{overviewData.enabled_filters.indexOf("interest_type") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="interest_type"
								label={uctrans("contactpersons.fields.interests")}
								options={overviewData.overview.filterdata.interest_types}
								collapsible
							/>
						)}
						{overviewData.enabled_filters.indexOf("website_permissiongroup") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="website_permissiongroup"
								label={uctrans("contactpersons.fields.website_permissiongroup")}
								options={overviewData.overview.filterdata.contactperson_permissiongroups}
								collapsible
							/>
						)}
						{overviewData.enabled_filters.indexOf("uses_app") > -1 && (
							<SelectFilter
								className="form-1-3"
								name="uses_app"
								label={uctrans("contactpersons.uses_app")}
								options={yesNoOptions()}
								collapsible
							/>
						)}

						{overviewData.enabled_filters.indexOf("not_logged_in_since") > -1 && (
							<DatePickerFilter
								className="form-1-4"
								name="not_logged_in_since"
								label={uctrans("contactpersons.not_logged_in_since")}
								collapsible
							/>
						)}
						{overviewData.enabled_filters.indexOf("logged_in_since") > -1 && (
							<DatePickerFilter
								className="form-1-4"
								name="logged_in_since"
								label={uctrans("contactpersons.logged_in_since")}
								collapsible
							/>
						)}
					</Filters>
				)}
				renderBody={() => (
					<DataTable>
						<DataColumn
							width={2}
							renderHeaderContent={() => ""}
							renderCellContent={person =>
								person.has_photo ? (
									<div className="avatar-img">
										<img
											className="max-h-full self-center"
											src={route("crm.contactpersons.photo-thumbnail", person.id)}
											alt={person.name}
										/>
									</div>
								) : (
									<div className="avatar-img">
										<EvaIcon type="person" fill="#249286" height="35" width="35" />
									</div>
								)
							}
						/>
						<DataColumn
							width={5}
							sortable="last_name"
							renderHeaderContent={() => uctrans("contactpersons.fields.name")}
							renderCellContent={person => (
								<a href={person.edit_link} className="row-title link">
									{person.name}
								</a>
							)}
						/>
						<DataColumn
							width={5}
							sortable="employments"
							renderHeaderContent={() => uctrans("contactpersons.fields.workingby")}
							renderCellContent={person => person.employments && person.employments.label}
						/>
						<DataColumn
							width={4}
							sortable="occupation.name"
							renderHeaderContent={() => uctrans("contactpersons.fields.occupation")}
							renderCellContent={person => person.occupation && person.occupation.label}
						/>
						<DataColumn
							width={5}
							sortable="email"
							renderHeaderContent={() => uctrans("contactpersons.fields.email")}
							renderCellContent={person => <span className="word-break">{person.email}</span>}
						/>
						<DataColumn
							width={4}
							sortable="mobile"
							renderHeaderContent={() => uctrans("contactpersons.fields.phone")}
							renderCellContent={person => (
								<>
									<span>{person.landline}</span>
									<span className="block">{person.mobile}</span>
								</>
							)}
						/>
						<DataColumn
							width={3}
							sortable="website_role"
							renderHeaderContent={() => uctrans("roles.singular")}
							renderCellContent={person => person.website_role}
						/>

						<DataColumn
							width={1}
							renderHeaderContent={() => ""}
							renderCellContent={person => (
								<div className="overview-actions">
									{person.employed ? (
										<a
											className="mr-3"
											onClick={async () => {
												await handleDelete(person);
											}}>
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("general.delete")}>
												<span>
													<EvaIcon type="close-outline" fill="#f9acaa" height="20" width="20" />
												</span>
											</Tippy>
										</a>
									) : (
										<a
											className="mr-3"
											onClick={() => {
												setContactpersonToAdd(person);
											}}>
											<Tippy
												placement="left"
												arrow
												animation="perspective"
												duration="400"
												content={uctrans("general.add")}>
												<span>
													<EvaIcon type="plus-outline" fill="#249286" height="20" width="20" />
												</span>
											</Tippy>
										</a>
									)}
								</div>
							)}
						/>
					</DataTable>
				)}
				renderDeleteDialog={overview => (
					<DeleteDialog
						onConfirm={() => handleDeleteDialog(overview)}
						onCancel={() => setOverviewData({ ...overviewData, dialogOpen: false })}
						isOpen={overviewData.dialogOpen}
						deleteData={overviewData.resultData}
					/>
				)}
				renderFooter={overview => (
					<ConfirmDialog
						onConfirm={() => handleAdd(overview)}
						onCancel={() => setContactpersonToAdd(null)}
						isOpen={!!contactpersonToAdd}
						title={uctrans(
							"general.are_you_sure_you_want_to_attach_:item_:label",
							{},
							{
								item: "contactpersons.singular",
								label: contactpersonToAdd ? contactpersonToAdd.name : "",
							},
						)}>
						<ContactpersonCommunicationRequestedBySelect
							name="communication_creation_requested_by_contactperson_id"
							requestContactpersonCompanyIds={window.data.filter_company_ids}
							formData={formData}
							setFormData={setFormData}
							requestedByTypeOptions={overviewData.requested_by_types}
							fromCrm
						/>
					</ConfirmDialog>
				)}
			/>
		</>
	);
}
