import PropTypes from "prop-types";
import { numberFormat } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";
import { useCallback, useState, useEffect } from "react";
import axios from "axios";
import Select from "../../../../../../js/react/components/general/Select";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import useSelectHandler from "../../../../../../js/react/hooks/useSelectHandler";
import RelationManagerSurveyHorizontalBarChart from "./RelationManagerSurveyHorizontalBarChart";

export default function RelationManagerSurveySummaryBlock({ summaryData }) {
	const [collapsed, setCollapsed] = useState(window.data.collapsed_summary_block !== "0");
	const [loadingSummary, setLoadingSummary] = useState(false);
	const [summary, setSummary] = useState(window.data.summary_data ?? []);
	const [formData, setFormData] = useState({
		relationmanager: summaryData.formValues.relationmanager,
		year: summaryData.formValues.year,
	});

	const selectHandler = useSelectHandler(setFormData);

	useEffect(() => {
		fetchSurveySummary();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData]);

	const fetchSurveySummary = useCallback(async () => {
		setLoadingSummary(true);
		const result = await axios.get(summaryData.summaryRoute, { params: formData });
		setSummary(result.data);
		setLoadingSummary(false);
	}, [summaryData, formData]);

	const collapseSummaryBlock = async collapsed => {
		const newCollapsed = !collapsed;
		try {
			await axios.put(summaryData.collepseRoute, { collapsed: newCollapsed });
		} catch (err) {
			console.error(err);
		}
		setCollapsed(newCollapsed);
	};

	return (
		<div className="bg-grey-lightest shadow rounded flex flex-col w-full">
			<div className="shadow p-6 flex">
				<span className="text-grey-darker w-full">
					<div className="w-full flex justify-between">
						<h3>{uctrans("survey.relationmanager.summary_block.title")}</h3>
						<div className="cursor-pointer" onClick={() => collapseSummaryBlock(collapsed)}>
							<EvaIcon fill="#000000" width="18" height="18" type={collapsed ? "plus" : "minus"} />
						</div>
					</div>
				</span>
			</div>
			{!collapsed && summary && summary.company && summary.relationmanager && (
				<>
					<div className="p-6 w-full flex mb-4">
						<div className="w-1/3 pr-2">
							<label htmlFor="year">{uctrans("report.year")}</label>
							<Select
								name="year"
								value={summaryData.formInfo.years.filter(({ value }) => `${value}` === `${formData.year}`)}
								options={summaryData.formInfo.years}
								isClearable={false}
								onChange={selectHandler}
							/>
						</div>
						<div className="w-1/3 pr-2">
							<label htmlFor="relationmanager">{uctrans("survey.relationmanager.fields.relationmanager_id")}</label>
							<Select
								name="relationmanager"
								value={summaryData.formInfo.relationmanagers.filter(
									({ value }) => `${value}` === `${formData.relationmanager}`,
								)}
								options={summaryData.formInfo.relationmanagers}
								isClearable={false}
								onChange={selectHandler}
							/>
						</div>
					</div>
					{loadingSummary ? (
						<div className="flex justify-center p-6">
							<Spinner width={50} />
						</div>
					) : (
						<>
							<div className="p-6 w-full">
								<div className="flex w-full">
									<div className="pl-8 w-1/3">
										<div className="flex w-full">
											<h6>{uctrans("survey.relationmanager.summary_block.average_appreciation")}</h6>
										</div>
									</div>
									<div className="pl-8 w-1/3">
										<div className="flex w-full">
											<h6>{uctrans("survey.relationmanager.summary_block.amount_of_completed_surveys")}</h6>
										</div>
									</div>
								</div>

								<div className="flex w-full">
									<div className="pl-8 w-1/3 flex justify-start">
										<div className="w-1/3">
											<div className="rounded-full dashboard-circle-rm-survey flex items-center justify-center">
												<span className="font-medium">
													{numberFormat(summary.company.appreciation_score ? summary.company.appreciation_score : 0, 1)}
												</span>
											</div>
										</div>
										<div className="flex items-center">
											<ul className="border-l p-2 text-opacity-50">
												<span className="opacity-75">{summary.relationmanager.label}</span>
												<br />
												<span className="opacity-75">
													{numberFormat(
														summary.relationmanager.appreciation_score ? summary.relationmanager.appreciation_score : 0,
														1,
													)}
												</span>
											</ul>
										</div>
									</div>
									<div className="pl-8 w-1/3 flex justify-start">
										<div className="w-1/3">
											<div className="rounded-full dashboard-circle-rm-survey flex items-center justify-center w-1/2">
												<span className="font-medium">
													{numberFormat(
														summary.company.questionnaires_completed &&
															summary.company.questionnaires_completed.finished
															? summary.company.questionnaires_completed.finished
															: 0,
													)}
												</span>
												<br />
												<span>
													/
													{numberFormat(
														summary.company.questionnaires_completed && summary.company.questionnaires_completed.total
															? summary.company.questionnaires_completed.total
															: 0,
													)}
												</span>
											</div>
										</div>
										<div className="flex items-center">
											<ul className="border-l p-2">
												<span className="opacity-75">{summary.relationmanager.label}</span>
												<br />
												<span className="opacity-75">
													{summary.relationmanager.questionnaires_completed &&
													summary.relationmanager.questionnaires_completed.finished
														? summary.relationmanager.questionnaires_completed.finished
														: 0}
													/
													{summary.relationmanager.questionnaires_completed &&
													summary.relationmanager.questionnaires_completed
														? summary.relationmanager.questionnaires_completed.total
														: 0}
												</span>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className="p-12">
								<div className="w-full">
									<div className="flex w-full">
										<div className="w-1/2">
											<div className="flex w-full">
												<h6>{uctrans("survey.relationmanager.summary_block.average_score_per_question")}</h6>
											</div>
										</div>
									</div>
								</div>
								{(summary.company.average_answer_score && summary.company.average_answer_score.length > 0) ||
								(summary.relationmanager.average_answer_score &&
									summary.relationmanager.average_answer_score.length > 0) ? (
									<div className="w-full">
										<div>
											<RelationManagerSurveyHorizontalBarChart
												currentLabel={summary.company.label}
												currentData={summary.company.average_answer_score}
												totalLabel={summary.relationmanager.label}
												totalData={summary.relationmanager.average_answer_score}
												minXValue={0}
												maxXValue={11}
											/>
										</div>
									</div>
								) : (
									<div className="w-full mb-4">
										<p>{uctrans("report.no_results")}</p>
									</div>
								)}
								<div className="w-full">
									<div className="flex w-full">
										<div className="pw-full w-1/2">
											<div className="flex w-full">
												<h6>{uctrans("survey.relationmanager.summary_block.contact_frequency")}</h6>
											</div>
										</div>
									</div>
								</div>
								{(summary.company.contact_frequency && summary.company.contact_frequency.length > 0) ||
								(summary.relationmanager.contact_frequency && summary.relationmanager.contact_frequency.length > 0) ? (
									<div className="w-full">
										<div>
											<RelationManagerSurveyHorizontalBarChart
												currentLabel={summary.company.label}
												currentData={summary.company.contact_frequency}
												totalLabel={summary.relationmanager.label}
												totalData={summary.relationmanager.contact_frequency}
												minXValue={0}
												maxXValue={101}
												calculatePercentages
											/>
										</div>
									</div>
								) : (
									<div className="w-full pb-6">
										<p>{uctrans("report.no_results")}</p>
									</div>
								)}
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
}

RelationManagerSurveySummaryBlock.propTypes = {
	summaryData: PropTypes.object,
};
