import { useState } from "react";
import { can, route } from "../../../../../../js/helpers";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import Checkmark from "../../../../../../js/react/components/general/form/checkmark/Checkmark";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import Navigation from "./Navigation";

export default function ClientLeadSegmentSettingsForm() {
	const [leadTypes] = useState(window.data.lead_types || []);
	const [segments] = useState(window.data.segments || []);
	const [clientLeadSegmentSettings, setClientLeadSegmentSettings] = useState(
		window.data.client_lead_segment_settings || [],
	);

	const canEdit = can("client_consent.client_lead_setting", "update");

	const onChange = (segment, leadType) => {
		const newLeadTypeSegments = [...clientLeadSegmentSettings];

		const index = clientLeadSegmentSettings.findIndex(
			setting => setting.client_lead_type_id === leadType.key && setting.segment_id === segment.id,
		);

		if (index > -1) {
			newLeadTypeSegments.splice(index, 1);
		} else {
			newLeadTypeSegments.push({
				client_lead_type_id: leadType.key,
				segment_id: segment.id,
			});
		}

		setClientLeadSegmentSettings(newLeadTypeSegments);
	};

	return (
		<AjaxForm
			method="PUT"
			submitUrl={route("crm.client-lead-segment-settings.update")}
			loaderText={uctrans("general.:items_are_being_saved", {}, { items: "client_consents.segment_settings.plural" })}
			successText={uctrans("general.saved_:items", {}, { items: "client_consents.segment_settings.plural" })}
			data={clientLeadSegmentSettings}
			onSuccess={response => {
				setClientLeadSegmentSettings(response.data.client_lead_segment_settings);
			}}>
			<Navigation selectedTab="clientLeadSegmentSettings" />

			<div className="page-title-container">
				<h1 className="page-title">{uctrans("client_consents.segment_settings.plural")}</h1>
			</div>
			<div className="form-full">
				<table className="table overview">
					<thead>
						<tr>
							<th className="text-left"> {uctrans("segments.singular")} </th>
							{leadTypes.map((leadType, index) => (
								<th className="text-left" key={index}>
									{leadType.label}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{segments.map((segment, index) => (
							<tr key={index}>
								<td>{segment.name}</td>
								{leadTypes.map((leadType, index) => (
									<td key={index}>
										<Checkmark
											name="status"
											checked={clientLeadSegmentSettings.some(
												setting => setting.client_lead_type_id === leadType.key && setting.segment_id === segment.id,
											)}
											onChange={() => {
												onChange(segment, leadType);
											}}
										/>
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>

				{canEdit && (
					<>
						<br />
						<br />
						<span className="italic">{uctrans("client_consents.segment_settings.save_warning")}</span>
						<SubmitBar item="client_consents.segment_settings.plural" />
					</>
				)}
			</div>
		</AjaxForm>
	);
}
