import map from "lodash/map";
import forEach from "lodash/forEach";
import sortBy from "lodash/sortBy";
import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { uctrans } from "../../../../../../../js/lib/Translator";
import Dialog from "../../../../../../../js/react/components/general/Dialog";
import EvaIcon from "../../../../../../../js/react/components/general/EvaIcon";
import Select from "../../../../../../../js/react/components/general/Select";
import { CmsTypesContext } from "../../cms-types-context";
import PartListLabel from "../part-lists/PartListLabel";
import PartContent from "../parts/PartContent";
import Attribute from "./Attribute";

export default function ContentList(props) {
	const [contentListData, setContentListData] = useState(props.values);
	useEffect(() => {
		setContentListData(props.values);
	}, [props.values]);

	const [openPartItem, setOpenPartItem] = useState(null);

	const changeOpenPartItemValues = contents => {
		if (openPartItem !== null) {
			const newOpenPartItem = { ...openPartItem };
			newOpenPartItem.contents = contents;
			setOpenPartItem(newOpenPartItem);
		}
	};
	const changeContentListItemAttributeValue = (index, value) => {
		const newContentListData = Array.isArray(contentListData) ? [...contentListData] : [];
		newContentListData[index].value = value;
		setContentListData(newContentListData);
		props.onChange(newContentListData);
	};

	const openPartEditDialog = (index, parttype, contents) => {
		setOpenPartItem({
			index,
			creating: false,
			parttype,
			contents: cloneDeep(contents),
		});
	};
	const addItem = selectedOption => {
		const { value } = selectedOption;
		if (value && value.type) {
			if (value.type === "attribute") {
				const newContentListData = Array.isArray(contentListData) ? [...contentListData] : [];
				newContentListData.push({ attributetype: value.subtype, value: null });
				setContentListData(newContentListData);
				props.onChange(newContentListData);
			} else if (value.type === "part") {
				setOpenPartItem({
					index: -1,
					creating: true,
					parttype: value.subtype,
					contents: null,
				});
			}
		}
	};

	const copyContentListItem = sourceListItemData => {
		if (Object.prototype.hasOwnProperty.call(sourceListItemData, "attributetype")) {
			const attributeType = allowedlistAttributeTypes.find(
				allowedlistAttributeType => allowedlistAttributeType.key === sourceListItemData.attributetype,
			);
			if (attributeType) {
				const newContentListData = Array.isArray(contentListData) ? [...contentListData] : [];
				newContentListData.push({ attributetype: attributeType.key, value: sourceListItemData.value });
				setContentListData(newContentListData);
				props.onChange(newContentListData);
			}
		} else if (Object.prototype.hasOwnProperty.call(sourceListItemData, "parttype")) {
			const partType = allowedlistPartTypes.find(
				allowedlistPartType => allowedlistPartType.key === sourceListItemData.parttype,
			);
			if (partType) {
				setOpenPartItem({
					index: -1,
					creating: true,
					parttype: partType.key,
					contents: sourceListItemData.contents,
				});
			}
		}
	};

	const handlePartDialogSave = () => {
		if (openPartItem !== null) {
			const newContentListData = Array.isArray(contentListData) ? [...contentListData] : [];
			if (openPartItem.creating) {
				newContentListData.push({ parttype: openPartItem.parttype, contents: openPartItem.contents });
			} else {
				newContentListData[openPartItem.index].contents = openPartItem.contents;
			}
			setContentListData(newContentListData);
			props.onChange(newContentListData);
			setOpenPartItem(null);
		}
	};
	const handleDialogCancel = () => {
		setOpenPartItem(null);
	};

	const deleteContentListItem = index => {
		const newContentListData = Array.isArray(contentListData) ? [...contentListData] : [];
		newContentListData.splice(index, 1);
		setContentListData(newContentListData);
		props.onChange(newContentListData);
	};

	const moveListItem = dragEvent => {
		if (dragEvent.destination) {
			const sourceIndex = dragEvent.source.index;
			const targetIndex = dragEvent.destination.index;

			const newContentlistData = Array.isArray(contentListData) ? cloneDeep(contentListData) : [];

			if (`${sourceIndex}` !== `${targetIndex}`) {
				const item = newContentlistData[sourceIndex];
				newContentlistData.splice(sourceIndex, 1);
				newContentlistData.splice(targetIndex, 0, item);

				setContentListData(newContentlistData);
				props.onChange(newContentlistData);
			}
		}
	};

	const changeTypeOfCreatingOpenPartItem = newType => {
		if (openPartItem !== null && openPartItem.creating) {
			setOpenPartItem({
				index: -1,
				creating: true,
				parttype: newType,
				contents: openPartItem.contents,
			});
		}
	};

	const { attributeTypes, partTypes } = useContext(CmsTypesContext);

	let contentListTypeOptions = [];
	const allowedlistAttributeTypes = [];
	const allowedlistPartTypes = [];
	if (Array.isArray(props.allowedAttributetypes)) {
		forEach(attributeTypes, attributeType => {
			if (props.allowedAttributetypes.indexOf(attributeType.key) !== -1) {
				contentListTypeOptions.push({
					label: attributeType.label,
					value: { type: "attribute", subtype: attributeType.key },
				});
				allowedlistAttributeTypes.push(attributeType);
			}
		});
	}
	if (Array.isArray(props.allowedParttypes)) {
		forEach(partTypes, partType => {
			if (props.allowedParttypes.indexOf(partType.key) !== -1) {
				contentListTypeOptions.push({
					label: partType.label,
					value: { type: "part", subtype: partType.key },
				});
				allowedlistPartTypes.push(partType);
			}
		});
	}

	if (contentListTypeOptions.length) {
		contentListTypeOptions = sortBy(contentListTypeOptions, [option => option.label]);
		return (
			<div className="w-full mt-5 mb-12">
				{props.label && <h5>{props.label}</h5>}

				<div className="pl-6 mt-3 border-l border-grey-light">
					<DragDropContext onDragEnd={dragEvent => moveListItem(dragEvent)}>
						<Droppable droppableId="droppable">
							{provided => (
								<div ref={provided.innerRef} {...provided.droppableProps}>
									{(() =>
										map(contentListData, (listItemData, index) => {
											let listAttributeType = null;
											let listPartType = null;
											if (Object.prototype.hasOwnProperty.call(listItemData, "attributetype")) {
												listAttributeType = allowedlistAttributeTypes.find(
													allowedlistAttributeType => allowedlistAttributeType.key === listItemData.attributetype,
												);
											} else if (Object.prototype.hasOwnProperty.call(listItemData, "parttype")) {
												listPartType = allowedlistPartTypes.find(
													allowedlistPartType => allowedlistPartType.key === listItemData.parttype,
												);
											}

											if (listAttributeType || listPartType) {
												return (
													<Draggable key={index} index={index} draggableId={index.toString()}>
														{provided => (
															<div
																{...provided.dragHandleProps}
																ref={provided.innerRef}
																{...provided.draggableProps}
																className="bg-primary-lightest items-center px-3 py-3 my-2 flex justify-between">
																<div className="flex items-center justify-between w-full">
																	<EvaIcon
																		className="flex items-center"
																		type="move-outline"
																		width="20"
																		height="20"
																		fill="#009286"
																	/>
																	<div className="w-full ml-3">
																		{listAttributeType ? (
																			<Attribute
																				attribute={{
																					label: listAttributeType.label,
																					type: listAttributeType.key,
																				}}
																				values={listItemData.value}
																				onChange={values => {
																					changeContentListItemAttributeValue(index, values);
																				}}
																			/>
																		) : (
																			<PartListLabel values={listItemData.contents} parttype={listPartType.key} />
																		)}
																	</div>
																</div>
																<div className="list-item-controls flex items-center">
																	{listPartType && (
																		<span
																			className="cursor-pointer"
																			onClick={() => {
																				openPartEditDialog(index, listPartType.key, listItemData.contents);
																			}}>
																			<EvaIcon
																				className="flex items-center"
																				type="edit-outline"
																				width="20"
																				height="20"
																				fill="#009286"
																			/>
																		</span>
																	)}

																	<span
																		className="cursor-pointer ml-3"
																		onClick={() => {
																			copyContentListItem(listItemData);
																		}}>
																		<EvaIcon
																			className="flex items-center"
																			type="copy-outline"
																			width="20"
																			height="20"
																			fill="#009286"
																		/>
																	</span>
																	<span
																		className="cursor-pointer ml-3"
																		onClick={() => {
																			deleteContentListItem(index);
																		}}>
																		<EvaIcon
																			className="flex items-center"
																			type="trash-2-outline"
																			width="20"
																			height="20"
																			fill="#009286"
																		/>
																	</span>
																</div>
															</div>
														)}
													</Draggable>
												);
											}
										}))()}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
					<div className="w-1/2 ">
						<Select
							placeholder={uctrans("general.add_placeholder")}
							onChange={addItem}
							options={contentListTypeOptions}
							value={null}
							isClearable={false}
						/>
					</div>
				</div>
				<Dialog
					isOpen={openPartItem !== null}
					onClose={handleDialogCancel}
					shouldCloseOnOverlayClick={false}
					width={850}>
					{(() => {
						if (openPartItem !== null) {
							const listPartType = allowedlistPartTypes.find(
								allowedlistPartType => allowedlistPartType.key === openPartItem.parttype,
							);
							if (listPartType) {
								return (
									<>
										<div className="flex justify-between">
											<h4>
												{openPartItem.creating
													? uctrans("general.add_:item", { item: listPartType.label })
													: uctrans("general.edit_:item", { item: listPartType.label })}
											</h4>
											{listPartType.key === "content_block" && openPartItem.creating && (
												<a
													className="link italic"
													onClick={() => {
														changeTypeOfCreatingOpenPartItem("center_column_block");
													}}>
													{uctrans("cms.change_content_block_to_center_column_block")}
												</a>
											)}
										</div>
										<PartContent
											parttype={listPartType.key}
											values={openPartItem.contents}
											onChange={contents => {
												changeOpenPartItemValues(contents);
											}}
										/>
									</>
								);
							}
						}
					})()}
					<div className="">
						<button type="button" className="button button-primary button-dialog" onClick={handlePartDialogSave}>
							{uctrans("general.ok")}
						</button>
						<a onClick={handleDialogCancel}>{uctrans("general.cancel")}</a>
					</div>
				</Dialog>
			</div>
		);
	}
}

ContentList.propTypes = {
	label: PropTypes.string,
	allowedParttypes: PropTypes.arrayOf(PropTypes.string),
	allowedAttributetypes: PropTypes.arrayOf(PropTypes.string),
	values: PropTypes.array,
	onChange: PropTypes.func.isRequired,
};
