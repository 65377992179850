import { uctrans } from "../../../../../js/lib/Translator";
import IconButton from "../../../../../js/react/components/general/IconButton";
import Select from "../../../../../js/react/components/general/Select";
import Tabs from "../dashboard/Tabs";
import BirthdayBlock from "./BirthdayBlock";
import ActiveManagementBlock from "./ActiveManagementBlock";
import CooperationBlock from "./CooperationBlock";
import EngagementScoreBlock from "./EngagementScoreBlock";
import ExternalMailsBlock from "./ExternalMailsBlock";
import IntermediariesToSpeakBlock from "./IntermediariesToSpeakBlock";
import LoanDetailsBlock from "./LoanDetailsBlock";
import LoanRequestBlock from "./LoanRequestBlock";
import LoanResultsBlock from "./LoanResultsBlock";
import ProcessBlock from "./ProcessBlock";
import { RmDashboardContext } from "./RmDashboardContext";
import RmDashboardManager from "./RmDashboardManager";
import SalesFunnelBlock from "./SalesFunnelBlock";

export default function RelationmanagementDashboard() {
	return (
		<RmDashboardManager>
			<Tabs selectedTab="relationmanagement-dashboard" />

			<RmDashboardContext.Consumer>
				{({ walletOptions, setWallet, wallet, printDashboardPDF }) => (
					<>
						<h1>
							{uctrans("dashboard.singular")} {wallet.label}
						</h1>

						<div className="flex w-full justify-between pr-5 pb-5">
							<div className="w-1/2">
								<label className="mr-4 m-auto	">{uctrans("dashboard.wallet")}:</label>
								<div className="w-full pr-2">
									<Select
										value={walletOptions.filter(
											option => option.wallet.type === wallet.type && option.wallet.id === wallet.id,
										)}
										options={walletOptions}
										onChange={selectedOption => {
											setWallet(selectedOption.wallet);
										}}
										isClearable={false}
									/>
								</div>
							</div>
							<div className="w-1/2 mt-3">
								<div className="w-full flex">
									<div className="dashboard-pdf-row w-3/4 mb-2 pl-2">
										<EngagementScoreBlock />
									</div>
									<div className="w-1/4 flex justify-end">
										<div>
											<IconButton
												icon="file-text-outline"
												onClick={() => printDashboardPDF()}
												content={uctrans("dashboard.export_pdf")}
												primary
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="dashboard-pdf-row w-full flex pb-5">
							<div className="w-1/2 pr-5 ">
								<LoanRequestBlock />
							</div>
							<div className="w-1/2 ">
								<SalesFunnelBlock />
							</div>
						</div>
						<div className="dashboard-pdf-row pb-5">
							<LoanResultsBlock />
						</div>
						<div className="dashboard-pdf-row pb-5">
							<LoanDetailsBlock />
						</div>
						<div className="dashboard-pdf-row pb-5">
							<ProcessBlock />
						</div>
						<div className="dashboard-pdf-row pb-5">
							<ActiveManagementBlock />
						</div>
						<div className="dashboard-pdf-row w-full flex pb-5">
							<div className="w-full" id="samenwerking">
								<CooperationBlock />
							</div>
						</div>
						<div className="pb-5">
							<IntermediariesToSpeakBlock />
						</div>
						<div className="pb-5">
							<BirthdayBlock />
						</div>
						<div className="pb-5">
							<ExternalMailsBlock />
						</div>
					</>
				)}
			</RmDashboardContext.Consumer>
		</RmDashboardManager>
	);
}
